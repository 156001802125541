import React from 'react';
import {Skeleton} from 'shared/ui/Skeleton';
import {Content} from 'shared/ui/Content';
import {Flex} from 'shared/ui/Flex';

const columns = Array.from(Array(10));

export const SkeletonPermissions = () => {
  return (
    <>
      <Content>
        {columns.map((_, index) => (
          <Flex
            key={`skeleton_${index}`}
            justifyContent="space-between"
            alignItems="center"
            style={{height: 80}}>
            <Flex alignItems="center">
              <Skeleton height={24} width={220}>
                <rect width={220} height={24} rx={12} ry={12} y={0} x={0} />
              </Skeleton>
            </Flex>
            <Flex alignItems="center">
              <Skeleton height={64} width={256}>
                <rect width={256} height={64} rx={18} />
              </Skeleton>
            </Flex>
          </Flex>
        ))}
      </Content>
      <Skeleton>
        <rect width={720} height={64} rx={14} x={0} y={0} />
      </Skeleton>
    </>
  );
};
