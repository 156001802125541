import React, {useState} from 'react';
import styled from 'styled-components';
import {Download} from 'shared/icons/Download';
import {Text} from './Text';
import {getImagesUrls} from 'shared/lib/api';
import {ImageUpload} from 'shared/__generated__/graphql';
import {Loader} from 'shared/icons/Loader';
import {showAlert} from './Alert';

const Label = styled.label`
  pointer-events: all;
  cursor: pointer;
`;
const Layout = styled.div<{drag: boolean}>`
  display: flex;
  padding: 48px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 24px;
  border: 1px dashed ${({theme}) => theme.mainPrimary};
  background: ${({theme, drag}) =>
    drag ? theme.mainLight + '80' : theme.fillPrimary};
  &:hover {
    background: ${({theme}) => theme.mainLight + '80'};
  }
`;
const Input = styled.input`
  display: none;
`;

function getFilesText(num: number) {
  const [first, second, third] = ['файл', 'файла', 'файлов'];
  const lastDigit = num % 10;
  const lastTwoDigits = num % 100;

  if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
    return `Загрузка: ${num} файлов`;
  }
  if (lastDigit === 1) {
    return `Загрузка: ${num} ${first}`;
  }
  if (lastDigit >= 2 && lastDigit <= 4) {
    return `Загрузка: ${num} ${second}`;
  }
  return `Загрузка: ${num} ${third}`;
}

export const DragDrop = ({
  onChange,
  onLoad,
}: {
  onChange?: (value?: Omit<ImageUpload, 'type'>[]) => void;
  onLoad?: (loading: boolean, loadLenght: number) => void;
}) => {
  const [drag, setDrag] = useState(false);
  const [loading, setLoading] = useState(false);
  const [lenghtFiles, setLenghtFiles] = useState(0);
  function onDragStart(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    setDrag(true);
  }
  function onDragLeave(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    setDrag(false);
  }
  async function onDrop(e: React.DragEvent<HTMLDivElement>) {
    try {
      e.preventDefault();
      const files = [...e.dataTransfer.files];
      const imageFiles = files.filter(file => file.type.startsWith('image/'));
      setLoading(true);
      setLenghtFiles(imageFiles?.length ?? 0);
      onLoad && onLoad(true, files?.length ?? 0);
      const images = await getImagesUrls(imageFiles);
      onChange && onChange(images?.data);
    } catch (e) {
      if (e instanceof Error) {
        showAlert('error', e.message);
      }
    } finally {
      setLoading(false);
      setDrag(false);
      setLenghtFiles(0);
      onLoad && onLoad(false, 0);
    }
  }

  return (
    <Label>
      <Layout
        drag={drag}
        onDragStart={onDragStart}
        onDragLeave={onDragLeave}
        onDragOver={onDragStart}
        onDrop={onDrop}>
        <Input
          type="file"
          accept="image/*"
          multiple
          onChange={async value => {
            const files = value.target.files;
            let size = 0;
            if (files?.length) {
              for (let i = 0; i <= files?.length - 1; i++) {
                size += files[i].size;
                if (size < 12 * 1024 * 1024) {
                  try {
                    setLenghtFiles(files?.length ?? 0);
                    setDrag(true);
                    setLoading(true);
                    onLoad && onLoad(true, files?.length ?? 0);
                    if (files) {
                      const images = await getImagesUrls(files);
                      onChange && onChange(images?.data);
                    }
                  } catch (e) {
                    if (e instanceof Error) {
                      showAlert('error', e.message);
                    }
                  } finally {
                    setLoading(false);
                    setDrag(false);
                    setLenghtFiles(0);
                    onLoad && onLoad(false, 0);
                    value.target.files = null;
                  }
                } else {
                  showAlert(
                    'error',
                    'Общий размер отправляемых данных не должно превышать 30МБ',
                  );
                }
              }
            }
          }}
        />
        {!loading ? <Download size={40} /> : <Loader size={40} />}
        <Text style={{cursor: 'pointer'}} color="mainPrimary">
          {!loading ? 'Загрузите файл' : getFilesText(lenghtFiles)}
        </Text>
      </Layout>
    </Label>
  );
};
