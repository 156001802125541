import React from 'react';
import {useColorScheme} from 'shared/lib/hooks';
import {EmptyContainer} from 'shared/ui/EmptyContainer';

export const EmptyAddCategoryBlock = ({size = 400}: {size?: number}) => {
  const colorScheme = useColorScheme();
  return (
    <EmptyContainer size={size}>
      {colorScheme === 'light' ? (
        <svg viewBox="0 0 400 400" fill="none">
          <path
            d="M179.874 338.559C256.367 338.559 318.381 276.545 318.381 199.779C318.381 123.014 256.094 61 179.874 61C103.382 61 41.3682 123.014 41.3682 199.779C41.3682 276.545 103.382 338.559 179.874 338.559Z"
            fill="#EAEEF9"
          />
          <path
            d="M335.963 155.809C342.149 155.809 347.164 150.794 347.164 144.608C347.164 138.422 342.149 133.408 335.963 133.408C329.777 133.408 324.763 138.422 324.763 144.608C324.763 150.794 329.777 155.809 335.963 155.809Z"
            fill="#D5DDEA"
          />
          <path
            d="M352.35 112.099C356.575 112.099 360 108.674 360 104.45C360 100.225 356.575 96.8005 352.35 96.8005C348.126 96.8005 344.701 100.225 344.701 104.45C344.701 108.674 348.126 112.099 352.35 112.099Z"
            fill="#F1F3F9"
          />
          <path
            d="M47.6493 108.808C51.8738 108.808 55.2985 105.383 55.2985 101.158C55.2985 96.9338 51.8738 93.5092 47.6493 93.5092C43.4247 93.5092 40 96.9338 40 101.158C40 105.383 43.4247 108.808 47.6493 108.808Z"
            fill="#D5DDEA"
          />
          <path
            d="M208.001 237.43V289.981C208.001 294.551 205.343 299.121 201.544 301.405L161.28 324.634C159.001 326.158 155.963 326.919 153.304 326.919V261.421L206.102 230.957C207.242 232.861 208.001 235.146 208.001 237.43Z"
            fill="#F2F5FE"
          />
          <path
            d="M153.304 261.421V326.919C150.645 326.919 147.986 326.158 145.327 324.634L105.064 301.405C100.886 299.121 98.6064 294.932 98.6064 289.981V237.43C98.6064 235.146 99.3661 232.861 100.506 230.957L153.304 261.421Z"
            fill="#D5DDEA"
          />
          <path
            d="M206.106 230.957L153.307 261.421L100.129 230.576C101.268 228.672 102.788 226.768 105.067 225.626L146.47 201.635C150.269 199.35 155.207 199.35 159.385 201.255L201.928 226.007C201.928 226.007 201.928 226.007 202.307 226.007C203.827 227.53 205.346 229.053 206.106 230.957Z"
            fill="white"
          />
          <path
            d="M317.395 237.43V289.981C317.395 294.551 314.736 299.121 310.938 301.405L270.674 324.634C268.395 326.158 265.356 326.919 262.697 326.919V261.421L315.496 230.957C316.635 232.861 317.395 235.146 317.395 237.43Z"
            fill="#F2F5FE"
          />
          <path
            d="M262.7 261.421V326.919C260.041 326.919 257.382 326.158 254.723 324.634L214.459 301.405C210.281 299.121 208.002 294.932 208.002 289.981V237.43C208.002 235.146 208.762 232.861 209.901 230.957L262.7 261.421Z"
            fill="#D5DDEA"
          />
          <path
            d="M315.501 230.957L262.703 261.421L209.524 230.576C210.664 228.672 212.183 226.768 214.462 225.626L255.866 201.635C259.664 199.35 264.602 199.35 268.78 201.255L311.323 226.007C311.323 226.007 311.323 226.007 311.703 226.007C313.222 227.53 314.742 229.053 315.501 230.957Z"
            fill="white"
          />
          <path
            d="M152.925 138.041V190.592C152.925 195.162 150.266 199.731 146.468 202.016L106.204 225.245C103.925 226.768 100.886 227.53 98.2275 227.53V162.032L151.026 131.568C152.545 133.472 152.925 135.756 152.925 138.041Z"
            fill="#F2F5FE"
          />
          <path
            d="M98.23 162.032V227.53C95.5711 227.53 92.9122 226.768 90.2532 225.245L49.9896 202.016C45.8113 199.731 43.5322 195.542 43.5322 190.592V138.041C43.5322 135.756 44.2919 133.472 45.4315 131.568L98.23 162.032Z"
            fill="#D5DDEA"
          />
          <path
            d="M151.409 131.567L98.61 162.031L45.4316 131.186C46.5712 129.282 48.0906 127.378 50.3696 126.235L91.7728 102.245C95.5713 99.9599 100.509 99.9599 104.688 101.864L146.85 126.997C146.85 126.997 146.85 126.997 147.23 126.997C148.75 128.139 150.269 129.663 151.409 131.567Z"
            fill="white"
          />
          <path
            d="M269.546 136.898V195.542C269.546 200.873 266.887 205.823 261.949 208.489L216.747 234.765C214.088 236.288 211.05 237.049 208.011 237.049V163.554L267.646 129.282C268.786 131.567 269.546 134.232 269.546 136.898Z"
            fill="#F2F5FE"
          />
          <path
            d="M208.009 163.554V210.013V237.05C204.97 237.05 201.931 236.288 199.272 234.765L179.52 223.341L153.691 208.489C149.133 205.824 146.474 200.873 146.094 195.923V136.517C146.094 133.852 146.853 131.186 147.993 128.901L208.009 163.554Z"
            fill="#D5DDEA"
          />
          <path
            d="M267.265 129.282L207.629 163.554L147.993 128.901C149.133 126.616 151.032 124.712 153.311 123.57L199.652 96.533C204.21 93.8673 209.528 93.8673 214.086 96.1522L261.947 123.951C261.947 123.951 262.327 123.951 262.327 124.332C264.606 125.855 266.125 127.378 267.265 129.282Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M266.689 91.4402C268.37 91.4402 269.732 92.8026 269.732 94.4832V112.741C269.732 114.422 268.37 115.784 266.689 115.784C265.009 115.784 263.646 114.422 263.646 112.741V94.4832C263.646 92.8026 265.009 91.4402 266.689 91.4402Z"
            fill="#989FB0"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M307.388 92.3315C308.576 93.5198 308.576 95.4465 307.388 96.6349L285.073 118.95C283.884 120.138 281.958 120.138 280.769 118.95C279.581 117.762 279.581 115.835 280.769 114.647L303.084 92.3315C304.273 91.1431 306.2 91.1431 307.388 92.3315Z"
            fill="#989FB0"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M281.902 135.057C281.902 133.376 283.265 132.014 284.945 132.014H305.232C306.913 132.014 308.275 133.376 308.275 135.057C308.275 136.737 306.913 138.1 305.232 138.1H284.945C283.265 138.1 281.902 136.737 281.902 135.057Z"
            fill="#989FB0"
          />
          <path
            d="M237.599 171.692C236.977 174.327 236.044 177.116 234.956 179.44C232.003 185.174 227.339 189.667 221.588 192.611C215.681 195.556 208.686 196.795 201.691 195.246C185.214 191.837 174.644 175.721 178.063 159.296C181.483 142.871 197.494 132.179 213.971 135.743C219.878 136.982 225.008 139.927 229.36 143.955C236.666 151.238 239.775 161.775 237.599 171.692Z"
            fill="url(#paint0_linear_18292_1241)"
          />
          <path
            d="M217.409 162.867H210.476V155.934C210.476 154.547 209.397 153.315 207.857 153.315C206.47 153.315 205.237 154.393 205.237 155.934V162.867H198.304C196.917 162.867 195.685 163.946 195.685 165.487C195.685 167.027 196.763 168.106 198.304 168.106H205.237V175.039C205.237 176.426 206.316 177.659 207.857 177.659C209.243 177.659 210.476 176.58 210.476 175.039V168.106H217.409C218.796 168.106 220.028 167.027 220.028 165.487C220.028 163.946 218.796 162.867 217.409 162.867Z"
            fill="white"
          />
          <defs>
            <linearGradient
              id="paint0_linear_18292_1241"
              x1="177.393"
              y1="165.493"
              x2="238.292"
              y2="165.493"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#B0BACC" />
              <stop offset="1" stopColor="#969EAE" />
            </linearGradient>
          </defs>
        </svg>
      ) : (
        <svg viewBox="0 0 400 400" fill="none">
          <path
            d="M179.874 338.559C256.367 338.559 318.38 276.545 318.38 199.779C318.38 123.014 256.094 61 179.874 61C103.382 61 41.3682 123.014 41.3682 199.779C41.3682 276.545 103.382 338.559 179.874 338.559Z"
            fill="#2A2E37"
          />
          <path
            d="M335.963 155.808C342.149 155.808 347.164 150.794 347.164 144.608C347.164 138.422 342.149 133.407 335.963 133.407C329.777 133.407 324.763 138.422 324.763 144.608C324.763 150.794 329.777 155.808 335.963 155.808Z"
            fill="#2A2E37"
          />
          <path
            d="M352.35 112.099C356.575 112.099 360 108.674 360 104.45C360 100.225 356.575 96.8005 352.35 96.8005C348.126 96.8005 344.701 100.225 344.701 104.45C344.701 108.674 348.126 112.099 352.35 112.099Z"
            fill="#2A2E37"
          />
          <path
            d="M47.6493 108.807C51.8738 108.807 55.2985 105.382 55.2985 101.158C55.2985 96.933 51.8738 93.5083 47.6493 93.5083C43.4247 93.5083 40 96.933 40 101.158C40 105.382 43.4247 108.807 47.6493 108.807Z"
            fill="#2A2E37"
          />
          <path
            d="M208 237.431V289.982C208 294.552 205.342 299.121 201.543 301.406L161.279 324.635C159 326.158 155.962 326.92 153.303 326.92V261.422L206.101 230.958C207.241 232.862 208 235.146 208 237.431Z"
            fill="#6C758B"
          />
          <path
            d="M153.305 261.422V326.92C150.646 326.92 147.987 326.158 145.328 324.635L105.065 301.406C100.886 299.121 98.6074 294.932 98.6074 289.982V237.431C98.6074 235.146 99.3671 232.862 100.507 230.958L153.305 261.422Z"
            fill="#474E61"
          />
          <path
            d="M206.105 230.958L153.306 261.422L100.128 230.577C101.267 228.673 102.787 226.769 105.066 225.627L146.469 201.636C150.268 199.351 155.206 199.351 159.384 201.255L201.926 226.007C201.926 226.007 201.926 226.007 202.306 226.007C203.826 227.531 205.345 229.054 206.105 230.958Z"
            fill="#5E6882"
          />
          <path
            d="M317.397 237.431V289.982C317.397 294.552 314.738 299.121 310.94 301.406L270.676 324.635C268.397 326.158 265.358 326.92 262.699 326.92V261.422L315.498 230.958C316.637 232.862 317.397 235.146 317.397 237.431Z"
            fill="#6C758B"
          />
          <path
            d="M262.702 261.422V326.92C260.043 326.92 257.384 326.158 254.725 324.635L214.461 301.406C210.283 299.121 208.004 294.932 208.004 289.982V237.431C208.004 235.146 208.764 232.862 209.903 230.958L262.702 261.422Z"
            fill="#474E61"
          />
          <path
            d="M315.501 230.958L262.703 261.422L209.524 230.577C210.664 228.673 212.183 226.769 214.462 225.627L255.866 201.636C259.664 199.351 264.602 199.351 268.78 201.255L311.323 226.007C311.323 226.007 311.323 226.007 311.703 226.007C313.222 227.531 314.742 229.054 315.501 230.958Z"
            fill="#5E6882"
          />
          <path
            d="M152.923 138.042V190.592C152.923 195.162 150.264 199.732 146.466 202.016L106.202 225.245C103.923 226.769 100.885 227.53 98.2256 227.53V162.032L151.024 131.568C152.543 133.472 152.923 135.757 152.923 138.042Z"
            fill="#6C758B"
          />
          <path
            d="M98.228 162.032V227.53C95.5691 227.53 92.9102 226.769 90.2513 225.245L49.9876 202.016C45.8093 199.732 43.5303 195.543 43.5303 190.592V138.042C43.5303 135.757 44.29 133.472 45.4295 131.568L98.228 162.032Z"
            fill="#474E61"
          />
          <path
            d="M151.407 131.567L98.608 162.031L45.4297 131.186C46.5692 129.282 48.0886 127.378 50.3677 126.236L91.7708 102.245C95.5693 99.9604 100.507 99.9604 104.686 101.864L146.848 126.997C146.848 126.997 146.848 126.997 147.228 126.997C148.748 128.14 150.267 129.663 151.407 131.567Z"
            fill="#5E6882"
          />
          <path
            d="M269.543 136.898V195.542C269.543 200.873 266.884 205.824 261.946 208.489L216.744 234.765C214.085 236.288 211.047 237.05 208.008 237.05V163.555L267.644 129.282C268.783 131.567 269.543 134.233 269.543 136.898Z"
            fill="#6C758B"
          />
          <path
            d="M208.006 163.555V210.013V237.05C204.967 237.05 201.928 236.289 199.269 234.765L179.517 223.341L153.688 208.49C149.13 205.824 146.471 200.874 146.091 195.923V136.518C146.091 133.853 146.851 131.187 147.99 128.902L208.006 163.555Z"
            fill="#474E61"
          />
          <path
            d="M267.262 129.282L207.626 163.555L147.99 128.901C149.13 126.617 151.029 124.713 153.308 123.57L199.649 96.5332C204.207 93.8676 209.525 93.8676 214.083 96.1524L261.944 123.951C261.944 123.951 262.324 123.951 262.324 124.332C264.603 125.855 266.122 127.378 267.262 129.282Z"
            fill="#5E6882"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M266.686 91.4404C268.366 91.4404 269.729 92.8028 269.729 94.4834V112.741C269.729 114.422 268.366 115.784 266.686 115.784C265.005 115.784 263.643 114.422 263.643 112.741V94.4834C263.643 92.8028 265.005 91.4404 266.686 91.4404Z"
            fill="#989FB0"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M307.383 92.3317C308.571 93.5201 308.571 95.4468 307.383 96.6351L285.068 118.95C283.879 120.139 281.953 120.139 280.764 118.95C279.576 117.762 279.576 115.835 280.764 114.647L303.08 92.3317C304.268 91.1433 306.195 91.1433 307.383 92.3317Z"
            fill="#989FB0"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M281.9 135.057C281.9 133.376 283.263 132.014 284.943 132.014H305.23C306.911 132.014 308.273 133.376 308.273 135.057C308.273 136.737 306.911 138.1 305.23 138.1H284.943C283.263 138.1 281.9 136.737 281.9 135.057Z"
            fill="#989FB0"
          />
          <g filter="url(#filter0_d_18292_1243)">
            <path
              d="M237.6 171.692C236.978 174.326 236.045 177.116 234.957 179.44C232.004 185.173 227.34 189.667 221.589 192.611C215.682 195.555 208.687 196.795 201.692 195.245C185.215 191.836 174.645 175.721 178.064 159.296C181.484 142.87 197.495 132.179 213.972 135.743C219.879 136.982 225.009 139.926 229.361 143.955C236.667 151.238 239.776 161.775 237.6 171.692Z"
              fill="url(#paint0_linear_18292_1243)"
            />
          </g>
          <path
            d="M217.408 162.868H211.475C210.923 162.868 210.475 162.42 210.475 161.868V155.934C210.475 154.548 209.396 153.315 207.856 153.315C206.469 153.315 205.236 154.394 205.236 155.934V161.868C205.236 162.42 204.789 162.868 204.236 162.868H198.303C196.916 162.868 195.684 163.946 195.684 165.487C195.684 167.028 196.762 168.106 198.303 168.106H204.236C204.789 168.106 205.236 168.554 205.236 169.106V175.04C205.236 176.426 206.315 177.659 207.856 177.659C209.242 177.659 210.475 176.581 210.475 175.04V169.106C210.475 168.554 210.923 168.106 211.475 168.106H217.408C218.795 168.106 220.027 167.028 220.027 165.487C220.027 163.946 218.795 162.868 217.408 162.868Z"
            fill="#474E61"
          />
          <defs>
            <filter
              id="filter0_d_18292_1243"
              x="173.422"
              y="135.057"
              width="68.8594"
              height="68.8596"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_18292_1243"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_18292_1243"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_18292_1243"
              x1="191.382"
              y1="151.032"
              x2="217.564"
              y2="195.357"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#E3EAF7" />
              <stop offset="1" stopColor="#ADB6C8" />
            </linearGradient>
          </defs>
        </svg>
      )}
    </EmptyContainer>
  );
};
