import React, {useEffect, useState} from 'react';
import {useQuery} from '@apollo/client';
import dayjs from 'dayjs';
import {gql} from 'shared/__generated__';
import {CircleAlert} from 'shared/icons/CircleAlert';
import {useColors, useResize} from 'shared/lib/hooks';
import {
  setCreateBookingWorkingDate,
  setEditBookingWorkingDate,
  useAppDispatch,
  useAppSelector,
} from 'shared/store';
import {DatePicker} from 'shared/ui/DatePicker';
import {Dropdown} from 'shared/ui/Dropdown';
import {Text} from 'shared/ui/Text';
import {TextInput} from 'shared/ui/TextInput';
import styled from 'styled-components';
import {Flex} from 'shared/ui/Flex';
import {TextView} from 'shared/ui/TextView';
import {ArrowDown2v} from 'shared/icons/ArrowDown2v';
import {Tooltip} from 'shared/ui/Tooltip';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  justify-content: space-between;
  gap: 8px;
  position: relative;
`;

const Block = styled.div`
  display: block;
  position: relative;
  flex: 1;
`;

const EMPLOYEE_WINDOWS = gql(`
  query EmployeeWindows($date: Date!, $employeeId: String, $companyId: String,  $services: [String!]) {
    employeeWindows(date: $date, employee_id: $employeeId, company_id: $companyId) {
      is_busy
      time
    }
    widgetGetWorkingDates(company_id: $companyId, employee_id: $employeeId, services: $services) {
      id
      date
      start_time
      end_time
    }
  }
`);

export const SelectDate = ({
  value,
  disabled,
  variant = 'edit',
}: {
  value?: string | null;
  disabled?: boolean;
  variant?: 'view' | 'edit';
}) => {
  const [dateStr, setDateStr] = useState(
    value ? dayjs(value).format('DD.MM.YYYY') : '',
  );
  const [date, setDate] = useState<string>(
    value ?? dayjs().format('YYYY-MM-DD HH:mm'),
  );
  const [timeStr, setTimeStr] = useState<string>(dayjs(value).format('HH:mm'));
  const [dateVisible, setDateVisible] = useState(false);
  const [timeVisible, setTimeVisible] = useState(false);
  const colors = useColors();
  const screen = useAppSelector(state => state.booking.screen);
  const companyId = useAppSelector(state => state.company.data!.id);
  const {employeeId, services} = useAppSelector(state => state.booking[screen]);
  const {isMobile} = useResize();
  const dispatch = useAppDispatch();
  const {data} = useQuery(EMPLOYEE_WINDOWS, {
    variables: {
      employeeId: employeeId !== '0' ? employeeId : undefined,
      companyId,
      date: dayjs(date ?? undefined).format('YYYY-MM-DD'),
      services: services.map(service => service.service_id),
    },
  });
  const setRedux =
    screen === 'create'
      ? setCreateBookingWorkingDate
      : setEditBookingWorkingDate;

  useEffect(() => {
    setDate(value ?? dayjs().format('YYYY-MM-DD'));
    setDateStr(
      value ? dayjs(value).format('DD.MM.YYYY') : dayjs().format('DD.MM.YYYY'),
    );
  }, [value]);

  return (
    <Flex direction="column" gap={16}>
      <Text typography="title18" color="textPrimary">
        Дата и время
      </Text>
      <Wrapper>
        {variant === 'view' ? (
          <>
            <Block>
              <TextView
                title={'Дата'}
                text={date ? dayjs(date).format('DD.MM.YYYY') : ''}
              />
            </Block>
            <Block>
              <TextView
                title={'Время'}
                text={date ? dayjs(date).format('HH:mm') : ''}
              />
            </Block>
          </>
        ) : (
          <>
            <Block>
              <TextInput
                variant="label_animation"
                label="Дата"
                disabled={disabled}
                width={'100%'}
                placeholder="Выберите дату"
                value={dateStr}
                onChange={value => {
                  setDateStr(value);
                  const day = value.split('.')[0];
                  const month = value.split('.')[1];
                  const year = value.split('.')[2];
                  setDate(
                    dayjs(date)
                      .date(+day)
                      .month(+month - 1)
                      .year(+year)
                      .format('YYYY-MM-DD HH:mm'),
                  );
                }}
                onKeyDown={event => {
                  if (event.key === 'Enter' || event.key === 'Escape') {
                    const workingDateId = data?.widgetGetWorkingDates.find(
                      item => item.date === dayjs(date).format('YYYY-MM-DD'),
                    )?.id;
                    dispatch(setRedux(date + ',' + workingDateId));
                    setDateVisible(false);
                  }
                  if (event.key === 'Escape') {
                    setDateVisible(false);
                  }
                }}
                mask="99.99.9999"
                onClick={() => disabled !== true && setDateVisible(true)}
                style={{flex: 1}}
              />
              <DatePicker
                value={
                  date
                    ? dayjs(date).format('YYYY-MM-DD')
                    : dayjs().format('YYYY-MM-DD')
                }
                onChange={value => {
                  setDate(value);
                  setDateStr(
                    dayjs(value).format(
                      'DD.MM.YYYY ' + dayjs(date).format('HH:mm'),
                    ),
                  );
                }}
                style={{
                  left: isMobile ? -32 : 'initial',
                  width: 'max-content',
                  top: 66,
                }}
                visible={dateVisible}
                marked={data?.widgetGetWorkingDates.map(day => day.date) ?? []}
                onClose={() => setDateVisible(false)}
              />
            </Block>
            <Block>
              <TextInput
                variant="label_animation"
                label="Время"
                width={'100%'}
                placeholder="Выберите время"
                onChange={value => {
                  setTimeStr(value);
                  if (value !== '') {
                    const hour = value.split(':')[0];
                    const minute = value.split(':')[1];
                    setDate(
                      dayjs(date)
                        .hour(+hour)
                        .minute(minute ? +minute : 0)
                        .format('YYYY-MM-DD HH:mm'),
                    );
                  }
                }}
                onKeyDown={event => {
                  if (event.key === 'Enter') {
                    const workingDateId = data?.widgetGetWorkingDates.find(
                      item => item.date === dayjs(date).format('YYYY-MM-DD'),
                    )?.id;
                    dispatch(setRedux(date + ',' + workingDateId));
                    setTimeVisible(false);
                  }
                  if (event.key === 'Escape') {
                    setTimeVisible(false);
                  }
                }}
                mask="99:99"
                disabled={disabled}
                value={timeStr}
                onClick={() => {
                  disabled !== true && setTimeVisible(true);
                }}
                rightElement={
                  <Flex direction="row" gap={12}>
                    {data?.employeeWindows.find(
                      item => item.time === dayjs(date).format('HH:mm'),
                    )?.is_busy ? (
                      <Tooltip
                        style={{width: '343px', marginLeft: -171}}
                        text="Обратите внимание, что время и сотрудник уже заняты или недоступны, но вы все равно можете его выбрать">
                        <CircleAlert color={colors.warningPrimary} />
                      </Tooltip>
                    ) : undefined}
                    <ArrowDown2v />
                  </Flex>
                }
              />
              <Dropdown
                visible={timeVisible}
                onClose={() => setTimeVisible(false)}
                listEmpty={
                  <Text typography="subHead14Regular" style={{width: 174}}>
                    В эту дату время <br /> не найдено
                  </Text>
                }
                withBackdrop={false}
                style={{width: 'max-content', top: 50}}
                data={data?.employeeWindows}
                renderItem={item => (
                  <DivTime
                    key={item.time}
                    active={item.time === timeStr}
                    onClick={() => {
                      setDate(
                        dayjs(date).format('YYYY-MM-DD') + ' ' + item.time,
                      );
                      const workingDate = data?.widgetGetWorkingDates.find(
                        wdate =>
                          wdate.date === dayjs(date).format('YYYY-MM-DD'),
                      );
                      dispatch(
                        setRedux(
                          dayjs(date).format('YYYY-MM-DD') +
                            ' ' +
                            item.time +
                            ',' +
                            workingDate!.id,
                        ),
                      );
                      setTimeStr(item.time);
                      setTimeVisible(false);
                    }}>
                    <Text
                      typography={
                        item.time === timeStr
                          ? 'text16Semibold'
                          : 'text16Regular'
                      }>
                      {item.time}
                    </Text>
                    {item.is_busy ? (
                      <Tooltip
                        style={{
                          width: 'max-content',
                          padding: '8px 12px',
                          borderRadius: 8,
                        }}
                        position="right"
                        text={'Время недоступно'}>
                        <CircleAlert />
                      </Tooltip>
                    ) : null}
                  </DivTime>
                )}></Dropdown>
            </Block>
          </>
        )}
      </Wrapper>
    </Flex>
  );
};

const DivTime = styled.button<{active: boolean}>`
  display: flex;
  flex-direction: row;
  padding: 0 8px 0 24px;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  border: none;
  border-bottom-width: 1px;
  width: 174px;
  height: 56px;
  background-color: transparent;
  pointer-events: all;
  background-color: ${({theme, active}) =>
    active ? theme.bgSecondary : 'transparent'};
  cursor: pointer;
  &:hover {
    background-color: ${({theme}) => theme.bgSecondary};
  }
`;
