import React, {useState} from 'react';
import {styled} from 'styled-components';
import {OptionsType, Table, TableSortType} from 'shared/ui/Table';
import {gql} from 'shared/__generated__';
import {useAppSelector} from 'shared/store';
import {useQuery} from '@apollo/client';
import {
  QueryGetStatisticsCertificateSalesOrderByColumn,
  SortOrder,
  StatisticsDates,
} from 'shared/__generated__/graphql';
import {Flex} from 'shared/ui/Flex';
import {
  FilterEmptyState,
  FilterResetButton,
  FilterSearch,
  FilterStatisticsCertificate,
  FilterStatisticsCustomer,
  FilterStatisticsEmployee,
  FilterStatisticsPeriod,
} from 'entities/statistics';
import dayjs from 'dayjs';
import {getPriceFormat} from 'shared/lib/utils';
import {Content} from 'shared/ui/Content';
import {PageHeader} from 'shared/ui/PageHeader';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
`;

const GET_STATISTICS_CERTIFICATE_SALES = gql(`
  query GetStatisticsCertificateSales(
    $companyId: String!
    $date: StatisticsDates!
    $first: Int!
    $page: Int
    $employeeId: String
    $customerId: String
    $query: String
    $certificateId: String
    $orderBy: [QueryGetStatisticsCertificateSalesOrderByOrderByClause!]
  ) {
    getStatisticsCertificateSales(
      company_id: $companyId
      date: $date
      first: $first
      page: $page
      employee_id: $employeeId
      customer_id: $customerId
      query: $query
      certificate_id: $certificateId
      orderBy: $orderBy
    ) {
      paginatorInfo {
        hasMorePages
        currentPage
        total
        count
        lastPage
      }
      data {
        balance
        client
        name
        sale_by
        sale_time
      }
    }
  }
`);
const titles = ['Название', 'Дата продажи', 'Клиент', 'Баланс', 'Продан от'];
const sortValues: QueryGetStatisticsCertificateSalesOrderByColumn[] = [
  QueryGetStatisticsCertificateSalesOrderByColumn.Name,
  QueryGetStatisticsCertificateSalesOrderByColumn.SaleTime,
  QueryGetStatisticsCertificateSalesOrderByColumn.Client,
  QueryGetStatisticsCertificateSalesOrderByColumn.Balance,
  QueryGetStatisticsCertificateSalesOrderByColumn.SaleBy,
];

export const LoyaltySalesCertificates = () => {
  const companyId = useAppSelector(state => state.company.data!.id);
  const [sort, setSort] = useState<TableSortType>({
    index: 0,
    order: SortOrder.Desc,
  });
  const [search, setSearch] = useState('');
  const [employeeId, setEmployeeId] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [certificateId, setCertificateId] = useState('');
  const [options, setOptions] = useState<OptionsType>({
    page: 1,
    first: 10,
    total: 0,
    count: 0,
    lastPage: 1,
  });
  const [period, setPeriod] = useState<StatisticsDates>(
    StatisticsDates.Alltime,
  );
  const {data, loading} = useQuery(GET_STATISTICS_CERTIFICATE_SALES, {
    variables: {
      companyId,
      employeeId,
      customerId,
      certificateId,
      date: period,
      first: options.first,
      page: options.page,
      query: search,
      orderBy: [
        {
          order: sort.order,
          column: sortValues[sort.index],
        },
      ],
    },
    onCompleted: res =>
      setOptions(prev => ({
        ...prev,
        total: res.getStatisticsCertificateSales?.paginatorInfo.total,
        count: res.getStatisticsCertificateSales?.paginatorInfo.count,
        lastPage: res.getStatisticsCertificateSales?.paginatorInfo.lastPage,
      })),
  });
  const statistics = data?.getStatisticsCertificateSales?.data ?? [];
  const isEmpty = !statistics?.filter(item => item.name !== 'Итого').length;

  return (
    <Wrapper>
      <PageHeader back title="Статистика по продаже сертификатов" />
      <Flex direction="row" gap={8}>
        <FilterSearch onChange={setSearch} value={search} />
        <FilterStatisticsPeriod {...{period, setPeriod}} />
        <FilterStatisticsCertificate {...{certificateId, setCertificateId}} />
        <FilterStatisticsCustomer {...{customerId, setCustomerId}} />
        <FilterStatisticsEmployee {...{employeeId, setEmployeeId}} />
        {certificateId ||
        customerId ||
        employeeId ||
        search ||
        period !== StatisticsDates.Alltime ? (
          <FilterResetButton
            onClick={() => {
              setCertificateId('');
              setCustomerId('');
              setEmployeeId('');
              setSearch('');
              setPeriod(StatisticsDates.Alltime);
            }}
          />
        ) : (
          <></>
        )}
      </Flex>
      {!loading && isEmpty ? (
        <FilterEmptyState />
      ) : (
        <Content>
          <Table
            options={options}
            setOptions={setOptions}
            titles={titles}
            sort={sort}
            results
            alignRightColumn={[3]}
            onClickTitle={(index, order) => setSort({index, order})}
            rows={statistics.map(item => [
              item.name,
              dayjs(item.sale_time).isValid()
                ? dayjs(item.sale_time).format('DD.MM.YYYY HH:mm')
                : item.sale_time,
              item.client,
              getPriceFormat(item.balance ?? 0),
              item.sale_by,
            ])}
          />
        </Content>
      )}
    </Wrapper>
  );
};
