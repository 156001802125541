import React, {useState} from 'react';
import {Button} from 'shared/ui/Button';
import {Divider} from 'shared/ui/Divider';
import {Flex} from 'shared/ui/Flex';
import {Text} from 'shared/ui/Text';
import {TextInput} from 'shared/ui/TextInput';
import styled from 'styled-components';
import {WelcomeChat} from './ui/WelcomeChat';
import {BlockChats} from './ui/BlockChats';
import {createMessage, useAppDispatch, useAppSelector} from 'shared/store';
import {Messages} from './ui/Messages';
import {yandexGptCompletion} from 'shared/api/yandexGPT';
import {showAlert} from 'shared/ui/Alert';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  height: 100vh;
  gap: 24px;
`;

const BlockMain = styled.div`
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  grid-template-rows: 1fr min-content min-content;
  gap: 24px;
  grid-column-start: 3;
  grid-column-end: 13;
  padding-top: 24px;
  padding-bottom: 64px;
`;

export const NeuralChat = () => {
  const [message, setMessage] = useState('');
  const dispatch = useAppDispatch();
  const [select, setSelect] = useState<number | null>(null);
  const messagesStorage = useAppSelector(state => state.chats.messages);
  const [loadingSend, setLoadingSend] = useState(false);

  const data = select
    ? messagesStorage.filter(item => item.chat_id === select)
    : [];

  const onSend = async (message: string) => {
    dispatch(
      createMessage({
        chat_id: select!,
        id: messagesStorage.length,
        message: message,
        type: 'user',
      }),
    );
    setMessage('');
    try {
      setLoadingSend(true);
      const response = await yandexGptCompletion({
        messages: [{role: 'user', text: message}],
      });
      const alternatives = response.data.result.alternatives ?? [];
      const length = response.data.result.alternatives.length;
      dispatch(
        createMessage({
          chat_id: select!,
          id: messagesStorage.length,
          message: alternatives[length - 1].message.text,
          type:
            alternatives[length - 1].message.role === 'user'
              ? 'user'
              : 'system',
        }),
      );
    } catch (e) {
      if (e instanceof Error) {
        showAlert('error', e.message);
      }
    } finally {
      setLoadingSend(false);
    }
  };

  return (
    <Wrapper>
      <BlockChats select={select} onSelect={setSelect} />
      <BlockMain>
        <Text
          typography="title24"
          style={{
            height: 24,
            gridColumnStart: 2,
            width: 'max-content',
          }}>
          Нейросеть Booky
        </Text>
        {data.length === 0 ? <WelcomeChat /> : null}
        <Messages messages={data} loading={loadingSend} />
        <InputContainer disabled={select !== null ? false : true}>
          <TextInput
            placeholder="Могу я чем-нибудь помочь?"
            value={message}
            onChange={setMessage}
            variant="default"
          />
          <Divider />
          <Flex justifyContent="flex-end">
            <Button
              disabled={message.length === 0}
              onClick={() => {
                onSend(message);
              }}>
              <Text color="white">Отправить</Text>
            </Button>
          </Flex>
        </InputContainer>
      </BlockMain>
    </Wrapper>
  );
};

const InputContainer = styled.div<{disabled: boolean}>`
  grid-column-start: 2;
  grid-column-end: 9;
  background-color: ${({theme}) => theme.bgPrimary};
  border-radius: 18px;
  padding: ${({disabled}) => (disabled ? '0px 24px' : '24px 24px')};
  display: flex;
  overflow: hidden;
  height: ${({disabled}) => (disabled ? '0px' : '217px')};
  flex-direction: column;
  gap: 24px;
  transition:
    height 200ms ease-out,
    padding 200ms ease-out 100ms;
`;
