import React from 'react';
import {Skeleton} from 'shared/ui/Skeleton';

const COLUMN = 4;
const columns = Array.from(Array(COLUMN).keys());

export const SkeletonCatalog = () => {
  return (
    <Skeleton height={424 * COLUMN}>
      {columns.map((column, index) => (
        <React.Fragment key={'column_' + column}>
          <rect
            width={210}
            height={28}
            rx={14}
            ry={14}
            x={24}
            y={10 + index * 424}
          />
          {columns.map((row, indexRow) => (
            <rect
              key={'row_' + row}
              width={385.5}
              height={320}
              rx={32}
              ry={32}
              x={24 + indexRow * (385.5 + 24)}
              y={28 + 26 + 10 + index * 424}
            />
          ))}
        </React.Fragment>
      ))}
    </Skeleton>
  );
};
