import React, {useState} from 'react';
import styled from 'styled-components';
import {ArrowRight2v} from 'shared/icons/ArrowRight2v';
import {Text} from 'shared/ui/Text';
import {Content} from 'shared/ui/Content';
import dayjs from 'dayjs';
import {ArrowLeft2v} from 'shared/icons/ArrowLeft2v';
import {setBookingDate, useAppDispatch, useAppSelector} from 'shared/store';
import {weekdays} from 'shared/lib/utils';
import {useColors} from 'shared/lib/hooks';
import {IconButton} from 'shared/ui/IconButton';

const Container = styled.div`
  gap: 24px;
  width: 408px;
`;

const Block = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  flex: 1;
  gap: 24px;
  padding: 0 24px 24px 0px;
  position: sticky;
  top: 0px;
`;

const MonthCalendar = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  align-items: center;
  justify-content: space-between;
  height: 52px;
  border: 1px solid ${({theme}) => theme.borderPrimary};
  border-radius: 12px;
  padding: 14px 16px;
`;

const WeekdayContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

const MonthContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
`;

const Dot = styled.div<{$backgroundColor: string}>`
  width: 6px;
  height: 6px;
  background-color: ${({$backgroundColor}) => $backgroundColor};
  border-radius: 3px;
`;

const DayContainer = styled.button<{
  $gridColumnStart?: number;
  $backgroundColor: string;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: none;
  width: auto;
  height: auto;
  margin: 0;
  gap: 0;
  padding: 0;
  aspect-ratio: 1;
  background-color: ${({$backgroundColor}) => $backgroundColor};
  grid-column-start: ${({$gridColumnStart}) => $gridColumnStart};
  border-radius: 38px;
  pointer-events: all;
  &:hover {
    border-color: ${({theme}) => theme.mainPrimary};
    border-width: 1px;
    border-style: solid;
  }
`;

export const FixedCalendar = () => {
  const colors = useColors();
  const [values] = useState(dayjs().format('YYYY-MM-DD'));
  const dateRedux = useAppSelector(state => state.booking.date);
  const [date, setDate] = useState(dayjs(values).format('YYYY-MM-DD'));
  const dispatch = useAppDispatch();
  const days = Array.from(Array(dayjs(date).daysInMonth()), (_, index) =>
    dayjs(date)
      .set('date', index + 1)
      .format('YYYY-MM-DD'),
  );

  return (
    <Container id="FixedCalendar">
      <Block>
        <MonthCalendar>
          <IconButton
            variant="unstyled"
            style={{borderRadius: 0}}
            onClick={() => {
              setDate(dayjs(date).subtract(1, 'month').format('YYYY-MM-DD'));
            }}
            size={24}>
            <ArrowLeft2v />
          </IconButton>
          <Text typography="title18">{dayjs(date).format('MMMM YYYY')}</Text>
          <IconButton
            variant="unstyled"
            style={{borderRadius: 0}}
            onClick={() => {
              setDate(dayjs(date).add(1, 'month').format('YYYY-MM-DD'));
            }}>
            <ArrowRight2v />
          </IconButton>
        </MonthCalendar>
        <Content>
          <WeekdayContainer>
            {weekdays.map((item, index) => (
              <Text
                color={
                  dayjs().format('dd') === item ? 'mainPrimary' : 'textTertiary'
                }
                style={{
                  width: '100%',
                  textAlign: 'center',
                }}
                typography="footNote12Medium"
                key={'weekday_' + index}>
                {item}
              </Text>
            ))}
          </WeekdayContainer>
          <MonthContainer>
            {days.map((item, index) => (
              <DayContainer
                key={'day_container_' + index}
                $gridColumnStart={
                  dayjs(item).get('day') === 0 ? 7 : dayjs(item).get('day')
                }
                $backgroundColor={
                  dayjs(dateRedux).isSame(dayjs(item), 'date')
                    ? colors.mainPrimary
                    : 'transparent'
                }
                onClick={() => {
                  dispatch(setBookingDate(item));
                  setDate(item);
                }}>
                <Text
                  typography="footNote12Medium"
                  color={
                    dayjs(dateRedux).isSame(dayjs(item), 'date')
                      ? 'white'
                      : 'textPrimary'
                  }>
                  {dayjs(item).format('D')}
                </Text>
                {dayjs().isSame(dayjs(item), 'date') ? (
                  <Dot
                    $backgroundColor={
                      dayjs(dateRedux).isSame(dayjs(item), 'date')
                        ? colors.white
                        : colors.mainPrimary
                    }
                  />
                ) : null}
              </DayContainer>
            ))}
          </MonthContainer>
        </Content>
      </Block>
    </Container>
  );
};
