import React, {useEffect, useState} from 'react';
import {Slide} from 'shared/ui/Slide';
import {Content} from 'shared/ui/Content';
import {Flex} from 'shared/ui/Flex';
import {Text} from 'shared/ui/Text';
import {TextInput} from 'shared/ui/TextInput';
import {useColors} from 'shared/lib/hooks';
import {IconButton} from 'shared/ui/IconButton';
import {Copy} from 'shared/icons/Copy';
import {useQuery} from '@apollo/client';
import {useAppSelector} from 'shared/store';
import styled from 'styled-components';
import {Skeleton} from 'shared/ui/Skeleton';
import {getNumberFormat, getPriceFormat} from 'shared/lib/utils';
import {gql} from 'shared/__generated__';
import {showAlert} from 'shared/ui/Alert';

const GET_REFERRAL = gql(`
  query GetReferal($companyId: String!, $first: Int!, $key: String) {
    getReferal(company_id: $companyId) {
      id
      referal
      statistics {
        added_alltime
        bonus_account_balance
        payments
        registered
      }
    }
  getDocument(first: $first, key: $key) {
    data {
      id
      content
    }
  }
}
`);
const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
`;

const ContainerHtml = styled.div<{showMore: boolean}>`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: ${({showMore}) => (showMore ? 'auto' : '100px')};
  color: ${({theme}) => theme.textPrimary};
`;

export const Main = ({activeSlide}: {activeSlide: number}) => {
  const companyId = useAppSelector(state => state.company.data!.id);
  const [showMore, setShowMore] = useState(false);
  const {data, loading} = useQuery(GET_REFERRAL, {
    variables: {companyId, first: 1, key: 'referral-program'},
  });
  const refData = data?.getReferal.statistics;
  const referal = data?.getReferal.referal ?? '';
  const content = data?.getDocument?.data[0]?.content ?? '';
  const colors = useColors();
  useEffect(() => {
    if (activeSlide === 1) {
      setShowMore(false);
    }
  }, [activeSlide]);

  return (
    <Slide gap={24}>
      <Content>
        <Text typography="title18">
          Скопируйте ссылку или промокод и приглашайте друзей
        </Text>
        <Flex gap={16}>
          <TextInput
            flex={1}
            label="Партнерский промокод для друзей"
            value={referal}
            disabled
            style={{color: colors.textPrimary}}
            rightElement={
              <IconButton
                onClick={e => {
                  e.stopPropagation();
                  navigator.clipboard
                    .writeText(referal)
                    .then(() => showAlert('success', 'Скопировано'))
                    .catch(() =>
                      showAlert('error', 'Сообщение не скопировано'),
                    );
                }}
                variant="unstyled">
                <Copy />
              </IconButton>
            }
          />
          <TextInput
            flex={1}
            label="Партнерская ссылка"
            disabled
            value={'https://business.booky.co/?ref=' + referal}
            style={{
              color: colors.textPrimary,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            rightElement={
              <IconButton
                onClick={e => {
                  e.stopPropagation();
                  navigator.clipboard
                    .writeText('https://business.booky.co/?ref=' + referal)
                    .then(() => showAlert('success', 'Скопировано'))
                    .catch(() =>
                      showAlert('error', 'Сообщение не скопировано'),
                    );
                }}
                variant="unstyled">
                <Copy />
              </IconButton>
            }
          />
        </Flex>
      </Content>
      {loading ? (
        <SkeletonBlock />
      ) : (
        <Grid>
          <Block title="Количество регистраций:" value={refData?.registered} />
          <Block title="Количество оплат:" value={refData?.payments} />
          <Block
            type="currency"
            title="Ваш баланс:"
            value={refData?.bonus_account_balance}
          />
          <Block
            type="currency"
            title="Выплачено за все время:"
            value={refData?.added_alltime}
          />
        </Grid>
      )}
      <Content gap="4px">
        <Text typography="title18">Основные правила</Text>
        <ContainerHtml
          showMore={showMore}
          dangerouslySetInnerHTML={{__html: content}}
        />
        <Text
          onClick={() => setShowMore(!showMore)}
          style={{pointerEvents: 'all', cursor: 'pointer'}}
          color="mainPrimary">
          {showMore ? 'Свернуть ' : 'Показать больше'}
        </Text>
      </Content>
    </Slide>
  );
};

const Value = styled.div`
  font-family: 'Inter';
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 64px;
  color: ${({theme}) => theme.textPrimary};
`;

const Block = ({
  title,
  value = 0,
  type = 'count',
}: {
  title: string;
  value?: number;
  type?: 'count' | 'currency';
}) => (
  <Content padding="32px 24px">
    <Text typography="text18">{title}</Text>
    <Value>
      {type === 'count' ? getNumberFormat(value) : getPriceFormat(value)}
    </Value>
  </Content>
);

const SkeletonBlock = () => {
  const width = 'calc((100% - 24px) / 2)';
  const positionX = 'calc(((100% - 24px) / 2) + 24px)';
  return (
    <Skeleton height={368}>
      <rect x={0} y={0} width={width} height={172} rx={12} ry={12} />
      <rect x={0} y={196} width={width} height={172} rx={12} ry={12} />
      <rect x={positionX} y={0} width={width} height={172} rx={12} ry={12} />
      <rect x={positionX} y={196} width={width} height={172} rx={12} ry={12} />
    </Skeleton>
  );
};
