export const colors = {
  light: {
    mainPrimary: '#6765F8',
    mainPrimary50: '#C2C1FC',
    mainLight50: '#F9F8FF',
    mainLight: '#EFEEFF',
    borderPrimary: '#F2F2F2',
    borderLiner: '#FFFFFF',
    dangerPrimary: '#FF1C45',
    waitingPrimary: '#6765F8',
    successPrimary: '#1BC760',
    warningPrimary: '#FF8E3C',
    goldPrimary: '#FFB743',
    grayPrimary: '#21272D',
    fillPrimary: '#F6F7F8',
    textPrimary: '#0B1F33',
    textSecondary: '#717195',
    textTertiary: '#989EAF',
    bgPrimary: '#FFFFFF',
    bgSecondary: '#F6F7F8',
    bgTertiary: '#FCFCFC',
    bgDanger: '#FFDCD8',
    bgSuccess: '#D4FFE5',
    bgWaiting: '#EFEEFF',
    bgWarning: '#FFE6C0',
    hoverButton: '#7A78F4',
    hoverButtonLight: '#E7E5FF',
    white: '#FFFFFF',
    black: '#000000',
    gray20: 'rgba(0, 0, 0, 0.2)',
    systemColor: '#000000',
    overlay48: 'rgba(5, 16, 27, 0.48)',

    skeletonBackground: '#E7E7E7',
    skeletonForeground: '#C7C7C7',
    sidebarTooltip: '#EEEEEE',
    modalOverlay: '#2d374a14',

    mapShadow: 'box-shadow: 0px 0px 22px 9px rgba(29, 27, 31, 0.18);',
    cardShadow: 'box-shadow: 0px 0px 43px 0px rgba(45, 55, 74, 0.08);',
    tabBarShadow: 'box-shadow: 0px -21px 18px 0px rgba(143, 154, 174, 0.04);',
    blurShadow: 'backdrop-filter: blur(10px);',
    dropShadow: 'box-shadow: 10px 10px 33px 0px #2D374A0A',
  },
  dark: {
    mainPrimary: '#6765F8',
    mainPrimary50: '#3A3974',
    mainLight50: '#282842',
    mainLight: '#232346',
    borderPrimary: '#2A2A2D',
    borderLiner: '#2A2A2D',
    dangerPrimary: '#FF1C45',
    waitingPrimary: '#6765F8',
    successPrimary: '#1BC760',
    warningPrimary: '#FF8E3C',
    goldPrimary: '#FF9900',
    grayPrimary: '#232326',
    fillPrimary: '#2D2D2F',
    textPrimary: '#FDFDFD',
    textSecondary: '#797B82',
    textTertiary: '#919198',
    bgPrimary: '#1D1D1D',
    bgSecondary: '#0E0E0F',
    bgTertiary: '#141415',
    bgDanger: '#571C27',
    bgSuccess: '#144126',
    bgWaiting: '#232346',
    bgWarning: '#5C3D26',
    hoverButton: '#7A78F4',
    hoverButtonLight: '#2C2C59',
    white: '#FFFFFF',
    black: '#000000',
    gray20: 'rgba(0, 0, 0, 0.20)',
    systemColor: '#FFFFFF',
    overlay48: 'rgba(1, 1, 3, 0.48)',

    skeletonBackground: '#171717',
    skeletonForeground: '#2E2E2E',
    sidebarTooltip: '#232326',
    modalOverlay: '#2d374a14',

    mapShadow: 'box-shadow: 0px 0px 22px 9px rgba(29, 27, 31, 0.18);',
    cardShadow: 'box-shadow: 0px 0px 43px 0px rgba(45, 55, 74, 0.08);',
    tabBarShadow: 'box-shadow: 0px -21px 18px 0px rgba(143, 154, 174, 0.04);',
    blurShadow: 'backdrop-filter: blur(2px);',
    dropShadow: 'box-shadow: 10px 10px 33px 0px #2D374A0A',
  },
};
export type ColorsType = keyof typeof colors.light;
