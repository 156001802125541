import React, {useState} from 'react';
import {useQuery} from '@apollo/client';
import {gql} from 'shared/__generated__';
import {Search} from 'shared/icons/Search';
import {getName, getRating} from 'shared/lib/utils';
import {
  setCreateBookingEmployee,
  setEditBookingEmployee,
  useAppDispatch,
  useAppSelector,
} from 'shared/store';
import {Avatar} from 'shared/ui/Avatar';
import {Dropdown} from 'shared/ui/Dropdown';
import {IconButton} from 'shared/ui/IconButton';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';
import {InputAvatar} from './InputAvatar';
import {Popover} from 'shared/ui/Popover';
import {Flex} from 'shared/ui/Flex';
import {ArrowDown2v} from 'shared/icons/ArrowDown2v';
import {useColors, useDebounce} from 'shared/lib/hooks';
import {ActionIcon} from 'shared/ui/ActionIcon';
import Check from 'shared/icons/Check';
import {Skeleton} from 'shared/ui/Skeleton';
import {EmptyEmployees} from 'shared/illustration/EmptyEmployees';
import {Button} from 'shared/ui/Button';
import {useNavigate} from 'react-router-dom';
import Xv2 from 'shared/icons/Xv2';
import {COMPANY_EMPLOYEES} from '..';

const Wrapper = styled.div`
  display: block;
  position: relative;
`;

const EMPLOYEE = gql(`
  query GetBookingEmployee($id: String!, $date: Date!, $time: String!) {
    employeeBusy(employee_id: [$id], date: $date, time: $time)
  }
`);

type Props = {
  services?: string[];
  workingDate?: string;
  start?: string;
  booking?: boolean;
  disabled?: boolean;
  value?: string | null;
  onChange?: (value: string | null) => void;
  variant?: 'view' | 'edit';
};

export const SelectEmployee = (props: Props) => {
  const {
    onChange,
    disabled,
    services,
    workingDate,
    start,
    value,
    booking = true,
    variant = 'edit',
  } = props;
  const [visible, setVisible] = useState(false);
  const companyId = useAppSelector(state => state.company.data?.id);
  const [search, setSearch] = useState('');
  const colors = useColors();

  const {data, loading, refetch} = useQuery(COMPANY_EMPLOYEES, {
    variables: {
      services: services,
      start: start,
      workingDate: workingDate,
      companyId: companyId,
      name: search !== '' ? search : undefined,
    },
    pollInterval: 30000,
  });
  const {data: dataEmployee} = useQuery(EMPLOYEE, {
    variables: {
      id: value!,
      date: workingDate,
      time: start!,
    },
  });
  const screen = useAppSelector(state => state.booking.screen);
  const dispatch = useAppDispatch();
  const setRedux =
    screen === 'create' ? setCreateBookingEmployee : setEditBookingEmployee;
  const navigate = useNavigate();
  const username = useAppSelector(state => state.company.data?.username);

  const employees = data?.widgetGetEmployees ?? [];
  const findEmployees = employees.find(employee => employee.id === value);
  const employeesData =
    booking && employees.length > 0
      ? [
          {
            id: '0',
            name: 'Любой',
            surname: 'Сотрудник',
            professions: [],
            position: 0,
            is_busy: false,
            avatar: null,
            rating_1_count: 0,
            rating_2_count: 0,
            rating_3_count: 0,
            rating_4_count: 0,
            rating_5_count: 0,
          },
          ...employees,
        ]
      : employees;

  const nameRefetch = useDebounce((name: string) => {
    const variables = {
      services,
      start,
      workingDate,
      companyId,
      name,
    };
    refetch(variables);
  }, 400);

  return (
    <Flex direction="column" gap={16}>
      <Text typography="title18" color="textPrimary">
        Сотрудник
      </Text>
      <Wrapper>
        <InputAvatar
          leftElement={
            value !== undefined ? (
              <Avatar
                size={40}
                url={findEmployees?.avatar?.url}
                active={findEmployees?.user?.is_online}
                activeSize={16}
              />
            ) : (
              <Search color={colors.textTertiary} />
            )
          }
          search={search}
          setSearch={value => {
            setSearch(value);
            nameRefetch(value);
          }}
          focused={visible}
          rightElement={
            variant === 'edit' ? (
              <>
                {value && (
                  <IconButton
                    size={24}
                    style={{
                      padding: 0,
                      borderColor: 'transparent',
                      backgroundColor: 'transparent',
                    }}
                    onClick={e => {
                      setSearch('');
                      dispatch(setRedux(undefined));
                      e.stopPropagation();
                    }}>
                    <Xv2 />
                  </IconButton>
                )}
                <IconButton
                  size={24}
                  style={{
                    padding: 0,
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    transform: visible ? 'rotate(180deg)' : 'rotate(0)',
                    transition: 'transform 300ms',
                  }}>
                  <ArrowDown2v />
                </IconButton>
              </>
            ) : undefined
          }
          label={
            findEmployees !== undefined
              ? getName(findEmployees.name, findEmployees.surname, 'employee')
              : value === '0'
              ? 'Любой сотрудник'
              : undefined
          }
          value={
            findEmployees && findEmployees.professions.length > 0
              ? findEmployees.professions[0].name
              : value === '0'
              ? null
              : undefined
          }
          tooltip={dataEmployee?.employeeBusy.includes(value!)}
          disabled={disabled}
          onClick={() => setVisible(true)}
        />
        <Dropdown
          visible={visible}
          data={employeesData}
          style={{
            top: 48,
            maxHeight: employees.length === 0 ? 380 : 350,
          }}
          withBackdrop={false}
          width="100%"
          gap={8}
          skeleton={loading ? <SkeletonEmployee /> : undefined}
          listEmpty={
            employees.length === 0 && search === '' ? (
              <Flex
                alignItems="center"
                flex={1}
                style={{marginBottom: 8, marginTop: 8}}
                direction="column"
                gap={16}>
                <EmptyEmployees size={200} />
                <Text color="textTertiary" align="center">
                  У вас пока нет сотрудников. Добавьте <br /> сотрудников и
                  начните работу
                </Text>
                <Button
                  style={{marginTop: 8}}
                  onClick={() => {
                    navigate(`/${username}/add-employee`);
                  }}
                  size="large">
                  Добавить сотрудников
                </Button>
              </Flex>
            ) : (
              <Flex
                alignItems="center"
                flex={1}
                style={{marginBottom: 8, marginTop: 8}}
                direction="column">
                <EmptyEmployees size={200} />
                <Text color="textTertiary" align="center">
                  По вашему запросу ничего не найдено. <br /> Проверьте данные и
                  попробуйте еще раз
                </Text>
              </Flex>
            )
          }
          onClose={() => setVisible(false)}
          renderItem={item => (
            <Row
              selected={item.id === value}
              onClick={() => {
                onChange && onChange(item.id);
                setVisible(false);
              }}>
              <Flex direction="row" gap={16}>
                <Avatar
                  size={56}
                  url={item.avatar?.url}
                  rating={getRating(item)}
                />
                <Flex direction="column" justifyContent="center" gap={4}>
                  <Text style={{pointerEvents: 'none'}}>
                    {getName(item.name, item.surname, 'employee')}
                  </Text>
                  {item.professions && (
                    <Text
                      style={{pointerEvents: 'none'}}
                      typography="subHead14Regular"
                      color="textTertiary">
                      {item.professions && item.professions.length > 0
                        ? item.professions[0].name
                        : ''}
                    </Text>
                  )}
                </Flex>
              </Flex>
              {value === item.id && (
                <ActionIcon
                  icon={<Check color={colors.mainPrimary} />}
                  style={{
                    backgroundColor: 'transparent',
                    width: 44,
                    height: 44,
                    border: 'none',
                  }}
                />
              )}
              {value !== item.id && item.is_busy && (
                <ActionIcon
                  icon={
                    <Popover
                      style={{
                        borderRadius: 8,
                        color: colors.textPrimary,
                        fontSize: 12,
                        // TODO: испрвить цвет когда будет правка по цветам
                        boxShadow: '0px 10px 22px 0.9px #1D1B1F2B',
                        padding: '8px 12px',
                      }}
                      text="Сотрудник недоступен"
                    />
                  }
                  style={{
                    width: 44,
                    height: 44,
                    border: 'none',
                    backgroundColor: 'transparent',
                  }}
                />
              )}
            </Row>
          )}
        />
      </Wrapper>
    </Flex>
  );
};

const Row = styled.button<{selected: boolean}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  width: 100%;
  background-color: ${({theme, selected}) =>
    selected ? theme.bgSecondary : 'transparent'};
  border: none;
  justify-content: space-between;
  cursor: pointer;
  &:hover {
    background-color: ${({theme}) => theme.bgSecondary};
  }
`;

const SkeletonEmployee = () => {
  return (
    <Skeleton width={'100%'} height={394}>
      {Array.from(Array(4).keys()).map((_, index) => (
        <React.Fragment key={'employee_sk_' + index}>
          <circle r={28} cx={44} cy={36 + 88 * index} />
          <rect
            width={180}
            height={24}
            rx={8}
            ry={8}
            x={88}
            y={12 + 88 * index}
          />
          <rect
            width={120}
            height={20}
            rx={8}
            ry={8}
            x={88}
            y={40 + 88 * index}
          />
        </React.Fragment>
      ))}
    </Skeleton>
  );
};
