import React, {useState} from 'react';
import {useMutation, useQuery} from '@apollo/client';
import {PaymentMethodEnum} from 'shared/__generated__/graphql';
import Close from 'shared/icons/Close';
import {Button} from 'shared/ui/Button';
import {Flex} from 'shared/ui/Flex';
import {IconButton} from 'shared/ui/IconButton';
import {Text} from 'shared/ui/Text';
import {TextInput} from 'shared/ui/TextInput';
import styled from 'styled-components';
import {Modal} from 'shared/ui/Modal';
import {List} from 'shared/ui/List';
import {GET_LOYALTIES, CREATE_PAYMENT} from 'entities/booking';
import dayjs from 'dayjs';
import {Error} from 'shared/ui/Error';
import {Popup} from 'shared/ui/Popup';
import {Grow} from 'shared/ui/Grow';
import {CloseButton} from 'shared/ui/CloseButton';
import {ArrowDown2v} from 'shared/icons/ArrowDown2v';
import {Select} from 'shared/ui/Select';
import {getNumberFormat, getPriceFormat} from 'shared/lib/utils';
import {showAlert} from 'shared/ui/Alert';

const getCurrentBonusSize = (
  bonusSizes: {
    id: string;
    min_amount?: number | null | undefined;
    size: number;
  }[],
  total: number,
) => {
  const values = bonusSizes
    .map(item => ({
      value: total - (item.min_amount ?? 0),
      id: item.id,
    }))
    .filter(item => item.value >= 0);
  if (values.length === 0) {
    return 0;
  }
  const min = Math.min(...values.map(item => item.value));
  const id = values.find(item => item.value === min)?.id;
  if (id) {
    return bonusSizes.find(item => item.id === id)?.size ?? 0;
  }

  return 0;
};

const PaymentMethodWrapper = styled.div`
  position: relative;
`;

const Body = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: column;
`;

const StyledIconButton = styled(IconButton)`
  width: 24px;
  height: 24px;
  padding: 0;
  border-color: transparent;
  background-color: transparent;
  &:hover {
    background-color: transparent !important;
  }
`;

const typePayment = [
  {
    label: 'Наличными',
    value: PaymentMethodEnum.Cash,
  },
  {
    label: 'Картой',
    value: PaymentMethodEnum.Card,
  },
  {
    label: 'Бонусами',
    value: PaymentMethodEnum.BonusCard,
  },
  {
    label: 'Сертификатом',
    value: PaymentMethodEnum.Certificate,
  },
];
const modalStyle = {
  top: 64,
  width: '100%',
  left: 0,
  padding: '16px 0',
};

const isBonusDisabled = (
  total: number,
  paid: number,
  balance: number,
  is_active: boolean,
  valid_until: string,
  bonusSizes: {
    id: string;
    min_amount?: number | null | undefined;
    size: number;
  }[],
  paymentLimit: number,
  date?: string,
) => {
  if (is_active === false) {
    return true;
  }
  if (valid_until && dayjs(date).isAfter(dayjs(valid_until), 'date')) {
    return true;
  }

  if (balance === 0) {
    return true;
  }

  const bonusSizePercent = Math.min(
    getCurrentBonusSize(bonusSizes, total - paid),
    paymentLimit,
  );
  const calcPrice = Math.trunc(((total - paid) / 100) * bonusSizePercent);

  if (balance < calcPrice) {
    return true;
  }

  if (paymentLimit !== 0 && calcPrice / 100 > paymentLimit) {
    return true;
  }

  return false;
};

const isCertificateDisabled = (
  price: number,
  balance: number,
  isActive: boolean,
  validUntil?: string,
  date?: string,
) => {
  if (isActive === false) {
    return true;
  }
  if (dayjs(date).isAfter(validUntil, 'date')) {
    return true;
  }
  if (balance < price) {
    return true;
  }
  return false;
};

export const AddPayment = ({
  visible,
  onClose,
  total,
  paid,
  id,
  customerId,
  date,
}: {
  visible: boolean;
  onClose: () => void;
  total?: number;
  paid?: number;
  id: string;
  customerId?: string;
  date?: string;
}) => {
  const [type, setType] = useState<PaymentMethodEnum>(PaymentMethodEnum.Cash);
  const [price, setPrice] = useState('');
  const [createPayment, {loading}] = useMutation(CREATE_PAYMENT, {
    refetchQueries: ['PaymentsPaymentCheques'],
  });
  const {data, error} = useQuery(GET_LOYALTIES, {
    variables: {
      customerId: customerId!,
    },
  });
  const bonuses = data?.bonusCardsByCustomerId ?? [];
  const certificates = data?.certificatesByCustomer ?? [];
  const [certificateVisible, setCertificateVisible] = useState(false);
  const [bonusVisible, setBonusVisible] = useState(false);
  const [certificateId, setCertificateId] = useState<string | undefined>();
  const [bonusId, setBonusId] = useState<string | undefined>();

  return (
    <Popup visible={visible} onClose={onClose}>
      <Grow style={{width: '558px', gap: 32}}>
        <Flex
          direction="row"
          alignItems="center"
          justifyContent="space-between">
          <Text typography="title20">Добавить платеж</Text>{' '}
          <CloseButton onClose={onClose} />
        </Flex>
        <Body>
          {error ? (
            <Error message={error.message} />
          ) : (
            <>
              <Select
                data={typePayment}
                onChange={value => {
                  if (
                    value === PaymentMethodEnum.Card ||
                    value === PaymentMethodEnum.Cash
                  ) {
                    setBonusId(undefined);
                    setCertificateId(undefined);
                  }
                  setType(value as PaymentMethodEnum);
                  setPrice('');
                }}
                label="Тип оплаты"
                value={typePayment.find(item => item.value === type)?.value}
              />
              {type === PaymentMethodEnum.Certificate && (
                <PaymentMethodWrapper>
                  <TextInput
                    label="Сертификат"
                    value={
                      !certificateId
                        ? '-'
                        : certificates.find(item => item.id === certificateId)
                            ?.name
                    }
                    onClick={() => {
                      setCertificateVisible(true);
                    }}
                    rightElement={
                      <Flex alignItems="center" gap={8}>
                        {certificateId && (
                          <StyledIconButton
                            onClick={() => setCertificateId(undefined)}>
                            <Close />
                          </StyledIconButton>
                        )}
                        <StyledIconButton
                          rotate={certificateVisible ? 180 : 0}
                          onClick={() => setCertificateVisible(true)}>
                          <ArrowDown2v />
                        </StyledIconButton>
                      </Flex>
                    }
                  />
                  <Modal
                    style={modalStyle}
                    visible={certificateVisible}
                    onClose={() => setCertificateVisible(false)}>
                    <Flex style={{maxHeight: 352, overflowY: 'auto'}}>
                      {certificates.length ? (
                        <List
                          data={certificates}
                          gap={8}
                          renderItem={item => (
                            <CertificateItem
                              disabled={isCertificateDisabled(
                                +price,
                                item.balance,
                                item.is_active,
                                item.valid_until,
                                date,
                              )}
                              name={item.name}
                              balance={getPriceFormat(item.balance)}
                              expiration={
                                item.valid_until
                                  ? dayjs(item.valid_until).format(
                                      'до DD.MM.YYYY',
                                    )
                                  : undefined
                              }
                              onClick={() => {
                                setCertificateId(item.id);
                                setPrice(
                                  Math.min(item.balance, total ?? 0).toString(),
                                );
                                setBonusId(undefined);
                                setCertificateVisible(false);
                              }}
                            />
                          )}
                        />
                      ) : (
                        <Flex
                          direction="column"
                          justifyContent="center"
                          style={{height: 56, width: '100%'}}>
                          <Text
                            color="textTertiary"
                            style={{
                              marginLeft: 16,
                              marginRight: 16,
                            }}>
                            Ничего не найдено
                          </Text>
                        </Flex>
                      )}
                    </Flex>
                  </Modal>
                </PaymentMethodWrapper>
              )}
              {type === PaymentMethodEnum.BonusCard && (
                <PaymentMethodWrapper>
                  <TextInput
                    label="Бонусная карта"
                    value={
                      !bonusId
                        ? '-'
                        : bonuses.find(item => item.id === bonusId)?.name
                    }
                    onClick={() => setBonusVisible(true)}
                    rightElement={
                      <Flex alignItems="center" gap={8}>
                        {bonusId && (
                          <StyledIconButton
                            onClick={() => setBonusId(undefined)}>
                            <Close />
                          </StyledIconButton>
                        )}
                        <StyledIconButton
                          rotate={bonusVisible ? 180 : 0}
                          onClick={() => setBonusVisible(true)}>
                          <ArrowDown2v />
                        </StyledIconButton>
                      </Flex>
                    }
                  />
                  <Modal
                    style={modalStyle}
                    visible={bonusVisible}
                    onClose={() => setBonusVisible(false)}>
                    <Flex style={{maxHeight: 352, overflow: 'auto'}}>
                      {bonuses.length ? (
                        <List
                          data={bonuses}
                          gap={8}
                          renderItem={item => (
                            <BonusItem
                              name={item.name}
                              balance={getNumberFormat(item.balance, ' Б')}
                              limit={
                                item.bonusCardTemplate.payment_limit + ' %'
                              }
                              disabled={isBonusDisabled(
                                total ?? 0,
                                paid ?? 0,
                                item.balance,
                                item.is_active,
                                item.valid_until,
                                item.bonusCardTemplate.bonusSizes,
                                item.bonusCardTemplate.payment_limit,
                                date,
                              )}
                              onClick={() => {
                                setBonusId(item.id);
                                item.bonusCardTemplate.payment_limit;
                                const bonusSizePercent = Math.min(
                                  getCurrentBonusSize(
                                    item.bonusCardTemplate.bonusSizes,
                                    (total ?? 0) - (paid ?? 0),
                                  ),
                                  item.bonusCardTemplate.payment_limit,
                                );
                                const calcPrice =
                                  ((total ?? 0) / 100) * bonusSizePercent;
                                setPrice(calcPrice.toFixed(2));
                                setCertificateId(undefined);
                                setBonusVisible(false);
                              }}
                            />
                          )}
                        />
                      ) : (
                        <Flex
                          direction="column"
                          justifyContent="center"
                          style={{height: 56, width: '100%'}}>
                          <Text
                            color="textTertiary"
                            style={{
                              marginLeft: 16,
                              marginRight: 16,
                            }}>
                            Ничего не найдено
                          </Text>
                        </Flex>
                      )}
                    </Flex>
                  </Modal>
                </PaymentMethodWrapper>
              )}
              <TextInput
                value={price}
                label="Сумма"
                type="number"
                disabled={type === PaymentMethodEnum.BonusCard}
                onChange={value => setPrice(value)}
                rightElement={
                  <Text typography="subHead14Regular" color="textTertiary">
                    {type === PaymentMethodEnum.BonusCard ? 'Б' : '₽'}
                  </Text>
                }
              />
            </>
          )}
        </Body>
        <Button
          disabled={loading || price === '' || total! < +price}
          loading={loading}
          size="large"
          onClick={async () => {
            createPayment({
              variables: {
                input: {
                  amount: Math.round(+price),
                  booking_id: id,
                  method: type,
                  bonus_card_id: bonusId,
                  certificate_id: certificateId,
                },
              },
            })
              .then(() => {
                setType(PaymentMethodEnum.Cash);
                setPrice('');
                setBonusId(undefined);
                setCertificateId(undefined);
                onClose();
              })
              .catch(e => {
                showAlert('error', e.message);
              });
          }}>
          Оплатить
        </Button>
      </Grow>
    </Popup>
  );
};

const ItemWrapper = styled.button<{selected: boolean}>`
  height: 64px;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 24px;
  border: none;
  pointer-events: all;
  gap: 4px;
  cursor: ${({disabled}) => (disabled ? 'auto' : 'pointer')};
  justify-content: center;
  background-color: ${({theme, selected}) =>
    selected ? theme.bgSecondary : 'transparent'};
  &:hover {
    background-color: ${({theme, disabled}) =>
      !disabled ? theme.bgSecondary : 'transparent'};
  }
`;

const CertificateItem = ({
  name,
  balance,
  expiration,
  disabled,
  onClick,
}: {
  name: string;
  balance: string;
  expiration?: string;
  disabled: boolean;
  onClick: () => void;
}) => {
  return (
    <ItemWrapper selected={false} disabled={disabled} onClick={onClick}>
      <Text
        color={disabled ? 'textTertiary' : 'textPrimary'}
        style={{pointerEvents: 'none'}}>
        {name}
      </Text>
      <Flex direction="row" gap={8}>
        <Text
          typography="subHead14Regular"
          color="textTertiary"
          style={{pointerEvents: 'none'}}>
          Баланс: {balance}
          {expiration ? ';' : undefined}
        </Text>
        {expiration && (
          <Text
            typography="subHead14Regular"
            color="textTertiary"
            style={{pointerEvents: 'none'}}>
            действует до {expiration}
          </Text>
        )}
      </Flex>
    </ItemWrapper>
  );
};

const BonusItem = ({
  name,
  balance,
  limit,
  onClick,
  disabled,
}: {
  name: string;
  balance: string;
  limit?: string;
  onClick: () => void;
  disabled: boolean;
}) => {
  return (
    <ItemWrapper selected={false} disabled={disabled} onClick={onClick}>
      <Text
        color={disabled ? 'textTertiary' : 'textPrimary'}
        style={{pointerEvents: 'none'}}>
        {name}
      </Text>
      <Flex direction="row" gap={8}>
        <Text typography="subHead14Regular" color="textTertiary">
          Баланс {balance}
          {limit ? ';' : undefined}
        </Text>
        {limit && (
          <Text typography="subHead14Regular" color="textTertiary">
            Лимит {limit}
          </Text>
        )}
      </Flex>
    </ItemWrapper>
  );
};
