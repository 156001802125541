import React from 'react';
import {usePayment} from './Provider';
import {TextInput} from 'shared/ui/TextInput';
import {Button} from 'shared/ui/Button';
import {TariffBox} from 'entities/tariff';
import {gql} from 'shared/__generated__';
import {useLazyQuery} from '@apollo/client';
import dayjs from 'dayjs';
import {showAlert} from 'shared/ui/Alert';
import Accept from 'shared/icons/Accept';
import {useColors} from 'shared/lib/hooks';

const CHECK_PROMOCODE = gql(`
  query Promocode($promocode: String!) {
    promocode(promocode: $promocode) {
      id
      name
      end_date
      start_date
      is_available
      discount_value
      promocode
      type
    }
  }
`);

export const PromoCodeSection = () => {
  const colors = useColors();
  const {state, setState} = usePayment();
  const [value, setValue] = React.useState('');
  const [error, setError] = React.useState<string | null>(null);
  const [checkPromocode, {loading}] = useLazyQuery(CHECK_PROMOCODE);
  const onPress = () => {
    checkPromocode({
      variables: {promocode: value},
    })
      .then(result => {
        if (!result.data || !result.data?.promocode) {
          setError('Такого промокода не существует');
          setState({
            ...state,
            promoCode: null,
          });
          return;
        }
        const resPromocode = result.data.promocode;
        const isAfterOrSameStart =
          dayjs().isAfter(resPromocode.start_date, 'date') ||
          dayjs().isSame(resPromocode.start_date, 'date');
        const isBeforeOrSameEnd =
          dayjs().isBefore(resPromocode.end_date, 'date') ||
          dayjs().isSame(resPromocode.end_date, 'date');
        if (
          isAfterOrSameStart &&
          isBeforeOrSameEnd &&
          resPromocode.is_available
        ) {
          showAlert('success', 'Промокод успешно применен!');
          setState({
            ...state,
            promoCode: resPromocode.discount_value + ',' + resPromocode.type,
          });
        } else {
          setError('Промокод недоступен');
        }
      })
      .catch(e => {
        showAlert('error', e.message);
      });
  };
  return (
    <TariffBox title="Промокод" gap={8} direction="row" alignItems="start">
      <TextInput
        flex={1}
        label="Введите промокод"
        onChange={value => {
          if (error !== null) setError(null);
          setValue(value);
        }}
        value={value}
        rightElement={
          state.promoCode ? <Accept color={colors.successPrimary} /> : undefined
        }
        error={error}
      />
      <Button
        style={{width: 148, height: 64}}
        loading={loading}
        size="large"
        onClick={() => {
          if (state.promoCode) {
            setState({...state, promoCode: null});
            setValue('');
          } else {
            onPress();
          }
        }}
        typography="text16Semibold"
        variant="outline">
        {state.promoCode ? 'Отменить' : 'Применить'}
      </Button>
    </TariffBox>
  );
};
