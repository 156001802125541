import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {Text} from 'shared/ui/Text';
import dayjs from 'dayjs';
import {ArrowLeft1v} from 'shared/icons/ArrowLeft1v';
import {ArrowRight1v} from 'shared/icons/ArrowRight1v';
import {Period, salaryPresets, schedulePresets} from 'shared/lib/utils';
import {Presets} from './ui/Presets';
import {Dates} from './ui/Dates';
import {SlidesContainer} from 'shared/ui/SlidesContainer';
import {from} from '@apollo/client';

type SelectProps = {
  onChange: (value: {from: string | null; to: string | null}) => void;
  type?: 'salary' | 'schedule';
  error?: string | null;
};

const SelectWrapper = styled.div<{isSalary: boolean}>`
  position: relative;
  display: flex;
  flex-direction: column;
  pointer-events: all;
  width: ${({isSalary}) => (isSalary ? 'max-content' : '100%')};
`;
const Button = styled.button`
  display: flex;
  justify-content: space-between;
  min-width: 384px;
  padding: 0 12px;
  align-items: center;
  height: 52px;
  border-radius: 12px;
  border: 1px solid ${({theme}) => theme.borderPrimary};
  background-color: ${({theme}) => theme.bgPrimary};
`;
const Select = styled.button`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  padding: 0 16px;
  height: 64px;
  border-radius: 18px;
  min-width: 100%;
  border: 1px solid ${({theme}) => theme.borderPrimary};
  background-color: ${({theme}) => theme.fillPrimary};
  gap: 4px;
`;
const StyledLabel = styled.div<{fly: boolean; required: boolean}>`
  display: flex;
  font-family: 'Inter';
  font-size: ${({fly}) => (fly ? '12px' : '16px')}px;
  font-style: normal;
  font-weight: 400;
  line-height: ${({fly}) => (fly ? '18px' : '24px')};
  color: ${({theme}) => theme.textTertiary};
  transition: all 150ms ease;
  &::after {
    content: '*';
    color: ${({theme}) => theme.dangerPrimary};
    visibility: ${({required}) => (required ? 'visible' : 'hidden')};
    font-size: ${({fly}) => (fly ? '12px' : '16px')};
  }
`;
const StyledValue = styled.div`
  display: flex;
  font-family: 'Inter';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: ${({theme}) => theme.textPrimary};
`;
const Arrow = styled.button<{position: 'left' | 'right'}>`
  display: flex;
  padding: 0;
  border: none;
  background-color: transparent;
  height: 100%;
  padding-right: ${({position}) => (position === 'left' ? '40px' : '0')};
  padding-left: ${({position}) => (position === 'left' ? '0' : '40px')};
  align-items: center;
`;

const Wrapper = styled.div<{
  visible: boolean;
  position?: 'left' | 'right' | 'middle';
}>`
  display: ${({visible}) => (visible ? 'flex' : 'none')};
  position: absolute;
  left: ${({position}) =>
    position === 'left'
      ? 0
      : position === 'right'
      ? 'calc(100% - 572px)'
      : 'calc((100% - 572px)/2)'};
  top: 64px;
  z-index: 1000;
  width: 572px;
  height: max-content;
  border-radius: 24px;
  flex-direction: column;
  padding-bottom: 24px;
`;
const Header = styled.div`
  padding: 32px 24px;
  background-color: ${({theme}) => theme.bgPrimary};
  border: 1px 1px 0 solid ${({theme}) => theme.borderPrimary};
  border-radius: 24px 24px 0 0;
  ${({theme}) => theme.cardShadow}
`;
const SegmentsControl = styled.div`
  position: relative;
  display: flex;
  padding: 4px;
  gap: 4px;
  border-radius: 12px;
  height: 50px;
  background-color: ${({theme}) => theme.fillPrimary};
`;
const Tabs = styled.button<{active: boolean}>`
  display: flex;
  border: none;
  background: none;
  padding: 0;
  height: 100%;
  width: 100px;
  align-items: center;
  justify-content: center;
  font-family: 'Inter';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  z-index: 2;
  color: ${({theme, active}) =>
    active ? theme.mainPrimary : theme.textPrimary};
  transition: color 300ms;
`;
const ActiveTab = styled.button<{activeIndex: number}>`
  position: absolute;
  height: 100%;
  width: 100px;
  top: 4px;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 12px;
  height: 42px;
  left: ${({activeIndex}) => 4 + activeIndex * 104}px;
  background-color: ${({theme}) => theme.bgPrimary};
  transition: left 300ms ease-out;
`;

export enum TYPE {
  Preset = 'preset',
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Period = 'period',
}
export const types = [
  {label: 'Пресеты', type: TYPE.Preset},
  {label: 'День', type: TYPE.Day},
  {label: 'Неделя', type: TYPE.Week},
  {label: 'Месяц', type: TYPE.Month},
  {label: 'Период', type: TYPE.Period},
];

const format = 'D MMM YYYY';
const defaultFormat = 'YYYY-MM-DD';
export type InfinityType = 'prev' | 'next' | 'both' | undefined;

const checkPeriod = (start: string, end: string, type?: 'next' | 'last') => {
  if (!start.length || !end.length) {
    return start.length
      ? dayjs(start).format(`D ${type === 'next' ? 'MMMM' : 'MMM'} YYYY`)
      : dayjs(end).format(`D ${type === 'next' ? 'MMMM' : 'MMM'} YYYY`);
  }
  if (type === 'next') {
    return (
      dayjs(start).format('D MMMM YYYY') +
      ' - ' +
      dayjs(end).format('D MMMM YYYY')
    );
  }
  const splitStart = start.length
    ? dayjs(start).format(format).split(' ')
    : [''];
  const splitEnd = end.length ? dayjs(end).format(format).split(' ') : [''];
  if (splitStart[0].length > 0 && splitEnd[0].length > 0) {
    if (splitStart[2] !== splitEnd[2])
      return splitStart.join(' ') + ' - ' + splitEnd.join(' ');
    if (splitStart[1] !== splitEnd[1]) {
      return splitStart[0] + ' ' + splitStart[1] + ' - ' + splitEnd.join(' ');
    }
    const periodStart = splitStart.length ? splitStart[0] : '';
    const periodEnd = splitEnd.length ? splitEnd.join(' ') : '';
    return (
      periodStart +
      (periodStart.length && periodEnd.length ? ' - ' : '') +
      periodEnd
    );
  }
  if (splitStart[0].length) {
    return splitStart.join(' ');
  }
  if (splitEnd[0].length) {
    return splitEnd.join(' ');
  }
  return 'Не выбрана дата';
};

const getPeriod = (
  {type: periodType, value, infinity}: Period,
  variant?: 'last' | 'next',
): {label: string; period: {from: string | null; to: string | null}} => {
  const type = variant ?? 'last';
  switch (periodType) {
    case 'date': {
      const diff = dayjs().startOf('day').diff(dayjs(value), 'day');
      const isYesterday = diff === 1;
      const isToday = diff === 0;
      const formatDate = dayjs(value).format('D MMMM YYYY,YYYY-MM-DD');
      const result = formatDate.split(',');
      return {
        label:
          (isToday ? 'Сегодня, ' : isYesterday ? 'Вчера, ' : '') + result[0],
        period: {from: result[1], to: result[1]},
      };
    }
    case 'months': {
      if (dayjs(value).isValid()) {
        const start = dayjs(value).startOf('month').format(defaultFormat);
        const end = dayjs(value).endOf('month').format(defaultFormat);
        return {
          label: checkPeriod(start, end, type),
          period: {from: start, to: end},
        };
      }
      if (value === 'quarter') {
        const month = dayjs().get('month') + 1;
        const quarter = Math.trunc(month / 3) - 2;
        const start = dayjs()
          .set('month', quarter * 3)
          .startOf('month')
          .format(defaultFormat);
        const end = dayjs()
          .startOf('month')
          .set('month', (quarter + 1) * 3)
          .add(-1, 'day')
          .format(defaultFormat);
        return {
          label: checkPeriod(start, end, type),
          period: {from: start, to: end},
        };
      }
      const lastYear = dayjs().add(-1, 'year');
      const start = lastYear.startOf('year').format(defaultFormat);
      const end = lastYear.endOf('year').format(defaultFormat);
      return {
        label: checkPeriod(start, end, type),
        period: {from: start, to: end},
      };
    }
    case 'days': {
      const start = dayjs().startOf('day').format(defaultFormat);
      const end = dayjs()
        .add((type === 'next' ? 1 : -1) * +value + 1, 'day')
        .startOf('day')
        .format(defaultFormat);
      const isSalary = type !== 'last';
      const first = isSalary ? start : end;
      const second = isSalary ? end : start;
      return {
        label: checkPeriod(first, second, type),
        period: {from: first, to: second},
      };
    }
    default: {
      if (value === 'Не выбрана дата') {
        return {
          label: value,
          period: {from: null, to: null},
        };
      }
      const dates = value.split(' - ');
      if (infinity) {
        switch (infinity) {
          case 'prev':
            if (dates[1].length > 0) {
              return {
                label: 'Все предыдущие даты - ' + dates[1],
                period: {
                  from: null,
                  to: dayjs(dates[1], format).format(defaultFormat),
                },
              };
            }
            return {
              label: 'Не выбран период',
              period: {from: null, to: null},
            };
          case 'next':
            if (dates[0].length > 0) {
              return {
                label: dates[0] + ' - Все будущие даты',
                period: {
                  from: dayjs(dates[0], format).format(defaultFormat),
                  to: null,
                },
              };
            }
            return {
              label: 'Не выбран период',
              period: {from: null, to: null},
            };
          default:
            return {
              label: 'Все даты',
              period: {from: null, to: null},
            };
        }
      }
      if (dates[0].length === 0 || dates[1].length === 0) {
        return {
          label: 'Не выбран период',
          period: {from: null, to: null},
        };
      }
      const isReverse = dayjs(dates[0], format).isAfter(
        dayjs(dates[1], format),
      );
      const start = isReverse ? dates[1] : dates[0];
      const end = isReverse ? dates[0] : dates[1];
      const formattedStart = dayjs(start, format).format(defaultFormat);
      const formattedEnd = dayjs(end, format).format(defaultFormat);
      return {
        label: checkPeriod(formattedStart, formattedEnd, type),
        period: {from: formattedStart, to: formattedEnd},
      };
    }
  }
};

export const SelectPeriod = ({
  onChange,
  type = 'salary',
  error,
}: SelectProps) => {
  const [isOpen, setOpen] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [typeIndex, setTypeIndex] = useState(0);
  const slideIndex = typeIndex === 0 ? 0 : typeIndex === 4 ? 2 : 1;
  const dataPresets = type === 'salary' ? salaryPresets : schedulePresets;
  const position = type === 'salary' ? 'right' : 'left';
  const typeDates = type === 'schedule' ? 'next' : 'last';

  //Presets
  const [activePreset, setActivePreset] = useState(0);
  //Dates
  const [date, setDate] = useState<string>(dayjs().format('YYYY-MM-DD'));
  const [periodWeek, setPeriodWeek] = useState<{start: string; end: string}>();
  const [month, setMonth] = useState<string>(dayjs().format('YYYY-MM-DD'));
  //Period
  const [period, setPeriod] = useState<{start: string; end: string}>({
    start: '',
    end: '',
  });
  const [infinity, setInfinity] = useState<InfinityType>();
  const [viewDate, setViewDate] = useState<string>(
    getPeriod(dataPresets[0], typeDates).label,
  );

  const onSelectArrow = (type: 'decrement' | 'increment') => {
    const sign = type === 'increment' ? 1 : -1;
    switch (typeIndex) {
      case 0:
        if (
          (type === 'increment' && salaryPresets.length - 1 === activePreset) ||
          (type === 'decrement' && activePreset === 0)
        ) {
          return null;
        }
        setActivePreset(activePreset + sign);
        break;
      case 1:
        setDate(dayjs(date).add(sign, 'day').format(defaultFormat));
        break;
      case 2: {
        if (!periodWeek) {
          return null;
        }
        const diffDays =
          dayjs(periodWeek.end).diff(periodWeek.start, 'day') + 1;
        const startWeek = dayjs(periodWeek.start)
          .add(diffDays * sign, 'day')
          .format(defaultFormat);
        const endWeek = dayjs(periodWeek.end)
          .add(diffDays * sign, 'day')
          .format(defaultFormat);
        setPeriodWeek({start: startWeek, end: endWeek});
        break;
      }
      case 3:
        setMonth(dayjs(month).add(sign, 'month').format(defaultFormat));
        break;
      default: {
        if (!period.start || !period.end) {
          return null;
        }
        const diffDays = dayjs(period.end).diff(period.start, 'day') + 1;
        const startPeriod = dayjs(period.start)
          .add(diffDays * sign, 'day')
          .format(defaultFormat);
        const endPeriod = dayjs(period.end)
          .add(diffDays * sign, 'day')
          .format(defaultFormat);
        setPeriod({start: startPeriod, end: endPeriod});
        break;
      }
    }
  };

  useEffect(() => {
    switch (typeIndex) {
      case 0: {
        const info = getPeriod(dataPresets[activePreset], typeDates);
        setViewDate(info.label);
        onChange(info.period);
        break;
      }
      case 1: {
        const info = getPeriod(
          {
            value: date ?? 'Не выбрана дата',
            type: 'date',
          },
          typeDates,
        );
        setViewDate(info.label);
        onChange(info.period);
        break;
      }
      case 2: {
        let temp: {value: string; type: 'period'} = {
          value: 'Не выбрана дата',
          type: 'period',
        };
        if (periodWeek) {
          const startWeek = dayjs(periodWeek.start).format('D MMM YYYY');
          const endWeek = dayjs(periodWeek.end).format('D MMM YYYY');
          temp = {...temp, value: startWeek + ' - ' + endWeek};
        }
        const info = getPeriod(temp, typeDates);
        setViewDate(info.label);
        onChange(info.period);
        break;
      }
      case 3: {
        const info = getPeriod({value: month, type: 'months'}, typeDates);
        setViewDate(info.label);
        onChange(info.period);
        break;
      }
      default: {
        const formattedStart = dayjs(period.start, 'DD.MM.YYYY').isValid()
          ? dayjs(period.start, 'DD.MM.YYYY').format('D MMM YYYY')
          : '';
        const formattedEnd = dayjs(period.end, 'DD.MM.YYYY').isValid()
          ? dayjs(period.end, 'DD.MM.YYYY').format('D MMM YYYY')
          : '';
        const info = getPeriod(
          {
            value: formattedStart + ' - ' + formattedEnd ?? 'Не выбрана дата',
            type: 'period',
            infinity,
          },
          typeDates,
        );
        setViewDate(info.label);
        onChange({
          from: dayjs(period.start).format('YYYY-MM-DD'),
          to: dayjs(period.end).format('YYYY-MM-DD'),
        });
        break;
      }
    }
  }, [
    activePreset,
    dataPresets,
    date,
    infinity,
    month,
    onChange,
    period.end,
    period.start,
    periodWeek,
    type,
    typeDates,
    typeIndex,
  ]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const datePickRef = wrapperRef.current;
      if (datePickRef && !datePickRef.contains(event.target as Node)) {
        setOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <SelectWrapper
      isSalary={type === 'salary'}
      onClick={() => {
        setOpen(true);
      }}>
      {type === 'salary' ? (
        <Button>
          <Arrow
            position="left"
            onClick={e => {
              e.stopPropagation();
              onSelectArrow('decrement');
            }}>
            <ArrowLeft1v />
          </Arrow>
          <Text>{viewDate}</Text>
          <Arrow
            position="right"
            onClick={e => {
              e.stopPropagation();
              onSelectArrow('increment');
            }}>
            <ArrowRight1v />
          </Arrow>
        </Button>
      ) : (
        <>
          <Select>
            <StyledLabel fly={viewDate.length > 0} required={true}>
              Выберите период
            </StyledLabel>
            {viewDate.length > 0 && <StyledValue>{viewDate}</StyledValue>}
          </Select>
          {error && (
            <Text mt={8} color="dangerPrimary" typography="footNote12Regular">
              {error}
            </Text>
          )}
        </>
      )}
      <Wrapper position={position} ref={wrapperRef} visible={isOpen}>
        <Header>
          <SegmentsControl>
            <ActiveTab activeIndex={typeIndex} />
            {types.map((type, index) => (
              <Tabs
                onClick={() => setTypeIndex(index)}
                active={index === typeIndex}
                key={index + '_tab'}>
                {type.label}
              </Tabs>
            ))}
          </SegmentsControl>
        </Header>
        <SlidesContainer index={slideIndex}>
          <Presets
            type={type}
            active={activePreset}
            onSelect={setActivePreset}
          />
          <Dates
            activeTypeIndex={typeIndex}
            date={date}
            setDate={setDate}
            period={periodWeek}
            setPeriod={setPeriodWeek}
            month={month}
            setMonth={setMonth}
          />
          <Dates
            activeTypeIndex={typeIndex}
            variantPeriod="custom"
            period={period}
            setPeriod={setPeriod}
            setInfinity={setInfinity}
          />
        </SlidesContainer>
      </Wrapper>
    </SelectWrapper>
  );
};
