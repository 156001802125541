import React, {useState} from 'react';
import {Layout} from 'shared/ui/Layout';
import styled from 'styled-components';
import {useLocation} from 'react-router-dom';
import {SubscriptionsType} from 'shared/__generated__/graphql';
import {useQuery} from '@apollo/client';
import {TariffPlan} from 'shared/lib/utils';
import {RightSection} from './ui/RightSection';
import PaymentProvider from './ui/Provider';
import {CardSection} from './ui/CardSection';
import {AccountInformationSection} from './ui/AccountInformationSection';
import {PromoCodeSection} from './ui/PromoCode';
import {TypeSection} from './ui/TypeSection';
import {CompanyInformation, PAYMENT_TARIFFS} from 'entities/tariff';
import {PageHeader} from 'shared/ui/PageHeader';

const MainSection = styled.div`
  display: grid;
  flex-direction: column;
  grid-column-start: 4;
  grid-column-end: 10;
  padding-bottom: 24px;
  gap: 24px;
  @media (max-width: 1500px) {
    padding-bottom: 0;
  }
`;

export function PaymentTariff() {
  const {plan, companyId}: {plan: TariffPlan; companyId: string} =
    useLocation().state;
  const [invoice, setInvoice] = useState<CompanyInformation | null>(null);
  const {data, loading} = useQuery(PAYMENT_TARIFFS, {
    variables: {companyId},
    onCompleted: resData => {
      if (resData?.company?.companyAccountInfo) {
        setInvoice(resData?.company?.companyAccountInfo);
      }
    },
  });
  const filtered = data?.priceList.filter(
    item =>
      item.type !== SubscriptionsType.Forever && item.slots === plan.slots,
  );
  const [selectedPlan, setSelectedPlan] = useState<TariffPlan>(plan);

  const findCompanyCard = data
    ? data.me?.card_token?.find(
        item => item.id === data?.company?.user_card_token_id,
      )
    : null;
  const bonusAccount = data?.me?.bonus_account ?? 0;
  const subscriptionActive = data?.company?.is_subscription_active;

  return (
    <Layout>
      <PageHeader variant="layout" back title="Оплата" />
      <PaymentProvider>
        <MainSection>
          <TypeSection
            plan={selectedPlan}
            onSelect={setSelectedPlan}
            data={filtered ?? []}
            initialPlan={plan}
          />
          <CardSection loading={loading} card={findCompanyCard} />
          <AccountInformationSection loading={loading} invoice={invoice} />
          <PromoCodeSection />
        </MainSection>
        <RightSection
          bonusAccount={bonusAccount}
          plan={selectedPlan}
          data={filtered ?? []}
          initialPlan={plan}
          defaultPlan={filtered ? filtered[0] : undefined}
          user_token={findCompanyCard?.id}
          companyPlan={data?.company?.plan}
          subscriptionActive={subscriptionActive ?? false}
          loading={loading}
        />
      </PaymentProvider>
    </Layout>
  );
}
