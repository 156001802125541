import React from 'react';
import {CircleAlert} from 'shared/icons/CircleAlert';
import styled from 'styled-components';
import {Text} from './Text';
import {toast} from 'react-toastify';
import CircleAccept from 'shared/icons/CircleAccept';
import {useColors} from 'shared/lib/hooks';
import {colors} from 'shared/colors';
import {store} from 'shared/store';
import {CircleClose} from 'shared/icons/CircleClose';

const Wrapper = styled.div<{status: 'success' | 'warning' | 'error'}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 307px;
  gap: 12px;
  background-color: ${({status, theme}) =>
    status === 'success'
      ? theme.bgSuccess
      : status === 'warning'
      ? theme.warningBg
      : theme.bgDanger};
`;

const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
`;

export const Alert = ({
  status = 'success',
  text,
}: {
  status: 'success' | 'warning' | 'error';
  text: string;
}) => {
  const colors = useColors();
  return (
    <Wrapper status={status}>
      <IconWrapper>
        {status === 'success' ? (
          <CircleAccept size={24} color={colors.successPrimary} />
        ) : status === 'warning' ? (
          <CircleAlert size={24} />
        ) : (
          <CircleClose size={24} color={colors.dangerPrimary} />
        )}
      </IconWrapper>
      <Text
        typography="text16Regular"
        color={
          status === 'error'
            ? 'dangerPrimary'
            : status === 'success'
            ? 'successPrimary'
            : 'warningPrimary'
        }>
        {text}
      </Text>
    </Wrapper>
  );
};

export const showAlert = (
  status: 'success' | 'warning' | 'error',
  text: string,
) => {
  const theme = store.getState().theme.theme;
  return toast(<Alert status={status} text={text} />, {
    position: 'top-center',
    closeButton: false,
    hideProgressBar: true,
    style: {
      width: '391px',
      marginLeft: -43,
      borderRadius: 16,
      backgroundColor:
        status === 'success'
          ? colors[theme].bgSuccess
          : status === 'warning'
          ? colors[theme].bgWarning
          : colors[theme].bgDanger,
      padding: '15px 16px',
    },
  });
};
