import React from 'react';
import styled from 'styled-components';
import {TextInput} from 'shared/ui/TextInput';

const Container = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
`;
const Cell = styled.div`
  display: flex;
  padding: 16px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 16px;
  border: 1px solid ${({theme}) => theme.borderPrimary};
  background: ${({theme}) => theme.fillPrimary};
`;
const Color = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 8px;
  background-color: ${({color}) => color ?? 'transparent'};
`;

export const ColorCell = ({
  onChange,
  value,
  label,
}: {
  label: string;
  value: string;
  onChange:
    | ((formatted: string, extracted?: string | undefined) => void)
    | undefined;
}) => {
  return (
    <Container>
      <Cell>
        <Color color={value} />
      </Cell>
      <TextInput
        flex={1}
        label={label}
        placeholder="#RRGGBB"
        value={value}
        onChange={onChange}
      />
    </Container>
  );
};
