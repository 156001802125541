import {ApolloError, useLazyQuery, useMutation} from '@apollo/client';
import React, {useEffect, useState} from 'react';
import {gql} from 'shared/__generated__';
import {EmptyTags} from 'shared/illustration/EmptyTags';
import {useAppSelector} from 'shared/store';
import {showAlert} from 'shared/ui/Alert';
import {Button} from 'shared/ui/Button';
import {Flex} from 'shared/ui/Flex';
import {Head} from 'shared/ui/Head';
import {IconButton} from 'shared/ui/IconButton';
import {Modal} from 'shared/ui/Modal';
import {Skeleton} from 'shared/ui/Skeleton';
import {Table} from 'shared/ui/Table';
import {Text} from 'shared/ui/Text';
import {TagCreateModal} from './TagCreateModal';
import Xv2 from 'shared/icons/Xv2';
export const GET_TAGS = gql(`
    query SelectCompanyTags($companyId: String!, $customerId: String) {
  companyTags(company_id: $companyId) {
    id
    name
    order
  }
  customer(id: $customerId) {
    id
    name
    tag {
      id
      name
    }
  }
}
`);

const UPDATE_CUSTOMER_TAG = gql(`
    mutation UpdateCustomerModalTag($input: UpdateCustomer!) {
  updateCustomer(input: $input) {
    id
    tag {
      id
      name
      order
      color {
        id
        rgb
      }
    }
  }
}
`);

export const SelectTags = ({
  visible,
  onClose,
  id,
}: {
  visible: boolean;
  onClose: () => void;
  id?: string;
}) => {
  const companyId = useAppSelector(state => state.company.data?.id);
  const [tagModal, setTagModal] = useState(false);
  const [selections, setSelections] = useState<number[]>([]);
  const [getData, {data, loading}] = useLazyQuery(GET_TAGS, {
    variables: {
      companyId: companyId!,
      customerId: id,
    },
    onCompleted: res => {
      const sortedData = res?.companyTags
        ? [...(res?.companyTags ?? [])].sort(function (a, b) {
            return a.order! - b.order!;
          })
        : [];
      const ids = res.customer?.tag?.map(item => item.id);
      const selects = sortedData
        .map((item, index) => ({id: item.id, index: index}))
        .filter(item => ids?.includes(item.id))
        .map(item => item.index);
      setSelections(selects);
    },
  });
  const [updateCustomerTag, {loading: loadingUpdate}] =
    useMutation(UPDATE_CUSTOMER_TAG);

  const tags = data?.companyTags
    ? [...(data?.companyTags ?? [])].sort(function (a, b) {
        return a.order! - b.order!;
      })
    : [];

  useEffect(() => {
    if (visible) {
      getData();
    }
  }, [getData, visible]);

  return (
    <>
      <TagCreateModal
        customerId={id}
        visible={tagModal}
        onClose={() => setTagModal(false)}
      />
      <Modal
        visible={visible}
        onClose={onClose}
        backdropVariant="overlay"
        style={{
          width: '792px',
          left: 'calc((100% - 792px) / 2)',
          padding: 24,
          top: 'calc((100% - 608px) / 2)',
          height: tags.length === 0 ? 554 : 596,
        }}>
        <Flex direction="column" gap={24}>
          <Head style={{justifyContent: 'space-between', marginBottom: 0}}>
            <Text typography="title20">Теги клиента</Text>
            <IconButton size={40} onClick={() => onClose()}>
              <Xv2 />
            </IconButton>
          </Head>
          <Flex direction="column" style={{height: '400px'}}>
            {loading ? (
              <SkeletonTags />
            ) : tags.length === 0 ? (
              <>
                <Flex direction="column" alignItems="center" gap={16}>
                  <EmptyTags size={200} />
                  <Text typography="title20" align="center">
                    У вас пока нет тегов
                  </Text>
                  <Text typography="text18" color="textTertiary" align="center">
                    Нажмите на кнопку “Создать тег” и начните работу
                  </Text>
                  <Button
                    style={{width: 360, marginTop: 16}}
                    size="large"
                    onClick={() => setTagModal(true)}>
                    Создать тег
                  </Button>
                </Flex>
              </>
            ) : (
              <Table
                scrollable
                titles={['Название']}
                rows={tags.map(item => [item.name]) ?? []}
                selections={selections}
                onSelectionChange={setSelections}
                onClick={index => {
                  if (selections.includes(index)) {
                    setSelections(selections.filter(item => item !== index));
                  } else {
                    setSelections([...selections, index]);
                  }
                }}
                styleTd={{
                  pointerEvents: 'all',
                  cursor: 'pointer',
                }}
              />
            )}
          </Flex>
          {tags.length > 0 && (
            <Flex gap={8} alignItems="center">
              <Button
                style={{width: 180}}
                size="large"
                loading={loadingUpdate}
                disabled={
                  tags
                    .filter((_, index) => selections.includes(index))
                    .map(item => item.id).length === 0
                }
                onClick={async () => {
                  try {
                    await updateCustomerTag({
                      variables: {
                        input: {
                          id: id!,
                          tags_id: tags
                            .filter((_, index) => selections.includes(index))
                            .map(item => item.id),
                        },
                      },
                    });
                    onClose();
                  } catch (e) {
                    if (e instanceof ApolloError) {
                      showAlert('error', e.message);
                    }
                  }
                }}>
                Сохранить
              </Button>
              <Button
                style={{width: 180}}
                variant="light"
                size="large"
                onClick={() => onClose()}>
                Отмена
              </Button>
              {selections.length ? (
                <Text
                  style={{padding: '0 35px'}}
                  typography="text16Semibold"
                  color="mainPrimary">
                  Выбрано {selections.length}
                </Text>
              ) : null}
              <Button
                variant="outline"
                style={{width: 180}}
                size="large"
                loading={loadingUpdate}
                onClick={() => {
                  setTagModal(true);
                }}>
                Создать тег
              </Button>
            </Flex>
          )}
        </Flex>
      </Modal>
    </>
  );
};

const SkeletonTags = () => {
  return (
    <Skeleton>
      <rect width={24} height={24} rx={4} ry={4} x={24} y={23} />
      <rect width={120} height={24} rx={8} ry={8} x={72} y={23} />
      {Array.from(Array(4).keys()).map(item => (
        <React.Fragment key={'sk_' + item}>
          <rect
            width={24}
            height={24}
            rx={4}
            ry={4}
            x={24}
            y={70 + 23.5 + item * 71}
          />
          <rect
            width={120 + Math.random() * 200}
            height={24}
            rx={8}
            ry={8}
            x={72}
            y={70 + 23.5 + item * 71}
          />
        </React.Fragment>
      ))}
    </Skeleton>
  );
};
