import React, {useState} from 'react';
import {styled} from 'styled-components';
import {OptionsType, Table, TableSortType} from 'shared/ui/Table';
import {gql} from 'shared/__generated__';
import {useAppSelector} from 'shared/store';
import {useQuery} from '@apollo/client';
import {
  BookingSort,
  CompanyBookingStatus,
  SortOrder,
  StatisticsDates,
} from 'shared/__generated__/graphql';
import {Flex} from 'shared/ui/Flex';
import {
  FilterEmptyState,
  FilterResetButton,
  FilterSearch,
  FilterStatisticsBookingStatus,
  FilterStatisticsEmployee,
  FilterStatisticsPeriod,
} from 'entities/statistics';
import {
  StatusText,
  getDurationHourMinutes,
  getName,
  getPriceFormat,
} from 'shared/lib/utils';
import dayjs from 'dayjs';
import {BadgeStatus} from 'shared/ui/BadgeStatus';
import {Content} from 'shared/ui/Content';
import {PageHeader} from 'shared/ui/PageHeader';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
`;

const GET_STATISTICS_VISIT_CUSTOMERS = gql(`
  query Visits(
    $companyId: String!
    $date: StatisticsDates!
    $first: Int!
    $status: CompanyBookingStatus
    $page: Int
    $search: String
    $employeeIds: [String!]
    $sortBy: BookingSort
    $sortDesc: Boolean
  ) {
    visits(
      company_id: $companyId
      date: $date
      first: $first
      status: $status
      page: $page
      search: $search
      employee_ids: $employeeIds
      sort_by: $sortBy
      sort_desc: $sortDesc
    ) {
      paginatorInfo {
        hasMorePages
        currentPage
        total
        count
        lastPage
      }
      data {
        customer {
          name
          surname
          id
        }
        id
        status
        bookingServices {
          id
          price
          service {
            name
            id
          }
        }
        workingDate {
          date
        }
        created_at
        end
        start
      }
    }
  }
`);
const titles = [
  'Номер записи',
  'Клиент',
  'Услуги',
  'Дата записи',
  'Длительность записи',
  'Запл. дата записи',
  'Цена',
  'Статус',
];
const sortValues: BookingSort[] = [
  BookingSort.Id,
  BookingSort.Customer,
  BookingSort.Service,
  BookingSort.CreatedAt,
  BookingSort.Start,
  BookingSort.End,
  BookingSort.Date,
  BookingSort.Price,
  BookingSort.Status,
];

export const VisitsCustomers = () => {
  const companyId = useAppSelector(state => state.company.data!.id);
  const [sort, setSort] = useState<TableSortType>({
    index: 0,
    order: SortOrder.Desc,
  });
  const [search, setSearch] = useState('');
  const [employeeId, setEmployeeId] = useState('');
  const [status, setStatus] = useState<CompanyBookingStatus | undefined>();
  const [options, setOptions] = useState<OptionsType>({
    page: 1,
    first: 10,
    total: 0,
    count: 0,
    lastPage: 1,
  });
  const [period, setPeriod] = useState<StatisticsDates>(
    StatisticsDates.Alltime,
  );
  const {data, loading} = useQuery(GET_STATISTICS_VISIT_CUSTOMERS, {
    variables: {
      companyId,
      employeeIds: employeeId ? [employeeId] : undefined,
      status,
      date: period,
      first: options.first,
      page: options.page,
      search,
      sortBy: sortValues[sort.index],
      sortDesc: sort.order === SortOrder.Desc ? true : false,
    },
    onCompleted: res =>
      setOptions(prev => ({
        ...prev,
        total: res.visits?.paginatorInfo.total,
        count: res.visits?.paginatorInfo.count,
        lastPage: res.visits?.paginatorInfo.lastPage,
      })),
  });
  const statistics = data?.visits?.data ?? [];
  return (
    <Wrapper>
      <PageHeader back title="Визиты клиентов" />
      <Flex direction="row" gap={8}>
        <FilterSearch onChange={setSearch} value={search} />
        <FilterStatisticsPeriod {...{period, setPeriod}} />
        <FilterStatisticsEmployee {...{employeeId, setEmployeeId}} />
        <FilterStatisticsBookingStatus {...{status, setStatus}} />
        {employeeId ||
        status ||
        search ||
        period !== StatisticsDates.Alltime ? (
          <FilterResetButton
            onClick={() => {
              setStatus(undefined);
              setEmployeeId('');
              setSearch('');
              setPeriod(StatisticsDates.Alltime);
            }}
          />
        ) : (
          <></>
        )}
      </Flex>
      {!loading && !statistics?.length ? (
        <FilterEmptyState />
      ) : (
        <Content>
          <Table
            widths={[224, 240, 240, 210, 120, 120]}
            options={options}
            setOptions={setOptions}
            titles={titles}
            sort={sort}
            alignRightColumn={[4, 7]}
            columnNoWrap={[1, 4, 6]}
            onClickTitle={(index, order) => setSort({index, order})}
            rows={statistics.map(item => {
              const bookingStatus = item.status as
                | CompanyBookingStatus
                | undefined;
              return [
                item.id,
                getName(item.customer?.name, item.customer?.surname),
                item.bookingServices.map(item => item.service.name).join(', '),
                item.start + dayjs(item.created_at).format(', DD.MM.YYYY'),
                getDurationHourMinutes(
                  +dayjs(item.end, 'HH:mm').diff(
                    dayjs(item.start, 'HH:mm'),
                    'minutes',
                  ),
                ),
                dayjs(item.workingDate.date).format('DD.MM.YYYY'),
                getPriceFormat(
                  item.bookingServices
                    .map(item2 => (item2.price ? parseFloat(item2.price) : 0))
                    .reduce((a, b) => a + b, 0),
                ),
                bookingStatus ? (
                  <BadgeStatus type={bookingStatus}>
                    {StatusText[bookingStatus]}
                  </BadgeStatus>
                ) : (
                  <></>
                ),
              ];
            })}
          />
        </Content>
      )}
    </Wrapper>
  );
};
