import dayjs from 'dayjs';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {TariffPlan, getMonths, getTariff} from 'shared/lib/utils';
import {useAppSelector} from 'shared/store';
import {Button} from 'shared/ui/Button';
import {Content} from 'shared/ui/Content';
import {Flex} from 'shared/ui/Flex';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';

const SubscriptionHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
`;
const BadgeStatus = styled.div<{status?: 'active' | 'canceled' | 'free'}>`
  display: ${({status}) => (status ? 'flex' : 'none')};
  padding: 0 16px;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  border-radius: 12px;
  height: 40px;
  color: ${({status, theme}) =>
    status !== 'canceled' ? theme.successPrimary : theme.dangerPrimary};
  background-color: ${({status, theme}) =>
    status !== 'canceled' ? theme.bgSuccess : theme.bgDanger};
`;

const Table = styled.table`
  tr:last-child td {
    border-bottom: 0;
  }
`;
const Tbody = styled.tbody`
  tr > td:last-of-type {
    border: none;
  }
`;
const Tr = styled.tr`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
`;
const Td = styled.td`
  border-right: 1px solid ${({theme}) => theme.borderPrimary};
  vertical-align: center;
`;

const CenteredText = styled.div<{height: number; padding?: string}>`
  justify-content: center;
  height: ${({height}) => height}px;
  display: flex;
  align-items: center;
  padding: ${({padding}) => padding};
`;
const TableRow = ({
  title,
  description,
}: {
  title: string;
  description?: string;
}) => (
  <Td>
    <Flex direction="column">
      <CenteredText padding="0 16px" height={40}>
        <Text
          style={{width: 'min-content'}}
          align="center"
          color="textTertiary"
          typography="subHead14Regular">
          {title}
        </Text>
      </CenteredText>
      <CenteredText height={56}>
        <Text>{description}</Text>
      </CenteredText>
    </Flex>
  </Td>
);

export const SubscriptionInfo = ({
  title,
  status,
  plan,
  subscriptionEndDate,
}: {
  title: string;
  status?: 'active' | 'canceled' | 'free';
  plan?: TariffPlan | null;
  subscriptionEndDate?: string | null;
}) => {
  const navigate = useNavigate();
  const username = useAppSelector(state => state.company.data?.username);
  const companyId = useAppSelector(state => state.company.data!.id);
  const badgeText = status !== 'canceled' ? 'Активный' : 'Отменен';
  return (
    <Content gap={'16px'}>
      <SubscriptionHead>
        <Text typography="title20">{title}</Text>
        <BadgeStatus status={status}>{badgeText}</BadgeStatus>
      </SubscriptionHead>
      <Flex gap={16} direction="column">
        {status === 'free' ? (
          <>
            <Text color="textTertiary">1 пользователь / Бесплатно</Text>
            <Button
              size="large"
              onClick={() => navigate(`/${username}/tariff-plans`)}>
              Посмотреть тарифные планы
            </Button>
          </>
        ) : null}
        {status === 'active' && plan ? (
          <>
            <Table>
              <Tbody>
                <Tr>
                  <TableRow
                    title={`Количество  сотрудников`}
                    description={plan.slots.toString()}
                  />
                  <TableRow
                    title={`Период  оплаты`}
                    description={getMonths(plan.type) + ' мес'}
                  />
                  <TableRow
                    title={`Следующее  списание`}
                    description={
                      dayjs(subscriptionEndDate).format('D.MM.YYYY') ??
                      undefined
                    }
                  />
                  <TableRow
                    title={`Сумма  списания`}
                    description={plan.price + ' ₽'}
                  />
                </Tr>
              </Tbody>
            </Table>
            <Button
              size="large"
              onClick={() => navigate(`/${username}/tariff-plans`)}>
              Изменить
            </Button>
          </>
        ) : null}
        {status === 'canceled' && plan ? (
          <>
            <Text typography="footNote12Regular" color="textTertiary">
              {getTariff(plan, 'dash')}
            </Text>
            <Text>
              Подписка закончиться{' '}
              {dayjs(subscriptionEndDate).format('DD.MM.YYYY')}
            </Text>
            <Button
              size="large"
              onClick={() =>
                navigate('/payment-tariff', {state: {plan, companyId}})
              }>
              Возобновить подписку
            </Button>
          </>
        ) : null}
      </Flex>
    </Content>
  );
};
