import React from 'react';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';
import {Content} from 'shared/ui/Content';
import {Button} from 'shared/ui/Button';
import {useParams} from 'react-router-dom';
import {gql} from 'shared/__generated__';
import {useAppSelector} from 'shared/store';
import {device} from 'shared/device';
import {useMutation, useQuery} from '@apollo/client';
import {getPhoneMask, getRating} from 'shared/lib/utils';
import {showAlert} from 'shared/ui/Alert';
import {Communicate} from './ui/Communicate';
import {SkeletonAbout} from './ui/Skeleton';
import {TextView} from 'shared/ui/TextView';
import {useEmployeePermission} from 'shared/lib/hooks';
import {Seo} from 'shared/ui/Seo';
import {Flex} from 'shared/ui/Flex';

const EMPLOYEE = gql(`
  query GetEmployeeEmployeeProfileAbout($employeeId: String!, $companyId: String!) {
    employee(id: $employeeId) {
      id
      name
      surname
      role
      avatar {
        id
        url
      }
      phone
      enabled
      invite_status
      professions {
        id
        name
      }
      contacts {
        id
        value
        type
      }
      rating_1_count
      rating_2_count
      rating_3_count
      rating_4_count
      rating_5_count
      user {
        id
        email
      }
    }
    ...Permissions
  }
`);

const CREATE_INVITATION = gql(`
  mutation CreateInvitation($employeeId: String!, $companyId: String!) {
    createInvitation(input: {
      employee_id: $employeeId, 
      company_id: $companyId
    }) {
      id
    }
  }
`);

const REVOKE_INVITATION = gql(`
  mutation RevokeInvitation($employeeId: String!) {
    revokeInvitation(employee_id: $employeeId) {
      id
      invite_status
    }
  }
`);

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  max-width: 720px;
  flex-direction: column;
  gap: 24px;
  padding-top: 8px;
  padding-bottom: 8px;
  @media (${device.mobile}) {
    gap: 8px;
    margin-bottom: 0;
    border-radius: 0;
  }
`;

const AddButton = styled(Button)`
  margin: 8px 0 0 0;
  width: auto;
  @media (${device.mobile}) {
    margin: 16px 16px 0 16px;
  }
`;

export const EmployeeAbout = () => {
  const params = useParams();
  const employeeId = params.id!;
  const companyId = useAppSelector(state => state.company.data!.id);

  const [createInvitation, {loading: createLoading}] = useMutation(
    CREATE_INVITATION,
    {
      variables: {
        companyId,
        employeeId,
      },
      refetchQueries: ['GetEmployeeEmployeeProfileAbout'],
    },
  );

  const [revokeInvitation, {loading: revokeLoading}] = useMutation(
    REVOKE_INVITATION,
    {
      variables: {
        employeeId,
      },
      refetchQueries: ['GetEmployeeEmployeeProfileAbout'],
    },
  );

  const {data, loading} = useQuery(EMPLOYEE, {
    variables: {
      employeeId,
      companyId,
    },
  });
  const {create_invitation_permission} = useEmployeePermission(data);
  const employee = data?.employee;

  return (
    <Wrapper>
      <Seo title="Профиль сотрудника" />
      {loading ? (
        <SkeletonAbout />
      ) : (
        <>
          <Content gap="24px">
            <Text typography="title18">Основная информация</Text>
            <Flex direction="column" gap={16}>
              <TextView
                title="Номер телефона"
                text={getPhoneMask(employee?.phone)}
              />
              <TextView
                title="Рейтинг"
                text={employee && getRating(employee).toString()}
              />
              <TextView
                text={
                  employee?.professions.length
                    ? employee?.professions.map(item => item.name).join(', ')
                    : 'Специализация не указана'
                }
                title="Специализация"
              />
            </Flex>
            <Communicate phone={employee?.phone} />
          </Content>
          {create_invitation_permission && employee?.role !== 'owner' && (
            <AddButton
              typography="text16Semibold"
              variant={
                employee?.invite_status === 'accepted'
                  ? 'outline'
                  : employee?.invite_status === 'pending'
                  ? 'light'
                  : 'filled'
              }
              loading={loading || createLoading || revokeLoading}
              disabled={loading || createLoading || revokeLoading}
              size="large"
              onClick={async () => {
                try {
                  if (employee?.invite_status === 'accepted') {
                    await revokeInvitation();
                    showAlert('success', 'Приглашение отозвано');
                  } else {
                    await createInvitation();
                    showAlert('success', 'Приглашение успешно отправлено!');
                  }
                } catch (e) {
                  if (e instanceof Error) {
                    showAlert('error', e.message);
                  }
                }
              }}>
              {employee?.invite_status === 'accepted'
                ? 'Отозвать приглашение'
                : employee?.invite_status === 'pending'
                ? 'Отправить повторно'
                : 'Пригласить сотрудника'}
            </AddButton>
          )}
        </>
      )}
    </Wrapper>
  );
};
