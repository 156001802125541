import React, {useState} from 'react';
import {Grow} from 'shared/ui/Grow';
import {CustomersHeader} from './ui/Header';
import {ApolloError, useMutation, useQuery} from '@apollo/client';
import {CustomersTable} from './ui/CustomersTable';
import {EmptyState} from './ui/EmptyState';
import {CustomersSkeleton} from './ui/Skeleton';
import {FilterType, Setting} from './ui/Setting';
import {useAppSelector} from 'shared/store';
import {OptionsType} from 'shared/ui/Table';
import {styled} from 'styled-components';
import {showAlert} from 'shared/ui/Alert';
import {FooterAction} from 'shared/ui/FooterAction';
import {DELETE_CUSTOMER, GET_CUSTOMERS} from 'entities/customers';
import {useEmployeePermission} from 'shared/lib/hooks';
import {Seo} from 'shared/ui/Seo';
import {getName} from 'shared/lib/utils';
import {
  Customer,
  CustomerCategory,
  CustomerOrder,
  OrderByOrder,
} from 'shared/__generated__/graphql';
import dayjs from 'dayjs';
import {Flex} from 'shared/ui/Flex';
import {SegmentCard} from './ui/SegmentCard';
import {FilterChips} from './ui/FilterChips';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const today = dayjs().format('DD.MM');

export const statusCustomerText = (
  customer: Pick<Customer, 'is_vip' | 'is_blocked' | 'birthdate'>,
) => {
  const birthdate = customer.birthdate
    ? dayjs(customer.birthdate).format('DD.MM')
    : undefined;
  switch (true) {
    case customer.is_blocked:
      return 'ЧС';
    case today === birthdate:
      return 'День рождения';
    case customer.is_vip:
      return 'VIP';
    default:
      return 'Обычный';
  }
};

const exportData = (
  data: Pick<
    Customer,
    | 'name'
    | 'surname'
    | 'phone'
    | 'total_visits'
    | 'total_cancelled'
    | 'total_earned'
    | 'is_vip'
    | 'is_blocked'
    | 'birthdate'
  >[],
) =>
  data.map(
    ({
      name,
      surname,
      phone,
      total_visits,
      total_cancelled,
      total_earned,
      is_vip,
      is_blocked,
      birthdate,
    }) => ({
      Имя: getName(name, surname, 'customer'),
      Телефон: phone,
      Посещений: total_visits,
      Отменённые: total_cancelled,
      Выручка: total_earned,
      Статус: statusCustomerText({is_vip, is_blocked, birthdate}),
    }),
  );

const CUSTOMER_CATEGORY = [
  CustomerCategory.Regular,
  CustomerCategory.Inactive,
  CustomerCategory.Lost,
  CustomerCategory.Prospects,

  CustomerCategory.Blocked,
];
const CUSTOMER_ORDER = [
  CustomerOrder.Customer,
  CustomerOrder.Phone,
  CustomerOrder.Visits,
  CustomerOrder.Cancelled,
  CustomerOrder.Earned,
  CustomerOrder.Tags,
];

export function Customers() {
  const companyId = useAppSelector(state => state.company.data?.id ?? '');
  const [sort, setSort] = useState<{index: number; order: OrderByOrder}>();
  const [options, setOptions] = useState<OptionsType>({
    page: 1,
    first: 10,
    total: 0,
    count: 0,
    lastPage: 1,
  });
  const [filter, setFilter] = useState<FilterType>({
    birthdate: undefined,
    gender: undefined,
    customerCategory: undefined,
    tags: undefined,
  });
  const [segmentValue, setSegmentValue] = useState<
    Record<CustomerCategory, number>
  >({
    regular: 0,
    prospects: 0,
    lost: 0,
    inactive: 0,
    blocked: 0,
  });
  const [search, setSearch] = useState('');
  const [selects, setSelects] = useState<{id: string; index: number}[]>([]);
  const [deleteCustomer, {loading: deleteLoading}] = useMutation(
    DELETE_CUSTOMER,
    {
      refetchQueries: ['GetCustomers'],
    },
  );
  const {data, loading, refetch} = useQuery(GET_CUSTOMERS, {
    variables: {
      companyId,
      first: options.first,
      page: options.page,
      query: search,
      birthdate: filter.birthdate,
      gender: filter.gender,
      customerCategory: filter.customerCategory,
      customerTags: filter.tags?.map(item => item.id),
      order: sort?.order,
      orderby: sort ? CUSTOMER_ORDER[sort.index] : undefined,
    },
    onCompleted({customers, getCustomerSegments}) {
      setSegmentValue(getCustomerSegments);
      setOptions(prev => ({
        ...prev,
        total: customers?.paginatorInfo.total,
        count: customers?.paginatorInfo.count,
        lastPage: customers?.paginatorInfo.lastPage,
      }));
    },
  });
  const {
    edit_customers_permission,
    customers_info_permission,
    create_customers_permission,
    contact_permission,
  } = useEmployeePermission(data);
  const customers = data?.customers?.data ?? [];
  const tags = data?.companyTags ?? [];

  const handleSelectionChange = (indexes: number[]) => {
    const newSelectedData = indexes.map(
      index => ({id: customers?.[index].id, index}) ?? [],
    );
    setSelects(newSelectedData);
  };
  const onDeleteCustomers = () => {
    selects.forEach(item => {
      deleteCustomer({
        variables: {
          deleteCustomerId: item.id,
        },
      }).catch((e: ApolloError) => showAlert('error', e.message));
    });
    setSelects([]);
  };

  const selectedCustomers = customers.filter(customer =>
    selects.map(item => item.id).includes(customer.id),
  );

  return (
    <Wrapper>
      <Seo title="Клиенты" />
      <Grow style={{padding: 24, gap: 24}}>
        <CustomersHeader number={options.total} />
        <Flex gap={16} style={{flexWrap: 'wrap'}}>
          {CUSTOMER_CATEGORY.map((item, index) => (
            <SegmentCard
              key={`segment_${index}`}
              activeSegmentCategoty={filter.customerCategory}
              value={segmentValue?.[item]}
              type={item}
              onClick={() => {
                setFilter(prev => ({
                  ...prev,
                  customerCategory:
                    item !== filter.customerCategory ? item : undefined,
                }));
                setSelects([]);
              }}
            />
          ))}
        </Flex>
        <Setting
          refetch={refetch}
          onFilterChange={() => setSelects([])}
          tags={tags}
          exportData={exportData(customers)}
          editPermission={create_customers_permission}
          search={search}
          onChangeText={setSearch}
          filter={filter}
          setFilter={setFilter}
        />
        <FilterChips {...{filter, setFilter}} />
        {loading ? (
          <CustomersSkeleton />
        ) : customers?.length ? (
          <CustomersTable
            sort={sort}
            setSort={setSort}
            contactPermission={contact_permission}
            permission={customers_info_permission}
            customers={customers}
            options={options}
            setOptions={setOptions}
            onSelectionChange={handleSelectionChange}
            selections={selects.map(item => item.index)}
          />
        ) : (
          <EmptyState
            filtered={
              !!search ||
              filter.birthdate ||
              !!filter.gender ||
              !!filter.customerCategory ||
              !!filter.tags
            }
          />
        )}
      </Grow>
      <FooterAction
        exportData={exportData(selectedCustomers)}
        filename="Список выбранных клиентов"
        exportable
        editPermission={edit_customers_permission}
        allICount={options.count}
        selectedCount={selects.length}
        disabled={selects.length === 0}
        onDelete={onDeleteCustomers}
        deleteLoading={deleteLoading}
        title="Удалить клиентов"
        text="Вы уверены, что хотите удалить выбранных клиентов?"
      />
    </Wrapper>
  );
}
