import React from 'react';
import {Outlet} from 'react-router';
import {Sidebar} from './Sidebar';
import {Flex} from 'shared/ui/Flex';
import {Tabbar} from './Tabbar';
import styled from 'styled-components';
import {mobileMediaQuery} from 'shared/lib/utils';

const Wrapper = styled.main`
  background-color: ${props => props.theme.bgSecondary};
  width: 100%;
  padding: 0;
  ${mobileMediaQuery} {
    margin-bottom: 64px;
    min-height: calc(100dvh - 64px);
  }
`;

export function Root() {
  return (
    <Flex>
      <Sidebar />
      <Wrapper>
        <Outlet />
      </Wrapper>
      <Tabbar />
    </Flex>
  );
}
