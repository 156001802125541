import React from 'react';
import {EmptyMedia} from 'shared/illustration/EmptyMedia';
import {Flex} from 'shared/ui/Flex';
import {Text} from 'shared/ui/Text';

export const EmptyState = () => {
  return (
    <Flex
      flex={1}
      alignItems="center"
      justifyContent="center"
      gap={16}
      direction="column">
      <EmptyMedia size={300} />
      <Text typography="title20" align="center">
        Нет данных для показа
      </Text>
      <Text color="textTertiary" align="center">
        Для изменения нажмите на кнопку “Добавить источник”
      </Text>
    </Flex>
  );
};
