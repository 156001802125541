import React from 'react';

export const Crown = ({
  color = '#6765F8',
  size = 24,
}: {
  color?: string;
  size?: number;
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 24 24`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.6481 13.4054L20.8544 11.2137C21.0164 9.49233 21.0974 8.63165 20.8029 8.27587C20.6436 8.08341 20.427 7.96548 20.1954 7.94514C19.7673 7.90754 19.2296 8.51961 18.1543 9.74376C17.5982 10.3768 17.3202 10.6934 17.01 10.7424C16.8381 10.7696 16.663 10.7416 16.5043 10.6618C16.2178 10.5176 16.0269 10.1263 15.6449 9.34366L13.6318 5.21837C12.91 3.73946 12.5492 3 12 3C11.4508 3 11.09 3.73946 10.3682 5.21837L8.35506 9.34366C7.97313 10.1263 7.78216 10.5176 7.49573 10.6618C7.33703 10.7416 7.16191 10.7696 6.99004 10.7424C6.67984 10.6934 6.40179 10.3768 5.84568 9.74376C4.77037 8.51961 4.23272 7.90754 3.80459 7.94514C3.57299 7.96548 3.35638 8.08341 3.19709 8.27587C2.90262 8.63165 2.98362 9.49233 3.14563 11.2137L3.3519 13.4054C3.69179 17.0167 3.86173 18.8224 4.92608 19.9112C5.99044 21 7.58565 21 10.7761 21H13.2239C16.4144 21 18.0096 21 19.0739 19.9112C20.1383 18.8224 20.3082 17.0167 20.6481 13.4054Z"
        stroke={color}
        strokeWidth="1.5"
      />
    </svg>
  );
};
