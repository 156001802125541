import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import React from 'react';
import {Employee, Image} from 'shared/__generated__/graphql';
import {EmployeeRole, getName} from 'shared/lib/utils';
import styled from 'styled-components';
import {GripVertical} from 'shared/icons/GripVertical';
import {TableViewRow} from 'shared/ui/TableViewRow';
import {Avatar} from 'shared/ui/Avatar';
import {useNavigate} from 'react-router-dom';
import {useAppSelector} from 'shared/store';

const Item = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  height: 80px;
  align-items: center;
  border-radius: 14px;
  &:hover {
    background-color: ${props => props.theme.bgSecondary};
    z-index: 100;
  }
`;
const CellGrip = styled.div`
  display: flex;
  width: 63px;
  height: 80px;
  align-items: center;
  justify-content: center;
`;
const AvatarCell = styled.div`
  display: flex;
  padding: 8px;
  margin-right: 16px;
`;
const GripBox = styled.div`
  display: flex;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  justify-content: center;
  align-items: center;
  pointer-events: all;
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
`;

type EmployeeType = Pick<
  Employee,
  'id' | 'name' | 'surname' | 'role' | 'invite_status' | 'position' | 'enabled'
> & {avatar?: Pick<Image, 'id' | 'url' | 'url_256'> | null};

export const SortableItem = ({employee}: {employee: EmployeeType}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    index,
    activeIndex,
  } = useSortable({id: employee.id});
  const activeTag = index === activeIndex;
  const navigate = useNavigate();
  const username = useAppSelector(state => state.company.data?.username);

  const style = {
    transform: CSS.Transform.toString(
      transform
        ? {
            x: transform.x,
            y: transform.y,
            scaleX: activeTag ? transform.scaleX + 0.03 : transform.scaleX,
            scaleY: activeTag ? transform.scaleY + 0.03 : transform.scaleY,
          }
        : null,
    ),
    transition,
  };

  return (
    <Item
      ref={setNodeRef}
      style={style}
      onClick={() => navigate(`/${username}/employee/${employee.id}`)}>
      <CellGrip>
        <GripBox {...attributes} {...listeners}>
          <GripVertical />
        </GripBox>
      </CellGrip>
      <AvatarCell>
        <Avatar
          url={employee.avatar?.url_256 ?? employee.avatar?.url}
          active={employee?.enabled}
          activeSize={16}
        />
      </AvatarCell>
      <TableViewRow
        title={getName(employee.name, employee.surname, 'employee')}
        subtitle={EmployeeRole[employee.role ?? 'participant']}
      />
    </Item>
  );
};
