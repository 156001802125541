import React, {useState} from 'react';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';
import {Content} from 'shared/ui/Content';
import {useParams} from 'react-router-dom';
import {useAppSelector} from 'shared/store';
import {device} from 'shared/device';
import {useMutation, useQuery} from '@apollo/client';
import {SkeletonPermissions} from './ui/Skeleton';
import {Seo} from 'shared/ui/Seo';
import {Flex} from 'shared/ui/Flex';
import {Select} from 'shared/ui/Select';
import {
  EMPLOYEE_PERMISSIONS_DEFAULT,
  EMPLOYEE_PERMISSIONS_TEXT,
  PERMISSION_KEYS,
} from './lib';
import {Button} from 'shared/ui/Button';
import {PermissionValues} from 'shared/__generated__/graphql';
import {
  CREATE_EMPLOYEE_PERMISSIONS,
  EMPLOYEE_PERMISSIONS,
} from 'entities/employees';
import {showAlert} from 'shared/ui/Alert';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  max-width: 720px;
  flex-direction: column;
  gap: 24px;
  padding-top: 8px;
  padding-bottom: 24px;
  @media (${device.mobile}) {
    gap: 8px;
    margin-bottom: 0;
    border-radius: 0;
  }
`;

const selectData: (value: boolean) => Array<{
  label: string;
  value: PermissionValues;
  hint?: string;
}> = (value: boolean) => [
  {
    label: 'По умолчанию',
    value: PermissionValues.Default,
    hint: `(${value ? 'вкл' : 'выкл'})`,
  },
  {
    label: 'Включен',
    value: PermissionValues.Granted,
  },
  {
    label: 'Выключен',
    value: PermissionValues.Forbidden,
  },
];

export const EmployeePermissions = () => {
  const params = useParams();
  const employeeId = params.id!;
  const companyId = useAppSelector(state => state.company.data!.id);
  const [employeePermissions, setEmployeePermissions] = useState(
    EMPLOYEE_PERMISSIONS_DEFAULT,
  );

  const {data, loading, refetch} = useQuery(EMPLOYEE_PERMISSIONS, {
    variables: {
      employeeId,
      companyId,
    },
    onCompleted({getEmployeePermission}) {
      if (getEmployeePermission) {
        PERMISSION_KEYS.map(item => {
          setEmployeePermissions(prev => ({
            ...prev,
            [item]: getEmployeePermission[item].is_default
              ? PermissionValues.Default
              : getEmployeePermission[item].permission
              ? PermissionValues.Granted
              : PermissionValues.Forbidden,
          }));
        });
      }
    },
  });
  const [create, {loading: createLoading}] = useMutation(
    CREATE_EMPLOYEE_PERMISSIONS,
  );
  const permissions = data?.company?.roles?.find(
    item => item.role === data.getEmployeePermission.role,
  );

  const onSave = async () => {
    try {
      if (data?.getEmployeePermission.role) {
        await create({
          variables: {
            input: {
              employee_id: employeeId,
              role: data?.getEmployeePermission.role,
              permissions: employeePermissions,
            },
          },
        });
        showAlert('success', 'Права доступа сотрудника сохранены.');
        refetch();
      }
    } catch (e) {
      if (e instanceof Error) {
        showAlert('error', e.message);
      }
    }
  };

  return (
    <Wrapper>
      <Seo title="Права доступа сотрудника" />
      {loading ? (
        <SkeletonPermissions />
      ) : (
        <>
          <Content gap="24px">
            {PERMISSION_KEYS.map((item, index) => (
              <Flex
                key={`select_${index}`}
                alignItems="center"
                justifyContent="space-between"
                gap={12}
                style={{height: 80, paddingLeft: 16}}>
                <Text>{EMPLOYEE_PERMISSIONS_TEXT[item]}</Text>
                <Select
                  data={selectData(permissions ? permissions[item] : false)}
                  variant="default"
                  value={employeePermissions[item]}
                  onChange={value => {
                    setEmployeePermissions(prev => ({
                      ...prev,
                      [item]: value,
                    }));
                  }}
                  wrapperStyle={{width: 256, minWidth: 256}}
                />
              </Flex>
            ))}
          </Content>
          <Button
            loading={createLoading}
            disabled={createLoading || loading}
            size="large"
            onClick={onSave}>
            Сохранить
          </Button>
        </>
      )}
    </Wrapper>
  );
};
