import React from 'react';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';
import {device} from 'shared/device';
import {Content} from 'shared/ui/Content';
import {Button} from 'shared/ui/Button';
import {TableViewRow} from 'shared/ui/TableViewRow';
import {RadioButton} from 'shared/ui/RadioButton';
import {CheckBox} from 'shared/ui/CheckBox';
import {Switch} from 'shared/ui/Switch';
import {gql} from 'shared/__generated__';
import {useMutation, useQuery} from '@apollo/client';
import {useAppSelector} from 'shared/store';
import {List} from 'shared/ui/List';
import {showAlert} from 'shared/ui/Alert';
import {Seo} from 'shared/ui/Seo';

const COMPANY_ADVANTAGE = gql(`
  query Advantages($companyId: String!) {
    company(id: $companyId) {
      id
      is_booking_auto_complete_enabled
      is_completed_booking_editable
      advantages {
        id
      }
      price_rating
      paymentMethods {
        id
      }
    }
    paymentMethods {
      id
      title
    }
    advantages {
      id
      title
    }
  }
`);

const COMPANY_ADVANTAGE_UPDATE = gql(`
  mutation MutationUpdateCompany($input: UpdateCompany!) {
    updateCompany(input: $input) {
      id
      is_completed_booking_editable
      is_booking_auto_complete_enabled
      advantages {
        id
      }
      paymentMethods {
        id
      }
      price_rating
    }
  }
`);

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  max-height: 350px;
  overflow-y: auto;
`;
const Wrapper = styled.div`
  display: flex;
  flex: 1;
  padding-bottom: 24px;
  flex-direction: column;
  margin-top: 8px;
  gap: 24px;
  @media (${device.mobile}) {
    gap: 8px;
    margin-bottom: 0;
    border-radius: 0;
  }
`;
const priceRatingList = [
  {value: 1, title: 'Низкие'},
  {value: 2, title: 'Средние'},
  {value: 3, title: 'Высокие'},
];

export const CompanyAdvantage = () => {
  const companyId = useAppSelector(state => state.company.data!.id);
  const [ratingIndex, setRatingIndex] = React.useState(0);
  const [methodIndexes, setMethodIndexes] = React.useState<number[]>([]);
  const [advantageIndexes, setAdvantageIndexes] = React.useState<number[]>([]);
  const [isBookingAutoCompleteEnabled, setIsBookingAutoCompleteEnabled] =
    React.useState<boolean>(false);
  const [isCompletedBookingEditable, setIsCompletedBookingEditable] =
    React.useState<boolean>(false);
  const {data} = useQuery(COMPANY_ADVANTAGE, {
    variables: {companyId},
    onCompleted: _data => {
      setRatingIndex(
        priceRatingList.findIndex(
          item => item.value === _data.company?.price_rating,
        ) ?? 0,
      );

      const selfAdv = _data.company?.advantages.map(item => item.id);
      const tempAdv: number[] = [];
      for (let i = 0; i < _data.advantages.length; i++) {
        const item = _data.advantages[i];
        if (selfAdv?.includes(item.id)) {
          tempAdv.push(i);
        }
      }
      setAdvantageIndexes(tempAdv);
      const selfMeth = _data.company?.paymentMethods.map(item => item.id);
      const tempMeth: number[] = [];
      for (let i = 0; i < _data.paymentMethods.length; i++) {
        const item = _data.paymentMethods[i];
        if (selfMeth?.includes(item.id)) {
          tempMeth.push(i);
        }
      }
      setMethodIndexes(tempMeth);
      setIsBookingAutoCompleteEnabled(
        _data?.company?.is_booking_auto_complete_enabled ?? false,
      );
      setIsCompletedBookingEditable(
        _data?.company?.is_completed_booking_editable ?? false,
      );
    },
  });

  const paymentMethodsList = data?.paymentMethods ?? [];
  const advantagesList = data?.advantages ?? [];

  const [updateCompanyAdvantage, {loading: loadingUpdate}] = useMutation(
    COMPANY_ADVANTAGE_UPDATE,
    {refetchQueries: [COMPANY_ADVANTAGE]},
  );
  const submit = () => {
    updateCompanyAdvantage({
      variables: {
        input: {
          is_completed_booking_editable: isCompletedBookingEditable,
          is_booking_auto_complete_enabled: isBookingAutoCompleteEnabled,
          price_rating: priceRatingList[ratingIndex].value ?? 2,
          paymentMethods: methodIndexes.map(
            index => paymentMethodsList[index].id,
          ),
          advantages: advantageIndexes.map(index => advantagesList[index].id),
          id: companyId,
        },
      },
    })
      .then(() => {
        showAlert('success', 'Данные успешно обновлены');
      })
      .catch(() => {
        showAlert('error', 'Произошла ошибка при обновлении данных');
      });
  };

  return (
    <Wrapper>
      <Seo title="Дополнительно" />
      <Content gap="24px">
        <Text typography="title18">Цены на услуги</Text>
        <List
          keyExtractor={item => item.value.toString()}
          data={priceRatingList}
          renderItem={(item, index) => (
            <TableViewRow
              pointer
              onClick={() => {
                setRatingIndex(index);
              }}
              leftElement={() => (
                <RadioButton selected={ratingIndex === index} />
              )}
              title={item.title}
            />
          )}
        />
      </Content>
      <Content gap="24px">
        <Text typography="title18">Способы оплаты</Text>
        <GridContainer>
          {paymentMethodsList.map((item, index) => (
            <TableViewRow
              key={item.id}
              pointer
              onClick={() => {
                if (methodIndexes.includes(index)) {
                  setMethodIndexes(
                    methodIndexes.filter(item => item !== index),
                  );
                } else {
                  setMethodIndexes([...methodIndexes, index]);
                }
              }}
              leftElement={() => (
                <CheckBox
                  checked={methodIndexes.includes(index)}
                  onChange={() => {
                    if (methodIndexes.includes(index)) {
                      setMethodIndexes(
                        methodIndexes.filter(item => item !== index),
                      );
                    } else {
                      setMethodIndexes([...methodIndexes, index]);
                    }
                  }}
                />
              )}
              title={item.title}
            />
          ))}
        </GridContainer>
      </Content>
      <Content gap="24px">
        <Text typography="title18">Удобства</Text>
        <GridContainer>
          {advantagesList.map((item, index) => (
            <TableViewRow
              key={item.id}
              pointer
              onClick={() => {
                if (advantageIndexes.includes(index)) {
                  setAdvantageIndexes(
                    advantageIndexes.filter(item => item !== index),
                  );
                } else {
                  setAdvantageIndexes([...advantageIndexes, index]);
                }
              }}
              leftElement={() => (
                <CheckBox
                  checked={advantageIndexes.includes(index)}
                  onChange={() => {
                    if (advantageIndexes.includes(index)) {
                      setAdvantageIndexes(
                        advantageIndexes.filter(item => item !== index),
                      );
                    } else {
                      setAdvantageIndexes([...advantageIndexes, index]);
                    }
                  }}
                />
              )}
              title={item.title}
            />
          ))}
        </GridContainer>
      </Content>
      <Content>
        <Text typography="title18">Закрытие записей</Text>
        <TableViewRow
          style={{padding: '11px 0'}}
          title="Разрешить автоматически менять статус и закрывать записи спустя 2 дня"
          rightElement={() => (
            <Switch
              value={isBookingAutoCompleteEnabled}
              onChange={selected => setIsBookingAutoCompleteEnabled(selected)}
            />
          )}
        />
      </Content>
      <Content>
        <Text typography="title18">Редактирование закрытых записей</Text>
        <TableViewRow
          style={{padding: '11px 0'}}
          title="Разрешить редактировать уже закрытые записи"
          rightElement={() => (
            <Switch
              value={isCompletedBookingEditable}
              onChange={() =>
                setIsCompletedBookingEditable(!isCompletedBookingEditable)
              }
            />
          )}
        />
      </Content>
      <Button size="large" onClick={submit} loading={loadingUpdate}>
        Сохранить
      </Button>
    </Wrapper>
  );
};
