import React, {useState} from 'react';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';
import {Content} from 'shared/ui/Content';
import {TextInput} from 'shared/ui/TextInput';
import {Head} from 'shared/ui/Head';
import {Button} from 'shared/ui/Button';
import {useMutation, useQuery} from '@apollo/client';
import {useLocation, useNavigate} from 'react-router-dom';
import {gql} from 'shared/__generated__';
import {
  setCreateBookingCustomer,
  setEditBookingCustomer,
  useAppDispatch,
  useAppSelector,
} from 'shared/store';
import {
  CreateCustomer,
  CustomerGender,
  ImageType,
} from 'shared/__generated__/graphql';
import {Layout} from 'shared/ui/Layout';
import {Flex} from 'shared/ui/Flex';
import {RadioButton} from 'shared/ui/RadioButton';
import {Gender, PHONE_MASK, isEmailValid} from 'shared/lib/utils';
import {TextArea} from 'shared/ui/TextArea';
import {AvatarCrop, AvatarLoadType} from 'shared/ui/AvatarCrop';
import {showAlert} from 'shared/ui/Alert';
import {NavigateBack} from 'shared/ui/NavigateBack';
import {Seo} from 'shared/ui/Seo';
import {DateInput} from 'shared/ui/DateInput';
import dayjs from 'dayjs';
import {GET_CUSTOMERS} from 'entities/customers';
import {MultiSelectChips} from 'shared/ui/MultiSelectChips';
import {TagCreateModal} from 'entities/tags';
import {TableViewRow} from 'shared/ui/TableViewRow';
import {useColors} from 'shared/lib/hooks';
import {EmptyTags} from 'shared/illustration/EmptyTags';
import {CUSTOMERS} from 'entities/booking';

const COMANY_TAGS = gql(`
  query CompanyTagAddCustomer($companyId: String!) {
    companyTags(company_id: $companyId) {
      id
      name
      order
      color {
        id
        rgb
      }
    }
  }
`);

const CREATE_CUSTOMER = gql(`
  mutation CreateCustomer($input: CreateCustomer!) {
    createCustomer(input: $input) {
      avatar {
        id
        url
        url_256
      }
      id
      name
      surname
      phone
      is_blocked
      is_vip
      gender
      email
      birthdate
      note
    }
  }
`);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-column-start: 4;
  grid-column-end: 10;
  padding: 64px 0 24px;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const AddCustomer = () => {
  const navigate = useNavigate();
  const colors = useColors();
  const companyId = useAppSelector(state => state.company.data!.id);
  const [avatar, setAvatar] = useState<AvatarLoadType>();
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [birthdate, setBirthdate] = useState('');
  const [gender, setGender] = useState<CustomerGender>();
  const [selectTags, setSelectTags] = useState<
    {value: string; label: string}[]
  >([]);
  const [note, setNote] = useState('');
  const [phoneError, setPhoneError] = React.useState<string | null>(null);
  const [emailError, setEmailError] = React.useState<string | null>(null);
  const [birthdateError, setBirthdateError] = useState<string | null>(null);
  const [tagModal, setTagModal] = useState(false);
  const {data} = useQuery(COMANY_TAGS, {
    variables: {
      companyId,
    },
  });
  const [update, {loading}] = useMutation(CREATE_CUSTOMER, {
    refetchQueries: [
      {
        query: GET_CUSTOMERS,
        variables: {
          companyId,
          first: 10,
          page: 1,
          query: '',
        },
      },
      {
        query: CUSTOMERS,
        variables: {companyId, first: 20, page: 1, isBlocked: false, query: ''},
      },
    ],
    awaitRefetchQueries: true,
  });
  const username = useAppSelector(state => state.company.data?.username);
  const {state} = useLocation();
  const from = state?.from;
  const dispatch = useAppDispatch();
  const screen = useAppSelector(state => state.booking.screen);
  const tags = data?.companyTags?.map(({id, name}) => ({
    value: id,
    label: name,
  }));

  return (
    <Layout columns={12}>
      <Seo title="Добавить клиента" />
      <TagCreateModal visible={tagModal} onClose={() => setTagModal(false)} />
      <Wrapper>
        <Head>
          <NavigateBack />
          <Text typography="title24">Добавить клиента</Text>
        </Head>
        <Content style={{marginTop: 8}}>
          <Text typography="title18">Основная информация</Text>
          <AvatarCrop
            onChange={value => {
              setAvatar(value ?? undefined);
            }}
            onDelete={() => {
              setAvatar(undefined);
            }}
            url={avatar?.url ?? ''}
          />
          <TextInput
            label="Имя"
            value={name}
            onChange={text => {
              setName(text);
            }}
          />
          <TextInput
            label="Фамилия"
            value={surname}
            onChange={text => {
              setSurname(text);
            }}
          />
          <TextInput
            label="Номер телефона"
            value={phone}
            mask={PHONE_MASK}
            error={phoneError}
            onChange={text => {
              if (phoneError) {
                setPhoneError(null);
              }
              setPhone(text);
            }}
            required
          />
        </Content>
        <Content style={{marginTop: 24}}>
          <Text typography="title18">Дополнительно</Text>
          <DateInput
            value={birthdate}
            error={birthdateError}
            label="Дата рождения"
            onChange={value => {
              birthdateError && setBirthdateError(null);
              setBirthdate(value);
            }}
            isBirthdate
          />
          <TextInput
            label="E-mail"
            value={email}
            error={emailError}
            onChange={text => {
              if (emailError) {
                setEmailError(null);
              }
              setEmail(text);
            }}
          />
          <Flex direction="column" gap={8}>
            <Text typography="subHead14Regular" color="textTertiary">
              Пол
            </Text>
            <Flex flex={1} style={{height: 48}} alignItems="center">
              <Flex flex={1}>
                <RadioButton
                  labelPosition="right"
                  title={Gender.M}
                  selected={gender === CustomerGender.M}
                  onChange={() => {
                    if (gender === CustomerGender.M) {
                      setGender(undefined);
                    } else {
                      setGender(CustomerGender.M);
                    }
                  }}
                />
              </Flex>
              <Flex flex={1}>
                <RadioButton
                  labelPosition="right"
                  title={Gender.F}
                  selected={gender === CustomerGender.F}
                  onChange={() => {
                    if (gender === CustomerGender.F) {
                      setGender(undefined);
                    } else {
                      setGender(CustomerGender.F);
                    }
                  }}
                />
              </Flex>
            </Flex>
          </Flex>
          <MultiSelectChips
            label="Теги клиента"
            addItem={
              <TableViewRow
                hover
                pointer
                title="+ Создать новый тег"
                style={{
                  width: '100%',
                  padding: '0 16px',
                  marginBottom: 8,
                  position: 'sticky',
                  top: -16,
                  backgroundColor: colors.bgPrimary,
                }}
                styleTitle={{color: colors.mainPrimary}}
                onClick={() => {
                  setTagModal(true);
                }}
              />
            }
            renderItemLeftElement={item => (
              <div
                style={{
                  width: 4,
                  height: '42px',
                  borderRadius: 4,
                  backgroundColor: data?.companyTags?.find(
                    tag => tag.id === item.value,
                  )?.color
                    ? `rgb(${data?.companyTags?.find(
                        tag => tag.id === item.value,
                      )?.color?.rgb})`
                    : 'transparent',
                  marginRight: 16,
                }}
              />
            )}
            itemHeight={56}
            data={tags}
            values={selectTags}
            onChange={setSelectTags}
            onDelete={value => {
              setSelectTags(prev => prev.filter(item => item.value !== value));
            }}
            ListEmptyComponent={
              <Flex flex={1} alignItems="center" direction="column" gap={16}>
                <EmptyTags size={200} mt={8} />
                <Text typography="title20">У вас пока нет тегов</Text>
                <Text color="textTertiary" style={{width: 343}} align="center">
                  Нажмите на кнопку “Создать новый тег” и начните работу
                </Text>
              </Flex>
            }
          />
          <TextArea
            size="medium"
            label="Примечание"
            value={note}
            onChange={text => setNote(text)}
          />
        </Content>
        <Flex direction="column">
          <Button
            size="large"
            style={{marginTop: 32}}
            disabled={phone.length < 18 || loading}
            loading={loading}
            onClick={async () => {
              try {
                if (phone.length < 18) {
                  setPhoneError('Номер телефона введен некорректно');
                  throw new Error('Номер телефона введен некорректно');
                }
                if (email !== '' && !isEmailValid(email)) {
                  setEmailError('Неверный формат email');
                  throw new Error('Неверный формат email');
                }
                const checkDate = dayjs(
                  birthdate,
                  'DD.MM.YYYY',
                  true,
                ).isValid();
                if (birthdate && !checkDate) {
                  setBirthdateError('Неверный формат даты');
                  throw new Error('Неверный формат даты');
                }
                const input: CreateCustomer = {
                  company_id: companyId,
                  avatar:
                    avatar !== undefined
                      ? {type: ImageType.Avatar, ...avatar}
                      : null,
                  name,
                  surname,
                  email,
                  phone,
                  note,
                  tags_id: selectTags.map(item => item.value),
                };
                if (
                  gender === CustomerGender.F ||
                  gender === CustomerGender.M
                ) {
                  Object.assign(input, {gender: gender});
                }
                if (birthdate) {
                  Object.assign(input, {birthdate});
                }
                const newCustomer = await update({
                  variables: {
                    input,
                  },
                });
                if (from === 'bookings') {
                  const setRedux =
                    screen === 'create'
                      ? setCreateBookingCustomer
                      : setEditBookingCustomer;
                  dispatch(
                    setRedux(newCustomer.data?.createCustomer?.id ?? null),
                  );
                  navigate(-1);
                } else {
                  navigate(
                    `/${username}/customer/${newCustomer.data?.createCustomer?.id}`,
                    {replace: true},
                  );
                }
              } catch (e) {
                if (e instanceof Error) {
                  showAlert('error', e.message);
                }
              }
            }}>
            Добавить
          </Button>
        </Flex>
      </Wrapper>
    </Layout>
  );
};
