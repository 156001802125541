import React from 'react';
import {useColors} from 'shared/lib/hooks';

export const NotFound = () => {
  const colors = useColors();
  return (
    <svg
      width="458"
      height="616"
      viewBox="0 0 458 616"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="229" cy="251" r="180" fill={colors.mainLight50} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M254.351 9.26617C243.666 9.14192 234.937 17.7699 234.937 28.4563V104.943H225.672V28.4563C225.672 12.611 238.615 -0.18227 254.459 0.0019652L458.008 2.36882L457.9 11.633L254.351 9.26617Z"
        fill="#2B4582"
      />
      <path
        d="M255.451 161.855H205.156V140.408C205.156 132.953 209.624 126.223 216.495 123.33L217.73 122.811V112.657C217.73 110.26 218.682 107.961 220.377 106.266C222.072 104.571 224.371 103.619 226.768 103.619H233.951C235.853 103.619 237.677 104.375 239.022 105.72L239.568 106.266C241.263 107.961 242.216 110.26 242.216 112.657V122.811L244.525 123.85C251.175 126.842 255.451 133.456 255.451 140.748V161.855Z"
        fill="#2C3C7D"
      />
      <path
        d="M199.859 164.503C199.859 163.041 201.045 161.855 202.506 161.855H258.095C259.557 161.855 260.743 163.041 260.743 164.503V167.15C260.743 168.612 259.557 169.797 258.095 169.797H202.506C201.045 169.797 199.859 168.612 199.859 167.15V164.503Z"
        fill="#344185"
      />
      <mask
        id="mask0_19737_87313"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x="205"
        y="103"
        width="51"
        height="59">
        <path
          d="M255.451 161.855H205.156V140.408C205.156 132.953 209.624 126.223 216.495 123.33L217.73 122.811V112.657C217.73 110.26 218.682 107.961 220.377 106.266C222.072 104.571 224.371 103.619 226.768 103.619H233.951C235.853 103.619 237.677 104.375 239.022 105.72L239.568 106.266C241.263 107.961 242.216 110.26 242.216 112.657V122.811L244.525 123.85C251.175 126.842 255.451 133.456 255.451 140.748V161.855Z"
          fill="#36498B"
        />
      </mask>
      <g mask="url(#mask0_19737_87313)">
        <g opacity="0.4" filter="url(#filter0_f_19737_87313)">
          <circle cx="200.521" cy="170.458" r="54.9272" fill="#37488D" />
        </g>
        <g opacity="0.4" filter="url(#filter1_f_19737_87313)">
          <circle cx="189.933" cy="177.075" r="44.3388" fill="#4557A3" />
        </g>
        <rect
          x="213.094"
          y="103.619"
          width="35.7358"
          height="19.8532"
          fill="#232853"
        />
        <mask
          id="mask1_19737_87313"
          style={{
            maskType: 'alpha',
          }}
          maskUnits="userSpaceOnUse"
          x="213"
          y="103"
          width="36"
          height="21">
          <rect
            x="213.094"
            y="103.619"
            width="35.7358"
            height="19.8532"
            fill="#2A3972"
          />
        </mask>
        <g mask="url(#mask1_19737_87313)">
          <g filter="url(#filter2_f_19737_87313)">
            <circle cx="207.138" cy="132.075" r="32.4269" fill="#2A3970" />
          </g>
        </g>
        <rect
          x="213.094"
          y="120.825"
          width="35.7358"
          height="2.64709"
          fill="#162357"
        />
        <path
          d="M213.094 120.825H248.83V121.487H213.094V120.825Z"
          fill="#21306A"
        />
      </g>
      <mask
        id="mask2_19737_87313"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x="225"
        y="0"
        width="234"
        height="105">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M254.351 9.26617C243.666 9.14192 234.937 17.7699 234.937 28.4563V104.943H225.672V28.4563C225.672 12.611 238.615 -0.18227 254.459 0.0019652L458.008 2.36882L457.9 11.633L254.351 9.26617Z"
          fill="#2B4582"
        />
      </mask>
      <g mask="url(#mask2_19737_87313)">
        <path
          d="M559.862 -5.57356C559.862 38.6504 488.418 32.1475 393.757 32.1475C299.095 32.1475 237.578 31.3707 237.578 -12.8533C237.578 -57.0772 267.992 -43.9569 362.653 -43.9569C457.315 -43.9569 559.862 -49.7975 559.862 -5.57356Z"
          fill="#253A72"
          fillOpacity="0.81"
        />
      </g>
      <path
        d="M165.412 180V199.191M152.839 204.486L138.941 190.588M148.206 217.721H131"
        stroke="#283870"
        strokeWidth="6.61773"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M299.002 335.721V316.529M311.576 311.235L325.473 325.132M316.208 298H333.414"
        stroke="#283870"
        strokeWidth="6.61773"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.00698853 615.069V525.347C0.00698853 509.501 12.9498 496.708 28.7941 496.892L208.242 496.713C218.928 496.837 227.657 488.209 227.657 477.523V401.036H236.922V477.523C236.922 493.368 223.979 508.427 208.135 508.243L28.6864 506.157C18.0007 506.032 9.2718 514.66 9.2718 525.347V615.069H0.00698853Z"
        fill="#2B4582"
      />
      <path
        d="M206.533 344.123H256.828V365.571C256.828 373.026 252.36 379.755 245.489 382.648L244.254 383.168V393.322C244.254 395.719 243.302 398.017 241.607 399.712C239.912 401.407 237.614 402.359 235.217 402.359H228.033C226.131 402.359 224.307 401.604 222.963 400.259L222.416 399.712C220.721 398.017 219.769 395.719 219.769 393.322V383.168L217.459 382.129C210.81 379.136 206.533 372.523 206.533 365.231V344.123Z"
        fill="#2C3C7D"
      />
      <path
        d="M262.117 341.477C262.117 342.939 260.932 344.124 259.47 344.124H203.881C202.419 344.124 201.234 342.939 201.234 341.477V338.83C201.234 337.368 202.419 336.183 203.881 336.183H259.47C260.932 336.183 262.117 337.368 262.117 338.83V341.477Z"
        fill="#344185"
      />
      <mask
        id="mask3_19737_87313"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x="206"
        y="344"
        width="51"
        height="59">
        <path
          d="M206.526 344.124H256.82V365.571C256.82 373.027 252.352 379.756 245.481 382.649L244.247 383.169V393.323C244.247 395.72 243.294 398.018 241.6 399.713C239.905 401.408 237.606 402.36 235.209 402.36H228.026C226.124 402.36 224.3 401.605 222.955 400.26L222.408 399.713C220.713 398.018 219.761 395.72 219.761 393.323V383.169L217.451 382.13C210.802 379.137 206.526 372.524 206.526 365.232V344.124Z"
          fill="#36498B"
        />
      </mask>
      <g mask="url(#mask3_19737_87313)">
        <g opacity="0.4" filter="url(#filter3_f_19737_87313)">
          <circle
            cx="200.77"
            cy="333.126"
            r="54.9272"
            transform="rotate(60 200.77 333.126)"
            fill="#37488D"
          />
        </g>
        <g opacity="0.4" filter="url(#filter4_f_19737_87313)">
          <circle
            cx="189.74"
            cy="327.265"
            r="44.3388"
            transform="rotate(60 189.74 327.265)"
            fill="#4557A3"
          />
        </g>
        <rect
          x="248.875"
          y="402.36"
          width="35.7358"
          height="19.8532"
          transform="rotate(180 248.875 402.36)"
          fill="#232853"
        />
        <mask
          id="mask4_19737_87313"
          style={{
            maskType: 'alpha',
          }}
          maskUnits="userSpaceOnUse"
          x="213"
          y="382"
          width="36"
          height="21">
          <rect
            x="248.875"
            y="402.36"
            width="35.7358"
            height="19.8532"
            transform="rotate(180 248.875 402.36)"
            fill="#2A3972"
          />
        </mask>
        <g mask="url(#mask4_19737_87313)">
          <g filter="url(#filter5_f_19737_87313)">
            <circle
              cx="205.807"
              cy="373.904"
              r="32.4269"
              transform="rotate(180 205.807 373.904)"
              fill="#2A3970"
            />
          </g>
        </g>
        <rect
          x="248.875"
          y="385.154"
          width="35.7358"
          height="2.64709"
          transform="rotate(180 248.875 385.154)"
          fill="#162357"
        />
        <path
          d="M248.875 385.154H213.139V384.493H248.875V385.154Z"
          fill="#21306A"
        />
      </g>
      <mask
        id="mask5_19737_87313"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="401"
        width="237"
        height="214">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.00698853 614.89V525.167C0.00698853 509.322 12.9498 496.529 28.7941 496.713L208.242 496.713C218.928 496.837 227.657 488.209 227.657 477.522V401.035H236.922V477.522C236.922 493.368 223.979 506.161 208.135 505.977L28.6864 505.977C18.0007 505.853 9.2718 514.481 9.2718 525.167V614.89H0.00698853Z"
          fill="#2B4582"
        />
      </mask>
      <g mask="url(#mask5_19737_87313)">
        <path
          d="M-97.8852 511.551C-97.8852 467.327 -26.4413 473.83 68.2199 473.83C162.881 473.83 224.398 474.607 224.398 518.831C224.398 563.055 101.285 645.892 6.62328 645.892C-88.038 645.892 -97.8852 555.775 -97.8852 511.551Z"
          fill="#253A72"
          fillOpacity="0.81"
        />
      </g>
      <path
        d="M215.742 313.02C215.742 310.462 217.816 308.388 220.375 308.388C222.933 308.388 225.007 310.462 225.007 313.02V336.182H215.742V313.02Z"
        fill="#272756"
      />
      <path
        d="M238.242 313.02C238.242 310.462 240.316 308.388 242.875 308.388C245.433 308.388 247.507 310.462 247.507 313.02V336.182H238.242V313.02Z"
        fill="#272756"
      />
      <defs>
        <filter
          id="filter0_f_19737_87313"
          x="142.947"
          y="112.884"
          width="115.146"
          height="115.149"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="1.32355"
            result="effect1_foregroundBlur_19737_87313"
          />
        </filter>
        <filter
          id="filter1_f_19737_87313"
          x="142.947"
          y="130.089"
          width="93.9739"
          height="93.9719"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="1.32355"
            result="effect1_foregroundBlur_19737_87313"
          />
        </filter>
        <filter
          id="filter2_f_19737_87313"
          x="169.417"
          y="94.3542"
          width="75.4399"
          height="75.4419"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2.64709"
            result="effect1_foregroundBlur_19737_87313"
          />
        </filter>
        <filter
          id="filter3_f_19737_87313"
          x="143.189"
          y="275.542"
          width="115.161"
          height="115.167"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="1.32355"
            result="effect1_foregroundBlur_19737_87313"
          />
        </filter>
        <filter
          id="filter4_f_19737_87313"
          x="142.744"
          y="280.272"
          width="93.9895"
          height="93.9866"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="1.32355"
            result="effect1_foregroundBlur_19737_87313"
          />
        </filter>
        <filter
          id="filter5_f_19737_87313"
          x="168.089"
          y="336.183"
          width="75.4399"
          height="75.4419"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2.64709"
            result="effect1_foregroundBlur_19737_87313"
          />
        </filter>
      </defs>
    </svg>
  );
};
