import React from 'react';
import styled from 'styled-components';
import {Flex} from 'shared/ui/Flex';
import {device} from 'shared/device';
import {Button} from 'shared/ui/Button';
import {Plus} from 'shared/icons/Plus';
import {IconButton} from 'shared/ui/IconButton';
import {useResize} from 'shared/lib/hooks';

const Head = styled(Flex)`
  align-self: flex-end;
  margin-bottom: 24px;
  @media ${device.mobile} {
    margin: 24px 16px 0px;
  }
`;

const AddButton = styled(IconButton)`
  background-color: ${({theme}) => theme.mainPrimary};
  position: absolute;
  top: 16px;
  right: 16px;
`;

export const Header = ({onClickAdd}: {onClickAdd: () => void}) => {
  const {isMobile} = useResize();

  return (
    <>
      <Head
        direction="row"
        style={{
          justifyContent: 'space-between',
          position: 'absolute',
          top: 64,
          overflow: 'visible',
        }}>
        <Flex gap={8}>
          {isMobile ? (
            <AddButton size={40} onClick={onClickAdd}>
              <Plus />
            </AddButton>
          ) : (
            <Button
              size="medium"
              style={{
                paddingLeft: 32,
                paddingRight: 32,
              }}
              onClick={onClickAdd}
              variant="filled"
              leftIcon={<Plus size={20} />}>
              Добавить услугу
            </Button>
          )}
        </Flex>
      </Head>
    </>
  );
};
