import React from 'react';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';
import {Content} from 'shared/ui/Content';
import {Head} from 'shared/ui/Head';
import {Button} from 'shared/ui/Button';
import {device} from 'shared/device';
import {NavLink} from 'shared/ui/NavLink';
import {CheckBox} from 'shared/ui/CheckBox';
import {Flex} from 'shared/ui/Flex';
import {WorkingDay} from 'shared/__generated__/graphql';
import {gql} from 'shared/__generated__';
import {useAppSelector} from 'shared/store';
import {useMutation, useQuery} from '@apollo/client';
import {List} from 'shared/ui/List';
import {Popup} from 'shared/ui/Popup';
import {TableViewRow} from 'shared/ui/TableViewRow';
import {Switch} from 'shared/ui/Switch';
import {ScheduleWorkTime, transaleteWeekDay} from 'entities/schedule';
import {Circle} from 'shared/icons/Circle';
import {showAlert} from 'shared/ui/Alert';
import {NavigateBack} from 'shared/ui/NavigateBack';
import {CloseButton} from 'shared/ui/CloseButton';
import {Seo} from 'shared/ui/Seo';
import {ArrowRight1v} from 'shared/icons/ArrowRight1v';

const COMPANY_WORKING_DATES = gql(`
query CompanyWorkingDays($companyId: String!) {
  workingDays(company_id: $companyId) {
    id
    day
    dayoff
    start_time
    end_time
    break_start_time
    break_end_time
  }
}
`);

const UPDAT_WORKING_DATES = gql(`
  mutation UpdateWorkingDays($input: [UpdateWorkingDays!]!, $companyId: String!) {
    updateWorkingDays(input: $input, company_id: $companyId) {
      id
      day
      dayoff
      start_time
      end_time
      break_start_time
      break_end_time
    }
  }
`);

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0 auto;
  max-width: 720px;
  min-height: 100dvh;
  border-radius: 0;
  padding-top: 64px;
  padding-bottom: 8px;
  @media screen and (${device.mobile}) {
    margin-top: 56px;
  }
`;

function format(item: Omit<WorkingDay, 'id' | '__typename' | 'company'>) {
  if (item.dayoff) {
    return 'Выходной';
  }
  const shedule = item.start_time + ' - ' + item.end_time;
  const breakTime =
    item.break_start_time && item.break_end_time
      ? 'перерыв ' + item.break_start_time + ' - ' + item.break_end_time
      : 'без перерыва';
  return shedule + ', ' + breakTime;
}
const labelFormat = (day: {day: string}) => {
  const translatedDay = transaleteWeekDay(day.day);
  return translatedDay.charAt(0).toLocaleUpperCase() + translatedDay.slice(1);
};

type IWorkingDay = Omit<WorkingDay, 'company'>;

export const CompanySchedule = () => {
  const companyId = useAppSelector(state => state.company.data!.id);
  const [modal, setModal] = React.useState(false);
  const [breakModal, setBreakModal] = React.useState(false);
  const [dayoffEnabled, setDayoffEnabled] = React.useState(false);
  const {data} = useQuery(COMPANY_WORKING_DATES, {
    variables: {companyId: companyId},
  });

  const weekData = data?.workingDays;
  const [start, setStart] = React.useState('');
  const [end, setEnd] = React.useState('');
  const [startBreak, setStartBreak] = React.useState<string | null>(null);
  const [endBreak, setEndBreak] = React.useState<string | null>(null);
  const [selectDaysWeek, setSelectDaysWeek] = React.useState<IWorkingDay[]>([]);
  const [selectedDay, setSelectedDay] = React.useState<IWorkingDay>();

  const DAYS_ORDER = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
  ];
  const getDayIndex = (day: string) => DAYS_ORDER.indexOf(day);
  const sortDays = (days: IWorkingDay[]) => {
    return days.sort((a, b) => getDayIndex(a.day) - getDayIndex(b.day));
  };
  const sortedSelectDaysWeek = sortDays(selectDaysWeek);
  const label =
    sortedSelectDaysWeek.length > 0
      ? sortedSelectDaysWeek.map(day => labelFormat(day)).join(', ')
      : labelFormat({day: selectedDay ? selectedDay.day : ''});
  const [update, {loading: loadingUpdate}] = useMutation(UPDAT_WORKING_DATES, {
    refetchQueries: ['CompanyWorkingDays'],
  });

  const updateWorkingDays = async () => {
    const daysToUpdate =
      selectDaysWeek.length > 0 ? selectDaysWeek : [selectedDay!];
    const formatted = daysToUpdate.map(item => ({
      day: item.day,
      dayoff: dayoffEnabled,
      start_time: start,
      end_time: end,
      break_start_time: startBreak,
      break_end_time: endBreak,
    }));
    await update({
      variables: {
        companyId: companyId,
        input: formatted.map(item => ({...item, day: item.day})),
      },
    })
      .then(() => {
        showAlert('success', 'Coxранено');
        setSelectDaysWeek([]);
        setModal(false);
      })
      .catch(err => {
        if (err instanceof Error) {
          showAlert('error', err.message);
        }
        setModal(false);
      });
  };

  const changeMainTime = ({
    time,
    location,
  }: {
    time: string;
    location: 'start' | 'end';
  }) => {
    if (location === 'start') {
      setStart(time);
      return;
    }
    setEnd(time);
  };

  const changeBreakTime = ({
    time,
    location,
  }: {
    time: string;
    location: 'start' | 'end';
  }) => {
    if (location === 'start') {
      setStartBreak(time);
      return;
    }
    setEndBreak(time);
  };

  return (
    <Wrapper>
      <Seo title="Режим работы" />
      <Head style={{marginBottom: 32}}>
        <NavigateBack />
        <Text typography="title24">Режим работы</Text>
      </Head>
      <Flex direction="column" gap={32}>
        <Content>
          <List
            keyExtractor={item => item.day.toString()}
            gap={16}
            data={weekData}
            renderItem={item => (
              <Flex direction="row" gap={16}>
                <CheckBox
                  style={{padding: '0 17px'}}
                  checked={selectDaysWeek.some(day => day.day === item.day)}
                  onChange={() => {
                    if (selectDaysWeek.includes(item)) {
                      setSelectDaysWeek(
                        selectDaysWeek.filter(day => day.day !== item.day),
                      );
                    } else {
                      setSelectDaysWeek([...selectDaysWeek, item]);
                    }
                  }}
                />
                <NavLink
                  rightIcon={<ArrowRight1v />}
                  disabled={selectDaysWeek.length > 0}
                  label={labelFormat(item)}
                  description={format(item)}
                  onClick={() => {
                    setStart(item.start_time);
                    setEnd(item.end_time);
                    setDayoffEnabled(item.dayoff);
                    setBreakModal(
                      item.break_start_time === null ? false : true,
                    );
                    setStartBreak(item.break_start_time ?? null);
                    setEndBreak(item.break_end_time ?? null);
                    setSelectedDay(item);
                    setModal(true);
                  }}
                />
              </Flex>
            )}
          />
          <Text typography="subHead14Regular" color="textTertiary">
            Не влияет на график работы сотрудников
          </Text>
        </Content>
        <Button
          size="large"
          disabled={selectDaysWeek.length === 0}
          onClick={() => {
            setStart(selectDaysWeek[0].start_time);
            setEnd(selectDaysWeek[0].end_time);
            setDayoffEnabled(selectDaysWeek[0].dayoff);
            setBreakModal(
              selectDaysWeek[0].break_start_time === null ? false : true,
            );
            setStartBreak(selectDaysWeek[0].break_start_time ?? null);
            setEndBreak(selectDaysWeek[0].break_end_time ?? null);
            setModal(true);
          }}>
          Изменить выбранные
        </Button>
      </Flex>

      <Popup
        style={{width: 472, alignItems: 'initial'}}
        visible={modal}
        onClose={() => {
          setModal(false);
        }}>
        <Flex direction="column" gap={24}>
          <Flex flex={1} justifyContent="space-between" alignItems="center">
            <Text typography="title20" color="textPrimary">
              Изменения графика
            </Text>
            <CloseButton
              onClose={() => {
                setModal(false);
              }}
            />
          </Flex>
          <TableViewRow
            style={{padding: '10px 0'}}
            title="Выходной день"
            rightElement={() => (
              <Switch
                value={dayoffEnabled}
                onChange={() => {
                  setDayoffEnabled(!dayoffEnabled);
                }}
              />
            )}
          />
          {!dayoffEnabled && (
            <>
              <Flex direction="column" flex={1} gap={12}>
                <Text typography="text16Regular" color="textSecondary">
                  {label}
                </Text>
                <ScheduleWorkTime
                  value={{start: start, end: end}}
                  onChange={(time, location) =>
                    changeMainTime({time, location})
                  }
                />
              </Flex>
              {breakModal ? (
                <Flex direction="column" gap={12}>
                  <Flex flex={1} justifyContent="space-between">
                    <Text typography="text16Regular" color="textSecondary">
                      Перерыв
                    </Text>
                    <Text
                      typography="text16Regular"
                      color="mainPrimary"
                      onClick={() => {
                        setStartBreak(null);
                        setEndBreak(null);
                        setBreakModal(false);
                      }}>
                      Удалить
                    </Text>
                  </Flex>
                  <ScheduleWorkTime
                    value={{start: startBreak, end: endBreak}}
                    onChange={(time, location) => {
                      changeBreakTime({time, location});
                    }}
                  />
                </Flex>
              ) : (
                <Button
                  size="medium"
                  variant="subtitle"
                  leftIcon={<Circle />}
                  onClick={() => {
                    setStartBreak('13:00');
                    setEndBreak('14:00');
                    setBreakModal(true);
                  }}
                  style={{alignSelf: 'flex-start', padding: 0}}>
                  Добавить перерыв
                </Button>
              )}
            </>
          )}
          <Button
            size="large"
            onClick={updateWorkingDays}
            loading={loadingUpdate}>
            Сохранить
          </Button>
        </Flex>
      </Popup>
    </Wrapper>
  );
};
