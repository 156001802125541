import React from 'react';
import {Text} from 'shared/ui/Text';
import {Grow} from 'shared/ui/Grow';
import {EmptyAddCategory} from 'shared/illustration/EmptyAddCategory';

export const EmptyState = () => {
  return (
    <Grow style={{paddingTop: 80}}>
      <EmptyAddCategory size={300} />
      <Text
        typography="text18"
        color="textTertiary"
        style={{alignSelf: 'center', marginTop: 16}}>
        Ничего не найдено
      </Text>
    </Grow>
  );
};
