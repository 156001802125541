import {useQuery} from '@apollo/client';
import React, {useState} from 'react';
import {gql} from 'shared/__generated__';
import {EmptyFileNotFound} from 'shared/illustration/EmptyFileNotFound';
import {getName, getPriceFormat} from 'shared/lib/utils';
import {useAppSelector} from 'shared/store';
import {Avatar} from 'shared/ui/Avatar';
import {Content} from 'shared/ui/Content';
import {Flex} from 'shared/ui/Flex';
import {Skeleton} from 'shared/ui/Skeleton';
import {Slide} from 'shared/ui/Slide';
import {OptionsType, Table} from 'shared/ui/Table';
import {Text} from 'shared/ui/Text';

const GET_SALARIES = gql(`
  query Salaries($companyId: String!, $period: StatisticsPeriod!, $first: Int!, $page: Int) {
    salaries(company_id: $companyId, period: $period, first: $first, page: $page) {
      paginatorInfo {
        currentPage
        total
        count
      }
      data {
        employee {
          id
          name
          surname
          enabled
          avatar {
            id
            url
          }
        }
        salary
        services_price
        services_provided
        worked_time
      }
    }
  }
`);
const calculationTitles = [
  '',
  'Сотрудник',
  'Рабочее время',
  'Оказано услуг',
  'Стоимость услуг, ₽',
  'Зарплата, ₽',
];

export const SalaryCalculation = ({
  dates,
}: {
  dates: {from: string | null; to: string | null};
}) => {
  const companyId = useAppSelector(state => state.company.data!.id);
  const [options, setOptions] = useState<OptionsType>({
    page: 1,
    first: 10,
    total: 0,
    count: 0,
  });
  const {data, loading} = useQuery(GET_SALARIES, {
    variables: {
      companyId,
      period: {from: dates.from, to: dates.to},
      first: options.first,
      page: options.page,
    },
    onCompleted: res =>
      setOptions(prev => ({
        ...prev,
        total: res.salaries?.paginatorInfo.total ?? 0,
        count: res.salaries?.paginatorInfo.count ?? 0,
        page: res.salaries?.paginatorInfo.currentPage ?? 1,
      })),
  });
  const salaries = data?.salaries?.data ?? [];
  return (
    <Slide>
      {loading ? (
        <CalculationSkeleton />
      ) : salaries.length === 0 ? (
        <Flex alignItems="center" flex={1} direction="column" gap={16}>
          <EmptyFileNotFound size={300} mt={32} />
          <Text typography="title20" align="center">
            На выбранную дату расчетов нет
          </Text>
          <Text color="textTertiary" align="center">
            Добавьте правило расчета сотрудникам или выберите <br /> другую дату
          </Text>
        </Flex>
      ) : salaries.length ? (
        <Content>
          <Table
            titles={calculationTitles}
            widths={[64]}
            columnNoWrap={[1]}
            options={options}
            setOptions={setOptions}
            rows={salaries.map((item, index) => [
              <Avatar
                key={index + '_avatar'}
                url={item.employee?.avatar?.url}
              />,
              getName(item.employee?.name, item.employee?.surname, 'employee'),
              item.worked_time + '\u00A0ч',
              item.services_provided,
              getPriceFormat(item.services_price),
              getPriceFormat(item.salary),
            ])}
          />
        </Content>
      ) : (
        <Flex
          alignItems="center"
          justifyContent="center"
          gap={16}
          direction="column">
          <EmptyFileNotFound size={300} />
          <Text typography="title20">На выбранную дату расчетов нет</Text>
          <Text typography="text18" color="textTertiary" align="center">
            Добавьте правило расчета сотрудникам или выберите <br /> другую дату
          </Text>
        </Flex>
      )}
    </Slide>
  );
};
const CalculationSkeleton = () => {
  return (
    <Content>
      <Skeleton height={400}>
        {Array(4)
          .fill(0)
          .map((_, index) => (
            <React.Fragment key={'skeleton_rect_' + index}>
              <circle r={20} cx={20} cy={92 + 80 * index} />
              <rect
                width={200}
                height={24}
                rx={14}
                ry={14}
                x={80}
                y={80 + 80 * index}
              />
              <rect
                width={80}
                height={24}
                rx={14}
                ry={14}
                x={320}
                y={80 + 80 * index}
              />
              <rect
                width={60}
                height={24}
                rx={14}
                ry={14}
                x={588}
                y={80 + 80 * index}
              />
              <rect
                width={160}
                height={24}
                rx={14}
                ry={14}
                x={768}
                y={80 + 80 * index}
              />
              <rect
                width={160}
                height={24}
                rx={14}
                ry={14}
                x={1076}
                y={80 + 80 * index}
              />
            </React.Fragment>
          ))}
      </Skeleton>
    </Content>
  );
};
