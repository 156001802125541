import React, {useState, useEffect} from 'react';
import {styled} from 'styled-components';
import {FilterButton} from 'shared/ui/FilterButton';
import {Modal} from 'shared/ui/Modal';
import {List} from 'shared/ui/List';
import {Text} from 'shared/ui/Text';
import {useLazyQuery} from '@apollo/client';
import {gql} from 'shared/__generated__';
import {useAppSelector} from 'shared/store';
import {Flex} from 'shared/ui/Flex';
import {Loading} from 'shared/ui/Loading';

const GET_BONUSES = gql(`
  query BonusCardTemplatesFilter(
    $first: Int!
    $companyId: String!
    $page: Int
  ) {
    bonusCardTemplates(
      first: $first
      company_id: $companyId
      page: $page
    ) {
      paginatorInfo {
        count
        total
        currentPage
        lastPage
      }
      data {
        id
        name
      }
    }
  }
`);

const PopupWrapper = styled.div`
  position: relative;
  display: inline-block;
`;
const ButtonEmployee = styled.button<{checked?: boolean}>`
  display: flex;
  flex-direction: row;
  padding: 0 16px;
  align-items: center;
  height: 44px;
  width: 343px;
  gap: 8px;
  background-color: ${({checked, theme}) =>
    checked ? theme.bgSecondary : 'transparent'};
  border: none;
`;

export const FilterStatisticsBonusCard = ({
  bonusCardId,
  setBonusCardId,
}: {
  bonusCardId?: string;
  setBonusCardId?: (id: string) => void;
}) => {
  const companyId = useAppSelector(state => state.company.data!.id);
  const [lazy, {data, loading}] = useLazyQuery(GET_BONUSES, {
    variables: {
      companyId,
      first: 100,
    },
  });
  const bonuses = data?.bonusCardTemplates?.data;
  const bonus = bonuses?.find(item => item.id === bonusCardId);
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    if (visible) lazy();
  }, [lazy, visible]);
  return (
    <PopupWrapper>
      <FilterButton
        onClick={() => setVisible(true)}
        active={!!bonusCardId}
        title={bonus ? bonus.name : 'Все бонусные карты'}
        visible={visible}
      />
      <Modal
        style={{
          left: 'auto',
          right: 'auto',
          top: 64,
          padding: 0,
          gap: 8,
        }}
        visible={visible}
        onClose={() => setVisible(false)}>
        {loading ? (
          <Flex justifyContent="center" style={{width: 343}}>
            <Loading />
          </Flex>
        ) : (
          <Flex
            direction="column"
            style={{
              maxHeight: 284,
              overflowY: 'auto',
              paddingTop: 24,
            }}>
            <List
              gap={16}
              data={[{name: 'Все бонусные карты', id: ''}, ...(bonuses ?? [])]}
              keyExtractor={item => 'key' + item.id}
              renderItem={(item, index) => (
                <ButtonEmployee
                  style={{
                    marginBottom: bonuses?.length === index ? 24 : 0,
                  }}
                  onClick={() => {
                    setBonusCardId && setBonusCardId(item.id);
                    setVisible(false);
                  }}
                  checked={item.id === bonusCardId}>
                  <Text>{item.name}</Text>
                </ButtonEmployee>
              )}
            />
          </Flex>
        )}
      </Modal>
    </PopupWrapper>
  );
};
