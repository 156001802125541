import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import React from 'react';
import {Color, CompanyTag} from 'shared/__generated__/graphql';
import styled from 'styled-components';
import {GripVertical} from 'shared/icons/GripVertical';
import {Text} from 'shared/ui/Text';
import {Flex} from 'shared/ui/Flex';
import {useColors} from 'shared/lib/hooks';

const Item = styled.div`
  overflow: hidden;
  padding: 0;
  border: none;
  background-color: transparent;
  display: flex;
  flex: 1;
  flex-direction: row;
  height: 80px;
  align-items: center;
  border-radius: 14px;
  touch-action: none;
  &:hover {
    background-color: ${({theme}) => theme.bgTertiary};
    z-index: 100;
  }
`;
const CellGrip = styled.div`
  display: flex;
  width: 52px;
  margin-right: 4px;
  height: 80px;
  align-items: center;
  justify-content: center;
`;

const ColorBorder = styled.div<{color?: string}>`
  height: 56px;
  width: 4px;
  border-radius: 4px;
  background-color: rgb(${({color}) => color});
`;

const GripBox = styled.div`
  display: flex;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  justify-content: center;
  align-items: center;
  pointer-events: all;
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
`;

type TagType = Pick<CompanyTag, 'id' | 'name' | 'order'> & {
  color?: Pick<Color, 'id' | 'rgb'> | null;
};

export const SortableItem = ({
  tag,
  onClick,
}: {
  tag: TagType;
  onClick?: React.FormEventHandler<HTMLDivElement>;
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    index,
    activeIndex,
  } = useSortable({
    id: tag.id,
  });
  const activeTag = index === activeIndex;
  const style = {
    transform: CSS.Transform.toString(
      transform
        ? {
            x: transform.x,
            y: transform.y,
            scaleX: activeTag ? transform.scaleX + 0.03 : transform.scaleX,
            scaleY: activeTag ? transform.scaleY + 0.03 : transform.scaleY,
          }
        : null,
    ),
    transition,
  };
  const colors = useColors();

  return (
    <Item ref={setNodeRef} style={style} onClick={onClick}>
      <Flex flex={1} alignItems="center" gap={16}>
        <Flex alignItems="center" justifyContent="center">
          <ColorBorder color={tag.color?.rgb} />
          <CellGrip>
            <GripBox {...attributes} {...listeners}>
              <GripVertical color={colors.textTertiary} />
            </GripBox>
          </CellGrip>
        </Flex>
        <Text>{tag.name}</Text>
      </Flex>
    </Item>
  );
};
