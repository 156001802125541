import React from 'react';
export const Category = ({
  size = 24,
  color = '#6765F8',
}: {
  size?: number;
  color?: string;
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 7.77476C3 5.98839 3 5.0952 3.55496 4.54025C4.10991 3.98529 5.0031 3.98529 6.78947 3.98529C8.57585 3.98529 9.46904 3.98529 10.024 4.54025C10.5789 5.0952 10.5789 5.98839 10.5789 7.77476C10.5789 9.56114 10.5789 10.4543 10.024 11.0093C9.46904 11.5642 8.57585 11.5642 6.78947 11.5642C5.0031 11.5642 4.10991 11.5642 3.55496 11.0093C3 10.4543 3 9.56114 3 7.77476Z"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M13.4211 18.1958C13.4211 16.4094 13.4211 15.5163 13.976 14.9613C14.531 14.4063 15.4242 14.4063 17.2105 14.4063C18.9969 14.4063 19.8901 14.4063 20.445 14.9613C21 15.5163 21 16.4094 21 18.1958C21 19.9822 21 20.8754 20.445 21.4303C19.8901 21.9853 18.9969 21.9853 17.2105 21.9853C15.4242 21.9853 14.531 21.9853 13.976 21.4303C13.4211 20.8754 13.4211 19.9822 13.4211 18.1958Z"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M3 18.1958C3 16.4094 3 15.5163 3.55496 14.9613C4.10991 14.4063 5.0031 14.4063 6.78947 14.4063C8.57585 14.4063 9.46904 14.4063 10.024 14.9613C10.5789 15.5163 10.5789 16.4094 10.5789 18.1958C10.5789 19.9822 10.5789 20.8754 10.024 21.4303C9.46904 21.9853 8.57585 21.9853 6.78947 21.9853C5.0031 21.9853 4.10991 21.9853 3.55496 21.4303C3 20.8754 3 19.9822 3 18.1958Z"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M13.4211 7.77476C13.4211 5.98839 13.4211 5.0952 13.976 4.54025C14.531 3.98529 15.4242 3.98529 17.2105 3.98529C18.9969 3.98529 19.8901 3.98529 20.445 4.54025C21 5.0952 21 5.98839 21 7.77476C21 9.56114 21 10.4543 20.445 11.0093C19.8901 11.5642 18.9969 11.5642 17.2105 11.5642C15.4242 11.5642 14.531 11.5642 13.976 11.0093C13.4211 10.4543 13.4211 9.56114 13.4211 7.77476Z"
        stroke={color}
        strokeWidth="1.5"
      />
    </svg>
  );
};
