import React, {useState} from 'react';
import {styled} from 'styled-components';
import {OptionsType, Table, TableSortType} from 'shared/ui/Table';
import {gql} from 'shared/__generated__';
import {useAppSelector} from 'shared/store';
import {useQuery} from '@apollo/client';
import {
  QueryGetStatisticsCertificateOrderByColumn,
  SortOrder,
  StatisticsDates,
} from 'shared/__generated__/graphql';
import {Flex} from 'shared/ui/Flex';
import {
  FilterEmptyState,
  FilterResetButton,
  FilterSearch,
  FilterStatisticsPeriod,
} from 'entities/statistics';
import dayjs from 'dayjs';
import {getNumberFormat} from 'shared/lib/utils';
import {Content} from 'shared/ui/Content';
import {PageHeader} from 'shared/ui/PageHeader';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
`;

const GET_STATISTICS_CERTIFICATES = gql(`
  query GetStatisticsCertificate(
    $companyId: String!
    $date: StatisticsDates!
    $first: Int!
    $page: Int
    $query: String
    $orderBy: [QueryGetStatisticsCertificateOrderByOrderByClause!]
  ) {
    getStatisticsCertificate(
      company_id: $companyId
      date: $date
      first: $first
      page: $page
      query: $query
      orderBy: $orderBy
    ) {
      paginatorInfo {
        hasMorePages
        currentPage
        total
        count
        lastPage
      }
      data {
        clients
        created_at
        expiration
        left
        name
        sales
        used
      }
    }
  }
`);
const titles = [
  'Название',
  'Дата создания',
  'Дата окончания',
  'Кол-во клиентов',
  'Продано',
  'Использовано',
  'Остаток',
];
const sortValues: QueryGetStatisticsCertificateOrderByColumn[] = [
  QueryGetStatisticsCertificateOrderByColumn.Name,
  QueryGetStatisticsCertificateOrderByColumn.CreatedAt,
  QueryGetStatisticsCertificateOrderByColumn.Expiration,
  QueryGetStatisticsCertificateOrderByColumn.Clients,
  QueryGetStatisticsCertificateOrderByColumn.Sales,
  QueryGetStatisticsCertificateOrderByColumn.Used,
  QueryGetStatisticsCertificateOrderByColumn.Left,
];
export const LoyaltyCertificates = () => {
  const companyId = useAppSelector(state => state.company.data!.id);
  const [sort, setSort] = useState<TableSortType>({
    index: 0,
    order: SortOrder.Desc,
  });
  const [search, setSearch] = useState('');
  const [options, setOptions] = useState<OptionsType>({
    page: 1,
    first: 10,
    total: 0,
    count: 0,
    lastPage: 1,
  });
  const [period, setPeriod] = useState<StatisticsDates>(
    StatisticsDates.Alltime,
  );
  const {data, loading} = useQuery(GET_STATISTICS_CERTIFICATES, {
    variables: {
      companyId,
      date: period,
      first: options.first,
      page: options.page,
      query: search,
      orderBy: [
        {
          order: sort.order,
          column: sortValues[sort.index],
        },
      ],
    },
    onCompleted: res =>
      setOptions(prev => ({
        ...prev,
        total: res.getStatisticsCertificate?.paginatorInfo.total,
        count: res.getStatisticsCertificate?.paginatorInfo.count,
        lastPage: res.getStatisticsCertificate?.paginatorInfo.lastPage,
      })),
  });
  const statistics = data?.getStatisticsCertificate?.data ?? [];
  const isEmpty = !statistics?.filter(item => item.name !== 'Итого').length;
  return (
    <Wrapper>
      <PageHeader back title="Статистика по сертификатам" />
      <Flex direction="row" gap={8}>
        <FilterSearch onChange={setSearch} value={search} />
        <FilterStatisticsPeriod {...{period, setPeriod}} />
        {search || period !== StatisticsDates.Alltime ? (
          <FilterResetButton
            onClick={() => {
              setSearch('');
              setPeriod(StatisticsDates.Alltime);
            }}
          />
        ) : (
          <></>
        )}
      </Flex>
      {!loading && isEmpty ? (
        <FilterEmptyState />
      ) : (
        <Content>
          <Table
            options={options}
            setOptions={setOptions}
            titles={titles}
            sort={sort}
            alignRightColumn={[3, 4, 5, 6]}
            results
            onClickTitle={(index, order) => setSort({index, order})}
            rows={statistics.map(item => [
              item.name,
              dayjs(item.created_at).isValid()
                ? dayjs(item.created_at).format('DD/MM/YYYY')
                : item.created_at,
              dayjs(item.expiration).isValid()
                ? dayjs(item.expiration).format('DD/MM/YYYY')
                : item.expiration,
              getNumberFormat(item.clients),
              getNumberFormat(item.sales),
              getNumberFormat(item.used),
              getNumberFormat(item.left),
            ])}
          />
        </Content>
      )}
    </Wrapper>
  );
};
