import {useMutation, useQuery} from '@apollo/client';
import dayjs from 'dayjs';
import React, {useState} from 'react';
import {gql} from 'shared/__generated__';
import {Plus} from 'shared/icons/Plus';
import {useColors, useEmployeePermission, useResize} from 'shared/lib/hooks';
import {useAppSelector} from 'shared/store';
import {Button} from 'shared/ui/Button';
import {Flex} from 'shared/ui/Flex';
import {Layout} from 'shared/ui/Layout';
import {Table} from 'shared/ui/Table';
import {Text} from 'shared/ui/Text';
import {TextInput} from 'shared/ui/TextInput';
import styled from 'styled-components';
import {CertificatesSkeleton} from './ui/CertificatesSkeleton';
import {EmptyState} from './ui/EmptyState';
import {Error} from 'shared/ui/Error';
import {useNavigate} from 'react-router-dom';
import {
  GET_CERTIFICATE_TEMPLATES,
  ModalIssueCertificate,
} from 'entities/loyalty';
import {Search} from 'shared/icons/Search';
import {showAlert} from 'shared/ui/Alert';
import {Seo} from 'shared/ui/Seo';
import {Content} from 'shared/ui/Content';
import {FooterAction} from 'shared/ui/FooterAction';
import {getPriceFormat} from 'shared/lib/utils';

type OptionsType = {
  page: number;
  first: number;
  total?: number;
  count?: number;
};

const DELETE_CERTIFICATES = gql(`
  mutation DeleteCertificates($ids: [String!]!) {
    deleteCertificateTemplates(ids: $ids) {
      id
    }
  }
`);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  grid-column-start: 1;
  grid-column-end: 13;
  gap: 24px;
  background-color: ${({theme}) => theme.bgSecondary};
`;

const Head = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 56px;
  gap: 8px;
  margin: 24px 24px 0 24px;
`;

export const Certificates = () => {
  const navigate = useNavigate();
  const username = useAppSelector(state => state.company.data?.username);
  const colors = useColors();
  const companyId = useAppSelector(state => state.company.data!.id);
  const [search, setSearch] = useState('');
  const [options, setOptions] = useState<OptionsType>({
    page: 1,
    first: 40,
    total: 0,
    count: 0,
  });
  const {width} = useResize();

  const {data, loading, error} = useQuery(GET_CERTIFICATE_TEMPLATES, {
    variables: {
      companyId,
      name: search === '' ? undefined : search,
      first: options.first,
      page: options.page,
    },
    onCompleted: res => {
      setOptions(prev => ({
        ...prev,
        total: res.certificateTemplates?.paginatorInfo.total,
        count: res.certificateTemplates?.paginatorInfo.count,
        lastPage: res.certificateTemplates?.paginatorInfo.lastPage,
      }));
    },
  });
  const {edit_certificate_templates} = useEmployeePermission(data);
  const [modalIssue, setModalIssue] = useState(false);
  const [id, setId] = useState<string | null>(null);
  const [selections, setSelections] = useState<number[]>([]);
  const [deleteCertificates] = useMutation(DELETE_CERTIFICATES, {
    refetchQueries: [
      {
        query: GET_CERTIFICATE_TEMPLATES,
        variables: {
          companyId,
          first: options.first,
          page: 1,
        },
      },
    ],
  });

  const certificates = data?.certificateTemplates?.data ?? [];

  return (
    <Layout variant="sidebar">
      <Seo title="Сертификаты" />
      <Wrapper>
        <Flex flex={1} gap={24} direction="column">
          <Head>
            <Text typography="title24">Сертификаты</Text>
            <Text typography="title24" color="textTertiary">
              {data?.certificateTemplates?.paginatorInfo.total ?? 0}
            </Text>
          </Head>
          <Flex
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            style={{
              pointerEvents: 'all',
              margin: '0 24px',
            }}>
            <TextInput
              borderRadius={14}
              width="392px"
              variant="default"
              placeholder="Поиск"
              backgroundColor={colors.bgPrimary}
              value={search}
              onChange={setSearch}
              height={54}
              leftElement={<Search color={colors.textTertiary} />}
            />
            <Button
              size="medium"
              typography="subHead14Medium"
              onClick={() => navigate(`/${username}/create-certificate`)}>
              <Plus />
              Добавить программу
            </Button>
          </Flex>
          {loading ? (
            <CertificatesSkeleton />
          ) : error ? (
            <Error message={error.message} />
          ) : certificates.length === 0 ? (
            <EmptyState />
          ) : (
            <Content style={{margin: '0 24px'}}>
              <Table
                titles={[
                  'Сертификаты',
                  'Клиенты',
                  'Цена',
                  'Баланс',
                  'Срок действия',
                ]}
                widths={Array(5).fill(width / 5)}
                options={options}
                setOptions={setOptions}
                onClick={index => {
                  navigate(
                    `/${username}/certificate/${certificates[index].id}/edit`,
                  );
                }}
                selections={selections}
                onSelectionChange={values => setSelections(values)}
                rows={certificates.map(certificate => [
                  certificate.name,
                  <Text
                    style={{width: 'max-content'}}
                    onClick={e => {
                      setId(certificate.id);
                      setModalIssue(true);
                      e.stopPropagation();
                    }}
                    key={'certificate_customers_' + certificate.id}
                    color="mainPrimary">
                    {certificate.certificates.length} шт
                  </Text>,
                  getPriceFormat(certificate.price),
                  getPriceFormat(certificate.balance),
                  certificate.validity_months
                    ? 'c ' +
                      dayjs(certificate.created_at).format('DD.MM.YYYY') +
                      ' до ' +
                      dayjs(certificate.created_at)
                        .add(certificate.validity_months, 'month')
                        .format('DD.MM.YYYY')
                    : 'не ограничено',
                ])}
              />
            </Content>
          )}
          <ModalIssueCertificate
            id={id!}
            visible={modalIssue}
            onClose={() => setModalIssue(false)}
          />
        </Flex>
        {selections.length > 0 && (
          <FooterAction
            allICount={options.count}
            selectedCount={selections.length}
            editPermission={edit_certificate_templates}
            disabled={selections.length === 0}
            onDelete={() => {
              deleteCertificates({
                variables: {
                  ids:
                    data?.certificateTemplates?.data
                      .filter((_, index) => selections.includes(index))
                      .map(item => item.id) ?? [],
                },
              })
                .then(() => {
                  setSelections([]);
                })
                .catch(e => {
                  showAlert('error', e.message);
                });
            }}
            title={'Удалить сертификаты'}
            text="Вы уверены, что хотите удалить сертификаты?"
          />
        )}
      </Wrapper>
    </Layout>
  );
};
