import React from 'react';
import {weekdays} from 'shared/lib/utils';
import styled from 'styled-components';

const Week = styled.div<{show: boolean}>`
  display: ${({show}) => (show ? 'flex' : 'none')};
  gap: 8px;
  align-items: center;
`;
const WeekDay = styled.button<{active: boolean}>`
  display: flex;
  flex: 1;
  height: 48px;
  background-color: ${({active, theme}) =>
    active ? theme.mainPrimary : 'transparent'};
  border-width: 1px;
  border-style: solid;
  font-family: 'Inter';
  font-size: 12px;
  font-style: normal;
  pointer-events: all;
  font-weight: 500;
  line-height: 18px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border-color: ${({active, theme}) =>
    active ? theme.mainPrimary : theme.borderPrimary};
  color: ${({active, theme}) => (active ? theme.white : theme.textPrimary)};
`;

export const WeekdaySchedule = ({
  selected,
  show,
  onChange,
}: {
  selected: number[];
  show: boolean;
  onChange: (days: number[]) => void;
}) => {
  const onPressDay = (index: number) => {
    const numberDay = index + 1 === 7 ? 0 : index + 1;
    const isInclude = selected.some(day => day === numberDay);
    if (isInclude) {
      onChange(selected.filter(item => item !== numberDay));
      return;
    }
    onChange([...selected, numberDay]);
  };
  return (
    <Week show={show}>
      {weekdays.map((day, index) => (
        <WeekDay
          key={index}
          onClick={() => onPressDay(index)}
          active={selected.some(
            item => (item === 0 && index === 6) || item === index + 1,
          )}>
          {day}
        </WeekDay>
      ))}
    </Week>
  );
};
