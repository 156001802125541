import {ApolloError, useMutation} from '@apollo/client';
import {GET_RULES_SALARIES, SalaryData, salaryType} from 'entities/salary';
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {gql} from 'shared/__generated__';
import {
  UpdateSalaryRule,
  UpdateSalaryRuleService,
} from 'shared/__generated__/graphql';
import {Trash} from 'shared/icons/Trash';
import {useColors} from 'shared/lib/hooks';
import {useAppSelector} from 'shared/store';
import {showAlert} from 'shared/ui/Alert';
import {Button} from 'shared/ui/Button';
import {CloseButton} from 'shared/ui/CloseButton';
import {Flex} from 'shared/ui/Flex';
import {Popup} from 'shared/ui/Popup';
import {Text} from 'shared/ui/Text';

type SelectedServices = {
  id: number;
  services: SalaryData[];
  value: string;
  typeIndex: string;
};
type Input = Omit<UpdateSalaryRule, 'custom_services'>;

const UPDATE_SALARY_RULE = gql(`
  mutation UpdateSalaryRule($input: UpdateSalaryRule!) {
    updateSalaryRule(input: $input) {
      id
      guaranteed_minimum_value
      guaranteed_minimum_type
      salary_type
      salary_value
      service_salary_type
      service_salary_value
      services {
        id
        service_salary_type
        service_salary_value
      }
    }
  }
`);
const DELETE_SALARY_RULE = gql(`
  mutation DeleteSalaryRule($deleteSalaryRuleId: String!) {
    deleteSalaryRule(id: $deleteSalaryRuleId) {
      id
      guaranteed_minimum_value
      guaranteed_minimum_type
      salary_type
      salary_value
      service_salary_type
      service_salary_value
      services {
        id
        service_salary_type
        service_salary_value
      }
    }
  }
`);

export const FooterButtons = ({
  input,
  services,
}: {
  input: Input;
  services: SelectedServices[];
}) => {
  const {id: companyId, username} = useAppSelector(
    state => state.company.data!,
  );
  const colors = useColors();
  const [deleteModal, setDeleteModal] = useState(false);
  const refetchQuery = [
    {query: GET_RULES_SALARIES, variables: {companyId, first: 10, page: 1}},
  ];
  const navigate = useNavigate();
  const [updateSalaryRule, {loading: updateLoading}] = useMutation(
    UPDATE_SALARY_RULE,
    {refetchQueries: refetchQuery},
  );
  const [deleteSalaryRule, {loading: deleteLoading}] = useMutation(
    DELETE_SALARY_RULE,
    {refetchQueries: refetchQuery},
  );
  return (
    <>
      <Popup
        visible={deleteModal}
        style={{width: 440, alignItems: 'initial', padding: '24px'}}
        onClose={() => {
          setDeleteModal(false);
        }}>
        <Flex direction="column" gap={16}>
          <Flex flex={1} justifyContent="space-between" alignItems="center">
            <Text typography="title20" color="textPrimary">
              Удалить правило расчета
            </Text>
            <CloseButton
              onClose={() => {
                setDeleteModal(false);
              }}
            />
          </Flex>
          <Text typography="subHead14Regular">
            Вы уверены, что хотите удалить правило расчета?
          </Text>
          <Flex gap={8}>
            <Button
              size="large"
              variant="outline"
              style={{flex: 1, borderColor: colors.mainPrimary}}
              onClick={() => {
                setDeleteModal(false);
              }}>
              Отмена
            </Button>
            <Button
              size="large"
              variant="outline"
              loading={deleteLoading}
              onClick={() => {
                deleteSalaryRule({variables: {deleteSalaryRuleId: input.id}})
                  .then(() => {
                    navigate(`/${username}/salary`, {
                      state: 'rules',
                      replace: true,
                    });
                    showAlert('success', 'Правило успешно удалено!');
                  })
                  .catch((e: ApolloError) => showAlert('error', e.message));
              }}
              style={{
                flex: 1,
                borderColor: colors.dangerPrimary,
                color: colors.dangerPrimary,
              }}>
              Удалить
            </Button>
          </Flex>
        </Flex>
      </Popup>
      <Flex gap={16}>
        <Button
          size="large"
          variant="outline"
          style={{
            borderColor: colors.dangerPrimary,
            color: colors.dangerPrimary,
            flex: 1,
          }}
          leftIcon={<Trash color={colors.dangerPrimary} />}
          onClick={() => setDeleteModal(true)}>
          Удалить
        </Button>
        <Button
          size="large"
          style={{flex: 1}}
          loading={updateLoading}
          disabled={updateLoading}
          onClick={() => {
            const custom_services: UpdateSalaryRuleService[] = [];
            services.forEach(item => {
              item.services.forEach(service => {
                const custom_service: UpdateSalaryRuleService = {
                  service_id: service.value,
                  service_salary_type: salaryType[+item.typeIndex].value,
                  service_salary_value: +item.value,
                };
                custom_services.push(custom_service);
              });
            });
            const data = {...input, custom_services};
            updateSalaryRule({
              variables: {input: data},
            })
              .then(() => {
                navigate(`/${username}/salary`, {
                  state: 'rules',
                  replace: true,
                });
                showAlert('success', 'Правило успешно обновлено!');
              })
              .catch((e: ApolloError) => showAlert('error', e.message));
          }}>
          Сохранить
        </Button>
      </Flex>
    </>
  );
};
