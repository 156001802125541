import {useQuery} from '@apollo/client';
import React, {useState} from 'react';
import {gql} from 'shared/__generated__';
import {
  App,
  AppCategory,
  Image,
  QueryGetAppCategoriesOrderByColumn,
  SortOrder,
} from 'shared/__generated__/graphql';
import {useAppSelector} from 'shared/store';
import {AppCard} from './ui/AppCard';
import {CatalogHeader} from './ui/Header';
import {ListHorizontal} from './ui/ListHorizontal';
import {Flex} from 'shared/ui/Flex';
import {EmptyState} from './ui/EmptyState';
import {SkeletonCatalog} from './ui/Skeleton';

export type AppType = Omit<
  App,
  | 'sort_num'
  | 'updated_at'
  | 'created_at'
  | 'image'
  | 'darkImage'
  | 'appCategory'
> & {
  image?: Pick<Image, 'url' | 'id'> | null;
  darkImage?: Pick<Image, 'url' | 'id'> | null;
  appCategory: Pick<AppCategory, 'id' | 'title'>;
};

const CATALOG_APPS = gql(`
  query GetAppCategories(
    $name: String
    $companyId: String!
    $orderBy: [QueryGetAppCategoriesOrderByOrderByClause!]
    $orderByApps: [AppCategoryAppsOrderByOrderByClause!]
  ) {
    getAppCategories(orderBy: $orderBy) {
      id
      title
      apps(name: $name, orderBy: $orderByApps) {
        id
        name
        is_recommended
        description
        short_description
        appCategory {
          title
          id
        }
        image {
          id
          url
        }
        is_connectable
        is_connected(company_id: $companyId)
        key
        darkImage {
          id
          url
        }
      }
    }
  }
`);

export const CatalogApps = () => {
  const companyId = useAppSelector(state => state.company.data?.id);
  const [search, setSearch] = useState('');
  const [moderationId, setModerationId] = useState<string>();
  const {data, loading} = useQuery(CATALOG_APPS, {
    variables: {
      companyId: companyId!,
      name: search,
      orderBy: [
        {
          order: SortOrder.Asc,
          column: QueryGetAppCategoriesOrderByColumn.Sort,
        },
      ],
    },
    onCompleted: res => {
      const widgetRes = res?.getAppCategories
        .flatMap(item => item.apps)
        .find(item => item?.key === 'widget');
      widgetRes && setModerationId(widgetRes.id);
    },
  });

  const recommendedApps =
    data?.getAppCategories
      .flatMap(item => item.apps)
      .filter(item => item?.is_recommended) ?? [];
  const appsCatalog = data?.getAppCategories;
  const allAppsLength = data?.getAppCategories.flatMap(item => item.apps)
    .length;

  return (
    <Flex flex={1} direction="column" gap={40} mb={24}>
      <CatalogHeader search={search} setSearch={setSearch} />
      {loading ? (
        <SkeletonCatalog />
      ) : (
        <>
          {!search && (
            <ListHorizontal title="Рекомендованные приложения">
              {recommendedApps.map(item => (
                <AppCard
                  moderationId={moderationId}
                  key={item?.id}
                  app={item}
                  isRecommended={item?.is_recommended}
                />
              ))}
            </ListHorizontal>
          )}
          {allAppsLength ? (
            appsCatalog?.map((catalog, indexCatalog) =>
              catalog.apps?.length ? (
                <ListHorizontal key={indexCatalog} title={catalog.title}>
                  {catalog.apps?.map((app, indexApp) => (
                    <AppCard
                      title={catalog.title}
                      moderationId={moderationId}
                      key={indexApp}
                      app={app}
                      isRecommended={app?.is_recommended}
                    />
                  ))}
                </ListHorizontal>
              ) : null,
            )
          ) : (
            <EmptyState />
          )}
        </>
      )}
    </Flex>
  );
};
