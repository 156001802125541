import React from 'react';
import styled from 'styled-components';
import {Day} from './Day';
import {WorkDay} from '..';

const StyledTd = styled.td`
  height: 152px;
  width: 152px;
  padding-top: 24px;
  padding-left: 8px;
`;

const defaultDayOff = {
  id: '',
  start_time: '',
  end_time: '',
  date: '',
  breakTimes: [],
};

export const DesktopDay = ({
  week,
  workingDates,
  onSelect,
}: {
  week: string[];
  workingDates: WorkDay[];
  onSelect: (day: WorkDay) => void;
}) => {
  return (
    <tr>
      {week.map(day => {
        const findWorkDay = workingDates.find(workDay => workDay.date === day);
        const workTime = findWorkDay
          ? findWorkDay.start_time + ' - ' + findWorkDay.end_time
          : '';
        return (
          <StyledTd key={day}>
            <Day
              onSelect={() =>
                onSelect(findWorkDay ?? {...defaultDayOff, date: day})
              }
              workTime={workTime}
              active={!!findWorkDay}
            />
          </StyledTd>
        );
      })}
    </tr>
  );
};
