import React from 'react';
import {CircleClose} from 'shared/icons/CircleClose';
import styled from 'styled-components';
import {getDataWorkTime} from 'shared/lib/utils';
import {SelectInput} from 'shared/ui/SelectInput';

const ChooseTime = styled.div<{show: boolean}>`
  display: ${({show}) => (show ? 'flex' : 'none')};
  height: 64px;
  transition: height 300ms;
  flex-direction: row;
  gap: 8px;
`;

const CloseBreakTime = styled.button`
  align-items: center;
  border: none;
  background: none;
`;

const times = getDataWorkTime();

export const ScheduleWorkTime = ({
  value,
  onChange,
  onDelete,
  WorkTimes = times,
  show = true,
  labelStart = 'Начало',
  labelEnd = 'Конец',
  variant,
}: {
  value: {start: string | null; end: string | null};
  onChange: (time: string, location: 'start' | 'end') => void;
  onDelete?: () => void;
  WorkTimes?: {label: string; value: string}[];
  show?: boolean;
  labelStart?: string;
  labelEnd?: string;
  variant?: 'default';
}) => (
  <ChooseTime show={show}>
    <SelectInput
      variant={variant}
      style={{borderRadius: 14}}
      value={value.start}
      onChange={time => {
        if (time !== null) {
          onChange(time, 'start');
        }
      }}
      mask="99:99"
      label={labelStart}
      data={WorkTimes}
    />
    <SelectInput
      variant={variant}
      style={{borderRadius: 14}}
      value={value.end}
      onChange={time => {
        if (time !== null) {
          onChange(time, 'end');
        }
      }}
      mask="99:99"
      label={labelEnd}
      data={WorkTimes}
    />
    {onDelete && (
      <CloseBreakTime onClick={() => onDelete()}>
        <CircleClose />
      </CloseBreakTime>
    )}
  </ChooseTime>
);
