import React from 'react';
import {Outlet} from 'react-router-dom';
import {Text} from 'shared/ui/Text';
import {Avatar} from 'shared/ui/Avatar';
import {ProfileContainer} from './ui/ProfileContainer';
import {Head} from 'shared/ui/Head';
import {Menu} from 'shared/ui/Menu';
import styled from 'styled-components';
import {device} from 'shared/device';
import {Phone} from 'shared/icons/Phone';
import {Category} from 'shared/icons/Category';
import {Case} from 'shared/icons/Case';
import {useQuery} from '@apollo/client';
import {gql} from 'shared/__generated__';
import {useAppSelector} from 'shared/store';
import {Flex} from 'shared/ui/Flex';
import {NavigateBack} from 'shared/ui/NavigateBack';

const COMPANY_INFO = gql(`
query CompanyInfo($companyId: String) {
  company(id: $companyId) {
    id
    type {
      id
      title
    }
    avatar {
      id
      url
    }
    name
  }
}
`);
const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 72px;
  grid-column-start: 1;
  grid-column-end: 4;
  @media (${device.mobile}) {
    margin-top: 56px;
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const CompanyRoot = () => {
  const companyId = useAppSelector(state => state.company.data!.id);
  const username = useAppSelector(state => state.company.data?.username);
  const {data, loading} = useQuery(COMPANY_INFO, {
    variables: {companyId},
  });
  const company = data?.company;
  return (
    <ProfileContainer>
      <Nav>
        <Menu
          skeletonItemNumber={3}
          loading={loading}
          data={[
            {
              icon: <Case />,
              label: 'Об организации',
              to: `/${username}/company`,
            },
            {
              icon: <Phone />,
              label: 'Контакты',
              to: `/${username}/company/contact`,
            },
            {
              icon: <Category />,
              label: 'Дополнительно',
              to: `/${username}/company/advantage`,
            },
          ]}
          avatar={
            <Avatar size={64} url={company?.avatar?.url} variant="company" />
          }
          description={company?.type.title ?? ''}
          title={company?.name ?? ' '}
        />
      </Nav>
      <Flex
        direction="column"
        style={{
          gridColumnStart: 4,
          gridColumnEnd: 10,
        }}>
        <Head
          style={{
            gridColumnStart: 4,
            gridColumnEnd: 10,
          }}>
          <NavigateBack />
          <Text
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            typography="title24">
            Редактирование организации
          </Text>
        </Head>
        <Outlet />
      </Flex>
    </ProfileContainer>
  );
};
