import React, {useState} from 'react';
import {styled} from 'styled-components';
import {Text} from 'shared/ui/Text';
import {gql} from 'shared/__generated__';
import {useAppSelector} from 'shared/store';
import {useQuery} from '@apollo/client';
import {
  PaymentMethodEnum,
  QueryGetStatisticsFinancePaymentsOrderColumn,
  SortOrder,
  StatisticsDates,
  StatisticsFinanceSum,
} from 'shared/__generated__/graphql';
import {Flex} from 'shared/ui/Flex';
import {
  FilterResetButton,
  FilterStatisticsEmployee,
  FilterStatisticsFinanceSum,
  FilterStatisticsPaymentMethod,
  FilterStatisticsPeriod,
} from 'entities/statistics';
import {Content} from 'shared/ui/Content';
import {Pie} from './ui/Pie';
import {useColors} from 'shared/lib/hooks';
import {Table, TableSortType} from 'shared/ui/Table';
import {
  PaymentMethodsText,
  getNumberFormat,
  getPriceFormat,
} from 'shared/lib/utils';
import {PageHeader} from 'shared/ui/PageHeader';
import {Grow} from 'shared/ui/Grow';
import {EmptyAnalytics} from 'shared/illustration/EmptyAnalytics';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
`;
const PayCard = styled.div`
  display: flex;
  padding: 32px 24px;
  align-items: center;
  gap: 24px;
  flex: 1 0 0;
  border-radius: 24px;
  border: 1px solid ${({theme}) => theme.borderPrimary};
  background: ${({theme}) => theme.fillPrimary};
`;

const Container = styled(Grow)`
  gap: 16px;
  min-height: 400px;
  @media ${`(min-height: 736px)`} {
    margin-top: calc(((100dvh - 400px) / 2) - 168px);
  }
`;

const GET_STATISTICS_PAYMENT_FINANCE = gql(`
  query GetStatisticsFinance(
    $companyId: String!
    $date: StatisticsDates!
    $employeeId: String
    $method: PaymentMethodEnum
    $sum: StatisticsFinanceSum
    $paymentsOrder: [QueryGetStatisticsFinancePaymentsOrderOrderByClause!]
  ) {
    getStatisticsFinance(
      company_id: $companyId
      date: $date
      employee_id: $employeeId
      method: $method
      sum: $sum
      payments_order: $paymentsOrder
    ) {
      sales
      discounts
      certificates_used
      certificates_not_used
      certificates
      payments {
        summary
        transactions
        type
      }
    }
  }
`);
const titles = ['Тип платежа', 'Кол-во транзакций', 'Сумма платежей'];
const sortValues: QueryGetStatisticsFinancePaymentsOrderColumn[] = [
  QueryGetStatisticsFinancePaymentsOrderColumn.Type,
  QueryGetStatisticsFinancePaymentsOrderColumn.Transactions,
  QueryGetStatisticsFinancePaymentsOrderColumn.Summary,
];

export const FinancePaymentSummary = () => {
  const colors = useColors();
  const companyId = useAppSelector(state => state.company.data!.id);
  const [employeeId, setEmployeeId] = useState('');
  const [period, setPeriod] = useState<StatisticsDates>(
    StatisticsDates.Alltime,
  );
  const [sort, setSort] = useState<TableSortType>({
    index: 0,
    order: SortOrder.Desc,
  });
  const [sum, setSum] = useState<StatisticsFinanceSum | undefined>();
  const [paymentMethod, setPaymentMethod] = useState<
    PaymentMethodEnum | undefined
  >();
  const {data} = useQuery(GET_STATISTICS_PAYMENT_FINANCE, {
    variables: {
      companyId,
      employeeId,
      method: paymentMethod,
      sum: sum,
      date: period,
      paymentsOrder: [
        {
          order: sort.order,
          column: sortValues[sort.index],
        },
      ],
    },
  });
  const finance = data?.getStatisticsFinance;
  const sales = finance?.sales ?? 0;
  const discounts = finance?.discounts ?? 0;
  const cetificateSales = finance?.certificates;
  const cetificateUsed = finance?.certificates_used;
  const cetificateNotUsed = finance?.certificates_not_used;
  const payments = finance?.payments ?? [];
  const empty =
    !sales &&
    !discounts &&
    !cetificateSales &&
    !cetificateUsed &&
    !cetificateNotUsed;
  const salesPie = [
    {
      name: 'Общая сумма',
      value: sales,
      color: colors.mainPrimary,
    },
    {
      name: 'Сумма скидок',
      value: discounts,
      color: colors.successPrimary,
    },
  ];
  const certificatePie = [
    {
      name: 'Проданные',
      value: cetificateSales ?? 0,
      color: colors.mainPrimary,
    },
    {
      name: 'Использованные',
      value: cetificateUsed ?? 0,
      color: colors.successPrimary,
    },
    {
      name: 'Неиспользованные',
      value: cetificateNotUsed ?? 0,
      color: colors.goldPrimary,
    },
  ];
  const paymentsPie = [
    {
      name: 'Безналичный',
      value:
        payments
          ?.filter(item => item?.type === PaymentMethodEnum.Card)
          .map(item => item?.summary ?? 0)
          .reduce((a, b) => a + b, 0) ?? 0,
      color: colors.mainPrimary,
    },
    {
      name: 'Наличные',
      value:
        payments
          ?.filter(item => item?.type === PaymentMethodEnum.Cash)
          .map(item => item?.summary ?? 0)
          .reduce((a, b) => a + b, 0) ?? 0,
      color: colors.successPrimary,
    },
    {
      name: 'Бонусы',
      value:
        payments
          ?.filter(item => item?.type === PaymentMethodEnum.BonusCard)
          .map(item => item?.summary ?? 0)
          .reduce((a, b) => a + b, 0) ?? 0,
      color: colors.goldPrimary,
    },
    {
      name: 'Сертификаты',
      value:
        payments
          ?.filter(item => item?.type === PaymentMethodEnum.Certificate)
          .map(item => item?.summary ?? 0)
          .reduce((a, b) => a + b, 0) ?? 0,
      color: colors.mainLight,
    },
  ];
  return (
    <Wrapper>
      <PageHeader back title="Сводка по финансам и платежам" />
      <Flex direction="row" gap={8}>
        <FilterStatisticsPeriod {...{period, setPeriod}} />
        <FilterStatisticsEmployee {...{employeeId, setEmployeeId}} />
        <FilterStatisticsPaymentMethod {...{paymentMethod, setPaymentMethod}} />
        <FilterStatisticsFinanceSum {...{sum, setSum}} />
        {sum ||
        paymentMethod ||
        employeeId ||
        period !== StatisticsDates.Alltime ? (
          <FilterResetButton
            onClick={() => {
              setSum(undefined);
              setPaymentMethod(undefined);
              setEmployeeId('');
              setPeriod(StatisticsDates.Alltime);
            }}
          />
        ) : (
          <></>
        )}
      </Flex>

      {empty ? (
        <Container>
          <EmptyAnalytics size={300} />
          <Text typography="title20" align="center">
            Ничего не найдено
          </Text>
          <Text color="textTertiary" typography="text18" align="center">
            К сожалению по вашему запросу ничего не найдено
          </Text>
        </Container>
      ) : (
        <Flex flex={1} direction="row" gap={24}>
          <Flex direction="column" flex={1} gap={24}>
            <Content gap="40px" style={{height: '100%'}}>
              <Text typography="title20">Продажи</Text>
              <Flex gap={105}>
                <Pie data={salesPie} />
                <Flex direction="column" gap={40}>
                  {salesPie.map(item => (
                    <Flex key={item.name} direction="column" gap={16}>
                      <Flex gap={8} alignItems="center">
                        <svg width="16" height="16">
                          <circle cx={8} cy={8} r={6} fill={item.color} />
                        </svg>
                        <Text
                          typography="subHead14Regular"
                          color="textTertiary">
                          {item.name}
                        </Text>
                      </Flex>
                      <Text
                        style={{
                          fontSize: 44,
                          fontWeight: 600,
                          lineHeight: '100%',
                        }}>
                        {getPriceFormat(item.value)}
                      </Text>
                    </Flex>
                  ))}
                </Flex>
              </Flex>
            </Content>
            <Content gap="40px" style={{height: '100%'}}>
              <Text typography="title20">Сертификаты</Text>
              <Flex gap={105}>
                <Pie data={certificatePie} />
                <Flex direction="column" gap={40}>
                  {certificatePie.map(item => (
                    <Flex key={item.name} direction="column" gap={16}>
                      <Flex gap={8} alignItems="center">
                        <svg width="16" height="16">
                          <circle cx={8} cy={8} r={6} fill={item.color} />
                        </svg>
                        <Text
                          typography="subHead14Regular"
                          color="textTertiary">
                          {item.name}
                        </Text>
                      </Flex>
                      <Text
                        style={{
                          fontSize: 44,
                          fontWeight: 600,
                          lineHeight: '100%',
                        }}>
                        {getPriceFormat(item.value)}
                      </Text>
                    </Flex>
                  ))}
                </Flex>
              </Flex>
            </Content>
          </Flex>
          <Flex flex={1}>
            <Content style={{width: '100%'}}>
              <Text mb={16} typography="title20">
                Платежи
              </Text>
              <Flex gap={105} mb={32}>
                <Pie data={paymentsPie} />
                <Flex
                  direction="row"
                  gap={40}
                  style={{flexWrap: 'wrap', minWidth: 200, maxWidth: 500}}>
                  {paymentsPie.map(item => (
                    <Flex
                      style={{width: '40%'}}
                      key={item.name}
                      direction="column"
                      gap={16}>
                      <Flex gap={8} alignItems="center">
                        <svg width="16" height="16">
                          <circle cx={8} cy={8} r={6} fill={item.color} />
                        </svg>
                        <Text
                          typography="subHead14Regular"
                          color="textTertiary">
                          {item.name}
                        </Text>
                      </Flex>
                      <Text
                        style={{
                          fontSize: 44,
                          fontWeight: 600,
                          lineHeight: '100%',
                          whiteSpace: 'nowrap',
                        }}>
                        {getPriceFormat(item.value)}
                      </Text>
                    </Flex>
                  ))}
                </Flex>
              </Flex>
              <Flex gap={16}>
                <PayCard>
                  <Text
                    color="mainPrimary"
                    style={{
                      fontSize: 44,
                      fontWeight: 600,
                      lineHeight: '100%',
                      whiteSpace: 'nowrap',
                    }}>
                    {getPriceFormat(
                      payments
                        ?.map(item => item?.summary ?? 0)
                        .reduce((a, b) => a + b, 0),
                    )}
                  </Text>
                  <Text typography="subHead14Regular">Сумма платежей</Text>
                </PayCard>
                <PayCard>
                  <Text
                    color="mainPrimary"
                    style={{
                      fontSize: 44,
                      fontWeight: 600,
                      lineHeight: '100%',
                      whiteSpace: 'nowrap',
                    }}>
                    {getNumberFormat(
                      payments
                        ?.map(item => item?.transactions ?? 0)
                        .reduce((a, b) => a + b, 0),
                    )}
                  </Text>
                  <Text typography="subHead14Regular">
                    Количество транзакций, шт.
                  </Text>
                </PayCard>
              </Flex>
              <Content
                padding="16px"
                style={{backgroundColor: colors.fillPrimary}}>
                <Table
                  styleTh={{backgroundColor: colors.fillPrimary}}
                  titles={titles}
                  sort={sort}
                  results
                  onClickTitle={(index, order) => setSort({index, order})}
                  alignRightColumn={[2]}
                  columnNoWrap={[2]}
                  rows={payments.map(item => [
                    PaymentMethodsText[item?.type ?? PaymentMethodEnum.Cash] ??
                      0,
                    getNumberFormat(item?.transactions),
                    getPriceFormat(item?.summary),
                  ])}
                />
              </Content>
            </Content>
          </Flex>
        </Flex>
      )}
    </Wrapper>
  );
};
