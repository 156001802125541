import React, {useState, useRef, useEffect} from 'react';
import styled from 'styled-components';
import {Text} from 'shared/ui/Text';
import {Flex} from 'shared/ui/Flex';
import {Popup} from 'shared/ui/Popup';
import {Head} from 'shared/ui/Head';
import {TextInput} from 'shared/ui/TextInput';
import {gql} from 'shared/__generated__';
import {useLazyQuery} from '@apollo/client';
import Check from 'shared/icons/Check';
import {useColors, useDebounce} from 'shared/lib/hooks';
import InfiniteScroll from 'react-infinite-scroll-component';
import {useAppSelector} from 'shared/store';
import {EmptyCity} from 'shared/illustration/EmptyCity';
import {Loader} from 'shared/icons/Loader';
import {CloseButton} from 'shared/ui/CloseButton';
import {Search} from 'shared/icons/Search';
import {ArrowDown2v} from 'shared/icons/ArrowDown2v';

const CITIES = gql(`
  query Cities($isActive: Boolean, $page: Int, $first: Int!, $name: String) {
    cities(is_active: $isActive, page: $page, first: $first, name: $name) {
      data {
        id
        name
        timezone
      }
      paginatorInfo {
        hasMorePages
        lastPage
        currentPage
        total
      }
    }
  }
`);

type SelectProps = {
  label?: string;
  onChange?: (value: string) => void;
  value?: string;
  backgroundColor?: string;
  disabled?: boolean;
  error?: string | null;
  required?: boolean;
  style?: React.CSSProperties;
};

const SelectWrapper = styled.div`
  position: relative;
  pointer-events: all;
  width: 100%;
  padding: 0;
  margin: 0;
`;
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
`;
const StyledLabel = styled(Text)<{
  required?: boolean;
  isOpen?: boolean;
  selectedCity?: string;
}>`
  &::after {
    content: '*';
    color: ${props => props.theme.dangerPrimary};
    visibility: ${props => (props.required ? 'visible' : 'hidden')};
    font-size: ${props => (props.isOpen ? '12px' : '16px')};
    transition:
      transform 150ms ease,
      color 150ms ease,
      font-size 150ms ease;
  }
  transition: ${props => (!props.selectedCity ? 'all 0.3s ease' : 'none')};
  transform: ${props =>
    props.isOpen && !props.selectedCity ? 'translateY(-14px)' : 'none'};
`;

const StyledButton = styled.button<{
  hasLabel: boolean;
  isOpen?: boolean;
  background?: string;
  error?: string | null;
}>`
  display: flex;
  height: 64px;
  border-radius: 18px;
  width: 100%;
  pointer-events: all;
  align-items: center;
  padding: 5px 16px 5px 16px;
  cursor: pointer;
  background-color: ${({background, theme}) => background ?? theme.fillPrimary};
  border: 1px solid
    ${props =>
      props.error ? props.theme.dangerPrimary : props.theme.borderPrimary};
  &:focus {
    border: 1px solid
      ${({error, theme, isOpen}) =>
        error
          ? theme.dangerPrimary
          : isOpen
          ? theme.mainPrimary
          : theme.borderPrimary};
    background-color: ${({background, isOpen, theme}) =>
      isOpen ? theme.mainLight : background};
    outline: none;
    gap: 8px;
  }
`;

const PopupWrapper = styled.div`
  flex-direction: column;
  display: flex;
  width: 100%;
  flex: 1;
`;
export const CitiesSelect = ({
  label,
  onChange,
  value: selected,
  backgroundColor,
  disabled,
  error,
  required,
  style,
}: SelectProps) => {
  const companyId = useAppSelector(state => state.company.data?.id);
  const colors = useColors();
  const [cityQuery, {data: citydata, loading, fetchMore, refetch, called}] =
    useLazyQuery(CITIES, {
      variables: {first: 25},
      notifyOnNetworkStatusChange: true,
    });
  React.useEffect(() => {
    if (!called) {
      cityQuery();
    }
  }, [called, cityQuery]);
  const dataLenght = citydata?.cities?.data.length ?? 0;
  const fetchMoreData = () => {
    const currentPage = citydata?.cities?.paginatorInfo.currentPage || 1;
    if (citydata?.cities?.paginatorInfo.hasMorePages && citydata && !loading) {
      fetchMore({
        variables: {
          companyId,
          page: currentPage + 1,
          first: 25,
          isActive: true,
          name: searchText,
        },
        updateQuery: (prev, {fetchMoreResult}) => {
          if (!fetchMoreResult) return prev;
          const prevCitiesData = prev.cities?.data || [];
          const newCitiesData = fetchMoreResult.cities?.data || [];

          return Object.assign({}, prev, {
            cities: {
              ...fetchMoreResult.cities,
              data: [...prevCitiesData, ...newCitiesData],
            },
          });
        },
      });
    }
  };
  const setSearchDebounce = useDebounce((named: string) => {
    const input = {
      page: 1,
      first: 25,
      isActive: true,
      name: named,
      companyId,
    };
    refetch(input);
  }, 400);

  const [isOpen, setIsOpen] = useState(false);

  const [selectedCity, setSelectedCity] = useState('');
  const [searchText, setSearchText] = React.useState<string>('');

  useEffect(() => {
    const city = citydata?.cities?.data.find(city => city.id === selected);
    if (city) {
      setSelectedCity(city.name);
    }
  }, [selected, citydata]);

  const isLabelShrink = !!selectedCity || isOpen;
  const selectRef = useRef<HTMLDivElement>(null);
  const handleCitySelect = (cityId: string, cityName: string) => {
    setSelectedCity(cityName);
    setIsOpen(false);
    onChange && onChange(cityId);
  };
  const highlightMatch = (text: string, searchText: string) => {
    if (!searchText) return text;

    const regex = new RegExp(`(${searchText})`, 'gi');
    const parts = text.split(regex);

    return parts.map((part, index) =>
      regex.test(part) ? (
        <span key={index} style={{color: colors.mainPrimary}}>
          {part}
        </span>
      ) : (
        part
      ),
    );
  };
  return (
    <>
      <SelectWrapper ref={selectRef} style={style}>
        <StyledButton
          error={error}
          disabled={disabled}
          background={backgroundColor}
          onClick={() => setIsOpen(true)}
          hasLabel={!!label}
          isOpen={isOpen}>
          <Flex flex={1} gap={label && selected ? 4 : 0} direction="column">
            <StyledLabel
              isOpen={isLabelShrink}
              required={required}
              selectedCity={selectedCity}
              typography={isLabelShrink ? 'footNote12Regular' : 'text16Regular'}
              color={'textTertiary'}>
              {'Город'}
            </StyledLabel>

            <Text
              typography="text16Regular"
              color={disabled ? 'textTertiary' : 'textPrimary'}
              style={{padding: 0}}>
              {selectedCity}
            </Text>
          </Flex>
          <ArrowDown2v />
        </StyledButton>
        {error && (
          <Text mt={8} color="dangerPrimary" typography="footNote12Regular">
            {error}
          </Text>
        )}
      </SelectWrapper>

      <Popup
        visible={isOpen}
        style={{maxHeight: 600, width: 880}}
        onClose={() => {
          setIsOpen(false);
        }}>
        <PopupWrapper>
          <Head>
            <Flex flex={1} direction="row" justifyContent="space-between">
              <Text typography="title20">Выберите город</Text>
              <CloseButton
                onClose={() => {
                  setIsOpen(false);
                }}
              />
            </Flex>
          </Head>
          <Flex flex={1} direction="column" gap={32}>
            <TextInput
              leftElement={<Search color={colors.textTertiary} />}
              width="413px"
              variant="default"
              value={searchText}
              onChange={name => {
                setSearchText(name);
                setSearchDebounce(name);
              }}
              placeholder="Поиск"
            />
            <Flex
              direction="column"
              id="scrollableElement"
              style={{overflowY: 'auto'}}>
              <InfiniteScroll
                dataLength={dataLenght}
                next={fetchMoreData}
                scrollableTarget="scrollableElement"
                hasMore={citydata?.cities?.paginatorInfo.hasMorePages || false}
                loader={
                  loading && (
                    <Flex justifyContent="center" alignItems="center">
                      <Text typography="text16Regular">Загрузка...</Text>
                    </Flex>
                  )
                }>
                {dataLenght > 0 ? (
                  <GridContainer>
                    {citydata?.cities?.data.map(city => (
                      <Flex direction="row" gap={16} key={city.id}>
                        <Text
                          typography="text16Regular"
                          color={
                            selectedCity === city.name
                              ? 'mainPrimary'
                              : 'textPrimary'
                          }
                          onClick={() => handleCitySelect(city.id, city.name)}>
                          {highlightMatch(city.name, searchText)}
                        </Text>
                        {selectedCity === city.name && (
                          <Check color={colors.mainPrimary} />
                        )}
                      </Flex>
                    ))}
                  </GridContainer>
                ) : (
                  <Flex
                    style={{
                      overflow: 'hidden',
                      height: loading ? 340 : undefined,
                    }}
                    direction="column"
                    justifyContent="center"
                    alignItems="center">
                    {searchText.length && !loading ? (
                      <>
                        <EmptyCity size="300px" />
                        <Text typography="text16Regular" color="textTertiary">
                          Ваш город не найден. Проверьте данные и попробуйте еще
                          раз
                        </Text>
                      </>
                    ) : (
                      <Loader size={40} />
                    )}
                  </Flex>
                )}
              </InfiniteScroll>
            </Flex>
          </Flex>
        </PopupWrapper>
      </Popup>
    </>
  );
};
