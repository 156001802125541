import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Flex} from './Flex';
import {IconButton} from './IconButton';
import {ArrowRight2v} from 'shared/icons/ArrowRight2v';
import Close from 'shared/icons/Close';
import {useColors} from 'shared/lib/hooks';

const Wrapper = styled.div`
  position: fixed;
  z-index: 1000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: all;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  background-color: #0101037a;
  padding: 64px 24px 24px 24px;
`;

const ImageMain = styled.img`
  max-height: calc(100vh - 228px);
  border-radius: 14px;
  max-width: 100%;
  object-fit: contain;
`;

const DivIconImitation = styled.div`
  height: 56px;
  width: 56px;
`;

const ImageBottom = styled.img<{active?: boolean}>`
  width: 100px;
  height: 132px;
  border-radius: 14px;
  border: 1px solid
    ${({theme, active}) => (active ? theme.mainPrimary : 'transparent')};
  object-fit: cover;
  background-color: ${({theme}) => theme.bgPrimary};
  cursor: pointer;
`;

const BottomDiv = styled.div<{index: number; length: number}>`
  display: flex;
  flex-direction: row;
  gap: 16px;
  transform: translateX(
    ${({index, length}) =>
      100 * (length - 1 - index) - length * 24 - index * 24}px
  );
  transition: transform 0.2s;
`;

type Props = {
  visible: boolean;
  onClose: () => void;
  images: string[];
  index?: number;
  radius?: number;
};

export const ImageView = (props: Props) => {
  const {visible, onClose, images, index = 0, radius} = props;
  const [indexImage, setIndexImage] = useState(index);
  const colors = useColors();

  useEffect(() => {
    setIndexImage(index);
  }, [index]);

  if (!visible) {
    return null;
  }
  return (
    <Wrapper>
      <Flex
        flex={1}
        style={{width: '100%'}}
        gap={24}
        direction="row"
        justifyContent="space-between">
        <Flex
          direction="column"
          justifyContent="space-between"
          alignItems="center">
          <DivIconImitation />
          <IconButton
            rotate={180}
            size={56}
            style={{borderRadius: 28}}
            onClick={() => {
              if (indexImage > 0) {
                setIndexImage(state => state - 1);
              }
            }}>
            <ArrowRight2v
              color={
                indexImage === 0 ? colors.textTertiary : colors.mainPrimary
              }
            />
          </IconButton>
          <DivIconImitation />
        </Flex>
        <Flex alignItems="center" justifyContent="center" flex={1}>
          <ImageMain style={{borderRadius: radius}} src={images[indexImage]} />
        </Flex>
        <Flex
          direction="column"
          justifyContent="space-between"
          alignItems="center">
          <IconButton
            onClick={() => onClose()}
            type="modal"
            size={56}
            style={{
              borderRadius: 28,
              alignSelf: 'flex-end',
            }}>
            <Close />
          </IconButton>
          <IconButton
            size={56}
            style={{borderRadius: 28}}
            onClick={() => {
              if (indexImage < images.length - 1) {
                setIndexImage(state => state + 1);
              }
            }}>
            <ArrowRight2v
              color={
                indexImage === images.length - 1
                  ? colors.textTertiary
                  : colors.mainPrimary
              }
            />
          </IconButton>
          <DivIconImitation />
        </Flex>
      </Flex>
      <BottomDiv index={indexImage} length={images.length}>
        {images.map((item, index2) => (
          <ImageBottom
            onClick={() => setIndexImage(index2)}
            key={'image_' + item}
            active={index2 === indexImage}
            src={item}
          />
        ))}
      </BottomDiv>
    </Wrapper>
  );
};
