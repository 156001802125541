import React, {RefObject, createRef, useEffect, useState} from 'react';
import styled from 'styled-components';

const Wrapper = styled.div<{adaptive?: boolean}>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: ${({adaptive}) => (adaptive ? 44 : 50)}px;
  border-radius: 12px;
  gap: 4px;
  padding: 4px;
  background-color: ${({theme}) => theme.fillPrimary};
`;

const Tab = styled.button<{active?: boolean; adaptive?: boolean}>`
  display: flex;
  height: ${({adaptive}) => (adaptive ? 36 : 42)}px;
  width: 100%;
  padding: 0px ${({adaptive}) => (adaptive ? 32 : 40)}px;
  background-color: transparent;
  position: relative;
  align-items: center;
  justify-content: center;
  font-family: 'Inter';
  font-size: ${({adaptive}) => (adaptive ? 12 : 16)}px;
  font-weight: ${({adaptive}) => (adaptive ? 500 : 400)};
  color: ${({active, theme}) =>
    active ? theme.mainPrimary : theme.textPrimary};
  border: none;
  font-style: 'normal';
  line-height: ${({adaptive}) => (adaptive ? 18 : 24)}px;
  gap: 8px;
  pointer-events: all;
  border-radius: 12px;
  transition: 0.3s;
  cursor: pointer;
`;

const ActiveTab = styled.div<{
  width: number;
  left: number;
  adaptive?: boolean;
}>`
  display: flex;
  position: absolute;
  border-radius: 12px;
  pointer-events: all;
  cursor: pointer;
  left: ${({left}) => left}px;
  background-color: ${({theme}) => theme.bgPrimary};
  height: ${({adaptive}) => (adaptive ? 36 : 42)}px;
  width: ${({width}) => width}px;
  transition: all 0.24s ease;
`;

type Props<T> = {
  data: {
    label: string;
    value: T;
  }[];
  value: T;
  adaptive?: boolean;
  style?: React.CSSProperties;
  onClick?: (value: T) => void;
};

const getLeftPosition = (
  refs: RefObject<HTMLButtonElement>[],
  activeIndex: number,
) => {
  let sum = 0;
  for (let i = 0; i < activeIndex; i++) {
    sum += refs[i].current?.offsetWidth ?? 0;
  }
  sum += 4 * activeIndex + 4;
  return sum;
};

export const SegmentControl = <T,>(props: Props<T>) => {
  const {data, style, adaptive, onClick, value} = props;
  const newRefs: RefObject<HTMLButtonElement>[] = data
    .map(item => item.label)
    .map(() => createRef<HTMLButtonElement>());
  const [activePosition, setActivePosition] = useState({
    width: 0,
    left: 4,
  });
  const activeIndex = data.map(item => item.value).indexOf(value);

  useEffect(() => {
    const width = newRefs[activeIndex].current?.offsetWidth ?? 51;
    const left = getLeftPosition(newRefs, activeIndex);
    setActivePosition({width, left});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex]);

  return (
    <Wrapper {...{style, adaptive}}>
      <ActiveTab
        width={activePosition.width}
        left={activePosition.left}
        adaptive={adaptive}
      />
      {data.map(({label, value: dataValue}, index) => (
        <Tab
          ref={newRefs[index]}
          adaptive={adaptive}
          style={style}
          key={`status_${index}`}
          active={dataValue === value}
          onClick={() => {
            onClick && onClick(dataValue);
          }}>
          {label}
        </Tab>
      ))}
    </Wrapper>
  );
};
