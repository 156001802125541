import React from 'react';
export const CircleArrowUp = ({
  size = 24,
  color = '#6765F8',
}: {
  size?: number;
  color?: string;
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.46967 14.4697C8.17678 14.7626 8.17678 15.2374 8.46967 15.5303C8.76256 15.8232 9.23744 15.8232 9.53033 15.5303L8.46967 14.4697ZM15 9H15.75C15.75 8.58579 15.4142 8.25 15 8.25V9ZM10.5 8.25C10.0858 8.25 9.75 8.58579 9.75 9C9.75 9.41421 10.0858 9.75 10.5 9.75V8.25ZM14.25 13.5C14.25 13.9142 14.5858 14.25 15 14.25C15.4142 14.25 15.75 13.9142 15.75 13.5H14.25ZM21.25 12C21.25 17.1086 17.1086 21.25 12 21.25V22.75C17.9371 22.75 22.75 17.9371 22.75 12H21.25ZM12 21.25C6.89137 21.25 2.75 17.1086 2.75 12H1.25C1.25 17.9371 6.06294 22.75 12 22.75V21.25ZM2.75 12C2.75 6.89137 6.89137 2.75 12 2.75V1.25C6.06294 1.25 1.25 6.06294 1.25 12H2.75ZM12 2.75C17.1086 2.75 21.25 6.89137 21.25 12H22.75C22.75 6.06294 17.9371 1.25 12 1.25V2.75ZM9.53033 15.5303L15.5303 9.53033L14.4697 8.46967L8.46967 14.4697L9.53033 15.5303ZM15 8.25H10.5V9.75H15V8.25ZM14.25 9V13.5H15.75V9H14.25Z"
        fill={color}
      />
    </svg>
  );
};
