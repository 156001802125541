import React from 'react';
import styled from 'styled-components';
import {Text} from 'shared/ui/Text';
import {Copy} from 'shared/icons/Copy';
import {showAlert} from 'shared/ui/Alert';

const CopyContainer = styled.div`
  display: flex;
  height: 64px;
  padding: 19px 16px;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 18px;
  border: 1px solid ${({theme}) => theme.borderPrimary};
  background-color: ${({theme}) => theme.fillPrimary};
`;
const CoptyButton = styled.button`
  padding: 0;
  border: none;
  background: none;
  pointer-events: all;
  cursor: pointer;
`;

export const CopyLink = ({link}: {link?: string | null}) => {
  return (
    <CopyContainer
      onClick={() => {
        if (link) window.open('https://' + link, '_blank');
      }}>
      <Text style={{flex: 1}} typography="text16Regular">
        {link}
      </Text>
      <CoptyButton
        onClick={() => {
          link &&
            navigator.clipboard.writeText(link).then(() => {
              showAlert('success', 'Ссылка скорирован');
            });
        }}>
        <Copy />
      </CoptyButton>
    </CopyContainer>
  );
};
