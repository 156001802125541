import React, {useState} from 'react';
import {styled} from 'styled-components';
import {OptionsType, Table, TableSortType} from 'shared/ui/Table';
import {gql} from 'shared/__generated__';
import {useAppSelector} from 'shared/store';
import {useQuery} from '@apollo/client';
import {
  CustomerGender,
  CustomerStatus,
  QueryGetStatisticsCustomerOrderByColumn,
  SortOrder,
  StatisticsDates,
} from 'shared/__generated__/graphql';
import {Flex} from 'shared/ui/Flex';
import {
  FilterEmptyState,
  FilterResetButton,
  FilterSearch,
  FilterStatisticsCustomerStatus,
  FilterStatisticsEmployee,
  FilterStatisticsGender,
  FilterStatisticsPeriod,
} from 'entities/statistics';
import dayjs from 'dayjs';
import {
  Gender,
  Status,
  getNumberFormat,
  getPriceFormat,
} from 'shared/lib/utils';
import {Content} from 'shared/ui/Content';
import {PageHeader} from 'shared/ui/PageHeader';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 24px;
  gap: 24px;
`;

const GET_STATISTICS_CUSTOMERS = gql(`
  query GetStatisticsCustomer(
    $companyId: String!
    $date: StatisticsDates!
    $first: Int!
    $page: Int
    $employeeId: String
    $gender: CustomerGender
    $status: CustomerStatus
    $query: String
    $orderBy: [QueryGetStatisticsCustomerOrderByOrderByClause!]
  ) {
    getStatisticsCustomer(
      company_id: $companyId
      date: $date
      first: $first
      page: $page
      employee_id: $employeeId
      gender: $gender
      status: $status
      query: $query
      orderBy: $orderBy
    ) {
      data {
        cancelled
        created_at
        customer
        gender
        income
        status
        visits
      }
      paginatorInfo {
        hasMorePages
        currentPage
        total
        count
        lastPage
      }
    }
  }
`);
const titles = [
  'Клиент',
  'Пол',
  'Статус',
  'Визиты',
  'Отмененные записи',
  'Заработок',
  'Дата добавления',
];
const sortValues: QueryGetStatisticsCustomerOrderByColumn[] = [
  QueryGetStatisticsCustomerOrderByColumn.Customer,
  QueryGetStatisticsCustomerOrderByColumn.Gender,
  QueryGetStatisticsCustomerOrderByColumn.Status,
  QueryGetStatisticsCustomerOrderByColumn.Visits,
  QueryGetStatisticsCustomerOrderByColumn.Cancelled,
  QueryGetStatisticsCustomerOrderByColumn.Income,
  QueryGetStatisticsCustomerOrderByColumn.CreatedAt,
];

export const CustomerStatistics = () => {
  const companyId = useAppSelector(state => state.company.data!.id);
  const [sort, setSort] = useState<TableSortType>({
    index: 0,
    order: SortOrder.Desc,
  });
  const [search, setSearch] = useState('');
  const [employeeId, setEmployeeId] = useState('');
  const [gender, setGender] = useState<CustomerGender | undefined>();
  const [status, setStatus] = useState<CustomerStatus | undefined>();
  const [options, setOptions] = useState<OptionsType>({
    page: 1,
    first: 10,
    total: 0,
    count: 0,
    lastPage: 1,
  });
  const [period, setPeriod] = useState<StatisticsDates>(
    StatisticsDates.Alltime,
  );
  const {data, loading} = useQuery(GET_STATISTICS_CUSTOMERS, {
    variables: {
      companyId,
      gender,
      status,
      date: period,
      first: options.first,
      employeeId,
      page: options.page,
      query: search,
      orderBy: [
        {
          order: sort.order,
          column: sortValues[sort.index],
        },
      ],
    },
    onCompleted: res =>
      setOptions(prev => ({
        ...prev,
        total: res.getStatisticsCustomer?.paginatorInfo.total,
        count: res.getStatisticsCustomer?.paginatorInfo.count,
        lastPage: res.getStatisticsCustomer?.paginatorInfo.lastPage,
      })),
  });
  const statistics = data?.getStatisticsCustomer?.data ?? [];
  const isEmpty = !statistics?.filter(item => item.customer !== 'Итого').length;

  return (
    <Wrapper>
      <PageHeader back title="Статистика по клиентам" />
      <Flex direction="row" gap={8}>
        <FilterSearch onChange={setSearch} value={search} />
        <FilterStatisticsPeriod {...{period, setPeriod}} />
        <FilterStatisticsEmployee {...{employeeId, setEmployeeId}} />
        <FilterStatisticsCustomerStatus {...{status, setStatus}} />
        <FilterStatisticsGender {...{gender, setGender}} />
        {employeeId ||
        status ||
        gender ||
        search ||
        period !== StatisticsDates.Alltime ? (
          <FilterResetButton
            onClick={() => {
              setStatus(undefined);
              setEmployeeId('');
              setGender(undefined);
              setSearch('');
              setPeriod(StatisticsDates.Alltime);
            }}
          />
        ) : (
          <></>
        )}
      </Flex>
      {!loading && isEmpty ? (
        <FilterEmptyState />
      ) : (
        <Content>
          <Table
            options={options}
            setOptions={setOptions}
            titles={titles}
            sort={sort}
            results
            alignRightColumn={[3, 4, 5]}
            columnNoWrap={[5]}
            onClickTitle={(index, order) => setSort({index, order})}
            rows={statistics.map(item => {
              const customerGender = item.gender as CustomerGender | undefined;
              return [
                item.customer,
                customerGender ? Gender[customerGender] : '',
                Status[item.status],
                getNumberFormat(item.visits),
                getNumberFormat(item.cancelled),
                getPriceFormat(item.income),
                dayjs(item.created_at).isValid()
                  ? dayjs(item.created_at).format('DD.MM.YYYY')
                  : item.created_at,
              ];
            })}
          />
        </Content>
      )}
    </Wrapper>
  );
};
