import React from 'react';
import styled from 'styled-components';
import {useColors} from 'shared/lib/hooks';
import {Trash} from 'shared/icons/Trash';
import {Text} from 'shared/ui/Text';
import DotsVertical from 'shared/icons/DotsVertical';

type Props = {
  onClick: () => void;
};

const TooltipContainer = styled.div`
  position: relative;
  display: flex;
  &:hover {
    .tooltipVisible {
      visibility: visible;
      opacity: 1;
    }
  }
`;
const Button = styled.button`
  display: flex;
  padding: 0 16px;
  height: 48px;
  align-items: center;
  border: none;
  background-color: transparent;
  gap: 10px;
  flex-direction: row;
  pointer-events: all;
  cursor: pointer;
  &:hover {
    background-color: ${({theme}) => theme.bgSecondary};
  }
`;

const TooltipContent = styled.div`
  visibility: hidden;
  background-color: ${({theme}) => theme.bgPrimary};
  justify-content: center;
  border-radius: 16px;
  position: absolute;
  z-index: 1;
  width: max-content;
  top: 100%;
  left: auto;
  right: -25%;
  margin-top: 0px;
  margin-left: auto;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  padding: 16px 0;
  border: 1px solid ${({theme}) => theme.borderPrimary};
  ${({theme}) => theme.cardShadow}
`;
export const TooltipMessage = (props: Props) => {
  const {onClick} = props;
  const colors = useColors();

  return (
    <TooltipContainer>
      <DotsVertical />
      <TooltipContent className="tooltipVisible">
        <Button
          onClick={e => {
            e.stopPropagation();
            onClick();
          }}>
          <Trash color={colors.dangerPrimary} />
          <Text style={{pointerEvents: 'none'}}>Удалить рассылку</Text>
        </Button>
      </TooltipContent>
    </TooltipContainer>
  );
};
