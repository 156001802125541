import React, {CSSProperties, useEffect, useMemo, useState} from 'react';

import {
  ColumnDef,
  Row,
  RowSelectionState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';

import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  type DragEndEvent,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  restrictToParentElement,
  restrictToVerticalAxis,
} from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {
  Category,
  Company,
  Employee,
  Service,
  Subcategory,
} from 'shared/__generated__/graphql';
import {useAppSelector} from 'shared/store';
import {getDurationHourMinutes, getServicePrice} from 'shared/lib/utils';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import {GripVertical} from 'shared/icons/GripVertical';
import {useColors} from 'shared/lib/hooks';
import {Content} from 'shared/ui/Content';
import {CheckBox} from 'shared/ui/CheckBox';
import {Text} from 'shared/ui/Text';
import {Flex} from 'shared/ui/Flex';

type TypeService = Pick<
  Service,
  | 'id'
  | 'name'
  | 'description'
  | 'type'
  | 'max_price'
  | 'min_price'
  | 'price'
  | 'duration'
  | 'breakout_time'
> & {
  category: Pick<Category, 'id' | 'title'> & {
    subcategories?: Pick<Subcategory, 'id' | 'title'>[];
  };
  subcategory?:
    | (Pick<Subcategory, 'id' | 'title'> & {
        category?: Pick<Category, 'id' | 'title'>;
      })
    | null;
  employees?: Pick<Employee, 'id' | 'name' | 'surname'>[];
  company?: Pick<Company, 'id'> & {
    categories?: Pick<Category, 'id' | 'title'>[];
    subcategories?: Pick<Subcategory, 'id' | 'title'>[];
  };
};

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const StyledThead = styled.thead<{
  backgroundColor?: string;
  scrollable: boolean;
}>`
  background-color: ${({backgroundColor, theme}) =>
    backgroundColor ?? theme.bgPrimary};
  position: ${({scrollable}) => (scrollable ? 'sticky' : 'static')};
  top: 0;
  z-index: 10;
`;

const StyledTr = styled.tr<{height?: string; hover?: boolean}>`
  border-bottom: 1px solid ${({theme}) => theme.borderPrimary};
  pointer-events: all;
  &:hover {
    background-color: ${({theme, hover}) =>
      hover ? theme.bgSecondary : undefined};
  }
  &:last-child {
    border-bottom: none;
  }
`;
const StyledTh = styled.th`
  padding: 16px;
  text-align: left;
  font-family: 'Inter';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: start;
  color: ${({theme}) => theme.textTertiary};
`;
const StyledTd = styled.td`
  flex: 1;
  padding: 16px;
  height: auto;
  text-align: left;
  font-family: 'Inter';
  font-size: 16px;
  font-style: normal;
  text-overflow: ellipsis;
  font-weight: 400;
  line-height: 24px;
  white-space: normal;
  color: ${({theme}) => theme.textPrimary};
`;
const GripBox = styled.div`
  display: flex;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  justify-content: center;
  align-items: center;
  pointer-events: all;
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
`;

const RowDragHandleCell = ({rowId}: {rowId: string}) => {
  const colors = useColors();
  const {attributes, listeners} = useSortable({
    id: rowId,
  });
  return (
    <GripBox {...attributes} {...listeners}>
      <GripVertical color={colors.textTertiary} />
    </GripBox>
  );
};

const DraggableRow = ({
  row,
  permission,
}: {
  row: Row<TypeService>;
  permission: boolean;
}) => {
  const {transform, transition, setNodeRef, isDragging, activeIndex, index} =
    useSortable({
      id: row.original.id,
    });
  const navigate = useNavigate();
  const username = useAppSelector(state => state.company.data?.username);
  const active = index === activeIndex;
  const style: CSSProperties = {
    transform: CSS.Transform.toString(
      transform
        ? {
            x: transform.x,
            y: transform.y,
            scaleX: active ? transform.scaleX + 0.01 : transform.scaleX,
            scaleY: active ? transform.scaleY + 0.01 : transform.scaleY,
          }
        : null,
    ),
    transition: transition,
    opacity: isDragging ? 0.8 : 1,
    zIndex: isDragging ? 1 : 0,
    position: 'relative',
  };
  return (
    <StyledTr
      key={row.id}
      ref={setNodeRef}
      hover
      style={style}
      onClick={() => {
        permission &&
          navigate(`/${username}/edit-service`, {
            state: {serviceId: row.original.id},
          });
      }}>
      {row.getVisibleCells().map(cell => (
        <StyledTd
          key={cell.id}
          style={{
            width:
              cell.column.getSize() === Number.MAX_SAFE_INTEGER
                ? 'auto'
                : cell.column.getSize(),
          }}>
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </StyledTd>
      ))}
    </StyledTr>
  );
};

export const BetaServiceTable = ({
  onDragEnd,
  onSelectionChange,
  scrollable = false,
  backgroundColor,
  tableData,
  dragDisabled,
  permission,
}: {
  onDragEnd?: (services: TypeService[]) => void;
  onSelectionChange?: (selectedItems: TypeService[]) => void;
  scrollable?: boolean;
  backgroundColor?: string;
  tableData: TypeService[];
  dragDisabled?: boolean;
  permission: boolean;
}) => {
  const columns = useMemo<ColumnDef<TypeService>[]>(() => {
    const baseColumns: ColumnDef<TypeService>[] = [
      ...(dragDisabled
        ? []
        : [
            {
              id: 'drag-handle',
              cell: ({row}: {row: Row<TypeService>}) => (
                <RowDragHandleCell rowId={row.id} />
              ),
              enableResizing: false,
              size: 60,
            },
          ]),
      {
        id: 'select-col',
        size: 60,
        enableResizing: false,
        header: ({table}) => (
          <CheckBox
            {...{
              checked: table.getIsAllRowsSelected(),
              onSelectedAll: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({row}) => (
          <CheckBox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        ),
      },
      {
        header: 'Название услуги',
        accessorFn: row => (
          <Flex gap={4} direction="column">
            <Text
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 1,
              }}>
              {row.name}
            </Text>
            <Text
              typography="subHead14Regular"
              color="textTertiary">{`${row.category.title} / ${row.subcategory?.title}`}</Text>
          </Flex>
        ),
        cell: info => info.getValue(),
      },
      {
        header: 'Цена',
        accessorFn: row => getServicePrice(row),
        cell: info => info.getValue(),
        maxSize: 150,
      },
      {
        header: 'Длительность',
        accessorFn: row => getDurationHourMinutes(row.duration),
        maxSize: 150,
      },
      {
        header: 'Перерыв',
        accessorFn: row => getDurationHourMinutes(Number(row.breakout_time)),
        maxSize: 150,
      },
      {
        header: 'Сотрудники',
        accessorFn: row =>
          row.employees && row.employees.length > 0
            ? row.employees
                .map(item =>
                  item.surname
                    ? `${item.name} ${item.surname}`
                    : `${item.name}`,
                )
                .join(', ')
            : '-',
        size: 350,
      },
    ];
    return baseColumns;
  }, [dragDisabled]);

  const [data, setData] = useState(tableData);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>(Object);
  const dataIds = data?.map(({id}) => id);
  const table = useReactTable({
    data,
    columns,
    defaultColumn: {
      minSize: 0,
      size: Number.MAX_SAFE_INTEGER,
      maxSize: Number.MAX_SAFE_INTEGER,
    },
    getCoreRowModel: getCoreRowModel(),
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    state: {
      rowSelection,
    },
    getRowId: row => row.id,
    getSortedRowModel: getSortedRowModel(),
  });
  useEffect(() => setData(tableData), [tableData]);
  useEffect(() => {
    const selectServices = table
      .getSelectedRowModel()
      .rows.map(({original}) => original);
    onSelectionChange && onSelectionChange(selectServices);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table.getSelectedRowModel().rows]);

  function handleDragEnd(event: DragEndEvent) {
    const {active, over} = event;
    if (active && over && active.id !== over.id) {
      setData(data => {
        const oldIndex = dataIds.indexOf(active.id.toString());
        const newIndex = dataIds.indexOf(over.id.toString());
        onDragEnd && onDragEnd(arrayMove(data, oldIndex, newIndex));
        return arrayMove(data, oldIndex, newIndex);
      });
    }
  }
  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {}),
  );
  return (
    <Content>
      <DndContext
        collisionDetection={closestCenter}
        modifiers={[restrictToVerticalAxis, restrictToParentElement]}
        onDragEnd={handleDragEnd}
        sensors={sensors}>
        <StyledTable>
          <StyledThead
            scrollable={scrollable}
            backgroundColor={backgroundColor}>
            {table.getHeaderGroups().map(headerGroup => (
              <StyledTr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <StyledTh
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{
                      width:
                        header.getSize() === Number.MAX_SAFE_INTEGER
                          ? 'auto'
                          : header.getSize(),
                    }}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </StyledTh>
                ))}
              </StyledTr>
            ))}
          </StyledThead>
          <tbody>
            <SortableContext
              items={dataIds}
              disabled={dragDisabled}
              strategy={verticalListSortingStrategy}>
              {table.getRowModel().rows.map(row => (
                <DraggableRow key={row.id} row={row} permission={permission} />
              ))}
            </SortableContext>
          </tbody>
        </StyledTable>
      </DndContext>
    </Content>
  );
};
