import {useQuery} from '@apollo/client';
import {NotificationSk} from 'entities/notification';
import React, {useState} from 'react';
import {gql} from 'shared/__generated__';
import {useAppSelector} from 'shared/store';
import {Content} from 'shared/ui/Content';
import {Divider} from 'shared/ui/Divider';
import {Error} from 'shared/ui/Error';
import {List} from 'shared/ui/List';
import {RenderItem} from './ui/RenderItem';
import dayjs from 'dayjs';
import {PaginInfo} from 'shared/ui/Table';
import {Text} from 'shared/ui/Text';
import {EmptyNotification} from 'shared/illustration/EmptyNotification';

type OptionsType = {
  page: number;
  first: number;
  total?: number;
  count?: number;
};

const NOTIFICATION_REST = gql(`
  query CompanyEventsNotificationOthers(
    $companyId: String!
    $first: Int!
    $page: Int
  ) {
    companyEvents(company_id: $companyId, first: $first, page: $page) {
      data {
        text
        title
        created_at
        icon_url
        id
        company_id
        updated_at
      }
      paginatorInfo {
        currentPage
        count
        total
        lastPage
      }
    }
  }
`);

export const NotificationRest = () => {
  const companyId = useAppSelector(state => state.company.data!.id);
  const [options, setOptions] = useState<OptionsType>({
    page: 1,
    first: 40,
    total: 0,
    count: 0,
  });
  const {loading, error, data} = useQuery(NOTIFICATION_REST, {
    variables: {
      companyId,
      first: options.first,
      page: options.page,
    },
    onCompleted: dataRes => {
      setOptions(prev => ({
        ...prev,
        total: dataRes.companyEvents?.paginatorInfo.total,
        count: dataRes.companyEvents?.paginatorInfo.count,
      }));
    },
  });

  const notifications = data?.companyEvents?.data ?? [];

  if (loading) {
    return <NotificationSk />;
  }

  if (error) {
    return (
      <Content>
        <Error message={error.message} />
      </Content>
    );
  }

  if (notifications.length === 0) {
    return (
      <Content style={{alignItems: 'center'}} gap="16px">
        <EmptyNotification size={300} />
        <Text typography="text18" color="textTertiary">
          Уведомлений пока нет
        </Text>
      </Content>
    );
  }

  return (
    <Content>
      <List
        data={[...notifications].sort(function (a, b) {
          if (dayjs(a.created_at).isBefore(b.created_at)) {
            return 1;
          } else {
            return -1;
          }
        })}
        gap={32}
        keyExtractor={item => item.id}
        renderItem={item => (
          <RenderItem
            url={item.icon_url}
            title={item.title}
            text={item.text}
            created={item.created_at}
          />
        )}
        divider={<Divider mb={32} />}
      />
      <PaginInfo
        first={options.first}
        page={options.page}
        total={options.total}
        count={options.count}
        setOptions={setOptions}
      />
    </Content>
  );
};
