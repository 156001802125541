import React, {useState} from 'react';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';
import {device} from 'shared/device';
import {Content} from 'shared/ui/Content';
import {Button} from 'shared/ui/Button';
import {Contact, Social} from 'entities/contact';
import {ContactType} from 'shared/__generated__/graphql';
import {gql} from 'shared/__generated__';
import {useMutation, useQuery} from '@apollo/client';
import {useAppSelector} from 'shared/store';
import {ensureError, modifyUrl} from 'shared/lib/utils';
import {showAlert} from 'shared/ui/Alert';
import {Error} from 'shared/ui/Error';
import {Loading} from 'shared/ui/Loading';
import {Seo} from 'shared/ui/Seo';

const COMPANY_CONTACTS = gql(`
  query Contacts($companyId: String!) {
    company(id: $companyId) {
      id
      contacts {
        id
        type
        value
      }
      youtube_url
      vk_url
      instagram_url
    }
  }
`);

const COMPANY_CONTACTS_UPDATE = gql(`
  mutation UpdateCompanyContacts(
    $contacts: [UpdateCompanyContacts!]!
    $urls: UpdateCompany!
    $companyId: String!
  ) {
    updateCompanyContacts(input: $contacts, company_id: $companyId) {
      id
      type
      value
    }
    updateCompany(input: $urls) {
      id
      youtube_url
      vk_url
      instagram_url
    }
  }
`);

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 8px;
  margin-bottom: 24px;
  gap: 24px;
  @media (${device.mobile}) {
    gap: 8px;
    margin-bottom: 0;
    border-radius: 0;
  }
`;

export const CompanyContact = () => {
  const companyId = useAppSelector(state => state.company.data!.id);
  const [contacts, setContacts] = useState<Record<ContactType, string[]>>({
    phone: [],
    whatsapp: [],
    telegram: [],
    viber: [],
    email: [],
    website: [],
  });
  const {loading, error: errorQuery} = useQuery(COMPANY_CONTACTS, {
    variables: {companyId},
    onCompleted: res => {
      const company = res.company;
      const typeContacts = Object.values(ContactType);
      const init = contacts;
      res.company?.contacts.forEach(contact => {
        const type = contact.type;
        init[type].push(contact.value);
      });
      typeContacts.forEach(type => {
        const newContacts =
          res.company?.contacts
            .filter(contact => contact.type === type)
            .map(contact => contact.value) || [];

        setContacts(prevContacts => ({
          ...prevContacts,
          [type]: newContacts,
        }));
      });
      setVk(modifyUrl(company?.vk_url ?? ''));
      setYoutube(modifyUrl(company?.youtube_url ?? ''));
    },
  });
  const [updateCompany, {loading: uploading}] = useMutation(
    COMPANY_CONTACTS_UPDATE,
    {refetchQueries: [COMPANY_CONTACTS]},
  );
  const [vk, setVk] = useState('');
  const [youtube, setYoutube] = useState('');
  const [error, setError] = useState<
    ContactType | 'name' | 'telephone' | null
  >();
  if (loading) {
    return (
      <Wrapper>
        <Loading />
      </Wrapper>
    );
  }
  if (errorQuery) {
    return (
      <Wrapper>
        <Error message={errorQuery.message} />
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      <Seo title="Контакты" />
      <Content>
        <Text typography="title18" color="textPrimary">
          Контакты
        </Text>
        <Contact
          type={ContactType.Phone}
          initialData={contacts[ContactType.Phone]}
          setError={setError}
          error={error === ContactType.Phone}
          setInitialDate={data => setContacts(prev => ({...prev, phone: data}))}
        />
        <Contact
          type={ContactType.Email}
          initialData={contacts[ContactType.Email]}
          setError={setError}
          error={error === ContactType.Email}
          setInitialDate={data => setContacts(prev => ({...prev, email: data}))}
        />
        <Contact
          type={ContactType.Website}
          initialData={contacts[ContactType.Website]}
          setError={setError}
          error={error === ContactType.Website}
          setInitialDate={data =>
            setContacts(prev => ({...prev, website: data}))
          }
        />
      </Content>
      <Content>
        <Text typography="title18" color="textPrimary">
          Мессенджеры
        </Text>
        <Contact
          type={ContactType.Whatsapp}
          initialData={contacts[ContactType.Whatsapp]}
          setError={setError}
          error={error === ContactType.Whatsapp}
          setInitialDate={data =>
            setContacts(prev => ({...prev, whatsapp: data}))
          }
        />
        <Contact
          type={ContactType.Telegram}
          initialData={contacts[ContactType.Telegram]}
          setError={setError}
          error={error === ContactType.Telegram}
          setInitialDate={data =>
            setContacts(prev => ({...prev, telegram: data}))
          }
        />
        <Contact
          type={ContactType.Viber}
          initialData={contacts[ContactType.Viber]}
          setError={setError}
          error={error === ContactType.Viber}
          setInitialDate={data => setContacts(prev => ({...prev, viber: data}))}
        />
      </Content>
      <Content>
        <Text typography="title18" color="textPrimary">
          Социальные сети
        </Text>
        <Social type="vk" value={vk} setValue={setVk} />
        <Social type="youtube" value={youtube} setValue={setYoutube} />
      </Content>
      <Button
        size="large"
        loading={uploading}
        onClick={async () => {
          const payload = [
            ...contacts.phone.map(value => ({
              value,
              type: ContactType.Phone,
            })),
            ...contacts.email.map(value => ({
              value,
              type: ContactType.Email,
            })),
            ...contacts.telegram.map(value => ({
              value,
              type: ContactType.Telegram,
            })),
            ...contacts.viber.map(value => ({
              value,
              type: ContactType.Viber,
            })),
            ...contacts.website.map(value => ({
              value,
              type: ContactType.Website,
            })),
            ...contacts.whatsapp.map(value => ({
              value,
              type: ContactType.Whatsapp,
            })),
          ];

          const nullContact = payload.find(item => item.value === '');
          if (nullContact) {
            return setError(nullContact.type);
          }
          try {
            await updateCompany({
              variables: {
                contacts: payload,
                urls: {
                  youtube_url: youtube === '' ? '' : 'https://www.' + youtube,
                  vk_url: vk === '' ? '' : 'https://' + vk,
                  id: companyId,
                },
                companyId,
              },
            });
            showAlert('success', 'Контакты успешно обновлены');
          } catch (e) {
            const error = ensureError(e);
            showAlert('error', error.message);
          }
        }}>
        Сохранить
      </Button>
    </Wrapper>
  );
};
