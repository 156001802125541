import React, {useEffect} from 'react';
import {TextInput} from 'shared/ui/TextInput';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {getMe, loginPassword} from 'shared/lib/api';
import {ensureError} from 'shared/lib/utils';
import {Button} from 'shared/ui/Button';
import {setToken, setUserId, useAppDispatch} from 'shared/store';
import {Content} from 'shared/ui/Content';
import {Head} from 'shared/ui/Head';
import {Text} from 'shared/ui/Text';
import {Flex} from 'shared/ui/Flex';
import {NavigateBack} from 'shared/ui/NavigateBack';
import {Seo} from 'shared/ui/Seo';
import {Tooltip} from 'shared/ui/Tooltip';
import CirclePelp from 'shared/icons/CirclePelp';
import {useColors} from 'shared/lib/hooks';

export function SignInPassword() {
  const colors = useColors();
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  useEffect(() => {
    setError(null);
  }, [login, password]);
  return (
    <Content gap="0px">
      <Seo title="Вход" />
      <Head>
        <NavigateBack />
        <Text typography="title20">Вход</Text>
      </Head>
      <Flex direction="column" gap={32}>
        <Flex direction="column" gap={16}>
          <TextInput
            value={login}
            wrapperStyle={{gap: 0}}
            onChange={setLogin}
            autoComplete="username"
            label="Номер телефона или email"
            error={error ? ' ' : null}
            rightElement={
              <Tooltip
                style={{padding: '8px 12px', borderRadius: 8}}
                typography="footNote12Medium"
                text={'Пример ввода телефона: 79142223322'}>
                <CirclePelp color={colors.textTertiary} />
              </Tooltip>
            }
          />
          <TextInput
            value={password}
            onChange={setPassword}
            error={error}
            label="Пароль"
            autoComplete="current-password"
            type="password"
          />
        </Flex>
        <Flex direction="column" gap={8}>
          <Button
            loading={loading}
            disabled={login.length === 0 || password.length < 8}
            size="large"
            onClick={async () => {
              try {
                setLoading(true);
                const token = await loginPassword(login, password);
                dispatch(setToken(token));
                const me = await getMe();
                dispatch(setUserId(me!.id));
                if (!me?.name) {
                  navigate('/sign-up', {state: {id: me!.id}});
                } else {
                  navigate('/my-companies');
                }
              } catch (err) {
                const error = ensureError(err);
                setError(error.message);
              } finally {
                setLoading(false);
              }
            }}>
            Войти
          </Button>
          <Button
            size="large"
            onClick={() => {
              navigate('/restore-password');
            }}
            variant="subtitle">
            Я забыл пароль
          </Button>
        </Flex>
      </Flex>
    </Content>
  );
}
