import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {Text} from './Text';
import {List} from './List';
import {CheckBox} from './CheckBox';
import {Flex} from './Flex';
import {ArrowDown2v} from 'shared/icons/ArrowDown2v';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
`;

const Body = styled.button<{error?: string}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 62px;
  border-radius: 14px;
  border: 1px solid
    ${({theme, error}) => (error ? theme.dangerPrimary : 'transparent')};
  background-color: ${({theme}) => theme.fillPrimary};
  pointer-events: all;
  cursor: pointer;
  padding: 0 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const StyledLabel = styled(Text)<{required?: boolean}>`
  &::after {
    content: '*';
    color: ${props => props.theme.dangerPrimary};
    visibility: ${props => (props.required ? 'visible' : 'hidden')};
    font-size: 12px;
  }
`;

type Props<T> = {
  variant?: 'default' | 'label_animation' | 'with_label';
  data: {label: string; value: T}[];
  onChange?: (values: T[]) => void;
  values: T[];
  error?: string;
  required?: boolean;
  style?: React.CSSProperties;
  label?: string;
  disabled?: boolean;
  allText?: string;
  arrow?: boolean;
};

export const MultiSelect = <T,>(props: Props<T>) => {
  const {
    variant = 'label_animation',
    data,
    error,
    values,
    onChange,
    style,
    required,
    label,
    disabled,
    allText,
    arrow = false,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef<HTMLDivElement>(null);
  const isLabelShrink = values.length > 0 || isOpen;

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        selectRef.current &&
        !selectRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectRef]);

  const text = data
    .filter(item => values.includes(item.value))
    .reduce((acc, cur) => acc + cur.label + ', ', '');

  return (
    <Wrapper ref={selectRef}>
      {variant === 'with_label' && label && (
        <StyledLabel
          required={required}
          typography="subHead14Regular"
          color="textTertiary">
          {label}
        </StyledLabel>
      )}
      <Body
        style={style}
        error={error}
        disabled={disabled}
        onClick={() => setIsOpen(true)}>
        <Flex gap={label && values.length > 0 ? 4 : 0} direction="column">
          {label && variant === 'label_animation' && (
            <StyledLabel
              required={required}
              style={{
                transition: values.length === 0 ? 'all 0.3s ease' : 'none',
                transform:
                  isLabelShrink && values.length === 0
                    ? 'translateY(-14px)'
                    : 'none',
              }}
              typography={isLabelShrink ? 'footNote12Regular' : 'text16Regular'}
              color={
                disabled || isLabelShrink ? 'textTertiary' : 'textPrimary'
              }>
              {label}
            </StyledLabel>
          )}
          <Text
            typography="text16Regular"
            color={disabled ? 'textTertiary' : 'textPrimary'}
            style={{marginTop: 0, padding: 0}}>
            {variant !== 'label_animation' && values.length === 0
              ? 'Выберите вариант'
              : data.length === values.length && allText
              ? allText
              : text.slice(0, -2)}
          </Text>
        </Flex>
        {arrow ? (
          <ArrowDown2v
            style={{
              transform: isOpen ? ' rotate(180deg)' : ' rotate(0deg)',
              transition: 'transform 0.3s ease',
            }}
          />
        ) : null}
      </Body>
      {error && (
        <Text typography="footNote12Regular" color="dangerPrimary">
          {error}
        </Text>
      )}
      <CustomDropdown isOpen={isOpen} variant={variant}>
        <List
          data={data}
          gap={8}
          renderItem={item => (
            <CheckBox
              title={item.label}
              hover
              style={{padding: '20px 16px'}}
              checked={values?.includes(item.value)}
              onChange={value => {
                if (value) {
                  onChange && onChange([...values, item.value]);
                } else {
                  onChange &&
                    onChange(values.filter(item2 => item2 !== item.value));
                }
              }}
            />
          )}
        />
      </CustomDropdown>
    </Wrapper>
  );
};
const CustomDropdown = styled.div<{
  isOpen: boolean;
  variant?: 'default' | 'with_label' | 'label_animation';
}>`
  position: absolute;
  margin-top: ${({variant}) => (variant === 'with_label' ? '90px' : '62px')};
  width: 100%;
  border-radius: 18px;
  background-color: ${props => props.theme.bgPrimary};
  border: 1px solid ${props => props.theme.borderPrimary};
  z-index: 100;
  display: ${props => (props.isOpen ? 'flex' : 'none')};
  flex-direction: column;
  gap: 8px;
  max-height: 300px;
  overflow: auto;
  padding: 16px 0;
`;
