import React from 'react';
import {useColorScheme} from 'shared/lib/hooks';
import {EmptyContainer} from 'shared/ui/EmptyContainer';

export const EmptyIssuedCard = ({size = 400}: {size?: number}) => {
  const colorScheme = useColorScheme();
  return (
    <EmptyContainer size={size}>
      {colorScheme === 'light' ? (
        <svg viewBox="0 0 400 400" fill="none">
          <path
            d="M354.232 201.223C354.232 234.234 343.156 264.844 324.598 289.452C314.421 302.656 302.149 314.36 288.081 323.363C265.332 338.367 238.094 347.07 208.76 347.07C128.242 346.77 62.9888 281.349 62.9888 201.223C62.9888 120.498 128.242 55.3767 208.46 55.3767C237.794 55.3767 265.033 64.0795 287.781 78.7842C301.85 87.7871 314.122 99.4909 324.299 112.695C343.156 137.303 354.232 167.913 354.232 201.223Z"
            fill="#EAEEF9"
          />
          <path
            d="M345.335 110.063C349.824 105.862 350.124 98.9597 345.933 94.4582C341.743 89.9568 334.858 89.6567 330.069 93.858C325.579 98.0594 325.28 104.962 329.47 109.463C333.661 113.964 340.545 114.265 345.335 110.063Z"
            fill="#EAEEF9"
          />
          <path
            d="M190.797 212.627V247.739C190.797 250.739 193.491 253.44 196.484 253.44C205.164 253.44 212.049 246.838 212.648 238.135L214.144 207.526"
            fill="white"
          />
          <path
            d="M190.797 212.627V247.739C190.797 250.739 193.491 253.44 196.484 253.44C205.164 253.44 212.049 246.838 212.648 238.135L214.144 207.526"
            stroke="#B0BACC"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M202.176 217.729L214.747 281.05C215.346 284.051 218.339 286.151 221.333 285.551C229.714 283.75 235.401 275.948 234.204 267.245L226.72 218.329"
            fill="white"
          />
          <path
            d="M202.176 217.729L214.747 281.05C215.346 284.051 218.339 286.151 221.333 285.551C229.714 283.75 235.401 275.948 234.204 267.245L226.72 218.329"
            stroke="#B0BACC"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <g filter="url(#filter0_d_18292_1037)">
            <path
              d="M50.117 178.717L218.637 230.333C226.12 232.734 233.902 228.532 236.297 221.03L266.529 121.998C268.923 114.496 264.733 106.693 257.25 104.293L88.4305 52.6761C80.9474 50.2753 73.1649 54.4767 70.7703 61.9791L40.5386 161.011C38.4433 168.513 42.6338 176.616 50.117 178.717Z"
              fill="url(#paint0_linear_18292_1037)"
            />
          </g>
          <path
            d="M230.608 206.626C230.309 206.626 230.009 206.626 229.71 206.626L170.743 188.62C169.246 188.02 168.348 186.519 168.648 185.019C169.246 183.518 170.743 182.618 172.24 182.918L231.207 200.924C232.703 201.524 233.601 203.025 233.302 204.525C233.002 205.725 231.805 206.626 230.608 206.626Z"
            fill="url(#paint1_linear_18292_1037)"
          />
          <path
            d="M223.722 184.718C223.423 184.718 223.124 184.718 222.824 184.718L152.783 163.112C151.286 162.511 150.388 161.011 150.687 159.51C151.286 158.01 152.783 157.11 154.279 157.41L224.321 179.017C225.818 179.617 226.716 181.117 226.416 182.618C226.117 183.818 224.92 184.718 223.722 184.718Z"
            fill="url(#paint2_linear_18292_1037)"
          />
          <path
            d="M253.265 165.233C274.517 170.034 301.25 176.316 315.618 179.617C322.502 181.117 327.591 186.219 329.686 193.121C332.38 203.024 336.271 217.429 338.367 228.233C341.958 246.238 341.958 261.843 354.231 280.449C355.428 282.25 356.925 284.351 358.122 286.151C360.816 289.752 360.517 294.854 357.823 298.455C357.823 298.455 332.38 322.463 320.706 333.566C317.115 336.867 311.727 337.468 307.536 334.767C295.863 327.564 272.815 313.16 265.631 306.858C255.753 298.455 247.971 287.952 241.984 275.348C235.998 262.744 232.705 253.141 211.453 232.134C200.977 221.931 193.194 212.628 187.507 206.025C182.419 199.723 182.718 190.42 188.704 184.719C194.99 178.417 205.167 178.417 211.453 184.719L264.134 237.235C264.134 237.235 256.352 228.833 259.345 206.926L243.055 198.646"
            fill="white"
          />
          <path
            d="M253.265 165.233C274.517 170.034 301.25 176.316 315.618 179.617C322.502 181.117 327.591 186.219 329.686 193.121C332.38 203.024 336.271 217.429 338.367 228.233C341.958 246.238 341.958 261.843 354.231 280.449C355.428 282.25 356.925 284.351 358.122 286.151C360.816 289.752 360.517 294.854 357.823 298.455C357.823 298.455 332.38 322.463 320.706 333.566C317.115 336.867 311.727 337.468 307.536 334.767C295.863 327.564 272.815 313.16 265.631 306.858C255.753 298.455 247.971 287.952 241.984 275.348C235.998 262.744 232.705 253.141 211.453 232.134C200.977 221.93 193.194 212.628 187.507 206.025C182.419 199.723 182.718 190.42 188.704 184.719C194.99 178.417 205.167 178.417 211.453 184.719L264.134 237.235C264.134 237.235 256.352 228.833 259.345 206.926L243.055 198.646"
            stroke="#B0BACC"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M269.818 242.037C268.022 240.536 265.927 238.436 264.131 236.035L269.818 242.037Z"
            fill="white"
          />
          <path
            d="M269.818 242.037C268.022 240.536 265.927 238.436 264.131 236.035"
            stroke="#B0BACC"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M192.002 202.783C193.202 204.246 194.701 206.001 196.2 207.756C197.4 209.218 199.799 209.511 201.298 208.048L212.693 197.81C214.192 196.347 214.192 194.3 212.693 192.837L208.195 187.864C206.096 185.816 203.097 184.646 200.399 184.646C197.4 184.646 194.401 185.816 192.302 188.157C188.404 192.252 188.704 198.688 192.002 202.783Z"
            fill="#EAEEF9"
          />
          <path
            d="M118.962 151.708C119.561 151.708 119.86 151.708 120.459 151.408C122.254 150.507 123.452 148.407 122.554 146.606C121.057 142.405 120.758 137.903 122.254 133.702C123.751 129.501 126.445 125.9 130.037 123.499C131.833 122.298 132.132 119.898 131.234 118.397C130.037 116.597 127.642 116.297 126.146 117.197C121.057 120.498 117.166 125.599 115.37 131.601C113.574 137.603 113.574 143.905 115.969 149.607C116.268 150.808 117.465 151.708 118.962 151.708Z"
            fill="#B0BACC"
          />
          <path
            d="M103.992 159.51C104.591 159.51 104.89 159.51 105.489 159.21C107.285 158.31 108.183 156.209 107.285 154.108C103.693 146.006 103.094 136.703 105.788 128.3C108.482 119.897 114.169 112.695 121.951 108.194C123.747 106.993 124.346 104.893 123.149 103.092C121.951 101.292 119.856 100.691 118.06 101.892C108.781 107.293 101.897 115.996 98.6042 126.2C95.3116 136.403 95.9102 147.506 100.4 157.41C100.999 158.91 102.495 159.51 103.992 159.51Z"
            fill="#B0BACC"
          />
          <path
            d="M89.6252 167.013C90.2238 167.013 90.8225 167.013 91.1218 166.713C92.9177 165.812 93.8157 163.712 92.9177 161.611C87.5299 149.607 86.6319 136.103 90.5231 123.499C94.4144 110.895 103.095 100.391 114.469 93.7892C116.265 92.8889 116.864 90.4881 115.966 88.6875C115.068 86.887 112.673 86.2868 110.877 87.1871C97.7069 94.6895 88.1286 106.693 83.6387 121.098C79.1488 135.503 80.0468 150.807 86.3326 164.612C86.9313 166.112 88.4279 167.013 89.6252 167.013Z"
            fill="#B0BACC"
          />
          <path
            d="M240.658 138.162C242.963 130.723 238.817 122.819 231.397 120.508C223.977 118.197 216.093 122.354 213.787 129.793C211.482 137.232 215.628 145.136 223.048 147.447C230.469 149.759 238.352 145.602 240.658 138.162Z"
            fill="#B0BACC"
          />
          <path
            d="M209.359 142.405C217.129 142.405 223.428 136.09 223.428 128.3C223.428 120.511 217.129 114.196 209.359 114.196C201.59 114.196 195.291 120.511 195.291 128.3C195.291 136.09 201.59 142.405 209.359 142.405Z"
            fill="url(#paint3_linear_18292_1037)"
          />
          <defs>
            <filter
              id="filter0_d_18292_1037"
              x="32"
              y="49"
              width="243.203"
              height="195.009"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="5" />
              <feGaussianBlur stdDeviation="4" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.396078 0 0 0 0 0.478431 0 0 0 0 0.576471 0 0 0 0.22 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_18292_1037"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_18292_1037"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_18292_1037"
              x1="40.127"
              y1="141.596"
              x2="267.078"
              y2="141.596"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#FDFEFF" />
              <stop offset="1" stopColor="#ECF0F5" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_18292_1037"
              x1="168.62"
              y1="194.438"
              x2="233.63"
              y2="194.438"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#B0BACC" />
              <stop offset="1" stopColor="#969EAE" stopOpacity="0.5" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_18292_1037"
              x1="150.608"
              y1="171.029"
              x2="226.687"
              y2="171.029"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#B0BACC" />
              <stop offset="1" stopColor="#969EAE" stopOpacity="0.5" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_18292_1037"
              x1="195.483"
              y1="128.354"
              x2="223.362"
              y2="128.354"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#F1F3F9" />
              <stop offset="0.0001" stopColor="#B0BACC" />
              <stop offset="1" stopColor="#EAEEF9" stopOpacity="0.5" />
            </linearGradient>
          </defs>
        </svg>
      ) : (
        <svg viewBox="0 0 400 400" fill="none">
          <path
            d="M354.321 201.223C354.321 234.234 343.411 264.844 325.13 289.451C315.104 302.656 303.015 314.359 289.157 323.362C266.748 338.367 239.915 347.07 211.019 347.07C131.702 346.77 67.4229 281.349 67.4229 201.223C67.4229 120.498 131.702 55.377 210.724 55.377C239.621 55.377 266.453 64.0797 288.862 78.7844C302.72 87.7873 314.81 99.491 324.835 112.695C343.411 137.303 354.321 167.913 354.321 201.223Z"
            fill="#282C35"
          />
          <path
            d="M45.0126 218.028C47.6663 218.028 50.0252 215.928 50.0252 212.927C50.0252 210.226 47.9612 207.825 45.0126 207.825C42.064 208.125 40 210.226 40 213.227C40 215.928 42.064 218.028 45.0126 218.028Z"
            fill="#2A2E37"
          />
          <path
            d="M74.7939 328.764C77.4476 328.764 79.8065 326.663 79.8065 323.662C79.8065 320.961 77.7424 318.561 74.7939 318.561C72.1401 318.561 69.7812 320.661 69.7812 323.662C70.0761 326.663 72.1401 328.764 74.7939 328.764Z"
            fill="#2A2E37"
          />
          <path
            d="M140.002 276.686C143.531 276.686 146.668 273.9 146.668 269.919C146.668 266.336 143.923 263.151 140.002 263.151C136.472 263.151 133.335 265.938 133.335 269.919C133.727 273.9 136.472 276.686 140.002 276.686Z"
            fill="#5C6479"
          />
          <path
            d="M345.556 110.063C349.979 105.862 350.273 98.9595 346.145 94.4581C342.017 89.9567 335.236 89.6566 330.518 93.8579C326.095 98.0592 325.8 104.961 329.928 109.463C334.056 113.964 340.838 114.264 345.556 110.063Z"
            fill="#282C35"
          />
          <path
            d="M305.572 77.8399C308.226 77.8399 310.29 75.7392 310.29 73.0383C310.29 70.3375 308.226 68.2368 305.572 68.2368C302.919 68.2368 300.854 70.3375 300.854 73.0383C300.854 75.7392 302.919 77.8399 305.572 77.8399Z"
            fill="#282C35"
          />
          <path
            d="M193.323 212.627V247.738C193.323 250.739 195.976 253.44 198.925 253.44C207.476 253.44 214.258 246.837 214.847 238.135L216.322 207.525"
            fill="url(#paint0_linear_18292_1039)"
          />
          <path
            d="M193.323 212.627V247.738C193.323 250.739 195.976 253.44 198.925 253.44C207.476 253.44 214.258 246.837 214.847 238.135L216.322 207.525"
            stroke="url(#paint1_linear_18292_1039)"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M204.532 217.728L216.916 281.048C217.506 284.049 220.455 286.15 223.403 285.55C231.659 283.749 237.262 275.947 236.082 267.244L228.711 218.328"
            fill="url(#paint2_linear_18292_1039)"
          />
          <path
            d="M204.532 217.728L216.916 281.048C217.506 284.049 220.455 286.15 223.403 285.55C231.659 283.749 237.262 275.947 236.082 267.244L228.711 218.328"
            stroke="url(#paint3_linear_18292_1039)"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <g filter="url(#filter0_d_18292_1039)">
            <path
              d="M54.7414 178.716L220.747 230.333C228.119 232.733 235.785 228.532 238.144 221.03L267.925 121.998C270.283 114.496 266.155 106.693 258.784 104.293L92.4834 52.6761C85.1119 50.2753 77.4456 54.4767 75.0867 61.979L45.3059 161.011C43.2419 168.513 47.3699 176.616 54.7414 178.716Z"
              fill="url(#paint4_linear_18292_1039)"
            />
          </g>
          <path
            d="M232.54 206.625C232.245 206.625 231.95 206.625 231.655 206.625L173.568 188.62C172.093 188.019 171.209 186.519 171.504 185.019C172.093 183.518 173.568 182.618 175.042 182.918L233.129 200.924C234.604 201.524 235.488 203.024 235.193 204.525C234.898 205.725 233.719 206.625 232.54 206.625Z"
            fill="url(#paint5_linear_18292_1039)"
          />
          <path
            d="M225.756 184.718C225.461 184.718 225.166 184.718 224.871 184.718L155.874 163.111C154.4 162.511 153.516 161.011 153.81 159.51C154.4 158.01 155.874 157.109 157.349 157.41L226.346 179.016C227.82 179.617 228.705 181.117 228.41 182.618C228.115 183.818 226.935 184.718 225.756 184.718Z"
            fill="url(#paint6_linear_18292_1039)"
          />
          <path
            d="M254.859 165.232C275.794 170.034 302.129 176.315 316.282 179.616C323.064 181.117 328.076 186.218 330.14 193.12C332.794 203.024 336.627 217.428 338.691 228.232C342.229 246.237 342.229 261.842 354.319 280.448C355.498 282.249 356.972 284.349 358.152 286.15C360.806 289.751 360.511 294.853 357.857 298.454C357.857 298.454 332.794 322.462 321.294 333.565C317.756 336.866 312.449 337.466 308.321 334.765C296.821 327.563 274.117 313.159 267.04 306.857C257.31 298.454 249.644 287.951 243.746 275.347C237.849 262.743 234.606 253.139 213.671 232.133C203.351 221.93 195.684 212.627 190.082 206.024C185.07 199.722 185.364 190.42 191.262 184.718C197.454 178.416 207.479 178.416 213.671 184.718L265.566 237.234C265.566 237.234 257.9 228.832 260.848 206.925L244.802 198.645"
            fill="url(#paint7_linear_18292_1039)"
          />
          <path
            d="M254.859 165.232C275.794 170.034 302.129 176.315 316.282 179.616C323.064 181.117 328.076 186.218 330.14 193.12C332.794 203.024 336.627 217.428 338.691 228.232C342.229 246.237 342.229 261.842 354.319 280.448C355.498 282.249 356.972 284.349 358.152 286.15C360.806 289.751 360.511 294.853 357.857 298.454C357.857 298.454 332.794 322.462 321.294 333.565C317.756 336.866 312.449 337.466 308.321 334.765C296.821 327.563 274.117 313.159 267.04 306.857C257.31 298.454 249.644 287.951 243.746 275.347C237.849 262.743 234.606 253.139 213.671 232.133C203.351 221.93 195.684 212.627 190.082 206.024C185.07 199.722 185.364 190.42 191.262 184.718C197.454 178.416 207.479 178.416 213.671 184.718L265.566 237.234C265.566 237.234 257.9 228.832 260.848 206.925L244.802 198.645"
            stroke="url(#paint8_linear_18292_1039)"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M271.164 242.036C269.395 240.536 267.331 238.435 265.562 236.034L271.164 242.036Z"
            fill="white"
          />
          <path
            d="M271.164 242.036C269.395 240.536 267.331 238.435 265.562 236.034"
            stroke="url(#paint9_linear_18292_1039)"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            opacity="0.3"
            d="M194.51 202.782C195.691 204.245 197.168 206 198.645 207.755C199.827 209.218 202.19 209.51 203.667 208.047L214.891 197.809C216.368 196.346 216.368 194.299 214.891 192.836L210.461 187.863C208.393 185.816 205.439 184.646 202.781 184.646C199.827 184.646 196.873 185.816 194.805 188.156C190.965 192.251 191.261 198.687 194.51 202.782Z"
            fill="#7C86A0"
          />
          <path
            d="M122.56 151.708C123.149 151.708 123.444 151.708 124.034 151.408C125.803 150.507 126.983 148.407 126.098 146.606C124.624 142.405 124.329 137.903 125.803 133.702C127.277 129.501 129.931 125.899 133.469 123.499C135.239 122.298 135.533 119.898 134.649 118.397C133.469 116.596 131.111 116.296 129.636 117.197C124.624 120.498 120.79 125.599 119.021 131.601C117.252 137.603 117.252 143.905 119.611 149.607C119.906 150.807 121.085 151.708 122.56 151.708Z"
            fill="#A9AFC3"
          />
          <path
            d="M107.814 159.51C108.403 159.51 108.698 159.51 109.288 159.21C111.057 158.31 111.942 156.209 111.057 154.109C107.519 146.006 106.929 136.703 109.583 128.3C112.236 119.898 117.839 112.696 125.505 108.194C127.274 106.994 127.864 104.893 126.685 103.092C125.505 101.292 123.441 100.692 121.672 101.892C112.531 107.294 105.75 115.997 102.506 126.2C99.2626 136.403 99.8524 147.507 104.275 157.41C104.865 158.91 106.339 159.51 107.814 159.51Z"
            fill="#A9AFC3"
          />
          <path
            d="M93.66 167.013C94.2497 167.013 94.8395 167.013 95.1343 166.713C96.9035 165.813 97.788 163.712 96.9035 161.612C91.596 149.608 90.7114 136.104 94.5446 123.5C98.3778 110.896 106.929 100.392 118.133 93.7901C119.902 92.8898 120.492 90.4891 119.608 88.6885C118.723 86.8879 116.364 86.2877 114.595 87.188C101.621 94.6904 92.1857 106.694 87.7628 121.099C83.34 135.503 84.2245 150.808 90.4166 164.613C91.0063 166.113 92.4806 167.013 93.66 167.013Z"
            fill="#A9AFC3"
          />
          <path
            d="M242.442 138.162C244.713 130.723 240.628 122.819 233.319 120.507C226.01 118.196 218.243 122.353 215.973 129.792C213.702 137.232 217.786 145.136 225.096 147.447C232.405 149.758 240.171 145.601 242.442 138.162Z"
            fill="#A9AFC3"
          />
          <path
            d="M211.61 142.405C219.264 142.405 225.469 136.09 225.469 128.3C225.469 120.511 219.264 114.196 211.61 114.196C203.957 114.196 197.752 120.511 197.752 128.3C197.752 136.09 203.957 142.405 211.61 142.405Z"
            fill="url(#paint10_linear_18292_1039)"
          />
          <defs>
            <filter
              id="filter0_d_18292_1039"
              x="36.7754"
              y="49"
              width="239.813"
              height="195.009"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="5" />
              <feGaussianBlur stdDeviation="4" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.22 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_18292_1039"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_18292_1039"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_18292_1039"
              x1="204.822"
              y1="207.525"
              x2="204.822"
              y2="253.44"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#E3EAF6" />
              <stop offset="1" stopColor="#ADB6C8" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_18292_1039"
              x1="204.822"
              y1="207.525"
              x2="204.822"
              y2="253.44"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#B0BACC" />
              <stop offset="1" stopColor="#7487A2" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_18292_1039"
              x1="220.386"
              y1="217.728"
              x2="220.386"
              y2="285.652"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#E3EAF6" />
              <stop offset="1" stopColor="#ADB6C8" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_18292_1039"
              x1="220.386"
              y1="217.728"
              x2="220.386"
              y2="285.652"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#B0BACC" />
              <stop offset="1" stopColor="#7487A2" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_18292_1039"
              x1="44.9005"
              y1="141.596"
              x2="268.466"
              y2="141.596"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#5C6479" />
              <stop offset="1" stopColor="#5C6479" />
            </linearGradient>
            <linearGradient
              id="paint5_linear_18292_1039"
              x1="171.476"
              y1="194.438"
              x2="235.517"
              y2="194.438"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#4F576B" />
              <stop offset="1" stopColor="#4F576B" stopOpacity="0.5" />
            </linearGradient>
            <linearGradient
              id="paint6_linear_18292_1039"
              x1="153.733"
              y1="171.029"
              x2="228.676"
              y2="171.029"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#4F576B" />
              <stop offset="1" stopColor="#4F576B" stopOpacity="0.5" />
            </linearGradient>
            <linearGradient
              id="paint7_linear_18292_1039"
              x1="273.281"
              y1="165.512"
              x2="273.281"
              y2="336.466"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#E3EAF6" />
              <stop offset="1" stopColor="#ADB6C8" />
            </linearGradient>
            <linearGradient
              id="paint8_linear_18292_1039"
              x1="273.281"
              y1="165.512"
              x2="273.281"
              y2="336.466"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#B0BACC" />
              <stop offset="1" stopColor="#969EAE" />
            </linearGradient>
            <linearGradient
              id="paint9_linear_18292_1039"
              x1="268.363"
              y1="236.034"
              x2="268.363"
              y2="242.036"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#B0BACC" />
              <stop offset="1" stopColor="#7487A2" />
            </linearGradient>
            <linearGradient
              id="paint10_linear_18292_1039"
              x1="197.941"
              y1="128.353"
              x2="225.404"
              y2="128.353"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#4F576B" />
              <stop offset="1" stopColor="#4F576B" stopOpacity="0.5" />
            </linearGradient>
          </defs>
        </svg>
      )}
    </EmptyContainer>
  );
};
