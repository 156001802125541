import {ApolloError, useMutation} from '@apollo/client';
import React, {useEffect, useState} from 'react';
import {gql} from 'shared/__generated__';
import {ArrowDown2v} from 'shared/icons/ArrowDown2v';
import Xv2 from 'shared/icons/Xv2';
import {EmptyAddCategoryBlock} from 'shared/illustration/EmptyAddCategoryBlock';
import {useAppSelector} from 'shared/store';
import {showAlert} from 'shared/ui/Alert';
import {Button} from 'shared/ui/Button';
import {Dropdown} from 'shared/ui/Dropdown';
import {Flex} from 'shared/ui/Flex';
import {IconButton} from 'shared/ui/IconButton';
import {Modal} from 'shared/ui/Modal';
import {Skeleton} from 'shared/ui/Skeleton';
import {TableViewRow} from 'shared/ui/TableViewRow';
import {Text} from 'shared/ui/Text';
import {TextInput} from 'shared/ui/TextInput';
import {styled} from 'styled-components';

const Wrapper = styled.div`
  display: block;
  position: relative;
`;

type Props = {
  data: {label: string; value: string; subtitle?: number}[];
  label?: string;
  value: string | null;
  onChange: (value: string) => void;
  loading: boolean;
  error?: string | null;
};

const CREATE_BRAND = gql(`
    mutation CreateBrand($companyId: String!, $name: String!) {
    createProductsBrand(company_id: $companyId, name: $name) {
        id
        name
    }
    }
`);

const MyTableViewRow = styled(TableViewRow)`
  padding: 0 16px;
  width: 100%;
  &:hover {
    background-color: ${({theme}) => theme.bgSecondary};
  }
`;

export const SelectBrand = (props: Props) => {
  const {data, label, value, onChange, loading, error} = props;
  const [search, setSearch] = useState(
    value ? data.find(item => item.value === value)?.label : '',
  );
  const [visible, setVisible] = useState(false);
  const [modalCreate, setModalCreate] = useState(false);
  const [createName, setCreateName] = useState('');
  const companyId = useAppSelector(state => state.company.data?.id);

  const [createBrand, {loading: loadingCreate}] = useMutation(CREATE_BRAND, {
    refetchQueries: ['GetDataCreateProduct'],
  });

  useEffect(() => {
    setSearch(data.find(item => item.value === value)?.label ?? '');
  }, [data, value]);

  return (
    <Wrapper>
      <TextInput
        label={label}
        value={search}
        onChange={setSearch}
        error={error}
        rightElement={<ArrowDown2v />}
        onClick={() => setVisible(true)}
      />
      <Dropdown
        visible={visible}
        onClose={() => setVisible(false)}
        data={data}
        style={{top: 50, width: '100%', maxHeight: 452}}
        header={
          <Flex
            direction="row"
            alignItems="center"
            style={{height: 40, paddingLeft: 16, marginBottom: 8}}>
            <Text
              color="mainPrimary"
              style={{cursor: 'pointer'}}
              onClick={() => {
                setModalCreate(true);
              }}>
              + Создать бренд
            </Text>
          </Flex>
        }
        skeleton={loading ? <SkeletonBrandItem /> : undefined}
        headerDivider={false}
        listEmpty={<EmptyState />}
        renderItem={item => (
          <MyTableViewRow
            key={item.value}
            title={item.label}
            subtitle={`Имеется ${item.subtitle} товар`}
            onClick={() => {
              onChange(item.value);
              setSearch(item.label);
              setVisible(false);
            }}
          />
        )}
      />
      <Modal
        style={{width: 588}}
        visible={modalCreate}
        onClose={() => setModalCreate(false)}>
        <Flex direction="column" gap={24}>
          <Flex
            direction="row"
            alignItems="center"
            justifyContent="space-between">
            <Text typography="title20">Добавить бренд</Text>
            <IconButton>
              <Xv2 />
            </IconButton>
          </Flex>
          <TextInput
            value={createName}
            onChange={setCreateName}
            label="Название бренда"
          />
          <Flex direction="row" gap={16}>
            <Button style={{flex: 1}} size="large" variant="outline">
              Отмена
            </Button>
            <Button
              style={{flex: 1}}
              size="large"
              loading={loadingCreate}
              onClick={() => {
                createBrand({
                  variables: {
                    name: createName,
                    companyId: companyId!,
                  },
                })
                  .then(() => {
                    setModalCreate(false);
                  })
                  .catch(e => {
                    if (e instanceof ApolloError) {
                      showAlert('error', e.message);
                    }
                  });
              }}>
              Добавить
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </Wrapper>
  );
};

const EmptyState = () => {
  return (
    <Flex direction="column" style={{maxHeight: 452}}>
      <Flex direction="column" gap={16} alignItems="center">
        <EmptyAddCategoryBlock size={200} />
        <Text typography="title20" align="center">
          Нет брендов
        </Text>
        <Text color="textTertiary" align="center">
          Нажмите на кнопку “Создать бренд” и <br /> добавьте информацию
        </Text>
      </Flex>
    </Flex>
  );
};

const SkeletonBrandItem = () => {
  return (
    <Skeleton height={236}>
      {Array.from(Array(3).keys()).map(item => (
        <React.Fragment key={'sk_brand_item_' + item}>
          <rect
            width={'60%'}
            height={24}
            x={16}
            y={10 + 68 * item}
            rx={4}
            ry={4}
          />
          <rect
            width={'40%'}
            height={20}
            x={16}
            y={38 + 68 * item}
            rx={4}
            ry={4}
          />
        </React.Fragment>
      ))}
    </Skeleton>
  );
};
