import {gql} from 'shared/__generated__';

export {SelectServices} from './ui/SelectServices';
export {SelectEmployees} from './ui/SelectEmployees';
export {ModalClient} from './ui/ModalClient';
export {ModalIssueBonus} from './ui/ModalIssueBonus';
export {ModalIssueCertificate} from './ui/ModalIssueCertificate';
export {DeleteSelection, WrapperDeleteSelection} from './ui/DeleteSelection';
export {PeriodSelect} from './ui/PeriodSelect';
export {
  type WEEK,
  DAYS_WEEK,
  initialDayjsWeekValues,
  getWorkingDays,
  getServicePriceOfType,
} from './lib/utils';

export const GET_BONUSES = gql(`
query BonusCardTemplates($first: Int!, $name: String, $companyId: String!, $page: Int) {
bonusCardTemplates(first: $first, name: $name, company_id: $companyId, page: $page) {
  paginatorInfo {
    count
    total
    currentPage
    lastPage
  }
  data {
    is_active
    id
    name
    bonusCards {
      customer {
        id
      }
    }
    bonusSizes {
      id
      size
      min_amount
    }
    payment_limit
    active_months
    created_at
    services {
      id
      name
    }
    paymentMethods {
      id
      title
    }
  }
}
...Permissions
}
`);

//TODO: Добавить новый атрибут в back // а то затеряется
export const GET_CERTIFICATE_TEMPLATES = gql(`
query CertificateTemplates($companyId: String!, $first: Int!, $name: String, $page: Int) {
  certificateTemplates(company_id: $companyId, first: $first, name: $name, page: $page) {
    paginatorInfo {
      currentPage
      total
      lastPage
      count
    }
    data {
      id
      name
      certificates {
        id
      }
      price
      balance
      created_at
      validity_months
    }
  }
  ...Permissions
}
`);

export const SERVICES_SELECT_SERVICES = gql(`
  query CompanyServiceSearch($first: Int!, $companyId: String, $query: String, $categoryId: [String!], $subcategoryId: [String!], $page: Int) {
    companyServiceSearch(first: $first, company_id: $companyId, query: $query, category_id: $categoryId, subcategory_id: $subcategoryId, page: $page) {
      data {
        id
        name
        full_duration
        duration
        type
        price
        min_price
        max_price
        is_active
      }
      paginatorInfo {
        total
        currentPage
      }
    }
    company(id: $companyId) {
      categories {
        id
        title
        subcategories {
          id
          title
        }
      }
      id
    }
  }
`);
