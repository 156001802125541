import React, {useState} from 'react';
import {styled} from 'styled-components';
import {FilterBtn} from 'shared/ui/FilterButton';
import {Modal} from 'shared/ui/Modal';
import {List} from 'shared/ui/List';
import {Text} from 'shared/ui/Text';
import {methodSending, statusNotification} from 'entities/messages';
import {Divider} from 'shared/ui/Divider';
import {RadioButton} from 'shared/ui/RadioButton';

const PopupWrapper = styled.div`
  position: relative;
  display: inline-block;
`;
const ButtonPeriod = styled.button<{checked?: boolean}>`
  display: flex;
  padding: 0 16px;
  align-items: center;
  height: 40px;
  width: 282px;
  justify-content: space-between;
  border: none;
  background-color: transparent;
  &:hover {
    background-color: ${({theme}) => theme.bgSecondary};
  }
`;

export const Filters = ({
  filters,
  setFilters,
}: {
  filters: {status: number; method: number};
  setFilters: (filters: {status: number; method: number}) => void;
}) => {
  const [visible, setVisible] = useState(false);
  const countFilters = +!!filters.method + +!!filters.status;
  return (
    <PopupWrapper>
      <FilterBtn
        hover={false}
        style={{height: 56}}
        onClick={() => setVisible(true)}
        indicator={countFilters}
      />
      <Modal
        style={{
          left: 'auto',
          right: 'auto',
          top: 64,
          padding: '16px 0',
          gap: 8,
        }}
        visible={visible}
        onClose={() => setVisible(false)}>
        <Text mb={8} style={{marginLeft: 16}} color="textTertiary">
          Статус уведомления
        </Text>
        <List
          data={[...Object.values(statusNotification)]}
          keyExtractor={(_, index) => 'status_' + index}
          renderItem={(item, index) => (
            <ButtonPeriod
              onClick={() => {
                setFilters({...filters, status: index});
                setVisible(false);
              }}
              checked={index === filters.status}>
              <Text>{item.label}</Text>
              <RadioButton
                style={{flex: 0}}
                selected={index === filters.status}
              />
            </ButtonPeriod>
          )}
        />
        <Divider style={{margin: '16px'}} />
        <Text mb={8} style={{marginLeft: 16}} color="textTertiary">
          Метод отправки
        </Text>
        <List
          data={[...Object.values(methodSending)]}
          keyExtractor={(_, index) => 'method_' + index}
          renderItem={(item, index) => (
            <ButtonPeriod
              onClick={() => {
                setFilters({...filters, method: index});
                setVisible(false);
              }}
              checked={index === filters.method}>
              <Text>{item.label}</Text>
              <RadioButton
                style={{flex: 0}}
                selected={index === filters.method}
              />
            </ButtonPeriod>
          )}
        />
      </Modal>
    </PopupWrapper>
  );
};
