import React from 'react';
const Arrow = ({
  color = '#6765F8',
  style,
  size = 24,
}: {
  color?: string;
  style?: React.CSSProperties;
  size?: number;
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}>
      <path
        d="M6 10L12 16L18 10"
        stroke={color}
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Arrow;
