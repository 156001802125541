import React, {useEffect, useState} from 'react';
import {useAppSelector} from 'shared/store';
import {styled} from 'styled-components';
import {PageHeader} from 'shared/ui/PageHeader';
import {SlidesContainer} from 'shared/ui/SlidesContainer';
import {Flex} from 'shared/ui/Flex';
import {Button} from 'shared/ui/Button';
import {Plus} from 'shared/icons/Plus';
import {useLocation, useNavigate} from 'react-router-dom';
import {SalaryCalculation} from './ui/SalaryCalculation';
import {RulesSalaries} from './ui/RulesSalaries';
import {SelectPeriod} from 'shared/ui/SelectPeriod';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
`;
const SectionTabs = styled(Flex)`
  display: flex;
  height: 56px;
  align-items: center;
  justify-content: space-between;
`;
const Tab = styled.button<{active: boolean}>`
  border: none;
  height: 40px;
  color: ${({theme, active}) =>
    active ? theme.mainPrimary : theme.textTertiary};
  background-color: transparent;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  font-family: 'Inter';
  padding: 0;
  left: 0;
`;
const ActiveTab = styled.div<{width: string; left: number}>`
  height: 2px;
  width: ${({width}) => width};
  background-color: ${({theme}) => theme.mainPrimary};
  border-radius: 1px;
  position: absolute;
  bottom: 0;
  left: ${({left}) => left}px;
  transition:
    left 0.3s,
    width 0.3s;
`;

const tabs: {title: string; value: 'calculation' | 'rules'}[] = [
  {
    title: 'Расчет зарплат',
    value: 'calculation',
  },
  {
    title: 'Правила расчета',
    value: 'rules',
  },
];

export const Salaries = () => {
  const username = useAppSelector(state => state.company.data?.username);
  const tab = useLocation().state;
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState<'calculation' | 'rules'>(
    tab ?? 'calculation',
  );
  const [dates, setDates] = useState<{from: string | null; to: string | null}>({
    from: null,
    to: null,
  });

  const [activeTab, setActiveTab] = useState<HTMLElement | null>(
    document.getElementById(
      'tab_salary_' + tabs.findIndex(item => item.value === selectedTab) ?? 0,
    ),
  );

  useEffect(() => {
    setActiveTab(
      document.getElementById(
        'tab_salary_' + tabs.findIndex(item => item.value === selectedTab) ?? 0,
      ),
    );
  }, [selectedTab]);

  return (
    <Wrapper>
      <PageHeader title="Зарплаты" />
      <SectionTabs>
        <Flex gap={32} style={{padding: '0 24px', position: 'relative'}}>
          {tabs.map(({title, value}, index) => (
            <Tab
              style={{marginBottom: 14}}
              id={'tab_salary_' + index}
              key={value}
              active={value === selectedTab}
              onClick={() => setSelectedTab(value)}>
              {title}
            </Tab>
          ))}
          <ActiveTab
            width={
              activeTab?.offsetWidth ? activeTab.offsetWidth + 'px' : '124px'
            }
            left={activeTab?.offsetLeft ?? 24}
          />
        </Flex>
        {selectedTab === 'rules' && (
          <Button
            onClick={() => navigate(`/${username}/create-salary-rule`)}
            leftIcon={<Plus size={20} />}>
            Создать правило
          </Button>
        )}
        {selectedTab === 'calculation' && <SelectPeriod onChange={setDates} />}
      </SectionTabs>
      <SlidesContainer index={selectedTab === 'calculation' ? 0 : 1}>
        <SalaryCalculation dates={dates} />
        <RulesSalaries active={selectedTab === 'rules'} />
      </SlidesContainer>
    </Wrapper>
  );
};
