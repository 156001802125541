import {ArcElement, Chart as ChartJS, Legend, Tooltip} from 'chart.js';
import React from 'react';
import {Pie as PieChart} from 'react-chartjs-2';
import {Flex} from 'shared/ui/Flex';

type DataType = {
  name: string;
  color: string;
  value: number;
}[];

ChartJS.register(ArcElement, Tooltip, Legend);

export function Pie({data: dataPie}: {data: DataType}) {
  const data = {
    datasets: [
      {
        label: 'Сумма',
        data: dataPie.map(item => item.value),
        backgroundColor: dataPie.map(item => item.color),
        spacing: 8,
        hoverOffset: 5,
        borderRadius: 50,
        borderColor: 'transparent',
        cutout: '80%',
      },
    ],
  };
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      style={{
        width: 200,
        height: 200,
      }}>
      <PieChart data={data} />
    </Flex>
  );
}
