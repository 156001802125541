import dayjs from 'dayjs';
import React, {useState} from 'react';
import {device} from 'shared/device';
import {Plus} from 'shared/icons/Plus';
import {useColors} from 'shared/lib/hooks';
import {Button} from 'shared/ui/Button';
import {Grow} from 'shared/ui/Grow';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';
import {gql} from 'shared/__generated__';
import {
  EMPLOYEE_SCHEDULE,
  GET_EMPLOYEES,
  ScheduleWorkTime,
} from 'entities/schedule';
import {GET_COMPANY_EMPLOYEES} from 'entities/employees';
import {BreakTime, WorkingDateType} from 'shared/__generated__/graphql';
import {ApolloError, useMutation} from '@apollo/client';
import {useAppSelector} from 'shared/store';
import {showAlert} from 'shared/ui/Alert';
import {Avatar} from 'shared/ui/Avatar';
import {Switch} from 'shared/ui/Switch';
import {TUTORIALCHECK} from 'entities/tutorial';
import {BOOKINGS} from 'entities/booking';
import {CloseButton} from 'shared/ui/CloseButton';
import {Popup} from 'shared/ui/Popup';

export type WorkDate = {
  __typename?: 'WorkingDate' | undefined;
  id?: string;
  date: string;
  end_time?: string;
  start_time?: string;
  breakTimes?: {
    __typename?: 'BreakTime' | undefined;
    id: string;
    start: string;
    end: string;
  }[];
};

const UPDATE_DATE = gql(`
  mutation UpdateWorkingDates($input: UpdateWorkingDate!) {
    updateWorkingDates(input: $input) {
      id
    }
  }
`);

const DELETE_DATE_MANY = gql(` 
  mutation DeleteWorkingDatesMany($input: DeleteWorkingDates!) {
    deleteWorkingDatesMany(input: $input) {
      id
    }
  }
`);

const CREATE_WORKING_DATE = gql(`
  mutation CreateWorkingDates($input: CreateWorkingDate!) {
    createWorkingDates(input: $input) {
      id
    }
  }
`);

const groupDates = (dates: WorkDate[]) => {
  if (dates.length) {
    const result = [];
    const sortedDates = dates
      ?.map(date => dayjs(date.date))
      ?.sort((a, b) => (dayjs(a).isBefore(dayjs(b)) ? -1 : 1));

    let currentGroup = [sortedDates[0]];

    for (let i = 1; i < sortedDates.length; i++) {
      const prevDate = sortedDates[i - 1];
      const currentDate = sortedDates[i];

      if (currentDate.diff(prevDate, 'day') === 1) {
        currentGroup.push(currentDate);
      } else {
        result.push(currentGroup);
        currentGroup = [currentDate];
      }
    }
    result.push(currentGroup);
    return result;
  }
};

const formatGroup = (group: dayjs.Dayjs[]) => {
  if (group.length === 1) {
    return group[0].format('D MMMM');
  }
  return `${group[0].format('D MMMM')} - ${group[group.length - 1].format(
    'D MMMM',
  )}`;
};

const ContentGrow = styled(Grow)`
  gap: 16px;
  @media ${device.desktop} {
    gap: 32px;
  }
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media ${device.desktop} {
    width: 540px;
  }
`;
const Box = styled.div<{hide?: boolean}>`
  display: ${({hide}) => (hide ? 'none' : 'flex')};
  flex-direction: column;
  gap: 16px;
`;
const SelectedEmployee = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;
const SwitchDayoff = styled(Row)`
  height: 48px;
  margin: 8px 0;
`;

export const SettingSchedule = ({
  employeeId,
  name,
  visible,
  onClose,
  selects,
  isSelectable,
  url,
}: {
  employeeId: string;
  name: string;
  url?: string;
  visible: boolean;
  onClose: () => void;
  selects: WorkDate[];
  isSelectable: boolean;
}) => {
  const colors = useColors();
  const date = useAppSelector(state => state.booking.date);
  const column = useAppSelector(state => state.booking.filter.countColumn);
  const companyId = useAppSelector(state => state.company.data!.id);
  const groupedDates = groupDates(selects);
  const formattedGroups = groupedDates?.map(formatGroup);
  const resultText = formattedGroups?.join('; ');
  const breakTimesSelects = isSelectable
    ? []
    : selects
        .flatMap(({breakTimes}) => breakTimes ?? [])
        .map(({start = '', end = ''}) => ({start, end}));
  const [times, setTimes] = useState<Pick<BreakTime, 'start' | 'end'>>(
    isSelectable
      ? {start: '09:00', end: '20:00'}
      : {
          start: selects[0]?.start_time?.length
            ? selects[0]?.start_time
            : '09:00',
          end: selects[0]?.end_time?.length ? selects[0]?.end_time : '20:00',
        },
  );
  const [breakTimes, setBreakTimes] =
    useState<Pick<BreakTime, 'start' | 'end'>[]>(breakTimesSelects);
  const refetchQueries = [
    {
      query: EMPLOYEE_SCHEDULE,
      variables: {employeeId, companyId},
    },
    {
      query: BOOKINGS,
      variables: {
        companyId: companyId,
        first: column === 'Автоматически' ? 4 : +column,
        date: [date],
      },
    },
    {
      query: GET_EMPLOYEES,
      variables: {companyId, first: 20, page: 1, enabled: true},
    },
    {
      query: TUTORIALCHECK,
      variables: {companyId},
    },
    {
      query: GET_COMPANY_EMPLOYEES,
      variables: {companyId, first: 20},
    },
  ];
  const [loading, setLoading] = useState(false);
  const [updateWorkingDate] = useMutation(UPDATE_DATE, {refetchQueries});
  const [createWorkDates] = useMutation(CREATE_WORKING_DATE, {refetchQueries});
  const [deleteWorkDates] = useMutation(DELETE_DATE_MANY, {refetchQueries});
  const [dayoff, setDayoff] = useState(
    selects.length === 1 ? selects[0].id?.length === 0 : false,
  );

  const deleteWorkingDates = (selects: WorkDate[]) => {
    const sortedDates = selects.sort((a, b) =>
      dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1,
    );
    deleteWorkDates({
      variables: {
        input: {
          employee_id: employeeId,
          from: sortedDates[0].date,
          to: sortedDates[sortedDates.length - 1].date,
        },
      },
    })
      .then(() => {
        setLoading(false);
        onClose();
      })
      .catch(e => {
        if (e instanceof ApolloError) {
          showAlert('error', e.message);
        }
      });
  };

  return (
    <Popup visible={visible} onClose={onClose}>
      <ContentGrow>
        <Row>
          <Text typography="title20">Изменение графика работы</Text>
          <CloseButton onClose={() => onClose()} />
        </Row>
        <Box>
          <Row>
            <Text typography="text16Semibold">{resultText}</Text>
          </Row>
          <SelectedEmployee>
            <Avatar size={32} url={url} />
            <Text typography="subHead14Medium">{name}</Text>
          </SelectedEmployee>
          <SwitchDayoff onClick={() => setDayoff(prev => !prev)}>
            <Text>Выходной день</Text>
            <Switch value={dayoff} />
          </SwitchDayoff>
          <ScheduleWorkTime
            show={!dayoff}
            onChange={(time, location) => {
              setTimes(prev => ({...prev, [location]: time}));
            }}
            value={times}
          />
          {breakTimes.length !== 0 && !dayoff && (
            <Text typography="title18" style={{fontSize: 16}}>
              Перерыв
            </Text>
          )}
          <Box hide={breakTimes.length === 0 || dayoff}>
            {breakTimes.map((item, index) => (
              <ScheduleWorkTime
                key={index}
                labelStart={'Начало перерыва'}
                labelEnd={'Конец перерыва'}
                onChange={(time, location) => {
                  const copy = [...breakTimes];
                  copy[index][location] = time;
                  setBreakTimes(copy);
                }}
                value={item}
                onDelete={() =>
                  setBreakTimes(
                    breakTimes.filter((_, breakIndex) => breakIndex !== index),
                  )
                }
              />
            ))}
          </Box>
          {breakTimes.length < 1 && !dayoff && (
            <Button
              variant="outline"
              leftIcon={<Plus color={colors.mainPrimary} size={20} />}
              onClick={() => {
                setBreakTimes(prev => [
                  ...prev,
                  {start: '13:00', end: '14:00'},
                ]);
              }}>
              Добавить перерыв
            </Button>
          )}
        </Box>
        <Button
          size="large"
          loading={loading}
          onClick={async () => {
            try {
              setLoading(true);
              if (dayoff) {
                deleteWorkingDates(selects);
                return;
              }
              selects.forEach(select => {
                const {id} = select;
                if (id) {
                  return updateWorkingDate({
                    variables: {
                      input: {
                        id,
                        break_times: breakTimes,
                        start_time: times.start,
                        end_time: times.end,
                      },
                    },
                  });
                }
                return createWorkDates({
                  variables: {
                    input: {
                      company_id: companyId,
                      employee_id: employeeId,
                      from: select.date,
                      to: select.date,
                      type: WorkingDateType.Everyday,
                      start_time: times.start,
                      end_time: times.end,
                      break_times: breakTimes,
                    },
                  },
                });
              });
            } catch (e) {
              if (e instanceof ApolloError) {
                showAlert('error', e.message);
              }
            } finally {
              setLoading(false);
              onClose();
            }
          }}>
          Сохранить
        </Button>
      </ContentGrow>
    </Popup>
  );
};
