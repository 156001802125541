import React from 'react';
import styled from 'styled-components';
import {Text} from 'shared/ui/Text';
import {Header} from 'shared/ui/Header';
import {device} from 'shared/device';
import {useResize} from 'shared/lib/hooks';
import {ArrowLeft2v} from 'shared/icons/ArrowLeft2v';
import {Button} from 'shared/ui/Button';
import {useNavigate} from 'react-router-dom';
import {useAppSelector} from 'shared/store';

const Wrapper = styled(Header)`
  position: relative;
  padding: 0px;
  margin-left: 24px;
  margin-top: 24px;
  height: 56px;
  @media ${device.mobile} {
    background-color: ${({theme}) => theme.bgPrimary};
    margin: 0;
    padding: 16px 16px 16px 0;
  }
`;

const NavButton = styled(Button)`
  background-color: ${({theme}) => theme.bgPrimary};
  width: 48px;
  height: 48px;
  margin-left: 6px;
  padding: 0;
`;

const H1 = styled(Text)`
  text-align: center;
  width: max-content;
  display: flex;
  gap: 10px;
  flex-direction: row;
`;

export const ReviewsHeader = ({count}: {count: number}) => {
  const isMobile = useResize().isMobile;
  const navigate = useNavigate();
  const username = useAppSelector(state => state.company.data?.username);

  return (
    <Wrapper>
      {isMobile ? (
        <>
          <NavButton
            onClick={() => {
              navigate(`/${username}/loyalty`);
            }}>
            <ArrowLeft2v />
          </NavButton>
          <Text
            typography="text16Semibold"
            align="center"
            style={{width: '75%'}}>
            Отзывы
          </Text>
        </>
      ) : (
        <H1 typography="title24" style={{display: isMobile ? 'none' : 'flex'}}>
          Отзывы
          <Text typography="title24" color="textTertiary">
            {count}
          </Text>
        </H1>
      )}
    </Wrapper>
  );
};
