import React, {useState} from 'react';
import {BookingsHeader} from './ui/BookingsHeader';
import {BookingsEmptyState} from './ui/BookingsEmptyState';
import {FixedCalendar} from './ui/FixedCalendar';
import {BookingsSkeleton} from './ui/Skeleton';
import {useQuery} from '@apollo/client';
import {Error} from 'shared/ui/Error';
import {useAppSelector} from 'shared/store';
import {getName} from 'shared/lib/utils';
import {Seo} from 'shared/ui/Seo';
import dayjs from 'dayjs';
import {BOOKINGS} from 'entities/booking';
import {useEmployeePermission} from 'shared/lib/hooks';
import {Flex} from 'shared/ui/Flex';
import {ModalFilter} from './ui/ModalFIlter';
import {TimeLines} from './ui/TimeLines';
import {Filters} from './ui/Filters';
import {Layout} from 'shared/ui/Layout';

const getMinWorkingDateStart = (data: string[], bookings: string[]) => {
  let temp = data.map(item => +item.split(':')[0] * 60 + +item.split(':')[1]);

  temp = temp.concat(
    bookings.map(item => +item.split(':')[0] * 60 + +item.split(':')[1]),
  );

  return Math.min(...temp) ?? 8 * 60;
};

const getMaxWorkingDateEnd = (data: string[]) => {
  const temp = data.map(item => +item.split(':')[0] * 60 + +item.split(':')[1]);
  return Math.max(...temp) ?? 18 * 60;
};

export function Bookings() {
  const companyId = useAppSelector(state => state.company.data!.id);
  const fixedCalendar = useAppSelector(
    state => state.booking.filter.fixedCalendar,
  );
  const date = useAppSelector(state => state.booking.date);
  const employeeWithBooking = useAppSelector(
    state => state.booking.filter.employeeWithBooking,
  );
  const employeeWithoutBooking = useAppSelector(
    state => state.booking.filter.employeeWithoutBooking,
  );
  const selectProfessions = useAppSelector(
    state => state.booking.filter.professions,
  );
  const column = useAppSelector(state => state.booking.filter.countColumn);
  const countColumn = column === 'Автоматически' ? 4 : +column;
  const {data, loading, refetch, error} = useQuery(BOOKINGS, {
    variables: {
      companyId: companyId,
      date: [date],
      companyBookingsDate2: date,
      haveBookings:
        employeeWithBooking === employeeWithoutBooking
          ? undefined
          : employeeWithBooking
          ? true
          : false,
      profession: selectProfessions.length > 0 ? selectProfessions : undefined,
      first: countColumn,
    },
  });
  const currentPage = data?.employeeFilters?.paginatorInfo.currentPage ?? 1;
  const lastPage = data?.employeeFilters?.paginatorInfo.lastPage ?? 1;

  const {
    create_bookings_permission,
    work_schedule_info_permission,
    employees_info_permission,
    edit_work_schedule_permission,
  } = useEmployeePermission(data);
  const [filterVisible, setFilterVisible] = useState(false);

  const resDataEmployees = data?.employeeFilters?.data ?? [];
  const workingDates =
    resDataEmployees
      .map(item => item.workingDates)
      .flat()
      .filter(item => item.date === date) ?? [];

  const bookings = data?.companyBookings ? [...data.companyBookings] : [];

  const start_time = getMinWorkingDateStart(
    workingDates.map(itemDate => itemDate.start_time),
    bookings.map(booking => booking.start),
  );
  const end_time = getMaxWorkingDateEnd(
    workingDates.map(itemDate => itemDate.end_time),
  );
  const professions =
    data?.companyProfessions?.map(item => ({
      id: item?.id,
      name: item?.name,
    })) ?? [];

  const sortBookings = bookings
    .filter(booking => booking.workingDate.date === date)
    .map(booking => ({
      id: booking.id,
      start: booking.start,
      end: booking.end,
      employee_id: booking.employee_id,
      workingDate: booking.workingDate,
      customer_id: booking.customer?.id,
      customer_name: getName(
        booking.customer?.name !== '' ? booking.customer?.name : undefined,
        booking.customer?.surname !== ''
          ? booking.customer?.surname
          : undefined,
      ),
      services: booking.bookingServices.map(service => ({
        name: service.service.name,
        price:
          service.discount?.type === 'percentage'
            ? +(service.price ?? 0) -
              (+(service.price ?? 0) / 100) * service.discount.value +
              +(service.additional_price ?? 0)
            : +(service.price ?? 0) -
              (service.discount?.value ?? 0) +
              +(service.additional_price ?? 0),
        resources: service.service.resources ?? [],
      })),
      customer_phone: booking.customer?.phone,
      is_vip: booking.customer?.is_vip,
      price: booking.bookingServices.reduce((acc, cur) => {
        if (cur.discount) {
          if (cur.discount.type === 'fixed') {
            return (
              acc +
              +(cur.additional_price ?? 0) +
              Math.max(+(cur.price ?? 0) - cur.discount.value, 0)
            );
          } else {
            return (
              acc +
              +(cur.additional_price ?? 0) +
              (+(cur.price ?? 0) -
                (+(cur.price ?? 0) * cur.discount.value) / 100)
            );
          }
        } else {
          return acc + +(cur.price ?? 0) + +(cur.additional_price ?? 0);
        }
      }, 0),
      status: booking.status,
    }))
    .sort((a, b) => {
      return dayjs(a.workingDate.date + a.start).isBefore(
        dayjs(b.workingDate.date + b.start),
        'minute',
      )
        ? -1
        : 1;
    });

  if (loading) {
    return <BookingsSkeleton />;
  }

  if (error) {
    <Error message={error.message} />;
  }

  return (
    <Layout
      columns={16}
      variant="sidebar"
      style={{overflowY: 'initial', height: 'initial'}}>
      <Flex
        flex={1}
        direction="column"
        gap={24}
        style={{
          gridColumnStart: 1,
          gridColumnEnd: fixedCalendar ? 16 : 17,
          marginLeft: 24,
          marginRight: fixedCalendar ? 0 : 24,
        }}>
        <Seo title="Журнал записи" />
        <BookingsHeader
          employeeCount={resDataEmployees.length ?? 0}
          createPermission={create_bookings_permission}
          openFilter={() => setFilterVisible(true)}
          workingDates={workingDates}
        />
        <Filters
          professions={professions.filter(item =>
            selectProfessions.includes(item.id!),
          )}
        />
        {resDataEmployees.length > 0 ? (
          <TimeLines
            employees={
              [...resDataEmployees]
                .sort(function (a, b) {
                  return (a.position ?? 0) - (b.position ?? 0);
                })
                .map(item => ({
                  id: item.id,
                  name: item.name,
                  surname: item.surname,
                  online: item.user?.is_online ?? false,
                  profession:
                    item.professions.find((_, index) => index === 0)?.name ??
                    undefined,
                  avatar: item.avatar?.url,
                  workingDates: item.workingDates.find(
                    workdate => workdate.date === date,
                  ),
                })) ?? []
            }
            columns={countColumn}
            bookings={sortBookings}
            length={resDataEmployees.length ?? 1}
            createPermission={create_bookings_permission}
            employeesInfoPermission={employees_info_permission}
            editWorkSchedulePermission={edit_work_schedule_permission}
            start_time={start_time}
            end_time={end_time}
            onPrevPage={
              currentPage > 1
                ? () => {
                    refetch({
                      page: currentPage - 1,
                    });
                  }
                : undefined
            }
            onNextPage={
              currentPage === lastPage
                ? undefined
                : () => {
                    refetch({
                      page: currentPage + 1,
                    });
                  }
            }
          />
        ) : (
          <BookingsEmptyState
            work_schedule_info_permission={work_schedule_info_permission}
          />
        )}
      </Flex>
      {fixedCalendar && <FixedCalendar />}
      <ModalFilter
        visible={filterVisible}
        onClose={() => setFilterVisible(false)}
        professions={professions}
      />
    </Layout>
  );
}
