import React, {useState} from 'react';
import {useColors} from 'shared/lib/hooks';
import {Content} from 'shared/ui/Content';
import {Divider} from 'shared/ui/Divider';
import dayjs from 'dayjs';
import {Text} from 'shared/ui/Text';
import {ColorsType} from 'shared/colors';
import {Flex} from 'shared/ui/Flex';
import styled from 'styled-components';

type ModerationStatus = 'approved' | 'pending' | 'declined';
interface Item {
  status: ModerationStatus;
  comment?: string | null;
  created_at: string;
  id: string;
}

const CommentText = styled(Text)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  white-space: normal;
  overflow-wrap: break-word;
`;
const getStatus = (
  status: ModerationStatus,
): {title: string; color: ColorsType} => {
  switch (status) {
    case 'approved':
      return {
        title: 'Опубликовано',
        color: 'successPrimary',
      };
    case 'pending':
      return {title: 'В обработке', color: 'mainPrimary'};
    case 'declined':
      return {title: 'На доработку', color: 'dangerPrimary'};
    default:
      throw new Error(`Unknown status: ${status}`);
  }
};
export const ModerationCard = ({item}: {item: Item}) => {
  const colors = useColors();
  const {title, color} = getStatus(item.status);
  const [show, setShow] = useState(false);
  const toggleText = () => setShow(!show);
  return (
    <Content gap="8px">
      <Content
        style={{
          height: 36,
          alignSelf: 'flex-start',
          borderRadius: 12,
          justifyContent: 'center',
          backgroundColor:
            item.status === 'declined'
              ? colors.bgDanger
              : item.status === 'approved'
              ? colors.bgSuccess
              : item.status === 'pending'
              ? colors.mainLight
              : 'defaultBackgroundColor',
          padding: '8px 14px',
        }}>
        <Text typography="text16Regular" color={color}>
          {title}
        </Text>
      </Content>
      <CommentText
        typography="text16Regular"
        style={show ? {display: 'block'} : {}}>
        {item.status === 'declined' ? `Причина: ${item.comment} ` : '-'}
      </CommentText>

      {item.status === 'declined' && item.comment!.length > 140 && (
        <Text
          typography="text16Regular"
          color="mainPrimary"
          onClick={toggleText}
          style={{cursor: 'pointer'}}>
          {show ? 'Скрыть' : 'Показать все'}
        </Text>
      )}

      <Divider />
      <Flex direction="column" gap={16}>
        <Flex justifyContent="space-between">
          <Text typography="subHead14Regular" color="textTertiary">
            Дата отправки
          </Text>
          <Text typography="subHead14Regular">
            {dayjs(item.created_at).format('DD.MM.YYYY')}
          </Text>
        </Flex>
        <Flex justifyContent="space-between">
          <Text typography="subHead14Regular" color="textTertiary">
            ID
          </Text>
          <Text typography="subHead14Regular">{item.id}</Text>
        </Flex>
      </Flex>
    </Content>
  );
};
