import React from 'react';
import {ReactNode} from 'react';
import {device} from 'shared/device';
import styled from 'styled-components';

export const Wrapper = styled.div<{
  columns: number;
  variant?: 'stack' | 'sidebar';
}>`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 24px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1464px;
  background-color: ${({theme}) => theme.bgSecondary};
  min-height: 100vh;
  @media ${device.mobile} {
    display: block;
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export const ProfileContainer = ({children}: {children: ReactNode}) => {
  return (
    <Wrapper
      variant="sidebar"
      columns={12}
      style={{
        paddingTop: 64,
      }}>
      {children}
    </Wrapper>
  );
};
