import React, {useState} from 'react';
import {Container} from './ui/Container';
import {useParams} from 'react-router-dom';
import {BookingPaymentInfo} from './ui/BookingPaymentInfo';
import {useMutation, useQuery} from '@apollo/client';
import {getName, getNumberFormat, getPriceFormat} from 'shared/lib/utils';
import {Content} from 'shared/ui/Content';
import {Button} from 'shared/ui/Button';
import {Plus} from 'shared/icons/Plus';
import {Text} from 'shared/ui/Text';
import {useColors, useEmployeePermission} from 'shared/lib/hooks';
import {AddPayment} from './ui/AddPayment';
import {DELETE_PAYMENT, PAYMENTS} from 'entities/booking';
import {PaymentMethodEnum} from 'shared/__generated__/graphql';
import {IconButton} from 'shared/ui/IconButton';
import {Trash} from 'shared/icons/Trash';
import {Flex} from 'shared/ui/Flex';
import {SkeletonPayment} from './ui/Skeleton';
import {Table} from 'shared/ui/Table';
import {showAlert} from 'shared/ui/Alert';
import {Seo} from 'shared/ui/Seo';
import {useAppSelector} from 'shared/store';

const paymentMethod: Record<PaymentMethodEnum, string> = {
  cash: 'Наличными',
  card: 'Картой',
  bonus_card: 'Бонусами',
  certificate: 'Сертификатом',
};

export const PaymentBooking = () => {
  const params = useParams();
  const companyId = useAppSelector(state => state.company.data?.id);
  const {id} = params;
  const {data, loading} = useQuery(PAYMENTS, {
    variables: {
      bookingId: id!,
      companyId: companyId!,
    },
  });
  const {edit_bookings_permission} = useEmployeePermission(data);
  const payments = data?.payments ?? [];
  const [deletePayment] = useMutation(DELETE_PAYMENT, {
    refetchQueries: [
      {
        query: PAYMENTS,
        variables: {
          companyId: companyId!,
          bookingId: id,
        },
      },
    ],
  });
  const titles = ['Способ оплаты', 'Оплачено'];
  const [visible, setVisible] = useState(false);
  const colors = useColors();
  const total = data?.booking?.bookingServices.reduce((acc, cur) => {
    if (cur.discount) {
      if (cur.discount.type === 'fixed') {
        return (
          acc +
          +(cur.additional_price ?? '') +
          Math.max(+(cur.price ?? '') - cur.discount.value, 0)
        );
      }
      return (
        acc +
        +(cur.additional_price ?? '') +
        +(cur.price ?? '') -
        (+(cur.price ?? '') / 100) * cur.discount.value
      );
    }
    return acc + +(cur.additional_price ?? '') + +(cur.price ?? '');
  }, 0);
  const totalBonus =
    data?.payments.reduce((acc, cur) => {
      if (cur.method === PaymentMethodEnum.BonusCard) {
        return acc + cur.amount;
      }
      return acc;
    }, 0) ?? 0;

  if (loading) {
    return <SkeletonPayment />;
  }

  return (
    <Container>
      <Seo title="Оплата и чеки" />
      <Content gap={'32px'}>
        <BookingPaymentInfo
          name={getName(
            data?.booking?.customer?.name,
            data?.booking?.customer?.surname,
          )}
          total={total ?? 0}
        />
        {data?.payments.length ? (
          <Flex direction="column" gap={16}>
            <Table
              heightRow="60px"
              widths={[166, 445, 60]}
              titles={titles.map(item => (
                <Text
                  key={'title_head_' + item}
                  typography="subHead14Medium"
                  color="textTertiary">
                  {item}
                </Text>
              ))}
              styleTd={{
                padding: '6px 0px',
                borderBottom: `1px solid ${colors.borderPrimary}`,
              }}
              styleTh={{padding: '16px 0px'}}
              rows={payments.map(payment => [
                <Flex
                  alignItems="center"
                  justifyContent="flex-start"
                  key={'body_method_' + payment.id}>
                  <Text typography="text16Regular" color="textPrimary">
                    {paymentMethod[payment.method]}
                  </Text>
                </Flex>,
                <Flex
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  style={{width: '100%'}}
                  key={'body_amount_' + payment.id}>
                  <Text typography="text16Regular" color="textPrimary">
                    {payment.method === PaymentMethodEnum.BonusCard
                      ? getNumberFormat(payment.amount, ' Б')
                      : getPriceFormat(payment.amount)}
                  </Text>
                  <IconButton
                    variant="unstyled"
                    onClick={() => {
                      deletePayment({
                        variables: {
                          deletePaymentId: payment.id,
                        },
                      }).catch(e => {
                        showAlert('error', e.message);
                      });
                    }}>
                    <Trash color={colors.dangerPrimary} />
                  </IconButton>
                </Flex>,
              ])}
            />
            <Flex direction="row" justifyContent="space-between">
              <Text>Итого</Text>
              <Text typography="title18">
                {getPriceFormat(
                  data?.payments.reduce((acc, cur) => {
                    if (cur.method !== PaymentMethodEnum.BonusCard) {
                      return acc + cur.amount;
                    }
                    return acc;
                  }, 0),
                )}
                {totalBonus > 0 ? ', ' + totalBonus + ' Б' : ''}
              </Text>
            </Flex>
          </Flex>
        ) : (
          <></>
        )}
        <Flex direction="column" gap={16}>
          {(data?.payments.reduce((acc, cur) => {
            if (cur.method !== PaymentMethodEnum.BonusCard) {
              return acc + cur.amount;
            }
            return acc;
          }, 0) ?? 0) +
            totalBonus <
            (total ?? 0) &&
            edit_bookings_permission && (
              <Button
                variant="outline"
                size="large"
                onClick={() => setVisible(true)}>
                <Plus color={colors.mainPrimary} />
                <Text typography="text16Regular" color="mainPrimary">
                  Добавить платеж
                </Text>
              </Button>
            )}
        </Flex>
      </Content>
      <AddPayment
        id={id!}
        total={total}
        visible={visible}
        customerId={data?.booking?.customer?.id}
        paid={data?.payments.reduce((acc, curItem) => {
          return acc + curItem.amount;
        }, 0)}
        onClose={() => setVisible(false)}
        date={data?.booking?.workingDate.date}
      />
    </Container>
  );
};
