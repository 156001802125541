import React from 'react';
import {
  CompanySubscriptions,
  SubscriptionsType,
} from 'shared/__generated__/graphql';
import CircleAccept from 'shared/icons/CircleAccept';
import {useColors} from 'shared/lib/hooks';
import {Flex} from 'shared/ui/Flex';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';

type Subscriptions = Pick<
  CompanySubscriptions,
  'id' | 'created_at' | 'slots' | 'summary' | 'type' | '__typename'
>;

const SubscriptionBox = styled.div`
  display: flex;
  border-radius: 20px;
  border: 1px solid ${({theme}) => theme.borderPrimary};
  background-color: ${({theme}) => theme.bgTertiary};
  padding: 11px 16px;
  gap: 16px;
  align-items: center;
`;
const Circle = styled.div<{status: 'active'}>`
  display: flex;
  height: 56px;
  width: 56px;
  background-color: ${({theme}) => theme.bgSuccess};
  border-radius: 28px;
  justify-content: center;
  align-items: center;
`;

export const getCountAndNameMonth = (value: SubscriptionsType) => {
  switch (value) {
    case 'sixmonths':
      return '6 месяцев';
    case 'threemonths':
      return '3 месяца';
    case 'year':
      return '12 месяцев';
    case 'forever':
      return 'бесплатный';
    default:
      return '1 месяц';
  }
};

export const Subscription = ({subscription}: {subscription: Subscriptions}) => {
  const colors = useColors();
  return (
    <SubscriptionBox>
      <Circle status="active">
        <CircleAccept size={32} color={colors.successPrimary} />
      </Circle>
      <Flex direction="column" flex={1}>
        <Text typography="text16Semibold">
          Тариф {getCountAndNameMonth(subscription.type)}
        </Text>
        <Text typography="subHead14Regular" color="textTertiary">
          до {subscription.slots} сотрудников
        </Text>
      </Flex>
      <Text typography="text16Semibold">— {subscription.summary} ₽</Text>
    </SubscriptionBox>
  );
};
