import React from 'react';
import styled from 'styled-components';
import {Text} from 'shared/ui/Text';
import Accept from 'shared/icons/Accept';
import {CustomSlide} from './CustomSlide';
import {Flex} from 'shared/ui/Flex';
import {salaryPresets, schedulePresets} from 'shared/lib/utils';

const Preset = styled.button`
  display: flex;
  border: none;
  min-height: 48px;
  background-color: transparent;
  padding: 0 16px;
  align-items: center;
  justify-content: space-between;
  border-radius: 12px;
  &:hover {
    background-color: ${({theme}) => theme.bgSecondary};
  }
`;

export const Presets = ({
  active,
  onSelect,
  type,
}: {
  active: number;
  onSelect: (index: number) => void;
  type: 'salary' | 'schedule';
}) => {
  const data = type === 'salary' ? salaryPresets : schedulePresets;
  return (
    <CustomSlide gap={16}>
      <Flex
        flex={1}
        justifyContent="space-between"
        style={{maxHeight: 300, overflowY: 'auto'}}
        direction="column">
        {data.map((preset, index) => (
          <Preset onClick={() => onSelect(index)} key={index + '_preset'}>
            <Text>{preset.label}</Text>
            {active === index && <Accept />}
          </Preset>
        ))}
      </Flex>
    </CustomSlide>
  );
};
