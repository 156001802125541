import {
  MessageMethod,
  MessageStatus,
  MessageType,
  QueryGetMessagesOrderByColumn,
} from 'shared/__generated__/graphql';

export const sortTypings = [
  {
    label: 'По дате (от новых к старым)',
    value: QueryGetMessagesOrderByColumn.CreatedAt,
  },
  {
    label: 'По дате (от старых к новым)',
    value: QueryGetMessagesOrderByColumn.CreatedAt,
  },
  {label: 'По имени (клиенты)', value: QueryGetMessagesOrderByColumn.Customer},
];

export const statusNotification: {label: string; value?: MessageStatus}[] = [
  {label: 'Все', value: undefined},
  {label: 'Не отправлено', value: MessageStatus.Cancelled},
  {label: 'Отправленные', value: MessageStatus.Completed},
  {label: 'В ожидании', value: MessageStatus.Pending},
];

export const methodSending: {label: string; value?: MessageMethod}[] = [
  {label: 'Все', value: undefined},
  {label: 'Ручная рассылка смс', value: MessageMethod.Sms},
  {label: 'Ручная рассылка Whatsapp', value: MessageMethod.Whatsapp},
  {label: 'Ручная рассылка Telegram', value: MessageMethod.Telegram},
  {label: 'ChatPush-WhatsApp', value: MessageMethod.CpWhatsapp},
  {label: 'ChatPush-Telegram', value: MessageMethod.CpTelegram},
  {label: 'sms.ru', value: MessageMethod.SmsRu},
];

export const messageTypes: {label: string; value?: MessageType}[] = [
  {label: 'Все', value: undefined},
  {label: 'Ручные', value: MessageType.Manual},
  {label: 'Автоматические', value: MessageType.Auto},
];
