import React, {useEffect} from 'react';
import {TextInput} from 'shared/ui/TextInput';
import {Text} from 'shared/ui/Text';
import {useLocation, useNavigate} from 'react-router-dom';
import {useState} from 'react';
import {resetPassword} from 'shared/lib/api';
import {ensureError} from 'shared/lib/utils';
import {Button} from 'shared/ui/Button';
import {Content} from 'shared/ui/Content';
import {Flex} from 'shared/ui/Flex';
import {showAlert} from 'shared/ui/Alert';
import {NavigateBack} from 'shared/ui/NavigateBack';
import {Seo} from 'shared/ui/Seo';
export function NewPassword() {
  const navigate = useNavigate();
  const {phone, code} = useLocation().state;
  const [repeat, setRepeat] = useState('');
  const [loading, setLoading] = useState(false);
  const [pass, setPass] = useState('');
  const [error, setError] = useState<string | null>(null);
  useEffect(() => {
    setError(null);
  }, [repeat, pass]);
  return (
    <Content gap="24px">
      <Seo title="Новый пароль" />
      <Flex alignItems="center" gap={16}>
        <NavigateBack />
        <Text typography="title20">Новый пароль</Text>
      </Flex>
      <Flex direction="column" gap={16}>
        <TextInput
          type="password"
          wrapperStyle={{gap: 0}}
          value={pass}
          error={error ? ' ' : null}
          label={'Новый пароль'}
          onChange={setPass}
        />
        <TextInput
          type="password"
          value={repeat}
          error={error}
          label={'Повторите пароль'}
          onChange={setRepeat}
        />

        <Button
          size="large"
          disabled={pass.length < 8 || repeat.length < 8 || loading}
          onClick={async () => {
            if (repeat !== pass) {
              setError('Пароли не совпадают');
            } else {
              setLoading(true);
              try {
                await resetPassword(code, pass, phone);
                showAlert('success', 'Пароль успошно изменён');
                navigate('/password');
              } catch (err) {
                const error = ensureError(err);
                showAlert('error', error.message);
              } finally {
                setLoading(false);
              }
            }
          }}
          loading={loading}>
          Сохранить
        </Button>
      </Flex>
      <Text align="center" typography="subHead14Regular" color="textTertiary">
        Минимальная длина пароля — 8 символов.
      </Text>
    </Content>
  );
}
