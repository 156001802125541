import React from 'react';
import {Skeleton} from 'shared/ui/Skeleton';
import {Content} from 'shared/ui/Content';
import {Text} from 'shared/ui/Text';
import {styled} from 'styled-components';
import {Layout} from 'shared/ui/Layout';
import {Head} from 'shared/ui/Head';
import {NavigateBack} from 'shared/ui/NavigateBack';

function columns(number: number) {
  return Array.from(Array(number).keys());
}

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  grid-column-start: 4;
  grid-column-end: 10;
  padding: 64px 0 24px;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const SkeletonEdit = () => {
  return (
    <Layout columns={12}>
      <Wrapper>
        <Head>
          <NavigateBack />
          <Text typography="title24">Редактирование сотрудника</Text>
        </Head>
        <Content style={{marginTop: 8}}>
          <Text typography="title18">Основная информация</Text>
          <Skeleton height={100 + 16 + 64 * 5 + 16 * 4}>
            <circle r={50} cx={'calc(100% - 50%)'} cy={50} />
            {columns(5).map((columnItem, index) => (
              <React.Fragment key={'column_' + columnItem}>
                <rect
                  width={'100%'}
                  height={64}
                  rx={20}
                  x={0}
                  y={index * (64 + 16) + 100 + 16}
                />
              </React.Fragment>
            ))}
          </Skeleton>
        </Content>
        <Content style={{marginTop: 24}}>
          <Text typography="title18">Дополнительно</Text>
          <Skeleton height={64 * 2 + 16}>
            {columns(2).map((columnItem, index) => (
              <React.Fragment key={'column_' + columnItem}>
                <rect
                  width={'100%'}
                  height={64}
                  rx={20}
                  x={0}
                  y={index * (64 + 16)}
                />
              </React.Fragment>
            ))}
          </Skeleton>
        </Content>
      </Wrapper>
    </Layout>
  );
};
