import React, {useState} from 'react';
import {Grow} from 'shared/ui/Grow';
import {DiscountsHeader} from './ui/Header';
import {gql} from 'shared/__generated__';
import {ApolloError, useMutation, useQuery} from '@apollo/client';
import {DiscountsTable} from './ui/DiscountsTable';
import {EmptyState} from './ui/EmptyState';
import {DiscountsSkeleton} from './ui/Skeleton';
import {Setting} from './ui/Setting';
import {useAppSelector} from 'shared/store';
import {styled} from 'styled-components';
import {OptionsType} from 'shared/ui/Table';
import {showAlert} from 'shared/ui/Alert';
import {FooterAction} from 'shared/ui/FooterAction';
import {Seo} from 'shared/ui/Seo';
import {useEmployeePermission} from 'shared/lib/hooks';
import {SALE_SCREEN} from 'entities/discounts';

const DELETE_DISCOUNT = gql(`
  mutation DeleteDiscount($deleteDiscountId: String!) {
    deleteDiscount(id: $deleteDiscountId) {
      id
    }
  }
`);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const Discounts = () => {
  const companyId = useAppSelector(state => state.company.data?.id ?? '');
  const [options, setOptions] = useState<OptionsType>({
    page: 1,
    first: 10,
    total: 0,
    count: 0,
    lastPage: 1,
  });
  const [search, setSearch] = useState('');
  const [selects, setSelects] = useState<string[]>([]);
  const [deleteDiscount] = useMutation(DELETE_DISCOUNT);
  const {data, loading, refetch} = useQuery(SALE_SCREEN, {
    variables: {
      companyId,
      first: options.first,
      page: options.page,
      name: search,
    },
    onCompleted: res =>
      setOptions(prev => ({
        ...prev,
        total: res.discounts?.paginatorInfo.total,
        count: res.discounts?.paginatorInfo.count,
        lastPage: res.discounts?.paginatorInfo.lastPage,
      })),
  });
  const {edit_discounts_permission} = useEmployeePermission(data);
  const discounts = data?.discounts?.data ?? [];

  const handleSelectionChange = (indexes: number[]) => {
    const newSelectedData = indexes.map(
      index => discounts?.[index].id ?? undefined,
    );
    setSelects(newSelectedData);
  };
  const onDeleteDiscounts = () => {
    selects.forEach(item => {
      deleteDiscount({
        variables: {
          deleteDiscountId: item,
        },
      })
        .then(() => {
          setSelects([]);
          refetch();
        })
        .catch((e: ApolloError) => showAlert('error', e.message));
    });
  };

  return (
    <Wrapper>
      <Seo title="Акции и скидки" />
      <Grow style={{padding: 24, gap: 24}}>
        <DiscountsHeader number={options.total} />
        <Setting search={search} onChangeText={setSearch} />
        {loading ? (
          <DiscountsSkeleton />
        ) : discounts?.length ? (
          <DiscountsTable
            discounts={discounts}
            options={options}
            setOptions={setOptions}
            onSelectionChange={handleSelectionChange}
          />
        ) : (
          <EmptyState search={search} />
        )}
      </Grow>
      {selects.length > 0 ? (
        <FooterAction
          allICount={options.count}
          selectedCount={selects.length}
          editPermission={edit_discounts_permission}
          disabled={selects.length === 0}
          onDelete={onDeleteDiscounts}
          title="Удалить акции и скидки"
          text="Вы уверены, что хотите удалить акции и скидки?"
        />
      ) : null}
    </Wrapper>
  );
};
