import React from 'react';

export function All() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 6.78947C3 5.0031 3 4.10991 3.55496 3.55496C4.10991 3 5.0031 3 6.78947 3C8.57585 3 9.46904 3 10.024 3.55496C10.5789 4.10991 10.5789 5.0031 10.5789 6.78947C10.5789 8.57585 10.5789 9.46904 10.024 10.024C9.46904 10.5789 8.57585 10.5789 6.78947 10.5789C5.0031 10.5789 4.10991 10.5789 3.55496 10.024C3 9.46904 3 8.57585 3 6.78947Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M13.4211 17.2105C13.4211 15.4242 13.4211 14.531 13.976 13.976C14.531 13.4211 15.4242 13.4211 17.2105 13.4211C18.9969 13.4211 19.8901 13.4211 20.445 13.976C21 14.531 21 15.4242 21 17.2105C21 18.9969 21 19.8901 20.445 20.445C19.8901 21 18.9969 21 17.2105 21C15.4242 21 14.531 21 13.976 20.445C13.4211 19.8901 13.4211 18.9969 13.4211 17.2105Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M3 17.2105C3 15.4242 3 14.531 3.55496 13.976C4.10991 13.4211 5.0031 13.4211 6.78947 13.4211C8.57585 13.4211 9.46904 13.4211 10.024 13.976C10.5789 14.531 10.5789 15.4242 10.5789 17.2105C10.5789 18.9969 10.5789 19.8901 10.024 20.445C9.46904 21 8.57585 21 6.78947 21C5.0031 21 4.10991 21 3.55496 20.445C3 19.8901 3 18.9969 3 17.2105Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M13.4211 6.78947C13.4211 5.0031 13.4211 4.10991 13.976 3.55496C14.531 3 15.4242 3 17.2105 3C18.9969 3 19.8901 3 20.445 3.55496C21 4.10991 21 5.0031 21 6.78947C21 8.57585 21 9.46904 20.445 10.024C19.8901 10.5789 18.9969 10.5789 17.2105 10.5789C15.4242 10.5789 14.531 10.5789 13.976 10.024C13.4211 9.46904 13.4211 8.57585 13.4211 6.78947Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  );
}
