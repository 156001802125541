import React from 'react';
import {DoubleArrow} from 'shared/icons/DoubleArrow';
import styled from 'styled-components';

export const BtnWrapper = styled.div<{open?: boolean}>`
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background-color: ${props => props.theme.bgPrimary};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  top: 28px;
  z-index: 100;
  padding: 0;
  right: -20px;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.borderPrimary};
  position: absolute;
  pointer-events: all;
  box-shadow: 0px 0px 15px 4px #1d1b1f14;
  transform: ${props => (props.open ? 'rotateZ(0deg)' : 'rotateZ(180deg)')};
  transition:
    transform 300ms ease-out,
    opacity 300ms ease-out,
    left 300ms ease-out;
  opacity: 0;
`;

export function OpenButton({
  onChange,
  open,
}: {
  onChange?: (open: boolean) => void;
  open?: boolean;
}) {
  return (
    <BtnWrapper
      open={open}
      onClick={e => {
        e.stopPropagation();
        onChange && onChange(!open);
      }}>
      <DoubleArrow />
    </BtnWrapper>
  );
}
