import {PayloadAction, createSlice} from '@reduxjs/toolkit';

type Theme = 'light' | 'dark';

interface ThemeStateInterface {
  theme: Theme;
}

const initialState: ThemeStateInterface = {
  theme: window.matchMedia('(prefers-color-scheme: dark)').matches
    ? 'dark'
    : 'light',
};

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setThemeSlice: (state, action: PayloadAction<Theme>) => {
      state.theme = action.payload;
    },
  },
});

export const {setThemeSlice} = themeSlice.actions;
export default themeSlice.reducer;
