import React from 'react';
import {useNavigate} from 'react-router-dom';
import {EmptyBonusCard} from 'shared/illustration/EmptyBonusCard';
import {useAppSelector} from 'shared/store';
import {Button} from 'shared/ui/Button';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  padding-top: 52px;
`;

export const EmptyState = ({search}: {search: string}) => {
  const navigate = useNavigate();
  const username = useAppSelector(state => state.company.data?.username);
  if (search === '') {
    return (
      <Wrapper>
        <EmptyBonusCard size={300} />
        <Text
          typography="text18"
          color="textTertiary"
          style={{textAlign: 'center'}}>
          У вас нету бонусных карт. <br /> Cоздайте бонусную карту и привлекайте
          больше <br /> клиентов
        </Text>
        <Button
          size="large"
          typography="text16Semibold"
          style={{marginTop: 12, width: 'max-content'}}
          onClick={() => {
            navigate(`/${username}/create-bonus`);
          }}>
          Добавить бонусную карту
        </Button>
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      <EmptyBonusCard size={300} />
      <Text
        typography="text18"
        color="textTertiary"
        style={{textAlign: 'center'}}>
        Бонусная карта не найдена
      </Text>
    </Wrapper>
  );
};
