import React from 'react';
import {Content} from 'shared/ui/Content';
import {Flex} from 'shared/ui/Flex';
import {Text} from 'shared/ui/Text';

export const TariffBox = ({
  children,
  title,
  gap,
  direction = 'column',
  alignItems,
}: React.PropsWithChildren<{
  title: string;
  gap?: number;
  direction?: 'row' | 'column';
  alignItems?: 'center' | 'start';
}>) => (
  <Content gap={'24px'}>
    <Text typography="title20">{title}</Text>
    <Flex gap={gap ?? 16} alignItems={alignItems} direction={direction}>
      {children}
    </Flex>
  </Content>
);
