import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import dayjs from 'dayjs';

type IChats = {
  chats: {
    id: number;
    created_at: string;
    message: string;
  }[];
  messages: {
    id: number;
    message: string;
    created_at: string;
    chat_id: number;
    type: 'system' | 'user';
  }[];
};

const initialState: IChats = {
  chats: [],
  messages: [],
};

const chatsSlice = createSlice({
  name: 'chats',
  initialState,
  reducers: {
    createChat: (
      state,
      action: PayloadAction<{id: number; message: string}>,
    ) => {
      state.chats = [
        {...action.payload, created_at: dayjs().toISOString()},
        ...state.chats,
      ];
    },
    clearChats: state => {
      state.chats = [];
      state.messages = [];
    },
    updateChat: (
      state,
      action: PayloadAction<{id: number; message: string}>,
    ) => {
      const temp = state.chats.map(item => ({
        id: item.id,
        created_at:
          item.id === action.payload.id
            ? dayjs().toISOString()
            : item.created_at,
        message:
          item.id === action.payload.id ? action.payload.message : item.message,
      }));
      state.chats = temp;
    },
    createMessage: (
      state,
      action: PayloadAction<{
        id: number;
        message: string;
        chat_id: number;
        type: 'system' | 'user';
      }>,
    ) => {
      state.messages = [
        ...state.messages,
        {
          ...action.payload,
          created_at: dayjs().toISOString(),
        },
      ];
      const temp = state.chats.map(item => ({
        id: item.id,
        created_at:
          item.id === action.payload.chat_id
            ? dayjs().toISOString()
            : item.created_at,
        message:
          item.id === action.payload.chat_id
            ? action.payload.message
            : item.message,
      }));
      state.chats = temp.sort(function (a, b) {
        return dayjs(a.created_at).isBefore(b.created_at) ? 1 : -1;
      });
    },
  },
});

export const {createChat, clearChats, updateChat, createMessage} =
  chatsSlice.actions;
export default chatsSlice.reducer;
