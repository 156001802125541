import {ApolloError, useMutation, useQuery} from '@apollo/client';
import {
  DELETE_PRODUCTS,
  FILL_PRODUCTS,
  GET_PRODUCTS_ONE,
  GET_HISTORY_RESERVE,
  REPEAL_PRODUCTS,
} from 'entities/products';
import React, {useEffect, useState} from 'react';
import {Outlet, useNavigate, useParams} from 'react-router-dom';
import {CircleClose} from 'shared/icons/CircleClose';
import {CirclePlus} from 'shared/icons/CirclePlus';
import {Minus} from 'shared/icons/Minus';
import {Pencil} from 'shared/icons/Pencil';
import {Plus} from 'shared/icons/Plus';
import {SquareTopUp} from 'shared/icons/SquareTopUp';
import {Trash} from 'shared/icons/Trash';
import Xv2 from 'shared/icons/Xv2';
import {useColors, useEmployeePermission} from 'shared/lib/hooks';
import {useAppSelector} from 'shared/store';
import {ActionIcon} from 'shared/ui/ActionIcon';
import {showAlert} from 'shared/ui/Alert';
import {Button} from 'shared/ui/Button';
import {Content} from 'shared/ui/Content';
import {Divider} from 'shared/ui/Divider';
import {Dropdown} from 'shared/ui/Dropdown';
import {Flex} from 'shared/ui/Flex';
import {Head} from 'shared/ui/Head';
import {IconButton} from 'shared/ui/IconButton';
import {Layout} from 'shared/ui/Layout';
import {Menu} from 'shared/ui/Menu';
import {Modal} from 'shared/ui/Modal';
import {PageHeader} from 'shared/ui/PageHeader';
import {Popup} from 'shared/ui/Popup';
import {Select} from 'shared/ui/Select';
import {Skeleton} from 'shared/ui/Skeleton';
import {TableViewRow} from 'shared/ui/TableViewRow';
import {Text} from 'shared/ui/Text';
import {TextInput} from 'shared/ui/TextInput';
import {styled} from 'styled-components';

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  grid-column-start: 1;
  grid-column-end: 4;
  margin-top: 136px;
  gap: 16px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  grid-column-start: 4;
  grid-column-end: 10;
  gap: 24px;
  padding-bottom: 24px;
`;

const MyTableViewRow = styled(TableViewRow)`
  &:hover {
    background-color: ${({theme}) => theme.bgSecondary};
  }
`;

const Input = styled.input`
  width: 160px;
  height: 62px;
  border: 1px solid ${({theme}) => theme.borderPrimary};
  border-radius: 18px;
  background-color: ${({theme}) => theme.fillPrimary};
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  font-family: 'Inter';
  font-size: 14px;
  outline: none;
  text-align: center;
  color: ${({theme}) => theme.textPrimary};
  &:focus {
    border-color: ${({theme}) => theme.mainPrimary};
  }
`;

const Badge = styled.div`
  background-color: ${({theme}) => theme.bgWaiting};
  padding: 7px 8px;
  border-radius: 8px;
  color: ${({theme}) => theme.mainPrimary};
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  font-family: 'Inter';
`;

export const ProductsRoot = () => {
  const username = useAppSelector(state => state.company.data?.username);
  const companyId = useAppSelector(state => state.company.data?.id);
  const params = useParams();
  const {id} = params;
  const colors = useColors();
  const [modalAddReserve, setModalAddReserve] = useState(false);
  const [modalRemoveReserve, setModalRemoveReserve] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const navigate = useNavigate();

  const {data, loading} = useQuery(GET_PRODUCTS_ONE, {
    variables: {
      id: id!,
      companyId: companyId!,
    },
  });
  const {edit_products_permission} = useEmployeePermission(data);

  const [visible, setVisible] = useState(false);
  return (
    <Layout columns={12}>
      {loading ? (
        <SkeletonMenu />
      ) : (
        <MenuWrapper>
          <Menu
            data={[
              {
                icon: <></>,
                label: 'Информация о товаре',
                to: `/${username}/product/${id}`,
              },
              // TODO: сделать в обновлении 1.2.0. На данный момент нет экрана продажи товара
              // {
              //   icon: <></>,
              //   label: 'История о продажах',
              //   to: `/${username}/product/${id}/history-sales`,
              // },
              {
                icon: <></>,
                label: 'История запасов',
                to: `/${username}/product/${id}/history-reserve`,
              },
            ]}
            title={data?.productsOne?.name}
            description={`${data?.productsOne?.quantity ?? 0} на складе`}
            descriptionStyle={{
              padding: '7px 8px',
              backgroundColor: colors.bgWaiting,
              borderRadius: 8,
              width: 'max-content',
              color: colors.mainPrimary,
            }}
          />
          {edit_products_permission && (
            <Content gap="16px" style={{position: 'relative'}}>
              <Button
                variant="subtitle"
                size="small"
                style={{fontSize: 16}}
                onClick={() => setVisible(true)}>
                <SquareTopUp />
                Выберите действие
              </Button>
              <Dropdown
                style={{
                  width: 'calc(100% - 48px)',
                  left: 24,
                  top: 48,
                }}
                data={[
                  {
                    label: 'Добавить запас',
                    icon: <CirclePlus color={colors.mainPrimary} />,
                    onClick: () => {
                      setModalAddReserve(true);
                    },
                  },
                  {
                    label: 'Удалить запас',
                    icon: <CircleClose color={colors.mainPrimary} />,
                    onClick: () => {
                      setModalRemoveReserve(true);
                    },
                  },
                  {
                    label: 'Редактировать товар',
                    icon: <Pencil color={colors.mainPrimary} />,
                    onClick: () => {
                      navigate(`/${username}/product/${id}/edit`);
                    },
                  },
                ]}
                gap={8}
                visible={visible}
                onClose={() => setVisible(false)}
                renderItem={item => (
                  <MyTableViewRow
                    title={item.label}
                    style={{
                      height: 56,
                      width: '100%',
                      padding: '0 6px 0 16px',
                    }}
                    onClick={() => item.onClick()}
                    rightElement={() => (
                      <IconButton
                        size={44}
                        style={{borderRadius: 0}}
                        variant="unstyled">
                        {item.icon}
                      </IconButton>
                    )}
                  />
                )}
              />
              <Divider />
              <Button
                size="small"
                variant="subtitle"
                style={{color: colors.dangerPrimary, fontSize: 16}}
                onClick={() => setModalDelete(true)}>
                <Trash color={colors.dangerPrimary} />
                Удалить товар
              </Button>
            </Content>
          )}
        </MenuWrapper>
      )}

      <Wrapper>
        <PageHeader variant="layout" title="Товар" back />
        <Outlet />
      </Wrapper>
      <ModalAddReserve
        visible={modalAddReserve}
        onClose={() => setModalAddReserve(false)}
        quantity={data?.productsOne?.quantity ?? 0}
        name={data?.productsOne?.name ?? ''}
        id={id!}
      />
      <ModalDeleteReserve
        visible={modalRemoveReserve}
        onClose={() => setModalRemoveReserve(false)}
        quantity={data?.productsOne?.quantity ?? 0}
        name={data?.productsOne?.name ?? ''}
        id={id!}
      />
      <ModalDelete
        visible={modalDelete}
        onClose={() => setModalDelete(false)}
        id={id!}
      />
    </Layout>
  );
};

const addData = [
  {label: 'Новый запас', value: 'Новый запас'},
  {label: 'Возврат', value: 'Возврат'},
  {label: 'Корректировка', value: 'Корректировка'},
  {label: 'Другое', value: 'Другое'},
];

const ModalAddReserve = ({
  visible,
  onClose,
  quantity,
  name,
  id,
}: {
  visible: boolean;
  onClose: () => void;
  quantity: number;
  name: string;
  id: string;
}) => {
  const [count, setCount] = useState('1');
  const [price, setPrice] = useState('');
  const colors = useColors();
  const [reason, setReason] = useState('');
  const companyId = useAppSelector(state => state.company.data!.id);

  const [fillProducts, {loading: loadingFill}] = useMutation(FILL_PRODUCTS, {
    refetchQueries: [
      {
        query: GET_PRODUCTS_ONE,
        variables: {
          id: id,
          companyId,
        },
      },
      {
        query: GET_HISTORY_RESERVE,
        variables: {
          first: 10,
          productsId: id,
        },
      },
    ],
  });

  useEffect(() => {
    if (visible) {
      setCount('1');
      setReason('');
    }
  }, [visible]);

  return (
    <Modal
      style={{
        display: 'flex',
        flexDirection: 'column',
        left: 'calc((100% - 588px) / 2)',
        width: 588,
        gap: 24,
        padding: '24px',
      }}
      backdropVariant="overlay"
      visible={visible}
      onClose={onClose}>
      <Head
        style={{
          justifyContent: 'space-between',
        }}>
        <Text typography="title20">Добавить запас</Text>
        <IconButton
          size={40}
          onClick={() => {
            onClose();
          }}>
          <Xv2 />
        </IconButton>
      </Head>
      <Flex direction="column" alignItems="center" gap={8}>
        <Text typography="text16Semibold">{name}</Text>
        <Badge>{quantity} на складе</Badge>
      </Flex>
      <Flex
        gap={8}
        justifyContent="center"
        style={{
          alignItems: 'center',
        }}>
        <ActionIcon
          style={{width: 64, height: 64}}
          icon={
            <Minus
              color={+count > 0 ? colors.mainPrimary : colors.textTertiary}
            />
          }
          onClick={() => {
            if (+count > 0) {
              setCount(state => (+state - 1).toString());
            }
          }}
        />
        <Input
          value={count}
          onChange={value => setCount(value.target.value)}
          type="number"
        />
        <ActionIcon
          style={{width: 64, height: 64}}
          icon={<Plus color={colors.mainPrimary} />}
          onClick={() => setCount(state => (+state + 1).toString())}
        />
      </Flex>
      <TextInput
        variant="with_label"
        label="Цена поставки (шт.)"
        mask="999 999 999"
        rightElement={
          <Text typography="subHead14Regular" color="textTertiary">
            ₽
          </Text>
        }
        value={price}
        onChange={setPrice}
      />
      <Flex direction="column" gap={8}>
        <Text
          typography="subHead14Regular"
          color="textTertiary"
          style={{pointerEvents: 'none'}}>
          Причина
        </Text>
        <Select
          variant="default"
          data={addData}
          value={reason}
          onChange={value => {
            setReason(value);
          }}
        />
      </Flex>
      <Flex gap={8}>
        <Button
          variant="outline"
          style={{flex: 1}}
          size="large"
          onClick={() => onClose()}>
          Отмена
        </Button>
        <Button
          style={{flex: 1}}
          size="large"
          loading={loadingFill}
          onClick={async () => {
            try {
              await fillProducts({
                variables: {
                  fillProductsId: id,
                  quantity: +count,
                  reason,
                  price: price !== '' ? +price : undefined,
                },
              });
              onClose();
            } catch (e) {
              if (e instanceof ApolloError) {
                showAlert('error', e.message);
              }
            }
          }}>
          Сохранить
        </Button>
      </Flex>
    </Modal>
  );
};

const removeData = [
  {
    label: 'Внутреннее использование',
    value: 'Внутреннее использование',
  },
  {
    label: 'Поврежден',
    value: 'Поврежден',
  },
  {
    label: 'Истек срок',
    value: 'Истек срок',
  },
  {
    label: 'Утерян',
    value: 'Утерян',
  },
  {
    label: 'Другое',
    value: 'Другое',
  },
];

const ModalDeleteReserve = ({
  visible,
  onClose,
  id,
  name,
  quantity,
}: {
  visible: boolean;
  onClose: () => void;
  quantity: number;
  name: string;
  id: string;
}) => {
  const [count, setCount] = useState('1');
  const colors = useColors();
  const [reason, setReason] = useState('');

  const [repealProducts, {loading: loadingRepeal}] = useMutation(
    REPEAL_PRODUCTS,
    {
      refetchQueries: [
        {
          query: GET_PRODUCTS_ONE,
          variables: {
            id,
          },
        },
        {
          query: GET_HISTORY_RESERVE,
          variables: {
            first: 10,
            productsId: id,
          },
        },
      ],
    },
  );

  useEffect(() => {
    if (visible) {
      setCount('1');
      setReason('');
    }
  }, [visible]);

  return (
    <Modal
      style={{
        display: 'flex',
        flexDirection: 'column',
        left: 'calc((100% - 588px) / 2)',
        width: 588,
        gap: 24,
        padding: '24px',
      }}
      backdropVariant="overlay"
      visible={visible}
      onClose={onClose}>
      <Head
        style={{
          justifyContent: 'space-between',
        }}>
        <Text typography="title20">Удалить запас</Text>
        <IconButton
          size={40}
          onClick={() => {
            onClose();
          }}>
          <Xv2 />
        </IconButton>
      </Head>
      <Flex direction="column" alignItems="center" gap={8}>
        <Text typography="text16Semibold">{name}</Text>
        <Badge>{quantity} на складе</Badge>
      </Flex>
      <Flex
        gap={8}
        justifyContent="center"
        style={{
          alignItems: 'center',
        }}>
        <ActionIcon
          style={{width: 64, height: 64}}
          icon={
            <Minus
              color={+count > 0 ? colors.mainPrimary : colors.textTertiary}
            />
          }
          onClick={() => {
            if (+count > 0) {
              setCount(state => (+state - 1).toString());
            }
          }}
        />
        <Input
          value={count}
          onChange={value => setCount(value.target.value)}
          type="number"
        />
        <ActionIcon
          style={{width: 64, height: 64}}
          icon={<Plus color={colors.mainPrimary} />}
          onClick={() => setCount(state => (+state + 1).toString())}
        />
      </Flex>
      <Flex direction="column" gap={8}>
        <Text
          typography="subHead14Regular"
          color="textTertiary"
          style={{pointerEvents: 'none'}}>
          Причина
        </Text>
        <Select
          variant="default"
          data={removeData}
          value={reason}
          onChange={value => {
            setReason(value);
          }}
        />
      </Flex>
      <Flex gap={8}>
        <Button
          variant="outline"
          style={{flex: 1}}
          size="large"
          onClick={() => onClose()}>
          Отмена
        </Button>
        <Button
          style={{flex: 1}}
          size="large"
          loading={loadingRepeal}
          onClick={async () => {
            try {
              await repealProducts({
                variables: {
                  repealProductsId: id,
                  quantity: +count,
                  reason: reason,
                },
              });
              onClose();
            } catch (e) {
              if (e instanceof ApolloError) {
                showAlert('error', e.message);
              }
            }
          }}>
          Сохранить
        </Button>
      </Flex>
    </Modal>
  );
};

const SkeletonMenu = () => {
  return (
    <MenuWrapper>
      <Content>
        <Skeleton height={329}>
          <rect width={'100%'} height={24} rx={8} ry={8} />
          <rect width={100} height={32} rx={8} ry={8} x={0} y={40} />
          <rect width={'100%'} height={1} y={88} />
          <rect width={'100%'} height={56} rx={12} ry={12} y={113} />
          <rect width={'100%'} height={56} rx={12} ry={12} y={185} />
          <rect width={'100%'} height={56} rx={12} ry={12} y={257} />
        </Skeleton>
      </Content>
      <Content>
        <Skeleton>
          <rect width={'100%'} height={48} rx={14} ry={14} />
          <rect width={'100%'} height={1} y={64} />
          <rect width={'100%'} height={48} y={81} rx={14} ry={14} />
        </Skeleton>
      </Content>
    </MenuWrapper>
  );
};

const ModalDelete = ({
  visible,
  onClose,
  id,
}: {
  visible: boolean;
  onClose: () => void;
  id: string;
}) => {
  const [deleteProduct, {loading}] = useMutation(DELETE_PRODUCTS, {
    refetchQueries: ['GetProducts'],
  });
  const navigate = useNavigate();
  const colors = useColors();

  return (
    <Popup style={{gap: 16, width: 440}} onClose={onClose} visible={visible}>
      <Head
        style={{
          justifyContent: 'space-between',
          width: '100%',
          marginBottom: 0,
        }}>
        <Text typography="title24">Удалить товар</Text>
        <IconButton size={40} onClick={() => onClose()}>
          <Xv2 />
        </IconButton>
      </Head>
      <Text style={{width: '100%'}}>
        Вы уверены, что хотите удалить этот товар?
      </Text>
      <Flex style={{width: '100%'}} justifyContent="space-between" gap={8}>
        <Button
          size="large"
          variant="outline"
          style={{flex: 1, borderColor: colors.mainPrimary}}
          onClick={() => onClose()}>
          Отмена
        </Button>
        <Button
          size="large"
          variant="outline"
          loading={loading}
          onClick={async () => {
            try {
              await deleteProduct({
                variables: {
                  id,
                },
              });
              onClose();
              navigate(-1);
            } catch (e) {
              if (e instanceof ApolloError) {
                showAlert('error', e.message);
              }
            }
          }}
          style={{
            flex: 1,
            borderColor: colors.dangerPrimary,
            color: colors.dangerPrimary,
          }}>
          Удалить
        </Button>
      </Flex>
    </Popup>
  );
};
