import React from 'react';
import styled from 'styled-components';
import {Text} from 'shared/ui/Text';

const Header = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10;
  height: 56px;
`;

export const ServicesHeader = ({total}: {total?: number}) => {
  return (
    <Header>
      <Text typography="title24">Услуги</Text>
      <Text style={{marginLeft: 10}} typography="title24" color="textTertiary">
        {total}
      </Text>
    </Header>
  );
};
