import React, {useRef} from 'react';
import {Text} from 'shared/ui/Text';
import {TextInput} from 'shared/ui/TextInput';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {getSmsCode} from 'shared/lib/api';
import {ensureError} from 'shared/lib/utils';
import {Button} from 'shared/ui/Button';
import {Flex} from 'shared/ui/Flex';
import {Divider} from 'shared/ui/Divider';
import {showAlert} from 'shared/ui/Alert';
import {Head} from 'shared/ui/Head';
import {Content} from 'shared/ui/Content';
import {client} from 'shared/config/apollo';
import {Seo} from 'shared/ui/Seo';
import ReCAPTCHA from 'react-google-recaptcha';

export function SignIn() {
  const [phone, setPhone] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);

  const onCaptchaChange = async (token: string | null) => {
    if (!token) {
      return;
    }
    try {
      await getSmsCode(phone, token);
      navigate('confirm-phone', {state: {phone, status: 'auth'}});
    } catch (err) {
      const error = ensureError(err);
      showAlert('error', error.message);
    }
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }
  };

  const onSubmit = async () => {
    try {
      setLoading(true);
      await client.resetStore();
      localStorage.clear();
      recaptchaRef.current?.execute();
    } catch (err) {
      const error = ensureError(err);
      showAlert('error', error.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Content gap="0px">
      <Seo title="Вход" />
      <Head>
        <Text typography="title20">Вход</Text>
      </Head>
      <Flex direction="column" gap={32}>
        <TextInput
          value={phone}
          onChange={setPhone}
          label="Номер телефона"
          autoComplete="tel"
          mask="+7 (999) 999-99-99"
        />
        <Flex direction="column" gap={16}>
          <Flex direction="column" gap={8}>
            <Button
              size="large"
              disabled={phone.length < 18}
              loading={loading}
              onClick={onSubmit}>
              Далее
            </Button>
            <Button
              size="large"
              onClick={() => {
                navigate('password');
              }}
              variant="subtitle">
              Войти с паролем
            </Button>
          </Flex>
          <Divider />
          <Text
            typography="subHead14Regular"
            color="textTertiary"
            align="center">
            Продолжая, вы соглашаетесь{' '}
            <Text
              typography="subHead14Regular"
              color="mainPrimary"
              display={'inline'}
              onClick={() => {
                window.open(
                  'https://business.booky.co/privacy-policy/',
                  '_blank',
                );
              }}>
              со сбором, обработкой персональных данных
            </Text>{' '}
            и{' '}
            <Text
              typography="subHead14Regular"
              color="mainPrimary"
              display={'inline'}
              onClick={() => {
                window.open(
                  'https://business.booky.co/terms-of-use/',
                  '_blank',
                );
              }}>
              Пользовательским соглашением
            </Text>
          </Text>
        </Flex>
      </Flex>
      <ReCAPTCHA
        style={{display: 'inline-block', visibility: 'hidden'}}
        ref={recaptchaRef}
        size="invisible"
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY!}
        onChange={onCaptchaChange}
        onExpired={() => {
          showAlert('error', 'Время ввода капчи истекло');
          if (recaptchaRef.current) {
            recaptchaRef.current.reset();
          }
        }}
        onErrored={() => {
          showAlert('error', 'Ошибка ввода капчи');
          if (recaptchaRef.current) {
            recaptchaRef.current.reset();
          }
        }}
      />
    </Content>
  );
}
