import React from 'react';
import {Content} from 'shared/ui/Content';
import {Text} from 'shared/ui/Text';
import {Button} from 'shared/ui/Button';
import {TableViewRow} from 'shared/ui/TableViewRow';
import {CheckBox} from 'shared/ui/CheckBox';
import {Skeleton} from 'shared/ui/Skeleton';
import {useQuery, useMutation} from '@apollo/client';
import {useAppSelector} from 'shared/store';
import {gql} from 'shared/__generated__';
import {Error} from 'shared/ui/Error';
import {Popup} from 'shared/ui/Popup';
import {Success} from 'shared/icons/Success';
import {ensureError} from 'shared/lib/utils';
import {showAlert} from 'shared/ui/Alert';
import {Seo} from 'shared/ui/Seo';
import {Flex} from 'shared/ui/Flex';

interface IRules {
  is_employee_added: boolean;
  is_photo_added: boolean;
  is_required_items_filled: boolean;
  is_employee_with_specialization_added: boolean;
  is_service_added: boolean;
}

const MODERATION_CREATE = gql(`
  mutation CreateModeration($companyId: String!) {
    createModeration(company_id: $companyId) {
      id
    }
  }
`);

const MODERATION = gql(`
  query ModerationsCheckNewModeration($companyId: String!) {
    moderationsCheck(company_id: $companyId) {
      result
      is_employee_added
      is_photo_added
      is_employee_with_specialization_added
      is_required_items_filled
      is_service_added
    }
  }
`);

export function NewModeration() {
  const companyId = useAppSelector(state => state.company.data!.id);
  const [visible, setVisible] = React.useState(false);
  const {error, data, loading} = useQuery(MODERATION, {
    variables: {companyId: companyId},
  });
  const [submitModeration, {loading: updateLoading}] =
    useMutation(MODERATION_CREATE);
  const rules: IRules | null = data?.moderationsCheck
    ? data?.moderationsCheck[0]
    : {
        is_employee_added: false,
        is_photo_added: false,
        is_required_items_filled: false,
        is_employee_with_specialization_added: false,
        is_service_added: false,
      };
  const isValid: boolean =
    (rules &&
      rules.is_employee_added &&
      rules.is_photo_added &&
      rules.is_employee_with_specialization_added &&
      rules.is_required_items_filled &&
      rules.is_service_added) ??
    false;

  const handlerSubmit = async () => {
    try {
      await submitModeration({
        variables: {
          companyId: companyId,
        },
      });
      setVisible(true);
    } catch (e) {
      const error = ensureError(e);
      showAlert('error', error.message);
    }
  };

  if (error) {
    return <Error message={'Не удалось проверить вашу организацию'} />;
  }
  return (
    <Flex direction="column">
      <Seo title="Модерация" />
      <Content style={{marginBottom: 24}}>
        <Text typography="title18">Отправка на модерацию</Text>
        <Text typography="text16Regular" color="textTertiary">
          Ваша заявка будет рассмотрена в течение 24 часов. Как только изменится
          статус, вы получите уведомление.
        </Text>
      </Content>
      <Content gap="24px" style={{marginBottom: 32}}>
        <Text typography="title18">
          Обязательные условия для публикации организации:
        </Text>
        {loading ? (
          <NewModerationSk />
        ) : (
          <>
            <TableViewRow
              title="1) Вы заполнили все поля в разделе
          “Настройки организации”"
              rightElement={() => (
                <CheckBox checked={rules?.is_required_items_filled} />
              )}
            />
            <TableViewRow
              title="2) Вы загрузили хотя бы 3 фотографии вашей организации в хорошем
          качестве"
              rightElement={() => <CheckBox checked={rules?.is_photo_added} />}
            />
            <TableViewRow
              title="3) Вы добавили хотя бы одного сотрудника со специализацией. Если работаете один, добавьту себе специализацию"
              rightElement={() => (
                <CheckBox
                  checked={rules?.is_employee_with_specialization_added}
                />
              )}
            />
            <TableViewRow
              title="4) Хотя бы у 1 сотрудника заполнены рабочие дни"
              rightElement={() => (
                <CheckBox checked={rules?.is_employee_added} />
              )}
            />
            <TableViewRow
              title="5) Вы добавить хотя бы 1 услугу"
              rightElement={() => (
                <CheckBox checked={rules?.is_service_added} />
              )}
            />
          </>
        )}
      </Content>
      <Button
        size="large"
        disabled={!isValid}
        loading={updateLoading}
        onClick={handlerSubmit}>
        Отправить
      </Button>
      <Popup
        style={{width: 343}}
        visible={visible}
        onClose={() => {
          setVisible(false);
        }}>
        <Success size={115} />
        <Text typography="title18" color="textPrimary">
          Заявка успешно отправлена!
        </Text>
      </Popup>
    </Flex>
  );
}

const NewModerationSk = () => {
  return (
    <Skeleton height={315}>
      <rect x={0} y={7} width={263} height={14} rx={8} />
      <rect x={0} y={27} width={455} height={14} rx={8} />
      <circle cx={659} cy={20} r={11} height={22} width={22} />

      <rect x={0} y={77} width={249} height={14} rx={8} />
      <rect x={0} y={97} width={328} height={14} rx={8} />
      <circle cx={659} cy={90} r={11} height={22} width={22} />

      <rect x={0} y={147} width={413} height={14} rx={8} />
      <rect x={0} y={167} width={325} height={14} rx={8} />
      <circle cx={659} cy={160} r={11} height={22} width={22} />

      <rect x={0} y={222} width={270} height={14} rx={8} />
      <circle cx={659} cy={230} r={11} height={22} width={22} />

      <rect x={0} y={284} width={353} height={14} rx={8} />
      <circle cx={659} cy={292} r={11} height={22} width={22} />
    </Skeleton>
  );
};
