import React from 'react';
const CircleAccept = ({
  size = 24,
  color = '#6765F8',
}: {
  size?: number;
  color?: string;
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.03 11.97a.75.75 0 00-1.06 1.06l1.06-1.06zm1.47 2.53l-.53.53a.75.75 0 001.06 0l-.53-.53zm5.53-4.47a.75.75 0 10-1.06-1.06l1.06 1.06zM21.25 12A9.25 9.25 0 0112 21.25v1.5c5.937 0 10.75-4.813 10.75-10.75h-1.5zM12 21.25A9.25 9.25 0 012.75 12h-1.5c0 5.937 4.813 10.75 10.75 10.75v-1.5zM2.75 12A9.25 9.25 0 0112 2.75v-1.5C6.063 1.25 1.25 6.063 1.25 12h1.5zM12 2.75A9.25 9.25 0 0121.25 12h1.5c0-5.937-4.813-10.75-10.75-10.75v1.5zM7.97 13.03l2 2 1.06-1.06-2-2-1.06 1.06zm3.06 2l5-5-1.06-1.06-5 5 1.06 1.06z"
        fill={color}
      />
    </svg>
  );
};

export default CircleAccept;
