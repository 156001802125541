import React, {useEffect, useState} from 'react';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';
import {Grow} from 'shared/ui/Grow';
import {Button} from 'shared/ui/Button';
import {StatisticsFinanceSum} from 'shared/__generated__/graphql';
import {FilterButton} from 'shared/ui/FilterButton';
import {TextInput} from 'shared/ui/TextInput';
import {Modal} from 'shared/ui/Modal';

const PopupWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const Row = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const Box = styled.div`
  display: flex;
  flex: 1;
  align-items: start;
  gap: 8px;
`;

export const FilterStatisticsFinanceSum = ({
  sum,
  setSum,
}: {
  sum?: StatisticsFinanceSum;
  setSum?: React.Dispatch<
    React.SetStateAction<StatisticsFinanceSum | undefined>
  >;
}) => {
  const [visible, setVisible] = useState(false);
  const [sumPrev, setSumPrev] = useState(sum);
  const onClose = () => setVisible(false);
  useEffect(() => {
    setSumPrev(sum);
  }, [sum]);
  return (
    <PopupWrapper>
      <FilterButton
        onClick={() => setVisible(true)}
        active={!!sum?.sum_start || !!sum?.sum_end}
        title={
          sum?.sum_end || sum?.sum_start
            ? `${sum.sum_start ? 'От ' + sum.sum_start : ''} ${
                sum.sum_end ? 'До ' + sum.sum_end : ''
              }`
            : 'Сумма'
        }
        visible={visible}
      />
      <Modal
        style={{
          width: 343,
          left: 'auto',
          right: 'auto',
          top: 64,
          gap: 8,
        }}
        visible={visible}
        onClose={onClose}>
        <Grow style={{gap: 16}}>
          <Text>Введите значения</Text>
          <Box>
            <TextInput
              leftElement={<Text color="textSecondary">От</Text>}
              variant="with_label"
              wrapperStyle={{flex: 1}}
              value={sumPrev?.sum_start?.toString() ?? ''}
              mask="9999999"
              onChange={text =>
                setSumPrev(prev => ({
                  ...prev,
                  sum_start: text ? parseInt(text, 10) : 0,
                  sum_end: prev ? prev.sum_end : 0,
                }))
              }
            />
            <TextInput
              wrapperStyle={{flex: 1}}
              leftElement={<Text color="textSecondary">До</Text>}
              variant="with_label"
              value={sumPrev?.sum_end?.toString() ?? ''}
              mask="9999999"
              onChange={text =>
                setSumPrev(prev => ({
                  ...prev,
                  sum_start: prev ? prev.sum_start : 0,
                  sum_end: text ? parseInt(text, 10) : 0,
                }))
              }
            />
          </Box>
          <Row style={{justifyContent: 'center', gap: 8}}>
            <Button
              style={{flex: 1}}
              size="medium"
              variant="outline"
              onClick={() => {
                setSum && setSum(undefined);
                onClose();
              }}>
              Сбросить
            </Button>
            <Button
              style={{flex: 1}}
              size="medium"
              disabled={
                typeof sumPrev?.sum_start === 'number' &&
                typeof sumPrev.sum_end === 'number' &&
                sumPrev.sum_start > sumPrev.sum_end
              }
              onClick={() => {
                setSum &&
                  setSum(
                    sumPrev?.sum_start || sumPrev?.sum_end
                      ? sumPrev
                      : undefined,
                  );
                onClose();
              }}>
              Применить
            </Button>
          </Row>
        </Grow>
      </Modal>
    </PopupWrapper>
  );
};
