import styled from 'styled-components';

export const CreateContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 64px;
  min-height: 100vh;
  padding-bottom: 24px;
  @media (max-width: 768px) {
    margin-top: 0;
    margin-bottom: 0;
    max-width: 768px;
    min-height: 100vh;
    border-radius: 0;
    padding-top: 16px;
    padding-bottom: 8px;
  }
`;
