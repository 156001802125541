import React from 'react';
import {Content} from 'shared/ui/Content';
import {Skeleton} from 'shared/ui/Skeleton';

export const SkeletonProduct = () => {
  return (
    <Content>
      <Skeleton height={70 * 4}>
        <rect width={24} height={24} rx={4} ry={4} x={17} y={23} />
        <rect width={150} height={24} rx={8} ry={8} x={76} y={23} />
        <rect width={71} height={24} rx={8} ry={8} x={320 + 16} y={23} />
        <rect width={43} height={24} rx={8} ry={8} x={600 + 16} y={23} />
        <rect width={110} height={24} rx={8} ry={8} x={880 + 16} y={23} />
        <rect width={116} height={24} rx={8} ry={8} x={1068 + 16} y={23} />
        <rect width={116} height={24} rx={8} ry={8} x={1287 + 16} y={23} />
        {Array.from(Array(3).keys()).map(item => (
          <React.Fragment key={'sk_good_' + item}>
            <rect
              width={24}
              height={24}
              rx={4}
              ry={4}
              x={17}
              y={23 + 70 * (item + 1)}
            />
            <rect
              width={150}
              height={24}
              rx={8}
              ry={8}
              x={76}
              y={23 + 70 * (item + 1)}
            />
            <rect
              width={120}
              height={24}
              rx={8}
              ry={8}
              x={320 + 16}
              y={23 + 70 * (item + 1)}
            />
            <rect
              width={200}
              height={24}
              rx={8}
              ry={8}
              x={600 + 16}
              y={23 + 70 * (item + 1)}
            />
            <rect
              width={130}
              height={24}
              rx={8}
              ry={8}
              x={880 + 16}
              y={23 + 70 * (item + 1)}
            />
            <rect
              width={100}
              height={24}
              rx={8}
              ry={8}
              x={1068 + 16}
              y={23 + 70 * (item + 1)}
            />
            <rect
              width={140}
              height={24}
              rx={8}
              ry={8}
              x={1287 + 16}
              y={23 + 70 * (item + 1)}
            />
          </React.Fragment>
        ))}
      </Skeleton>
    </Content>
  );
};

export const SkeletonBrands = () => {
  return (
    <Content>
      <Skeleton height={374}>
        <rect width={24} height={24} rx={4} ry={4} x={18} y={24} />
        <rect width={80} height={24} rx={8} ry={8} x={80} y={24} />
        <rect width={120} height={24} rx={8} ry={8} x={'40%'} y={24} />
        {Array.from(Array(4).keys()).map(item => (
          <React.Fragment key={'sk_' + item}>
            <rect
              width={24}
              height={24}
              rx={4}
              ry={4}
              x={18}
              y={96 + item * 80}
            />
            <rect
              width={180 + 200 * Math.random()}
              height={24}
              rx={8}
              ry={8}
              x={80}
              y={96 + item * 80}
            />
            <rect
              width={200 + 200 * Math.random()}
              height={24}
              rx={8}
              ry={8}
              x={'40%'}
              y={96 + item * 80}
            />
          </React.Fragment>
        ))}
      </Skeleton>
    </Content>
  );
};

export const SkeletonProductCategory = () => {
  return (
    <Content>
      <Skeleton height={374}>
        <rect width={24} height={24} rx={4} ry={4} x={18} y={24} />
        <rect width={80} height={24} rx={8} ry={8} x={80} y={24} />
        <rect width={120} height={24} rx={8} ry={8} x={524} y={24} />
        <rect width={180} height={24} rx={8} ry={8} x={904} y={24} />
        <rect width={180} height={24} rx={8} ry={8} x={1229 + 48} y={24} />
        {Array.from(Array(4).keys()).map(item => (
          <React.Fragment key={'sk_' + item}>
            <rect
              width={24}
              height={24}
              rx={4}
              ry={4}
              x={18}
              y={96 + item * 80}
            />
            <rect
              width={350}
              height={24}
              rx={8}
              ry={8}
              x={80}
              y={96 + item * 80}
            />
            <rect
              width={300}
              height={24}
              rx={8}
              ry={8}
              x={524}
              y={96 + item * 80}
            />
            <rect
              width={300}
              height={24}
              rx={8}
              ry={8}
              x={904}
              y={96 + item * 80}
            />
            <rect
              width={300}
              height={24}
              rx={8}
              ry={8}
              x={1229 + 48}
              y={96 + item * 80}
            />
          </React.Fragment>
        ))}
      </Skeleton>
    </Content>
  );
};
