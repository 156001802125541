import React from 'react';
import {useColorScheme} from 'shared/lib/hooks';
import {EmptyContainer} from 'shared/ui/EmptyContainer';

export const EmptyAddCategory = ({size = 400}: {size?: number}) => {
  const colorScheme = useColorScheme();
  return (
    <EmptyContainer size={size}>
      {colorScheme === 'light' ? (
        <svg viewBox="0 0 400 400" fill="none">
          <path
            d="M179.87 359.313C257.118 359.313 319.739 296.478 319.739 218.967C319.739 141.455 257.118 78.6204 179.87 78.6204C102.622 78.6204 40 141.455 40 218.967C40 296.478 102.622 359.313 179.87 359.313Z"
            fill="#EAEEF9"
          />
          <path
            d="M339.177 101.168C341.012 99.4847 341.141 96.6268 339.463 94.7848C337.785 92.9427 334.937 92.814 333.101 94.4974C331.266 96.1808 331.137 99.0387 332.815 100.881C334.493 102.723 337.341 102.851 339.177 101.168Z"
            fill="#EAEEF9"
          />
          <path
            opacity="0.3"
            d="M264.467 154.077C269.44 154.077 273.472 150.031 273.472 145.041C273.472 140.05 269.44 136.004 264.467 136.004C259.493 136.004 255.461 140.05 255.461 145.041C255.461 150.031 259.493 154.077 264.467 154.077Z"
            fill="#AAB2C5"
          />
          <g filter="url(#filter0_d_18292_1057)">
            <path
              d="M203.767 165.947C202.643 171.94 197.773 177.559 191.405 179.057C186.536 180.181 181.666 179.057 178.295 176.435V219.888H137.839C139.337 216.891 139.712 213.894 139.337 210.523C138.588 203.031 132.22 197.038 125.103 196.289C115.364 195.165 106.748 202.657 106.748 212.396C106.748 215.018 107.123 217.64 108.247 219.513H62.5469V135.98C62.5469 118.749 76.4066 104.889 94.0123 104.889H177.92V148.341C180.917 146.468 183.913 145.345 187.285 145.345C197.399 146.843 205.639 155.458 203.767 165.947Z"
              fill="url(#paint0_linear_18292_1057)"
            />
          </g>
          <g filter="url(#filter1_d_18292_1057)">
            <path
              d="M150.95 274.577C149.452 284.691 157.318 293.306 167.432 293.306C171.552 293.306 175.298 291.808 178.295 289.56V334.511H94.3873C77.1562 334.511 62.9219 320.276 62.9219 303.42V219.512H108.247C107.123 217.265 106.749 215.017 106.749 212.02C107.123 204.154 113.117 197.412 120.608 196.288C130.722 195.164 139.338 202.656 139.338 212.395C139.338 215.017 138.963 217.265 137.839 219.512H177.546V264.463C174.174 261.466 169.679 259.968 164.81 260.717C157.693 261.466 152.074 267.459 150.95 274.577Z"
              fill="url(#paint1_linear_18292_1057)"
            />
          </g>
          <g filter="url(#filter2_d_18292_1057)">
            <path
              d="M293.669 219.513V302.672C293.669 319.903 279.435 333.763 262.204 333.763H178.296V288.812C175.299 291.434 171.553 292.558 167.433 292.558C157.694 292.558 150.202 283.942 150.951 273.828C152.075 266.337 158.068 260.718 164.811 259.594C169.68 259.219 174.55 260.718 177.547 263.34V218.389H225.869C224.37 220.637 223.996 223.259 223.996 225.881C223.996 235.246 232.237 243.112 242.351 241.988C249.842 241.239 256.21 234.871 256.959 227.754C257.334 224.383 256.585 221.386 255.461 218.389L293.669 219.513Z"
              fill="url(#paint2_linear_18292_1057)"
            />
          </g>
          <path
            d="M328.876 185.425L291.792 179.057C292.541 182.054 292.915 185.05 291.792 188.047C289.544 195.164 282.801 200.034 275.31 199.659C265.57 198.91 258.453 190.295 259.952 180.93C260.326 178.308 261.45 176.06 262.948 174.187L215.001 165.572L222.493 122.494C225.489 125.491 229.984 127.364 235.229 127.364C241.971 126.989 247.59 122.869 249.463 116.875C253.209 107.136 246.841 96.6476 236.727 95.1493C233.356 94.4001 229.984 95.1493 226.988 96.273L234.48 53.1954L316.889 67.4298C334.12 70.4265 345.358 86.5338 342.361 103.39L328.876 185.425Z"
            fill="url(#paint3_linear_18292_1057)"
          />
          <path
            d="M346.869 217.012L359.323 213.258C360.225 212.996 360.225 211.687 359.323 211.425L346.869 207.671C346.549 207.584 346.316 207.351 346.229 207.031L342.475 194.606C342.213 193.704 340.904 193.704 340.642 194.606L336.888 207.031C336.801 207.351 336.568 207.584 336.248 207.671L323.823 211.425C322.921 211.687 322.921 212.996 323.823 213.258L336.277 217.012C336.597 217.099 336.83 217.332 336.917 217.652L340.671 230.106C340.933 231.008 342.242 231.008 342.504 230.106L346.258 217.652C346.316 217.332 346.549 217.099 346.869 217.012Z"
            fill="#AAB2C5"
          />
          <path
            d="M319.317 108.046L334.807 103.377C335.929 103.051 335.929 101.423 334.807 101.097L319.317 96.4284C318.919 96.3198 318.629 96.0302 318.521 95.6321L313.852 80.1783C313.526 79.0563 311.898 79.0563 311.572 80.1783L306.903 95.6321C306.795 96.0302 306.505 96.3198 306.107 96.4284L290.653 101.097C289.531 101.423 289.531 103.051 290.653 103.377L306.143 108.046C306.541 108.154 306.831 108.444 306.939 108.842L311.608 124.332C311.934 125.454 313.562 125.454 313.888 124.332L318.557 108.842C318.629 108.444 318.919 108.154 319.317 108.046Z"
            fill="white"
          />
          <path
            d="M206.714 55.4253L214.498 53.0794C215.062 52.9157 215.062 52.0974 214.498 51.9337L206.714 49.5879C206.514 49.5333 206.369 49.3878 206.314 49.1878L203.968 41.4228C203.804 40.8591 202.986 40.8591 202.822 41.4228L200.476 49.1878C200.422 49.3878 200.276 49.5333 200.076 49.5879L192.311 51.9337C191.747 52.0974 191.747 52.9157 192.311 53.0794L200.094 55.4253C200.294 55.4798 200.44 55.6253 200.494 55.8253L202.84 63.6085C203.004 64.1722 203.823 64.1722 203.986 63.6085L206.332 55.8253C206.369 55.6253 206.514 55.4798 206.714 55.4253Z"
            fill="#AAB2C5"
          />
          <defs>
            <filter
              id="filter0_d_18292_1057"
              x="40.5469"
              y="93.889"
              width="185.486"
              height="158.998"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_18292_1057"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_18292_1057"
                result="shape"
              />
            </filter>
            <filter
              id="filter1_d_18292_1057"
              x="40.9219"
              y="185.176"
              width="159.373"
              height="182.335"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_18292_1057"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_18292_1057"
                result="shape"
              />
            </filter>
            <filter
              id="filter2_d_18292_1057"
              x="128.899"
              y="207.389"
              width="186.77"
              height="159.373"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_18292_1057"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_18292_1057"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_18292_1057"
              x1="133.244"
              y1="102.229"
              x2="133.244"
              y2="221.127"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#FDFEFF" />
              <stop offset="0.9964" stopColor="#ECF0F5" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_18292_1057"
              x1="120.571"
              y1="192.976"
              x2="120.571"
              y2="336.002"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#FDFEFF" />
              <stop offset="0.9964" stopColor="#ECF0F5" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_18292_1057"
              x1="222.238"
              y1="215.721"
              x2="222.238"
              y2="335.006"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#FDFEFF" />
              <stop offset="0.9964" stopColor="#ECF0F5" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_18292_1057"
              x1="214.942"
              y1="126.453"
              x2="342.867"
              y2="126.453"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#B0BACC" />
              <stop offset="1" stopColor="#969EAE" />
            </linearGradient>
          </defs>
        </svg>
      ) : (
        <svg viewBox="0 0 400 400" fill="none">
          <path
            d="M179.869 359.313C257.117 359.313 319.739 296.478 319.739 218.967C319.739 141.456 257.117 78.6211 179.869 78.6211C102.622 78.6211 40 141.456 40 218.967C40 296.478 102.622 359.313 179.869 359.313Z"
            fill="#2A2E37"
          />
          <path
            d="M339.176 101.168C341.011 99.4847 341.14 96.6268 339.462 94.7848C337.784 92.9427 334.936 92.8141 333.1 94.4974C331.265 96.1808 331.136 99.0387 332.814 100.881C334.492 102.723 337.34 102.851 339.176 101.168Z"
            fill="#2A2E37"
          />
          <path
            opacity="0.3"
            d="M264.465 154.076C269.438 154.076 273.47 150.03 273.47 145.039C273.47 140.049 269.438 136.003 264.465 136.003C259.491 136.003 255.459 140.049 255.459 145.039C255.459 150.03 259.491 154.076 264.465 154.076Z"
            fill="#AAB2C5"
          />
          <path
            d="M105.363 211.684C105.363 214.012 105.653 216.404 106.533 218.302H62.1621V135.269C62.1621 118.318 75.7941 104.678 93.1274 104.678H176.535V147.63V148.532L177.3 148.054C180.233 146.221 183.127 145.142 186.363 145.133C196.257 146.617 204.197 155.019 202.39 165.146C201.299 170.958 196.566 176.409 190.407 177.859C185.675 178.951 180.962 177.854 177.716 175.329L176.909 174.702V175.724V218.676H137.742C139.005 215.818 139.303 212.938 138.95 209.759C138.175 202.024 131.614 195.854 124.273 195.08C114.247 193.925 105.363 201.638 105.363 211.684Z"
            fill="#5C6479"
            stroke="#2A2E37"
          />
          <path
            d="M149.571 273.79L149.57 273.794C148.025 284.226 156.144 293.097 166.547 293.097C170.43 293.097 173.982 291.805 176.91 289.822V333.302H93.5024C76.5437 333.302 62.5371 319.287 62.5371 302.711V219.303H107.362H108.171L107.809 218.58C106.733 216.428 106.365 214.268 106.364 211.324C106.733 203.694 112.55 197.166 119.788 196.075C129.62 194.988 137.953 202.266 137.953 211.686C137.953 214.256 137.587 216.421 136.507 218.58L136.146 219.303H136.955H176.161V262.687C172.793 260.064 168.488 258.803 163.861 259.512C156.495 260.293 150.725 266.483 149.571 273.79Z"
            fill="#5C6479"
            stroke="#2A2E37"
          />
          <path
            d="M256.572 227.097C256.929 223.881 256.293 221.007 255.299 218.2L292.284 219.288V301.961C292.284 318.908 278.281 332.551 261.319 332.551H177.911V288.101V286.999L177.082 287.725C174.196 290.25 170.576 291.347 166.548 291.347C157.133 291.347 149.844 283.017 150.564 273.173C151.66 265.919 157.465 260.477 163.986 259.38C168.738 259.02 173.458 260.49 176.333 263.005L177.162 263.731V262.629V218.178H224.098C222.912 220.358 222.611 222.795 222.611 225.17C222.611 234.829 231.106 242.93 241.518 241.775C249.239 241.001 255.797 234.454 256.572 227.097Z"
            fill="#5C6479"
            stroke="#2A2E37"
          />
          <path
            d="M328.881 185.425L291.797 179.057C292.546 182.053 292.921 185.05 291.797 188.047C289.55 195.164 282.807 200.033 275.315 199.659C265.576 198.91 258.459 190.294 259.957 180.929C260.332 178.307 261.456 176.06 262.954 174.187L215.007 165.571L222.499 122.494C225.495 125.491 229.99 127.364 235.235 127.364C241.977 126.989 247.596 122.868 249.469 116.875C253.215 107.136 246.847 96.6474 236.733 95.1491C233.362 94.3999 229.99 95.1491 226.994 96.2728L234.485 53.1953L316.895 67.4296C334.126 70.4263 345.363 86.5336 342.366 103.39L328.881 185.425Z"
            fill="url(#paint0_linear_18292_1075)"
          />
          <path
            d="M346.869 217.011L359.323 213.258C360.225 212.996 360.225 211.686 359.323 211.425L346.869 207.671C346.549 207.584 346.316 207.351 346.229 207.031L342.475 194.606C342.213 193.704 340.904 193.704 340.642 194.606L336.888 207.031C336.801 207.351 336.568 207.584 336.248 207.671L323.823 211.425C322.921 211.686 322.921 212.996 323.823 213.258L336.277 217.011C336.597 217.099 336.83 217.331 336.917 217.652L340.671 230.106C340.933 231.008 342.242 231.008 342.504 230.106L346.258 217.652C346.316 217.331 346.549 217.099 346.869 217.011Z"
            fill="#AAB2C5"
          />
          <path
            d="M319.315 108.046L334.805 103.378C335.927 103.052 335.927 101.423 334.805 101.098L319.315 96.4289C318.917 96.3203 318.627 96.0308 318.519 95.6327L313.85 80.1789C313.524 79.0569 311.896 79.0569 311.57 80.1789L306.901 95.6327C306.793 96.0308 306.503 96.3203 306.105 96.4289L290.651 101.098C289.529 101.423 289.529 103.052 290.651 103.378L306.141 108.046C306.539 108.155 306.829 108.445 306.937 108.843L311.606 124.333C311.932 125.455 313.56 125.455 313.886 124.333L318.555 108.843C318.627 108.445 318.917 108.155 319.315 108.046Z"
            fill="white"
          />
          <path
            d="M206.714 55.4252L214.498 53.0794C215.062 52.9157 215.062 52.0974 214.498 51.9337L206.714 49.5878C206.514 49.5333 206.369 49.3878 206.314 49.1878L203.968 41.4228C203.804 40.8591 202.986 40.8591 202.822 41.4228L200.476 49.1878C200.422 49.3878 200.276 49.5333 200.076 49.5878L192.311 51.9337C191.747 52.0974 191.747 52.9157 192.311 53.0794L200.094 55.4252C200.294 55.4798 200.44 55.6253 200.494 55.8253L202.84 63.6084C203.004 64.1722 203.823 64.1722 203.986 63.6084L206.332 55.8253C206.369 55.6253 206.514 55.4798 206.714 55.4252Z"
            fill="#AAB2C5"
          />
          <defs>
            <linearGradient
              id="paint0_linear_18292_1075"
              x1="214.948"
              y1="126.453"
              x2="342.873"
              y2="126.453"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#B0BACC" />
              <stop offset="1" stopColor="#969EAE" />
            </linearGradient>
          </defs>
        </svg>
      )}
    </EmptyContainer>
  );
};
