import {useQuery} from '@apollo/client';
import {
  GET_MESSAGES,
  getEvent,
  getMethod,
  getStatus,
  getTime,
} from 'entities/messageTemplates';
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Plus} from 'shared/icons/Plus';
import {EmptySendEmail} from 'shared/illustration/EmptySendEmail';
import {useAppSelector} from 'shared/store';
import {Button} from 'shared/ui/Button';
import {Content} from 'shared/ui/Content';
import {Grow} from 'shared/ui/Grow';
import {NavigateBack} from 'shared/ui/NavigateBack';
import {Seo} from 'shared/ui/Seo';
import {Skeleton} from 'shared/ui/Skeleton';
import {OptionsType, Table} from 'shared/ui/Table';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 56px;
  gap: 16px;
  margin: 0;
`;

export const MessageTemplates = () => {
  const companyId = useAppSelector(state => state.company.data!.id);
  const username = useAppSelector(state => state.company.data?.username);
  const navigate = useNavigate();
  const [options, setOptions] = useState<OptionsType>({
    page: 1,
    first: 20,
    total: 0,
    count: 0,
    lastPage: 1,
  });
  const {data, loading} = useQuery(GET_MESSAGES, {
    variables: {companyId, first: options.first, page: options.page},
    onCompleted: res =>
      setOptions(prev => ({
        ...prev,
        total: res.messageSamples?.paginatorInfo.total,
        count: res.messageSamples?.paginatorInfo.count,
        lastPage: res.messageSamples?.paginatorInfo.lastPage,
      })),
  });
  const messages = data?.messageSamples?.data ?? [];

  return (
    <Wrapper>
      <Seo title="Шаблоны рассылок" />
      <Header>
        <NavigateBack />
        <Text style={{flex: 1}} typography="title24">
          Шаблоны рассылок
        </Text>
        <Button
          leftIcon={<Plus />}
          onClick={() => navigate(`/${username}/create-message-sample`)}>
          Создать шаблон рассылок
        </Button>
      </Header>
      {loading ? (
        <SkeletonMessages />
      ) : messages.length ? (
        <Content>
          <Table
            onClick={index =>
              messages.length &&
              navigate(`/${username}/edit-message-sample/${messages[index].id}`)
            }
            titles={[
              'Название',
              'Способ рассылки',
              'Событие',
              'Момент рассылки',
              'Статус',
            ]}
            rows={messages.map(item => [
              <Text
                key={'name_' + item.id}
                color={item.is_active ? 'textPrimary' : 'textTertiary'}>
                {item.name}
              </Text>,
              <Text
                key={'method_' + item.id}
                color={item.is_active ? 'textPrimary' : 'textTertiary'}>
                {getMethod(item.method)}
              </Text>,
              <Text
                key={'event_' + item.id}
                color={item.is_active ? 'textPrimary' : 'textTertiary'}>
                {getEvent(item.event)}
              </Text>,
              <Text
                key={'time_' + item.id}
                color={item.is_active ? 'textPrimary' : 'textTertiary'}>
                {getTime(item.time)}
              </Text>,
              <Text
                key={'status_' + item.id}
                color={item.is_active ? 'textPrimary' : 'textTertiary'}>
                {getStatus(item.is_active)}
              </Text>,
            ])}
            options={options}
            setOptions={setOptions}
          />
        </Content>
      ) : (
        <EmptyMessages />
      )}
    </Wrapper>
  );
};

const EmptyMessages = () => {
  return (
    <Grow style={{padding: '0 16px'}}>
      <EmptySendEmail size={300} />
      <Text
        typography="title20"
        style={{textAlign: 'center', alignSelf: 'center', marginTop: 16}}>
        Шаблонов нету
      </Text>
      <Text
        typography="text16Regular"
        color="textTertiary"
        style={{textAlign: 'center', alignSelf: 'center', marginTop: 16}}>
        Создайте свой новый шаблон или используйте уже готовые шаблоны
      </Text>
    </Grow>
  );
};

const SkeletonMessages = () => (
  <Content>
    <Skeleton height={400}>
      <rect width={66} height={20} rx={8} ry={8} x={0} y={16} />
      <rect width={122} height={20} rx={8} ry={8} x="30%" y={16} />
      <rect width={62} height={20} rx={8} ry={8} x="50%" y={16} />
      <rect width={126} height={20} rx={8} ry={8} x="70%" y={16} />
      <rect width={48} height={20} rx={8} ry={8} x="90%" y={16} />
      {Array(4)
        .fill(0)
        .map((_, index) => (
          <React.Fragment key={'service_skeleton_' + index}>
            <rect
              width="15%"
              height={24}
              rx={8}
              ry={8}
              x={0}
              y={88 + 64 * index}
            />
            <rect
              width="8%"
              height={24}
              rx={8}
              ry={8}
              x="30%"
              y={88 + 64 * index}
            />
            <rect
              width="13%"
              height={24}
              rx={8}
              ry={8}
              x="50%"
              y={88 + 64 * index}
            />
            <rect
              width="10%"
              height={24}
              rx={8}
              ry={8}
              x="70%"
              y={88 + 64 * index}
            />
            <rect
              width="10%"
              height={24}
              rx={8}
              ry={8}
              x="90%"
              y={88 + 64 * index}
            />
          </React.Fragment>
        ))}
    </Skeleton>
  </Content>
);
