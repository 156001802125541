import React from 'react';
import {Content} from 'shared/ui/Content';
import {Skeleton} from 'shared/ui/Skeleton';

export const NotificationSk = () => {
  return (
    <Content>
      <Skeleton height={450}>
        {Array(3)
          .fill(0)
          .map((item, index) => (
            <React.Fragment key={'notification_booking_sk_' + index}>
              <rect
                width={40}
                height={40}
                rx={10}
                ry={10}
                x={0}
                y={index * 177}
              />
              <rect
                width={98}
                height={14}
                rx={7}
                ry={7}
                x={54}
                y={13 + index * 177}
              />
              <rect
                width={36}
                height={14}
                rx={7}
                ry={7}
                x={'calc(100% - 36px)'}
                y={13 + index * 177}
              />
              <rect
                width={121}
                height={14}
                rx={7}
                ry={7}
                x={0}
                y={56 + index * 177}
              />
              <rect
                width={138}
                height={14}
                rx={7}
                ry={7}
                x={169}
                y={56 + index * 177}
              />
              <rect
                width={153}
                height={14}
                rx={7}
                ry={7}
                x={0}
                y={82 + index * 177}
              />
              {index < 2 && (
                <rect width={'100%'} height={1} y={144 + index * 177} />
              )}
            </React.Fragment>
          ))}
      </Skeleton>
    </Content>
  );
};
