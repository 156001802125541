import axios from 'axios';

const config = {
  baseURL: process.env.REACT_APP_CHAT_GPT_BASE_URL,
  timeout: 60000,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${process.env.REACT_APP_CHAT_GPT_API_KEY}`,
  },
};

const GPT = axios.create(config);

type PromptTarget = 'employee' | 'company';

const defaultPrompt: Record<PromptTarget, string> = {
  employee:
    'Сгенерируй короткий ответ по этому отзыву, представь что ты мастер оказывающий эту услугу',
  company:
    'Сгенерируй короткий ответ по этому отзыву, представь что ты администратор салона оказывающий эту услугу',
};

export const generateReviewReply = async (
  review: string,
  rating: number,
  target: PromptTarget = 'employee',
) => {
  const payload = {
    model: 'gpt-3.5-turbo',
    messages: [
      {
        role: 'user',
        content:
          review + '\nС оценкой: ' + rating + '\n' + defaultPrompt[target],
      },
    ],
  };
  return GPT.post('/chat/completions', JSON.stringify(payload));
};
