import React, {useEffect, useState} from 'react';
import {useLazyQuery} from '@apollo/client';
import {gql} from 'shared/__generated__';
import {Search} from 'shared/icons/Search';
import {useAppSelector} from 'shared/store';
import {Button} from 'shared/ui/Button';
import {Skeleton} from 'shared/ui/Skeleton';
import {Text} from 'shared/ui/Text';
import {TextInput} from 'shared/ui/TextInput';
import styled from 'styled-components';
import {Flex} from 'shared/ui/Flex';
import {useNavigate} from 'react-router-dom';
import {getName} from 'shared/lib/utils';
import {EmptyEmployees} from 'shared/illustration/EmptyEmployees';
import {Avatar} from 'shared/ui/Avatar';
import {CheckBox} from 'shared/ui/CheckBox';
import {TableViewRow} from 'shared/ui/TableViewRow';
import {List} from 'shared/ui/List';
import {CloseButton} from 'shared/ui/CloseButton';
import {useColors} from 'shared/lib/hooks';

const Wrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${({theme}) => theme.bgPrimary};
  width: 588px;
  max-height: calc(100vh - 96px);
  border-radius: 24px;
  padding: 24px;
  z-index: 1001;
  gap: 24px;
  border: 1px solid ${({theme}) => theme.borderPrimary};
  ${({theme}) => theme.cardShadow}
`;

const Head = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`;

const FilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const Backdrop = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  background-color: ${({theme}) => theme.modalOverlay};
  opacity: 0.5;
  bottom: 0;
  z-index: 999;
  pointer-events: all;
`;

const EMPLOYEES = gql(`
query CompanyEmployeesLoyalty(
  $first: Int!
  $companyId: String!
  $page: Int
  $query: String
) {
  companyEmployees(
    first: $first
    company_id: $companyId
    page: $page
    query: $query
  ) {
    data {
      id
      enabled
      role
      avatar {
        id
        url
        url_256
      }
      professions {
        id
        name
      }
      name
      surname
      invite_status
    }
    paginatorInfo {
      currentPage
      hasMorePages
      lastPage
      total
    }
  }
}
`);

type Props = {
  label?: string;
  visible: boolean;
  onChange: (values: {label: string; value: string}[]) => void;
  onClose: () => void;
  values: {label: string; value: string}[];
};

export const SelectEmployees = ({
  label,
  values,
  visible,
  onChange,
  onClose,
}: Props) => {
  const companyId = useAppSelector(state => state.company.data!.id);
  const colors = useColors();
  const [search, setSearch] = useState('');
  const [selected, setSelected] = useState<string[]>([]);
  const [lazy, {data, loading}] = useLazyQuery(EMPLOYEES, {
    variables: {
      companyId,
      query: search,
      page: 1,
      first: 100,
    },
    onCompleted: ({companyEmployees}) => {
      const temp: string[] = [];
      companyEmployees?.data.map(employee => {
        if (values.map(value => value.value).includes(employee.id)) {
          temp.push(employee.id);
        }
      });
      setSelected(temp);
    },
  });

  useEffect(() => {
    visible && lazy();
  }, [lazy, visible]);

  const employees = data?.companyEmployees?.data ?? [];

  return (
    <>
      <Backdrop onClick={() => onClose()} />
      <Wrapper onClick={e => e.stopPropagation()}>
        <Head>
          <Text typography="title20" color="textPrimary">
            {label}
          </Text>
          <CloseButton
            onClose={() => {
              onClose();
            }}
          />
        </Head>
        <FilterWrapper>
          <TextInput
            width="540px"
            placeholder="Введите имя или телефон"
            value={search}
            height={54}
            variant="default"
            onChange={setSearch}
            leftElement={<Search color={colors.textTertiary} />}
          />
        </FilterWrapper>
        {loading ? (
          <EmployeeSkeleton />
        ) : employees.length ? (
          <>
            <Flex
              direction="column"
              flex={1}
              justifyContent="space-between"
              style={{maxHeight: 412, overflowY: 'scroll'}}>
              <List
                gap={24}
                keyExtractor={employee => `employee_${employee.id}`}
                data={employees}
                renderItem={employee => {
                  const checked = selected.includes(employee.id);
                  const onClick = () => {
                    if (checked) {
                      setSelected(prev =>
                        prev.filter(item => item !== employee.id),
                      );
                    } else {
                      setSelected(prev => [...prev, employee.id]);
                    }
                  };
                  return (
                    <Flex
                      onClick={onClick}
                      justifyContent="space-between"
                      alignItems="center"
                      gap={16}
                      style={{paddingRight: 8}}>
                      <Flex gap={16}>
                        <Avatar
                          size={64}
                          url={employee.avatar?.url_256 ?? employee.avatar?.url}
                        />
                        <TableViewRow
                          title={getName(
                            employee.name,
                            employee.surname,
                            'employee',
                          )}
                          subtitle={employee.professions
                            .map(item => item.name)
                            .join(', ')}
                        />
                      </Flex>
                      <CheckBox onChange={onClick} checked={checked} />
                    </Flex>
                  );
                }}
              />
            </Flex>
            <Footer>
              <Button
                size="large"
                style={{flex: 1}}
                onClick={() => {
                  const temp = employees
                    .filter(employee => selected.includes(employee.id))
                    .map(employee => ({
                      label: getName(
                        employee.name,
                        employee.surname,
                        'employee',
                      ),
                      value: employee.id,
                    }));
                  onChange(temp);
                }}>
                Сохранить
              </Button>
              <Button
                size="large"
                style={{flex: 1}}
                variant="light"
                onClick={() => onClose()}>
                Отмена
              </Button>
              {selected.length > 0 && (
                <Button
                  size="large"
                  variant="subtitle"
                  style={{
                    flex: 1,
                    backgroundColor: 'transparent',
                    cursor: 'default',
                  }}>
                  Выбрано {selected.length}
                </Button>
              )}
            </Footer>
          </>
        ) : (
          <EmptyState search={search} />
        )}
      </Wrapper>
    </>
  );
};

const EmployeeSkeleton = () => {
  return (
    <Skeleton height={344} style={{marginTop: 8}}>
      {Array(4)
        .fill(0)
        .map((_, index) => (
          <React.Fragment key={'service_skeleton_' + index}>
            <circle r={32} cx={32} cy={86 * index + 32} />
            <rect
              width={284}
              height={64}
              rx={20}
              ry={20}
              x={64 + 8}
              y={86 * index}
            />
            <rect
              width={24}
              height={24}
              rx={12}
              ry={12}
              x={500}
              y={86 * index + 18}
            />
          </React.Fragment>
        ))}
    </Skeleton>
  );
};

const EmptyState = ({search}: {search: string}) => {
  const navigate = useNavigate();
  const username = useAppSelector(state => state.company.data?.username);
  return (
    <Flex
      flex={1}
      direction="column"
      justifyContent="center"
      gap={16}
      alignItems="center">
      <EmptyEmployees size={300} />
      {search.length === 0 ? (
        <>
          <Text
            typography="text16Regular"
            color="textTertiary"
            style={{textAlign: 'center'}}>
            У вас пока нет сотрудников. Добавьте сотрудников и <br /> начните
            работу
          </Text>
          <Button
            size="large"
            style={{width: 360, marginTop: 16}}
            onClick={() => navigate(`/${username}/add-employee`)}>
            Добавить
          </Button>
        </>
      ) : (
        <Text
          typography="text16Regular"
          color="textTertiary"
          style={{textAlign: 'center'}}>
          По данному запросу сотрудников не найдено. <br /> Проверьте данные и
          попробуйте еще раз
        </Text>
      )}
    </Flex>
  );
};
