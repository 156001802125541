import React, {useState} from 'react';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';
import {useAppSelector} from 'shared/store';
import {Layout} from 'shared/ui/Layout';
import {PageHeader} from 'shared/ui/PageHeader';
import {Flex} from 'shared/ui/Flex';
import {Select} from 'shared/ui/Select';
import {Button} from 'shared/ui/Button';
import {ApolloError, useMutation, useQuery} from '@apollo/client';
import {gql} from 'shared/__generated__';
import {
  GET_WEBHOOKS,
  Key,
  SendWebHookType,
  TitleContent,
  WebhookRadioBox,
} from 'entities/webhooks';
import {TextInput} from 'shared/ui/TextInput';
import {useColors} from 'shared/lib/hooks';
import {TextSwitch} from 'shared/ui/TextSwitch';
import {CirclePlus} from 'shared/icons/CirclePlus';
import {WebHookType} from 'shared/__generated__/graphql';
import {showAlert} from 'shared/ui/Alert';
import {useNavigate, useParams} from 'react-router-dom';
import {Skeleton} from 'shared/ui/Skeleton';
import {Trash} from 'shared/icons/Trash';
import {TextArea} from 'shared/ui/TextArea';
import {Popup} from 'shared/ui/Popup';
import {Head} from 'shared/ui/Head';
import {CloseButton} from 'shared/ui/CloseButton';

const UPDATE_WEBHOOK = gql(`
  mutation UpdateWebHook($input: UpdateWebHook!) {
    updateWebHook(input: $input) {
      id
      email
      description
      is_active
      notify_email
      send_type
      type
      interval
      tries
    }
  }
`);

const DELETE_WEBHOOK = gql(`
  mutation DeleteWebHook($id: String!) {
    deleteWebHook(id: $id) {
      id
    }
  }
`);

const GET_WEBHOOK = gql(`
  query GetWebHook($companyId: String!, $id: String) {
    getWebHooks(company_id: $companyId, id: $id) {
      id
      url
      send_type
      type
      is_active
      answer_code
      custom_header
      custom_payload
      description
      email
      notify_email
      interval
      tries
    }
  }
`);

type IKey = {id: number; name: string; value: string};
type IResKey = {name: string; value: string};
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-column-start: 4;
  grid-column-end: 10;
  gap: 24px;
  padding: 0 0 16px 0;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const EventsContainer = styled.div`
  display: grid;
  gap: 16px 8px;
  grid-template-columns: 50% 50%;
`;
const AddKey = styled.button`
  display: flex;
  padding: 0;
  border: none;
  background-color: transparent;
  gap: 8px;
  height: 48px;
  align-items: center;
  pointer-events: all;
  cursor: pointer;
  font-family: 'Inter';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: ${({theme}) => theme.mainPrimary};
  align-items: center;
`;

export const EditWebhook = () => {
  const companyId = useAppSelector(state => state.company.data!.id);
  const colors = useColors();
  const {id} = useParams();
  const navigate = useNavigate();
  const [enable, setEnable] = useState(true);
  const [url, setUrl] = useState('');
  const [errorUrl, setErrorUrl] = useState<string | null>(null);
  const [type, setType] = useState(SendWebHookType[0].value);
  const [answerCode, setAnswerCode] = useState('200');
  const [description, setDescription] = useState('');

  const [attempts, setAttempts] = useState('5');
  const [interval, setInterval] = useState('10');
  const [email, setEmail] = useState('');
  const [errorMail, setErrorMail] = useState<string | null>(null);
  const [notificationEmail, setNotificationEmail] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const [bookingType, setBookingType] = useState<WebHookType>(
    WebHookType.BookingCreated,
  );

  const [params, setParams] = useState<IKey[]>([]);
  const [headings, setHeadings] = useState<IKey[]>([]);

  const {loading} = useQuery(GET_WEBHOOK, {
    variables: {companyId, id: id!},
    onCompleted: res => {
      const webHook = res.getWebHooks?.length ? res.getWebHooks[0] : null;
      if (!webHook) return;
      setEnable(webHook.is_active);
      setUrl(webHook.url);
      setType(webHook.send_type);
      setAnswerCode(webHook.answer_code.toString());
      setDescription(webHook.description ?? '');
      setAttempts(webHook.tries.toString());
      setInterval(webHook.interval.toString());
      setEmail(webHook.email ?? '');
      setNotificationEmail(webHook.notify_email);
      setBookingType(webHook.type);
      if (webHook.custom_header.length) {
        const formattedHeaders: IKey[] = webHook.custom_header.map(
          (item: IResKey, index: number) => ({
            value: item.value,
            name: item.name,
            id: index,
          }),
        );
        setHeadings(formattedHeaders);
      }
      if (webHook.custom_payload.length) {
        const formattedParams: IKey[] = webHook.custom_payload.map(
          (item: IResKey, index: number) => ({
            value: item.value,
            name: item.name,
            id: index,
          }),
        );
        setParams(formattedParams);
      }
    },
  });

  const [updateWebhook, {loading: updateLoading}] = useMutation(
    UPDATE_WEBHOOK,
    {
      refetchQueries: [
        {
          query: GET_WEBHOOKS,
          variables: {
            companyId,
          },
        },
      ],
    },
  );

  const [deleteWebhook, {loading: deleteLoading}] = useMutation(
    DELETE_WEBHOOK,
    {
      refetchQueries: [
        {
          query: GET_WEBHOOKS,
          variables: {
            companyId,
          },
        },
      ],
    },
  );

  return (
    <Layout>
      <Wrapper>
        <PageHeader variant="layout" back title="Редактировать Webhook" />
        {loading ? (
          <WebhookSkeleton />
        ) : (
          <>
            <TitleContent title="Основная настройки">
              <TextSwitch
                title="Включить Webhook"
                value={enable}
                onClick={setEnable}
              />
              <Flex gap={8}>
                <Select
                  wrapperStyle={{flex: 2}}
                  value={type}
                  onChange={setType}
                  label="Метод"
                  data={SendWebHookType}
                />
                <TextInput
                  flex={6}
                  value={url}
                  required
                  error={errorUrl}
                  onChange={name => {
                    if (errorUrl) setErrorUrl(null);
                    setUrl(name);
                  }}
                  label="URL"
                />
              </Flex>
              <Flex direction="column" gap={8}>
                <TextInput
                  value={answerCode}
                  type="number"
                  onChange={setAnswerCode}
                  label="Код ответа"
                />
                <Text color="textTertiary" typography="footNote12Regular">
                  Повторять попытки с заданным интервалом до получения этого
                  кода ответа
                </Text>
              </Flex>
              <TextArea
                size="medium"
                value={description}
                onChange={setDescription}
                label="Описание"
              />
            </TitleContent>
            <TitleContent title="Автоматическое отключение">
              <Flex direction="column" gap={8}>
                <Flex gap={8}>
                  <TextInput
                    flex={1}
                    type="number"
                    style={{
                      color:
                        +attempts === 5
                          ? colors.textTertiary
                          : colors.textPrimary,
                    }}
                    value={attempts}
                    onChange={setAttempts}
                    label="Попыток"
                  />
                  <TextInput
                    flex={1}
                    type="number"
                    style={{
                      color:
                        +interval === 10
                          ? colors.textTertiary
                          : colors.textPrimary,
                    }}
                    value={interval}
                    onChange={setInterval}
                    label="Интервал (сек)"
                  />
                </Flex>
                <Text color="textTertiary" typography="footNote12Regular">
                  После {attempts.length ? attempts : 5} неудачных попыток
                  подряд с интервалом {interval.length ? interval : 10} секунд,
                  webhook будет автоматически отключен
                </Text>
              </Flex>
              <TextInput
                value={email}
                error={errorMail}
                onChange={text => {
                  setEmail(text);
                  if (!notificationEmail) {
                    setNotificationEmail(true);
                  }
                }}
                label="Почта для оповещения"
              />
              <TextSwitch
                title="Оповещать по E-mail"
                value={notificationEmail}
                onClick={setNotificationEmail}
              />
            </TitleContent>
            <TitleContent title="Запускать по событию">
              <EventsContainer>
                <WebhookRadioBox
                  title="booking-created"
                  description="Событие наступает сразу после создания записи из календаря или виджета."
                  checked={bookingType === WebHookType.BookingCreated}
                  onClick={() => setBookingType(WebHookType.BookingCreated)}
                />
                <WebhookRadioBox
                  title="booking-updated"
                  description="Событие наступает сразу после обновления записи."
                  checked={bookingType === WebHookType.BookingUpdated}
                  onClick={() => setBookingType(WebHookType.BookingUpdated)}
                />
                <WebhookRadioBox
                  title="booking-succeeded"
                  description="Событие наступает сразу после успешного завершения записи."
                  checked={bookingType === WebHookType.BookingSucceeded}
                  onClick={() => setBookingType(WebHookType.BookingSucceeded)}
                />
                <WebhookRadioBox
                  title="booking-canceled"
                  description="Событие наступает сразу после отмены записи."
                  checked={bookingType === WebHookType.BookingCancelled}
                  onClick={() => setBookingType(WebHookType.BookingCancelled)}
                />
                <WebhookRadioBox
                  title="products-sold"
                  description="Событие наступает сразу после оформления продажи товара."
                  checked={bookingType === WebHookType.ProductsSold}
                  onClick={() => setBookingType(WebHookType.ProductsSold)}
                />
              </EventsContainer>
            </TitleContent>
            <TitleContent title="Параметры">
              {params.map((param, index) => (
                <Key
                  key={'param_' + index}
                  data={param}
                  onChange={newData => {
                    const copy = [...params];
                    copy[index] = newData;
                    setParams(copy);
                  }}
                  onDelete={id =>
                    setParams(params.filter(param => param.id !== id))
                  }
                />
              ))}
              <AddKey
                onClick={() =>
                  setParams([
                    ...params,
                    {
                      id: params.length ? params[params.length - 1].id + 1 : 0,
                      name: '',
                      value: '',
                    },
                  ])
                }>
                <CirclePlus />
                Добавить ключ
              </AddKey>
            </TitleContent>
            <TitleContent title="Заголовки">
              {headings.map((heading, index) => (
                <Key
                  key={'headings' + index}
                  data={heading}
                  onChange={newData => {
                    const copy = [...headings];
                    copy[index] = newData;
                    setHeadings(copy);
                  }}
                  onDelete={id =>
                    setHeadings(headings.filter(heading => heading.id !== id))
                  }
                />
              ))}
              <AddKey
                onClick={() =>
                  setHeadings([
                    ...headings,
                    {
                      id: headings.length
                        ? headings[headings.length - 1].id + 1
                        : 0,
                      name: '',
                      value: '',
                    },
                  ])
                }>
                <CirclePlus />
                Добавить ключ
              </AddKey>
            </TitleContent>
          </>
        )}

        <Flex gap={16}>
          <Button
            variant="outline"
            size="large"
            style={{
              flex: 1,
              color: colors.dangerPrimary,
              borderColor: colors.dangerPrimary,
            }}
            leftIcon={<Trash color={colors.dangerPrimary} />}
            onClick={() => setDeleteModal(true)}>
            Удалить
          </Button>
          <Button
            style={{flex: 1}}
            loading={updateLoading}
            onClick={() => {
              if (url.length === 0) {
                setErrorUrl('Обязательное поле');
              }
              const emailRegex = /@/;
              if (email.length && !emailRegex.test(email)) {
                setErrorMail('Неправильный ввод email');
              }
              if ((email.length && !emailRegex.test(email)) || !url.length) {
                return null;
              }
              const formattedParams = params.map(param => ({
                name: param.name,
                value: param.value,
              }));
              const formattedHeaders = headings.map(param => ({
                name: param.name,
                value: param.value,
              }));
              const input = {
                answer_code: +answerCode,
                description,
                email,
                is_active: enable,
                notify_email: notificationEmail,
                send_type: type,
                type: bookingType,
                url,
                id: id!,
                interval: +interval,
                tries: +attempts,
              };
              if (formattedParams.length) {
                Object.assign(input, {custom_payload: formattedParams});
              }
              if (formattedHeaders.length) {
                Object.assign(input, {custom_header: formattedHeaders});
              }
              updateWebhook({variables: {input}})
                .then(() => {
                  showAlert('success', 'webhook успешно изменен!');
                  navigate(-1);
                })
                .catch((e: ApolloError) => showAlert('error', e.message));
            }}
            size="large">
            Сохранить
          </Button>
        </Flex>
        <Popup
          style={{gap: 16, width: 440}}
          onClose={() => setDeleteModal(false)}
          visible={deleteModal}>
          <Head
            style={{
              justifyContent: 'space-between',
              width: '100%',
              marginBottom: 0,
            }}>
            <Text typography="title20">Удалить Webhook</Text>
            <CloseButton onClose={() => setDeleteModal(false)} />
          </Head>
          <Text style={{width: '100%'}}>
            Вы уверены, что хотите удалить этот Webhook?
          </Text>
          <Flex style={{width: '100%'}} justifyContent="space-between" gap={8}>
            <Button
              size="large"
              variant="outline"
              style={{flex: 1, borderColor: colors.mainPrimary}}
              onClick={() => setDeleteModal(false)}>
              Отмена
            </Button>
            <Button
              size="large"
              variant="outline"
              loading={deleteLoading}
              onClick={() => {
                deleteWebhook({variables: {id: id!}})
                  .then(() => {
                    showAlert('success', 'Webhook успешно удален!');
                    navigate(-1);
                  })
                  .catch((e: ApolloError) => showAlert('error', e.message));
              }}
              style={{
                flex: 1,
                borderColor: colors.dangerPrimary,
                color: colors.dangerPrimary,
              }}>
              Удалить
            </Button>
          </Flex>
        </Popup>
      </Wrapper>
    </Layout>
  );
};

const WebhookSkeleton = () => {
  return (
    <>
      <TitleContent title="Основная настройки">
        <Skeleton height={248}>
          <rect width="10%" height={64} rx={14} ry={14} x={0} y={0} />
          <rect
            width="calc(90% - 8px)"
            height={64}
            rx={14}
            ry={14}
            x="calc(10% + 8px)"
            y={0}
          />
          <rect width="100%" height={64} rx={14} ry={14} x={0} y={80} />
          <rect width="100%" height={88} rx={14} ry={14} x={0} y={160} />
        </Skeleton>
      </TitleContent>
      <TitleContent title="Автоматическое отключение">
        <Skeleton height={144}>
          <rect width="50%" height={64} rx={14} ry={14} x={0} y={0} />
          <rect
            width="calc(50% - 8px)"
            height={64}
            rx={14}
            ry={14}
            x="calc(50% + 8px)"
            y={0}
          />
          <rect width="100%" height={64} rx={14} ry={14} x={0} y={80} />
        </Skeleton>
      </TitleContent>
      <TitleContent title="Запускать по событию">
        <EventsContainer>
          <WebhookRadioBox
            title="booking-created"
            description="Событие наступает сразу после создания записи из календаря или виджета."
          />
          <WebhookRadioBox
            title="booking-updated"
            description="Событие наступает сразу после обновления записи."
          />
          <WebhookRadioBox
            title="booking-succeeded"
            description="Событие наступает сразу после успешного завершения записи."
          />
          <WebhookRadioBox
            title="booking-canceled"
            description="Событие наступает сразу после отмены записи."
          />
          <WebhookRadioBox
            title="products-sold"
            description="Событие наступает сразу после оформления продажи товара."
          />
        </EventsContainer>
      </TitleContent>
      <TitleContent title="Параметры">
        <Skeleton height={64}>
          <rect width="50%" height={64} rx={14} ry={14} x={0} y={0} />
          <rect
            width="calc(50% - 8px)"
            height={64}
            rx={14}
            ry={14}
            x="calc(50% + 8px)"
            y={0}
          />
        </Skeleton>
      </TitleContent>
      <TitleContent title="Заголовки">
        <Skeleton height={64}>
          <rect width="50%" height={64} rx={14} ry={14} x={0} y={0} />
          <rect
            width="calc(50% - 8px)"
            height={64}
            rx={14}
            ry={14}
            x="calc(50% + 8px)"
            y={0}
          />
        </Skeleton>
      </TitleContent>
    </>
  );
};
