import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {Text} from 'shared/ui/Text';
import {Flex} from 'shared/ui/Flex';
import {useColors, useResize} from 'shared/lib/hooks';
import {IconButton} from 'shared/ui/IconButton';
import {ArrowLeft2v} from 'shared/icons/ArrowLeft2v';
import {ArrowRight2v} from 'shared/icons/ArrowRight2v';

type ListHorizontalProps = React.PropsWithChildren<{title?: string}>;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 0 24px;
  background-color: ${({theme}) => theme.bgPrimary};
  border-radius: 24px;
`;
const List = styled.div`
  display: flex;
  gap: 24px;
  overflow-x: scroll;
  padding: 0 24px 24px;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;
export const ListHorizontal: React.FC<ListHorizontalProps> = ({
  children,
  title,
}) => {
  const colors = useColors();
  const {width} = useResize();
  const containerRef = useRef<HTMLDivElement>(null);
  const [showScrollButtons, setShowScrollButtons] = useState(false);
  useEffect(() => {
    if (
      containerRef.current !== null &&
      containerRef.current.scrollWidth &&
      containerRef.current.clientWidth
    ) {
      const handleScroll = () => {
        containerRef.current !== null &&
          setShowScrollButtons(
            containerRef.current.scrollWidth > containerRef.current.clientWidth,
          );
      };
      containerRef.current.addEventListener('scroll', handleScroll);
      handleScroll();
    }
  }, [width]);

  const scrollTo = (direction: 'left' | 'right') => {
    const container = containerRef.current;
    if (!container) return;
    const scrollAmount =
      direction === 'right' ? container.clientWidth : -container.clientWidth;
    container.scrollBy({
      left: scrollAmount,
    });
  };

  return (
    <Container>
      <Flex
        mt={24}
        style={{marginLeft: 24, marginRight: 24}}
        alignItems="center"
        justifyContent="space-between">
        <Text typography="title20">{title}</Text>
        {showScrollButtons && (
          <Row>
            <IconButton
              size={32}
              style={{
                borderRadius: 32,
                backgroundColor: colors.bgPrimary,
              }}
              onClick={() => scrollTo('left')}>
              <ArrowLeft2v />
            </IconButton>
            <IconButton
              size={32}
              style={{
                borderRadius: 32,
                backgroundColor: colors.bgPrimary,
              }}
              onClick={() => scrollTo('right')}>
              <ArrowRight2v />
            </IconButton>
          </Row>
        )}
      </Flex>
      <List ref={containerRef}>{children}</List>
    </Container>
  );
};
