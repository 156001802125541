import React from 'react';
import {useColorScheme} from 'shared/lib/hooks';

type Props = {
  size?: number;
};
export const Avatar = ({size = 56}: Props) => {
  const colorScheme = useColorScheme();

  if (colorScheme === 'dark') {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_11126_10932)">
          <path
            d="M56 28C56 34.8453 53.5605 41.0715 49.4824 45.9506C44.3485 52.104 36.6294 56 27.9636 56C19.6619 56 12.2341 52.3953 7.10013 46.6788C2.69441 41.7269 0 35.173 0 28C0 12.5254 12.5254 0 28 0C43.4746 0 56 12.5254 56 28Z"
            fill="#4B5161"
          />
          <path
            d="M56 56H28H0C0 56 0.917529 50.1508 3.5 47.8333C4.52424 46.9142 6.47889 45.9507 6.47889 45.9507C6.47889 45.9507 15.9118 42.7101 19.298 41.2901C20.0626 40.9624 20.6816 40.3798 21.0093 39.6152C21.1913 39.2147 21.3006 38.7413 21.3006 38.268V31.9689C21.1913 31.8233 21.0821 31.714 21.0093 31.5684C20.4631 30.7674 20.099 29.8207 19.9898 28.8012L19.4436 28.5463C17.8051 28.9468 17.9144 27.1991 17.5138 23.8493C17.3318 22.4657 17.5502 22.1744 18.1692 21.9559L18.679 21.2641C16.5789 14.3824 17.1133 9.72184 20.2082 8.15617C19.1887 5.8987 19.6396 6.37657 19.6396 6.37657C19.6396 6.37657 25.7063 6.18998 28.0002 5.75305C30.8766 5.17048 35.3188 5.86229 36.9573 9.68543C39.6881 10.7778 40.0808 12.6451 40.2993 14.5384C40.6998 17.5241 39.6881 20.6451 39.3604 21.9195C39.3968 21.9923 39.4332 22.0287 39.4332 22.0651C39.8701 22.2836 40.0158 22.6477 39.8701 23.8493C39.4696 27.017 39.5788 28.9832 37.9404 28.5463L36.4839 31.0951C36.4111 31.3863 36.4111 31.4956 36.3747 31.714C36.3019 32.1874 36.3383 33.1341 36.3383 38.3408C36.3383 38.9598 36.5203 39.5424 36.848 40.0886C37.2121 40.6347 37.7219 41.1081 38.3773 41.3629L49.462 45.9507C49.462 45.9507 51.4568 46.906 52.5 47.8333C55.0933 50.1387 56 56 56 56Z"
            fill="#A9AFC3"
          />
          <path
            d="M36.9213 40.0157V41.2537H21.0098V39.6516C21.1918 39.2511 21.3011 38.7777 21.3011 38.3044V31.9689C21.1918 31.8232 21.0826 31.714 21.0098 31.5684C21.3375 32.1509 21.7744 32.333 22.3206 32.7699C22.3206 32.7699 24.1238 34.4659 25.5062 35.1981C26.8979 35.9352 27.7575 36.5069 29.332 36.4734C30.8125 36.4419 31.6376 35.9673 32.9029 35.1981C34.3639 34.3099 36.0111 32.1145 36.0111 32.1145C36.1567 31.9689 36.328 31.5178 36.4737 31.3722C36.4008 31.8455 36.448 33.0976 36.448 38.3044C36.4116 38.9234 36.5936 39.5424 36.9213 40.0157Z"
            fill="url(#paint0_linear_11126_10932)"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_11126_10932"
            x1="28.9704"
            y1="41.3148"
            x2="28.9704"
            y2="35.7786"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#8E94A7" stopOpacity="0" />
            <stop offset="1" stopColor="#6C707C" />
          </linearGradient>
          <clipPath id="clip0_11126_10932">
            <rect width="56" height="56" rx="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_14814_7793)">
        <path
          d="M56 28C56 34.8453 53.5605 41.0715 49.4824 45.9506C44.3485 52.104 36.6294 56 27.9636 56C19.6619 56 12.2341 52.3953 7.10013 46.6788C2.69441 41.7269 0 35.173 0 28C0 12.5254 12.5254 0 28 0C43.4746 0 56 12.5254 56 28Z"
          fill={'#EAEEF9'}
        />
        <path
          d="M56 56H28H0C0 56 0.917529 50.1508 3.5 47.8333C4.52424 46.9142 6.47889 45.9507 6.47889 45.9507C6.47889 45.9507 15.9118 42.7101 19.298 41.2901C20.0626 40.9624 20.6816 40.3798 21.0093 39.6152C21.1913 39.2147 21.3006 38.7413 21.3006 38.268V31.9689C21.1913 31.8233 21.0821 31.714 21.0093 31.5684C20.4631 30.7674 20.099 29.8207 19.9898 28.8012L19.4436 28.5463C17.8051 28.9468 17.9144 27.1991 17.5138 23.8493C17.3318 22.4657 17.5502 22.1744 18.1692 21.9559L18.679 21.2641C16.5789 14.3824 17.1133 9.72184 20.2082 8.15617C19.1887 5.8987 19.6396 6.37657 19.6396 6.37657C19.6396 6.37657 25.7063 6.18998 28.0002 5.75305C30.8766 5.17048 35.3188 5.86229 36.9573 9.68543C39.6881 10.7778 40.0808 12.6451 40.2993 14.5384C40.6998 17.5241 39.6881 20.6451 39.3604 21.9195C39.3968 21.9923 39.4332 22.0287 39.4332 22.0651C39.8701 22.2836 40.0158 22.6477 39.8701 23.8493C39.4696 27.017 39.5788 28.9832 37.9404 28.5463L36.4839 31.0951C36.4111 31.3863 36.4111 31.4956 36.3747 31.714C36.3019 32.1874 36.3383 33.1341 36.3383 38.3408C36.3383 38.9598 36.5203 39.5424 36.848 40.0886C37.2121 40.6347 37.7219 41.1081 38.3773 41.3629L49.462 45.9507C49.462 45.9507 51.4568 46.906 52.5 47.8333C55.0933 50.1387 56 56 56 56Z"
          fill={'#FFFFFF'}
        />
        <path
          d="M36.9194 40.0156V41.2535H21.0078V39.6515C21.1899 39.2509 21.2991 38.7776 21.2991 38.3043V31.9688C21.1899 31.8231 21.0806 31.7139 21.0078 31.5682C21.3355 32.1508 21.7724 32.3329 22.3186 32.7698C22.3186 32.7698 24.1219 34.4658 25.5042 35.1979C26.896 35.9351 27.7555 36.5067 29.3301 36.4732C30.8105 36.4417 31.6356 35.9671 32.9009 35.1979C34.3619 34.3098 36.0091 32.1144 36.0091 32.1144C36.1548 31.9688 36.3261 31.5177 36.4717 31.3721C36.3989 31.8454 36.446 33.0975 36.446 38.3043C36.4096 38.9232 36.5917 39.5422 36.9194 40.0156Z"
          fill="url(#paint0_linear_14814_7793)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_14814_7793"
          x1="28.9684"
          y1="41.3146"
          x2="28.9684"
          y2="35.7785"
          gradientUnits="userSpaceOnUse">
          <stop stopColor={'white'} />
          <stop offset="1" stopColor={'#E2E5EC'} />
        </linearGradient>
        <clipPath id="clip0_14814_7793">
          <rect width="56" height="56" rx="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
