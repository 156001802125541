import {ApolloError, useMutation, useQuery} from '@apollo/client';
import {DELETE_MANY_PRODUCTS, GET_PRODUCTS} from 'entities/products';
import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {Search} from 'shared/icons/Search';
import {useColors, useDebounce, useEmployeePermission} from 'shared/lib/hooks';
import {useAppSelector} from 'shared/store';
import {Button} from 'shared/ui/Button';
import {Flex} from 'shared/ui/Flex';
import {FooterAction} from 'shared/ui/FooterAction';
import {OptionsType, Table} from 'shared/ui/Table';
import {TextInput} from 'shared/ui/TextInput';
import {SkeletonProduct} from './Skeleton';
import {EmptyStateProducts} from './EmptyState';
import {Content} from 'shared/ui/Content';
import {getPriceFormat} from 'shared/lib/utils';
import {Slide} from 'shared/ui/Slide';
import {showAlert} from 'shared/ui/Alert';
import {FilterBtn} from 'shared/ui/FilterButton';
import {Plus} from 'shared/icons/Plus';

export const Products = ({
  categories,
  brands,
  onModalOpen,
  refetchQuery,
}: {
  categories: string[];
  brands: string[];
  onModalOpen: () => void;
  refetchQuery: boolean;
}) => {
  const colors = useColors();
  const navigate = useNavigate();
  const location = useLocation();
  const username = useAppSelector(state => state.company.data?.username);
  const [search, setSearch] = useState('');
  const companyId = useAppSelector(state => state.company.data?.id);
  const [options, setOptions] = useState<OptionsType>({
    page: 1,
    first: 10,
    total: 0,
    count: 0,
  });
  const {data, loading, refetch} = useQuery(GET_PRODUCTS, {
    variables: {
      companyId: companyId!,
      productsBrands: brands.length ? brands : undefined,
      productsCategory: categories.length ? categories : undefined,
      first: options.first,
      page: options.page,
      query: search,
    },
    onCompleted: res => {
      setOptions(prev => ({
        ...prev,
        total: res.getProducts?.paginatorInfo.total,
        count: res.getProducts?.paginatorInfo.count,
        lastPage: res.getProducts?.paginatorInfo.lastPage,
      }));
    },
  });
  const {edit_products_permission} = useEmployeePermission(data);
  const [deleteManyProducts, {loading: loadingDelete}] = useMutation(
    DELETE_MANY_PRODUCTS,
    {
      refetchQueries: [
        {
          query: GET_PRODUCTS,
          variables: {
            companyId: companyId!,
            productsBrands: brands.length ? brands : undefined,
            productsCategory: categories.length ? categories : undefined,
            first: options.first,
            page: options.page,
            query: search,
          },
        },
      ],
    },
  );
  const [selections, setSelections] = useState<number[]>([]);
  const products = data?.getProducts?.data ?? [];

  const setSearchDebounce = useDebounce((query: string) => {
    refetch({
      companyId: companyId!,
      query,
      productsCategory: categories.length ? categories : undefined,
      productsBrands: brands.length ? brands : undefined,
    });
  }, 400);

  useEffect(() => {
    if (refetchQuery) {
      refetch().then(() => {
        navigate(location.pathname, {
          state: {
            refetch: false,
          },
        });
      });
    }
  }, [location.pathname, navigate, refetch, refetchQuery]);

  return (
    <Slide style={{height: '100%'}}>
      <Flex direction="column" flex={1}>
        <Flex
          direction="column"
          flex={1}
          style={{padding: '0 24px 24px 24px'}}
          gap={products.length > 0 ? 24 : 32}>
          <Flex
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end">
            <Flex
              direction="row"
              gap={8}
              alignItems="center"
              justifyContent="flex-end"
              style={{paddingTop: 4}}>
              <TextInput
                height={54}
                backgroundColor={colors.bgPrimary}
                variant="default"
                width="392px"
                borderRadius={14}
                leftElement={<Search color={colors.textTertiary} />}
                placeholder="Поиск"
                value={search}
                onChange={value => {
                  setSearch(value);
                  setSearchDebounce(value);
                }}
              />
              <FilterBtn
                onClick={onModalOpen}
                style={{height: 56}}
                indicator={categories.length + brands.length}
              />
            </Flex>
            {edit_products_permission && (
              <Button
                size="medium"
                variant="filled"
                onClick={() => navigate(`/${username}/create-product`)}>
                <Plus color={colors.white} />
                Добавить товар
              </Button>
            )}
          </Flex>
          {loading ? (
            <SkeletonProduct />
          ) : products.length === 0 ? (
            <EmptyStateProducts />
          ) : (
            <Content style={{height: 'max-content'}}>
              <Table
                titles={[
                  'Название товара',
                  'Категория',
                  'Бренд',
                  'Количество',
                  'Розничная цена',
                  'Цена со скидкой',
                ]}
                widths={[320, 280, 280, 188, 219, 219]}
                selections={selections}
                options={options}
                setOptions={setOptions}
                onSelectionChange={values => setSelections(values)}
                rows={products.map(product => [
                  product?.name,
                  product?.products_category?.name,
                  product?.products_brand?.name,
                  product?.quantity ?? '-',
                  getPriceFormat(product?.retail_price),
                  getPriceFormat(product?.discount_price),
                ])}
                styleTd={{
                  cursor: 'pointer',
                }}
                onClick={index => {
                  const product = products[index];
                  if (product) {
                    navigate(`/${username}/product/${product.id}`);
                  }
                }}
              />
            </Content>
          )}
        </Flex>
        {selections.length > 0 && (
          <FooterAction
            allICount={options.count}
            selectedCount={selections.length}
            disabled={selections.length === 0}
            editPermission={true}
            onDelete={async () => {
              try {
                await deleteManyProducts({
                  variables: {
                    deleteManyProductsId: products
                      .filter((_, index) => selections.includes(index))
                      .map(item => item!.id),
                  },
                });
                setSelections([]);
              } catch (e) {
                if (e instanceof ApolloError) {
                  showAlert('error', e.message);
                }
              }
            }}
            deleteLoading={loadingDelete}
            title="Удалить товары"
            text="Вы уверены, что хотите удалить выбранные товары?"
          />
        )}
      </Flex>
    </Slide>
  );
};
