import React from 'react';
import styled from 'styled-components';
import {Select} from 'shared/ui/Select';
import {device} from 'shared/device';

const periodData: {label: string; value: number | undefined}[] = [
  {label: 'За все время', value: undefined},
  {label: 'Сегодня', value: 0},
  {label: 'Вчера', value: 1},
  {label: 'Последние 7 дней', value: 7},
  {label: 'Последние 30 дней', value: 30},
  {label: 'Этот месяц', value: 999},
];

const typeData: {label: string; value: 'with' | 'without' | undefined}[] = [
  {label: 'Все отзывы', value: undefined},
  {label: 'Отзывы с ответом', value: 'with'},
  {label: 'Отзывы без ответа', value: 'without'},
];

const recipientData: {
  label: string;
  value: 'employees' | 'company' | undefined;
}[] = [
  {label: 'Общие отзывы', value: undefined},
  {label: 'Отзывы сотрудникам', value: 'employees'},
  {label: 'Отзывы организации', value: 'company'},
];

const ratingData: {label: string; value: 'good' | 'bad' | undefined}[] = [
  {label: 'Все оценки', value: undefined},
  {label: 'Положительные', value: 'good'},
  {label: 'Негативные', value: 'bad'},
];

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  pointer-events: all;
  gap: 24px;
  @media (${device.mobile}) {
    gap: 8px;
  }
`;

type FilterType = {
  reply?: 'with' | 'without';
  target?: 'company' | 'employees';
  ratings?: 'good' | 'bad';
  period?: number;
};

export const ReviewsFilters = ({
  onChange,
  filtersData,
}: {
  onChange: (value: FilterType) => void;
  filtersData: FilterType;
}) => {
  return (
    <Wrapper>
      <Select
        variant="label_animation"
        label="Период"
        style={{flex: 1}}
        data={periodData.map(item => ({
          value: item.value,
          label: item.label,
        }))}
        value={filtersData.period}
        onChange={value => {
          onChange({...filtersData, period: value});
        }}
      />

      <Select
        variant="label_animation"
        label="Оценка"
        style={{flex: 1}}
        data={ratingData.map(item => ({
          label: item.label,
          value: item.value,
        }))}
        value={filtersData.ratings}
        onChange={value => {
          onChange({
            ...filtersData,
            ratings: value as 'good' | 'bad' | undefined,
          });
        }}
      />

      <Select
        variant="label_animation"
        label="Адресат"
        style={{flex: 1}}
        data={recipientData.map(item => ({
          value: item.value,
          label: item.label,
        }))}
        value={filtersData.target}
        onChange={value => {
          onChange({
            ...filtersData,
            target: value as 'company' | 'employees' | undefined,
          });
        }}
      />
      <Select
        variant="label_animation"
        label="Обработка"
        style={{flex: 1}}
        data={typeData.map(item => ({
          value: item.value,
          label: item.label,
        }))}
        value={filtersData.reply}
        onChange={value => {
          onChange({
            ...filtersData,
            reply: value as 'with' | 'without' | undefined,
          });
        }}
      />
    </Wrapper>
  );
};
