import {ApolloError, useMutation, useQuery} from '@apollo/client';
import {
  GET_PRODUCTS,
  GET_PRODUCTS_ONE,
  SelectBrand,
  SelectProductsCategory,
  UPDATE_PRODUCTS,
  productPieceCount,
} from 'entities/products';
import React, {useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {gql} from 'shared/__generated__';
import {
  ProductsPieceCount,
  Image as ImageUpload,
  ImageType,
} from 'shared/__generated__/graphql';
import {useAppSelector} from 'shared/store';
import {showAlert} from 'shared/ui/Alert';
import {Button} from 'shared/ui/Button';
import {CheckBox} from 'shared/ui/CheckBox';
import {Content} from 'shared/ui/Content';
import {DragDrop} from 'shared/ui/DragDrop';
import {Flex} from 'shared/ui/Flex';
import {Image} from 'shared/ui/Image';
import {ImageView} from 'shared/ui/ImageView';
import {Layout} from 'shared/ui/Layout';
import {PageHeader} from 'shared/ui/PageHeader';
import {Select} from 'shared/ui/Select';
import {Switch} from 'shared/ui/Switch';
import {TableViewRow} from 'shared/ui/TableViewRow';
import {Text} from 'shared/ui/Text';
import {TextArea} from 'shared/ui/TextArea';
import {TextInput} from 'shared/ui/TextInput';
import {styled} from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  grid-column-start: 4;
  grid-column-end: 10;
  gap: 32px;
  padding-bottom: 24px;
`;

const ImageArea = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 16px 8px;
`;

const GET_DATA = gql(`
  query GetDataCreateProductEdit($companyId: String!, $first: Int!, $categoryFirst: Int!) {
    productsBrands(company_id: $companyId, first: $first) {
        data {
        id
        name
        products {
            id
          }
        }
    }
    productsCategory(company_id: $companyId, first: $categoryFirst) {
      data {
        id
        name
      }
    }
  }
`);

export const EditProduct = () => {
  const companyId = useAppSelector(state => state.company.data?.id);
  const {data: dataBrands} = useQuery(GET_DATA, {
    variables: {
      first: 40,
      companyId: companyId!,
      categoryFirst: 40,
    },
  });
  const categories = dataBrands?.productsCategory?.data ?? [];
  const brands = dataBrands?.productsBrands?.data ?? [];
  const params = useParams();
  const {id} = params;
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [category, setCategory] = useState<string | null>(null);
  const [brand, setBrand] = useState<string | null>(null);
  const [barcode, setBarcode] = useState('');
  const [count, setCount] = useState('');
  const [pieceCount, setPieceCount] = useState<string | null>(null);
  const [description, setDescription] = useState('');
  const [photos, setPhotos] = useState<Omit<ImageUpload, 'type'>[]>([]);
  const [photosOld, setPhotosOld] = useState<Omit<ImageUpload, 'type'>[]>([]);

  const [costPrice, setCostPrice] = useState('');
  const [retailPrice, setRetailPrice] = useState('');
  const [charge, setCharge] = useState('');
  const [discountPrice, setDiscountPrice] = useState('');
  const [quantitySwitch, setQuantitySwitch] = useState(false);
  const [quantity, setQuantity] = useState('');
  const [criticalQuantity, setCriticalQuantity] = useState('');
  const [notifyCritical, setNotifyCritical] = useState(false);

  const [imageVisible, setImageVisible] = useState(false);
  const [indexPhoto, setIndexPhoto] = useState(0);

  const {loading} = useQuery(GET_PRODUCTS_ONE, {
    variables: {
      id: id!,
      companyId: companyId!,
    },
    onCompleted: res => {
      setName(res.productsOne?.name ?? '');
      setCategory(res.productsOne?.products_category?.id ?? null);
      setBrand(res.productsOne?.products_brand?.id ?? null);
      setBarcode(res.productsOne?.barcode ?? '');
      setCount(res.productsOne?.count?.toString() ?? '');
      setPieceCount(res.productsOne?.piece_count ?? null);
      setDescription(res.productsOne?.description ?? '');
      setCostPrice(res.productsOne?.cost_price?.toString() ?? '');
      setRetailPrice(res.productsOne?.retail_price.toString() ?? '');
      setCharge(res.productsOne?.charge.toString() ?? '');
      setDiscountPrice(res.productsOne?.discount_price?.toString() ?? '');
      setPhotos(res.productsOne?.images ?? []);
      setPhotosOld(res.productsOne?.images ?? []);

      setQuantitySwitch(
        res.productsOne?.quantity || res.productsOne?.notify_critical
          ? true
          : false,
      );
      setQuantity(res.productsOne?.quantity?.toString() ?? '');
      setCriticalQuantity(res.productsOne?.critical_quantity?.toString() ?? '');
      setNotifyCritical(res.productsOne?.notify_critical ?? false);
    },
  });
  const [updateProducts, {loading: loadingUpdate}] = useMutation(
    UPDATE_PRODUCTS,
    {
      refetchQueries: [
        {
          query: GET_PRODUCTS,
          variables: {
            companyId: companyId!,
          },
        },
      ],
    },
  );

  const [errorBorder, setErrorBorder] = useState<{
    charge: null | string;
    name: null | string;
    retailPrice: null | string;
  }>({
    charge: null,
    name: null,
    retailPrice: null,
  });

  return (
    <Layout variant="stack" columns={12}>
      <Wrapper>
        <PageHeader variant="layout" back title="Редактировать товар" />
        <Flex direction="column" gap={24}>
          <Content gap="16px">
            <Text typography="title18">Основная информация</Text>
            <Flex direction="column" gap={16} style={{marginTop: 8}}>
              <TextInput
                value={name}
                onChange={value => {
                  setName(value);
                  setErrorBorder({...errorBorder, name: null});
                }}
                required
                error={errorBorder.name}
                label="Название товара"
              />
              <SelectProductsCategory
                data={categories.map(categoryItem => ({
                  label: categoryItem.name,
                  value: categoryItem.id,
                }))}
                label="Категория товара"
                value={category}
                onChange={setCategory}
                loading={loading}
              />
              <SelectBrand
                data={brands.map(item => ({
                  label: item.name,
                  value: item.id,
                  subtitle: item.products?.length,
                }))}
                loading={loading}
                label="Бренд"
                value={brand}
                onChange={setBrand}
              />
              <TextInput
                value={barcode}
                onChange={setBarcode}
                label="Штрихкод товара (необязательно)"
                placeholder="UPC, EAN, GTIN"
              />
              <Flex direction="row" gap={8}>
                <TextInput
                  width="332px"
                  value={count}
                  height={62}
                  type="number"
                  onChange={setCount}
                  label="Количество"
                  rightElement={
                    <Text
                      style={{width: 'max-content'}}
                      typography="subHead14Regular"
                      color="textTertiary">
                      {productPieceCount.find(item => item.value === pieceCount)
                        ?.small ?? ''}
                    </Text>
                  }
                />
                <Select
                  wrapperStyle={{width: '50%'}}
                  data={productPieceCount}
                  label="Единица измерения"
                  value={pieceCount}
                  onChange={setPieceCount}
                />
              </Flex>
              <TextArea
                value={description}
                onChange={setDescription}
                maxLength={1000}
                label="Описание товара"
                size="large"
              />
            </Flex>
          </Content>
          <Content>
            <Text typography="title18">Фотографии товара</Text>
            <DragDrop
              onLoad={() => {}}
              onChange={values => {
                if (Array.isArray(values)) {
                  const temp = values.map((v, indexV) => ({
                    ...v,
                    id: indexV.toString(),
                    url: v.url!,
                  }));
                  setPhotos([...photos, ...temp]);
                }
              }}
            />
            {photos.length ? (
              <ImageArea>
                {photos.map((item, index) => (
                  <Image
                    key={'image_' + index}
                    src={item.url ?? undefined}
                    onDelete={() => {
                      setPhotos(photos.filter((_, index2) => index2 !== index));
                    }}
                    onClick={() => {
                      setIndexPhoto(index);
                      setImageVisible(true);
                    }}
                  />
                ))}
              </ImageArea>
            ) : null}
            <Flex direction="column" gap={8}>
              <Text typography="subHead14Regular" color="textTertiary">
                Доступные форматы: jpeg, jpg, png
              </Text>
              <Text typography="subHead14Regular" color="textTertiary">
                Размер файла: до 12 Мб
              </Text>
            </Flex>
          </Content>
          <Content>
            <Text typography="title18">Цены</Text>
            <TextInput
              value={costPrice}
              onChange={setCostPrice}
              label="Себестоимость"
              rightElement={
                <Text typography="subHead14Regular" color="textTertiary">
                  ₽
                </Text>
              }
            />
            <Flex direction="row" gap={8}>
              <TextInput
                required
                error={errorBorder.retailPrice}
                value={retailPrice}
                onChange={value => {
                  setRetailPrice(value);
                  setErrorBorder({...errorBorder, retailPrice: null});
                }}
                wrapperStyle={{flex: 1}}
                placeholder="Розничная цена"
                variant="default"
                rightElement={
                  <Text typography="subHead14Regular" color="textTertiary">
                    ₽
                  </Text>
                }
              />
              <TextInput
                required
                error={errorBorder.charge}
                value={charge}
                onChange={value => {
                  setCharge(value);
                  setErrorBorder({...errorBorder, charge: null});
                }}
                wrapperStyle={{flex: 1}}
                placeholder="Наценка"
                variant="default"
                rightElement={
                  <Text typography="subHead14Regular" color="textTertiary">
                    %
                  </Text>
                }
              />
            </Flex>
            <TextInput
              value={discountPrice}
              onChange={setDiscountPrice}
              label="Цена со скидкой"
              rightElement={
                <Text typography="subHead14Regular" color="textTertiary">
                  ₽
                </Text>
              }
            />
          </Content>
          <Content>
            <Text typography="title18">Запасы товаров</Text>
            <TableViewRow
              style={{gap: 26, cursor: 'pointer'}}
              styleTitle={{pointerEvents: 'none'}}
              styleSubTitle={{pointerEvents: 'none'}}
              leftElement={() => (
                <Switch
                  value={quantitySwitch}
                  onChange={() => setQuantitySwitch(state => !state)}
                />
              )}
              title="Количество на складе"
              subtitle="Отслеживайте количество товаров на складе"
              onClick={() => setQuantitySwitch(state => !state)}
            />
            <Flex direction="row" gap={8}>
              <TextInput
                value={quantity}
                onChange={setQuantity}
                flex={1}
                type="number"
                label="Количество на складе"
                rightElement={
                  <Text typography="subHead14Regular" color="textTertiary">
                    шт
                  </Text>
                }
              />
              <TextInput
                value={criticalQuantity}
                onChange={setCriticalQuantity}
                flex={1}
                type="number"
                label="Критический остаток"
                rightElement={
                  <Text typography="subHead14Regular" color="textTertiary">
                    шт
                  </Text>
                }
              />
            </Flex>
            <TableViewRow
              leftElement={() => (
                <CheckBox
                  checked={notifyCritical}
                  onChange={() => setNotifyCritical(state => !state)}
                />
              )}
              title="Уведомлять о низких запасах склада"
              onClick={() => setNotifyCritical(state => !state)}
              style={{cursor: 'pointer'}}
              styleTitle={{pointerEvents: 'none'}}
            />
          </Content>
        </Flex>
        <Button
          loading={loadingUpdate}
          onClick={async () => {
            if (charge !== '' && name !== '' && retailPrice !== '') {
              try {
                const deleteImage = photosOld
                  .filter(
                    old => !photos.map(photo => photo.id).includes(old.id),
                  )
                  .map(old => old.id);
                const createImage = photos
                  .filter(
                    photo => !photosOld.map(old => old.id).includes(photo.id),
                  )
                  .map(photo => ({
                    ...photo,
                    type: ImageType.Image,
                    id: undefined,
                  }));
                await updateProducts({
                  variables: {
                    input: {
                      id: id!,
                      barcode: barcode !== '' ? barcode : undefined,
                      charge: +charge,
                      cost_price: costPrice !== '' ? +costPrice : undefined,
                      count: count !== '' ? +count : undefined,
                      critical_quantity:
                        criticalQuantity !== '' ? +criticalQuantity : undefined,
                      description: description,
                      discount_price:
                        discountPrice !== '' ? +discountPrice : undefined,
                      products_brand_id: brand ?? undefined,
                      products_category_id: category ?? undefined,
                      images: {
                        create:
                          createImage.length > 0 ? createImage : undefined,
                        delete:
                          deleteImage.length > 0 ? deleteImage : undefined,
                      },
                      notify_critical: notifyCritical,
                      piece_count: pieceCount as ProductsPieceCount,
                      quantity: quantity !== '' ? +quantity : undefined,
                      retail_price: +retailPrice,
                      name: name,
                    },
                  },
                  refetchQueries: [GET_PRODUCTS_ONE],
                  awaitRefetchQueries: true,
                });
                navigate(-1);
              } catch (e) {
                if (e instanceof ApolloError) {
                  showAlert('error', e.message);
                }
              }
            } else {
              showAlert('error', 'Заполните обязательные поля');
              setErrorBorder({
                charge: charge !== '' ? null : 'Заполните обязательное поле',
                name: name !== '' ? null : 'Заполните обязательное поле',
                retailPrice:
                  retailPrice !== '' ? null : 'Заполните обязательное поле',
              });
            }
          }}
          size="large">
          Сохранить
        </Button>
        <ImageView
          visible={imageVisible}
          onClose={() => setImageVisible(false)}
          images={photos.map(item => item.url!)}
          index={indexPhoto}
        />
      </Wrapper>
    </Layout>
  );
};
