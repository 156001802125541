import React, {useCallback, useRef} from 'react';
import {Text} from 'shared/ui/Text';
import {useLocation, useNavigate} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {getEmailCode, loginEmail} from 'shared/lib/api';
import {ensureError} from 'shared/lib/utils';
import {CodeInput} from 'shared/ui/CodeInput';
import {Content} from 'shared/ui/Content';
import {Flex} from 'shared/ui/Flex';
import {showAlert} from 'shared/ui/Alert';
import {NavigateBack} from 'shared/ui/NavigateBack';
import {Seo} from 'shared/ui/Seo';
import ReCAPTCHA from 'react-google-recaptcha';

const TIMEOUT = 60;

export function ConfirmEmail() {
  const {email} = useLocation().state;
  const navigate = useNavigate();
  const [value, setValue] = useState(['', '', '', '']);
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState(0);
  const [sending, setSending] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);

  const onCaptchaChange = async (token: string | null) => {
    if (!token) {
      return;
    }
    try {
      await getEmailCode(email, token);
    } catch (err) {
      const error = ensureError(err);
      showAlert('error', error.message);
    }
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimer(t => t + 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);
  const submit = useCallback(
    async (code: string) => {
      try {
        setLoading(true);
        setError(null);
        await loginEmail(code);
        navigate('/new-password', {state: {email, code}});
      } catch (err) {
        if (err instanceof Error) {
          setError(err.message);
        }
      } finally {
        setLoading(false);
      }
    },
    [email, navigate],
  );
  useEffect(() => {
    if (value.join('').length === 4) {
      submit(value.join(''));
    }
    setError(null);
  }, [value, submit]);
  return (
    <>
      <Content gap="24px">
        <Seo title="Код подтверждения" />
        <Flex alignItems="center" gap={16}>
          <NavigateBack />
          <Text typography="title20">Введите код подтверждения</Text>
        </Flex>
        <Text color="textTertiary">Отправили на почту {email}</Text>
        <CodeInput
          error={error}
          value={value}
          onChange={setValue}
          disabled={loading}
        />
        {timer < TIMEOUT ? (
          <Text color="textTertiary" align="center">
            {`Вы можете повторно получить код через ${TIMEOUT - timer} сек`}
          </Text>
        ) : (
          <Text
            disabled={sending}
            onClick={async () => {
              try {
                setSending(true);
                setError(null);
                recaptchaRef.current?.execute();
                setTimer(0);
              } catch (err) {
                const error = ensureError(err);
                showAlert('error', error.message);
              } finally {
                setSending(false);
              }
            }}
            align="center"
            color="mainPrimary">
            Получить код повторно
          </Text>
        )}
      </Content>
      <ReCAPTCHA
        style={{display: 'inline-block', visibility: 'hidden'}}
        ref={recaptchaRef}
        size="invisible"
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY!}
        onChange={onCaptchaChange}
        onExpired={() => {
          showAlert('error', 'Время ввода капчи истекло');
          if (recaptchaRef.current) {
            recaptchaRef.current?.reset();
          }
        }}
        onErrored={() => {
          showAlert('error', 'Ошибка ввода капчи');
          if (recaptchaRef.current) {
            recaptchaRef.current?.reset();
          }
        }}
      />
    </>
  );
}
