import {setContext} from '@apollo/client/link/context';
import {ApolloClient, InMemoryCache, createHttpLink} from '@apollo/client';
import {store} from 'shared/store';
import {gql} from 'shared/__generated__';
import {createFragmentRegistry} from '@apollo/client/cache';
const httpLink = createHttpLink({
  uri: process.env.REACT_APP_DOMAIN + '/graphql',
});

export const PERMISSIONS = gql(`
  fragment Permissions on Query {
    companyPermissions(company_id: $companyId) {
      id
      add_to_customers_bonus_card_permission
      add_to_customers_certificate_permission
      create_bookings_permission
      create_customers_permission
      create_invitation_permission
      customers_info_permission
      edit_apps_permission
      edit_bonus_card_permission
      edit_bonus_card_templates
      edit_bookings_permission
      edit_certificate_permission
      edit_certificate_templates
      edit_company_permission
      edit_company_services_permission
      edit_customers_permission
      edit_discounts_permission
      edit_employees_permission
      edit_work_schedule_permission
      employees_info_permission
      fill_bonus_card_permission
      moderation_permission
      notation_permission
      reviews_info_permission
      reviews_reply_permission
      subscription_and_pay_permission
      work_schedule_info_permission
      reports_permission
      contact_permission
      view_service_permission
      bookings_info_permission
      messages_permission
      delete_messages_permission
      view_products_permission
      edit_products_permission
      salaries_permission
      message_sample_permission
      webhook_permission
    }
  }
`);

const authLink = setContext((_, {headers}) => {
  // get the authentication token from local storage if it exists
  const token = store.getState().auth.token;
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});
export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    fragments: createFragmentRegistry(PERMISSIONS),
  }),
});
