import React, {useCallback, useEffect, useRef, useState} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {RouterProvider, createBrowserRouter} from 'react-router-dom';
import {publicRouter, mainRoutes} from './app/router';
import AppRouter from 'app';
import {ApolloProvider} from '@apollo/client';
import {client} from './shared/config/apollo';
import {ThemeProvider, createGlobalStyle} from 'styled-components';
import {colors} from 'shared/colors';
import dayjs from 'dayjs';
import ru from 'dayjs/locale/ru';
import {Provider} from 'react-redux';
import {
  persistor,
  setThemeSlice,
  store,
  useAppDispatch,
  useAppSelector,
} from 'shared/store';
import {PersistGate} from 'redux-persist/integration/react';
import localeData from 'dayjs/plugin/localeData';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {initializeApp} from 'firebase/app';
import {getToken, getMessaging} from 'firebase/messaging';
import {putOnlineMe, registrationToken} from 'shared/lib/api';
import {showNotification} from 'shared/ui/Notification';
import {Helmet, HelmetProvider} from 'react-helmet-async';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {PERMISSIONS} from 'entities/permission';
import {CookiesProvider} from 'react-cookie';
dayjs.extend(localeData);
dayjs.extend(customParseFormat);
dayjs.locale({
  ...ru,
  weekStart: 1,
  weekdaysMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
});
const colorScheme = store.getState().theme.theme;
export const styledTheme = colors[colorScheme];
const isProd = !process.env.REACT_APP_DOMAIN?.includes('dev');
const GlobalStyles = createGlobalStyle`
body{
  background-color: ${({theme}) => theme.bgSecondary};;
}
  ::-webkit-scrollbar-track {
      border-radius: 6px;
    }
    ::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 6px;
      background-color: ${({theme}) => theme.textTertiary};
    }
    ::placeholder{
      color:${({theme}) => theme.textTertiary};
    }
`;

const Head = () => {
  return (
    <Helmet
      prioritizeSeoTags
      title="Booky Business"
      script={[
        {
          src: 'https://checkout.cloudpayments.ru/checkout.js',
          type: 'text/javascript',
        },
        {id: '', src: '', type: ''},
      ]}
      meta={[
        {
          name: 'description',
          content:
            'Booky Business - это инновационное приложение для онлайн-записи на самые разнообразные услуги, от парикмахерских до массажных кабинетов. Получите доступ к проверенным отзывам, удобному управлению записями и эксклюзивным акциям. С Booky Business, записаться на нужную услугу стало проще и быстрее, где бы вы ни находились.',
        },
        {
          name: 'keywords',
          content:
            'онлайн-запись, мобильное приложение для записи, Booky Business, запись в парикмахерскую, запись в салон красоты, управление записями, отзывы о мастерах, бонусная система для клиентов, акции и скидки в салонах, уведомления о записях, простота использования, мобильный сервис, запись в массажный кабинет, найти услуги, экономия времени с Booky Business.',
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:locale',
          content: 'ru',
        },
        {
          property: 'og:image',
          content: '/apple-touch-icon-180x180.png',
        },
        {
          property: 'og:title',
          content: 'Booky Business - сервис онлайн-записи клиентов на услуги',
        },
        {
          property: 'og:description',
          content:
            'Найдите лучшие салоны и спа-салоны в вашем районе с помощью программного обеспечения для бронирования салонов Booky Business. Забронируйте сейчас!',
        },
        {
          name: 'apple-mobile-web-app-title',
          content: 'Booky Business',
        },
        {
          name: 'mobile-web-app-capable',
          content: 'yes',
        },
        {
          name: 'apple-mobile-web-app-status-bar-style',
          content: 'black-translucent',
        },
        {
          name: 'format-detection',
          content: 'telephone=no',
        },
        {
          name: 'robots',
          content: isProd ? 'index,follow' : 'noindex,nofollow',
        },
      ]}
      link={[
        {
          rel: 'canonical',
          href: 'https://booky.co/',
        },
        {
          rel: 'preconnect',
          href: 'https://api.booky.co/',
        },
      ]}>
      {isProd && (
        <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-MQ42JTHQ');`}</script>
      )}
      <body>
        {isProd &&
          `<noscript>
          <iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-MQ42JTHQ"
            height="0"
            width="0"
            style="display: none; visibility: hidden"></iframe>
        </noscript>`}
      </body>
    </Helmet>
  );
};

const App = () => {
  const theme = useAppSelector(state => state.theme.theme);
  const serviceWorker = useRef<ServiceWorkerContainer | null>(null);
  const companyId = useAppSelector(state => state.company.data?.id);
  const [stateSend, setStateSend] = React.useState(false);
  const dispatch = useAppDispatch();
  const mq = window.matchMedia('(prefers-color-scheme: dark)');
  const [failing, setFailing] = useState<'permission'>();
  const apolloToken = useAppSelector(state => state.auth.token);

  const router = createBrowserRouter([
    publicRouter(),
    {
      path: '/',
      element: <AppRouter failing={failing} />,
      children: [mainRoutes()],
      loader: async () => {
        if (companyId) {
          await client
            .query({
              query: PERMISSIONS,
              variables: {companyId},
            })
            .catch(() => {
              setFailing('permission');
            });
        }
        return null;
      },
    },
  ]);

  const initializeFirebase = useCallback(async () => {
    const app = initializeApp(
      JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG!),
    );
    const messaging = getMessaging(app);
    const token = await getToken(messaging, {
      vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
    });
    if (apolloToken) {
      try {
        await registrationToken(token);
      } catch (e) {
        if (e instanceof Error) {
          console.log('error:', e.message);
        }
      }
    }
  }, [apolloToken]);

  const handleForegroundMessage = useCallback((event: MessageEvent) => {
    const title: string | null = event.data?.notification?.title || null;
    const body: string | null = event.data?.notification?.body || null;
    showNotification(title ?? '', body ?? '');
    if (!title || !body) return;
  }, []);

  useEffect(() => {
    if (Notification.permission === 'granted') {
      initializeFirebase();
    } else {
      Notification.requestPermission();
    }
  }, [initializeFirebase]);

  useEffect(() => {
    mq.addEventListener('change', function (evt) {
      if (evt.matches) {
        dispatch(setThemeSlice('dark'));
      } else {
        dispatch(setThemeSlice('light'));
      }
    });
    return () => {
      mq.removeEventListener('change', () => {});
    };
  }, [dispatch, mq, mq.matches]);

  useEffect(() => {
    serviceWorker.current = navigator.serviceWorker;
    serviceWorker.current.addEventListener('message', handleForegroundMessage);
    return () => {
      if (!serviceWorker.current) return;
      serviceWorker.current.removeEventListener(
        'message',
        handleForegroundMessage,
      );
    };
  }, [handleForegroundMessage]);

  useEffect(() => {
    if (companyId) {
      setTimeout(() => {
        putOnlineMe();
        setStateSend(!stateSend);
      }, 1000 * 30);
    }
  }, [stateSend, companyId]);

  return (
    <ThemeProvider theme={colors[theme]}>
      <GlobalStyles />
      <ApolloProvider client={client}>
        <RouterProvider router={router} />
      </ApolloProvider>
    </ThemeProvider>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <HelmetProvider>
          <Head />
          <CookiesProvider>
            <App />
          </CookiesProvider>
        </HelmetProvider>
        <ToastContainer />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
