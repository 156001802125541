import React from 'react';
import dayjs from 'dayjs';
import {Text} from 'shared/ui/Text';
import {TextArea} from 'shared/ui/TextArea';
import {Flex} from 'shared/ui/Flex';

export const Comment = ({
  value,
  onChange,
  disabled,
  id,
  author,
  created_at,
}: {
  value: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
  id?: string;
  author?: string;
  created_at?: string;
}) => {
  return (
    <Flex direction="column" gap={16}>
      <Text typography="title18" color="textPrimary">
        Комментарий
      </Text>
      <TextArea
        value={value}
        onChange={onChange}
        disabled={disabled}
        size="large"
        maxLength={1000}
        placeholder="Примечание (только для сотрудников)"
      />
      <Flex direction="column">
        <Text
          typography="text16Regular"
          color="textTertiary"
          style={{fontSize: 12, height: 18}}>
          ID: {id}
        </Text>
        <Text
          typography="text16Regular"
          color="textTertiary"
          style={{fontSize: 12, height: 18}}>
          Автор записи: {author}
        </Text>
        <Text
          typography="text16Regular"
          color="textTertiary"
          style={{
            fontSize: 12,
            height: 18,
          }}>
          Создано: {dayjs(created_at).format('HH:mm, DD MMMM YYYY')}
        </Text>
      </Flex>
    </Flex>
  );
};
