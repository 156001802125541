import * as React from 'react';
import {useColorScheme} from 'shared/lib/hooks';
import styled from 'styled-components';
import {device} from 'shared/device';

type EmptyCloudsProps = {
  width?: string;
  mt?: number;
};

const EmptyContainer = styled.div<{width?: string; mt: number}>`
  width: ${props => props.width || '400px'};
  align-self: center;
  margin-top: ${({mt}) => mt}px;
  @media (${device.mobile}) {
    width: 200px;
  }
`;

export const EmptyCloud = ({width, mt = 58}: EmptyCloudsProps) => {
  const colorScheme = useColorScheme();
  return (
    <EmptyContainer width={width} mt={mt}>
      {colorScheme === 'light' ? (
        <svg
          viewBox="0 0 400 400"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M199.267 294.035C257.847 294.035 305.58 246.5 305.58 188.116C305.383 129.535 257.847 82 199.267 82C140.488 82 92.9531 129.535 92.9531 187.919C92.9531 246.5 140.488 294.035 199.267 294.035Z"
            fill="#EAEEF9"
          />
          <g filter="url(#filter0_d_8737_47540)">
            <path
              d="M312.682 201.528C312.682 224.211 294.338 242.555 271.458 242.555C271.064 242.555 267.71 242.555 243.055 242.555C225.895 242.555 198.479 242.555 154.691 242.555H133.783C106.367 243.146 84.4727 221.253 84.4727 194.822C84.4727 168.194 106.564 146.103 134.375 147.484C158.241 72.9266 267.513 83.3804 276.784 160.502C297.297 163.066 312.682 180.423 312.682 201.528Z"
              fill="url(#paint0_linear_8737_47540)"
            />
          </g>
          <path
            d="M271.458 242.557C294.141 242.557 312.682 224.213 312.682 201.53C312.682 178.847 294.141 160.504 271.458 160.504C248.775 160.504 230.234 178.847 230.234 201.53C230.234 224.213 248.775 242.557 271.458 242.557Z"
            fill="url(#paint1_linear_8737_47540)"
          />
          <path
            d="M204.198 242.95C244.633 242.95 277.572 210.208 277.572 169.773C277.572 129.339 244.633 96.5967 204.198 96.5967C163.764 96.5967 130.824 129.339 130.824 169.773C130.824 210.208 163.566 242.95 204.198 242.95Z"
            fill="url(#paint2_linear_8737_47540)"
          />
          <path
            d="M177.054 188.55C180.16 188.55 182.788 185.922 182.788 182.816C182.788 179.71 180.16 177.082 177.054 177.082C173.948 177.082 171.32 179.71 171.32 182.816C171.32 185.922 173.948 188.55 177.054 188.55Z"
            fill="#989FB0"
          />
          <path
            d="M221.015 188.55C224.121 188.55 226.749 185.922 226.749 182.816C226.749 179.71 224.121 177.082 221.015 177.082C217.909 177.082 215.281 179.71 215.281 182.816C215.281 186.161 217.909 188.55 221.015 188.55Z"
            fill="#989FB0"
          />
          <path
            d="M176.832 163.846L164.219 170.656L165.921 173.81L178.535 166.999L176.832 163.846Z"
            fill="#989FB0"
          />
          <path
            d="M220.234 163.758L218.531 166.912L231.146 173.721L232.848 170.567L220.234 163.758Z"
            fill="#989FB0"
          />
          <path
            d="M199.033 201.929C202.728 201.929 205.723 199.682 205.723 196.911C205.723 194.141 202.728 191.894 199.033 191.894C195.339 191.894 192.344 194.141 192.344 196.911C192.344 199.682 195.339 201.929 199.033 201.929Z"
            fill="#989FB0"
          />
          <path
            d="M61.6995 110.315C61.6995 110.315 34.1279 117.315 42.4829 122.916C50.838 128.236 70.6115 137.476 89.271 131.316C107.931 125.156 89.271 116.475 89.5495 116.475C89.828 116.475 61.6995 110.315 61.6995 110.315Z"
            fill="#CED7E2"
          />
          <path
            d="M28 108.074C38.026 111.154 68.9396 106.114 88.7131 93.2336C104.309 112.555 110.158 117.595 115.728 118.155C107.094 126.835 78.4086 140.836 62.2556 122.635C43.3175 134.676 28 108.074 28 108.074Z"
            fill="#E9EDF9"
          />
          <path
            d="M284.551 178.089C295.134 178.649 323.819 165.768 339.415 148.407C359.467 163.248 366.151 166.608 372 165.768C365.594 176.409 346.099 199.65 318.528 201.33C298.476 189.569 284.551 178.089 284.551 178.089Z"
            fill="#E9EDF9"
          />
          <path
            d="M200.07 309.432C197.976 299.043 178.356 274.47 157.658 263.69C167.036 240.574 168.625 233.263 166.355 227.809C178.255 231.362 205.62 244.453 214.115 270.737C207.72 293.086 200.07 309.432 200.07 309.432Z"
            fill="white"
          />
          <defs>
            <filter
              id="filter0_d_8737_47540"
              x="62.4727"
              y="85.7739"
              width="272.209"
              height="189.792"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_8737_47540"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_8737_47540"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_8737_47540"
              x1="198.577"
              y1="157.021"
              x2="198.503"
              y2="244.138"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="white" />
              <stop offset="0.9964" stopColor="#ECF0F5" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_8737_47540"
              x1="225.848"
              y1="151.671"
              x2="262.392"
              y2="191.64"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#BCCBE1" />
              <stop offset="0.9942" stopColor="white" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_8737_47540"
              x1="123.953"
              y1="136.195"
              x2="181.551"
              y2="158.734"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#E2E8F0" />
              <stop offset="0.9942" stopColor="white" stopOpacity="0" />
            </linearGradient>
          </defs>
        </svg>
      ) : (
        <svg
          viewBox="0 0 400 400"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M199.267 294.035C257.847 294.035 305.58 246.5 305.58 188.116C305.383 129.535 257.847 82 199.267 82C140.488 82 92.9531 129.535 92.9531 187.919C92.9531 246.5 140.488 294.035 199.267 294.035Z"
            fill="#2A2E37"
          />
          <g filter="url(#filter0_d_10185_7658)">
            <path
              d="M312.682 201.528C312.682 224.211 294.339 242.555 271.459 242.555C271.064 242.555 267.711 242.555 243.056 242.555C225.896 242.555 198.479 242.555 154.691 242.555H133.784C106.367 243.146 84.4731 221.253 84.4731 194.822C84.4731 168.194 106.564 146.103 134.375 147.484C158.242 72.9266 267.514 83.3804 276.784 160.502C297.297 163.066 312.682 180.423 312.682 201.528Z"
              fill="#5C6479"
            />
          </g>
          <path
            d="M204.198 242.95C244.633 242.95 277.572 210.208 277.572 169.773C277.572 129.339 244.633 96.5967 204.198 96.5967C163.764 96.5967 130.824 129.339 130.824 169.773C130.824 210.208 163.566 242.95 204.198 242.95Z"
            fill="url(#paint0_linear_10185_7658)"
          />
          <path
            d="M271.458 242.557C294.141 242.557 312.682 224.213 312.682 201.53C312.682 178.847 294.141 160.504 271.458 160.504C248.776 160.504 230.235 178.847 230.235 201.53C230.235 224.213 248.776 242.557 271.458 242.557Z"
            fill="url(#paint1_linear_10185_7658)"
            fillOpacity="0.37"
          />
          <path
            d="M177.062 188.546C180.168 188.546 182.796 185.918 182.796 182.812C182.796 179.706 180.168 177.078 177.062 177.078C173.956 177.078 171.328 179.706 171.328 182.812C171.328 185.918 173.956 188.546 177.062 188.546Z"
            fill="#989FB0"
          />
          <path
            d="M221.019 188.546C224.125 188.546 226.753 185.918 226.753 182.812C226.753 179.706 224.125 177.078 221.019 177.078C217.913 177.078 215.285 179.706 215.285 182.812C215.285 186.157 217.913 188.546 221.019 188.546Z"
            fill="#989FB0"
          />
          <path
            d="M176.836 163.842L164.223 170.652L165.925 173.806L178.538 166.995L176.836 163.842Z"
            fill="#989FB0"
          />
          <path
            d="M220.241 163.755L218.539 166.908L231.154 173.717L232.856 170.564L220.241 163.755Z"
            fill="#989FB0"
          />
          <path
            d="M199.041 201.925C202.736 201.925 205.731 199.679 205.731 196.908C205.731 194.137 202.736 191.891 199.041 191.891C195.347 191.891 192.352 194.137 192.352 196.908C192.352 199.679 195.347 201.925 199.041 201.925Z"
            fill="#989FB0"
          />
          <path
            d="M61.6995 110.315C61.6995 110.315 34.1279 117.315 42.4829 122.916C50.838 128.236 70.6115 137.476 89.271 131.316C107.931 125.156 89.271 116.475 89.5495 116.475C89.828 116.475 61.6995 110.315 61.6995 110.315Z"
            fill="#CED7E2"
          />
          <path
            d="M28 108.074C38.026 111.154 68.9396 106.114 88.7131 93.2334C104.309 112.554 110.158 117.595 115.728 118.155C107.094 126.835 78.4086 140.836 62.2556 122.635C43.3175 134.675 28 108.074 28 108.074Z"
            fill="#A9AFC3"
          />
          <path
            d="M284.551 178.089C295.134 178.649 323.819 165.768 339.415 148.407C359.467 163.248 366.151 166.608 372 165.768C365.594 176.409 346.099 199.65 318.528 201.33C298.476 189.569 284.551 178.089 284.551 178.089Z"
            fill="#A9AFC3"
          />
          <path
            d="M200.07 309.432C197.976 299.043 178.356 274.47 157.658 263.69C167.036 240.574 168.625 233.263 166.355 227.809C178.255 231.362 205.62 244.453 214.115 270.737C207.72 293.086 200.07 309.432 200.07 309.432Z"
            fill="#A9AFC3"
          />
          <defs>
            <filter
              id="filter0_d_10185_7658"
              x="71.4731"
              y="94.7739"
              width="254.209"
              height="171.792"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="6.5" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.22 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_10185_7658"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_10185_7658"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_10185_7658"
              x1="148.707"
              y1="126.995"
              x2="173.277"
              y2="145.986"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#80889D" />
              <stop offset="0.9942" stopColor="#5C6479" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_10185_7658"
              x1="225.755"
              y1="151.8"
              x2="262.238"
              y2="191.791"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="white" />
              <stop offset="0.9942" stopColor="white" stopOpacity="0" />
            </linearGradient>
          </defs>
        </svg>
      )}
    </EmptyContainer>
  );
};
