import {gql} from 'shared/__generated__';

export const SERVICES = gql(`
query CompanyServiceSearchServices(
  $companyId: String!
  $query: String!
  $first: Int!
  $page: Int
  $categoryId:[String!]
  $subcategoryId:[String!]
  $orderBy: [QueryCompanyServiceSearchOrderByOrderByClause!]
) {
  companyServiceSearch(
    company_id: $companyId
    query: $query
    first: $first
    page: $page
    category_id: $categoryId
    subcategory_id: $subcategoryId
    orderBy: $orderBy
  ) {
    data {
      id
      order
      name
      duration
      min_price
      price
      max_price
      description
      breakout_time
      type
      company {
        id
        categories {
          id
          title
        }
        subcategories {
          id
          title
        }
      }
      employees {
        id
        name
        surname
      }
      category {
        id
        title
        subcategories {
          id
          title
        }
      }
      subcategory {
        id
        title
        category {
          id
          title
        }
      }
      images {
        id
        type
        url
      }
      resources {
        id
        name
      }
    }
    paginatorInfo {
      currentPage
      hasMorePages
      total
      count
    }
  }
  ...Permissions
}
`);

export const SERVICE_EDIT = gql(`
query ServiceEdit($serviceId: String!) {
  service(id: $serviceId) {
    id
    name
    description
    category {
      id
      title
    }
    subcategory {
      id
      title
    }
    type
    min_price
    price
    max_price
    duration
    breakout_time
    employees {
      id
      name
      surname
    }
    images {
      id
      type
      url
    }
    resources {
      id
      name
    }
  }
  categories {
    id
    title
    subcategories {
      id
      title
    }
  }
}
`);

export const UPDATE_SERVICE = gql(`
  mutation UpdateService($input: UpdateCompanyService!) {
    updateCompanyService(input: $input) {
      id
    }
  }
`);
export const DELETE_SERVICES = gql(`
  mutation DeleteCompanyServices($ids: [String!]!) {
    deleteCompanyServices(ids: $ids) {
      id
    }
  }
`);
export const SERVICES_TEMPLATES = gql(`
  query ServiceTemplates($first: Int!, $page: Int, $name: String) {
    serviceTemplates(first: $first, page: $page, name: $name) {
      data {
        id
        name
        category {
          id
          title
          subcategories {
            id
            title
          }
        }
        subcategory {
          id
          title
        }
      }
      paginatorInfo {
        currentPage
        hasMorePages
      }
    }
  }
`);

export const RESPONSE_SERVICE_TEMPLATE = gql(`
query ServiceTemplatesData($first: Int!, $id: String, $companyId: String) {
  serviceTemplates(first: $first, id: $id) {
    data {
      id
      name
      duration
      category {
        id
        title
      }
      subcategory {
        id
        title
      }
    }
  }
  categories {
    id
    title
    subcategories {
      id
      title
    }
  }
  company(id: $companyId) {
    id
    resources {
      id
      count
      name
      order
      services {
        id
        name
      }
    }
  }
}
`);

export const CREATE_SERVICES = gql(`
  mutation CreateCompanyService($input: CreateCompanyService!) {
    createCompanyService(input: $input) {
      id
      name
      duration
      min_price
      price
      max_price
      type
      employees {
        id
        name
      }
      description
      breakout_time
      type
        employees {
          id
          name
          surname
        }
        category {
          id
          title
          subcategories {
            id
            title
          }
        }
        subcategory {
          id
          title
          category {
            id
            title
          }
        }
    }
  }
`);
