import {Status} from './ui/Status';
import {SelectCustomer} from './ui/SelectCustomer';
import {SelectDate} from './ui/SelectDate';
import {SelectServices} from './ui/SelectServices';
import {SelectEmployee} from './ui/SelectEmployee';
import {InputAvatar} from './ui/InputAvatar';
import {Comment} from './ui/Comment';
import {ModalDeleteBooking} from './ui/ModalDeleteBooking';
import {SelectBookingSource} from './ui/SelectBookingSource';
import {gql} from 'shared/__generated__';

export {
  Status,
  SelectCustomer,
  SelectDate,
  SelectServices,
  SelectEmployee,
  InputAvatar,
  Comment,
  ModalDeleteBooking,
  SelectBookingSource,
};

export const COMPANY_EMPLOYEES = gql(`
query WidgetGetSelectEmployees(
  $services: [String!]
  $workingDate: String
  $companyId: String
  $name: String
  $start: String
) {
  widgetGetEmployees(
    services: $services
    working_date: $workingDate
    company_id: $companyId
    name: $name
    start: $start
  ) {
    position
    name
    id
    phone
    surname
    is_busy
    avatar {
      url
    }
    professions {
      name
      id
    }
    rating_1_count
    rating_2_count
    rating_3_count
    rating_4_count
    rating_5_count
    user {
      is_online
      id
    }
  }
}
`);

export const CUSTOMERS = gql(`
  query CustomersModalCustomer(
    $companyId: String!
    $first: Int!
    $page: Int
    $isBlocked: Boolean
    $query: String
  ) {
    customers(
      company_id: $companyId
      first: $first
      page: $page
      is_blocked: $isBlocked
      query: $query
    ) {
      data {
        id
        name
        surname
        birthdate
        is_blocked
        is_vip
        phone
        email
        avatar {
          id
          url
        }
        total_cancelled
      total_visits
      total_earned
        tag {
          id
          name
          order
          color {
            id
            rgb
          }
        }
      }
      paginatorInfo {
        currentPage
        hasMorePages
      }
    }
  }
`);

export const CREATE_PAYMENT = gql(`
  mutation CreatePayment($input: CreatePayment!) {
    createPayment(input: $input) {
      id
    }
  }
`);

export const GET_LOYALTIES = gql(`
  query GetLoyaltyByCustomerId($customerId: String!) {
    bonusCardsByCustomerId(customer_id: $customerId) {
      balance
      id
      is_active
      name
      valid_until
      bonusCardTemplate {
        paymentMethods {
          id
          title
        }
        payment_limit
        services {
          id
          name
        }
        bonusSizes {
          id
          min_amount
          size
        }
      }
    }
    certificatesByCustomer(customer_id: $customerId) {
      balance
      id
      is_active
      name
      valid_until
    }
  }
`);

export const PAYMENTS = gql(`
  query PaymentsPaymentCheques($bookingId: String!, $companyId: String!) {
    payments(booking_id: $bookingId) {
      amount
      id
      method
    }
    booking(id: $bookingId) {
      bookingServices {
        id
        price
        additional_price
        discount {
          type
          value
        }
      }
      customer {
        id
        name
        surname
      }
      workingDate {
        id
        date
      }
    }
    ...Permissions
  }
`);

export const DELETE_PAYMENT = gql(`
  mutation DeletePayment($deletePaymentId: String!) {
    deletePayment(id: $deletePaymentId) {
      id
    }
  }
`);

export const BOOKING = gql(`
query EmployeeDetailScreen($bookingId: String!, $companyId: String!) {
  booking(id: $bookingId) {
    employee {
      name
      rating_1_count
      rating_2_count
      rating_3_count
      rating_4_count
      rating_5_count
      surname
      avatar {
        id
        url
      }
      id
      professions {
        id
        name
      }
    }
    comment
    customer_comment
    created_at
    author
    bookingServices {
      id
      price
      additional_price
      discount {
        id
        name
        type
        value
      }
      service {
        id
        name
        duration
        full_duration
      }
    }
    end
    id
    start
    status
    workingDate {
      id
      date
    }
    customer {
      avatar {
        id
        url
      }
      id
      name
      is_vip
      is_blocked
      phone
      surname
    }
    duration
    messages {
      id
      status
    }
    source {
      id
      name
    }
  }
  ...Permissions
}
`);

export const GET_BOOKING_MESSAGES = gql(`
  query GetBookingMessages($bookingId: String!, $companyId: String!) {
    booking(id: $bookingId) {
      id
      messages {
        id
        send_at
        message
        status
        method
        messageSample {
          event
          name
          id
        }
        customer {
          id
          name
          surname
        }
        booking {
          id
        }
      }
    }
    ...Permissions
  }
`);

export const UPDATE_COMPANY_BOOKING = gql(`
  mutation UpdateCompanyBooking($input: UpdateCompanyBooking!) {
    updateCompanyBooking(input: $input) {
      id
    }
  }
`);

export const BOOKING_PHOTOS = gql(`
  query BookingPhotos($bookingId: String!, $companyId: String!) {
    booking(id: $bookingId) {
      workPhotos {
        id
        created_at
        image {
          id
          url
        }
      }
    }
    ...Permissions
  }
`);

export const BOOKING_IMAGE_DELETE = gql(`
  mutation UpdateCompanyBookingImagePhotoScreen($input: UpdateCompanyBooking!) {
    updateCompanyBooking(input: $input) {
      id
    }
  }
`);

export const BOOKINGS = gql(`
  query BookingsData(
    $companyId: String!
    $date: [String!]
    $profession: [String!]
    $haveBookings: Boolean
    $customerId: String
    $companyBookingsDate2: Date
    $first: Int!
    $page: Int
  ) {
    companyProfessions(company_id: $companyId) {
      id
      name
    }
    employeeFilters(
      company_id: $companyId
      date: $date
      profession: $profession
      have_bookings: $haveBookings
      first: $first
      page: $page
    ) {
      data {
        id
        name
        surname
        position
        workingDates {
          id
          date
          end_time
          start_time
          breakTimes {
            end
            start
            id
          }
          employee {
            id
          }
        }
        avatar {
          id
          url
        }
        rating_1_count
        rating_2_count
        rating_3_count
        rating_4_count
        rating_5_count
        professions {
          id
          name
        }
        user {
          is_online
          id
        }
      }
      paginatorInfo {
        currentPage
        hasMorePages
        lastPage
      }
    }
    companyBookings(
      company_id: $companyId
      customer_id: $customerId
      date: $companyBookingsDate2
    ) {
      id
      start
      end
      status
      customer {
        id
        name
        surname
        phone
        is_vip
      }
      employee_id
      bookingServices {
        id
        additional_price
        price
        service {
          id
          name
          breakout_time
          resources {
            id
            is_available
            name
            order
          }
        }
        discount {
          id
          type
          value
        }
      }
      workingDate {
        date
        id
        start_time
        end_time
      }
    }
    ...Permissions
  }
`);
