import React from 'react';
import {useColors} from 'shared/lib/hooks';
export const DeleteBold = ({
  size = 24,
  color = '#FF1C45',
}: {
  size?: number;
  color?: string;
}) => {
  const colors = useColors();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25 13C25 19.6274 19.6274 25 13 25C6.37258 25 1 19.6274 1 13C1 6.37258 6.37258 1 13 1C19.6274 1 25 6.37258 25 13Z"
        fill={color}
      />
      <path
        d="M8.2 8.2L13 13M13 13L17.8 17.8M13 13L8.2 17.8M13 13L17.8 8.2M25 13C25 19.6274 19.6274 25 13 25C6.37258 25 1 19.6274 1 13C1 6.37258 6.37258 1 13 1C19.6274 1 25 6.37258 25 13Z"
        stroke={colors.bgPrimary}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
