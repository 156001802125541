import React, {useState} from 'react';
import {device} from 'shared/device';
import {Content} from 'shared/ui/Content';
import {Flex} from 'shared/ui/Flex';
import {Layout} from 'shared/ui/Layout';
import {SegmentedControl} from 'shared/ui/SegmentedControl';
import {SlidesContainer} from 'shared/ui/SlidesContainer';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';
import {gql} from 'shared/__generated__';
import {useQuery} from '@apollo/client';
import {SubscriptionsType} from 'shared/__generated__/graphql';
import {Skeleton} from 'shared/ui/Skeleton';
import {Slide} from 'shared/ui/Slide';
import {FreeSlide, ProSlide} from 'entities/tariff';
import {NavigateBack} from 'shared/ui/NavigateBack';
import {Seo} from 'shared/ui/Seo';
import {useLocation} from 'react-router-dom';

export const TARIFF_PLANS = gql(`
  query PriceListTariffPlans($companyId: String!) {
    priceList {
      id
      price
      slots
      type
      created_at
    }
    company(id: $companyId) {
      id
      plan {
        id
        price
        slots
        type
        created_at
      }
    }
    getCompanyEmployeesLength(company_id: $companyId, enabled: true)
  }
`);

const Header = styled(Flex)`
  width: 100%;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  @media ${device.mobile} {
    padding: 0 16px;
    margin: 8px 0;
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  grid-column-start: 4;
  grid-column-end: 9;
  gap: 32px;
  margin: 32px 0;
`;
const skeletonWidthRow = [1, 0.72, 0.81, 0.93, 1, 0.81, 1];

export function UnavailableTariffPlans() {
  const {companyId} = useLocation().state;
  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);
  const {data, loading} = useQuery(TARIFF_PLANS, {variables: {companyId}});
  const plans =
    data?.priceList.filter(plan => plan.type === SubscriptionsType.Year) ?? [];
  const currentTariffPlan = data?.company?.plan;
  const activeEmployees = data?.getCompanyEmployeesLength ?? 1;
  const tabs = [
    {
      name: 'PRO-тариф',
      onClick: () => setActiveSlideIndex(0),
    },
    {
      name: 'Бесплатный',
      onClick: () => setActiveSlideIndex(1),
    },
  ];
  return (
    <Layout>
      <Seo title="Тарифные планы" />
      <Container>
        <Header>
          <NavigateBack />
          <Text typography="title24">Тарифные планы</Text>
        </Header>
        <Content>
          <Text typography="title18">Уведомления</Text>
          <SegmentedControl tabs={tabs} />
        </Content>
        <SlidesContainer index={activeSlideIndex}>
          {loading ? (
            <SkeletonPayment />
          ) : (
            <ProSlide
              companyId={companyId}
              unavailable
              countActiveEmployees={activeEmployees}
              tariffPlans={plans}
              tariffPlan={currentTariffPlan}
            />
          )}
          <FreeSlide
            companyId={companyId}
            countActiveEmployees={activeEmployees}
            unavailable
            tariffPlan={currentTariffPlan}
          />
        </SlidesContainer>
      </Container>
    </Layout>
  );
}

const SkeletonPayment = () => {
  return (
    <Slide>
      <Content gap={'0'}>
        <Skeleton height={411}>
          {Array(7)
            .fill(0)
            .map((_, index) => (
              <rect
                key={index + '_line_skeleton'}
                x={0}
                y={index * 61}
                width={`calc(100% * ${skeletonWidthRow[index]})`}
                height={45}
                rx={8}
                ry={8}
              />
            ))}
        </Skeleton>
      </Content>
    </Slide>
  );
};
