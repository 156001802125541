import React, {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react';
import {TariffPlan} from 'shared/lib/utils';

export interface IPaymentState {
  numberCard: string;
  dateCard: string;
  cvvCard: string;
  name: string;
  index: string;
  city: string;
  street: string;
  office: string;
  promoCode: string | null;
  error: 'number' | 'date' | 'cvv' | undefined;
  plan: TariffPlan | undefined;
  paymentType: 'current' | 'new';
}

interface IPaymentContext {
  state: IPaymentState;
  setState: Dispatch<SetStateAction<IPaymentState>>;
}

export const initialValue: IPaymentState = {
  numberCard: '',
  dateCard: '',
  cvvCard: '',
  name: '',
  index: '',
  city: '',
  street: '',
  office: '',
  promoCode: null,
  error: undefined,
  plan: undefined,
  paymentType: 'current',
};

const initialContextValue: IPaymentContext = {
  state: initialValue,
  setState: () => null,
};

const Context = createContext<IPaymentContext>(initialContextValue);

const PaymentProvider: FC<PropsWithChildren> = props => {
  const {children} = props;
  const [state, setState] = useState<IPaymentState>(initialValue);

  const exposed: IPaymentContext = {
    state,
    setState,
  };

  return <Context.Provider value={exposed}>{children}</Context.Provider>;
};

export const usePayment = () => useContext(Context);

export default PaymentProvider;
