import {gql} from 'shared/__generated__';

export const TUTORIALCHECK = gql(`
query TutorialCheck($companyId: String!) {
  moderationsCheck(company_id: $companyId) {
    result
    is_employee_added
    is_employee_with_specialization_added
    is_required_items_filled
    is_service_added
  }
}
`);
