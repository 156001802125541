import React, {useEffect, useState} from 'react';
import styled, {useTheme} from 'styled-components';
import {Text} from './Text';
import {Modal} from './Modal';
import {useColors} from 'shared/lib/hooks';
import {CheckBox} from './CheckBox';
import {device} from 'shared/device';
import {Flex} from './Flex';
import {SortOrder} from 'shared/__generated__/graphql';
import {Selector} from 'shared/icons/Selector';
import Check from 'shared/icons/Check';
import {ArrowDown1v} from 'shared/icons/ArrowDown1v';
import {ArrowDown2v} from 'shared/icons/ArrowDown2v';
import {ArrowRight1v} from 'shared/icons/ArrowRight1v';
import {ArrowLeft1v} from 'shared/icons/ArrowLeft1v';

export type OptionsType = {
  page: number;
  first: number;
  lastPage?: number;
  total?: number;
  count?: number;
};

export type TableSortType = {
  index: number;
  order: SortOrder;
};

const counts = [10, 20, 30, 40];

const Wrapper = styled.div<{backgroundColor?: string; scrollable: boolean}>`
  background-color: ${({backgroundColor}) => backgroundColor ?? 'transparent'};
  overflow: ${({scrollable}) => (scrollable ? 'scroll' : 'visible')};
  &::-webkit-scrollbar-corner {
    display: none;
  }

  @media ${device.mobile} {
    margin: 16px;
  }
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const StyledThead = styled.thead<{
  backgroundColor?: string;
  scrollable: boolean;
}>`
  background-color: ${({backgroundColor, theme}) =>
    backgroundColor ?? theme.bgPrimary};
  position: ${({scrollable}) => (scrollable ? 'sticky' : 'static')};
  top: 0;
  z-index: 10;
`;

const StyledTr = styled.tr<{height?: string; hover?: boolean}>`
  border-bottom: 1px solid ${({theme}) => theme.borderPrimary};
  pointer-events: all;
  &:hover {
    background-color: ${({theme, hover}) =>
      hover ? theme.bgSecondary : undefined};
  }
  &:last-child {
    border-bottom: none;
  }
`;

const StyledTh = styled.th<{width?: number; alignRight: boolean}>`
  padding: 16px;
  text-align: left;
  font-family: 'Inter';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: ${({alignRight}) => (alignRight ? 'end' : 'start')};
  color: ${({theme}) => theme.textTertiary};
`;

const StyledTd = styled.td<{
  alignRight: boolean;
  bold: boolean;
  columnWrap: boolean;
}>`
  flex: 1;
  padding: 16px;
  height: auto;
  text-align: ${({alignRight}) => (alignRight ? 'right' : 'left')};
  font-family: 'Inter';
  font-size: 16px;
  font-style: normal;
  text-overflow: ellipsis;
  font-weight: ${({bold}) => (bold ? '600' : '400')};
  line-height: 24px;
  white-space: ${({columnWrap}) => (columnWrap ? 'nowrap' : 'normal')};
  color: ${({theme}) => theme.textPrimary};
`;

const StyledThSort = styled.th`
  text-align: left;
`;
const SortIconBox = styled.div`
  display: none;
  width: 20px;
  height: 20px;
`;

const SortButton = styled.button<{
  hover?: boolean;
  alignRight: boolean;
  active: boolean;
}>`
  display: flex;
  padding: 16px;
  align-items: center;
  margin-left: ${({alignRight}) => (alignRight ? 'auto' : 0)};
  border-radius: 24px;
  border-width: 0;
  gap: 8px;
  background-color: transparent;
  color: ${({theme, active}) =>
    active ? theme.textPrimary : theme.textTertiary};
  &:hover {
    background-color: ${({theme, hover}) =>
      hover ? theme.bgSecondary : undefined};
    & > div {
      & > ${SortIconBox} {
        display: block;
      }
    }
  }
`;

const StyledCheckboxTd = styled.td`
  padding: 14px 18px 14px 18px;
  width: 1%;
`;

const Footer = styled.footer`
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 24px;
`;

const ButtonPaging = styled.button`
  display: flex;
  border: none;
  pointer-events: all;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
`;

const PopupWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 144px;
  justify-content: space-between;
  border-radius: 18px;
  gap: 8px;
  height: calc(56px * 4);
`;

const Page = styled.button<{checked: boolean}>`
  pointer-events: all;
  cursor: pointer;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 38px;
  border-width: 0px;
  background-color: ${({theme, checked}) =>
    checked ? theme.bgSecondary : 'transparent'};
  width: 100%;
  height: 56px;
  padding: 0 16px;
  &:hover {
    background-color: ${({theme}) => theme.bgSecondary};
  }
`;

const pageInfoText = (first: number, page: number, count: number = 0) => {
  const firstNumber = first * page - first + 1;
  const secondNumber =
    count - page * first < first ? count + first * (page - 1) : first * page;
  return count !== 0 ? `${firstNumber} - ${secondNumber}` : '0';
};

type PaginInfoProps = OptionsType & {
  results?: boolean;
  setOptions: React.Dispatch<React.SetStateAction<OptionsType>>;
  onSelectionChange?: (selectedRowIndices: number[]) => void;
};

const PageButton = ({
  first,
  number,
  onClick,
}: {
  first: number;
  number: number;
  onClick: (value: number) => void;
}) => {
  const colors = useColors();
  return (
    <Page checked={first === number} onClick={() => onClick(number)}>
      <Text style={{pointerEvents: 'none'}}>{number}</Text>
      {first === number && <Check size={24} color={colors.mainPrimary} />}
    </Page>
  );
};

export const PaginInfo = ({
  first,
  page,
  count,
  total,
  setOptions,
  results,
  onSelectionChange,
}: PaginInfoProps) => {
  const [modal, setModal] = useState(false);
  const colors = useTheme();
  const countTotal = results && total ? total - 1 : total ?? 0;
  const setFirst = (value: number) => {
    if (value === first) {
      setModal(false);
      return;
    }
    setOptions(prev => ({
      ...prev,
      first: value,
      page: 1,
    }));
    setModal(false);
    if (value < first) {
      onSelectionChange && onSelectionChange([]);
    }
  };
  const isNextPage = first * page < countTotal;

  return (
    <Footer>
      <Flex
        style={{cursor: 'pointer', pointerEvents: 'all'}}
        onClick={() => setModal(true)}
        gap={16}>
        <Text
          color="textSecondary"
          style={{pointerEvents: 'all', cursor: 'pointer'}}>
          Кол-во строк:
        </Text>
        <Text
          style={{pointerEvents: 'all', cursor: 'pointer', marginRight: 4}}
          color="textSecondary">
          {first}
        </Text>
      </Flex>
      <ButtonPaging onClick={() => setModal(true)}>
        <ArrowDown1v size={16} />
      </ButtonPaging>
      <PopupWrapper>
        <Modal
          style={{
            left: -144,
            width: 144,
            top: -224,
            padding: '16px 0',
            overflow: 'hidden',
          }}
          visible={modal}
          onClose={() => setModal(false)}>
          <Column>
            {counts.map(count => (
              <PageButton
                key={'count_' + count}
                first={first}
                number={count}
                onClick={setFirst}
              />
            ))}
          </Column>
        </Modal>
      </PopupWrapper>
      <Text style={{marginLeft: 60, marginRight: 60}} color="textSecondary">
        {pageInfoText(first, page, results ? (count ?? 0) - 1 : count)} из{' '}
        {countTotal}
      </Text>
      <Flex gap={16}>
        <ButtonPaging
          onClick={() => {
            setOptions(prev => ({...prev, page: page - 1}));
            onSelectionChange && onSelectionChange([]);
          }}
          disabled={page === 1}>
          <ArrowLeft1v
            color={page === 1 ? colors.textTertiary : colors.mainPrimary}
          />
        </ButtonPaging>
        <ButtonPaging
          onClick={() => {
            setOptions(prev => ({...prev, page: page + 1}));
            onSelectionChange && onSelectionChange([]);
          }}
          disabled={!isNextPage}>
          <ArrowRight1v
            color={isNextPage ? colors.mainPrimary : colors.textTertiary}
          />
        </ButtonPaging>
      </Flex>
    </Footer>
  );
};

interface CustomTableProps {
  rows: React.ReactNode[][];
  titles: React.ReactNode[];
  widths?: (number | undefined)[];
  heightRow?: string;
  options?: OptionsType;
  onClick?: (index: number) => void;
  sort?: TableSortType;
  onClickTitle?: (index: number, sort: SortOrder) => void;
  setOptions?: React.Dispatch<React.SetStateAction<OptionsType>>;
  selections?: number[];
  onSelectionChange?: (selectedRowIndices: number[]) => void;
  style?: React.CSSProperties;
  backgroundColor?: string;
  results?: boolean;
  alignRightColumn?: number[];
  columnNoWrap?: number[];
  id?: string;
  scrollable?: boolean;
  styleTd?: React.CSSProperties;
  styleTh?: React.CSSProperties;
}

export const Table: React.FC<CustomTableProps> = ({
  rows,
  widths,
  titles,
  heightRow = '60px',
  options,
  onClick,
  onClickTitle,
  setOptions,
  selections = [],
  sort,
  onSelectionChange,
  backgroundColor,
  style,
  results = false,
  alignRightColumn = [],
  columnNoWrap = [],
  scrollable = false,
  id,
  styleTd,
  styleTh,
}) => {
  const colors = useColors();
  const [selectedRows, setSelectedRows] = React.useState<Set<number>>(
    new Set([...selections]),
  );
  const [selectAll, setSelectAll] = React.useState(false);

  const toggleSelectAll = () => {
    let newSelectedRows;
    if (selectAll) {
      newSelectedRows = new Set<number>();
    } else {
      newSelectedRows = new Set(rows?.map((_, index) => index));
    }
    setSelectedRows(newSelectedRows);
    setSelectAll(!selectAll);

    if (onSelectionChange) {
      const selectedRowIndices = Array.from(newSelectedRows);
      onSelectionChange(selectAll ? [] : selectedRowIndices);
    }
  };
  const handleCheckboxChange = (rowIndex: number) => {
    const newSelectedRows = new Set(selectedRows);
    if (newSelectedRows.has(rowIndex)) {
      newSelectedRows.delete(rowIndex);
    } else {
      newSelectedRows.add(rowIndex);
    }
    setSelectedRows(newSelectedRows);
    setSelectAll(newSelectedRows.size === rows?.length);

    if (onSelectionChange) {
      const selectedRowIndices = Array.from(newSelectedRows);
      onSelectionChange(selectedRowIndices);
    }
  };

  useEffect(() => {
    if (selections.length !== rows.length) setSelectAll(false);
    setSelectedRows(new Set(selections));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selections.length]);

  return (
    <Wrapper
      scrollable={scrollable}
      id={id}
      style={style}
      backgroundColor={backgroundColor}>
      <StyledTable>
        <StyledThead scrollable={scrollable} backgroundColor={backgroundColor}>
          <StyledTr>
            {onSelectionChange && (
              <StyledCheckboxTd style={{border: 'none'}}>
                <CheckBox checked={selectAll} onChange={toggleSelectAll} />
              </StyledCheckboxTd>
            )}
            {titles.map((item, index) =>
              onClickTitle ? (
                <StyledThSort style={styleTh} key={index}>
                  <SortButton
                    active={sort?.index === index}
                    alignRight={alignRightColumn.includes(index)}
                    hover={!!item}
                    onClick={() => {
                      const titleSort =
                        sort?.index !== index
                          ? SortOrder.Desc
                          : sort.order === SortOrder.Asc
                          ? SortOrder.Desc
                          : SortOrder.Asc;
                      onClickTitle && onClickTitle(index, titleSort);
                    }}>
                    {item}
                    {sort?.index === index ? (
                      <ArrowDown2v
                        color={colors.mainPrimary}
                        size={20}
                        style={{
                          transform:
                            sort?.order === SortOrder.Desc
                              ? ' rotate(0deg)'
                              : ' rotate(180deg)',
                        }}
                      />
                    ) : (
                      <div style={{width: 20, height: 20}}>
                        <SortIconBox>
                          <Selector size={20} color={colors.textPrimary} />
                        </SortIconBox>
                      </div>
                    )}
                  </SortButton>
                </StyledThSort>
              ) : (
                <StyledTh
                  style={styleTh}
                  alignRight={alignRightColumn.includes(index)}
                  width={widths?.find((_, indexWidth) => index === indexWidth)}
                  key={index}>
                  {item}
                </StyledTh>
              ),
            )}
          </StyledTr>
        </StyledThead>
        <tbody>
          {rows.map((row, index) => (
            <StyledTr
              hover
              height={heightRow}
              key={index}
              style={styleTd}
              onClick={() => onClick && onClick(index)}>
              {onSelectionChange && (
                <StyledCheckboxTd
                  onClick={event => {
                    event.stopPropagation();
                    handleCheckboxChange(index);
                  }}>
                  <CheckBox
                    style={{pointerEvents: 'none'}}
                    checked={selectedRows.has(index)}
                  />
                </StyledCheckboxTd>
              )}
              {row.map((cell, cellIndex) => (
                <StyledTd
                  alignRight={alignRightColumn.includes(cellIndex)}
                  columnWrap={columnNoWrap.includes(cellIndex)}
                  bold={index === 0 && results && options?.page === 1}
                  width={widths?.find(
                    (_, indexWidth) => cellIndex === indexWidth,
                  )}
                  style={styleTd}
                  key={cellIndex}>
                  {cell}
                </StyledTd>
              ))}
            </StyledTr>
          ))}
        </tbody>
      </StyledTable>
      {setOptions && options && (
        <PaginInfo
          results={results}
          first={options.first}
          page={options.page}
          total={options?.total}
          count={options.count}
          setOptions={setOptions}
          onSelectionChange={onSelectionChange}
        />
      )}
    </Wrapper>
  );
};
