import React from 'react';
import {useColorScheme} from 'shared/lib/hooks';
import {EmptyContainer} from 'shared/ui/EmptyContainer';

export const EmptyWhiteContent = ({size = 400}: {size?: number}) => {
  const colorScheme = useColorScheme();
  return (
    <EmptyContainer size={size}>
      {colorScheme === 'light' ? (
        <svg viewBox="0 0 400 400" fill="none">
          <path
            d="M198.978 318.288C268.119 318.288 324.081 261.996 324.081 192.543C324.081 123.089 268.119 67 198.978 67C130.04 67 73.876 123.292 73.876 192.745C73.876 262.199 130.04 318.288 198.978 318.288Z"
            fill="#EAEEF9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25 321.168C25 319.286 26.5261 317.76 28.4087 317.76H371.591C373.474 317.76 375 319.286 375 321.168C375 323.051 373.474 324.577 371.591 324.577H28.4087C26.5261 324.577 25 323.051 25 321.168Z"
            fill="#EAEEF9"
          />
          <path
            d="M58.8693 177.559C63.5327 177.559 67.1824 173.914 67.1824 169.257C67.1824 164.6 63.5327 160.955 58.8693 160.955C54.2058 160.955 50.5562 164.6 50.5562 169.257C50.5562 173.914 54.4086 177.559 58.8693 177.559Z"
            fill="#EAEEF9"
          />
          <path
            d="M50.5567 150.628C53.598 150.628 56.2339 147.995 56.2339 144.958C56.2339 141.921 53.598 139.288 50.5567 139.288C47.5153 139.288 44.8794 141.921 44.8794 144.958C44.8794 148.198 47.5153 150.628 50.5567 150.628Z"
            fill="#EAEEF9"
          />
          <path
            d="M348.007 198.415C353.887 198.415 358.551 193.758 358.551 187.885C358.551 182.013 353.887 177.356 348.007 177.356C342.127 177.356 337.464 182.013 337.464 187.885C337.464 193.758 342.127 198.415 348.007 198.415Z"
            fill="#EAEEF9"
          />
          <g filter="url(#filter0_d_18292_456)">
            <path
              d="M324.688 297.791H347.772C347.772 309.115 338.843 318.262 328.173 318.479C328.173 318.479 327.955 318.479 327.737 318.479H258.051V298.009H310.969L324.688 297.791Z"
              fill="#EAEEF9"
            />
          </g>
          <path
            d="M318.81 313.471C318.593 313.253 318.375 313.035 318.157 312.6C315.762 309.769 314.019 305.849 314.019 301.929V179.76C313.802 168.436 305.309 159.508 294.42 159.29C294.42 159.29 294.202 159.29 293.985 159.29H224.516V179.76H196.206V300.623C196.424 304.76 198.166 308.68 200.561 311.293C209.708 320.657 201.433 318.48 211.885 318.48H329.481C325.343 318.262 321.641 316.302 318.81 313.471Z"
            fill="#D5DDEA"
          />
          <g filter="url(#filter1_d_18292_456)">
            <path
              d="M294.638 159.29C290.718 159.29 287.016 161.685 284.62 164.516C282.225 167.565 280.265 173.663 280.265 177.583V298.445C280.265 309.986 271.119 319.133 260.012 319.133H162.669V174.534C162.887 170.396 164.629 166.476 167.025 163.863C170.074 160.814 172.905 159.29 177.26 159.29L276.563 159.072L293.984 159.29C294.42 159.29 294.42 159.29 294.638 159.29Z"
              fill="url(#paint0_linear_18292_456)"
            />
          </g>
          <path
            d="M224.952 112.252C220.815 112.47 217.113 114.43 214.282 117.043C214.064 117.261 213.846 117.478 213.628 117.914C211.233 120.745 209.273 124.883 209.273 128.802V201.537H91.6772V127.931C91.895 123.794 93.6372 119.874 96.0326 117.261C99.0814 114.212 103.001 112.252 107.574 112.252H224.952Z"
            fill="#E7EBF0"
          />
          <path
            d="M248.036 314.342C244.987 311.293 243.463 307.155 243.463 302.8L243.245 133.158C243.245 121.834 234.534 112.687 223.646 112.469C223.646 112.469 223.428 112.469 223.21 112.469H222.339H221.904H153.524H149.169V133.158H125.214V185.64H127.174V302.8C127.174 307.373 129.134 311.729 131.747 314.342C134.578 317.608 138.716 319.133 142.853 319.133H259.142C254.787 319.133 250.649 316.955 248.036 314.342Z"
            fill="#D5DDEA"
          />
          <g filter="url(#filter2_d_18292_456)">
            <path
              d="M223.425 112.252C219.069 112.47 215.15 114.212 212.319 117.261C212.101 117.478 211.883 117.696 211.447 118.132C208.834 121.181 207.092 124.883 207.092 129.238V298.227C207.092 309.987 197.728 319.351 186.186 319.351H83.8345V128.149C84.0522 123.794 85.7944 119.874 88.6254 117.043C91.6742 114.212 95.8118 112.252 100.385 112.252H203.172H222.771C222.989 112.252 223.207 112.252 223.425 112.252Z"
              fill="url(#paint1_linear_18292_456)"
            />
          </g>
          <path
            d="M186.187 140.997H155.699C154.61 140.997 153.739 140.126 153.739 139.038C153.739 137.949 154.61 137.078 155.699 137.078H185.969C187.058 137.078 187.929 137.949 187.929 139.038C188.147 140.126 187.276 140.997 186.187 140.997Z"
            fill="#989FB0"
          />
          <path
            d="M141.544 140.997H101.91C100.821 140.997 99.9502 140.126 99.9502 139.038C99.9502 137.949 100.821 137.078 101.91 137.078H141.544C142.633 137.078 143.504 137.949 143.504 139.038C143.504 140.126 142.633 140.997 141.544 140.997Z"
            fill="#D6DCE8"
          />
          <path
            d="M166.807 160.814H127.391C126.302 160.814 125.431 159.943 125.431 158.854C125.431 157.766 126.302 156.895 127.391 156.895H166.807C167.896 156.895 168.767 157.766 168.767 158.854C168.985 159.943 168.114 160.814 166.807 160.814Z"
            fill="#989FB0"
          />
          <path
            d="M114.759 160.814H101.91C100.821 160.814 99.9502 159.943 99.9502 158.854C99.9502 157.766 100.821 156.895 101.91 156.895H114.759C115.847 156.895 116.718 157.766 116.718 158.854C116.718 159.943 115.847 160.814 114.759 160.814Z"
            fill="#D6DCE8"
          />
          <path
            d="M186.188 180.631H162.015C160.926 180.631 160.055 179.76 160.055 178.671C160.055 177.583 160.926 176.711 162.015 176.711H186.188C187.276 176.711 188.147 177.583 188.147 178.671C188.147 179.76 187.276 180.631 186.188 180.631Z"
            fill="#D6DCE8"
          />
          <path
            d="M150.255 180.631H101.91C100.821 180.631 99.9502 179.76 99.9502 178.671C99.9502 177.583 100.821 176.711 101.91 176.711H150.255C151.344 176.711 152.215 177.583 152.215 178.671C152.215 179.76 151.562 180.631 150.255 180.631Z"
            fill="#989FB0"
          />
          <path
            d="M166.804 200.667H140.236C139.147 200.667 138.276 199.796 138.276 198.707C138.276 197.618 139.147 196.747 140.236 196.747H166.804C167.893 196.747 168.764 197.618 168.764 198.707C168.981 199.578 168.11 200.667 166.804 200.667Z"
            fill="#989FB0"
          />
          <path
            d="M128.696 200.667H101.91C100.821 200.667 99.9502 199.796 99.9502 198.707C99.9502 197.618 100.821 196.747 101.91 196.747H128.696C129.785 196.747 130.656 197.618 130.656 198.707C130.656 199.796 129.785 200.667 128.696 200.667Z"
            fill="#D6DCE8"
          />
          <path
            d="M186.189 220.483H167.679C166.59 220.483 165.719 219.612 165.719 218.523C165.719 217.435 166.59 216.563 167.679 216.563H186.189C187.278 216.563 188.149 217.435 188.149 218.523C188.149 219.612 187.278 220.483 186.189 220.483Z"
            fill="#989FB0"
          />
          <path
            d="M155.483 220.483H124.342C123.253 220.483 122.382 219.612 122.382 218.523C122.382 217.435 123.253 216.563 124.342 216.563H155.483C156.572 216.563 157.443 217.435 157.443 218.523C157.443 219.612 156.572 220.483 155.483 220.483Z"
            fill="#D6DCE8"
          />
          <path
            d="M112.799 220.483H101.91C100.821 220.483 99.9502 219.612 99.9502 218.523C99.9502 217.435 100.821 216.563 101.91 216.563H112.799C113.887 216.563 114.759 217.435 114.759 218.523C114.759 219.612 113.67 220.483 112.799 220.483Z"
            fill="#989FB0"
          />
          <path
            d="M186.188 260.553H162.015C160.926 260.553 160.055 259.682 160.055 258.593C160.055 257.504 160.926 256.633 162.015 256.633H186.188C187.276 256.633 188.147 257.504 188.147 258.593C188.147 259.682 187.276 260.553 186.188 260.553Z"
            fill="#D6DCE8"
          />
          <path
            d="M150.255 260.553H101.91C100.821 260.553 99.9502 259.682 99.9502 258.593C99.9502 257.504 100.821 256.633 101.91 256.633H150.255C151.344 256.633 152.215 257.504 152.215 258.593C152.215 259.682 151.562 260.553 150.255 260.553Z"
            fill="#989FB0"
          />
          <path
            d="M166.804 280.588H140.236C139.147 280.588 138.276 279.717 138.276 278.628C138.276 277.539 139.147 276.668 140.236 276.668H166.804C167.893 276.668 168.764 277.539 168.764 278.628C168.981 279.499 168.11 280.588 166.804 280.588Z"
            fill="#989FB0"
          />
          <path
            d="M128.696 280.588H101.91C100.821 280.588 99.9502 279.717 99.9502 278.628C99.9502 277.539 100.821 276.668 101.91 276.668H128.696C129.785 276.668 130.656 277.539 130.656 278.628C130.656 279.499 129.785 280.588 128.696 280.588Z"
            fill="#D6DCE8"
          />
          <path
            d="M166.806 240.3H148.296C147.207 240.3 146.336 239.429 146.336 238.34C146.336 237.251 147.207 236.38 148.296 236.38H166.806C167.895 236.38 168.766 237.251 168.766 238.34C168.984 239.429 168.113 240.3 166.806 240.3Z"
            fill="#989FB0"
          />
          <path
            d="M136.318 240.3H101.91C100.821 240.3 99.9502 239.429 99.9502 238.34C99.9502 237.251 100.821 236.38 101.91 236.38H136.318C137.407 236.38 138.278 237.251 138.278 238.34C138.278 239.429 137.407 240.3 136.318 240.3Z"
            fill="#D6DCE8"
          />
          <g filter="url(#filter3_d_18292_456)">
            <path
              d="M140.456 302.583H167.459C167.459 307.156 169.419 311.294 172.25 314.343C175.299 317.391 179.437 319.133 183.792 319.133H62.4943C57.9212 319.133 53.7836 317.174 50.9525 314.343C47.9038 311.294 45.9438 307.156 45.9438 302.583H125.865C125.865 302.583 140.891 302.583 140.456 302.583Z"
              fill="url(#paint2_linear_18292_456)"
            />
          </g>
          <g filter="url(#filter4_d_18292_456)">
            <path
              d="M189.206 288.127C176.701 266.111 177.799 257.345 179.628 250.143C182.861 251.665 185.286 253.067 185.286 253.067C184.721 252.775 185.43 248.104 185.509 247.701C186.056 242.799 186.523 238.3 187.474 233.458C192.872 209.138 209.412 187.318 228.635 170.883C232.335 167.907 236.036 164.931 239.819 162.59C241.751 161.218 243.844 160.077 245.775 158.705C246.258 158.362 251.573 155.628 251.249 155.164C251.815 177.677 252.264 190.204 259.48 200.007C253.116 202.792 249.974 203.983 242.068 204.045C253.958 212.264 257.662 210.326 257.662 210.326C257.662 210.326 256.365 230.691 224.607 261.24C230.107 264.971 231.801 264.809 231.801 264.809C231.801 264.809 212.684 288.113 193.401 287.925C189.206 288.127 189.206 288.127 189.206 288.127Z"
              fill="url(#paint3_linear_18292_456)"
            />
          </g>
          <path
            d="M185.527 319.759C188.884 288.908 190.988 246.449 213.678 208.112C236.447 169.371 255.015 150.972 255.015 150.972L253.336 155.289C253.336 155.289 226.494 183.038 210.431 224.652C194.447 265.863 193.587 295.634 192.041 314.09C185.527 319.759 185.527 319.759 185.527 319.759Z"
            fill="#989FB0"
          />
          <defs>
            <filter
              id="filter0_d_18292_456"
              x="236.051"
              y="286.791"
              width="133.721"
              height="64.688"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_18292_456"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_18292_456"
                result="shape"
              />
            </filter>
            <filter
              id="filter1_d_18292_456"
              x="140.669"
              y="148.072"
              width="175.968"
              height="204.061"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_18292_456"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_18292_456"
                result="shape"
              />
            </filter>
            <filter
              id="filter2_d_18292_456"
              x="61.8345"
              y="101.252"
              width="183.59"
              height="251.099"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_18292_456"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_18292_456"
                result="shape"
              />
            </filter>
            <filter
              id="filter3_d_18292_456"
              x="23.9438"
              y="291.583"
              width="181.848"
              height="60.5505"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_18292_456"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_18292_456"
                result="shape"
              />
            </filter>
            <filter
              id="filter4_d_18292_456"
              x="156.518"
              y="144.164"
              width="124.962"
              height="176.963"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_18292_456"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_18292_456"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_18292_456"
              x1="228.611"
              y1="155.37"
              x2="228.611"
              y2="320.858"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#FDFEFF" />
              <stop offset="0.9964" stopColor="#ECF0F5" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_18292_456"
              x1="153.584"
              y1="107.462"
              x2="153.584"
              y2="321.583"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#FDFEFF" />
              <stop offset="0.9964" stopColor="#ECF0F5" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_18292_456"
              x1="114.823"
              y1="302.2"
              x2="114.823"
              y2="319.312"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#FDFEFF" />
              <stop offset="0.9964" stopColor="#ECF0F5" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_18292_456"
              x1="197.959"
              y1="189.874"
              x2="246.763"
              y2="258.565"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#FDFEFF" />
              <stop offset="0.9964" stopColor="#ECF0F5" />
            </linearGradient>
          </defs>
        </svg>
      ) : (
        <svg viewBox="0 0 400 400" fill="none">
          <path
            d="M198.977 303.288C268.118 303.288 324.079 246.996 324.079 177.543C324.079 108.089 268.118 52 198.977 52C130.039 52 73.8745 108.292 73.8745 177.745C73.8745 247.199 130.039 303.288 198.977 303.288Z"
            fill="#2A2E37"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25 306.167C25 304.285 26.5261 302.759 28.4087 302.759H371.591C373.474 302.759 375 304.285 375 306.167C375 308.05 373.474 309.576 371.591 309.576H28.4087C26.5261 309.576 25 308.05 25 306.167Z"
            fill="#2A2E37"
          />
          <path
            d="M58.8703 162.558C63.5337 162.558 67.1834 158.914 67.1834 154.256C67.1834 149.599 63.5337 145.954 58.8703 145.954C54.2068 145.954 50.5571 149.599 50.5571 154.256C50.5571 158.914 54.4096 162.558 58.8703 162.558Z"
            fill="#2A2E37"
          />
          <path
            d="M50.5562 135.628C53.5975 135.628 56.2334 132.995 56.2334 129.958C56.2334 126.921 53.5975 124.288 50.5562 124.288C47.5148 124.288 44.8789 126.921 44.8789 129.958C44.8789 133.198 47.5148 135.628 50.5562 135.628Z"
            fill="#2A2E37"
          />
          <path
            d="M348.007 183.415C353.887 183.415 358.551 178.758 358.551 172.886C358.551 167.014 353.887 162.356 348.007 162.356C342.127 162.356 337.464 167.014 337.464 172.886C337.464 178.758 342.127 183.415 348.007 183.415Z"
            fill="#2A2E37"
          />
          <path
            d="M324.691 282.791H347.774C347.774 294.115 338.846 303.261 328.175 303.479C328.175 303.479 327.957 303.479 327.739 303.479H258.053V283.008H310.971L324.691 282.791Z"
            fill="#2A2E37"
          />
          <path
            d="M318.813 298.471C318.595 298.253 318.377 298.035 318.159 297.6C315.764 294.769 314.022 290.849 314.022 286.929V164.76C313.804 153.436 305.311 144.508 294.423 144.29C294.423 144.29 294.205 144.29 293.987 144.29H224.519V164.76H196.208V285.622C196.426 289.76 198.168 293.68 200.564 296.293C209.71 305.657 201.435 303.479 211.888 303.479H329.483C325.346 303.262 321.644 301.302 318.813 298.471Z"
            fill="#474E61"
          />
          <g filter="url(#filter0_d_18292_458)">
            <path
              d="M294.64 144.29C290.72 144.29 287.018 146.686 284.622 149.517C282.227 152.565 280.267 158.663 280.267 162.583V283.445C280.267 294.987 271.121 304.133 260.014 304.133H162.671V159.534C162.889 155.396 164.631 151.476 167.027 148.863C170.076 145.814 172.907 144.29 177.262 144.29L276.565 144.072L293.986 144.29C294.422 144.29 294.422 144.29 294.64 144.29Z"
              fill="#5C6479"
            />
          </g>
          <path
            d="M224.955 97.2524C220.818 97.4702 217.115 99.4301 214.284 102.043C214.067 102.261 213.849 102.479 213.631 102.914C211.236 105.745 209.276 109.883 209.276 113.803V186.538H91.6802V112.932C91.8979 108.794 93.6401 104.874 96.0356 102.261C99.0843 99.2124 103.004 97.2524 107.577 97.2524H224.955Z"
            fill="#7B8291"
          />
          <path
            d="M248.037 299.342C244.988 296.294 243.464 292.156 243.464 287.801L243.246 118.158C243.246 106.834 234.535 97.6877 223.647 97.47C223.647 97.47 223.429 97.47 223.211 97.47H222.34H221.905H153.525H149.169V118.158H125.215V170.641H127.175V287.801C127.175 292.374 129.135 296.729 131.748 299.342C134.579 302.609 138.717 304.133 142.854 304.133H259.143C254.788 304.133 250.65 301.956 248.037 299.342Z"
            fill="#474E61"
          />
          <g filter="url(#filter1_d_18292_458)">
            <path
              d="M223.425 97.2524C219.069 97.4702 215.15 99.2124 212.319 102.261C212.101 102.479 211.883 102.697 211.447 103.132C208.834 106.181 207.092 109.883 207.092 114.238V283.228C207.092 294.987 197.728 304.351 186.186 304.351H83.8345V113.15C84.0522 108.794 85.7944 104.874 88.6254 102.043C91.6742 99.2124 95.8118 97.2524 100.385 97.2524H203.172H222.771C222.989 97.2524 223.207 97.2524 223.425 97.2524Z"
              fill="#5C6479"
            />
          </g>
          <path
            d="M186.189 125.998H155.701C154.612 125.998 153.741 125.127 153.741 124.038C153.741 122.949 154.612 122.078 155.701 122.078H185.971C187.06 122.078 187.931 122.949 187.931 124.038C188.149 125.127 187.278 125.998 186.189 125.998Z"
            fill="#7F8CA7"
          />
          <path
            d="M141.548 125.998H101.914C100.825 125.998 99.9536 125.127 99.9536 124.038C99.9536 122.949 100.825 122.078 101.914 122.078H141.548C142.636 122.078 143.508 122.949 143.508 124.038C143.508 125.127 142.636 125.998 141.548 125.998Z"
            fill="#363B45"
          />
          <path
            d="M166.807 145.814H127.391C126.302 145.814 125.431 144.943 125.431 143.854C125.431 142.765 126.302 141.894 127.391 141.894H166.807C167.896 141.894 168.767 142.765 168.767 143.854C168.985 144.943 168.114 145.814 166.807 145.814Z"
            fill="#7F8CA7"
          />
          <path
            d="M114.762 145.814H101.914C100.825 145.814 99.9536 144.943 99.9536 143.854C99.9536 142.765 100.825 141.894 101.914 141.894H114.762C115.851 141.894 116.722 142.765 116.722 143.854C116.722 144.943 115.851 145.814 114.762 145.814Z"
            fill="#363B45"
          />
          <path
            d="M186.188 165.631H162.015C160.926 165.631 160.055 164.759 160.055 163.671C160.055 162.582 160.926 161.711 162.015 161.711H186.188C187.276 161.711 188.147 162.582 188.147 163.671C188.147 164.759 187.276 165.631 186.188 165.631Z"
            fill="#363B45"
          />
          <path
            d="M150.258 165.631H101.914C100.825 165.631 99.9536 164.759 99.9536 163.671C99.9536 162.582 100.825 161.711 101.914 161.711H150.258C151.347 161.711 152.218 162.582 152.218 163.671C152.218 164.759 151.565 165.631 150.258 165.631Z"
            fill="#7F8CA7"
          />
          <path
            d="M166.806 185.667H140.238C139.149 185.667 138.278 184.796 138.278 183.707C138.278 182.618 139.149 181.747 140.238 181.747H166.806C167.895 181.747 168.766 182.618 168.766 183.707C168.984 184.578 168.113 185.667 166.806 185.667Z"
            fill="#7F8CA7"
          />
          <path
            d="M128.699 185.667H101.914C100.825 185.667 99.9536 184.796 99.9536 183.707C99.9536 182.618 100.825 181.747 101.914 181.747H128.699C129.788 181.747 130.659 182.618 130.659 183.707C130.659 184.796 129.788 185.667 128.699 185.667Z"
            fill="#363B45"
          />
          <path
            d="M186.191 205.483H167.68C166.591 205.483 165.72 204.612 165.72 203.523C165.72 202.434 166.591 201.563 167.68 201.563H186.191C187.279 201.563 188.15 202.434 188.15 203.523C188.15 204.612 187.279 205.483 186.191 205.483Z"
            fill="#7F8CA7"
          />
          <path
            d="M155.483 205.483H124.342C123.253 205.483 122.382 204.612 122.382 203.523C122.382 202.434 123.253 201.563 124.342 201.563H155.483C156.572 201.563 157.443 202.434 157.443 203.523C157.443 204.612 156.572 205.483 155.483 205.483Z"
            fill="#363B45"
          />
          <path
            d="M112.802 205.483H101.914C100.825 205.483 99.9536 204.612 99.9536 203.523C99.9536 202.434 100.825 201.563 101.914 201.563H112.802C113.891 201.563 114.762 202.434 114.762 203.523C114.762 204.612 113.673 205.483 112.802 205.483Z"
            fill="#7F8CA7"
          />
          <path
            d="M186.188 245.553H162.015C160.926 245.553 160.055 244.682 160.055 243.593C160.055 242.504 160.926 241.633 162.015 241.633H186.188C187.276 241.633 188.147 242.504 188.147 243.593C188.147 244.682 187.276 245.553 186.188 245.553Z"
            fill="#363B45"
          />
          <path
            d="M150.258 245.553H101.914C100.825 245.553 99.9536 244.682 99.9536 243.593C99.9536 242.504 100.825 241.633 101.914 241.633H150.258C151.347 241.633 152.218 242.504 152.218 243.593C152.218 244.682 151.565 245.553 150.258 245.553Z"
            fill="#7F8CA7"
          />
          <path
            d="M166.806 265.588H140.238C139.149 265.588 138.278 264.717 138.278 263.628C138.278 262.539 139.149 261.668 140.238 261.668H166.806C167.895 261.668 168.766 262.539 168.766 263.628C168.984 264.499 168.113 265.588 166.806 265.588Z"
            fill="#7F8CA7"
          />
          <path
            d="M128.699 265.588H101.914C100.825 265.588 99.9536 264.717 99.9536 263.628C99.9536 262.539 100.825 261.668 101.914 261.668H128.699C129.788 261.668 130.659 262.539 130.659 263.628C130.659 264.499 129.788 265.588 128.699 265.588Z"
            fill="#363B45"
          />
          <path
            d="M166.808 225.301H148.298C147.209 225.301 146.338 224.43 146.338 223.341C146.338 222.252 147.209 221.381 148.298 221.381H166.808C167.897 221.381 168.768 222.252 168.768 223.341C168.986 224.43 168.115 225.301 166.808 225.301Z"
            fill="#7F8CA7"
          />
          <path
            d="M136.321 225.301H101.914C100.825 225.301 99.9536 224.43 99.9536 223.341C99.9536 222.252 100.825 221.381 101.914 221.381H136.321C137.41 221.381 138.281 222.252 138.281 223.341C138.281 224.43 137.41 225.301 136.321 225.301Z"
            fill="#363B45"
          />
          <path
            d="M140.457 287.583H167.461C167.461 292.156 169.421 296.293 172.252 299.342C175.3 302.391 179.438 304.133 183.793 304.133H62.4958C57.9226 304.133 53.785 302.173 50.954 299.342C47.9052 296.293 45.9453 292.156 45.9453 287.583H125.867C125.867 287.583 140.893 287.583 140.457 287.583Z"
            fill="#474E61"
          />
          <g filter="url(#filter2_d_18292_458)">
            <path
              d="M187.105 273.49C175.369 252.501 176.764 243.854 178.836 236.704C182.012 238.041 184.386 239.299 184.386 239.299C183.831 239.04 184.698 234.426 184.79 234.027C185.503 229.194 186.124 224.76 187.238 219.967C193.455 195.86 210.713 173.671 230.466 156.633C234.262 153.537 238.058 150.441 241.915 147.964C243.89 146.526 246.019 145.308 247.994 143.87C248.488 143.51 253.888 140.574 253.58 140.134C253.379 162.183 253.4 174.445 260.272 183.712C253.823 186.748 250.645 188.066 242.749 188.506C254.341 195.995 258.105 193.917 258.105 193.917C258.105 193.917 256.116 213.949 223.368 245.427C228.732 248.822 230.429 248.582 230.429 248.582C230.429 248.582 210.548 272.35 191.301 273.09C187.105 273.49 187.105 273.49 187.105 273.49Z"
              fill="url(#paint0_linear_18292_458)"
            />
          </g>
          <path
            d="M182.719 304.76C187.12 274.357 190.665 232.636 214.621 193.967C238.669 154.899 257.831 135.973 257.831 135.973L256.009 140.285C256.009 140.285 228.268 168.773 210.816 210.336C193.457 251.5 191.586 280.724 189.414 298.89C182.719 304.76 182.719 304.76 182.719 304.76Z"
            fill="#474E61"
          />
          <defs>
            <filter
              id="filter0_d_18292_458"
              x="140.671"
              y="133.072"
              width="175.968"
              height="204.061"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_18292_458"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_18292_458"
                result="shape"
              />
            </filter>
            <filter
              id="filter1_d_18292_458"
              x="61.8345"
              y="86.2524"
              width="183.59"
              height="251.099"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_18292_458"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_18292_458"
                result="shape"
              />
            </filter>
            <filter
              id="filter2_d_18292_458"
              x="173.442"
              y="140.134"
              width="90.8291"
              height="141.356"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_18292_458"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_18292_458"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_18292_458"
              x1="183.234"
              y1="215.638"
              x2="241.724"
              y2="256.363"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#E3EAF7" />
              <stop offset="1" stopColor="#ADB6C8" />
            </linearGradient>
          </defs>
        </svg>
      )}
    </EmptyContainer>
  );
};
