import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import {BookingService} from 'shared/__generated__/graphql';

type IBookingService = Pick<BookingService, 'price' | 'additional_price'> & {
  service_id: string;
  discount_id?: string;
};

interface IBooking {
  create: {
    workingDate: string | null;
    services: IBookingService[];
    duration: number;
    employeeId?: string | null;
    customerId: string | null;
  };
  edit: {
    bookingId: string | null;
    workingDate: string | null;
    services: IBookingService[];
    duration: number;
    employeeId?: string | null;
    customerId: string | null;
  };
  filter: {
    interval_time: number;
    countColumn: 'Автоматически' | '1' | '2' | '3' | '4';
    employeeWithBooking: boolean;
    employeeWithoutBooking: boolean;
    professions: string[];
    fixedCalendar: boolean;
  };
  screen: 'create' | 'edit';
  date: string;
}

const initialState: IBooking = {
  create: {
    workingDate: null,
    services: [],
    duration: 0,
    employeeId: undefined,
    customerId: null,
  },
  edit: {
    bookingId: null,
    workingDate: null,
    services: [],
    duration: 0,
    employeeId: undefined,
    customerId: null,
  },
  filter: {
    interval_time: 30,
    countColumn: 'Автоматически',
    employeeWithBooking: false,
    employeeWithoutBooking: false,
    professions: [],
    fixedCalendar: localStorage.getItem('booking_fixed_calendar')
      ? localStorage.getItem('booking_fixed_calendar') === 'true'
      : false,
  },
  screen: 'create',
  date: dayjs().format('YYYY-MM-DD'),
};

const bookingSlice = createSlice({
  name: 'editBooking',
  initialState,
  reducers: {
    setCreateBookingWorkingDate: (
      state,
      action: PayloadAction<string | null>,
    ) => {
      state.create.workingDate = action.payload;
    },
    setCreateBookingServices: (
      state,
      action: PayloadAction<IBookingService[]>,
    ) => {
      state.create.services = action.payload;
    },
    setCreateBookingDuration: (state, action: PayloadAction<number>) => {
      state.create.duration = action.payload;
    },
    setCreateBookingEmployee: (
      state,
      action: PayloadAction<string | null | undefined>,
    ) => {
      state.create.employeeId = action.payload;
    },
    setCreateBookingCustomer: (state, action: PayloadAction<string | null>) => {
      state.create.customerId = action.payload;
    },
    setEditBookingId: (state, action: PayloadAction<string | null>) => {
      state.edit.bookingId = action.payload;
    },
    setEditBookingWorkingDate: (
      state,
      action: PayloadAction<string | null>,
    ) => {
      state.edit.workingDate = action.payload;
    },
    setEditBookingServices: (
      state,
      action: PayloadAction<IBookingService[]>,
    ) => {
      state.edit.services = action.payload;
    },
    setEditBookingDuration: (state, action: PayloadAction<number>) => {
      state.edit.duration = action.payload;
    },
    setEditBookingEmployee: (
      state,
      action: PayloadAction<string | null | undefined>,
    ) => {
      state.edit.employeeId = action.payload;
    },
    setEditBookingCustomer: (state, action: PayloadAction<string | null>) => {
      state.edit.customerId = action.payload;
    },
    clearCreateBookingState: state => {
      state.create = initialState.create;
    },
    clearEditBookingState: state => {
      state.create = initialState.create;
    },
    setBookingScreen: (state, action: PayloadAction<'create' | 'edit'>) => {
      state.screen = action.payload;
    },
    setBookingDate: (state, action: PayloadAction<string>) => {
      state.date = action.payload;
    },
    setBookingFilterInterval: (state, action: PayloadAction<number>) => {
      state.filter.interval_time = action.payload;
    },
    setBookingFilterColumn: (
      state,
      action: PayloadAction<'Автоматически' | '1' | '2' | '3' | '4'>,
    ) => {
      state.filter.countColumn = action.payload;
    },
    setBookingFilterWith: (state, action: PayloadAction<boolean>) => {
      state.filter.employeeWithBooking = action.payload;
    },
    setBookingFilterWithout: (state, action: PayloadAction<boolean>) => {
      state.filter.employeeWithoutBooking = action.payload;
    },
    setBookingFilterProfessions: (state, action: PayloadAction<string[]>) => {
      state.filter.professions = action.payload;
    },
    setBookingFilterFixedCalendar: (state, action: PayloadAction<boolean>) => {
      state.filter.fixedCalendar = action.payload;
      localStorage.setItem('booking_fixed_calendar', action.payload + '');
    },
    clearBookingFilter: state => {
      state.filter = initialState.filter;
    },
  },
});

export const {
  setCreateBookingWorkingDate,
  setCreateBookingServices,
  setCreateBookingDuration,
  setCreateBookingCustomer,
  setCreateBookingEmployee,
  clearCreateBookingState,
  clearEditBookingState,
  setEditBookingId,
  setEditBookingWorkingDate,
  setEditBookingServices,
  setEditBookingDuration,
  setEditBookingEmployee,
  setEditBookingCustomer,
  setBookingScreen,
  setBookingDate,
  setBookingFilterInterval,
  setBookingFilterColumn,
  setBookingFilterWith,
  setBookingFilterWithout,
  setBookingFilterProfessions,
  setBookingFilterFixedCalendar,
  clearBookingFilter,
} = bookingSlice.actions;
export default bookingSlice.reducer;
