import React from 'react';
const Check = ({
  color = 'white',
  size = 24,
  strokeWidth = 2.5,
}: {
  color?: string;
  size?: number;
  strokeWidth?: number;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none">
      <path
        d="M5 12.875L9.2 17L19 6"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Check;
