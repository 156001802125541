import React from 'react';
import {Text} from 'shared/ui/Text';
import {App, Image} from 'shared/__generated__/graphql';
import styled from 'styled-components';

type AppType =
  | (Pick<
      App,
      'name' | 'id' | 'is_connected' | 'short_description' | 'is_recommended'
    > & {
      image?: Pick<Image, 'url' | 'id'> | null;
      darkImage?: Pick<Image, 'url' | 'id'> | null;
    })
  | null;

const Head = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
`;
const Img = styled.img`
  border-radius: 40px;
  display: flex;
  width: 80px;
  height: 80px;
  justify-content: center;
  align-items: center;
  object-fit: cover;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;
const Status = styled.div<{visible?: boolean; plug?: boolean}>`
  display: ${({visible}) => (visible ? 'flex' : 'none')};
  padding: 8px 14px;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 10px;
  border-radius: 12px;
  background-color: ${({theme, plug}) =>
    plug ? theme.bgSuccess : theme.mainLight};
`;

export const Header = ({app}: {app?: AppType}) => {
  return (
    <Head>
      <Img src={app?.image?.url} />
      <Content>
        <Text typography="title18">{app?.name}</Text>
        <Text typography="text16Regular">{app?.short_description}</Text>
        <Row>
          <Status visible={app?.is_connected ?? false} plug>
            <Text typography="subHead14Medium" color="successPrimary">
              Подключено
            </Text>
          </Status>
          <Status visible={app?.is_recommended}>
            <Text typography="subHead14Medium" color="mainPrimary">
              Рекомендуем
            </Text>
          </Status>
        </Row>
      </Content>
    </Head>
  );
};
