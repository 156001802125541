import React, {useState} from 'react';
import {Popup} from 'shared/ui/Popup';
import {Grow} from 'shared/ui/Grow';
import {Flex} from 'shared/ui/Flex';
import {Text} from 'shared/ui/Text';
import {TextInput} from 'shared/ui/TextInput';
import {CloseButton} from 'shared/ui/CloseButton';
import {Color, CompanyTag} from 'shared/__generated__/graphql';
import styled from 'styled-components';
import Check from 'shared/icons/Check';
import {Button} from 'shared/ui/Button';
import {useColors} from 'shared/lib/hooks';
import {useMutation} from '@apollo/client';
import {gql} from 'shared/__generated__';
import {showAlert} from 'shared/ui/Alert';
import {useAppSelector} from 'shared/store';
import {TAGS} from 'entities/tags';

const EDIT_TAG_CUSTOMERS = gql(`
  mutation UpdateCustomerTag($input: UpdateCustomerTag!) {
    updateCustomerTag(input: $input) {
      id
    }
  }
`);
const DELETE_TAG_CUSTOMERS = gql(`
  mutation DeleteCustomerTag($deleteCustomerTagId: String!) {
    deleteCustomerTag(id: $deleteCustomerTagId) {
      id
    }
  }
`);

type TagType = Pick<CompanyTag, 'id' | 'name' | 'order'> & {
  color?: Pick<Color, 'id' | 'rgb'> | null;
};

const ColorCircle = styled.button<{color: string}>`
  display: flex;
  height: 56px;
  width: 56px;
  border-radius: 28px;
  background-color: rgb(${({color}) => color});
  padding: 0;
  border: none;
  margin: 0;
  align-items: center;
  justify-content: center;
  &:hover {
    transform: scale(1.1);
    transition: 0.3s;
  }
`;

const ColorButton = ({
  color,
  active,
  onClick,
}: {
  color: Pick<Color, 'id' | 'rgb'>;
  active: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}) => {
  return (
    <ColorCircle onClick={onClick} color={color.rgb}>
      {active && <Check size={28} />}
    </ColorCircle>
  );
};

export const TagEditModal = ({
  visible,
  onClose,
  tag,
  colors,
}: {
  visible: boolean;
  onClose: () => void;
  tag: TagType;
  colors: Array<Pick<Color, 'id' | 'rgb'>>;
}) => {
  const color = useColors();
  const companyId = useAppSelector(state => state.company.data!.id);
  const [name, setName] = useState(tag?.name);
  const [selectColor, setSelectColor] = useState<string | undefined>(
    tag?.color?.id,
  );
  const refetchQueries = [
    {
      query: TAGS,
      variables: {
        companyId,
        first: 100,
        page: 1,
        colorsFirst: 100,
        colorsPage: 1,
      },
    },
  ];
  const [updateTag, {loading: updateLoading}] = useMutation(
    EDIT_TAG_CUSTOMERS,
    {
      refetchQueries,
    },
  );
  const [deleteTag, {loading: deleteLoading}] = useMutation(
    DELETE_TAG_CUSTOMERS,
    {
      refetchQueries,
    },
  );

  return (
    <Popup style={{width: 598}} visible={visible} onClose={onClose}>
      <Grow style={{width: '100%', gap: 32}}>
        <Flex alignItems="center" justifyContent="space-between">
          <Text typography="title20">Редактировать тег</Text>
          <CloseButton onClose={onClose} />
        </Flex>
        <TextInput value={name} onChange={setName} label="Название тега" />
        <Text typography="title18">Цвет тега</Text>
        <Flex style={{flexWrap: 'wrap'}} gap={26}>
          {colors.map((item, index) => (
            <ColorButton
              onClick={() => setSelectColor(item.id)}
              key={`color_${index}`}
              active={selectColor === item.id}
              color={item}
            />
          ))}
        </Flex>
        <Flex gap={8}>
          <Button
            size="large"
            style={{
              flex: 1,
              color: color.dangerPrimary,
              borderColor: color.dangerPrimary,
            }}
            loading={deleteLoading}
            disabled={updateLoading || deleteLoading}
            variant="outline"
            onClick={async () => {
              if (tag) {
                try {
                  await deleteTag({
                    variables: {
                      deleteCustomerTagId: tag.id,
                    },
                  });
                  onClose();
                } catch (e) {
                  if (e instanceof Error) {
                    showAlert('error', e.message);
                  }
                }
              }
            }}>
            Удалить
          </Button>
          <Button
            size="large"
            style={{flex: 1}}
            loading={updateLoading}
            disabled={
              updateLoading || deleteLoading || !name.trim() || !selectColor
            }
            onClick={async () => {
              if (tag) {
                try {
                  await updateTag({
                    variables: {
                      input: {
                        id: tag?.id,
                        color_id: selectColor,
                        name,
                        order: tag.order,
                      },
                    },
                  });
                  onClose();
                } catch (e) {
                  if (e instanceof Error) {
                    showAlert('error', e.message);
                  }
                }
              }
            }}>
            Сохранить
          </Button>
        </Flex>
      </Grow>
    </Popup>
  );
};
