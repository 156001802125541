import React from 'react';
import {Avatar as AvatarIcon} from 'shared/icons/Avatar';
import {Image} from './Image';
import styled from 'styled-components';
import {AvatarCompany} from 'shared/icons/AvatarCompany';
import {Loader} from 'shared/icons/Loader';
import {Camera} from 'shared/icons/Camera';
import {NoImage} from 'shared/icons/NoImage';

const Wrapper = styled.button<{size: number}>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;
  padding: 0px;
  width: min-content;
  pointer-events: all;
  border: 0 solid;
  background-color: transparent;
  cursor: pointer;
  border-radius: ${props => props.size / 2}px;
  &:disabled {
    pointer-events: none;
  }
`;
export function Avatar({
  variant = 'avatar',
  url,
  mt,
  mb,
  size = 44,
  placeholder,
  active,
  rating,
  editable = false,
  overlay = false,
  indicator,
  icon,
  onClick,
  activeSize = 10,
  children,
}: React.PropsWithChildren<{
  variant?: 'avatar' | 'company' | 'image';
  url?: string;
  size?: number;
  mt?: number;
  mb?: number;
  placeholder?: string;
  indicator?: boolean;
  active?: boolean;
  rating?: number;
  editable?: boolean;
  overlay?: boolean;
  icon?: React.ReactNode;
  activeSize?: number;
  onClick?: () => void;
}>) {
  const src = url ?? placeholder;
  return (
    <Wrapper disabled={!editable} size={size} onClick={onClick}>
      {src ? (
        <Image
          width={size}
          height={size}
          radius={size / 2}
          style={{
            display: 'flex',
            marginTop: mt,
            marginBottom: mb,
            pointerEvents: editable ? 'all' : 'none',
            cursor: editable ? 'pointer' : 'none',
            filter: indicator ? 'blur(1.5px)' : 'none',
          }}
          src={src}
        />
      ) : variant === 'avatar' ? (
        <AvatarIcon size={size} />
      ) : variant === 'company' ? (
        <AvatarCompany size={size} />
      ) : (
        <NoImage size={size} />
      )}
      {rating ? (
        <WrapperRating>{rating}</WrapperRating>
      ) : active !== undefined ? (
        <ActiveStatus status={active} size={activeSize} />
      ) : icon ? (
        <WrapperIcon>{icon}</WrapperIcon>
      ) : null}
      {indicator ? (
        <WrapperLoader size={size}>
          <Loader />
        </WrapperLoader>
      ) : null}
      {overlay && (
        <Overlay border={size / 2}>
          <Camera size={size} />
        </Overlay>
      )}
      {children}
    </Wrapper>
  );
}

const ActiveStatus = styled.div<{status?: boolean; size?: number}>`
  width: ${({size}) => size}px;
  height: ${({size}) => size}px;
  border-radius: 8px;
  background-color: ${({theme, status}) =>
    status ? theme.mainPrimary : theme.textTertiary};
  position: absolute;
  right: 0;
  bottom: 0;
  border: 3px solid ${({theme}) => theme.bgPrimary};
`;

const WrapperRating = styled.div`
  border: 3px solid ${({theme}) => theme.bgPrimary};
  position: absolute;
  border-radius: 48px;
  bottom: 0;
  right: 0;
  background-color: ${({theme}) => theme.mainPrimary};
  padding: 0 6px;
  font-size: 14px;
  line-height: 20px;
  font-style: normal;
  font-weight: 400;
  color: ${({theme}) => theme.white};
`;

const WrapperIcon = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  height: min-content;
  padding: 0;
  margin: 0;
`;

const WrapperLoader = styled.div<{size: number}>`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${props => props.size / 2}px;
`;

const Overlay = styled.div<{border: number}>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  border-radius: ${({border}) => border}px;
`;
