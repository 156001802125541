import React from 'react';
import {EmptyReview} from 'shared/illustration/EmptyReview';
import {useResize} from 'shared/lib/hooks';
import {Grow} from 'shared/ui/Grow';
import {Text} from 'shared/ui/Text';

export const ErrorComponent = () => {
  const isMobile = useResize().isMobile;
  return (
    <Grow style={{padding: '0 16px'}}>
      <EmptyReview size={isMobile ? '200px' : '300px'} />
      <Text
        typography="title20"
        style={{textAlign: 'center', alignSelf: 'center', marginTop: 16}}>
        Ничего не найдено
      </Text>
      <Text
        typography="text16Regular"
        color="textTertiary"
        style={{textAlign: 'center', alignSelf: 'center', marginTop: 16}}>
        К сожалению по вашему запросу ничего не найдено
      </Text>
    </Grow>
  );
};
