import React from 'react';
import styled from 'styled-components';
import {Button} from 'shared/ui/Button';
import {Text} from 'shared/ui/Text';
import {App, AppCategory, Image} from 'shared/__generated__/graphql';
import {useNavigate} from 'react-router';
import {useAppSelector} from 'shared/store';
import {useColors} from 'shared/lib/hooks';
import Accept from 'shared/icons/Accept';

export type AppType = Pick<
  App,
  | 'name'
  | 'id'
  | 'is_connectable'
  | 'is_connected'
  | 'key'
  | 'short_description'
> & {
  image?: Pick<Image, 'url' | 'id'> | null;
  darkImage?: Pick<Image, 'url' | 'id'> | null;
  appCategory: Pick<AppCategory, 'id' | 'title'>;
};

const Card = styled.button`
  display: flex;
  padding: 24px 40px;
  flex-direction: column;
  align-items: center;
  border-radius: 32px;
  width: 385.5px;
  height: 348px;
  border: 32px solid transparent;
  background: ${({theme}) => theme.bgPrimary};
`;
const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  position: relative;
`;
const Img = styled.img`
  border-radius: 40px;
  display: flex;
  width: 80px;
  height: 80px;
  justify-content: center;
  align-items: center;
  object-fit: cover;
`;
const TextSection = styled.section`
  display: flex;
  width: 306px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const RecommendedDiv = styled.div<{isRecommended?: boolean}>`
  display: ${({isRecommended}) => (isRecommended ? 'flex' : 'none')};
  position: absolute;
  right: calc((100%) / 2 - 32px - 11px);
  top: 0;
  width: 22px;
  height: 22px;
  align-items: center;
  justify-content: center;
  border-radius: 22px;
  background-color: ${({theme}) => theme.successPrimary};
`;

type Props = {
  app?: AppType | null;
  moderationId?: string;
  title?: string;
  isRecommended?: boolean;
};

export const AppCard = ({
  app,
  moderationId,
  title = 'От Booky',
  isRecommended,
}: Props) => {
  const navigate = useNavigate();
  const username = useAppSelector(state => state.company.data?.username);
  const colors = useColors();
  return (
    <Card>
      <Section>
        <Img src={app?.image?.url ?? undefined} width={32} height={32} />
        <TextSection
          style={{
            overflowWrap: 'break-word',
            marginBottom: '0',
            paddingBottom: '0',
          }}>
          <Text typography="title18">{app?.name ?? ''}</Text>
          <Text
            style={{WebkitLineClamp: 2, textAlign: 'center'}}
            typography="subHead14Regular">
            {app?.short_description ?? ''}
          </Text>
        </TextSection>
        <RecommendedDiv isRecommended={isRecommended}>
          <Accept size={15} color={colors.white} />
        </RecommendedDiv>
      </Section>
      <Button
        typography="subHead14Regular"
        size="medium"
        variant="outline"
        style={{marginBottom: '3%', marginTop: 'auto'}}
        onClick={() => {
          if (app?.key === 'widget') {
            return navigate(`/${username}/widget-app/${app.id}`, {
              state: title,
            });
          }
          if (app?.key === 'qrcode') {
            return navigate(`/${username}/qrcode-app/${app.id}`, {
              state: title,
            });
          }
          if (app?.key === 'platformbooky') {
            return navigate(`/${username}/platform-app/${app.id}`, {
              state: title,
            });
          }
          if (app?.key === 'websitebooky') {
            return navigate(`/${username}/website-app/${app.id}`, {
              state: title,
            });
          }
          if (
            app?.key === 'chatpush-telegram' ||
            app?.key === 'chatpush-whatsapp'
          ) {
            return navigate(`/${username}/chat-app/${app.id}`, {
              state: {
                title,
                variant: app.key,
              },
            });
          }
          if (
            (app?.appCategory.title === 'CMS' ||
              app?.appCategory.title === 'Конструкторы сайтов') &&
            moderationId
          ) {
            return navigate(`/${username}/csm-app/${app.id}--${moderationId}`, {
              state: title,
            });
          }
          if (app?.id)
            return navigate(`/${username}/other-app/${app.id}`, {
              state: title,
            });
        }}>
        Подробнее
      </Button>
    </Card>
  );
};
