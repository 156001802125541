import React, {useState} from 'react';
import {DndContext, DragEndEvent, closestCenter} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {Employee, Image} from 'shared/__generated__/graphql';
import {SortableItem} from './SortableItem';

type EmployeeType = Pick<
  Employee,
  'id' | 'name' | 'surname' | 'role' | 'invite_status' | 'position' | 'enabled'
> & {avatar?: Pick<Image, 'id' | 'url' | 'url_256'> | null};

type Props = {
  employees: Array<EmployeeType>;
  onDragEnd?: (emploees: EmployeeType[]) => void;
};

export const EmployeesDragDrop = ({
  employees: emplloyeesData,
  onDragEnd,
}: Props) => {
  const [employees, setEmployees] = useState(emplloyeesData);

  function handleDragEnd(event: DragEndEvent) {
    const {active, over} = event;

    if (active.id !== over?.id) {
      setEmployees(items => {
        const ids = items.map(item => item.id);
        const activeIndex = ids.indexOf(active!.id.toString());
        const overIndex = ids.indexOf(over!.id.toString());
        onDragEnd && onDragEnd(arrayMove(items, activeIndex, overIndex));
        return arrayMove(items, activeIndex, overIndex);
      });
    }
  }

  return (
    <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext items={employees} strategy={verticalListSortingStrategy}>
        {employees.map(employee => (
          <SortableItem key={employee.id} employee={employee} />
        ))}
      </SortableContext>
    </DndContext>
  );
};
