import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Content} from 'shared/ui/Content';
import {NetworkStatus, useMutation, useQuery} from '@apollo/client';
import {useAppSelector} from 'shared/store';
import {TagsDragDrop} from './ui/TagsDragDrop';
import {Loader} from 'shared/icons/Loader';
import {showAlert} from 'shared/ui/Alert';
import {Flex} from 'shared/ui/Flex';
import {PageHeader} from 'shared/ui/PageHeader';
import {Button} from 'shared/ui/Button';
import {Plus} from 'shared/icons/Plus';
import {TAGS, TagCreateModal, UPDATE_TAGS_POSITION} from 'entities/tags';
import {EmptyState} from './ui/EmptyState';
import {
  Color,
  CompanyTag,
  UpdateCompanyTagPositions,
} from 'shared/__generated__/graphql';
import {Text} from 'shared/ui/Text';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 720px;
  width: 100%;
  padding: 64px 0 24px;
`;
type TagType = Pick<CompanyTag, 'id' | 'name' | 'order'> & {
  color?: Pick<Color, 'id' | 'rgb'> | null;
};

export const TagsCustomers = () => {
  const companyId = useAppSelector(state => state.company.data!.id);
  const [createTagModal, setCreateTagModal] = useState(false);
  const [tags, setTags] = useState<TagType[]>([]);
  const {data, networkStatus} = useQuery(TAGS, {
    variables: {
      companyId,
      colorsFirst: 100,
      colorsPage: 1,
    },
  });
  const [update] = useMutation(UPDATE_TAGS_POSITION);
  const colors = data?.colors?.data ?? [];

  useEffect(() => {
    setTags(data?.companyTags ?? []);
  }, [data?.companyTags]);

  const sortEmployees = [...tags].sort((a, b) => {
    if (a.order && b.order) {
      return a.order - b.order;
    }
    return 0;
  });

  return (
    <Flex justifyContent="center" flex={1}>
      <Wrapper>
        <TagCreateModal
          colors={colors}
          visible={!!createTagModal}
          onClose={() => setCreateTagModal(false)}
        />
        <Flex justifyContent="space-between" alignItems="center">
          <Flex gap={12} alignItems="center">
            <PageHeader title="Теги клиентов" />
            {sortEmployees.length && (
              <Text typography="title24" color="textTertiary">
                {sortEmployees.length}
              </Text>
            )}
          </Flex>
          <Button
            size="medium"
            onClick={() => setCreateTagModal(true)}
            leftIcon={<Plus size={20} />}>
            Добавить тег
          </Button>
        </Flex>
        {networkStatus === NetworkStatus.loading ? (
          <Flex
            mb={136}
            flex={1}
            direction="column"
            justifyContent="center"
            alignItems="center">
            <Loader />
          </Flex>
        ) : tags.length ? (
          <Content style={{marginTop: 32, marginBottom: 24}}>
            <TagsDragDrop
              colors={colors}
              data={sortEmployees}
              onDragEnd={async value => {
                setTags(
                  value.map((item, index) => ({...item, order: index + 1})),
                );
                try {
                  const input: UpdateCompanyTagPositions = {
                    companyTags: value.map((item, index) => ({
                      company_tag_id: item.id,
                      position: index + 1,
                    })),
                  };
                  await update({variables: {input}});
                } catch (e) {
                  if (e instanceof Error) {
                    showAlert('error', e.message);
                  }
                }
              }}
            />
          </Content>
        ) : (
          <EmptyState />
        )}
      </Wrapper>
    </Flex>
  );
};
