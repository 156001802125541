import React from 'react';
import {CircleClose} from 'shared/icons/CircleClose';
import {
  clearBookingFilter,
  setBookingFilterProfessions,
  setBookingFilterWith,
  setBookingFilterWithout,
  useAppDispatch,
  useAppSelector,
} from 'shared/store';
import {Button} from 'shared/ui/Button';
import {Flex} from 'shared/ui/Flex';
import {IconButton} from 'shared/ui/IconButton';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  gap: 8px;
  grid-column-start: 14;
  flex-wrap: wrap;
`;

const ChipWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  padding: 8px;
  border-radius: 8px;
  width: max-content;
  height: 32px;
  border: 1px solid ${({theme}) => theme.borderPrimary};
  background-color: ${({theme}) => theme.bgPrimary};
`;

const Chip = ({
  variant = 'profession',
  text,
  onClick,
}: {
  variant?: 'profession' | 'booking';
  text: string;
  onClick: () => void;
}) => {
  return (
    <ChipWrapper>
      <Flex direction="row" alignItems="center" gap={4}>
        <Text typography="footNote12Medium" color="textTertiary">
          {variant === 'profession' ? 'По\u00A0профессиям' : 'По\u00A0записям'}:
        </Text>
        <Text typography="footNote12Medium" style={{whiteSpace: 'nowrap'}}>
          {text}
        </Text>
      </Flex>
      <IconButton variant="unstyled" size={24} onClick={onClick}>
        <CircleClose />
      </IconButton>
    </ChipWrapper>
  );
};

export const Filters = ({
  professions,
}: {
  professions: {
    id?: string;
    name?: string;
  }[];
}) => {
  const dispatch = useAppDispatch();
  const selectProfessions = useAppSelector(
    state => state.booking.filter.professions,
  );
  const employeeWithBooking = useAppSelector(
    state => state.booking.filter.employeeWithBooking,
  );
  const employeeWithoutBooking = useAppSelector(
    state => state.booking.filter.employeeWithoutBooking,
  );

  const hasFilter = React.useCallback(() => {
    const filter: number[] = [];
    if (employeeWithBooking) {
      filter.push(0);
    }
    if (employeeWithoutBooking) {
      filter.push(1);
    }
    if (selectProfessions.length) {
      filter.push(2);
    }

    return filter.length > 0 ? true : false;
  }, [employeeWithBooking, employeeWithoutBooking, selectProfessions.length]);

  if (!hasFilter()) {
    return undefined;
  }

  return (
    <Wrapper id="FilterWrapper">
      {professions.map(profession => (
        <Chip
          key={'profession_filter' + profession.id}
          text={profession.name ?? ''}
          onClick={() => {
            dispatch(
              setBookingFilterProfessions(
                selectProfessions.filter(item => item !== profession.id),
              ),
            );
          }}
        />
      ))}
      {employeeWithBooking && (
        <Chip
          variant="booking"
          text={'Сотрудники с записями'}
          onClick={() => dispatch(setBookingFilterWith(false))}
        />
      )}
      {employeeWithoutBooking && (
        <Chip
          variant="booking"
          text="Сотрудники без записей"
          onClick={() => dispatch(setBookingFilterWithout(false))}
        />
      )}

      <Button
        variant="light"
        style={{height: 32, borderRadius: 8}}
        onClick={() => dispatch(clearBookingFilter())}>
        Сбросить
      </Button>
    </Wrapper>
  );
};
