import styled from 'styled-components';
import {Box} from './Box';

export const Flex = styled(Box)<{
  direction?: 'row' | 'column';
  flex?: number;
  justifyContent?: 'center' | 'space-between' | 'flex-end' | 'flex-start';
  alignItems?: 'center' | 'start' | 'flex-end';
  gap?: number;
}>`
  display: flex;
  justify-content: ${props => props.justifyContent};
  flex-direction: ${props => props.direction};
  align-items: ${props => props.alignItems};
  flex: ${props => props.flex};
  gap: ${props => props.gap + 'px'};
`;
