import React from 'react';
import {useNavigate} from 'react-router-dom';
import {EmptyWhiteContent} from 'shared/illustration/EmptyWriteContent';
import {useAppSelector} from 'shared/store';
import {Button} from 'shared/ui/Button';
import {Flex} from 'shared/ui/Flex';
import {Text} from 'shared/ui/Text';

export const EmptyState = () => {
  const navigate = useNavigate();
  const username = useAppSelector(state => state.company.data?.username);
  return (
    <Flex
      flex={1}
      direction="column"
      alignItems="center"
      gap={16}
      style={{paddingTop: 52}}>
      <EmptyWhiteContent size={300} />
      <Text
        typography="text18"
        color="textTertiary"
        style={{textAlign: 'center'}}>
        У вас нет сертификатов.
        <br />
        Создайте сертификат для своих клиентов
      </Text>
      <Button
        size="large"
        typography="text16Semibold"
        style={{marginTop: 12, padding: '0 46.5px'}}
        onClick={() => navigate(`/${username}/create-certificate`)}>
        Добавить программу
      </Button>
    </Flex>
  );
};
