import React, {useState} from 'react';
import styled from 'styled-components';
import {Text} from 'shared/ui/Text';
import {Trash} from 'shared/icons/Trash';
import {useColors} from 'shared/lib/hooks';
import dayjs from 'dayjs';
import {Certificate as CertificateType} from 'shared/__generated__/graphql';
import {HistoryPopup} from './HistoryPopup';
import {DeletePopup} from './DeletePopup';
import {ApolloError, useMutation} from '@apollo/client';
import {gql} from 'shared/__generated__';
import {showAlert} from 'shared/ui/Alert';
import {History} from 'shared/icons/History';

const DELETE_CERTIFICATE = gql(`
  mutation DeleteCertificateCustomerCertificate($deleteCertificateId: String!) {
    deleteCertificate(id: $deleteCertificateId) {
      id
    }
  }
`);

const Certif = styled.div`
  display: flex;
  flex-direction: column;
  width: '100%';
  gap: 24px;
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Button = styled.button`
  display: flex;
  width: 40px;
  height: 40px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 14px;
  background-color: ${({theme}) => theme.bgPrimary};
  border: 1px solid ${({theme}) => theme.borderPrimary};
  pointer-events: all;
  cursor: pointer;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Certificate = ({
  certificate,
  editPermission,
}: {
  certificate: Pick<
    CertificateType,
    'id' | 'name' | 'balance' | 'created_at' | 'valid_until'
  >;
  editPermission: boolean;
}) => {
  const color = useColors();
  const [deleteModal, setDeleteModal] = useState(false);
  const [historyModal, setHistoryModal] = useState(false);
  const [deleteCertificate, {loading: deleteLoading}] = useMutation(
    DELETE_CERTIFICATE,
    {refetchQueries: ['CustomerCertificates']},
  );

  return (
    <Certif>
      <DeletePopup
        loading={deleteLoading}
        visible={deleteModal}
        onClose={() => setDeleteModal(false)}
        onClickDelete={() => {
          deleteCertificate({variables: {deleteCertificateId: certificate.id}})
            .then(() => setDeleteModal(false))
            .catch((e: ApolloError) => showAlert('error', e.message));
        }}
      />
      <HistoryPopup
        id={certificate.id}
        visible={historyModal}
        onClose={() => setHistoryModal(false)}
      />
      <Content>
        <Text typography="title20">{certificate?.name}</Text>
        <div style={{display: 'flex', flexDirection: 'row', gap: 8}}>
          <Button onClick={() => setHistoryModal(true)}>
            <History />
          </Button>
          {editPermission && (
            <Button onClick={() => setDeleteModal(true)}>
              <Trash color={color.dangerPrimary} />
            </Button>
          )}
        </div>
      </Content>
      <Content>
        <Box>
          <Text color="textTertiary" typography="subHead14Regular">
            Номер сертификата
          </Text>
          <Text
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              maxWidth: 210,
            }}
            typography="text16Semibold">
            {certificate.id}
          </Text>
        </Box>
        <Box>
          <Text color="textTertiary" typography="subHead14Regular">
            Баланс
          </Text>
          <Text typography="text16Semibold">{certificate.balance} ₽</Text>
        </Box>
        <Box>
          <Text color="textTertiary" typography="subHead14Regular">
            Дата привязки
          </Text>
          <Text typography="text16Semibold">
            {dayjs(certificate.created_at).format('DD.MM.YYYY')}
          </Text>
        </Box>
        <Box>
          <Text color="textTertiary" typography="subHead14Regular">
            Действует до
          </Text>
          <Text typography="text16Semibold">
            {certificate.valid_until
              ? dayjs(certificate.valid_until).format('DD.MM.YYYY')
              : 'Бессрочно'}
          </Text>
        </Box>
      </Content>
    </Certif>
  );
};
