import React from 'react';
import {PHONE_MASK} from 'shared/lib/utils';
import {useColors} from 'shared/lib/hooks';
import styled from 'styled-components';
import {TextInput} from 'shared/ui/TextInput';
import {Text} from 'shared/ui/Text';
import {CircleClose} from 'shared/icons/CircleClose';
import {CirclePlus} from 'shared/icons/CirclePlus';
import {Whatsapp} from 'shared/icons/Whatsapp';
import {Grow} from 'shared/ui/Grow';
import {Viber} from 'shared/icons/Viber';
import {Phone} from 'shared/icons/Phone';
import {Telegram} from 'shared/icons/Telegram';
import {Globus} from 'shared/icons/Globus';
import {ContactType} from 'shared/__generated__/graphql';
import {MailBold} from 'shared/icons/MailBold';

interface Props {
  type: ContactType;
  initialData: string[];
  error?: boolean;
  setInitialDate: (data: string[]) => void;
  setError?: (value: ContactType | null) => void;
}

interface IContactFields {
  icon: React.ReactNode;
  label: string;
  inputFirstLabel: string;
  inputLabel: string;
  mask?: string;
  placeholder?: string;
  type: React.HTMLInputTypeAttribute;
}

const contactGroups: Record<ContactType, IContactFields> = {
  phone: {
    icon: <Phone type="bold" />,
    label: 'Телефон',
    inputFirstLabel: 'Телефон',
    inputLabel: 'Дополнительный телефон',
    type: 'text',
    placeholder: 'Номер телефона',
    mask: PHONE_MASK,
  },
  whatsapp: {
    icon: <Whatsapp />,
    label: 'Whatsapp',
    inputFirstLabel: 'Whatsapp',
    inputLabel: 'Whatsapp',
    type: 'tel',
    mask: PHONE_MASK,
  },
  telegram: {
    icon: <Telegram />,
    label: 'Telegram',
    inputFirstLabel: 'Telegram',
    inputLabel: 'Telegram',
    type: 'text',
    placeholder: 'Номер телефона или никнейм',
  },
  viber: {
    icon: <Viber />,
    label: 'Viber',
    inputFirstLabel: 'Viber',
    inputLabel: 'Viber',
    type: 'tel',
    mask: PHONE_MASK,
  },
  email: {
    icon: <MailBold />,
    label: 'Электронная почта',
    inputFirstLabel: 'Email',
    inputLabel: 'Email',
    type: 'text',
    placeholder: 'mycompany@domain.com',
  },
  website: {
    icon: <Globus />,
    label: 'Сайт',
    inputFirstLabel: 'Сайт',
    inputLabel: 'Сайт',
    type: 'text',
    placeholder: 'https://mycompany.com',
  },
};

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 16px;
`;

const Row = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

const Icon = styled.div`
  display: flex;
  height: 40px;
  width: 40px;
  border-radius: 20;
  justify-content: 'center';
  align-items: 'center';
  background-color: ${({color}) => color};
  border-radius: 100px;
  justify-content: center;
  align-items: center;
`;

const CloseButton = styled.div`
  display: flex;
  padding: 10px;
  border-radius: 100px;
  align-items: center;
  justify-content: center;
  pointer-events: all;
  cursor: pointer;
  margin-top: 10px;
  align-self: flex-start;
`;

export const ContactInput = styled(TextInput)`
  display: flex;
  width: auto;
  margin-right: 8px;
`;

export const Contact = ({
  type,
  initialData,
  error,
  setInitialDate,
  setError,
}: Props) => {
  const colors = useColors();
  const iconColors: Record<ContactType, string> = {
    phone: colors.mainLight,
    whatsapp: colors.bgSuccess,
    telegram: colors.bgWaiting,
    viber: colors.mainLight,
    email: colors.mainLight,
    website: colors.mainLight,
  };

  const handleAdd = () => {
    setInitialDate([...initialData, '']);
  };
  const handleChange = (index: number, value: string) => {
    const newData = [...initialData];
    newData[index] = value;
    setInitialDate(newData);
    setError && setError(null);
  };
  const handleDelete = (index: number) => {
    const newData = [...initialData];
    newData.splice(index, 1);
    setInitialDate(newData);
    setError && setError(null);
  };
  return (
    <Container>
      <Row>
        <Icon style={{marginRight: 16}} color={iconColors[type]}>
          {contactGroups[type].icon}
        </Icon>

        <Text style={{flex: 1}} typography="subHead14Medium">
          {contactGroups[type].label}
        </Text>

        {initialData.length < 5 && (
          <Icon
            style={{pointerEvents: 'all', cursor: 'pointer'}}
            onClick={handleAdd}
            color={colors.fillPrimary}>
            <CirclePlus />
          </Icon>
        )}
      </Row>
      {initialData.map((item, index) => (
        <Row style={{gap: 8}} key={index}>
          <Grow>
            <TextInput
              label={
                index === 0
                  ? contactGroups[type].inputFirstLabel
                  : contactGroups[type].inputLabel
              }
              value={item}
              error={error && !item ? 'Поле не должно быть пустым' : undefined}
              mask={contactGroups[type].mask}
              placeholder={contactGroups[type].placeholder}
              type={contactGroups[type].type}
              onChange={text => handleChange(index, text)}
            />
          </Grow>
          <CloseButton onClick={() => handleDelete(index)}>
            <CircleClose color={colors.dangerPrimary} />
          </CloseButton>
        </Row>
      ))}
    </Container>
  );
};
