import React, {useState} from 'react';
import styled from 'styled-components';
import {Content} from 'shared/ui/Content';
import {Button} from 'shared/ui/Button';
import {useParams} from 'react-router-dom';
import {gql} from 'shared/__generated__';
import {device} from 'shared/device';
import {NetworkStatus, useQuery} from '@apollo/client';
import {BonusCard} from './ui/BonusCard';
import {Divider} from 'shared/ui/Divider';
import {CustomerBonusesSkeleton} from './ui/Skeleton';
import {EmptyState} from './ui/EmptyState';
import {AddBonusPopup} from './ui/AddBonusPopup';
import {useAppSelector} from 'shared/store';
import {useEmployeePermission} from 'shared/lib/hooks';
import {Seo} from 'shared/ui/Seo';

const CUSTOMER_BONUSES = gql(`
query CustomersBonus($first: Int!, $customerId: String!, $page: Int!, $companyId: String!) {
  customer(id: $customerId) {
    id
    bonusCards(first: $first, page: $page) {
      data {
        id
        name
        is_rechargeable
        bonusCardTemplate {
          id
          active_months
        }
        bonusSizes {
          id
          min_amount
          size
        }
        valid_until
        created_at
        balance
      }
      paginatorInfo {
        hasMorePages
        lastPage
        currentPage
        total
      }
    }
  }
  ...Permissions
}
`);

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 32px;
  padding-top: 8px;
  padding-bottom: 24px;
  @media (${device.mobile}) {
    gap: 8px;
    margin-bottom: 0;
    border-radius: 0;
  }
`;

export const CustomerBonuses = () => {
  const params = useParams();
  const customerId = params.id!;
  const companyId = useAppSelector(state => state.company.data!.id);
  const [modal, setModal] = useState(false);
  const {data, networkStatus} = useQuery(CUSTOMER_BONUSES, {
    variables: {
      customerId,
      first: 40,
      page: 1,
      companyId,
    },
  });
  const {
    add_to_customers_bonus_card_permission,
    edit_bonus_card_permission,
    fill_bonus_card_permission,
  } = useEmployeePermission(data);

  const bonuses = data?.customer?.bonusCards?.data;
  const loading = networkStatus === NetworkStatus.loading;

  if (!bonuses?.length && !loading) {
    return <EmptyState customerId={customerId} />;
  }

  return (
    <Wrapper>
      <Seo title="Бонусные карты клиента" />
      <AddBonusPopup
        customerId={customerId}
        visible={modal}
        onClose={() => {
          setModal(false);
        }}
      />
      {!loading ? (
        <>
          <Content gap="32px">
            {bonuses?.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  {index !== 0 && <Divider />}
                  <BonusCard
                    bonus={item}
                    fillPermission={fill_bonus_card_permission}
                    editPermission={edit_bonus_card_permission}
                  />
                </React.Fragment>
              );
            })}
          </Content>
          {add_to_customers_bonus_card_permission && (
            <Button
              size="large"
              loading={loading}
              onClick={() => {
                setModal(true);
              }}>
              Привязать бонусную карту
            </Button>
          )}
        </>
      ) : (
        <CustomerBonusesSkeleton />
      )}
    </Wrapper>
  );
};
