import React, {useState} from 'react';
import {styled} from 'styled-components';
import {OptionsType, Table, TableSortType} from 'shared/ui/Table';
import {gql} from 'shared/__generated__';
import {useAppSelector} from 'shared/store';
import {useQuery} from '@apollo/client';
import {
  QueryGetStatisticsBonusCardUsesOrderByColumn,
  SortOrder,
  StatisticsDates,
} from 'shared/__generated__/graphql';
import {Flex} from 'shared/ui/Flex';
import {
  FilterEmptyState,
  FilterResetButton,
  FilterSearch,
  FilterStatisticsBonusCard,
  FilterStatisticsCustomer,
  FilterStatisticsPeriod,
} from 'entities/statistics';
import dayjs from 'dayjs';
import {getNumberFormat, getPriceFormat} from 'shared/lib/utils';
import {Content} from 'shared/ui/Content';
import {PageHeader} from 'shared/ui/PageHeader';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
`;

const GET_STATISTICS_BONUS_CARD_USES = gql(`
  query GetStatisticsBonusCardUses(
    $companyId: String!
    $date: StatisticsDates!
    $first: Int!
    $query: String
    $customerId: String
    $bonusCardId: String
    $page: Int
    $orderBy: [QueryGetStatisticsBonusCardUsesOrderByOrderByClause!]
  ) {
    getStatisticsBonusCardUses(
      company_id: $companyId
      date: $date
      first: $first
      query: $query
      customer_id: $customerId
      bonus_card_id: $bonusCardId
      page: $page
      orderBy: $orderBy
    ) {
      paginatorInfo {
        hasMorePages
        currentPage
        total
        count
        lastPage
      }
      data {
        bonus_left
        bonus_summary
        bonus_used
        booking_number
        created_at
        customer
        name
        price
      }
    }
  }
`);
const titles = [
  'Название',
  'Дата создания',
  'Клиент',
  'Номер записи',
  'Стоимость записи',
  'Кол-во бонусов',
  'Использовано',
  'Остаток',
];
const sortValues: QueryGetStatisticsBonusCardUsesOrderByColumn[] = [
  QueryGetStatisticsBonusCardUsesOrderByColumn.Name,
  QueryGetStatisticsBonusCardUsesOrderByColumn.CreatedAt,
  QueryGetStatisticsBonusCardUsesOrderByColumn.Customer,
  QueryGetStatisticsBonusCardUsesOrderByColumn.BookingNumber,
  QueryGetStatisticsBonusCardUsesOrderByColumn.Price,
  QueryGetStatisticsBonusCardUsesOrderByColumn.BonusSummary,
  QueryGetStatisticsBonusCardUsesOrderByColumn.BonusUsed,
  QueryGetStatisticsBonusCardUsesOrderByColumn.BonusLeft,
];

export const LoyaltyUsesBonusCards = () => {
  const companyId = useAppSelector(state => state.company.data!.id);
  const [sort, setSort] = useState<TableSortType>({
    index: 0,
    order: SortOrder.Desc,
  });
  const [search, setSearch] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [bonusCardId, setBonusCardId] = useState('');
  const [options, setOptions] = useState<OptionsType>({
    page: 1,
    first: 10,
    total: 0,
    count: 0,
    lastPage: 1,
  });
  const [period, setPeriod] = useState<StatisticsDates>(
    StatisticsDates.Alltime,
  );
  const {data, loading} = useQuery(GET_STATISTICS_BONUS_CARD_USES, {
    variables: {
      companyId,
      customerId,
      bonusCardId,
      date: period,
      first: options.first,
      page: options.page,
      query: search,
      orderBy: [
        {
          order: sort.order,
          column: sortValues[sort.index],
        },
      ],
    },
    onCompleted: res =>
      setOptions(prev => ({
        ...prev,
        total: res.getStatisticsBonusCardUses?.paginatorInfo.total,
        count: res.getStatisticsBonusCardUses?.paginatorInfo.count,
        lastPage: res.getStatisticsBonusCardUses?.paginatorInfo.lastPage,
      })),
  });
  const statistics = data?.getStatisticsBonusCardUses?.data ?? [];
  const isEmpty = !statistics?.filter(item => item.name !== 'Итого').length;
  return (
    <Wrapper>
      <PageHeader back title="Статистика по погашению бонусов" />
      <Flex direction="row" gap={8}>
        <FilterSearch onChange={setSearch} value={search} />
        <FilterStatisticsPeriod {...{period, setPeriod}} />
        <FilterStatisticsBonusCard {...{bonusCardId, setBonusCardId}} />
        <FilterStatisticsCustomer {...{customerId, setCustomerId}} />
        {bonusCardId ||
        customerId ||
        search ||
        period !== StatisticsDates.Alltime ? (
          <FilterResetButton
            onClick={() => {
              setBonusCardId('');
              setCustomerId('');
              setSearch('');
              setPeriod(StatisticsDates.Alltime);
            }}
          />
        ) : (
          <></>
        )}
      </Flex>
      {!loading && isEmpty ? (
        <FilterEmptyState />
      ) : (
        <Content>
          <Table
            options={options}
            setOptions={setOptions}
            titles={titles}
            sort={sort}
            results
            alignRightColumn={[3, 4, 5, 6, 7]}
            columnNoWrap={[4]}
            onClickTitle={(index, order) => {
              const sortable = titles.find(
                (_, indexTitle) => indexTitle === index,
              );
              sortable && setSort({index, order});
            }}
            rows={statistics.map((item, index) => [
              item.name,
              dayjs(item.created_at).isValid()
                ? dayjs(item.created_at).format('DD.MM.YYYY')
                : item.created_at,
              item.customer,
              (index === 0 ? '' : '#') + item.booking_number,
              getPriceFormat(item.price),
              getNumberFormat(item.bonus_summary),
              getNumberFormat(item.bonus_used),
              getNumberFormat(item.bonus_left),
            ])}
          />
        </Content>
      )}
    </Wrapper>
  );
};
