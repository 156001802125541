import React, {useState} from 'react';
import {Layout} from 'shared/ui/Layout';
import styled from 'styled-components';
import {PageHeader} from 'shared/ui/PageHeader';
import {Flex} from 'shared/ui/Flex';
import {
  FilterStatisticsEmployee,
  FilterStatisticsPeriod,
} from 'entities/statistics';
import {AnalyiticCard} from './ui/AnalyticCard';
import {Text} from 'shared/ui/Text';
import {getNumberFormat, getPriceFormat} from 'shared/lib/utils';
import {LineChart} from './ui/LineChart';
import {BarChart} from './ui/BarChart';
import {gql} from 'shared/__generated__';
import {useQuery} from '@apollo/client';
import {useAppSelector} from 'shared/store';
import {StatisticsDates} from 'shared/__generated__/graphql';
import {StatisticsTimeline} from 'shared/__generated__/graphql';

const GET_STATISTICS = gql(`
  query GetStatistics(
    $companyId: String!
    $date: StatisticsDates!
    $employeeId: String
  ) {
    getStatistics(
      company_id: $companyId
      date: $date
      employee_id: $employeeId
    ) {
      sales {
        count
        total
      }
      occupancy {
        bookedHours
        notBookedHours
        occupancy
        schedule
      }
      averageSales {
        averageSales
        countSales
      }
      bookings {
        bookings
        cancelled
        completed
      }
    }
  }
`);

const LINE_STATISTICS = gql(`
query GetStatisticsLine(
  $companyId: String!
  $timeline: StatisticsTimeline
  $date: StatisticsDates!
) {
  getStatistics(
    company_id: $companyId
    date: $date
  ) {
    salesGraph(timeline: $timeline) {
      date
      sales
    }
  }
}
`);
const BAR_STATISTICS = gql(`
query GetStatisticsBar(
  $companyId: String!
  $timebar: StatisticsTimeline
  $date: StatisticsDates!
) {
  getStatistics(
    company_id: $companyId
    date: $date
  ) {
    visits(timeline: $timebar) {
      date
      statistics {
        bookings
        cancelled
        completed
      }
    }
  }
}
`);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-column-start: 1;
  grid-column-end: 13;
  padding: 24px;
  gap: 24px;
`;
const TextCard = styled(Text)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`;
const time = [
  StatisticsTimeline.Day,
  StatisticsTimeline.Week,
  StatisticsTimeline.Month,
];

export const Analytics = () => {
  const companyId = useAppSelector(state => state.company.data!.id);
  const [employeeId, setEmployeeId] = useState('');
  const [period, setPeriod] = useState<StatisticsDates>(
    StatisticsDates.Alltime,
  );
  const [lineValue, setLineValue] = useState(0);
  const [barValue, setBarValue] = useState(0);
  const {data} = useQuery(GET_STATISTICS, {
    variables: {
      companyId,
      timeline: time[lineValue],
      timebar: time[barValue],
      date: period,
      employeeId,
    },
  });
  const {data: lineData} = useQuery(LINE_STATISTICS, {
    variables: {
      companyId,
      timeline: time[lineValue],
      date: StatisticsDates.Alltime,
    },
  });
  const {data: barData} = useQuery(BAR_STATISTICS, {
    variables: {
      companyId,
      timebar: time[barValue],
      date: StatisticsDates.Alltime,
    },
  });
  const sales = data?.getStatistics?.sales;
  const averageSales = data?.getStatistics?.averageSales;
  const bookings = data?.getStatistics?.bookings;
  const occupancy = data?.getStatistics?.occupancy;

  return (
    <Layout variant="sidebar">
      <Wrapper>
        <PageHeader title="Основные показатели" />
        <Flex gap={8}>
          <FilterStatisticsPeriod {...{period, setPeriod}} />
          <FilterStatisticsEmployee {...{employeeId, setEmployeeId}} />
        </Flex>

        <Flex gap={24} style={{flexWrap: 'wrap'}}>
          <AnalyiticCard
            title="Общая сумма продаж"
            value={getPriceFormat(sales?.total)}>
            <TextCard color="textTertiary">
              Кол-во продаж: &nbsp;<Text>{sales?.count ?? 0}</Text>
            </TextCard>
          </AnalyiticCard>
          <AnalyiticCard
            title="Средний чек"
            value={getPriceFormat(averageSales?.averageSales)}>
            <TextCard color="textTertiary">
              Кол-во продаж: &nbsp;<Text>{averageSales?.countSales ?? 0}</Text>
            </TextCard>
          </AnalyiticCard>
          <AnalyiticCard
            title="Общее кол-во записей"
            value={getNumberFormat(bookings?.bookings, '')}
            percent={0}>
            <TextCard color="textTertiary">
              Выполнено: &nbsp;<Text>{bookings?.completed ?? 0}</Text>
            </TextCard>
            <TextCard color="textTertiary">
              Отменено: &nbsp;<Text>{bookings?.cancelled ?? 0}</Text>
            </TextCard>
          </AnalyiticCard>
          <AnalyiticCard
            title="Заполняемость"
            value={getNumberFormat(occupancy?.occupancy, ' %')}>
            <TextCard color="textTertiary">
              График работ: &nbsp;<Text>{occupancy?.schedule ?? 0}ч</Text>
            </TextCard>
            <TextCard color="textTertiary">
              Заполненные: &nbsp;<Text>{occupancy?.bookedHours ?? 0}ч</Text>
            </TextCard>
            <TextCard color="textTertiary">
              Свободные: &nbsp;<Text>{occupancy?.notBookedHours ?? 0}ч</Text>
            </TextCard>
          </AnalyiticCard>
        </Flex>
        <Flex flex={1} gap={24}>
          <LineChart
            segmentValue={lineValue}
            setSegmentValue={setLineValue}
            data={lineData}
          />
          <BarChart
            segmentValue={barValue}
            setSegmentValue={setBarValue}
            data={barData}
          />
        </Flex>
      </Wrapper>
    </Layout>
  );
};
