import React from 'react';
import {getPriceFormat} from 'shared/lib/utils';
import {Flex} from 'shared/ui/Flex';
import {Text} from 'shared/ui/Text';

export const BookingPaymentInfo = ({
  name,
  total = 0,
}: {
  name: string;
  total: number;
}) => {
  return (
    <Flex direction="column" gap={8}>
      <Flex direction="row" justifyContent="space-between">
        <Text typography="text16Regular" color="textTertiary">
          Клиент
        </Text>
        <Text typography="text16Regular" color="textTertiary">
          Сумма к оплате
        </Text>
      </Flex>
      <Flex direction="row" justifyContent="space-between">
        <Text typography="title20">{name}</Text>
        <Text typography="title20" color="mainPrimary">
          {getPriceFormat(total)}
        </Text>
      </Flex>
    </Flex>
  );
};
