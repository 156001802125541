import React, {useState} from 'react';
import {Text} from 'shared/ui/Text';
import {TextInput} from 'shared/ui/TextInput';
import {Button} from 'shared/ui/Button';
import {Flex} from 'shared/ui/Flex';
import {gql} from 'shared/__generated__';
import {useMutation, useQuery} from '@apollo/client';
import {showAlert} from 'shared/ui/Alert';
import {MultiSelectChips} from 'shared/ui/MultiSelectChips';
import {useAppSelector} from 'shared/store';
import {CloseButton} from 'shared/ui/CloseButton';
import {Popup} from 'shared/ui/Popup';
import {ResourceType} from '..';
import {useColors} from 'shared/lib/hooks';
import {ensureError} from 'shared/lib/utils';

const UPDATE_RESOURCES = gql(`
mutation UpdateCompanyResources($input: [UpdateCompanyResources!]!, $companyId: String!) {
    updateCompanyResources(input: $input, company_id: $companyId) {
      id
    }
  }
`);
const DELETE_RESOURCES = gql(`
mutation DeleteCompanyResources($resourceIds: [String!]!, $companyId: String!) {
  deleteCompanyResources(resource_ids: $resourceIds, company_id: $companyId) {
    id
  }
}
`);

const RESOURCE_SERVICES = gql(`
query CompanyResourceServices($first: Int!, $companyId: String) {
    companyServiceSearch(first: $first, company_id: $companyId) {
      data {
        id
        name
      }
    }
  }
`);

export const ResourceEditModal = ({
  onClose,
  visible,
  resource,
}: {
  visible: boolean;
  onClose: () => void;
  resource?: ResourceType | null;
}) => {
  const colors = useColors();
  const companyId = useAppSelector(state => state.company.data?.id);
  const [modal, setModal] = useState(false);
  const [name, setName] = useState<string | undefined>(resource?.name);
  const [count, setCount] = useState<string | undefined>(
    resource?.count.toString(),
  );
  const {data} = useQuery(RESOURCE_SERVICES, {
    variables: {companyId: companyId!, first: 100},
  });
  const services = data?.companyServiceSearch?.data;
  const [resourceServices, setResourceServices] = useState<
    {label: string; value: string}[]
  >(
    resource?.services?.map(item => ({
      label: item.name,
      value: item.id,
    })) ?? [],
  );
  const [resourceUpdate] = useMutation(UPDATE_RESOURCES, {
    refetchQueries: ['CompanyResources', 'CompanyServiceSearchServices'],
  });
  const updateResource = async () => {
    try {
      await resourceUpdate({
        variables: {
          companyId: companyId!,
          input: [
            {
              resource_id: resource!.id,
              name: name,
              count: +count!,
              service_ids: resourceServices.map(item => item.value),
            },
          ],
        },
      });
      onClose();
      showAlert('success', 'Сохранено');
    } catch (e) {
      const error = ensureError(e);
      showAlert('error', error.message);
    }
  };
  const [resourceDelete] = useMutation(DELETE_RESOURCES, {
    refetchQueries: ['CompanyResources'],
  });
  const deleteResource = async () => {
    try {
      await resourceDelete({
        variables: {
          companyId: companyId!,
          resourceIds: [resource!.id],
        },
      });
      onClose();
      showAlert('success', 'Ресурс успешно удален');
    } catch (e) {
      const error = ensureError(e);
      showAlert('error', error.message);
    }
  };
  return (
    <Popup
      style={{alignItems: 'normal', gap: 32, width: 472}}
      visible={visible}
      onClose={() => {
        onClose();
      }}>
      <Flex direction="row" justifyContent="space-between" alignItems="center">
        <Text typography="title20">Редактирование ресурса</Text>
        <CloseButton
          onClose={() => {
            onClose();
          }}
        />
      </Flex>
      <Flex direction="column" gap={16}>
        <TextInput
          variant="label_animation"
          label="Название ресурса"
          value={name}
          onChange={text => {
            setName(text);
          }}
        />
        <TextInput
          variant="label_animation"
          label="Количество единиц ресурса"
          value={count}
          mask="99999"
          onChange={text => {
            setCount(text);
          }}
        />
        <MultiSelectChips
          style={{position: 'relative'}}
          data={services?.map(item => ({label: item.name, value: item.id}))}
          label="Услуги"
          listEmpty={'У вас пока нет услуг. Добавьте услуги и начните работу'}
          values={resourceServices}
          onDelete={id => {
            setResourceServices(
              resourceServices.filter(selected => selected.value !== id),
            );
          }}
          onChange={values => {
            setResourceServices(values);
          }}
        />
      </Flex>
      <Flex gap={8}>
        <Button
          size="large"
          style={{
            flex: 1,
            borderColor: colors.dangerPrimary,
            color: colors.dangerPrimary,
          }}
          variant="outline"
          typography="text16Semibold"
          onClick={() => {
            setModal(true);
          }}>
          Удалить
        </Button>
        <Popup
          backDrop={false}
          visible={modal}
          style={{
            width: 440,
            alignItems: 'initial',
            padding: '24px 16px',
          }}
          onClose={() => {
            setModal(false);
          }}>
          <Flex direction="column" gap={16}>
            <Flex flex={1} justifyContent="space-between" alignItems="center">
              <Text typography="title20" color="textPrimary">
                Удалить ресурс
              </Text>
              <CloseButton
                onClose={() => {
                  setModal(false);
                }}
              />
            </Flex>
            <Text typography="subHead14Regular">
              Вы уверены, что хотите удалить этот ресурс?
            </Text>
            <Flex gap={8}>
              <Button
                size="large"
                variant="outline"
                style={{flex: 1, borderColor: colors.mainPrimary}}
                onClick={() => {
                  setModal(false);
                }}>
                Отмена
              </Button>
              <Button
                size="large"
                variant="outline"
                onClick={deleteResource}
                style={{
                  flex: 1,
                  borderColor: colors.dangerPrimary,
                  color: colors.dangerPrimary,
                }}>
                Удалить
              </Button>
            </Flex>
          </Flex>
        </Popup>
        <Button
          size="large"
          style={{flex: 1}}
          onClick={updateResource}
          typography="text16Semibold">
          Сохранить
        </Button>
      </Flex>
    </Popup>
  );
};
