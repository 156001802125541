import React from 'react';
import {useColors} from 'shared/lib/hooks';

export const Radio = ({
  color = '#6765F8',
  active = false,
}: {
  color?: string;
  active?: boolean;
}) => {
  const colors = useColors();
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <circle
        cx="12"
        cy="12"
        r={active ? '8' : '10'}
        strokeWidth={active ? 7 : 1}
        stroke={active ? color : colors.textTertiary}
        fill={active ? 'white' : 'transparent'}
      />
    </svg>
  );
};
