import React, {useEffect, useState} from 'react';
import {Popup} from 'shared/ui/Popup';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';
import {Grow} from 'shared/ui/Grow';
import {CloseButton} from 'shared/ui/CloseButton';
import {Select} from 'shared/ui/Select';
import {Flex} from 'shared/ui/Flex';
import {Switch} from 'shared/ui/Switch';
import {FilterType} from './Setting';
import {
  CompanyTag,
  CustomerCategory,
  CustomerGender,
} from 'shared/__generated__/graphql';
import {Gender} from 'shared/lib/utils';
import {Button} from 'shared/ui/Button';
import {MultiSelectChips} from 'shared/ui/MultiSelectChips';
import {TableViewRow} from 'shared/ui/TableViewRow';
import {TagCreateModal} from 'entities/tags';
import {useColors} from 'shared/lib/hooks';
import {EmptyTags} from 'shared/illustration/EmptyTags';

const Row = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const FilterModal = ({
  visible,
  onClose,
  filter,
  setFilter,
  tags,
}: React.PropsWithChildren<{
  visible?: boolean;
  onClose?: () => void;
  filter: FilterType;
  setFilter: React.Dispatch<React.SetStateAction<FilterType>>;
  tags: Array<Pick<CompanyTag, 'id' | 'name'>>;
}>) => {
  const [newFilter, setNewFilter] = useState(filter);
  const [tagModal, setTagModal] = useState(false);
  const colors = useColors();

  useEffect(() => {
    setNewFilter(filter);
  }, [filter]);

  return (
    <Popup {...{visible, onClose}}>
      <TagCreateModal visible={tagModal} onClose={() => setTagModal(false)} />
      <Grow style={{gap: 24, width: 540}}>
        <Row>
          <Text typography="title20">Фильтры</Text>
          <CloseButton
            onClose={() => {
              onClose && onClose();
            }}
          />
        </Row>
        <Select
          onChange={value => {
            setNewFilter(prev => ({...prev, customerCategory: value}));
          }}
          label="Сегменты клиентов"
          value={newFilter.customerCategory}
          data={[
            {label: 'Все', value: undefined},
            {label: 'Постоянные', value: CustomerCategory.Regular},
            {label: 'Спящие', value: CustomerCategory.Prospects},
            {label: 'Пропавшие', value: CustomerCategory.Lost},
            {label: 'Не посещали', value: CustomerCategory.Inactive},
            {label: 'Черный список', value: CustomerCategory.Blocked},
          ]}
        />
        <MultiSelectChips
          label="Теги"
          addItem={
            <TableViewRow
              hover
              pointer
              title="+ Создать новый тег"
              style={{width: '100%', padding: '0 16px', marginBottom: 8}}
              styleTitle={{color: colors.mainPrimary}}
              onClick={() => {
                setTagModal(true);
              }}
            />
          }
          data={tags.map(({id, name}) => ({
            value: id,
            label: name,
          }))}
          values={
            newFilter.tags?.map(({id, name}) => ({
              value: id,
              label: name,
            })) ?? []
          }
          onChange={values =>
            setNewFilter(prev => ({
              ...prev,
              tags: values.map(item => ({id: item.value, name: item.label})),
            }))
          }
          onDelete={value => {
            setNewFilter(prev => ({
              ...prev,
              tags: prev.tags?.filter(item => item.id !== value),
            }));
          }}
          ListEmptyComponent={
            <Flex flex={1} alignItems="center" direction="column" gap={16}>
              <EmptyTags size={200} mt={8} />
              <Text typography="title20">У вас пока нет тегов</Text>
              <Text color="textTertiary" style={{width: 343}} align="center">
                Предлагаем это исправить. Начните добавлять теги для клиентов
              </Text>
            </Flex>
          }
        />
        <Select
          label="Пол"
          value={newFilter.gender}
          onChange={value => {
            setNewFilter(prev => ({...prev, gender: value}));
          }}
          data={[
            {value: undefined, label: 'Не выбрано'},
            {value: CustomerGender.M, label: Gender.M},
            {value: CustomerGender.F, label: Gender.F},
          ]}
        />
        <Flex
          style={{height: 48}}
          alignItems="center"
          justifyContent="space-between">
          <Text>Дни рождения</Text>
          <Switch
            value={newFilter.birthdate}
            onChange={value =>
              setNewFilter(prev => ({
                ...prev,
                birthdate: value ? value : undefined,
              }))
            }
          />
        </Flex>
        <Flex gap={16}>
          <Button
            style={{flex: 1}}
            size="large"
            variant="outline"
            onClick={() => {
              setNewFilter(filter);
              onClose && onClose();
            }}>
            Отмена
          </Button>
          <Button
            style={{flex: 1}}
            size="large"
            onClick={() => {
              setFilter(newFilter);
              onClose && onClose();
            }}>
            Применить
          </Button>
        </Flex>
      </Grow>
    </Popup>
  );
};
