import styled from 'styled-components';
import {device} from 'shared/device';

export const Content = styled.div<{padding?: string; gap?: string}>`
  padding: ${props => props.padding ?? '24px'};
  background-color: ${props => props.theme.bgPrimary};
  border-radius: 24px;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.borderPrimary};
  gap: ${props => props.gap ?? '16px'};
  display: flex;
  flex-direction: column;
  @media (${device.mobile}) {
    padding: ${props => props.padding ?? '16px'};
  }
`;
