import React from 'react';
import {Content} from 'shared/ui/Content';
import {Text} from 'shared/ui/Text';
import {TextInput} from 'shared/ui/TextInput';
import {Button} from 'shared/ui/Button';
import {setPassword} from 'shared/lib/api';
import {ensureError} from 'shared/lib/utils';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import {device} from 'shared/device';
import {showAlert} from 'shared/ui/Alert';
import {Seo} from 'shared/ui/Seo';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  padding-bottom: 24px;
  flex-direction: column;
  margin-top: 8px;
  gap: 32px;
  @media (${device.mobile}) {
    gap: 8px;
    margin-bottom: 0;
    border-radius: 0;
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export function PasswordSet() {
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = React.useState('');
  const [repPassword, setRepPassword] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);
  const passSet = async () => {
    if (newPassword === repPassword) {
      setLoading(true);
      try {
        await setPassword(newPassword);
        navigate('/password-change', {replace: true});
        showAlert('success', 'Пароль успешно установлен');
      } catch (e) {
        const error = ensureError(e);
        showAlert('error', error.message);
      } finally {
        setLoading(false);
      }
    } else {
      setError('Пароли не совподают');
    }
  };
  return (
    <Wrapper>
      <Seo title="Новый пароль" />
      <Content>
        <TextInput
          wrapperStyle={{gap: 0}}
          label="Новый пароль"
          type="password"
          value={newPassword}
          onChange={text => {
            setNewPassword(text);
            setError(null);
          }}
          error={error ? ' ' : null}
        />
        <TextInput
          type="password"
          label="Повторите пароль"
          value={repPassword}
          onChange={text => {
            setRepPassword(text);
            setError(null);
          }}
          error={error}
        />
        <Text typography="footNote12Medium" color="textSecondary">
          Минимальная длина пароля — 8 символов
        </Text>
      </Content>
      <Button
        size="large"
        disabled={newPassword.length < 8 || repPassword.length < 8 || loading}
        loading={loading}
        onClick={passSet}>
        Сохранить
      </Button>
    </Wrapper>
  );
}
