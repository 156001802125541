import React, {useState} from 'react';
import {Content} from 'shared/ui/Content';
import {gql} from 'shared/__generated__';
import {useMutation, useQuery} from '@apollo/client';
import {useAppSelector} from 'shared/store';
import {List} from 'shared/ui/List';
import {RenderItem} from './ui/RenderItem';
import {Divider} from 'shared/ui/Divider';
import {getShortName} from 'shared/lib/utils';
import dayjs from 'dayjs';
import {EmptyNotification} from 'shared/illustration/EmptyNotification';
import {Text} from 'shared/ui/Text';
import {Error} from 'shared/ui/Error';
import {NotificationSk} from 'entities/notification';
import {PaginInfo} from 'shared/ui/Table';
import {useNavigate} from 'react-router-dom';

type OptionsType = {
  page: number;
  first: number;
  total?: number;
  count?: number;
};

const UPDATE_VIEWED_NOTIFICATIONS = gql(`
  mutation UpdateMeViewed($input: UpdateMe!) {
    updateMe(input: $input) {
      unviewed_notifications
      id
    }
  }
`);

const NOTIFICATION_BOOKING_QUERY = gql(`
  query CompanyBookingEvents($companyId: String!, $first: Int!, $page: Int) {
    companyBookingEvents(company_id: $companyId, first: $first, page: $page) {
      data {
        id
        type
        time
        employee {
          id
          name
          surname
        }
        date
        created_at
        booking {
          id
          bookingServices {
            id
            service {
              id
              name
            }
          }
          start
          end
        }
      }
      paginatorInfo {
        total
        currentPage
        lastPage
        count
      }
    }
  }
`);

export const NotificationBooking = () => {
  const companyId = useAppSelector(state => state.company.data!.id);
  const navigate = useNavigate();
  const userId = useAppSelector(state => state.auth.userId);
  const username = useAppSelector(state => state.company.data?.username);
  const [options, setOptions] = useState<OptionsType>({
    page: 1,
    first: 40,
    total: 0,
    count: 0,
  });
  const [updateMe] = useMutation(UPDATE_VIEWED_NOTIFICATIONS);
  const {loading, data, error} = useQuery(NOTIFICATION_BOOKING_QUERY, {
    variables: {
      companyId,
      first: options.first,
      page: options.page,
    },
    onCompleted: dataRes => {
      setOptions(prev => ({
        ...prev,
        total: dataRes.companyBookingEvents?.paginatorInfo.total,
        count: dataRes.companyBookingEvents?.paginatorInfo.count,
      }));
      updateMe({
        variables: {
          input: {
            id: userId!,
            last_time_viewed_notifications: dayjs().format(
              'YYYY-MM-DD HH:mm:ss',
            ),
          },
        },
      });
    },
  });

  const notifications = data?.companyBookingEvents?.data ?? [];

  if (loading) {
    return <NotificationSk />;
  }

  if (error) {
    return (
      <Content>
        <Error message={error.message} />
      </Content>
    );
  }

  if (notifications.length === 0) {
    return (
      <Content style={{alignItems: 'center'}} gap="16px">
        <EmptyNotification size={300} />
        <Text
          typography="text18"
          color="textTertiary"
          style={{textAlign: 'center'}}>
          Уведомлений пока нет
        </Text>
      </Content>
    );
  }

  return (
    <Content>
      <List
        data={[...notifications].sort(function (a, b) {
          if (dayjs(a.created_at).isBefore(b.created_at)) {
            return 1;
          } else {
            return -1;
          }
        })}
        gap={32}
        keyExtractor={item => item.id}
        renderItem={item => (
          <RenderItem
            key={item.id}
            onClick={() => {
              navigate(`/${username}/booking/${item.booking.id}`);
            }}
            status={item.type}
            created={item.created_at}
            employee={getShortName(
              item.employee.name,
              item.employee.surname,
              'employee',
            )}
            date={dayjs(item.date).format(
              'DD MMMM,' + ' ' + item.booking.start + '-' + item.booking.end,
            )}
            services={item.booking.bookingServices
              .reduce((acc, cur) => acc + cur.service.name + ', ', '')
              .slice(0, -2)}
          />
        )}
        divider={<Divider mb={32} />}
      />
      <PaginInfo
        first={options.first}
        page={options.page}
        total={options.total}
        count={options.count}
        setOptions={setOptions}
      />
    </Content>
  );
};
