import React, {CSSProperties} from 'react';
import {device} from 'shared/device';
import styled from 'styled-components';

export const Wrapper = styled.div<{
  columns: number;
  variant?: 'stack' | 'sidebar';
}>`
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(
    ${props => props.columns},
    ${props => (props.variant === 'stack' ? '100px' : '1fr')}
  );
  gap: 24px;
  height: 100vh;
  overflow-y: scroll;
  background-color: ${({theme}) => theme.bgSecondary};
  @media ${device.mobile} {
    display: block;
  }
`;

type Props = React.PropsWithChildren<{
  columns?: number;
  variant?: 'sidebar' | 'stack';
  style?: CSSProperties;
  onScroll?: (e: React.UIEvent<HTMLElement>) => void;
}>;

export const Layout = (props: Props) => {
  const {children, variant = 'stack', style, columns = 12} = props;
  return (
    <Wrapper columns={columns} variant={variant} style={style}>
      {children}
    </Wrapper>
  );
};

export const LayoutItemWrapper = styled.div<{
  start?: number;
  end?: number;
}>`
  grid-column-start: ${props => props.start};
  grid-column-end: ${props => props.end};
  @media ${device.mobile} {
    grid-column-start: none;
    grid-column-end: none;
    display: block;
    align-items: flex-start;
    justify-content: flex-start;
  }
`;
