import React from 'react';

export const StarStroke = ({
  fillColor = 'none',
  color = '#6765F8',
  size = 24,
}: {
  fillColor?: string;
  color?: string;
  size?: number;
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={fillColor}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.15316 5.23796C10.4198 3.07932 11.0531 2 12 2C12.9469 2 13.5802 3.07932 14.8468 5.23796L15.1745 5.79642C15.5345 6.40984 15.7144 6.71655 15.9951 6.91892C16.2757 7.12129 16.6251 7.19641 17.3241 7.34665L17.9605 7.48343C20.4201 8.01213 21.65 8.27648 21.9426 9.17034C22.2352 10.0642 21.3968 10.9956 19.7199 12.8584L19.2861 13.3404C18.8096 13.8697 18.5713 14.1344 18.4641 14.4618C18.357 14.7893 18.393 15.1424 18.465 15.8487L18.5306 16.4917C18.7841 18.9771 18.9109 20.2198 18.1449 20.7722C17.3788 21.3246 16.2273 20.821 13.9243 19.8136L13.3285 19.553C12.6741 19.2667 12.3469 19.1236 12 19.1236C11.6531 19.1236 11.3259 19.2667 10.6715 19.553L10.0757 19.8136C7.77268 20.821 6.62118 21.3246 5.85515 20.7722C5.08912 20.2198 5.21588 18.9771 5.4694 16.4917L5.53498 15.8487C5.60703 15.1424 5.64305 14.7893 5.53586 14.4618C5.42868 14.1344 5.19043 13.8697 4.71392 13.3404L4.2801 12.8584C2.60325 10.9956 1.76482 10.0642 2.05742 9.17034C2.35002 8.27648 3.57986 8.01213 6.03954 7.48343L6.67589 7.34665C7.37485 7.19641 7.72433 7.12129 8.00494 6.91892C8.28555 6.71655 8.46553 6.40984 8.82547 5.79642L9.15316 5.23796Z"
        stroke={color}
        strokeWidth="1.5"
      />
    </svg>
  );
};
