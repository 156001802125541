import React, {useState} from 'react';
import styled from 'styled-components';
import {Content} from 'shared/ui/Content';
import {Button} from 'shared/ui/Button';
import {useParams} from 'react-router-dom';
import {gql} from 'shared/__generated__';
import {device} from 'shared/device';
import {NetworkStatus, useQuery} from '@apollo/client';
import {Certificate} from './ui/Certificate';
import {Divider} from 'shared/ui/Divider';
import {EmptyState} from './ui/EmptyState';
import {CustomerCertificatesSkeleton} from './ui/Skeleton';
import {AddCertificatePopup} from './ui/AddCertificatePopup';
import {useAppSelector} from 'shared/store';
import {useEmployeePermission} from 'shared/lib/hooks';
import {Seo} from 'shared/ui/Seo';

const CUSTOMER_CERTIFICATES = gql(`
query CustomerCertificates(
  $companyId: String!
  $first: Int!
  $customerId: String!
  $page: Int!
) {
  customer(id: $customerId) {
    id
    certificates(first: $first, page: $page) {
      data {
        name
        id
        valid_until
        balance
        created_at
      }
      paginatorInfo {
        hasMorePages
        lastPage
        currentPage
        total
      }
    }
  }
  ...Permissions
}
`);

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 32px;
  padding-top: 8px;
  padding-bottom: 24px;
  @media (${device.mobile}) {
    gap: 8px;
    margin-bottom: 0;
    border-radius: 0;
  }
`;

export const CustomerCertificates = () => {
  const params = useParams();
  const customerId = params.id!;
  const companyId = useAppSelector(state => state.company.data!.id);
  const [modal, setModal] = useState(false);
  const {data, networkStatus} = useQuery(CUSTOMER_CERTIFICATES, {
    variables: {
      companyId,
      customerId,
      first: 40,
      page: 1,
    },
  });
  const {add_to_customers_certificate_permission, edit_certificate_permission} =
    useEmployeePermission(data);

  const certificates = data?.customer?.certificates?.data;
  const loading = networkStatus === NetworkStatus.loading;

  if (!certificates?.length && !loading) {
    return <EmptyState customerId={customerId} />;
  }

  return (
    <Wrapper>
      <Seo title="Сертификаты клиента" />
      <AddCertificatePopup
        customerId={customerId}
        visible={modal}
        onClose={() => {
          setModal(false);
        }}
      />
      {!loading ? (
        <>
          <Content gap="32px">
            {certificates?.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  {index !== 0 && <Divider />}
                  <Certificate
                    certificate={item}
                    editPermission={edit_certificate_permission}
                  />
                </React.Fragment>
              );
            })}
          </Content>
          {add_to_customers_certificate_permission && (
            <Button
              size="large"
              loading={loading}
              onClick={() => {
                setModal(true);
              }}>
              Продать сертификат
            </Button>
          )}
        </>
      ) : (
        <CustomerCertificatesSkeleton />
      )}
    </Wrapper>
  );
};
