import React from 'react';
import {useColorScheme} from 'shared/lib/hooks';
import {EmptyContainer} from 'shared/ui/EmptyContainer';

export const EmptySendEmail = ({size = 400}: {size?: number}) => {
  const colorScheme = useColorScheme();
  return (
    <EmptyContainer size={size}>
      {colorScheme === 'light' ? (
        <svg width={size} height={size} viewBox="0 0 400 400" fill="none">
          <path
            d="M181.107 314.487C242.307 314.487 291.923 264.872 291.923 203.453C291.923 142.035 242.307 92.4194 181.107 92.4194C119.908 92.4194 70.292 142.035 70.292 203.453C70.292 264.872 119.908 314.487 181.107 314.487Z"
            fill="#EAEEF9"
          />
          <g filter="url(#filter0_d_18298_2269)">
            <path
              d="M203.592 160.588C203.592 176.848 190.443 189.998 174.041 189.998C173.758 189.998 171.355 189.998 153.68 189.998C141.379 189.998 121.725 189.998 90.3361 189.998H75.3484C55.6947 190.422 40 174.727 40 155.78C40 136.692 55.8361 120.856 75.7726 121.846C92.8812 68.3991 171.213 75.893 177.859 131.178C192.564 133.016 203.592 145.459 203.592 160.588Z"
              fill="url(#paint0_linear_18298_2269)"
            />
          </g>
          <path
            d="M174.041 189.998C190.302 189.998 203.593 176.848 203.593 160.588C203.593 144.328 190.302 131.178 174.041 131.178C157.781 131.178 144.49 144.328 144.49 160.588C144.49 176.848 157.781 189.998 174.041 189.998Z"
            fill="url(#paint1_linear_18298_2269)"
          />
          <path
            d="M125.827 190.28C154.813 190.28 178.425 166.809 178.425 137.823C178.425 108.838 154.813 85.3663 125.827 85.3663C96.8412 85.3663 73.2285 108.838 73.2285 137.823C73.2285 166.809 96.6998 190.28 125.827 190.28Z"
            fill="url(#paint2_linear_18298_2269)"
          />
          <g filter="url(#filter1_d_18298_2269)">
            <path
              d="M360 237.384C360 255.242 345.558 269.684 327.544 269.684C327.234 269.684 324.594 269.684 305.183 269.684C291.672 269.684 270.087 269.684 235.613 269.684H219.152C197.567 270.15 180.33 252.913 180.33 232.104C180.33 211.14 197.722 193.748 219.618 194.835C238.408 136.136 324.439 144.366 331.737 205.084C347.887 207.103 360 220.768 360 237.384Z"
              fill="url(#paint3_linear_18298_2269)"
            />
          </g>
          <path
            d="M327.544 269.685C345.403 269.685 360 255.243 360 237.385C360 219.527 345.403 205.085 327.544 205.085C309.686 205.085 295.089 219.527 295.089 237.385C295.089 255.243 309.686 269.685 327.544 269.685Z"
            fill="url(#paint4_linear_18298_2269)"
          />
          <path
            d="M274.591 269.996C306.425 269.996 332.358 244.218 332.358 212.384C332.358 180.549 306.425 154.771 274.591 154.771C242.757 154.771 216.823 180.549 216.823 212.384C216.823 244.218 242.601 269.996 274.591 269.996Z"
            fill="url(#paint5_linear_18298_2269)"
          />
          <g filter="url(#filter2_d_18298_2269)">
            <path
              d="M178.425 273.784C178.425 283.946 170.206 292.165 159.955 292.165C159.779 292.165 158.276 292.165 147.23 292.165C139.542 292.165 127.258 292.165 107.64 292.165H98.2724C85.9889 292.43 76.1797 282.621 76.1797 270.779C76.1797 258.849 86.0772 248.952 98.5375 249.57C109.23 216.166 158.188 220.85 162.341 255.403C171.532 256.551 178.425 264.328 178.425 273.784Z"
              fill="url(#paint6_linear_18298_2269)"
            />
          </g>
          <path
            d="M159.956 292.165C170.119 292.165 178.425 283.947 178.425 273.784C178.425 263.621 170.119 255.403 159.956 255.403C149.793 255.403 141.486 263.621 141.486 273.784C141.486 283.947 149.793 292.165 159.956 292.165Z"
            fill="url(#paint7_linear_18298_2269)"
          />
          <path
            d="M129.821 292.342C147.937 292.342 162.695 277.672 162.695 259.556C162.695 241.44 147.937 226.771 129.821 226.771C111.705 226.771 96.9473 241.44 96.9473 259.556C96.9473 277.672 111.617 292.342 129.821 292.342Z"
            fill="url(#paint8_linear_18298_2269)"
          />
          <path
            d="M199.05 175.29L184.636 186.909C181.948 188.913 178.438 187.485 177.616 184.051L172.805 150.716"
            fill="#A5B0BE"
          />
          <path
            d="M336.811 91.0377L327.55 99.6075L230.62 189.968C226.203 194.202 219.372 194.022 214.818 190.12L179.995 158.07L173.01 151.575L153.749 133.496C149.623 129.493 151.506 122.235 157.173 121.333L316.49 94.9945L336.811 91.0377Z"
            fill="#CBD4E0"
          />
          <path
            d="M336.811 91.0376L327.55 99.6074L180.32 157.539L172.907 151.145L316.815 94.4628L336.811 91.0376Z"
            fill="#A5B0BE"
          />
          <path
            d="M68.4217 273.021L62.2488 278.276C61.0959 279.185 59.5429 278.601 59.1428 277.107L56.6447 262.573"
            fill="#A5B0BE"
          />
          <path
            d="M127.731 234.523L123.778 238.383L82.4129 279.074C80.5293 280.979 77.5373 280.981 75.498 279.326L59.8788 265.707L56.745 262.946L48.1019 255.259C46.2488 253.555 46.9877 250.356 49.4574 249.895L118.883 236.493L127.731 234.523Z"
            fill="#CBD4E0"
          />
          <path
            d="M127.731 234.523L123.778 238.383L60.0148 265.471L56.6948 262.759L119.019 236.257L127.731 234.523Z"
            fill="#A5B0BE"
          />
          <path
            d="M188.688 295.978L177.385 305.599C175.274 307.264 172.431 306.194 171.698 303.46L167.124 276.847"
            fill="#A5B0BE"
          />
          <path
            d="M297.285 225.488L290.047 232.556L214.306 307.062C210.857 310.55 205.378 310.553 201.645 307.524L173.046 282.587L167.307 277.531L151.482 263.457C148.089 260.337 149.442 254.48 153.964 253.634L281.083 229.096L297.285 225.488Z"
            fill="#CBD4E0"
          />
          <path
            d="M297.285 225.488L290.046 232.555L173.295 282.154L167.216 277.189L281.332 228.663L297.285 225.488Z"
            fill="#A5B0BE"
          />
          <defs>
            <filter
              id="filter0_d_18298_2269"
              x="18"
              y="74.4941"
              width="207.593"
              height="148.512"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_18298_2269"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_18298_2269"
                result="shape"
              />
            </filter>
            <filter
              id="filter1_d_18298_2269"
              x="158.33"
              y="143.911"
              width="223.67"
              height="158.783"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_18298_2269"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_18298_2269"
                result="shape"
              />
            </filter>
            <filter
              id="filter2_d_18298_2269"
              x="54.1797"
              y="215.85"
              width="146.245"
              height="109.32"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_18298_2269"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_18298_2269"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_18298_2269"
              x1="121.796"
              y1="128.682"
              x2="121.743"
              y2="191.133"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="white" />
              <stop offset="0.9964" stopColor="#ECF0F5" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_18298_2269"
              x1="141.346"
              y1="124.846"
              x2="167.542"
              y2="153.498"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#BCCBE1" />
              <stop offset="0.9942" stopColor="white" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_18298_2269"
              x1="68.3032"
              y1="113.752"
              x2="109.592"
              y2="129.91"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#E2E8F0" />
              <stop offset="0.9942" stopColor="white" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_18298_2269"
              x1="270.165"
              y1="202.343"
              x2="270.106"
              y2="270.931"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="white" />
              <stop offset="0.9964" stopColor="#ECF0F5" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_18298_2269"
              x1="291.635"
              y1="198.131"
              x2="320.407"
              y2="229.598"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#BCCBE1" />
              <stop offset="0.9942" stopColor="white" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id="paint5_linear_18298_2269"
              x1="211.414"
              y1="185.947"
              x2="256.76"
              y2="203.692"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#E2E8F0" />
              <stop offset="0.9942" stopColor="white" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id="paint6_linear_18298_2269"
              x1="127.302"
              y1="253.843"
              x2="127.269"
              y2="292.874"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="white" />
              <stop offset="0.9964" stopColor="#ECF0F5" />
            </linearGradient>
            <linearGradient
              id="paint7_linear_18298_2269"
              x1="139.521"
              y1="251.445"
              x2="155.894"
              y2="269.353"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#BCCBE1" />
              <stop offset="0.9942" stopColor="white" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id="paint8_linear_18298_2269"
              x1="93.8689"
              y1="244.512"
              x2="119.674"
              y2="254.61"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#E2E8F0" />
              <stop offset="0.9942" stopColor="white" stopOpacity="0" />
            </linearGradient>
          </defs>
        </svg>
      ) : (
        <svg width={size} height={size} viewBox="0 0 400 400" fill="none">
          <path
            d="M181.108 314.488C242.308 314.488 291.924 264.872 291.924 203.454C291.924 142.035 242.308 92.4192 181.108 92.4192C119.908 92.4192 70.292 142.035 70.292 203.454C70.292 264.872 119.908 314.488 181.108 314.488Z"
            fill="#2A2E37"
          />
          <g filter="url(#filter0_d_18298_2271)">
            <path
              d="M203.593 160.588C203.593 176.848 190.444 189.998 174.042 189.998C173.759 189.998 171.355 189.998 153.681 189.998C141.38 189.998 121.726 189.998 90.3364 189.998H75.3486C55.6948 190.422 40 174.728 40 155.781C40 136.692 55.8362 120.856 75.7728 121.846C92.8815 68.399 171.214 75.8929 177.859 131.178C192.564 133.016 203.593 145.459 203.593 160.588Z"
              fill="#5C6479"
            />
          </g>
          <path
            d="M125.827 190.281C154.813 190.281 178.426 166.809 178.426 137.823C178.426 108.838 154.813 85.3662 125.827 85.3662C96.8414 85.3662 73.2285 108.838 73.2285 137.823C73.2285 166.809 96.7 190.281 125.827 190.281Z"
            fill="url(#paint0_linear_18298_2271)"
          />
          <path
            d="M174.042 189.998C190.302 189.998 203.593 176.849 203.593 160.588C203.593 144.328 190.302 131.178 174.042 131.178C157.781 131.178 144.49 144.328 144.49 160.588C144.49 176.849 157.781 189.998 174.042 189.998Z"
            fill="url(#paint1_linear_18298_2271)"
            fillOpacity="0.37"
          />
          <g filter="url(#filter1_d_18298_2271)">
            <path
              d="M360 237.385C360 255.243 345.558 269.685 327.544 269.685C327.234 269.685 324.594 269.685 305.182 269.685C291.672 269.685 270.087 269.685 235.612 269.685H219.152C197.566 270.151 180.329 252.914 180.329 232.105C180.329 211.141 197.722 193.748 219.617 194.835C238.408 136.136 324.438 144.366 331.737 205.084C347.887 207.103 360 220.769 360 237.385Z"
              fill="#5C6479"
            />
          </g>
          <path
            d="M274.591 269.996C306.426 269.996 332.359 244.218 332.359 212.384C332.359 180.549 306.426 154.771 274.591 154.771C242.757 154.771 216.823 180.549 216.823 212.384C216.823 244.218 242.601 269.996 274.591 269.996Z"
            fill="url(#paint2_linear_18298_2271)"
          />
          <path
            d="M327.544 269.686C345.403 269.686 360 255.244 360 237.386C360 219.527 345.403 205.085 327.544 205.085C309.686 205.085 295.089 219.527 295.089 237.386C295.089 255.244 309.686 269.686 327.544 269.686Z"
            fill="url(#paint3_linear_18298_2271)"
            fillOpacity="0.37"
          />
          <g filter="url(#filter2_d_18298_2271)">
            <path
              d="M178.424 273.785C178.424 283.948 170.206 292.167 159.955 292.167C159.778 292.167 158.276 292.167 147.229 292.167C139.541 292.167 127.257 292.167 107.639 292.167H98.2716C85.9879 292.432 76.1787 282.622 76.1787 270.781C76.1787 258.851 86.0763 248.953 98.5367 249.572C109.23 216.167 158.187 220.851 162.341 255.404C171.531 256.553 178.424 264.33 178.424 273.785Z"
              fill="#5C6479"
            />
          </g>
          <path
            d="M159.957 292.167C170.12 292.167 178.427 283.948 178.427 273.785C178.427 263.623 170.12 255.404 159.957 255.404C149.794 255.404 141.487 263.623 141.487 273.785C141.487 283.948 149.794 292.167 159.957 292.167Z"
            fill="url(#paint4_linear_18298_2271)"
          />
          <path
            d="M129.819 292.343C147.936 292.343 162.694 277.673 162.694 259.557C162.694 241.441 147.936 226.771 129.819 226.771C111.703 226.771 96.9453 241.441 96.9453 259.557C96.9453 277.673 111.615 292.343 129.819 292.343Z"
            fill="url(#paint5_linear_18298_2271)"
          />
          <path
            d="M199.049 175.289L184.634 186.907C181.947 188.912 178.437 187.483 177.615 184.05L172.804 150.715"
            fill="#A5B0BE"
          />
          <path
            d="M336.808 91.0373L327.546 99.6069L230.617 189.966C226.201 194.2 219.37 194.02 214.816 190.119L179.993 158.069L173.008 151.573L153.748 133.495C149.622 129.492 151.505 122.234 157.172 121.332L316.487 94.994L336.808 91.0373Z"
            fill="#CBD4E0"
          />
          <path
            d="M336.808 91.0374L327.546 99.6071L180.319 157.538L172.906 151.144L316.812 94.4625L336.808 91.0374Z"
            fill="#A5B0BE"
          />
          <path
            d="M68.4215 273.018L62.2487 278.273C61.0958 279.182 59.5428 278.598 59.1427 277.104L56.6447 262.57"
            fill="#A5B0BE"
          />
          <path
            d="M127.73 234.52L123.777 238.38L82.4124 279.071C80.5289 280.976 77.5368 280.978 75.4976 279.324L59.8786 265.704L56.7448 262.943L48.1018 255.257C46.2488 253.553 46.9877 250.354 49.4574 249.892L118.882 236.491L127.73 234.52Z"
            fill="#CBD4E0"
          />
          <path
            d="M127.73 234.521L123.777 238.381L60.0147 265.468L56.6947 262.757L119.018 236.255L127.73 234.521Z"
            fill="#A5B0BE"
          />
          <path
            d="M188.686 295.975L177.384 305.596C175.273 307.261 172.429 306.191 171.697 303.457L167.123 276.845"
            fill="#A5B0BE"
          />
          <path
            d="M297.282 225.486L290.044 232.553L214.304 307.059C210.855 310.547 205.377 310.55 201.643 307.521L173.044 282.584L167.306 277.528L151.481 263.454C148.088 260.334 149.441 254.477 153.963 253.632L281.081 229.094L297.282 225.486Z"
            fill="#CBD4E0"
          />
          <path
            d="M297.282 225.486L290.044 232.553L173.293 282.151L167.215 277.187L281.33 228.661L297.282 225.486Z"
            fill="#A5B0BE"
          />
          <defs>
            <filter
              id="filter0_d_18298_2271"
              x="27"
              y="83.4941"
              width="189.593"
              height="130.512"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="6.5" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.22 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_18298_2271"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_18298_2271"
                result="shape"
              />
            </filter>
            <filter
              id="filter1_d_18298_2271"
              x="167.329"
              y="152.911"
              width="205.671"
              height="140.783"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="6.5" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.22 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_18298_2271"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_18298_2271"
                result="shape"
              />
            </filter>
            <filter
              id="filter2_d_18298_2271"
              x="63.1787"
              y="224.852"
              width="128.246"
              height="91.3203"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="6.5" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.22 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_18298_2271"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_18298_2271"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_18298_2271"
              x1="86.0477"
              y1="107.158"
              x2="103.661"
              y2="120.771"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#80889D" />
              <stop offset="0.9942" stopColor="#5C6479" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_18298_2271"
              x1="141.279"
              y1="124.939"
              x2="167.432"
              y2="153.607"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="white" />
              <stop offset="0.9942" stopColor="white" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_18298_2271"
              x1="230.902"
              y1="178.704"
              x2="250.247"
              y2="193.656"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#80889D" />
              <stop offset="0.9942" stopColor="#5C6479" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_18298_2271"
              x1="291.562"
              y1="198.233"
              x2="320.285"
              y2="229.718"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="white" />
              <stop offset="0.9942" stopColor="white" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_18298_2271"
              x1="139.522"
              y1="251.447"
              x2="155.895"
              y2="269.354"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#BCCBE1" />
              <stop offset="0.9942" stopColor="white" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id="paint5_linear_18298_2271"
              x1="104.957"
              y1="240.391"
              x2="115.966"
              y2="248.9"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#80889D" />
              <stop offset="0.9942" stopColor="#5C6479" />
            </linearGradient>
          </defs>
        </svg>
      )}
    </EmptyContainer>
  );
};
