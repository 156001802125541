import React from 'react';
export const CircleClose = ({
  color = '#6765F8',
  fill = 'none',
  size = 24,
  borderWidth = 1.5,
}: {
  color?: string;
  fill?: string;
  size?: number;
  borderWidth?: number;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={fill}>
      <path
        d="M14.5 9.5L12 12M12 12L9.5 14.5M12 12L9.49998 9.49998M12 12L14.5 14.5M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
        stroke={color}
        strokeWidth={borderWidth}
        strokeLinecap="round"
      />
    </svg>
  );
};
