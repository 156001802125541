import React from 'react';
export const ChartBar = ({
  size = 24,
  color = '#6765F8',
}: {
  size?: number;
  color?: string;
}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path
        d="M2 21.25C1.58579 21.25 1.25 21.5858 1.25 22C1.25 22.4142 1.58579 22.75 2 22.75V21.25ZM22 21.25H2V22.75H22V21.25ZM16.5 13.75H19.5V12.25H16.5V13.75ZM20.25 14.5V22H21.75V14.5H20.25ZM15.75 22V14.5H14.25V22H15.75ZM19.5 13.75C19.9142 13.75 20.25 14.0858 20.25 14.5H21.75C21.75 13.2574 20.7426 12.25 19.5 12.25V13.75ZM16.5 12.25C15.2574 12.25 14.25 13.2574 14.25 14.5H15.75C15.75 14.0858 16.0858 13.75 16.5 13.75V12.25ZM14.25 5V22H15.75V5H14.25ZM9.75 22V5H8.25V22H9.75ZM12 2.75C12.7283 2.75 13.1991 2.75159 13.546 2.79823C13.8713 2.84197 13.9732 2.91258 14.0303 2.96967L15.091 1.90901C14.7087 1.52676 14.2375 1.3777 13.7458 1.31161C13.2758 1.24841 12.6859 1.25 12 1.25V2.75ZM15.75 5C15.75 4.3141 15.7516 3.72422 15.6884 3.25416C15.6223 2.76252 15.4732 2.29126 15.091 1.90901L14.0303 2.96967C14.0874 3.02676 14.158 3.12872 14.2018 3.45403C14.2484 3.80091 14.25 4.27169 14.25 5H15.75ZM12 1.25C11.3141 1.25 10.7242 1.24841 10.2542 1.31161C9.76252 1.3777 9.29126 1.52676 8.90901 1.90901L9.96967 2.96967C10.0268 2.91258 10.1287 2.84197 10.454 2.79823C10.8009 2.75159 11.2717 2.75 12 2.75V1.25ZM9.75 5C9.75 4.27169 9.75159 3.80091 9.79823 3.45403C9.84197 3.12872 9.91258 3.02676 9.96967 2.96967L8.90901 1.90901C8.52676 2.29126 8.3777 2.76252 8.31161 3.25416C8.24841 3.72422 8.25 4.3141 8.25 5H9.75ZM4.5 8.75H7.5V7.25H4.5V8.75ZM8.25 9.5V22H9.75V9.5H8.25ZM3.75 22V9.5H2.25V22H3.75ZM7.5 8.75C7.91421 8.75 8.25 9.08579 8.25 9.5H9.75C9.75 8.25736 8.74264 7.25 7.5 7.25V8.75ZM4.5 7.25C3.25736 7.25 2.25 8.25736 2.25 9.5H3.75C3.75 9.08579 4.08579 8.75 4.5 8.75V7.25Z"
        fill={color}
      />
    </svg>
  );
};
