import React from 'react';
export const UserDouble = ({
  color = '#6765F8',
  size = 24,
}: {
  color?: string;
  size?: number;
}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path
        d="M15 10C16.6569 10 18 8.65685 18 7C18 5.34315 16.6569 4 15 4M18 15C19.7542 15.3847 21 16.3589 21 17.5C21 18.5293 19.9863 19.4229 18.5 19.8704M13 7C13 9.20914 11.2091 11 9 11C6.79086 11 5 9.20914 5 7C5 4.79086 6.79086 3 9 3C11.2091 3 13 4.79086 13 7ZM16 18C16 20.2091 12.866 22 9 22C5.13401 22 2 20.2091 2 18C2 15.7909 5.13401 14 9 14C12.866 14 16 15.7909 16 18Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
