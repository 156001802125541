import React from 'react';
import styled from 'styled-components';
import {device} from 'shared/device';
import {Text} from 'shared/ui/Text';
import {TextInput} from 'shared/ui/TextInput';
import {Search as SearchIcon} from 'shared/icons/Search';
import {useColors} from 'shared/lib/hooks';
import {Seo} from 'shared/ui/Seo';

const Header = styled.header`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  margin: 24px;
  @media ${device.mobile} {
    margin-top: 14px;
    margin-left: 16px;
  }
`;
const H1 = styled.div`
  color: ${({theme}) => theme.textPrimary};
  display: flex;
  text-align: left;
  justify-content: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  height: 56px;
  align-items: center;
  @media (max-width: 390px) {
    font-size: 20px;
    line-height: 28px;
  }
`;
const Section = styled.section`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  @media ${device.mobile} {
    flex-direction: column;
    gap: 8px;
  }
`;
const Search = styled(TextInput)`
  display: flex;
  width: 320px;
  height: 56px;
  pointer-events: all;
  @media ${device.mobile} {
    width: calc(100vw - 128px);
  }
`;

export const CatalogHeader = ({
  search,
  setSearch,
}: {
  search: string;
  setSearch: (value: string) => void;
}) => {
  const color = useColors();
  return (
    <Header>
      <Seo title="Каталог приложений" />
      <H1>Каталог приложений</H1>
      <Section>
        <Text
          typography="text16Regular"
          style={{maxWidth: 956}}
          color="textSecondary">
          Интегрируйте Booky Business с вашими системами, максимизируя
          автоматизацию и улучшая опыт для клиентов. Переведите взаимодействие с
          клиентами и управление бизнесом на новый уровень.
        </Text>
        <Search
          height={54}
          variant="default"
          onChange={setSearch}
          value={search}
          backgroundColor={color.bgPrimary}
          placeholder="Поиск"
          leftElement={<SearchIcon color={color.textTertiary} />}
        />
      </Section>
    </Header>
  );
};
