import React, {useState} from 'react';
import styled from 'styled-components';
import {Avatar} from 'shared/ui/Avatar';
import Arrow from 'shared/icons/Arrow';
import {device} from 'shared/device';
import {Text} from 'shared/ui/Text';
import {ArrowRight2v} from 'shared/icons/ArrowRight2v';
import {Employee} from 'shared/__generated__/graphql';
import {getName} from 'shared/lib/utils';
import {WorkDay} from '..';
import {typography} from 'shared/typography';
import {useColors, useResize} from 'shared/lib/hooks';
import dayjs from 'dayjs';
import {useNavigate} from 'react-router-dom';
import {useAppSelector} from 'shared/store';

const styleDay = typography.text16Regular;
const EmployeeBox = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  height: 128px;
  pointer-events: all;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: calc(100% - 32px);
  gap: 8px;
  @media ${device.mobile} {
    flex: 1;
    margin-top: 0;
    height: 56px;
  }
`;
const Box = styled.div`
  @media ${device.mobile} {
    padding: 16px;
    padding-bottom: 0;
    border-radius: 24px;
    background-color: ${({theme}) => theme.bgPrimary};
    overflow: hidden;
  }
`;
const WorkDayBox = styled.div<{show: boolean}>`
  display: none;
  @media ${device.mobile} {
    display: flex;
    margin-top: 16px;
    height: ${props => (props.show ? '468px' : 0)};
    flex-direction: column;
    gap: 8px;
    margin-bottom: ${props => (props.show ? '16px' : 0)};
    transition: height 300ms ease-out;
  }
`;
const EmployeeInfo = styled.div`
  display: flex;
  flex-direction: column;
  pointer-events: none;
  flex: 1;
  gap: 8px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  @media ${device.mobile} {
    width: min-content;
  }
`;
const OverflowText = styled(Text)`
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
`;
const MobileBoxDay = styled.button<{active?: boolean}>`
  display: none;
  @media ${device.mobile} {
    background: ${({active, theme}) =>
      active ? theme.mainPrimary : theme.fillPrimary};
    color: ${({active, theme}) => (active ? theme.white : theme.textPrimary)};
    border-radius: 20px;
    border: none;
    padding: 8px 16px 8px 16px;
    pointer-events: all;
    font-size: ${styleDay.fontSize};
    font-weight: ${styleDay.fontWeight};
    line-height: ${styleDay.lineHeight};
    font-style: ${styleDay.fontSize};
    display: flex;
    height: 60px;
    flex-direction: row;
    align-items: center;
    width: calc(100dvw - 32px);
  }
`;
const EmployeeCardInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const DateBox = styled.div`
  display: none;
  @media ${device.mobile} {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
`;
const ToggleArrow = styled.div`
  display: flex;
  flex: 0;
  flex-direction: column;
  pointer-events: all;
  cursor: pointer;
  @media ${device.mobile} {
    margin-top: 0;
    flex: 1;
    align-items: flex-end;
  }
`;

type IEmployee = Pick<Employee, 'id' | 'name' | 'surname'>;
const defaultDayOff = {
  id: '',
  start_time: '',
  end_time: '',
  date: '',
  breakTimes: [],
};
const MobileDay = ({
  workTime,
  active,
  workDayDate,
  onSelect,
}: {
  workTime: string;
  active?: boolean;
  workDayDate: string;
  onSelect: () => void;
}) => {
  const date = workDayDate.split('  ');
  const colors = useColors();
  return (
    <MobileBoxDay onClick={onSelect} active={active}>
      <DateBox>
        <Text
          style={{color: active ? colors.white + '80' : colors.textTertiary}}
          typography="subHead14Medium">
          {date[0]}
        </Text>
        <Text
          style={{color: active ? colors.white : colors.textPrimary}}
          typography="text16Semibold">
          {date[1]}
        </Text>
      </DateBox>
      {active && workTime ? workTime : '-'}
    </MobileBoxDay>
  );
};

export const EmployeeCard = ({
  employee,
  professions,
  url,
  week,
  workingDates,
  onSelect,
}: {
  employee: IEmployee;
  url?: string;
  professions: string;
  week: string[];
  workingDates: WorkDay[];
  onSelect: (day: WorkDay) => void;
}) => {
  const {isMobile} = useResize();
  const navigate = useNavigate();
  const title = getName(employee.name, employee.surname, 'employee');
  const [toggle, setToggle] = useState(false);
  const username = useAppSelector(state => state.company.data?.username);

  return (
    <Box>
      <EmployeeCardInfo>
        <EmployeeBox
          onClick={() =>
            navigate(`/${username}/employee/${employee.id}/schedule`)
          }>
          <Avatar size={56} url={url} />
          <EmployeeInfo>
            <OverflowText typography="text16Semibold">{title}</OverflowText>
            {professions ? (
              <OverflowText color="textTertiary" typography="subHead14Medium">
                {professions}
              </OverflowText>
            ) : null}
          </EmployeeInfo>
        </EmployeeBox>
        <ToggleArrow
          onClick={() => {
            if (isMobile) {
              setToggle(!toggle);
            } else {
              navigate(`/${username}/employee/${employee.id}`);
            }
          }}>
          {isMobile ? (
            <Arrow
              style={{
                transform: toggle ? 'rotateZ(180deg)' : 'rotateZ(0)',
                transition: 'transform 300ms ease-out',
              }}
            />
          ) : (
            <ArrowRight2v />
          )}
        </ToggleArrow>
      </EmployeeCardInfo>
      <WorkDayBox show={toggle}>
        {week.map(day => {
          const findWorkDay = workingDates.find(
            workDay => workDay.date === day,
          );
          const workTime = findWorkDay
            ? findWorkDay.start_time + ' - ' + findWorkDay.end_time
            : '';
          const workDayDate = dayjs(day).format('dddd  D MMMM');
          return (
            <MobileDay
              key={day + '_date'}
              onSelect={() =>
                onSelect(findWorkDay ?? {...defaultDayOff, date: day})
              }
              workTime={workTime}
              active={!!findWorkDay}
              workDayDate={workDayDate}
            />
          );
        })}
      </WorkDayBox>
    </Box>
  );
};
