import React from 'react';
import {useNavigate} from 'react-router-dom';
import {
  CompanyBookingStatus,
  Employee,
  Image,
  SortOrder,
} from 'shared/__generated__/graphql';
import {getName, getPhoneMask} from 'shared/lib/utils';
import {Avatar} from 'shared/ui/Avatar';
import {OptionsType, Table, TableSortType} from 'shared/ui/Table';
import {Text} from 'shared/ui/Text';
import {StarStroke} from 'shared/icons/StarStroke';
import {useColors} from 'shared/lib/hooks';
import {Flex} from 'shared/ui/Flex';
import {BadgeStatus} from 'shared/ui/BadgeStatus';
import {useAppSelector} from 'shared/store';
import {Content} from 'shared/ui/Content';

type Props = {
  employees?: Array<
    Pick<
      Employee,
      'id' | 'name' | 'surname' | 'phone' | 'invite_status' | 'enabled'
    > & {
      rayting?: number;
      is_online: boolean;
    } & {
      serviceLenght?: number;
    } & {
      avatar?: Pick<Image, 'id' | 'url' | 'url_256'>;
    }
  >;
  onClick?: () => void;
  onChange?: () => void;
  onSelectionChange?: (values: number[]) => void;
  selections: number[];
  checked?: boolean;
  options: OptionsType;
  setOptions: React.Dispatch<React.SetStateAction<OptionsType>>;
  sort?: TableSortType;
  setSort: React.Dispatch<React.SetStateAction<TableSortType | undefined>>;
  permission: boolean;
};

export const EmployeesTable = ({
  employees = [],
  options,
  setOptions,
  onSelectionChange,
  selections,
  permission,
  sort,
  setSort,
}: Props) => {
  const titles = [
    'Сотрудник',
    'Телефон',
    'Услуги',
    'Рейтинг',
    'Статус приглашения',
  ];
  const navigate = useNavigate();
  const colors = useColors();
  const username = useAppSelector(state => state.company.data?.username);

  return (
    <Content>
      <Table
        titles={titles}
        widths={[372]}
        options={options}
        setOptions={setOptions}
        onSelectionChange={onSelectionChange}
        selections={selections}
        sort={sort}
        onClickTitle={(index, order) => {
          if (index === 2) return;
          if (sort?.index === index && sort.order === SortOrder.Asc) {
            setSort(undefined);
            return;
          }
          setSort({
            index,
            order,
          });
          onSelectionChange && onSelectionChange([]);
        }}
        onClick={index =>
          permission &&
          !!employees?.length &&
          navigate(`/${username}/employee/${employees[index].id}`)
        }
        rows={employees.map((employee, index) => [
          <Flex key={index + 1} alignItems="center" gap={16}>
            <Avatar
              size={40}
              active={employee.is_online}
              activeSize={16}
              url={employee.avatar?.url_256 ?? employee.avatar?.url}
            />
            <Text>{getName(employee.name, employee.surname, 'employee')}</Text>
          </Flex>,
          <Text key={`phone_${index}`} style={{whiteSpace: 'nowrap'}}>
            {getPhoneMask(employee.phone)}
          </Text>,
          employee.serviceLenght,
          <Flex gap={4} key={index + 5}>
            <StarStroke color={'transparent'} fillColor={colors.goldPrimary} />
            <Text typography="text16Semibold" color="goldPrimary">
              {employee.rayting}
            </Text>
          </Flex>,
          <BadgeStatus
            type={
              employee.invite_status === 'accepted'
                ? CompanyBookingStatus.Completed
                : employee.invite_status === 'pending'
                ? CompanyBookingStatus.Confirmed
                : CompanyBookingStatus.Pending
            }
            key={index + 6}>
            {employee.invite_status === 'accepted'
              ? 'Активный'
              : employee.invite_status === 'pending'
              ? 'Отправлено'
              : 'Не активный'}
          </BadgeStatus>,
        ])}
      />
    </Content>
  );
};
