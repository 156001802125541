import React from 'react';
import {device} from 'shared/device';
import {Skeleton} from 'shared/ui/Skeleton';
import styled from 'styled-components';
import {Content} from 'shared/ui/Content';

const Container = styled(Content)`
  padding: 16px;
  background: none;
  width: 720px;
  background-color: ${props => props.theme.bgPrimary};
  border-width: 1px;
  padding: 24px;
`;

const RectName = styled.rect`
  display: flex;
  @media ${device.mobile} {
    display: none;
  }
`;
const row = Array.from(Array(6).keys());

export const CustomerBonusesSkeleton = () => {
  return (
    <>
      <Container>
        <Skeleton height={392}>
          <RectName width={56} height={12} rx={6} x={0} />
          <RectName width={56} height={12} rx={6} x={174} />
          <RectName width={56} height={12} rx={6} x={348} />
          <RectName width={56} height={12} rx={6} x={522} />
          {row.map((rowItem, index) => (
            <React.Fragment key={'row_' + rowItem}>
              <RectName
                width={140}
                height={10}
                rx={8}
                x={0}
                y={index * 60 + 56}
              />
              <RectName
                width={72}
                height={10}
                rx={8}
                x={0}
                y={index * 60 + 78}
              />
              <RectName
                width={150}
                height={8}
                rx={4}
                x={174}
                y={index * 60 + 56}
              />
              <RectName
                width={72}
                height={8}
                rx={4}
                x={174}
                y={index * 60 + 78}
              />
              <RectName
                width={140}
                height={8}
                rx={4}
                x={348}
                y={index * 60 + 80}
              />
              <RectName
                width={150}
                height={32}
                rx={18}
                x={522}
                y={index * 60 + 56}
              />
            </React.Fragment>
          ))}
        </Skeleton>
      </Container>
      <Skeleton>
        <RectName width={720} height={64} rx={14} x={0} y={0} />
      </Skeleton>
    </>
  );
};
