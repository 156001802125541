import React from 'react';
import styled from 'styled-components';

const PopupContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  gap: 20px;
  justify-content: center;
  border: solid 1px ${props => props.theme.borderPrimary};
  position: fixed;
  border-radius: 24px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${props => props.theme.bgPrimary};
  padding: 24px;
  z-index: 999;
  ${({theme}) => theme.cardShadow}
`;
const Backdrop = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  background-color: ${({theme}) => theme.modalOverlay};
  opacity: 0.5;
  bottom: 0;
  z-index: 999;
  pointer-events: all;
`;
export const Popup = ({
  visible,
  onClose,
  children,
  style,
  backDrop = true,
}: React.PropsWithChildren<{
  visible?: boolean;
  onClose?: () => void;
  style?: React.CSSProperties;
  backDrop?: boolean;
}>) => {
  if (!visible) {
    return null;
  }
  return (
    <>
      {backDrop && <Backdrop onClick={onClose} />}
      <PopupContainer style={style}>{children}</PopupContainer>
    </>
  );
};
