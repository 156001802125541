import React, {useState} from 'react';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';
import {Content} from 'shared/ui/Content';
import {TextInput} from 'shared/ui/TextInput';
import {Head} from 'shared/ui/Head';
import {Select} from 'shared/ui/Select';
import {Button} from 'shared/ui/Button';
import {useMutation, useQuery} from '@apollo/client';
import {ActionIcon} from 'shared/ui/ActionIcon';
import {useNavigate} from 'react-router-dom';
import {gql} from 'shared/__generated__';
import {useAppSelector} from 'shared/store';
import {
  DiscountType,
  ImageType,
  ImageUpload,
} from 'shared/__generated__/graphql';
import {ArrowLeft2v} from 'shared/icons/ArrowLeft2v';
import {Layout} from 'shared/ui/Layout';
import {Flex} from 'shared/ui/Flex';
import {RadioButton} from 'shared/ui/RadioButton';
import {AvatarCrop} from 'shared/ui/AvatarCrop';
import {showAlert} from 'shared/ui/Alert';
import {Switch} from 'shared/ui/Switch';
import {MultiSelect} from 'shared/ui/MultiSelect';
import dayjs from 'dayjs';
import {
  DAYS_WEEK,
  PeriodSelect,
  SelectEmployees,
  SelectServices,
  WEEK,
  getWorkingDays,
  initialDayjsWeekValues,
} from 'entities/loyalty';
import {MultiSelectChips} from 'shared/ui/MultiSelectChips';
import {ScheduleWorkTime} from 'entities/schedule';
import {Divider} from 'shared/ui/Divider';
import {Seo} from 'shared/ui/Seo';
import {Grow} from 'shared/ui/Grow';
import {SALE_SCREEN} from 'entities/discounts';
import {useColors} from 'shared/lib/hooks';

const CATEGORIES = gql(`
  query DiscountNewSale {
    discountCategories {
      title
      id
    }
  }
`);

const ADD_DISCOUNT = gql(`
  mutation UpdateCompanyNewSale($input: CreateDiscount!) {
    createDiscount(input: $input) {
      id
    }
  }
`);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-column-start: 4;
  grid-column-end: 10;
  padding: 64px 0 24px;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const START_DATE = dayjs().format('YYYY-MM-DD');
const END_DATE = dayjs().add(1, 'month').format('YYYY-MM-DD');

export const AddDiscount = () => {
  const colors = useColors();
  const navigate = useNavigate();
  const companyId = useAppSelector(state => state.company.data!.id);
  const {data} = useQuery(CATEGORIES);
  const [avatar, setAvatar] = React.useState<Omit<ImageUpload, 'type'>>();
  const [name, setName] = useState('');
  const [type, setType] = useState(true);
  const [value, setValue] = useState('0');
  const [periodCheck, setPeriodCheck] = useState(false);
  const [period, setPeriod] = useState({from: START_DATE, to: END_DATE});
  const [week, setWeek] = useState<WEEK[]>(initialDayjsWeekValues);
  const [services, setServices] = useState<{label: string; value: string}[]>(
    [],
  );
  const [employees, setEmployees] = useState<{label: string; value: string}[]>(
    [],
  );
  const [allDay, setAllDay] = useState(true);
  const [time, setTime] = useState({start: '09:00', end: '20:00'});
  const [selectCategory, setSelectedCategy] = useState<string>();
  const [isWithBonus, setIsWithBonus] = React.useState(false);
  const [isNewCustomer, setIsNewCustomer] = React.useState(false);
  const [allServices, setAllServices] = React.useState(false);
  const [allEmployees, setAllEmployees] = React.useState(false);

  const [createDiscount, {loading}] = useMutation(ADD_DISCOUNT, {
    refetchQueries: [
      {
        query: SALE_SCREEN,
        variables: {
          companyId,
          first: 10,
          page: 1,
          name: '',
        },
      },
    ],
  });
  const categoies =
    data?.discountCategories?.map(item => ({
      value: item?.id ?? '',
      label: item?.title ?? '',
    })) ?? [];
  const [errorName, setErrorName] = React.useState<string | undefined>();
  const [errorValue, setErrorValue] = React.useState<string | undefined>();
  const [errorCategory, setErrorCategory] = React.useState<
    string | undefined
  >();
  const [errorWeek, setErrorWeek] = React.useState<string | undefined>();

  async function onPressSave() {
    try {
      if (!name) {
        setErrorName('Обязательное поле не введено');
        throw new Error('Обязательное поле не введено');
      }
      if (!selectCategory) {
        setErrorCategory('Обязательное поле не введено');
        throw new Error('Обязательное поле не введено');
      }
      if (!value) {
        setErrorValue('Обязательное поле не введено');
        throw new Error('Обязательное поле не введено');
      }
      if (!week.length) {
        setErrorWeek('Обязательное поле не введено');
        throw new Error('Обязательное поле не введено');
      }
      let saleValue = parseInt(value, 10);
      if (type && saleValue > 100) {
        saleValue = 100;
      }
      await createDiscount({
        variables: {
          input: {
            company_id: companyId,
            value: saleValue,
            name,
            type: type ? DiscountType.Percentage : DiscountType.Fixed,
            discount_category_id: selectCategory,
            is_active: true,
            active_on_monday: week.includes('monday'),
            active_on_tuesday: week.includes('tuesday'),
            active_on_wednesday: week.includes('wednesday'),
            active_on_thursday: week.includes('thursday'),
            active_on_friday: week.includes('friday'),
            active_on_saturday: week.includes('saturday'),
            active_on_sunday: week.includes('sunday'),
            image: avatar
              ? {
                  ...avatar,
                  type: ImageType.Avatar,
                }
              : null,
            is_all_employees: allEmployees,
            is_all_services: allServices,
            is_new_customer: isNewCustomer,
            is_with_bonus: isWithBonus,
            valid_from: periodCheck ? period.from : null,
            valid_to: periodCheck ? period.to : null,
            valid_from_time: !allDay ? time.start : null,
            valid_to_time: !allDay ? time.end : null,
            services: services?.map(item => item.value) ?? [],
            employees: employees?.map(item => item.value) ?? [],
          },
        },
      });
      navigate(-1);
    } catch (e) {
      if (e instanceof Error) {
        showAlert('error', e.message);
      }
    }
  }

  return (
    <Layout columns={12}>
      <Seo title="Создание скидки" />
      <Wrapper>
        <Head>
          <ActionIcon icon={<ArrowLeft2v />} onClick={() => navigate(-1)} />
          <Text typography="title24">Создать скидку</Text>
        </Head>
        <Content gap="24px" style={{marginTop: 8}}>
          <AvatarCrop
            variant="image"
            onChange={value => {
              setAvatar(value);
            }}
            onDelete={() => {
              setAvatar(undefined);
            }}
            url={avatar?.url ?? ''}
          />
          <TextInput
            label="Название"
            required
            error={errorName}
            value={name}
            onChange={text => {
              if (errorName) {
                setErrorName(undefined);
              }
              setName(text);
            }}
          />
          <Select
            required
            error={errorCategory}
            data={categoies}
            value={selectCategory}
            onChange={value => {
              if (errorCategory) {
                setErrorCategory(undefined);
              }
              setSelectedCategy(value);
            }}
            label="Категория"
          />
          <Grow style={{gap: 8}}>
            <Flex alignItems="center">
              <Flex flex={1}>
                <RadioButton
                  selected={type}
                  labelPosition="right"
                  title="Процент"
                  style={{gap: 10, minHeight: 56}}
                  onChange={() => {
                    if (errorValue) {
                      setErrorValue(undefined);
                    }
                    setType(true);
                  }}
                />
              </Flex>
              <Flex flex={2}>
                <RadioButton
                  selected={!type}
                  labelPosition="right"
                  title="Конкретная сумма"
                  style={{gap: 10, minHeight: 56}}
                  onChange={() => {
                    if (errorValue) {
                      setErrorValue(undefined);
                    }
                    setType(false);
                  }}
                />
              </Flex>
            </Flex>
            <TextInput
              required
              error={errorValue}
              value={value}
              placeholder="0"
              variant="with_label"
              mask={type ? '999' : '999999'}
              rightElement={
                <Text color="textTertiary">{type ? '%' : '₽'}</Text>
              }
              onChange={text => {
                if (errorValue) {
                  setErrorValue(undefined);
                }
                setValue(text);
              }}
            />
          </Grow>
          <Divider />
          <Flex
            justifyContent="space-between"
            alignItems="center"
            style={{height: 48}}>
            <Text>Действует ограниченный период</Text>
            <Switch
              value={periodCheck}
              onChange={value => setPeriodCheck(value)}
            />
          </Flex>
          {periodCheck && (
            <PeriodSelect
              label=" Период действия скидки"
              period={period}
              onChange={value => {
                setPeriod(value);
              }}
            />
          )}
          <MultiSelect
            required
            arrow
            error={errorWeek}
            label="Рабочий день недели"
            data={DAYS_WEEK}
            values={week}
            allText="Все дни"
            onChange={values => {
              if (errorWeek) {
                setErrorWeek(undefined);
              }
              const days = getWorkingDays(week, values);
              setWeek(days);
            }}
            style={{border: `1px solid ${colors.borderPrimary}`}}
          />
          <Flex
            justifyContent="space-between"
            alignItems="center"
            style={{height: 48}}>
            <Text>Весь день</Text>
            <Switch value={allDay} onChange={value => setAllDay(value)} />
          </Flex>
          {!allDay && (
            <Flex direction="column">
              <ScheduleWorkTime
                onChange={(time, location) =>
                  setTime(prev => ({...prev, [location]: time}))
                }
                value={{start: time.start, end: time.end}}
              />
            </Flex>
          )}
          <Grow style={{gap: 8}}>
            <Flex
              justifyContent="space-between"
              alignItems="center"
              style={{height: 48}}>
              <Text>Не начислять бонусы</Text>
              <Switch
                value={isWithBonus}
                onChange={value => setIsWithBonus(value)}
              />
            </Flex>
            <Flex
              justifyContent="space-between"
              alignItems="center"
              style={{height: 48}}>
              <Text>Для новых клиентов</Text>
              <Switch
                value={isNewCustomer}
                onChange={value => setIsNewCustomer(value)}
              />
            </Flex>
          </Grow>
          <Divider />
          <Flex
            justifyContent="space-between"
            alignItems="center"
            style={{height: 48}}>
            <Text>Действует на все услуги</Text>
            <Switch
              value={allServices}
              onChange={value => setAllServices(value)}
            />
          </Flex>
          {!allServices && (
            <MultiSelectChips
              label="Услуги"
              values={services}
              onDelete={value =>
                setServices(prev => prev.filter(item => item.value !== value))
              }
              modal={(visible, setVisible) => (
                <SelectServices
                  label="Услуги"
                  visible={visible}
                  values={services}
                  onChange={values => {
                    setServices(values);
                    setVisible(false);
                  }}
                  onClose={() => setVisible(false)}
                />
              )}
            />
          )}
          <Divider />
          <Flex
            justifyContent="space-between"
            alignItems="center"
            style={{height: 48}}>
            <Text>Действует на всех сотрудников</Text>
            <Switch
              value={allEmployees}
              onChange={value => setAllEmployees(value)}
            />
          </Flex>
          {!allEmployees && (
            <MultiSelectChips
              label="Сотрудники"
              values={employees}
              onDelete={value =>
                setEmployees(prev => prev.filter(item => item.value !== value))
              }
              modal={(visible, setVisible) => (
                <SelectEmployees
                  label="Сотрудники"
                  visible={visible}
                  values={employees}
                  onChange={values => {
                    setEmployees(values);
                    setVisible(false);
                  }}
                  onClose={() => setVisible(false)}
                />
              )}
            />
          )}
        </Content>
        <Flex mt={32} gap={8}>
          <Button
            size="large"
            style={{flex: 1}}
            loading={loading}
            onClick={onPressSave}>
            Добавить
          </Button>
        </Flex>
      </Wrapper>
    </Layout>
  );
};
