import React from 'react';
import {useQuery} from '@apollo/client';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {gql} from 'shared/__generated__';
import {CirclePlus} from 'shared/icons/CirclePlus';
import {GooglePlay} from 'shared/icons/GooglePlay';
import {Huawei} from 'shared/icons/Huawei';
import {useColors} from 'shared/lib/hooks';
import {useAppSelector} from 'shared/store';
import {ActionIcon} from 'shared/ui/ActionIcon';
import {Button} from 'shared/ui/Button';
import {Content} from 'shared/ui/Content';
import {Divider} from 'shared/ui/Divider';
import {Flex} from 'shared/ui/Flex';
import {Head} from 'shared/ui/Head';
import {Layout} from 'shared/ui/Layout';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';
import {Header} from './ui/Header';
import {AppStore} from 'shared/icons/AppStore';
import {ArrowLeft2v} from 'shared/icons/ArrowLeft2v';
import {Support} from 'shared/icons/Support';
import {Seo} from 'shared/ui/Seo';

const APP = gql(`
  query GetAppPlatform($id: String!, $companyId: String!) {
    companyGetModerationStatus(company_id: $companyId)
    getAppById(id: $id, company_id: $companyId) {
      id
      key
      name
      image {
        id
        url
      }
      description
      app_url(company_id: $companyId)
      darkImage {
        id
        url
      }
      is_connectable
      is_connected(company_id: $companyId)
      is_free
      is_out
      is_recommended
      short_description
      instruction
      instruction_link
    }
  }
`);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-column-start: 4;
  grid-column-end: 10;
  padding: 64px 0;
  gap: 8px;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const AppButton = styled.button`
  display: flex;
  justify-content: center;
  height: 60px;
  padding: none;
  border-radius: 14px;
  border: 1px solid ${({theme}) => theme.borderPrimary};
  background: transparent;
`;
const URL = {
  appStore:
    'https://apps.apple.com/ru/app/booky-%D0%BE%D0%BD%D0%BB%D0%B0%D0%B9%D0%BD-%D0%B7%D0%B0%D0%BF%D0%B8%D1%81%D1%8C-%D0%BA%D0%BB%D0%B8%D0%B5%D0%BD%D1%82%D0%BE%D0%B2/id6447231466',
  googlePlay: 'https://play.google.com/store/apps/details?id=co.booky.business',
  appGallery: 'https://appgallery.huawei.com/#/app/C108405015',
  webBooky: 'https://booky.co/',
};

export const PlatformApp = () => {
  const navigate = useNavigate();
  const title = useLocation().state;
  const username = useAppSelector(state => state.company.data?.username);
  const params = useParams();
  const colors = useColors();
  const companyId = useAppSelector(state => state.company.data!.id);
  const id = params.id!;
  const {data, loading} = useQuery(APP, {
    variables: {
      companyId,
      id,
    },
  });
  const app = data?.getAppById;
  const moderation = data?.companyGetModerationStatus;

  return (
    <Layout columns={12}>
      <Seo title={title} />
      <Wrapper>
        <Head>
          <ActionIcon
            icon={<ArrowLeft2v />}
            onClick={() => navigate(`/${username}/catalog-apps`)}
          />
          <Text typography="title24">{title}</Text>
        </Head>
        <Content gap="24px">
          <Header app={app} moderation={moderation} />
          <Flex flex={1} direction="column" gap={8}>
            <Button
              loading={loading}
              size="large"
              leftIcon={
                moderation ? undefined : <CirclePlus color={colors.white} />
              }
              onClick={() => {
                if (moderation) {
                  navigate('/support');
                  return;
                }
                navigate(`/${username}/moderation/new-moderation/${id}`, {
                  state: title,
                });
              }}>
              {moderation ? 'Отключить' : 'Подключить'}
            </Button>
            <Button
              variant="light"
              size="large"
              onClick={() => {
                navigate(
                  `/${username}/moderation/status-of-moderations/${id}`,
                  {
                    state: title,
                  },
                );
              }}>
              Статус заявки
            </Button>
          </Flex>
          <Divider />
          <Button
            size="medium"
            variant="outline"
            onClick={() => {
              window.open(URL['webBooky'], '_blank');
            }}>
            Перейти на сайт Booky
          </Button>
          <Flex direction="column" gap={8}>
            <AppButton onClick={() => window.open(URL['appStore'], '_blank')}>
              <AppStore />
            </AppButton>
            <AppButton onClick={() => window.open(URL['googlePlay'], '_blank')}>
              <GooglePlay />
            </AppButton>
            <AppButton onClick={() => window.open(URL['appGallery'], '_blank')}>
              <Huawei />
            </AppButton>
          </Flex>
          {app?.description ? (
            <Flex gap={16} direction="column">
              <Text typography="text16Semibold">Описание</Text>
              <Text color="textSecondary">{app?.description}</Text>
            </Flex>
          ) : null}
          <Button
            variant="outline"
            size="large"
            style={{
              color: colors.textPrimary,
              backgroundColor: colors.fillPrimary,
              border: 'none',
            }}
            onClick={() => navigate('/support')}
            leftIcon={<Support color={colors.textPrimary} />}>
            Написать в чат
          </Button>
        </Content>
      </Wrapper>
    </Layout>
  );
};
