import React from 'react';
export const Support = ({
  size = 24,
  color = '#6765F8',
}: {
  size?: number;
  color?: string;
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.9999 14.9362V9.2766C18.9999 5.25784 15.642 2 11.4999 2C7.35783 2 4 5.25784 4 9.27659V14.9362M18.9999 14.9362L19 11.7021C19 10.8091 18.2538 10.0851 17.3333 10.0851H16.5C15.5796 10.0851 14.8334 10.8091 14.8334 11.7021V14.9362C14.8334 15.8292 15.5796 16.5532 16.5 16.5532H16.9167M18.9999 14.9362C18.9999 15.8292 18.2538 16.5532 17.3333 16.5532H16.9167M4 14.9362L4.00014 11.7021C4.00014 10.8091 4.74633 10.0851 5.6668 10.0851H6.50012C7.42059 10.0851 8.16677 10.8091 8.16677 11.7021V14.9362C8.16677 15.8292 7.42059 16.5532 6.50012 16.5532H5.6668C4.74633 16.5532 4 15.8292 4 14.9362ZM16.9167 16.5532C16.9167 18.3393 15.4243 19.7872 13.5834 19.7872H13.1667M13.1667 19.7872C13.1667 20.457 12.6071 21 11.9167 21H11.8796C11.1892 21 10.6296 20.457 10.6296 19.7872C10.6296 19.1174 11.1892 18.5745 11.8796 18.5745H11.9167C12.6071 18.5745 13.1667 19.1174 13.1667 19.7872Z"
        stroke={color}
        strokeWidth="1.5"
      />
    </svg>
  );
};
