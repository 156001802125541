import React from 'react';

export const Selector = ({
  color = '#6765F8',
  size = 24,
}: {
  color?: string;
  size?: number;
}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0001 3.99829C12.3033 3.99829 12.5941 4.11872 12.8084 4.3331L16.2377 7.7624C16.6841 8.20881 16.6841 8.93258 16.2377 9.37899C15.7913 9.8254 15.0676 9.8254 14.6211 9.37899L12.0001 6.75798L9.37913 9.37899C8.93272 9.8254 8.20895 9.8254 7.76254 9.37899C7.31613 8.93258 7.31613 8.20881 7.76254 7.7624L11.1918 4.3331C11.4062 4.11872 11.697 3.99829 12.0001 3.99829ZM7.76254 14.621C8.20895 14.1746 8.93272 14.1746 9.37913 14.621L12.0001 17.242L14.6212 14.621C15.0676 14.1746 15.7913 14.1746 16.2377 14.621C16.6841 15.0674 16.6841 15.7912 16.2377 16.2376L12.8084 19.6669C12.362 20.1133 11.6383 20.1133 11.1918 19.6669L7.76254 16.2376C7.31613 15.7912 7.31613 15.0674 7.76254 14.621Z"
        fill={color}
      />
    </svg>
  );
};
