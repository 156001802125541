import React from 'react';
export const UserTriple = ({
  size = 24,
  color = '#6765F8',
}: {
  size?: number;
  color?: string;
}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path
        d="M17.4545 9.63158C18.9608 9.63158 20.1818 8.5712 20.1818 7.26316C20.1818 5.95511 18.9608 4.89474 17.4545 4.89474M6.54545 9.63158C5.03922 9.63158 3.81818 8.5712 3.81818 7.26316C3.81818 5.95511 5.03922 4.89474 6.54545 4.89474M19.2727 19.1053C20.8675 18.7408 22 17.8179 22 16.7368C22 15.6558 20.8675 14.7329 19.2727 14.3684M4.72727 19.1053C3.1325 18.7408 2 17.8179 2 16.7368C2 15.6558 3.1325 14.7329 4.72727 14.3684M15.6364 6.78947C15.6364 8.88234 14.0083 10.5789 12 10.5789C9.99169 10.5789 8.36364 8.88234 8.36364 6.78947C8.36364 4.69661 9.99169 3 12 3C14.0083 3 15.6364 4.69661 15.6364 6.78947ZM17.4545 17.2105C17.4545 19.3034 15.0125 21 12 21C8.98754 21 6.54545 19.3034 6.54545 17.2105C6.54545 15.1177 8.98754 13.4211 12 13.4211C15.0125 13.4211 17.4545 15.1177 17.4545 17.2105Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
