import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {Text} from './Text';
import {Chip} from './Chip';
import {CheckBox} from './CheckBox';
import {List} from './List';
import {Flex} from './Flex';
import {ArrowDown1v} from 'shared/icons/ArrowDown1v';

const Wrapper = styled.div<{modal: boolean}>`
  position: ${({modal}) => (modal ? 'static' : 'relative')};
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledButton = styled.button<{
  hasLabel: boolean;
  isOpen: boolean;
  background?: string;
  error?: string | null;
}>`
  display: flex;
  min-height: 64px;
  border-radius: 18px;
  width: 100%;
  pointer-events: all;
  align-items: center;
  padding: 5px 16px 5px 16px;
  cursor: pointer;
  background-color: ${({background, theme}) => background ?? theme.fillPrimary};
  border: 1px solid
    ${props =>
      props.error ? props.theme.dangerPrimary : props.theme.borderPrimary};
  &:focus {
    border: 1px solid
      ${({error, theme, isOpen}) =>
        error
          ? theme.dangerPrimary
          : isOpen
          ? theme.mainPrimary
          : theme.borderPrimary};
    background-color: ${({background}) => background};
    outline: none;
    gap: 8px;
  }
`;

const StyledLabel = styled(Text)<{
  required?: boolean;
  isOpen?: boolean;
  selected?: boolean;
}>`
  &::after {
    content: '*';
    color: ${({theme}) => theme.dangerPrimary};
    visibility: ${({required}) => (required ? 'visible' : 'hidden')};
    font-size: ${({isOpen}) => (isOpen ? '12px' : '16px')};
    transition:
      transform 150ms ease,
      color 150ms ease,
      font-size 150ms ease;
  }
  transition: ${({selected}) => (!selected ? 'all 0.3s ease' : 'none')};
  transform: ${({isOpen, selected}) =>
    isOpen && !selected ? 'translateY(-14px)' : 'none'};
`;

const WrapperValues = styled.div<{length: number}>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  width: 100%;
`;

type Props = {
  label?: string;
  error?: string;
  values: {label: string; value: string}[];
  data?: {label: string; value: string}[];
  modal?: (
    visible: boolean,
    setValue: (value: boolean) => void,
  ) => React.ReactNode;
  style?: React.CSSProperties;
  onDelete?: (value: string) => void;
  onChange?: (values: {label: string; value: string}[]) => void;
  disabled?: boolean;
  backgroundColor?: string;
  listEmpty?: React.ReactElement | string | undefined;
  addItem?: React.ReactElement;
  itemHeight?: number;
  ListEmptyComponent?: React.ReactElement;
  renderItemLeftElement?: (obj: {
    label: string;
    value: string;
  }) => React.ReactNode;
};

export const MultiSelectChips = ({
  label,
  values,
  error,
  modal,
  style,
  onDelete,
  onChange,
  data,
  disabled,
  backgroundColor,
  listEmpty,
  addItem,
  itemHeight,
  ListEmptyComponent,
  renderItemLeftElement,
}: Props) => {
  const [visible, setVisible] = useState(false);
  const selectRef = useRef<HTMLDivElement>(null);
  const [temp, setTemp] = useState(false);
  const selectedLabel = values.length > 0;
  const isLabelShrink = selectedLabel || visible;

  const [buttonHeight, setButtonHeight] = useState(0);
  const buttonRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    const currentButton = buttonRef.current;
    const resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        if (entry.target === currentButton) {
          setButtonHeight(entry.target.clientHeight);
        }
      }
    });
    if (currentButton) {
      resizeObserver.observe(currentButton);
    }
    return () => {
      if (currentButton) {
        resizeObserver.unobserve(currentButton);
      }
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        selectRef.current &&
        !selectRef.current.contains(event.target as Node)
      ) {
        setVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectRef]);

  return (
    <Wrapper ref={selectRef} style={style} modal={!!modal}>
      <StyledButton
        ref={buttonRef}
        error={error}
        disabled={disabled}
        background={backgroundColor}
        onClick={() => setVisible(!visible)}
        hasLabel={!!label}
        isOpen={visible}>
        <Flex direction="column" flex={1} gap={4}>
          <StyledLabel
            isOpen={isLabelShrink}
            selected={selectedLabel}
            typography={isLabelShrink ? 'footNote12Regular' : 'text16Regular'}
            color={'textTertiary'}>
            {label}
          </StyledLabel>
          <WrapperValues length={values.length}>
            {values.map(item => (
              <Chip
                key={item.value}
                text={item.label}
                onClick={() => {
                  onDelete && onDelete(item.value);
                }}
              />
            ))}
          </WrapperValues>
        </Flex>
        <ArrowDown1v
          style={{
            transform: visible ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: 'transform 300ms',
          }}
        />
      </StyledButton>
      {error && (
        <Text typography="footNote12Regular" color="dangerPrimary">
          {error}
        </Text>
      )}
      {visible && modal && modal(visible, setVisible)}
      {visible && modal === undefined && (
        <ModalWrapper
          style={{
            top: `${buttonHeight + 2}px`,
          }}>
          {addItem}
          {!data?.length ? ListEmptyComponent : null}
          <List
            gap={8}
            data={data ?? []}
            keyExtractor={(item, index) => item.value + index}
            placeholder={
              <Text
                style={{textAlign: 'center', padding: '0 24px'}}
                typography="text16Regular"
                color="textTertiary">
                {listEmpty}
              </Text>
            }
            renderItem={item => (
              <CheckBox
                hover
                title={item.label}
                style={{padding: '16px', height: itemHeight}}
                checked={values.some(
                  selectItem => selectItem.value === item.value,
                )}
                leftElement={
                  renderItemLeftElement && renderItemLeftElement(item)
                }
                onChange={value => {
                  if (value) {
                    const temp2 = values;
                    temp2.push(item);
                    onChange && onChange(temp2);
                  } else {
                    onChange &&
                      onChange(
                        values.filter(
                          selectItem => selectItem.value !== item.value,
                        ),
                      );
                  }
                  setTemp(!temp);
                }}
              />
            )}
          />
        </ModalWrapper>
      )}
    </Wrapper>
  );
};

const ModalWrapper = styled.div`
  position: absolute;
  background-color: ${({theme}) => theme.bgPrimary};
  border-radius: 18px;
  padding: 16px 0;
  width: 100%;
  z-index: 100;
  max-height: 392px;
  overflow-y: scroll;
  border: 1px solid ${({theme}) => theme.borderPrimary};
  pointer-events: all;
`;
