import React from 'react';
import {typography} from 'shared/typography';
import {colors} from 'shared/colors';
import styled from 'styled-components';
import {useColors} from 'shared/lib/hooks';

interface ITextProps {
  typography?: keyof typeof typography;
  color?: keyof typeof colors.dark;
  style?: React.CSSProperties;
  align?: 'center' | 'left';
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  disabled?: boolean;
  mb?: number;
  mt?: number;
  display?: 'inline';
  className?: string;
}

const Box = styled.div`
  text-align: left;
  font-family: 'Inter';
`;

export const Text = (props: React.PropsWithChildren<ITextProps>) => {
  const {
    children,
    typography: t = 'text16Regular',
    onClick,
    disabled,
    color = 'textPrimary',
    style,
    align,
    mb,
    mt,
    display,
    className,
  } = props;
  const ts = typography[t];
  const colors = useColors();
  return (
    <Box
      className={className}
      style={{
        cursor: onClick ? 'pointer' : 'default',
        display: display,
        marginBottom: mb,
        marginTop: mt,
        textAlign: align,
        fontSize: ts.fontSize,
        fontWeight: ts.fontWeight,
        lineHeight: ts.lineHeight,
        fontStyle: ts.fontStyle,
        color: color ? colors[color] : undefined,
        ...style,
      }}
      onClick={disabled ? () => {} : onClick}>
      {children}
    </Box>
  );
};
