import React, {useState} from 'react';
import {device} from 'shared/device';
import {Content} from 'shared/ui/Content';
import {Flex} from 'shared/ui/Flex';
import {Layout} from 'shared/ui/Layout';
import {SegmentedControl} from 'shared/ui/SegmentedControl';
import {SlidesContainer} from 'shared/ui/SlidesContainer';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';
import {gql} from 'shared/__generated__';
import {useQuery} from '@apollo/client';
import {useAppSelector} from 'shared/store';
import {SubscriptionsType} from 'shared/__generated__/graphql';
import {Skeleton} from 'shared/ui/Skeleton';
import {Slide} from 'shared/ui/Slide';
import {FreeSlide, TariffSelect, TooltipRow} from 'entities/tariff';
import {Seo} from 'shared/ui/Seo';
import {Button} from 'shared/ui/Button';
import {TariffPlan, getPriceFormat, getTariff} from 'shared/lib/utils';
import {Divider} from 'shared/ui/Divider';
import {Popup} from 'shared/ui/Popup';
import {CloseButton} from 'shared/ui/CloseButton';
import {useLocation, useNavigate} from 'react-router-dom';

const CONFIG_TARIFF_PLANS = gql(`
  query ConfigHideFreePlan($key: String) {
    config(key: $key) {
      id
      key
      value
    }
  }  
`);

export const TARIFF_PLANS = gql(`
  query PriceListTariffPlans($companyId: String!) {
    priceList {
      id
      price
      slots
      type
      created_at
    }
    company(id: $companyId) {
      id
      plan {
        id
        price
        slots
        type
        created_at
      }
    }
    getCompanyEmployeesLength(company_id: $companyId, enabled: true)
  }
`);
type TooltipRow = {title: string; tooltip: string | null};
const SelectColumn = styled.div`
  margin-bottom: 8px;
`;
const PopupContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 538px;
  gap: 16px;
`;
const CancelButton = styled(Button)`
  flex: 1;
  border-color: ${({theme}) => theme.borderPrimary};
`;
const rows: TooltipRow[] = [
  {
    title: 'Все модули Booky',
    tooltip:
      'Полный комплект функций для управления вашим бизнесом, от онлайн-записи до учета рабочего времени сотрудников',
  },
  {
    title: 'Все интеграции Booky',
    tooltip:
      'Совместимость с популярными инструментами и приложениями. Список всегда пополняется',
  },
  {
    title: 'Управление с любого устройства',
    tooltip:
      'Удобство контроля вашего бизнеса везде и всегда. Полное управление с мобильного приложения',
  },
  {
    title: 'Техническая поддержка 24/7',
    tooltip: 'Поддержка круглосуточно без выходных.',
  },
  {
    title: 'Консультации по API',
    tooltip: 'Помощь в настройке и интеграции API.',
  },
];

const Header = styled(Flex)`
  width: 100%;
  flex-direction: row;
  align-items: center;
  height: 56px;
  justify-content: space-between;
  @media ${device.mobile} {
    padding: 0 16px;
    height: auto;
    margin: 8px 0;
  }
`;

const Wrapper = styled.div`
  grid-column-start: 1;
  grid-column-end: 13;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 720px;
  gap: 24px;
  margin: 24px auto 0px auto;
`;
const skeletonWidthRow = [1, 0.72, 0.81, 0.93, 1, 0.81, 1];

export function TariffPlans() {
  const navigate = useNavigate();
  const companyId = useAppSelector(state => state.company.data!.id);
  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);
  const {data: dataConfig} = useQuery(CONFIG_TARIFF_PLANS, {
    variables: {
      key: 'BUSINESS_HIDE_FREE_PLAN',
    },
  });
  const {data, loading} = useQuery(TARIFF_PLANS, {variables: {companyId}});
  const plans =
    data?.priceList.filter(plan => plan.type === SubscriptionsType.Month) ?? [];
  const pathname = useLocation().pathname;

  const [selectedPlan, setSelectedPlan] = useState<TariffPlan | undefined>();
  const [visible, setVisible] = useState(false);
  const formattedPlans = plans.map(plan => ({
    label: getTariff(plan),
    value: plan,
  }));
  const calcPrice = selectedPlan
    ? selectedPlan.price
    : plans.length > 0
    ? plans[0].price
    : 800;
  const currentTariffPlan = data?.company?.plan;
  const activeEmployees = data?.getCompanyEmployeesLength ?? 1;
  const tabs = [
    {
      name: 'PRO-тариф',
      onClick: () => setActiveSlideIndex(0),
    },
    {
      name: 'Бесплатный',
      onClick: () => setActiveSlideIndex(1),
    },
  ];
  return (
    <Layout variant="sidebar">
      <Seo title="Тарифные планы" />
      <Wrapper>
        <Container>
          <Header style={{height: 56}}>
            <Text typography="title24">Тарифные планы</Text>
          </Header>
          <Content>
            <Text typography="title18">Тариф</Text>
            <SegmentedControl
              tabs={
                dataConfig?.config.find(
                  item => item.key === 'BUSINESS_HIDE_FREE_PLAN',
                )?.value === '0'
                  ? tabs
                  : [
                      {
                        name: 'PRO-тариф',
                        onClick: () => setActiveSlideIndex(0),
                      },
                    ]
              }
              index={pathname.includes('tariff-plans') ? 0 : 1}
            />
          </Content>
          <SlidesContainer index={activeSlideIndex}>
            {loading ? (
              <SkeletonPayment />
            ) : (
              <Slide style={{paddingBottom: 24}}>
                <Content>
                  <Text
                    typography="title18"
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      gap: 8,
                      alignItems: 'center',
                    }}>
                    Сотрудники{' '}
                    <Text typography="text18" color="textTertiary">
                      (Активных {activeEmployees})
                    </Text>
                  </Text>
                  <SelectColumn>
                    <TariffSelect
                      data={formattedPlans}
                      value={selectedPlan}
                      onChange={setSelectedPlan}
                    />
                    <Text
                      mt={8}
                      color="textTertiary"
                      typography="footNote12Regular">
                      Доступно мест для сотрудника{' '}
                      {Math.max(
                        (currentTariffPlan?.slots ?? 1) - activeEmployees,
                        1,
                      )}
                    </Text>
                  </SelectColumn>
                  <Text mt={8} typography="title18">
                    Что входит:
                  </Text>
                  <Flex gap={8} direction="column">
                    {rows.map(row => (
                      <TooltipRow key={row.title} {...row} />
                    ))}
                  </Flex>
                  <Divider style={{margin: '8px 0'}} />
                  <div>
                    <Flex alignItems="center" gap={5} justifyContent="center">
                      <Text
                        style={{width: 'max-content'}}
                        typography="title24"
                        color="mainPrimary">
                        {getPriceFormat(calcPrice)}
                      </Text>
                      <Text style={{width: 'max-content'}}>/ мес</Text>
                    </Flex>
                    <Text
                      mt={8}
                      align="center"
                      typography="footNote12Regular"
                      color="textTertiary">
                      Цены указаны за месяц при годовой оплате
                    </Text>
                  </div>
                </Content>
                <Button
                  disabled={plans.length === 0}
                  onClick={() => {
                    if (currentTariffPlan) {
                      setVisible(true);
                      return;
                    }
                    const state = selectedPlan ?? plans[0];
                    if (currentTariffPlan && state.slots < activeEmployees) {
                      navigate('/change-tariff', {
                        state: {plan: state, companyId},
                      });
                      return;
                    }
                    navigate('/payment-tariff', {
                      state: {plan: state, companyId},
                    });
                  }}
                  size="large"
                  typography="text16Semibold">
                  {currentTariffPlan
                    ? 'Изменить тариф'
                    : 'Перейти к оформлению'}
                </Button>
                <Text
                  color="textTertiary"
                  align="center"
                  typography="subHead14Regular">
                  Тарифный план будет продлеваться автоматически, в зависимости
                  от выбранного типа оплаты. Подписку можно отменить в любое
                  время
                </Text>
              </Slide>
            )}
            {dataConfig?.config.find(
              item => item.key === 'BUSINESS_HIDE_FREE_PLAN',
            )?.value === '0' ? (
              <FreeSlide
                countActiveEmployees={activeEmployees}
                companyId={companyId}
                tariffPlan={currentTariffPlan}
              />
            ) : null}
          </SlidesContainer>
          <Popup visible={visible} onClose={() => setVisible(false)}>
            <PopupContainer>
              <Flex justifyContent="space-between" alignItems="center">
                <Text typography="title20">Смена тарифа</Text>
                <CloseButton onClose={() => setVisible(false)} />
              </Flex>
              <Text>
                У вас есть действующий тариф. Вы уверены, что хотите сменить
                его? После подтверждения вам начислятся баллы за
                неиспользованные дни
              </Text>
              <Flex gap={8}>
                <CancelButton
                  variant="outline"
                  onClick={() => setVisible(false)}
                  size="large">
                  Отмена
                </CancelButton>
                <Button
                  style={{flex: 1}}
                  onClick={() => {
                    const state = selectedPlan ?? plans[0];
                    if (currentTariffPlan && state.slots < activeEmployees) {
                      navigate('/change-tariff', {
                        state: {plan: state, companyId},
                      });
                      return;
                    }
                    navigate('/payment-tariff', {
                      state: {plan: state, companyId},
                    });
                  }}
                  size="large">
                  Сменить тариф
                </Button>
              </Flex>
            </PopupContainer>
          </Popup>
        </Container>
      </Wrapper>
    </Layout>
  );
}

const SkeletonPayment = () => {
  return (
    <Slide>
      <Content gap={'0'}>
        <Skeleton height={411}>
          {Array(7)
            .fill(0)
            .map((_, index) => (
              <rect
                key={index + '_line_skeleton'}
                x={0}
                y={index * 61}
                width={`calc(100% * ${skeletonWidthRow[index]})`}
                height={45}
                rx={8}
                ry={8}
              />
            ))}
        </Skeleton>
      </Content>
    </Slide>
  );
};
