import React, {useState} from 'react';
import {styled} from 'styled-components';
import {CustomerGender} from 'shared/__generated__/graphql';
import {FilterButton} from 'shared/ui/FilterButton';
import {Gender} from 'shared/lib/utils';
import {Modal} from 'shared/ui/Modal';
import {List} from 'shared/ui/List';
import {Text} from 'shared/ui/Text';

const PopupWrapper = styled.div`
  position: relative;
  display: inline-block;
`;
const ButtonPeriod = styled.button<{checked?: boolean}>`
  display: flex;
  padding: 0 16px;
  align-items: center;
  height: 40px;
  width: 343px;
  background-color: ${({checked, theme}) =>
    checked ? theme.bgSecondary : 'transparent'};
  border: none;
`;

export const FilterStatisticsGender = ({
  gender,
  setGender,
}: {
  gender?: CustomerGender;
  setGender?: (gender?: CustomerGender) => void;
}) => {
  const [visible, setVisible] = useState(false);
  return (
    <PopupWrapper>
      <FilterButton
        onClick={() => setVisible(true)}
        active={!!gender}
        title={gender ? Gender[gender] : 'Пол'}
        visible={visible}
      />
      <Modal
        style={{
          left: 'auto',
          right: 'auto',
          top: 64,
          paddingLeft: 0,
          paddingRight: 0,
          gap: 8,
        }}
        visible={visible}
        onClose={() => setVisible(false)}>
        <List
          data={['Пол', ...Object.values(Gender)]}
          keyExtractor={(_, index) => 'key_' + index}
          renderItem={(item, index) => (
            <ButtonPeriod
              onClick={() => {
                if (index === 0) {
                  setGender && setGender(undefined);
                } else {
                  const values = Object.keys(Gender) as CustomerGender[];
                  setGender && setGender(values[index - 1]);
                }
                setVisible(false);
              }}
              checked={
                (index === 0 && !gender) || (gender && Gender[gender] === item)
              }>
              <Text>{item}</Text>
            </ButtonPeriod>
          )}
        />
      </Modal>
    </PopupWrapper>
  );
};
