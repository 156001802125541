import React, {useState, useEffect} from 'react';
import {styled} from 'styled-components';
import {FilterButton} from 'shared/ui/FilterButton';
import {Modal} from 'shared/ui/Modal';
import {List} from 'shared/ui/List';
import {Text} from 'shared/ui/Text';
import {useLazyQuery} from '@apollo/client';
import {gql} from 'shared/__generated__';
import {useAppSelector} from 'shared/store';
import {Flex} from 'shared/ui/Flex';
import {Loading} from 'shared/ui/Loading';

const GET_CERTIFICATES = gql(`
  query CertificateTemplatesFilter($companyId: String!, $first: Int!, $page: Int) {
    certificateTemplates(company_id: $companyId, first: $first, page: $page) {
      paginatorInfo {
        currentPage
        total
      }
      data {
        id
        name
        balance
      }
    }
  }
`);

const PopupWrapper = styled.div`
  position: relative;
  display: inline-block;
`;
const ButtonEmployee = styled.button<{checked?: boolean}>`
  display: flex;
  flex-direction: row;
  padding: 0 16px;
  align-items: center;
  height: 44px;
  width: 343px;
  gap: 8px;
  background-color: ${({checked, theme}) =>
    checked ? theme.bgSecondary : 'transparent'};
  border: none;
`;

export const FilterStatisticsCertificate = ({
  certificateId,
  setCertificateId,
}: {
  certificateId?: string;
  setCertificateId?: (id: string) => void;
}) => {
  const companyId = useAppSelector(state => state.company.data!.id);
  const [lazy, {data, loading}] = useLazyQuery(GET_CERTIFICATES, {
    variables: {
      companyId,
      first: 100,
    },
  });
  const certificates = data?.certificateTemplates?.data;
  const certificate = certificates?.find(item => item.id === certificateId);
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    if (visible) lazy();
  }, [lazy, visible]);
  return (
    <PopupWrapper>
      <FilterButton
        onClick={() => setVisible(true)}
        active={!!certificateId}
        title={certificate ? certificate.name : 'Все сертификаты'}
        visible={visible}
      />
      <Modal
        style={{
          left: 'auto',
          right: 'auto',
          top: 64,
          padding: 0,
          gap: 8,
        }}
        visible={visible}
        onClose={() => setVisible(false)}>
        {loading ? (
          <Flex justifyContent="center" style={{width: 343}}>
            <Loading />
          </Flex>
        ) : (
          <Flex
            direction="column"
            style={{
              maxHeight: 284,
              overflowY: 'auto',
              paddingTop: 24,
            }}>
            <List
              gap={16}
              keyExtractor={(_, index) => 'key_' + index}
              data={[
                {name: 'Все сертификаты', id: ''},
                ...(certificates ?? []),
              ]}
              renderItem={(item, index) => (
                <ButtonEmployee
                  style={{
                    marginBottom: certificates?.length === index ? 24 : 0,
                  }}
                  onClick={() => {
                    setCertificateId && setCertificateId(item.id);
                    setVisible(false);
                  }}
                  checked={item.id === certificateId}>
                  <Text>{item.name}</Text>
                </ButtonEmployee>
              )}
            />
          </Flex>
        )}
      </Modal>
    </PopupWrapper>
  );
};
