import React from 'react';
import {device} from 'shared/device';
import {Text} from 'shared/ui/Text';

import styled from 'styled-components';

const CustomTextInput = styled.input`
  flex: 1;
  width: 100%;
  height: 56px;
  border-width: 1px;
  border-radius: 14px;
  text-align: center;
  background-color: transparent;
  border-color: ${({theme}) => theme.fillPrimary};
  color: ${({theme}) => theme.textPrimary};
  border-style: solid;
  outline-style: none;
  font-family: 'Inter';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  @media (${device.mobile}) {
    height: 56px;
    font-size: 14px;
  }
`;

const Row = styled.div<{show: boolean}>`
  display: ${({show}) => (show ? 'flex' : 'none')};
  gap: 8px;
  align-items: center;
`;

export const CustomSchedule = ({
  start,
  end,
  show,
  onChange,
}: {
  start: string;
  end: string;
  show: boolean;
  onChange: (value: string, type: 'workday' | 'dayoff') => void;
}) => (
  <Row show={show}>
    <CustomTextInput
      type="number"
      placeholder="Рабочий"
      value={start}
      onChange={e => onChange(e.target.value, 'workday')}
    />
    <Text typography="subHead14Regular">через</Text>
    <CustomTextInput
      placeholder="Нерабочий"
      type="number"
      onChange={e => onChange(e.target.value, 'dayoff')}
      value={end}
    />
  </Row>
);
