import React from 'react';
import {useColors} from 'shared/lib/hooks';

export function ArrowRight2v({
  style,
  color,
  size = 24,
}: {
  style?: React.CSSProperties;
  color?: string;
  size?: number;
}) {
  const colors = useColors();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}>
      <path
        d="M10 5L16 12L10 19"
        stroke={color ?? colors.mainPrimary}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
