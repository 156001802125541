import React, {useEffect, useState} from 'react';
import {Popup} from 'shared/ui/Popup';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';
import {Grow} from 'shared/ui/Grow';
import {List} from 'shared/ui/List';
import {gql} from 'shared/__generated__';
import {ApolloError, useLazyQuery, useMutation} from '@apollo/client';
import {useAppSelector} from 'shared/store';
import {Flex} from 'shared/ui/Flex';
import {RadioButton} from 'shared/ui/RadioButton';
import {Button} from 'shared/ui/Button';
import {showAlert} from 'shared/ui/Alert';
import {CloseButton} from 'shared/ui/CloseButton';
import {Skeleton} from 'shared/ui/Skeleton';
import {TextInput} from 'shared/ui/TextInput';
import {Search} from 'shared/icons/Search';
import {useNavigate} from 'react-router-dom';
import {useColors} from 'shared/lib/hooks';
import {EmptyWhiteContent} from 'shared/illustration/EmptyWriteContent';

const Row = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 438px;
`;

const Bonus = styled.div`
  display: flex;
  height: 48px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 438px;
`;

const CERTIFICATES = gql(`
query AddCertificatesCustomer(
  $first: Int!
  $companyId: String!
  $page: Int!
  $name: String
) {
  certificateTemplates(
    first: $first
    company_id: $companyId
    page: $page
    name: $name
  ) {
    paginatorInfo {
      hasMorePages
      lastPage
      currentPage
      total
    }
    data {
      id
      name
    }
  }
}
`);

const CREATE_CERTIFICATE = gql(`
  mutation CreateCertificate($input: CreateCertificate!) {
    createCertificate(input: $input) {
      id
    }
  }
`);

export const AddCertificatePopup = ({
  customerId,
  visible,
  onClose,
}: {
  customerId: string;
  visible?: boolean;
  onClose?: () => void;
}) => {
  const companyId = useAppSelector(state => state.company.data!.id);
  const colors = useColors();
  const [search, setSearch] = useState('');
  const [lazy, {data, loading}] = useLazyQuery(CERTIFICATES, {
    variables: {
      companyId,
      name: search,
      first: 40,
      page: 1,
    },
  });
  const [sellCertif, {loading: createLoading}] = useMutation(
    CREATE_CERTIFICATE,
    {
      refetchQueries: ['CustomerCertificates'],
    },
  );
  const certificates = data?.certificateTemplates?.data;
  const [select, setSelect] = useState<string>();
  useEffect(() => {
    visible && lazy();
  }, [lazy, visible]);

  return (
    <Popup {...{visible, onClose}}>
      <Grow style={{gap: 24}}>
        <Row>
          <Text typography="title20">Выберите сертификат</Text>
          <CloseButton
            onClose={() => {
              onClose && onClose();
            }}
          />
        </Row>
        <TextInput
          height={54}
          variant="default"
          onChange={text => setSearch(text)}
          value={search}
          placeholder="Поиск"
          leftElement={<Search color={colors.textTertiary} />}
        />
        {loading ? (
          <AddSkeleton />
        ) : !certificates?.length ? (
          <EmptyState search={search} />
        ) : (
          <>
            <Flex style={{maxHeight: 480, overflowY: 'scroll'}}>
              <List
                data={certificates}
                gap={16}
                keyExtractor={(_, index) => `certificate_${index}`}
                renderItem={certificate => {
                  return (
                    <Bonus>
                      <RadioButton
                        labelPosition="right"
                        title={certificate.name}
                        selected={select === certificate.id}
                        onChange={() => {
                          setSelect(certificate.id);
                        }}
                      />
                    </Bonus>
                  );
                }}
              />
            </Flex>
            <Button
              size="large"
              disabled={!select || createLoading}
              loading={createLoading}
              onClick={() => {
                select &&
                  sellCertif({
                    variables: {
                      input: {
                        customer_ids: [customerId],
                        certificate_template_id: select,
                      },
                    },
                  })
                    .then(() => {
                      onClose && onClose();
                    })
                    .catch((e: ApolloError) => showAlert('error', e.message));
              }}>
              Продать
            </Button>
          </>
        )}
      </Grow>
    </Popup>
  );
};

const EmptyState = ({search}: {search: string}) => {
  const navigate = useNavigate();
  const username = useAppSelector(state => state.company.data?.username);
  return (
    <Flex
      style={{width: 438}}
      flex={1}
      direction="column"
      gap={16}
      alignItems="center">
      <EmptyWhiteContent size={300} />
      {!search && <Text typography="title20">Нету сертификатов</Text>}
      <Text align="center" typography="text18" color="textTertiary">
        {search ? 'Не найдено' : 'У данного клиента пока нет сертификатов'}
      </Text>
      {!search && (
        <Button
          style={{width: '100%', marginTop: 16}}
          size="large"
          onClick={() => navigate(`/${username}/create-certificate`)}>
          Добавить сертификаты
        </Button>
      )}
    </Flex>
  );
};

const AddSkeleton = () => {
  return (
    <Skeleton width={438} height={312}>
      {Array.from(Array(5).keys()).map((columnItem, index) => (
        <React.Fragment key={'column_' + columnItem}>
          <circle r={12} cx={24} cy={64 * index + 24} />
          <rect
            width={210}
            height={22}
            rx={11}
            ry={11}
            x={48}
            y={64 * index + 12}
          />
        </React.Fragment>
      ))}
    </Skeleton>
  );
};
