import React from 'react';
import styled from 'styled-components';
import {Text} from 'shared/ui/Text';
import {Flex} from 'shared/ui/Flex';
import {Radio} from 'shared/icons/Radio';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  pointer-events: all;
  cursor: pointer;
  gap: 8px;
  &:hover {
    background-color: ${({theme}) => theme.bgSecondary};
  }
`;

const ViewIcon = styled.div`
  width: 24px;
  height: 24px;
`;

type Props = {
  title: string;
  description: string;
  checked?: boolean;
  onClick?: () => void;
};

export const WebhookRadioBox = (props: Props) => {
  const {title, description, checked, onClick} = props;
  return (
    <Wrapper onClick={onClick}>
      <ViewIcon>
        <Radio active={checked} />
      </ViewIcon>
      <Flex direction="column" gap={4}>
        <Text style={{pointerEvents: 'none'}}>{title}</Text>
        <Text
          color="textTertiary"
          typography="subHead14Regular"
          style={{pointerEvents: 'none'}}>
          {description}
        </Text>
      </Flex>
    </Wrapper>
  );
};
