import dayjs from 'dayjs';
import React, {useState} from 'react';
import {weekdays} from 'shared/lib/utils';
import {Flex} from 'shared/ui/Flex';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';

const DaysWrapper = styled.button`
  padding: 0;
  background-color: transparent;
  border: none;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
`;
export const Day = styled.div<{selected: boolean}>`
  width: 36px;
  height: 36px;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  background-color: ${({theme, selected}) =>
    selected ? theme.mainPrimary : 'transparent'};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${({theme, selected}) => (selected ? theme.white : theme.textPrimary)};
  pointer-events: all;
  border: none;
  cursor: pointer;
  border: 1px solid transparent;
  &:hover {
    background-color: ${({theme, selected}) =>
      selected ? theme.mainPrimary : theme.mainLight50};
  }
  z-index: 3;
`;
const Dot = styled.div<{active: boolean}>`
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: ${({active, theme}) =>
    active ? theme.white : theme.mainPrimary};
`;
const SelectedPeriod = styled.div<{gridColumnStart: number}>`
  display: flex;
  height: 48px;
  align-items: center;
  justify-content: center;
  pointer-events: all;
  cursor: pointer;
  grid-column-start: ${({gridColumnStart}) => gridColumnStart};
`;
const BackgroundDay = styled.div<{
  unActive: boolean;
  type: 'left' | 'middle' | 'right';
}>`
  background-color: ${({theme, unActive}) =>
    unActive ? 'transparent' : theme.borderPrimary};
  position: absolute;
  margin-right: ${({type}) => (type === 'right' ? 'calc((100% / 7) / 2)' : 0)};
  margin-left: ${({type}) => (type === 'left' ? 'calc((100% / 7) / 2)' : 0)};
  width: ${({type}) =>
    type === 'middle' ? 'calc(100% / 7)' : 'calc((100% / 7) / 2)'};
  height: 36px;
`;
const BackgroundCircle = styled.div<{active: boolean}>`
  display: ${({active}) => (active ? 'flex' : 'none')};
  position: absolute;
  height: 36px;
  width: 36px;
  background-color: ${({theme}) => theme.borderPrimary};
  border-radius: 18px;
`;

const getActive = (
  date: string,
  firstDayMonth: string,
  endDayMonth: string,
  week?: {start: string; end: string},
): {
  position: 'left' | 'right' | 'middle' | 'outside';
  start: boolean;
  end: boolean;
} => {
  if (!week) return {position: 'outside', start: false, end: false};
  if (date === week.start) return {position: 'left', start: true, end: false};
  if (date === week.end) return {position: 'right', start: false, end: true};
  const formattedDate = dayjs(date);
  const firstMonth = date === firstDayMonth;
  const endMonth = date === endDayMonth;
  const isInsideWeek =
    formattedDate.isAfter(dayjs(week.start).add(-1, 'd')) &&
    formattedDate.isBefore(dayjs(week.end).add(1, 'd'));
  return {
    position: isInsideWeek
      ? firstMonth
        ? 'left'
        : endMonth
        ? 'right'
        : 'middle'
      : 'outside',
    start: false,
    end: false,
  };
};

const getActiveInput = (
  date: string,
  firstDayMonth: string,
  endDayMonth: string,
  period?: {start: string; end: string},
  hoverDate?: string,
): {
  position: 'left' | 'right' | 'middle' | 'outside';
  start: boolean;
  end: boolean;
} => {
  if (!period) return {position: 'outside', start: false, end: false};
  const startValid = dayjs(period.start, 'DD.MM.YYYY').isValid();
  const endValid = dayjs(period.end, 'DD.MM.YYYY').isValid();
  const startWeek = date === dayjs(date).startOf('week').format('YYYY-MM-DD');
  const endWeek = date === dayjs(date).endOf('week').format('YYYY-MM-DD');
  const startMonth = date === firstDayMonth;
  const endMonth = date === endDayMonth;
  const formattedDate = dayjs(date);

  if (startValid && !endValid && hoverDate) {
    const formattedStart = dayjs(period.start, 'DD.MM.YYYY').format(
      'YYYY-MM-DD',
    );
    const isRevert = dayjs(period.start, 'DD.MM.YYYY').isAfter(hoverDate);
    if (formattedStart === hoverDate && hoverDate === date)
      return {position: 'outside', start: true, end: false};
    const start = isRevert ? hoverDate : formattedStart;
    const endHover = isRevert ? formattedStart : hoverDate;
    if (date === start)
      return {
        position: !endWeek && !endMonth ? 'left' : 'outside',
        start: !isRevert,
        end: false,
      };
    if (date === endHover)
      return {
        position: !startWeek && !startMonth ? 'right' : 'outside',
        start: false,
        end: isRevert,
      };
    const isInsideHoverPeriod =
      formattedDate.isAfter(dayjs(start).add(-1, 'd')) &&
      formattedDate.isBefore(dayjs(endHover).add(1, 'd'));
    return {
      position: isInsideHoverPeriod
        ? startMonth || startWeek
          ? 'left'
          : endMonth || endWeek
          ? 'right'
          : 'middle'
        : 'outside',
      start: formattedStart === date,
      end: false,
    };
  }
  const formattedStart = startValid
    ? dayjs(period.start, 'DD.MM.YYYY').format('YYYY-MM-DD')
    : period.start;
  const formattedEnd = endValid
    ? dayjs(period.end, 'DD.MM.YYYY').format('YYYY-MM-DD')
    : period.end;
  if (!startValid || !endValid)
    return {
      position: 'outside',
      start: startValid ? formattedStart === date : false,
      end: endValid ? formattedEnd === date : false,
    };

  const isRevert = dayjs(formattedStart).isBefore(formattedEnd);
  const start = isRevert ? formattedStart : formattedEnd;
  const end = isRevert ? formattedEnd : formattedStart;
  if (date === start)
    return {
      position: !endWeek && !endMonth ? 'left' : 'outside',
      start: true,
      end: false,
    };
  if (date === end)
    return {
      position: !startWeek && !startMonth ? 'right' : 'outside',
      start: false,
      end: true,
    };

  const isInsidePeriod =
    formattedDate.isAfter(dayjs(start).add(-1, 'd')) &&
    formattedDate.isBefore(dayjs(end).add(1, 'd'));
  return {
    position: isInsidePeriod
      ? startMonth || startWeek
        ? 'left'
        : endMonth || endWeek
        ? 'right'
        : 'middle'
      : 'outside',
    start: false,
    end: false,
  };
};

export const Days = ({
  days,
  date,
  input = false,
  variant,
  period,
  onClick,
}: {
  days: string[];
  date?: string;
  input?: boolean;
  variant: 'day' | 'week';
  period?: {start: string; end: string};
  onClick: (value: string) => void;
}) => {
  const [hoverDate, setHoverDate] = useState<string>();
  return (
    <Flex direction="column">
      <Flex
        direction="row"
        alignItems="center"
        style={{justifyContent: 'space-around', height: 43}}>
        {weekdays.map(weekday => (
          <Text
            key={weekday}
            typography="footNote12Medium"
            color={
              dayjs().format('dd') === weekday ? 'mainPrimary' : 'textTertiary'
            }>
            {weekday}
          </Text>
        ))}
      </Flex>
      <DaysWrapper>
        {days.map(day => {
          const startWeek = dayjs(day).startOf('week').format('YYYY-MM-DD');
          const endWeek = dayjs(day).startOf('week').format('YYYY-MM-DD');
          const check = hoverDate === startWeek || hoverDate === endWeek;
          const {position, start, end} = input
            ? getActiveInput(
                day,
                days[0],
                days[days.length - 1],
                period,
                hoverDate,
              )
            : getActive(day, days[0], days[days.length - 1], period);
          const activeDay = variant === 'day' ? date === day : start || end;
          const isToday =
            dayjs().format('YYYY-MM-DD') === dayjs(day).format('YYYY-MM-DD');
          const isActive =
            variant === 'day'
              ? dayjs().format('YYYY-MM-DD') === date
              : start || end;

          return (
            <SelectedPeriod
              onClick={e => {
                e.stopPropagation();
                onClick(day);
              }}
              onMouseMoveCapture={() => {
                setHoverDate(day);
              }}
              onMouseLeave={() => {
                setHoverDate(undefined);
              }}
              gridColumnStart={
                dayjs(day).get('d') === 0 ? 7 : dayjs(day).get('d')
              }
              key={'day_' + day}>
              {variant === 'week' && position !== 'outside' && period && (
                <>
                  <BackgroundDay unActive={check} type={position} />
                  <BackgroundCircle active={position !== 'middle'} />
                </>
              )}
              <Day selected={activeDay}>
                {dayjs(day).format('D')}
                {isToday && <Dot active={isActive} />}
              </Day>
            </SelectedPeriod>
          );
        })}
      </DaysWrapper>
    </Flex>
  );
};
