import {useMutation, useQuery, ApolloError} from '@apollo/client';
import {ModalClient} from 'entities/loyalty';
import React, {useState} from 'react';
import {useParams} from 'react-router-dom';
import {gql} from 'shared/__generated__';
import {CircleClose} from 'shared/icons/CircleClose';
import {EmptyWhiteContent} from 'shared/illustration/EmptyWriteContent';
import {getName, getPhoneMask} from 'shared/lib/utils';
import {showAlert} from 'shared/ui/Alert';
import {Button} from 'shared/ui/Button';
import {Content} from 'shared/ui/Content';
import {Flex} from 'shared/ui/Flex';
import {IconButton} from 'shared/ui/IconButton';
import {Layout} from 'shared/ui/Layout';
import {List} from 'shared/ui/List';
import {NavigateBack} from 'shared/ui/NavigateBack';
import {Seo} from 'shared/ui/Seo';
import {Skeleton} from 'shared/ui/Skeleton';
import {TableViewRow} from 'shared/ui/TableViewRow';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';

const CREATE_CERTIFICATE = gql(`
mutation DeleteCertificate($input: CreateCertificate!) {
    createCertificate(input: $input) {
      id
    }
  }
`);

const Head = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding-top: 64px;
  align-items: center;
  background-color: ${({theme}) => theme.bgSecondary};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  grid-column-start: 4;
  grid-column-end: 10;
  padding-bottom: 24px;
  gap: 32px;
`;

const GET_CERTIFICATES = gql(`
  query CertificateTemplateCertificates($id: String!) {
    certificateTemplate(id: $id) {
      certificates {
        id
        customer {
          id
          name
          surname
          phone
        }
      }
    }
  }
`);

const DELETE_CERTIFICATE = gql(`
  mutation DeleteCertificateCustomer($id: String!) {
    deleteCertificate(id: $id) {
      id
    }
  }
`);

export const IssueCertificate = () => {
  const params = useParams();
  const id = params.id!;
  const [modal, setModal] = useState(false);
  const {data, loading} = useQuery(GET_CERTIFICATES, {
    variables: {
      id,
    },
  });
  const [createCertificate, {loading: loadinCreate}] = useMutation(
    CREATE_CERTIFICATE,
    {
      refetchQueries: ['CertificateTemplates', 'GetCertificateById'],
    },
  );
  const [deleteCertificate] = useMutation(DELETE_CERTIFICATE, {
    refetchQueries: ['CertificateTemplates', 'certificateTemplate'],
  });

  const customers =
    data?.certificateTemplate?.certificates.map(item => ({
      certificate_id: item.id,
      id: item.customer.id,
      name: item.customer.name,
      surname: item.customer.surname,
      phone: item.customer.phone,
    })) ?? [];

  return (
    <Layout>
      <Seo title="Продать сертификат" />
      <Wrapper>
        <Head>
          <NavigateBack />
          <Text typography="title24">Продать сертификат</Text>
        </Head>
        <Content gap="24px">
          <Text typography="title18">Клиенты</Text>
          {loading ? (
            <CertificateSkeleton />
          ) : customers.length === 0 ? (
            <Flex direction="column" gap={16}>
              <EmptyWhiteContent size={300} />
              <Text typography="text18" color="textTertiary" align="center">
                У вас нет выданных сертификатов клиентам. Вы <br /> можете
                выбрать клиентов и выдать им бонусные карты
              </Text>
            </Flex>
          ) : (
            <List
              data={customers}
              gap={16}
              renderItem={customer => (
                <TableViewRow
                  style={{width: '100%', height: 64}}
                  title={getName(customer.name, customer.surname)}
                  subtitle={getPhoneMask(customer.phone)}
                  rightElement={() => (
                    <IconButton
                      size={44}
                      variant="unstyled"
                      onClick={() => {
                        if (customer.certificate_id) {
                          deleteCertificate({
                            variables: {
                              id: customer.certificate_id,
                            },
                          })
                            .then(() => {
                              showAlert('success', 'Сертификат успешно удален');
                            })
                            .catch(e => {
                              if (e instanceof ApolloError) {
                                showAlert('error', e.message);
                              }
                            });
                        }
                      }}>
                      <CircleClose size={32} />
                    </IconButton>
                  )}
                />
              )}
            />
          )}
        </Content>
        <div style={{width: '100%'}}>
          <Button
            typography="text16Semibold"
            size="large"
            style={{width: '100%'}}
            onClick={() => setModal(true)}>
            Продать сертификат
          </Button>
        </div>
        <ModalClient
          visible={modal}
          loading={loadinCreate}
          onSelect={values => {
            createCertificate({
              variables: {
                input: {
                  certificate_template_id: id,
                  customer_ids: values,
                },
              },
            })
              .then(() => setModal(false))
              .catch(e => {
                if (e instanceof ApolloError) {
                  showAlert('error', e.message);
                }
              });
          }}
          onClose={() => setModal(false)}
        />
      </Wrapper>
    </Layout>
  );
};

const CertificateSkeleton = () => {
  return (
    <Skeleton style={{marginTop: 4}} width={'100%'} height={64 * 5}>
      {Array.from(Array(4).keys()).map((_, index) => (
        <React.Fragment key={'bonus_sk_' + index}>
          <rect
            y={8 + index * (64 + 16)}
            width={'55%'}
            height={24}
            rx={4}
            ry={4}
          />
          <rect
            y={36 + index * (64 + 16)}
            width={'40%'}
            height={20}
            rx={4}
            ry={4}
          />
          <circle r={12} cy={32 + index * (48 + 16)} cx={'calc(100% - 12px)'} />
        </React.Fragment>
      ))}
    </Skeleton>
  );
};
