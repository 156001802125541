import React from 'react';
import styled from 'styled-components';
import {device} from 'shared/device';
import {CompanyBookingStatus} from 'shared/__generated__/graphql';

const StatusContainer = styled.div<{type: CompanyBookingStatus}>`
  display: inline-flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  white-space: nowrap;
  border-radius: 12px;
  background-color: ${({type, theme}) =>
    type === CompanyBookingStatus.Completed
      ? theme.bgSuccess
      : type === CompanyBookingStatus.Cancelled
      ? theme.bgDanger
      : type === CompanyBookingStatus.Pending
      ? theme.mainLight
      : theme.fillPrimary};
  font-family: 'Inter';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  color: ${({type, theme}) =>
    type === CompanyBookingStatus.Completed
      ? theme.successPrimary
      : type === CompanyBookingStatus.Cancelled
      ? theme.dangerPrimary
      : type === CompanyBookingStatus.Pending
      ? theme.waitingPrimary
      : theme.textSecondary};
  @media ${device.mobile} {
    padding: 8px 14px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
`;

export const BadgeStatus = ({
  children,
  type = CompanyBookingStatus.Pending,
}: {
  children: string;
  type?: CompanyBookingStatus;
}) => {
  return <StatusContainer type={type}>{children}</StatusContainer>;
};
