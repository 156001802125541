import React, {useEffect} from 'react';
import {Content} from 'shared/ui/Content';
import {TableViewRow} from 'shared/ui/TableViewRow';
import {gql} from 'shared/__generated__';
import {useMutation, useQuery} from '@apollo/client';
import styled from 'styled-components';
import {device} from 'shared/device';
import {Switch} from 'shared/ui/Switch';
import {Button} from 'shared/ui/Button';
import {useAppSelector} from 'shared/store';
import {showAlert} from 'shared/ui/Alert';
import {ensureError} from 'shared/lib/utils';
import {Seo} from 'shared/ui/Seo';

const OPTION = gql(`
query NotificationOption {
  me {
    id
    email_notification_enabled
  }
}
`);

const UPDATE = gql(`
mutation NotificationUpdate($input: UpdateMe!) {
  updateMe(input: $input) {
    email_notification_enabled
  }
}
`);

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  padding-bottom: 24px;
  flex-direction: column;
  gap: 32px;
  margin-top: 8px;
  @media (${device.mobile}) {
    gap: 8px;
    margin-bottom: 0;
    border-radius: 0;
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export function PushNotificationSetting() {
  const userId = useAppSelector(state => state.auth.userId);
  useQuery(OPTION, {
    onCompleted: data => {
      setMail(data.me?.email_notification_enabled ?? false);
    },
  });

  const [update, {loading: updateLoading}] = useMutation(UPDATE, {
    refetchQueries: [OPTION],
  });
  const [pushEnabled, setPushEnabled] = React.useState(false);
  const [mail, setMail] = React.useState<boolean>(false);

  const submit = async () => {
    try {
      update({
        variables: {
          input: {
            id: userId!,
            email_notification_enabled: mail,
          },
        },
      });
      showAlert('success', 'Сохранено');
    } catch (e) {
      const error = ensureError(e);
      showAlert('error', error.message);
    }
  };

  useEffect(() => {
    const checkNotificationPermission = async () => {
      const permission = await Notification.requestPermission();
      setPushEnabled(permission === 'granted');
    };

    if ('Notification' in window) {
      checkNotificationPermission();
    }
  }, []);

  const handlePushSwitchChange = async () => {
    if (!('Notification' in window)) {
      showAlert(
        'warning',
        'PUSH-уведомления не поддерживаются вашим браузером',
      );
      return;
    }

    if (Notification.permission === 'granted') {
      showAlert(
        'warning',
        'Вы должны отключить PUSH-уведомления в настройках вашего браузера',
      );
      return;
    }

    if (Notification.permission === 'denied') {
      showAlert(
        'warning',
        'Вы должны разрешить PUSH-уведомления в настройках вашего браузера',
      );
      return;
    }

    const permission = await Notification.requestPermission();
    setPushEnabled(permission === 'granted');
  };

  return (
    <Wrapper>
      <Seo title="Настройка уведомлений" />
      <Content>
        <TableViewRow
          style={{padding: '10px 0'}}
          title="PUSH-уведомления"
          rightElement={() => (
            <Switch value={pushEnabled} onChange={handlePushSwitchChange} />
          )}
        />
        <TableViewRow
          style={{padding: '10px 0'}}
          title="Email-уведомления"
          rightElement={() => <Switch value={mail} onChange={setMail} />}
        />
      </Content>
      <Button
        size="large"
        disabled={updateLoading}
        loading={updateLoading}
        onClick={submit}>
        Сохранить
      </Button>
    </Wrapper>
  );
}
