import React, {useState} from 'react';
import {Content} from 'shared/ui/Content';
import {Skeleton} from 'shared/ui/Skeleton';
import {Seo} from 'shared/ui/Seo';
import {Table} from 'shared/ui/Table';
import {
  MessageMethod,
  MessageSampleEvent,
  MessageStatus,
} from 'shared/__generated__/graphql';
import {getName} from 'shared/lib/utils';
import dayjs from 'dayjs';
import styled from 'styled-components';
import {Flex} from 'shared/ui/Flex';
import {Text} from 'shared/ui/Text';
import {ApolloError, useMutation, useQuery} from '@apollo/client';
import {useNavigate, useParams} from 'react-router-dom';
import {DELETE_MESSAGE, Send, TooltipMessage} from 'entities/messages';
import {GET_BOOKING_MESSAGES} from 'entities/booking';
import {showAlert} from 'shared/ui/Alert';
import {useEmployeePermission} from 'shared/lib/hooks';
import {useAppSelector} from 'shared/store';
import {Button} from 'shared/ui/Button';
import {EmptySendEmail} from 'shared/illustration/EmptySendEmail';

const getLabelStatus = (event: MessageStatus) => {
  switch (event) {
    case MessageStatus.Cancelled:
      return 'Отменено';
    case MessageStatus.Completed:
      return 'Отправлено';
    default:
      return 'Не отправлено';
  }
};

const getLabelEvent = (event: MessageSampleEvent) => {
  switch (event) {
    case MessageSampleEvent.After:
      return 'После визита';
    case MessageSampleEvent.Before:
      return 'До визита';
    default:
      return 'Новая запись';
  }
};

const getMethodLabel = (value?: MessageMethod | null) => {
  switch (value) {
    case MessageMethod.CpTelegram:
      return 'ChatPush-Telegram';
    case MessageMethod.Whatsapp:
      return 'Whatsapp';
    case MessageMethod.Telegram:
      return 'Telegram';
    case MessageMethod.SmsRu:
      return 'Sms.ru';
    case MessageMethod.CpWhatsapp:
      return 'ChatPush-WhatsApp';
    default:
      return 'Смс';
  }
};
const Status = styled.div<{sent: boolean}>`
  height: 40px;
  border-radius: 12px;
  padding: 8px 16px;
  width: max-content;
  font-family: 'Inter';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  color: ${({theme, sent}) =>
    sent ? theme.dangerPrimary : theme.textTertiary};
  background-color: ${({theme, sent}) =>
    sent ? theme.bgDanger : theme.fillPrimary};
`;

export const MessagesBooking = () => {
  const navigate = useNavigate();
  const username = useAppSelector(state => state.company.data?.username);
  const params = useParams();
  const {id} = params;
  const [sendIndexModal, setSendIndexModal] = useState<number | null>(null);
  const companyId = useAppSelector(state => state.company.data!.id);
  const {data, loading, refetch} = useQuery(GET_BOOKING_MESSAGES, {
    variables: {bookingId: id!, companyId},
  });
  const {delete_messages_permission, message_sample_permission} =
    useEmployeePermission(data);
  const [deleteMessage] = useMutation(DELETE_MESSAGE, {
    refetchQueries: [
      {
        query: GET_BOOKING_MESSAGES,
        variables: {bookingId: id!},
      },
    ],
  });
  const messages = data?.booking?.messages ?? [];

  return (
    <>
      <Seo title="Рассылки" />
      {loading ? (
        <SkeletonMessages />
      ) : messages.length > 0 ? (
        <Content style={{width: 1092}} gap="24px">
          <Table
            titles={[
              'Период визита',
              'Клиент',
              'Дата отправки',
              'Метод отправки',
              'Статус',
            ]}
            onClick={index => {
              setSendIndexModal(index);
            }}
            rows={messages.map((item, index) => [
              item.messageSample
                ? getLabelEvent(item.messageSample?.event)
                : null,
              getName(item.customer.surname, item.customer.name),
              dayjs(item.send_at).format('DD MMM YYYY, в HH:mm'),
              getMethodLabel(item.method),
              <Status
                key={item.id}
                sent={item.status === MessageStatus.Pending}>
                {getLabelStatus(item.status)}
              </Status>,
              delete_messages_permission ? (
                <TooltipMessage
                  onClick={() => {
                    deleteMessage({
                      variables: {id: item.id},
                    })
                      .then(() => {
                        showAlert('success', 'рассылка удалена');
                        refetch();
                      })
                      .catch((e: ApolloError) => showAlert('error', e.message));
                  }}
                  key={'bookink_tooltip_' + index}
                />
              ) : null,
            ])}
          />
        </Content>
      ) : (
        <Flex
          flex={1}
          alignItems="center"
          justifyContent="center"
          direction="column"
          gap={16}>
          <EmptySendEmail size={300} />
          <Text typography="title20">Рассылки нет</Text>
          <Text color="textTertiary" align="center">
            У вас пока нет рассылок. Запустите рассылку и <br /> привлекайте
            клиентов
          </Text>
          {message_sample_permission && (
            <Button
              size="large"
              onClick={() => navigate(`/${username}/create-message-sample`)}
              style={{marginTop: 16}}>
              Перейти к шаблонам рассылок
            </Button>
          )}
        </Flex>
      )}
      <Send
        data={sendIndexModal !== null ? messages[sendIndexModal] : null}
        onClose={() => setSendIndexModal(null)}
      />
    </>
  );
};

const SkeletonMessages = () => {
  return (
    <Content style={{width: 1092}} gap="24px">
      <Skeleton height={300}>
        {Array(3)
          .fill(0)
          .map((_, index) => (
            <React.Fragment key={'mailing_sk_' + index}>
              <rect
                width={200}
                height={24}
                rx={12}
                ry={12}
                x={0}
                y={28 + 80 * index}
              />
              <rect
                width={125}
                height={24}
                rx={12}
                ry={12}
                y={28 + 80 * index}
                x="25%"
              />
              <rect
                width={170}
                height={24}
                rx={12}
                ry={12}
                y={28 + 80 * index}
                x="42%"
              />
              <rect
                width={75}
                height={24}
                rx={12}
                ry={12}
                y={28 + 80 * index}
                x="65%"
              />
              <rect
                width={130}
                height={40}
                rx={12}
                ry={12}
                y={20 + 80 * index}
                x="83%"
              />
            </React.Fragment>
          ))}
      </Skeleton>
    </Content>
  );
};
