import {useMutation, useQuery, ApolloError} from '@apollo/client';
import React, {useState} from 'react';
import {useParams} from 'react-router-dom';
import {gql} from 'shared/__generated__';
import {CircleClose} from 'shared/icons/CircleClose';
import {getName, getPhoneMask} from 'shared/lib/utils';
import {Button} from 'shared/ui/Button';
import {Content} from 'shared/ui/Content';
import {IconButton} from 'shared/ui/IconButton';
import {Layout} from 'shared/ui/Layout';
import {List} from 'shared/ui/List';
import {TableViewRow} from 'shared/ui/TableViewRow';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';
import {ModalClient} from 'entities/loyalty';
import {NavigateBack} from 'shared/ui/NavigateBack';
import {Seo} from 'shared/ui/Seo';
import {Flex} from 'shared/ui/Flex';
import {Skeleton} from 'shared/ui/Skeleton';
import {showAlert} from 'shared/ui/Alert';
import {EmptyIssuedCard} from 'shared/illustration/EmptyIssuedCard';

const Wrapper = styled.div`
  grid-column-start: 4;
  grid-column-end: 10;
  gap: 32px;
  display: flex;
  flex-direction: column;
  margin: 0 0 24px 0;
  position: relative;
`;

const Head = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  padding-top: 64px;
  background-color: ${({theme}) => theme.bgSecondary};
`;

const GET_BONUS_ISSUE_CUSTOMERS = gql(`
  query GetBonusIssuedCustomers($bonusCardTemplateId: String!) {
    bonusCardTemplate(id: $bonusCardTemplateId) {
      id
      bonusCards {
        id
        customer {
          id
          name
          surname
          phone
        }
      }
    }
  }
`);

const DELETE_BONUS_CARD = gql(`
  mutation DeleteBonusCardsCustomer($ids: [String!]!) {
    deleteBonusCards(ids: $ids) {
      id
    }
  }
`);

const CREATE_BONUS_CARDS = gql(`
  mutation CreateBonusCards($input: CreateBonusCard!) {
    createBonusCard(input: $input) {
      id
    }
  }
`);

export const IssueBonus = () => {
  const params = useParams();
  const bonusId = params.id!;

  const [customers, setCustomers] = useState<
    {
      bonus_id?: string;
      id: string;
      name?: string | null;
      surname?: string | null;
      phone?: string | null;
    }[]
  >([]);

  const [modal, setModal] = useState(false);
  const {loading} = useQuery(GET_BONUS_ISSUE_CUSTOMERS, {
    variables: {
      bonusCardTemplateId: bonusId,
    },
    onCompleted: res => {
      if (res.bonusCardTemplate?.bonusCards !== undefined) {
        setCustomers(
          res.bonusCardTemplate?.bonusCards.map(item => ({
            bonus_id: item.id,
            id: item.customer.id,
            name: item.customer.name,
            surname: item.customer.surname,
            phone: item.customer.phone,
          })),
        );
      } else {
        setCustomers([]);
      }
    },
  });
  const [deleteBonus] = useMutation(DELETE_BONUS_CARD, {
    refetchQueries: ['GetBonusIssuedCustomers'],
  });
  const [createBonuses, {loading: loadingCreate}] = useMutation(
    CREATE_BONUS_CARDS,
    {
      refetchQueries: [
        'GetBonusIssuedCustomers',
        'bonusCardTemplate',
        'bonusCardTemplates',
      ],
    },
  );

  return (
    <Layout columns={12}>
      <Seo title="Выдать бонусную карту" />
      <Wrapper>
        <Head>
          <NavigateBack />
          <Text typography="title24">Выдать бонусную карту</Text>
        </Head>
        <Content gap="24px">
          <Text typography="title18">Клиенты</Text>
          {loading ? (
            <BonusSkeleton />
          ) : (
            <>
              {customers.length === 0 ? (
                <Flex direction="column" gap={16}>
                  <EmptyIssuedCard size={300} />
                  <Text typography="text18" color="textTertiary" align="center">
                    У вас нет выданных бонусных карт клиентам. Вы <br /> можете
                    выбрать клиентов и выдать им бонусные карты
                  </Text>
                </Flex>
              ) : (
                <List
                  data={customers}
                  gap={16}
                  renderItem={customer => (
                    <TableViewRow
                      style={{
                        width: '100%',
                        height: 64,
                      }}
                      title={getName(customer.name, customer.surname)}
                      subtitle={getPhoneMask(customer.phone)}
                      rightElement={() => (
                        <IconButton
                          size={44}
                          variant="unstyled"
                          onClick={() => {
                            if (customer.bonus_id) {
                              deleteBonus({
                                variables: {
                                  ids: [customer.bonus_id],
                                },
                              })
                                .then(() => {
                                  showAlert(
                                    'success',
                                    'Бонусная карта успешно удалена',
                                  );
                                })
                                .catch(e => {
                                  if (e instanceof ApolloError) {
                                    showAlert('error', e.message);
                                  }
                                });
                            }
                          }}>
                          <CircleClose size={32} />
                        </IconButton>
                      )}
                    />
                  )}
                />
              )}
            </>
          )}
        </Content>
        <Button size="large" onClick={() => setModal(true)}>
          Выдать карту
        </Button>
        <ModalClient
          visible={modal}
          loading={loadingCreate}
          onSelect={values => {
            createBonuses({
              variables: {
                input: {
                  customer_ids: values,
                  bonus_card_template_id: bonusId,
                },
              },
            })
              .then(() => setModal(false))
              .catch(e => {
                showAlert('error', e.message);
              });
          }}
          onClose={() => setModal(false)}
        />
      </Wrapper>
    </Layout>
  );
};

const BonusSkeleton = () => {
  return (
    <Skeleton style={{marginTop: 4}} width={'100%'} height={64 * 5}>
      {Array.from(Array(4).keys()).map((_, index) => (
        <React.Fragment key={'bonus_sk_' + index}>
          <rect
            y={8 + index * (64 + 16)}
            width={'55%'}
            height={24}
            rx={4}
            ry={4}
          />
          <rect
            y={36 + index * (64 + 16)}
            width={'40%'}
            height={20}
            rx={4}
            ry={4}
          />
          <circle r={12} cy={32 + index * (48 + 16)} cx={'calc(100% - 12px)'} />
        </React.Fragment>
      ))}
    </Skeleton>
  );
};
