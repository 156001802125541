import * as React from 'react';
import {useColors} from 'shared/lib/hooks';

export function WidgetBlock({
  size,
  selected,
}: {
  size?: number;
  selected?: boolean;
}) {
  const colors = useColors();
  const sizeByWindow = 130;
  const sizeWidet = size ?? sizeByWindow;
  if (selected) {
    return (
      <svg
        width={sizeWidet}
        height={sizeWidet}
        viewBox="0 0 109 109"
        fill="none">
        <rect
          x={1}
          y={6}
          width={107}
          height={98}
          rx={19}
          fill={colors.bgSecondary}
        />
        <circle cx={26} cy={28} r={7} fill="#E5E5E5" />
        <rect x={37} y={24} width={20} height={3} rx={1.5} fill="#E5E5E5" />
        <rect x={37} y={29} width={16} height={3} rx={1.5} fill="#E5E5E5" />
        <rect
          x={56.1025}
          y={58.6025}
          width={18.7951}
          height={1.79508}
          rx={0.897541}
          fill={colors.textTertiary}
          stroke={colors.textTertiary}
          strokeWidth={1.20492}
        />
        <rect
          x={56.1025}
          y={63.6025}
          width={14.7951}
          height={1.79508}
          rx={0.897541}
          fill={colors.textTertiary}
          stroke={colors.textTertiary}
          strokeWidth={1.20492}
        />
        <rect
          x={36.5}
          y={54}
          width={14}
          height={14}
          rx={3.81967}
          stroke={colors.textTertiary}
          strokeWidth={2}
        />
        <rect
          x={56.1025}
          y={78.6025}
          width={18.7951}
          height={1.79508}
          rx={0.897541}
          fill={colors.textTertiary}
          stroke={colors.textTertiary}
          strokeWidth={1.20492}
        />
        <rect
          x={56.1025}
          y={83.6025}
          width={14.7951}
          height={1.79508}
          rx={0.897541}
          fill={colors.textTertiary}
          stroke={colors.textTertiary}
          strokeWidth={1.20492}
        />
        <rect
          x={36.5}
          y={75}
          width={14}
          height={14}
          rx={3.81967}
          stroke={colors.textTertiary}
          strokeWidth={2}
        />
        <rect
          x={20.5}
          y={47}
          width={69}
          height={49}
          rx={9}
          stroke={colors.textTertiary}
          strokeWidth={2}
        />
        <rect x={76} y={16} width={22} height={22} rx={11} fill="#1BC760" />
        <path
          d="M83 28.375L85.4 31l5.6-7"
          stroke="#fff"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect
          x={1}
          y={6}
          width={107}
          height={98}
          rx={19}
          stroke="#1BC760"
          strokeWidth={2}
        />
      </svg>
    );
  }
  return (
    <svg width={sizeWidet} height={sizeWidet} viewBox="0 0 109 109" fill="none">
      <rect y={5} width={109} height={100} rx={20} fill={colors.bgSecondary} />
      <circle cx={26} cy={28} r={7} fill="#E5E5E5" />
      <rect x={37} y={24} width={20} height={3} rx={1.5} fill="#E5E5E5" />
      <rect x={37} y={29} width={16} height={3} rx={1.5} fill="#E5E5E5" />
      <rect
        x={56.1025}
        y={58.6025}
        width={18.7951}
        height={1.79508}
        rx={0.897541}
        fill={colors.textTertiary}
        stroke={colors.textTertiary}
        strokeWidth={1.20492}
      />
      <rect
        x={56.1025}
        y={63.6025}
        width={14.7951}
        height={1.79508}
        rx={0.897541}
        fill={colors.textTertiary}
        stroke={colors.textTertiary}
        strokeWidth={1.20492}
      />
      <rect
        x={36.5}
        y={54}
        width={14}
        height={14}
        rx={3.81967}
        stroke={colors.textTertiary}
        strokeWidth={2}
      />
      <rect
        x={56.1025}
        y={78.6025}
        width={18.7951}
        height={1.79508}
        rx={0.897541}
        fill={colors.textTertiary}
        stroke={colors.textTertiary}
        strokeWidth={1.20492}
      />
      <rect
        x={56.1025}
        y={83.6025}
        width={14.7951}
        height={1.79508}
        rx={0.897541}
        fill={colors.textTertiary}
        stroke={colors.textTertiary}
        strokeWidth={1.20492}
      />
      <rect
        x={36.5}
        y={75}
        width={14}
        height={14}
        rx={3.81967}
        stroke={colors.textTertiary}
        strokeWidth={2}
      />
      <rect
        x={20.5}
        y={47}
        width={69}
        height={49}
        rx={9}
        stroke={colors.textTertiary}
        strokeWidth={2}
      />
    </svg>
  );
}
