import React from 'react';
import {EmptyAddCategoryBlock} from 'shared/illustration/EmptyAddCategoryBlock';
import {EmptyBox} from 'shared/illustration/EmptyBox';
import {Flex} from 'shared/ui/Flex';
import {Text} from 'shared/ui/Text';

export const EmptyStateProducts = () => {
  return (
    <Flex direction="column" flex={1} gap={16}>
      <EmptyBox size={300} />
      <Text typography="title20" align="center">
        Товаров нет
      </Text>
      <Text typography="text18" color="textTertiary" align="center">
        Нажмите на кнопку “Добавить товар” и начните работу
      </Text>
    </Flex>
  );
};

export const EmptyStateProductsCategory = () => {
  return (
    <Flex direction="column" flex={1} gap={16}>
      <EmptyAddCategoryBlock size={300} />
      <Text typography="title20" align="center">
        Нет категорий
      </Text>
      <Text typography="text18" color="textTertiary" align="center">
        Нажмите на кнопку “Создать категорию” и добавьте информацию
      </Text>
    </Flex>
  );
};
