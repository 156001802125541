import {gql} from 'shared/__generated__';

export const MY_COMPANIES = gql(`
query MyEmployees($first: Int!, $page: Int) {
  myEmployees(first: $first, page: $page) {
    data {
      id
      role
      invite_status
      company {
        id
        name
        username
        slots
        city {
          id
          name
        }
        address
        avatar {
          id
          type
          url
        }
        plan {
          id
          created_at
          updated_at
          slots
          type
          price
        }
        subscription_end_date
        rating_1_count
        rating_2_count
        rating_3_count
        rating_4_count
        rating_5_count
      }
      user {
        id
        avatar {
          id
          url
        }
        name
        surname
        phone
      }
    }
    paginatorInfo {
      currentPage
      hasMorePages
      total
      count
    }
  }
}`);
