import React from 'react';

export const DocumentAccept = ({
  color = '#6765F8',
  size = 24,
}: {
  color?: string;
  size?: number;
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.6316 16.1944H6.21053M14.6316 11.9722H6.21053M9.36842 7.75H6.21053M11.4868 21.9969L6.55389 22C3.7403 22 2 20.1186 2 17.2454V7.75464C2 4.89587 3.72716 3.02167 6.51649 3.00413L14.2872 3C17.1008 3 18.8421 4.88142 18.8421 7.75464V11.5146M22 16.7222L17.7895 22L15.6842 19.8889"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
