import React, {useState} from 'react';
import {styled} from 'styled-components';
import {Text} from 'shared/ui/Text';
import {OptionsType, Table, TableSortType} from 'shared/ui/Table';
import {gql} from 'shared/__generated__';
import {useAppSelector} from 'shared/store';
import {useQuery} from '@apollo/client';
import {
  QueryGetStatisticsDiscountOrderByColumn,
  SortOrder,
  StatisticsDates,
} from 'shared/__generated__/graphql';
import {Flex} from 'shared/ui/Flex';
import {
  FilterEmptyState,
  FilterResetButton,
  FilterSearch,
  FilterStatisticsDiscountStatus,
  FilterStatisticsPeriod,
} from 'entities/statistics';
import dayjs from 'dayjs';
import {getNumberFormat, getPriceFormat} from 'shared/lib/utils';
import {Content} from 'shared/ui/Content';
import {PageHeader} from 'shared/ui/PageHeader';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
`;

const GET_STATISTICS_DISCOUNT = gql(`
  query GetStatisticsDiscount(
    $companyId: String!
    $date: StatisticsDates!
    $first: Int!
    $page: Int
    $query: String
    $isActive: Boolean
    $orderBy: [QueryGetStatisticsDiscountOrderByOrderByClause!]
  ) {
    getStatisticsDiscount(
      company_id: $companyId
      date: $date
      first: $first
      page: $page
      query: $query
      is_active: $isActive
      orderBy: $orderBy
    ) {
      paginatorInfo {
        hasMorePages
        currentPage
        total
        count
        lastPage
      }
      data {
        created_at
        name
        status
        used_quantity
        used_summary
      }
    }
  }
`);
const titles = [
  'Название',
  'Дата создания',
  'Кол-во заказов',
  'Сумма скидок по услугам',
  'Статус',
];
const sortValues: QueryGetStatisticsDiscountOrderByColumn[] = [
  QueryGetStatisticsDiscountOrderByColumn.Name,
  QueryGetStatisticsDiscountOrderByColumn.CreatedAt,
  QueryGetStatisticsDiscountOrderByColumn.UsedQuantity,
  QueryGetStatisticsDiscountOrderByColumn.UsedSummary,
  QueryGetStatisticsDiscountOrderByColumn.Status,
];

export const LoyaltyDiscounts = () => {
  const companyId = useAppSelector(state => state.company.data!.id);
  const [sort, setSort] = useState<TableSortType>({
    index: 0,
    order: SortOrder.Desc,
  });
  const [search, setSearch] = useState('');
  const [isActive, setIsActive] = useState<boolean | undefined>();
  const [options, setOptions] = useState<OptionsType>({
    page: 1,
    first: 10,
    total: 0,
    count: 0,
    lastPage: 1,
  });
  const [period, setPeriod] = useState<StatisticsDates>(
    StatisticsDates.Alltime,
  );
  const {data, loading} = useQuery(GET_STATISTICS_DISCOUNT, {
    variables: {
      companyId,
      isActive,
      date: period,
      first: options.first,
      page: options.page,
      query: search,
      orderBy: [
        {
          order: sort.order,
          column: sortValues[sort.index],
        },
      ],
    },
    onCompleted: res =>
      setOptions(prev => ({
        ...prev,
        total: res.getStatisticsDiscount?.paginatorInfo.total,
        count: res.getStatisticsDiscount?.paginatorInfo.count,
        lastPage: res.getStatisticsDiscount?.paginatorInfo.lastPage,
      })),
  });
  const statistics = data?.getStatisticsDiscount?.data ?? [];
  const isEmpty = !statistics?.filter(item => item.name !== 'Итого').length;
  return (
    <Wrapper>
      <PageHeader back title="Статистика по скидкам" />
      <Flex direction="row" gap={8}>
        <FilterSearch onChange={setSearch} value={search} />
        <FilterStatisticsPeriod {...{period, setPeriod}} />
        <FilterStatisticsDiscountStatus {...{isActive, setIsActive}} />
        {isActive !== undefined ||
        search ||
        period !== StatisticsDates.Alltime ? (
          <FilterResetButton
            onClick={() => {
              setIsActive(undefined);
              setSearch('');
              setPeriod(StatisticsDates.Alltime);
            }}
          />
        ) : (
          <></>
        )}
      </Flex>
      {!loading && isEmpty ? (
        <FilterEmptyState />
      ) : (
        <Content>
          <Table
            options={options}
            setOptions={setOptions}
            titles={titles}
            sort={sort}
            alignRightColumn={[2, 3]}
            columnNoWrap={[3]}
            results
            onClickTitle={(index, order) => setSort({index, order})}
            rows={statistics.map((item, index) => {
              const isTotal = item.name === 'Итого';
              return [
                item.name,
                dayjs(item.created_at).isValid()
                  ? dayjs(item.created_at).format('DD.MM.YYYY')
                  : item.created_at,
                getNumberFormat(item.used_quantity),
                getPriceFormat(item.used_summary),
                <Text
                  key={index.toString() + '_status'}
                  color={
                    isTotal
                      ? 'textPrimary'
                      : item.status
                      ? 'successPrimary'
                      : 'dangerPrimary'
                  }>
                  {isTotal ? '-' : item.status ? 'Действует' : 'Отключен'}
                </Text>,
              ];
            })}
          />
        </Content>
      )}
    </Wrapper>
  );
};
