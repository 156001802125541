import {Dayjs} from 'dayjs';
import React, {useState} from 'react';
import styled from 'styled-components';
import {Text} from 'shared/ui/Text';
import {ArrowLeft1v} from 'shared/icons/ArrowLeft1v';
import {ArrowRight1v} from 'shared/icons/ArrowRight1v';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: max-content;
  padding: 14px 16px;
  gap: 32px;
  align-items: center;
  border-radius: 12px;
  border: 1px solid ${({theme}) => theme.borderPrimary};
`;
const Button = styled.button`
  display: flex;
  padding: 0;
  border: none;
  background-color: transparent;
  pointer-events: all;
  cursor: pointer;
`;

export const ButtonDate = ({
  startMonth,
  onChange,
}: {
  startMonth: Dayjs;
  onChange?: (date: Dayjs) => void;
}) => {
  const [date, setDate] = useState(startMonth);
  return (
    <Container>
      <Button
        onClick={() => {
          setDate(prev => {
            onChange && onChange(prev.add(-1, 'month'));
            return prev.add(-1, 'month');
          });
        }}>
        <ArrowLeft1v />
      </Button>
      <Text typography="text18" style={{textTransform: 'capitalize'}}>
        {date.format('MMMM YYYY')}
      </Text>
      <Button
        onClick={() => {
          setDate(prev => {
            onChange && onChange(prev.add(1, 'month'));
            return prev.add(1, 'month');
          });
        }}>
        <ArrowRight1v />
      </Button>
    </Container>
  );
};
