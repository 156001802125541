import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import {Layout} from 'shared/ui/Layout';
import {Content} from 'shared/ui/Content';
import {TextInput} from 'shared/ui/TextInput';
import {Button} from 'shared/ui/Button';
import {gql} from 'shared/__generated__';
import {useLazyQuery, useMutation} from '@apollo/client';
import {setCompanyData, useAppDispatch, useAppSelector} from 'shared/store';
import {showAlert} from 'shared/ui/Alert';
import {ensureError} from 'shared/lib/utils';
import {PageHeader} from 'shared/ui/PageHeader';
import {Tooltip} from 'shared/ui/Tooltip';
import CirclePelp from 'shared/icons/CirclePelp';
import {useColors} from 'shared/lib/hooks';
const COMPANY_ABOUT_UPDATE = gql(`
  mutation MutationCompanyAbout($input: UpdateCompany!) {
    updateCompany(input: $input) {
      id
    }
  }
`);
const UNIQUE_DOMEN = gql(`
  query UniqueDomen($username: String!) {
    usernameUnique(username: $username)
  }
`);
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-column-start: 4;
  grid-column-end: 10;
  gap: 24px;
  padding-bottom: 24px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export function DomenChange() {
  const navigate = useNavigate();
  const colors = useColors();
  const companyId = useAppSelector(state => state.company.data!.id);
  const companyData = useAppSelector(state => state.company.data);
  const userName = useAppSelector(state => state.company.data?.username);
  const dispatch = useAppDispatch();
  const [domen, setDomen] = useState(userName ?? '');
  const [errorDomen, setErrorDomen] = useState<string | null>(null);
  const [getUniqueDomen] = useLazyQuery(UNIQUE_DOMEN, {
    variables: {username: domen},
  });
  const [updateCompanyDomen, {loading}] = useMutation(COMPANY_ABOUT_UPDATE);

  const regex = /^[a-z0-9-]*$/;

  const handleDomenChange = (text: string) => {
    if (regex.test(text)) {
      setDomen(text);
      setErrorDomen(null);
    }
  };

  const updateDomen = async () => {
    if (!domen) {
      setErrorDomen('Домен не может быть пустым');
      return;
    }
    try {
      const {data} = await getUniqueDomen();
      if (!data?.usernameUnique) {
        setErrorDomen('Домен уже занят');
        return;
      }
      await updateCompanyDomen({
        variables: {
          input: {
            id: companyId,
            username: domen,
          },
        },
      });
      if (companyData?.employeeId) {
        dispatch(
          setCompanyData({
            id: companyId!,
            employeeId: companyData?.employeeId,
            username: domen,
          }),
        );
        navigate(`/${domen}/setting`, {replace: true});
      }

      showAlert('success', 'Coxранено');
    } catch (e) {
      const error = ensureError(e);
      setErrorDomen(error.message);
    }
  };
  return (
    <Layout columns={12}>
      <Wrapper>
        <PageHeader variant="layout" title="Изменить домен" back={true} />
        <Content>
          <TextInput
            variant="with_label"
            label="Домен"
            value={domen}
            onChange={handleDomenChange}
            error={errorDomen}
            rightElement={
              <Tooltip
                position="right"
                style={{padding: '8px 12px', borderRadius: 8}}
                typography="footNote12Medium"
                text={
                  'Домен может содержать только строчные латинские буквы, цифры и дефис. Не может начинаться дефисом.'
                }>
                <CirclePelp color={colors.textTertiary} />
              </Tooltip>
            }
          />
        </Content>
        <Button
          style={{marginTop: 8}}
          size="large"
          onClick={updateDomen}
          loading={loading}>
          Сохранить
        </Button>
      </Wrapper>
    </Layout>
  );
}
