import {useLazyQuery, useMutation} from '@apollo/client';
import dayjs from 'dayjs';
import React, {useEffect, useState} from 'react';
import {gql} from 'shared/__generated__';
import {Search} from 'shared/icons/Search';
import {Trash} from 'shared/icons/Trash';
import {EmptyWhiteContent} from 'shared/illustration/EmptyWriteContent';
import {useColors, useDebounce, useResize} from 'shared/lib/hooks';
import {getName, getPhoneMask} from 'shared/lib/utils';
import {showAlert} from 'shared/ui/Alert';
import {CloseButton} from 'shared/ui/CloseButton';
import {Error} from 'shared/ui/Error';
import {Flex} from 'shared/ui/Flex';
import {IconButton} from 'shared/ui/IconButton';
import {Popup} from 'shared/ui/Popup';
import {Skeleton} from 'shared/ui/Skeleton';
import {Table} from 'shared/ui/Table';
import {Text} from 'shared/ui/Text';
import {TextInput} from 'shared/ui/TextInput';

const GET_CERTIIFICATE_ISSUE = gql(`
  query CertificateTemplate($id: String!, $customer: String) {
    certificateTemplate(id: $id) {
      certificates(customer: $customer) {
        id
        customer {
          id
          name
          surname
          phone
        }
        balance
        valid_until
        created_at
      }
    }
  }
`);

const DELETE_CERTIFICATE = gql(`
mutation DeleteCertificateIssue($id: String!) {
    deleteCertificate(id: $id) {
      id
    }
  }
`);

export const ModalIssueCertificate = ({
  visible,
  onClose,
  id,
}: {
  visible: boolean;
  onClose: () => void;
  id: string;
}) => {
  const colors = useColors();
  const [search, setSearch] = useState('');
  const [getCertificateIssue, {data, loading, error}] = useLazyQuery(
    GET_CERTIIFICATE_ISSUE,
    {
      variables: {
        id: id,
        customer: search === '' ? undefined : search,
      },
    },
  );

  const setSearchDebounce = useDebounce((named: string) => {
    const variables = {
      id: id,
      customer: named === '' ? undefined : named,
    };
    getCertificateIssue({variables});
  }, 400);

  const certificates = data?.certificateTemplate?.certificates ?? [];
  const [deleteCertificate] = useMutation(DELETE_CERTIFICATE, {
    refetchQueries: ['CertificateTemplate'],
  });

  useEffect(() => {
    if (visible) {
      getCertificateIssue();
    }
  }, [getCertificateIssue, visible]);

  return (
    <Popup style={{width: 988}} visible={visible} onClose={onClose}>
      <Flex direction="column" gap={24} style={{width: '100%', maxHeight: 632}}>
        <Flex
          direction="row"
          alignItems="center"
          justifyContent="space-between">
          <Text typography="title20">Проданные сертификаты</Text>
          <CloseButton
            onClose={() => {
              onClose();
            }}
          />
        </Flex>
        <TextInput
          width="348px"
          value={search}
          onChange={value => {
            setSearch(value);
            setSearchDebounce(value);
          }}
          variant="default"
          placeholder="Имя или телефон клиента"
          leftElement={<Search color={colors.textTertiary} />}
        />
        {loading ? (
          <IssueSkeleton />
        ) : error ? (
          <Error message={error.message} />
        ) : certificates.length === 0 ? (
          <EmptyState />
        ) : (
          <Table
            titles={['Имя', 'Телефон', 'Баланс', 'Действует до', '']}
            style={{
              maxHeight: 644,
            }}
            scrollable
            widths={[220, 220, 220, 220, 60]}
            rows={certificates.map(certificate => [
              <Text key={'issue_customer_name_' + certificate.id}>
                {getName(
                  certificate.customer.name,
                  certificate.customer.surname,
                )}
              </Text>,
              <Text key={'issue_customer_phone' + certificate.id}>
                {getPhoneMask(certificate.customer.phone)}
              </Text>,
              <Text key={'issue_balance_' + certificate.id}>
                {certificate.balance} ₽
              </Text>,
              <Text key={'issue_expiration_' + certificate.id}>
                {certificate.valid_until
                  ? dayjs(certificate.valid_until).format('DD MMMM, YYYY')
                  : 'Неограничено'}
              </Text>,
              <IconButton
                key={'issue_button_delete_' + certificate.id}
                size={24}
                onClick={() => {
                  deleteCertificate({
                    variables: {
                      id: certificate.id,
                    },
                  }).catch(e => {
                    showAlert('error', e.message);
                  });
                }}
                style={{
                  backgroundColor: 'transparent',
                  borderColor: 'transparent',
                  padding: 0,
                }}>
                <Trash color={colors.dangerPrimary} />
              </IconButton>,
            ])}
          />
        )}
      </Flex>
    </Popup>
  );
};

const calcSize = (width: number) => {
  return (width * 100) / 940 + '%';
};

const IssueSkeleton = () => {
  const {isMobile} = useResize();
  return (
    <Skeleton width={isMobile ? 'calc(100% - 32px)' : 940} height={356}>
      <rect width={calcSize(59)} height={22} rx={11} ry={11} x={0} y={1} />
      <rect
        width={calcSize(59)}
        height={22}
        rx={11}
        ry={11}
        x={calcSize(227)}
        y={1}
      />
      <rect
        width={calcSize(59)}
        height={22}
        rx={11}
        ry={11}
        x={calcSize(470)}
        y={1}
      />
      <rect
        width={calcSize(59)}
        height={22}
        rx={11}
        ry={11}
        x={calcSize(624)}
        y={1}
      />
      <circle r={12} cx={'calc(100% - 24px)'} cy={12} />
      {Array(4)
        .fill(0)
        .map((_, index) => (
          <React.Fragment key={'sk_issue_certificate_' + index}>
            <rect
              width={calcSize(181)}
              height={22}
              rx={11}
              ry={11}
              x={0}
              y={55 * (index + 1)}
            />
            <rect
              width={calcSize(181)}
              height={22}
              rx={11}
              ry={11}
              x={calcSize(227)}
              y={55 * (index + 1)}
            />
            <rect
              width={calcSize(108)}
              height={22}
              rx={11}
              ry={11}
              x={calcSize(470)}
              y={55 * (index + 1)}
            />
            <rect
              width={calcSize(181)}
              height={22}
              rx={11}
              ry={11}
              x={calcSize(624)}
              y={55 * (index + 1)}
            />
            <circle r={12} cx={'calc(100% - 24px)'} cy={66 * (index + 1)} />
          </React.Fragment>
        ))}
    </Skeleton>
  );
};

const EmptyState = () => {
  return (
    <Flex direction="column" gap={24} flex={1} alignItems="center">
      <Flex flex={1} direction="column" gap={8}>
        <EmptyWhiteContent size={300} />
        <Text color="textTertiary" style={{textAlign: 'center'}}>
          У вас нет выданных сертификатов клиентам
        </Text>
      </Flex>
    </Flex>
  );
};
