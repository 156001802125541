import React, {useState} from 'react';
import {Content} from 'shared/ui/Content';
import styled from 'styled-components';
import {device} from 'shared/device';
import {Seo} from 'shared/ui/Seo';
import {SlidesContainer} from 'shared/ui/SlidesContainer';
import {Slide} from 'shared/ui/Slide';
import {Text} from 'shared/ui/Text';
import {getPriceFormat} from 'shared/lib/utils';
import {Grow} from 'shared/ui/Grow';
import dayjs from 'dayjs';
import {gql} from 'shared/__generated__';
import {NetworkStatus, useLazyQuery} from '@apollo/client';
import {Main} from './ui/Main';
import {useAppSelector} from 'shared/store';
import {Flex} from 'shared/ui/Flex';
import {PaymentType} from 'shared/__generated__/graphql';
import {Error} from 'shared/ui/Error';
import {Skeleton} from 'shared/ui/Skeleton';
import {EmptyReceipt} from 'shared/illustration/EmptyReceipt';

const GET_COMPANY_OPERATIONS = gql(`
  query GetCompanyOperations($companyId: String!) {
    getCompanyOperations(company_id: $companyId) {
      current_balance
      history {
        date
        method
        description
        type
        value
      }
    }
  }`);

const Wrapper = styled.div`
  display: flex;
  padding-bottom: 24px;
  flex-direction: column;
  gap: 32px;
  margin-top: 8px;
  @media (${device.mobile}) {
    gap: 8px;
    margin-bottom: 0;
    border-radius: 0;
    padding-left: 16px;
    padding-right: 16px;
  }
`;
const SegmentsControl = styled.div`
  position: relative;
  display: flex;
  gap: 32px;
  border-radius: 12px;
  height: 40px;
`;
const Tabs = styled.button<{active: boolean}>`
  display: flex;
  border: none;
  background: none;
  padding: 0;
  height: 100%;
  width: max-content;
  align-items: center;
  justify-content: center;
  font-family: 'Inter';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  z-index: 2;
  color: ${({theme, active}) =>
    active ? theme.mainPrimary : theme.textPrimary};
  transition: color 300ms;
  padding-bottom: 16px;
`;
const ActiveTab = styled.button<{activeIndex: number}>`
  position: absolute;
  height: 100%;
  bottom: 0;
  width: 151px;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 2px;
  height: 2px;
  background-color: ${({theme}) => theme.mainPrimary};
  left: ${({activeIndex}) => activeIndex * 183}px;
  transition: left 300ms ease-out;
`;

const tabsTitle = ['Основные условия', 'История операций'];

export function Referral() {
  const [slide, setSlide] = useState(0);
  const companyId = useAppSelector(state => state.company.data!.id);

  const [getHistory, {data, networkStatus, error, called}] = useLazyQuery(
    GET_COMPANY_OPERATIONS,
    {variables: {companyId}},
  );
  const loading = networkStatus === NetworkStatus.loading;
  const histories = data?.getCompanyOperations?.history ?? [];
  if (error) {
    return <Error message={error.message} />;
  }
  return (
    <Wrapper>
      <Seo title="Реферальная программа" />
      <SegmentsControl>
        <ActiveTab activeIndex={slide} />
        {tabsTitle.map((title, index) => (
          <Tabs
            onClick={() => {
              if (index === 1 && !called) {
                getHistory();
              }
              setSlide(index);
            }}
            active={index === slide}
            key={index + '_tab_referral'}>
            {title}
          </Tabs>
        ))}
      </SegmentsControl>
      <SlidesContainer index={slide}>
        <Main activeSlide={slide} />
        <Slide>
          <Content gap="32px">
            <Grow gap={16}>
              <Text typography="title18">Ваш баланс</Text>
              <Grow style={{padding: '5px 0'}} gap={4}>
                <Text typography="footNote12Regular" color="textTertiary">
                  Баланс
                </Text>
                {loading ? (
                  <BalanceSkeleton />
                ) : (
                  <Text typography="title24">
                    {getPriceFormat(
                      data?.getCompanyOperations?.current_balance,
                    )}
                  </Text>
                )}
              </Grow>
            </Grow>
            {loading ? (
              <HistorySkeleton />
            ) : (
              <Grow gap={16}>
                <Text typography="title18">История выплат</Text>
                {histories.length > 0 ? (
                  histories.map((item, index) => {
                    return (
                      <Flex key={'historyItem_' + index}>
                        <Grow style={{padding: '9x 0'}} gap={4}>
                          <Text
                            typography="footNote12Regular"
                            color="textTertiary">
                            {dayjs(item.date).format('DD.MM.YY, в HH:mm')}
                          </Text>
                          <Text
                            color={
                              item.type === PaymentType.Accrual
                                ? 'successPrimary'
                                : 'textPrimary'
                            }>
                            {(item.type === PaymentType.Accrual
                              ? '+\u00A0'
                              : '-\u00A0') + getPriceFormat(item.value)}
                          </Text>
                        </Grow>
                        <Grow style={{padding: '9x 0'}} gap={4}>
                          <Text
                            style={{textAlign: 'right'}}
                            typography="footNote12Regular">
                            {item.method}
                          </Text>
                          <Text
                            style={{textAlign: 'right'}}
                            typography="footNote12Regular"
                            color="textTertiary">
                            {item.description}
                          </Text>
                        </Grow>
                      </Flex>
                    );
                  })
                ) : (
                  <Flex direction="column" flex={1} gap={16}>
                    <EmptyReceipt size={300} />
                    <Text typography="title20" align="center">
                      Нет истории операций
                    </Text>
                    <Text
                      typography="text18"
                      color="textTertiary"
                      align="center">
                      Здесь пока нет подходящей информации
                    </Text>
                  </Flex>
                )}
              </Grow>
            )}
          </Content>
        </Slide>
      </SlidesContainer>
    </Wrapper>
  );
}

const BalanceSkeleton = () => (
  <Skeleton height={32}>
    <rect width={150} height={32} rx={14} ry={14} />
  </Skeleton>
);

const HistorySkeleton = () => (
  <Skeleton height={210}>
    {Array.from(Array(3).keys()).map((_, index) => (
      <React.Fragment key={'history_referral_sk_' + index}>
        <rect width={100} height={18} rx={8} ry={8} x={0} y={80 * index} />
        <rect width={120} height={24} rx={8} ry={8} x={0} y={22 + 80 * index} />
        <rect
          width={100}
          height={18}
          rx={8}
          ry={8}
          x={'calc(100% - 100px)'}
          y={80 * index}
        />
        <rect
          width={100}
          height={24}
          rx={8}
          ry={8}
          x={'calc(100% - 100px)'}
          y={22 + 80 * index}
        />
      </React.Fragment>
    ))}
  </Skeleton>
);
