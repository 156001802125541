import React, {useState} from 'react';
import {Flex} from 'shared/ui/Flex';
import {Layout} from 'shared/ui/Layout';
import {PageHeader} from 'shared/ui/PageHeader';
import {styled} from 'styled-components';
import {Products} from './ui/Products';
import {SlidesContainer} from 'shared/ui/SlidesContainer';
import {Categories} from './ui/Categories';
import {Brands} from './ui/Brands';
import {ModalFilter} from './ui/ModalFilter';
import {
  PRODUCTS_CATEGORIES,
  IBrandEditModalProps,
  ICategoryEditModalProps,
  ModalBrandCreate,
  ModalBrandEdit,
  ModalCategoryCreate,
  ModalCategoryEdit,
} from 'entities/products';
import {useAppSelector} from 'shared/store';
import {useQuery} from '@apollo/client';
import {useLocation} from 'react-router-dom';

const Wrapper = styled.div`
  display: flex;
  grid-column-start: 1;
  grid-column-end: 13;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  gap: 24px;
`;

const ActiveTab = styled.div<{width: string; left: number}>`
  height: 2px;
  width: ${({width}) => width};
  background-color: ${({theme}) => theme.mainPrimary};
  border-radius: 1px;
  position: absolute;
  bottom: 0;
  left: ${({left}) => left}px;
  transition:
    left 0.3s,
    width 0.3s;
`;

const Tab = styled.button<{active: boolean}>`
  border: none;
  display: flex;
  height: 40px;
  color: ${({theme, active}) =>
    active ? theme.mainPrimary : theme.textTertiary};
  background-color: transparent;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  pointer-events: all;
  cursor: pointer;
  font-family: 'Inter';
  padding: 0;
  left: 0;
`;

const tabs: {title: string; value: 'products' | 'categories' | 'brands'}[] = [
  {
    title: 'Товары',
    value: 'products',
  },
  {
    title: 'Категории',
    value: 'categories',
  },
  {
    title: 'Бренды',
    value: 'brands',
  },
];

export const ProductsMain = () => {
  const [selectedTab, setSelectedTab] = React.useState<
    'products' | 'categories' | 'brands'
  >('products');
  const [categories, setCategories] = React.useState<string[]>([]);
  const [brands, setBrands] = React.useState<string[]>([]);
  const [filterVisible, setFilterVisible] = React.useState(false);
  const companyId = useAppSelector(state => state.company.data?.id);
  const location = useLocation();
  const refetchProducts = location.state?.refetch ?? false;

  const [modalCategoryCreate, setModalCategoryCreate] = useState(false);
  const [modalCategoryEdit, setModalCategoryEdit] =
    useState<ICategoryEditModalProps | null>(null);
  const [modalBrandCreate, setModalBrandCreate] = useState(false);
  const [modalBrandEdit, setModalBrandEdit] =
    useState<IBrandEditModalProps | null>(null);
  const activeTab = document.getElementById(
    'tab_' + tabs.findIndex(item => item.value === selectedTab) ?? 0,
  );

  const {data, loading} = useQuery(PRODUCTS_CATEGORIES, {
    variables: {companyId: companyId!, page: 1, first: 1000},
  });
  const resCategories = data?.productsCategory?.data ?? [];

  const openModalCategory = (value?: ICategoryEditModalProps) => {
    if (value) {
      setModalCategoryEdit(value);
      return;
    }
    setModalCategoryCreate(true);
  };
  const openModalBrand = (value?: IBrandEditModalProps) => {
    if (value) {
      setModalBrandEdit(value);
      return;
    }
    setModalBrandCreate(true);
  };

  return (
    <Layout variant="sidebar">
      <Wrapper>
        <Flex style={{padding: '24px 0 0 24px'}}>
          <PageHeader title="Товары" />
        </Flex>
        <Flex
          gap={32}
          style={{marginBottom: 8, padding: '0 24px', position: 'relative'}}>
          {tabs.map(({title, value}, index) => (
            <Tab
              id={'tab_' + index}
              key={value}
              active={value === selectedTab}
              onClick={() => setSelectedTab(value)}>
              {title}
            </Tab>
          ))}
          <ActiveTab
            width={
              activeTab?.offsetWidth ? activeTab.offsetWidth + 'px' : '60px'
            }
            left={activeTab?.offsetLeft ?? 24}
          />
        </Flex>
        <Flex direction="column" flex={1}>
          <SlidesContainer
            wrapperStyle={{height: '100%'}}
            containerStyle={{height: '100%', width: '100%'}}
            index={tabs.findIndex(item => item.value === selectedTab) ?? 0}>
            <Products
              onModalOpen={() => setFilterVisible(true)}
              categories={categories}
              brands={brands}
              refetchQuery={refetchProducts}
            />
            <Categories
              visible={selectedTab === 'categories'}
              onOpenModal={openModalCategory}
            />
            <Brands
              visible={selectedTab === 'brands'}
              onOpenModal={openModalBrand}
            />
          </SlidesContainer>
          <ModalFilter
            visible={filterVisible}
            categories={categories}
            brands={brands}
            onClose={() => setFilterVisible(false)}
            onChangeCategory={setCategories}
            onChangeBrand={setBrands}
          />
          <ModalCategoryCreate
            visible={modalCategoryCreate && !loading}
            onClose={() => setModalCategoryCreate(false)}
            categories={resCategories.map(category => ({
              label: category.name,
              value: category.id,
            }))}
          />
          <ModalCategoryEdit
            visible={!!modalCategoryEdit && !loading}
            onClose={() => setModalCategoryEdit(null)}
            id={modalCategoryEdit?.id}
            name={modalCategoryEdit?.name}
            parentId={modalCategoryEdit?.parent}
            categories={resCategories.map(category => ({
              label: category.name,
              value: category.id,
            }))}
          />
          <ModalBrandCreate
            visible={modalBrandCreate}
            onClose={() => setModalBrandCreate(false)}
          />
          <ModalBrandEdit
            visible={!!modalBrandEdit}
            onClose={() => setModalBrandEdit(null)}
            data={modalBrandEdit}
          />
        </Flex>
      </Wrapper>
    </Layout>
  );
};
