import React from 'react';
import {IPreset} from 'entities/messageTemplates';
import {Flex} from 'shared/ui/Flex';
import styled from 'styled-components';
import {Text} from 'shared/ui/Text';
const SetPreset = styled.button`
  background-color: ${({theme}) => theme.mainLight50};
  color: ${({theme}) => theme.mainPrimary};
  border-radius: 8px;
  padding: 2px 4px;
  border: none;
`;
export const Preset = ({
  preset,
  onClick,
}: {
  preset: IPreset;
  onClick: (value: string) => void;
}) => (
  <Flex>
    <SetPreset onClick={() => onClick(preset.value)}>{preset.value}</SetPreset>
    <Text typography="footNote12Regular" color="textSecondary">
      — {preset.label}
    </Text>
  </Flex>
);
