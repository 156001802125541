import {Slide} from 'shared/ui/Slide';
import styled from 'styled-components';

export const CustomSlide = styled(Slide)`
  background-color: ${({theme}) => theme.bgPrimary};
  border-radius: 0 0 24px 24px;
  padding: 0 24px 32px;
  height: max-content;
  transition: height 300ms ease;
  border: 1px solid ${({theme}) => theme.borderPrimary};
  border-top: 0;
  box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.1);
`;
