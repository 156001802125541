import React from 'react';
import {device} from 'shared/device';
import {Skeleton} from 'shared/ui/Skeleton';
import styled from 'styled-components';

const COLUMN = 5;

const Container = styled.div`
  display: flex;
  height: 388px;
  flex-direction: row;
  padding-top: 24px;
  background-color: ${({theme}) => theme.bgPrimary};
  @media ${device.desktop} {
    padding: 24px;
    border-radius: 24px;
  }
`;

const RectName = styled.rect<{index: number}>`
  display: none;
  @media ${device.desktop} {
    display: flex;
    y: calc(28px + ${props => props.index * 52}px);
  }
`;
const columns = Array.from(Array(COLUMN).keys());

export const DiscountsSkeleton = () => {
  return (
    <Container>
      <Skeleton>
        {columns.map((columnItem, index) => (
          <React.Fragment key={'column_' + columnItem}>
            <circle r={7} cx={35} cy={35 + 52 * index} />
            <RectName index={columnItem} width={73} height={14} rx={8} x={94} />
            <RectName
              index={columnItem}
              width={116}
              height={14}
              rx={8}
              x={294}
            />
            <RectName
              index={columnItem}
              width={116}
              height={14}
              rx={8}
              x={424 + 170}
            />
            <RectName
              index={columnItem}
              width={66}
              height={14}
              rx={8}
              x={424 + 170 * 3}
            />
            <RectName
              index={columnItem}
              width={66}
              height={14}
              rx={8}
              x={424 + 170 * 4}
            />
            <RectName
              index={columnItem}
              width={66}
              height={14}
              rx={8}
              x={424 + 170 * 5}
            />
            <RectName
              index={columnItem}
              width={66}
              height={14}
              rx={8}
              x={424 + 170 * 6}
            />
          </React.Fragment>
        ))}
      </Skeleton>
    </Container>
  );
};
