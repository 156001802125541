import React, {useState} from 'react';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';
import {Content} from 'shared/ui/Content';
import {useParams} from 'react-router-dom';
import {gql} from 'shared/__generated__';
import {device} from 'shared/device';
import {ApolloError, useMutation, useQuery} from '@apollo/client';
import {Communicate} from './ui/Communicate';
import {SkeletonAbout} from './ui/Skeleton';
import {TextArea} from 'shared/ui/TextArea';
import {useColors, useDebounce, useEmployeePermission} from 'shared/lib/hooks';
import dayjs from 'dayjs';
import {Gender, getPhoneMask} from 'shared/lib/utils';
import {TextView} from 'shared/ui/TextView';
import {Seo} from 'shared/ui/Seo';
import {useAppSelector} from 'shared/store';
import {Flex} from 'shared/ui/Flex';
import {IconButton} from 'shared/ui/IconButton';
import {CirclePlus} from 'shared/icons/CirclePlus';
import {CircleClose} from 'shared/icons/CircleClose';
import {showAlert} from 'shared/ui/Alert';
import {SelectTags} from 'entities/tags';

const CUSTOMER = gql(`
  query EditCustomerAbout($customerId: String!, $companyId: String!) {
    customer(id: $customerId) {
      id
      email
      name
      phone
      surname
      birthdate
      avatar {
        id
        url
      }
      tag {
        id
        name
        order
        color {
          id
          rgb
        }
      }
      gender
      is_blocked
      is_vip
      note
    }
    ...Permissions
  }
`);

const DELETE_TAG_FROM_CUSTOMER = gql(`
  mutation DeleteTagFromCustomer($input: UpdateCustomer!) {
    updateCustomer(input: $input) {
      id
      name
      tag {
        id
        name
        order
        color {
          id
          rgb
        }
      }
    }
  }
`);

const UPDATE_CUSTOMER = gql(`
  mutation UpdateCustomerAbout($input: UpdateCustomer!) {
    updateCustomer(input: $input) {
      id
      note
    }
  }
`);

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 24px;
  padding-top: 8px;
  padding-bottom: 24px;
  @media (${device.mobile}) {
    gap: 8px;
    margin-bottom: 0;
    border-radius: 0;
  }
`;

const TagsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-wrap: wrap;
  overflow-x: scroll;
`;

const TagItemWrapper = styled.button`
  display: flex;
  flex-direction: row;
  background-color: transparent;
  border: 1px solid ${({theme}) => theme.borderPrimary};
  align-items: center;
  padding: 8px;
  border-radius: 8px;
  gap: 4px;
  height: 32px;
  width: max-content;
`;

export const CustomerAbout = () => {
  const params = useParams();
  const customerId = params.id!;
  const companyId = useAppSelector(state => state.company.data!.id);
  const [note, setNote] = useState('');
  const [updateCustomer] = useMutation(UPDATE_CUSTOMER);
  const {data, loading} = useQuery(CUSTOMER, {
    variables: {
      companyId,
      customerId,
    },
    onCompleted: ({customer}) => {
      setNote(customer?.note ?? '');
    },
  });
  const {contact_permission} = useEmployeePermission(data);

  const customer = data?.customer;
  const name = customer?.name ?? '';

  const [deleteTagFromCustomer] = useMutation(DELETE_TAG_FROM_CUSTOMER);
  const [modalCreateTag, setModalCreateTag] = useState(false);
  const customerTags = customer?.tag ?? [];
  const tags = [
    ...[...customerTags].sort(function (a, b) {
      return a.order! - b.order!;
    }),
    {
      id: '0',
      name: 'Добавить тег',
      color: {
        id: '0',
        rgb: '103, 101, 248, 1',
      },
    },
  ];

  const setSearchDebounce = useDebounce(text => {
    updateCustomer({
      variables: {
        input: {
          id: customerId,
          note: text,
        },
      },
    });
  }, 400);

  return (
    <Wrapper>
      <Seo title="Основная информация" />
      {loading ? (
        <SkeletonAbout />
      ) : (
        <>
          <Content gap="24px">
            <Text typography="title18">Основная информация</Text>
            <Flex direction="column" gap={16}>
              {tags && (
                <TagsWrapper>
                  {tags.map(item => (
                    <TagItem
                      key={item.id}
                      isCreate={item.id === '0'}
                      name={item.name}
                      color={item.color?.rgb}
                      onClick={async () => {
                        if (item.id === '0') {
                          setModalCreateTag(true);
                        } else {
                          if (customer) {
                            try {
                              await deleteTagFromCustomer({
                                variables: {
                                  input: {
                                    id: customerId!,
                                    tags_id: tags
                                      .filter(
                                        item2 =>
                                          item2.id !== item.id &&
                                          item2.id !== '0',
                                      )
                                      .map(item2 => item2.id),
                                  },
                                },
                              });
                            } catch (e) {
                              if (e instanceof ApolloError) {
                                showAlert('error', e.message);
                              }
                            }
                          }
                        }
                      }}
                    />
                  ))}
                </TagsWrapper>
              )}
              <TextView title="Имя" text={name} />
              <TextView title="Фамилия" text={customer?.surname} />
              <TextView
                title="Номер телефона"
                text={getPhoneMask(customer?.phone) ?? ''}
              />
            </Flex>
            <SelectTags
              visible={modalCreateTag}
              onClose={() => setModalCreateTag(false)}
              id={customer?.id}
            />
            {contact_permission && <Communicate phone={customer?.phone} />}
          </Content>
          <Content gap="24px">
            <Text typography="title18">Дополнительно</Text>
            <Flex direction="column" gap={16}>
              <TextView
                title="Дата рождения"
                text={
                  customer?.birthdate &&
                  dayjs(customer.birthdate).format('DD.MM.YYYY')
                }
              />
              <TextView title="E-mail" text={customer?.email} />
              <TextView
                title="Пол"
                text={customer?.gender && Gender[customer?.gender]}
              />
            </Flex>
            <TextArea
              label="Примечание"
              size="medium"
              value={note}
              onChange={text => {
                setNote(text);
                setSearchDebounce(text);
              }}
            />
          </Content>
        </>
      )}
    </Wrapper>
  );
};

const TagItem = ({
  name,
  color,
  onClick,
  isCreate,
}: {
  name: string;
  color?: string;
  onClick: () => void;
  isCreate: boolean;
}) => {
  const colors = useColors();
  return (
    <TagItemWrapper
      onClick={() => {
        if (isCreate) {
          onClick();
        }
      }}>
      <Text
        typography="footNote12Medium"
        style={{color: `rgb(${color})`, width: 'max-content'}}>
        {name}
      </Text>
      <IconButton variant="unstyled" size={16} onClick={onClick}>
        {isCreate ? (
          <CirclePlus size={16} />
        ) : (
          <CircleClose size={16} color={colors.textSecondary} />
        )}
      </IconButton>
    </TagItemWrapper>
  );
};
