import {gql} from 'shared/__generated__';

export {Messengers} from './ui/Messengers';
export {BadgeTag} from './ui/BadgeTag';
export {ImportModal} from './ui/ImportModal';

export const GET_CUSTOMERS = gql(`
query GetCustomers($companyId: String!, 
  $first: Int!, 
  $page: Int, 
  $query: String, 
  $isVip: Boolean, 
  $isBlocked: Boolean, 
  $birthdate: Boolean,
  $gender: CustomerGender,
  $customerCategory: CustomerCategory,
  $customerTags: [String!],
  $orderby: CustomerOrder,
  $order: OrderByOrder) {
  customers(company_id: $companyId, 
    first: $first, 
    page: $page, 
    query: $query, 
    is_vip: $isVip, 
    is_blocked: $isBlocked, 
    birthdate: $birthdate,
    gender: $gender,
    customer_category: $customerCategory,
    customer_tags: $customerTags,
    orderby: $orderby,
    order: $order) {
    paginatorInfo {
      hasMorePages
      currentPage
      total
      count
      lastPage
    }
    data {
      id
      avatar {
        id
        url
        url_256
      }
      name
      surname
      phone
      email
      total_visits
      total_cancelled
      total_earned
      is_vip
      is_blocked
      birthdate
      tag {
        id
        name
        order
        color {
          id
          rgb
        }
      }
    }
  }
  companyTags(company_id: $companyId) {
    id
    name
  }
  getCustomerSegments(company_id: $companyId) {
      regular
      prospects
      lost
      inactive
      blocked
    }
  ...Permissions
}
`);

export const DELETE_CUSTOMER = gql(`
  mutation DeleteCustomer($deleteCustomerId: String!) {
    deleteCustomer(id: $deleteCustomerId) {
      id
    }
  }
`);
