import React, {useEffect, useRef} from 'react';
import {useState} from 'react';
import {TextInput} from 'shared/ui/TextInput';
import {Text} from 'shared/ui/Text';
import {checkEmail, checkPhone, getEmailCode, getSmsCode} from 'shared/lib/api';
import {useNavigate} from 'react-router-dom';
import {ensureError} from 'shared/lib/utils';
import {Button} from 'shared/ui/Button';
import {Content} from 'shared/ui/Content';
import {Flex} from 'shared/ui/Flex';
import {Tooltip} from 'shared/ui/Tooltip';
import CirclePelp from 'shared/icons/CirclePelp';
import {useColors} from 'shared/lib/hooks';
import {NavigateBack} from 'shared/ui/NavigateBack';
import {Seo} from 'shared/ui/Seo';
import ReCAPTCHA from 'react-google-recaptcha';
import {showAlert} from 'shared/ui/Alert';

export function RestorePassword() {
  const colors = useColors();
  const [login, setLogin] = useState('');
  const [error, setError] = useState<string | null>(null);
  const emailRegex = /@/;
  const phoneRegex = /^\d{11}$/;
  const navigate = useNavigate();
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);

  const getNewCode = async () => {
    if (phoneRegex.test(login)) {
      try {
        await checkPhone(login);
        recaptchaRef.current?.execute();
      } catch (err) {
        const error = ensureError(err);
        setError(error.message);
      }
    } else if (emailRegex.test(login)) {
      try {
        await checkEmail(login);
        recaptchaRef.current?.execute();
      } catch (err) {
        const error = ensureError(err);
        setError(error.message);
      }
    } else {
      if (!/^\d+$/.test(login)) {
        setError('Адрес электронной почты введен неправильно!');
      } else {
        setError('Номер телефона введен неправильно!');
      }
    }
  };

  const onCaptchaChange = async (token: string | null) => {
    if (!token) {
      return;
    }
    if (phoneRegex.test(login)) {
      await getSmsCode(login, token);
      navigate('/confirm-phone', {
        state: {phone: login, status: 'restore'},
      });
      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
      }
    } else {
      await getEmailCode(login, token);
      navigate('/confirm-email', {state: {email: login}});
      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
      }
    }
  };
  useEffect(() => {
    setError(null);
  }, [login]);
  return (
    <Content gap="24px">
      <Seo title="Восстановление пароля" />
      <Flex direction="column" gap={16}>
        <Flex alignItems="center" gap={16}>
          <NavigateBack />
          <Text typography="title20">Восстановление пароля</Text>
        </Flex>
        <Text color="textTertiary">
          Введите номер телефона или Email. Мы вышлем вам код подтверждения
        </Text>
      </Flex>
      <Flex direction="column" gap={32}>
        <TextInput
          value={login}
          onChange={setLogin}
          label="Номер телефона или Email"
          error={error}
          rightElement={
            <Tooltip
              style={{padding: '8px 12px', borderRadius: 8}}
              typography="footNote12Medium"
              text={'Пример ввода телефона: 79142223322'}>
              <CirclePelp color={colors.textTertiary} />
            </Tooltip>
          }
        />

        <Button size="large" disabled={login.length === 0} onClick={getNewCode}>
          Получить код
        </Button>
      </Flex>
      <Text align="center" typography="subHead14Regular" color="textTertiary">
        После нажатия на кнопку вы получите код
      </Text>
      <ReCAPTCHA
        style={{display: 'inline-block', visibility: 'hidden'}}
        ref={recaptchaRef}
        size="invisible"
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY!}
        onChange={onCaptchaChange}
        onExpired={() => {
          showAlert('error', 'Время ввода капчи истекло');
          recaptchaRef.current?.reset();
        }}
        onErrored={() => {
          showAlert('error', 'Ошибка ввода капчи');
          recaptchaRef.current?.reset();
        }}
      />
    </Content>
  );
}
