import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {Text} from 'shared/ui/Text';
import {IconButton} from 'shared/ui/IconButton';
import {ArrowLeft2v} from 'shared/icons/ArrowLeft2v';
import {ArrowRight2v} from 'shared/icons/ArrowRight2v';
import {useColors, useResize} from 'shared/lib/hooks';

type ListHorizontalProps = React.PropsWithChildren<{title?: string}>;

const Container = styled.div`
  display: flex;
  overflow-x: scroll;
  scroll-behavior: smooth;
  padding: 0 24px;
  gap: 24px;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const TitleHeader = styled.div`
  display: flex;
  flex: 1;
  height: 48px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 16px;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const ListHorizontal: React.FC<ListHorizontalProps> = ({
  children,
  title,
}) => {
  const colors = useColors();
  const {width} = useResize();
  const containerRef = useRef<HTMLDivElement>(null);
  const [showScrollButtons, setShowScrollButtons] = useState(false);

  useEffect(() => {
    if (
      containerRef.current !== null &&
      containerRef.current.scrollWidth &&
      containerRef.current.clientWidth
    ) {
      const handleScroll = () => {
        containerRef.current !== null &&
          setShowScrollButtons(
            containerRef.current.scrollWidth > containerRef.current.clientWidth,
          );
      };
      containerRef.current.addEventListener('scroll', handleScroll);
      handleScroll();
    }
  }, [width]);

  const scrollTo = (direction: 'left' | 'right') => {
    const container = containerRef.current;
    if (!container) return;
    const scrollAmount =
      direction === 'right' ? container.clientWidth : -container.clientWidth;
    container.scrollBy({
      left: scrollAmount,
    });
  };

  return (
    <div>
      <TitleHeader>
        <Text typography="title20">{title}</Text>
        {showScrollButtons && (
          <Row>
            <IconButton
              size={48}
              style={{
                borderRadius: 48,
                backgroundColor: colors.bgPrimary,
              }}
              onClick={() => scrollTo('left')}>
              <ArrowLeft2v />
            </IconButton>
            <IconButton
              size={48}
              style={{
                borderRadius: 48,
                backgroundColor: colors.bgPrimary,
              }}
              onClick={() => scrollTo('right')}>
              <ArrowRight2v />
            </IconButton>
          </Row>
        )}
      </TitleHeader>
      <div style={{display: 'grid'}}>
        <Container ref={containerRef}>{children}</Container>
      </div>
    </div>
  );
};
