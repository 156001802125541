import React from 'react';

export const NewPage = ({
  size = 24,
  color = '#6765F8',
}: {
  size?: number;
  color?: string;
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.75 15.75H19.25C19.8023 15.75 20.25 15.3023 20.25 14.75V4.75C20.25 4.19772 19.8023 3.75 19.25 3.75H9.25C8.69772 3.75 8.25 4.19772 8.25 4.75V8.25"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.75 8.25H4.75C4.19772 8.25 3.75 8.69772 3.75 9.25V19.25C3.75 19.8023 4.19772 20.25 4.75 20.25H14.75C15.3023 20.25 15.75 19.8023 15.75 19.25V9.25C15.75 8.69772 15.3023 8.25 14.75 8.25Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
