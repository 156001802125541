import dayjs from 'dayjs';
import React, {useState} from 'react';
import {useParams} from 'react-router-dom';
import {device} from 'shared/device';
import styled from 'styled-components';
import {useAppSelector} from 'shared/store';
import {Seo} from 'shared/ui/Seo';
import {gql} from 'shared/__generated__';
import {useColors, useEmployeePermission} from 'shared/lib/hooks';
import {
  getDates,
  getName,
  getStartEndWeekDaysMonth,
  weekdays,
} from 'shared/lib/utils';
import {
  EMPLOYEE_SCHEDULE,
  GET_EMPLOYEES,
  SettingSchedule,
  WorkDate,
} from 'entities/schedule';
import {NetworkStatus, useMutation, useQuery} from '@apollo/client';
import {GET_COMPANY_EMPLOYEES} from 'entities/employees';
import {Content} from 'shared/ui/Content';
import {ButtonDate} from './ui/ButtonDate';
import {Button} from 'shared/ui/Button';
import {ScheduleCard} from './ui/ScheduleCard';
import {showAlert} from 'shared/ui/Alert';
import {Pencil} from 'shared/icons/Pencil';
import {Forbid} from 'shared/icons/Forbid';
import {Skeleton} from 'shared/ui/Skeleton';
import {Text} from 'shared/ui/Text';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  border-radius: 24px;
  min-height: 50vh;
  gap: 24px;
  padding-bottom: 24px;
  margin-top: 8px;
  @media (${device.mobile}) {
    gap: 8px;
    border-radius: 0;
  }
`;
const ScheduleLayout = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
  flex-wrap: wrap;
  max-width: 968px;
`;
const Head = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  margin: 24px 0 8px;
`;
const Week = styled(Text)`
  display: flex;
  padding: 16px 0px 8px 0px;
  justify-content: center;
  align-items: center;
  width: 124px;
`;
const Row = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const DELETE_DATE = gql(`
  mutation DeleteWorkingDates($deleteWorkingDatesId: String!) {
    deleteWorkingDates(id: $deleteWorkingDatesId) {
      id
    }
  }
`);

const WeekName = ({children, month}: {children: string; month: number}) => {
  const isToday = dayjs().format('dd') === children;
  const isCurrentMonth = dayjs().month() === month;
  return (
    <Week
      typography="text16Semibold"
      color={isToday && isCurrentMonth ? 'mainPrimary' : 'textPrimary'}>
      {children}
    </Week>
  );
};

export const EmployeeSchedule = () => {
  const params = useParams();
  const colors = useColors();
  const employeeId = params.id!;
  const companyId = useAppSelector(state => state.company.data!.id);
  const [isSelectable, setIsSelectable] = useState(false);
  const [visible, setVisible] = useState(false);
  const [startMonth, setStartMonth] = useState(dayjs().startOf('month'));
  const datesStartEnd = getStartEndWeekDaysMonth(startMonth);
  const [selects, setSelects] = useState<WorkDate[]>([]);
  const {data, networkStatus} = useQuery(EMPLOYEE_SCHEDULE, {
    variables: {employeeId, companyId},
  });
  const {edit_work_schedule_permission} = useEmployeePermission(data);
  const employee = data?.employee;
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteWorkingDate] = useMutation(DELETE_DATE, {
    refetchQueries: [
      {
        query: EMPLOYEE_SCHEDULE,
        variables: {employeeId, companyId},
      },
      {
        query: GET_EMPLOYEES,
        variables: {companyId, first: 20, page: 1, enabled: true},
      },
      {
        query: GET_COMPANY_EMPLOYEES,
        variables: {companyId, first: 20},
      },
    ],
  });
  const datesArray = getDates(datesStartEnd);
  const workDates = data?.employee?.workingDates;
  const schedules = datesArray.map(item => ({
    date: item,
    ...workDates?.find(wDate => wDate.date === item),
  }));

  return (
    <Wrapper>
      <Seo title="График работ сотрудника" />
      <Content gap="0">
        {visible && employee && (
          <SettingSchedule
            employeeId={employee.id}
            name={getName(employee.name, employee.surname, 'employee')}
            url={employee.avatar?.url}
            isSelectable={isSelectable}
            selects={selects}
            visible={visible}
            onClose={() => {
              setVisible(false);
              setSelects([]);
            }}
          />
        )}
        <Row>
          <ButtonDate startMonth={startMonth} onChange={setStartMonth} />
          {edit_work_schedule_permission ? (
            <Button
              variant="light"
              typography="subHead14Medium"
              onClick={() => {
                setIsSelectable(prev => !prev);
                isSelectable && setSelects([]);
              }}>
              {isSelectable ? 'Отмена' : 'Выбрать несколько дат'}
            </Button>
          ) : null}
        </Row>
        <Head>
          {weekdays.map(item => (
            <WeekName month={dayjs(startMonth).month()} key={item}>
              {item}
            </WeekName>
          ))}
        </Head>
        <ScheduleLayout>
          {networkStatus === NetworkStatus.loading ? (
            <ScheduleSkeleton />
          ) : (
            schedules.map((item, index) => {
              const isWork = item.id;
              const isSelect = !!selects.find(
                _item => _item.date === item.date,
              );
              return (
                <ScheduleCard
                  onClick={() => {
                    if (!edit_work_schedule_permission) {
                      showAlert(
                        'warning',
                        'У вас нет прав на создание/редактирование/удаление графика работы',
                      );
                      return;
                    }
                    if (!isSelectable) {
                      setSelects([item]);
                      setVisible(true);
                      return;
                    }
                    if (!isSelect) {
                      setSelects(prev => [...prev, item]);
                      return;
                    }
                    setSelects(prev =>
                      prev.filter(select => item.date !== select.date),
                    );
                  }}
                  key={index}
                  date={item.date}
                  isWork={!!isWork}
                  start={item?.start_time}
                  end={item?.end_time}
                  select={isSelect}
                  selectedMonth={startMonth.format('YYYY-MM-DD')}
                />
              );
            })
          )}
        </ScheduleLayout>
      </Content>
      {isSelectable && (
        <Row style={{justifyContent: 'center', gap: 16, marginBottom: 32}}>
          <Button
            disabled={!selects.length}
            size="medium"
            variant="outline"
            leftIcon={<Pencil />}
            onClick={() => {
              setVisible(true);
            }}>
            Настроить
          </Button>
          <Button
            disabled={!selects.length && !!selects.map(item => item.id)}
            size="medium"
            variant="outline"
            leftIcon={<Forbid color={colors.dangerPrimary} />}
            loading={deleteLoading}
            style={{
              borderColor: colors.dangerPrimary,
              color: colors.dangerPrimary,
            }}
            onClick={async () => {
              try {
                setDeleteLoading(true);
                selects
                  .filter(item => item.id)
                  .forEach(({id}) => {
                    if (id) {
                      deleteWorkingDate({
                        variables: {deleteWorkingDatesId: id},
                      });
                    }
                  });
              } catch (e) {
                if (e instanceof Error) {
                  showAlert('error', e.message);
                }
              } finally {
                setDeleteLoading(false);
                setSelects([]);
              }
            }}>
            Выходной
          </Button>
        </Row>
      )}
    </Wrapper>
  );
};

const counts = Array.from(Array(5).keys());
const days = Array.from(Array(7).keys());

const ScheduleSkeleton = () => {
  return (
    <Skeleton height={680}>
      {counts.map((columnItem, index) => (
        <React.Fragment key={'row_' + columnItem}>
          {days.map((_, indexDay) => (
            <>
              <rect
                key={'employee_day_' + index + indexDay}
                width={124}
                height={128}
                rx={12}
                y={136 * index}
                x={indexDay * 132}
              />
              <rect
                key={'employee_day_' + index + indexDay + 1}
                width={124}
                height={48}
                rx={12}
                y={136 * index}
                x={indexDay * 132}
              />
            </>
          ))}
        </React.Fragment>
      ))}
    </Skeleton>
  );
};
