import React from 'react';
export const Chat = ({
  size = 24,
  color = '#6765F8',
}: {
  size?: number;
  color?: string;
}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path
        d="M9 12C9 12.5523 8.55228 13 8 13C7.44772 13 7 12.5523 7 12C7 11.4477 7.44772 11 8 11C8.55228 11 9 11.4477 9 12Z"
        fill={color}
      />
      <path
        d="M13 12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12Z"
        fill={color}
      />
      <path
        d="M17 12C17 12.5523 16.5523 13 16 13C15.4477 13 15 12.5523 15 12C15 11.4477 15.4477 11 16 11C16.5523 11 17 11.4477 17 12Z"
        fill={color}
      />
      <path
        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 13.4397 3.33805 14.8005 3.93912 16.0072C4.09884 16.3279 4.15201 16.6945 4.05941 17.0406L3.52336 19.044C3.29066 19.9137 4.08631 20.7093 4.95601 20.4766L6.95945 19.9406C7.30554 19.848 7.67209 19.9012 7.99278 20.0609C9.19953 20.6619 10.5603 21 12 21Z"
        stroke={color}
        strokeWidth="1.5"
      />
    </svg>
  );
};
