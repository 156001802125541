import React, {useState} from 'react';
import styled from 'styled-components';
import {Text} from './Text';

const sizes = {
  small: 62,
  medium: 100,
  large: 160,
};

const labelTop = {
  small: 8,
  medium: 0,
  large: 6,
};

const areaTop = {
  small: 19,
  medium: 16,
  large: 16,
};

const Wrapper = styled.div<{
  size: 'small' | 'medium' | 'large';
  error?: string;
  focused?: boolean;
}>`
  position: relative;
  height: ${props => sizes[props.size]}px;
  background-color: ${props => props.theme.fillPrimary};
  border: 1px solid
    ${({error, focused, theme}) =>
      error
        ? theme.dangerPrimary
        : focused
        ? theme.mainPrimary
        : theme.borderPrimary};
  border-radius: 18px;
  padding: 0 16px;
  &:focus {
    border-color: ${props =>
      props.error ? props.theme.dangerPrimary : props.theme.mainPrimary};
  }
`;

const TextAreaSelf = styled.textarea<{
  size: 'small' | 'medium' | 'large';
  floating?: boolean;
  label?: string;
}>`
  background-color: transparent;
  height: ${props => sizes[props.size] - 48}px;
  outline: none;
  font-family: 'Inter';
  font-size: 16px;
  resize: none;
  padding: 0;
  border: none;
  width: 100%;
  color: ${props => props.theme.textPrimary};
  margin: ${props => areaTop[props.size]}px 0 0 0;
  transform: ${props =>
    props.floating && props.label
      ? `translateY(${20 - labelTop[props.size]}px)`
      : 'none'};
  transition: transform 150ms ease;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  &::placeholder {
    color: ${props => props.theme.textTertiary};
  }
`;

const Label = styled.span<{
  floating?: boolean;
  size: 'small' | 'medium' | 'large';
}>`
  position: absolute;
  left: 16px;
  top: ${props => areaTop[props.size]}px;
  font-size: ${props => (props.floating ? '12px' : '16px')};
  line-height: ${props => (props.floating ? '18px' : '20px')};
  font-weight: 400;
  color: ${props => props.theme.textTertiary};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  transition:
    transform 150ms ease,
    color 150ms ease,
    font-size 150ms ease;
  transform: ${props =>
    props.floating ? `translateY(-${labelTop[props.size]}px)` : 'none'};
  pointer-events: none;
  @media (max-width: 768px) {
    font-size: ${props => (props.floating ? '12px' : '20px')};
  }
`;

const LimitView = styled.div`
  position: absolute;
  right: 16px;
  bottom: 16px;
`;

type Props = {
  ref?: React.LegacyRef<HTMLTextAreaElement>;
  value: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  maxLength?: number;
  label?: string;
  size?: 'small' | 'medium' | 'large';
  error?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
  rightElement?: React.ReactElement;
  color?: string;
};

export const TextArea = (props: Props) => {
  const {
    ref,
    value,
    onChange,
    placeholder,
    maxLength,
    label,
    size = 'small',
    disabled,
    error,
    style,
    rightElement,
    color,
  } = props;
  const [focused, setFocused] = useState(false);
  const floating = (value && value.toString().trim().length !== 0) || focused;
  return (
    <div>
      <Wrapper focused={focused} style={style} size={size} error={error}>
        {label ? (
          <Label floating={floating || !!placeholder} size={size}>
            {label}
          </Label>
        ) : null}
        <TextAreaSelf
          ref={ref}
          size={size}
          label={label}
          value={value}
          disabled={disabled}
          floating={floating || !!placeholder}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          onChange={e => onChange && onChange(e.target.value)}
          placeholder={placeholder}
          maxLength={maxLength}
          style={{
            color: color,
          }}
        />
        {maxLength && (
          <LimitView>
            <Text typography="footNote12Regular" color="textTertiary">
              {value.length}/{maxLength}
            </Text>
          </LimitView>
        )}
        {rightElement}
      </Wrapper>
      {error ? (
        <Text
          typography="footNote12Regular"
          color="dangerPrimary"
          style={{marginTop: 8}}>
          {error}
        </Text>
      ) : null}
    </div>
  );
};
