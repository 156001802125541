import React from 'react';
import styled from 'styled-components';
import {Text} from './Text';
import {Radio} from 'shared/icons/Radio';
import {device} from 'shared/device';
import {useColors} from 'shared/lib/hooks';

const WrapperRadioButton = styled.div<{
  labelPosition?: 'left' | 'right';
  justifyContent?: string;
  pointerEvents?: 'all' | 'none';
  hover: boolean;
}>`
  display: flex;
  gap: 8px;
  flex: 1;
  flex-direction: ${props =>
    props.labelPosition === 'right' ? 'row-reverse' : 'row'};
  align-items: center;
  min-height: 48px;
  max-height: 48px;
  justify-content: ${props => props.justifyContent ?? 'space-between'};
  pointer-events: ${({pointerEvents}) => pointerEvents ?? 'auto'};
  cursor: pointer;
  &:hover {
    background-color: ${({theme, hover}) =>
      hover ? theme.bgSecondary : 'transparent'};
  }
  @media ${device.desktop} {
    height: 46px;
  }
`;

type Props = {
  labelPosition?: 'left' | 'right';
  pointerEvents?: 'all' | 'none';
  title?: string;
  selected?: boolean;
  disabled?: boolean;
  onChange?: (value: boolean) => void;
  style?: React.CSSProperties;
  hoverEnabled?: boolean;
};

export const RadioButton = (props: Props) => {
  const {
    title,
    labelPosition = 'left',
    selected,
    onChange,
    disabled,
    pointerEvents,
    style,
    hoverEnabled = false,
  } = props;
  const colors = useColors();
  return (
    <WrapperRadioButton
      justifyContent={labelPosition === 'right' ? 'flex-end' : 'space-between'}
      labelPosition={labelPosition}
      pointerEvents={pointerEvents}
      hover={hoverEnabled}
      style={style}
      onClick={() => onChange && !disabled && onChange(!selected)}>
      {title ? (
        <Text
          typography={'text16Regular'}
          color={disabled ? 'textTertiary' : 'textPrimary'}
          style={{
            marginLeft: labelPosition === 'right' ? 8 : 0,
            pointerEvents: 'all',
            cursor: 'pointer',
          }}>
          {title}
        </Text>
      ) : null}
      <Radio
        color={
          disabled
            ? colors.textTertiary
            : selected
            ? colors.mainPrimary
            : colors.textTertiary
        }
        active={selected}
      />
    </WrapperRadioButton>
  );
};
