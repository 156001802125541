import React, {useEffect, useState} from 'react';
import {ReviewsHeader} from './ui/ReviewsHeader';
import {ReviewsSettings} from './ui/Setting';
import {NetworkStatus, useQuery} from '@apollo/client';
import {gql} from 'shared/__generated__';
import {useAppSelector} from 'shared/store';
import {ReviewsTable} from './ui/ReviewsTable';
import {Layout, LayoutItemWrapper} from 'shared/ui/Layout';
import {EmptyState} from './ui/EmptyState';
import {ReviewsSkeleton} from './ui/Skeleton';
import {ReviewModal} from './ui/ReviewModal';
import {
  InputMaybe,
  RatingsType,
  ReviewsOrderBy,
  ReviewsReply,
  ReviewsTarget,
} from 'shared/__generated__/graphql';
import {FilterChip} from './ui/FilterChipComp';
import dayjs from 'dayjs';
import {ErrorComponent} from './ui/ErrorComp';
import {useLocation} from 'react-router-dom';
import {useEmployeePermission} from 'shared/lib/hooks';
import {Seo} from 'shared/ui/Seo';

const GET_REVIEWS = gql(`
query ReviewsMainReviews($input: GetReviews!, $first: Int!, $page: Int, $companyId: String!) {
  reviews(input: $input, first: $first, page: $page) {
    data {
      id
      rating
      created_at
      updated_at
      comment
      user {
        id
        name
        surname
        avatar {
          id
          type
          url
        }
      }
      reply {
        text
        as_company
        id
        updated_at
      }
      images {
        id
        url
      }
      employee {
        id
        name
        surname
        professions {
          id
          name
        }
        avatar {
          id
          type
          url
        }
      }
      booking {
        workingDate {
          id
          date
        }
      }
      company {
        id
        avatar {
          url
          type
          id
        }
        name
      }
    }
    paginatorInfo {
      hasMorePages
      currentPage
      lastPage
      total
      count
    }
  }
  ...Permissions
}
`);

type OptionsType = {
  first: number;
  page: number;
  lastPage?: number;
  total?: number;
  count?: number;
};

type FilterType = {
  reply?: 'with' | 'without';
  target?: 'company' | 'employees';
  ratings?: 'good' | 'bad';
  period?: number;
  orderBy?: string;
  search?: string;
};

const getPeriodDate = (value: number) => {
  switch (true) {
    case value === 999:
      return {
        from: dayjs().startOf('month').format('YYYY-MM-DD'),
        to: dayjs().endOf('month').format('YYYY-MM-DD'),
      };
    case value > 1:
      return {
        from: dayjs().add(-value, 'd').format('YYYY-MM-DD'),
        to: dayjs().format('YYYY-MM-DD'),
      };
    case value === 0:
      return {
        from: dayjs().format('YYYY-MM-DD'),
        to: dayjs().format('YYYY-MM-DD'),
      };
    case value === 1:
      return {
        from: dayjs().add(-1, 'd').format('YYYY-MM-DD'),
        to: dayjs().add(-1, 'd').format('YYYY-MM-DD'),
      };
    case value === undefined:
      return {
        from: undefined,
        to: undefined,
      };
  }
};

export const Reviews = () => {
  const [modal, setModal] = useState(false);
  const [reviewIndex, setReviewIndex] = useState(0);
  const companyId = useAppSelector(state => state.company.data!.id);

  const mobileFilters: FilterType = useLocation().state;

  const [options, setOptions] = useState<OptionsType>({
    page: 1,
    first: 20,
    lastPage: 0,
    total: 0,
    count: 0,
  });

  const [filters, setFilters] = useState<FilterType>({
    reply: undefined,
    target: undefined,
    ratings: undefined,
    period: undefined,
    orderBy: undefined,
    search: undefined,
  });

  const {data, networkStatus} = useQuery(GET_REVIEWS, {
    variables: {
      companyId,
      input: {
        company_id: companyId,
        reply: filters.reply as InputMaybe<ReviewsReply>,
        target: filters.target as InputMaybe<ReviewsTarget>,
        ratings: filters.ratings as InputMaybe<RatingsType>,
        from: getPeriodDate(filters.period!)?.from,
        to: getPeriodDate(filters.period!)?.to,
        orderBy: filters.orderBy as InputMaybe<ReviewsOrderBy>,
        query: filters.search === '' ? undefined : filters.search,
      },
      first: options.first,
      page: options.page,
    },
    onCompleted: res =>
      setOptions(prev => ({
        ...prev,
        lastPage: res.reviews?.paginatorInfo.lastPage,
        total: res.reviews?.paginatorInfo.total,
        count: res.reviews?.paginatorInfo.count,
      })),
  });
  const {reviews_reply_permission} = useEmployeePermission(data);
  const reviews = data?.reviews?.data ?? [];
  const workingDate = reviews[reviewIndex]?.booking?.workingDate.date ?? '';
  const getProfessionName = reviews[reviewIndex]?.employee?.professions ?? [];
  const professionName = getProfessionName.length
    ? getProfessionName[0].id
    : 'Нет специализации';

  const isActiveFilters =
    filters.period !== undefined ||
    filters.ratings !== undefined ||
    filters.reply !== undefined ||
    (filters.search !== undefined && reviews.length < 1) ||
    filters.target !== undefined;

  useEffect(() => {
    if (mobileFilters === null) {
      setFilters({
        reply: undefined,
        target: undefined,
        ratings: undefined,
        period: undefined,
        orderBy: undefined,
        search: undefined,
      });
    } else {
      setFilters(mobileFilters);
    }
  }, [mobileFilters]);

  return (
    <Layout variant="sidebar">
      <Seo title="Отзывы" />
      <LayoutItemWrapper start={1} end={13}>
        <ReviewsHeader count={options.total ?? 0} />
        <ReviewsSettings filtersData={filters} onChange={setFilters} />
        <FilterChip filters={filters} onChange={setFilters} />
        {networkStatus === NetworkStatus.loading ||
        networkStatus === NetworkStatus.setVariables ? (
          <ReviewsSkeleton />
        ) : reviews?.length > 0 ? (
          <ReviewsTable
            workingdate={workingDate}
            reviews={reviews}
            options={options}
            setOptions={setOptions}
            onPressOpenReview={index => {
              setReviewIndex(index);
              setModal(true);
            }}
          />
        ) : isActiveFilters ? (
          <ErrorComponent />
        ) : (
          <EmptyState />
        )}
        <ReviewModal
          visible={modal}
          onClose={() => setModal(false)}
          review={reviews[reviewIndex]}
          workingdate={workingDate}
          professionName={professionName}
          permission={reviews_reply_permission}
        />
      </LayoutItemWrapper>
    </Layout>
  );
};
