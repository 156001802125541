import React from 'react';
import {useAppSelector} from 'shared/store';

export const AvatarCompany = ({size = 24}: {size?: number}) => {
  const theme = useAppSelector(state => state.theme.theme);

  if (theme === 'light') {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 124 124"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <rect
          x="0.5"
          y="0.5"
          width="123"
          height="123"
          rx="61.5"
          fill="#EAEEF9"
        />
        <rect
          x="0.5"
          y="0.5"
          width="123"
          height="123"
          rx="61.5"
          stroke="#F2F2F2"
        />
        <rect
          x="19.6333"
          y="19.6334"
          width="84.7333"
          height="84.7333"
          rx="42.3667"
          fill="url(#paint0_linear_15465_5381)"
        />
        <rect x="29" y="29" width="66" height="66" rx="33" fill="#EAEEF9" />
        <path
          d="M64.714 74.1676L42.7506 74.3202L53.6382 61.7885L64.714 74.1676Z"
          fill="#B0B7C3"
        />
        <path
          d="M82.9287 74.0193L51.2675 74.2444L66.9477 56.2486L82.9287 74.0193Z"
          fill="#CED6E2"
        />
        <path
          d="M56.6185 56.2769C58.355 54.7834 58.5507 52.1634 57.0555 50.4249C55.5603 48.6864 52.9404 48.4878 51.2039 49.9813C49.4674 51.4748 49.2718 54.0949 50.767 55.8333C52.2622 57.5718 54.882 57.7704 56.6185 56.2769Z"
          fill="#B0B7C3"
        />
        <defs>
          <linearGradient
            id="paint0_linear_15465_5381"
            x1="61.9724"
            y1="17.6734"
            x2="61.9724"
            y2="105.28"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#FDFEFF" />
            <stop offset="0.9964" stopColor="#ECF0F5" />
          </linearGradient>
        </defs>
      </svg>
    );
  }

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 124 124"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="123" height="123" rx="61.5" fill="#2A2E37" />
      <rect
        x="0.5"
        y="0.5"
        width="123"
        height="123"
        rx="61.5"
        stroke="#2A2A2D"
      />
      <rect
        x="19.6333"
        y="19.6334"
        width="84.7333"
        height="84.7333"
        rx="42.3667"
        fill="url(#paint0_linear_15465_5382)"
      />
      <rect
        x="29"
        y="29"
        width="66"
        height="66"
        rx="33"
        fill="#2A2E37"
        fillOpacity="0.4"
      />
      <path
        d="M64.714 74.1676L42.7506 74.3202L53.6382 61.7885L64.714 74.1676Z"
        fill="#292D36"
      />
      <path
        d="M82.9287 74.0193L51.2675 74.2444L66.9477 56.2486L82.9287 74.0193Z"
        fill="#A9AFC3"
      />
      <path
        d="M56.6185 56.2769C58.355 54.7834 58.5507 52.1634 57.0555 50.4249C55.5603 48.6864 52.9404 48.4878 51.2039 49.9813C49.4674 51.4748 49.2718 54.0949 50.767 55.8333C52.2622 57.5718 54.882 57.7704 56.6185 56.2769Z"
        fill="#292D36"
      />
      <defs>
        <linearGradient
          id="paint0_linear_15465_5382"
          x1="61.9724"
          y1="17.6734"
          x2="61.9724"
          y2="105.28"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#5C6479" />
          <stop offset="0.9964" stopColor="#5C6479" />
        </linearGradient>
      </defs>
    </svg>
  );
};
