import React from 'react';

export const Search = ({
  color = '#6765F8',
  size = 24,
}: {
  color?: string;
  size?: number;
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.2 19.2L21 21M20.1 11.55C20.1 16.272 16.272 20.1 11.55 20.1C6.82797 20.1 3 16.272 3 11.55C3 6.82797 6.82797 3 11.55 3C16.272 3 20.1 6.82797 20.1 11.55Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
