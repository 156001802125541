import React from 'react';
import {IconButton} from './IconButton';
import {ArrowLeft2v} from 'shared/icons/ArrowLeft2v';
import {useNavigate} from 'react-router-dom';

export const NavigateBack = ({size = 40}: {size?: number}) => {
  const navigate = useNavigate();
  return (
    <IconButton size={size} onClick={() => navigate(-1)}>
      <ArrowLeft2v />
    </IconButton>
  );
};
