import {gql} from 'shared/__generated__';
import {
  presets,
  getMethod,
  getEvent,
  getStatus,
  getTime,
  messageSampleBookingsStatus,
  messageSampleEvents,
  messageSampleMethods,
  messageSampleTimes,
  type IPreset,
} from './lib/utils';
import {Preset} from './ui/Preset';
import {GetReadyMessage} from './ui/GetReadyMessage';

const GET_MESSAGES = gql(`
  query MessageSamples(
    $companyId: String!
    $first: Int!
    $name: String
    $page: Int
  ) {
    messageSamples(
      company_id: $companyId
      first: $first
      name: $name
      page: $page
    ) {
      data {
        id
        name
        method
        event
        time
        booking_status
        message
        is_active
      }
      paginatorInfo {
        currentPage
        hasMorePages
        total
        count
        lastPage
      }
    }
  }
`);

export {
  presets,
  getMethod,
  getEvent,
  getStatus,
  getTime,
  messageSampleBookingsStatus,
  messageSampleEvents,
  messageSampleMethods,
  messageSampleTimes,
  IPreset,
  GET_MESSAGES,
  Preset,
  GetReadyMessage,
};
