import React, {useEffect, useState} from 'react';
import {device} from 'shared/device';
import {Button} from 'shared/ui/Button';
import {Content} from 'shared/ui/Content';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';
import {CompanyBookingStatus} from 'shared/__generated__/graphql';
import {
  clearCreateBookingState,
  setCreateBookingEmployee,
  useAppDispatch,
  useAppSelector,
} from 'shared/store';
import {
  BOOKINGS,
  SelectBookingSource,
  SelectCustomer,
  SelectDate,
  SelectEmployee,
  SelectServices,
  Status,
} from 'entities/booking';
import {TextArea} from 'shared/ui/TextArea';
import {Layout, LayoutItemWrapper} from 'shared/ui/Layout';
import {useNavigate} from 'react-router-dom';
import {gql} from 'shared/__generated__';
import {useMutation, useQuery} from '@apollo/client';
import dayjs from 'dayjs';
import {useEmployeePermission} from 'shared/lib/hooks';
import {Flex} from 'shared/ui/Flex';
import {showAlert} from 'shared/ui/Alert';
import {NavigateBack} from 'shared/ui/NavigateBack';
import {Seo} from 'shared/ui/Seo';

const Container = styled(LayoutItemWrapper)`
  gap: 24px;
  display: flex;
  flex-direction: column;
  padding: 64px 0 80px 0;

  position: relative;
  @media ${device.mobile} {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 16px;
    gap: 16px;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
`;

const CREATE_BOOKING = gql(`
  mutation CreateCompanyBooking($input: CreateCompanyBooking!) {
    createCompanyBooking(input: $input) {
      id
    }
  }
`);

const DISCOUNTS = gql(`
  query DiscountsBooking($companyId: String!, $first: Int!) {
    discounts(company_id: $companyId, first: $first) {
      data {
        id
        name
        type
        value
      }
    }
    ...Permissions
  }
`);

export const CreateBooking = () => {
  const [status, setStatus] = useState<CompanyBookingStatus>(
    CompanyBookingStatus.Pending,
  );
  const companyId = useAppSelector(state => state.company.data!.id);
  const {employeeId, customerId, services, workingDate, duration} =
    useAppSelector(state => state.booking.create);
  const [comment, setComment] = useState('');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const dateParam = workingDate ? workingDate.split(',')[0] : undefined;
  const date = dateParam
    ? dayjs(dateParam).format('YYYY-MM-DD HH:mm')
    : undefined;
  const workDateId = workingDate ? workingDate.split(',')[1] : undefined;
  const {data} = useQuery(DISCOUNTS, {
    variables: {
      companyId,
      first: 20,
    },
  });
  const dateBookings = useAppSelector(state => state.booking.date);
  const {create_customers_permission, customers_info_permission} =
    useEmployeePermission(data);
  const [selectSource, setSelectSource] = useState<string | null>(null);

  const discounts = data?.discounts?.data ?? [];

  const [createBooking, {loading}] = useMutation(CREATE_BOOKING, {
    refetchQueries: [
      {
        query: BOOKINGS,
        variables: {
          companyId: companyId,
          date: [dateBookings],
          companyBookingsDate2: dateBookings,
          first: 100,
        },
      },
    ],
  });

  useEffect(() => {}, [customerId]);

  return (
    <Layout columns={12} style={{overflow: 'scroll'}}>
      <Seo title="Создание записи" />
      <Container start={4} end={10}>
        <Header>
          <NavigateBack />
          <Text typography="title24">Новая запись</Text>
        </Header>
        <Status value={status} onChange={setStatus} />
        <Content style={{gap: 32}}>
          <SelectEmployee
            value={employeeId}
            workingDate={dayjs(date).format('YYYY-MM-DD')}
            start={dayjs(date).format('HH:mm')}
            onChange={value => {
              dispatch(setCreateBookingEmployee(value));
            }}
            services={services.map(service => service.service_id)}
          />
          <SelectDate value={date} />
          <SelectCustomer
            value={customerId ?? undefined}
            createPermission={create_customers_permission}
            permission={customers_info_permission}
          />
          <SelectServices />
          <SelectBookingSource
            value={selectSource}
            onChange={setSelectSource}
          />
          <Flex direction="column" gap={16}>
            <Text typography="title18" color="textPrimary">
              Комментарий
            </Text>
            <TextArea
              value={comment}
              onChange={setComment}
              size="large"
              maxLength={1000}
              label="Введите комментарий(только для сотрудников)"
            />
          </Flex>
        </Content>
        <Flex>
          <Button
            size="large"
            style={{
              width: 720,
              position: 'fixed',
              bottom: 24,
            }}
            loading={loading}
            onClick={() => {
              if (date && workDateId) {
                const formattedServices = services.map(item => ({
                  additional_price: +(item.additional_price ?? ''),
                  discount_id: item.discount_id,
                  discount_value: discounts.find(
                    discount => discount.id === item.discount_id,
                  )?.value,
                  price: +(item.price ?? ''),
                  service_id: item.service_id,
                }));
                createBooking({
                  variables: {
                    input: {
                      customer_id: customerId ?? undefined,
                      employee_id:
                        employeeId !== '0' && employeeId
                          ? employeeId
                          : undefined,
                      booking_services: formattedServices!,
                      company_id: companyId,
                      start: dayjs(date).format('HH:mm'),
                      working_date_id: workDateId,
                      status: status,
                      source_id: selectSource ?? undefined,
                      comment: comment,
                      duration: duration !== 0 ? duration : undefined,
                    },
                  },
                })
                  .then(() => {
                    dispatch(clearCreateBookingState());
                    navigate(-1);
                  })
                  .catch(e => {
                    showAlert('error', e.message);
                  });
              } else {
                showAlert('warning', 'Выберите дату');
              }
            }}>
            Создать
          </Button>
        </Flex>
      </Container>
    </Layout>
  );
};
