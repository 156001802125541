import React, {useState} from 'react';
import styled from 'styled-components';
import {Flex} from 'shared/ui/Flex';
import {device} from 'shared/device';
import {Button} from 'shared/ui/Button';
import {TextInput} from 'shared/ui/TextInput';
import {Search as SearchIcon} from 'shared/icons/Search';
import {Text} from 'shared/ui/Text';
import {Plus} from 'shared/icons/Plus';
import {Modal} from 'shared/ui/Modal';
import {useNavigate} from 'react-router-dom';
import {IconButton} from 'shared/ui/IconButton';
import {useColors, useResize} from 'shared/lib/hooks';
import DotsVertical from 'shared/icons/DotsVertical';
import {useAppSelector} from 'shared/store';
import {CSVLink} from 'react-csv';
import {Data} from 'react-csv/lib/core';
import {FilterModal} from './FilterModal';
import {Filter1} from 'shared/icons/Filter1';
import {
  CompanyTag,
  CustomerCategory,
  CustomerGender,
} from 'shared/__generated__/graphql';
import {ImportModal} from 'entities/customers';

export type FilterType = {
  birthdate?: boolean;
  gender?: CustomerGender;
  customerCategory?: CustomerCategory;
  tags?: Array<Pick<CompanyTag, 'id' | 'name'>>;
};

type Props = {
  search?: string;
  page?: number;
  tags: Array<Pick<CompanyTag, 'id' | 'name'>>;
  onChangeText?: (value: string) => void;
  onChangePage?: (value: number) => void;
  filter: FilterType;
  setFilter: React.Dispatch<React.SetStateAction<FilterType>>;
  editPermission: boolean;
  exportData?: Data;
  refetch: () => void;
  onFilterChange: () => void;
};

const Header = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  @media ${device.mobile} {
    margin: 24px 16px 0px;
  }
`;
const PopupWrapper = styled.div`
  position: relative;
  display: inline-block;
`;
const AddButton = styled(IconButton)`
  background-color: ${({theme}) => theme.mainPrimary};
  position: absolute;
  top: 16px;
  right: 16px;
`;
const ButtonSelect = styled.button<{checked?: boolean}>`
  pointer-events: all;
  cursor: pointer;
  display: flex;
  padding: 0 16px;
  align-items: center;
  height: 56px;
  width: 284px;
  background-color: transparent;
  border: none;
  &:hover {
    background-color: ${({theme}) => theme.bgSecondary};
  }
`;
const FilterIndicator = styled.div<{show: boolean}>`
  position: absolute;
  right: -5px;
  top: -5px;
  width: 20px;
  height: 20px;
  background-color: ${({theme}) => theme.mainPrimary};
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  display: ${({show}) => (show ? 'flex' : 'none')};
`;
const CSVButton = styled(CSVLink)<{checked?: boolean}>`
  pointer-events: all;
  cursor: pointer;
  display: flex;
  padding: 0 16px;
  align-items: center;
  height: 56px;
  width: 284px;
  background-color: transparent;
  border: none;
  &:hover {
    background-color: ${({theme}) => theme.bgSecondary};
  }
  text-decoration-line: none;
`;

export const Setting = ({
  search,
  filter,
  tags,
  onChangeText,
  setFilter,
  editPermission,
  refetch,
  onFilterChange,
  exportData = [
    {
      Имя: '',
      Телефон: '',
      Посещений: '',
      Отменённые: '',
      Выручка: '',
      Статус: '',
    },
  ],
}: Props) => {
  const color = useColors();
  const navigate = useNavigate();
  const {isMobile} = useResize();
  const [modalSetting, setModalSetting] = useState(false);
  const [modalFilter, setModalFilter] = useState(false);
  const [modalImport, setModalImport] = useState(false);
  const username = useAppSelector(state => state.company.data?.username);

  const active =
    !!filter.birthdate ||
    !!filter.gender ||
    !!filter.customerCategory ||
    !!filter.tags;

  const countFilter =
    +!!filter.birthdate +
    +!!filter.gender +
    +!!filter.customerCategory +
    +!!filter.tags;

  return (
    <Header direction="row">
      <ImportModal
        visible={modalImport}
        onClose={() => setModalImport(false)}
        onSuccess={() => {
          onFilterChange();
          refetch();
        }}
      />
      <FilterModal
        tags={tags}
        filter={filter}
        setFilter={setFilter}
        visible={modalFilter}
        onClose={() => setModalFilter(false)}
      />
      <Flex gap={8}>
        <TextInput
          height={54}
          borderRadius={14}
          width="320px"
          variant="default"
          onChange={onChangeText}
          value={search}
          backgroundColor={color.bgPrimary}
          placeholder="Введите имя или телефон"
          leftElement={<SearchIcon color={color.textTertiary} />}
        />
        <PopupWrapper>
          <Button
            variant={active ? 'light' : 'filled'}
            typography="subHead14Medium"
            style={{
              backgroundColor: color.bgPrimary,
              borderColor: color.borderPrimary,
              position: 'relative',
              height: 56,
              color: color.textPrimary,
            }}
            size="large"
            onClick={() => setModalFilter(true)}>
            <FilterIndicator show={active ? true : false}>
              <Text typography="footNote12Medium" color="white">
                {countFilter}
              </Text>
            </FilterIndicator>
            Фильтры
            <Filter1 color={color.mainPrimary} />
          </Button>
        </PopupWrapper>
      </Flex>
      <Flex gap={8}>
        {!isMobile && (
          <PopupWrapper>
            <IconButton
              onClick={() => setModalSetting(true)}
              size={48}
              style={{
                borderRadius: 16,
                pointerEvents: 'all',
                backgroundColor: color.bgPrimary,
              }}>
              <DotsVertical />
            </IconButton>
            <Modal
              style={{
                right: 0,
                left: 'auto',
                top: 56,
                paddingLeft: 0,
                paddingRight: 0,
              }}
              visible={modalSetting}
              onClose={() => setModalSetting(false)}>
              <Flex gap={8} direction="column" justifyContent="space-between">
                <ButtonSelect
                  onClick={() => {
                    setModalSetting(false);
                    setModalImport(true);
                  }}>
                  <Text style={{pointerEvents: 'all', cursor: 'pointer'}}>
                    Импортировать клиентов
                  </Text>
                </ButtonSelect>
                <CSVButton
                  filename={'Список клиентов.csv'}
                  data={exportData}
                  onClick={() => {
                    setModalSetting(false);
                  }}>
                  <Text style={{pointerEvents: 'all', cursor: 'pointer'}}>
                    Экспортировать в Excel
                  </Text>
                </CSVButton>
              </Flex>
            </Modal>
          </PopupWrapper>
        )}

        {!editPermission ? null : isMobile ? (
          <AddButton
            size={40}
            onClick={() => navigate(`/${username}/add-customer`)}>
            <Plus />
          </AddButton>
        ) : (
          <Button
            size="medium"
            style={{
              paddingLeft: 32,
              paddingRight: 32,
            }}
            onClick={() => navigate(`/${username}/add-customer`)}
            variant="filled"
            leftIcon={<Plus size={20} />}>
            Добавить клиента
          </Button>
        )}
      </Flex>
    </Header>
  );
};
