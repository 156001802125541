import {gql} from 'shared/__generated__';

export const SALE_SCREEN = gql(`
query Discounts($companyId: String!, $page: Int, $name: String, $first: Int!) {
  discounts(company_id: $companyId, page: $page, name: $name, first: $first) {
    paginatorInfo {
      total
      count
      hasMorePages
      currentPage
      lastPage
    }
    data {
        id
        name
        type
        value
        category {
          id
          title
        }
        valid_from
        valid_to
        valid_from_time
        valid_to_time
        active_on_friday
        active_on_monday
        active_on_saturday
        active_on_sunday
        active_on_thursday
        active_on_tuesday
        active_on_wednesday
    }
  }
  ...Permissions
}
`);
