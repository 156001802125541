import React from 'react';
import styled from 'styled-components';

const Label = styled.label<{changed?: boolean}>`
  pointer-events: ${({changed}) => (changed ? 'all' : 'none')};
  position: relative;
  display: inline-block;
  width: 44px;
  height: 26px;
`;

const Input = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
  pointer-events: auto;
  &:checked + span {
    background-color: ${({theme}) => theme.mainPrimary};
  }
  &:focus + span {
    box-shadow: 0 0 1px ${({theme}) => theme.mainPrimary};
  }
  &:checked + span:before {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
  }
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({theme}) => theme.textTertiary};
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 26px;
  &::before {
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }
`;

export function Switch({
  value,
  onChange,
}: {
  value?: boolean;
  onChange?: (value: boolean) => void;
}) {
  return (
    <Label changed={!!onChange}>
      <Input
        type="checkbox"
        value={value ? '1' : '0'}
        checked={value}
        onChange={() => onChange && onChange(!value)}
      />
      <Slider />
    </Label>
  );
}
