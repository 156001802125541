import React from 'react';
import {device} from 'shared/device';
import styled from 'styled-components';
import {Text} from './Text';
import {Switch} from './Switch';
import {useColors} from 'shared/lib/hooks';
import {ArrowDown2v} from 'shared/icons/ArrowDown2v';
import {Filter1} from 'shared/icons/Filter1';
const Wrapper = styled.button<{active?: boolean}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  gap: 8px;
  height: 56px;
  border-radius: 14px;
  border: 0 solid;
  padding: 0 16px;
  pointer-events: all;
  cursor: pointer;
  background-color: ${props =>
    props.active ? props.theme.mainPrimary : props.theme.bgPrimary};
  border: 1px solid
    ${props =>
      props.active ? props.theme.mainPrimary : props.theme.borderPrimary};
  @media ${device.mobile} {
    border-radius: 12px;
    padding: 0 12px;
  }
`;

const ArrowRotation = styled.div<{visible?: boolean}>`
  transform: ${({visible}) => (visible ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 0.4s ease;
`;

export const FilterButton = ({
  type = 'arrow',
  active,
  visible,
  title,
  leftElement,
  onClick,
}: {
  type?: 'arrow' | 'switch' | 'label' | 'filter';
  active?: boolean;
  visible?: boolean;
  title?: string;
  leftElement?: React.ReactElement;
  onClick?: () => void;
}) => {
  const colors = useColors();
  const TypeElements = {
    arrow: (active?: boolean, visible?: boolean) => (
      <>
        <Text
          style={{
            width: 'max-content',
            pointerEvents: 'all',
            cursor: 'pointer',
          }}
          typography="subHead14Regular"
          color={active ? 'white' : 'textPrimary'}>
          {title}
        </Text>
        <ArrowRotation visible={visible}>
          <ArrowDown2v color={active ? colors.white : colors.mainPrimary} />
        </ArrowRotation>
      </>
    ),
    switch: (active?: boolean) => (
      <>
        <Text
          typography="subHead14Regular"
          color={active ? 'white' : 'textPrimary'}>
          {title}
        </Text>
        <Switch
          value={active}
          onChange={() => {
            onClick && onClick();
          }}
        />
      </>
    ),
    label: (active?: boolean) => (
      <>
        <Text
          typography="subHead14Regular"
          color={active ? 'white' : 'textPrimary'}>
          {title}
        </Text>
      </>
    ),
    filter: (active?: boolean) => <>{active ? '1' : '0'}</>,
  };

  return (
    <Wrapper active={active} onClick={onClick}>
      {leftElement}
      {TypeElements[type](active, visible)}
    </Wrapper>
  );
};

type Size = 'large' | 'medium' | 'small';
const getSize = (size: Size) => {
  switch (size) {
    case 'large':
      return '60px';
    case 'medium':
      return '48px';
    case 'small':
      return '40px';
  }
};

const FilterBtnWrapper = styled.button<{
  size: Size;
  indicator: boolean;
  disabled?: boolean;
  hover: boolean;
}>`
  display: flex;
  position: relative;
  flex-direction: row;
  gap: 8px;
  background-color: ${({theme}) => theme.bgPrimary};
  border: none;
  border-radius: 14px;
  border: 1px solid ${({theme}) => theme.borderPrimary};
  align-items: center;
  height: ${({size}) => getSize(size)};
  padding-left: 32px;
  padding-right: 32px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  font-family: 'Inter';
  pointer-events: all;
  cursor: pointer;
  color: ${({theme, indicator}) =>
    indicator ? theme.mainPrimary : theme.textPrimary};
  &:hover {
    background-color: ${({theme, hover}) =>
      !hover ? theme.bgPrimary : theme.bgSecondary};
  }
`;
const Indicator = styled.div`
  position: absolute;
  top: -4px;
  right: -4px;
  z-index: 1;
  padding: 0 6px;
  color: ${({theme}) => theme.white};
  background-color: ${({theme}) => theme.mainPrimary};
  border-radius: 26px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  font-family: 'Inter';
`;
type Props = {
  onClick: () => void;
  style?: React.CSSProperties;
  size?: Size;
  indicator?: number;
  disabled?: boolean;
  hover?: boolean;
  textVisible?: boolean;
};

export const FilterBtn = ({
  onClick,
  style,
  size = 'medium',
  indicator,
  disabled,
  hover = true,
  textVisible = true,
}: Props) => {
  return (
    <FilterBtnWrapper
      indicator={!!indicator}
      size={size}
      style={style}
      disabled={disabled}
      hover={hover}
      onClick={onClick}>
      {textVisible && 'Фильтры'}
      <Filter1 />
      {!!indicator && <Indicator>{indicator}</Indicator>}
    </FilterBtnWrapper>
  );
};
