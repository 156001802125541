import React from 'react';
export const CalendarBold = ({
  size = 24,
  color = '#6765F8',
}: {
  size?: number;
  color?: string;
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.75 2.74074C7.75 2.33164 7.41421 2 7 2C6.58579 2 6.25 2.33164 6.25 2.74074V4.3005C4.81067 4.41433 3.86577 4.6937 3.17157 5.37933C2.47737 6.06496 2.19451 6.99819 2.07926 8.41975H21.9207C21.8055 6.99819 21.5226 6.06496 20.8284 5.37933C20.1342 4.6937 19.1893 4.41433 17.75 4.3005V2.74074C17.75 2.33164 17.4142 2 17 2C16.5858 2 16.25 2.33164 16.25 2.74074V4.23497C15.5847 4.22222 14.839 4.22222 14 4.22222H10C9.16097 4.22222 8.41527 4.22222 7.75 4.23497V2.74074Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 12.1235C2 11.2948 2 10.5583 2.0129 9.90123H21.9871C22 10.5583 22 11.2948 22 12.1235V14.0988C22 17.8234 22 19.6858 20.8284 20.8429C19.6569 22 17.7712 22 14 22H10C6.22876 22 4.34315 22 3.17157 20.8429C2 19.6858 2 17.8234 2 14.0988V12.1235ZM17 14.0988C17.5523 14.0988 18 13.6566 18 13.1111C18 12.5656 17.5523 12.1235 17 12.1235C16.4477 12.1235 16 12.5656 16 13.1111C16 13.6566 16.4477 14.0988 17 14.0988ZM17 18.0494C17.5523 18.0494 18 17.6072 18 17.0617C18 16.5163 17.5523 16.0741 17 16.0741C16.4477 16.0741 16 16.5163 16 17.0617C16 17.6072 16.4477 18.0494 17 18.0494ZM13 13.1111C13 13.6566 12.5523 14.0988 12 14.0988C11.4477 14.0988 11 13.6566 11 13.1111C11 12.5656 11.4477 12.1235 12 12.1235C12.5523 12.1235 13 12.5656 13 13.1111ZM13 17.0617C13 17.6072 12.5523 18.0494 12 18.0494C11.4477 18.0494 11 17.6072 11 17.0617C11 16.5163 11.4477 16.0741 12 16.0741C12.5523 16.0741 13 16.5163 13 17.0617ZM7 14.0988C7.55228 14.0988 8 13.6566 8 13.1111C8 12.5656 7.55228 12.1235 7 12.1235C6.44772 12.1235 6 12.5656 6 13.1111C6 13.6566 6.44772 14.0988 7 14.0988ZM7 18.0494C7.55228 18.0494 8 17.6072 8 17.0617C8 16.5163 7.55228 16.0741 7 16.0741C6.44772 16.0741 6 16.5163 6 17.0617C6 17.6072 6.44772 18.0494 7 18.0494Z"
        fill={color}
      />
    </svg>
  );
};
