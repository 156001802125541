import React, {useState} from 'react';
import {Content as MainContent} from 'shared/ui/Content';
import {Text} from 'shared/ui/Text';
import {useQuery} from '@apollo/client';
import dayjs from 'dayjs';
import {Error} from 'shared/ui/Error';
import styled from 'styled-components';
import {device} from 'shared/device';
import {useAppSelector} from 'shared/store';
import {DateSwitcher} from './ui/DateSwitcher';
import {DesktopDay} from './ui/DesktopDay';
import {Button} from 'shared/ui/Button';
import {useNavigate} from 'react-router-dom';
import {useEmployeePermission} from 'shared/lib/hooks';
import {EmployeeCard} from './ui/EmployeeCard';
import {BreakTime, WorkingDate} from 'shared/__generated__/graphql';
import {Skeleton} from 'shared/ui/Skeleton';
import {GET_EMPLOYEES, SettingSchedule} from 'entities/schedule';
import {getName} from 'shared/lib/utils';
import {showAlert} from 'shared/ui/Alert';
import {PageHeader} from 'shared/ui/PageHeader';
import {EmptyAddDate} from 'shared/illustration/EmptyAddDate';
import InfiniteScroll from 'react-infinite-scroll-component';
import {Flex} from 'shared/ui/Flex';

const HeadStyledTd = styled.td`
  min-width: 136px;
  height: 48px;
  padding-left: 8px;
`;
const HeadDay = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  color: ${({theme}) => theme.textPrimary};
  justify-content: center;
  align-items: center;
`;

const EmployeesLink = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 256px;
  max-width: 256px;
  padding-top: 72px;
  gap: 24px;
  @media ${device.mobile} {
    display: flex;
    padding-top: 0;
    min-width: 100%;
    max-width: 100%;
    flex-direction: column;
    gap: 24px;
  }
`;
const TableContainer = styled.div`
  overflow-x: scroll;
  pointer-events: all;
  padding-bottom: 20px;
  padding-right: 24px;
  width: 100%;
  @media ${device.mobile} {
    display: none;
  }
`;
const Table = styled.table`
  border-collapse: collapse;
  table-layout: fixed;
  white-space: nowrap;
`;

const Container = styled.div`
  background-color: ${({theme}) => theme.bgSecondary};
  @media ${device.mobile} {
    width: auto;
    padding: 16px 0px;
  }
`;
const Content = styled(MainContent)`
  display: flex;
  background-color: ${({theme}) => theme.bgPrimary};
  max-width: 1386px;
  flex-direction: row;
  padding: 32px 0px 32px 24px;
  gap: 8px;
  @media ${device.mobile} {
    padding: 16px 0px 16px 0px;
    background: none;
    border: none;
  }
`;
const EmptyButton = styled(Button)`
  display: flex;
  flex-direction: column;
  width: calc(100% - 32px);
  padding-left: 32px;
  padding-right: 32px;
  pointer-events: all;
  height: 60px;
  margin-bottom: 24px;
  margin-top: 24px;
  padding-left: 32px;
  padding-right: 32px;
  pointer-events: all;
  @media ${device.desktop} {
    margin: 40px 16px 8px 16px;
    width: auto;
  }
`;
const EmptyComponent = styled.div`
  max-width: 1386px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media ${device.desktop} {
    flex: 0;
  }
`;
const NewScheduleButton = styled(Button)`
  @media ${device.mobile} {
    display: none;
  }
`;
const Header = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 1384px;
  justify-content: space-between;
  margin-bottom: 24px;
`;
const Circle = styled.circle`
  display: flex;
  @media ${device.mobile} {
    display: none;
  }
`;
const RectName = styled.rect`
  display: flex;
  @media ${device.mobile} {
    display: none;
  }
`;
const RectNameMobile = styled.rect`
  display: none;
  @media ${device.mobile} {
    display: flex;
  }
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
`;

export type WorkDay = Pick<
  WorkingDate,
  'id' | 'date' | 'start_time' | 'end_time'
> & {
  breakTimes: Pick<BreakTime, 'id' | 'start' | 'end'>[];
};

const getWeekDates = (weekIndex: number): string[] => {
  const today = dayjs();
  const firstDayWeek = today.startOf('week').add(weekIndex, 'week');
  const newWeek = [];
  for (let day = 0; day < 7; day++) {
    const dayDate = firstDayWeek.add(day, 'day').format('YYYY-MM-DD');
    newWeek.push(dayDate);
  }
  return newWeek;
};

export function Schedule() {
  const companyId = useAppSelector(state => state.company.data!.id);
  const [weekIndex, setWeekIndex] = useState(0);
  const weekDates = getWeekDates(weekIndex);
  const [selected, setSelected] = useState<{
    workingDate: WorkDay;
    employeeIndex: number;
  } | null>(null);
  const navigate = useNavigate();
  const sidebarOffset = document.getElementById('sidebar')?.offsetWidth ?? 0;
  const username = useAppSelector(state => state.company.data?.username);
  const {data, error, loading, fetchMore} = useQuery(GET_EMPLOYEES, {
    variables: {companyId, first: 10, page: 1, enabled: true},
  });

  const fetchMoreData = () => {
    const currentPage = data?.companyEmployees?.paginatorInfo.currentPage || 1;
    if (
      data?.companyEmployees?.paginatorInfo.hasMorePages &&
      data &&
      !loading
    ) {
      fetchMore({
        variables: {
          companyId,
          page: currentPage + 1,
          first: 10,
        },
        updateQuery: (prev, {fetchMoreResult}) => {
          if (!fetchMoreResult) return prev;
          const prevCitiesData = prev.companyEmployees?.data || [];
          const newCitiesData = fetchMoreResult.companyEmployees?.data || [];

          return Object.assign({}, prev, {
            companyEmployees: {
              ...fetchMoreResult.companyEmployees,
              data: [...prevCitiesData, ...newCitiesData],
            },
          });
        },
      });
    }
  };
  const {edit_work_schedule_permission} = useEmployeePermission(data);
  const employees = data?.companyEmployees?.data ?? [];
  const selectedEmployee = selected ? employees[selected.employeeIndex] : null;
  if (error) {
    return <Error message={error.message} />;
  }

  return (
    <Wrapper>
      <PageHeader title={'График работы'} />
      <Container style={{width: `calc(100dvw - ${sidebarOffset + 48}px)`}}>
        <Header>
          <DateSwitcher difference={weekIndex} onChange={setWeekIndex} />
          {edit_work_schedule_permission ? (
            <NewScheduleButton
              size="medium"
              typography="subHead14Medium"
              onClick={() => navigate(`/${username}/create-schedule`)}>
              Создать новый график
            </NewScheduleButton>
          ) : null}
        </Header>
        {loading ? (
          <Content>
            <ScheduleSkeleton />
          </Content>
        ) : employees.length !== 0 ? (
          <InfiniteScroll
            dataLength={employees.length}
            next={fetchMoreData}
            hasMore={
              data?.companyEmployees?.paginatorInfo.hasMorePages || false
            }
            loader={
              loading && (
                <Flex justifyContent="center" alignItems="center">
                  <Text typography="text16Regular">Загрузка...</Text>
                </Flex>
              )
            }>
            <Content>
              <EmployeesLink>
                {employees.map((employee, index) => (
                  <EmployeeCard
                    key={employee.id + index}
                    employee={employee}
                    url={employee.avatar?.url}
                    professions={employee.professions
                      .map(profession => profession.name)
                      .join(', ')}
                    onSelect={date =>
                      setSelected({workingDate: date, employeeIndex: index})
                    }
                    week={weekDates}
                    workingDates={employee.workingDates}
                  />
                ))}
              </EmployeesLink>
              <TableContainer>
                <Table>
                  <thead>
                    {weekDates.map((date, index) => (
                      <HeadStyledTd key={index + '_day_week'}>
                        <HeadDay>{dayjs(date).format('D dd')}</HeadDay>
                      </HeadStyledTd>
                    ))}
                  </thead>
                  <tbody>
                    {employees.map((employee, index) => (
                      <DesktopDay
                        key={employee.id + index}
                        onSelect={date => {
                          if (edit_work_schedule_permission) {
                            setSelected({
                              workingDate: date,
                              employeeIndex: index,
                            });
                          } else {
                            showAlert(
                              'warning',
                              'У вас нет прав доступа на создание/редактирование/удаление графика работы',
                            );
                          }
                        }}
                        week={weekDates}
                        workingDates={employee.workingDates}
                      />
                    ))}
                  </tbody>
                </Table>
              </TableContainer>
            </Content>
          </InfiniteScroll>
        ) : (
          <EmptyComponent>
            <EmptyAddDate size={300} />
            <Text
              typography="text16Regular"
              color="textTertiary"
              align="center">
              У вас пока нет сотрудников.
              <br />
              Добавьте их и настройте график
            </Text>
            <EmptyButton onClick={() => navigate(`/${username}/employees`)}>
              Добавить сотрудника
            </EmptyButton>
          </EmptyComponent>
        )}

        {selected && selectedEmployee && (
          <SettingSchedule
            employeeId={selectedEmployee.id}
            name={getName(
              selectedEmployee.name,
              selectedEmployee.surname,
              'employee',
            )}
            url={selectedEmployee?.avatar?.url}
            isSelectable={false}
            selects={[selected.workingDate]}
            visible={!!selected}
            onClose={() => {
              setSelected(null);
            }}
          />
        )}
      </Container>
    </Wrapper>
  );
}

const COUNT = 3;
const DAY = 7;
const counts = Array.from(Array(COUNT).keys());
const days = Array.from(Array(DAY).keys());

const ScheduleSkeleton = () => {
  return (
    <Skeleton height={600}>
      {days.map((_, index) => (
        <RectName
          key={'day' + index}
          width={46}
          height={17.5}
          rx={12}
          x={313 + index * 136}
          y={15}
        />
      ))}
      {counts.map((columnItem, index) => (
        <React.Fragment key={'row_' + columnItem}>
          <RectNameMobile
            x={0}
            y={8 + 96 * index}
            height={88}
            width="100%"
            rx={24}
          />
          <Circle r={28} cx={28} cy={136 + 152 * index} />
          <RectName
            width={100}
            height={24}
            rx={5}
            y={110 + 152 * index}
            x={64}
          />
          <RectName
            width={140}
            height={20}
            rx={5}
            y={142 + 152 * index}
            x={64}
          />
          {days.map((_, indexDay) => (
            <RectName
              key={'employee_day_' + index + indexDay}
              width={128}
              height={128}
              rx={20}
              y={72 + 152 * index}
              x={272 + indexDay * 136}
            />
          ))}
        </React.Fragment>
      ))}
    </Skeleton>
  );
};
