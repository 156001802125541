import React, {useEffect, useState} from 'react';
import {Popup} from 'shared/ui/Popup';
import {Grow} from 'shared/ui/Grow';
import {Flex} from 'shared/ui/Flex';
import {Text} from 'shared/ui/Text';
import {TextInput} from 'shared/ui/TextInput';
import {CloseButton} from 'shared/ui/CloseButton';
import {Color} from 'shared/__generated__/graphql';
import styled from 'styled-components';
import Check from 'shared/icons/Check';
import {Button} from 'shared/ui/Button';
import {gql} from 'shared/__generated__';
import {useAppSelector} from 'shared/store';
import {useLazyQuery, useMutation} from '@apollo/client';
import {showAlert} from 'shared/ui/Alert';
import {TAGS, TAG_COLORS} from '..';
import {Skeleton} from 'shared/ui/Skeleton';
import {GET_TAGS} from './SelectTags';

const CREATE_CUSTOMER_TAG = gql(`
  mutation CreateCustomerTag($input: CreateCustomerTag!) {
    createCustomerTag(input: $input) {
      id
      name
      order
      color {
        id
        rgb
      }
    }
  }
`);

const ColorCircle = styled.button<{color: string}>`
  display: flex;
  height: 56px;
  width: 56px;
  border-radius: 28px;
  background-color: rgb(${({color}) => color});
  padding: 0;
  border: none;
  margin: 0;
  align-items: center;
  justify-content: center;
  &:hover {
    transform: scale(1.1);
    transition: 0.3s;
  }
`;

const ColorButton = ({
  color,
  active,
  onClick,
}: {
  color: Pick<Color, 'id' | 'rgb'>;
  active: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}) => {
  return (
    <ColorCircle onClick={onClick} color={color.rgb}>
      {active && <Check size={28} />}
    </ColorCircle>
  );
};

export const TagCreateModal = ({
  visible,
  onClose,
  customerId,
  colors = [],
}: {
  visible: boolean;
  onClose: () => void;
  customerId?: string;
  colors?: Array<Pick<Color, 'id' | 'rgb'>>;
}) => {
  const companyId = useAppSelector(state => state.company.data!.id);
  const [tagColors, setTagColors] = useState<Pick<Color, 'id' | 'rgb'>[]>([]);
  const [lazy, {loading}] = useLazyQuery(TAG_COLORS, {
    onCompleted({colors: recColors}) {
      recColors && setTagColors(recColors.data);
    },
  });
  const [create, {loading: createLoading}] = useMutation(CREATE_CUSTOMER_TAG, {
    refetchQueries: [
      {
        query: TAGS,
        variables: {
          companyId,
          first: 100,
          page: 1,
          colorsFirst: 100,
          colorsPage: 1,
        },
      },
      {
        query: GET_TAGS,
        variables: {
          companyId,
          customerId,
        },
      },
    ],
  });
  const [name, setName] = useState('');
  const [selectColor, setSelectColor] = useState<string>('');

  useEffect(() => {
    if (colors.length) {
      setTagColors(colors);
      return;
    }
    visible && lazy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Popup
      style={{width: 598, zIndex: 10000}}
      visible={visible}
      onClose={onClose}>
      <Grow style={{width: '100%', gap: 32}}>
        <Flex alignItems="center" justifyContent="space-between">
          <Text typography="title20">Создать тег</Text>
          <CloseButton
            onClose={() => {
              onClose();
              setName('');
              setSelectColor('');
            }}
          />
        </Flex>
        <TextInput value={name} onChange={setName} label="Название тега" />
        <Text typography="title18">Цвет тега</Text>
        <Flex style={{flexWrap: 'wrap'}} gap={26}>
          {!loading && tagColors?.length
            ? tagColors.map((item, index) => (
                <ColorButton
                  onClick={() => setSelectColor(item.id)}
                  key={`color_${index}`}
                  active={selectColor === item.id}
                  color={item}
                />
              ))
            : Array.from(Array(18)).map((_, index) => (
                <Skeleton key={`skeleton_${index}`} width={56} height={56}>
                  <rect width={56} height={56} rx={28} ry={28} />
                </Skeleton>
              ))}
        </Flex>
        <Flex gap={8}>
          <Button
            size="large"
            style={{flex: 1}}
            variant="outline"
            onClick={() => {
              onClose();
              setName('');
              setSelectColor('');
            }}>
            Отмена
          </Button>
          <Button
            size="large"
            disabled={!selectColor || !name.trim() || createLoading}
            loading={createLoading}
            style={{flex: 1}}
            onClick={async () => {
              try {
                await create({
                  variables: {
                    input: {
                      company_id: companyId,
                      color_id: selectColor,
                      name,
                    },
                  },
                });
                onClose();
                setName('');
                setSelectColor('');
              } catch (e) {
                if (e instanceof Error) {
                  showAlert('error', e.message);
                }
              }
            }}>
            Сохранить
          </Button>
        </Flex>
      </Grow>
    </Popup>
  );
};
