import React from 'react';
import {DeleteBold} from 'shared/icons/DeleteBold';
import {NoImage} from 'shared/icons/NoImage';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  cursor: pointer;
`;

const Img = styled.img<{radius?: number}>`
  border-radius: ${({radius}) => radius}px;
  object-fit: cover;
`;

const DeleteDiv = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  padding: 0;
  z-index: 99;
  transform: translate(8.73px, -8.73px);
  pointer-events: all;
`;

export const Image = ({
  src,
  style,
  width = 88,
  height = 88,
  onDelete,
  radius = 18,
  onClick,
}: {
  src?: string | undefined;
  style?: React.CSSProperties;
  width?: number | string;
  height?: number | string;
  onDelete?: () => void;
  radius?: number;
  onClick?: () => void;
}) => {
  return (
    <Wrapper>
      {src ? (
        <Img
          onClick={onClick}
          radius={radius}
          width={width}
          height={height}
          style={style}
          src={src}
        />
      ) : (
        <NoImage size={+width} />
      )}

      {onDelete && (
        <DeleteDiv onClick={() => onDelete()}>
          <DeleteBold size={26} />
        </DeleteDiv>
      )}
    </Wrapper>
  );
};
