import {useLazyQuery, useMutation} from '@apollo/client';
import React, {useEffect, useState} from 'react';
import {gql} from 'shared/__generated__';
import {Loader} from 'shared/icons/Loader';
import {Search} from 'shared/icons/Search';
import {Trash} from 'shared/icons/Trash';
import {EmptyIssuedCard} from 'shared/illustration/EmptyIssuedCard';
import {useColors, useDebounce} from 'shared/lib/hooks';
import {getName, getNumberFormat, getPhoneMask} from 'shared/lib/utils';
import {CloseButton} from 'shared/ui/CloseButton';
import {Error} from 'shared/ui/Error';
import {Flex} from 'shared/ui/Flex';
import {IconButton} from 'shared/ui/IconButton';
import {Popup} from 'shared/ui/Popup';
import {Skeleton} from 'shared/ui/Skeleton';
import {Table} from 'shared/ui/Table';
import {Text} from 'shared/ui/Text';
import {TextInput} from 'shared/ui/TextInput';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 1;
  width: 940px;
  max-height: 604px;
`;

const GET_BONUS_CUSTOMERS = gql(`
  query BonusCardTemplateCustomers($id: String!, $search: String) {
    bonusCardTemplate(id: $id) {
      bonusCards(search: $search) {
        id
        balance
        customer {
          id
          name
          surname
          phone
        }
      }
    }
  }
`);

const DELETE_BONUS_CARD = gql(`
  mutation DeleteBonusCardIssue($id: String!) {
    deleteBonusCard(id: $id) {
      id
    }
  }
`);

export const ModalIssueBonus = ({
  onClose,
  visible,
  id,
}: {
  id: string;
  visible: boolean;
  onClose: () => void;
}) => {
  const colors = useColors();
  const [search, setSearch] = useState('');
  const [getData, {data, loading, refetch, error}] = useLazyQuery(
    GET_BONUS_CUSTOMERS,
    {
      variables: {
        id,
        search: search,
      },
    },
  );
  const cards =
    data?.bonusCardTemplate?.bonusCards.map(item => ({
      id: item.id,
      name: getName(item.customer.name, item.customer.surname),
      phone: getPhoneMask(item.customer.phone),
      balance: item.balance,
    })) ?? [];

  const [deleteBonusCard, {loading: loadingDelete}] = useMutation(
    DELETE_BONUS_CARD,
    {
      refetchQueries: [{query: GET_BONUS_CUSTOMERS, variables: {id: id}}],
    },
  );

  const queryRefetch = useDebounce((query: string) => {
    const vaiables = {
      id,
      search: query,
    };
    refetch(vaiables);
  }, 400);

  useEffect(() => {
    if (visible) {
      getData();
    }
  }, [getData, visible]);

  if (!visible) {
    return null;
  }

  return (
    <Popup style={{width: 988}} visible={visible} onClose={onClose}>
      <Wrapper>
        <Flex
          direction="row"
          alignItems="center"
          flex={1}
          gap={8}
          justifyContent="space-between">
          <Text typography="title20">Выданные карты</Text>
          <CloseButton onClose={onClose} />
        </Flex>
        <TextInput
          value={search}
          onChange={value => {
            setSearch(value);
            queryRefetch(value);
          }}
          height={54}
          variant="default"
          placeholder="Имя или телефон клиента"
          leftElement={<Search color={colors.textTertiary} />}
          width="348px"
        />
        {loading ? (
          <Skeletons />
        ) : error ? (
          <Error message={error.message} />
        ) : cards.length === 0 ? (
          <EmptyState />
        ) : (
          <Table
            titles={['Имя', 'Телефон', 'Баллы', '']}
            widths={[293.33, 293.33, 293.33, 60]}
            scrollable
            rows={cards.map(card => [
              <Text key={'bonus_customer_name_' + card.id}>{card.name}</Text>,
              <Text key={'bonus_customer_phone_' + card.id}>{card.phone}</Text>,
              <Text key={'bonus_balance_' + card.id}>
                {getNumberFormat(card.balance, ' Б') as string}
              </Text>,
              <IconButton
                key={'bonus_delete_' + card.id}
                style={{
                  backgroundColor: 'transparent',
                  borderColor: 'transparent',
                }}
                onClick={() => {
                  deleteBonusCard({
                    variables: {
                      id: card.id,
                    },
                  });
                }}>
                {loadingDelete && card.id ? (
                  <Loader color={colors.dangerPrimary} />
                ) : (
                  <Trash color={colors.dangerPrimary} />
                )}
              </IconButton>,
            ])}
          />
        )}
      </Wrapper>
    </Popup>
  );
};

const width_sk = 940;

const Skeletons = () => {
  return (
    <Skeleton width={'100%'} height={230 + 71}>
      <rect
        width={(162 * 100) / width_sk + '%'}
        height={22}
        rx={11}
        ry={11}
        x={0}
        y={0}
      />
      <rect
        width={(59 * 100) / width_sk + '%'}
        height={22}
        rx={11}
        ry={11}
        x={(363 * 100) / width_sk + '%'}
        y={0}
      />
      <rect
        width={(59 * 100) / width_sk + '%'}
        height={22}
        rx={11}
        ry={11}
        x={(623 * 100) / width_sk + '%'}
        y={0}
      />
      <circle r={11} cx={(976 * 100) / width_sk + '%'} cy={11} />
      {Array(4)
        .fill(0)
        .map((_, index) => (
          <React.Fragment key={'issue_sk_rect_' + index}>
            <rect
              width={(284 * 100) / width_sk + '%'}
              height={22}
              rx={11}
              ry={11}
              x={0}
              y={52 * (index + 1)}
            />
            <rect
              width={(181 * 100) / width_sk + '%'}
              height={22}
              rx={11}
              ry={11}
              x={(363 * 100) / width_sk + '%'}
              y={52 * (index + 1)}
            />
            <rect
              width={(181 * 100) / width_sk + '%'}
              height={22}
              rx={11}
              ry={11}
              x={(623 * 100) / width_sk + '%'}
              y={52 * (index + 1)}
            />
            <circle
              r={11}
              cx={(976 * 100) / width_sk + '%'}
              cy={11 + 52 * (index + 1)}
            />
          </React.Fragment>
        ))}
    </Skeleton>
  );
};

const EmptyState = () => {
  return (
    <Flex
      direction="column"
      alignItems="center"
      gap={8}
      justifyContent="center">
      <EmptyIssuedCard size={300} />
      <Text align="center" typography="text16Regular" color="textTertiary">
        У вас нет выданных бонусных карт клиентам
      </Text>
    </Flex>
  );
};
