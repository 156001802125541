import React from 'react';
import {CompanyBookingStatus} from 'shared/__generated__/graphql';
import {Avatar} from 'shared/ui/Avatar';
import {BadgeStatus} from 'shared/ui/BadgeStatus';
import {Flex} from 'shared/ui/Flex';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';

const getStatus = {
  pending: CompanyBookingStatus.Pending,
  cancelled: CompanyBookingStatus.Cancelled,
  completed: CompanyBookingStatus.Completed,
};

const getStatusTranslate = {
  pending: 'Ожидание операции',
  cancelled: 'Отменен',
  completed: 'Выполнено',
};

const Wrapper = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: none;
  gap: 16px;
  background-color: transparent;
  padding: 12px 24px;
  pointer-events: all;
  cursor: pointer;
  &:hover {
    background-color: ${({theme}) => theme.bgSecondary};
  }
`;

type Props = {
  avatar?: string;
  title: string;
  name: string;
  status: 'pending' | 'cancelled' | 'completed';
  info: React.ReactElement;
  onClick?: () => void;
};

export const Card = ({avatar, title, name, status, info, onClick}: Props) => {
  return (
    <Wrapper onClick={onClick}>
      <Avatar size={40} url={avatar} />
      <Flex direction="row" alignItems="center" flex={1}>
        <Flex direction="column" flex={1} gap={8}>
          <Text style={{pointerEvents: 'none'}} typography="subHead14Medium">
            {title}
          </Text>
          <Text
            style={{pointerEvents: 'none'}}
            typography="footNote12Medium"
            color="textTertiary">
            {name}
          </Text>
        </Flex>
        <Flex direction="column" gap={6} alignItems="flex-end">
          <BadgeStatus type={getStatus[status]}>
            {getStatusTranslate[status]}
          </BadgeStatus>
          {info}
        </Flex>
      </Flex>
    </Wrapper>
  );
};
