import React, {useState} from 'react';
import {Outlet, useNavigate, useMatches} from 'react-router-dom';
import {Text} from 'shared/ui/Text';
import {Avatar} from 'shared/ui/Avatar';
import User from 'shared/icons/User';
import LockOpen from 'shared/icons/LockOpen';
import {Phone} from 'shared/icons/Phone';
import {Pencil} from 'shared/icons/Pencil';
import Logout from 'shared/icons/Logout';
import {ProfileContainer} from './ui/ProfileContainer';
import {Head} from 'shared/ui/Head';
import {Menu} from 'shared/ui/Menu';
import {MenuAction} from 'shared/ui/MenuAction';
import {gql} from 'shared/__generated__';
import {useMutation, useQuery} from '@apollo/client';
import {Setting} from 'shared/icons/Setting';
import {Trash} from 'shared/icons/Trash';
import styled from 'styled-components';
import {device} from 'shared/device';
import {Flex} from 'shared/ui/Flex';
import {Popup} from 'shared/ui/Popup';
import {Button} from 'shared/ui/Button';
import {useColors} from 'shared/lib/hooks';
import {logoutThunk, useAppDispatch, useAppSelector} from 'shared/store';
import {Tooltip} from 'shared/ui/Tooltip';
import Info from 'shared/icons/Info';
import {showAlert} from 'shared/ui/Alert';
import {ensureError} from 'shared/lib/utils';
import {NavigateBack} from 'shared/ui/NavigateBack';
import {CloseButton} from 'shared/ui/CloseButton';
import {Handshake} from 'shared/icons/Handshake';

const ME = gql(`
  query GetMyAvatar {
    me {
      id
      name
      surname
      phone
      has_password
      avatar {
        id
        type
        url
      }
    }
  }
`);
const DELETE_USER = gql(`
mutation DeleteUser($deleteUserId: String!) {
  deleteUser(id: $deleteUserId) {
    id
  }
}`);

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 72px;
  grid-column-start: 1;
  grid-column-end: 4;
  @media (${device.mobile}) {
    margin-top: 56px;
    flex-direction: row;
    justify-content: space-between;
  }
`;

export function ProfileRoot() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const colors = useColors();
  const userId = useAppSelector(state => state.auth.userId);
  const companyId = useAppSelector(state => state.company.data?.id);
  const matches = useMatches();
  const {data} = useQuery(ME);
  const user = data?.me;
  const [deleteUser, {loading}] = useMutation(DELETE_USER, {
    onCompleted: () => {
      localStorage.clear();
    },
  });
  const accauntDelete = async () => {
    try {
      await deleteUser({
        variables: {
          deleteUserId: userId!,
        },
      });
      setDeleteModal(false);
      dispatch(logoutThunk()).then(() => {
        window.location.reload();
      });

      showAlert('success', 'Аккаунт успешно удален');
    } catch (e) {
      setDeleteModal(false);
      const error = ensureError(e);
      showAlert('error', error.message);
    }
  };

  const match = matches[matches.length - 1];
  const handle = match.handle as {title?: string};
  const [deleteModal, setDeleteModal] = useState(false);
  const [quitModal, setQuitModal] = useState(false);
  return (
    <ProfileContainer>
      <Nav>
        <Menu
          data={[
            {
              icon: <User />,
              label: 'Профиль',
              to: '/profile',
            },
            {
              icon: <Pencil />,
              label: 'Редактировать профиль',
              to: '/profile-set',
            },
            {
              icon: <LockOpen />,
              label: user?.has_password
                ? 'Изменить пароль'
                : 'Установить пароль',
              to: user?.has_password ? '/password-change' : '/password-set',
              tooltip: () =>
                user?.has_password ? (
                  <></>
                ) : (
                  <Tooltip
                    width={320}
                    typography="footNote12Regular"
                    position="right"
                    style={{marginTop: 4, borderRadius: 8, padding: '8px 12px'}}
                    text={
                      'Пароль пригодится, если вы утратите доступ к вашему текущему номеру телефона или в случае, когда вы решите сменить его на новый.'
                    }>
                    <Info />
                  </Tooltip>
                ),
            },
            {
              icon: <Phone />,
              label: 'Изменить номер',
              disabled: !user?.has_password,
              alertMessage: 'Установите пароль',
              tooltip: () =>
                user?.has_password ? (
                  <></>
                ) : (
                  <Tooltip
                    style={{borderRadius: 8, padding: '8px 12px'}}
                    typography="footNote12Regular"
                    position="right"
                    text={'Чтобы изменить номер телефона вам нужен пароль.'}>
                    <Info />
                  </Tooltip>
                ),
              to: '/phone-change',
            },
            {
              icon: <Handshake />,
              label: 'Реферальная программа',
              to: '/referral',
            },
            {
              icon: <Setting />,
              label: 'Настройка уведомлений',
              to: '/push-notification-setting',
            },
          ].filter(item =>
            item.label === 'Реферальная программа' ? !!companyId : true,
          )}
          avatar={<Avatar size={64} url={user?.avatar?.url} />}
          title={`${user?.name} ${user?.surname}`}
        />
        <MenuAction
          data={[
            {
              icon: <Trash />,
              label: 'Удалить аккаунт',
              onClick: () => {
                setDeleteModal(true);
              },
            },
            {
              icon: <Logout />,
              label: 'Выйти',
              onClick: () => {
                setQuitModal(true);
              },
            },
          ]}
        />
      </Nav>
      <Flex
        direction="column"
        style={{
          gridColumnStart: 4,
          gridColumnEnd: 10,
        }}>
        <Head>
          <NavigateBack />
          <Text
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            typography="title24">
            {handle.title}
          </Text>
        </Head>
        <Outlet />
      </Flex>

      <Popup
        visible={deleteModal}
        style={{width: 343, alignItems: 'initial', padding: '24px'}}
        onClose={() => {
          setDeleteModal(false);
        }}>
        <Flex direction="column" gap={16}>
          <Flex flex={1} justifyContent="space-between" alignItems="center">
            <Text typography="title20" color="textPrimary">
              Удалить аккаунт
            </Text>
            <CloseButton
              onClose={() => {
                setDeleteModal(false);
              }}
            />
          </Flex>
          <Text typography="subHead14Regular">
            Вы уверены, что хотите удалить аккаунт?
          </Text>
          <Flex gap={8}>
            <Button
              size="medium"
              variant="outline"
              style={{flex: 1, borderColor: colors.mainPrimary}}
              onClick={() => {
                setDeleteModal(false);
              }}>
              Отмена
            </Button>
            <Button
              size="medium"
              variant="outline"
              loading={loading}
              onClick={accauntDelete}
              style={{
                flex: 1,
                borderColor: colors.dangerPrimary,
                color: colors.dangerPrimary,
              }}>
              Удалить
            </Button>
          </Flex>
        </Flex>
      </Popup>
      <Popup
        visible={quitModal}
        style={{width: 343, alignItems: 'initial', padding: '24px'}}
        onClose={() => {
          setQuitModal(false);
        }}>
        <Flex direction="column" gap={16}>
          <Flex flex={1} justifyContent="space-between" alignItems="center">
            <Text typography="title20" color="textPrimary">
              Выйти
            </Text>
            <CloseButton
              onClose={() => {
                setQuitModal(false);
              }}
            />
          </Flex>
          <Text typography="subHead14Regular">
            Вы уверены, что хотите выйти?
          </Text>
          <Flex gap={8}>
            <Button
              size="medium"
              variant="outline"
              style={{flex: 1, borderColor: colors.mainPrimary}}
              onClick={() => {
                setQuitModal(false);
              }}>
              Отмена
            </Button>
            <Button
              size="medium"
              variant="outline"
              onClick={() => {
                dispatch(logoutThunk()).then(() => {
                  navigate('/');
                });
              }}
              style={{
                flex: 1,
                borderColor: colors.dangerPrimary,
                color: colors.dangerPrimary,
              }}>
              Выйти
            </Button>
          </Flex>
        </Flex>
      </Popup>
    </ProfileContainer>
  );
}
