import React, {useState} from 'react';
import {useMutation, useQuery} from '@apollo/client';
import {BOOKING_IMAGE_DELETE, BOOKING_PHOTOS} from 'entities/booking';
import {useParams} from 'react-router-dom';
import {Content} from 'shared/ui/Content';
import {Divider} from 'shared/ui/Divider';
import {Skeleton} from 'shared/ui/Skeleton';
import {Text} from 'shared/ui/Text';
import {Flex} from 'shared/ui/Flex';
import {DragDrop} from 'shared/ui/DragDrop';
import {Image} from 'shared/ui/Image';
import styled from 'styled-components';
import {useAppSelector} from 'shared/store';
import {ImageType} from 'shared/__generated__/graphql';
import {ImageView} from 'shared/ui/ImageView';
import {Seo} from 'shared/ui/Seo';
import {useEmployeePermission} from 'shared/lib/hooks';

const ImageArea = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 16px 8px;
`;

export const PhotosBooking = () => {
  const params = useParams();
  const {id} = params;
  const companyId = useAppSelector(state => state.company.data?.id);
  const {data, loading} = useQuery(BOOKING_PHOTOS, {
    variables: {
      bookingId: id!,
      companyId: companyId!,
    },
  });
  const {edit_bookings_permission} = useEmployeePermission(data);
  const photos = data?.booking?.workPhotos ?? [];
  const [updatePhotos] = useMutation(BOOKING_IMAGE_DELETE, {
    refetchQueries: ['BookingPhotos'],
  });
  const [imageVisible, setImageVisible] = useState(false);
  const [indexPhoto, setIndexPhoto] = useState(0);

  return (
    <Content gap="24px">
      <Seo title="Фото работ" />
      {edit_bookings_permission && (
        <DragDrop
          onChange={value => {
            updatePhotos({
              variables: {
                input: {
                  booking_id: id!,
                  company_id: companyId!,
                  work_photos: {
                    create: value?.map(item => ({
                      type: ImageType.Image,
                      ...item,
                    })),
                  },
                },
              },
            });
          }}
        />
      )}
      {loading ? (
        <SkeletonPhoto />
      ) : photos.length ? (
        <ImageArea style={{marginTop: edit_bookings_permission ? 8 : 0}}>
          {photos.map((photo, photoIndex) => (
            <Image
              width={'100%'}
              height={'100%'}
              style={{aspectRatio: '1 / 1'}}
              key={'booking_photo_' + photo.image.id}
              src={photo.image.url}
              onClick={() => {
                setIndexPhoto(photoIndex);
                setImageVisible(true);
              }}
              onDelete={
                edit_bookings_permission
                  ? () => {
                      updatePhotos({
                        variables: {
                          input: {
                            booking_id: id!,
                            company_id: companyId!,
                            work_photos: {
                              delete: [photo.image.id],
                            },
                          },
                        },
                      });
                    }
                  : undefined
              }
            />
          ))}
        </ImageArea>
      ) : (
        <></>
      )}
      <Flex direction="column" gap={16}>
        <Text typography="text16Regular" color="textTertiary">
          Доступные форматы: jpeg, jpg, png
        </Text>
        <Text typography="text16Regular" color="textTertiary">
          Размер файла: до 12 Мб
        </Text>
      </Flex>
      <Divider mt={8} />
      <Text
        typography="text16Regular"
        color="textTertiary"
        style={{textAlign: 'center'}}>
        Фото будут отображаться в портфолио организации и сотрудника
      </Text>
      <ImageView
        visible={imageVisible}
        onClose={() => setImageVisible(false)}
        images={photos.map(photo => photo.image.url)}
        index={indexPhoto}
      />
    </Content>
  );
};

const SkeletonPhoto = () => {
  return (
    <>
      <DragDrop />
      <Skeleton height={88 * 2 + 16}>
        {Array(7)
          .fill(0)
          .map((_, index) => (
            <rect
              key={'photos_' + index}
              width={88}
              height={88}
              rx={18}
              ry={18}
              x={(88 + 8) * index}
            />
          ))}
        {Array(5)
          .fill(0)
          .map((_, index) => (
            <rect
              key={'photos_2_' + index}
              width={88}
              height={88}
              rx={18}
              ry={18}
              x={(88 + 8) * index}
              y={88 + 8}
            />
          ))}
      </Skeleton>
    </>
  );
};
