import React, {CSSProperties, PropsWithChildren} from 'react';
import ContentLoader from 'react-content-loader';
import {useColors} from 'shared/lib/hooks';

interface ISkeletonProps extends PropsWithChildren {
  width?: number | string;
  height?: number | string;
  backgroundColor?: string;
  foregroundColor?: string;
  speed?: number;
  style?: CSSProperties;
}

export const Skeleton: React.FC<ISkeletonProps> = props => {
  const {
    backgroundColor,
    foregroundColor,
    speed,
    width,
    height,
    children,
    style,
    ...rest
  } = props;
  const colors = useColors();

  return (
    <ContentLoader
      {...rest}
      style={style}
      height={height || '100%'}
      width={width || '100%'}
      speed={speed || 4}
      backgroundColor={backgroundColor || colors.skeletonBackground}
      foregroundColor={foregroundColor || colors.skeletonForeground}>
      {children}
    </ContentLoader>
  );
};
