import React, {useState} from 'react';
import {Content} from 'shared/ui/Content';
import {useNavigate} from 'react-router-dom';
import {Text} from 'shared/ui/Text';
import {TextInput} from 'shared/ui/TextInput';
import {Select} from 'shared/ui/Select';
import styled from 'styled-components';
import {Circle} from 'shared/icons/Circle';
import {useMutation, useQuery} from '@apollo/client';
import {ensureError, getMinutStartBooking} from 'shared/lib/utils';
import {
  ImageType,
  ImageUpload,
  QueryCompanyServiceSearchOrderByColumn,
  ServiceType,
  SortOrder,
} from 'shared/__generated__/graphql';
import {Button} from 'shared/ui/Button';
import {useAppSelector} from 'shared/store';
import {useColors, useDebounce} from 'shared/lib/hooks';
import {Dropdown} from 'shared/ui/Dropdown';
import {Layout} from 'shared/ui/Layout';
import {Flex} from 'shared/ui/Flex';
import {MultiSelectChips} from 'shared/ui/MultiSelectChips';
import {showAlert} from 'shared/ui/Alert';
import {TUTORIALCHECK} from 'entities/tutorial';
import {NavigateBack} from 'shared/ui/NavigateBack';
import {TextArea} from 'shared/ui/TextArea';
import {SERVICES_SELECT_SERVICES, SelectEmployees} from 'entities/loyalty';
import {Seo} from 'shared/ui/Seo';
import {Search} from 'shared/icons/Search';
import {DragDrop} from 'shared/ui/DragDrop';
import {Image} from 'shared/ui/Image';
import {ImageView} from 'shared/ui/ImageView';
import {
  CREATE_SERVICES,
  RESPONSE_SERVICE_TEMPLATE,
  SERVICES,
  SERVICES_TEMPLATES,
} from 'entities/services';
import {TableViewRow} from 'shared/ui/TableViewRow';
import {EmptyList2} from 'shared/illustration/EmptyList2';
import {EmptyBox} from 'shared/illustration/EmptyBox';
interface ITypePrice {
  name: string;
  value: ServiceType;
}
const typeValue: ITypePrice[] = [
  {name: 'Бесплатная услуга', value: ServiceType.Free},
  {name: 'Фиксированный', value: ServiceType.Fixed},
  {name: 'Нефиксированный', value: ServiceType.NotFixed},
  {name: 'Диапазон', value: ServiceType.Range},
];

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  grid-column-start: 4;
  grid-column-end: 10;
  max-width: 720px;
  padding-top: 64px;
  padding-bottom: 24px;
  gap: 32px;
`;
const ImageArea = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 88px);
  gap: 8px;
`;

export function AddService() {
  const navigate = useNavigate();
  const colors = useColors();
  const username = useAppSelector(state => state.company.data?.username);
  const companyId = useAppSelector(state => state.company.data!.id);
  const arrMinutService = getMinutStartBooking(18);
  const [template, setTemplate] = useState<string>();
  const [selectedEmployees, setSelectedEmployees] = useState<
    {label: string; value: string}[]
  >([]);
  const [selectedResource, setSelectedResource] = useState<
    {label: string; value: string}[]
  >([]);
  const {data} = useQuery(RESPONSE_SERVICE_TEMPLATE, {
    variables: {
      id: template ?? null,
      first: 1,
      companyId: companyId,
    },
    onCompleted: resData => {
      const serviceTemplate = resData.serviceTemplates?.data[0];
      setName(serviceTemplate!.name ?? '');
      setCategoryId(
        resData.categories.find(
          item => item.id === serviceTemplate?.category.id,
        )?.id ?? '',
      );
      setSubcategoryId(
        resData.categories
          .find(t => t.id === serviceTemplate?.category.id)
          ?.subcategories.find(
            item => item.id === serviceTemplate?.subcategory?.id,
          )?.id,
      );
      setType(typeValue.find(item => item.value === 'fixed')?.value);
      setDuration(
        arrMinutService
          .find(item => item.value === serviceTemplate?.duration)
          ?.value.toString(),
      );
    },
  });
  const {data: templateData, refetch} = useQuery(SERVICES_TEMPLATES, {
    variables: {first: 100, page: 1, name: ''},
    notifyOnNetworkStatusChange: true,
  });

  const [search, setSearch] = React.useState('');
  const [visibleTemplates, setVisibleTemplates] = useState(false);

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [categoryId, setCategoryId] = useState<string>();
  const [subcategoryId, setSubcategoryId] = useState<string>();
  const [type, setType] = useState<string | undefined>(
    typeValue.find(item => item.value === 'fixed')?.value.toString(),
  );
  const [minPrice, setMinPrice] = useState('0');
  const [price, setPrice] = useState('0');
  const [maxPrice, setMaxPrice] = useState('0');
  const [duration, setDuration] = useState<string | undefined>(
    arrMinutService.find(item => item.value === 0)?.value.toString(),
  );
  const [breakTime, setBreakTime] = useState<string | undefined>(
    arrMinutService.find(item => item.value === 0)?.value.toString(),
  );
  const [photos, setPhotos] = useState<Omit<ImageUpload, 'type'>[]>([]);
  const [imageVisible, setImageVisible] = useState(false);
  const [indexPhoto, setIndexPhoto] = useState(0);
  const resourcesList = data?.company?.resources ?? [];
  const [errorName, setErrorName] = useState<string | null>(null);
  const [errorCategory, setErrorCategory] = useState<string | null>(null);
  const [errorSubcategory, setErrorSubcategory] = useState<string | null>(null);
  const [errorPrice, setErrorPrice] = useState<string | null>(null);
  const [errorMinPrice, setErrorMinPrice] = useState<string | null>(null);
  const [errorMaxPrice, setErrorMaxPrice] = useState<string | null>(null);
  const [errorDuration, setErrorDuration] = useState<string | null>(null);
  const subCategories = data?.categories.find(
    subCat => subCat.id === categoryId,
  )?.subcategories;

  const [updateServices, {loading: updateLoad}] = useMutation(CREATE_SERVICES, {
    refetchQueries: [
      {
        query: SERVICES,
        variables: {
          companyId,
          first: 10,
          page: 1,
          query: '',
          categoryId: undefined,
          subcategoryId: undefined,
          orderBy: [
            {
              order: SortOrder.Asc,
              column: QueryCompanyServiceSearchOrderByColumn.Order,
            },
          ],
        },
      },
      {
        query: TUTORIALCHECK,
        variables: {
          companyId,
        },
      },
      {
        query: SERVICES_SELECT_SERVICES,
        variables: {
          companyId,
          query: '',
          page: 1,
          first: 100,
        },
      },
    ],
    awaitRefetchQueries: true,
  });

  const nameRefetch = useDebounce((name: string) => {
    const input = {
      first: 20,
      name,
      page: 1,
    };
    refetch(input);
  }, 400);

  const update = async () => {
    let isValid = true;
    if (!name) {
      setErrorName('Обязательное поле');
      isValid = false;
    }
    if (!categoryId) {
      setErrorCategory('Выберите категорию');
      isValid = false;
    }
    if (!subcategoryId) {
      setErrorSubcategory('Выберите подкатегорию');
      isValid = false;
    }
    if (type === 'fixed' && (!price || +price === 0)) {
      setErrorPrice('Укажите цену');
      isValid = false;
    }
    if (type === 'not_fixed' && (!minPrice || +minPrice === 0)) {
      setErrorMinPrice('Укажите цену');
      isValid = false;
    }
    if (
      type === 'range' &&
      (!minPrice ||
        +minPrice === 0 ||
        +minPrice === +maxPrice ||
        !maxPrice ||
        +maxPrice === 0 ||
        +minPrice > +maxPrice)
    ) {
      if (+minPrice > +maxPrice) {
        setErrorMinPrice('"Цена от" не может быть больше от "Цена до"');
      }
      if (!maxPrice || +maxPrice === 0) {
        setErrorMaxPrice('Укажите цену');
      }
      if (!minPrice || +minPrice === 0) {
        setErrorMinPrice('Укажите цену');
      } else {
        setErrorMinPrice('"Цена от" не может быть равна от "Цена до"');
      }
      isValid = false;
    }
    if (isValid) {
      try {
        await updateServices({
          variables: {
            input: {
              company_id: companyId,
              name: name,
              duration: Number(duration),
              description: description,
              breakout_time: +breakTime!,
              min_price: +minPrice!,
              price: +price!,
              max_price: +maxPrice!,
              type: typeValue.find(item => item.value === type)!.value,
              category_id: categoryId!,
              subcategory_id: subcategoryId,
              employees: selectedEmployees.map(item => ({id: item.value})),
              images: photos?.map(item => ({
                ...item,
                type: ImageType.Image,
              })),
              resources_id: selectedResource.map(item => item.value),
            },
          },
        });
        navigate(`/${username}/services`);
        showAlert('success', 'Услуга успешно создана');
      } catch (e) {
        const error = ensureError(e);
        showAlert('error', error.message);
      }
    }
  };
  return (
    <Layout columns={12}>
      <Seo title="Добавить услугу" />
      <Wrapper>
        <Flex alignItems="center" gap={16}>
          <NavigateBack />
          <Text typography="title24">Добавить услугу</Text>
        </Flex>
        <Flex direction="column" gap={24}>
          <Content>
            <Text typography="title18">Название услуги</Text>
            <TextInput
              label="Введите название услуги"
              value={name}
              required
              error={errorName}
              onChange={text => {
                setName(text);
                setErrorName(null);
              }}
            />
            <TextArea
              size="medium"
              label="Описание"
              maxLength={1000}
              value={description}
              onChange={text => {
                setDescription(text);
              }}
            />
            <Flex style={{position: 'relative'}}>
              <Button
                style={{padding: 0}}
                variant="subtitle"
                leftIcon={<Circle />}
                onClick={() => {
                  setVisibleTemplates(true);
                }}>
                <Text typography="subHead14Medium" color="mainPrimary">
                  Выбрать готовый шаблон
                </Text>
              </Button>
              <Dropdown
                style={{marginTop: 56, maxHeight: 500, paddingBottom: 24}}
                width="672px"
                visible={visibleTemplates}
                onClose={() => {
                  setVisibleTemplates(false);
                }}
                header={
                  <TextInput
                    wrapperStyle={{margin: '0 16px'}}
                    value={search}
                    leftElement={<Search color={colors.textTertiary} />}
                    variant="default"
                    placeholder="Поиск услуг"
                    onChange={text => {
                      setSearch(text);
                      nameRefetch(text);
                    }}
                  />
                }
                listEmpty={
                  <Flex direction="column">
                    <EmptyList2 size={300} />
                    <Text
                      typography="text18"
                      color="textTertiary"
                      align="center"
                      style={{alignSelf: 'center', maxWidth: 500}}>
                      По данному запросу ничего не найдено. Проверьте данные и
                      попробуйте еще раз
                    </Text>
                  </Flex>
                }
                data={templateData?.serviceTemplates?.data}
                renderItem={item => (
                  <TableViewRow
                    pointer
                    hover
                    style={{padding: '0 16px', width: '100%'}}
                    title={item.name}
                    subtitle={`${item.category.title}${
                      item.subcategory?.title
                        ? ` / ${item.subcategory.title}`
                        : ''
                    }`}
                    onClick={() => {
                      setTemplate(item.id);
                      setErrorName(null);
                      setErrorCategory(null);
                      setErrorSubcategory(null);
                      setErrorPrice(null);
                      setErrorMinPrice(null);
                      setErrorMaxPrice(null);
                      setErrorDuration(null);
                      setVisibleTemplates(false);
                    }}
                  />
                )}
              />
            </Flex>
          </Content>
          <Content>
            <Text typography="title18">Категория</Text>
            <Select
              label="Категория услуг"
              error={errorCategory}
              required
              data={data?.categories.map(categories => ({
                value: categories.id,
                label: categories.title,
              }))}
              value={categoryId}
              onChange={selected => {
                setCategoryId(selected);
                setSubcategoryId(undefined);
                setErrorCategory(null);
              }}
            />
            {categoryId && (
              <Select
                label="Подкатегория услуг"
                error={errorSubcategory}
                required
                data={subCategories?.map(sub => ({
                  label: sub.title,
                  value: sub.id,
                }))}
                value={subcategoryId}
                onChange={selected => {
                  setSubcategoryId(selected);
                  setErrorSubcategory(null);
                }}
              />
            )}
          </Content>
          <Content>
            <Text typography="title18">О услуге</Text>
            <Select
              label="Тип цен"
              required
              data={typeValue.map(typePrice => ({
                value: typePrice.value,
                label: typePrice.name,
              }))}
              value={type}
              onChange={selected => {
                setType(selected);
                setErrorPrice(null);
                setErrorMaxPrice(null);
                setErrorMinPrice(null);
              }}
            />
            {type === 'free' || !type ? null : (
              <Flex gap={8}>
                {type === 'fixed' ? (
                  <TextInput
                    error={errorPrice}
                    flex={1}
                    required
                    mask="999999"
                    label="Цена от"
                    value={price}
                    onChange={text => {
                      setPrice(text);
                      setErrorPrice(null);
                    }}
                  />
                ) : (
                  <TextInput
                    flex={1}
                    required
                    error={errorMinPrice}
                    mask="999999"
                    label="Цена от"
                    value={minPrice}
                    onChange={text => {
                      setMinPrice(text);
                      setErrorMaxPrice(null);
                      setErrorMinPrice(null);
                    }}
                  />
                )}

                <TextInput
                  disabled={type === 'fixed' || type === 'not_fixed'}
                  flex={1}
                  required={type === 'range' ? true : false}
                  error={errorMaxPrice}
                  mask="999999"
                  label="Цена до"
                  value={maxPrice}
                  onChange={text => {
                    setMaxPrice(text);
                    setErrorMaxPrice(null);
                    setErrorMinPrice(null);
                  }}
                />
              </Flex>
            )}
            <Select
              error={errorDuration}
              label="Длительность"
              required
              data={arrMinutService.map(item => ({
                value: item.value.toString(),
                label: item.name,
              }))}
              value={duration}
              onChange={selected => {
                setDuration(selected);
                setErrorDuration(null);
              }}
            />
            <Select
              label="Перерыв после записи"
              data={arrMinutService.map(item => ({
                value: item.value.toString(),
                label: item.name,
              }))}
              value={breakTime}
              onChange={selected => {
                setBreakTime(selected);
              }}
            />
            <MultiSelectChips
              label="Привязать сотрудника к услуге"
              values={selectedEmployees}
              onDelete={id =>
                setSelectedEmployees(
                  selectedEmployees.filter(
                    selectItem => selectItem.value !== id,
                  ),
                )
              }
              modal={(visible, setVisible) => (
                <SelectEmployees
                  label="Сотрудники"
                  visible={visible}
                  values={selectedEmployees}
                  onChange={values => {
                    setSelectedEmployees(values);
                    setVisible(false);
                  }}
                  onClose={() => setVisible(false)}
                />
              )}
            />
          </Content>
          <Content>
            <Text typography="title18">Ресурсы</Text>
            <MultiSelectChips
              label="Выберите ресурсы"
              addItem={
                <TableViewRow
                  hover
                  pointer
                  title="+ Создать новый ресурс"
                  style={{width: '100%', padding: '0 16px', marginBottom: 8}}
                  styleTitle={{color: colors.mainPrimary}}
                  onClick={() => {
                    navigate(`/${username}/resources`);
                  }}
                />
              }
              listEmpty={
                <Flex gap={16} direction="column">
                  <EmptyBox size={200} mt={0} />
                  <Text
                    align="center"
                    typography="text16Regular"
                    color="textTertiary">
                    Нажмите на кнопку “Создать новый ресурс” и начните работу
                  </Text>
                </Flex>
              }
              onDelete={id =>
                setSelectedResource(
                  selectedResource.filter(
                    selectItem => selectItem.value !== id,
                  ),
                )
              }
              onChange={setSelectedResource}
              data={
                resourcesList.map(item => ({
                  value: item.id,
                  label: item.name,
                })) ?? []
              }
              values={selectedResource}
            />
          </Content>
          <Content>
            <ImageView
              visible={imageVisible}
              onClose={() => setImageVisible(false)}
              images={photos?.map(photo => photo.url!) ?? []}
              index={indexPhoto}
            />
            <Text typography="title18">Фотографии услуги</Text>
            <Flex gap={24} direction="column">
              <DragDrop
                onChange={newPhotos => {
                  setPhotos(prevPhotos => {
                    if (newPhotos) {
                      return [...(prevPhotos ?? []), ...newPhotos];
                    } else {
                      return prevPhotos ?? [];
                    }
                  });
                }}
              />
              {photos.length > 0 && (
                <ImageArea>
                  {photos?.map((photo, index) => (
                    <Image
                      key={'booking_photo_' + index}
                      src={photo.url ?? undefined}
                      onClick={() => {
                        setIndexPhoto(index);
                        setImageVisible(true);
                      }}
                      onDelete={() => {
                        setPhotos(
                          currentPhotos =>
                            currentPhotos?.filter(
                              (_, filterIndex) => filterIndex !== index,
                            ),
                        );
                      }}
                    />
                  ))}
                </ImageArea>
              )}
              <Flex direction="column" gap={8}>
                <Text typography="subHead14Regular" color="textTertiary">
                  Доступные форматы: jpeg, jpg, png
                </Text>
                <Text typography="subHead14Regular" color="textTertiary">
                  Размер файла: до 12 Мб
                </Text>
              </Flex>
            </Flex>
          </Content>
        </Flex>

        <Button size="large" loading={updateLoad} onClick={update}>
          Добавить
        </Button>
      </Wrapper>
    </Layout>
  );
}
