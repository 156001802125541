import React, {useState} from 'react';
import {styled} from 'styled-components';
import {OptionsType, Table, TableSortType} from 'shared/ui/Table';
import {gql} from 'shared/__generated__';
import {useAppSelector} from 'shared/store';
import {useQuery} from '@apollo/client';
import {
  QueryGetStatisticsSalesJournalOrderByColumn,
  SortOrder,
  StatisticsDates,
} from 'shared/__generated__/graphql';
import {Flex} from 'shared/ui/Flex';
import {
  FilterEmptyState,
  FilterResetButton,
  FilterSearch,
  FilterStatisticsEmployee,
  FilterStatisticsPeriod,
} from 'entities/statistics';
import dayjs from 'dayjs';
import {getNumberFormat, getPriceFormat} from 'shared/lib/utils';
import {Content} from 'shared/ui/Content';
import {PageHeader} from 'shared/ui/PageHeader';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
`;

const GET_STATISTICS_SALES_JOURNAL = gql(`
  query GetStatisticsSalesJournal(
    $companyId: String!
    $date: StatisticsDates!
    $first: Int!
    $page: Int
    $employeeId: String
    $orderBy: [QueryGetStatisticsSalesJournalOrderByOrderByClause!]
  ) {
    getStatisticsSalesJournal(
      company_id: $companyId
      date: $date
      first: $first
      page: $page
      employee_id: $employeeId
      orderBy: $orderBy
    ) {
      paginatorInfo {
        hasMorePages
        currentPage
        total
        count
        lastPage
      }
      data {
        bonus_used
        booking_number
        certificate_used
        discount
        income
        quantity
        sale_date
        service_name
      }
    }
  }
`);
const titles = [
  'Номер записи',
  'Дата продажи',
  'Услуги',
  'Количество',
  'Заработок',
  'Скидки по услугам',
  'Исп. бонусов',
  'Исп. сертификаты',
];
const sortValues: QueryGetStatisticsSalesJournalOrderByColumn[] = [
  QueryGetStatisticsSalesJournalOrderByColumn.BookingNumber,
  QueryGetStatisticsSalesJournalOrderByColumn.SaleDate,
  QueryGetStatisticsSalesJournalOrderByColumn.ServiceName,
  QueryGetStatisticsSalesJournalOrderByColumn.Quantity,
  QueryGetStatisticsSalesJournalOrderByColumn.Income,
  QueryGetStatisticsSalesJournalOrderByColumn.Discount,
  QueryGetStatisticsSalesJournalOrderByColumn.BonusUsed,
  QueryGetStatisticsSalesJournalOrderByColumn.CertificateUsed,
];

export const SalesOnJournal = () => {
  const companyId = useAppSelector(state => state.company.data!.id);
  const [sort, setSort] = useState<TableSortType>({
    index: 0,
    order: SortOrder.Desc,
  });
  const [search, setSearch] = useState('');
  const [employeeId, setEmployeeId] = useState('');
  const [options, setOptions] = useState<OptionsType>({
    page: 1,
    first: 10,
    total: 0,
    count: 0,
    lastPage: 1,
  });
  const [period, setPeriod] = useState<StatisticsDates>(
    StatisticsDates.Alltime,
  );
  const {data, loading} = useQuery(GET_STATISTICS_SALES_JOURNAL, {
    variables: {
      companyId,
      employeeId,
      date: period,
      first: options.first,
      page: options.page,
      orderBy: [
        {
          order: sort.order,
          column: sortValues[sort.index],
        },
      ],
    },
    onCompleted: res =>
      setOptions(prev => ({
        ...prev,
        total: res.getStatisticsSalesJournal?.paginatorInfo.total,
        count: res.getStatisticsSalesJournal?.paginatorInfo.count,
        lastPage: res.getStatisticsSalesJournal?.paginatorInfo.lastPage,
      })),
  });
  const statistics = data?.getStatisticsSalesJournal?.data ?? [];
  const isEmpty = !statistics?.filter(item => item.sale_date !== 'Итого')
    .length;
  return (
    <Wrapper>
      <PageHeader back title="Журнал продаж" />
      <Flex direction="row" gap={8}>
        <FilterSearch onChange={setSearch} value={search} />
        <FilterStatisticsPeriod {...{period, setPeriod}} />
        <FilterStatisticsEmployee {...{employeeId, setEmployeeId}} />
        {employeeId || search || period !== StatisticsDates.Alltime ? (
          <FilterResetButton
            onClick={() => {
              setEmployeeId('');
              setSearch('');
              setPeriod(StatisticsDates.Alltime);
            }}
          />
        ) : (
          <></>
        )}
      </Flex>
      {!loading && isEmpty ? (
        <FilterEmptyState />
      ) : (
        <Content>
          <Table
            options={options}
            setOptions={setOptions}
            titles={titles}
            sort={sort}
            results
            columnNoWrap={[2, 4, 5, 6, 7]}
            alignRightColumn={[4, 5, 6, 7]}
            onClickTitle={(index, order) => setSort({index, order})}
            rows={statistics.map((item, index) => [
              index === 0 ? 'Итого' : item.booking_number,
              dayjs(item.sale_date).isValid()
                ? dayjs(item.sale_date).format('DD.MM.YYYY HH:mm')
                : '-',
              item.service_name,
              getNumberFormat(item.quantity),
              getPriceFormat(item.income),
              getPriceFormat(item.discount),
              getPriceFormat(item.bonus_used),
              getPriceFormat(item.certificate_used),
            ])}
          />
        </Content>
      )}
    </Wrapper>
  );
};
