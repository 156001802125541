import React from 'react';
import {Content} from 'shared/ui/Content';
import {Flex} from 'shared/ui/Flex';
import {Skeleton} from 'shared/ui/Skeleton';

export const ModerationSkeleton = () => {
  return (
    <Flex gap={16} direction="column" flex={1}>
      <Content>
        <Skeleton height={156}>
          <rect x={0} y={0} width={78} height={24} rx={12} />
          <rect x={0} y={40} width={340} height={14} rx={8} />
          <rect x={0} y={64} width={210} height={14} rx={8} />
          <rect x={0} y={116} width={78} height={12} rx={6} />
          <rect x={624} y={116} width={46} height={12} rx={6} />
          <rect x={0} y={144} width={78} height={12} rx={6} />
          <rect x={632} y={144} width={38} height={12} rx={6} />
        </Skeleton>
      </Content>
      <Content>
        <Skeleton height={156}>
          <rect x={0} y={0} width={78} height={24} rx={12} />
          <rect x={0} y={40} width={340} height={14} rx={8} />
          <rect x={0} y={66} width={210} height={14} rx={8} />
          <rect x={0} y={116} width={78} height={12} rx={6} />
          <rect x={624} y={116} width={46} height={12} rx={6} />
          <rect x={0} y={144} width={78} height={12} rx={6} />
          <rect x={632} y={144} width={38} height={12} rx={6} />
        </Skeleton>
      </Content>
      <Content>
        <Skeleton height={156}>
          <rect x={0} y={0} width={78} height={24} rx={12} />
          <rect x={0} y={40} width={340} height={14} rx={8} />
          <rect x={0} y={66} width={210} height={14} rx={8} />
          <rect x={0} y={116} width={78} height={12} rx={6} />
          <rect x={624} y={116} width={46} height={12} rx={6} />
          <rect x={0} y={144} width={78} height={12} rx={6} />
          <rect x={632} y={144} width={38} height={12} rx={6} />
        </Skeleton>
      </Content>
    </Flex>
  );
};
