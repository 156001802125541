import React from 'react';
import {Content} from 'shared/ui/Content';
import {Text} from 'shared/ui/Text';
import {TextInput} from 'shared/ui/TextInput';
import {Button} from 'shared/ui/Button';
import {changePassword} from 'shared/lib/api';
import {ensureError} from 'shared/lib/utils';
import styled from 'styled-components';
import {device} from 'shared/device';
import {showAlert} from 'shared/ui/Alert';
import {Seo} from 'shared/ui/Seo';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  padding-bottom: 24px;
  flex-direction: column;
  margin-top: 8px;
  gap: 32px;
  @media (${device.mobile}) {
    gap: 8px;
    margin-bottom: 0;
    border-radius: 0;
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export function PasswordChange() {
  const [currentPassword, setCurrentPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [repPassword, setRepPassword] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);
  const passChange = async () => {
    if (newPassword === repPassword) {
      setLoading(true);
      try {
        await changePassword(currentPassword, newPassword);
        setCurrentPassword('');
        setNewPassword('');
        setRepPassword('');
        showAlert('success', 'Пароль успешно изменён');
      } catch (e) {
        const error = ensureError(e);
        showAlert('warning', error.message);
      } finally {
        setLoading(false);
      }
    } else {
      setError('Пароли не совподают');
    }
  };
  return (
    <Wrapper>
      <Seo title="Изменить пароль" />
      <Content>
        <TextInput
          label="Старый пароль"
          type="password"
          value={currentPassword}
          onChange={text => {
            setCurrentPassword(text);
          }}
        />
        <TextInput
          wrapperStyle={{gap: 0}}
          label="Новый пароль"
          type="password"
          value={newPassword}
          onChange={text => {
            setNewPassword(text);
            setError(null);
          }}
          error={error ? ' ' : null}
        />
        <TextInput
          label="Повторите пароль"
          type="password"
          value={repPassword}
          onChange={text => {
            setRepPassword(text);
            setError(null);
          }}
          error={error}
        />
        <Text typography="footNote12Medium" color="textSecondary">
          Минимальная длина пароля — 8 символов
        </Text>
      </Content>
      <Button
        size="large"
        disabled={
          newPassword.length < 8 ||
          repPassword.length < 8 ||
          currentPassword.length < 8 ||
          loading
        }
        loading={loading}
        onClick={passChange}>
        Сохранить
      </Button>
    </Wrapper>
  );
}
