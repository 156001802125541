import React, {useState} from 'react';
import styled from 'styled-components';
import {useColors, useResize} from 'shared/lib/hooks';
import {Divider} from './Divider';
import {IconButton} from './IconButton';
import DotsVertical from 'shared/icons/DotsVertical';
import {device} from 'shared/device';
import {Modal} from './Modal';
import {Skeleton} from './Skeleton';

type DataItem = {
  icon: React.JSX.Element;
  label: string;
  onClick: () => void;
};

interface Props {
  data: DataItem[];
  style?: React.CSSProperties;
  loading?: boolean;
  skeletonItemNumber?: number;
}
const Column = styled.div`
  display: flex;
  flex: 1;
  gap: 16px;
  flex-direction: column;
`;
const MenuItem = styled.button`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 56px;
  padding: 16px;
  white-space: nowrap;
  gap: 16px;
  border-width: 0;
  border-radius: 12px;
  background-color: ${props => props.theme.bgPrimary};
  color: ${props => props.theme.textPrimary};
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
  &:active {
    transform: translateY(2px);
  }
  &:disabled {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
  }
  transition: background-color 0.3s;
  &:hover {
    background-color: ${props => props.theme.bgSecondary};
  }
  @media (${device.mobile}) {
    height: auto;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    white-space: nowrap;
  }
`;
const Box = styled.div`
  padding: 24px;
  background-color: ${props => props.theme.bgPrimary};
  border-radius: 24px;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.borderPrimary};
  gap: 16px;
  display: flex;
  flex-direction: column;
`;

export const MenuAction = (props: React.PropsWithChildren<Props>) => {
  const {data, loading, skeletonItemNumber = 2} = props;
  const colors = useColors();
  const [modal, setModal] = useState(false);
  const {isMobile} = useResize();

  if (!isMobile) {
    return (
      <Box style={{marginBottom: 16}}>
        <Column>
          {loading
            ? Array.from(Array(skeletonItemNumber)).map((_, index) => {
                return (
                  <React.Fragment key={`skeleton_${index}`}>
                    <Skeleton height={56} width={'100%'}>
                      <rect height={56} width={'100%'} rx={12} />
                    </Skeleton>
                    {index !== data.length - 1 && <Divider />}
                  </React.Fragment>
                );
              })
            : data?.map(({icon, label, onClick}, index) => (
                <React.Fragment key={index}>
                  <MenuItem
                    style={{
                      color:
                        index === data.length - 1
                          ? colors.dangerPrimary
                          : colors.mainPrimary,
                    }}
                    key={label}
                    onClick={onClick}>
                    {icon}
                    {label}
                  </MenuItem>
                  {index !== data.length - 1 && <Divider />}
                </React.Fragment>
              ))}
        </Column>
      </Box>
    );
  }
  return (
    <div
      style={{
        display: 'inline-block',
        position: 'absolute',
        right: 16,
        top: 16,
      }}>
      <IconButton
        onClick={() => setModal(true)}
        style={{backgroundColor: colors.bgPrimary}}>
        <DotsVertical />
      </IconButton>
      <Modal
        style={{left: 'auto', right: 0, top: 48}}
        visible={modal}
        onClose={() => setModal(false)}>
        {data?.map(({icon, label, onClick}, index) => (
          <React.Fragment key={index}>
            <MenuItem
              style={{
                color:
                  index === data.length - 1
                    ? colors.dangerPrimary
                    : colors.mainPrimary,
              }}
              key={label}
              onClick={onClick}>
              {icon}
              {label}
            </MenuItem>
            {index !== data.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </Modal>
    </div>
  );
};
