import React from 'react';
import {Text} from 'shared/ui/Text';
import {Content} from 'shared/ui/Content';
import {Button} from 'shared/ui/Button';
import {ActionIcon} from 'shared/ui/ActionIcon';
import {useLocation, useNavigate} from 'react-router-dom';
import {NavLink} from 'shared/ui/NavLink';
import {CheckBox} from 'shared/ui/CheckBox';
import {Flex} from 'shared/ui/Flex';
import {ArrowLeft2v} from 'shared/icons/ArrowLeft2v';
import {WorkingDay} from 'shared/__generated__/graphql';
import {List} from 'shared/ui/List';
import {Popup} from 'shared/ui/Popup';
import {TableViewRow} from 'shared/ui/TableViewRow';
import {Switch} from 'shared/ui/Switch';
import {ScheduleWorkTime, transaleteWeekDay} from 'entities/schedule';
import {Circle} from 'shared/icons/Circle';
import {showAlert} from 'shared/ui/Alert';
import {CloseButton} from 'shared/ui/CloseButton';
import {Seo} from 'shared/ui/Seo';

function format(item: Omit<WorkingDay, 'id' | '__typename' | 'company'>) {
  if (item.dayoff) {
    return 'Выходной';
  }
  const shedule = item.start_time + ' - ' + item.end_time;
  const breakTime =
    item.break_start_time && item.break_end_time
      ? 'перерыв ' + item.break_start_time + ' - ' + item.break_end_time
      : 'без перерыва';
  return shedule + ', ' + breakTime;
}
const labelFormat = (day: {day: string}) => {
  const translatedDay = transaleteWeekDay(day.day);
  return translatedDay.charAt(0).toLocaleUpperCase() + translatedDay.slice(1);
};

type IWorkingDay = Omit<WorkingDay, 'company'>;

export const CreateCompanySchedule = () => {
  const navigate = useNavigate();
  const locationState = useLocation().state;
  const [week, setWeek] = React.useState<IWorkingDay[]>(locationState.week);
  const [modal, setModal] = React.useState(false);
  const [breakModal, setBreakModal] = React.useState(false);
  const [dayoffEnabled, setDayoffEnabled] = React.useState(false);
  const [start, setStart] = React.useState('');
  const [end, setEnd] = React.useState('');
  const [startBreak, setStartBreak] = React.useState<string | null>(null);
  const [endBreak, setEndBreak] = React.useState<string | null>(null);
  const [selectDaysWeek, setSelectDaysWeek] = React.useState<IWorkingDay[]>([]);
  const [selectedDay, setSelectedDay] = React.useState<IWorkingDay>();
  const label =
    selectDaysWeek.length > 0
      ? selectDaysWeek.map(day => labelFormat(day)).join(', ')
      : labelFormat({day: selectedDay ? selectedDay.day : ''});

  const changeMainTime = ({
    time,
    location,
  }: {
    time: string;
    location: 'start' | 'end';
  }) => {
    if (location === 'start') {
      setStart(time);
      return;
    }
    setEnd(time);
  };

  const changeBreakTime = ({
    time,
    location,
  }: {
    time: string;
    location: 'start' | 'end';
  }) => {
    if (location === 'start') {
      setStartBreak(time);
      return;
    }
    setEndBreak(time);
  };

  const updateSchedule = () => {
    const updatedSelectedDay = selectedDay
      ? {
          ...selectedDay,
          start_time: start,
          end_time: end,
          dayoff: dayoffEnabled,
          break_start_time: startBreak,
          break_end_time: endBreak,
        }
      : null;

    const updatedWeek = week.map(item => {
      if (updatedSelectedDay && item.day === updatedSelectedDay.day) {
        return updatedSelectedDay;
      }
      if (selectDaysWeek.some(day => day.day === item.day)) {
        return {
          ...item,
          start_time: start,
          end_time: end,
          dayoff: dayoffEnabled,
          break_start_time: startBreak,
          break_end_time: endBreak,
        };
      }
      return item;
    });
    showAlert('success', 'Сохранено');
    setSelectDaysWeek([]);
    setWeek(updatedWeek);
  };

  return (
    <Flex direction="column" gap={32}>
      <Seo title="Режим работы" />
      <Flex alignItems="center" gap={16}>
        <ActionIcon
          icon={<ArrowLeft2v />}
          onClick={() =>
            navigate('/create-company', {
              state: {
                updatedWeek: week,
                name: locationState.name,
                specialization: locationState.specializationId,
                type: locationState.typeId,
                city: locationState.cityId,
                address: locationState.address,
              },
              replace: true,
            })
          }
        />
        <Text typography="title24">Режим работы</Text>
      </Flex>
      <Content>
        <List
          gap={16}
          keyExtractor={item => item.day.toString()}
          data={week}
          renderItem={item => (
            <Flex direction="row" gap={24}>
              <CheckBox
                checked={selectDaysWeek.some(day => day.day === item.day)}
                onChange={() => {
                  if (selectDaysWeek.includes(item)) {
                    setSelectDaysWeek(
                      selectDaysWeek.filter(day => day.day !== item.day),
                    );
                  } else {
                    setSelectDaysWeek([...selectDaysWeek, item]);
                  }
                }}
              />
              <NavLink
                disabled={selectDaysWeek.length > 0}
                label={labelFormat(item)}
                description={format(item)}
                onClick={() => {
                  setStart(item.start_time);
                  setEnd(item.end_time);
                  setDayoffEnabled(item.dayoff);
                  setBreakModal(item.break_start_time === null ? false : true);
                  setStartBreak(item.break_start_time ?? null);
                  setEndBreak(item.break_end_time ?? null);
                  setSelectedDay(item);
                  setModal(true);
                }}
              />
            </Flex>
          )}
        />
        <Text typography="subHead14Regular" color="textTertiary">
          Не влияет на график работы сотрудника
        </Text>
      </Content>

      <Button
        size="large"
        disabled={selectDaysWeek.length === 0}
        onClick={() => {
          setStart(selectDaysWeek[0].start_time);
          setEnd(selectDaysWeek[0].end_time);
          setDayoffEnabled(selectDaysWeek[0].dayoff);
          setBreakModal(
            selectDaysWeek[0].break_start_time === null ? false : true,
          );
          setStartBreak(selectDaysWeek[0].break_start_time ?? null);
          setEndBreak(selectDaysWeek[0].break_end_time ?? null);
          setModal(true);
        }}>
        Изменить выбранные
      </Button>
      <Popup
        style={{width: 472, alignItems: 'initial'}}
        visible={modal}
        onClose={() => {
          setModal(false);
        }}>
        <Flex direction="column" gap={24}>
          <Flex flex={1} justifyContent="space-between" alignItems="center">
            <Text typography="title20" color="textPrimary">
              Изменения графика
            </Text>
            <CloseButton
              onClose={() => {
                setModal(false);
              }}
            />
          </Flex>
          <TableViewRow
            style={{padding: '10px 0'}}
            title="Выходной день"
            rightElement={() => (
              <Switch
                value={dayoffEnabled}
                onChange={() => {
                  setDayoffEnabled(!dayoffEnabled);
                }}
              />
            )}
          />
          {!dayoffEnabled && (
            <>
              <Flex direction="column" flex={1} gap={12}>
                <Text typography="text16Regular" color="textSecondary">
                  {label}
                </Text>
                <ScheduleWorkTime
                  value={{start: start, end: end}}
                  onChange={(time, location) =>
                    changeMainTime({time, location})
                  }
                />
              </Flex>
              {breakModal ? (
                <Flex direction="column" gap={12}>
                  <Flex flex={1} justifyContent="space-between">
                    <Text typography="text16Regular" color="textSecondary">
                      Перерыв
                    </Text>
                    <Text
                      typography="text16Regular"
                      color="mainPrimary"
                      onClick={() => {
                        setStartBreak(null);
                        setEndBreak(null);
                        setBreakModal(false);
                      }}>
                      Удалить
                    </Text>
                  </Flex>
                  <ScheduleWorkTime
                    value={{start: startBreak, end: endBreak}}
                    onChange={(time, location) => {
                      changeBreakTime({time, location});
                    }}
                  />
                </Flex>
              ) : (
                <Button
                  size="medium"
                  variant="subtitle"
                  leftIcon={<Circle />}
                  onClick={() => {
                    setStartBreak('13:00');
                    setEndBreak('14:00');
                    setBreakModal(true);
                  }}
                  style={{alignSelf: 'flex-start', padding: 0}}>
                  Добавить перерыв
                </Button>
              )}
            </>
          )}
          <Button
            size="large"
            onClick={() => {
              updateSchedule();
              setModal(false);
            }}>
            Сохранить
          </Button>
        </Flex>
      </Popup>
    </Flex>
  );
};
