import React, {useEffect, useState} from 'react';
import {Popup} from 'shared/ui/Popup';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';
import {Grow} from 'shared/ui/Grow';
import {List} from 'shared/ui/List';
import {gql} from 'shared/__generated__';
import {ApolloError, useLazyQuery, useMutation} from '@apollo/client';
import {useAppSelector} from 'shared/store';
import {Button} from 'shared/ui/Button';
import {Flex} from 'shared/ui/Flex';
import {RadioButton} from 'shared/ui/RadioButton';
import {showAlert} from 'shared/ui/Alert';
import {CloseButton} from 'shared/ui/CloseButton';
import {TextInput} from 'shared/ui/TextInput';
import {Search} from 'shared/icons/Search';
import {Skeleton} from 'shared/ui/Skeleton';
import {useNavigate} from 'react-router-dom';
import {useColors} from 'shared/lib/hooks';
import {EmptyBonusCard} from 'shared/illustration/EmptyBonusCard';

const Row = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 438px;
`;

const Bonus = styled.div`
  display: flex;
  height: 48px;
  flex-direction: row;
  align-items: center;
  width: 438px;
`;

const BONUS_CARDS = gql(`
query AddBonusCustomer(
  $first: Int!
  $companyId: String!
  $page: Int!
  $name: String
) {
  bonusCardTemplates(
    first: $first
    company_id: $companyId
    page: $page
    name: $name
  ) {
    paginatorInfo {
      hasMorePages
      lastPage
      currentPage
      total
    }
    data {
      id
      name
    }
  }
}
`);

const CREATE_BONUS_CARD = gql(`
  mutation CreateBonusCard($input: CreateBonusCard!) {
    createBonusCard(input: $input) {
      id
    }
  }
`);

export const AddBonusPopup = ({
  customerId,
  visible,
  onClose,
}: {
  customerId: string;
  visible?: boolean;
  onClose?: () => void;
}) => {
  const companyId = useAppSelector(state => state.company.data!.id);
  const colors = useColors();
  const [search, setSearch] = useState('');
  const [lazy, {data, loading}] = useLazyQuery(BONUS_CARDS, {
    variables: {
      companyId,
      name: search,
      first: 40,
      page: 1,
    },
  });
  const [sellCard, {loading: createLoading}] = useMutation(CREATE_BONUS_CARD, {
    refetchQueries: ['CustomersBonus'],
  });

  const bonuses = data?.bonusCardTemplates?.data;
  const [select, setSelect] = useState<string>();
  useEffect(() => {
    visible && lazy();
  }, [lazy, visible]);

  return (
    <Popup {...{visible, onClose}}>
      <Grow style={{gap: 24}}>
        <Row>
          <Text typography="title20">Выберите бонусную карту</Text>
          <CloseButton
            onClose={() => {
              onClose && onClose();
            }}
          />
        </Row>
        <TextInput
          height={54}
          variant="default"
          onChange={text => setSearch(text)}
          value={search}
          placeholder="Поиск"
          leftElement={<Search color={colors.textTertiary} />}
        />
        {loading ? (
          <AddSkeleton />
        ) : !bonuses?.length ? (
          <EmptyState search={search} />
        ) : (
          <>
            <Flex style={{maxHeight: 480, overflowY: 'scroll'}}>
              <List
                data={bonuses}
                gap={16}
                keyExtractor={(_, index) => `bonus_${index}`}
                renderItem={bonus => {
                  return (
                    <Bonus>
                      <RadioButton
                        labelPosition="right"
                        title={bonus.name}
                        selected={select === bonus.id}
                        onChange={() => {
                          setSelect(bonus.id);
                        }}
                      />
                    </Bonus>
                  );
                }}
              />
            </Flex>
            <Button
              size="large"
              disabled={!select || createLoading}
              loading={createLoading}
              onClick={() => {
                select &&
                  sellCard({
                    variables: {
                      input: {
                        customer_ids: [customerId],
                        bonus_card_template_id: select,
                      },
                    },
                  })
                    .then(() => {
                      onClose && onClose();
                    })
                    .catch((e: ApolloError) => showAlert('error', e.message));
              }}>
              Выбрать для привязки
            </Button>
          </>
        )}
      </Grow>
    </Popup>
  );
};

const EmptyState = ({search}: {search: string}) => {
  const navigate = useNavigate();
  const username = useAppSelector(state => state.company.data?.username);
  return (
    <Flex
      style={{width: 438}}
      flex={1}
      direction="column"
      gap={16}
      alignItems="center">
      <EmptyBonusCard size={300} />
      {!search && <Text typography="title20">Нету бонусных карт</Text>}
      <Text align="center" typography="text18" color="textTertiary">
        {search ? 'Не найдено' : 'У данного клиента пока нет бонусных карт'}
      </Text>
      {!search && (
        <Button
          style={{width: '100%', marginTop: 16}}
          size="large"
          onClick={() => navigate(`/${username}/create-bonus`)}>
          Добавить бонусные карты
        </Button>
      )}
    </Flex>
  );
};

const AddSkeleton = () => {
  return (
    <Skeleton width={438} height={312}>
      {Array.from(Array(5).keys()).map((columnItem, index) => (
        <React.Fragment key={'column_' + columnItem}>
          <circle r={12} cx={24} cy={64 * index + 24} />
          <rect
            width={210}
            height={22}
            rx={11}
            ry={11}
            x={48}
            y={64 * index + 12}
          />
        </React.Fragment>
      ))}
    </Skeleton>
  );
};
