import React from 'react';
import {Skeleton} from 'shared/ui/Skeleton';

export const SkeletonBookingSource = () => {
  return (
    <Skeleton height={4 * 80}>
      {Array.from(Array(4).keys()).map(item => (
        <React.Fragment key={'sk_' + item}>
          <rect width={4} height={56} x={0} y={12 + 80 * item} rx={4} ry={4} />
          <rect
            width={item % 3 === 0 ? 150 : item % 3 === 1 ? 200 : 180}
            height={24}
            x={4 + 12}
            rx={8}
            ry={8}
            y={12 + 16 + 80 * item}
          />
          <rect
            width={item % 3 === 2 ? 80 : item % 2 === 0 ? 90 : 120}
            height={24}
            x={4 + 12 + 239}
            y={12 + 16 + 80 * item}
            rx={8}
            ry={8}
          />
          <rect
            width={item % 2 === 0 ? 100 : 92}
            height={40}
            x={4 + 12 + 239 + 240}
            y={20 + item * 80}
            rx={12}
            ry={12}
          />
          <rect
            width={24}
            height={24}
            x={'calc(100% - 42px)'}
            y={12 + 16 + item * 80}
            rx={8}
            ry={8}
          />
        </React.Fragment>
      ))}
    </Skeleton>
  );
};
