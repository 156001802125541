import {ApolloError, useMutation} from '@apollo/client';
import dayjs from 'dayjs';
import {BOOKING, GET_BOOKING_MESSAGES} from 'entities/booking';
import {GET_MESSAGES_CUSTOMERS} from 'entities/messages';
import React, {useEffect, useState} from 'react';
import {gql} from 'shared/__generated__';
import {
  Customer,
  Image,
  MessageMethod,
  MessageSampleEvent,
  MessageStatus,
  SendAt,
  UpdateMessage,
} from 'shared/__generated__/graphql';
import {Copy} from 'shared/icons/Copy';
import {Dialog} from 'shared/icons/Dialog';
import {useColors} from 'shared/lib/hooks';
import {getName, getPhoneMask} from 'shared/lib/utils';
import {useAppSelector} from 'shared/store';
import {showAlert} from 'shared/ui/Alert';
import {Avatar} from 'shared/ui/Avatar';
import {Button} from 'shared/ui/Button';
import {CloseButton} from 'shared/ui/CloseButton';
import {Divider} from 'shared/ui/Divider';
import {Flex} from 'shared/ui/Flex';
import {IconButton} from 'shared/ui/IconButton';
import {List} from 'shared/ui/List';
import {Popup} from 'shared/ui/Popup';
import {SegmentedControl} from 'shared/ui/SegmentedControl';
import {Select} from 'shared/ui/Select';
import {Text} from 'shared/ui/Text';
import {TextArea} from 'shared/ui/TextArea';
import styled from 'styled-components';

const PopupWrapper = styled.div<{gap?: number; isSend: boolean}>`
  flex-direction: column;
  display: flex;
  width: 100%;
  gap: ${({gap}) => gap ?? 24}px;
  padding: ${({isSend}) => (isSend ? '0 24px' : 0)};
  flex: 1;
  overflow-y: hidden;
`;
const IconBackground = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background-color: ${({theme}) => theme.bgWaiting};
`;
const ButtonPeriod = styled.button<{checked?: boolean}>`
  display: flex;
  align-items: center;
  height: 48px;
  width: 100%;
  justify-content: space-between;
  background-color: transparent;
  padding: 0 24px;
  border: none;
  background-color: ${({theme, checked}) =>
    checked ? theme.bgSecondary : 'transparent'};
`;

const sendMethods = [
  {label: 'Ручная рассылка смс', value: MessageMethod.Sms},
  {label: 'Ручная рассылка Whatsapp', value: MessageMethod.Whatsapp},
  {label: 'Ручная рассылка Telegram', value: MessageMethod.Telegram},
  {label: 'ChatPush-WhatsApp', value: MessageMethod.CpWhatsapp},
  {label: 'ChatPush-Telegram', value: MessageMethod.CpTelegram},
  {label: 'sms.ru', value: MessageMethod.SmsRu},
];

const postpones = [
  {label: 'Через 5 минут', value: SendAt.Five},
  {label: 'Через 15 минут', value: SendAt.Fiveteen},
  {label: 'Через 30 минут', value: SendAt.Thirty},
  {label: 'Через 1 час', value: SendAt.Hour},
  {label: 'Через 4 часа', value: SendAt.Fourhour},
  {label: 'Через 8 часов', value: SendAt.Eighthour},
  {label: 'Через 24 часа', value: SendAt.Day},
];

const status = [
  MessageStatus.Pending,
  MessageStatus.Completed,
  MessageStatus.Cancelled,
];

interface IMessages {
  id: string;
  message?: string | null | undefined;
  status: MessageStatus;
  send_at?: string | null | undefined;
  method?: MessageMethod | null | undefined;
  messageSample?:
    | {
        name: string;
        event: MessageSampleEvent;
        id: string;
      }
    | null
    | undefined;
  customer: Pick<Customer, 'id' | 'name' | 'surname' | 'phone'> & {
    avatar?: Pick<Image, 'id' | 'url' | 'url_64'> | null;
  };
  booking?: {id?: string | null} | null | undefined;
}

const UPDATE_MESSAGE = gql(`
  mutation UpdateMessage($input: UpdateMessage) {
    updateMessage(input: $input) {
      id
      message
      messageSample {
        message
        method
        name
        time
        event
        is_active
        id
        booking_status
      }
      method
      status
    }
  }
`);

const getLabelStatus = (event?: MessageSampleEvent) => {
  if (!event) return;
  switch (event) {
    case MessageSampleEvent.After:
      return 'Сообщение после визита';
    case MessageSampleEvent.Before:
      return 'Сообщение до визита';
    default:
      return 'Сообщение после создание записи';
  }
};

const openMessaging = (type: MessageMethod, value: string, message: string) => {
  switch (type) {
    case MessageMethod.Sms:
      window.open(`sms:${value}?&body=${message}`, '_blank', 'noreferrer');
      break;
    case MessageMethod.Whatsapp:
      window.open(
        `https://wa.me/${value.replace('+', '')}?text=${message}`,
        '_blank',
      );
      break;
    case MessageMethod.Telegram:
      window.open(`https://t.me/${value}`, '_blank');
      break;
    default:
      break;
  }
};

export const Send = ({
  data,
  onClose,
}: {
  data: IMessages | null;
  onClose: () => void;
}) => {
  const colors = useColors();
  const [message, setMessage] = useState('');
  const companyId = useAppSelector(state => state.company.data!.id);
  const [method, setMethod] = useState(sendMethods[0].value);
  const [type, setType] = useState<'send' | 'postpone'>('send');
  const [sendTypeIndex, setSendTypeIndex] = useState(0);
  const [postpone, setPostpone] = useState(0);
  const isSend = type === 'send';
  const [updateMessage, {loading}] = useMutation(UPDATE_MESSAGE, {
    refetchQueries: [
      {
        query: GET_MESSAGES_CUSTOMERS,
        variables: {
          companyId: companyId,
          first: 10,
          page: 1,
        },
      },
      {
        query: GET_BOOKING_MESSAGES,
        variables: {bookingId: data?.booking?.id},
      },
      {
        query: BOOKING,
        variables: {bookingId: data?.booking?.id, companyId: companyId},
      },
    ],
  });

  const updateMessageClick = (input: UpdateMessage, index: number) => {
    updateMessage({variables: {input}})
      .then(() => {
        onClose();
        setType('send');
        setSendTypeIndex(index);
        showAlert('success', 'Рассылка изменена');
      })
      .catch((e: ApolloError) => {
        showAlert('error', e.message);
      });
  };

  const tabs = [
    {
      name: 'Не отправлено',
      onClick: () => {
        if (!data?.id || sendTypeIndex === 0) return null;
        const input: UpdateMessage = {
          id: data.id,
          message,
          method,
          status: MessageStatus.Pending,
        };
        updateMessageClick(input, 0);
      },
    },
    {
      name: 'Отправлено',
      onClick: () => {
        if (!data?.id || sendTypeIndex === 1) return null;
        const input: UpdateMessage = {
          id: data.id,
          message,
          method,
          status: MessageStatus.Completed,
        };
        updateMessageClick(input, 1);
      },
    },
    {
      name: 'Отменено',
      onClick: () => {
        if (!data?.id || sendTypeIndex === 2) return null;
        const input: UpdateMessage = {
          id: data.id,
          message,
          method,
          status: MessageStatus.Cancelled,
        };
        updateMessageClick(input, 2);
      },
    },
  ];

  useEffect(() => {
    if (data) {
      setMessage(data.message ?? '');
      const findSendType = status.findIndex(item => item === data.status);
      if (findSendType !== -1) {
        setSendTypeIndex(findSendType);
      }
      setMethod(data.method ?? sendMethods[0].value);
    }
  }, [data]);

  return (
    <Popup
      visible={data !== null}
      style={{
        maxHeight: '100%',
        width: isSend ? 596 : 472,
        padding: '24px 0',
      }}
      onClose={() => {
        setType('send');
        onClose();
      }}>
      {isSend ? (
        <PopupWrapper isSend>
          <Flex justifyContent="space-between">
            <Text typography="title20">Сообщение клиенту</Text>
            <CloseButton
              onClose={() => {
                setType('send');
                onClose();
              }}
            />
          </Flex>
          <Flex
            gap={24}
            direction="column"
            style={{
              scrollbarWidth: 'none',
              overflow: 'scroll',
            }}>
            <SegmentedControl
              typography="text16Regular"
              index={sendTypeIndex}
              tabs={tabs}
            />
            <Flex alignItems="center" gap={24}>
              <Avatar
                size={64}
                url={
                  data?.customer?.avatar?.url_64 ?? data?.customer?.avatar?.url
                }
              />
              <Flex direction="column" gap={8}>
                <Text typography="title18">
                  {getName(data?.customer?.surname, data?.customer?.name)}
                </Text>
                <Text color="textTertiary" typography="subHead14Medium">
                  {getPhoneMask(data?.customer.phone)}
                </Text>
              </Flex>
            </Flex>
            <Divider />
            <Flex alignItems="center" gap={16}>
              <IconBackground>
                <Dialog />
              </IconBackground>
              <Flex direction="column" gap={8}>
                <Text>{getLabelStatus(data?.messageSample?.event)}</Text>
                <Text color="textTertiary" typography="subHead14Regular">
                  {dayjs(data?.send_at).format('DD.MM.YYYY, в HH:mm')}
                </Text>
              </Flex>
            </Flex>
            <Divider />
            <TextArea
              size="large"
              label="Сообщение"
              value={message}
              onChange={text => setMessage(text)}
              maxLength={1000}
              disabled={!(sendTypeIndex === 0)}
              color={
                sendTypeIndex === 0 ? colors.textPrimary : colors.textTertiary
              }
              rightElement={
                <IconButton
                  style={{
                    position: 'absolute',
                    right: 0,
                    top: -2,
                    zIndex: 30,
                    display: 'flex',
                  }}
                  onClick={e => {
                    e.stopPropagation();
                    navigator.clipboard
                      .writeText(message)
                      .then(() => showAlert('success', 'Сообщение скопировано'))
                      .catch(() =>
                        showAlert('error', 'Сообщение не скопировано'),
                      );
                  }}
                  variant="unstyled">
                  <Copy />
                </IconButton>
              }
            />
            <Select
              style={{marginTop: 8}}
              data={sendMethods}
              dropdownPosition="top"
              label="Метод отправки сообщения"
              value={method}
              valueColor={sendTypeIndex === 0 ? 'textPrimary' : 'textTertiary'}
              disabled={!(sendTypeIndex === 0)}
              onChange={setMethod}
            />
          </Flex>
        </PopupWrapper>
      ) : (
        <PopupWrapper isSend={false} gap={16} style={{padding: '0'}}>
          <Flex
            style={{margin: '0 24px'}}
            alignItems="center"
            justifyContent="space-between">
            <Text typography="title20">Отложить отправку сообщения</Text>
            <CloseButton
              style={{marginLeft: 16}}
              onClose={() => {
                setType('send');
                onClose();
              }}
            />
          </Flex>
          <Text style={{margin: '0 24px'}} color="textTertiary">
            Выберите через какое время вас уведомить об отправке сообщения
            клиенту
          </Text>
          <List
            gap={8}
            data={[...Object.values(postpones)]}
            keyExtractor={(_, index) => 'key_' + index}
            overflow="auto"
            renderItem={(item, index) => (
              <ButtonPeriod
                onClick={() => {
                  setPostpone(index);
                }}
                checked={index === postpone}>
                <Text>{item.label}</Text>
              </ButtonPeriod>
            )}
          />
        </PopupWrapper>
      )}
      {sendTypeIndex === 0 && (
        <Flex style={{width: 'calc(100% - 48px)', marginTop: 8}} gap={8}>
          <Button
            style={{flex: 1}}
            size="large"
            variant="outline"
            onClick={() => {
              isSend ? setType('postpone') : setType('send');
            }}>
            {isSend ? 'Отложить' : 'Отмена'}
          </Button>
          <Button
            style={{flex: 1}}
            loading={loading}
            size="large"
            onClick={() => {
              if (isSend && data?.id) {
                const input: UpdateMessage = {
                  id: data.id,
                  message,
                  method,
                  status: MessageStatus.Completed,
                };
                openMessaging(method, data.customer.phone ?? '', message);
                updateMessageClick(input, 1);
                return;
              }
              if (!isSend && data?.id) {
                const input: UpdateMessage = {
                  id: data.id,
                  send_at: postpones[postpone].value,
                };
                updateMessageClick(input, 0);
                return;
              }
              return null;
            }}>
            {isSend ? 'Отправить' : 'Выбрать'}
          </Button>
        </Flex>
      )}
    </Popup>
  );
};
