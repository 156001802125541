import React from 'react';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';
import {device} from 'shared/device';
import {EmptyList2} from 'shared/illustration/EmptyList2';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 58px;
  @media (${device.mobile}) {
    margin-left: 0;
    align-self: center;
    justify-content: center;
    padding: 16px;
  }
`;
export const EmptyState = () => {
  return (
    <Wrapper>
      <EmptyList2 size={300} />
      <Text
        typography="text16Regular"
        color="textTertiary"
        mt={16}
        style={{
          textAlign: 'center',
          alignSelf: 'center',
          maxWidth: 500,
        }}>
        У данного сотрудника пока нет услуг
      </Text>
    </Wrapper>
  );
};
