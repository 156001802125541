import React from 'react';
import {NavigateBack} from './NavigateBack';
import {Text} from './Text';
import {device} from 'shared/device';
import styled from 'styled-components';
import {Seo} from './Seo';

const Header = styled.div<{isLayout: boolean}>`
  display: flex;
  align-items: center;
  flex-direction: row;
  grid-column-start: ${({isLayout}) => (isLayout ? 4 : undefined)};
  grid-column-end: ${({isLayout}) => (isLayout ? 10 : undefined)};
  height: ${({isLayout}) => (isLayout ? 40 : 56)}px;
  gap: 16px;
  margin: ${({isLayout}) => (isLayout ? '64px 0 8px 0' : 0)};
  @media (${device.mobile}) {
    position: absolute;
    top: 16px;
    left: 16px;
    margin-bottom: 0;
  }
`;

export const PageHeader = ({
  title,
  variant = 'sidebar',
  back = false,
}: {
  title: string;
  back?: boolean;
  variant?: 'sidebar' | 'layout';
}) => (
  <>
    <Seo title={title} />
    <Header isLayout={variant === 'layout'}>
      {back && <NavigateBack />}
      <Text typography="title24">{title}</Text>
    </Header>
  </>
);
