import {gql} from 'shared/__generated__';
import {
  sortTypings,
  statusNotification,
  methodSending,
  messageTypes,
} from './lib/utils';
import {Send} from './ui/Send';
import {TooltipMessage} from './ui/TooltipMessage';

export const GET_MESSAGES_CUSTOMERS = gql(`
  query GetMessages(
    $companyId: String!
    $first: Int!
    $page: Int
    $status: MessageStatus
    $method: MessageMethod
    $orderBy: [QueryGetMessagesOrderByOrderByClause!]
    $employeeId: String
    $customerId: String
    $type: MessageType
  ) {
    getMessages(
      company_id: $companyId
      first: $first
      page: $page
      status: $status
      method: $method
      orderBy: $orderBy
      employee_id: $employeeId
      customer_id: $customerId
      type: $type
    ) {
      paginatorInfo {
        hasMorePages
        count
        currentPage
        total
        lastPage
      }
      data {
        id
        message
        status
        method
        send_at
        messageSample {
          name
          event
          id
        }
        customer {
          id
          name
          surname
          phone
          avatar {
            id
            url
            url_64
          }
        }
        employee {
          id
          name
          surname
          professions {
            id
            name
          }
          avatar {
            id
            url
            url_64
          }
        }
        booking {
          id
        }
      }
    }
    ...Permissions
  }
`);

export const DELETE_MESSAGE = gql(`
  mutation DeleteMessage($id: String!) {
    deleteMessage(id: $id) {
      id
    }
}`);

export {
  sortTypings,
  statusNotification,
  methodSending,
  messageTypes,
  Send,
  TooltipMessage,
};
