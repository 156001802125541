import dayjs from 'dayjs';
import React from 'react';
import styled from 'styled-components';

const BodyMonthWrapper = styled.div`
  display: grid;
  grid-auto-rows: 1;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  justify-content: center;
  gap: 16px 56px;
`;

const MonthText = styled.div<{current: boolean}>`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 54px;
  cursor: pointer;
  pointer-events: all;
  border-radius: 27px;
  color: ${({theme, current}) => (current ? theme.white : theme.textPrimary)};
  background-color: ${({theme, current}) =>
    current ? theme.mainPrimary : 'transparent'};
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-transform: capitalize;
  &:hover {
    background-color: ${({theme, current}) =>
      current ? theme.mainPrimary : theme.mainLight50};
  }
`;

export const Months = ({
  calendarDate,
  selectMonth,
  onSelect,
}: {
  calendarDate: string;
  selectMonth?: string;
  onSelect: (newDate: string) => void;
}) => {
  const month = Array.from(Array(12), (_, index) =>
    dayjs(calendarDate).set('month', index).format('YYYY-MM-DD'),
  );
  return (
    <BodyMonthWrapper>
      {month.map((item, index) => (
        <MonthText
          key={item}
          current={dayjs(item).format('YYYY-MM-DD') === selectMonth}
          onClick={() =>
            onSelect(dayjs(item).set('month', index).format('YYYY-MM-DD'))
          }>
          {dayjs(item).format('MMM')}
        </MonthText>
      ))}
    </BodyMonthWrapper>
  );
};
