import React from 'react';
import {device} from 'shared/device';
import {Loader} from 'shared/icons/Loader';
import styled from 'styled-components';
import {typography as textTypography} from 'shared/typography';

type Variant = 'filled' | 'light' | 'outline' | 'subtitle';
type Size = 'large' | 'medium' | 'small';

const getSize = (size: Size) => {
  switch (size) {
    case 'large':
      return '60px';
    case 'medium':
      return '48px';
    case 'small':
      return '40px';
  }
};

const Wrapper = styled.button<{variant?: Variant; size: Size}>`
  padding: 0;
  height: ${({size}) => getSize(size)};
  border: 1px solid
    ${({variant, theme}) =>
      variant === 'outline' ? theme.mainPrimary : 'transparent'};
  border-radius: 14px;
  padding: 0 32px;
  background-color: ${({variant, theme}) =>
    variant === 'filled'
      ? theme.mainPrimary
      : variant === 'light'
      ? theme.mainLight
      : 'transparent'};
  color: ${({variant, theme}) =>
    variant === 'filled' ? theme.white : theme.mainPrimary};
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  pointer-events: all;
  cursor: pointer;
  &:disabled {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
  }
  &:hover {
    background-color: ${({variant, theme}) =>
      variant === 'filled'
        ? theme.hoverButton
        : variant === 'light'
        ? theme.hoverButtonLight
        : theme.bgPrimary};
  }
  &:active {
    background-color: ${({variant, theme}) =>
      variant === 'filled'
        ? theme.hoverButton
        : variant === 'light'
        ? theme.hoverButtonLight
        : theme.bgPrimary};
  }
  @media ${device.mobile} {
    width: 100%;
    height: ${({size}) => (size === 'large' ? 56 : getSize(size))};
  }
`;

type Props = {
  loading?: boolean;
  variant?: Variant;
  size?: Size;
  leftIcon?: React.JSX.Element;
  rightIcon?: React.JSX.Element;
  typography?: keyof typeof textTypography;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;
export const Button = ({
  children,
  loading,
  size = 'medium',
  variant = 'filled',
  typography = size === 'large'
    ? 'text16Semibold'
    : size === 'medium'
    ? 'subHead14Medium'
    : 'footNote12Medium',
  leftIcon,
  rightIcon,
  ...rest
}: React.PropsWithChildren<Props>) => {
  return (
    <Wrapper
      variant={variant}
      size={size}
      {...rest}
      style={{...textTypography[typography], ...rest.style}}>
      {leftIcon}
      {loading ? <Loader color={rest.style?.color} /> : children}
      {rightIcon}
    </Wrapper>
  );
};
