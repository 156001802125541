import {gql} from 'shared/__generated__';

export const GET_PROFILE = gql(`
  query GetProfile {
    me {
      id
      birthdate
      gender
      phone
      email
      temp_email
    }
  }
`);
