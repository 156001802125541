import React, {ReactNode} from 'react';
import styled from 'styled-components';

const Container = styled.div<{index: number}>`
  display: flex;
  flex-wrap: no-wrap;
  width: 100%;
  transform: translateX(
    ${({index}) => `calc(${-100 * index}% - ${index * 16}px)`}
  );
  transition: transform 300ms;
  & > div {
    flex-shrink: 0;
  }
`;

const StyledOuterSliders = styled.div`
  overflow: hidden;
`;

export const SlidesContainer = ({
  children,
  index,
  wrapperStyle,
  containerStyle,
}: {
  children: ReactNode;
  index: number;
  wrapperStyle?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
}) => {
  return (
    <StyledOuterSliders style={wrapperStyle}>
      <Container index={index} style={containerStyle}>
        {children}
      </Container>
    </StyledOuterSliders>
  );
};
