import React from 'react';

export const Forbid = ({
  size = 24,
  color = '#6765F8',
}: {
  size?: number;
  color?: string;
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.96969 17.9694C4.6768 18.2623 4.67679 18.7372 4.96968 19.0301C5.26258 19.323 5.73745 19.323 6.03034 19.0301L4.96969 17.9694ZM17.9697 4.96967L4.96969 17.9694L6.03034 19.0301L19.0303 6.03033L17.9697 4.96967ZM21.25 12C21.25 17.1086 17.1086 21.25 12 21.25V22.75C17.9371 22.75 22.75 17.9371 22.75 12H21.25ZM12 21.25C6.89137 21.25 2.75 17.1086 2.75 12H1.25C1.25 17.9371 6.06294 22.75 12 22.75V21.25ZM2.75 12C2.75 6.89137 6.89137 2.75 12 2.75V1.25C6.06294 1.25 1.25 6.06294 1.25 12H2.75ZM12 2.75C17.1086 2.75 21.25 6.89137 21.25 12H22.75C22.75 6.06294 17.9371 1.25 12 1.25V2.75Z"
        fill={color}
      />
    </svg>
  );
};
