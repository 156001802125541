import React from 'react';
import {useColorScheme} from 'shared/lib/hooks';
import {EmptyContainer} from 'shared/ui/EmptyContainer';

export const EmptyBlockedState = ({size = 400}: {size?: number}) => {
  const colorScheme = useColorScheme();
  return (
    <EmptyContainer size={size}>
      {colorScheme === 'light' ? (
        <svg width={size} height={size} viewBox="0 0 400 400" fill="none">
          <path
            d="M187.7 343.164C266.288 343.164 330.001 279.451 330.001 200.582C330.001 121.713 266.288 58 187.7 58C109.111 58 45.3984 121.713 45.3984 200.582C45.3984 279.451 109.111 343.164 187.7 343.164Z"
            fill="#EAEEF9"
          />
          <path
            d="M346.285 141.922C352.64 141.922 357.793 136.77 357.793 130.415C357.793 124.059 352.64 118.907 346.285 118.907C339.929 118.907 334.777 124.059 334.777 130.415C334.777 136.77 339.929 141.922 346.285 141.922Z"
            fill="#EAEEF9"
          />
          <path
            d="M363.124 96.7343C367.465 96.7343 370.983 93.2158 370.983 88.8755C370.983 84.5351 367.465 81.0166 363.124 81.0166C358.784 81.0166 355.266 84.5351 355.266 88.8755C355.266 93.2158 358.784 96.7343 363.124 96.7343Z"
            fill="#EAEEF9"
          />
          <path
            d="M35.8589 150.062C40.1992 150.062 43.7177 146.543 43.7177 142.203C43.7177 137.863 40.1992 134.344 35.8589 134.344C31.5185 134.344 28 137.863 28 142.203C28 146.543 31.5185 150.062 35.8589 150.062Z"
            fill="#EAEEF9"
          />
          <path
            d="M50.4544 320.991C58.515 320.991 65.0494 314.456 65.0494 306.396C65.0494 298.335 58.515 291.801 50.4544 291.801C42.3938 291.801 35.8594 298.335 35.8594 306.396C35.8594 314.456 42.3938 320.991 50.4544 320.991Z"
            fill="#EAEEF9"
          />
          <g filter="url(#filter0_d_13007_9033)">
            <path
              d="M300.86 290.592H75.1064C61.371 290.592 50.4531 279.675 50.4531 265.939V135.981C50.4531 122.246 61.371 111.328 75.1064 111.328H300.86C314.595 111.328 325.513 122.246 325.513 135.981V265.939C325.513 279.675 314.595 290.592 300.86 290.592Z"
              fill="url(#paint0_linear_13007_9033)"
            />
          </g>
          <path
            d="M120.187 250.09H84.2639C81.0942 250.09 78.6289 247.625 78.6289 244.455C78.6289 241.286 81.0942 238.82 84.2639 238.82H120.187C123.357 238.82 125.822 241.286 125.822 244.455C125.822 247.625 123.357 250.09 120.187 250.09Z"
            fill="#D5DDEA"
          />
          <path
            d="M178.297 250.091H142.373C139.204 250.091 136.738 247.626 136.738 244.456C136.738 241.286 139.204 238.821 142.373 238.821H178.297C181.466 238.821 183.932 241.286 183.932 244.456C183.932 247.626 181.466 250.091 178.297 250.091Z"
            fill="#D5DDEA"
          />
          <path
            d="M236.41 250.091H200.487C197.317 250.091 194.852 247.626 194.852 244.456C194.852 241.286 197.317 238.821 200.487 238.821H236.41C239.58 238.821 242.045 241.286 242.045 244.456C242.045 247.626 239.58 250.091 236.41 250.091Z"
            fill="#D5DDEA"
          />
          <path
            d="M294.519 250.09H258.596C255.426 250.09 252.961 247.625 252.961 244.455C252.961 241.286 255.426 238.82 258.596 238.82H294.519C297.689 238.82 300.154 241.286 300.154 244.455C300.154 247.625 297.689 250.09 294.519 250.09Z"
            fill="#D5DDEA"
          />
          <path
            d="M281.491 182.471C293.161 182.471 302.622 173.01 302.622 161.339C302.622 149.669 293.161 140.208 281.491 140.208C269.82 140.208 260.359 149.669 260.359 161.339C260.359 173.01 269.82 182.471 281.491 182.471Z"
            fill="#989FB0"
          />
          <path
            d="M253.667 182.471C265.337 182.471 274.798 173.01 274.798 161.339C274.798 149.669 265.337 140.208 253.667 140.208C241.996 140.208 232.535 149.669 232.535 161.339C232.535 173.01 241.996 182.471 253.667 182.471Z"
            fill="#D5DDEA"
          />
          <path
            d="M123.357 189.162H83.9117C81.0942 189.162 78.6289 186.696 78.6289 183.879V158.521C78.6289 155.704 81.0942 153.238 83.9117 153.238H123.005C125.822 153.238 128.288 155.704 128.288 158.521V183.879C128.64 186.696 126.174 189.162 123.357 189.162Z"
            fill="#D5DDEA"
          />
          <path
            d="M257.933 297.791L303.788 225.217C308.779 218.408 319.229 218.408 324.22 225.217L370.075 297.791C374.91 304.298 370.075 313.529 361.809 313.529H266.356C257.933 313.378 253.098 304.298 257.933 297.791Z"
            fill="url(#paint1_linear_13007_9033)"
          />
          <path
            d="M314.005 243.797C309.635 243.797 306.237 247.638 306.56 252.147L308.988 279.202C309.15 281.874 311.415 284.045 314.005 284.045C316.594 284.045 318.86 281.874 319.021 279.202L321.449 252.147C321.773 247.471 318.374 243.797 314.005 243.797Z"
            fill="white"
          />
          <path
            d="M314.006 289.055C310.769 289.055 308.18 291.728 308.18 295.068C308.18 298.408 310.769 301.08 314.006 301.08C317.242 301.08 319.832 298.408 319.832 295.068C319.832 291.728 317.242 289.055 314.006 289.055Z"
            fill="white"
          />
          <defs>
            <filter
              id="filter0_d_13007_9033"
              x="28.4531"
              y="100.328"
              width="319.06"
              height="223.264"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_13007_9033"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_13007_9033"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_13007_9033"
              x1="187.893"
              y1="107.182"
              x2="187.893"
              y2="292.525"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#FDFEFF" />
              <stop offset="0.9964" stopColor="#ECF0F5" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_13007_9033"
              x1="255.959"
              y1="269.276"
              x2="372.021"
              y2="269.276"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#B0BACC" />
              <stop offset="1" stopColor="#969EAE" />
            </linearGradient>
          </defs>
        </svg>
      ) : (
        <svg width={size} height={size} viewBox="0 0 400 400" fill="none">
          <path
            d="M187.7 342C266.288 342 330.001 278.324 330.001 199.5C330.001 120.676 266.288 57 187.7 57C109.111 57 45.3984 120.676 45.3984 199.5C45.3984 278.324 109.111 342 187.7 342Z"
            fill="#2A2E37"
          />
          <path
            d="M346.285 140.873C352.64 140.873 357.793 135.724 357.793 129.372C357.793 123.02 352.64 117.871 346.285 117.871C339.929 117.871 334.777 123.02 334.777 129.372C334.777 135.724 339.929 140.873 346.285 140.873Z"
            fill="#2A2E37"
          />
          <path
            d="M363.121 95.7111C367.461 95.7111 370.979 92.1946 370.979 87.8568C370.979 83.5189 367.461 80.0024 363.121 80.0024C358.78 80.0024 355.262 83.5189 355.262 87.8568C355.262 92.1946 358.78 95.7111 363.121 95.7111Z"
            fill="#2A2E37"
          />
          <path
            d="M35.8588 149.007C40.1992 149.007 43.7177 145.491 43.7177 141.153C43.7177 136.815 40.1992 133.299 35.8588 133.299C31.5185 133.299 28 136.815 28 141.153C28 145.491 31.5185 149.007 35.8588 149.007Z"
            fill="#2A2E37"
          />
          <path
            d="M50.4544 319.839C58.5149 319.839 65.0493 313.308 65.0493 305.252C65.0493 297.196 58.5149 290.666 50.4544 290.666C42.3938 290.666 35.8594 297.196 35.8594 305.252C35.8594 313.308 42.3938 319.839 50.4544 319.839Z"
            fill="#2A2E37"
          />
          <g filter="url(#filter0_d_13007_9055)">
            <path
              d="M300.859 289.458H75.1063C61.371 289.458 50.4531 278.546 50.4531 264.819V134.935C50.4531 121.208 61.371 110.296 75.1063 110.296H300.859C314.595 110.296 325.512 121.208 325.512 134.935V264.819C325.512 278.546 314.595 289.458 300.859 289.458Z"
              fill="url(#paint0_linear_13007_9055)"
            />
          </g>
          <path
            d="M120.191 248.979H84.2678C81.0981 248.979 78.6328 246.515 78.6328 243.348C78.6328 240.18 81.0981 237.716 84.2678 237.716H120.191C123.361 237.716 125.826 240.18 125.826 243.348C125.826 246.515 123.361 248.979 120.191 248.979Z"
            fill="#A9AFC3"
          />
          <path
            d="M178.297 248.979H142.373C139.204 248.979 136.738 246.515 136.738 243.348C136.738 240.18 139.204 237.716 142.373 237.716H178.297C181.466 237.716 183.932 240.18 183.932 243.348C183.932 246.515 181.466 248.979 178.297 248.979Z"
            fill="#A9AFC3"
          />
          <path
            d="M236.414 248.979H200.49C197.321 248.979 194.855 246.515 194.855 243.348C194.855 240.18 197.321 237.716 200.49 237.716H236.414C239.583 237.716 242.049 240.18 242.049 243.348C242.049 246.515 239.583 248.979 236.414 248.979Z"
            fill="#A9AFC3"
          />
          <path
            d="M294.519 248.979H258.596C255.426 248.979 252.961 246.515 252.961 243.348C252.961 240.18 255.426 237.716 258.596 237.716H294.519C297.689 237.716 300.154 240.18 300.154 243.348C300.154 246.515 297.689 248.979 294.519 248.979Z"
            fill="#A9AFC3"
          />
          <path
            d="M281.491 181.399C293.161 181.399 302.622 171.944 302.622 160.28C302.622 148.616 293.161 139.161 281.491 139.161C269.82 139.161 260.359 148.616 260.359 160.28C260.359 171.944 269.82 181.399 281.491 181.399Z"
            fill="#989FB0"
          />
          <path
            d="M253.666 181.399C265.337 181.399 274.798 171.944 274.798 160.28C274.798 148.616 265.337 139.161 253.666 139.161C241.996 139.161 232.535 148.616 232.535 160.28C232.535 171.944 241.996 181.399 253.666 181.399Z"
            fill="#A9AFC3"
          />
          <path
            d="M123.361 188.086H83.9156C81.0981 188.086 78.6328 185.622 78.6328 182.806V157.463C78.6328 154.648 81.0981 152.184 83.9156 152.184H123.009C125.826 152.184 128.291 154.648 128.291 157.463V182.806C128.644 185.622 126.178 188.086 123.361 188.086Z"
            fill="#A9AFC3"
          />
          <path
            d="M257.929 296.654L303.784 224.122C308.775 217.316 319.225 217.316 324.216 224.122L370.071 296.654C374.906 303.157 370.071 312.383 361.805 312.383H266.352C257.929 312.232 253.094 303.157 257.929 296.654Z"
            fill="url(#paint1_linear_13007_9055)"
          />
          <path
            d="M314.001 242.691C309.631 242.691 306.233 246.53 306.556 251.037L308.984 278.076C309.146 280.747 311.411 282.917 314.001 282.917C316.59 282.917 318.856 280.747 319.018 278.076L321.445 251.037C321.769 246.363 318.37 242.691 314.001 242.691Z"
            fill="#474E61"
          />
          <path
            d="M313.998 287.923C310.761 287.923 308.172 290.594 308.172 293.932C308.172 297.27 310.761 299.941 313.998 299.941C317.234 299.941 319.824 297.27 319.824 293.932C319.824 290.594 317.234 287.923 313.998 287.923Z"
            fill="#474E61"
          />
          <defs>
            <filter
              id="filter0_d_13007_9055"
              x="28.4531"
              y="99.2964"
              width="319.06"
              height="223.161"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_13007_9055"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_13007_9055"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_13007_9055"
              x1="187.893"
              y1="106.152"
              x2="187.893"
              y2="291.389"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#5C6479" />
              <stop offset="0.9964" stopColor="#5C6479" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_13007_9055"
              x1="255.955"
              y1="268.155"
              x2="372.017"
              y2="268.155"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#B0BACC" />
              <stop offset="1" stopColor="#969EAE" />
            </linearGradient>
          </defs>
        </svg>
      )}
    </EmptyContainer>
  );
};
