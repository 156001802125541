import React, {useState} from 'react';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';
import {Content} from 'shared/ui/Content';
import {Head} from 'shared/ui/Head';
import {Button} from 'shared/ui/Button';
import {device} from 'shared/device';
import {Flex} from 'shared/ui/Flex';
import {Select} from 'shared/ui/Select';
import {TableViewRow} from 'shared/ui/TableViewRow';
import {CheckBox} from 'shared/ui/CheckBox';
import {gql} from 'shared/__generated__';
import {useMutation, useQuery} from '@apollo/client';
import {useAppSelector} from 'shared/store';
import {showAlert} from 'shared/ui/Alert';
import {EmployeeRole, ensureError} from 'shared/lib/utils';
import {Notifications} from 'shared/__generated__/graphql';
import {NavigateBack} from 'shared/ui/NavigateBack';
import {Avatar} from 'shared/ui/Avatar';
import {Error} from 'shared/ui/Error';
import {Loading} from 'shared/ui/Loading';
import {Seo} from 'shared/ui/Seo';

const COMPANY_INFO = gql(`
query IfnoCompany($companyId: String) {
  company(id: $companyId) {
    id
    avatar {
      id
      url
    }
    city {
      id
      name
    }
    address
    name
  }
}
`);

const EMPLOYEE_INFO = gql(`
query InfoEmployee($employeeId: String!) {
  employee(id: $employeeId) {
    id
    name
    surname
    avatar {
      id
      type
      url
    }
    enabled
    role
  }
}
`);

const NOTIFICATION_SETTING = gql(`
query NotificationSettings($employeeId: String!) {
    notificationSettings(employee_id: $employeeId) {
       id
      bonuses_sold
      booking_cancelled
      booking_completed
      booking_confirmed
      products_email_notifications
      products_low_capacity
      products_sold
      new_booking
      new_review
      notification
      review_email_notifications
    }
  }
`);
const NOTIFICATION_SETTING_UPDATE = gql(`
mutation MutationNotificationUpdate($employeeId: String!, $input: UpdateNotificationSettings) {
    updateNotificationSettings(employee_id: $employeeId, input: $input) {
      id
    }
  }
`);
const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0 auto;
  max-width: 720px;
  min-height: 100dvh;
  border-radius: 0;
  padding-top: 64px;
  padding-bottom: 24px;
  @media screen and (${device.mobile}) {
    margin-top: 56px;
  }
`;

export const NotificationSettings = () => {
  const companyId = useAppSelector(state => state.company.data!.id);
  const employeeId = useAppSelector(state => state.company.data!.employeeId);
  const {data: companyData, loading: companyLoading} = useQuery(COMPANY_INFO, {
    variables: {companyId},
  });
  const company = companyData?.company;
  const {data: employeeData, loading: employeeLoading} = useQuery(
    EMPLOYEE_INFO,
    {
      variables: {employeeId},
    },
  );
  const employee = employeeData?.employee;
  const {loading, error} = useQuery(NOTIFICATION_SETTING, {
    variables: {employeeId},
    onCompleted: data => {
      const notifData = data.notificationSettings;
      setMyNotification(notifData?.notification);
      setNewBooking(notifData?.new_booking);
      setBookingConfirm(notifData?.booking_confirmed);
      setBookingCancalled(notifData?.booking_cancelled);
      setBookingCompleted(notifData?.booking_completed);
      setBonusesSold(notifData?.bonuses_sold);
      setProductsSold(notifData?.products_sold);
      setNewReview(notifData?.new_review);
      setReviewMail(notifData?.review_email_notifications);
      setProductsLow(notifData?.products_low_capacity);
      setProductsMail(notifData?.products_email_notifications);
    },
  });

  const optionNotification = [
    {
      label: 'Обо всех активностях',
      value: Notifications.All,
    },
    {
      label: 'Только моя активность',
      value: Notifications.Mine,
    },
  ];
  const [myNotification, setMyNotification] = useState<
    Notifications | undefined
  >();
  const [newBooking, setNewBooking] = useState<boolean>();
  const [bookingConfirm, setBookingConfirm] = useState<boolean>();
  const [bookingCancalled, setBookingCancalled] = useState<boolean>();
  const [bookingCompleted, setBookingCompleted] = useState<boolean>();
  const [bonusesSold, setBonusesSold] = useState<boolean>();
  const [productsSold, setProductsSold] = useState<boolean>();
  const [newReview, setNewReview] = useState<boolean>();
  const [reviewMail, setReviewMail] = useState<boolean>();
  const [productsLow, setProductsLow] = useState<boolean>();
  const [productsMail, setProductsMail] = useState<boolean>();
  const [updateSettingNotification, {loading: udpateLoading}] = useMutation(
    NOTIFICATION_SETTING_UPDATE,
    {
      refetchQueries: [
        {
          query: NOTIFICATION_SETTING,
          variables: {
            employeeId,
          },
        },
      ],
    },
  );
  const update = async () => {
    try {
      await updateSettingNotification({
        variables: {
          employeeId: employeeId!,
          input: {
            bonuses_sold: bonusesSold,
            booking_cancelled: bookingCancalled,
            booking_completed: bookingCompleted,
            booking_confirmed: bookingConfirm,
            products_email_notifications: productsMail,
            products_low_capacity: productsLow,
            products_sold: productsSold,
            new_booking: newBooking,
            new_review: newReview,
            notification: myNotification,
            review_email_notifications: reviewMail,
          },
        },
      });
      showAlert('success', 'Сохранено');
    } catch (e) {
      const error = ensureError(e);
      showAlert('error', error.message);
    }
  };
  if (loading || employeeLoading || companyLoading) {
    return (
      <Wrapper>
        <Loading />
      </Wrapper>
    );
  }
  if (error) {
    return <Error message={error.message} />;
  }

  return (
    <Wrapper>
      <Seo title="Настройки уведомлений" />
      <Head>
        <NavigateBack />
        <Text typography="title24">Настройки уведомлений</Text>
      </Head>
      <Flex direction="column" gap={32}>
        <Flex gap={16}>
          <Flex gap={24} flex={1}>
            <Avatar size={64} url={company?.avatar?.url} variant="company" />
            <Flex direction="column" gap={8} justifyContent="center">
              <Text typography="title18">{company?.name}</Text>
              <Text typography="subHead14Medium" color="textTertiary">
                {`г.${company?.city?.name}, ${company?.address}`}
              </Text>
            </Flex>
          </Flex>
          <Flex gap={24} flex={1} justifyContent="center" alignItems="center">
            <Avatar
              size={64}
              url={employee?.avatar?.url}
              variant="avatar"
              active={employee?.enabled}
              activeSize={16}
            />
            <Flex direction="column" gap={8} justifyContent="center">
              <Text typography="title18">{`${employee?.name} ${employee?.surname}`}</Text>
              <Text typography="subHead14Medium" color="textTertiary">
                {EmployeeRole[employee?.role ?? '-']}
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Content>
          <Text typography="title18">Мои уведомления</Text>
          <Select
            label="Уведомлять меня о"
            data={optionNotification}
            value={myNotification}
            onChange={setMyNotification}
          />
        </Content>
        <Content gap="24px">
          <Text typography="title18">Записи</Text>
          <TableViewRow
            onClick={() => {
              setNewBooking(state => !state);
            }}
            style={{cursor: 'pointer', alignSelf: 'flex-start'}}
            styleTitle={{cursor: 'pointer'}}
            styleSubTitle={{cursor: 'pointer'}}
            leftElement={() => (
              <CheckBox
                onChange={() => {
                  setNewBooking(state => !state);
                }}
                style={{padding: 10}}
                checked={newBooking}
              />
            )}
            title={'Новые визиты'}
            subtitle={'При создании новых приемов'}
          />
          <TableViewRow
            style={{cursor: 'pointer', alignSelf: 'flex-start'}}
            styleTitle={{cursor: 'pointer'}}
            styleSubTitle={{cursor: 'pointer'}}
            onClick={() => {
              setBookingConfirm(state => !state);
            }}
            leftElement={() => (
              <CheckBox
                onChange={() => {
                  setBookingConfirm(state => !state);
                }}
                style={{padding: 10}}
                checked={bookingConfirm}
              />
            )}
            title={'Подтверждения'}
            subtitle={'При подтверждении записи клиентом'}
          />
          <TableViewRow
            onClick={() => {
              setBookingCancalled(state => !state);
            }}
            style={{cursor: 'pointer', alignSelf: 'flex-start'}}
            styleTitle={{cursor: 'pointer'}}
            styleSubTitle={{cursor: 'pointer'}}
            leftElement={() => (
              <CheckBox
                onChange={() => {
                  setBookingCancalled(state => !state);
                }}
                style={{padding: 10}}
                checked={bookingCancalled}
              />
            )}
            title={'Неявка или отмена'}
            subtitle={
              'Когда запись либо отменена либо клиент не прибыл на запись'
            }
          />
          <TableViewRow
            onClick={() => {
              setBookingCompleted(state => !state);
            }}
            style={{cursor: 'pointer', alignSelf: 'flex-start'}}
            styleTitle={{cursor: 'pointer'}}
            styleSubTitle={{cursor: 'pointer'}}
            leftElement={() => (
              <CheckBox
                onChange={() => {
                  setBookingCompleted(state => !state);
                }}
                style={{padding: 10}}
                checked={bookingCompleted}
              />
            )}
            title={'Прибытие или завершение'}
            subtitle={
              'Когда запись либо завершена либо клиент прибыл на запись'
            }
          />
        </Content>
        <Content gap="24px">
          <Text typography="title18">Продажи</Text>
          <TableViewRow
            style={{cursor: 'pointer', alignSelf: 'flex-start'}}
            styleTitle={{cursor: 'pointer'}}
            styleSubTitle={{cursor: 'pointer'}}
            onClick={() => {
              setBonusesSold(state => !state);
            }}
            leftElement={() => (
              <CheckBox
                onChange={() => {
                  setBonusesSold(state => !state);
                }}
                style={{padding: 10}}
                checked={bonusesSold}
              />
            )}
            title={'Подарочные сертификаты и бонусные карты'}
            subtitle={'При продаже сертификатов и бонусных карт'}
          />
          <TableViewRow
            style={{cursor: 'pointer', alignSelf: 'flex-start'}}
            styleTitle={{cursor: 'pointer'}}
            styleSubTitle={{cursor: 'pointer'}}
            onClick={() => {
              setProductsSold(state => !state);
            }}
            leftElement={() => (
              <CheckBox
                onChange={() => {
                  setProductsSold(state => !state);
                }}
                style={{padding: 10}}
                checked={productsSold}
              />
            )}
            title={'Товары'}
            subtitle={'При продаже товаров'}
          />
        </Content>
        <Content gap="24px">
          <Text typography="title18">Отзывы</Text>
          <TableViewRow
            style={{cursor: 'pointer', alignSelf: 'flex-start'}}
            styleTitle={{cursor: 'pointer'}}
            styleSubTitle={{cursor: 'pointer'}}
            onClick={() => {
              setNewReview(state => !state);
            }}
            leftElement={() => (
              <CheckBox
                onChange={() => {
                  setNewReview(state => !state);
                }}
                style={{padding: 10}}
                checked={newReview}
              />
            )}
            title={'Отзывы'}
            subtitle={'Когда клиенты оставляют отзывы'}
          />
          <TableViewRow
            style={{cursor: 'pointer', alignSelf: 'flex-start'}}
            styleTitle={{cursor: 'pointer'}}
            styleSubTitle={{cursor: 'pointer'}}
            onClick={() => {
              setReviewMail(state => !state);
            }}
            leftElement={() => (
              <CheckBox
                onChange={() => {
                  setReviewMail(state => !state);
                }}
                style={{padding: 10}}
                checked={reviewMail}
              />
            )}
            title={'Уведомлять по электронной почте'}
            subtitle={'Получайте уведомления на свою электронную почту'}
          />
        </Content>
        <Content gap="24px">
          <Text typography="title18">Запас товаров</Text>
          <TableViewRow
            style={{cursor: 'pointer', alignSelf: 'flex-start'}}
            styleTitle={{cursor: 'pointer'}}
            styleSubTitle={{cursor: 'pointer'}}
            onClick={() => {
              setProductsLow(state => !state);
            }}
            leftElement={() => (
              <CheckBox
                style={{padding: 10}}
                checked={productsLow}
                onChange={() => {
                  setProductsLow(state => !state);
                }}
              />
            )}
            title={'Оповещения о низких запасах'}
            subtitle={'Когда конкретный товар достигает низкий запас'}
          />
          <TableViewRow
            style={{cursor: 'pointer', alignSelf: 'flex-start'}}
            styleTitle={{cursor: 'pointer'}}
            styleSubTitle={{cursor: 'pointer'}}
            onClick={() => {
              setProductsMail(state => !state);
            }}
            leftElement={() => (
              <CheckBox
                onChange={() => {
                  setProductsMail(state => !state);
                }}
                style={{padding: 10}}
                checked={productsMail}
              />
            )}
            title={'Уведомлять по электронной почте'}
            subtitle={'Получайте сводку о запасе товаров с низким запасом'}
          />
        </Content>
        <Button size="large" onClick={update} loading={udpateLoading}>
          Сохранить
        </Button>
      </Flex>
    </Wrapper>
  );
};
