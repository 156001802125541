import React from 'react';
import {useColorScheme} from 'shared/lib/hooks';
import {EmptyContainer} from 'shared/ui/EmptyContainer';

export const EmptyAddDate = ({size = 400}: {size?: number}) => {
  const colorScheme = useColorScheme();
  return (
    <EmptyContainer size={size}>
      {colorScheme === 'light' ? (
        <svg viewBox="0 0 400 400" fill="none">
          <path
            d="M185.143 346.869C259.781 346.869 320.286 285.983 320.286 210.877C320.286 135.77 259.781 74.8843 185.143 74.8843C110.506 74.8843 50 135.77 50 210.877C50 285.983 110.506 346.869 185.143 346.869Z"
            fill="#EAEEF9"
          />
          <path
            d="M342.373 175.011C346.586 171.137 346.88 164.561 343.03 160.321C339.18 156.082 332.645 155.786 328.432 159.66C324.219 163.534 323.925 170.111 327.775 174.35C331.624 178.589 338.16 178.885 342.373 175.011Z"
            fill="#EAEEF9"
          />
          <path
            d="M107.02 86.604C110.59 85.4362 112.544 81.577 111.383 77.9841C110.223 74.3912 106.388 72.4253 102.817 73.5931C99.2469 74.7608 97.2933 78.6201 98.4538 82.213C99.6143 85.8059 103.449 87.7718 107.02 86.604Z"
            fill="#EAEEF9"
          />
          <path
            d="M121.857 64.2852C124.285 63.4911 125.614 60.8668 124.825 58.4237C124.035 55.9805 121.428 54.6437 119 55.4378C116.572 56.2319 115.243 58.8562 116.032 61.2993C116.821 63.7425 119.429 65.0793 121.857 64.2852Z"
            fill="#EAEEF9"
          />
          <path
            d="M343.135 128.783C344.909 127.151 345.032 124.382 343.412 122.597C341.791 120.812 339.039 120.688 337.265 122.319C335.491 123.95 335.367 126.719 336.988 128.504C338.609 130.289 341.361 130.414 343.135 128.783Z"
            fill="#EAEEF9"
          />
          <path
            d="M233.376 64.8C234.596 63.6781 234.681 61.7734 233.567 60.5458C232.452 59.3181 230.559 59.2323 229.339 60.3542C228.119 61.4761 228.034 63.3808 229.149 64.6085C230.263 65.8362 232.156 65.9219 233.376 64.8Z"
            fill="#EAEEF9"
          />
          <path
            d="M264.545 100.606H203.092H101.666C86.711 101.7 74.7466 114.561 74.7466 129.884V197.196H293.912V129.884C293.64 113.74 280.588 100.606 264.545 100.606ZM127.771 125.232C124.236 125.232 121.516 122.496 121.516 118.939C121.516 115.382 124.236 112.645 127.771 112.645C131.305 112.645 134.025 115.382 134.025 118.939C133.753 122.222 131.034 125.232 127.771 125.232ZM238.713 125.232C235.178 125.232 232.459 122.496 232.459 118.939C232.459 115.382 235.178 112.645 238.713 112.645C242.248 112.645 244.967 115.382 244.967 118.939C244.695 122.222 241.976 125.232 238.713 125.232Z"
            fill="#CED4E2"
          />
          <g filter="url(#filter0_d_18292_945)">
            <path
              d="M74.7466 200.753V291.871C74.7466 308.015 87.7986 321.149 103.842 321.149H264.545C280.588 321.149 293.64 308.015 293.64 291.871V200.753H74.7466Z"
              fill="white"
            />
          </g>
          <path
            d="M74.7454 208.962L64.1407 153.416C61.6934 140.829 74.7455 130.157 93.2359 130.157H278.14C296.63 130.157 309.41 140.829 306.419 153.416L293.911 208.962"
            fill="#F1F3F9"
          />
          <path
            d="M163.39 268.612C145.444 268.612 132.12 257.94 131.848 243.165V241.523H139.733V243.165C140.005 253.289 150.338 261.224 163.39 261.224C176.986 261.224 187.047 253.015 187.047 241.523C187.047 230.03 177.802 222.916 162.031 222.916H150.338V215.255H162.031C174.539 215.255 183.24 208.14 183.24 197.743C183.24 187.892 174.539 180.504 162.574 180.504C151.426 180.504 142.724 187.618 141.909 197.469V198.837H134.023V197.195C134.839 182.967 146.803 172.569 162.846 172.569C179.161 172.569 191.398 182.967 191.398 196.922C191.398 206.499 186.231 214.434 177.53 217.991C188.678 221.001 195.476 229.483 195.476 240.702C195.204 256.846 181.609 268.612 163.39 268.612Z"
            fill="#AAB2C5"
          />
          <path
            d="M226.747 267.244V184.062C224.027 185.703 220.22 188.166 216.686 190.629L216.142 190.902C211.791 193.639 207.44 196.648 204.721 198.29L202.274 199.932V190.629L203.09 190.081C205.265 188.713 208.528 186.524 212.063 184.335C217.501 180.778 223.755 176.674 227.562 174.211L227.834 173.937H234.632V266.971H226.747V267.244Z"
            fill="#AAB2C5"
          />
          <path
            d="M347.428 298.451C346.39 302.863 344.833 307.535 343.016 311.428C338.085 321.031 330.298 328.558 320.695 333.489C310.833 338.42 299.154 340.496 287.474 337.901C259.963 332.191 242.314 305.199 248.024 277.688C253.734 250.176 280.467 232.268 307.978 238.237C317.84 240.314 326.405 245.245 333.672 251.993C345.871 264.192 351.062 281.84 347.428 298.451Z"
            fill="url(#paint0_linear_18292_945)"
          />
          <path
            d="M313.949 283.656H302.269V271.977C302.269 269.641 300.453 267.565 297.857 267.565C295.521 267.565 293.445 269.381 293.445 271.977V283.656H281.766C279.43 283.656 277.354 285.473 277.354 288.068C277.354 290.664 279.17 292.481 281.766 292.481H293.445V304.16C293.445 306.496 295.262 308.572 297.857 308.572C300.193 308.572 302.269 306.755 302.269 304.16V292.481H313.949C316.285 292.481 318.361 290.664 318.361 288.068C318.361 285.473 316.285 283.656 313.949 283.656Z"
            fill="white"
          />
          <defs>
            <filter
              id="filter0_d_18292_945"
              x="52.7466"
              y="189.753"
              width="262.894"
              height="164.396"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_18292_945"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_18292_945"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_18292_945"
              x1="246.905"
              y1="288.068"
              x2="348.585"
              y2="288.068"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#B0BACC" />
              <stop offset="1" stopColor="#969EAE" />
            </linearGradient>
          </defs>
        </svg>
      ) : (
        <svg viewBox="0 0 400 400" fill="none">
          <path
            d="M185.143 346.869C259.781 346.869 320.286 285.983 320.286 210.877C320.286 135.77 259.781 74.8843 185.143 74.8843C110.506 74.8843 50 135.77 50 210.877C50 285.983 110.506 346.869 185.143 346.869Z"
            fill="#2A2E37"
          />
          <path
            d="M342.373 175.011C346.586 171.137 346.88 164.56 343.03 160.321C339.18 156.082 332.645 155.786 328.432 159.66C324.219 163.534 323.925 170.111 327.775 174.35C331.624 178.589 338.16 178.885 342.373 175.011Z"
            fill="#2A2E37"
          />
          <path
            d="M107.02 86.604C110.59 85.4362 112.544 81.577 111.383 77.9841C110.223 74.3912 106.388 72.4253 102.817 73.5931C99.2469 74.7608 97.2933 78.6201 98.4538 82.213C99.6143 85.8059 103.449 87.7718 107.02 86.604Z"
            fill="#2A2E37"
          />
          <path
            d="M121.857 64.2851C124.285 63.491 125.614 60.8667 124.825 58.4236C124.035 55.9804 121.428 54.6436 119 55.4377C116.572 56.2318 115.243 58.8561 116.032 61.2992C116.821 63.7424 119.429 65.0792 121.857 64.2851Z"
            fill="#2A2E37"
          />
          <path
            d="M343.135 128.783C344.909 127.151 345.032 124.382 343.412 122.597C341.791 120.812 339.039 120.688 337.265 122.319C335.491 123.95 335.367 126.719 336.988 128.504C338.609 130.289 341.361 130.414 343.135 128.783Z"
            fill="#2A2E37"
          />
          <path
            d="M233.376 64.8002C234.596 63.6783 234.681 61.7736 233.567 60.5459C232.452 59.3182 230.559 59.2325 229.339 60.3544C228.119 61.4763 228.034 63.381 229.149 64.6086C230.263 65.8363 232.156 65.9221 233.376 64.8002Z"
            fill="#2A2E37"
          />
          <path
            d="M264.545 100.606H203.092H101.666C86.711 101.7 74.7466 114.561 74.7466 129.884V197.196H293.912V129.884C293.64 113.74 280.588 100.606 264.545 100.606ZM127.771 125.232C124.236 125.232 121.516 122.496 121.516 118.939C121.516 115.382 124.236 112.645 127.771 112.645C131.305 112.645 134.025 115.382 134.025 118.939C133.753 122.222 131.034 125.232 127.771 125.232ZM238.713 125.232C235.178 125.232 232.459 122.496 232.459 118.939C232.459 115.382 235.178 112.645 238.713 112.645C242.248 112.645 244.967 115.382 244.967 118.939C244.695 122.222 241.976 125.232 238.713 125.232Z"
            fill="#414656"
          />
          <g filter="url(#filter0_d_18292_924)">
            <path
              d="M74.7466 200.753V291.871C74.7466 308.015 87.7986 321.149 103.842 321.149H264.545C280.588 321.149 293.64 308.015 293.64 291.871V200.753H74.7466Z"
              fill="#5C6479"
            />
          </g>
          <path
            d="M74.7454 208.962L64.1407 153.416C61.6934 140.829 74.7455 130.157 93.2359 130.157H278.14C296.63 130.157 309.41 140.829 306.419 153.416L293.911 208.962"
            fill="#4F576B"
          />
          <path
            d="M163.39 268.612C145.444 268.612 132.12 257.94 131.848 243.165V241.523H139.733V243.165C140.005 253.289 150.338 261.224 163.39 261.224C176.986 261.224 187.047 253.015 187.047 241.523C187.047 230.03 177.802 222.916 162.031 222.916H150.338V215.255H162.031C174.539 215.255 183.24 208.14 183.24 197.743C183.24 187.892 174.539 180.504 162.574 180.504C151.426 180.504 142.724 187.618 141.909 197.469V198.837H134.023V197.195C134.839 182.967 146.803 172.569 162.846 172.569C179.161 172.569 191.398 182.967 191.398 196.922C191.398 206.499 186.231 214.434 177.53 217.991C188.678 221.001 195.476 229.483 195.476 240.702C195.204 256.846 181.609 268.612 163.39 268.612Z"
            fill="#2A2E37"
          />
          <path
            d="M226.747 267.244V184.061C224.027 185.703 220.22 188.166 216.686 190.629L216.142 190.902C211.791 193.638 207.44 196.648 204.721 198.29L202.274 199.932V190.629L203.09 190.081C205.265 188.713 208.528 186.524 212.063 184.335C217.501 180.778 223.755 176.674 227.562 174.211L227.834 173.937H234.632V266.97H226.747V267.244Z"
            fill="#2A2E37"
          />
          <g filter="url(#filter1_d_18292_924)">
            <path
              d="M347.428 298.451C346.39 302.863 344.833 307.535 343.016 311.428C338.085 321.031 330.298 328.558 320.695 333.489C310.833 338.42 299.154 340.496 287.474 337.901C259.963 332.191 242.314 305.199 248.024 277.688C253.734 250.176 280.467 232.268 307.978 238.237C317.84 240.314 326.405 245.245 333.672 251.993C345.871 264.192 351.062 281.84 347.428 298.451Z"
              fill="url(#paint0_linear_18292_924)"
            />
          </g>
          <path
            d="M313.949 283.656H303.269C302.717 283.656 302.269 283.209 302.269 282.656V271.977C302.269 269.641 300.453 267.565 297.857 267.565C295.521 267.565 293.445 269.381 293.445 271.977V282.656C293.445 283.209 292.997 283.656 292.445 283.656H281.766C279.43 283.656 277.354 285.473 277.354 288.068C277.354 290.664 279.17 292.481 281.766 292.481H292.445C292.997 292.481 293.445 292.928 293.445 293.481V304.16C293.445 306.496 295.262 308.572 297.857 308.572C300.193 308.572 302.269 306.755 302.269 304.16V293.481C302.269 292.928 302.717 292.481 303.269 292.481H313.949C316.285 292.481 318.361 290.664 318.361 288.068C318.361 285.473 316.285 283.656 313.949 283.656Z"
            fill="#474E61"
          />
          <defs>
            <filter
              id="filter0_d_18292_924"
              x="52.7466"
              y="189.753"
              width="262.894"
              height="164.396"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_18292_924"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_18292_924"
                result="shape"
              />
            </filter>
            <filter
              id="filter1_d_18292_924"
              x="242.951"
              y="237.089"
              width="109.615"
              height="109.936"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_18292_924"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_18292_924"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_18292_924"
              x1="270.26"
              y1="263.847"
              x2="314.179"
              y2="337.967"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#E3EAF7" />
              <stop offset="1" stopColor="#ADB6C8" />
            </linearGradient>
          </defs>
        </svg>
      )}
    </EmptyContainer>
  );
};
