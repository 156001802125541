import React from 'react';
import {Text} from 'shared/ui/Text';
import {Grow} from 'shared/ui/Grow';
import {EmptyBox} from 'shared/illustration/EmptyBox';
export const EmptyState = () => {
  return (
    <Grow gap={16}>
      <EmptyBox size={300} />
      <Text typography="title20" align="center">
        Ресурсов нет
      </Text>
      <Text typography="text18" color="textTertiary" align="center">
        Нажмите на кнопку “Добавить ресурс” и начните работу
      </Text>
    </Grow>
  );
};
