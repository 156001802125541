import React from 'react';
import {Content} from 'shared/ui/Content';
import {Skeleton} from 'shared/ui/Skeleton';

export const BonusSkeleton = () => {
  return (
    <Content style={{margin: '0 24px 24px 24px'}}>
      <Skeleton height={180}>
        <circle r={12} cx={12 + 18} cy={12 + 14} />
        <rect
          width={64}
          height={12.25}
          x={76}
          y={(52 - 12.25) / 2}
          rx={6}
          ry={6}
        />
        <rect
          width={49}
          height={12.25}
          x={(375 * 100) / 1614 + '%'}
          y={(52 - 12.25) / 2}
          rx={6}
          ry={6}
        />
        <rect
          width={90}
          height={12.25}
          x={(513 * 100) / 1614 + '%'}
          y={(52 - 12.25) / 2}
          rx={6}
          ry={6}
        />
        <rect
          width={68}
          height={12.25}
          x={(704 * 100) / 1614 + '%'}
          y={(52 - 12.25) / 2}
          rx={6}
          ry={6}
        />
        <rect
          width={190}
          height={12.25}
          x={(898 * 100) / 1614 + '%'}
          y={(52 - 12.25) / 2}
          rx={6}
          ry={6}
        />
        <rect
          width={141}
          height={12.25}
          x={(1153 * 100) / 1614 + '%'}
          y={(52 - 12.25) / 2}
          rx={6}
          ry={6}
        />
        <circle r={12} cx={30} cy={90} />
        <rect
          width={162}
          height={12.25}
          y={60 + 47.75 / 2}
          x={76}
          rx={6}
          ry={6}
        />
        <rect
          width={32}
          height={12.25}
          y={60 + 47.75 / 2}
          x={(375 * 100) / 1614 + '%'}
          rx={6}
          ry={6}
        />
        <rect
          width={74}
          height={12.25}
          x={(513 * 100) / 1614 + '%'}
          y={60 + 47.75 / 2}
          rx={6}
          ry={6}
        />
        <rect
          width={86}
          height={12.25}
          x={(704 * 100) / 1614 + '%'}
          y={60 + 47.75 / 2}
          rx={6}
          ry={6}
        />
        <rect
          width={25}
          height={12.25}
          x={(898 * 100) / 1614 + '%'}
          y={60 + 47.75 / 2}
          rx={6}
          ry={6}
        />
        <rect
          width={149}
          height={12.25}
          x={(1153 * 100) / 1614 + '%'}
          y={60 + 47.75 / 2}
          rx={6}
          ry={6}
        />
        <circle r={12} cx={30} cy={150} />
        <rect
          width={32}
          height={12.25}
          y={120 + 47.75 / 2}
          x={76}
          rx={6}
          ry={6}
        />
        <rect
          width={37}
          height={12.25}
          y={120 + 47.75 / 2}
          x={(375 * 100) / 1614 + '%'}
          rx={6}
          ry={6}
        />
        <rect
          width={25}
          height={12.25}
          x={(513 * 100) / 1614 + '%'}
          y={60 + 60 + 47.75 / 2}
          rx={6}
          ry={6}
        />
        <rect
          width={111}
          height={12.25}
          x={(704 * 100) / 1614 + '%'}
          y={60 + 60 + 47.75 / 2}
          rx={6}
          ry={6}
        />
        <rect
          width={25}
          height={12.25}
          x={(898 * 100) / 1614 + '%'}
          y={60 + 60 + 47.75 / 2}
          rx={6}
          ry={6}
        />
        <rect
          width={146}
          height={12.25}
          x={(1153 * 100) / 1614 + '%'}
          y={60 + 60 + 47.75 / 2}
          rx={6}
          ry={6}
        />
      </Skeleton>
    </Content>
  );
};
