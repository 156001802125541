import React from 'react';
import {device} from 'shared/device';
import {Skeleton} from 'shared/ui/Skeleton';
import styled from 'styled-components';

const COLUMN = 7;

const Container = styled.div`
  display: flex;
  height: 650px;
  flex-direction: row;
  padding-top: 24px;
  background-color: ${props => props.theme.bgPrimary};
  @media ${device.desktop} {
    padding: 24px;
    border-radius: 24px;
  }
`;

const RectName = styled.rect<{index: number}>`
  display: none;
  @media ${device.desktop} {
    display: flex;
    y: calc(20px + ${props => props.index * 86}px);
  }
`;

const columns = Array.from(Array(COLUMN).keys());

export const ServiceSkeleton = () => {
  return (
    <Container>
      <Skeleton>
        {columns.map((columnItem, index) => (
          <React.Fragment key={'column_' + columnItem}>
            <circle r={18} cx={37} cy={33 + 86 * index} />
            <RectName
              index={columnItem}
              width={510}
              height={24}
              rx={16}
              x={76}
            />
            <RectName
              index={columnItem}
              width={232}
              height={24}
              rx={16}
              x={640}
            />
            <RectName
              index={columnItem}
              width={232}
              height={24}
              rx={16}
              x={922}
            />
            <RectName
              index={columnItem}
              width={322}
              height={24}
              rx={16}
              x={1204}
            />
          </React.Fragment>
        ))}
      </Skeleton>
    </Container>
  );
};
