import React from 'react';

export const History = ({
  size = 24,
  color = '#6765F8',
}: {
  size?: number;
  color?: string;
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.9731 8.08789V12.0879L13.9782 14.0879M3 11.088C3.22467 8.88801 4.25349 6.84757 5.89017 5.35592C7.52685 3.86428 9.65682 3.02584 11.8737 3.00059C14.0906 2.97533 16.2392 3.76502 17.9096 5.21899C19.58 6.67296 20.6553 8.68943 20.9303 10.8837C21.2053 13.0779 20.6607 15.2964 19.4005 17.1157C18.1404 18.9351 16.2527 20.228 14.0978 20.7478C11.9429 21.2676 9.67157 20.9779 7.71713 19.934C5.76269 18.8901 4.26197 17.165 3.50129 15.0879M3 20.0879V15.0879H8.0129"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
