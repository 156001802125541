import React from 'react';
import {Navigate, RouteObject} from 'react-router-dom';
import {SignIn} from 'pages/auth/SignIn';
import {ConfirmPhone} from 'pages/auth/ConfirmPhone';
import {SignInPassword} from 'pages/auth/SignInPassword';
import {SignUp} from 'pages/auth/SingUp';
import {RestorePassword} from 'pages/auth/RestorePassword';
import {ConfirmEmail} from 'pages/auth/ConfirmEmail';
import {NewPassword} from 'pages/auth/NewPassword';
import {MyCompanies} from 'pages/create/MyCompanies';
import {AuthRoot} from './AuthRoot';
import {CreateCompany} from 'pages/create/CreateCompany';
import {CreateRoot} from './CreateRoot';
import {Schedule} from 'pages/schedule/Schedule';
import {Root} from './Root';
import {MyTariff} from 'pages/tariff/MyTariff';
import {Wallet} from 'shared/icons/Wallet';
import {PaymentHistory} from 'pages/tariff/PaymentsHistory';
import {TariffPlans} from 'pages/tariff/TariffPlans';
import {ProfileRoot} from './ProfileRoot';
import {Profile} from 'pages/profile/Profile';
import {ProfileSet} from 'pages/profile/ProfileSet';
import {PhoneChange} from 'pages/profile/PhoneChange';
import {PasswordSet} from 'pages/profile/PasswordSet';
import {PasswordChange} from 'pages/profile/PasswordChange';
import {EmailCode} from 'pages/profile/EmailCode';
import {PhoneChangeCode} from 'pages/profile/PhoneChangeCode';
import {Bookings} from 'pages/booking/Bookings';
import {Document} from 'shared/icons/Document';
import {All} from 'shared/icons/All';
import {MoreRoot} from './MoreRoot';
import {Customers} from 'pages/customers/Customers';
import {UserTriple} from 'shared/icons/UserTriple';
import {ModerationRoot} from './ModerationRoot';
import {NewModeration} from 'pages/moderation/NewModeration';
import {StatusOfModeration} from 'pages/moderation/StatusOfModeration';
import {CustomerProfileRoot} from './CustomerRoot';
import {Services} from 'pages/services/Services';
import {AddService} from 'pages/services/AddService';
import {AddCustomer} from 'pages/customers/AddCustomer';
import {CustomerEdit} from 'pages/customers/CustomerEdit';
import {CustomerAbout} from 'pages/customers/CustomerAbout';
import {CustomerHistory} from 'pages/customers/CustomerHistory';
import {CustomerBonuses} from 'pages/customers/CustomerBonuses';
import {CustomerCertificates} from 'pages/customers/CustomerCertificates';
import {CreateBooking} from 'pages/booking/CreateBooking';
import {CreateSchedule} from 'pages/schedule/CreateSchedule';
import {Reviews} from 'pages/reviews/Rivews';
import {Coupon} from 'shared/icons/Coupon';
import {Employees} from 'pages/employees/Employees';
import {AddEmployee} from 'pages/employees/AddEmployee';
import {EditEmployee} from 'pages/employees/EditEmployee';
import {EmployeeRoot} from './EmployeeRoot';
import {EmployeeAbout} from 'pages/employees/EmployeeAbout';
import {EmployeeSchedule} from 'pages/employees/EmployeeSchedule';
import {EmployeeBookings} from 'pages/employees/EmployeeBookings';
import {EmployeeServices} from 'pages/employees/EmployeeServices';
import {AboutBookingRoot} from './AboutBookingRoot';
import {PaymentBooking} from 'pages/booking/PaymentBooking';
import {PhotosBooking} from 'pages/booking/PhotosBooking';
import {DetailBooking} from 'pages/booking/DetailBooking';
import {EditBooking} from 'pages/booking/EditBooking';
import {NotificationRoot} from './NotificationRoot';
import {NotificationBooking} from 'pages/notification/NotificationBooking';
import {NotificationRest} from 'pages/notification/NotificationRest';
import {OrderEmployees} from 'pages/employees/OrderEmployees';
import {BonusCards} from 'pages/bonuses/BonusCards';
import {CreateBonus} from 'pages/bonuses/CreateBonus';
import {Tutorial} from 'pages/tutorial/Tutorial';
import {CatalogApps} from 'pages/catalogApps/CatalogApps';
import {Layers} from 'shared/icons/Layers';
import {WidgetApp} from 'pages/catalogApps/WidgetApp';
import {QrCodeApp} from 'pages/catalogApps/QrCodApp';
import {IssueBonus} from 'pages/bonuses/IssueBonus';
import {Setting} from 'shared/icons/Setting';
import {EditBonus} from 'pages/bonuses/EditBonus';
import {Certificates} from 'pages/certificates/Certificates';
import {PlatformApp} from 'pages/catalogApps/PlatformApp';
import {WebsiteApp} from 'pages/catalogApps/WebsiteApp';
import {CsmApp} from 'pages/catalogApps/CsmApp';
import {OtherApp} from 'pages/catalogApps/OtherApp';
import {CreateCertificate} from 'pages/certificates/CreateCertificate';
import {EditCertificate} from 'pages/certificates/EditCertificate';
import {IssueCertificate} from 'pages/certificates/IssueCertificate';
import {Permissions} from 'pages/setting/Permissions';
import {Chart} from 'shared/icons/Chart';
import {Statistics} from 'pages/statistics/Statistics';
import {SalesOnServices} from 'pages/statistics/SalesOnServices';
import {SalesOnCustomers} from 'pages/statistics/SalesOnCustomers';
import {SalesOnEmployees} from 'pages/statistics/SalesOnEmployees';
import {SalesOnDate} from 'pages/statistics/SalesOnDate';
import {SalesOnJournal} from 'pages/statistics/SalesOnJournal';
import {SettingRoot} from 'pages/setting/Setting';
import {CompanyRoot} from './CompanyRoot';
import {CompanyEdit} from 'pages/company/CompanyEdit';
import {CompanyContact} from 'pages/company/CompanyContact';
import {CompanyAdvantage} from 'pages/company/CompanyAdvantage';
import {CompanySchedule} from 'pages/company/CompanySchedule';
import {LoyaltyDiscounts} from 'pages/statistics/LoyaltyDiscounts';
import {LoyaltyBonusCards} from 'pages/statistics/LoyaltyBonusCards';
import {LoyaltyUsesBonusCards} from 'pages/statistics/LoyaltyUsesBonusCards';
import {LoyaltyCertificates} from 'pages/statistics/LoyaltyCertificates';
import {LoyaltyUsesCertificates} from 'pages/statistics/LoyaltyUsesCertificates';
import {LoyaltySalesCertificates} from 'pages/statistics/LoyaltySalesCertificates';
import {VisitsCustomers} from 'pages/statistics/VisitsCustomers';
import {VisitSummary} from 'pages/statistics/VisitSummary';
import {VisitsCancellation} from 'pages/statistics/VisitsCancellation';
import {FinancePaymentList} from 'pages/statistics/FinancePaymentList';
import {CustomerStatistics} from 'pages/statistics/CustomerStatistics';
import {FinancePaymentSummary} from 'pages/statistics/FinancePaymentSummary';
import {JournalSetting} from 'pages/setting/JournalSetting';
import {PaymentTariff} from 'pages/tariff/PaymentTariff';
import {AboutApp} from 'pages/setting/AboutApp';
import {PushNotificationSetting} from 'pages/profile/PushNotificationSetting';
import {ChangeTariff} from 'pages/tariff/ChangeTariff';
import {ReviewFiltersMobile} from 'pages/reviews/ReviewsAdaptive/Filters/index';
import {ReviewMobile} from 'pages/reviews/ReviewsAdaptive/Review';
import {NotFoundPage} from 'pages/notfound/NotFound';
import {UnavailableCompany} from 'pages/create/UnavailableCompany';
import {InviteCompany} from 'pages/create/InviteCompany';
import {CreateCompanySchedule} from 'pages/create/CreateCompanyShcedule';
import {Discounts} from 'pages/discounts/Discounts';
import {AddDiscount} from 'pages/discounts/AddDiscount';
import {EditDiscount} from 'pages/discounts/EditDiscount';
import {Loyalty} from 'pages/loyalty/Loyalty';
import {TariffAndPayment} from 'pages/tariff/TariffAndPayment';
import {ProfileMobileRoot} from 'pages/profile/ProfileMobileRoot';
import {UnavailableTariffPlans} from 'pages/create/UnavailableTariffPlans';
import {NotificationSettings} from 'pages/setting/NotificationSettings';
import {EditService} from 'pages/services/EditService';
import PrivateRouter from './PrivateRouter';
import {Support as IconSupport} from 'shared/icons/Support';
import {Res, useEmployeePermission} from 'shared/lib/hooks';
import {Support} from 'pages/support/Support';
import {NoPermission} from 'pages/noPermission/NoPermission';
import {useAppSelector} from 'shared/store';
import {PERMISSIONS} from 'entities/permission';
import {Education} from 'shared/icons/Education';
import {client} from 'shared/config/apollo';
import {ProductsMain} from 'pages/products/Products';
import {CreateProduct} from 'pages/products/createProduct';
import {ProductsRoot} from './ProductsRoot';
import {DetailProduct} from 'pages/products/DetailProduct';
// import {HistorySales} from 'pages/products/HistorySales';
import {HistoryReserve} from 'pages/products/HistoryReserve';
import {EditProduct} from 'pages/products/EditProduct';
import {TagsCustomers} from 'pages/customers/TagsCustomers';
import {Salaries} from 'pages/salary/Salaries';
import {Bookmark} from 'shared/icons/Bookmark';
import {Resources} from 'pages/resources';
import {AddSalaryRule} from 'pages/salary/AddSalaryRule';
import {Analytics} from 'pages/statistics/Analytics';
import {BookingSource} from 'pages/bookingSource/BookingSource';
import {CreateBookingSource} from 'pages/bookingSource/CreateBookingSource';
import {MessageTemplates} from 'pages/messageTemplates/MessageTemplates';
import {CreateMessageTemplates} from 'pages/messageTemplates/CreateMessageTemplates';
import {DomenChange} from 'pages/setting/DomenChange';
import {EditBookingSource} from 'pages/bookingSource/EditBookingSource';
import {EditMessageTemplates} from 'pages/messageTemplates/EditMessageTemplates';
import {WebHooks} from 'pages/webhooks/Webhooks';
import {EmployeePermissions} from 'pages/employees/EmployeePermissions';
import {AddWebhook} from 'pages/webhooks/AddWebhook';
import {EmployeeSalaryRule} from 'pages/employees/EmployeeSalaryRule';
import {EditSalaryRule} from 'pages/salary/EditSalaryRule';
import {EditWebhook} from 'pages/webhooks/EditWebhook';
import {Referral} from 'pages/profile/Referral';
import {Messages} from 'pages/messages';
import {MessagesBooking} from 'pages/booking/MessagesBooking';
import {ChatApp} from 'pages/catalogApps/ChatApp';
import {Plain} from 'shared/icons/Plain';
import {UserDouble} from 'shared/icons/UserDouble';
import {NeuralChat} from 'pages/neural/NeuralChat';
type PERMISSION_NAMES = keyof Res;

const ProtectedRoute = ({
  permissionName,
  children,
}: {
  permissionName?: PERMISSION_NAMES;
  children: React.JSX.Element;
}) => {
  const companyId = useAppSelector(state => state.company.data?.id);
  const userData = client.readQuery({
    query: PERMISSIONS,
    variables: {companyId: companyId!},
  });
  const permissions = useEmployeePermission(userData);
  if (permissionName && !permissions[permissionName]) {
    return <Navigate to={'/no-permission'} replace />;
  }
  return children;
};

const createStack = (
  routes: {path: string; element: React.JSX.Element; title: string}[],
): RouteObject[] => {
  return routes.map(({path, element, title}, index) => ({
    path,
    element,
    handle: {title, canGoBack: index > 0},
  }));
};

const permissionStack = (
  routes: {
    path: string;
    element: React.JSX.Element;
    title: string;
    permission?: PERMISSION_NAMES;
  }[],
): RouteObject[] => {
  return routes.map(({path, element, title, permission}, index) => ({
    path,
    element: (
      <ProtectedRoute permissionName={permission}>{element}</ProtectedRoute>
    ),
    handle: {title, canGoBack: index > 0},
  }));
};

type SimpleTab = {
  place: 'tabbar' | 'sidebar' | 'all' | 'sidebar_bottom' | 'unplaced';
  type: 'simple';
  path: string;
  element: React.JSX.Element;
  title: string;
  icon?: React.JSX.Element;
  username?: boolean;
  permission?: PERMISSION_NAMES;
};

type SubTab = {
  path: string;
  element: React.JSX.Element;
  title: string;
  username?: boolean;
  permission?: PERMISSION_NAMES;
};

type GroupTab = {
  type: 'group';
  place?: 'tabbar' | 'sidebar' | 'all' | 'sidebar_bottom' | 'unplaced';
  path?: string;
  subtabs: SubTab[];
  title: string;
  icon: React.JSX.Element;
  intercom?: boolean;
  username?: boolean;
  permission?: PERMISSION_NAMES;
};

type Tab = SimpleTab | GroupTab;

export const tabs: Tab[] = [
  {
    place: 'all',
    type: 'simple',
    path: ':username/bookings',
    element: <Bookings />,
    title: 'Записи',
    icon: <Document />,
    username: true,
  },
  {
    type: 'group',
    path: ':username/customers',
    subtabs: [
      {
        path: ':username/customers',
        element: (
          <ProtectedRoute permissionName="customers_info_permission">
            <Customers />
          </ProtectedRoute>
        ),
        title: 'Клиенты',
        username: true,
      },
      {
        path: ':username/tags',
        element: (
          <ProtectedRoute permissionName="customers_info_permission">
            <TagsCustomers />
          </ProtectedRoute>
        ),
        title: 'Теги клиентов',
        username: true,
      },
    ],
    title: 'Клиенты',
    icon: <UserTriple color="currentColor" />,
    username: true,
    permission: 'customers_info_permission',
  },
  {
    type: 'simple',
    place: 'sidebar',
    title: 'Рассылки',
    path: ':username/messages',
    element: (
      <ProtectedRoute permissionName="messages_permission">
        <Messages />
      </ProtectedRoute>
    ),
    icon: <Plain color="currentColor" />,
    username: true,
    permission: 'messages_permission',
  },
  {
    type: 'simple',
    place: 'sidebar',
    title: 'Нейросеть',
    icon: <UserTriple />,
    path: ':username/neuralChat',
    element: <NeuralChat />,
    username: true,
  },
  {
    type: 'group',
    path: ':username/employees',
    subtabs: process.env.REACT_APP_DOMAIN?.includes('dev')
      ? [
          {
            path: ':username/employees',
            element: <Employees />,
            title: 'Сотрудники',
            username: true,
          },
          {
            path: ':username/schedule',
            element: (
              <ProtectedRoute permissionName="work_schedule_info_permission">
                <Schedule />
              </ProtectedRoute>
            ),
            title: 'График работы',
            username: true,
            permission: 'work_schedule_info_permission',
          },
          {
            path: ':username/salary',
            element: (
              <ProtectedRoute permissionName="salaries_permission">
                <Salaries />
              </ProtectedRoute>
            ),
            title: 'Зарплаты',
            username: true,
            permission: 'salaries_permission',
          },
        ]
      : [
          {
            path: ':username/employees',
            element: <Employees />,
            title: 'Сотрудники',
            username: true,
          },
          {
            path: ':username/schedule',
            element: (
              <ProtectedRoute permissionName="work_schedule_info_permission">
                <Schedule />
              </ProtectedRoute>
            ),
            title: 'График работы',
            username: true,
            permission: 'work_schedule_info_permission',
          },
        ],
    title: 'Сотрудники',
    icon: <UserDouble color="currentColor" />,
    username: true,
  },
  {
    type: 'group',
    path: ':username/analytics',
    subtabs: [
      {
        path: ':username/analytics',
        element: (
          <ProtectedRoute permissionName="reports_permission">
            <Analytics />
          </ProtectedRoute>
        ),
        title: 'Основные показатели',
        username: true,
      },
      {
        path: ':username/statistics',
        element: (
          <ProtectedRoute permissionName="reports_permission">
            <Statistics />
          </ProtectedRoute>
        ),
        title: 'Отчеты',
        username: true,
      },
    ],
    title: 'Аналитика',
    icon: <Chart color="currentColor" />,
    username: true,
    permission: 'reports_permission',
  },
  {
    type: 'group',
    path: ':username/catalog',
    subtabs: [
      {
        path: ':username/services',
        element: <Services />,
        title: 'Услуги',
        username: true,
        permission: 'view_service_permission',
      },
      {
        path: ':username/products',
        element: (
          <ProtectedRoute permissionName="view_products_permission">
            <ProductsMain />
          </ProtectedRoute>
        ),
        title: 'Товары',
        username: true,
        permission: 'view_products_permission',
      },
      {
        path: ':username/resources',
        element: <Resources />,
        title: 'Ресурсы',
        username: true,
      },
    ],
    title: 'Каталог',
    icon: <Bookmark color="currentColor" />,
    username: true,
  },
  {
    type: 'group',
    path: ':username/loyalty',
    subtabs: [
      {
        path: ':username/bonuses',
        element: (
          <ProtectedRoute permissionName="edit_bonus_card_templates">
            <BonusCards />
          </ProtectedRoute>
        ),
        title: 'Бонусные карты',
        username: true,
        permission: 'edit_bonus_card_templates',
      },
      {
        path: ':username/certificates',
        element: (
          <ProtectedRoute permissionName="edit_certificate_templates">
            <Certificates />
          </ProtectedRoute>
        ),
        title: 'Сертификаты',
        username: true,
        permission: 'edit_certificate_templates',
      },
      {
        path: ':username/discounts',
        element: (
          <ProtectedRoute permissionName="edit_discounts_permission">
            <Discounts />
          </ProtectedRoute>
        ),
        title: 'Акции и скидки',
        username: true,
        permission: 'edit_discounts_permission',
      },
      {
        path: ':username/reviews',
        element: (
          <ProtectedRoute permissionName="reviews_info_permission">
            <Reviews />
          </ProtectedRoute>
        ),
        title: 'Отзывы',
        username: true,
        permission: 'reviews_info_permission',
      },
    ],
    title: 'Лояльность',
    icon: <Coupon color="currentColor" />,
    username: true,
  },
  {
    place: 'sidebar',
    type: 'simple',
    path: ':username/catalog-apps',
    element: (
      <ProtectedRoute permissionName="edit_apps_permission">
        <CatalogApps />
      </ProtectedRoute>
    ),
    title: 'Каталог приложений',
    icon: <Layers color="currentColor" />,
    username: true,
    permission: 'edit_apps_permission',
  },
  {
    type: 'group',
    path: ':username/tariff-and-payment',
    subtabs: [
      {
        path: ':username/my-tariff',
        element: <MyTariff />,
        title: 'Мой тариф',
        username: true,
      },
      {
        path: ':username/payments-history',
        element: <PaymentHistory />,
        title: 'История платежей',
        username: true,
      },
      {
        path: ':username/tariff-plans',
        element: <TariffPlans />,
        title: 'Тарифные планы',
        username: true,
      },
    ],
    title: 'Тарифы и оплата',
    icon: <Wallet color="currentColor" />,
    username: true,
    permission: 'subscription_and_pay_permission',
  },
  {
    place: 'sidebar',
    type: 'simple',
    path: ':username/setting',
    element: <SettingRoot />,
    title: 'Настройки',
    username: true,
    icon: <Setting color="currentColor" />,
  },
  {
    place: 'sidebar_bottom',
    type: 'simple',
    path: ':username/tutorial',
    element: <Tutorial />,
    title: 'Быстрый старт',
    icon: <Education color="currentColor" />,
    username: true,
  },
  {
    place: 'sidebar_bottom',
    type: 'simple',
    path: 'support',
    element: <Support />,
    title: 'Центр помощи',
    icon: <IconSupport color={'currentColor'} />,
  },
  {
    place: 'tabbar',
    type: 'simple',
    path: ':username/more',
    element: <MoreRoot />,
    title: 'Еще',
    icon: <All />,
  },
  {
    path: '/:username/statistics/sales_on_employees',
    element: <SalesOnEmployees />,
    type: 'simple',
    place: 'unplaced',
    title: '',
  },
  {
    path: '/:username/setting/message-samples',
    element: <MessageTemplates />,
    type: 'simple',
    place: 'unplaced',
    title: '',
  },

  {
    path: '/:username/setting/webhooks',
    element: <WebHooks />,
    type: 'simple',
    place: 'unplaced',
    title: '',
  },
  {
    path: '/:username/statistics/sales_on_services',
    element: <SalesOnServices />,
    type: 'simple',
    place: 'unplaced',
    title: '',
  },
  {
    path: '/:username/statistics/sales_on_customers',
    element: <SalesOnCustomers />,
    type: 'simple',
    place: 'unplaced',
    title: '',
  },
  {
    path: '/:username/statistics/sales_on_date',
    element: <SalesOnDate />,
    type: 'simple',
    place: 'unplaced',
    title: '',
  },
  {
    path: '/:username/statistics/sales_on_journal',
    element: <SalesOnJournal />,
    type: 'simple',
    place: 'unplaced',
    title: '',
  },
  {
    path: '/:username/statistics/loyalty_discounts',
    element: <LoyaltyDiscounts />,
    type: 'simple',
    place: 'unplaced',
    title: '',
  },
  {
    path: '/:username/statistics/loyalty_bonus_cards',
    element: <LoyaltyBonusCards />,
    type: 'simple',
    place: 'unplaced',
    title: '',
  },
  {
    path: '/:username/statistics/loyalty_uses_bonus_cards',
    element: <LoyaltyUsesBonusCards />,
    type: 'simple',
    place: 'unplaced',
    title: '',
  },
  {
    path: '/:username/statistics/loyalty_certificates',
    element: <LoyaltyCertificates />,
    type: 'simple',
    place: 'unplaced',
    title: '',
  },
  {
    path: '/:username/statistics/loyalty_uses_certificates',
    element: <LoyaltyUsesCertificates />,
    type: 'simple',
    place: 'unplaced',
    title: '',
  },
  {
    path: '/:username/statistics/loyalty_sales_certificates',
    element: <LoyaltySalesCertificates />,
    type: 'simple',
    place: 'unplaced',
    title: '',
  },
  {
    path: '/:username/statistics/visit_customers',
    element: <VisitsCustomers />,
    type: 'simple',
    place: 'unplaced',
    title: '',
  },
  {
    path: '/:username/statistics/visit_summary',
    element: <VisitSummary />,
    type: 'simple',
    place: 'unplaced',
    title: '',
  },
  {
    path: '/:username/statistics/visit_cancellation',
    element: <VisitsCancellation />,
    type: 'simple',
    place: 'unplaced',
    title: '',
  },
  {
    path: '/:username/statistics/finance_payment_summary',
    element: <FinancePaymentSummary />,
    type: 'simple',
    place: 'unplaced',
    title: '',
  },
  {
    path: '/:username/statistics/finance_payment_list',
    element: <FinancePaymentList />,
    type: 'simple',
    place: 'unplaced',
    title: '',
  },
  {
    path: '/:username/statistics/customer_statistics',
    element: <CustomerStatistics />,
    type: 'simple',
    place: 'unplaced',
    title: '',
  },
  {
    path: '/:username/order-employees',
    element: <OrderEmployees />,
    type: 'simple',
    place: 'unplaced',
    title: '',
  },
];
function simpleTab(tab: SubTab) {
  const {path, element, title} = tab;
  return {
    path,
    element,
    handle: {title},
  };
}
function createTabs(tabs: Tab[]): RouteObject[] {
  return tabs.flatMap(tab => {
    if (tab.type === 'simple') {
      return simpleTab(tab);
    }
    return tab.subtabs.map(tab => {
      return simpleTab(tab);
    });
  });
}

export function publicRouter() {
  return {
    path: '',
    element: <AuthRoot />,
    children: createStack([
      {
        path: '',
        element: <SignIn />,
        title: 'Вход',
      },
      {
        path: 'confirm-phone',
        element: <ConfirmPhone />,
        title: 'Введите код',
      },
      {
        path: 'confirm-email',
        element: <ConfirmEmail />,
        title: 'Введите код подтверждения',
      },
      {
        path: 'password',
        element: <SignInPassword />,
        title: 'Вход',
      },
      {
        path: 'sign-up',
        element: <SignUp />,
        title: 'Регистрация',
      },
      {
        path: 'restore-password',
        element: <RestorePassword />,
        title: 'Восстановление пароля',
      },
      {
        path: 'new-password',
        element: <NewPassword />,
        title: 'Новый пароль',
      },
    ]),
  };
}

export function mainRoutes() {
  return {
    element: <PrivateRouter />,
    children: [
      {
        path: '',
        element: <CreateRoot />,
        children: permissionStack([
          {
            path: 'my-companies',
            element: <MyCompanies />,
            title: 'Мои организации',
          },
          {
            path: 'create-company',
            element: <CreateCompany />,
            title: 'Создание организации',
          },
          {
            path: 'unavailable-company',
            element: <UnavailableCompany />,
            title: 'Доступ приостановлен',
          },
          {
            path: 'invite-company',
            element: <InviteCompany />,
            title: 'Приглашение',
          },
          {
            path: 'create-company-schedule',
            element: <CreateCompanySchedule />,
            title: 'Режим работы организации',
          },
        ]),
      },
      {
        path: '',
        element: <Root />,
        children: createTabs(tabs),
      },
      {
        path: '',
        element: <ProfileRoot />,
        children: permissionStack([
          {
            path: 'profile',
            element: <Profile />,
            title: 'Профиль',
          },
          {
            path: 'phone-change',
            element: <PhoneChange />,
            title: 'Изменить номер',
          },
          {
            path: 'password-set',
            element: <PasswordSet />,
            title: 'Установить пароль',
          },
          {
            path: 'password-change',
            element: <PasswordChange />,
            title: 'Изменить пароль',
          },
          {
            path: 'profile-set',
            element: <ProfileSet />,
            title: 'Редактировать профиль',
          },
          {
            path: 'push-notification-setting',
            element: <PushNotificationSetting />,
            title: 'Настройки уведомлений',
          },
          {
            path: 'referral',
            element: <Referral />,
            title: 'Реферальная программа',
          },
        ]),
      },
      {
        path: 'phone-change-code',
        element: <PhoneChangeCode />,
      },
      {
        path: 'email-code',
        element: <EmailCode />,
      },
      {
        path: '/:username/add-service',
        element: (
          <ProtectedRoute permissionName="edit_company_services_permission">
            <AddService />
          </ProtectedRoute>
        ),
      },
      {
        path: '/:username/edit-service',
        element: (
          <ProtectedRoute permissionName="edit_company_services_permission">
            <EditService />
          </ProtectedRoute>
        ),
      },
      {
        path: '/:username/moderation',
        element: (
          <ProtectedRoute permissionName="edit_apps_permission">
            <ModerationRoot />
          </ProtectedRoute>
        ),
        children: permissionStack([
          {
            path: 'new-moderation/:id',
            element: <NewModeration />,
            title: 'Новая заявка',
          },
          {
            path: 'status-of-moderations/:id',
            element: <StatusOfModeration />,
            title: 'Заявки на модерацию',
          },
        ]),
      },
      {
        path: ':username/add-customer',
        element: <AddCustomer />,
      },
      {
        path: ':username/create-schedule',
        element: <CreateSchedule />,
      },
      {
        path: ':username/create-message-sample',
        element: (
          <ProtectedRoute permissionName="message_sample_permission">
            <CreateMessageTemplates />
          </ProtectedRoute>
        ),
      },
      {
        path: ':username/edit-message-sample/:id',
        element: <EditMessageTemplates />,
      },
      {
        path: ':username/add-webhook',
        element: <AddWebhook />,
      },
      {
        path: '/:username/edit-webhook/:id',
        element: <EditWebhook />,
      },
      {
        path: ':username/create-booking',
        element: <CreateBooking />,
      },
      {
        path: ':username/edit-customer/:id',
        element: <CustomerEdit />,
      },
      {
        path: ':username/customer/:id',
        element: <CustomerProfileRoot />,
        children: permissionStack([
          {
            path: '',
            element: <CustomerAbout />,
            title: 'О клиенте',
          },
          {
            path: 'history',
            element: <CustomerHistory />,
            title: 'История посещений',
          },
          {
            path: 'bonuses',
            element: <CustomerBonuses />,
            title: 'Бонусные карты',
          },
          {
            path: 'certificates',
            element: <CustomerCertificates />,
            title: 'Сертификаты',
          },
        ]),
      },
      {
        path: ':username/employees',
        element: <Employees />,
      },
      {
        path: ':username/order-employees',
        element: <OrderEmployees />,
      },
      {
        path: ':username/add-employee',
        element: <AddEmployee />,
      },
      {
        path: ':username/edit-employee/:id',
        element: <EditEmployee />,
      },
      {
        path: ':username/employee/:id',
        element: <EmployeeRoot />,
        children: permissionStack(
          [
            {
              path: '',
              element: <EmployeeAbout />,
              title: 'О сотруднике',
            },
            {
              path: 'schedule',
              element: <EmployeeSchedule />,
              title: 'График работ',
            },
            {
              path: 'bookings',
              element: <EmployeeBookings />,
              title: 'Записи',
            },
            {
              path: 'services',
              element: <EmployeeServices />,
              title: 'Услуги',
            },
            {
              path: 'permissions',
              element: <EmployeePermissions />,
              title: 'Права доступа',
            },
            {
              path: 'salary-rule',
              element: (
                <ProtectedRoute permissionName="salaries_permission">
                  <EmployeeSalaryRule />
                </ProtectedRoute>
              ),
              title: 'Расчет зарплаты',
            },
          ].filter(item =>
            item.title === 'Расчет зарплаты'
              ? process.env.REACT_APP_DOMAIN?.includes('dev')
              : true,
          ),
        ),
      },
      {
        path: ':username/booking/:id',
        element: <AboutBookingRoot />,
        children: permissionStack([
          {
            path: '',
            element: <DetailBooking />,
            title: 'Подробнее о записи',
          },
          {
            path: 'payment',
            element: <PaymentBooking />,
            title: 'Подробнее о записи',
          },
          {
            path: 'photos',
            element: <PhotosBooking />,
            title: 'Подробнее о записи',
          },
          {
            path: 'messages',
            element: (
              <ProtectedRoute permissionName="messages_permission">
                <MessagesBooking />
              </ProtectedRoute>
            ),
            title: 'Рассылки',
          },
        ]),
      },
      {
        path: '/:username/booking/:id/edit',
        element: <EditBooking />,
      },
      {
        path: '/:username/notification',
        element: <NotificationRoot />,
        children: permissionStack([
          {
            path: 'booking',
            element: <NotificationBooking />,
            title: 'Записи',
          },
          {
            path: 'rest',
            element: <NotificationRest />,
            title: 'Остальное',
          },
        ]),
      },
      {
        path: ':username/company',
        element: (
          <ProtectedRoute permissionName="edit_company_permission">
            <CompanyRoot />
          </ProtectedRoute>
        ),
        children: permissionStack([
          {
            path: '',
            element: <CompanyEdit />,
            title: 'Редактирование организации',
          },
          {
            path: 'contact',
            element: <CompanyContact />,
            title: 'Контакты',
          },
          {
            path: 'advantage',
            element: <CompanyAdvantage />,
            title: 'Дополнительно',
          },
        ]),
      },
      {
        path: ':username/company-schedule',
        element: (
          <ProtectedRoute permissionName="edit_company_permission">
            <CompanySchedule />
          </ProtectedRoute>
        ),
      },
      {
        path: '/:username/permissions',
        element: <Permissions />,
      },
      {
        path: '/:username/journal-setting',
        element: <JournalSetting />,
      },
      {
        path: '/:username/notification-settings',
        element: <NotificationSettings />,
      },
      {
        path: '/about-app',
        element: <AboutApp />,
      },
      {
        path: '/:username/domen-change',
        element: <DomenChange />,
      },
      {
        path: '/:username/create-bonus',
        element: <CreateBonus />,
      },
      {
        path: '/:username/create-salary-rule',
        element: <AddSalaryRule />,
      },
      {
        path: '/:username/edit-salary-rule/:id',
        element: <EditSalaryRule />,
      },

      {
        path: '/:username/bonus/:id/issue',
        element: <IssueBonus />,
      },
      {
        path: '/:username/bonus/:id/edit',
        element: <EditBonus />,
      },
      {
        path: '/:username/create-certificate',
        element: <CreateCertificate />,
      },
      {
        path: '/:username/certificate/:id/edit',
        element: <EditCertificate />,
      },
      {
        path: '/:username/certificate/:id/issue',
        element: <IssueCertificate />,
      },
      {
        path: '/:username/add-discount',
        element: <AddDiscount />,
      },
      {
        path: '/:username/edit-discount/:id',
        element: <EditDiscount />,
      },
      {
        path: '/:username/widget-app/:id',
        element: <WidgetApp />,
      },
      {
        path: '/:username/qrcode-app/:id',
        element: <QrCodeApp />,
      },
      {
        path: '/:username/platform-app/:id',
        element: <PlatformApp />,
      },
      {
        path: '/:username/website-app/:id',
        element: <WebsiteApp />,
      },
      {
        path: '/:username/csm-app/:appIds',
        element: <CsmApp />,
      },
      {
        path: '/:username/other-app/:id',
        element: <OtherApp />,
      },
      {
        path: '/:username/chat-app/:id',
        element: <ChatApp />,
      },
      {
        path: 'payment-tariff',
        element: <PaymentTariff />,
      },
      {
        path: 'unavailable-tariff-plans',
        element: <UnavailableTariffPlans />,
      },
      {
        path: 'change-tariff',
        element: <ChangeTariff />,
      },

      {
        path: '/:username/reviews/filters',
        element: <ReviewFiltersMobile />,
      },
      {
        path: '/:username/reviews/:id',
        element: <ReviewMobile />,
      },
      {
        path: '/:username/loyalty',
        element: <Loyalty />,
      },
      {
        path: '/tariff-and-payment',
        element: <TariffAndPayment />,
      },
      {
        path: '/profile-and-settings',
        element: <ProfileMobileRoot />,
      },
      {
        path: '*',
        element: <NotFoundPage />,
      },
      {
        path: '/no-permission',
        element: <NoPermission />,
      },
      {
        path: '/:username/create-product',
        element: <CreateProduct />,
      },
      {
        path: '/:username/product/:id',
        element: <ProductsRoot />,
        children: permissionStack([
          {
            path: '',
            element: <DetailProduct />,
            title: 'Товар',
          },
          // TODO: Добавить в обновлении 1.2.0
          // {
          //   path: 'history-sales',
          //   element: <HistorySales />,
          //   title: 'История продаж',
          // },
          {
            path: 'history-reserve',
            element: <HistoryReserve />,
            title: 'История запасов',
          },
        ]),
      },
      {
        path: '/:username/product/:id/edit',
        element: <EditProduct />,
      },
      {
        path: '/:username/booking-source',
        element: <BookingSource />,
      },
      {
        path: '/:username/booking-source/create',
        element: <CreateBookingSource />,
      },
      {
        path: '/:username/booking-source/:id/edit',
        element: <EditBookingSource />,
      },
    ],
  };
}
