import styled from 'styled-components';

export const Header = styled.header`
  height: 100px;
  align-items: center;
  gap: 12px;
  display: flex;
  padding-left: 50px;
  padding-right: 50px;
  position: sticky;
  top: 0;
  z-index: 100;
`;
