import React from 'react';
import {useColorScheme} from 'shared/lib/hooks';
import {EmptyContainer} from 'shared/ui/EmptyContainer';

export const EmptyCustomers = ({
  size = 400,
  mt = 40,
}: {
  size?: number;
  mt?: number;
}) => {
  const colorScheme = useColorScheme();
  return (
    <EmptyContainer size={size} mt={mt}>
      {colorScheme === 'light' ? (
        <svg
          viewBox="0 0 400 400"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M189.372 342.15C267.681 342.15 331.167 278.663 331.167 200.075C331.167 121.486 267.681 58 189.372 58C111.063 58 47.5771 121.486 47.5771 200.075C47.5771 278.663 111.063 342.15 189.372 342.15Z"
            fill="#EAEEF9"
          />
          <path
            d="M347.389 141.623C353.722 141.623 358.856 136.489 358.856 130.156C358.856 123.823 353.722 118.689 347.389 118.689C341.056 118.689 335.922 123.823 335.922 130.156C335.922 136.489 341.056 141.623 347.389 141.623Z"
            fill="#EAEEF9"
          />
          <path
            d="M364.169 96.5949C368.494 96.5949 372 93.0889 372 88.764C372 84.4391 368.494 80.9331 364.169 80.9331C359.844 80.9331 356.338 84.4391 356.338 88.764C356.338 93.0889 359.844 96.5949 364.169 96.5949Z"
            fill="#EAEEF9"
          />
          <path
            d="M37.7884 149.733C42.1133 149.733 45.6193 146.227 45.6193 141.902C45.6193 137.577 42.1133 134.071 37.7884 134.071C33.4635 134.071 29.9575 137.577 29.9575 141.902C29.9575 146.227 33.4635 149.733 37.7884 149.733Z"
            fill="#EAEEF9"
          />
          <path
            d="M52.3317 320.055C60.3636 320.055 66.8747 313.544 66.8747 305.512C66.8747 297.48 60.3636 290.969 52.3317 290.969C44.2997 290.969 37.7886 297.48 37.7886 305.512C37.7886 313.544 44.2997 320.055 52.3317 320.055Z"
            fill="#EAEEF9"
          />
          <g filter="url(#filter0_d_12728_9192)">
            <path
              d="M320.82 143.301H78.3419C73.0281 143.301 68.833 139.105 68.833 133.792V79.255C68.833 73.9412 73.0281 69.7461 78.3419 69.7461H320.54C325.854 69.7461 330.049 73.9412 330.049 79.255V133.792C330.329 139.105 325.854 143.301 320.82 143.301Z"
              fill="white"
            />
          </g>
          <path
            d="M127.564 106.384C127.564 112.537 124.768 118.13 120.573 122.046C116.937 125.402 111.903 127.359 106.589 127.359C101.275 127.359 96.2409 125.402 92.6051 122.046C88.1304 118.13 85.6133 112.537 85.6133 106.384C85.6133 94.9171 95.1222 85.4082 106.589 85.4082C118.056 85.4082 127.564 94.9171 127.564 106.384Z"
            fill="#DCE1EE"
          />
          <path
            d="M120.572 122.045C116.937 125.402 111.903 127.359 106.589 127.359C101.275 127.359 96.2408 125.402 92.605 122.045C94.283 121.486 96.2407 120.647 98.7578 119.528C99.3172 119.249 99.8765 118.689 100.156 118.13C100.436 117.85 100.436 117.291 100.436 117.011V111.697L100.156 111.418C99.5968 110.858 99.3172 110.019 99.3172 109.18L98.7578 108.901C97.3594 109.18 97.6391 107.782 97.0798 104.985C97.0798 103.867 97.0798 103.587 97.6391 103.307L98.1985 102.748C95.6814 96.8747 96.8001 93.2389 99.5968 91.8406C98.7578 89.8828 98.7578 89.3235 98.7578 89.3235C98.7578 89.3235 104.351 90.1625 106.029 89.8828C108.546 89.3235 112.182 89.8828 113.581 93.2389C115.818 94.078 116.657 95.4763 116.937 97.1544C117.216 99.6715 115.818 102.468 115.538 103.307C115.818 103.587 116.098 103.867 115.818 104.706C115.538 107.223 115.538 108.901 114.14 108.621L113.021 111.138C113.021 111.418 113.021 111.418 113.021 111.697C113.021 111.977 113.021 112.816 113.021 117.291C113.021 117.85 113.301 118.41 113.301 118.689C113.581 119.249 114.14 119.528 114.42 119.808C116.937 120.647 118.894 121.486 120.572 122.045Z"
            fill="white"
          />
          <path
            d="M115.612 103.587C115.612 103.005 115.321 102.132 115.03 101.55V101.258C113.866 98.9297 111.537 98.0565 109.208 98.0565C103.386 97.7654 102.804 98.9297 100.766 97.1832C101.349 98.0565 101.349 99.5119 100.475 101.258C99.8932 102.423 98.7288 103.005 97.5644 103.296C94.9446 97.1832 96.109 93.399 99.0199 91.9435C98.1466 89.9059 98.1466 89.3237 98.1466 89.3237C98.1466 89.3237 103.968 90.197 105.715 89.9059C108.335 89.3237 112.119 89.9059 113.574 93.399C115.903 94.2723 116.776 95.7277 117.068 97.4743C117.359 99.5119 115.903 102.423 115.612 103.587Z"
            fill="#A2ABBC"
          />
          <path
            d="M113.301 118.689V119.808H100.156V118.41C100.436 118.13 100.436 117.571 100.436 117.291V111.977L100.156 111.697V111.418C100.436 111.977 100.716 112.257 101.275 112.816L105.47 115.613C106.309 116.452 107.707 116.452 108.826 115.613L112.742 112.257L113.021 111.977C113.021 112.257 113.021 113.096 113.021 117.571C112.742 117.85 113.021 118.13 113.301 118.689Z"
            fill="url(#paint0_linear_12728_9192)"
          />
          <path
            d="M196.365 100.231H142.667C141.269 100.231 140.43 99.1121 140.43 97.9934V91.0016C140.43 89.6032 141.548 88.7642 142.667 88.7642H196.365C197.763 88.7642 198.602 89.8829 198.602 91.0016V97.9934C198.602 99.3918 197.763 100.231 196.365 100.231Z"
            fill="#CED6E2"
          />
          <path
            d="M260.13 124.003H142.667C141.269 124.003 140.43 122.885 140.43 121.766V114.774C140.43 113.376 141.548 112.537 142.667 112.537H260.13C261.529 112.537 262.368 113.655 262.368 114.774V121.766C262.368 123.164 261.249 124.003 260.13 124.003Z"
            fill="#CED6E2"
          />
          <g filter="url(#filter1_d_12728_9192)">
            <path
              d="M320.82 326.768H78.3419C73.0281 326.768 68.833 322.572 68.833 317.259V262.722C68.833 257.408 73.0281 253.213 78.3419 253.213H320.54C325.854 253.213 330.049 257.408 330.049 262.722V317.259C330.329 322.572 325.854 326.768 320.82 326.768Z"
              fill="white"
            />
          </g>
          <path
            d="M127.564 289.851C127.564 296.004 124.768 301.597 120.573 305.513C116.937 308.869 111.903 310.827 106.589 310.827C101.275 310.827 96.2409 308.869 92.6051 305.513C88.1304 301.597 85.6133 296.004 85.6133 289.851C85.6133 278.384 95.1222 268.875 106.589 268.875C118.056 268.875 127.564 278.384 127.564 289.851Z"
            fill="#DCE1EE"
          />
          <path
            d="M120.572 305.513C116.937 308.869 111.903 310.827 106.589 310.827C101.275 310.827 96.2408 308.869 92.605 305.513C94.283 304.953 96.2407 304.114 98.7578 302.996C99.3172 302.716 99.8765 302.157 100.156 301.597C100.436 301.318 100.436 300.758 100.436 300.479V295.165L100.156 294.885C99.5968 294.326 99.3172 293.487 99.3172 292.648L98.7578 292.368C97.3594 292.648 97.6391 291.249 97.0798 288.453C97.0798 287.334 97.0798 287.054 97.6391 286.775L98.1985 286.215C95.6814 280.342 96.8001 276.706 99.5968 275.308C98.7578 273.35 98.7578 272.791 98.7578 272.791C98.7578 272.791 104.351 273.63 106.029 273.35C108.546 272.791 112.182 273.35 113.581 276.706C115.818 277.545 116.657 278.944 116.937 280.622C117.216 283.139 115.818 285.935 115.538 286.775C115.818 287.054 116.098 287.334 115.818 288.173C115.538 290.69 115.538 292.368 114.14 292.088L113.021 294.326C113.021 294.605 113.021 294.605 113.021 294.885C113.021 295.165 113.021 296.004 113.021 300.479C113.021 301.038 113.301 301.597 113.301 301.877C113.581 302.436 114.14 302.716 114.42 302.996C116.937 304.394 118.894 304.953 120.572 305.513Z"
            fill="white"
          />
          <path
            d="M115.612 287.054C115.612 286.472 115.321 285.599 115.03 285.016V284.725C113.866 282.397 111.537 281.523 109.208 281.523C103.386 281.232 102.804 282.397 100.766 280.65C101.349 281.523 101.349 282.979 100.475 284.725C99.8932 285.89 98.7288 286.472 97.5644 286.763C94.9446 280.65 96.109 276.866 99.0199 275.41C98.1466 273.373 98.1466 272.791 98.1466 272.791C98.1466 272.791 103.968 273.664 105.715 273.373C108.335 272.791 112.119 273.373 113.574 276.866C115.903 277.739 116.776 279.195 117.068 280.941C117.359 283.27 115.903 285.89 115.612 287.054Z"
            fill="#A2ABBC"
          />
          <path
            d="M113.301 302.156V303.275H100.156V301.876C100.436 301.597 100.436 301.037 100.436 300.758V295.444L100.156 295.164V294.885C100.436 295.444 100.716 295.724 101.275 296.283L105.47 299.08C106.309 299.919 107.707 299.919 108.826 299.08L112.742 295.724L113.021 295.444C113.021 295.724 113.021 296.563 113.021 301.037C112.742 301.317 113.021 301.876 113.301 302.156Z"
            fill="url(#paint1_linear_12728_9192)"
          />
          <path
            d="M196.365 283.977H142.667C141.269 283.977 140.43 282.859 140.43 281.74V274.748C140.43 273.35 141.548 272.511 142.667 272.511H196.365C197.763 272.511 198.602 273.629 198.602 274.748V281.74C198.602 282.859 197.763 283.977 196.365 283.977Z"
            fill="#CED6E2"
          />
          <path
            d="M260.13 307.75H142.667C141.269 307.75 140.43 306.631 140.43 305.512V298.521C140.43 297.122 141.548 296.283 142.667 296.283H260.13C261.529 296.283 262.368 297.402 262.368 298.521V305.512C262.368 306.631 261.249 307.75 260.13 307.75Z"
            fill="#CED6E2"
          />
          <g filter="url(#filter2_d_12728_9192)">
            <path
              d="M279.707 235.034H37.5089C32.1951 235.034 28 230.839 28 225.525V170.989C28 165.675 32.1951 161.48 37.5089 161.48H279.707C285.021 161.48 289.216 165.675 289.216 170.989V225.525C289.496 230.839 285.021 235.034 279.707 235.034Z"
              fill="white"
            />
          </g>
          <path
            d="M155.532 191.964H101.835C100.436 191.964 99.5972 190.846 99.5972 189.727V182.735C99.5972 181.337 100.716 180.498 101.835 180.498H155.532C156.93 180.498 157.77 181.616 157.77 182.735V189.727C157.77 191.125 156.93 191.964 155.532 191.964Z"
            fill="#CED6E2"
          />
          <path
            d="M219.298 216.017H101.835C100.436 216.017 99.5972 214.898 99.5972 213.78V206.788C99.5972 205.389 100.716 204.55 101.835 204.55H219.018C220.417 204.55 221.256 205.669 221.256 206.788V213.78C221.535 214.898 220.417 216.017 219.298 216.017Z"
            fill="#CED6E2"
          />
          <path
            d="M87.0112 198.117C87.0112 203.991 84.4941 209.304 80.5786 213.22C80.299 213.5 80.299 213.5 80.0193 213.779C76.3835 217.135 71.3494 219.093 66.0356 219.093C60.7217 219.093 55.9673 217.135 52.3315 214.059C52.3315 214.059 52.3315 214.059 52.0518 214.059C47.577 210.143 44.7803 204.55 44.7803 198.117C44.7803 186.651 54.2892 177.142 65.7559 177.142C77.5022 177.142 87.0112 186.651 87.0112 198.117Z"
            fill="#DCE1EE"
          />
          <path
            d="M80.4969 213.646C79.6461 211.661 78.228 209.959 75.9591 208.825C74.8246 208.257 73.9737 207.974 72.5556 207.974H71.7048C69.7195 207.974 69.4359 207.69 69.4359 207.69V204.287C71.7048 202.585 73.4065 200.316 73.9737 197.763C75.3918 197.48 76.2427 196.345 75.9591 194.927C75.9591 194.36 75.3918 193.793 75.3918 193.226C75.3918 192.942 75.3918 192.658 75.3918 192.375C75.3918 192.091 75.3918 192.091 75.3918 191.808C75.3918 191.524 75.3918 191.524 75.3918 191.24C75.3918 190.106 75.1082 189.255 74.2573 188.12C72.5556 185.001 69.1522 183.015 65.4652 183.015C64.6143 183.015 64.0471 183.015 63.1963 183.299C61.7782 183.583 60.6437 184.15 59.5092 185.001C59.2256 185.284 59.2256 185.284 58.942 185.568C58.942 185.568 58.942 185.568 58.6584 185.852C57.5239 186.986 56.6731 188.404 56.1058 189.822C55.5386 191.24 55.5386 192.942 55.5386 194.644C55.5386 194.644 55.5386 194.644 55.5386 194.927C55.5386 195.211 55.5386 195.211 55.5386 195.495C55.5386 195.495 55.5386 195.778 55.255 195.778C55.8222 195.211 55.5386 196.062 55.8222 196.629C56.3894 198.047 57.2403 197.763 58.3748 198.614C58.3748 198.614 58.3748 198.614 58.0911 198.614L56.9567 198.898C50.7171 200.883 50.1499 207.406 56.3894 208.825C55.8222 209.108 55.5386 209.392 55.255 209.392C53.5533 210.81 52.4188 212.512 51.8516 214.213C55.5386 217.333 60.3601 219.318 65.7488 219.318C71.1376 219.318 76.2427 217.333 79.9297 213.93C80.2133 214.213 80.4969 213.93 80.4969 213.646C80.7806 213.646 80.7806 213.646 80.4969 213.646ZM62.9126 206.839C62.629 206.556 62.3454 206.272 62.3454 206.272C62.0618 205.988 61.7782 205.988 61.4946 205.705C61.4946 205.705 61.2109 205.705 61.2109 205.421C60.3601 204.854 59.5092 204.287 59.2256 203.436C59.2256 203.152 59.5092 202.585 60.0765 202.018C60.3601 202.301 60.6437 202.585 60.9273 203.152C61.2109 203.436 61.4946 203.719 61.7782 204.003C62.0618 204.003 62.3454 204.287 62.3454 204.57C62.3454 204.57 62.3454 204.57 62.629 204.57C62.629 204.57 62.629 204.57 62.9126 204.57V206.839Z"
            fill="white"
          />
          <path
            d="M72.0492 207.812C71.7695 208.93 71.2102 209.77 71.2102 209.77L60.5825 208.091L61.1419 205.295C61.1419 205.295 61.4215 205.295 61.4215 205.574C61.7012 205.854 61.9809 205.854 62.2606 206.134C62.5402 206.134 62.5402 206.413 62.8199 206.693V204.735C62.8199 204.735 62.8199 204.735 62.5402 204.735C64.2183 205.574 66.4557 206.134 69.5321 204.176V207.532C69.8118 207.532 70.0915 207.812 72.0492 207.812Z"
            fill="url(#paint2_linear_12728_9192)"
          />
          <path
            d="M69.8386 192.212C71.8339 192.212 73.8291 191.927 75.5393 191.357C75.5393 191.357 75.5393 191.072 75.8243 190.787C75.5393 189.932 75.2542 188.792 74.6842 187.652C72.974 184.516 69.5536 182.521 65.8482 182.521C64.9931 182.521 64.1729 182.582 63.0292 182.867C61.5073 183.332 60.8006 183.741 60.44 183.959C60.44 183.959 59.6069 184.52 59.0075 185.086C58.408 185.653 57.0123 187.652 56.4422 189.077C55.8721 190.787 55.4122 192.24 55.6972 193.951C55.9823 193.951 56.4422 193.637 56.7272 193.637C59.0075 192.497 60.7177 191.072 61.5728 188.792C63.853 190.787 66.7033 191.927 69.8386 192.212Z"
            fill="#A2ABBC"
          />
          <path
            d="M60.1626 202.313C60.4423 202.592 60.7219 202.872 61.0016 203.152C60.7219 202.872 60.4423 202.592 60.1626 202.313Z"
            fill="#3973F4"
          />
          <path
            d="M64.0783 212.66C63.2393 213.779 61.8409 213.779 60.4426 213.779C61.8409 212.381 61.0019 208.186 56.5271 208.745C50.0946 207.626 50.9336 200.914 57.0865 198.956L58.2052 198.677C58.2052 198.677 58.2052 198.677 58.4848 198.956C59.0442 200.355 59.6035 201.194 60.4426 202.313C57.9255 204.55 61.5613 205.109 63.2393 206.787C64.358 207.347 65.4767 210.982 64.0783 212.66Z"
            fill="#A2ABBC"
          />
          <defs>
            <filter
              id="filter0_d_12728_9192"
              x="59.833"
              y="65.7461"
              width="279.229"
              height="91.5544"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="5" />
              <feGaussianBlur stdDeviation="4.5" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.17 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_12728_9192"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_12728_9192"
                result="shape"
              />
            </filter>
            <filter
              id="filter1_d_12728_9192"
              x="59.833"
              y="249.213"
              width="279.229"
              height="91.5544"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="5" />
              <feGaussianBlur stdDeviation="4.5" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.17 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_12728_9192"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_12728_9192"
                result="shape"
              />
            </filter>
            <filter
              id="filter2_d_12728_9192"
              x="19"
              y="157.48"
              width="279.229"
              height="91.5544"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="5" />
              <feGaussianBlur stdDeviation="4.5" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.17 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_12728_9192"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_12728_9192"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_12728_9192"
              x1="106.733"
              y1="119.859"
              x2="106.733"
              y2="115.237"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="white" />
              <stop offset="1" stopColor="#E2E5EC" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_12728_9192"
              x1="106.733"
              y1="303.326"
              x2="106.733"
              y2="298.704"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="white" />
              <stop offset="1" stopColor="#E2E5EC" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_12728_9192"
              x1="66.3193"
              y1="209.804"
              x2="66.3193"
              y2="206.722"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="white" />
              <stop offset="1" stopColor="#E2E5EC" />
            </linearGradient>
          </defs>
        </svg>
      ) : (
        <svg
          viewBox="0 0 400 400"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M189.374 342.14C267.683 342.14 331.169 278.656 331.169 200.07C331.169 121.484 267.683 58 189.374 58C111.064 58 47.5781 121.484 47.5781 200.07C47.5781 278.656 111.064 342.14 189.374 342.14Z"
            fill="#2A2E37"
          />
          <path
            d="M347.389 141.621C353.721 141.621 358.855 136.487 358.855 130.154C358.855 123.822 353.721 118.688 347.389 118.688C341.056 118.688 335.922 123.822 335.922 130.154C335.922 136.487 341.056 141.621 347.389 141.621Z"
            fill="#2A2E37"
          />
          <path
            d="M364.167 96.5944C368.492 96.5944 371.998 93.0885 371.998 88.7637C371.998 84.439 368.492 80.9331 364.167 80.9331C359.842 80.9331 356.336 84.439 356.336 88.7637C356.336 93.0885 359.842 96.5944 364.167 96.5944Z"
            fill="#2A2E37"
          />
          <path
            d="M37.7879 149.73C42.1128 149.73 45.6189 146.224 45.6189 141.899C45.6189 137.575 42.1128 134.069 37.7879 134.069C33.463 134.069 29.957 137.575 29.957 141.899C29.957 146.224 33.463 149.73 37.7879 149.73Z"
            fill="#2A2E37"
          />
          <path
            d="M52.3322 320.046C60.3641 320.046 66.8753 313.535 66.8753 305.503C66.8753 297.471 60.3641 290.96 52.3322 290.96C44.3002 290.96 37.7891 297.471 37.7891 305.503C37.7891 313.535 44.3002 320.046 52.3322 320.046Z"
            fill="#2A2E37"
          />
          <g filter="url(#filter0_d_12728_9238)">
            <path
              d="M320.82 143.3H78.341C73.0272 143.3 68.832 139.105 68.832 133.791V79.2567C68.832 73.943 73.0272 69.748 78.341 69.748H320.54C325.854 69.748 330.049 73.943 330.049 79.2567V133.791C330.329 139.105 325.854 143.3 320.82 143.3Z"
              fill="url(#paint0_linear_12728_9238)"
            />
          </g>
          <path
            d="M127.561 106.385C127.561 112.538 124.764 118.131 120.569 122.046C116.933 125.402 111.899 127.36 106.585 127.36C101.271 127.36 96.237 125.402 92.6013 122.046C88.1265 118.131 85.6094 112.538 85.6094 106.385C85.6094 94.9188 95.1183 85.4102 106.585 85.4102C118.052 85.4102 127.561 94.9188 127.561 106.385Z"
            fill="#484D5B"
          />
          <path
            d="M120.565 122.047C116.929 125.403 111.895 127.361 106.581 127.361C101.268 127.361 96.2334 125.403 92.5977 122.047C94.2757 121.488 96.2334 120.649 98.7505 119.53C99.3099 119.25 99.8692 118.691 100.149 118.132C100.429 117.852 100.429 117.293 100.429 117.013V111.699L100.149 111.42C99.5895 110.86 99.3099 110.021 99.3099 109.182L98.7505 108.903C97.3521 109.182 97.6318 107.784 97.0725 104.987C97.0725 103.869 97.0725 103.589 97.6318 103.309L98.1912 102.75C95.6741 96.8771 96.7928 93.2415 99.5895 91.8432C98.7505 89.8855 98.7505 89.3262 98.7505 89.3262C98.7505 89.3262 104.344 90.1652 106.022 89.8855C108.539 89.3262 112.175 89.8855 113.573 93.2415C115.811 94.0805 116.65 95.4788 116.929 97.1568C117.209 99.6738 115.811 102.47 115.531 103.309C115.811 103.589 116.09 103.869 115.811 104.708C115.531 107.225 115.531 108.903 114.133 108.623L113.014 111.14C113.014 111.42 113.014 111.42 113.014 111.699C113.014 111.979 113.014 112.818 113.014 117.293C113.014 117.852 113.294 118.411 113.294 118.691C113.573 119.25 114.133 119.53 114.412 119.81C116.929 120.649 118.887 121.488 120.565 122.047Z"
            fill="#8E94A7"
          />
          <path
            d="M115.605 103.589C115.605 103.007 115.314 102.134 115.023 101.552V101.26C113.858 98.9318 111.53 98.0586 109.201 98.0586C103.379 97.7675 102.797 98.9318 100.759 97.1854C101.341 98.0586 101.341 99.514 100.468 101.26C99.8858 102.425 98.7215 103.007 97.5571 103.298C94.9373 97.1854 96.1017 93.4013 99.0126 91.9459C98.1393 89.9083 98.1393 89.3262 98.1393 89.3262C98.1393 89.3262 103.961 90.1994 105.708 89.9083C108.327 89.3262 112.112 89.9083 113.567 93.4013C115.896 94.2745 116.769 95.73 117.06 97.4764C117.351 99.514 115.896 102.425 115.605 103.589Z"
            fill="#2A2E37"
          />
          <path
            d="M113.289 118.692V119.811H100.145V118.413C100.424 118.133 100.424 117.574 100.424 117.294V111.98L100.145 111.701V111.421C100.424 111.98 100.704 112.26 101.263 112.819L105.458 115.616C106.297 116.455 107.696 116.455 108.814 115.616L112.73 112.26L113.01 111.98C113.01 112.26 113.01 113.099 113.01 117.574C112.73 117.853 113.01 118.133 113.289 118.692Z"
            fill="url(#paint1_linear_12728_9238)"
          />
          <path
            d="M196.361 100.231H142.663C141.265 100.231 140.426 99.1128 140.426 97.9941V91.0025C140.426 89.6041 141.544 88.7651 142.663 88.7651H196.361C197.759 88.7651 198.598 89.8838 198.598 91.0025V97.9941C198.598 99.3924 197.759 100.231 196.361 100.231Z"
            fill="#484D5B"
          />
          <path
            d="M260.127 124.002H142.663C141.265 124.002 140.426 122.884 140.426 121.765V114.773C140.426 113.375 141.544 112.536 142.663 112.536H260.127C261.525 112.536 262.364 113.655 262.364 114.773V121.765C262.364 123.163 261.246 124.002 260.127 124.002Z"
            fill="#484D5B"
          />
          <g filter="url(#filter1_d_12728_9238)">
            <path
              d="M320.82 326.756H78.341C73.0272 326.756 68.832 322.561 68.832 317.247V262.712C68.832 257.399 73.0272 253.204 78.341 253.204H320.54C325.854 253.204 330.049 257.399 330.049 262.712V317.247C330.329 322.561 325.854 326.756 320.82 326.756Z"
              fill="url(#paint2_linear_12728_9238)"
            />
          </g>
          <path
            d="M127.561 289.841C127.561 295.994 124.764 301.587 120.569 305.502C116.933 308.858 111.899 310.816 106.585 310.816C101.271 310.816 96.237 308.858 92.6013 305.502C88.1265 301.587 85.6094 295.994 85.6094 289.841C85.6094 278.375 95.1183 268.866 106.585 268.866C118.052 268.866 127.561 278.375 127.561 289.841Z"
            fill="#484D5B"
          />
          <path
            d="M120.565 305.504C116.929 308.86 111.895 310.817 106.581 310.817C101.268 310.817 96.2334 308.86 92.5977 305.504C94.2757 304.944 96.2334 304.105 98.7505 302.987C99.3099 302.707 99.8692 302.148 100.149 301.588C100.429 301.309 100.429 300.749 100.429 300.47V295.156L100.149 294.876C99.5895 294.317 99.3099 293.478 99.3099 292.639L98.7505 292.359C97.3521 292.639 97.6318 291.241 97.0725 288.444C97.0725 287.325 97.0725 287.046 97.6318 286.766L98.1912 286.207C95.6741 280.334 96.7928 276.698 99.5895 275.3C98.7505 273.342 98.7505 272.783 98.7505 272.783C98.7505 272.783 104.344 273.622 106.022 273.342C108.539 272.783 112.175 273.342 113.573 276.698C115.811 277.537 116.65 278.935 116.929 280.613C117.209 283.13 115.811 285.927 115.531 286.766C115.811 287.046 116.09 287.325 115.811 288.164C115.531 290.681 115.531 292.359 114.133 292.08L113.014 294.317C113.014 294.597 113.014 294.597 113.014 294.876C113.014 295.156 113.014 295.995 113.014 300.47C113.014 301.029 113.294 301.588 113.294 301.868C113.573 302.427 114.133 302.707 114.412 302.987C116.929 304.385 118.887 304.944 120.565 305.504Z"
            fill="#8E94A7"
          />
          <path
            d="M115.605 287.046C115.605 286.464 115.314 285.591 115.023 285.009V284.718C113.858 282.389 111.53 281.516 109.201 281.516C103.379 281.225 102.797 282.389 100.759 280.642C101.341 281.516 101.341 282.971 100.468 284.718C99.8858 285.882 98.7215 286.464 97.5571 286.755C94.9373 280.642 96.1017 276.858 99.0126 275.403C98.1393 273.365 98.1393 272.783 98.1393 272.783C98.1393 272.783 103.961 273.656 105.708 273.365C108.327 272.783 112.112 273.365 113.567 276.858C115.896 277.732 116.769 279.187 117.06 280.933C117.351 283.262 115.896 285.882 115.605 287.046Z"
            fill="#2A2E37"
          />
          <path
            d="M113.289 302.149V303.268H100.145V301.87C100.424 301.59 100.424 301.031 100.424 300.751V295.437L100.145 295.158V294.878C100.424 295.437 100.704 295.717 101.263 296.276L105.458 299.073C106.297 299.912 107.696 299.912 108.814 299.073L112.73 295.717L113.01 295.437C113.01 295.717 113.01 296.556 113.01 301.031C112.73 301.31 113.01 301.87 113.289 302.149Z"
            fill="url(#paint3_linear_12728_9238)"
          />
          <path
            d="M196.361 283.967H142.663C141.265 283.967 140.426 282.848 140.426 281.729V274.738C140.426 273.339 141.544 272.5 142.663 272.5H196.361C197.759 272.5 198.598 273.619 198.598 274.738V281.729C198.598 282.848 197.759 283.967 196.361 283.967Z"
            fill="#484D5B"
          />
          <path
            d="M260.127 307.738H142.663C141.265 307.738 140.426 306.62 140.426 305.501V298.509C140.426 297.111 141.544 296.272 142.663 296.272H260.127C261.525 296.272 262.364 297.391 262.364 298.509V305.501C262.364 306.62 261.246 307.738 260.127 307.738Z"
            fill="#484D5B"
          />
          <g filter="url(#filter2_d_12728_9238)">
            <path
              d="M279.708 235.027H37.509C32.1951 235.027 28 230.832 28 225.518V170.983C28 165.67 32.1951 161.475 37.509 161.475H279.708C285.022 161.475 289.217 165.67 289.217 170.983V225.518C289.496 230.832 285.022 235.027 279.708 235.027Z"
              fill="url(#paint4_linear_12728_9238)"
            />
          </g>
          <path
            d="M155.533 191.96H101.835C100.437 191.96 99.5977 190.842 99.5977 189.723V182.731C99.5977 181.333 100.716 180.494 101.835 180.494H155.533C156.931 180.494 157.77 181.613 157.77 182.731V189.723C157.77 191.121 156.931 191.96 155.533 191.96Z"
            fill="#484D5B"
          />
          <path
            d="M219.299 216.011H101.835C100.437 216.011 99.5977 214.893 99.5977 213.774V206.782C99.5977 205.384 100.716 204.545 101.835 204.545H219.019C220.417 204.545 221.256 205.664 221.256 206.782V213.774C221.536 214.893 220.417 216.011 219.299 216.011Z"
            fill="#484D5B"
          />
          <path
            d="M87.0122 198.11C87.0122 203.983 84.4952 209.296 80.5797 213.212C80.3 213.491 80.3 213.491 80.0204 213.771C76.3846 217.127 71.3504 219.085 66.0366 219.085C60.7227 219.085 55.9683 217.127 52.3325 214.051C52.3325 214.051 52.3325 214.051 52.0528 214.051C47.578 210.135 44.7812 204.542 44.7812 198.11C44.7812 186.643 54.2902 177.135 65.7569 177.135C77.5033 177.135 87.0122 186.643 87.0122 198.11Z"
            fill="#484D5B"
          />
          <path
            d="M80.497 213.636C79.6461 211.65 78.2281 209.949 75.9591 208.814C74.8246 208.247 73.9738 207.963 72.5557 207.963H71.7048C69.7195 207.963 69.4359 207.68 69.4359 207.68V204.276C71.7048 202.575 73.4065 200.306 73.9738 197.753C75.3919 197.47 76.2427 196.335 75.9591 194.917C75.9591 194.35 75.3919 193.783 75.3919 193.216C75.3919 192.932 75.3919 192.649 75.3919 192.365C75.3919 192.081 75.3919 192.081 75.3919 191.798C75.3919 191.514 75.3919 191.514 75.3919 191.23C75.3919 190.096 75.1083 189.245 74.2574 188.111C72.5557 184.991 69.1523 183.006 65.4652 183.006C64.6144 183.006 64.0471 183.006 63.1963 183.289C61.7782 183.573 60.6437 184.14 59.5093 184.991C59.2256 185.275 59.2256 185.275 58.942 185.558C58.942 185.558 58.942 185.558 58.6584 185.842C57.5239 186.976 56.6731 188.394 56.1058 189.812C55.5386 191.23 55.5386 192.932 55.5386 194.634C55.5386 194.634 55.5386 194.634 55.5386 194.917C55.5386 195.201 55.5386 195.201 55.5386 195.485C55.5386 195.485 55.5386 195.768 55.255 195.768C55.8222 195.201 55.5386 196.052 55.8222 196.619C56.3895 198.037 57.2403 197.753 58.3748 198.604C58.3748 198.604 58.3748 198.604 58.0912 198.604L56.9567 198.888C50.7171 200.873 50.1499 207.396 56.3895 208.814C55.8222 209.098 55.5386 209.381 55.255 209.381C53.5533 210.799 52.4188 212.501 51.8516 214.203C55.5386 217.322 60.3601 219.308 65.7489 219.308C71.1376 219.308 76.2427 217.322 79.9298 213.919C80.2134 214.203 80.497 213.919 80.497 213.636C80.7806 213.636 80.7806 213.636 80.497 213.636ZM62.9127 206.829C62.6291 206.545 62.3454 206.262 62.3454 206.262C62.0618 205.978 61.7782 205.978 61.4946 205.695C61.4946 205.695 61.211 205.694 61.211 205.411C60.3601 204.844 59.5093 204.276 59.2256 203.426C59.2256 203.142 59.5093 202.575 60.0765 202.008C60.3601 202.291 60.6437 202.575 60.9273 203.142C61.211 203.426 61.4946 203.709 61.7782 203.993C62.0618 203.993 62.3454 204.276 62.3454 204.56C62.3454 204.56 62.3454 204.56 62.6291 204.56C62.6291 204.56 62.6291 204.56 62.9127 204.56V206.829Z"
            fill="#8E94A7"
          />
          <path
            d="M72.0487 207.802C71.769 208.921 71.2097 209.76 71.2097 209.76L60.582 208.082L61.1414 205.285C61.1414 205.285 61.4211 205.285 61.4211 205.565C61.7007 205.844 61.9804 205.844 62.2601 206.124C62.5398 206.124 62.5398 206.404 62.8194 206.683V204.726C62.8194 204.726 62.8194 204.726 62.5398 204.726C64.2178 205.565 66.4552 206.124 69.5316 204.167V207.522C69.8113 207.522 70.091 207.802 72.0487 207.802Z"
            fill="url(#paint5_linear_12728_9238)"
          />
          <path
            d="M69.8416 192.207C71.8368 192.207 73.832 191.922 75.5422 191.352C75.5422 191.352 75.5422 191.067 75.8273 190.782C75.5422 189.927 75.2572 188.787 74.6871 187.647C72.977 184.512 69.5566 182.517 65.8512 182.517C64.9961 182.517 64.1759 182.578 63.0322 182.863C61.5102 183.328 60.8035 183.737 60.443 183.955C60.443 183.955 59.6098 184.515 59.0104 185.082C58.411 185.648 57.0152 187.647 56.4451 189.072C55.8751 190.782 55.4151 192.236 55.7002 193.946C55.9852 193.946 56.4451 193.632 56.7302 193.632C59.0104 192.492 60.7206 191.067 61.5757 188.787C63.8559 190.782 66.7063 191.922 69.8416 192.207Z"
            fill="#2A2E37"
          />
          <path
            d="M60.1641 202.306C60.4436 202.585 60.7231 202.865 61.0026 203.144C60.7231 202.865 60.4436 202.585 60.1641 202.306Z"
            fill="#3973F4"
          />
          <path
            d="M64.0798 212.652C63.2408 213.771 61.8424 213.771 60.444 213.771C61.8424 212.373 61.0034 208.178 56.5286 208.737C50.0961 207.618 50.9351 200.906 57.0879 198.949L58.2066 198.669C58.2066 198.669 58.2066 198.669 58.4863 198.949C59.0457 200.347 59.605 201.186 60.444 202.305C57.927 204.542 61.5627 205.101 63.2408 206.779C64.3595 207.339 65.4782 210.974 64.0798 212.652Z"
            fill="#2A2E37"
          />
          <defs>
            <filter
              id="filter0_d_12728_9238"
              x="46.832"
              y="58.748"
              width="305.229"
              height="117.552"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_12728_9238"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_12728_9238"
                result="shape"
              />
            </filter>
            <filter
              id="filter1_d_12728_9238"
              x="46.832"
              y="242.204"
              width="305.229"
              height="117.552"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_12728_9238"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_12728_9238"
                result="shape"
              />
            </filter>
            <filter
              id="filter2_d_12728_9238"
              x="6"
              y="150.475"
              width="305.229"
              height="117.552"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_12728_9238"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_12728_9238"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_12728_9238"
              x1="199.362"
              y1="68.0467"
              x2="199.362"
              y2="144.093"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#5C6479" />
              <stop offset="0.9964" stopColor="#5C6479" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_12728_9238"
              x1="106.691"
              y1="119.55"
              x2="106.691"
              y2="115.155"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#8E94A7" />
              <stop offset="0.9913" stopColor="#6C707C" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_12728_9238"
              x1="199.362"
              y1="251.502"
              x2="199.362"
              y2="327.549"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#5C6479" />
              <stop offset="0.9964" stopColor="#5C6479" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_12728_9238"
              x1="106.691"
              y1="303.007"
              x2="106.691"
              y2="298.612"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#8E94A7" />
              <stop offset="0.9913" stopColor="#6C707C" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_12728_9238"
              x1="158.529"
              y1="159.773"
              x2="158.529"
              y2="235.82"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#5C6479" />
              <stop offset="0.9964" stopColor="#5C6479" />
            </linearGradient>
            <linearGradient
              id="paint5_linear_12728_9238"
              x1="66.2932"
              y1="209.586"
              x2="66.2932"
              y2="206.656"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#8E94A7" />
              <stop offset="0.9913" stopColor="#6C707C" />
            </linearGradient>
          </defs>
        </svg>
      )}
    </EmptyContainer>
  );
};
