import React, {useEffect} from 'react';
import {Popup} from 'shared/ui/Popup';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';
import {Grow} from 'shared/ui/Grow';
import {Table} from 'shared/ui/Table';
import {useLazyQuery} from '@apollo/client';
import {Skeleton} from 'shared/ui/Skeleton';
import {gql} from 'shared/__generated__';
import dayjs from 'dayjs';
import {Flex} from 'shared/ui/Flex';
import {CloseButton} from 'shared/ui/CloseButton';
import {getNumberFormat} from 'shared/lib/utils';
import {PaymentType} from 'shared/__generated__/graphql';
import {EmptyList} from 'shared/illustration/EmptyList';

const HISTORY_BONUS = gql(`
query GetBonusCardHistory($first: Int!, $page: Int, $bonusCardId: String!) {
  bonusCard(id: $bonusCardId) {
    payments(first: $first, page: $page) {
      paginatorInfo {
        currentPage
        hasMorePages
      }
      data {
        id
        type
        amount
        created_at
        updated_at
        booking {
          id
        }
      }
    }
  }
}
`);

const Row = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
`;
const titles = ['Время записи', 'Изменено', 'Сумма платежа', 'Сумма бонуса'];

export const HistoryPopup = ({
  visible,
  onClose,
  id,
}: {
  visible: boolean;
  onClose: () => void;
  id: string;
}) => {
  const [lazy, {data, loading}] = useLazyQuery(HISTORY_BONUS, {
    variables: {
      bonusCardId: id,
      first: 40,
      page: 1,
    },
  });

  useEffect(() => {
    visible && lazy();
  }, [lazy, visible]);

  const bonus = data?.bonusCard?.payments?.data ?? [];
  return (
    <Popup style={{paddingLeft: 0, paddingRight: 0}} {...{visible, onClose}}>
      <Grow style={{width: 744, maxHeight: '50vh', gap: 24}}>
        <Row>
          <Text typography="title20">История бонусов</Text>
          <CloseButton
            onClose={() => {
              onClose && onClose();
            }}
          />
        </Row>
        {loading ? (
          <SkeletonHistory />
        ) : bonus.length ? (
          <Table
            scrollable
            style={{maxHeight: '50vh', margin: '0 24px'}}
            titles={titles}
            rows={bonus.map(item => [
              dayjs(item.created_at).format('DD.MM.YYYY HH:mm'),
              dayjs(item.updated_at).format('DD.MM.YYYY HH:mm'),
              item.type === PaymentType.Payment
                ? getNumberFormat(item.amount, ' Б')
                : '-',
              item.type === PaymentType.Accrual
                ? getNumberFormat(item.amount, ' Б')
                : '-',
            ])}
          />
        ) : (
          <EmptyState />
        )}
      </Grow>
    </Popup>
  );
};

const SkeletonHistory = () => {
  return (
    <Row>
      <Skeleton style={{marginTop: 32}} height={248} width={'100%'}>
        {Array.from(Array(5).keys()).map((columnItem, index) => (
          <React.Fragment key={'column_' + columnItem}>
            <rect height={24} width={152} rx={8} x={0} y={index * 56} />
            <rect height={24} width={152} rx={8} x={192} y={index * 56} />
            <rect height={24} width={152} rx={8} x={384} y={index * 56} />
            <rect height={24} rx={8} x={576} y={index * 56} width={120} />
          </React.Fragment>
        ))}
      </Skeleton>
    </Row>
  );
};

const EmptyState = () => {
  return (
    <Flex flex={1} direction="column" gap={16} mb={24} alignItems="center">
      <EmptyList size={300} />
      <Text typography="text18" color="textTertiary">
        У данного клиента пока нету историй использования бонуса
      </Text>
    </Flex>
  );
};
