import * as React from 'react';
import {useColorScheme} from 'shared/lib/hooks';
export function EmptyTime({size = 200}: {size: number}) {
  const colorScheme = useColorScheme();
  return colorScheme === 'light' ? (
    <svg width={size} height={size} viewBox="0 0 200 217" fill="none">
      <path
        d="M106.797 171.183c39.309 0 71.178-31.975 71.178-71.557 0-39.583-32.009-71.558-71.178-71.558-39.31 0-71.178 31.976-71.178 71.558 0 39.582 31.869 71.557 71.178 71.557z"
        fill="#EAEEF9"
      />
      <path
        d="M145.059 28.068C130.357 57.58 108.776 69.32 88.997 76.332c-4.59 1.65-9.586 3.093-14.312 1.856-4.725-1.238-8.843-6.118-7.56-10.93 1.147-4.467 6.818-6.667 10.936-4.88 4.185 1.787 6.48 6.736 6.075 11.342-.405 4.605-3.173 8.66-6.683 11.479-3.578 2.818-7.83 4.536-12.151 5.842-9.519 2.887-19.442 4.262-28.69 7.905-9.316 3.643-18.16 10.173-21.333 19.727-2.295 6.805-1.35 14.503 1.62 20.965 2.971 6.461 8.034 11.891 13.704 16.221 8.709 6.599 19.442 10.723 30.243 10.448 10.801-.275 21.738-5.086 28.42-13.747 6.684-8.661 8.642-21.239 3.916-31.206-3.24-6.874-10.598-12.51-17.957-10.861-5.805 1.375-10.058 7.149-10.666 13.267-.607 6.117 2.026 12.166 5.94 16.702 3.916 4.606 9.114 7.836 14.515 10.655 7.898 4.055 17.214 7.148 25.382 3.711 7.764-3.23 12.286-11.41 15.257-19.315 2.97-7.904 5.198-16.496 10.598-23.026 6.481-7.836 17.417-11.479 27.205-9.142 9.789 2.337 18.025 10.585 20.455 20.483 1.89 7.699-.405 17.322-7.493 20.69-5.536 2.681-12.354.756-17.147-3.093-4.793-3.849-8.033-9.348-11.139-14.71"
        stroke="#D6DCE8"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeDasharray="4 4"
      />
      <g filter="url(#filter0_d_12387_9377)">
        <path
          d="M90.65 60.965c11.819 4.075 24.643-2.38 28.644-14.416 4.001-12.037-2.337-25.097-14.156-29.171-11.819-4.075-24.643 2.38-28.644 14.416-4 12.037 2.337 25.097 14.156 29.171z"
          fill="url(#paint0_linear_12387_9377)"
        />
      </g>
      <path
        d="M97.125 41.482c1.253.431 2.612-.253 3.036-1.528.424-1.276-.247-2.66-1.5-3.092-1.253-.432-2.612.252-3.036 1.528-.424 1.275.247 2.66 1.5 3.092z"
        fill="#989FB0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M98.434 37.544a1.68 1.68 0 00-2.14 1.077c-.298.899.175 1.874 1.058 2.178a1.68 1.68 0 002.139-1.076c.299-.899-.174-1.874-1.057-2.179zm-3.48.615c.55-1.653 2.31-2.539 3.934-1.98 1.622.56 2.492 2.353 1.943 4.006-.549 1.652-2.31 2.539-3.933 1.98-1.623-.56-2.493-2.353-1.944-4.006z"
        fill="#989FB0"
      />
      <path
        d="M101.01 29.452l-2.122 7.204M100.419 39.184l4.834.353"
        stroke="#989FB0"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M103.282 25.606c.343-.13.441-.598.218-1.044-.223-.446-.682-.702-1.025-.572-.343.13-.441.598-.218 1.044.223.446.682.702 1.025.572z"
        fill="#989FB0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M102.848 24.738c-.026.01-.033.045-.016.079.017.033.051.053.077.043.026-.01.034-.045.017-.079s-.052-.053-.078-.043zm-1.165.515c-.429-.858-.242-1.758.419-2.009.66-.25 1.543.242 1.972 1.1.429.859.242 1.758-.418 2.01-.66.25-1.544-.242-1.973-1.1z"
        fill="#989FB0"
      />
      <path
        d="M93.722 54.329c.343-.13.44-.598.217-1.044-.223-.446-.682-.702-1.025-.572-.343.13-.44.598-.217 1.044.223.446.682.702 1.025.572z"
        fill="#989FB0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M93.288 53.46c-.026.01-.034.046-.017.08.017.033.052.052.078.042.026-.01.033-.045.016-.078-.017-.034-.051-.053-.077-.043zm-1.165.516c-.43-.859-.242-1.758.418-2.009.66-.25 1.544.242 1.973 1.1.429.859.242 1.758-.419 2.009-.66.25-1.543-.242-1.972-1.1z"
        fill="#989FB0"
      />
      <path
        d="M84.388 35.133c.343-.13.44-.598.217-1.044-.223-.446-.682-.702-1.025-.571-.343.13-.44.597-.217 1.044.223.446.682.702 1.025.571z"
        fill="#989FB0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M83.952 34.265c-.026.01-.033.045-.017.078.017.034.052.053.078.044.026-.01.033-.045.016-.08-.017-.033-.051-.052-.077-.042zm-1.165.515c-.43-.858-.242-1.758.418-2.009.66-.25 1.544.242 1.973 1.1.429.859.242 1.758-.419 2.009-.66.251-1.543-.242-1.972-1.1z"
        fill="#989FB0"
      />
      <path
        d="M112.593 44.87c.343-.13.44-.598.217-1.044-.223-.446-.682-.702-1.025-.571-.343.13-.44.597-.217 1.043.223.447.682.703 1.025.572z"
        fill="#989FB0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M112.159 44.002c-.026.01-.033.045-.017.078.017.034.052.053.078.044.026-.01.033-.046.016-.08-.017-.033-.051-.052-.077-.042zm-1.165.515c-.429-.859-.242-1.758.418-2.009.661-.251 1.544.241 1.973 1.1.429.859.242 1.758-.419 2.009-.66.251-1.543-.241-1.972-1.1z"
        fill="#989FB0"
      />
      <path
        d="M29.99 67.775l3.737-1.147c.27-.08.27-.48 0-.56l-3.738-1.148a.27.27 0 01-.192-.196l-1.127-3.798c-.078-.275-.471-.275-.55 0l-1.127 3.798a.27.27 0 01-.192.196l-3.729 1.147c-.27.08-.27.48 0 .56l3.738 1.148a.27.27 0 01.192.196l1.127 3.806c.078.276.471.276.55 0l1.127-3.806a.246.246 0 01.183-.196zM149.195 23.218l3.823.807c.276.061.463-.29.263-.492l-2.748-2.825a.278.278 0 01-.078-.265l.789-3.885c.06-.28-.285-.471-.484-.267l-2.768 2.79a.266.266 0 01-.261.079l-3.815-.803c-.275-.061-.463.29-.262.492l2.747 2.825c.072.07.1.166.078.265l-.793 3.893c-.06.28.285.471.484.267l2.773-2.798a.24.24 0 01.252-.083z"
        fill="#EAEEF9"
      />
      <path
        d="M146.846 119.523l2.92-2.638c.213-.188.048-.552-.231-.509l-3.865.556a.266.266 0 01-.255-.096l-2.587-2.966c-.185-.217-.542-.049-.5.235l.542 3.929a.276.276 0 01-.094.259l-2.913 2.635c-.212.188-.047.552.231.509l3.866-.556a.264.264 0 01.255.096l2.591 2.974c.184.217.541.049.499-.235l-.545-3.937a.248.248 0 01.086-.256zM117.099 92.598l3.271-1.004c.237-.07.237-.42 0-.49l-3.271-1.004a.236.236 0 01-.168-.171l-.986-3.323c-.069-.241-.412-.241-.481 0l-.986 3.323a.236.236 0 01-.168.17l-3.263 1.005c-.237.07-.237.42 0 .49l3.27 1.004a.236.236 0 01.169.171l.985 3.33c.069.242.413.242.482 0l.986-3.33a.214.214 0 01.16-.171zM54.341 139.333l3.27-1.148c.238-.08.238-.48 0-.56l-3.27-1.147a.257.257 0 01-.168-.196l-.986-3.798c-.068-.276-.412-.276-.481 0l-.986 3.798a.257.257 0 01-.168.196l-3.263 1.147c-.237.08-.237.48 0 .56l3.27 1.148a.254.254 0 01.169.195l.986 3.807c.068.276.412.276.481 0l.986-3.807a.233.233 0 01.16-.195zM48.936 83.01l2.337-.717c.169-.05.169-.3 0-.35l-2.337-.717a.169.169 0 01-.12-.122l-.704-2.374c-.05-.172-.295-.172-.344 0l-.704 2.374a.169.169 0 01-.12.122l-2.33.717c-.17.05-.17.3 0 .35l2.335.717c.06.017.104.061.12.123l.705 2.379c.049.172.294.172.343 0l.705-2.38a.154.154 0 01.114-.122z"
        fill="#D6DCE8"
      />
      <path
        d="M183.536 84.582l2.336-.717c.169-.05.169-.3 0-.35l-2.336-.717a.168.168 0 01-.12-.123l-.704-2.373c-.049-.172-.295-.172-.344 0l-.704 2.373a.17.17 0 01-.12.123l-2.331.717c-.169.05-.169.3 0 .35l2.336.717c.06.017.104.061.12.122l.704 2.38c.049.172.295.172.344 0l.704-2.38a.154.154 0 01.115-.122z"
        fill="#EAEEF9"
      />
      <path
        d="M66.204 49.845l2.336-.717c.17-.05.17-.3 0-.35l-2.336-.717a.169.169 0 01-.12-.122l-.704-2.374c-.05-.172-.295-.172-.344 0l-.704 2.374a.169.169 0 01-.12.122l-2.331.717c-.17.05-.17.3 0 .35l2.336.717c.06.017.104.061.12.123l.704 2.379c.05.172.295.172.344 0l.704-2.38a.154.154 0 01.115-.122z"
        fill="#D6DCE8"
      />
      <path
        d="M31.415 129.739l2.336-.717c.17-.05.17-.3 0-.35l-2.336-.717a.17.17 0 01-.12-.122l-.704-2.374c-.05-.172-.295-.172-.344 0l-.704 2.374a.17.17 0 01-.12.122l-2.331.717c-.17.05-.17.3 0 .35l2.336.717c.06.017.104.061.12.123l.704 2.379c.05.172.295.172.344 0l.704-2.379a.154.154 0 01.115-.123z"
        fill="#EAEEF9"
      />
      <path
        d="M118.04 157.755s-14.309 13.512-15.27 13.582c-.636.249-6.266 2.626-9.852 4.16l-.949-1.378c-.474-.689-.948-1.377-1.383-2.301.176-.331.257-.8.338-1.268 1.835-5.834 7.539-14.843 19.561-14.387l7.555 1.592z"
        fill="#F1F3F9"
        stroke="#D6DCE8"
        strokeWidth={1.9781}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g filter="url(#filter1_d_12387_9377)">
        <path
          d="M95.624 92.98l4.65 6.75c1.328 1.929 2.237 3.541 2.93 5.719 2.592 6.987 1.625 14.667-1.943 20.917-.662 1.089-.999 2.358-.835 3.475.028 1.213.381 2.605 1.14 3.707a6.744 6.744 0 006.623 2.875c8.216-1.553 16.666 1.044 22.453 7.394.705.73 1.274 1.557 1.938 2.521l4.651 6.751c.569.826 1.003 1.75 1.478 2.439l-39.773 28.414c-.61-.592-1.274-1.557-1.749-2.245l-4.65-6.751c-.474-.689-.813-1.475-1.288-2.163-.19-.276-.34-.786-.624-1.199-3.663-7.662-3.225-16.403.965-23.508 1.325-2.18 1.119-5.116-.4-7.32-.664-.965-1.694-1.874-2.804-2.314-.095-.137-.23-.041-.23-.041-1.111-.44-2.302-.411-3.494-.382-5.522 1.067-11.182.177-16.288-2.548-1.3-.715-2.6-1.431-3.725-2.477-.325-.179-.61-.593-.8-.868-1.22-1.185-2.534-2.507-3.578-4.022l-4.65-6.751-.474-.689 40.042-28.608c.15.51.245.648.435.924z"
          fill="url(#paint1_linear_12387_9377)"
        />
      </g>
      <path
        d="M92.566 121.066l-15.8 12.521-8.16 1.925c-1.3-.715-2.601-1.431-3.726-2.478-.325-.178-.61-.592-.8-.867 4.366-7.436 8.382-12.155 20.063-14.539 1.962-.374 3.79-.651 5.982-.985 19.519-2.432 2.441 4.423 2.441 4.423z"
        fill="#BAC3D1"
      />
      <path
        d="M103.298 105.587c2.592 6.987 1.625 14.668-1.943 20.917-.662 1.09-.999 2.358-.835 3.475l-.136.097c-2.164 1.546-2.73 4.828-1.211 7.032l14.14 20.528-3.923 2.803-14.235-20.666c-1.519-2.204-4.606-2.877-6.906-1.234-1.11-.44-2.301-.411-3.493-.382-5.522 1.067-11.182.177-16.288-2.548-1.3-.715-2.6-1.431-3.725-2.477 12.046-2.439 16.1-9.447 19.222-15.172 1.013-1.752 1.891-3.407 2.865-4.925 3.852-5.836 9.536-7.841 16.468-7.448z"
        fill="#D5DDEA"
      />
      <path
        d="M132.445 146.435l4.65 6.751c.569.826 1.004 1.75 1.478 2.438l-35.443 25.322-6.512-.076-4.176-6.062c-.474-.689-.949-1.378-1.288-2.163-.19-.276-.34-.786-.624-1.199.135-.096.23.041.366-.055.23.041.365-.056.5-.153 13.183-2.839 16.708-10.908 20.046-17.199a25.926 25.926 0 011.811-2.938c4.083-5.795 9.997-7.759 17.159-7.325.664.965 1.369 1.694 2.033 2.659z"
        fill="#D5DDEA"
      />
      <path
        d="M98.875 92.714l-44.372 31.7c-.947.677-2.328.43-2.898-.397l-3.036-4.408c-.665-.965-.328-2.233.62-2.91l44.507-31.797c.947-.677 2.233-.567 2.897.397l2.942 4.27c.394 1.159.287 2.468-.66 3.145z"
        fill="url(#paint2_linear_12387_9377)"
      />
      <path
        d="M143.667 157.742l-44.372 31.701c-.947.676-2.234.567-2.898-.397l-2.942-4.271c-.664-.965-.327-2.233.62-2.91l44.507-31.797c.947-.676 2.233-.568 2.898.397l2.942 4.271c.393 1.157.192 2.33-.755 3.006z"
        fill="url(#paint3_linear_12387_9377)"
      />
      <path
        d="M145.338 87.46s-9.663-3.675-9.924-4.121a521.45 521.45 0 00-4.268-3.751l.414-.784c.207-.392.414-.784.739-1.212.195.006.432-.066.668-.138 3.142-.502 8.674.103 11.316 5.994l1.055 4.012z"
        fill="#F1F3F9"
        stroke="#D6DCE8"
        strokeWidth={1.9781}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g filter="url(#filter2_d_12387_9377)">
        <path
          d="M170.064 61.302l-2.027 3.84c-.58 1.096-1.112 1.916-1.957 2.766-2.625 2.906-6.417 4.264-10.162 4.031-.663-.06-1.332.08-1.811.423-.556.302-1.117.802-1.448 1.429a3.715 3.715 0 00.239 3.868c2.67 3.583 3.471 8.265 1.9 12.556-.171.512-.42.982-.709 1.53l-2.027 3.84c-.249.47-.574.898-.781 1.29l-22.619-12.387c.13-.434.419-.983.626-1.374l2.027-3.84c.207-.391.491-.741.698-1.133.082-.157.284-.35.408-.585 2.684-3.58 6.842-5.444 11.131-5.116 1.326.12 2.639-.676 3.301-1.93.289-.548.466-1.26.407-1.898.041-.078-.036-.12-.036-.12-.059-.639-.355-1.205-.651-1.771-1.806-2.404-2.737-5.337-2.685-8.44.023-.796.046-1.591.264-2.38.006-.2.13-.434.213-.591.26-.868.561-1.814 1.016-2.676l2.027-3.84.207-.391 22.774 12.47a1.31 1.31 0 00-.325.429z"
          fill="url(#paint4_linear_12387_9377)"
        />
      </g>
      <path
        d="M156.309 66.498l-9.557-4.628-2.83-3.47c.023-.795.046-1.59.264-2.38.006-.198.13-.433.213-.59 4.485.335 7.627 1.146 11.505 6.2.64.854 1.202 1.668 1.877 2.643 5.762 8.812-1.472 2.225-1.472 2.225z"
        fill="#BAC3D1"
      />
      <path
        d="M166.036 67.986c-2.625 2.906-6.417 4.264-10.162 4.03-.663-.059-1.331.08-1.81.424l-.077-.042c-1.231-.674-2.888-.167-3.55 1.086l-6.164 11.675-2.231-1.222 6.205-11.753c.662-1.254.241-2.898-1.067-3.614-.059-.64-.355-1.205-.651-1.772-1.806-2.403-2.737-5.336-2.685-8.44.023-.795.046-1.59.265-2.38 3.99 5.216 8.203 5.503 11.599 5.645 1.053.072 2.029.102 2.964.21 3.621.467 5.901 2.725 7.364 6.153z"
        fill="#D5DDEA"
      />
      <path
        d="M154.009 91.703l-2.027 3.84c-.248.47-.574.898-.78 1.29l-20.158-11.04-1.511-3.15 1.821-3.447c.207-.392.413-.784.697-1.133.083-.157.284-.35.408-.585.077.042.036.12.113.163.035.12.112.162.189.204 4.447 5.668 9.026 5.448 12.735 5.56.586.018 1.249.078 1.793.174 3.657.588 5.972 2.967 7.47 6.515-.289.549-.46 1.06-.75 1.61z"
        fill="#D5DDEA"
      />
      <path
        d="M170.956 62.801l-25.235-13.82c-.539-.294-.752-1.017-.504-1.487l1.324-2.507c.289-.549.958-.688 1.496-.393l25.313 13.862c.538.294.793.94.504 1.487l-1.283 2.43c-.443.464-1.076.723-1.615.428z"
        fill="url(#paint5_linear_12387_9377)"
      />
      <path
        d="M151.433 99.785l-25.236-13.82c-.538-.294-.793-.939-.503-1.487l1.282-2.43c.29-.548.958-.687 1.497-.392l25.312 13.862c.539.294.794.94.504 1.488l-1.282 2.428c-.444.465-1.035.646-1.574.35z"
        fill="url(#paint6_linear_12387_9377)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_12387_9377"
          x1={105.459}
          y1={16.3644}
          x2={89.9825}
          y2={61.259}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FDFEFF" />
          <stop offset={0.9964} stopColor="#ECF0F5" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_12387_9377"
          x1={74.1452}
          y1={104.904}
          x2={120.39}
          y2={169.634}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FDFEFF" />
          <stop offset={0.9964} stopColor="#ECF0F5" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_12387_9377"
          x1={50.0655}
          y1={121.83}
          x2={98.6954}
          y2={88.332}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#B0BACC" />
          <stop offset={1} stopColor="#969EAE" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_12387_9377"
          x1={94.9047}
          y1={186.927}
          x2={143.535}
          y2={153.429}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#B0BACC" />
          <stop offset={1} stopColor="#969EAE" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_12387_9377"
          x1={159.435}
          y1={53.8006}
          x2={139.197}
          y2={90.7578}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FDFEFF" />
          <stop offset={0.9964} stopColor="#ECF0F5" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_12387_9377"
          x1={145.866}
          y1={46.2339}
          x2={173.418}
          y2={60.7811}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#B0BACC" />
          <stop offset={1} stopColor="#969EAE" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_12387_9377"
          x1={126.322}
          y1={83.2568}
          x2={153.874}
          y2={97.8039}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#B0BACC" />
          <stop offset={1} stopColor="#969EAE" />
        </linearGradient>
      </defs>
    </svg>
  ) : (
    <svg width={size} height={size} viewBox="0 0 200 215" fill="none">
      <path
        d="M103.731 169.27c38.729 0 70.126-31.398 70.126-70.265 0-38.866-31.536-70.264-70.126-70.264-38.728 0-70.125 31.398-70.125 70.264 0 38.867 31.397 70.265 70.125 70.265z"
        fill="#2A2E37"
      />
      <path
        d="M145.059 28.741c-14.702 28.979-36.283 40.507-56.062 47.391-4.59 1.62-9.586 3.038-14.312 1.823-4.725-1.215-8.843-6.007-7.56-10.732 1.147-4.387 6.818-6.547 10.936-4.792 4.185 1.755 6.48 6.615 6.075 11.137-.405 4.522-3.172 8.504-6.683 11.271-3.578 2.767-7.83 4.455-12.151 5.737-9.519 2.835-19.442 4.185-28.69 7.762-9.316 3.577-18.16 9.989-21.333 19.371-2.295 6.682-1.35 14.241 1.62 20.585 2.971 6.345 8.034 11.677 13.704 15.929 8.709 6.479 19.442 10.529 30.243 10.259 10.802-.27 21.738-4.995 28.42-13.499 6.684-8.504 8.642-20.855 3.916-30.642-3.24-6.749-10.598-12.284-17.957-10.664-5.805 1.35-10.058 7.019-10.666 13.026-.607 6.007 2.026 11.947 5.94 16.401 3.916 4.522 9.114 7.694 14.515 10.462 7.898 3.982 17.214 7.019 25.382 3.644 7.764-3.172 12.287-11.204 15.257-18.965 2.97-7.762 5.198-16.199 10.598-22.611 6.481-7.694 17.417-11.271 27.206-8.976 9.788 2.294 18.024 10.394 20.454 20.113 1.89 7.559-.405 17.008-7.493 20.315-5.536 2.633-12.354.743-17.147-3.037-4.793-3.78-8.033-9.179-11.138-14.444"
        stroke="#4F576B"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeDasharray="4 4"
      />
      <g filter="url(#filter0_d_12387_9427)">
        <path
          d="M90.651 61.044c11.819 4.001 24.644-2.336 28.645-14.155 4.001-11.82-2.337-24.644-14.156-28.645-11.819-4-24.643 2.337-28.644 14.156-4.001 11.819 2.337 24.643 14.156 28.644z"
          fill="#5C6479"
        />
      </g>
      <path
        d="M97.129 41.912a2.394 2.394 0 101.536-4.536 2.394 2.394 0 00-1.536 4.536z"
        fill="#989FB0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M98.436 38.046a1.687 1.687 0 10-1.082 3.197 1.687 1.687 0 001.082-3.197zm-3.48.604a3.102 3.102 0 115.877 1.99 3.102 3.102 0 01-5.876-1.99z"
        fill="#989FB0"
      />
      <path
        d="M101.012 30.1l-2.123 7.074M100.421 39.656l4.834.347"
        stroke="#989FB0"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M103.236 26.237c.369-.175.507-.634.31-1.025-.198-.39-.657-.564-1.025-.389-.369.176-.507.635-.31 1.026.198.39.657.564 1.025.388z"
        fill="#989FB0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M102.852 25.477c-.028.014-.038.048-.024.078.015.03.05.042.078.03.028-.014.038-.049.023-.078-.015-.03-.049-.043-.077-.03zm-1.258.666c-.38-.751-.113-1.634.597-1.973.709-.338 1.592-.003 1.972.748.38.752.113 1.635-.596 1.973-.709.338-1.592.003-1.973-.748z"
        fill="#989FB0"
      />
      <path
        d="M93.676 54.44c.368-.175.507-.634.31-1.024-.198-.39-.657-.565-1.026-.389-.368.176-.507.635-.31 1.025.198.39.657.565 1.026.389z"
        fill="#989FB0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M93.29 53.681c-.029.013-.039.048-.024.077.015.03.05.043.077.03.028-.013.039-.048.024-.078s-.05-.042-.078-.029zm-1.258.666c-.38-.752-.113-1.635.596-1.973.71-.338 1.593-.003 1.973.748.38.751.113 1.635-.597 1.973-.709.338-1.592.003-1.972-.748z"
        fill="#989FB0"
      />
      <path
        d="M84.345 35.593c.369-.176.508-.635.31-1.025-.197-.39-.656-.564-1.025-.389-.368.176-.507.635-.31 1.025.198.39.657.565 1.025.39z"
        fill="#989FB0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M83.96 34.833c-.029.013-.04.048-.024.077.015.03.05.043.077.03.028-.014.038-.048.024-.078-.015-.03-.05-.042-.078-.03zm-1.258.665c-.38-.75-.113-1.634.596-1.972.71-.338 1.592-.003 1.972.748.38.751.114 1.634-.596 1.972-.709.339-1.592.004-1.972-.748z"
        fill="#989FB0"
      />
      <path
        d="M112.549 45.154c.368-.176.507-.634.309-1.025-.197-.39-.656-.564-1.025-.389-.368.176-.507.635-.309 1.026.197.39.656.564 1.025.388z"
        fill="#989FB0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M112.164 44.394c-.028.013-.038.048-.023.077.015.03.049.043.077.03a.056.056 0 00.024-.078c-.015-.03-.05-.042-.078-.029zm-1.257.666c-.38-.752-.113-1.635.596-1.973.709-.338 1.592-.003 1.973.748.38.751.113 1.634-.596 1.973-.71.338-1.593.003-1.973-.748z"
        fill="#989FB0"
      />
      <path
        d="M29.987 67.73l3.738-1.126c.27-.079.27-.472 0-.55l-3.738-1.127a.268.268 0 01-.192-.192l-1.127-3.73c-.078-.27-.471-.27-.55 0l-1.127 3.73a.268.268 0 01-.192.192l-3.729 1.127c-.27.078-.27.471 0 .55l3.738 1.127a.268.268 0 01.192.192l1.127 3.738c.079.27.472.27.55 0l1.127-3.738a.243.243 0 01.183-.192zM149.194 23.979l3.822.792c.276.06.463-.285.263-.483l-2.748-2.773a.267.267 0 01-.077-.26l.788-3.816c.06-.275-.285-.463-.483-.262l-2.769 2.74a.269.269 0 01-.261.077l-3.815-.788c-.275-.06-.463.285-.262.483l2.748 2.773a.27.27 0 01.077.26l-.793 3.823c-.06.276.285.463.484.263l2.773-2.748a.244.244 0 01.253-.081z"
        fill="#2A2E37"
      />
      <path
        d="M146.844 118.544l2.92-2.591c.213-.185.048-.542-.23-.5l-3.866.546a.27.27 0 01-.255-.094l-2.587-2.912c-.185-.213-.542-.048-.5.23l.542 3.858a.27.27 0 01-.094.255l-2.912 2.587c-.213.185-.048.542.23.499l3.866-.545a.27.27 0 01.255.094l2.591 2.92c.185.213.541.048.499-.231l-.545-3.865a.242.242 0 01.086-.251zM117.099 92.105l3.271-.986c.237-.069.237-.412 0-.481l-3.271-.986a.234.234 0 01-.168-.168l-.986-3.263c-.069-.237-.412-.237-.481 0l-.986 3.263a.234.234 0 01-.168.168l-3.263.986c-.237.069-.237.412 0 .481l3.27.986a.234.234 0 01.169.168l.985 3.27c.069.237.413.237.482 0l.986-3.27a.212.212 0 01.16-.168zM54.34 137.995l3.27-1.126c.237-.079.237-.472 0-.551l-3.27-1.126a.253.253 0 01-.169-.192l-.986-3.73c-.068-.27-.412-.27-.481 0l-.986 3.73a.253.253 0 01-.168.192l-3.263 1.126c-.237.079-.237.472 0 .551l3.27 1.126a.253.253 0 01.169.192l.986 3.738c.068.271.412.271.481 0l.986-3.738a.232.232 0 01.16-.192zM48.934 82.69l2.337-.704c.169-.049.169-.294 0-.344l-2.337-.704a.168.168 0 01-.12-.12l-.704-2.33c-.049-.17-.295-.17-.344 0l-.704 2.33a.167.167 0 01-.12.12l-2.33.704c-.17.05-.17.295 0 .344l2.335.704c.06.017.104.06.12.12l.705 2.336c.049.17.294.17.344 0l.704-2.336a.152.152 0 01.114-.12z"
        fill="#959EB0"
      />
      <path
        d="M183.538 84.234l2.336-.704c.169-.05.169-.295 0-.344l-2.336-.704a.167.167 0 01-.12-.12l-.704-2.33c-.049-.17-.295-.17-.344 0l-.704 2.33a.168.168 0 01-.12.12l-2.331.704c-.169.049-.169.295 0 .344l2.336.704c.06.016.104.06.12.12l.704 2.336c.049.17.295.17.344 0l.704-2.336a.153.153 0 01.115-.12z"
        fill="#2A2E37"
      />
      <path
        d="M66.202 50.125l2.336-.704c.17-.05.17-.295 0-.344l-2.336-.704a.167.167 0 01-.12-.12l-.704-2.33c-.05-.17-.295-.17-.344 0l-.704 2.33a.167.167 0 01-.12.12l-2.331.704c-.17.05-.17.295 0 .344l2.336.704c.06.016.104.06.12.12l.704 2.336c.05.17.295.17.344 0l.704-2.336a.152.152 0 01.115-.12z"
        fill="#959EB0"
      />
      <path
        d="M31.413 128.575l2.336-.704c.17-.05.17-.295 0-.344l-2.336-.704a.168.168 0 01-.12-.12l-.704-2.331c-.05-.169-.295-.169-.344 0l-.704 2.331a.168.168 0 01-.12.12l-2.331.704c-.17.049-.17.294 0 .344l2.336.704c.06.016.104.06.12.12l.704 2.336c.05.169.295.169.344 0l.704-2.336a.152.152 0 01.115-.12z"
        fill="#2A2E37"
      />
      <g filter="url(#filter1_d_12387_9427)">
        <path
          d="M95.624 92.48l4.65 6.629c1.328 1.894 2.237 3.477 2.93 5.615 2.592 6.861 1.625 14.402-1.943 20.539-.662 1.07-.999 2.316-.835 3.412.028 1.191.381 2.558 1.14 3.64 1.519 2.165 4.065 3.205 6.623 2.823 8.216-1.525 16.666 1.026 22.453 7.261.705.716 1.274 1.528 1.938 2.475l4.651 6.629c.569.811 1.003 1.718 1.478 2.394l-39.773 27.901c-.61-.581-1.274-1.528-1.749-2.205l-4.65-6.628c-.474-.677-.813-1.448-1.288-2.125-.19-.27-.34-.771-.624-1.177-3.663-7.523-3.225-16.106.965-23.082 1.325-2.141 1.119-5.024-.4-7.188-.664-.947-1.694-1.84-2.804-2.272-.095-.135-.23-.04-.23-.04-1.111-.432-2.302-.404-3.494-.376-5.522 1.048-11.182.174-16.288-2.501-1.3-.703-2.6-1.406-3.725-2.433-.325-.176-.61-.582-.8-.852-1.22-1.163-2.534-2.462-3.578-3.95l-4.65-6.629-.474-.676 40.042-28.09c.15.5.245.636.435.906z"
          fill="#5C6479"
        />
      </g>
      <path
        d="M118.038 156.084s-14.309 13.268-15.27 13.337c-.636.244-6.266 2.578-9.852 4.085l-.948-1.353c-.475-.676-.95-1.353-1.384-2.259.176-.326.257-.786.338-1.246 1.835-5.729 7.539-14.574 19.561-14.126l7.555 1.562zM92.564 120.059l-15.8 12.295-8.16 1.89c-1.3-.703-2.601-1.406-3.726-2.433-.325-.176-.61-.582-.8-.852 4.366-7.302 8.382-11.935 20.063-14.276 1.962-.367 3.79-.64 5.982-.967 19.519-2.389 2.441 4.343 2.441 4.343z"
        fill="#272B33"
      />
      <path
        d="M103.298 104.859c2.592 6.861 1.625 14.403-1.943 20.539-.662 1.07-.999 2.316-.835 3.412l-.136.095c-2.164 1.519-2.73 4.741-1.211 6.906l14.14 20.156-3.923 2.753-14.235-20.292c-1.519-2.165-4.606-2.825-6.906-1.212-1.11-.432-2.301-.404-3.493-.375-5.522 1.048-11.182.173-16.288-2.502-1.3-.703-2.6-1.405-3.725-2.433 12.046-2.395 16.1-9.276 19.222-14.897 1.013-1.721 1.891-3.346 2.865-4.836 3.852-5.73 9.536-7.699 16.468-7.314z"
        fill="#3E4350"
      />
      <path
        d="M132.443 144.969l4.65 6.629c.57.811 1.004 1.718 1.478 2.394l-35.443 24.864-6.512-.074-4.176-5.953c-.474-.676-.949-1.352-1.288-2.124-.19-.27-.34-.771-.624-1.177.135-.095.23.041.366-.054.23.04.365-.055.5-.15 13.183-2.788 16.709-10.711 20.046-16.888a25.43 25.43 0 011.811-2.885c4.083-5.69 9.997-7.619 17.159-7.193.664.947 1.369 1.664 2.033 2.611z"
        fill="#3E4350"
      />
      <path
        d="M98.873 92.219L54.5 123.346c-.947.665-2.328.422-2.898-.389l-3.037-4.329c-.664-.947-.327-2.193.62-2.857l44.508-31.223c.947-.664 2.233-.557 2.897.39l2.942 4.194c.394 1.136.287 2.423-.66 3.087z"
        fill="url(#paint0_linear_12387_9427)"
      />
      <path
        d="M143.665 156.071l-44.372 31.128c-.947.664-2.234.557-2.898-.39l-2.942-4.193c-.664-.947-.327-2.193.62-2.858l44.507-31.222c.947-.664 2.234-.557 2.898.39l2.942 4.193c.394 1.137.192 2.288-.755 2.952z"
        fill="url(#paint1_linear_12387_9427)"
      />
      <g filter="url(#filter2_d_12387_9427)">
        <path
          d="M170.066 61.374l-2.028 3.77c-.579 1.078-1.111 1.882-1.956 2.717-2.626 2.853-6.417 4.187-10.162 3.958-.663-.059-1.332.078-1.811.415-.556.297-1.117.788-1.448 1.404a3.594 3.594 0 00.238 3.798c2.671 3.518 3.472 8.115 1.901 12.328-.171.504-.42.965-.709 1.504l-2.027 3.77c-.249.461-.574.882-.781 1.266l-22.619-12.163c.13-.426.419-.964.626-1.349l2.027-3.77c.207-.385.491-.728.698-1.113.082-.154.283-.343.408-.574 2.684-3.516 6.842-5.346 11.131-5.023 1.326.117 2.639-.665 3.301-1.896.289-.538.466-1.236.407-1.864.041-.076-.036-.118-.036-.118-.059-.627-.355-1.183-.652-1.739-1.805-2.36-2.736-5.24-2.684-8.287.023-.781.046-1.562.264-2.337.006-.196.13-.426.213-.58.26-.852.561-1.782 1.016-2.628l2.027-3.77.207-.385 22.774 12.246c-.201.19-.243.267-.325.42z"
          fill="#5C6479"
        />
      </g>
      <path
        d="M145.342 87.06s-9.663-3.609-9.924-4.046a519.903 519.903 0 00-4.268-3.684l.414-.77c.207-.384.413-.769.739-1.189.195.006.431-.065.668-.136 3.141-.493 8.674.101 11.316 5.886l1.055 3.94zM156.311 66.477l-9.557-4.545-2.83-3.406c.023-.78.046-1.562.264-2.337.006-.195.13-.426.213-.58 4.485.329 7.627 1.126 11.505 6.087.64.84 1.202 1.638 1.877 2.597 5.762 8.652-1.472 2.183-1.472 2.183z"
        fill="#272B33"
      />
      <path
        d="M166.04 67.938c-2.625 2.853-6.417 4.187-10.162 3.958-.663-.058-1.332.078-1.811.415l-.077-.04c-1.231-.663-2.887-.165-3.549 1.066l-6.164 11.464-2.231-1.2 6.205-11.54c.662-1.232.241-2.847-1.067-3.55-.059-.627-.355-1.183-.651-1.739-1.806-2.36-2.737-5.24-2.685-8.287.023-.781.046-1.562.264-2.337 3.991 5.12 8.204 5.402 11.6 5.542 1.053.07 2.029.1 2.964.206 3.621.46 5.9 2.677 7.364 6.042z"
        fill="#3E4350"
      />
      <path
        d="M154.013 91.226l-2.027 3.77c-.248.462-.574.882-.78 1.267l-20.158-10.84-1.511-3.093 1.821-3.385c.206-.384.413-.77.697-1.112.083-.154.284-.344.408-.575.077.042.036.119.113.16.035.118.112.16.189.201 4.446 5.565 9.026 5.35 12.735 5.46a13.44 13.44 0 011.793.17c3.657.578 5.972 2.913 7.47 6.397-.289.539-.46 1.042-.75 1.58z"
        fill="#3E4350"
      />
      <path
        d="M170.958 62.847l-25.235-13.57c-.539-.29-.752-.999-.504-1.46l1.324-2.463c.289-.538.958-.675 1.496-.385l25.313 13.61c.538.29.793.923.504 1.462l-1.283 2.385c-.443.456-1.076.71-1.615.421z"
        fill="url(#paint2_linear_12387_9427)"
      />
      <path
        d="M151.433 99.162l-25.236-13.57c-.538-.289-.793-.922-.503-1.46l1.282-2.386c.29-.538.958-.674 1.497-.385l25.312 13.611c.539.29.794.922.504 1.461l-1.282 2.385c-.444.456-1.035.634-1.574.344z"
        fill="url(#paint3_linear_12387_9427)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_12387_9427"
          x1={50.0635}
          y1={120.809}
          x2={98.1189}
          y2={87.0972}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#B0BACC" />
          <stop offset={1} stopColor="#969EAE" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_12387_9427"
          x1={94.9028}
          y1={184.729}
          x2={142.958}
          y2={151.017}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#B0BACC" />
          <stop offset={1} stopColor="#969EAE" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_12387_9427"
          x1={145.868}
          y1={46.5792}
          x2={173.198}
          y2={61.2751}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#B0BACC" />
          <stop offset={1} stopColor="#969EAE" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_12387_9427"
          x1={126.322}
          y1={82.9328}
          x2={153.653}
          y2={97.6287}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#B0BACC" />
          <stop offset={1} stopColor="#969EAE" />
        </linearGradient>
      </defs>
    </svg>
  );
}
