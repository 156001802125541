import React, {useState} from 'react';
import {useQuery} from '@apollo/client';
import dayjs from 'dayjs';
import {gql} from 'shared/__generated__';
import {Content} from 'shared/ui/Content';
import {Error as ErrorComponent} from 'shared/ui/Error';
import styled from 'styled-components';
import {Text} from 'shared/ui/Text';
import {store, useAppSelector} from 'shared/store';
import {
  SubscriptionsStatus,
  SubscriptionsType,
} from 'shared/__generated__/graphql';
import {Receipt} from 'shared/icons/Receipt';
import {Document} from 'shared/icons/Document';
import {useColors} from 'shared/lib/hooks';
import {Flex} from 'shared/ui/Flex';
import {showAlert} from 'shared/ui/Alert';
import {Loader} from 'shared/icons/Loader';
import {Skeleton} from 'shared/ui/Skeleton';
import {Table} from 'shared/ui/Table';
import {Seo} from 'shared/ui/Seo';
import {getPriceFormat} from 'shared/lib/utils';
import {EmptyRecord} from 'shared/illustration/EmptyRecord';
import {TooltipPayments} from './ui/TooltipPayments';

const Head = styled.div`
  height: 56px;
  margin: 24px 0 24px 0;
  display: flex;
  align-items: center;
`;

const CentralBlock = styled.div`
  padding: 0 24px 16px 24px;
  width: 100%;
`;
const DownloadItem = styled(Flex)`
  pointer-events: all;
  cursor: pointer;
  height: 48px;
  padding: 0 16px;
  &:hover {
    background-color: ${({theme}) => theme.bgSecondary};
  }
`;

const PAYMENTS = gql(`
  query CompanyPaymentHistory($companyId: String, $first: Int!, $page: Int) {
    company(id: $companyId) {
      id
      subscriptions(first: $first, page: $page) {
        data {
          type
          id
          created_at
          status
          summary
          bonus_account
          bonus_account_before
          slots
        }
        paginatorInfo {
          total
          currentPage
          hasMorePages
          count
          lastPage
        }
      }
    }
  }
`);
type OptionsType = {
  page: number;
  first: number;
  total?: number;
  count?: number;
};

const getDescription = (type: SubscriptionsType, slots: number) => {
  if (type === SubscriptionsType.Forever)
    return 'Тариф бесплатный. 1 пользователь';
  const countEmployeesText = slots
    ? '1 пользователь'
    : `До ${slots} пользователей`;
  switch (type) {
    case SubscriptionsType.Threemonths:
      return 'Тариф 3 месяца. ' + countEmployeesText;
    case SubscriptionsType.Sixmonths:
      return 'Тариф 6 месяцев. ' + countEmployeesText;
    case SubscriptionsType.Year:
      return 'Тариф год. ' + countEmployeesText;
    default:
      return 'Тариф месяц. ' + countEmployeesText;
  }
};

async function pdf(route: string, type: 'check' | 'invoice') {
  const token = store.getState().auth.token;
  const DOMAIN = process.env.REACT_APP_DOMAIN;
  const headers = {
    ...(token ? {Authorization: `Bearer ${token}`} : null),
    'Content-Type': 'application/json',
    Accept: 'application/pdf',
  };
  const res = await fetch(`${DOMAIN}${route}`, {
    method: 'GET',
    headers,
  });

  if (!res.ok) {
    throw new Error(`Ошибка скачивания файла, ${res.status}`);
  }
  await res.blob().then(blob => {
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `${type === 'check' ? 'Act' : 'Invoice'}.pdf`,
    );
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
  });
}

async function getInvoice(subscription_id: string, type: 'check' | 'invoice') {
  return await pdf(
    `/api/payment/${type}?subscription_id=` + subscription_id,
    type,
  );
}

export function PaymentHistory() {
  const companyId = useAppSelector(state => state.company.data!.id);
  const colors = useColors();
  const [options, setOptions] = useState<OptionsType>({
    page: 1,
    first: 10,
    total: 0,
    count: 0,
  });
  const [downloadLoading, setDownloadLoading] = useState<
    'invoice' | 'check' | null
  >(null);

  const {loading, data, error} = useQuery(PAYMENTS, {
    variables: {companyId, first: options.first, page: options.page},
    onCompleted: dataRes => {
      setOptions(prev => ({
        ...prev,
        total: dataRes.company?.subscriptions?.paginatorInfo.total,
        count: dataRes.company?.subscriptions?.paginatorInfo.count,
      }));
    },
  });
  const subscriptions = data?.company?.subscriptions?.data ?? [];

  const download = async (id: string, type: 'check' | 'invoice') => {
    try {
      if (!id) {
        throw new Error('Не выбран платеж');
      }
      setDownloadLoading(type);
      await getInvoice(id, type);
    } catch (err) {
      if (err instanceof Error) {
        showAlert('error', err.message);
      }
    } finally {
      setDownloadLoading(null);
    }
  };

  if (error) {
    return <ErrorComponent message={error.message} />;
  }
  return (
    <CentralBlock>
      <Seo title="История платежей" />
      <Head>
        <Text typography="title24">История платежей</Text>
      </Head>
      {loading ? (
        <Content>
          <SkeletonPaymentHistory />
        </Content>
      ) : !subscriptions.length ? (
        <EmptyState />
      ) : (
        <Content>
          <Table
            options={options}
            setOptions={setOptions}
            widths={[undefined, undefined, undefined, undefined, undefined, 60]}
            titles={['Описание', 'Дата', 'Остаток', 'Сумма', 'Статус', '']}
            columnNoWrap={[1, 2, 3]}
            rows={subscriptions.map((item, index) => {
              const bonusAccount = parseInt(item.bonus_account ?? '0');
              const bonusAccountBefore = parseInt(
                item.bonus_account_before ?? '0',
              );
              const status =
                item.type !== SubscriptionsType.Forever
                  ? item.status
                  : undefined;
              const color =
                status === SubscriptionsStatus.Completed
                  ? colors.successPrimary
                  : status === SubscriptionsStatus.Failed ||
                    status === SubscriptionsStatus.Pending
                  ? colors.dangerPrimary
                  : colors.textPrimary;
              return [
                getDescription(item.type, item.slots),
                dayjs(item.created_at).format('DD.MM.YYYY'),
                getPriceFormat(bonusAccountBefore + bonusAccount),
                <Text
                  key={index + '_column'}
                  style={{
                    color:
                      item.status === SubscriptionsStatus.Completed
                        ? colors.successPrimary
                        : colors.textPrimary,
                  }}>
                  {item.type === SubscriptionsType.Forever
                    ? 'Бесплатный'
                    : (item.status === SubscriptionsStatus.Completed
                        ? '+ '
                        : '') + getPriceFormat(item.summary)}
                </Text>,
                <Text key={index + '_column_2'} style={{color}}>
                  {item.type === SubscriptionsType.Forever
                    ? '-'
                    : item.status === SubscriptionsStatus.Completed
                    ? 'Пополнение'
                    : 'Отменен'}
                </Text>,
                <TooltipPayments key={'tooltip_payments_' + index}>
                  <DownloadItem
                    onClick={e => {
                      e.stopPropagation();
                      download(item.id, 'invoice');
                    }}
                    alignItems="center"
                    gap={10}>
                    <Receipt />
                    <Text style={{pointerEvents: 'none'}}>Скачать счёт</Text>
                    {downloadLoading === 'invoice' ? <Loader /> : null}
                  </DownloadItem>
                  <DownloadItem
                    onClick={e => {
                      e.stopPropagation();
                      download(item.id, 'check');
                    }}
                    alignItems="center"
                    gap={10}>
                    <Document color={colors.mainPrimary} />
                    <Text style={{pointerEvents: 'none'}}>Скачать акт</Text>
                    {downloadLoading === 'check' ? <Loader /> : null}
                  </DownloadItem>
                </TooltipPayments>,
              ];
            })}
          />
        </Content>
      )}
    </CentralBlock>
  );
}

const EmptyState = () => {
  return (
    <Flex
      style={{height: 'calc(100dvh - 152px'}}
      direction="column"
      gap={16}
      justifyContent="center"
      alignItems="center">
      <EmptyRecord size={300} />
      <Text
        color="textTertiary"
        style={{textAlign: 'center', marginBottom: 56}}>
        У вас нету истории платежей
      </Text>
    </Flex>
  );
};

const skeletonsWidth = [
  [67, 36, 48, 37, 34],
  [165, 76, 49, 44, 68],
  [154, 44, 59, 39, 72],
  [199, 74, 54, 45, 71],
  [281, 71, 68, 55, 52],
];
const SKELETON_SIZE = 1566;
const getWidth = (line: number, column: number) => {
  return Math.round((skeletonsWidth[line][column] / SKELETON_SIZE) * 100) + '%';
};

const SkeletonPaymentHistory = () => (
  <Skeleton height={300}>
    {Array(5)
      .fill(0)
      .map((_, index) => (
        <>
          <rect
            key={index + '_line_skeleton_1'}
            x={0}
            y={23 + 60 * index}
            width={getWidth(index, 0)}
            height={14}
            rx={8}
            ry={8}
          />
          <rect
            x={'24%'}
            y={23 + 60 * index}
            key={index + '_line_skeleton_2'}
            width={getWidth(index, 1)}
            height={14}
            rx={8}
            ry={8}
          />
          <rect
            x={'43%'}
            y={23 + 60 * index}
            key={index + '_line_skeleton_3'}
            width={getWidth(index, 2)}
            height={14}
            rx={8}
            ry={8}
          />
          <rect
            x={'60%'}
            y={23 + 60 * index}
            key={index + '_line_skeleton_4'}
            width={getWidth(index, 3)}
            height={14}
            rx={8}
            ry={8}
          />
          <rect
            x={'78%'}
            y={23 + 60 * index}
            key={index + '_line_skeleton_5'}
            width={getWidth(index, 4)}
            height={14}
            rx={8}
            ry={8}
          />
          {index !== 0 ? (
            <circle r={11} cx={'96%'} cy={29 + 60 * index} />
          ) : null}
        </>
      ))}
  </Skeleton>
);
