import React from 'react';
import {useAppSelector} from 'shared/store';
import {Flex} from 'shared/ui/Flex';
import {Skeleton} from 'shared/ui/Skeleton';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';

const COLUMN = 3;

const View = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 500px;
  padding-top: 24px;
  background-color: ${({theme}) => theme.bgPrimary};
  border-radius: 24px;
  padding: 24px;
`;

const times = Array.from(Array(26).keys());
const columns = Array.from(Array(COLUMN).keys());

export const BookingsSkeleton = () => {
  const fixedCalendar = useAppSelector(
    state => state.booking.filter.fixedCalendar,
  );
  return (
    <Flex flex={1} style={{height: '100%'}}>
      <Flex
        flex={1}
        direction="column"
        style={{
          margin: 24,
          height: 'calc(100% - 48px)',
        }}
        gap={24}>
        <Flex alignItems="center" justifyContent="space-between">
          <Text typography="title24">Журнал записи</Text>
          <Flex>
            <Skeleton width={770} height={48}>
              <rect width={287} height={48} rx={14} ry={14} />
              <rect width={169} height={48} rx={14} ry={14} x={295} />
              <rect width={48} height={48} rx={14} ry={14} x={472} />
              <rect width={48} height={48} rx={14} ry={14} x={528} />
              <rect width={186} height={48} rx={14} ry={14} x={584} />
            </Skeleton>
          </Flex>
        </Flex>
        <View>
          <Skeleton height={'100%'}>
            {columns.map(item => (
              <React.Fragment key={'skeleton_' + item}>
                <circle
                  r={28}
                  cx={`calc(((100% - 17px * ${
                    COLUMN - 1
                  }) / ${COLUMN}) * ${item} + 12px + ((100% - 17px * ${COLUMN} - 46px) / ${COLUMN}) / 2)`}
                  cy={28}
                />
                <rect
                  width={48}
                  height={12}
                  x={`calc(((100% - 17px * ${
                    COLUMN - 1
                  }) / ${COLUMN}) * ${item} + 60px + ((100% - 17px * ${COLUMN} - 46px) / ${COLUMN}) / 2)`}
                  y={10}
                  rx={6}
                  ry={6}
                />
                <rect
                  width={48}
                  height={12}
                  x={`calc(((100% - 17px * ${
                    COLUMN - 1
                  }) / ${COLUMN}) * ${item} + 60px + ((100% - 17px * ${COLUMN} - 46px) / ${COLUMN}) / 2)`}
                  y={34}
                  rx={6}
                  ry={6}
                />
                <rect
                  width={`calc((100% - 17px * ${COLUMN} - 46px) / ${COLUMN})`}
                  height={157}
                  x={`calc(((100% - 17px * ${
                    COLUMN - 1
                  }) / ${COLUMN}) * ${item} + 46px)`}
                  y={80}
                  rx={12}
                  ry={12}
                />
                <rect
                  width={`calc((100% - 17px * ${COLUMN} - 46px) / ${COLUMN})`}
                  height={157}
                  x={`calc(((100% - 17px * ${
                    COLUMN - 1
                  }) / ${COLUMN}) * ${item} + 46px)`}
                  y={265}
                  rx={12}
                  ry={12}
                />
                <rect
                  width={`calc((100% - 17px * ${COLUMN} - 46px) / ${COLUMN})`}
                  height={157}
                  x={`calc(((100% - 17px * ${
                    COLUMN - 1
                  }) / ${COLUMN}) * ${item} + 46px)`}
                  y={450}
                  rx={12}
                  ry={12}
                />
                <rect
                  width={`calc((100% - 17px * ${COLUMN} - 46px) / ${COLUMN})`}
                  height={157}
                  x={`calc(((100% - 17px * ${
                    COLUMN - 1
                  }) / ${COLUMN}) * ${item} + 46px)`}
                  y={635}
                  rx={12}
                  ry={12}
                />
              </React.Fragment>
            ))}

            {times.map((_, index) => (
              <rect
                key={index.toString()}
                x={0}
                y={index * 42 + 80}
                width={index % 2 === 0 ? 34 : 27}
                rx={4}
                ry={4}
                height={index % 2 === 0 ? 10.5 : 11.35}
              />
            ))}
          </Skeleton>
        </View>
      </Flex>
      {fixedCalendar && (
        <Flex style={{width: 408}}>
          <Skeleton height={504}>
            <rect width={24} height={24} rx={8} ry={8} x={0} y={36} />
            <rect width={312} height={24} rx={8} ry={8} x={32} y={36} />
            <rect width={24} height={24} rx={8} ry={36} x={352} y={36} />
            <rect width={384} height={408} rx={24} ry={24} y={96} />
          </Skeleton>
        </Flex>
      )}
    </Flex>
  );
};
