import React, {useState} from 'react';
import {useQuery} from '@apollo/client';
import {useNavigate, useParams} from 'react-router-dom';
import {Content} from 'shared/ui/Content';
import {Flex} from 'shared/ui/Flex';
import {Text} from 'shared/ui/Text';
import {Divider} from 'shared/ui/Divider';
import {getPriceFormat} from 'shared/lib/utils';
import styled from 'styled-components';
import {Image} from 'shared/ui/Image';
import {GET_PRODUCTS_ONE, productPieceCount} from 'entities/products';
import {SkeletonDetailProduct} from './ui/Skeleton';
import {useAppSelector} from 'shared/store';
import {ImageView} from 'shared/ui/ImageView';
import {useEmployeePermission} from 'shared/lib/hooks';

const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
`;

const SectionTableViewRow = ({
  left,
  right,
}: {
  left: string;
  right?: string | null;
}) => {
  return (
    <Flex direction="row" justifyContent="space-between">
      <Text typography="subHead14Medium" color="textTertiary">
        {left}
      </Text>
      <Text typography="subHead14Regular">{right}</Text>
    </Flex>
  );
};

export const DetailProduct = () => {
  const params = useParams();
  const {id} = params;
  const navigate = useNavigate();
  const username = useAppSelector(state => state.company.data?.username);
  const companyId = useAppSelector(state => state.company.data!.id);
  const [imageVisible, setImageVisible] = useState(false);
  const [indexPhoto, setIndexPhoto] = useState<number>(0);

  const {data, loading} = useQuery(GET_PRODUCTS_ONE, {
    variables: {
      id: id!,
      companyId,
    },
  });
  const {edit_products_permission} = useEmployeePermission(data);

  if (loading) {
    return <SkeletonDetailProduct />;
  }

  return (
    <Flex flex={1} gap={24} direction="column">
      <Content gap="24px">
        <Flex justifyContent="space-between">
          <Text typography="title18">Основная информация</Text>
          {edit_products_permission && (
            <Text
              typography="subHead14Regular"
              color="mainPrimary"
              style={{cursor: 'pointer'}}
              onClick={() => navigate(`/${username}/product/${id}/edit`)}>
              Изменить
            </Text>
          )}
        </Flex>
        <Flex direction="column" gap={16}>
          <SectionTableViewRow
            left="Категория"
            right={data?.productsOne?.products_category?.name}
          />
          <SectionTableViewRow
            left="Бренд"
            right={data?.productsOne?.products_brand?.name}
          />
          <SectionTableViewRow
            left="Штрихкод товара"
            right={data?.productsOne?.barcode ?? '-'}
          />
          <SectionTableViewRow
            left="Масса нетто"
            right={
              data?.productsOne?.count +
              ` ${
                productPieceCount.find(
                  item => item.value === data?.productsOne?.piece_count,
                )?.small ?? ''
              }`
            }
          />
          <Divider />
          <Flex direction="column" gap={8}>
            <Text typography="subHead14Medium" color="textTertiary">
              Описание
            </Text>
            <Text
              typography="subHead14Regular"
              style={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}>
              {data?.productsOne?.description ?? '-'}
            </Text>
          </Flex>
        </Flex>
      </Content>
      <Content gap="24px">
        <Flex justifyContent="space-between">
          <Text typography="title18">Информация о запасе</Text>
          {edit_products_permission && (
            <Text
              typography="subHead14Regular"
              color="mainPrimary"
              style={{cursor: 'pointer'}}
              onClick={() => navigate(`/${username}/product/${id}/edit`)}>
              Изменить
            </Text>
          )}
        </Flex>
        <Flex gap={16} direction="column">
          <SectionTableViewRow
            left="Себестоимость"
            right={getPriceFormat(data?.productsOne?.cost_price)}
          />
          <SectionTableViewRow
            left="Розничная цена"
            right={getPriceFormat(data?.productsOne?.retail_price)}
          />
          <SectionTableViewRow
            left="Цена со скидкой"
            right={getPriceFormat(data?.productsOne?.discount_price)}
          />
          <SectionTableViewRow
            left="Наценка"
            right={data?.productsOne?.charge + ' %'}
          />
          <SectionTableViewRow
            left="В наличии"
            right={(data?.productsOne?.quantity ?? 0) + ' шт.'}
          />
          <SectionTableViewRow
            left="Общая стоимость товаров"
            right={getPriceFormat(
              (data?.productsOne?.cost_price ?? 0) *
                (data?.productsOne?.quantity ?? 0),
            )}
          />
        </Flex>
      </Content>
      {data?.productsOne?.images?.length !== 0 && (
        <Content>
          <Text typography="title18">Фотографии товара</Text>
          <ImageGrid>
            {data?.productsOne?.images?.map((item, index) => (
              <Image
                radius={24}
                style={{width: '100%', height: '100%', aspectRatio: '1 / 1'}}
                key={'image_' + item.id}
                src={item.url}
                onClick={() => {
                  setIndexPhoto(index);
                  setImageVisible(true);
                }}
              />
            ))}
          </ImageGrid>
        </Content>
      )}
      <ImageView
        visible={imageVisible}
        onClose={() => setImageVisible(false)}
        images={
          data?.productsOne?.images
            ? data?.productsOne?.images?.map(item => item.url)
            : []
        }
        index={indexPhoto}
      />
    </Flex>
  );
};
