import {ReviewsFilters} from 'pages/reviews/Rivews/ui/ReviewsFilters';
import React, {useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {ArrowLeft2v} from 'shared/icons/ArrowLeft2v';
import {Button} from 'shared/ui/Button';
import {Flex} from 'shared/ui/Flex';
import {Select} from 'shared/ui/Select';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({theme}) => theme.bgSecondary};
  gap: 16px;
`;

const Header = styled(Flex)`
  background-color: ${({theme}) => theme.bgPrimary};
  height: 56px;
  align-items: center;
  justify-content: space-between;
  padding: 16px 16px 16px 0;
`;

const Filters = styled.div`
  background-color: ${({theme}) => theme.bgPrimary};
  border-radius: 20px;
  padding: 16px;
`;

const NavButton = styled(Button)`
  background-color: ${({theme}) => theme.bgPrimary};
  width: 48px;
  height: 48px;
  margin-left: 6px;
  padding: 0;
`;

const Buttons = styled.div`
  padding: 16px;
  display: flex;
  width: 100%;
  position: absolute;
  bottom: 0;
`;

type FilterType = {
  reply?: 'with' | 'without';
  target?: 'company' | 'employees';
  ratings?: 'good' | 'bad';
  period?: number;
  orderBy?: string;
  search?: string;
};

const sortData = [
  {label: 'По умолчанию', value: undefined},
  {label: 'От новым к старым', value: 'new'},
  {label: 'От старым к новым', value: 'old'},
  {label: 'От лучших к худшим', value: 'best'},
  {label: 'От худших к лучшим', value: 'worst'},
];

export const ReviewFiltersMobile = () => {
  const navigate = useNavigate();
  const {filtersData}: {filtersData: FilterType} = useLocation().state;
  const [mobileFilters, setMobileFilters] = useState<FilterType>({
    reply: filtersData?.reply,
    target: filtersData?.target,
    ratings: filtersData?.ratings,
    period: filtersData?.period,
    orderBy: filtersData?.orderBy,
  });

  return (
    <Wrapper>
      <Header>
        <NavButton onClick={() => navigate('/reviews')}>
          <ArrowLeft2v />
        </NavButton>
        <Text style={{marginLeft: 36}} typography="text16Semibold">
          Фильтры
        </Text>
        <Text
          typography="subHead14Medium"
          color="mainPrimary"
          onClick={() => {
            setMobileFilters({
              reply: undefined,
              target: undefined,
              ratings: undefined,
              period: undefined,
              orderBy: undefined,
            });
          }}>
          Сбросить
        </Text>
      </Header>
      <Filters>
        <ReviewsFilters
          filtersData={mobileFilters}
          onChange={setMobileFilters}
        />
        <Select
          style={{marginTop: 8}}
          label="Сортировка"
          data={sortData.map(item => ({
            value: item.value,
            label: item.label,
          }))}
          value={mobileFilters.orderBy}
          onChange={value => {
            setMobileFilters({
              ...mobileFilters,
              orderBy: value as 'new' | 'old' | 'best' | 'worst' | undefined,
            });
          }}
        />
      </Filters>

      <Buttons>
        <Button
          size="large"
          typography="text16Semibold"
          onClick={() => {
            navigate('/reviews', {state: mobileFilters});
          }}>
          Показать отзывы
        </Button>
      </Buttons>
    </Wrapper>
  );
};
