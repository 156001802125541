import React from 'react';
import {IconButton} from './IconButton';
import Xv2 from 'shared/icons/Xv2';

export const CloseButton = ({
  size = 40,
  onClose,
  style,
}: {
  size?: number;
  onClose: () => void;
  style?: React.CSSProperties;
}) => {
  return (
    <IconButton style={style} type="modal" size={size} onClick={onClose}>
      <Xv2 />
    </IconButton>
  );
};
