import {useQuery} from '@apollo/client';
import React from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {gql} from 'shared/__generated__';
import {useAppSelector} from 'shared/store';
import {Button} from 'shared/ui/Button';
import {Content} from 'shared/ui/Content';
import {Divider} from 'shared/ui/Divider';
import {Flex} from 'shared/ui/Flex';
import {Head} from 'shared/ui/Head';
import {Layout} from 'shared/ui/Layout';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';
import {Header} from './ui/Header';
import {NavigateBack} from 'shared/ui/NavigateBack';
import {Support} from 'shared/icons/Support';
import {useColors} from 'shared/lib/hooks';
import {Seo} from 'shared/ui/Seo';
import {CircleArrowUp} from 'shared/icons/CircleArrowUp';

const APP = gql(`
  query GetAppCsm($id: String!, $companyId: String!) {
    getAppById(id: $id, company_id: $companyId) {
      id
      key
      name
      image {
        id
        url
      }
      description
      app_url(company_id: $companyId)
      darkImage {
        id
        url
      }
      is_connectable
      is_connected(company_id: $companyId)
      is_free
      is_out
      is_recommended
      short_description
      instruction
      instruction_link
    }
  }
`);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-column-start: 4;
  grid-column-end: 10;
  padding: 64px 0;
  gap: 8px;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const HTMLContainer = styled.div`
  display: flex;
  padding: 16px 19px;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 20px;
  border: 1px solid ${({theme}) => theme.borderPrimary};
  background: ${({theme}) => theme.fillPrimary};
`;

export const CsmApp = () => {
  const navigate = useNavigate();
  const title = useLocation().state;
  const colors = useColors();
  const username = useAppSelector(state => state.company.data?.username);
  const {appIds} = useParams();
  const companyId = useAppSelector(state => state.company.data!.id);
  const [id, moderationId] = appIds!.split('--');
  const {data} = useQuery(APP, {
    variables: {
      companyId,
      id,
    },
  });
  const app = data?.getAppById;
  const isInstruction =
    !!data?.getAppById?.instruction_link &&
    data?.getAppById?.instruction_link !== '#';

  return (
    <Layout columns={12}>
      <Seo title={title} />
      <Wrapper>
        <Head>
          <NavigateBack />
          <Text typography="title24">{title}</Text>
        </Head>
        <Content gap="24px">
          <Header app={app} />
          <Divider />
          <Flex gap={16} direction="column">
            <Text typography="text16Semibold">
              Шаг 1. Создайте HTML-код для вставки
            </Text>
            <Button
              onClick={() => {
                if (moderationId)
                  navigate(`/${username}/widget-app/${moderationId}`);
              }}
              size="medium">
              Сгенерировать код
            </Button>
          </Flex>
          <Flex gap={16} direction="column">
            <Text typography="text16Semibold">
              Шаг 2. Следуйте инструкциям в нашем руководстве
            </Text>
            <Button
              onClick={() => {
                if (app?.instruction_link)
                  window.open(app.instruction_link, '_blank');
              }}
              size="medium">
              Настроить интеграцию
            </Button>
          </Flex>
          <Flex gap={16} direction="column">
            <Text style={{width: '100%'}} typography="text16Semibold">
              Запись по кнопке{' '}
              <Text
                typography="text16Semibold"
                display="inline"
                color="textTertiary">
                (Опционально)
              </Text>
            </Text>
            <HTMLContainer>
              <Text>javascrip:ewWidget.show();</Text>
            </HTMLContainer>
          </Flex>
          <Flex gap={16} direction="column">
            <Text style={{width: '100%'}} typography="text16Semibold">
              Поменять шрифт кнопки{' '}
              <Text
                typography="text16Semibold"
                display="inline"
                color="textTertiary">
                (Опционально)
              </Text>
            </Text>
            <HTMLContainer>
              <Text>
                {
                  "<style> .EWBookingWidgetTrigger__text { font-family: 'Geometria', Arial, sans-serif; } </style>"
                }
              </Text>
            </HTMLContainer>
          </Flex>
          {app?.description ? (
            <Flex gap={16} direction="column">
              <Text typography="text16Semibold">Описание</Text>
              <Text color="textSecondary">{app?.description}</Text>
            </Flex>
          ) : null}
          {isInstruction && (
            <Button
              variant="outline"
              onClick={() =>
                !!data?.getAppById?.instruction_link &&
                window.open(data.getAppById.instruction_link, '_blank')
              }
              rightIcon={<CircleArrowUp />}>
              Инструкция по подключению
            </Button>
          )}
          <Button
            variant="outline"
            size="large"
            style={{
              color: colors.textPrimary,
              backgroundColor: colors.fillPrimary,
              border: 'none',
            }}
            onClick={() => navigate('/support')}
            leftIcon={<Support color={colors.textPrimary} />}>
            Написать в чат
          </Button>
        </Content>
      </Wrapper>
    </Layout>
  );
};
