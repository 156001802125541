import React, {useEffect, useRef, useState} from 'react';
import Close from 'shared/icons/Close';
import {IconButton} from 'shared/ui/IconButton';
import styled from 'styled-components';
import InputMask from 'react-input-mask';

const DateInput = styled.div<{focused: boolean}>`
  position: relative;
  pointer-events: all;
  width: 240px;
  padding: 12px 16px 6px;
  height: 64px;
  pointer-events: all;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-bottom: 1px solid
    ${({theme, focused}) => (focused ? theme.mainPrimary : theme.borderPrimary)};
`;
const StyledLabel = styled.div<{
  required?: boolean;
  selected?: boolean;
}>`
  position: absolute;
  top: ${({selected}) => (selected ? '12px' : '23px')};
  font-size: ${({selected}) => (selected ? '12px' : '16px')};
  line-height: ${({selected}) => (selected ? '18px' : '24px')};
  color: ${({theme}) => theme.textTertiary};
  font-style: normal;
  font-weight: 400;
  font-family: 'Inter';
  background-color: transparent;
  transition: all 0.3s ease;
`;
const Input = styled.input`
  width: 100%;
  background-color: transparent;
  border: none;
  outline-style: none;
  padding: 0;
  font-family: 'Inter';
  font-size: 16px;
  height: 24px;
  margin-top: 31px;
  color: ${({theme}) => theme.textPrimary};
  pointer-events: none;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const DateInputSalary = ({
  label,
  date,
  onChangeDate,
}: {
  label: string;
  date: string;
  onChangeDate: (text: string) => void;
}) => {
  const [active, setActive] = useState(false);
  const isLabelShrink = date.trim().length > 0 || active;
  const inputRef = useRef<HTMLInputElement>(null);
  const isClose = date.trim().length > 0 && date[0] !== '_';

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const datePickRef = inputRef.current;
      if (datePickRef && !datePickRef.contains(event.target as Node)) {
        setActive(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setActive, inputRef]);

  return (
    <DateInput
      focused={active}
      onClick={() => {
        inputRef.current?.focus();
        setActive(true);
      }}>
      <StyledLabel selected={isLabelShrink} color={'textTertiary'}>
        {label}
      </StyledLabel>
      <InputMask
        autoComplete="new-password"
        mask="99.99.9999"
        maskPlaceholder={'__.__.____'}
        value={date}
        onChange={e => onChangeDate(e.target.value)}>
        <Input ref={inputRef} />
      </InputMask>
      {isClose ? (
        <IconButton
          variant="unstyled"
          onClick={e => {
            e.stopPropagation();
            onChangeDate('');
          }}
          size={24}>
          <Close />
        </IconButton>
      ) : null}
    </DateInput>
  );
};
