import React from 'react';
import styled from 'styled-components';

type Props = {
  enabled: boolean;
};

const Status = styled.div<{enable: boolean}>`
  padding: 8px 16px;
  border-radius: 12px;
  width: max-content;
  font-family: 'Inter';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  align-items: center;
  color: ${({theme, enable}) =>
    enable ? theme.successPrimary : theme.textSecondary};
  background-color: ${({theme, enable}) =>
    enable ? theme.bgSuccess : theme.fillPrimary};
`;

export const WebhookStatus = ({enabled}: Props) => {
  const text = enabled ? 'Включен' : 'Выключен';
  return <Status enable={enabled}>{text}</Status>;
};
