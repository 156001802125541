import {device} from 'shared/device';
import styled from 'styled-components';

export const EmptyContainer = styled.div<{mt?: number; size: number}>`
  align-self: center;
  margin-top: ${({mt}) => mt}px;
  width: ${({size}) => size}px;
  height: ${({size}) => size}px;
  @media ${device.mobile} {
    width: 200px;
    height: 200px;
  }
`;
