import React from 'react';
import {useColorScheme} from 'shared/lib/hooks';
import {EmptyContainer} from 'shared/ui/EmptyContainer';

export const EmptyListSearch = ({size = 400}: {size?: number}) => {
  const colorScheme = useColorScheme();
  return (
    <EmptyContainer size={size}>
      {colorScheme === 'light' ? (
        <svg viewBox="0 0 400 400" fill="none">
          <circle cx="187.98" cy="204.297" r="131.494" fill="#EAEEF9" />
          <path
            d="M335.821 150.034C342.042 150.034 347.085 144.991 347.085 138.77C347.085 132.548 342.042 127.505 335.821 127.505C329.599 127.505 324.556 132.548 324.556 138.77C324.556 144.991 329.599 150.034 335.821 150.034Z"
            fill="#EAEEF9"
          />
          <path
            d="M352.308 106.074C356.557 106.074 360.001 102.63 360.001 98.3814C360.001 94.1327 356.557 90.6885 352.308 90.6885C348.059 90.6885 344.615 94.1327 344.615 98.3814C344.615 102.63 348.059 106.074 352.308 106.074Z"
            fill="#EAEEF9"
          />
          <path
            d="M72.1994 93.5737C80.0898 93.5737 86.4863 87.1773 86.4863 79.2869C86.4863 71.3964 80.0898 65 72.1994 65C64.309 65 57.9126 71.3964 57.9126 79.2869C57.9126 87.1773 64.309 93.5737 72.1994 93.5737Z"
            fill="#EAEEF9"
          />
          <path
            d="M238.829 132.657H168.782V106.074H260.602C260.771 106.074 260.94 106.074 261.108 106.074C275.455 106.413 287.102 118.265 287.102 132.657H256.72"
            fill="#CCD4E2"
          />
          <g filter="url(#filter0_d_18292_524)">
            <path
              d="M263.137 106.074C257.736 106.413 252.672 108.614 249.128 112.339C248.79 112.677 248.453 113.016 248.115 113.524C244.908 117.249 242.545 122.159 242.545 127.408V285.551C242.545 300.282 230.73 312.134 216.046 312.134H87.0928V126.223C87.4303 120.805 89.4558 115.894 93.0003 112.339C96.8824 108.444 102.115 106.074 108.022 106.074H237.819H262.462C262.631 106.074 262.799 106.074 263.137 106.074Z"
              fill="url(#paint0_linear_18292_524)"
            />
          </g>
          <path
            d="M216.889 142.308H178.575C177.224 142.308 176.043 141.123 176.043 139.768C176.043 138.413 177.224 137.228 178.575 137.228H216.889C218.24 137.228 219.421 138.413 219.421 139.768C219.421 141.123 218.408 142.308 216.889 142.308Z"
            fill="#B3BFD3"
          />
          <path
            d="M160.514 142.308H110.553C109.203 142.308 108.021 141.123 108.021 139.768C108.021 138.413 109.203 137.228 110.553 137.228H160.683C162.033 137.228 163.215 138.413 163.215 139.768C163.215 141.123 162.033 142.308 160.514 142.308Z"
            fill="#B3BFD3"
          />
          <path
            d="M192.585 167.367H142.793C141.442 167.367 140.261 166.182 140.261 164.827C140.261 163.473 141.442 162.288 142.793 162.288H192.585C193.935 162.288 195.116 163.473 195.116 164.827C195.285 166.182 194.104 167.367 192.585 167.367Z"
            fill="#B3BFD3"
          />
          <path
            d="M126.757 167.367H110.553C109.203 167.367 108.021 166.182 108.021 164.827C108.021 163.473 109.203 162.288 110.553 162.288H126.757C128.107 162.288 129.289 163.473 129.289 164.827C129.289 166.182 128.107 167.367 126.757 167.367Z"
            fill="#D4DBEC"
          />
          <path
            d="M216.89 192.426H186.339C184.989 192.426 183.808 191.241 183.808 189.887C183.808 188.532 184.989 187.347 186.339 187.347H216.89C218.24 187.347 219.422 188.532 219.422 189.887C219.422 191.241 218.409 192.426 216.89 192.426Z"
            fill="#D4DBEC"
          />
          <path
            d="M171.654 192.426H110.553C109.203 192.426 108.021 191.241 108.021 189.887C108.021 188.532 109.203 187.347 110.553 187.347H171.654C173.004 187.347 174.186 188.532 174.186 189.887C174.186 191.241 173.173 192.426 171.654 192.426Z"
            fill="#B3BFD3"
          />
          <path
            d="M192.583 217.485H158.995C157.644 217.485 156.463 216.3 156.463 214.945C156.463 213.591 157.644 212.406 158.995 212.406H192.583C193.933 212.406 195.115 213.591 195.115 214.945C195.284 216.3 194.102 217.485 192.583 217.485Z"
            fill="#B3BFD3"
          />
          <path
            d="M144.311 217.485H110.553C109.203 217.485 108.021 216.3 108.021 214.945C108.021 213.591 109.203 212.406 110.553 212.406H144.479C145.83 212.406 147.011 213.591 147.011 214.945C147.011 216.3 145.83 217.485 144.311 217.485Z"
            fill="#D4DBEC"
          />
          <path
            d="M216.888 242.544H193.427C192.077 242.544 190.895 241.359 190.895 240.005C190.895 238.65 192.077 237.465 193.427 237.465H216.888C218.238 237.465 219.42 238.65 219.42 240.005C219.42 241.359 218.407 242.544 216.888 242.544Z"
            fill="#B3BFD3"
          />
          <path
            d="M178.236 242.544H138.909C137.558 242.544 136.377 241.359 136.377 240.005C136.377 238.65 137.558 237.465 138.909 237.465H178.236C179.586 237.465 180.768 238.65 180.768 240.005C180.768 241.359 179.586 242.544 178.236 242.544Z"
            fill="#D4DBEC"
          />
          <path
            d="M124.225 242.544H110.553C109.203 242.544 108.021 241.359 108.021 240.005C108.021 238.65 109.203 237.465 110.553 237.465H124.225C125.575 237.465 126.757 238.65 126.757 240.005C126.757 241.359 125.575 242.544 124.225 242.544Z"
            fill="#B3BFD3"
          />
          <path
            d="M192.586 267.603H169.293C167.943 267.603 166.761 266.418 166.761 265.064C166.761 263.709 167.943 262.524 169.293 262.524H192.586C193.936 262.524 195.117 263.709 195.117 265.064C195.286 266.418 194.105 267.603 192.586 267.603Z"
            fill="#B3BFD3"
          />
          <path
            d="M153.931 267.603H110.553C109.203 267.603 108.021 266.418 108.021 265.064C108.021 263.709 109.203 262.524 110.553 262.524H153.931C155.282 262.524 156.463 263.709 156.463 265.064C156.463 266.418 155.282 267.603 153.931 267.603Z"
            fill="#D4DBEC"
          />
          <path
            d="M159.332 291.138H193.427C193.427 296.895 195.79 302.144 199.503 305.869C203.217 309.763 208.449 311.964 214.188 311.964H60.7607C55.022 311.964 49.7896 309.594 46.0763 305.869C42.363 302.144 40 296.895 40 291.138H140.934C140.934 291.138 159.67 291.308 159.332 291.138Z"
            fill="#CCD4E2"
          />
          <path
            d="M305.702 274.924L284.391 253.629L289.277 248.744L310.571 270.055L305.702 274.924Z"
            fill="#858B9B"
          />
          <path
            d="M313.111 282.416L296.77 266.075C295.405 264.71 295.405 262.487 296.77 261.122C298.135 259.757 300.358 259.757 301.723 261.122L318.064 277.463C319.429 278.828 319.429 281.052 318.064 282.416C316.7 283.781 314.476 283.781 313.111 282.416Z"
            fill="#858B9B"
          />
          <path
            d="M311.95 283.46L290.925 262.436C290.437 261.947 290.437 261.155 290.925 260.65L296.3 255.276C296.788 254.787 297.58 254.787 298.085 255.276L319.11 276.301C319.599 276.789 319.599 277.581 319.11 278.086L313.736 283.46C313.231 283.949 312.439 283.949 311.95 283.46Z"
            fill="url(#paint1_linear_18292_524)"
          />
          <path
            d="M260.267 185.013C237.962 185.013 219.886 203.089 219.886 225.394C219.886 247.683 237.962 265.776 260.251 265.776C282.556 265.776 300.632 247.699 300.632 225.394C300.632 203.089 282.556 185.013 260.267 185.013ZM260.267 258.296C242.359 258.296 227.854 243.555 227.854 225.378C227.854 207.2 242.359 192.476 260.267 192.476C278.176 192.476 292.681 207.217 292.681 225.394C292.681 243.572 278.159 258.296 260.267 258.296Z"
            fill="url(#paint2_linear_18292_524)"
          />
          <defs>
            <filter
              id="filter0_d_18292_524"
              x="65.0928"
              y="95.074"
              width="220.044"
              height="250.06"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_18292_524"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_18292_524"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_18292_524"
              x1="175.058"
              y1="101.308"
              x2="175.058"
              y2="314.356"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#FDFEFF" />
              <stop offset="0.9964" stopColor="#ECF0F5" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_18292_524"
              x1="290.546"
              y1="269.371"
              x2="319.482"
              y2="269.371"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#B0BACC" />
              <stop offset="1" stopColor="#969EAE" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_18292_524"
              x1="219.849"
              y1="225.403"
              x2="300.647"
              y2="225.403"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#B0BACC" />
              <stop offset="1" stopColor="#969EAE" />
            </linearGradient>
          </defs>
        </svg>
      ) : (
        <svg width={size} height={size} viewBox="0 0 400 400" fill="none">
          <circle cx="187.979" cy="204.297" r="131.495" fill="#2A2E37" />
          <path
            d="M335.821 150.034C342.043 150.034 347.086 144.991 347.086 138.77C347.086 132.548 342.043 127.505 335.821 127.505C329.6 127.505 324.557 132.548 324.557 138.77C324.557 144.991 329.6 150.034 335.821 150.034Z"
            fill="#2A2E37"
          />
          <path
            d="M352.308 106.074C356.557 106.074 360.001 102.629 360.001 98.3807C360.001 94.132 356.557 90.6877 352.308 90.6877C348.059 90.6877 344.615 94.132 344.615 98.3807C344.615 102.629 348.059 106.074 352.308 106.074Z"
            fill="#2A2E37"
          />
          <path
            d="M72.199 93.5737C80.0894 93.5737 86.4858 87.1773 86.4858 79.2869C86.4858 71.3965 80.0894 65 72.199 65C64.3086 65 57.9121 71.3965 57.9121 79.2869C57.9121 87.1773 64.3086 93.5737 72.199 93.5737Z"
            fill="#2A2E37"
          />
          <path
            d="M238.829 132.657H168.782V106.074H260.602C260.771 106.074 260.94 106.074 261.109 106.074C275.455 106.413 287.102 118.265 287.102 132.657H256.72"
            fill="#3A4050"
          />
          <g filter="url(#filter0_d_18292_554)">
            <path
              d="M263.138 106.074C257.737 106.413 252.673 108.614 249.129 112.339C248.791 112.678 248.453 113.016 248.116 113.524C244.909 117.249 242.546 122.159 242.546 127.408V285.552C242.546 300.282 230.731 312.135 216.046 312.135H87.0933V126.223C87.4308 120.805 89.4563 115.895 93.0008 112.339C96.8829 108.445 102.115 106.074 108.023 106.074H237.82H262.463C262.631 106.074 262.8 106.074 263.138 106.074Z"
              fill="url(#paint0_linear_18292_554)"
            />
          </g>
          <path
            d="M216.889 142.308H178.575C177.224 142.308 176.043 141.123 176.043 139.769C176.043 138.414 177.224 137.229 178.575 137.229H216.889C218.24 137.229 219.421 138.414 219.421 139.769C219.421 141.123 218.408 142.308 216.889 142.308Z"
            fill="#A9AFC3"
          />
          <path
            d="M160.514 142.308H110.553C109.203 142.308 108.021 141.123 108.021 139.769C108.021 138.414 109.203 137.229 110.553 137.229H160.683C162.033 137.229 163.215 138.414 163.215 139.769C163.215 141.123 162.033 142.308 160.514 142.308Z"
            fill="#A9AFC3"
          />
          <path
            d="M192.585 167.368H142.793C141.443 167.368 140.261 166.183 140.261 164.828C140.261 163.474 141.443 162.289 142.793 162.289H192.585C193.935 162.289 195.117 163.474 195.117 164.828C195.286 166.183 194.104 167.368 192.585 167.368Z"
            fill="#A9AFC3"
          />
          <path
            d="M126.757 167.368H110.553C109.203 167.368 108.021 166.183 108.021 164.828C108.021 163.474 109.203 162.289 110.553 162.289H126.757C128.107 162.289 129.289 163.474 129.289 164.828C129.289 166.183 128.107 167.368 126.757 167.368Z"
            fill="#788095"
          />
          <path
            d="M216.89 192.427H186.339C184.989 192.427 183.808 191.242 183.808 189.887C183.808 188.533 184.989 187.347 186.339 187.347H216.89C218.24 187.347 219.422 188.533 219.422 189.887C219.422 191.242 218.409 192.427 216.89 192.427Z"
            fill="#788095"
          />
          <path
            d="M171.654 192.427H110.553C109.203 192.427 108.021 191.242 108.021 189.887C108.021 188.533 109.203 187.347 110.553 187.347H171.654C173.004 187.347 174.186 188.533 174.186 189.887C174.186 191.242 173.173 192.427 171.654 192.427Z"
            fill="#A9AFC3"
          />
          <path
            d="M192.582 217.486H158.994C157.643 217.486 156.462 216.3 156.462 214.946C156.462 213.591 157.643 212.406 158.994 212.406H192.582C193.933 212.406 195.114 213.591 195.114 214.946C195.283 216.3 194.101 217.486 192.582 217.486Z"
            fill="#A9AFC3"
          />
          <path
            d="M144.311 217.486H110.553C109.203 217.486 108.021 216.3 108.021 214.946C108.021 213.591 109.203 212.406 110.553 212.406H144.479C145.83 212.406 147.011 213.591 147.011 214.946C147.011 216.3 145.83 217.486 144.311 217.486Z"
            fill="#788095"
          />
          <path
            d="M216.89 242.545H193.428C192.078 242.545 190.896 241.36 190.896 240.006C190.896 238.651 192.078 237.466 193.428 237.466H216.89C218.24 237.466 219.421 238.651 219.421 240.006C219.421 241.36 218.409 242.545 216.89 242.545Z"
            fill="#A9AFC3"
          />
          <path
            d="M178.235 242.545H138.908C137.557 242.545 136.376 241.36 136.376 240.006C136.376 238.651 137.557 237.466 138.908 237.466H178.235C179.585 237.466 180.767 238.651 180.767 240.006C180.767 241.36 179.585 242.545 178.235 242.545Z"
            fill="#788095"
          />
          <path
            d="M124.225 242.545H110.553C109.203 242.545 108.021 241.36 108.021 240.006C108.021 238.651 109.203 237.466 110.553 237.466H124.225C125.575 237.466 126.757 238.651 126.757 240.006C126.757 241.36 125.575 242.545 124.225 242.545Z"
            fill="#A9AFC3"
          />
          <path
            d="M192.584 267.604H169.291C167.941 267.604 166.759 266.419 166.759 265.064C166.759 263.71 167.941 262.524 169.291 262.524H192.584C193.934 262.524 195.115 263.71 195.115 265.064C195.284 266.419 194.103 267.604 192.584 267.604Z"
            fill="#A9AFC3"
          />
          <path
            d="M153.931 267.604H110.553C109.203 267.604 108.021 266.419 108.021 265.064C108.021 263.71 109.203 262.524 110.553 262.524H153.931C155.282 262.524 156.463 263.71 156.463 265.064C156.463 266.419 155.282 267.604 153.931 267.604Z"
            fill="#788095"
          />
          <path
            d="M159.332 291.14H193.427C193.427 296.896 195.79 302.145 199.503 305.87C203.217 309.765 208.449 311.966 214.188 311.966H60.7608C55.022 311.966 49.7896 309.595 46.0763 305.87C42.363 302.145 40 296.896 40 291.14H140.934C140.934 291.14 159.67 291.309 159.332 291.14Z"
            fill="#3A4050"
          />
          <path
            d="M305.701 274.924L284.39 253.63L289.276 248.744L310.57 270.055L305.701 274.924Z"
            fill="#858B9B"
          />
          <path
            d="M313.11 282.417L296.769 266.075C295.404 264.711 295.404 262.487 296.769 261.122C298.133 259.758 300.357 259.758 301.722 261.122L318.063 277.464C319.427 278.828 319.427 281.052 318.063 282.417C316.698 283.781 314.475 283.781 313.11 282.417Z"
            fill="#858B9B"
          />
          <path
            d="M311.947 283.461L290.923 262.436C290.434 261.948 290.434 261.156 290.923 260.65L296.297 255.276C296.785 254.788 297.577 254.788 298.082 255.276L319.107 276.301C319.596 276.79 319.596 277.581 319.107 278.087L313.733 283.461C313.228 283.95 312.436 283.95 311.947 283.461Z"
            fill="url(#paint1_linear_18292_554)"
          />
          <path
            d="M260.266 185.013C237.96 185.013 219.884 203.09 219.884 225.395C219.884 247.683 237.96 265.776 260.249 265.776C282.554 265.776 300.63 247.7 300.63 225.395C300.63 203.09 282.554 185.013 260.266 185.013ZM260.266 258.296C242.357 258.296 227.852 243.556 227.852 225.378C227.852 207.2 242.357 192.476 260.266 192.476C278.174 192.476 292.679 207.217 292.679 225.395C292.679 243.572 278.157 258.296 260.266 258.296Z"
            fill="url(#paint2_linear_18292_554)"
          />
          <defs>
            <filter
              id="filter0_d_18292_554"
              x="65.0933"
              y="95.0742"
              width="220.044"
              height="250.061"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_18292_554"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_18292_554"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_18292_554"
              x1="175.058"
              y1="101.308"
              x2="175.058"
              y2="314.356"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#5C6479" />
              <stop offset="0.9964" stopColor="#5C6479" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_18292_554"
              x1="290.543"
              y1="269.372"
              x2="319.479"
              y2="269.372"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#B0BACC" />
              <stop offset="1" stopColor="#969EAE" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_18292_554"
              x1="219.847"
              y1="225.403"
              x2="300.645"
              y2="225.403"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#B0BACC" />
              <stop offset="1" stopColor="#969EAE" />
            </linearGradient>
          </defs>
        </svg>
      )}
    </EmptyContainer>
  );
};
