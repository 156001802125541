import React, {useState} from 'react';
import styled from 'styled-components';
import {device} from 'shared/device';
import DotsVertical from 'shared/icons/DotsVertical';
import {Modal} from 'shared/ui/Modal';
import {Restart} from 'shared/icons/Restart';
import {ActionIcon} from 'shared/ui/ActionIcon';
import {Flex} from 'shared/ui/Flex';
import {NavigateBack} from 'shared/ui/NavigateBack';
import {Head} from 'shared/ui/Head';
import {Text} from 'shared/ui/Text';

const H1 = styled.div`
  color: ${({theme}) => theme.textPrimary};
  display: flex;
  text-align: left;
  justify-content: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  align-items: center;
  @media (${device.mobile}) {
    font-size: 20px;
    line-height: 28px;
  }
`;
const PopupWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

export const Header = ({onReset}: {onReset?: () => void}) => {
  const [visible, setVisible] = useState(false);
  return (
    <Flex flex={1} justifyContent="space-between" style={{marginBottom: 8}}>
      <Head>
        <NavigateBack />
        <H1>Права доступа</H1>
      </Head>
      <PopupWrapper>
        <ActionIcon
          icon={<DotsVertical />}
          onClick={() => {
            setVisible(true);
          }}
        />
        <Modal
          style={{
            width: 404,
            top: 48,
            left: 'auto',
            right: 0,
            padding: 24,
            alignItems: 'center',
            justifyContent: 'center',
          }}
          visible={visible}
          onClose={() => setVisible(false)}>
          <Flex
            style={{pointerEvents: 'all', cursor: 'pointer'}}
            onClick={() => (onReset && onReset()) || setVisible(false)}
            gap={16}>
            <Restart />
            <Text
              typography="text18"
              color="mainPrimary"
              style={{
                textAlign: 'center',
                whiteSpace: 'nowrap',
                cursor: 'pointer',
              }}>
              Сбросить настройки по умолчанию
            </Text>
          </Flex>
        </Modal>
      </PopupWrapper>
    </Flex>
  );
};
