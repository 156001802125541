import {Res} from 'shared/lib/hooks';
import {PermissionValues} from 'shared/__generated__/graphql';

type KEY_RES = keyof Omit<
  Res,
  | 'company'
  | 'edit_bonus_card_permission'
  | 'edit_certificate_permission'
  | 'employee'
  | 'fill_bonus_card_permission'
  | 'moderation_permission'
  | 'bookings_info_permission'
>;

export const EMPLOYEE_PERMISSIONS_TEXT: Record<KEY_RES, string> = {
  edit_bookings_permission: 'Журнал записи',
  create_bookings_permission: 'Создание записи',
  customers_info_permission: 'Информации о клиентах',
  contact_permission: 'Доступ к контактам клиентов',
  create_customers_permission: 'Добавление клиента',
  edit_customers_permission: 'Редактирование и удаление клиента',
  add_to_customers_bonus_card_permission:
    'Присвоение, пополнение, удаление бонусной карты у клиента',
  add_to_customers_certificate_permission:
    'Присвоение, удаление сертификата у клиента',
  employees_info_permission: 'Информации о сотрудниках',
  edit_employees_permission:
    'Добавление, редактирование и удаление сотрудников',
  create_invitation_permission:
    'Возможность приглашать и отзывать заявки сотрудников',
  work_schedule_info_permission: 'Просмотр графика работы',
  edit_work_schedule_permission:
    'Добавление, редактирование и удаление графика работы',
  edit_company_services_permission: 'Редактировать и добавлять услуги',
  view_service_permission: 'Просмотр услуг',
  edit_bonus_card_templates: 'Бонусные карты',
  edit_certificate_templates: 'Сертификаты',
  edit_discounts_permission: 'Акции и скидки',
  reviews_info_permission: 'Отзывы',
  reviews_reply_permission: 'Ответ на отзывы',
  edit_company_permission: 'Редактирование страницы организации',
  notation_permission: 'Настройки онлайн-записи',
  edit_apps_permission: 'Каталог приложений',
  reports_permission: 'Статистика',
  subscription_and_pay_permission: 'Доступ к тарифным планам и оплате',
  messages_permission: 'Рассылки',
  delete_messages_permission: 'Удаление рассылок',
  message_sample_permission: 'Шаблоны рассылок',
  view_products_permission: 'Товары',
  edit_products_permission: 'Редактирование товаров',
  salaries_permission: 'Зарплаты',
  webhook_permission: 'Webhook',
};

export const EMPLOYEE_PERMISSIONS_DEFAULT: Record<KEY_RES, PermissionValues> = {
  edit_bookings_permission: PermissionValues.Default,
  create_bookings_permission: PermissionValues.Default,
  customers_info_permission: PermissionValues.Default,
  contact_permission: PermissionValues.Default,
  create_customers_permission: PermissionValues.Default,
  edit_customers_permission: PermissionValues.Default,
  add_to_customers_bonus_card_permission: PermissionValues.Default,
  add_to_customers_certificate_permission: PermissionValues.Default,
  employees_info_permission: PermissionValues.Default,
  edit_employees_permission: PermissionValues.Default,
  create_invitation_permission: PermissionValues.Default,
  work_schedule_info_permission: PermissionValues.Default,
  edit_work_schedule_permission: PermissionValues.Default,
  edit_company_services_permission: PermissionValues.Default,
  view_service_permission: PermissionValues.Default,
  edit_bonus_card_templates: PermissionValues.Default,
  edit_certificate_templates: PermissionValues.Default,
  edit_discounts_permission: PermissionValues.Default,
  reviews_info_permission: PermissionValues.Default,
  reviews_reply_permission: PermissionValues.Default,
  edit_company_permission: PermissionValues.Default,
  notation_permission: PermissionValues.Default,
  edit_apps_permission: PermissionValues.Default,
  reports_permission: PermissionValues.Default,
  subscription_and_pay_permission: PermissionValues.Default,
  messages_permission: PermissionValues.Default,
  delete_messages_permission: PermissionValues.Default,
  message_sample_permission: PermissionValues.Default,
  view_products_permission: PermissionValues.Default,
  edit_products_permission: PermissionValues.Default,
  salaries_permission: PermissionValues.Default,
  webhook_permission: PermissionValues.Default,
};

export const PERMISSION_KEYS: Array<KEY_RES> = [
  'edit_bookings_permission',
  'create_bookings_permission',
  'customers_info_permission',
  'contact_permission',
  'create_customers_permission',
  'edit_customers_permission',
  'add_to_customers_bonus_card_permission',
  'add_to_customers_certificate_permission',
  'employees_info_permission',
  'edit_employees_permission',
  'create_invitation_permission',
  'work_schedule_info_permission',
  'edit_work_schedule_permission',
  'edit_company_services_permission',
  'view_service_permission',
  'edit_bonus_card_templates',
  'edit_certificate_templates',
  'edit_discounts_permission',
  'reviews_info_permission',
  'reviews_reply_permission',
  'edit_company_permission',
  'notation_permission',
  'edit_apps_permission',
  'reports_permission',
  'subscription_and_pay_permission',
  'messages_permission',
  'delete_messages_permission',
  'message_sample_permission',
  'view_products_permission',
  'edit_products_permission',
  'salaries_permission',
  'webhook_permission',
];
