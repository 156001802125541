import React from 'react';
import styled from 'styled-components';
import {device} from 'shared/device';
import {Text} from 'shared/ui/Text';

const H1 = styled.div`
  color: ${({theme}) => theme.textPrimary};
  display: flex;
  text-align: left;
  justify-content: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  height: 56px;
  align-items: center;
  @media (max-width: 390px) {
    font-size: 20px;
    line-height: 28px;
  }
`;

const Header = styled.header`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  @media ${device.mobile} {
    margin-top: 14px;
    margin-left: 16px;
  }
`;

export const CustomersHeader = ({number}: {number?: number}) => {
  return (
    <Header>
      <H1>Клиенты</H1>
      <Text typography="title24" color="textTertiary">
        {number ? number : ''}
      </Text>
    </Header>
  );
};
