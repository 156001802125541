import React, {useState} from 'react';
import {useColors} from 'shared/lib/hooks';
import styled from 'styled-components';
import {Flex} from './Flex';
import {Text} from './Text';
import {ActionIcon} from './ActionIcon';
import {ArrowRight1v} from 'shared/icons/ArrowRight1v';

const Wrapper = styled.button`
  background-color: transparent;
  appearance: none;
  display: flex;
  width: 100%;
  align-items: center;
  border-width: 0;
  gap: 16px;
  padding: 0;
  height: 56px;
  cursor: pointer;
  pointer-events: all;
`;

type Props = {
  icon?: React.JSX.Element;
  label?: string | null;
  tag?: string;
  rightIcon?: React.JSX.Element;
  description?: string | null;
  styleLabel?: React.CSSProperties | undefined;
  styleDescription?: React.CSSProperties | undefined;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const NavLink = ({
  label,
  description,
  icon,
  tag,
  styleDescription,
  styleLabel,
  rightIcon = <ArrowRight1v />,
  ...rest
}: Props) => {
  const colors = useColors();
  const [isHovered, setIsHovered] = useState(false);
  return (
    <Wrapper
      {...rest}
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}>
      {icon}
      <Flex direction="column" flex={1}>
        <Text
          style={{
            cursor: 'pointer',
            ...styleLabel,
            color: isHovered
              ? colors.mainPrimary
              : styleLabel?.color ?? colors.textPrimary,
          }}>
          {label}
        </Text>
        <Text
          style={{cursor: 'pointer', ...styleDescription}}
          color={'textTertiary'}
          typography="subHead14Regular">
          {description}
        </Text>
      </Flex>
      {tag && (
        <Text typography="subHead14Regular" style={{color: colors.textPrimary}}>
          {tag}
        </Text>
      )}
      <ActionIcon
        style={{
          height: 48,
          width: 48,
          backgroundColor: 'transparent',
          border: 'none',
        }}
        icon={rightIcon}
      />
    </Wrapper>
  );
};
