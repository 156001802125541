import React, {useState} from 'react';
import styled from 'styled-components';
import {Flex} from 'shared/ui/Flex';
import {Button} from 'shared/ui/Button';
import {TextInput} from 'shared/ui/TextInput';
import {useColors} from 'shared/lib/hooks';
import {Plus} from 'shared/icons/Plus';
import {useNavigate} from 'react-router-dom';
import {Select} from 'shared/ui/Select';
import {gql} from 'shared/__generated__';
import {useQuery} from '@apollo/client';
import {useAppSelector} from 'shared/store';
import {Search} from 'shared/icons/Search';

const CATEGORIES = gql(`
  query CompanyCategoryScreen($companyId: String) {
    company(id: $companyId) {
      id
      categories {
        id
        title
      }
    }
  }
`);
const SUBCATEGORIES = gql(`
  query CompanySubCategoryScreen($companyId: String, $categoryId: String) {
    company(id: $companyId) {
      id
      subcategories(category_id: $categoryId) {
        id
        title
      }
    }
  }
`);

type Props = {
  search?: string;
  page?: number;
  editPermission: boolean;
  onChangeText?: (value: string) => void;
  onChangePage?: (value: number) => void;
  options?: {
    page: number;
    first: number;
  };
  setOptions?: React.Dispatch<
    React.SetStateAction<{
      page: number;
      first: number;
    }>
  >;
  onCategoryChange?: (categoryId: string | undefined) => void;
  onSubCategoryChange?: (subCategory: string | undefined) => void;
};

const Header = styled(Flex)`
  align-items: center;
  justify-content: space-between;
`;

const FilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export const ServicesSetting = ({
  search,
  onChangeText,
  onCategoryChange,
  onSubCategoryChange,
  editPermission,
}: Props) => {
  const companyId = useAppSelector(state => state.company.data!.id);
  const navigate = useNavigate();
  const username = useAppSelector(state => state.company.data?.username);
  const colors = useColors();
  const [category, setCategory] = useState<
    | {
        label: string;
        value: string;
      }
    | undefined
  >();
  const [subcategory, setSubCategory] = useState<
    | {
        label: string;
        value: string;
      }
    | undefined
  >();
  const {data} = useQuery(CATEGORIES, {
    variables: {companyId: companyId},
    notifyOnNetworkStatusChange: true,
  });

  const {data: subCategoryData} = useQuery(SUBCATEGORIES, {
    variables: {companyId: companyId, categoryId: category?.value},
    notifyOnNetworkStatusChange: true,
  });

  const categories =
    data?.company?.categories.map(categoryItem => ({
      label: categoryItem.title,
      value: categoryItem.id,
    })) ?? [];
  const subcategories =
    subCategoryData?.company?.subcategories.map(subcategoryItem => ({
      label: subcategoryItem.title,
      value: subcategoryItem.id,
    })) ?? [];

  return (
    <Header>
      <Flex gap={8} style={{flexDirection: 'row', display: 'flex'}}>
        <TextInput
          height={54}
          width={'320px'}
          borderRadius={14}
          leftElement={<Search color={colors.textTertiary} />}
          backgroundColor={colors.bgPrimary}
          onChange={onChangeText}
          value={search}
          variant="default"
          placeholder="Поиск"
        />
        <FilterWrapper>
          <Select
            label={category?.value ? '' : 'Категория'}
            variant="default"
            wrapperStyle={{width: 'auto'}}
            style={{height: 56, borderRadius: 14}}
            valueColor={category?.value ? 'white' : 'textPrimary'}
            data={[{label: 'Все категории', value: ''}, ...categories]}
            value={category?.value}
            backgroundColor={
              category?.value ? colors.mainPrimary : colors.bgPrimary
            }
            onChange={value => {
              const selectedCategory = categories.find(
                categoryItem => categoryItem.value === value,
              );
              setCategory(selectedCategory);
              setSubCategory(undefined);
              if (onCategoryChange) {
                onCategoryChange(
                  selectedCategory ? selectedCategory.value : undefined,
                );
              }
            }}
          />
          {category?.value && (
            <Select
              variant="default"
              backgroundColor={
                subcategory?.value ? colors.mainPrimary : colors.bgPrimary
              }
              label={subcategory?.value ? '' : 'Подкатегория'}
              wrapperStyle={{width: 'auto'}}
              style={{height: 56}}
              valueColor={subcategory?.value ? 'white' : 'textPrimary'}
              data={[{label: 'Все подкатегории', value: ''}, ...subcategories]}
              value={subcategory?.value}
              onChange={value => {
                const selectedSubCategory = subcategories.find(
                  subcategoryItem => subcategoryItem.value === value,
                );
                setSubCategory(selectedSubCategory);
                if (onSubCategoryChange) {
                  onSubCategoryChange(
                    selectedSubCategory ? selectedSubCategory.value : undefined,
                  );
                }
              }}
            />
          )}
        </FilterWrapper>
      </Flex>
      {editPermission && (
        <Button
          size="medium"
          variant="filled"
          leftIcon={<Plus size={20} />}
          onClick={() => {
            navigate(`/${username}/add-service`);
          }}>
          Добавить услугу
        </Button>
      )}
    </Header>
  );
};
