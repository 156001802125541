import React from 'react';
import {Content} from 'shared/ui/Content';
import {Flex} from 'shared/ui/Flex';
import {Skeleton} from 'shared/ui/Skeleton';

export const MyCompaniesSkeleton = () => {
  return (
    <Flex direction="column" gap={24}>
      <Content>
        <Skeleton height={56}>
          <circle r={28} cx={28} cy={28} />
          <rect x={72} y={6} width={250} height={20} rx={4} />
          <rect x={72} y={34} width={150} height={20} rx={4} />
        </Skeleton>
      </Content>
      <Content>
        <Skeleton height={224}>
          <circle r={28} cx={28} cy={28} />
          <rect x={72} y={6} width={508} height={20} rx={4} />
          <rect x={72} y={34} width={508} height={20} rx={4} />

          <circle r={28} cx={28} cy={112} />
          <rect x={72} y={90} width={508} height={20} rx={4} />
          <rect x={72} y={118} width={508} height={20} rx={4} />

          <circle r={28} cx={28} cy={196} />
          <rect x={72} y={174} width={508} height={20} rx={4} />
          <rect x={72} y={202} width={508} height={20} rx={4} />
        </Skeleton>
      </Content>
    </Flex>
  );
};
