import React, {useState} from 'react';
import {CircleAlert} from 'shared/icons/CircleAlert';
import {useColors} from 'shared/lib/hooks';
import {Flex} from 'shared/ui/Flex';
import {Tooltip} from 'shared/ui/Tooltip';
import styled from 'styled-components';

const Wrapper = styled.button<{focused: boolean}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  background-color: ${({theme}) => theme.fillPrimary};
  border-radius: 18px;
  padding: 0 16px;
  height: 64px;
  gap: 8px;
  border: 1px solid
    ${({theme, focused}) => (focused ? theme.mainPrimary : theme.borderPrimary)};
  pointer-events: all;
  cursor: pointer;
`;

const Input = styled.input`
  background-color: transparent;
  border: none;
  height: 100%;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-family: 'Inter';
  color: ${({theme}) => theme.textPrimary};
  &:hover {
    border: 1px solid transparent;
  }
  &:focus {
    border: 1px solid transparent;
    outline: none;
  }
`;

type Props = {
  leftElement?: React.ReactElement;
  rightElement?: React.ReactElement;
  label?: string;
  value?: string | null;
  tooltip?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  placeholder?: string;
  search?: string;
  setSearch?: (value: string) => void;
  focused?: boolean;
};

// отдельный компонент используемый только в записи(по дизайну так сделано)
export const InputAvatar = (props: Props) => {
  const {
    leftElement,
    rightElement,
    placeholder = 'Выбор сотрудника',
    label,
    value,
    tooltip,
    onClick,
    disabled,
    search,
    setSearch,
    focused: modalFocused,
  } = props;
  const floating =
    !!value || (label !== undefined && label !== 'Выбор сотрудника');
  const colors = useColors();
  const [focused, setFocused] = useState(false);
  return (
    <Wrapper
      focused={focused || (modalFocused ?? false)}
      onClick={onClick}
      disabled={disabled}>
      {leftElement}
      {label !== undefined ? (
        <Flex flex={1} gap={8} direction="row" alignItems="center">
          <Flex direction="column" style={{width: 'max-content'}}>
            <Label floating={floating}>{label ?? placeholder}</Label>
            {value && <Value>{value}</Value>}
          </Flex>
          {tooltip && (
            <Tooltip text="Обратите внимание, что время и сотрудник уже заняты или недоступны, но вы все равно можете его выбрать">
              <CircleAlert color={colors.warningPrimary} />
            </Tooltip>
          )}
        </Flex>
      ) : search !== undefined ? (
        <Input
          value={search}
          placeholder={placeholder}
          onChange={e => setSearch && setSearch(e.target.value)}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
        />
      ) : null}
      {rightElement}
    </Wrapper>
  );
};

const Label = styled.div<{floating: boolean}>`
  color: ${({theme, floating}) =>
    floating ? theme.textPrimary : theme.textTertiary};
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  font-style: normal;
  position: relative;
  width: max-content;
  transition: transform 0.3s;
`;

const Value = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${({theme}) => theme.textTertiary};
  position: relative;
  text-align: left;
  width: max-content;
`;
