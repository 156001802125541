import React, {useState} from 'react';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';
import {useNavigate, useParams} from 'react-router-dom';
import {gql} from 'shared/__generated__';
import {device} from 'shared/device';
import {useMutation, useQuery} from '@apollo/client';
import {OptionsType, Table} from 'shared/ui/Table';
import {EmployeeServicesSkeleton} from './ui/Skeleton';
import {TableViewRow} from 'shared/ui/TableViewRow';
import {Trash} from 'shared/icons/Trash';
import {useColors, useEmployeePermission} from 'shared/lib/hooks';
import {DeletePopup} from './ui/DeletePopup';
import {Header} from './ui/Header';
import {EmptyState} from './ui/EmptyState';
import {Content} from 'shared/ui/Content';
import {useAppSelector} from 'shared/store';
import {SelectServices} from 'entities/loyalty';
import {showAlert} from 'shared/ui/Alert';
import {Seo} from 'shared/ui/Seo';
import {getDurationHourMinutes, getPriceFormat} from 'shared/lib/utils';

const EMPLOYEE_SERVICES = gql(`
query EmployeeSerivces(
  $companyId: String! 
  $employeeId: String!
  $first: Int!
  $page: Int
  ) {
  employee(id: $employeeId) {
    id
    getServicesIds
    services(first: $first, page: $page) {
      paginatorInfo {
        total
        hasMorePages
        currentPage
        count
        lastPage
      }
        data {
          id
        name
        duration
        min_price
        price
        max_price
        description
        breakout_time
        type
        employees {
          id
          name
          surname
        }
        category {
          id
          title
          subcategories {
            id
            title
          }
        }
        subcategory {
          id
          title
          category {
            id
            title
          }
        }
      }
    }
  }
  ...Permissions
}
`);

const UPDATE_EMPLOYEE = gql(`
  mutation UpdateEmployeeServices($input: UpdateEmployee!) {
    updateEmployee(input: $input) {
      id
    }
  }
`);

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-top: 8px;
  padding-bottom: 24px;
  @media screen and (${device.mobile}) {
    margin-top: 56px;
  }
`;
const CellButton = styled.div`
  align-self: center;
  pointer-events: all;
  cursor: pointer;
`;

const titles = ['Услуга', 'Длительность', 'Перерыв', 'Цена', ''];
const widths = [372, 200, 156, 208, 60];

export const EmployeeServices = () => {
  const params = useParams();
  const navigate = useNavigate();
  const username = useAppSelector(state => state.company.data?.username);
  const employeeId = params.id!;
  const companyId = useAppSelector(state => state.company.data!.id);
  const [modal, setModal] = useState(false);
  const colors = useColors();
  const [options, setOptions] = useState<OptionsType>({
    page: 1,
    first: 10,
    total: 0,
    count: 0,
    lastPage: 1,
  });
  const [modalDelete, setModalDelete] = useState<{
    modal: boolean;
    serviceId: string | null;
  }>({
    modal: false,
    serviceId: null,
  });
  const [services, setServices] = useState<{value: string; label: string}[]>(
    [],
  );
  const {data, loading} = useQuery(EMPLOYEE_SERVICES, {
    variables: {
      companyId,
      employeeId,
      first: options.first,
      page: options.page,
    },
    onCompleted: ({employee}) => {
      setOptions(prev => ({
        ...prev,
        total: employee?.services?.paginatorInfo.total,
        count: employee?.services?.paginatorInfo.count,
        lastPage: employee?.services?.paginatorInfo.lastPage,
      }));
      setServices(
        employee?.services?.data.map(item => ({
          value: item.id,
          label: item.name,
        })) ?? [],
      );
    },
  });
  const [updateEmployee, {loading: updateLoading}] = useMutation(
    UPDATE_EMPLOYEE,
    {
      refetchQueries: [
        'EmployeeSerivces',
        'CompanyServiceSearchEmployeeSetServices',
      ],
    },
  );
  const {edit_employees_permission, edit_company_services_permission} =
    useEmployeePermission(data);

  const servicesData = data?.employee?.services?.data ?? [];
  const servicesIds = data?.employee?.getServicesIds ?? [];

  if (loading) {
    return <EmployeeServicesSkeleton />;
  }

  return (
    <Wrapper>
      <Seo title="Услуги сотрудника" />
      {modal && (
        <SelectServices
          label="Услуги"
          visible={modal}
          values={services}
          changeLoading={updateLoading}
          onChange={async values => {
            try {
              await updateEmployee({
                variables: {
                  input: {
                    employee_id: employeeId,
                    service_ids: [
                      ...new Set([
                        ...services.map(item => item.value),
                        ...values.map(item => item.value),
                      ]),
                    ],
                  },
                },
              });
            } catch (e) {
              if (e instanceof Error) {
                showAlert('error', e.message);
              }
            } finally {
              setModal(false);
            }
          }}
          onClose={() => setModal(false)}
        />
      )}
      {edit_employees_permission && (
        <Header onClickAdd={() => setModal(true)} />
      )}
      {!servicesData.length ? (
        <EmptyState />
      ) : (
        <>
          <Content>
            <Table
              widths={widths}
              titles={titles.map((item, index) => (
                <Text
                  typography="subHead14Regular"
                  color="textTertiary"
                  key={index}>
                  {item}
                </Text>
              ))}
              heightRow={'80px'}
              options={options}
              setOptions={setOptions}
              onClick={index =>
                edit_company_services_permission &&
                !!servicesData?.length &&
                navigate(`/${username}/edit-service`, {
                  state: {
                    serviceId: servicesData?.[index].id,
                  },
                })
              }
              rows={servicesData.map((item, index) => [
                <TableViewRow
                  key={index.toString() + '1'}
                  title={item.name}
                  subtitle={`${item.category.title}${
                    item.subcategory?.title
                      ? ' / ' + item.subcategory.title
                      : ''
                  }`}
                />,
                getDurationHourMinutes(item.duration ?? 0),
                getDurationHourMinutes(item.breakout_time ?? 0),
                getPriceFormat(item.price),
                edit_employees_permission && (
                  <CellButton
                    onClick={e => {
                      e.stopPropagation();
                      setModalDelete({
                        modal: true,
                        serviceId: item.id,
                      });
                    }}>
                    <Trash color={colors.dangerPrimary} />
                  </CellButton>
                ),
              ])}
            />
          </Content>
        </>
      )}
      <DeletePopup
        visible={modalDelete.modal}
        servicesIds={servicesIds}
        serviceId={modalDelete.serviceId}
        employeeId={employeeId}
        onClose={() => {
          setModalDelete({
            modal: false,
            serviceId: null,
          });
        }}
      />
    </Wrapper>
  );
};
