import React from 'react';
import {useColorScheme} from 'shared/lib/hooks';
import {EmptyContainer} from 'shared/ui/EmptyContainer';

export const EmptyAnalytics = ({size = 400}: {size?: number}) => {
  const colorScheme = useColorScheme();
  return (
    <EmptyContainer size={size}>
      {colorScheme === 'light' ? (
        <svg viewBox="0 0 400 400" fill="none">
          <g clipPath="url(#clip0_18292_1175)">
            <path
              d="M63.0676 119.444C68.6283 119.444 73.3549 115 73.3549 109.167C73.3549 103.611 68.9063 98.8889 63.0676 98.8889C57.5069 98.8889 52.7803 103.333 52.7803 109.167C52.7803 115 57.2288 119.444 63.0676 119.444Z"
              fill="#D5DDEA"
            />
            <path
              d="M83.6425 84.9999C85.8668 84.9999 87.535 83.3333 87.535 81.1111C87.535 78.8888 85.8668 77.2222 83.6425 77.2222C81.4182 77.2222 79.75 78.8888 79.75 81.1111C79.75 83.3333 81.4182 84.9999 83.6425 84.9999Z"
              fill="#D5DDEA"
            />
            <path
              d="M329.703 107.5C332.206 107.5 333.874 105.556 333.874 103.333C333.874 100.833 331.928 99.1666 329.703 99.1666C327.479 99.1666 325.255 100.833 325.255 103.056C325.255 105.556 327.201 107.5 329.703 107.5Z"
              fill="#D5DDEA"
            />
            <path
              d="M200.139 347.222C281.603 347.222 347.498 281.389 347.498 200C347.498 118.611 281.325 52.7778 200.139 52.7778C118.675 52.7778 52.7803 118.611 52.7803 200C52.7803 281.389 118.675 347.222 200.139 347.222Z"
              fill="#EAEEF9"
            />
            <path
              d="M186.359 114.801C190.937 111.357 192.153 104.811 188.462 99.9111C185.016 95.3375 178.466 94.1234 173.561 97.8135C168.983 101.258 167.767 107.803 171.458 112.703C174.904 117.277 181.781 118.245 186.359 114.801Z"
              fill="#C2C8D6"
            />
            <g filter="url(#filter0_d_18292_1175)">
              <path
                d="M254.645 291.445L116.672 331.013C114.259 331.549 112.087 330.368 111.265 327.916L54.6168 130.995C54.0806 128.583 55.2638 126.413 57.7179 125.591L195.731 85.7359C198.145 85.1995 200.316 86.3813 201.139 88.8333L258.073 285.795C258.323 288.166 257.099 290.622 254.645 291.445Z"
                fill="url(#paint0_linear_18292_1175)"
              />
            </g>
            <path
              opacity="0.4"
              d="M97.1416 187.791L85.8926 190.88L78.3604 165.581L89.3633 162.165L97.1416 187.791Z"
              fill="#C2C8D6"
            />
            <path
              d="M112.071 183.47L100.495 186.805L89.2983 148.141L100.547 145.052L112.071 183.47Z"
              fill="#989FB0"
            />
            <path
              d="M142.013 174.256L131.011 177.671L115.66 127.034L126.949 123.658L142.013 174.256Z"
              fill="#C2C8D6"
            />
            <path
              opacity="0.4"
              d="M156.05 170.1L145.046 173.515L140.849 159.783L152.425 156.448L156.05 170.1Z"
              fill="#C2C8D6"
            />
            <path
              d="M207.627 173.268L92.1845 206.376C91.2439 206.827 89.892 206.053 89.4806 204.827C89.0288 203.887 89.8042 202.536 90.7447 202.084L206.187 168.977C207.127 168.525 208.479 169.299 208.891 170.525C209.302 171.752 208.854 172.857 207.627 173.268Z"
              fill="#D5DDEA"
            />
            <path
              d="M210.716 184.508L95.2744 217.615C94.3338 218.067 92.9819 217.292 92.5705 216.066C92.1187 215.126 92.894 213.775 93.8346 213.323L209.277 180.216C210.217 179.764 211.569 180.539 211.981 181.765C212.719 182.745 211.903 184.383 210.716 184.508Z"
              fill="#D5DDEA"
            />
            <path
              d="M214.134 195.501L98.6513 228.895C97.7107 229.347 96.3588 228.572 95.9474 227.346C95.4956 226.406 96.271 225.055 97.2115 224.603L212.654 191.496C213.594 191.044 214.946 191.818 215.357 193.045C215.769 194.271 215.32 195.376 214.134 195.501Z"
              fill="#D5DDEA"
            />
            <path
              d="M145.636 227.323L102.356 239.922C101.415 240.374 100.063 239.6 99.6521 238.373C99.2003 237.434 99.9756 236.082 100.916 235.631L144.156 223.318C145.096 222.866 146.448 223.641 146.86 224.867C147.025 225.766 146.577 226.871 145.636 227.323Z"
              fill="#989FB0"
            />
            <path
              d="M225.566 224.267L228.611 233.749C229.268 235.302 228.125 237.186 226.285 237.803L201.617 244.845L112.03 270.785C110.476 271.442 108.591 270.301 107.974 268.461L104.93 258.979C104.272 257.426 105.415 255.543 107.256 254.925L196.516 229.231L221.223 221.903C223.556 221.94 225.195 222.754 225.566 224.267Z"
              fill="#D5DDEA"
            />
            <path
              d="M231.786 246.46L234.831 255.942C235.488 257.495 234.346 259.378 232.505 259.996L224.282 262.343L118.537 293.018C116.983 293.675 115.098 292.534 114.481 290.694L111.436 281.212C110.779 279.659 111.922 277.776 113.762 277.158L219.754 246.81L227.936 244.749C229.817 243.846 231.456 244.661 231.786 246.46Z"
              fill="#D5DDEA"
            />
            <path
              d="M238.333 268.406L241.378 277.889C242.035 279.442 240.893 281.325 239.052 281.942L196.713 294.09L125.124 314.678C123.57 315.336 121.686 314.194 121.069 312.355L118.31 302.913C117.653 301.36 118.796 299.476 120.636 298.859L194.065 277.654L234.564 266.123C236.118 265.466 238.003 266.608 238.333 268.406Z"
              fill="#D5DDEA"
            />
            <path
              d="M120.31 299.102L193.738 277.897L196.712 294.086L125.125 314.674C123.571 315.332 121.686 314.19 121.069 312.351L118.024 302.869C117.653 301.356 118.796 299.473 120.31 299.102Z"
              fill="#C2C8D6"
            />
            <path
              d="M108.221 268.786L105.176 259.303C104.519 257.75 105.661 255.867 107.502 255.25L196.762 229.556L201.29 245.088L112.031 270.782C110.476 271.44 108.551 270.585 108.221 268.786Z"
              fill="#C2C8D6"
            />
            <path
              d="M220.08 246.564L224.568 262.383L118.536 293.017C116.982 293.675 115.097 292.533 114.48 290.694L111.436 281.211C110.778 279.659 111.921 277.775 113.762 277.158L220.08 246.564Z"
              fill="#C2C8D6"
            />
            <path
              d="M128.854 248.905L133.382 264.436L112.07 270.49C110.516 271.147 108.631 270.006 108.014 268.166L104.97 258.685C104.312 257.132 105.455 255.248 107.296 254.631L128.854 248.905Z"
              fill="#989FB0"
            />
            <path
              d="M113.763 277.151L135.075 271.098L139.604 286.629L118.291 292.683C116.737 293.34 114.853 292.199 114.236 290.359L111.191 280.877C111.434 279.159 112.249 277.522 113.763 277.151Z"
              fill="#989FB0"
            />
            <g filter="url(#filter1_d_18292_1175)">
              <path
                d="M320.513 301.258L177.04 301.875C174.709 301.793 172.798 299.931 172.88 297.602L172.155 101.412C172.237 99.0818 174.099 97.1736 176.431 97.2557L319.904 96.6383C322.235 96.7205 324.145 98.5821 324.063 100.912L324.789 297.102C324.995 299.406 323.133 301.314 320.513 301.258Z"
                fill="url(#paint1_linear_18292_1175)"
              />
            </g>
            <path
              d="M307.484 178.458L187.792 178.693C186.639 178.796 185.671 177.721 185.567 176.568C185.464 175.416 186.54 174.449 187.693 174.346L307.359 173.822C308.512 173.719 309.48 174.795 309.584 175.947C309.713 177.388 308.637 178.355 307.484 178.458Z"
              fill="#D5DDEA"
            />
            <path
              d="M307.624 189.767L187.67 190.316C186.517 190.419 185.549 189.344 185.445 188.191C185.342 187.039 186.418 186.072 187.571 185.969L307.526 185.42C308.679 185.317 309.647 186.392 309.75 187.544C309.879 188.985 308.777 189.664 307.624 189.767Z"
              fill="#D5DDEA"
            />
            <path
              d="M307.504 201.39L187.838 201.913C186.684 202.016 185.716 200.941 185.613 199.789C185.51 198.636 186.586 197.669 187.739 197.566L307.694 197.017C308.847 196.914 309.815 197.989 309.918 199.141C309.759 200.608 308.683 201.575 307.504 201.39Z"
              fill="#D5DDEA"
            />
            <path
              d="M240.902 126.057L187.738 126.155C186.585 126.258 185.617 125.183 185.514 124.03C185.41 122.877 186.486 121.91 187.639 121.807L240.777 121.42C241.93 121.318 242.899 122.393 243.002 123.546C243.105 124.698 241.767 125.98 240.902 126.057Z"
              fill="#D5DDEA"
            />
            <path
              d="M240.755 137.392L187.591 137.49C186.438 137.593 185.47 136.517 185.366 135.365C185.263 134.212 186.339 133.245 187.492 133.142L240.656 133.043C241.809 132.941 242.777 134.016 242.88 135.169C243.009 136.61 241.908 137.289 240.755 137.392Z"
              fill="#D5DDEA"
            />
            <path
              d="M223.496 149.093L187.469 149.114C186.316 149.217 185.348 148.141 185.244 146.989C185.141 145.836 186.217 144.869 187.37 144.766L223.397 144.745C224.55 144.642 225.518 145.717 225.622 146.87C225.751 148.311 224.649 148.99 223.496 149.093Z"
              fill="#D5DDEA"
            />
            <path
              d="M307.42 239.424L267.043 239.545C265.89 239.648 264.922 238.573 264.818 237.42C264.715 236.268 265.791 235.301 266.944 235.198L307.321 235.077C308.475 234.974 309.443 236.049 309.546 237.201C309.361 238.379 308.599 239.609 307.42 239.424Z"
              fill="#D5DDEA"
            />
            <path
              d="M257.243 240.422C258.998 240.555 260.31 238.986 260.156 237.258C260.289 235.505 258.719 234.193 256.989 234.348C255.234 234.214 253.922 235.783 254.077 237.511C254.232 239.239 255.514 240.576 257.243 240.422Z"
              fill="#D5DDEA"
            />
            <path
              d="M307.402 252.199L267.024 252.32C265.871 252.423 264.903 251.348 264.8 250.195C264.697 249.043 265.772 248.076 266.925 247.973L307.303 247.851C308.456 247.749 309.424 248.824 309.527 249.976C309.342 251.154 308.581 252.384 307.402 252.199Z"
              fill="#D5DDEA"
            />
            <path
              d="M257.224 253.197C258.98 253.33 260.292 251.761 260.137 250.033C260.271 248.279 258.7 246.968 256.971 247.122C255.216 246.989 253.903 248.558 254.058 250.286C254.213 252.014 255.783 253.325 257.224 253.197Z"
              fill="#C2C8D6"
            />
            <path
              d="M307.698 265.235L267.32 265.357C266.167 265.46 265.199 264.385 265.096 263.232C264.992 262.08 266.068 261.113 267.221 261.01L307.599 260.888C308.752 260.785 309.72 261.861 309.823 263.013C309.612 263.903 308.562 265.158 307.698 265.235Z"
              fill="#D5DDEA"
            />
            <path
              d="M257.519 266.234C259.274 266.367 260.587 264.799 260.432 263.071C260.565 261.317 258.995 260.005 257.266 260.16C255.51 260.026 254.198 261.595 254.353 263.323C254.194 264.789 255.764 266.1 257.519 266.234Z"
              fill="#989FB0"
            />
            <path
              d="M212.545 234.544L212.459 220.616C197.422 221.378 185.773 234.031 185.7 249.425C185.712 259.295 190.861 268.126 198.582 273.243L205.306 260.739C201.877 258.142 199.761 253.976 199.611 249.054C199.542 241.802 205.366 235.476 212.545 234.544Z"
              fill="#989FB0"
            />
            <path
              d="M216.239 220.568L216.325 234.497C220.726 234.975 224.731 237.521 227.11 241.374L238.622 233.669C233.89 226.252 225.879 221.16 216.239 220.568Z"
              fill="#D5DDEA"
            />
            <path
              d="M240.657 236.968L228.857 244.699C229.274 246.113 229.691 247.528 229.558 249.282C229.705 257.398 223.041 264.09 214.631 264.26C212.299 264.178 210.23 263.781 208.423 263.072L201.674 275.288C205.627 277.258 210.08 278.312 214.717 278.189C230.699 278.214 243.475 265.17 243.496 249.199C243.397 244.853 242.433 240.584 240.657 236.968Z"
              fill="#C2C8D6"
            />
            <path
              d="M268.869 156.067L261.324 156.16C259.883 156.289 258.889 154.926 258.786 153.774L258.679 136.365C258.55 134.925 259.914 133.932 261.066 133.829L268.612 133.736C270.053 133.607 271.047 134.97 271.15 136.122L271.257 153.532C271.386 154.972 270.336 156.227 268.869 156.067Z"
              fill="#989FB0"
            />
            <path
              d="M288.049 156.095L280.503 156.188C279.062 156.317 278.069 154.954 277.965 153.802L278 128.251C277.871 126.811 279.235 125.819 280.388 125.716L287.933 125.623C289.375 125.494 290.368 126.857 290.471 128.009L290.436 153.559C290.565 154.999 289.202 155.992 288.049 156.095Z"
              fill="#C2C8D6"
            />
            <path
              d="M306.916 155.861L299.371 155.954C297.929 156.083 296.936 154.72 296.833 153.568L296.696 119.614C296.567 118.174 297.931 117.181 299.083 117.078L306.629 116.985C308.07 116.856 309.063 118.219 309.167 119.371L309.303 153.325C309.432 154.765 308.383 156.02 306.916 155.861Z"
              fill="#989FB0"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_18292_1175"
              x="32.4893"
              y="74.6085"
              width="247.616"
              height="289.532"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_18292_1175"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_18292_1175"
                result="shape"
              />
            </filter>
            <filter
              id="filter1_d_18292_1175"
              x="150.154"
              y="85.6383"
              width="196.65"
              height="249.237"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_18292_1175"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_18292_1175"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_18292_1175"
              x1="174.817"
              y1="76.5121"
              x2="138.128"
              y2="337.138"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#FDFEFF" />
              <stop offset="0.9964" stopColor="#ECF0F5" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_18292_1175"
              x1="238.331"
              y1="86.6792"
              x2="258.199"
              y2="309.192"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#FDFEFF" />
              <stop offset="0.9964" stopColor="#ECF0F5" />
            </linearGradient>
            <clipPath id="clip0_18292_1175">
              <rect
                width="300"
                height="300"
                fill="white"
                transform="translate(50 50)"
              />
            </clipPath>
          </defs>
        </svg>
      ) : (
        <svg viewBox="0 0 400 400" fill="none">
          <g clipPath="url(#clip0_18292_1177)">
            <path
              d="M63.0676 119.444C68.6283 119.444 73.3549 115 73.3549 109.167C73.3549 103.611 68.9063 98.8889 63.0676 98.8889C57.5069 98.8889 52.7803 103.333 52.7803 109.167C52.7803 115 57.2288 119.444 63.0676 119.444Z"
              fill="#2A2E37"
            />
            <path
              d="M83.6425 84.9999C85.8668 84.9999 87.535 83.3333 87.535 81.1111C87.535 78.8888 85.8668 77.2222 83.6425 77.2222C81.4182 77.2222 79.75 78.8888 79.75 81.1111C79.75 83.3333 81.4182 84.9999 83.6425 84.9999Z"
              fill="#2A2E37"
            />
            <path
              d="M329.703 107.5C332.206 107.5 333.874 105.556 333.874 103.333C333.874 100.833 331.928 99.1667 329.703 99.1667C327.479 99.1667 325.255 100.833 325.255 103.056C325.255 105.556 327.201 107.5 329.703 107.5Z"
              fill="#2A2E37"
            />
            <path
              d="M200.139 347.222C281.603 347.222 347.498 281.389 347.498 200C347.498 118.611 281.325 52.7778 200.139 52.7778C118.675 52.7778 52.7803 118.611 52.7803 200C52.7803 281.389 118.675 347.222 200.139 347.222Z"
              fill="#2A2E37"
            />
            <path
              d="M186.359 114.801C190.937 111.357 192.153 104.811 188.462 99.9111C185.016 95.3375 178.466 94.1234 173.561 97.8135C168.983 101.258 167.767 107.803 171.458 112.703C174.904 117.277 181.781 118.245 186.359 114.801Z"
              fill="#8E96AB"
            />
            <g filter="url(#filter0_d_18292_1177)">
              <path
                d="M254.645 291.445L116.672 331.013C114.259 331.549 112.087 330.368 111.265 327.916L54.6168 130.995C54.0806 128.583 55.2638 126.413 57.7179 125.591L195.731 85.7359C198.145 85.1995 200.316 86.3813 201.139 88.8333L258.073 285.795C258.323 288.166 257.099 290.622 254.645 291.445Z"
                fill="#5C6479"
              />
            </g>
            <path
              opacity="0.4"
              d="M97.1416 187.791L85.8926 190.88L78.3604 165.581L89.3633 162.165L97.1416 187.791Z"
              fill="#8E96AB"
            />
            <path
              d="M112.071 183.47L100.495 186.805L89.2983 148.141L100.547 145.052L112.071 183.47Z"
              fill="#474E61"
            />
            <path
              d="M142.013 174.256L131.011 177.671L115.66 127.034L126.949 123.658L142.013 174.256Z"
              fill="#8E96AB"
            />
            <path
              opacity="0.4"
              d="M156.05 170.1L145.046 173.516L140.849 159.783L152.425 156.448L156.05 170.1Z"
              fill="#8E96AB"
            />
            <path
              d="M207.627 173.268L92.1845 206.376C91.2439 206.827 89.892 206.053 89.4806 204.827C89.0288 203.887 89.8042 202.536 90.7447 202.084L206.187 168.977C207.127 168.525 208.479 169.299 208.891 170.525C209.302 171.752 208.854 172.857 207.627 173.268Z"
              fill="#C6CDDF"
            />
            <path
              d="M210.716 184.508L95.2744 217.615C94.3338 218.067 92.9819 217.292 92.5705 216.066C92.1187 215.126 92.894 213.775 93.8346 213.324L209.277 180.216C210.217 179.764 211.569 180.539 211.981 181.765C212.719 182.745 211.903 184.383 210.716 184.508Z"
              fill="#C6CDDF"
            />
            <path
              d="M214.134 195.501L98.6513 228.895C97.7107 229.347 96.3588 228.572 95.9474 227.346C95.4956 226.406 96.271 225.055 97.2115 224.603L212.654 191.496C213.594 191.044 214.946 191.819 215.357 193.045C215.769 194.271 215.32 195.376 214.134 195.501Z"
              fill="#C6CDDF"
            />
            <path
              d="M145.636 227.323L102.356 239.922C101.415 240.374 100.063 239.6 99.6521 238.374C99.2003 237.434 99.9756 236.083 100.916 235.631L144.156 223.318C145.096 222.866 146.448 223.641 146.86 224.867C147.025 225.766 146.577 226.872 145.636 227.323Z"
              fill="#474E61"
            />
            <path
              d="M225.566 224.267L228.611 233.75C229.268 235.302 228.125 237.186 226.285 237.803L201.617 244.845L112.03 270.785C110.476 271.442 108.591 270.301 107.974 268.462L104.93 258.979C104.272 257.426 105.415 255.543 107.256 254.926L196.516 229.231L221.223 221.903C223.556 221.94 225.195 222.755 225.566 224.267Z"
              fill="#C6CDDF"
            />
            <path
              d="M231.786 246.46L234.831 255.942C235.488 257.495 234.346 259.379 232.505 259.996L224.282 262.343L118.537 293.018C116.983 293.675 115.098 292.534 114.481 290.695L111.436 281.212C110.779 279.659 111.922 277.776 113.762 277.159L219.754 246.81L227.936 244.75C229.817 243.846 231.456 244.661 231.786 246.46Z"
              fill="#C6CDDF"
            />
            <path
              d="M238.333 268.406L241.378 277.889C242.035 279.442 240.893 281.325 239.052 281.942L196.713 294.09L125.124 314.678C123.57 315.336 121.686 314.194 121.069 312.355L118.31 302.913C117.653 301.36 118.796 299.476 120.636 298.859L194.065 277.654L234.564 266.123C236.118 265.466 238.003 266.608 238.333 268.406Z"
              fill="#C6CDDF"
            />
            <path
              d="M120.31 299.102L193.738 277.897L196.712 294.086L125.125 314.674C123.571 315.332 121.686 314.19 121.069 312.351L118.024 302.869C117.653 301.356 118.796 299.473 120.31 299.102Z"
              fill="#8E96AB"
            />
            <path
              d="M108.221 268.786L105.176 259.304C104.519 257.751 105.661 255.867 107.502 255.25L196.762 229.556L201.29 245.089L112.031 270.783C110.476 271.44 108.551 270.585 108.221 268.786Z"
              fill="#8E96AB"
            />
            <path
              d="M220.08 246.564L224.568 262.383L118.536 293.017C116.982 293.675 115.097 292.533 114.48 290.694L111.436 281.211C110.778 279.659 111.921 277.775 113.762 277.158L220.08 246.564Z"
              fill="#8E96AB"
            />
            <path
              d="M128.854 248.905L133.382 264.437L112.07 270.49C110.516 271.147 108.631 270.006 108.014 268.167L104.97 258.685C104.312 257.132 105.455 255.249 107.296 254.632L128.854 248.905Z"
              fill="#474E61"
            />
            <path
              d="M113.763 277.151L135.075 271.098L139.604 286.629L118.291 292.683C116.737 293.34 114.853 292.199 114.236 290.359L111.191 280.877C111.434 279.159 112.249 277.522 113.763 277.151Z"
              fill="#474E61"
            />
            <g filter="url(#filter1_d_18292_1177)">
              <path
                d="M320.513 301.258L177.04 301.875C174.709 301.793 172.798 299.931 172.88 297.602L172.155 101.412C172.237 99.0818 174.099 97.1736 176.431 97.2557L319.904 96.6383C322.235 96.7205 324.145 98.5821 324.063 100.912L324.789 297.102C324.995 299.406 323.133 301.314 320.513 301.258Z"
                fill="#5C6479"
              />
            </g>
            <path
              d="M307.484 178.458L187.792 178.693C186.639 178.796 185.671 177.721 185.567 176.568C185.464 175.416 186.54 174.449 187.693 174.346L307.359 173.822C308.512 173.719 309.48 174.795 309.584 175.947C309.713 177.388 308.637 178.355 307.484 178.458Z"
              fill="#C6CDDF"
            />
            <path
              d="M307.624 189.767L187.67 190.316C186.517 190.419 185.549 189.344 185.445 188.192C185.342 187.039 186.418 186.072 187.571 185.969L307.526 185.42C308.679 185.317 309.647 186.392 309.75 187.545C309.879 188.985 308.777 189.664 307.624 189.767Z"
              fill="#C6CDDF"
            />
            <path
              d="M307.504 201.39L187.838 201.913C186.684 202.016 185.716 200.941 185.613 199.789C185.51 198.636 186.586 197.669 187.739 197.566L307.694 197.017C308.847 196.914 309.815 197.989 309.918 199.141C309.759 200.608 308.683 201.575 307.504 201.39Z"
              fill="#C6CDDF"
            />
            <path
              d="M240.902 126.057L187.738 126.155C186.585 126.258 185.617 125.183 185.514 124.03C185.41 122.877 186.486 121.91 187.639 121.807L240.777 121.42C241.93 121.318 242.899 122.393 243.002 123.546C243.105 124.698 241.767 125.98 240.902 126.057Z"
              fill="#C6CDDF"
            />
            <path
              d="M240.755 137.392L187.591 137.49C186.438 137.593 185.47 136.517 185.366 135.365C185.263 134.212 186.339 133.244 187.492 133.142L240.656 133.043C241.809 132.94 242.777 134.016 242.88 135.169C243.009 136.609 241.908 137.289 240.755 137.392Z"
              fill="#C6CDDF"
            />
            <path
              d="M223.496 149.093L187.469 149.114C186.316 149.217 185.348 148.141 185.244 146.989C185.141 145.836 186.217 144.869 187.37 144.766L223.397 144.745C224.55 144.642 225.518 145.717 225.622 146.87C225.751 148.311 224.649 148.99 223.496 149.093Z"
              fill="#C6CDDF"
            />
            <path
              d="M307.42 239.424L267.043 239.545C265.89 239.648 264.922 238.573 264.818 237.42C264.715 236.268 265.791 235.301 266.944 235.198L307.321 235.077C308.475 234.974 309.443 236.049 309.546 237.201C309.361 238.379 308.599 239.609 307.42 239.424Z"
              fill="#C6CDDF"
            />
            <path
              d="M257.243 240.422C258.998 240.555 260.31 238.986 260.156 237.258C260.289 235.505 258.719 234.193 256.989 234.348C255.234 234.214 253.922 235.783 254.077 237.511C254.232 239.239 255.514 240.576 257.243 240.422Z"
              fill="#C6CDDF"
            />
            <path
              d="M307.402 252.199L267.024 252.32C265.871 252.423 264.903 251.348 264.8 250.195C264.697 249.043 265.772 248.076 266.925 247.973L307.303 247.851C308.456 247.749 309.424 248.824 309.527 249.976C309.342 251.154 308.581 252.384 307.402 252.199Z"
              fill="#C6CDDF"
            />
            <path
              d="M257.224 253.197C258.98 253.33 260.292 251.761 260.137 250.033C260.271 248.279 258.7 246.968 256.971 247.122C255.216 246.989 253.903 248.558 254.058 250.286C254.213 252.014 255.783 253.325 257.224 253.197Z"
              fill="#8E96AB"
            />
            <path
              d="M307.698 265.235L267.32 265.357C266.167 265.46 265.199 264.385 265.096 263.232C264.992 262.08 266.068 261.113 267.221 261.01L307.599 260.888C308.752 260.785 309.72 261.861 309.823 263.013C309.612 263.903 308.562 265.158 307.698 265.235Z"
              fill="#C6CDDF"
            />
            <path
              d="M257.519 266.234C259.274 266.367 260.587 264.799 260.432 263.071C260.565 261.317 258.995 260.005 257.266 260.16C255.51 260.026 254.198 261.595 254.353 263.323C254.194 264.789 255.764 266.1 257.519 266.234Z"
              fill="#474E61"
            />
            <path
              d="M212.545 234.544L212.459 220.616C197.422 221.378 185.773 234.031 185.7 249.425C185.712 259.295 190.861 268.126 198.582 273.243L205.306 260.739C201.877 258.142 199.761 253.976 199.611 249.054C199.542 241.802 205.366 235.476 212.545 234.544Z"
              fill="#474E61"
            />
            <path
              d="M216.239 220.568L216.325 234.497C220.726 234.975 224.731 237.521 227.11 241.374L238.622 233.669C233.89 226.252 225.879 221.16 216.239 220.568Z"
              fill="#C6CDDF"
            />
            <path
              d="M240.657 236.968L228.857 244.699C229.274 246.113 229.691 247.528 229.558 249.282C229.705 257.398 223.041 264.09 214.631 264.26C212.299 264.178 210.23 263.781 208.423 263.072L201.674 275.288C205.627 277.258 210.08 278.312 214.717 278.189C230.699 278.214 243.475 265.17 243.496 249.199C243.397 244.853 242.433 240.584 240.657 236.968Z"
              fill="#8E96AB"
            />
            <path
              d="M268.869 156.067L261.324 156.16C259.883 156.289 258.889 154.926 258.786 153.774L258.679 136.365C258.55 134.925 259.914 133.932 261.066 133.829L268.612 133.736C270.053 133.607 271.047 134.97 271.15 136.122L271.257 153.532C271.386 154.972 270.336 156.227 268.869 156.067Z"
              fill="#474E61"
            />
            <path
              d="M288.049 156.095L280.503 156.188C279.062 156.317 278.069 154.954 277.965 153.802L278 128.251C277.871 126.811 279.235 125.819 280.388 125.716L287.933 125.623C289.375 125.494 290.368 126.857 290.471 128.009L290.436 153.559C290.565 154.999 289.202 155.992 288.049 156.095Z"
              fill="#8E96AB"
            />
            <path
              d="M306.916 155.861L299.371 155.954C297.929 156.083 296.936 154.72 296.833 153.568L296.696 119.614C296.567 118.174 297.931 117.181 299.083 117.078L306.629 116.985C308.07 116.856 309.063 118.219 309.167 119.371L309.303 153.325C309.432 154.765 308.383 156.02 306.916 155.861Z"
              fill="#474E61"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_18292_1177"
              x="32.4893"
              y="74.6086"
              width="247.616"
              height="289.532"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_18292_1177"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_18292_1177"
                result="shape"
              />
            </filter>
            <filter
              id="filter1_d_18292_1177"
              x="150.154"
              y="85.6384"
              width="196.65"
              height="249.237"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_18292_1177"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_18292_1177"
                result="shape"
              />
            </filter>
            <clipPath id="clip0_18292_1177">
              <rect
                width="300"
                height="300"
                fill="white"
                transform="translate(50 50)"
              />
            </clipPath>
          </defs>
        </svg>
      )}
    </EmptyContainer>
  );
};
