import React from 'react';
import {Text} from 'shared/ui/Text';
import {Grow} from 'shared/ui/Grow';
import {EmptyLoyalty} from 'shared/illustration/EmptyLoyalty';

export const EmptyState = ({search}: {search?: string}) => {
  return (
    <Grow>
      <EmptyLoyalty size={300} />
      {search && (
        <Text typography="title20" style={{alignSelf: 'center', marginTop: 16}}>
          Ничего не найдено
        </Text>
      )}
      <Text
        typography="text16Regular"
        color="textTertiary"
        style={{
          textAlign: 'center',
          alignSelf: 'center',
          maxWidth: 500,
          marginTop: 16,
        }}>
        {search
          ? `По вашему запросу ничего не найдено. Попробуйте изменить \n параметры поиска`
          : 'У вас пока нет акций и скидок. Предлагаем это исправить. Придумывайте акции и привлекайте клиентов в ваш салон.'}
      </Text>
    </Grow>
  );
};
