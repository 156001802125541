import React, {useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {NotFound} from 'shared/illustration/NotFound';
import {setCompanyData, useAppDispatch, useAppSelector} from 'shared/store';
import {Button} from 'shared/ui/Button';
import {Flex} from 'shared/ui/Flex';
import {Seo} from 'shared/ui/Seo';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100vh;
  gap: 234px;
  background-color: ${({theme}) => theme.bgPrimary};
`;

const getCode = (type: null | 'permission') => {
  if (type === 'permission') {
    return '500';
  }
  return '404';
};

export const NotFoundPage = () => {
  const type = useLocation().state;
  const companyId = useAppSelector(state => state.company.data?.id);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const code = getCode(type);

  const handleButton = () => {
    if (code === '500') {
      navigate('/my-companies');
      return;
    }
    navigate('/');
  };

  useEffect(() => {
    if (type === 'permission') {
      dispatch(setCompanyData(null));
    }
  }, [dispatch, type]);

  return (
    <Wrapper>
      <Seo title="Не найдено" />
      <NotFound />
      <Flex direction="column" alignItems="center">
        <Text style={{fontSize: '180px', fontWeight: 800, lineHeight: '180px'}}>
          {code}
        </Text>
        <Text typography="title24" style={{marginTop: 16}}>
          Страница не найдена :(
        </Text>
        <Text
          typography="text18"
          color="textTertiary"
          style={{marginTop: 12, textAlign: 'center'}}>
          Такой страницы здесь нет. Она удалена, <br />
          либо её никогда не было
        </Text>
        <Button
          typography="text16Semibold"
          size="large"
          style={{marginTop: 32, paddingLeft: 76.5, paddingRight: 76.5}}
          onClick={handleButton}>
          {companyId
            ? 'Перейти на главную страницу'
            : 'Перейти на страницу входа'}
        </Button>
      </Flex>
    </Wrapper>
  );
};
