import React from 'react';
import {Skeleton} from 'shared/ui/Skeleton';
import {Content} from 'shared/ui/Content';

const COLUMN = 3;

const columns = Array.from(Array(COLUMN).keys());
const contact = Array.from(Array(4).keys());
const additionalWidth = [180, 264, 204];

export const SkeletonAbout = () => {
  return (
    <>
      <Content>
        <Skeleton height={336}>
          <rect width={363} height={24} rx={12} y={0} x={0} />
          {columns.map((columnItem, index) => (
            <React.Fragment key={'column_' + columnItem}>
              <rect width={80} height={12} rx={6} y={59 + 78 * index} x={0} />
              <rect width={160} height={16} rx={6} y={89 + 78 * index} x={0} />
            </React.Fragment>
          ))}
          {contact.map((columnItem, index) => (
            <React.Fragment key={'column_' + columnItem}>
              <circle r={20} cx={20 + 160 * index} cy={316} />
              <rect
                width={64}
                height={12}
                rx={6}
                y={310}
                x={56 + 160 * index}
              />
            </React.Fragment>
          ))}
        </Skeleton>
      </Content>
      <Content>
        <Skeleton height={396}>
          <rect width={363} height={24} rx={12} y={0} x={0} />
          {additionalWidth.map((addItem, index) => (
            <React.Fragment key={'column_' + addItem}>
              <rect width={80} height={12} rx={6} x={0} y={index * 78 + 58} />
              <rect width={180} height={16} rx={8} x={0} y={index * 78 + 88} />
            </React.Fragment>
          ))}
          <rect
            width={'100%'}
            height={100}
            rx={20}
            x={0}
            y={2 * 86 + 36 + 88}
          />
        </Skeleton>
      </Content>
    </>
  );
};
