import React from 'react';
import styled from 'styled-components';
import {Text} from './Text';
import Check from 'shared/icons/Check';
import {useColors} from 'shared/lib/hooks';
import {Flex} from './Flex';

const WrapperCheckBox = styled.div<{hover: boolean}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 9px;
  padding-bottom: 9px;
  pointer-events: all;
  cursor: pointer;
  &:hover {
    background-color: ${({hover, theme}) =>
      hover ? theme.bgSecondary : 'transparent'};
  }
`;

const ViewIcon = styled.div<{checked?: boolean; disabled?: boolean}>`
  display: flex;
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
  background-color: ${({theme, disabled, checked}) =>
    disabled ? theme.fillPrimary : checked ? theme.mainPrimary : 'transparent'};
  border-radius: 6px;
  border: 1px solid
    ${({theme, disabled, checked}) =>
      disabled
        ? theme.borderPrimary
        : checked
        ? 'transparent'
        : theme.textTertiary};
`;

type Props = {
  title?: string;
  description?: string | React.ReactNode;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (value: boolean) => void;
  onSelectedAll?: (value: React.MouseEvent) => void;
  style?: React.CSSProperties;
  hover?: boolean;
  pointerNone?: boolean;
  leftElement?: React.ReactNode;
  rightElement?: React.ReactNode;
};

export const CheckBox = (props: Props) => {
  const {
    title,
    description,
    checked,
    disabled,
    onChange,
    onSelectedAll,
    hover = false,
    pointerNone = false,
    style,
    leftElement,
    rightElement,
  } = props;
  const colors = useColors();
  const handleCheckBoxClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.stopPropagation();
    if ((disabled === false || disabled === undefined) && onChange) {
      onChange(!checked);
    }
    if (onSelectedAll) {
      onSelectedAll(event);
    }
  };
  return (
    <WrapperCheckBox
      style={{pointerEvents: pointerNone ? 'none' : 'all', ...style}}
      hover={hover}
      onClick={event => handleCheckBoxClick(event)}>
      {leftElement}
      <Flex flex={1} direction="column">
        {title ? (
          <Text
            typography="text16Regular"
            color={disabled ? 'textTertiary' : 'textPrimary'}
            style={{cursor: 'pointer'}}>
            {title}
          </Text>
        ) : null}
        {description ? (
          typeof description === 'string' ? (
            <Text>{description}</Text>
          ) : (
            description
          )
        ) : null}
      </Flex>

      <ViewIcon checked={checked} disabled={disabled}>
        {checked ? (
          <Check color={disabled ? colors.textTertiary : colors.white} />
        ) : (
          <Check color={'transparent'} />
        )}
      </ViewIcon>
      {rightElement}
    </WrapperCheckBox>
  );
};
