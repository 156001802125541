import React, {useCallback} from 'react';
import {Text} from 'shared/ui/Text';
import {useLocation, useNavigate} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {ensureError} from 'shared/lib/utils';
import {CodeInput} from 'shared/ui/CodeInput';
import {Content} from 'shared/ui/Content';
import {gql} from 'shared/__generated__';
import {useMutation} from '@apollo/client';
import styled from 'styled-components';
import {device} from 'shared/device';
import {Head} from 'shared/ui/Head';
import {Popup} from 'shared/ui/Popup';
import {Flex} from 'shared/ui/Flex';
import {TextInput} from 'shared/ui/TextInput';
import {Button} from 'shared/ui/Button';
import {showAlert} from 'shared/ui/Alert';
import {useAppSelector} from 'shared/store';
import {NavigateBack} from 'shared/ui/NavigateBack';
import {CloseButton} from 'shared/ui/CloseButton';
import {Seo} from 'shared/ui/Seo';
import {GET_PROFILE} from 'entities/profile';

const UPDATE_ME = gql(`
  mutation EmailChange($input: UpdateMe!) {
    updateMe(input: $input) {
      temp_email
    }
  }
`);

const SUBMITCODE = gql(`
  mutation SubmitEmailEmailVerify($code: Int!) {
    submitEmailVerification(code: $code)
  }
`);

const SENDCODE = gql(`
  mutation SendEmailVerification {
    sendEmailVerification
  }
`);

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0 auto;
  max-width: 472px;
  min-height: 100dvh;
  border-radius: 0;
  padding-top: 64px;
  padding-bottom: 24px;
  gap: 8px;
  @media screen and (${device.mobile}) {
    margin-top: 56px;
  }
`;
const TIMEOUT = 60;

export function EmailCode() {
  const userId = useAppSelector(state => state.auth.userId);
  const {tempMail} = useLocation().state;
  const [newEmail, setNewEmail] = useState(tempMail);
  const navigate = useNavigate();
  const [updateMail, {loading: updateLoading}] = useMutation(UPDATE_ME);
  const [sendMailCode, {loading: mailCodeSending}] = useMutation(SENDCODE);
  const [submitCode, {loading}] = useMutation(SUBMITCODE, {
    refetchQueries: [{query: GET_PROFILE}],
  });
  const [value, setValue] = useState(['', '', '', '']);
  const [timer, setTimer] = useState(0);
  const [sending, setSending] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [emailModal, setEmailModal] = useState(false);
  const [infoModal, setInfoModal] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimer(t => t + 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);
  const submit = useCallback(async () => {
    try {
      await submitCode({
        variables: {
          code: parseInt(value.join(''), 10),
        },
      });
      showAlert('success', 'Почта подтверждена');
      navigate('/profile', {replace: true});
    } catch (e) {
      const error = ensureError(e);
      setError(error.message);
    }
  }, [navigate, submitCode, value]);
  useEffect(() => {
    if (value.join('').length === 4) {
      submit();
    }
    setError(null);
  }, [value, submit]);
  const updateEmail = async () => {
    if (!newEmail) {
      return setError('Введите почту');
    } else {
      try {
        await updateMail({
          variables: {
            input: {
              id: userId!,
              temp_email: newEmail,
            },
          },
        });
        await sendMailCode();
        setEmailModal(false);
      } catch (e) {
        const error = ensureError(e);
        showAlert('error', error.message);
      }
    }
  };
  return (
    <Wrapper>
      <Seo title="Подтвердите почту" />
      <Head>
        <NavigateBack />
        <Text typography="title24">Подтвердите почту</Text>
      </Head>
      <Content gap="24px">
        <Text typography="title24">Введите код</Text>
        <Flex direction="column">
          <Text color="textTertiary">Мы отправили код на вашу почту:</Text>
          <Text display="inline">
            {newEmail}{' '}
            <Text
              display="inline"
              typography="text16Regular"
              color="mainPrimary"
              onClick={() => {
                setEmailModal(true);
              }}>
              изменить
            </Text>
          </Text>
        </Flex>
        <CodeInput
          error={error}
          value={value}
          onChange={setValue}
          disabled={loading || mailCodeSending}
        />
        <Flex
          direction="column"
          gap={16}
          justifyContent="center"
          alignItems="center">
          {timer < TIMEOUT ? (
            <Text color="textTertiary" align="center">
              {`Вы можете повторно получить код через ${TIMEOUT - timer} сек`}
            </Text>
          ) : (
            <Text
              disabled={sending}
              onClick={async () => {
                try {
                  setSending(true);
                  setError(null);
                  await sendMailCode();
                  setTimer(0);
                } catch (err) {
                  const error = ensureError(err);
                  showAlert('error', error.message);
                } finally {
                  setSending(false);
                }
              }}
              align="center"
              color="mainPrimary">
              Получить код повторно
            </Text>
          )}
          <Text
            color="textTertiary"
            onClick={() => {
              setInfoModal(true);
            }}>
            Почему не приходит код?
          </Text>
          <Popup
            style={{width: 442, alignItems: 'initial'}}
            visible={infoModal}
            onClose={() => {
              setInfoModal(false);
            }}>
            <Flex direction="column" gap={16}>
              <Flex flex={1} justifyContent="space-between" alignItems="center">
                <Text typography="title20" color="textPrimary">
                  Не получили код?
                </Text>
                <CloseButton
                  onClose={() => {
                    setInfoModal(false);
                  }}
                />
              </Flex>
              <Flex direction="column" gap={16}>
                <Text>
                  Проверьте папку спама или попробуйте другой электронный адрес
                </Text>
                <Text>
                  Если код не приходит даже с третьей попытки,{' '}
                  <Text
                    display="inline"
                    color="mainPrimary"
                    onClick={() => {
                      setInfoModal(false);
                      window.open('https://support.booky.co', '_blank');
                    }}>
                    напишите нам
                  </Text>
                </Text>
              </Flex>
            </Flex>
          </Popup>
        </Flex>
      </Content>
      <Popup
        visible={emailModal}
        style={{width: 588, alignItems: 'initial'}}
        onClose={() => {
          setEmailModal(false);
        }}>
        <Flex direction="column" gap={24}>
          <Flex flex={1} justifyContent="space-between" alignItems="center">
            <Text typography="title20" color="textPrimary">
              Изменить почту
            </Text>
            <CloseButton
              onClose={() => {
                setEmailModal(false);
              }}
            />
          </Flex>
          <TextInput
            type="email"
            variant="with_label"
            label="Введите новую почту"
            placeholder="Новая почта"
            error={error}
            value={newEmail}
            onChange={text => {
              setNewEmail(text);
              setError(null);
            }}
          />
          <Button
            size="large"
            loading={updateLoading || mailCodeSending}
            disabled={updateLoading || mailCodeSending}
            onClick={updateEmail}>
            Получить код
          </Button>
        </Flex>
      </Popup>
    </Wrapper>
  );
}
