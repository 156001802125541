import React from 'react';
import {useColorScheme} from 'shared/lib/hooks';
import {EmptyContainer} from 'shared/ui/EmptyContainer';

export const EmptyReceipt = ({size = 400}: {size?: number}) => {
  const colorScheme = useColorScheme();
  return (
    <EmptyContainer size={size}>
      {colorScheme === 'light' ? (
        <svg width={size} height={size} viewBox="0 0 400 400" fill="none">
          <path
            d="M349.821 200.442C349.821 234.493 338.42 266.067 319.315 291.45C308.839 305.071 296.205 317.143 281.722 326.43C258.303 341.907 230.263 350.884 200.065 350.884C117.175 350.884 50 283.402 50 200.442C50 117.173 117.175 50 199.757 50C229.954 50 257.995 58.977 281.414 74.145C295.897 83.4316 308.53 95.5041 319.007 109.124C338.42 134.817 349.821 166.082 349.821 200.442Z"
            fill="#EAEEF9"
          />
          <path
            d="M60.0519 330.538C62.8251 330.538 65.2903 328.372 65.2903 325.276C65.2903 322.49 63.1333 320.014 60.0519 320.014C57.2786 320.014 54.8135 322.181 54.8135 325.276C54.8135 328.062 56.9705 330.538 60.0519 330.538Z"
            fill="#989FB0"
          />
          <path
            d="M345.07 121.102C347.843 121.102 350 118.936 350 116.15C350 113.364 347.843 111.197 345.07 111.197C342.297 111.197 340.14 113.364 340.14 116.15C340.14 118.936 342.297 121.102 345.07 121.102Z"
            fill="#989FB0"
          />
          <g filter="url(#filter0_d_18298_2525)">
            <path
              d="M299.511 73.2162V323.953C299.511 328.287 294.581 330.454 291.5 327.358L286.261 322.405C284.412 320.548 281.331 320.548 279.482 322.405L269.622 332.002C267.773 333.859 264.691 333.859 262.842 332.002L252.982 322.405C251.133 320.548 248.052 320.548 246.203 322.405L236.342 332.002C234.493 333.859 231.412 333.859 229.563 332.002L219.703 322.405C217.854 320.548 214.772 320.548 212.924 322.405L203.371 332.002C201.522 333.859 198.441 333.859 196.592 332.002L186.732 322.405C184.883 320.548 181.801 320.548 179.953 322.405L170.092 332.002C168.243 333.859 165.162 333.859 163.313 332.002L153.452 322.405C151.604 320.548 148.522 320.548 146.673 322.405L136.813 332.002C134.964 333.859 131.883 333.859 130.034 332.002L117.092 319L116.167 319.929L108.464 327.358C105.382 330.454 100.452 328.287 100.452 323.953V73.2162C100.452 68.8825 105.382 66.7156 108.464 69.8112L113.702 74.764C115.551 76.6213 118.632 76.6213 120.481 74.764L130.342 65.1679C132.191 63.3106 135.272 63.3106 137.121 65.1679L146.981 74.764C148.83 76.6213 151.912 76.6213 153.761 74.764L163.621 65.1679C165.47 63.3106 168.551 63.3106 170.4 65.1679L180.261 74.764C182.11 76.6213 185.191 76.6213 187.04 74.764L196.9 65.1679C198.749 63.3106 201.831 63.3106 203.679 65.1679L213.232 74.764C215.081 76.6213 218.162 76.6213 220.011 74.764L229.871 65.1679C231.72 63.3106 234.802 63.3106 236.651 65.1679L246.511 74.764C248.36 76.6213 251.441 76.6213 253.29 74.764L263.151 65.1679C264.999 63.3106 268.081 63.3106 269.93 65.1679L279.79 74.764C281.639 76.6213 284.721 76.6213 286.569 74.764L291.808 69.8112C294.581 66.7156 299.511 68.8825 299.511 73.2162Z"
              fill="white"
            />
          </g>
          <path
            d="M262.227 129.555H240.657C238.5 129.555 236.651 127.698 236.651 125.531C236.651 123.364 238.5 121.506 240.657 121.506H262.227C264.384 121.506 266.233 123.364 266.233 125.531C266.233 127.698 264.384 129.555 262.227 129.555Z"
            fill="#C5CCDE"
          />
          <path
            d="M220.936 129.555H135.889C133.732 129.555 131.883 127.698 131.883 125.531C131.883 123.364 133.732 121.506 135.889 121.506H220.936C223.093 121.506 224.941 123.364 224.941 125.531C224.941 127.698 223.093 129.555 220.936 129.555Z"
            fill="#C5CCDE"
          />
          <path
            d="M262.226 167.939H225.249C223.092 167.939 221.243 166.082 221.243 163.915C221.243 161.748 223.092 159.891 225.249 159.891H262.226C264.383 159.891 266.232 161.748 266.232 163.915C266.232 166.082 264.383 167.939 262.226 167.939Z"
            fill="#C5CCDE"
          />
          <path
            d="M205.529 167.939H167.628C165.471 167.939 163.622 166.082 163.622 163.915C163.622 161.748 165.471 159.891 167.628 159.891H205.837C207.994 159.891 209.843 161.748 209.843 163.915C209.843 166.082 207.686 167.939 205.529 167.939Z"
            fill="#C5CCDE"
          />
          <path
            d="M152.836 167.939H135.889C133.732 167.939 131.883 166.082 131.883 163.915C131.883 161.748 133.732 159.891 135.889 159.891H152.836C154.993 159.891 156.842 161.748 156.842 163.915C156.842 166.082 154.993 167.939 152.836 167.939Z"
            fill="#C5CCDE"
          />
          <path
            d="M199.058 206.633H169.784C167.627 206.633 165.778 204.776 165.778 202.609C165.778 200.442 167.627 198.585 169.784 198.585H199.058C201.215 198.585 203.063 200.442 203.063 202.609C203.063 204.776 201.215 206.633 199.058 206.633Z"
            fill="#C5CCDE"
          />
          <path
            d="M154.069 206.633H135.889C133.732 206.633 131.883 204.776 131.883 202.609C131.883 200.442 133.732 198.585 135.889 198.585H154.069C156.226 198.585 158.075 200.442 158.075 202.609C158.075 204.776 156.226 206.633 154.069 206.633Z"
            fill="#C5CCDE"
          />
          <path
            d="M199.058 245.018H135.889C133.732 245.018 131.883 243.16 131.883 240.993C131.883 238.827 133.732 236.969 135.889 236.969H199.058C201.215 236.969 203.063 238.827 203.063 240.993C203.063 243.16 201.215 245.018 199.058 245.018Z"
            fill="#C5CCDE"
          />
          <path
            d="M199.058 275.663C176.872 275.663 158.075 275.663 135.889 275.663C130.65 275.663 130.65 283.402 135.889 283.402C160.232 283.402 175.023 283.402 199.058 283.402C201.215 283.402 203.064 281.545 203.064 279.378C203.064 277.211 201.215 275.663 199.058 275.663Z"
            fill="#C5CCDE"
          />
          <path
            d="M250.516 287.736C247.435 287.736 244.97 285.259 244.97 282.164V276.282C240.348 275.973 236.034 274.734 232.336 273.187C229.563 271.948 228.33 268.853 229.255 265.757C230.179 263.281 232.336 261.733 234.801 261.733C235.726 261.733 236.342 262.043 237.266 262.352C239.731 263.591 243.737 264.829 248.668 264.829C255.447 264.829 260.069 261.114 260.069 255.852C260.069 250.899 256.679 247.803 248.668 244.398C238.499 240.374 229.255 235.112 229.255 223.349C229.255 213.753 235.726 206.014 245.894 203.847V197.656C245.894 194.561 248.359 192.084 251.441 192.084C254.522 192.084 256.987 194.561 256.987 197.656V203.228C260.685 203.538 263.766 204.157 266.848 205.395C269.621 206.633 271.162 210.038 269.929 212.824C269.005 214.991 266.848 216.539 264.383 216.539C263.458 216.539 262.842 216.539 262.226 216.229C259.144 214.991 256.063 214.372 252.673 214.372C245.278 214.372 242.813 218.396 242.813 222.111C242.813 226.444 245.586 228.921 255.447 232.945C268.389 238.207 273.935 245.017 273.935 255.233C273.935 265.448 266.848 273.496 256.371 275.663V282.164C256.063 285.259 253.598 287.736 250.516 287.736Z"
            fill="#989FB0"
          />
          <defs>
            <filter
              id="filter0_d_18298_2525"
              x="78.4521"
              y="52.7749"
              width="243.06"
              height="313.62"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.18 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_18298_2525"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_18298_2525"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      ) : (
        <svg width={size} height={size} viewBox="0 0 400 400" fill="none">
          <path
            d="M350 199.532C350 233.603 338.592 265.196 319.476 290.594C308.993 304.223 296.352 316.302 281.86 325.595C258.428 341.081 230.37 350.064 200.154 350.064C117.215 350.064 50 282.541 50 199.532C50 116.213 117.215 49 199.846 49C230.062 49 258.119 57.9824 281.552 73.1594C296.043 82.4515 308.684 94.5312 319.168 108.16C338.592 133.868 350 165.151 350 199.532Z"
            fill="#282C35"
          />
          <path
            d="M60.0579 329.705C62.8328 329.705 65.2994 327.537 65.2994 324.44C65.2994 321.652 63.1412 319.174 60.0579 319.174C57.283 319.174 54.8164 321.342 54.8164 324.44C54.8164 327.227 56.9747 329.705 60.0579 329.705Z"
            fill="#191B1F"
          />
          <g filter="url(#filter0_d_18298_2548)">
            <path
              d="M299.142 72.2304V323.117C299.142 327.453 294.208 329.621 291.125 326.524L285.884 321.568C284.034 319.71 280.951 319.71 279.101 321.568L269.234 331.17C267.384 333.028 264.301 333.028 262.451 331.17L252.585 321.568C250.735 319.71 247.651 319.71 245.801 321.568L235.935 331.17C234.085 333.028 231.002 333.028 229.152 331.17L219.286 321.568C217.436 319.71 214.352 319.71 212.502 321.568L202.944 331.17C201.094 333.028 198.011 333.028 196.161 331.17L186.295 321.568C184.445 319.71 181.362 319.71 179.512 321.568L169.645 331.17C167.795 333.028 164.712 333.028 162.862 331.17L152.996 321.568C151.146 319.71 148.063 319.71 146.213 321.568L136.346 331.17C134.496 333.028 131.413 333.028 129.563 331.17L116.613 318.161L115.688 319.09L107.98 326.524C104.897 329.621 99.9639 327.453 99.9639 323.117V72.2304C99.9639 67.8941 104.897 65.7259 107.98 68.8233L113.222 73.7791C115.072 75.6375 118.155 75.6375 120.005 73.7791L129.871 64.1773C131.721 62.3188 134.805 62.3188 136.655 64.1773L146.521 73.7791C148.371 75.6375 151.454 75.6375 153.304 73.7791L163.17 64.1773C165.02 62.3188 168.104 62.3188 169.954 64.1773L179.82 73.7791C181.67 75.6375 184.753 75.6375 186.603 73.7791L196.47 64.1773C198.319 62.3188 201.403 62.3188 203.253 64.1773L212.811 73.7791C214.661 75.6375 217.744 75.6375 219.594 73.7791L229.46 64.1773C231.31 62.3188 234.393 62.3188 236.243 64.1773L246.11 73.7791C247.96 75.6375 251.043 75.6375 252.893 73.7791L262.759 64.1773C264.609 62.3188 267.693 62.3188 269.542 64.1773L279.409 73.7791C281.259 75.6375 284.342 75.6375 286.192 73.7791L291.434 68.8233C294.208 65.7259 299.142 67.8941 299.142 72.2304Z"
              fill="#586178"
            />
          </g>
          <path
            d="M261.834 128.602H240.251C238.093 128.602 236.243 126.744 236.243 124.576C236.243 122.407 238.093 120.549 240.251 120.549H261.834C263.992 120.549 265.842 122.407 265.842 124.576C265.842 126.744 263.992 128.602 261.834 128.602Z"
            fill="#2E3746"
          />
          <path
            d="M220.518 128.602H135.42C133.262 128.602 131.412 126.744 131.412 124.576C131.412 122.407 133.262 120.549 135.42 120.549H220.518C222.676 120.549 224.526 122.407 224.526 124.576C224.526 126.744 222.676 128.602 220.518 128.602Z"
            fill="#2E3746"
          />
          <path
            d="M261.832 167.009H224.833C222.675 167.009 220.825 165.151 220.825 162.983C220.825 160.815 222.675 158.956 224.833 158.956H261.832C263.991 158.956 265.841 160.815 265.841 162.983C265.841 165.151 263.991 167.009 261.832 167.009Z"
            fill="#2E3746"
          />
          <path
            d="M205.102 167.009H167.178C165.02 167.009 163.17 165.151 163.17 162.983C163.17 160.815 165.02 158.956 167.178 158.956H205.41C207.569 158.956 209.419 160.815 209.419 162.983C209.419 165.151 207.26 167.009 205.102 167.009Z"
            fill="#2E3746"
          />
          <path
            d="M152.378 167.009H135.42C133.262 167.009 131.412 165.151 131.412 162.983C131.412 160.815 133.262 158.956 135.42 158.956H152.378C154.536 158.956 156.386 160.815 156.386 162.983C156.386 165.151 154.536 167.009 152.378 167.009Z"
            fill="#191B1F"
          />
          <path
            d="M198.626 205.726H169.335C167.177 205.726 165.327 203.868 165.327 201.7C165.327 199.532 167.177 197.673 169.335 197.673H198.626C200.784 197.673 202.634 199.532 202.634 201.7C202.634 203.868 200.784 205.726 198.626 205.726Z"
            fill="#2E3746"
          />
          <path
            d="M153.611 205.726H135.42C133.262 205.726 131.412 203.868 131.412 201.7C131.412 199.532 133.262 197.673 135.42 197.673H153.611C155.77 197.673 157.62 199.532 157.62 201.7C157.62 203.868 155.77 205.726 153.611 205.726Z"
            fill="#191B1F"
          />
          <path
            d="M198.627 244.134H135.42C133.262 244.134 131.412 242.276 131.412 240.108C131.412 237.939 133.262 236.081 135.42 236.081H198.627C200.785 236.081 202.635 237.939 202.635 240.108C202.635 242.276 200.785 244.134 198.627 244.134Z"
            fill="#2E3746"
          />
          <path
            d="M198.627 274.798C176.428 274.798 157.62 274.798 135.42 274.798C130.179 274.798 130.179 282.541 135.42 282.541C159.778 282.541 174.578 282.541 198.627 282.541C200.785 282.541 202.635 280.683 202.635 278.515C202.635 276.347 200.785 274.798 198.627 274.798Z"
            fill="#2E3746"
          />
          <path
            d="M250.117 286.878C247.034 286.878 244.567 284.4 244.567 281.303V275.418C239.942 275.108 235.626 273.869 231.926 272.32C229.151 271.081 227.918 267.984 228.843 264.887C229.768 262.409 231.926 260.86 234.393 260.86C235.318 260.86 235.934 261.17 236.859 261.48C239.326 262.718 243.334 263.957 248.267 263.957C255.05 263.957 259.675 260.241 259.675 254.975C259.675 250.019 256.284 246.922 248.267 243.515C238.092 239.488 228.843 234.223 228.843 222.453C228.843 212.851 235.318 205.108 245.492 202.939V196.745C245.492 193.647 247.959 191.169 251.042 191.169C254.125 191.169 256.592 193.647 256.592 196.745V202.32C260.292 202.63 263.375 203.249 266.458 204.488C269.233 205.727 270.775 209.134 269.542 211.922C268.617 214.09 266.458 215.639 263.992 215.639C263.067 215.639 262.45 215.639 261.833 215.329C258.75 214.09 255.667 213.47 252.275 213.47C244.876 213.47 242.409 217.497 242.409 221.214C242.409 225.55 245.184 228.028 255.05 232.055C268 237.32 273.55 244.134 273.55 254.356C273.55 264.577 266.458 272.63 255.975 274.798V281.303C255.667 284.4 253.2 286.878 250.117 286.878Z"
            fill="#A9AFC3"
          />
          <path
            d="M345.07 120.102C347.843 120.102 350 117.936 350 115.15C350 112.364 347.843 110.197 345.07 110.197C342.297 110.197 340.14 112.364 340.14 115.15C340.14 117.936 342.297 120.102 345.07 120.102Z"
            fill="#191B1F"
          />
          <defs>
            <filter
              id="filter0_d_18298_2548"
              x="77.9639"
              y="51.7834"
              width="243.178"
              height="313.78"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.18 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_18298_2548"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_18298_2548"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      )}
    </EmptyContainer>
  );
};
