import React, {useEffect} from 'react';
import {Navigate, Outlet, redirect} from 'react-router-dom';
import {Logo} from 'shared/icons/Logo';
import {Title} from 'shared/icons/Title';
import {Header} from 'shared/ui/Header';
import {Button} from 'shared/ui/Button';
import {Flex} from 'shared/ui/Flex';
import {Support} from 'shared/icons/Support';
import {useColors} from 'shared/lib/hooks';
import styled from 'styled-components';
import {device} from 'shared/device';
import {useAppSelector} from 'shared/store';
import {Layout} from 'shared/ui/Layout';

const Wrapper = styled.div`
  grid-column-start: 5;
  grid-column-end: 9;
  justify-content: center;
  align-items: 'center';
  @media screen and (${device.mobile}) {
    margin-top: 56px;
  }
`;
export function AuthRoot() {
  const colors = useColors();
  const companyId = useAppSelector(state => state.company.data?.id);
  const username = useAppSelector(state => state.company.data?.username);
  const token = useAppSelector(state => state.auth.token);

  useEffect(() => {
    if (companyId) {
      redirect(`/${username}/bookings`);
    }
  }, [companyId, username]);

  if (companyId && token) {
    return <Navigate to={`/${username}/bookings`} replace />;
  }

  return (
    <>
      <Header style={{justifyContent: 'space-between'}}>
        <Flex
          justifyContent="center"
          alignItems="center"
          gap={12}
          onClick={() => {
            window.open('https://business.booky.co/', '_self');
          }}
          style={{cursor: 'pointer'}}>
          <Logo />
          <Title />
        </Flex>
        <Button
          size="large"
          variant="light"
          style={{
            backgroundColor: colors.bgPrimary,
            color: colors.textPrimary,
            fontWeight: 600,
          }}
          leftIcon={<Support color={colors.textPrimary} />}
          onClick={() => {
            window.open('https://support.booky.co', '_blank');
          }}>
          Написать в чат
        </Button>
      </Header>
      <Layout
        columns={12}
        style={{
          height: 'calc(100vh - 100px)',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Wrapper>
          <Outlet />
        </Wrapper>
      </Layout>
    </>
  );
}
