import React, {useState} from 'react';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';
import {Content} from 'shared/ui/Content';
import {TextInput} from 'shared/ui/TextInput';
import {Head} from 'shared/ui/Head';
import {Select} from 'shared/ui/Select';
import {Button} from 'shared/ui/Button';
import {useMutation, useQuery} from '@apollo/client';
import {useNavigate} from 'react-router-dom';
import {gql} from 'shared/__generated__';
import {useAppSelector} from 'shared/store';
import {EmployeeRole, ImageType} from 'shared/__generated__/graphql';
import {Layout} from 'shared/ui/Layout';
import {TableViewRow} from 'shared/ui/TableViewRow';
import {Switch} from 'shared/ui/Switch';
import {Flex} from 'shared/ui/Flex';
import {Popup} from 'shared/ui/Popup';
import {Grow} from 'shared/ui/Grow';
import {AvatarCrop, AvatarLoadType} from 'shared/ui/AvatarCrop';
import {MultiSelectChips} from 'shared/ui/MultiSelectChips';
import {GET_COMPANY_EMPLOYEES, SelectProfessions} from 'entities/employees';
import {PHONE_MASK} from 'shared/lib/utils';
import {showAlert} from 'shared/ui/Alert';
import {NavigateBack} from 'shared/ui/NavigateBack';
import {Seo} from 'shared/ui/Seo';
import {DateInput} from 'shared/ui/DateInput';
import dayjs from 'dayjs';
import {COMPANY_EMPLOYEES} from 'entities/booking';

const SLOTS = gql(`
  query CompanyProfessionSlots($companyId: String!) {
    company(id: $companyId) {
      id
      slots
    }
    getCompanyEmployeesLength(company_id: $companyId, enabled: true)
  }
`);

const CREATE_EMPLOYEE = gql(`
  mutation CreateEmployee($input: CreateEmployee!) {
    createEmployee(input: $input) {
      id
      name
      surname
      biography
      role
      phone
      enabled
      career_start
      online_booking_enabled
      avatar {
        id
        url
      }
      career_start
      phone
      enabled
      professions {
        id
        name
      }
      contacts {
        id
        value
        type
      }
      vk_url
      youtube_url
    }
  }
`);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-column-start: 4;
  grid-column-end: 10;
  padding: 64px 0 24px;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const AddEmployee = () => {
  const navigate = useNavigate();
  const username = useAppSelector(state => state.company.data?.username);
  const companyId = useAppSelector(state => state.company.data!.id);
  const [avatar, setAvatar] = useState<AvatarLoadType>();
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [phone, setPhone] = useState('');
  const [startCareer, setStartCareer] = useState<string | undefined>();
  const [role, setRole] = useState('participant');
  const [professions, setProfessions] = useState<
    {label: string; value: string}[]
  >([]);
  const [onlineBooking, setOnlineBooking] = useState(true);
  const [enabled, setEnabled] = useState(false);
  const [modal, setModal] = useState(false);
  const [activeEmployees, setActiveEmployees] = React.useState(1);
  const [errorName, setErrorName] = React.useState<string | undefined>(
    undefined,
  );
  const [errorPhone, setErrorPhone] = React.useState<string | undefined>(
    undefined,
  );
  const [errorStart, setErrorStart] = React.useState<string | undefined>(
    undefined,
  );
  const {data} = useQuery(SLOTS, {
    variables: {companyId},
    onCompleted: res => {
      const activeSlots = res.getCompanyEmployeesLength ?? 0;
      const companySlots = res.company?.slots ?? 0;
      setActiveEmployees(res?.getCompanyEmployeesLength ?? 1);
      if (activeSlots < companySlots) {
        setEnabled(true);
      }
    },
  });
  const [update, {loading}] = useMutation(CREATE_EMPLOYEE, {
    refetchQueries: [
      {
        query: GET_COMPANY_EMPLOYEES,
        variables: {
          companyId,
          first: 10,
          page: 1,
        },
      },
      {
        query: COMPANY_EMPLOYEES,
        variables: {
          services: undefined,
          start: undefined,
          workingDate: undefined,
          companyId: companyId,
        },
      },
    ],
    awaitRefetchQueries: true,
  });
  const slots = data?.company?.slots ?? 1;

  return (
    <Layout columns={12}>
      <Seo title="Добавить сотрудника" />
      <Wrapper>
        <Popup visible={modal} onClose={() => setModal(false)}>
          <Grow style={{gap: 16}}>
            <Flex
              gap={12}
              direction="column"
              justifyContent="space-between"
              alignItems="center"
              style={{width: 440, paddingLeft: 32, paddingRight: 32}}>
              <Text align="center" typography="text16Semibold">
                Невозможно сделать сотрудника оказывающим услуги
              </Text>
              <Text typography="subHead14Regular" align="center">
                Улучшите тарифный план
              </Text>
            </Flex>
            <Flex gap={8} justifyContent="center">
              <Button
                style={{flex: 1}}
                variant="outline"
                size="large"
                onClick={() => setModal(false)}>
                Закрыть
              </Button>
              <Button
                style={{flex: 1}}
                size="large"
                onClick={() => {
                  navigate(`/${username}/tariff-plans`);
                }}>
                Улучшить тариф
              </Button>
            </Flex>
          </Grow>
        </Popup>
        <Head>
          <NavigateBack />
          <Text typography="title24">Добавить сотрудника</Text>
        </Head>
        <Content style={{marginTop: 8}}>
          <Text typography="title18">Основная информация</Text>
          <AvatarCrop
            onChange={value => {
              setAvatar(value);
            }}
            onDelete={() => {
              setAvatar(undefined);
            }}
            url={avatar?.url ?? ''}
          />
          <TextInput
            label="Имя"
            required
            error={errorName}
            value={name}
            onChange={text => {
              if (errorName) {
                setErrorName(undefined);
              }
              setName(text);
            }}
          />
          <TextInput
            label="Фамилия"
            value={surname}
            onChange={text => {
              setSurname(text);
            }}
          />
          <Select
            data={[
              {value: EmployeeRole.Participant, label: 'Сотрудник'},
              {value: EmployeeRole.Admin, label: 'Администратор'},
            ]}
            value={role}
            onChange={setRole}
            label="Роль сотрудника"
          />
          <TextInput
            label="Номер телефона"
            value={phone}
            mask={PHONE_MASK}
            required
            error={errorPhone}
            onChange={text => {
              if (errorPhone) {
                setErrorPhone(undefined);
              }
              setPhone(text);
            }}
          />
          <MultiSelectChips
            label="Специализация"
            values={professions}
            onDelete={value =>
              setProfessions(prev => prev.filter(item => item.value !== value))
            }
            modal={(visible, setVisible) => (
              <SelectProfessions
                label="Специализации"
                visible={visible}
                values={professions}
                onChange={values => {
                  setProfessions(values);
                  setVisible(false);
                }}
                onClose={() => setVisible(false)}
              />
            )}
          />
          <DateInput
            value={startCareer}
            error={errorStart}
            label="Начало карьеры"
            onChange={value => {
              errorStart && setErrorStart(undefined);
              setStartCareer(value === '' ? undefined : value);
            }}
          />
          <Flex alignItems="center">
            <TableViewRow title="Онлайн-запись" />
            <Switch value={onlineBooking} onChange={setOnlineBooking} />
          </Flex>
          <Flex alignItems="center">
            <TableViewRow title="Оказывает услуги" subtitle="Занимает 1 слот" />
            <Switch
              value={enabled}
              onChange={() => {
                if (enabled) {
                  setActiveEmployees(item => item - 1);
                  setEnabled(prev => !prev);
                } else {
                  if (activeEmployees >= slots) {
                    setModal(true);
                  } else {
                    setActiveEmployees(item => item + 1);
                    setEnabled(prev => !prev);
                  }
                }
              }}
            />
          </Flex>
        </Content>
        <Flex direction="column" mt={32}>
          <Button
            size="large"
            loading={loading}
            disabled={!name.trim() || phone.length < 18 || loading}
            onClick={async () => {
              try {
                const errorText = 'Обязательное поле не введено';
                if (!name.trim().length) {
                  setErrorName(errorText);
                  throw new Error(errorText);
                }
                if (phone.length < 18) {
                  setErrorPhone('Номер телефона введен некорректно');
                  throw new Error('Номер телефона введен некорректно');
                }
                const checkDate = dayjs(
                  startCareer,
                  'DD.MM.YYYY',
                  true,
                ).isValid();
                if (startCareer && !checkDate) {
                  setErrorStart('Неверный формат даты');
                  throw new Error('Неверный формат даты');
                }
                const employee = await update({
                  variables: {
                    input: {
                      avatar:
                        avatar !== undefined
                          ? {type: ImageType.Avatar, ...avatar}
                          : null,
                      company_id: companyId,
                      name: name,
                      email: '',
                      surname: surname,
                      professions: professions.map(item => item.value),
                      phone: phone,
                      career_start: startCareer,
                      enabled: enabled,
                      online_booking_enabled: onlineBooking,
                      role:
                        role === 'admin'
                          ? EmployeeRole.Admin
                          : role === 'participant'
                          ? EmployeeRole.Participant
                          : undefined,
                    },
                  },
                });
                employee.data?.createEmployee?.id &&
                  navigate(
                    `/${username}/employee/${employee.data?.createEmployee?.id}`,
                    {replace: true},
                  );
              } catch (e) {
                if (e instanceof Error) {
                  showAlert('error', e.message);
                }
              }
            }}>
            Добавить
          </Button>
        </Flex>
      </Wrapper>
    </Layout>
  );
};
