import React, {useRef} from 'react';
import styled from 'styled-components';
import {Text} from './Text';
import {Flex} from './Flex';

const Container = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
`;

const StyledInput = styled.input<{
  error: boolean;
}>`
  width: 62px;
  height: 62px;
  border-radius: 18px;
  text-align: center;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  font-style: normal;
  font-family: 'Inter';
  background-color: ${props => props.theme.fillPrimary};
  color: ${({error, theme}) =>
    error ? theme.dangerPrimary : theme.textPrimary};
  border: 1px solid
    ${({error, theme}) => (error ? theme.dangerPrimary : theme.borderPrimary)};
  &:focus {
    outline: none;
    color: ${({theme}) => theme.textPrimary};
    border-color: ${({theme}) => theme.mainPrimary};
  }
`;

type CodeInputProps = {
  value: string[];
  onChange: (newValues: string[]) => void;
  error?: string | null;
  disabled?: boolean;
};

export const CodeInput = ({
  value,
  onChange,
  error,
  disabled,
}: CodeInputProps) => {
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const handleKeyDown =
    (index: number) => (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Backspace' && value[index] === '') {
        e.preventDefault();
        if (index > 0) {
          const prevInput = inputRefs.current[index - 1];
          if (prevInput) {
            prevInput.focus();
          }
        }
      }
    };
  const handleChange =
    (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!/^\d*$/.test(e.target.value)) {
        return;
      }
      const newValues = [...value];
      newValues[index] = e.target.value.slice(0, 1);
      onChange(newValues);

      if (e.target.value) {
        if (index < 3) {
          const nextInput = inputRefs.current[index + 1];
          nextInput?.focus();
        }
      } else {
        if (index > 0) {
          const prevInput = inputRefs.current[index - 1];
          prevInput?.focus();
        }
      }
    };

  return (
    <Flex
      direction="column"
      justifyContent="center"
      alignItems="center"
      gap={8}>
      <Container>
        {value.map((val, index) => (
          <StyledInput
            disabled={disabled}
            error={!!error}
            key={index}
            autoFocus={index === 0}
            value={val}
            onChange={handleChange(index)}
            onKeyDown={handleKeyDown(index)}
            ref={el => (inputRefs.current[index] = el)}
          />
        ))}
      </Container>
      {error ? (
        <Text
          align="center"
          color="dangerPrimary"
          typography="subHead14Regular">
          {error}
        </Text>
      ) : null}
    </Flex>
  );
};
