import {ApolloError, useMutation, useQuery} from '@apollo/client';
import {
  GET_DATA_CREATE_PRODUCT,
  GET_PRODUCTS,
  SelectBrand,
  SelectProductsCategory,
  productPieceCount,
} from 'entities/products';
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {gql} from 'shared/__generated__';
import {
  ProductsPieceCount,
  ImageType,
  ImageUpload,
} from 'shared/__generated__/graphql';
import {useAppSelector} from 'shared/store';
import {showAlert} from 'shared/ui/Alert';
import {Button} from 'shared/ui/Button';
import {CheckBox} from 'shared/ui/CheckBox';
import {Content} from 'shared/ui/Content';
import {DragDrop} from 'shared/ui/DragDrop';
import {Flex} from 'shared/ui/Flex';
import {Image} from 'shared/ui/Image';
import {ImageView} from 'shared/ui/ImageView';
import {Layout} from 'shared/ui/Layout';
import {PageHeader} from 'shared/ui/PageHeader';
import {Select} from 'shared/ui/Select';
import {Switch} from 'shared/ui/Switch';
import {TableViewRow} from 'shared/ui/TableViewRow';
import {Text} from 'shared/ui/Text';
import {TextArea} from 'shared/ui/TextArea';
import {TextInput} from 'shared/ui/TextInput';
import {styled} from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  grid-column-start: 4;
  grid-column-end: 10;
  gap: 32px;
  padding-bottom: 24px;
`;

const ImageArea = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 16px 8px;
`;

const CREATE_PRODUCTS = gql(`
  mutation CreateProducts($input: CreateProducts!, $companyId: String!) {
    createProducts(input: $input, company_id: $companyId) {
      id
      name
      products_category {
        id
        name
      }
      products_brand {
        id
        name
      }
      retail_price
      count
      discount_price
    }
  }
`);

export const CreateProduct = () => {
  const companyId = useAppSelector(state => state.company.data?.id);
  const {data, loading} = useQuery(GET_DATA_CREATE_PRODUCT, {
    variables: {
      first: 40,
      companyId: companyId!,
      categoryFirst2: 40,
    },
  });
  const username = useAppSelector(state => state.company.data?.username);
  const navigate = useNavigate();
  const [createProducts, {loading: loadingCreate}] = useMutation(
    CREATE_PRODUCTS,
    {
      refetchQueries: [
        {
          query: GET_PRODUCTS,
          variables: {
            companyId: companyId!,
            first: 10,
            page: 1,
          },
        },
      ],
    },
  );
  const brands = data?.productsBrands?.data ?? [];
  const categories = data?.productsCategory?.data ?? [];

  const [name, setName] = useState('');
  const [category, setCategory] = useState<string | null>(null);
  const [brand, setBrand] = useState<string | null>(null);
  const [barcode, setBarcode] = useState('');
  const [count, setCount] = useState('');
  const [pieceCount, setPieceCount] = useState<string | null>(null);
  const [description, setDescription] = useState('');
  const [photos, setPhotos] = useState<Omit<ImageUpload, 'type'>[]>([]);

  const [costPrice, setCostPrice] = useState('');
  const [retailPrice, setRetailPrice] = useState('');
  const [charge, setCharge] = useState('');
  const [discountPrice, setDiscountPrice] = useState('');
  const [quantitySwitch, setQuantitySwitch] = useState(false);
  const [quantity, setQuantity] = useState('');
  const [criticalQuantity, setCriticalQuantity] = useState('');
  const [notifyCritical, setNotifyCritical] = useState(false);

  const [imageVisible, setImageVisible] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const [errorBorder, setErrorBorder] = useState<{
    charge: null | string;
    name: null | string;
    retail_price: null | string;
  }>({
    charge: null,
    name: null,
    retail_price: null,
  });

  return (
    <Layout variant="stack">
      <Wrapper>
        <PageHeader variant="layout" back title="Добавить новый товар" />
        <Flex direction="column" gap={24}>
          <Content gap="16px">
            <Text typography="title18">Основная информация</Text>
            <Flex direction="column" gap={16} style={{marginTop: 8}}>
              <TextInput
                value={name}
                onChange={value => {
                  setName(value);
                  setErrorBorder({...errorBorder, name: null});
                }}
                required
                error={errorBorder.name}
                label="Название товара"
              />
              <SelectProductsCategory
                data={categories.map(item => ({
                  label: item.name,
                  value: item.id,
                }))}
                label="Категория товара"
                value={category}
                onChange={setCategory}
                loading={loading}
              />
              <SelectBrand
                data={brands.map(item => ({
                  label: item.name,
                  value: item.id,
                  subtitle: item.products?.length,
                }))}
                loading={loading}
                label="Бренд"
                value={brand}
                onChange={setBrand}
              />
              <TextInput
                value={barcode}
                onChange={setBarcode}
                label="Штрихкод товара (необязательно)"
                placeholder="UPC, EAN, GTIN"
              />
              <Flex direction="row" gap={8}>
                <TextInput
                  width="332px"
                  value={count}
                  height={62}
                  type="number"
                  onChange={setCount}
                  label="Количество"
                  rightElement={
                    <Text
                      style={{width: 'max-content'}}
                      typography="subHead14Regular"
                      color="textTertiary">
                      {productPieceCount.find(item => item.value === pieceCount)
                        ?.small ?? ''}
                    </Text>
                  }
                />
                <Select
                  wrapperStyle={{width: '50%'}}
                  data={productPieceCount}
                  label="Единица измерения"
                  value={pieceCount}
                  onChange={setPieceCount}
                />
              </Flex>
              <TextArea
                value={description}
                onChange={setDescription}
                maxLength={1000}
                label="Описание товара"
                size="large"
              />
            </Flex>
          </Content>
          <Content>
            <Text typography="title18">Фотографии товара</Text>
            <DragDrop
              onLoad={() => {}}
              onChange={values => {
                if (Array.isArray(values)) {
                  setPhotos([...photos, ...values]);
                }
              }}
            />
            {photos.length ? (
              <ImageArea>
                {photos.map((item, index) => (
                  <Image
                    key={'image_' + index}
                    src={item.url ?? undefined}
                    onDelete={() => {
                      setPhotos(photos.filter((_, index2) => index !== index2));
                    }}
                    onClick={() => {
                      setPhotoIndex(index);
                      setImageVisible(true);
                    }}
                  />
                ))}
              </ImageArea>
            ) : null}
            <Flex direction="column" gap={8}>
              <Text typography="subHead14Regular" color="textTertiary">
                Доступные форматы: jpeg, jpg, png
              </Text>
              <Text typography="subHead14Regular" color="textTertiary">
                Размер файла: до 12 Мб
              </Text>
            </Flex>
          </Content>
          <Content>
            <Text typography="title18">Цены</Text>
            <TextInput
              value={costPrice}
              onChange={setCostPrice}
              label="Себестоимость"
              rightElement={
                <Text typography="subHead14Regular" color="textTertiary">
                  ₽
                </Text>
              }
            />
            <Flex direction="row" gap={8}>
              <TextInput
                value={retailPrice}
                required
                error={errorBorder.retail_price}
                onChange={value => {
                  setRetailPrice(value);
                  setErrorBorder({...errorBorder, retail_price: null});
                }}
                wrapperStyle={{flex: 1}}
                placeholder="Розничная цена"
                variant="default"
                rightElement={
                  <Text typography="subHead14Regular" color="textTertiary">
                    ₽
                  </Text>
                }
              />
              <TextInput
                required
                error={errorBorder.charge}
                value={charge}
                onChange={value => {
                  setCharge(value);
                  setErrorBorder({...errorBorder, charge: null});
                }}
                wrapperStyle={{flex: 1}}
                placeholder="Наценка"
                variant="default"
                rightElement={
                  <Text typography="subHead14Regular" color="textTertiary">
                    %
                  </Text>
                }
              />
            </Flex>
            <TextInput
              value={discountPrice}
              onChange={setDiscountPrice}
              label="Цена со скидкой"
              rightElement={
                <Text typography="subHead14Regular" color="textTertiary">
                  ₽
                </Text>
              }
            />
          </Content>
          <Content>
            <Text typography="title18">Запасы товаров</Text>
            <TableViewRow
              style={{gap: 26, cursor: 'pointer'}}
              styleTitle={{pointerEvents: 'none'}}
              styleSubTitle={{pointerEvents: 'none'}}
              leftElement={() => (
                <Switch
                  value={quantitySwitch}
                  onChange={() => setQuantitySwitch(state => !state)}
                />
              )}
              title="Количество на складе"
              subtitle="Отслеживайте количество товаров на складе"
              onClick={() => setQuantitySwitch(state => !state)}
            />
            <Flex direction="row" gap={8}>
              <TextInput
                value={quantity}
                onChange={setQuantity}
                flex={1}
                type="number"
                label="Количество на складе"
                rightElement={
                  <Text typography="subHead14Regular" color="textTertiary">
                    шт
                  </Text>
                }
              />
              <TextInput
                value={criticalQuantity}
                onChange={setCriticalQuantity}
                flex={1}
                type="number"
                label="Критический остаток"
                rightElement={
                  <Text typography="subHead14Regular" color="textTertiary">
                    шт
                  </Text>
                }
              />
            </Flex>
            <TableViewRow
              leftElement={() => (
                <CheckBox
                  checked={notifyCritical}
                  onChange={() => setNotifyCritical(state => !state)}
                />
              )}
              title="Уведомлять о низких запасах склада"
              onClick={() => setNotifyCritical(state => !state)}
              style={{cursor: 'pointer'}}
              styleTitle={{pointerEvents: 'none'}}
            />
          </Content>
        </Flex>
        <Button
          loading={loadingCreate}
          onClick={() => {
            if (charge !== '' && name !== '' && retailPrice !== '') {
              createProducts({
                variables: {
                  input: {
                    barcode: barcode !== '' ? barcode : undefined,
                    charge: +charge,
                    cost_price: +costPrice,
                    count: +count,
                    critical_quantity:
                      criticalQuantity !== '' ? +criticalQuantity : undefined,
                    description: description,
                    discount_price: +discountPrice,
                    products_brand_id: brand ?? undefined,
                    products_category_id: category ?? undefined,
                    images:
                      photos.length > 0
                        ? photos.map(item => ({
                            ...item,
                            type: ImageType.Image,
                          }))
                        : undefined,
                    notify_critical: quantitySwitch
                      ? notifyCritical
                      : undefined,
                    piece_count: pieceCount as ProductsPieceCount,
                    quantity:
                      quantitySwitch && quantity !== '' ? +quantity : undefined,
                    retail_price: +retailPrice,
                    name: name,
                  },
                  companyId: companyId!,
                },
              })
                .then(() => {
                  navigate(`/${username}/products`, {state: {refetch: true}});
                })
                .catch(e => {
                  if (e instanceof ApolloError) {
                    showAlert('error', e.message);
                  }
                });
            } else {
              showAlert('error', 'Заполните обязательные поля');
              setErrorBorder({
                charge: charge !== '' ? null : 'Заполните обязательное поле',
                name: name !== '' ? null : 'Заполните обязательное поле',
                retail_price:
                  retailPrice !== '' ? null : 'Заполните обязательное поле',
              });
            }
          }}
          size="large">
          Сохранить
        </Button>
        <ImageView
          visible={imageVisible}
          onClose={() => setImageVisible(false)}
          images={photos.map(item => item.url!)}
          index={photoIndex}
        />
      </Wrapper>
    </Layout>
  );
};
