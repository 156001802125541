import React from 'react';
import styled from 'styled-components';
import {Text} from './Text';

type Props = {
  style?: React.CSSProperties;
  styleTitle?: React.CSSProperties;
  styleSubTitle?: React.CSSProperties;
  title: string;
  subtitle?: string;
  leftElement?: () => React.ReactNode;
  rightElement?: () => React.ReactNode;
  pointer?: boolean;
  hover?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;
const Wrapper = styled.button<{pointer?: boolean; hover?: boolean}>`
  padding: 0px;
  display: flex;
  flex: 1;
  height: 56px;
  flex-direction: row;
  gap: 16px;
  pointer-events: all;
  align-items: center;
  background-color: transparent;
  border-width: 0px;
  border-style: solid;
  border-color: ${props => props.theme.borderPrimary};
  cursor: ${props => (props.pointer ? 'pointer' : 'default')};
  &:hover {
    background-color: ${({theme, hover}) =>
      hover ? theme.bgSecondary : undefined};
  }
`;
const Column = styled.div`
  display: flex;
  flex: 1;
  gap: 4px;
  flex-direction: column;
`;
export const TableViewRow = ({
  title,
  subtitle,
  styleTitle,
  styleSubTitle,
  leftElement,
  rightElement,
  pointer,
  hover,
  ...rest
}: React.PropsWithChildren<Props>) => {
  const cursorStyle = pointer ? {cursor: 'pointer'} : {};

  return (
    <Wrapper pointer={pointer} hover={hover} {...rest}>
      {leftElement && leftElement()}
      <Column>
        <Text
          style={{...styleTitle, ...cursorStyle}}
          typography="text16Regular"
          color={rest.disabled ? 'textTertiary' : 'textPrimary'}>
          {title}
        </Text>
        {subtitle && (
          <Text
            style={{...styleSubTitle, ...cursorStyle}}
            typography="subHead14Regular"
            color="textTertiary">
            {subtitle}
          </Text>
        )}
      </Column>
      {rightElement && rightElement()}
    </Wrapper>
  );
};
