import React from 'react';

export const Vk = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="12" fill="#0077FF" />
      <path
        d="M12.6708 16.3341C8.21171 16.3341 5.50662 13.2405 5.40039 8.09961H7.65896C7.72937 11.8762 9.44692 13.4765 10.7643 13.8056V8.09961H12.9283V11.3587C14.1987 11.2179 15.5284 9.73564 15.9756 8.09961H18.1063C17.7648 10.1111 16.3171 11.5934 15.2944 12.2048C16.3184 12.6995 17.9649 13.9934 18.6004 16.3341H16.2591C15.765 14.7697 14.5532 13.558 12.9296 13.3931V16.3341H12.6708Z"
        fill="white"
      />
    </svg>
  );
};
