import React from 'react';
import {useColorScheme} from 'shared/lib/hooks';
import {EmptyContainer} from 'shared/ui/EmptyContainer';

export const EmptyTags = ({
  size = 400,
  mt = 58,
}: {
  size?: number;
  mt?: number;
}) => {
  const colorScheme = useColorScheme();
  return (
    <EmptyContainer size={size} mt={mt}>
      {colorScheme === 'light' ? (
        <svg
          viewBox="0 0 400 400"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M319.825 334.644C326.222 334.644 331.409 329.458 331.409 323.06C331.409 316.663 326.222 311.476 319.825 311.476C313.427 311.476 308.241 316.663 308.241 323.06C308.241 329.458 313.427 334.644 319.825 334.644Z"
            fill="#EAEEF9"
          />
          <path
            d="M350.16 150.104C354.529 150.104 358.071 146.562 358.071 142.193C358.071 137.824 354.529 134.282 350.16 134.282C345.791 134.282 342.249 137.824 342.249 142.193C342.249 146.562 345.791 150.104 350.16 150.104Z"
            fill="#EAEEF9"
          />
          <path
            d="M67.3649 114.307C71.734 114.307 75.2758 110.765 75.2758 106.396C75.2758 102.027 71.734 98.4851 67.3649 98.4851C62.9959 98.4851 59.4541 102.027 59.4541 106.396C59.4541 110.765 62.9959 114.307 67.3649 114.307Z"
            fill="#EAEEF9"
          />
          <path
            d="M59.8263 322.962C67.9403 322.962 74.5179 316.384 74.5179 308.27C74.5179 300.156 67.9403 293.578 59.8263 293.578C51.7124 293.578 45.1348 300.156 45.1348 308.27C45.1348 316.384 51.7124 322.962 59.8263 322.962Z"
            fill="#EAEEF9"
          />
          <path
            d="M201.925 351.527C281.033 351.527 345.167 287.393 345.167 208.002C345.167 128.612 280.75 64.4775 201.925 64.4775C122.817 64.4775 58.6826 128.612 58.6826 208.002C58.6826 287.393 122.817 351.527 201.925 351.527Z"
            fill="#EAEEF9"
          />
          <g filter="url(#filter0_d_17967_3027)">
            <path
              d="M304.1 160.152L290.278 208.527L286.329 222.019L251.776 343.45C249.143 353.322 238.613 358.917 228.74 356.284L170.164 339.501L109.942 322.06C100.07 319.427 94.4754 308.896 97.108 299.024L142.521 139.42L149.432 115.726C151.735 107.828 157.33 101.247 164.569 97.6269L232.031 65.377C242.232 60.4408 254.408 64.0607 260.661 73.604L300.809 136.458C305.087 143.369 306.403 151.925 304.1 160.152Z"
              fill="url(#paint0_linear_17967_3027)"
            />
          </g>
          <g filter="url(#filter1_d_17967_3027)">
            <path
              d="M133.636 331.274L46.4298 244.067C39.1901 236.828 39.1901 224.981 46.4298 217.741L181.353 82.8181C187.276 76.8947 195.174 73.9329 203.401 74.262L277.773 78.8692C288.962 79.5273 298.176 88.4125 298.834 99.9303L303.442 174.302C304.1 182.529 300.809 190.756 294.885 196.351L159.963 331.274C152.723 338.514 140.876 338.514 133.636 331.274Z"
              fill="url(#paint1_linear_17967_3027)"
            />
          </g>
          <path
            d="M268.734 140.805C277.473 132.066 277.473 117.898 268.734 109.159C259.995 100.42 245.827 100.42 237.088 109.159C228.349 117.898 228.349 132.066 237.088 140.805C245.827 149.544 259.995 149.544 268.734 140.805Z"
            fill="#E5EAF6"
          />
          <path
            d="M89.8687 235.183C88.2233 235.183 86.5779 234.525 85.2616 233.209C82.6289 230.576 82.6289 226.627 85.2616 223.994L170.823 138.433C173.456 135.8 177.404 135.8 180.037 138.433C182.67 141.066 182.67 145.015 180.037 147.647L94.805 233.209C93.4886 234.525 91.8432 235.183 89.8687 235.183Z"
            fill="#E5EAF6"
          />
          <path
            d="M112.905 257.89C111.259 257.89 109.614 257.232 108.298 255.915C105.665 253.283 105.665 249.334 108.298 246.701L193.53 161.469C196.163 158.836 200.112 158.836 202.744 161.469C205.377 164.102 205.377 168.051 202.744 170.683L117.512 255.915C116.196 257.232 114.55 257.89 112.905 257.89Z"
            fill="#E5EAF6"
          />
          <path
            d="M144.826 289.809C143.18 289.809 141.535 289.151 140.219 287.835C137.586 285.202 137.586 281.253 140.219 278.62L168.52 250.319C171.152 247.687 175.101 247.687 177.734 250.319C180.367 252.952 180.367 256.901 177.734 259.534L149.433 287.835C148.117 289.151 146.471 289.809 144.826 289.809Z"
            fill="#E5EAF6"
          />
          <path
            d="M330.755 91.375C330.755 110.462 319.566 127.903 301.796 135.472L300.479 132.181V128.561C314.63 121.651 323.844 107.171 323.844 91.375C323.844 68.6684 305.416 49.9107 282.38 49.9107C259.673 49.9107 240.916 68.3393 240.916 91.375C240.916 95.9822 241.574 100.26 243.219 104.538C247.826 118.031 259.015 128.561 273.166 131.852L272.507 135.143L270.533 138.434C255.066 134.485 242.89 123.296 237.296 108.816C235.321 103.551 234.005 97.6276 234.005 91.7041C234.334 64.7194 255.724 43 282.38 43C309.365 43 330.755 64.7194 330.755 91.375Z"
            fill="#989FB0"
          />
          <defs>
            <filter
              id="filter0_d_17967_3027"
              x="74.459"
              y="52.1733"
              width="252.762"
              height="337.76"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_17967_3027"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_17967_3027"
                result="shape"
              />
            </filter>
            <filter
              id="filter1_d_17967_3027"
              x="19"
              y="63.2373"
              width="306.524"
              height="306.466"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_17967_3027"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_17967_3027"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_17967_3027"
              x1="200.772"
              y1="56.3784"
              x2="200.772"
              y2="360.1"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#FDFEFF" />
              <stop offset="0.9964" stopColor="#ECF0F5" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_17967_3027"
              x1="172.177"
              y1="68.1662"
              x2="172.177"
              y2="339.533"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#FDFEFF" />
              <stop offset="0.9964" stopColor="#ECF0F5" />
            </linearGradient>
          </defs>
        </svg>
      ) : (
        <svg
          viewBox="0 0 400 400"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M319.823 334.645C326.221 334.645 331.407 329.459 331.407 323.061C331.407 316.664 326.221 311.478 319.823 311.478C313.425 311.478 308.239 316.664 308.239 323.061C308.239 329.459 313.425 334.645 319.823 334.645Z"
            fill="#2A2E37"
          />
          <path
            d="M350.157 150.104C354.526 150.104 358.068 146.562 358.068 142.193C358.068 137.824 354.526 134.282 350.157 134.282C345.788 134.282 342.246 137.824 342.246 142.193C342.246 146.562 345.788 150.104 350.157 150.104Z"
            fill="#2A2E37"
          />
          <path
            d="M67.3611 114.307C71.7301 114.307 75.2719 110.765 75.2719 106.396C75.2719 102.027 71.7301 98.4849 67.3611 98.4849C62.992 98.4849 59.4502 102.027 59.4502 106.396C59.4502 110.765 62.992 114.307 67.3611 114.307Z"
            fill="#2A2E37"
          />
          <path
            d="M59.8225 322.962C67.9364 322.962 74.5141 316.385 74.5141 308.271C74.5141 300.157 67.9364 293.579 59.8225 293.579C51.7085 293.579 45.1309 300.157 45.1309 308.271C45.1309 316.385 51.7085 322.962 59.8225 322.962Z"
            fill="#2A2E37"
          />
          <path
            d="M201.926 351.53C281.035 351.53 345.169 287.396 345.169 208.004C345.169 128.613 280.752 64.4785 201.926 64.4785C122.817 64.4785 58.6826 128.613 58.6826 208.004C58.6826 287.396 122.817 351.53 201.926 351.53Z"
            fill="#2A2E37"
          />
          <g filter="url(#filter0_d_17967_3049)">
            <path
              d="M304.102 160.154L290.281 208.529L286.332 222.021L251.778 343.453C249.145 353.326 238.615 358.92 228.742 356.287L170.165 339.504L109.943 322.063C100.071 319.43 94.4763 308.899 97.109 299.027L142.522 139.422L149.433 115.728C151.737 107.83 157.331 101.248 164.571 97.6281L232.033 65.378C242.235 60.4418 254.411 64.0617 260.663 73.6051L300.811 136.46C305.089 143.371 306.406 151.927 304.102 160.154Z"
              fill="url(#paint0_linear_17967_3049)"
            />
          </g>
          <g filter="url(#filter1_d_17967_3049)">
            <path
              d="M133.637 331.276L46.4299 244.069C39.19 236.829 39.19 224.982 46.4299 217.743L181.354 82.8187C187.277 76.8952 195.175 73.9334 203.402 74.2625L277.775 78.8697C288.964 79.5278 298.178 88.4131 298.836 99.931L303.444 174.304C304.102 182.531 300.811 190.758 294.887 196.352L159.963 331.276C152.724 338.516 140.877 338.516 133.637 331.276Z"
              fill="url(#paint1_linear_17967_3049)"
            />
          </g>
          <path
            d="M268.736 140.806C277.475 132.067 277.475 117.899 268.736 109.16C259.997 100.421 245.829 100.421 237.09 109.16C228.351 117.899 228.351 132.067 237.09 140.806C245.829 149.545 259.997 149.545 268.736 140.806Z"
            fill="#393E4D"
          />
          <path
            d="M89.8688 235.184C88.2234 235.184 86.5779 234.526 85.2616 233.21C82.6289 230.577 82.6289 226.628 85.2616 223.996L170.823 138.434C173.456 135.801 177.405 135.801 180.037 138.434C182.67 141.067 182.67 145.016 180.037 147.648L94.805 233.21C93.4887 234.526 91.8433 235.184 89.8688 235.184Z"
            fill="#393E4D"
          />
          <path
            d="M112.904 257.891C111.259 257.891 109.613 257.233 108.297 255.916C105.664 253.284 105.664 249.335 108.297 246.702L193.529 161.47C196.162 158.837 200.111 158.837 202.744 161.47C205.376 164.102 205.376 168.051 202.744 170.684L117.511 255.916C116.195 257.233 114.549 257.891 112.904 257.891Z"
            fill="#393E4D"
          />
          <path
            d="M144.826 289.812C143.18 289.812 141.535 289.154 140.219 287.838C137.586 285.205 137.586 281.256 140.219 278.623L168.52 250.322C171.152 247.689 175.101 247.689 177.734 250.322C180.367 252.955 180.367 256.904 177.734 259.536L149.433 287.838C148.117 289.154 146.471 289.812 144.826 289.812Z"
            fill="#393E4D"
          />
          <path
            d="M330.758 91.3752C330.758 110.462 319.569 127.903 301.799 135.472L300.483 132.181V128.562C314.633 121.651 323.847 107.171 323.847 91.3752C323.847 68.6685 305.419 49.9107 282.383 49.9107C259.676 49.9107 240.919 68.3394 240.919 91.3752C240.919 95.9824 241.577 100.26 243.222 104.539C247.829 118.031 259.018 128.562 273.169 131.852L272.511 135.143L270.536 138.434C255.069 134.485 242.893 123.296 237.299 108.817C235.324 103.551 234.008 97.6278 234.008 91.7043C234.337 64.7195 255.727 43 282.383 43C309.368 43 330.758 64.7195 330.758 91.3752Z"
            fill="url(#paint2_linear_17967_3049)"
          />
          <defs>
            <filter
              id="filter0_d_17967_3049"
              x="74.46"
              y="52.1743"
              width="252.764"
              height="337.762"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_17967_3049"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_17967_3049"
                result="shape"
              />
            </filter>
            <filter
              id="filter1_d_17967_3049"
              x="19"
              y="63.2378"
              width="306.526"
              height="306.468"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_17967_3049"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_17967_3049"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_17967_3049"
              x1="200.774"
              y1="56.3793"
              x2="200.774"
              y2="360.103"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#5C6479" />
              <stop offset="0.9964" stopColor="#5C6479" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_17967_3049"
              x1="172.178"
              y1="68.1666"
              x2="172.178"
              y2="339.536"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#5C6479" />
              <stop offset="0.9964" stopColor="#5C6479" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_17967_3049"
              x1="256.201"
              y1="68.0514"
              x2="296.983"
              y2="138.046"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#E3EAF7" />
              <stop offset="1" stopColor="#ADB6C8" />
            </linearGradient>
          </defs>
        </svg>
      )}
    </EmptyContainer>
  );
};
