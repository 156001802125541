import {Card, GET_HISTORY_RESERVE} from 'entities/products';
import React, {useState} from 'react';
import {ProductsHistoryEnum} from 'shared/__generated__/graphql';
import {EmptyCloud} from 'shared/illustration/EmptyCloud';
import {getNumberFormat, getShortName, getTypedDate} from 'shared/lib/utils';
import {Flex} from 'shared/ui/Flex';
import {SectionList} from 'shared/ui/SectionList';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';
import {ModalReserve} from './ui/ModalReserve';
import {useQuery} from '@apollo/client';
import {useParams} from 'react-router-dom';
import dayjs from 'dayjs';
import {SkeletonHistorySalesProducts} from './ui/Skeleton';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: ${({theme}) => theme.bgPrimary};
  padding: 24px 0;
  border-radius: 24px;
  gap: 8px;
  margin: 0 0 24px 0;
  max-height: calc(100vh - 64px - 24px - 64px);
`;

type SectionItem = {
  title: string;
  data: {
    id: string;
    avatar?: string;
    reason: string;
    employeeId: string;
    companyName: string;
    name: string;
    price: number;
    type: ProductsHistoryEnum;
    count: number;
    countAfter: number;
    created: string;
  }[];
};

export const HistoryReserve = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [modalData, setModalData] = useState<{
    id: string;
    employeeName: string;
    employeeId?: string;
    operation: string;
    count: number;
    costPrice: number;
    companyName: string;
    countAfter: number;
    created: string;
    type: ProductsHistoryEnum;
  } | null>(null);
  const params = useParams();
  const [sections, setSections] = useState<SectionItem[]>([]);
  const {loading} = useQuery(GET_HISTORY_RESERVE, {
    variables: {
      first: 10,
      productsId: params.id!,
    },
    onCompleted: res => {
      const data = res.getProductsHistory?.data ?? [];
      const dates = data.map(item =>
        dayjs(item.created_at).format('YYYY-MM-DD'),
      );
      const filteredDates = [...new Set(dates)];
      setSections(
        filteredDates.map(item => ({
          title: item,
          data: data
            .filter(item2 => dayjs(item).isSame(item2.created_at, 'date'))
            .map(item2 => ({
              avatar:
                item2.employee?.avatar?.url_64 ?? item2.employee?.avatar?.url,
              id: item2.id,
              reason: item2.reason,
              employeeId: item2.employee?.id ?? '',
              name: getShortName(
                item2.employee?.name,
                item2.employee?.surname,
                'employee',
              ),
              price: item2.price,
              companyName: item2.products?.company.name ?? '',
              type: item2.type,
              count: item2.count,
              countAfter: item2.count_after,
              created: item2.created_at,
            })),
        })),
      );
    },
  });

  return (
    <Wrapper>
      {loading ? (
        <SkeletonHistorySalesProducts />
      ) : (
        <SectionList
          sections={sections}
          renderItem={item => (
            <Card
              avatar={item.avatar}
              title={item.reason}
              name={item.name}
              status={'completed'}
              onClick={() => {
                setModalData({
                  id: item.id,
                  type: item.type,
                  employeeId: item.employeeId,
                  employeeName: item.name,
                  operation: item.reason,
                  companyName: item.companyName,
                  count: item.count,
                  costPrice: item.price,
                  countAfter: item.countAfter,
                  created: item.created,
                });
                setModalVisible(true);
              }}
              info={
                <Flex gap={4}>
                  <Text
                    typography="footNote12Regular"
                    color="textTertiary"
                    style={{pointerEvents: 'none'}}>
                    Склад ·
                  </Text>
                  <Text
                    typography="footNote12Medium"
                    style={{pointerEvents: 'none'}}>
                    {(item.type === 'repeal' ? '- ' : '+ ') +
                      getNumberFormat(item.count, '\u00A0шт.')}
                  </Text>
                </Flex>
              }
            />
          )}
          sectionItem={section => (
            <Flex style={{height: 36, padding: '0 24px'}} alignItems="center">
              <Text>{getTypedDate(section.title)}</Text>
            </Flex>
          )}
          ListEmptyComponent={
            <Flex direction="column" gap={16}>
              <EmptyCloud width="300px" />
              <Text typography="title20" align="center">
                Нет запасов
              </Text>
              <Text typography="text18" color="textTertiary" align="center">
                К сожалению история запасов товара отсутсвует.
              </Text>
            </Flex>
          }
        />
      )}
      <ModalReserve
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
        id={modalData?.id}
        type={modalData?.type}
        address={modalData?.companyName}
        countAfter={modalData?.countAfter ?? 0}
        costPrice={modalData?.costPrice}
        created={modalData?.created}
        employeeName={modalData?.employeeName}
        count={modalData?.count}
        operation={modalData?.operation}
      />
    </Wrapper>
  );
};
