import React, {useEffect} from 'react';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import ru from 'dayjs/locale/ru';
import dayjs from 'dayjs';
import {gql} from 'shared/__generated__';
import {useQuery} from '@apollo/client';
import {setCompanyData, useAppDispatch, useAppSelector} from 'shared/store';
import {client} from 'shared/config/apollo';
dayjs.locale({...ru, weekStart: 1});

const USERNAME_COMPANY = gql(`
query CompanyUsername($username: String) {
  company(username: $username) {
    id
    username
    subscription_end_date
    plan {
      created_at
      id
      price
      slots
      type
      updated_at
    }
    name
  }
  me {
    employees {
      id
      company {
        id
      }
      role
      invite_status
    }
  }
}
`);

function App({failing}: {failing?: 'permission'}) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const companyId = useAppSelector(state => state.company.data?.id);
  const subs = location.pathname.substring(1);
  const token = useAppSelector(state => state.auth.token);

  useQuery(USERNAME_COMPANY, {
    variables: {
      username: subs.substring(0, subs.indexOf('/')),
    },
    skip: token ? false : true,
    onCompleted: async ({company, me}) => {
      if (company) {
        const myEmployee = me?.employees?.find(
          item => item.company.id === companyId,
        );
        if (!myEmployee || myEmployee.invite_status === 'declined') {
          navigate('/my-companies');
          return;
        }
        if (company.id !== companyId) {
          dispatch(
            setCompanyData({
              id: company.id,
              employeeId: myEmployee?.id ?? '',
              username: company?.username,
            }),
          );
        }
        await client.refetchQueries({
          include: ['Me', 'SidebarQuery'],
        });
        if (
          company.id === companyId &&
          dayjs().isAfter(dayjs(company.subscription_end_date))
        ) {
          navigate('/unavailable-company', {
            state: {
              plan: company.plan,
              name: company.name,
              role: myEmployee?.role,
              companyId: companyId,
            },
          });
        }
      }
    },
    onError: e => {
      console.log('error app', e);
    },
  });

  useEffect(() => {
    if (failing) {
      navigate('*', {state: failing});
    }
  }, [dispatch, failing, navigate]);

  return <Outlet />;
}

export default App;
