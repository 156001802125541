import React from 'react';

export const Telegram = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="url(#paint0_linear_18294_9727)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.43494 11.873C8.93319 10.3488 11.2659 9.34403 12.4331 8.85856C15.7656 7.47245 16.4581 7.23167 16.9094 7.22372C17.0087 7.22197 17.2306 7.24657 17.3744 7.36323C17.4958 7.46174 17.5292 7.59481 17.5452 7.68821C17.5612 7.78161 17.5811 7.99436 17.5653 8.16061C17.3847 10.0581 16.6033 14.6628 16.2057 16.788C16.0375 17.6873 15.7063 17.9888 15.3856 18.0183C14.6888 18.0824 14.1596 17.5577 13.4847 17.1153C12.4285 16.423 11.8318 15.992 10.8067 15.3164C9.62189 14.5357 10.3899 14.1066 11.0651 13.4053C11.2418 13.2217 14.3122 10.429 14.3717 10.1756C14.3791 10.144 14.386 10.0258 14.3158 9.96348C14.2457 9.90112 14.1421 9.92244 14.0674 9.93941C13.9614 9.96345 12.2742 11.0786 9.00569 13.285C8.52678 13.6138 8.09299 13.7741 7.70434 13.7657C7.27588 13.7564 6.45169 13.5234 5.83899 13.3242C5.08749 13.08 4.49021 12.9508 4.54222 12.5359C4.56931 12.3199 4.86688 12.0989 5.43494 11.873Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_18294_9727"
          x1="12"
          y1="0"
          x2="12"
          y2="23.822"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#2AABEE" />
          <stop offset="1" stopColor="#229ED9" />
        </linearGradient>
      </defs>
    </svg>
  );
};
