import React from 'react';
import {useColorScheme} from 'shared/lib/hooks';
import {EmptyContainer} from 'shared/ui/EmptyContainer';

export const EmptyList2 = ({size = 400}: {size?: number}) => {
  const colorScheme = useColorScheme();
  return (
    <EmptyContainer size={size}>
      {colorScheme === 'light' ? (
        <svg viewBox="0 0 400 400" fill="none">
          <path
            d="M200 350C282.843 350 350 282.843 350 200C350 117.157 282.843 50 200 50C117.157 50 50 117.157 50 200C50 282.843 117.157 350 200 350Z"
            fill="#EAEEF9"
          />
          <g filter="url(#filter0_d_18292_389)">
            <path
              d="M312.924 151.576H87.2619C82.9113 151.576 79.3174 147.982 79.3174 143.632V75.3467C79.3174 70.9962 82.9113 67.4022 87.2619 67.4022H312.924C317.275 67.4022 320.868 70.9962 320.868 75.3467V143.632C320.868 147.982 317.275 151.576 312.924 151.576Z"
              fill="url(#paint0_linear_18292_389)"
            />
          </g>
          <path
            d="M296.846 93.6949H147.224C145.143 93.6949 143.63 95.3973 143.63 97.2889C143.63 99.3696 145.332 100.883 147.224 100.883H296.846C298.926 100.883 300.439 99.1804 300.439 97.2889C300.439 95.2082 298.737 93.6949 296.846 93.6949Z"
            fill="#AAB2C5"
          />
          <path
            d="M229.695 118.096H147.224C145.143 118.096 143.63 119.798 143.63 121.69C143.63 123.582 145.332 125.284 147.224 125.284H229.695C231.776 125.284 233.289 123.582 233.289 121.69C233.289 119.798 231.776 118.096 229.695 118.096Z"
            fill="#AAB2C5"
          />
          <path
            d="M113.933 128.31C124.379 128.31 132.848 119.841 132.848 109.395C132.848 98.9478 124.379 90.479 113.933 90.479C103.486 90.479 95.0171 98.9478 95.0171 109.395C95.0171 119.841 103.486 128.31 113.933 128.31Z"
            fill="#D6DCE8"
            stroke="#AAB2C5"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M122.444 102.017C121.309 101.261 119.796 101.45 119.039 102.396L112.229 111.475L108.257 107.881C107.311 106.935 105.798 107.124 104.852 108.07C103.906 109.016 104.096 110.529 105.041 111.475L110.905 116.771C111.284 117.15 111.851 117.339 112.608 117.339H112.797C113.553 117.339 114.121 116.961 114.499 116.393L123.011 105.233C123.768 104.287 123.579 102.774 122.444 102.017Z"
            fill="#AAB2C5"
          />
          <path
            d="M293.064 246.154H86.5069C82.5347 246.154 79.1299 242.938 79.1299 238.777V176.356C79.1299 172.383 82.3455 168.979 86.5069 168.979H293.064C297.037 168.979 300.441 172.194 300.441 176.356V238.966C300.441 242.938 297.226 246.154 293.064 246.154Z"
            fill="url(#paint1_linear_18292_389)"
          />
          <path
            d="M278.501 193.19H141.363C139.472 193.19 138.147 194.703 138.147 196.405C138.147 198.108 139.661 199.621 141.363 199.621H278.311C280.203 199.621 281.527 198.108 281.527 196.405C281.527 194.703 280.203 193.19 278.501 193.19Z"
            fill="#D6DCE8"
          />
          <path
            d="M217.025 215.51H141.363C139.472 215.51 138.147 217.023 138.147 218.725C138.147 220.428 139.661 221.941 141.363 221.941H216.836C218.728 221.941 220.052 220.428 220.052 218.725C220.052 217.023 218.728 215.51 217.025 215.51Z"
            fill="#D6DCE8"
          />
          <path
            d="M110.907 224.779C120.414 224.779 128.121 217.072 128.121 207.566C128.121 198.059 120.414 190.353 110.907 190.353C101.401 190.353 93.6943 198.059 93.6943 207.566C93.6943 217.072 101.401 224.779 110.907 224.779Z"
            fill="#F1F3F9"
            stroke="#D6DCE8"
            strokeWidth="1.5"
            strokeMiterlimit="10"
          />
          <path
            d="M275.093 336.759H85.9378C82.1547 336.759 79.3174 333.733 79.3174 330.139V272.825C79.3174 269.042 82.3439 266.205 85.9378 266.205H275.093C278.876 266.205 281.713 269.231 281.713 272.825V329.95C281.713 333.733 278.687 336.759 275.093 336.759Z"
            fill="url(#paint2_linear_18292_389)"
          />
          <path
            d="M261.663 288.147H136.253C134.551 288.147 133.227 289.471 133.227 291.173C133.227 292.876 134.551 294.2 136.253 294.2H261.663C263.365 294.2 264.689 292.876 264.689 291.173C264.689 289.471 263.176 288.147 261.663 288.147Z"
            fill="#D6DCE8"
          />
          <path
            d="M205.295 308.575H136.253C134.551 308.575 133.227 309.899 133.227 311.602C133.227 313.304 134.551 314.628 136.253 314.628H205.484C207.186 314.628 208.51 313.304 208.51 311.602C208.321 309.899 206.997 308.575 205.295 308.575Z"
            fill="#D6DCE8"
          />
          <path
            d="M108.257 317.276C117.032 317.276 124.146 310.163 124.146 301.387C124.146 292.612 117.032 285.498 108.257 285.498C99.4819 285.498 92.3682 292.612 92.3682 301.387C92.3682 310.163 99.4819 317.276 108.257 317.276Z"
            fill="#F1F3F9"
            stroke="#D6DCE8"
            strokeWidth="1.5"
            strokeMiterlimit="10"
          />
          <defs>
            <filter
              id="filter0_d_18292_389"
              x="57.3174"
              y="56.4022"
              width="285.551"
              height="128.174"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_18292_389"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_18292_389"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_18292_389"
              x1="200.014"
              y1="65.4552"
              x2="200.014"
              y2="152.484"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#FDFEFF" />
              <stop offset="0.9964" stopColor="#ECF0F5" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_18292_389"
              x1="189.713"
              y1="167.193"
              x2="189.713"
              y2="246.986"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#FDFEFF" />
              <stop offset="0.9964" stopColor="#ECF0F5" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_18292_389"
              x1="180.449"
              y1="264.573"
              x2="180.449"
              y2="337.52"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#FDFEFF" />
              <stop offset="0.9964" stopColor="#ECF0F5" />
            </linearGradient>
          </defs>
        </svg>
      ) : (
        <svg viewBox="0 0 400 400" fill="none">
          <path
            d="M200 350C282.843 350 350 282.843 350 200C350 117.157 282.843 50 200 50C117.157 50 50 117.157 50 200C50 282.843 117.157 350 200 350Z"
            fill="#2A2E37"
          />
          <g filter="url(#filter0_d_18292_395)">
            <path
              d="M312.927 151.576H87.2648C82.9143 151.576 79.3203 147.982 79.3203 143.632V75.3466C79.3203 70.996 82.9143 67.4021 87.2648 67.4021H312.927C317.277 67.4021 320.871 70.996 320.871 75.3466V143.632C320.871 147.982 317.277 151.576 312.927 151.576Z"
              fill="url(#paint0_linear_18292_395)"
            />
          </g>
          <path
            d="M296.848 93.6948H147.226C145.146 93.6948 143.632 95.3972 143.632 97.2888C143.632 99.3695 145.335 100.883 147.226 100.883H296.848C298.929 100.883 300.442 99.1803 300.442 97.2888C300.442 95.2081 298.74 93.6948 296.848 93.6948Z"
            fill="#8E96AB"
          />
          <path
            d="M229.698 118.096H147.226C145.146 118.096 143.632 119.798 143.632 121.69C143.632 123.581 145.335 125.284 147.226 125.284H229.698C231.779 125.284 233.292 123.581 233.292 121.69C233.292 119.798 231.779 118.096 229.698 118.096Z"
            fill="#8E96AB"
          />
          <path
            d="M113.935 128.31C124.382 128.31 132.851 119.841 132.851 109.395C132.851 98.9478 124.382 90.479 113.935 90.479C103.488 90.479 95.0195 98.9478 95.0195 109.395C95.0195 119.841 103.488 128.31 113.935 128.31Z"
            fill="#5C6479"
          />
          <path
            d="M122.446 102.017C121.311 101.261 119.798 101.45 119.041 102.396L112.232 111.475L108.26 107.881C107.314 106.935 105.8 107.125 104.855 108.07C103.909 109.016 104.098 110.529 105.044 111.475L110.908 116.772C111.286 117.15 111.853 117.339 112.61 117.339H112.799C113.556 117.339 114.123 116.961 114.502 116.393L123.014 105.233C123.77 104.287 123.581 102.774 122.446 102.017Z"
            fill="#C6CDDF"
          />
          <path
            d="M293.064 246.154H86.5069C82.5347 246.154 79.1299 242.938 79.1299 238.777V176.356C79.1299 172.383 82.3455 168.979 86.5069 168.979H293.064C297.037 168.979 300.441 172.194 300.441 176.356V238.966C300.441 242.938 297.226 246.154 293.064 246.154Z"
            fill="#5C6479"
          />
          <path
            d="M278.501 193.19H141.363C139.472 193.19 138.147 194.703 138.147 196.405C138.147 198.108 139.661 199.621 141.363 199.621H278.311C280.203 199.621 281.527 198.108 281.527 196.405C281.527 194.703 280.203 193.19 278.501 193.19Z"
            fill="#8E96AB"
          />
          <path
            d="M217.025 215.51H141.363C139.472 215.51 138.147 217.023 138.147 218.725C138.147 220.428 139.661 221.941 141.363 221.941H216.836C218.728 221.941 220.052 220.428 220.052 218.725C220.052 217.023 218.728 215.51 217.025 215.51Z"
            fill="#8E96AB"
          />
          <path
            d="M110.907 224.779C120.414 224.779 128.121 217.072 128.121 207.566C128.121 198.059 120.414 190.353 110.907 190.353C101.401 190.353 93.6943 198.059 93.6943 207.566C93.6943 217.072 101.401 224.779 110.907 224.779Z"
            stroke="#8E96AB"
            strokeWidth="1.5"
            strokeMiterlimit="10"
          />
          <path
            d="M275.096 336.759H85.9412C82.1581 336.759 79.3208 333.733 79.3208 330.139V272.825C79.3208 269.042 82.3473 266.205 85.9412 266.205H275.096C278.879 266.205 281.717 269.231 281.717 272.825V329.95C281.717 333.733 278.69 336.759 275.096 336.759Z"
            fill="#5C6479"
          />
          <path
            d="M261.667 288.147H136.257C134.555 288.147 133.23 289.471 133.23 291.173C133.23 292.876 134.555 294.2 136.257 294.2H261.667C263.369 294.2 264.693 292.876 264.693 291.173C264.693 289.471 263.18 288.147 261.667 288.147Z"
            fill="#8E96AB"
          />
          <path
            d="M205.299 308.575H136.257C134.555 308.575 133.23 309.899 133.23 311.602C133.23 313.304 134.555 314.628 136.257 314.628H205.488C207.19 314.628 208.514 313.304 208.514 311.602C208.325 309.899 207.001 308.575 205.299 308.575Z"
            fill="#8E96AB"
          />
          <path
            d="M108.26 317.276C117.035 317.276 124.149 310.163 124.149 301.387C124.149 292.612 117.035 285.498 108.26 285.498C99.4849 285.498 92.3711 292.612 92.3711 301.387C92.3711 310.163 99.4849 317.276 108.26 317.276Z"
            stroke="#8E96AB"
            strokeWidth="1.5"
            strokeMiterlimit="10"
          />
          <defs>
            <filter
              id="filter0_d_18292_395"
              x="57.3203"
              y="56.4021"
              width="285.551"
              height="128.174"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_18292_395"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_18292_395"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_18292_395"
              x1="134.729"
              y1="89.4978"
              x2="151.058"
              y2="168.83"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#E3EAF7" />
              <stop offset="1" stopColor="#ADB6C8" />
            </linearGradient>
          </defs>
        </svg>
      )}
    </EmptyContainer>
  );
};
