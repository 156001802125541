import {device} from 'shared/device';
import styled from 'styled-components';

export const IconButton = styled.button<{
  rotate?: number;
  size?: number;
  type?: 'modal';
  variant?: 'unstyled';
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({size}) => size ?? 48}px;
  height: ${({size}) => size ?? 48}px;
  border-radius: 14px;
  pointer-events: all;
  padding: ${({variant}) => (variant === 'unstyled' ? 0 : '1px 6px')};
  cursor: pointer;
  border: ${({variant}) => (variant === 'unstyled' ? 0 : 1)}px solid
    ${({theme}) => theme.borderPrimary};
  background-color: ${({theme, variant}) =>
    variant === 'unstyled' ? 'transparent' : theme.bgPrimary};
  transform: ${({rotate}) => (rotate ? `rotate(${rotate}deg)` : 'none')};
  transition: transform 300ms;
  &:hover {
    background-color: ${({theme, type, variant}) =>
      variant === 'unstyled'
        ? 'transparent'
        : type === 'modal'
        ? theme.fillPrimary
        : theme.bgTertiary} !important;
  }
  @media ${device.mobile} {
    width: ${({size}) => size ?? 40}px;
    height: ${({size}) => size ?? 40}px;
    border-radius: 12px;
  }
`;
