import React from 'react';
import {useColorScheme} from 'shared/lib/hooks';
import {EmptyContainer} from 'shared/ui/EmptyContainer';

export const EmptyWallet = ({size = 400}: {size?: number}) => {
  const colorScheme = useColorScheme();
  return (
    <EmptyContainer size={size}>
      {colorScheme === 'light' ? (
        <svg
          viewBox="0 0 400 400"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M56.7309 90.6012C60.4921 89.3474 62.6862 85.2728 61.4324 81.5115C60.1787 77.7503 56.104 75.5562 52.0293 76.81C48.2681 78.0637 46.0741 82.1384 47.3278 85.8996C48.5815 89.6609 52.6562 91.8549 56.7309 90.6012Z"
            fill="#EAEEF9"
          />
          <path
            d="M72.7164 66.7797C75.2239 65.8394 76.7911 63.0185 75.8508 60.511C74.9105 58.0035 72.0896 56.4363 69.5821 57.3767C67.0746 58.317 65.5074 61.1379 66.4477 63.6454C67.0746 66.1529 69.8955 67.7201 72.7164 66.7797Z"
            fill="#EAEEF9"
          />
          <path
            d="M338.063 123.741C342.765 119.666 343.078 112.457 338.69 108.069C334.615 103.681 327.406 103.367 323.018 107.442C318.317 111.517 318.003 118.726 322.391 123.114C326.466 127.502 333.675 127.815 338.063 123.741Z"
            fill="#EAEEF9"
          />
          <path
            d="M354.988 145.704C356.869 143.823 357.182 141.002 355.302 139.121C353.421 137.241 350.6 136.927 348.72 138.808C346.839 140.689 346.525 143.509 348.406 145.39C349.973 147.584 353.108 147.584 354.988 145.704Z"
            fill="#EAEEF9"
          />
          <path
            d="M69.583 331.634C71.4636 329.753 71.777 326.932 69.8964 325.052C68.0158 323.171 65.1949 322.857 63.3142 324.738C61.4336 326.619 61.1202 329.44 63.0008 331.32C64.8814 333.201 67.7023 333.514 69.583 331.634Z"
            fill="#EAEEF9"
          />
          <path
            d="M188.061 346.992C268.614 346.992 334.123 281.797 334.123 201.558C333.809 121.005 268.614 55.8098 188.061 55.8098C107.195 55.8098 42 121.005 42 201.244C42 281.797 107.195 346.992 188.061 346.992Z"
            fill="#EAEEF9"
          />
          <path
            d="M284.994 126.281V137.545H284.128H273.153L104.774 136.967C101.597 136.967 98.4197 136.389 95.2427 135.523C94.9539 135.523 94.6651 135.234 94.3763 135.234C90.3329 133.79 86.8671 132.057 83.979 129.169C81.6684 126.858 79.9355 123.393 79.9355 120.216C79.9355 114.151 84.8454 109.529 91.777 106.641C94.0875 105.486 96.9756 104.908 99.8638 104.62C101.019 104.62 102.174 104.331 103.33 104.331H263.044C264.777 104.331 266.221 104.62 267.665 104.908C268.821 105.197 269.976 105.486 271.131 106.064C279.507 109.529 284.994 117.039 284.994 126.281Z"
            fill="url(#paint0_linear_17967_3073)"
          />
          <path
            opacity="0.1"
            d="M284.128 137.545H273.153L104.774 136.967C101.597 136.967 98.4203 136.389 95.2433 135.523C94.9545 135.523 94.6657 135.234 94.3768 135.234C90.3334 133.79 86.8676 132.057 83.9795 129.169C86.0012 127.147 88.6005 125.414 91.7775 124.259C94.088 123.104 96.9762 122.526 99.8643 122.237C101.02 121.948 102.175 121.948 103.33 121.948H263.045C264.778 121.948 266.222 122.237 267.666 122.526C268.821 122.815 269.976 123.104 271.132 123.681C277.486 126.281 282.107 131.479 284.128 137.545Z"
            fill="#020305"
          />
          <g filter="url(#filter0_d_17967_3073)">
            <path
              d="M305.5 155.162V296.971C305.5 306.79 297.702 314.588 287.882 314.588H117.77C103.618 314.588 92.6432 307.946 85.7116 302.458C82.5346 299.859 80.5129 295.815 80.5129 291.772C80.5129 289.75 80.5129 287.729 80.5129 285.707C80.5129 282.819 80.5129 279.353 80.5129 275.887C80.5129 259.714 80.5129 238.052 80.2241 216.102C80.2241 212.925 80.2241 209.749 80.2241 206.283C80.2241 194.73 80.2241 183.178 79.9353 172.203C79.9353 169.026 79.9353 165.849 79.9353 162.383C79.9353 140.722 79.6465 124.548 79.6465 120.793C79.6465 127.147 85.7116 132.924 93.7984 135.234C94.0872 135.234 94.3761 135.523 94.6649 135.523C97.553 136.389 101.019 136.967 104.196 136.967L272.575 137.834H284.705H287.593C297.702 137.545 305.5 145.343 305.5 155.162Z"
              fill="url(#paint1_linear_17967_3073)"
            />
          </g>
          <path
            opacity="0.1"
            d="M271.421 203.395C265.356 203.395 260.157 205.705 256.403 209.46C252.648 213.214 250.049 218.702 250.049 224.478C250.049 224.767 250.049 225.344 250.049 225.633C250.626 236.897 260.157 245.85 271.421 245.85H305.501V203.395H271.421Z"
            fill="url(#paint2_linear_17967_3073)"
          />
          <g filter="url(#filter1_d_17967_3073)">
            <path
              d="M305.5 195.307V200.506C305.211 200.506 304.922 200.506 304.345 200.506H269.687C263.622 200.506 258.423 202.817 254.668 206.571C251.203 210.037 248.603 215.236 248.314 220.723C248.314 221.012 248.314 221.59 248.314 221.878C248.314 233.72 257.845 243.251 269.687 243.251H304.345C306.077 243.251 307.522 242.673 308.099 241.807C308.388 241.518 308.677 240.94 308.677 240.651V197.907C308.677 196.463 307.233 195.596 305.5 195.307Z"
              fill="url(#paint3_linear_17967_3073)"
            />
          </g>
          <path
            d="M267.955 234.587C274.887 234.587 280.663 228.81 280.663 221.879C280.663 214.947 274.887 209.171 267.955 209.171C261.023 209.171 255.247 214.947 255.247 221.879C255.247 228.81 261.023 234.587 267.955 234.587Z"
            fill="#DADFEC"
          />
          <path
            d="M157.104 233.404C161.876 233.404 165.914 229.366 165.914 224.594C165.914 219.822 161.876 215.784 157.104 215.784C152.332 215.784 148.294 219.822 148.294 224.594C148.294 229.366 152.332 233.404 157.104 233.404Z"
            fill="#989FB0"
          />
          <path
            d="M224.648 233.404C229.42 233.404 233.458 229.366 233.458 224.594C233.458 219.822 229.42 215.784 224.648 215.784C219.876 215.784 215.838 219.822 215.838 224.594C215.838 229.733 219.876 233.404 224.648 233.404Z"
            fill="#989FB0"
          />
          <path
            d="M156.76 195.447L137.38 205.911L139.996 210.756L159.376 200.292L156.76 195.447Z"
            fill="#989FB0"
          />
          <path
            d="M223.447 195.308L220.832 200.153L240.214 210.615L242.829 205.769L223.447 195.308Z"
            fill="#989FB0"
          />
          <path
            d="M190.874 253.96C196.55 253.96 201.152 250.509 201.152 246.252C201.152 241.994 196.55 238.543 190.874 238.543C185.197 238.543 180.596 241.994 180.596 246.252C180.596 250.509 185.197 253.96 190.874 253.96Z"
            fill="#989FB0"
          />
          <path
            d="M342.16 32.5429C313.495 26.2567 282.818 27.2625 254.655 35.5603C248.872 37.3205 242.586 39.5835 238.06 43.6067C231.019 49.3901 228.002 59.6995 230.265 68.5003C232.528 77.301 240.071 84.5931 249.375 86.3532C255.158 87.8619 262.199 85.8503 265.719 81.0728C268.988 76.0438 268.234 68.5003 264.211 63.9742C260.187 58.9452 253.65 56.9336 247.615 57.185C241.58 57.4365 235.545 59.6995 230.265 62.4655C213.166 71.7691 199.336 88.1134 193.553 107.224C190.621 116.507 189.589 126.266 190.34 135.865"
            stroke="#C9D4E2"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeDasharray="4 4"
          />
          <path
            d="M357.247 26.005C356.996 29.7768 356.745 33.2971 353.979 34.0514C351.213 34.8058 349.955 32.2913 348.195 28.771C346.435 25.5021 347.441 21.9818 350.458 21.2275C353.224 20.4731 357.75 21.4789 357.247 26.005Z"
            fill="#DAE2EB"
          />
          <path
            d="M354.232 44.1098C354.987 39.5837 355.741 37.0692 353.226 35.812C350.46 34.5547 348.7 36.8178 345.683 39.8352C343.168 42.6011 344.677 46.6243 347.192 47.8816C350.209 49.3903 353.478 47.8816 354.232 44.1098Z"
            fill="#DAE2EB"
          />
          <path
            d="M357.752 35.8122C357.501 37.5723 356.243 38.8296 354.483 39.081C353.729 39.081 352.974 39.081 351.969 39.081C348.448 38.5781 345.682 36.3151 345.934 34.052C346.185 31.789 349.454 30.5317 353.477 31.0346C354.232 31.0346 354.986 31.2861 355.489 31.5375C356.998 32.0404 358.003 33.8006 357.752 35.8122C357.752 35.8122 357.752 35.5607 357.752 35.8122Z"
            fill="#989FB0"
          />
          <path
            d="M80.9414 73.6524C80.9414 69.3778 80.9414 65.1031 83.9588 63.8459C87.2277 62.5886 88.9878 65.606 91.5023 69.8807C93.7654 73.6524 92.7596 77.6756 89.4907 78.9329C86.7248 80.1901 80.9414 79.4358 80.9414 73.6524Z"
            fill="#DAE2EB"
          />
          <path
            d="M82.7018 52.5311C82.1989 57.8116 81.4445 60.829 84.7134 62.0862C87.9822 63.3435 89.7424 60.5775 93.0112 56.5543C95.5257 53.034 93.7656 48.5079 90.4967 47.2507C87.2279 45.9934 83.2047 48.2565 82.7018 52.5311Z"
            fill="#DAE2EB"
          />
          <path
            d="M79.9355 62.5888C79.9355 60.5772 81.4442 59.0685 83.2044 58.817C83.9587 58.5656 84.9645 58.5656 85.9703 58.817C89.9936 59.0685 93.5139 61.3315 93.2624 63.846C93.011 66.3605 89.7421 68.1207 85.4674 67.6178C84.7131 67.6178 83.9587 67.3663 83.2044 67.1149C81.1928 66.8634 79.9355 64.8518 79.9355 62.5888Z"
            fill="#989FB0"
          />
          <path
            d="M97.0342 63.3435C123.436 63.3435 172.218 78.682 172.972 135.007"
            stroke="#C9D4E2"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeDasharray="4 4"
          />
          <defs>
            <filter
              id="filter0_d_17967_3073"
              x="57.6465"
              y="109.793"
              width="269.854"
              height="237.795"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_17967_3073"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_17967_3073"
                result="shape"
              />
            </filter>
            <filter
              id="filter1_d_17967_3073"
              x="226.314"
              y="184.307"
              width="104.362"
              height="91.9434"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_17967_3073"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_17967_3073"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_17967_3073"
              x1="182.481"
              y1="105.485"
              x2="182.481"
              y2="124.127"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#B0BACC" />
              <stop offset="1" stopColor="#969EAE" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_17967_3073"
              x1="192.5"
              y1="116.311"
              x2="192.5"
              y2="316.678"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#FDFEFF" />
              <stop offset="0.9964" stopColor="#ECF0F5" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_17967_3073"
              x1="277.779"
              y1="204.869"
              x2="277.779"
              y2="228.699"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#B0BACC" />
              <stop offset="1" stopColor="#969EAE" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_17967_3073"
              x1="278.476"
              y1="194.198"
              x2="278.476"
              y2="243.768"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#FDFEFF" />
              <stop offset="0.9964" stopColor="#ECF0F5" />
            </linearGradient>
          </defs>
        </svg>
      ) : (
        <svg
          viewBox="0 0 400 400"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M56.7301 90.6008C60.4915 89.347 62.6856 85.2722 61.4318 81.5109C60.178 77.7496 56.1033 75.5555 52.0285 76.8093C48.2672 78.063 46.0731 82.1378 47.3268 85.8991C48.5806 89.6605 52.6554 91.8546 56.7301 90.6008Z"
            fill="#2A2E37"
          />
          <path
            d="M72.7166 66.7797C75.2241 65.8394 76.7914 63.0184 75.851 60.5109C74.9107 58.0033 72.0897 56.4361 69.5821 57.3764C67.0746 58.3167 65.5074 61.1377 66.4477 63.6453C67.0746 66.1528 69.8956 67.7201 72.7166 66.7797Z"
            fill="#2A2E37"
          />
          <path
            d="M338.064 123.741C342.765 119.667 343.079 112.457 338.691 108.069C334.616 103.681 327.407 103.367 323.018 107.442C318.317 111.517 318.003 118.726 322.391 123.114C326.466 127.503 333.675 127.816 338.064 123.741Z"
            fill="#2A2E37"
          />
          <path
            d="M354.988 145.703C356.869 143.823 357.183 141.002 355.302 139.121C353.421 137.24 350.6 136.927 348.72 138.807C346.839 140.688 346.525 143.509 348.406 145.39C349.973 147.584 353.108 147.584 354.988 145.703Z"
            fill="#2A2E37"
          />
          <path
            d="M69.5831 331.633C71.4638 329.752 71.7773 326.931 69.8966 325.051C68.0159 323.17 65.1949 322.856 63.3143 324.737C61.4336 326.618 61.1202 329.439 63.0008 331.319C64.8815 333.2 67.7025 333.514 69.5831 331.633Z"
            fill="#2A2E37"
          />
          <path
            d="M188.061 346.991C268.614 346.991 334.122 281.797 334.122 201.557C333.808 121.004 268.614 55.8096 188.061 55.8096C107.195 55.8096 42 121.004 42 201.244C42 281.797 107.195 346.991 188.061 346.991Z"
            fill="#2A2E37"
          />
          <path
            d="M284.993 126.281V137.545H284.126H273.151L104.773 136.967C101.596 136.967 98.4187 136.389 95.2417 135.523C94.9529 135.523 94.6641 135.234 94.3753 135.234C90.3319 133.79 86.8661 132.057 83.978 129.169C81.6675 126.859 79.9346 123.393 79.9346 120.216C79.9346 114.151 84.8444 109.53 91.776 106.642C94.0865 105.486 96.9746 104.909 99.8628 104.62C101.018 104.62 102.173 104.331 103.329 104.331H263.043C264.776 104.331 266.22 104.62 267.664 104.909C268.819 105.197 269.974 105.486 271.13 106.064C279.505 109.53 284.993 117.039 284.993 126.281Z"
            fill="#414758"
          />
          <g filter="url(#filter0_d_17967_3092)">
            <path
              d="M305.499 155.161V296.969C305.499 306.789 297.701 314.587 287.882 314.587H117.77C103.618 314.587 92.6431 307.944 85.7116 302.457C82.5346 299.857 80.5129 295.814 80.5129 291.771C80.5129 289.749 80.5129 287.727 80.5129 285.705C80.5129 282.817 80.5129 279.352 80.5129 275.886C80.5129 259.712 80.5129 238.051 80.2241 216.101C80.2241 212.924 80.2241 209.747 80.2241 206.282C80.2241 194.729 80.2241 183.176 79.9353 172.201C79.9353 169.024 79.9353 165.848 79.9353 162.382C79.9353 140.721 79.6465 124.547 79.6465 120.792C79.6465 127.146 85.7116 132.923 93.7984 135.233C94.0872 135.233 94.376 135.522 94.6648 135.522C97.553 136.388 101.019 136.966 104.196 136.966L272.574 137.833H284.705H287.593C297.701 137.544 305.499 145.342 305.499 155.161Z"
              fill="url(#paint0_linear_17967_3092)"
            />
          </g>
          <path
            opacity="0.1"
            d="M271.419 203.394C265.354 203.394 260.155 205.705 256.401 209.459C252.646 213.214 250.047 218.701 250.047 224.477C250.047 224.766 250.047 225.344 250.047 225.633C250.625 236.897 260.155 245.85 271.419 245.85H305.499V203.394H271.419Z"
            fill="url(#paint1_linear_17967_3092)"
          />
          <path
            d="M305.499 195.308V200.507C305.21 200.507 304.921 200.507 304.343 200.507H269.686C263.621 200.507 258.422 202.817 254.667 206.572C251.202 210.038 248.602 215.236 248.313 220.724C248.313 221.013 248.313 221.59 248.313 221.879C248.313 233.72 257.844 243.251 269.686 243.251H304.343C306.076 243.251 307.52 242.674 308.098 241.807C308.387 241.518 308.676 240.941 308.676 240.652V197.907C308.676 196.463 307.232 195.597 305.499 195.308Z"
            fill="#414758"
          />
          <path
            d="M267.954 234.586C274.886 234.586 280.662 228.81 280.662 221.878C280.662 214.946 274.886 209.17 267.954 209.17C261.023 209.17 255.246 214.946 255.246 221.878C255.246 228.81 261.023 234.586 267.954 234.586Z"
            fill="#5C6479"
          />
          <path
            d="M157.105 233.403C161.877 233.403 165.915 229.366 165.915 224.593C165.915 219.821 161.877 215.783 157.105 215.783C152.333 215.783 148.295 219.821 148.295 224.593C148.295 229.366 152.333 233.403 157.105 233.403Z"
            fill="#A9AFC3"
          />
          <path
            d="M224.648 233.403C229.42 233.403 233.458 229.366 233.458 224.593C233.458 219.821 229.42 215.783 224.648 215.783C219.876 215.783 215.838 219.821 215.838 224.593C215.838 229.733 219.876 233.403 224.648 233.403Z"
            fill="#A9AFC3"
          />
          <path
            d="M156.761 195.447L137.381 205.911L139.997 210.756L159.377 200.292L156.761 195.447Z"
            fill="#989FB0"
          />
          <path
            d="M223.45 195.308L220.834 200.154L240.216 210.616L242.832 205.77L223.45 195.308Z"
            fill="#989FB0"
          />
          <path
            d="M190.875 253.961C196.552 253.961 201.154 250.51 201.154 246.252C201.154 241.995 196.552 238.543 190.875 238.543C185.199 238.543 180.597 241.995 180.597 246.252C180.597 250.51 185.199 253.961 190.875 253.961Z"
            fill="#A9AFC3"
          />
          <path
            d="M341.7 31.9765C313.034 25.6902 282.358 26.696 254.195 34.9939C248.412 36.754 242.126 39.0171 237.6 43.0403C230.559 48.8236 227.542 59.133 229.805 67.9337C232.068 76.7345 239.611 84.0265 248.915 85.7867C254.698 87.2954 261.739 85.2838 265.259 80.5062C268.528 75.4772 267.774 67.9338 263.75 63.4077C259.727 58.3787 253.19 56.3671 247.155 56.6185C241.12 56.87 235.085 59.133 229.805 61.899C212.706 71.2026 198.876 87.5468 193.093 106.657C190.161 115.941 189.129 125.699 189.88 135.298"
            stroke="#667089"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeDasharray="4 4"
          />
          <path
            d="M357.248 26.0051C356.996 29.777 356.745 33.2973 353.979 34.0517C351.213 34.8061 349.955 32.2915 348.195 28.7711C346.435 25.5022 347.441 21.9818 350.458 21.2275C353.224 20.4731 357.751 21.4789 357.248 26.0051Z"
            fill="#AFB7C9"
          />
          <path
            d="M354.232 44.11C354.987 39.5838 355.741 37.0693 353.227 35.812C350.461 34.5547 348.7 36.8178 345.683 39.8353C343.168 42.6013 344.677 46.6246 347.192 47.8819C350.209 49.3906 353.478 47.8819 354.232 44.11Z"
            fill="#AFB7C9"
          />
          <path
            d="M357.752 35.8116C357.501 37.5718 356.244 38.829 354.483 39.0805C353.729 39.0805 352.975 39.0805 351.969 39.0805C348.448 38.5776 345.682 36.3145 345.934 34.0514C346.185 31.7883 349.454 30.531 353.478 31.0339C354.232 31.0339 354.986 31.2854 355.489 31.5368C356.998 32.0397 358.004 33.7999 357.752 35.8116C357.752 35.8116 357.752 35.5601 357.752 35.8116Z"
            fill="#474E61"
          />
          <path
            d="M80.9414 73.6522C80.9414 69.3774 80.9414 65.1027 83.9589 63.8454C87.2278 62.5881 88.988 65.6056 91.5026 69.8803C93.7657 73.6522 92.7598 77.6755 89.4909 78.9327C86.7249 80.19 80.9414 79.4357 80.9414 73.6522Z"
            fill="#AFB7C9"
          />
          <path
            d="M82.7018 52.5313C82.1989 57.8118 81.4445 60.8293 84.7134 62.0866C87.9824 63.3439 89.7426 60.5779 93.0115 56.5546C95.526 53.0342 93.7659 48.508 90.4969 47.2507C87.228 45.9934 83.2047 48.2565 82.7018 52.5313Z"
            fill="#AFB7C9"
          />
          <path
            d="M79.9355 62.5889C79.9355 60.5772 81.4443 59.0685 83.2045 58.817C83.9588 58.5656 84.9647 58.5656 85.9705 58.817C89.9938 59.0685 93.5142 61.3316 93.2627 63.8461C93.0113 66.3607 89.7423 68.1209 85.4676 67.618C84.7132 67.618 83.9588 67.3665 83.2045 67.1151C81.1928 66.8636 79.9355 64.852 79.9355 62.5889Z"
            fill="#474E61"
          />
          <path
            d="M96.5723 62.7764C122.974 62.7764 171.756 78.1148 172.51 134.439"
            stroke="#667089"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeDasharray="4 4"
          />
          <defs>
            <filter
              id="filter0_d_17967_3092"
              x="57.6465"
              y="109.792"
              width="269.853"
              height="237.794"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_17967_3092"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_17967_3092"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_17967_3092"
              x1="192.499"
              y1="116.31"
              x2="192.499"
              y2="316.676"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#5C6479" />
              <stop offset="0.9964" stopColor="#5C6479" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_17967_3092"
              x1="277.777"
              y1="204.869"
              x2="277.777"
              y2="228.699"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#B0BACC" />
              <stop offset="1" stopColor="#969EAE" />
            </linearGradient>
          </defs>
        </svg>
      )}
    </EmptyContainer>
  );
};
