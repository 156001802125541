import {ApolloError, useLazyQuery, useMutation} from '@apollo/client';
import {
  BRANDS,
  DELETE_MANY_PRODUCTS_BRANDS,
  IBrandEditModalProps,
} from 'entities/products';
import React, {useEffect, useState} from 'react';
import {Plus} from 'shared/icons/Plus';
import {useColors, useDebounce, useEmployeePermission} from 'shared/lib/hooks';
import {useAppSelector} from 'shared/store';
import {Button} from 'shared/ui/Button';
import {Content} from 'shared/ui/Content';
import {Flex} from 'shared/ui/Flex';
import {OptionsType, Table} from 'shared/ui/Table';
import {TextInput} from 'shared/ui/TextInput';
import {FooterAction} from 'shared/ui/FooterAction';
import {SkeletonBrands} from './Skeleton';
import {Slide} from 'shared/ui/Slide';
import {showAlert} from 'shared/ui/Alert';
import {Text} from 'shared/ui/Text';
import {Search} from 'shared/icons/Search';
import {EmptyAddCategoryBlock} from 'shared/illustration/EmptyAddCategoryBlock';

export const Brands = ({
  visible,
  onOpenModal,
}: {
  visible: boolean;
  onOpenModal: (value?: IBrandEditModalProps) => void;
}) => {
  const colors = useColors();
  const [search, setSearch] = useState('');
  const companyId = useAppSelector(state => state.company.data?.id);
  const [options, setOptions] = useState<OptionsType>({
    page: 1,
    first: 40,
    count: 0,
    total: 0,
  });
  const [selections, setSelections] = useState<number[]>([]);
  const [getBrands, {data, loading, refetch}] = useLazyQuery(BRANDS, {
    onCompleted: res => {
      setOptions({
        ...options,
        total: res.productsBrands?.paginatorInfo.total ?? 0,
        count: res.productsBrands?.paginatorInfo.count ?? 0,
      });
    },
  });
  const brands = data?.productsBrands?.data ?? [];
  const {edit_products_permission} = useEmployeePermission(data);
  const [deleteManyBrands, {loading: deleteLoading}] = useMutation(
    DELETE_MANY_PRODUCTS_BRANDS,
    {
      refetchQueries: [
        {
          query: BRANDS,
          variables: {
            companyId: companyId!,
            page: 1,
            first: options.first,
          },
        },
      ],
    },
  );

  const searchDebounce = useDebounce((query: string) => {
    refetch({
      companyId: companyId!,
      page: 1,
      first: options.first,
      name: query,
    });
  }, 400);

  useEffect(() => {
    if (visible) {
      getBrands({
        variables: {
          companyId: companyId!,
          page: options.page,
          first: options.first,
        },
      });
    }
  }, [companyId, getBrands, options.first, options.page, search, visible]);

  return (
    <Slide style={{height: '100%', position: 'relative'}}>
      <Flex direction="column" flex={1}>
        <Flex
          direction="column"
          gap={brands.length > 0 ? 24 : 32}
          flex={1}
          style={{padding: '0 24px 24px 24px'}}>
          <Flex justifyContent="space-between" alignItems="center">
            <TextInput
              value={search}
              borderRadius={14}
              onChange={value => {
                setSearch(value);
                searchDebounce(value);
              }}
              leftElement={<Search color={colors.textTertiary} />}
              placeholder="Поиск"
              variant="default"
              width="352px"
              height={54}
              backgroundColor={colors.bgPrimary}
            />
            {edit_products_permission && (
              <Button onClick={() => onOpenModal()}>
                <Plus color={colors.white} />
                Создать бренд
              </Button>
            )}
          </Flex>
          {loading ? (
            <SkeletonBrands />
          ) : brands.length > 0 ? (
            <Content>
              <Table
                titles={['Название', 'Количество товаров']}
                rows={brands.map(item => [item.name, item.products?.length])}
                options={options}
                setOptions={setOptions}
                onClick={index => {
                  if (edit_products_permission) {
                    onOpenModal({
                      id: brands[index].id,
                      name: brands[index].name,
                    });
                  } else {
                    showAlert(
                      'warning',
                      'У вас нет прав доступа на создание/редактирование/удаление брендов',
                    );
                  }
                }}
                selections={selections}
                onSelectionChange={
                  edit_products_permission ? setSelections : undefined
                }
              />
            </Content>
          ) : (
            <EmptyState />
          )}
        </Flex>
        {selections.length > 0 && (
          <FooterAction
            popupStyle={{
              left: 'calc(300% / 2 + 100%)',
              top: 'calc((100% - 220px) / 2)',
            }}
            title="Удалить бренды"
            text="Вы уверены, что хотите удалить выбранные бренды?"
            onDelete={async () => {
              try {
                await deleteManyBrands({
                  variables: {
                    deleteManyProductsBrandId: brands
                      .filter((_, index) => selections.includes(index))
                      .map(item => item.id),
                  },
                });
                setOptions({
                  ...options,
                  page: 1,
                });
                setSelections([]);
              } catch (e) {
                if (e instanceof ApolloError) {
                  showAlert('error', e.message);
                }
              }
            }}
            deleteLoading={deleteLoading}
            editPermission
            disabled={selections.length === 0}
            selectedCount={selections.length}
            allICount={options.count}
          />
        )}
      </Flex>
    </Slide>
  );
};

const EmptyState = () => {
  return (
    <Flex
      direction="column"
      alignItems="center"
      justifyContent="center"
      gap={16}>
      <EmptyAddCategoryBlock size={300} />
      <Text typography="title20">Нет брендов</Text>
      <Text
        typography="text18"
        color="textTertiary"
        style={{textAlign: 'center'}}>
        Нажмите на кнопку “Создать бренд” и добавьте <br /> информацию
      </Text>
    </Flex>
  );
};
