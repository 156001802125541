import React from 'react';
import styled from 'styled-components';

const IconWrapper = styled.div`
  height: 40px;
  width: 40px;
  background-color: ${props => props.theme.bgPrimary};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 14px;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.borderPrimary};
  cursor: pointer;
`;

export function ActionIcon({
  icon,
  onClick,
  onMouseDown,
  style,
  ...rest
}: {
  icon: React.JSX.Element;
  onMouseDown?: React.MouseEventHandler<HTMLDivElement>;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  style?: React.CSSProperties;
}) {
  return (
    <IconWrapper
      onMouseDown={onMouseDown}
      onClick={onClick}
      style={style}
      {...rest}>
      {icon}
    </IconWrapper>
  );
}
