import {useMutation, useQuery} from '@apollo/client';
import dayjs from 'dayjs';
import {
  BOOKING,
  BOOKINGS,
  Comment,
  SelectBookingSource,
  SelectCustomer,
  SelectDate,
  SelectEmployee,
  SelectServices,
  Status,
  UPDATE_COMPANY_BOOKING,
} from 'entities/booking';
import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {CompanyBookingStatus} from 'shared/__generated__/graphql';
import {useEmployeePermission} from 'shared/lib/hooks';
import {
  setBookingScreen,
  setEditBookingEmployee,
  setEditBookingServices,
  setEditBookingWorkingDate,
  useAppDispatch,
  useAppSelector,
} from 'shared/store';
import {showAlert} from 'shared/ui/Alert';
import {Button} from 'shared/ui/Button';
import {Content} from 'shared/ui/Content';
import {Flex} from 'shared/ui/Flex';
import {Head} from 'shared/ui/Head';
import {Layout} from 'shared/ui/Layout';
import {NavigateBack} from 'shared/ui/NavigateBack';
import {Seo} from 'shared/ui/Seo';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';

const Container = styled.div`
  gap: 24px;
  display: flex;
  flex-direction: column;
  padding: 64px 0 24px 0;
  grid-column-start: 4;
  grid-column-end: 10;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const EditBooking = () => {
  const params = useParams();
  const navigate = useNavigate();
  const {id} = params;
  const company_id = useAppSelector(state => state.company.data!.id);
  const [comment, setComment] = useState('');
  const [status, setStatus] = useState(CompanyBookingStatus.Pending);
  const [selectSource, setSelectSource] = useState<string | null>(null);
  const {data} = useQuery(BOOKING, {
    variables: {
      bookingId: id!,
      companyId: company_id,
    },
    onCompleted: dataRes => {
      setComment(dataRes.booking?.comment ?? '');
      setStatus(dataRes.booking?.status as CompanyBookingStatus);
      setSelectSource(dataRes.booking?.source?.id ?? null);
      dispatch(setBookingScreen('edit'));
      dispatch(setEditBookingEmployee(dataRes.booking?.employee.id));
      dispatch(
        setEditBookingWorkingDate(
          dataRes.booking?.workingDate.date +
            ' ' +
            dataRes.booking?.start +
            ',' +
            dataRes.booking?.workingDate.id,
        ),
      );
      dispatch(
        setEditBookingServices(
          dataRes.booking?.bookingServices.map(service => ({
            price: service.price,
            additional_price: service.additional_price,
            service_id: service.service.id,
            discount_id: service.discount?.id,
          })) ?? [],
        ),
      );
    },
  });
  const {customers_info_permission, create_customers_permission} =
    useEmployeePermission(data);
  const dispatch = useAppDispatch();
  const {employeeId, customerId, services, duration, workingDate} =
    useAppSelector(state => state.booking.edit);
  const dateParam = workingDate ? workingDate.split(',')[0] : undefined;
  const date = dateParam
    ? dayjs(dateParam).format('YYYY-MM-DD HH:mm')
    : undefined;
  const dateBookings = useAppSelector(state => state.booking.date);
  const [updateCompanyBooking, {loading}] = useMutation(
    UPDATE_COMPANY_BOOKING,
    {
      refetchQueries: [
        {
          query: BOOKING,
          variables: {
            bookingId: id,
            companyId: company_id,
          },
        },
        {
          query: BOOKINGS,
          variables: {
            companyId: company_id,
            date: [dateBookings],
            companyBookingsDate2: dateBookings,
            first: 100,
          },
        },
      ],
    },
  );

  useEffect(() => {}, [customerId]);

  return (
    <Layout columns={12} style={{overflowY: 'scroll'}}>
      <Seo title="Редактирование записи" />
      <Container>
        <Head style={{marginBottom: 8}}>
          <NavigateBack />
          <Text typography="title24">Редактирование записи</Text>
        </Head>
        <Status value={status} onChange={value => setStatus(value)} />
        <Content style={{gap: 32}}>
          <SelectEmployee
            services={data?.booking?.bookingServices.map(
              service => service.service.id,
            )}
            value={employeeId}
            workingDate={data?.booking?.workingDate.date}
            start={data?.booking?.start}
            booking={false}
            onChange={value => dispatch(setEditBookingEmployee(value))}
          />
          <SelectDate value={date} />
          <SelectCustomer
            value={customerId ?? undefined}
            comment={data?.booking?.customer_comment}
            permission={customers_info_permission}
            createPermission={create_customers_permission}
          />
          <SelectServices />
          <SelectBookingSource
            value={selectSource}
            onChange={setSelectSource}
          />
          <Comment
            value={comment}
            onChange={value => setComment(value)}
            id={id}
            author={data?.booking?.author}
            created_at={data?.booking?.created_at}
          />
        </Content>
        <Flex direction="row" gap={8} flex={1}>
          <Button
            size="large"
            style={{flex: 1}}
            loading={loading}
            onClick={() => {
              updateCompanyBooking({
                variables: {
                  input: {
                    booking_id: id!,
                    booking_services: services.map(service => ({
                      additional_price: +(service.additional_price ?? ''),
                      price: +(service.price ?? ''),
                      discount_id: service.discount_id,
                      discount_value: null,
                      service_id: service.service_id,
                    })),
                    comment: comment,
                    company_id: company_id!,
                    customer_id: customerId,
                    start: date?.split(' ')[1],
                    status: status,
                    working_date_id: workingDate?.split(',')[1],
                    duration: duration !== 0 ? duration : undefined,
                    source_id: selectSource ?? undefined,
                  },
                },
              })
                .then(() => {
                  navigate(-1);
                })
                .catch(e => {
                  showAlert('error', e.message);
                });
            }}>
            Сохранить изменения
          </Button>
        </Flex>
      </Container>
    </Layout>
  );
};
