import React from 'react';
import {useResize} from 'shared/lib/hooks';
import {Button} from 'shared/ui/Button';
import {Chip} from 'shared/ui/Chip';
import {Flex} from 'shared/ui/Flex';
import styled from 'styled-components';

const periodData = [
  {label: 'За все время', value: 99},
  {label: 'Сегодня', value: 0},
  {label: 'Вчера', value: 1},
  {label: 'Последние 7 дней', value: 7},
  {label: 'Последние 30 дней', value: 30},
  {label: 'Этот месяц', value: 999},
];

const typeData = [
  {label: 'Все отзывы', value: undefined},
  {label: 'Отзывы с ответом', value: 'with'},
  {label: 'Отзывы без ответа', value: 'without'},
];

const recipientData = [
  {label: 'Общие отзывы', value: undefined},
  {label: 'Отзывы сотрудникам', value: 'employees'},
  {label: 'Отзывы организации', value: 'company'},
];

const ratingData = [
  {label: 'Все оценки', value: undefined},
  {label: 'Положительные', value: 'good'},
  {label: 'Негативные', value: 'bad'},
];

const Wrapper = styled(Flex)`
  margin-top: 24px;
  margin-left: 24px;
  gap: 16px;
`;

type FilterType = {
  reply?: 'with' | 'without';
  target?: 'company' | 'employees';
  ratings?: 'good' | 'bad';
  period?: number;
  orderBy?: string;
};

export const FilterChip = ({
  onChange,
  filters,
}: {
  onChange?: (value: FilterType) => void;
  filters?: FilterType;
}) => {
  const isMobile = useResize().isMobile;
  const isActive =
    filters?.period !== undefined ||
    !!filters?.ratings ||
    !!filters?.reply ||
    !!filters?.target;

  const getPeriodLabel =
    periodData.find(item => item.value === filters?.period)?.label ?? '';
  const getReplyLabel =
    typeData.find(item => item.value === filters?.reply)?.label ?? '';
  const getTargetLabel =
    recipientData.find(item => item.value === filters?.target)?.label ?? '';
  const getRatingLabel =
    ratingData.find(item => item.value === filters?.ratings)?.label ?? '';

  return (
    <>
      {isMobile ? (
        <></>
      ) : (
        <Wrapper style={{display: isActive ? 'flex' : 'none'}}>
          <Chip
            text={getPeriodLabel}
            style={{
              height: 32,
              display: filters?.period === undefined ? 'none' : 'flex',
            }}
            onClick={() => {
              onChange && onChange({...filters, period: undefined});
            }}
          />
          <Chip
            text={getRatingLabel}
            style={{
              height: 32,
              display: filters?.ratings === undefined ? 'none' : 'flex',
              pointerEvents: 'all',
            }}
            onClick={() => {
              onChange && onChange({...filters, ratings: undefined});
            }}
          />
          <Chip
            text={getTargetLabel}
            style={{
              height: 32,
              display: filters?.target === undefined ? 'none' : 'flex',
              pointerEvents: 'all',
            }}
            onClick={() => {
              onChange && onChange({...filters, target: undefined});
            }}
          />
          <Chip
            text={getReplyLabel}
            style={{
              height: 32,
              display: filters?.reply === undefined ? 'none' : 'flex',
              pointerEvents: 'all',
            }}
            onClick={() => {
              onChange && onChange({...filters, reply: undefined});
            }}
          />
          <Button
            variant="light"
            style={{width: 82, height: 32}}
            typography="footNote12Medium"
            onClick={() =>
              onChange &&
              onChange({
                reply: undefined,
                target: undefined,
                ratings: undefined,
                period: undefined,
              })
            }>
            Сбросить
          </Button>
        </Wrapper>
      )}
    </>
  );
};
