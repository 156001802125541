import {useMutation} from '@apollo/client';
import {GET_BONUSES} from 'entities/loyalty';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {gql} from 'shared/__generated__';
import {useColors} from 'shared/lib/hooks';
import {useAppSelector} from 'shared/store';
import {showAlert} from 'shared/ui/Alert';
import {Button} from 'shared/ui/Button';
import {CloseButton} from 'shared/ui/CloseButton';
import {Flex} from 'shared/ui/Flex';
import {Popup} from 'shared/ui/Popup';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';

const DELETE_BONUS = gql(`
mutation DeleteBonusCardTemplate($id: String!) {
    deleteBonusCardTemplate(id: $id) {
      id
    }
  }
`);

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;

export function DeleteBonus({
  visible,
  onClose,
  id,
}: {
  visible?: boolean;
  onClose?: () => void;
  id: string;
}) {
  const colors = useColors();
  const companyId = useAppSelector(state => state.company.data?.id);
  const [deleteBonus, {loading}] = useMutation(DELETE_BONUS, {
    refetchQueries: [
      {
        query: GET_BONUSES,
        variables: {
          first: 40,
          page: 1,
          companyId,
        },
      },
    ],
  });
  const navigate = useNavigate();
  if (!visible) {
    return null;
  }
  return (
    <Popup style={{width: '472px'}} visible={visible} onClose={onClose}>
      <Wrapper>
        <Flex
          direction="row"
          alignItems="center"
          flex={1}
          justifyContent="space-between">
          <Text typography="title24">Удалить бонусную карту</Text>
          <CloseButton
            onClose={() => {
              onClose && onClose();
            }}
          />
        </Flex>
        <Text typography="text16Regular">
          Вы точно хотите удалить бонусную карту?
        </Text>
        <Flex direction="row" gap={8}>
          <Button
            size="large"
            variant="outline"
            typography="text16Semibold"
            loading={loading}
            style={{
              flex: 1,
              borderColor: colors.mainPrimary,
            }}
            onClick={() => {
              onClose && onClose();
            }}>
            Отмена
          </Button>
          <Button
            size="large"
            variant="outline"
            typography="text16Semibold"
            style={{
              flex: 1,
              color: colors.dangerPrimary,
              borderColor: colors.dangerPrimary,
            }}
            disabled={loading}
            onClick={() => {
              deleteBonus({
                variables: {
                  id,
                },
              })
                .then(() => {
                  onClose && onClose();
                  navigate(-1);
                })
                .catch(e => {
                  showAlert('error', e.message);
                });
            }}>
            Удалить
          </Button>
        </Flex>
      </Wrapper>
    </Popup>
  );
}
