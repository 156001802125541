import React from 'react';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';

const ScaleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: ${props => props.theme.mainLight};
  border-radius: 16px;
  max-width: 344px;
  height: 124px;
`;
const ProgressBarContainer = styled.div`
  flex-direction: row;
  margin-top: 16px;
  background-color: ${props => props.theme.bgPrimary};
  border-radius: 16px;
  height: 24px;
`;
const ProgressBar = styled.div<{width: number}>`
  border-radius: 16px;
  height: 24px;
  justify-content: center;
  align-items: center;
  width: ${props => `${props.width}%`};
  background-color: ${props => props.theme.mainPrimary};
`;

export const TutoriaScale = ({
  result,
  totalChecks,
  completePercent,
}: {
  result?: number;
  totalChecks?: number;
  completePercent: number;
}) => {
  return (
    <ScaleWrapper>
      <Text typography="title18" color="textPrimary">
        {completePercent === 100 ? 'Отлично!' : 'Завершенность настройки'}
      </Text>
      <Text typography="text16Regular" color="textSecondary">
        {completePercent === 100
          ? 'Вы полностью заполнили все данные!'
          : `${result} из ${totalChecks} выполненных задач`}
      </Text>
      <ProgressBarContainer>
        <ProgressBar width={completePercent}>
          {completePercent === 0 ? (
            <Text
              style={{marginLeft: 8}}
              typography="text16Regular"
              color="mainPrimary">
              {completePercent}%
            </Text>
          ) : (
            <Text
              style={{textAlign: 'end', marginRight: 8}}
              typography="text16Regular"
              color="white">
              {completePercent}%
            </Text>
          )}
        </ProgressBar>
      </ProgressBarContainer>
    </ScaleWrapper>
  );
};
