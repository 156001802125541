import React from 'react';

export const Pencil = ({
  size = 20,
  color = '#6765F8',
}: {
  size?: number;
  color?: string;
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.905 3.219a2.465 2.465 0 013.478-.002l2.23 2.22c.952.95.962 2.485.022 3.446l-8.26 8.442a3.358 3.358 0 01-2.401 1.008H4.512a2.008 2.008 0 01-2.01-2.09l.106-2.503c.035-.837.384-1.63.978-2.222l8.32-8.3zm2.53.944a1.12 1.12 0 00-1.58 0l-8.32 8.3a2.003 2.003 0 00-.587 1.333L3.842 16.3a.67.67 0 00.67.697h2.462c.542 0 1.062-.218 1.44-.605l8.26-8.441a1.112 1.112 0 00-.01-1.566l-2.229-2.22z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.397 10.607a.672.672 0 01-.949 0l-4.428-4.41a.667.667 0 010-.945.672.672 0 01.948 0l4.428 4.41a.667.667 0 010 .945z"
        fill={color}
      />
    </svg>
  );
};
