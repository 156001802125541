import React from 'react';
import {useColorScheme} from 'shared/lib/hooks';
import {EmptyContainer} from 'shared/ui/EmptyContainer';

export const EmptyFileNotFound = ({
  size = 400,
  mt = 58,
}: {
  size?: number;
  mt?: number;
}) => {
  const colorScheme = useColorScheme();
  return (
    <EmptyContainer size={size} mt={mt}>
      {colorScheme === 'light' ? (
        <svg
          viewBox="0 0 400 400"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M203.279 332C273.478 332 330.389 275.089 330.389 204.64C330.389 134.191 273.227 77.2795 203.279 77.2795C133.081 77.2795 76.1699 134.191 76.1699 204.64C76.1699 275.089 133.081 332 203.279 332Z"
            fill="#EAEEF9"
          />
          <path
            d="M322.116 121.153C327.793 121.153 332.395 116.551 332.395 110.874C332.395 105.197 327.793 100.595 322.116 100.595C316.439 100.595 311.837 105.197 311.837 110.874C311.837 116.551 316.439 121.153 322.116 121.153Z"
            fill="#EAEEF9"
          />
          <path
            d="M337.159 81.0399C341.036 81.0399 344.179 77.8971 344.179 74.0201C344.179 70.1431 341.036 67.0002 337.159 67.0002C333.283 67.0002 330.14 70.1431 330.14 74.0201C330.14 77.8971 333.283 81.0399 337.159 81.0399Z"
            fill="#EAEEF9"
          />
          <path
            d="M82.9398 120.902C86.8167 120.902 89.9596 117.759 89.9596 113.882C89.9596 110.005 86.8167 106.863 82.9398 106.863C79.0628 106.863 75.9199 110.005 75.9199 113.882C75.9199 117.759 79.0628 120.902 82.9398 120.902Z"
            fill="#EAEEF9"
          />
          <path
            d="M40.0691 256.286C47.2692 256.286 53.106 250.449 53.106 243.249C53.106 236.049 47.2692 230.212 40.0691 230.212C32.869 230.212 27.0322 236.049 27.0322 243.249C27.0322 250.449 32.869 256.286 40.0691 256.286Z"
            fill="#EAEEF9"
          />
          <g filter="url(#filter0_d_17967_3112)">
            <path
              d="M289.775 140.959L291.78 302.416C291.78 307.932 287.268 312.194 281.752 312.194H124.307C118.791 312.194 114.278 307.681 114.278 302.416V94.8289C114.278 89.3133 118.791 85.0513 124.307 85.0513H238.379L289.775 140.959Z"
              fill="url(#paint0_linear_17967_3112)"
            />
          </g>
          <path
            d="M190.996 279.852H185.23C183.976 279.852 182.974 278.598 182.974 277.094C182.974 275.59 183.976 274.336 185.23 274.336H190.996C192.25 274.336 193.253 275.59 193.253 277.094C193.253 278.849 192.25 279.852 190.996 279.852Z"
            fill="#CED7E2"
          />
          <path
            d="M173.445 279.852H139.85C138.597 279.852 137.594 278.598 137.594 277.094C137.594 275.59 138.597 274.336 139.85 274.336H173.445C174.699 274.336 175.702 275.59 175.702 277.094C175.702 278.849 174.699 279.852 173.445 279.852Z"
            fill="#D5DDEA"
          />
          <path
            d="M216.316 118.897H140.853C139.098 118.897 137.594 117.393 137.594 115.638C137.594 113.883 139.098 112.379 140.853 112.379H216.316C218.071 112.379 219.576 113.883 219.576 115.638C219.576 117.393 218.071 118.897 216.316 118.897Z"
            fill="#D5DDEA"
          />
          <path
            d="M169.935 136.948H140.853C139.098 136.948 137.594 135.444 137.594 133.689C137.594 131.934 139.098 130.43 140.853 130.43H169.685C171.439 130.43 172.944 131.934 172.944 133.689C172.944 135.444 171.439 136.948 169.935 136.948Z"
            fill="#D5DDEA"
          />
          <path
            d="M238.379 85.0513V129.677C238.379 135.945 243.894 140.959 250.162 140.959H289.774"
            fill="#D5DDEA"
          />
          <path
            d="M301.81 315.954C299.303 315.954 296.795 314.951 294.79 312.695L252.921 270.826L251.417 271.829C237.879 281.858 222.084 287.123 206.039 287.123C186.734 287.123 167.43 279.351 153.139 265.812C138.097 251.522 129.823 232.217 129.823 211.158C129.823 169.289 163.92 135.193 205.788 135.193C234.369 135.193 259.44 150.235 272.978 175.808C286.266 201.129 284.511 230.713 268.215 254.53L267.212 256.035L309.331 298.154C313.593 302.416 312.59 306.678 311.838 308.934C309.832 312.946 305.821 315.954 301.81 315.954ZM205.788 156.002C175.202 156.002 150.632 180.822 150.632 211.158C150.632 245.756 178.962 266.564 206.54 266.564C223.338 266.564 238.631 259.043 249.411 245.505C262.699 228.958 264.955 206.645 255.679 187.34C246.152 168.036 227.098 156.002 205.788 156.002Z"
            fill="#989FB0"
          />
          <path
            d="M181.972 220.183C185.231 220.183 187.989 217.426 187.989 214.166C187.989 210.907 185.231 208.149 181.972 208.149C178.713 208.149 175.955 210.907 175.955 214.166C175.955 217.426 178.713 220.183 181.972 220.183Z"
            fill="#989FB0"
          />
          <path
            d="M228.101 220.183C231.36 220.183 234.118 217.426 234.118 214.166C234.118 210.907 231.36 208.149 228.101 208.149C224.842 208.149 222.084 210.907 222.084 214.166C222.084 217.676 224.842 220.183 228.101 220.183Z"
            fill="#989FB0"
          />
          <path
            d="M181.736 194.259L168.5 201.406L170.287 204.715L183.523 197.568L181.736 194.259Z"
            fill="#989FB0"
          />
          <path
            d="M227.283 194.165L225.497 197.474L238.735 204.619L240.521 201.31L227.283 194.165Z"
            fill="#989FB0"
          />
          <path
            d="M205.036 234.223C208.913 234.223 212.056 231.866 212.056 228.958C212.056 226.051 208.913 223.693 205.036 223.693C201.16 223.693 198.017 226.051 198.017 228.958C198.017 231.866 201.16 234.223 205.036 234.223Z"
            fill="#989FB0"
          />
          <defs>
            <filter
              id="filter0_d_17967_3112"
              x="92.2783"
              y="74.0513"
              width="221.502"
              height="271.142"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_17967_3112"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_17967_3112"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_17967_3112"
              x1="202.971"
              y1="79.7972"
              x2="202.971"
              y2="314.642"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#FDFEFF" />
              <stop offset="0.9964" stopColor="#ECF0F5" />
            </linearGradient>
          </defs>
        </svg>
      ) : (
        <svg
          viewBox="0 0 400 400"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M203.249 332.002C273.449 332.002 330.36 275.091 330.36 204.641C330.36 134.191 273.198 77.2793 203.249 77.2793C133.05 77.2793 76.1387 134.191 76.1387 204.641C76.1387 275.091 133.05 332.002 203.249 332.002Z"
            fill="#2A2E37"
          />
          <path
            d="M322.087 121.154C327.764 121.154 332.366 116.551 332.366 110.874C332.366 105.197 327.764 100.595 322.087 100.595C316.41 100.595 311.808 105.197 311.808 110.874C311.808 116.551 316.41 121.154 322.087 121.154Z"
            fill="#2A2E37"
          />
          <path
            d="M337.131 81.0398C341.008 81.0398 344.151 77.8969 344.151 74.0199C344.151 70.1429 341.008 67 337.131 67C333.254 67 330.111 70.1429 330.111 74.0199C330.111 77.8969 333.254 81.0398 337.131 81.0398Z"
            fill="#2A2E37"
          />
          <path
            d="M82.9086 120.903C86.7856 120.903 89.9285 117.76 89.9285 113.883C89.9285 110.006 86.7856 106.863 82.9086 106.863C79.0316 106.863 75.8887 110.006 75.8887 113.883C75.8887 117.76 79.0316 120.903 82.9086 120.903Z"
            fill="#2A2E37"
          />
          <path
            d="M40.037 256.287C47.2371 256.287 53.074 250.451 53.074 243.25C53.074 236.05 47.2371 230.213 40.037 230.213C32.8369 230.213 27 236.05 27 243.25C27 250.451 32.8369 256.287 40.037 256.287Z"
            fill="#2A2E37"
          />
          <g filter="url(#filter0_d_17967_3131)">
            <path
              d="M289.745 140.96L291.751 302.419C291.751 307.934 287.238 312.196 281.722 312.196H124.276C118.76 312.196 114.247 307.683 114.247 302.419V94.8295C114.247 89.3139 118.76 85.0518 124.276 85.0518H238.349L289.745 140.96Z"
              fill="url(#paint0_linear_17967_3131)"
            />
          </g>
          <path
            d="M190.966 279.855H185.2C183.946 279.855 182.943 278.601 182.943 277.097C182.943 275.592 183.946 274.339 185.2 274.339H190.966C192.22 274.339 193.223 275.592 193.223 277.097C193.223 278.852 192.22 279.855 190.966 279.855Z"
            fill="#CED7E2"
          />
          <path
            d="M173.414 279.855H139.819C138.565 279.855 137.562 278.601 137.562 277.097C137.562 275.592 138.565 274.339 139.819 274.339H173.414C174.668 274.339 175.671 275.592 175.671 277.097C175.671 278.852 174.668 279.855 173.414 279.855Z"
            fill="#A9AFC4"
          />
          <path
            d="M216.286 118.897H140.822C139.067 118.897 137.562 117.393 137.562 115.638C137.562 113.883 139.067 112.379 140.822 112.379H216.286C218.041 112.379 219.545 113.883 219.545 115.638C219.545 117.393 218.041 118.897 216.286 118.897Z"
            fill="#A9AFC4"
          />
          <path
            d="M169.904 136.948H140.822C139.067 136.948 137.562 135.444 137.562 133.689C137.562 131.934 139.067 130.43 140.822 130.43H169.654C171.409 130.43 172.913 131.934 172.913 133.689C172.913 135.444 171.409 136.948 169.904 136.948Z"
            fill="#A9AFC4"
          />
          <path
            d="M238.35 85.0518V129.678C238.35 135.946 243.865 140.96 250.133 140.96H289.745"
            fill="#3A4050"
          />
          <g filter="url(#filter1_d_17967_3131)">
            <path
              d="M301.779 315.957C299.272 315.957 296.765 314.954 294.759 312.698L252.89 270.829L251.386 271.832C237.848 281.861 222.053 287.125 206.007 287.125C186.703 287.125 167.398 279.353 153.107 265.815C138.064 251.524 129.791 232.22 129.791 211.16C129.791 169.291 163.888 135.194 205.757 135.194C234.338 135.194 259.409 150.237 272.947 175.81C286.235 201.131 284.48 230.715 268.184 254.533L267.181 256.037L309.3 298.157C313.562 302.419 312.56 306.681 311.807 308.937C309.802 312.949 305.79 315.957 301.779 315.957ZM205.757 156.003C175.17 156.003 150.6 180.824 150.6 211.16C150.6 245.758 178.93 266.567 206.509 266.567C223.306 266.567 238.6 259.046 249.38 245.507C262.668 228.96 264.924 206.647 255.648 187.342C246.121 168.038 227.067 156.003 205.757 156.003Z"
              fill="url(#paint1_linear_17967_3131)"
            />
          </g>
          <path
            d="M181.939 220.186C185.198 220.186 187.956 217.428 187.956 214.169C187.956 210.91 185.198 208.152 181.939 208.152C178.68 208.152 175.922 210.91 175.922 214.169C175.922 217.428 178.68 220.186 181.939 220.186Z"
            fill="#A9AFC4"
          />
          <path
            d="M228.069 220.186C231.328 220.186 234.086 217.428 234.086 214.169C234.086 210.91 231.328 208.152 228.069 208.152C224.81 208.152 222.052 210.91 222.052 214.169C222.052 217.679 224.81 220.186 228.069 220.186Z"
            fill="#A9AFC4"
          />
          <path
            d="M181.703 194.262L168.467 201.409L170.254 204.718L183.49 197.571L181.703 194.262Z"
            fill="#989FB0"
          />
          <path
            d="M227.251 194.167L225.465 197.476L238.702 204.621L240.489 201.312L227.251 194.167Z"
            fill="#989FB0"
          />
          <path
            d="M205.004 234.226C208.881 234.226 212.024 231.869 212.024 228.961C212.024 226.053 208.881 223.696 205.004 223.696C201.127 223.696 197.984 226.053 197.984 228.961C197.984 231.869 201.127 234.226 205.004 234.226Z"
            fill="#A9AFC4"
          />
          <defs>
            <filter
              id="filter0_d_17967_3131"
              x="92.2471"
              y="74.0518"
              width="221.504"
              height="271.145"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_17967_3131"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_17967_3131"
                result="shape"
              />
            </filter>
            <filter
              id="filter1_d_17967_3131"
              x="125.791"
              y="135.194"
              width="190.688"
              height="188.763"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_17967_3131"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_17967_3131"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_17967_3131"
              x1="202.941"
              y1="79.7977"
              x2="202.941"
              y2="314.645"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#5C6479" />
              <stop offset="0.9964" stopColor="#5C6479" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_17967_3131"
              x1="171.697"
              y1="182.645"
              x2="249.06"
              y2="315.014"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#E3EAF7" />
              <stop offset="1" stopColor="#ADB6C8" />
            </linearGradient>
          </defs>
        </svg>
      )}
    </EmptyContainer>
  );
};
