import React, {useEffect, useRef, useState} from 'react';
import {NetworkStatus, useQuery} from '@apollo/client';
import {gql} from 'shared/__generated__';
import {device} from 'shared/device';
import {Search} from 'shared/icons/Search';
import {Trash} from 'shared/icons/Trash';
import {useColors} from 'shared/lib/hooks';
import {
  getDiscountCalc,
  getDurationHourMinutes,
  getNumberFormat,
  getPriceFormat,
} from 'shared/lib/utils';
import {
  setCreateBookingDuration,
  setCreateBookingServices,
  setEditBookingDuration,
  setEditBookingServices,
  useAppDispatch,
  useAppSelector,
} from 'shared/store';
import {Divider} from 'shared/ui/Divider';
import {Flex} from 'shared/ui/Flex';
import {List} from 'shared/ui/List';
import {Modal} from 'shared/ui/Modal';
import {Text} from 'shared/ui/Text';
import {TextInput} from 'shared/ui/TextInput';
import styled from 'styled-components';
import {RadioButton} from 'shared/ui/RadioButton';
import {TableViewRow} from 'shared/ui/TableViewRow';
import {IconButton} from 'shared/ui/IconButton';

import {Discount} from 'shared/__generated__/graphql';
import {ArrowDown2v} from 'shared/icons/ArrowDown2v';
import Check from 'shared/icons/Check';
import {InputAvatar} from './InputAvatar';
import {Skeleton} from 'shared/ui/Skeleton';
import {EmptyRecord} from 'shared/illustration/EmptyRecord';
import {client} from 'shared/config/apollo';
import {Tooltip} from 'shared/ui/Tooltip';
import {CircleAlert} from 'shared/icons/CircleAlert';
import dayjs from 'dayjs';
import {Dropdown} from 'shared/ui/Dropdown';
import Xv2 from 'shared/icons/Xv2';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
`;

type DiscountT = Omit<
  Discount,
  | 'category'
  | 'company'
  | 'description'
  | 'image'
  | 'is_new_customer'
  | 'is_salary'
  | 'is_with_bonus'
  | 'updated_at'
  | 'employees'
  | 'services'
>;

const GET_DATA = gql(`
  query SelectServicesGetData($companyId: String!, $employeeId: String, $workingDate: String, $start: String, $services: [String!], $query: String, $first: Int!, $page: Int) {
    widgetGetServices(company_id: $companyId, employee_id: $employeeId, working_date: $workingDate, start: $start, services: $services, query: $query) {
      name
      price
      min_price
      max_price
      is_active
      is_overlapping
      id
      full_duration
      duration
      type
      resources {
        id
        is_available
        name
      }
    }
  discounts(company_id: $companyId, first: $first, page: $page) {
    data {
        id
        name
        active_on_wednesday
        active_on_tuesday
        active_on_thursday
        active_on_sunday
        active_on_saturday
        active_on_monday
        active_on_friday
        is_active
        employees {
          id
        }
        created_at
        type
        is_all_employees
        is_all_services
        services {
          id
        }
        valid_from
        valid_from_time
        valid_to
        valid_to_time
        value
      }
      paginatorInfo {
        currentPage
        hasMorePages
      }
  }
  }
`);

const ServiceItemWrapper = styled.button`
  padding: 10px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: transparent;
  width: 100%;
  border: none;
  gap: 16px;
  pointer-events: all;
  cursor: pointer;
  &:hover {
    background-color: ${({theme}) => theme.bgSecondary};
  }
`;

const ServicePrice = styled(Text)<{
  price: string;
}>`
  pointer-events: none;
  &::after {
    color: ${({theme}) => theme.mainPrimary};
    content: '${({price}) => price}';
  }
`;

const ServiceItem = ({
  title,
  duration,
  price,
  onClick,
  active,
}: {
  title: string;
  duration: string;
  price: string;
  onClick: () => void;
  active: boolean;
}) => {
  const colors = useColors();
  return (
    <ServiceItemWrapper onClick={onClick}>
      <Flex
        alignItems="center"
        justifyContent="center"
        style={{
          width: 44,
          height: 44,
        }}>
        <Flex
          alignItems="center"
          justifyContent="center"
          style={{
            width: 24,
            height: 24,
            borderRadius: 6,
            backgroundColor: active ? colors.mainPrimary : 'transparent',
            border: `1px solid ${
              active ? colors.mainPrimary : colors.textTertiary
            }`,
          }}>
          <Check color={active ? colors.white : 'transparent'} size={18} />
        </Flex>
      </Flex>
      <Flex direction="column" gap={4}>
        <Text style={{pointerEvents: 'none'}}>{title}</Text>
        <ServicePrice
          color="textTertiary"
          typography="subHead14Regular"
          price={price}>
          {duration}
        </ServicePrice>
      </Flex>
    </ServiceItemWrapper>
  );
};

const Time = styled.button<{selected: boolean}>`
  width: 100%;
  pointer-events: all;
  cursor: pointer;
  height: 56px;
  background-color: ${({theme, selected}) =>
    selected ? theme.bgSecondary : 'transparent'};
  border: none;
  color: ${({theme}) => theme.textPrimary};
  text-align: left;
  padding: 0 24px;
  font-size: 16px;
  font-family: 'Inter';
  font-weight: 600;
  position: relative;
  &:hover {
    background-color: ${({theme}) => theme.bgSecondary};
  }
`;

export const SelectServices = ({
  disabled,
  variant = 'edit',
}: {
  disabled?: boolean;
  variant?: 'edit' | 'view';
}) => {
  const companyId = useAppSelector(state => state.company.data!.id);
  const screen = useAppSelector(state => state.booking.screen);
  const {employeeId, workingDate, services} = useAppSelector(
    state => state.booking[screen],
  );
  const [search, setSearch] = useState('');
  const colors = useColors();
  const start = dayjs(workingDate?.split(',')[0]).format('HH:mm');

  const {data, networkStatus} = useQuery(GET_DATA, {
    variables: {
      companyId: companyId,
      employeeId: employeeId ?? undefined,
      services: services.map(service => service.service_id),
      workingDate: workingDate?.split(' ')[0],
      start,
      first: 20,
      page: 1,
      query: search !== '' ? search : undefined,
    },
    onCompleted: resData => {
      setServicesData(resData.widgetGetServices ?? []);
    },
  });
  const cachedData = client.readQuery({
    query: GET_DATA,
    variables: {
      companyId: companyId,
      employeeId: employeeId ?? undefined,
      services: services.map(service => service.service_id),
      workingDate: workingDate?.split(' ')[0],
      start,
      first: 20,
      page: 1,
    },
  });
  const cachedServices = cachedData?.widgetGetServices ?? [];
  const discounts = data?.discounts?.data ?? [];
  const [servicesData, setServicesData] = useState(
    data?.widgetGetServices ?? [],
  );
  const dispatch = useAppDispatch();
  const [visible, setVisible] = useState(false);
  const setRedux =
    screen === 'create' ? setCreateBookingServices : setEditBookingServices;
  const selectRef = useRef<HTMLDivElement>(null);
  const total = services.reduce(
    (acc, cur) =>
      acc +
      getDiscountCalc(
        +(cur.price ?? ''),
        data?.discounts?.data.find(discount => discount.id === cur.discount_id)
          ?.type,
        data?.discounts?.data.find(discount => discount.id === cur.discount_id)
          ?.value,
      ) +
      +(cur.additional_price ?? ''),
    0,
  );
  const duration = cachedServices
    .filter(service =>
      services.map(service2 => service2.service_id).includes(service.id),
    )
    .reduce((acc, cur) => acc + (cur.full_duration ?? 0), 0);
  const [durationCalc, setDurationCalc] = useState<number>(duration);
  const [timeVisible, setTimeVisible] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        selectRef.current &&
        !selectRef.current.contains(event.target as Node)
      ) {
        setVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectRef]);

  useEffect(() => {
    setDurationCalc(duration);
  }, [duration, services.length]);

  return (
    <Flex ref={selectRef} direction="column" gap={16}>
      <Text typography="title18">Услуги</Text>
      <Wrapper>
        {variant === 'edit' && (
          <InputAvatar
            search={search}
            setSearch={setSearch}
            disabled={disabled}
            placeholder="Поиск услуг"
            leftElement={<Search color={colors.textTertiary} />}
            onClick={() => setVisible(true)}
            rightElement={
              <ArrowDown2v
                style={{
                  transform: visible ? 'rotate(180deg)' : 'rotate(0)',
                  transition: 'transform 0.3s',
                }}
              />
            }
            focused={visible}
          />
        )}
        <List
          data={services}
          renderItem={item => (
            <ServiceSelected
              start={start}
              discounts={discounts}
              key={'service_selected' + item.service_id}
              discount_id={item.discount_id}
              service_id={item.service_id}
              name={
                cachedServices.find(service => service.id === item.service_id)
                  ?.name ?? ''
              }
              variant={variant}
              disabled={disabled}
              price={item.price ?? ''}
              additional={item.additional_price ?? ''}
              duration={
                cachedServices.find(service => service.id === item.service_id)
                  ?.full_duration ?? 0
              }
              resources={
                cachedServices.find(service => service.id === item.service_id)
                  ?.resources ?? []
              }
              onDelete={() => {
                dispatch(
                  setRedux(
                    services.filter(
                      service => service.service_id !== item.service_id,
                    ),
                  ),
                );
              }}
              onChange={values => {
                dispatch(
                  setRedux(
                    services.map(service => {
                      if (item.service_id === service.service_id) {
                        const temp = {...service, ...values};
                        return temp;
                      } else {
                        return service;
                      }
                    }),
                  ),
                );
              }}
            />
          )}
          divider={<Divider mt={16} mb={16} />}
          footer={
            <>
              <Divider mt={16} mb={16} />
              <Flex direction="column" gap={8}>
                <Flex direction="row" style={{justifyContent: 'space-between'}}>
                  <Text typography="title20">Итого:</Text>
                  <Text typography="title20" color="mainPrimary">
                    {getPriceFormat(total)}
                  </Text>
                </Flex>
                {variant === 'view' && (
                  <Flex direction="row" justifyContent="space-between">
                    <Text color="textTertiary">Общее время</Text>
                    <Text color="textTertiary">
                      {getDurationHourMinutes(duration)}
                    </Text>
                  </Flex>
                )}
              </Flex>
            </>
          }
        />
        {variant === 'edit' && (
          <Flex
            direction="column"
            style={{marginTop: 16, position: 'relative'}}
            gap={16}>
            <Text typography="title18">Длительность записи</Text>
            <TextInput
              label="Длительность"
              onClick={() => setTimeVisible(state => !state)}
              value={getDurationHourMinutes(durationCalc)}
              onChange={() => {}}
            />
            <Dropdown
              gap={0}
              visible={timeVisible}
              onClose={() => setTimeVisible(false)}
              withBackdrop={false}
              style={{width: 'max-content', top: 90}}
              data={Array.from(Array(60).keys()).map(item => item * 10)}
              renderItem={item => (
                <React.Fragment key={'time_' + item}>
                  <Time
                    key={'time_' + item}
                    selected={durationCalc === item}
                    onClick={() => {
                      setDurationCalc(item);
                      if (screen === 'edit') {
                        dispatch(setEditBookingDuration(item));
                      } else {
                        dispatch(setCreateBookingDuration(item));
                      }
                      setTimeVisible(false);
                    }}>
                    {getDurationHourMinutes(item)}
                  </Time>
                  {item < 590 && <Divider style={{margin: '0 10px 0 24px'}} />}
                </React.Fragment>
              )}
            />
          </Flex>
        )}
        {visible && (
          <ModalWrapper length={servicesData.length}>
            {networkStatus === NetworkStatus.loading ||
            networkStatus === NetworkStatus.setVariables ? (
              <ServicesSkeleton />
            ) : servicesData.length === 0 ? (
              <Flex
                flex={1}
                alignItems="center"
                justifyContent="center"
                direction="column"
                gap={16}>
                <EmptyRecord size={200} />
                <Text color="textTertiary" align="center">
                  По вашему запросу ничего не найдено. <br /> Проверьте данные и
                  попробуйте еще раз
                </Text>
              </Flex>
            ) : (
              <>
                {servicesData.map(service => {
                  const getPrice = () => {
                    switch (service.type) {
                      case 'fixed':
                        return getPriceFormat(service.price);
                      case 'range':
                        return (
                          getPriceFormat(service.min_price) +
                          '-' +
                          getPriceFormat(service.max_price)
                        );
                      case 'free':
                        return 'Бесплатно';
                      case 'not_fixed':
                        return 'от ' + getPriceFormat(service.min_price);
                      default:
                        return '';
                    }
                  };
                  return (
                    <ServiceItem
                      key={service.id}
                      title={service.name}
                      onClick={() => {
                        if (
                          !services
                            .map(service => service.service_id)
                            .includes(service.id)
                        ) {
                          dispatch(
                            setRedux(
                              services.concat([
                                {
                                  service_id: service.id,
                                  price:
                                    service.type === 'fixed'
                                      ? service.price?.toString()
                                      : service.type === 'free'
                                      ? '0'
                                      : service.min_price,
                                  additional_price: '',
                                },
                              ]),
                            ),
                          );
                        } else {
                          dispatch(
                            setRedux(
                              services.filter(
                                item => item.service_id !== service.id,
                              ),
                            ),
                          );
                        }
                      }}
                      duration={`${service.full_duration ?? ''} мин • `}
                      price={getPrice()}
                      active={services
                        .map(service => service.service_id)
                        .includes(service.id)}
                    />
                  );
                })}
              </>
            )}
          </ModalWrapper>
        )}
      </Wrapper>
    </Flex>
  );
};

const ModalWrapper = styled.div<{length: number}>`
  position: absolute;
  background-color: ${({theme}) => theme.bgPrimary};
  border-radius: 18px;
  padding: 16px 0;
  width: 100%;
  z-index: 100;
  max-height: ${({length}) => (length > 0 ? '256px' : 'initial')};
  top: 64px;
  overflow-y: scroll;
  border: 1px solid ${({theme}) => theme.borderPrimary};
  pointer-events: all;
`;

const WrapperServiceSelected = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const TextServiceName = styled(Text)<{
  duration: string;
}>`
  &::after {
    margin-left: 8px;
    color: ${({theme}) => theme.textTertiary};
    content: '${({duration}) => duration}';
  }
`;

const ServiceSelected = ({
  service_id,
  discount_id,
  name,
  price,
  additional,
  duration,
  onDelete,
  onChange,
  discounts,
  disabled,
  start,
  resources,
  variant,
}: {
  variant: 'edit' | 'view';
  start?: string;
  discount_id?: string;
  service_id: string;
  name: string;
  price: string;
  additional: string;
  duration: number;
  onDelete: () => void;
  onChange: (values: {price: string; additional_price: string}) => void;
  discounts: DiscountT[];
  disabled?: boolean;
  resources?: {
    id: string;
    name: string;
    is_available?: boolean | null;
  }[];
}) => {
  const colors = useColors();
  const [visible, setVisible] = useState(false);
  const discount = discounts.find(discount => discount.id === discount_id);
  const discountCalcPrice = discount
    ? discount.type === 'fixed'
      ? Math.max(+price - discount.value, 0)
      : Math.max(+price - (+price * discount.value) / 100, 0)
    : +price;
  const total = (discountCalcPrice + +additional).toString();
  const nonAvailableResources = resources?.filter(
    resource => resource.is_available === false,
  );

  return (
    <>
      <WrapperServiceSelected>
        <Flex flex={1} direction="column" gap={8}>
          <Flex>
            <Text
              typography="title18"
              style={{
                display: 'flex',
                flexDirection: 'row',
                textDecorationLine: discount ? 'line-through' : 'none',
              }}>
              {total === '0' ? 'Бесплатно' : getPriceFormat(price)}
            </Text>
            {discount ? (
              <Text
                typography="text16Semibold"
                color="mainPrimary"
                style={{marginLeft: 8}}>
                {total} ₽
              </Text>
            ) : (
              ''
            )}
          </Flex>
          <TextServiceName
            typography="text18"
            duration={getDurationHourMinutes(duration)}>
            {name}
          </TextServiceName>
          {start &&
          nonAvailableResources?.length &&
          nonAvailableResources?.length > 0 ? (
            <Flex gap={8}>
              <Tooltip
                position="right"
                style={{
                  width: '375px',
                  backgroundColor: colors.bgTertiary,
                }}
                text={`Ресурс ${nonAvailableResources
                  .map(resource => resource.name)
                  .join(
                    ', ',
                  )} уже занят на ${start}, однако ваши сотрудники все равно могут бронировать его на это время для других клиентов.`}>
                <CircleAlert />
              </Tooltip>
              <Text color="warningPrimary">Ресурс недоступен</Text>
            </Flex>
          ) : null}
        </Flex>
        <Flex flex={0} direction="row" style={{gap: 8}}>
          <IconButton
            rotate={visible ? 180 : 0}
            style={{backgroundColor: colors.fillPrimary}}
            onClick={() => setVisible(state => !state)}>
            <ArrowDown2v />
          </IconButton>
          {variant === 'edit' && (
            <IconButton
              style={{backgroundColor: colors.fillPrimary}}
              disabled={disabled}
              onClick={onDelete}>
              <Trash color={colors.mainPrimary} />
            </IconButton>
          )}
        </Flex>
      </WrapperServiceSelected>
      <SetupService
        discounts={discounts}
        service_id={service_id}
        visible={visible}
        valuePrice={price}
        disabled={disabled}
        valueAdditional={additional}
        onChange={(price, additional) =>
          onChange({
            price,
            additional_price: additional,
          })
        }
      />
    </>
  );
};

const WrapperSetupService = styled.div<{visible: boolean}>`
  margin-top: ${props => (props.visible ? '16px' : '0px')};
  border-radius: 18px;
  padding: ${props => (props.visible ? '16px' : '0px')};
  gap: ${props => (props.visible ? '16px' : '0px')};
  display: flex;
  height: ${props => (props.visible ? 'auto' : '0px')};
  overflow: ${props => (props.visible ? 'visible' : 'hidden')};
  transition:
    padding 0.4s,
    border-color 0.2s,
    gap 0.4s,
    height 0.4s,
    background-color 0.2s,
    margin-top 0.4s;
  flex-direction: column;
  position: relative;
  border: 1px solid;
  border-color: ${props =>
    props.visible ? props.theme.borderPrimary : 'transparent'};
  background-color: ${props =>
    props.visible ? props.theme.bgSecondary : 'transparent'};
  pointer-events: all;
  @media ${device.desktop} {
    margin-top: ${props => (props.visible ? '24px' : '0px')};
  }
`;

const SetupService = ({
  service_id,
  visible,
  valuePrice,
  valueAdditional,
  onChange,
  discounts,
  disabled,
}: {
  service_id: string;
  visible: boolean;
  valuePrice: string;
  valueAdditional: string;
  onChange: (price: string, additional: string) => void;
  discounts: DiscountT[];
  disabled?: boolean;
}) => {
  const colors = useColors();
  const screen = useAppSelector(state => state.booking.screen);
  const {services} = useAppSelector(state => state.booking[screen]);
  const discount_id = services.find(
    service => service.service_id === service_id,
  )?.discount_id;

  return (
    <WrapperSetupService visible={visible}>
      <TextInput
        label="Цена"
        value={valuePrice}
        disabled={disabled}
        type="number"
        onChange={value => onChange(value, valueAdditional)}
        backgroundColor={colors.bgPrimary}
        rightElement={
          <Text typography="subHead14Regular" color="textTertiary">
            ₽
          </Text>
        }
      />
      <Flex
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        style={{height: 64, overflow: 'visible'}}
        gap={8}>
        <DiscountSelect
          discounts={discounts}
          name={discounts.find(discount => discount.id === discount_id)?.name}
          service_id={service_id}
          discount_id={discount_id}
          disabled={disabled}
        />
        <DiscountValue
          type={discounts.find(discount => discount.id === discount_id)?.type}
          value={discounts.find(discount => discount.id === discount_id)?.value}
        />
      </Flex>
      <TextInput
        label="Сумма доплаты"
        value={valueAdditional}
        type="number"
        disabled={disabled}
        backgroundColor={colors.bgPrimary}
        onChange={value => onChange(valuePrice, value)}
        rightElement={
          <Text typography="subHead14Regular" color="textTertiary">
            ₽
          </Text>
        }
      />
    </WrapperSetupService>
  );
};

const DiscountSelectWrapper = styled.div<{disabled?: boolean}>`
  background-color: ${props => props.theme.bgPrimary};
  width: 100%;
  height: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  border-radius: 14px;
  padding: 0 16px;
  pointer-events: all;
  cursor: pointer;
  border: 1px solid ${props => props.theme.borderPrimary};
  @media ${device.desktop} {
    height: 64px;
  }
`;

const DiscountSelect = ({
  name,
  discount_id,
  discounts,
  service_id,
  disabled,
}: {
  name?: string;
  discount_id?: string;
  discounts: DiscountT[];
  service_id: string;
  disabled?: boolean;
}) => {
  const screen = useAppSelector(state => state.booking.screen);
  const {services} = useAppSelector(state => state.booking[screen]);
  const [visible, setVisible] = useState(false);
  const dispatch = useAppDispatch();
  const setRedux =
    screen === 'create' ? setCreateBookingServices : setEditBookingServices;
  const selectRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        selectRef.current &&
        !selectRef.current.contains(event.target as Node)
      ) {
        setVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectRef]);

  return (
    <DiscountSelectWrapper
      disabled={disabled}
      ref={selectRef}
      onClick={() => !disabled && setVisible(!visible)}>
      <Flex direction="column" flex={1}>
        <Text typography="footNote12Regular" color="textTertiary">
          Скидка
        </Text>
        <Text typography="subHead14Regular" color="textPrimary">
          {name ?? 'Не выбрано'}
        </Text>
      </Flex>
      <IconButton
        rotate={visible ? 180 : 0}
        variant="unstyled"
        onClick={e => {
          if (discount_id) {
            dispatch(
              setRedux(
                services.map(service => ({
                  ...service,
                  discount_id:
                    service.service_id === service_id
                      ? undefined
                      : service.discount_id,
                })),
              ),
            );
            e.stopPropagation();
          }
        }}
        size={24}>
        {discount_id ? <Xv2 /> : <ArrowDown2v size={24} />}
      </IconButton>
      <Modal
        visible={visible}
        onClose={() => setVisible(false)}
        style={{
          left: 0,
          top: 62,
          width: '100%',
        }}>
        <List
          data={discounts}
          renderItem={item => (
            <TableViewRow
              title={item.name}
              subtitle={
                item.value.toString() + `${item.type === 'fixed' ? ' ₽' : ' %'}`
              }
              rightElement={() => (
                <RadioButton
                  style={{flex: 0}}
                  selected={item.id === discount_id}
                />
              )}
              onClick={e => {
                e.stopPropagation();
                dispatch(
                  setRedux(
                    services.map(service => ({
                      ...service,
                      discount_id:
                        service.service_id === service_id
                          ? item.id
                          : service.discount_id,
                    })),
                  ),
                );
                setVisible(false);
              }}
              styleTitle={{pointerEvents: 'none'}}
              styleSubTitle={{pointerEvents: 'none'}}
              style={{
                width: '100%',
                pointerEvents: 'all',
                cursor: 'pointer',
              }}
            />
          )}
        />
      </Modal>
    </DiscountSelectWrapper>
  );
};

const DiscountValueWrapper = styled.div`
  height: 56px;
  width: 140px;
  border-radius: 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  border: 1px solid ${({theme}) => theme.borderPrimary};
  background-color: ${({theme}) => theme.bgPrimary};
  @media ${device.desktop} {
    height: 64px;
  }
`;

const DiscountValue = ({
  value = 0,
  type = 'fixed',
}: {
  value?: number;
  type?: 'fixed' | 'percentage';
}) => {
  return (
    <DiscountValueWrapper>
      <Text typography="subHead14Regular" style={{marginLeft: 16}}>
        {getNumberFormat(value)}
      </Text>
      <Text
        typography="subHead14Regular"
        color="textTertiary"
        style={{marginRight: 16}}>
        {type === 'fixed' ? '₽' : '%'}
      </Text>
    </DiscountValueWrapper>
  );
};

const ServicesSkeleton = () => {
  return (
    <Skeleton width={'100%'} height={372}>
      {Array.from(Array(5).keys()).map((_, index) => (
        <React.Fragment key={'services_sk_' + index}>
          <rect
            width={44}
            height={44}
            rx={8}
            ry={8}
            x={16}
            y={12 + 76 * index}
          />
          <rect
            width={240}
            height={24}
            rx={8}
            ry={8}
            x={76}
            y={10 + 76 * index}
          />
          <rect
            width={100}
            height={20}
            rx={8}
            ry={8}
            x={76}
            y={38 + 76 * index}
          />
        </React.Fragment>
      ))}
    </Skeleton>
  );
};
