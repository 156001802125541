import React from 'react';
import {useColorScheme} from 'shared/lib/hooks';
import {EmptyContainer} from 'shared/ui/EmptyContainer';

export const EmptyLoyalty = ({
  size = 400,
  style,
}: {
  size?: number;
  style?: React.CSSProperties;
}) => {
  const colorScheme = useColorScheme();
  return (
    <EmptyContainer style={style} size={size} mt={58}>
      {colorScheme === 'light' ? (
        <svg
          viewBox="0 0 400 400"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M191.875 336.928C257.146 336.928 310.063 284.012 310.063 218.508C310.063 153.003 256.913 100.087 191.875 100.087C126.604 100.087 73.6875 153.003 73.6875 218.508C73.6875 284.012 126.604 336.928 191.875 336.928Z"
            fill="#EAEEF9"
          />
          <path
            d="M302.37 140.883C307.649 140.883 311.928 136.604 311.928 131.326C311.928 126.047 307.649 121.768 302.37 121.768C297.092 121.768 292.812 126.047 292.812 131.326C292.812 136.604 297.092 140.883 302.37 140.883Z"
            fill="#EAEEF9"
          />
          <path
            d="M316.359 103.585C319.964 103.585 322.886 100.662 322.886 97.0574C322.886 93.4526 319.964 90.5303 316.359 90.5303C312.754 90.5303 309.832 93.4526 309.832 97.0574C309.832 100.662 312.754 103.585 316.359 103.585Z"
            fill="#EAEEF9"
          />
          <path
            d="M79.9842 140.65C83.589 140.65 86.5113 137.728 86.5113 134.123C86.5113 130.518 83.589 127.596 79.9842 127.596C76.3793 127.596 73.457 130.518 73.457 134.123C73.457 137.728 76.3793 140.65 79.9842 140.65Z"
            fill="#EAEEF9"
          />
          <path
            d="M40.1218 266.529C46.8165 266.529 52.2436 261.101 52.2436 254.407C52.2436 247.712 46.8165 242.285 40.1218 242.285C33.4271 242.285 28 247.712 28 254.407C28 261.101 33.4271 266.529 40.1218 266.529Z"
            fill="#EAEEF9"
          />
          <path
            d="M372 181.329H360.435V169.764H350.522V181.329H338.957V191.572H350.522V203.137H360.435V191.572H372V181.329Z"
            fill="url(#paint0_linear_8730_44606)"
          />
          <path
            d="M180.614 71.8864H170.728V62H162.254V71.8864H152.367V80.6429H162.254V90.5293H170.728V80.6429H180.614V71.8864Z"
            fill="url(#paint1_linear_8730_44606)"
          />
          <path
            d="M64.259 170.521H55.8121V162.074H48.5719V170.521H40.125V178.002H48.5719V186.449H55.8121V178.002H64.259V170.521Z"
            fill="url(#paint2_linear_8730_44606)"
          />
          <g filter="url(#filter0_d_8730_44606)">
            <path
              d="M275.017 195.667L251.674 138.667C247.512 128.534 236.655 122.744 225.979 125.096L165.723 138.124C160.656 139.21 156.313 142.648 153.78 147.172L96.4187 256.466C94.0663 260.99 95.6949 266.78 100.4 269.133L204.265 323.78C208.789 326.132 214.579 324.503 216.932 319.799L274.293 210.505C276.826 205.981 277.007 200.552 275.017 195.667ZM217.294 172.686C209.875 168.886 206.979 159.658 210.96 152.239C214.76 144.82 223.989 141.924 231.408 145.905C238.827 149.705 241.722 158.934 237.741 166.353C233.941 173.772 224.713 176.667 217.294 172.686Z"
              fill="url(#paint3_linear_8730_44606)"
            />
          </g>
          <path
            d="M224.713 158.754C271.941 134.325 290.579 97.9544 265.97 82.2117C241.361 66.469 217.113 82.2117 210.961 127.992"
            stroke="#ADB6C8"
            strokeWidth="5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M212.384 237.612C215.579 239.38 219.833 238.158 221.602 234.963C223.37 231.768 222.148 227.513 218.953 225.745C215.758 223.976 211.504 225.199 209.735 228.394C207.84 231.817 208.961 235.717 212.384 237.612Z"
            fill="#AAB2C5"
          />
          <path
            d="M194.181 245.962L191.214 244.319C191.736 237.453 186.254 231.735 179.49 231.568C176.93 231.344 174.094 232.159 172.044 233.708L169.078 232.065C175.504 226.38 185.741 227.277 191.071 233.805C193.508 236.943 194.959 241.324 194.181 245.962Z"
            fill="#AAB2C5"
          />
          <path
            d="M166.743 212.347C169.938 214.116 174.193 212.893 175.961 209.698C177.73 206.504 176.507 202.249 173.312 200.48C170.117 198.712 165.863 199.934 164.094 203.129C162.428 206.679 163.548 210.579 166.743 212.347Z"
            fill="#AAB2C5"
          />
          <defs>
            <filter
              id="filter0_d_8730_44606"
              x="73.3516"
              y="113.574"
              width="225.017"
              height="244.272"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_8730_44606"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_8730_44606"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_8730_44606"
              x1="355.481"
              y1="170.923"
              x2="355.481"
              y2="189.655"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#B0BACC" />
              <stop offset="1" stopColor="#969EAE" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_8730_44606"
              x1="166.493"
              y1="62.9911"
              x2="166.493"
              y2="79.0043"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#B0BACC" />
              <stop offset="1" stopColor="#969EAE" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_8730_44606"
              x1="52.1939"
              y1="162.921"
              x2="52.1939"
              y2="176.602"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#B0BACC" />
              <stop offset="1" stopColor="#969EAE" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_8730_44606"
              x1="185.801"
              y1="119.942"
              x2="185.801"
              y2="327.006"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#FDFEFF" />
              <stop offset="0.9964" stopColor="#ECF0F5" />
            </linearGradient>
          </defs>
        </svg>
      ) : (
        <svg
          viewBox="0 0 400 400"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M191.875 336.928C257.146 336.928 310.063 284.012 310.063 218.508C310.063 153.003 256.913 100.087 191.875 100.087C126.604 100.087 73.6875 153.003 73.6875 218.508C73.6875 284.012 126.604 336.928 191.875 336.928Z"
            fill="#2A2E37"
          />
          <path
            d="M302.37 140.883C307.649 140.883 311.928 136.604 311.928 131.326C311.928 126.047 307.649 121.768 302.37 121.768C297.092 121.768 292.812 126.047 292.812 131.326C292.812 136.604 297.092 140.883 302.37 140.883Z"
            fill="#2A2E37"
          />
          <path
            d="M316.359 103.585C319.964 103.585 322.886 100.662 322.886 97.0574C322.886 93.4526 319.964 90.5303 316.359 90.5303C312.754 90.5303 309.832 93.4526 309.832 97.0574C309.832 100.662 312.754 103.585 316.359 103.585Z"
            fill="#2A2E37"
          />
          <path
            d="M79.9842 140.65C83.589 140.65 86.5113 137.728 86.5113 134.123C86.5113 130.518 83.589 127.596 79.9842 127.596C76.3793 127.596 73.457 130.518 73.457 134.123C73.457 137.728 76.3793 140.65 79.9842 140.65Z"
            fill="#2A2E37"
          />
          <path
            d="M40.1218 266.529C46.8165 266.529 52.2436 261.102 52.2436 254.407C52.2436 247.712 46.8165 242.285 40.1218 242.285C33.4271 242.285 28 247.712 28 254.407C28 261.102 33.4271 266.529 40.1218 266.529Z"
            fill="#2A2E37"
          />
          <path
            d="M372 181.329H360.435V169.764H350.522V181.329H338.957V191.572H350.522V203.137H360.435V191.572H372V181.329Z"
            fill="#5C6479"
          />
          <path
            d="M180.614 71.8864H170.728V62H162.254V71.8864H152.367V80.6429H162.254V90.5293H170.728V80.6429H180.614V71.8864Z"
            fill="#5C6479"
          />
          <path
            d="M64.259 170.521H55.8121V162.074H48.5719V170.521H40.125V178.003H48.5719V186.45H55.8121V178.003H64.259V170.521Z"
            fill="#5C6479"
          />
          <g filter="url(#filter0_d_10185_8161)">
            <path
              d="M275.017 195.666L251.674 138.667C247.512 128.534 236.655 122.743 225.979 125.095L165.723 138.124C160.656 139.21 156.313 142.648 153.78 147.171L96.4187 256.465C94.0663 260.989 95.6949 266.78 100.4 269.132L204.265 323.779C208.789 326.131 214.579 324.503 216.932 319.798L274.293 210.504C276.826 205.98 277.007 200.552 275.017 195.666ZM217.294 172.685C209.875 168.885 206.979 159.657 210.96 152.238C214.76 144.819 223.989 141.924 231.408 145.905C238.827 149.705 241.722 158.933 237.741 166.352C233.941 173.771 224.713 176.666 217.294 172.685Z"
              fill="url(#paint0_linear_10185_8161)"
            />
          </g>
          <path
            d="M224.713 158.754C271.941 134.325 290.579 97.9542 265.97 82.2115C241.361 66.4688 217.113 82.2115 210.961 127.992"
            stroke="#9CA4B5"
            strokeWidth="5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M212.384 237.611C215.579 239.38 219.833 238.158 221.602 234.963C223.37 231.768 222.148 227.513 218.953 225.745C215.758 223.976 211.504 225.198 209.735 228.393C207.84 231.817 208.961 235.716 212.384 237.611Z"
            fill="#9CA4B5"
          />
          <path
            d="M194.181 245.962L191.214 244.319C191.736 237.453 186.254 231.735 179.49 231.568C176.93 231.344 174.094 232.159 172.044 233.708L169.078 232.065C175.504 226.38 185.741 227.277 191.071 233.805C193.508 236.943 194.959 241.324 194.181 245.962Z"
            fill="#9CA4B5"
          />
          <path
            d="M166.743 212.347C169.938 214.116 174.193 212.893 175.961 209.698C177.73 206.504 176.507 202.249 173.312 200.48C170.117 198.712 165.863 199.934 164.094 203.129C162.428 206.679 163.548 210.579 166.743 212.347Z"
            fill="#9CA4B5"
          />
          <defs>
            <filter
              id="filter0_d_10185_8161"
              x="73.3516"
              y="113.574"
              width="225.017"
              height="244.272"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_10185_8161"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_10185_8161"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_10185_8161"
              x1="185.801"
              y1="119.941"
              x2="185.801"
              y2="327.005"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#5C6479" />
              <stop offset="0.9964" stopColor="#5C6479" />
            </linearGradient>
          </defs>
        </svg>
      )}
    </EmptyContainer>
  );
};
