import React, {useEffect, useState} from 'react';
import {ArrowDown1v} from 'shared/icons/ArrowDown1v';
import Close from 'shared/icons/Close';
import {
  setBookingFilterColumn,
  setBookingFilterProfessions,
  setBookingFilterWith,
  setBookingFilterWithout,
  useAppDispatch,
  useAppSelector,
} from 'shared/store';
import {Button} from 'shared/ui/Button';
import {CheckBox} from 'shared/ui/CheckBox';
import {Divider} from 'shared/ui/Divider';
import {Flex} from 'shared/ui/Flex';
import {Head} from 'shared/ui/Head';
import {IconButton} from 'shared/ui/IconButton';
import {Modal} from 'shared/ui/Modal';
import {TableViewRow} from 'shared/ui/TableViewRow';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';

const Wrapper = styled.div`
  gap: 24px;
  display: flex;
  height: calc(100% - 100px);
  flex-direction: column;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: scroll;
  max-height: min(340px, calc(100vh - 304px));
  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
`;

export const ModalFilter = ({
  visible,
  onClose,
  professions,
}: {
  visible: boolean;
  onClose: () => void;
  professions: {id?: string; name?: string}[];
}) => {
  const [showAllProfession, setShowAllProfession] = useState(false);
  const dispatch = useAppDispatch();
  const employeeWithBooking = useAppSelector(
    state => state.booking.filter.employeeWithBooking,
  );
  const employeeWithoutBooking = useAppSelector(
    state => state.booking.filter.employeeWithoutBooking,
  );
  const selectProfessions = useAppSelector(
    state => state.booking.filter.professions,
  );
  const [filterState, setFilterState] = useState({
    withBooking: employeeWithBooking,
    withoutBooking: employeeWithoutBooking,
    professions: selectProfessions,
  });

  useEffect(() => {
    if (visible) {
      setFilterState({
        withBooking: employeeWithBooking,
        withoutBooking: employeeWithoutBooking,
        professions: selectProfessions,
      });
    }
  }, [employeeWithBooking, employeeWithoutBooking, selectProfessions, visible]);

  return (
    <Modal
      backdropVariant="overlay"
      style={{
        width: 540,
        left: 'calc((100% - 540px) / 2)',
        height: 'min-content',
        maxHeight: 'calc(100vh - 124px)',
        overflow: 'hidden',
        position: 'fixed',
      }}
      visible={visible}
      onClose={onClose}>
      <Wrapper>
        <Head style={{justifyContent: 'space-between', marginBottom: 0}}>
          <Text typography="title20">Фильтры</Text>
          <IconButton size={40} onClick={() => onClose()}>
            <Close />
          </IconButton>
        </Head>
        <Body>
          <Text typography="title18">Отображение сотрудников</Text>
          <Flex direction="column" gap={8}>
            <Text typography="subHead14Regular" color="textTertiary">
              По записям:
            </Text>
            <TableViewRow
              title="Сотрудники с записями"
              pointer
              styleTitle={{pointerEvents: 'none'}}
              rightElement={() => (
                <CheckBox checked={filterState.withBooking} pointerNone />
              )}
              onClick={() => {
                setFilterState({
                  ...filterState,
                  withBooking: !filterState.withBooking,
                });
              }}
            />
            <TableViewRow
              title="Сотрудники без записей"
              style={{height: 48}}
              pointer
              rightElement={() => (
                <CheckBox checked={filterState.withoutBooking} pointerNone />
              )}
              onClick={() => {
                setFilterState({
                  ...filterState,
                  withoutBooking: !filterState.withoutBooking,
                });
              }}
            />
          </Flex>
          <Divider />
          <Flex direction="column" gap={8}>
            <Text typography="subHead14Regular" color="textTertiary">
              По профессиям:
            </Text>
            {professions
              .filter((_, index) => index < 5 || showAllProfession)
              .map(profession => (
                <TableViewRow
                  pointer
                  key={profession.id}
                  title={profession.name ?? ''}
                  onClick={() => {
                    if (filterState.professions.includes(profession.id!)) {
                      setFilterState({
                        ...filterState,
                        professions: filterState.professions.filter(
                          item => item !== profession.id,
                        ),
                      });
                    } else {
                      setFilterState({
                        ...filterState,
                        professions: [
                          ...filterState.professions,
                          profession.id!,
                        ],
                      });
                    }
                  }}
                  rightElement={() => (
                    <CheckBox
                      checked={filterState.professions.includes(profession.id!)}
                      pointerNone
                    />
                  )}
                  styleTitle={{pointerEvents: 'none'}}
                />
              ))}
          </Flex>
          {professions.length > 5 && (
            <Flex
              gap={8}
              style={{pointerEvents: 'all', cursor: 'pointer'}}
              onClick={() => setShowAllProfession(!showAllProfession)}>
              <Text style={{cursor: 'pointer'}} color="mainPrimary">
                {showAllProfession ? 'Скрыть' : 'Показать все'}
              </Text>
              <ArrowDown1v
                style={{
                  transform: `rotate(${showAllProfession ? '180deg' : '0'})`,
                  transition: 'transform 0.3s',
                }}
              />
            </Flex>
          )}
        </Body>
        <Flex alignItems="center" gap={16}>
          <Button
            style={{width: '100%'}}
            variant="outline"
            size="large"
            onClick={() => onClose()}>
            Отмена
          </Button>
          <Button
            size="large"
            style={{width: '100%'}}
            disabled={
              employeeWithBooking === filterState.withBooking &&
              employeeWithoutBooking === filterState.withoutBooking &&
              selectProfessions === filterState.professions
            }
            onClick={() => {
              dispatch(setBookingFilterWith(filterState.withBooking));
              dispatch(setBookingFilterWithout(filterState.withoutBooking));
              dispatch(setBookingFilterColumn('Автоматически'));
              dispatch(setBookingFilterProfessions(filterState.professions));
              onClose();
            }}>
            Применить
          </Button>
        </Flex>
      </Wrapper>
    </Modal>
  );
};
