import React from 'react';
import {Flex} from 'shared/ui/Flex';
import {Text} from 'shared/ui/Text';
import {Button} from 'shared/ui/Button';
import {useLocation, useNavigate} from 'react-router-dom';
import {ActionIcon} from 'shared/ui/ActionIcon';
import {ArrowLeft2v} from 'shared/icons/ArrowLeft2v';
import {Content} from 'shared/ui/Content';
import {TableViewRow} from 'shared/ui/TableViewRow';
import {Avatar} from 'shared/ui/Avatar';
import {Divider} from 'shared/ui/Divider';
import {gql} from 'shared/__generated__';
import {useMutation, useQuery} from '@apollo/client';
import {ensureError, getRating} from 'shared/lib/utils';
import {showAlert} from 'shared/ui/Alert';
import {StarStroke} from 'shared/icons/StarStroke';
import {useColors} from 'shared/lib/hooks';
import dayjs from 'dayjs';
import {Error} from 'shared/ui/Error';
import {Loading} from 'shared/ui/Loading';
import styled from 'styled-components';
import {MY_COMPANIES} from 'entities/create';
import {Seo} from 'shared/ui/Seo';

const Circle = styled.div`
  border-radius: 36px;
  width: 36px;
  height: 36px;
  background-color: ${props => props.theme.bgPrimary};
`;
const InnerView = styled.div`
  display: flex;
  flex: 1;
  margin: 0 20px;
  height: 2px;
  border: 2px dashed ${props => props.theme.bgPrimary};
`;

const INVITATIONS = gql(`
query Invitation($userId: String!, $companyId: String!) {
  invitationUser(user_id: $userId, company_id: $companyId) {
    company {
      avatar {
        id
        type
        url
      }
      name
      type {
        id
        title
      }
      city {
        id
        name
      }
      address
      rating_1_count
      rating_2_count
      rating_3_count
      rating_4_count
      rating_5_count
    }
    invite_expiration
    user {
      id
    }
    id
  }
}
`);
const ACCEPT_INVITE = gql(`
mutation AcceptInvitation($companyId: String!) {
  acceptInvitation(company_id: $companyId)
}
`);
const DECLINE_INVITE = gql(`
mutation DeclineInvitation($companyId: String!) {
  declineInvitation(company_id: $companyId)
}
`);

export function InviteCompany() {
  const navigate = useNavigate();
  const colors = useColors();
  const {user_id, company_id} = useLocation().state;
  const [acceptInvitation, {loading: loadingAccept}] = useMutation(
    ACCEPT_INVITE,
    {
      refetchQueries: [
        {
          query: MY_COMPANIES,
          variables: {
            page: 1,
            first: 10,
          },
        },
      ],
    },
  );
  const accept = async () => {
    try {
      await acceptInvitation({
        variables: {
          companyId: company_id,
        },
      });
      navigate(-1);
    } catch (e) {
      const error = ensureError(e);
      showAlert('error', error.message);
    }
  };
  const [declineInvitation, {loading: loadingDecline}] = useMutation(
    DECLINE_INVITE,
    {
      refetchQueries: [
        {
          query: MY_COMPANIES,
          variables: {
            page: 1,
            first: 10,
          },
        },
      ],
    },
  );
  const decline = async () => {
    try {
      await declineInvitation({
        variables: {
          companyId: company_id,
        },
      });
      navigate(-1);
    } catch (e) {
      const error = ensureError(e);
      showAlert('error', error.message);
    }
  };
  const {data, error, loading} = useQuery(INVITATIONS, {
    variables: {userId: user_id, companyId: company_id},
  });
  const rating = data?.invitationUser?.company;
  const inviteStatus = dayjs(data?.invitationUser?.invite_expiration);
  const sending = loadingDecline || loadingAccept;
  const company = data?.invitationUser?.company;
  if (error) {
    return <Error message={error.message} />;
  }
  if (loading) {
    return <Loading />;
  }
  return (
    <Flex direction="column" gap={32}>
      <Seo title="Приглашение" />
      <Flex alignItems="center" gap={16}>
        <ActionIcon icon={<ArrowLeft2v />} onClick={() => navigate(-1)} />
        <Text typography="title24">Приглашение</Text>
      </Flex>
      <Content>
        <TableViewRow
          leftElement={() => (
            <Avatar variant="company" size={56} url={company?.avatar?.url} />
          )}
          title={company?.name ?? '-'}
          subtitle={company?.type.title ?? '-'}
        />
      </Content>
      <Content>
        <Flex direction="column" gap={16}>
          <Flex justifyContent="space-between">
            <Text typography="text16Semibold">Адрес организации</Text>
            <Text color="textTertiary">{`${company?.city?.name}, ${company?.address} `}</Text>
          </Flex>
          <Divider />
          {getRating(rating) !== 0 && (
            <>
              <Flex justifyContent="space-between">
                <Text typography="text16Semibold">Рейтинг</Text>
                <Flex gap={4}>
                  <StarStroke
                    color={colors.goldPrimary}
                    fillColor={colors.goldPrimary}
                  />
                  <Text color="goldPrimary">
                    {getRating(rating).toString()}
                  </Text>
                </Flex>
              </Flex>
              <Divider />
            </>
          )}
          <Flex justifyContent="space-between">
            <Text typography="text16Semibold">Активен до</Text>
            <Text color="textTertiary">
              {inviteStatus.format('DD.MM.YYYY')}
            </Text>
          </Flex>
          <Content
            style={{
              borderRadius: 15,
              backgroundColor: colors.fillPrimary,
              borderWidth: 0,
            }}>
            <Flex justifyContent="space-between">
              <Text typography="title20">{company?.name}</Text>
              <Text
                color={
                  dayjs().isAfter(inviteStatus, 'minute')
                    ? 'warningPrimary'
                    : 'successPrimary'
                }>
                {dayjs().isAfter(inviteStatus, 'minute')
                  ? 'Истекший'
                  : 'Активна'}
              </Text>
            </Flex>
            <Flex alignItems="center">
              <Circle style={{marginLeft: -42}} />
              <InnerView />
              <Circle style={{marginRight: -42}} />
            </Flex>
            <Flex
              direction="column"
              gap={8}
              style={{alignSelf: 'center', alignItems: 'center'}}>
              <Text align="center">
                Приглашаем вас присоединиться к команде организации
              </Text>
              <Text
                align="center"
                typography="footNote12Regular"
                color="textTertiary">
                Приглашение действует 14 дней с момента создания
              </Text>
            </Flex>
          </Content>
        </Flex>
      </Content>
      <Flex gap={8}>
        <Button
          disabled={sending}
          loading={loadingDecline}
          style={{
            flex: 1,
            backgroundColor: colors.bgDanger,
            color: colors.dangerPrimary,
          }}
          size="large"
          variant="light"
          onClick={decline}>
          Отклонить
        </Button>
        <Button
          disabled={sending}
          loading={loadingAccept}
          style={{flex: 1}}
          size="large"
          onClick={accept}>
          Принять
        </Button>
      </Flex>
    </Flex>
  );
}
