import React from 'react';
import styled from 'styled-components';

const ProgressWrapper = styled.svg`
  transform: rotate(-90deg);
  justify-content: center;
  align-items: center;
`;

const CircleBackground = styled.circle`
  fill: none;
  stroke: ${props => props.theme.bgWaiting};
`;

const CircleProgress = styled.circle<{progress: number}>`
  fill: none;
  stroke: ${props => props.theme.mainPrimary};
  stroke-dasharray: 283;
  stroke-dashoffset: ${props => 283 - (283 * props.progress) / 100};
  transition: stroke-dashoffset 0.35s;
  stroke-linecap: round;
`;

const ProgressText = styled.text`
  fill: ${props => props.theme.textTertiary};
  font-size: 30px;
  text-anchor: middle;
  transform: rotate(90deg);
  transform-origin: 50px 50px;
`;

export const ProgressIndicator = ({
  percent,
  adjustedY,
}: {
  percent: number;
  adjustedY: number;
}) => {
  return (
    <ProgressWrapper
      id="progressIndicator"
      width="40"
      height="40"
      viewBox="0 0 100 100">
      <CircleBackground cx="50" cy="50" r="45" strokeWidth="10" />
      <CircleProgress
        cx="50"
        cy="50"
        r="45"
        strokeWidth="10"
        progress={percent}
      />
      <ProgressText x="50" y={adjustedY}>{`${percent}%`}</ProgressText>
    </ProgressWrapper>
  );
};
