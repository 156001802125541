import React from 'react';
import {Loader} from 'shared/icons/Loader';
import {Flex} from './Flex';

export function Loading() {
  return (
    <Flex flex={1} alignItems="center" justifyContent="center">
      <Loader />
    </Flex>
  );
}
