export const typography = {
  largeTitle48: {
    fontStyle: 'normal',
    fontWeight: 800,
    fontSize: 48,
    lineHeight: '64px',
    fontFamily: 'Inter',
  },
  title28: {
    fontStyle: 'normal',
    fontWeight: 800,
    fontSize: 28,
    lineHeight: '38px',
    fontFamily: 'Inter',
  },
  title24: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 24,
    lineHeight: '32px',
    fontFamily: 'Inter',
  },
  title24ExtraBold: {
    fontStyle: 'normal',
    fontWeight: 800,
    fontSize: 24,
    lineHeight: '32px',
    fontFamily: 'Inter',
  },
  title20: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 20,
    lineHeight: '28px',
    fontFamily: 'Inter',
  },
  title18: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 18,
    lineHeight: '24px',
    fontFamily: 'Inter',
  },
  text18: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 18,
    lineHeight: '28px',
    fontFamily: 'Inter',
  },
  text16Semibold: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '24px',
    fontFamily: 'Inter',
  },
  text16Regular: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '24px',
    fontFamily: 'Inter',
  },
  subHead14Medium: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
    fontFamily: 'Inter',
  },
  subHead14Regular: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '20px',
    fontFamily: 'Inter',
  },
  footNote12Medium: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '18px',
    fontFamily: 'Inter',
  },
  footNote12Regular: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '18px',
    fontFamily: 'Inter',
  },
  tab10: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 10,
    lineHeight: '12px',
    fontFamily: 'Inter',
  },
};
