import React, {useCallback, useRef} from 'react';
import {Text} from 'shared/ui/Text';
import {Navigate, useLocation, useNavigate} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {getMe, getSmsCode, login} from 'shared/lib/api';
import {ensureError} from 'shared/lib/utils';
import {CodeInput} from 'shared/ui/CodeInput';
import {setToken, setUserId, useAppDispatch} from 'shared/store';
import {Content} from 'shared/ui/Content';
import {Head} from 'shared/ui/Head';
import {showAlert} from 'shared/ui/Alert';
import {Flex} from 'shared/ui/Flex';
import {Popup} from 'shared/ui/Popup';
import {NavigateBack} from 'shared/ui/NavigateBack';
import {CloseButton} from 'shared/ui/CloseButton';
import {Seo} from 'shared/ui/Seo';
import ReCAPTCHA from 'react-google-recaptcha';

const TIMEOUT = 60;

export function ConfirmPhone() {
  const {phone, status} = useLocation().state;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState(0);
  const [sending, setSending] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [value, setValue] = useState(['', '', '', '']);
  const [infoModal, setInfoModal] = useState(false);
  const [me, setMe] = useState<string | undefined>('');
  const [redirect, setRedirect] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);

  const onCaptchaChange = async (token: string | null) => {
    if (!token) {
      return;
    }
    try {
      await getSmsCode(phone, token);
    } catch (err) {
      const error = ensureError(err);
      showAlert('error', error.message);
    }

    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }
  };
  const submit = useCallback(
    async (code: string) => {
      try {
        setLoading(true);
        setError(null);
        const token = await login(phone, code);
        dispatch(setToken(token));
        const me = await getMe();
        setMe(me?.id);
        dispatch(setUserId(me!.id));
        if (status === 'auth') {
          if (!me?.name) {
            setRedirect('/sign-up');
          } else {
            setRedirect('/my-companies');
          }
        } else if (status === 'restore') {
          setRedirect('/new-password');
        }
        setLoading(false);
      } catch (err) {
        const error = ensureError(err);
        setError(error.message);
        setLoading(false);
      }
    },
    [phone, status, dispatch],
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimer(t => t + 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  if (redirect) {
    return (
      <Navigate
        to={redirect}
        state={status === 'restore' ? {phone, code: value.join('')} : {id: me}}
      />
    );
  }
  return (
    <Content gap="0px">
      <Seo title="Код из смс" />
      <Head>
        <NavigateBack />
        <Text typography="title20">Код из смс</Text>
      </Head>
      <Flex direction="column" gap={24}>
        <Flex direction="column">
          <Text color="textTertiary">
            Мы отправили смс код на ваш номер телефона:
          </Text>
          <Text display="inline">
            {phone}{' '}
            <Text
              display="inline"
              typography="text16Regular"
              color="mainPrimary"
              onClick={() => {
                navigate(-1);
              }}>
              изменить
            </Text>
          </Text>
        </Flex>
        <Flex direction="column" gap={32}>
          <CodeInput
            error={error}
            disabled={loading}
            onChange={text => {
              const code = text.join('');
              if (code.length === 4) {
                submit(code);
              }
              setValue(text);
              setError(null);
            }}
            value={value}
          />
          {timer < TIMEOUT ? (
            <Text color="textTertiary" align="center">
              {`Вы можете повторно получить код через ${TIMEOUT - timer} сек`}
            </Text>
          ) : (
            <Text
              disabled={sending}
              onClick={async () => {
                try {
                  setSending(true);
                  setError(null);
                  recaptchaRef.current?.execute();
                  setTimer(0);
                } catch (err) {
                  const error = ensureError(err);
                  showAlert('error', error.message);
                } finally {
                  setSending(false);
                }
              }}
              align="center"
              color="mainPrimary">
              Получить код повторно
            </Text>
          )}
        </Flex>
        <Text
          color="textTertiary"
          style={{alignSelf: 'center'}}
          align="center"
          onClick={() => {
            setInfoModal(true);
          }}>
          Почему не приходит код?
        </Text>
      </Flex>

      <Popup
        style={{width: 442, alignItems: 'initial'}}
        visible={infoModal}
        onClose={() => {
          setInfoModal(false);
        }}>
        <Flex direction="column" gap={16}>
          <Flex flex={1} justifyContent="space-between" alignItems="center">
            <Text typography="title20" color="textPrimary">
              Почему не приходит код?
            </Text>
            <CloseButton
              onClose={() => {
                setInfoModal(false);
              }}
            />
          </Flex>
          <Flex direction="column" gap={16}>
            <Text>
              — Просто задерживается на пару минут — иногда такое бывает
            </Text>
            <Text>— У оператора технические проблемы</Text>
            <Text>
              — Если у оператора всё хорошо, но СМС не приходит даже с третьей
              попытки,{' '}
              <Text
                display="inline"
                color="mainPrimary"
                onClick={() => {
                  setInfoModal(false);
                  window.open('https://support.booky.co', '_blank');
                }}>
                напишите нам
              </Text>
            </Text>
          </Flex>
        </Flex>
      </Popup>
      <ReCAPTCHA
        style={{display: 'inline-block', visibility: 'hidden'}}
        ref={recaptchaRef}
        size="invisible"
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY!}
        onChange={onCaptchaChange}
        onExpired={() => {
          showAlert('error', 'Время ввода капчи истекло');
          if (recaptchaRef.current) {
            recaptchaRef.current?.reset();
          }
        }}
        onErrored={() => {
          showAlert('error', 'Ошибка ввода капчи');
          if (recaptchaRef.current) {
            recaptchaRef.current?.reset();
          }
        }}
      />
    </Content>
  );
}
