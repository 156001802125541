import React from 'react';
import {Outlet} from 'react-router-dom';
import {CreateContainer} from './ui/CreateContainer';

export function CreateRoot() {
  return (
    <CreateContainer>
      <Outlet />
    </CreateContainer>
  );
}
