import * as React from 'react';
import {useColorScheme} from 'shared/lib/hooks';
import {EmptyContainer} from 'shared/ui/EmptyContainer';

export const EmptyRecord = ({size = 400}: {size?: number}) => {
  const colorScheme = useColorScheme();
  return (
    <EmptyContainer size={size}>
      {colorScheme === 'light' ? (
        <svg
          viewBox="0 0 400 400"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M335.066 269.376C340.817 269.376 345.479 264.714 345.479 258.963C345.479 253.212 340.817 248.55 335.066 248.55C329.315 248.55 324.652 253.212 324.652 258.963C324.652 264.714 329.315 269.376 335.066 269.376Z"
            fill="#EAEEF9"
          />
          <path
            d="M315.069 298.431C318.996 298.431 322.18 295.247 322.18 291.319C322.18 287.392 318.996 284.208 315.069 284.208C311.141 284.208 307.957 287.392 307.957 291.319C307.957 295.247 311.141 298.431 315.069 298.431Z"
            fill="#EAEEF9"
          />
          <path
            d="M84.6389 124.606C88.5665 124.606 91.7504 121.422 91.7504 117.494C91.7504 113.567 88.5665 110.383 84.6389 110.383C80.7113 110.383 77.5273 113.567 77.5273 117.494C77.5273 121.422 80.7113 124.606 84.6389 124.606Z"
            fill="#EAEEF9"
          />
          <path
            d="M41.2071 261.758C48.5012 261.758 54.4142 255.844 54.4142 248.55C54.4142 241.256 48.5012 235.343 41.2071 235.343C33.913 235.343 28 241.256 28 248.55C28 255.844 33.913 261.758 41.2071 261.758Z"
            fill="#EAEEF9"
          />
          <path
            d="M201.598 328.047C272.713 328.047 330.367 270.393 330.367 199.023C330.367 127.654 272.713 70 201.598 70C130.482 70 72.8281 127.654 72.8281 199.023C72.8281 270.393 130.482 328.047 201.598 328.047Z"
            fill="#EAEEF9"
          />
          <path
            d="M276.379 89.3936C280.209 89.3936 283.45 92.3254 283.45 96.4299V304.001C283.45 307.813 280.504 311.038 276.379 311.038H122.876C119.046 311.038 115.805 308.106 115.805 304.001V96.4299C115.805 92.6185 118.751 89.3936 122.876 89.3936H276.379Z"
            fill="#CED7E2"
          />
          <path
            d="M283.452 111.089V303.708C283.452 307.519 280.506 310.744 276.381 310.744H136.136L123.762 298.431L263.122 107.571L275.202 103.173L283.452 111.089Z"
            fill="#BCC4CF"
          />
          <path
            d="M275.791 105.518C275.791 104.346 274.908 103.173 273.434 103.173H126.119C124.94 103.173 123.762 104.052 123.762 105.518V296.085C123.762 297.258 124.646 298.431 126.119 298.431H273.14C274.318 298.431 275.497 297.551 275.497 296.085L275.791 105.518Z"
            fill="#D9DFEE"
          />
          <path
            d="M275.79 105.518C275.79 104.346 274.906 103.173 273.432 103.173H126.117C124.938 103.173 123.76 104.052 123.76 105.518C125.528 181.452 126.117 267.354 117.867 289.635L258.996 287.583C267.54 248.883 274.022 179.4 275.79 105.518Z"
            fill="#EFF3FB"
          />
          <g filter="url(#filter0_d_10905_10073)">
            <path
              d="M275.497 103.466C275.497 103.466 275.202 114.9 271.667 187.901C271.667 188.488 271.667 188.781 271.667 189.367C267.247 260.903 203.607 275.855 195.947 276.735C192.411 277.028 185.634 277.614 173.555 277.614C158.528 278.201 135.547 278.494 99.6021 278.787C98.4236 278.787 97.5397 277.614 98.129 276.442C122.878 226.894 123.173 103.759 123.173 103.759L275.497 103.466Z"
              fill="white"
            />
          </g>
          <path
            d="M271.37 189.074C267.245 260.61 203.31 275.562 195.65 276.442C192.114 276.735 185.338 277.321 173.258 277.321C205.078 262.662 222.167 236.276 221.872 215.754C237.193 216.926 263.71 215.754 271.37 189.074Z"
            fill="#EAEEF9"
          />
          <path
            d="M235.426 89.979C235.426 89.6858 235.426 89.6858 235.426 89.979L233.953 88.5131C233.953 88.5131 233.069 88.8063 232.774 89.3926H209.793C209.793 88.8063 209.793 88.5131 209.793 87.9267C209.793 83.2359 205.668 79.1313 200.954 79.1313C196.24 79.1313 192.115 83.2359 192.115 87.9267C192.115 88.5131 192.115 88.8063 192.41 89.0995H168.545C167.366 89.0995 166.188 89.979 166.188 91.7381V99.3608C166.188 105.224 170.312 108.743 175.91 108.743H224.819C230.712 108.743 235.72 105.224 235.72 99.3608V91.4449C236.015 90.8585 235.72 90.2722 235.426 89.979Z"
            fill="#1C3754"
          />
          <path
            d="M234.542 89.9798V97.6025C234.542 98.1888 234.542 98.482 234.542 99.0684C233.659 104.052 229.534 108.157 224.23 108.157H175.027C169.724 108.157 165.599 104.052 164.715 99.0684C164.715 98.482 164.715 98.1888 164.715 97.6025V89.9798C164.715 88.8071 165.599 87.3412 167.072 87.3412H191.232C191.232 86.7548 191.232 86.4616 191.232 86.1684C191.232 81.4776 195.356 77.373 200.071 77.373C204.785 77.373 208.91 81.4776 208.91 86.1684C208.91 86.7548 208.91 87.048 208.91 87.3412H233.069C233.659 87.6343 234.542 88.5139 234.542 89.9798Z"
            fill="url(#paint0_linear_10905_10073)"
          />
          <path
            d="M199.48 90.2715C201.837 90.2715 203.605 88.5125 203.605 86.167C203.605 83.8216 201.837 82.0625 199.48 82.0625C197.123 82.0625 195.355 83.8216 195.355 86.167C195.355 88.5125 197.418 90.2715 199.48 90.2715Z"
            fill="#EAEEF9"
          />
          <path
            d="M234.248 99.0684C233.364 104.052 229.239 108.157 223.936 108.157H175.027C169.724 108.157 165.599 104.052 164.715 99.0684H234.248Z"
            fill="#9AA1B2"
          />
          <path
            d="M160 188.194C164.714 188.194 168.25 184.383 168.25 179.985C168.25 175.295 164.419 171.776 160 171.776C155.286 171.776 151.75 175.588 151.75 179.985C151.456 184.383 155.286 188.194 160 188.194Z"
            fill="#989FB0"
          />
          <path
            d="M225.406 187.9C230.12 187.9 233.656 184.089 233.656 179.691C233.656 175.001 229.825 171.482 225.406 171.482C220.986 171.482 217.156 175.294 217.156 179.691C217.156 184.089 220.692 187.9 225.406 187.9Z"
            fill="#989FB0"
          />
          <path
            d="M200.66 196.99H184.75V200.802H200.66V196.99Z"
            fill="#989FB0"
          />
          <path
            d="M359.464 104.345C365.946 134.835 365.062 167.085 356.223 196.99C354.16 203.146 352.098 209.89 347.679 214.58C341.491 221.91 330.295 225.135 321.162 222.789C311.734 220.444 304.073 212.235 302.305 202.267C300.832 196.11 302.895 188.781 308.198 184.969C313.796 181.451 321.751 182.331 326.465 186.728C331.768 191.126 333.831 197.869 333.536 204.319C333.242 210.769 330.885 217.219 327.644 222.789C317.921 240.967 300.243 255.332 279.913 261.782C264.887 266.473 248.682 266.473 233.656 262.369"
            stroke="#C9D4E2"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeDasharray="4 4"
          />
          <path
            d="M371.84 95.5502C370.956 98.7752 367.42 99.9479 363.885 97.8957C360.055 96.1366 357.403 94.6707 357.992 91.7389C358.876 88.8071 362.412 88.5139 366.536 88.2207C371.545 87.6344 372.429 92.3253 371.84 95.5502Z"
            fill="#DAE2EB"
          />
          <path
            d="M343.261 98.4822C344.734 101.121 348.859 102.88 351.805 100.241C355.046 97.3094 357.698 95.2572 356.224 92.3254C354.751 89.6868 352.394 90.5663 347.386 91.1527C343.261 92.0322 341.493 95.5504 343.261 98.4822Z"
            fill="#DAE2EB"
          />
          <path
            d="M356.223 87.634C358.285 87.3408 360.348 88.5135 360.937 90.2726C361.232 90.859 361.526 91.7385 361.526 92.3249C362.116 96.4294 360.643 99.9476 358.285 100.241C355.634 100.827 352.982 97.8953 352.688 94.084C352.688 92.9112 352.688 92.3249 352.688 91.4453C352.982 89.3931 354.161 87.9272 356.223 87.634C356.518 87.634 356.223 87.634 356.223 87.634Z"
            fill="#989FB0"
          />
          <defs>
            <filter
              id="filter0_d_10905_10073"
              x="75.9414"
              y="92.4656"
              width="221.556"
              height="219.322"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_10905_10073"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_10905_10073"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_10905_10073"
              x1="164.799"
              y1="92.7688"
              x2="234.61"
              y2="92.7688"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#B0BACC" />
              <stop offset="1" stopColor="#969EAE" />
            </linearGradient>
          </defs>
        </svg>
      ) : (
        <svg
          viewBox="0 0 400 400"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M335.066 269.376C340.817 269.376 345.479 264.714 345.479 258.963C345.479 253.212 340.817 248.55 335.066 248.55C329.315 248.55 324.652 253.212 324.652 258.963C324.652 264.714 329.315 269.376 335.066 269.376Z"
            fill="#2A2E37"
          />
          <path
            d="M315.069 298.43C318.996 298.43 322.18 295.246 322.18 291.319C322.18 287.391 318.996 284.207 315.069 284.207C311.141 284.207 307.957 287.391 307.957 291.319C307.957 295.246 311.141 298.43 315.069 298.43Z"
            fill="#2A2E37"
          />
          <path
            d="M84.6389 124.606C88.5665 124.606 91.7504 121.422 91.7504 117.494C91.7504 113.567 88.5665 110.383 84.6389 110.383C80.7113 110.383 77.5273 113.567 77.5273 117.494C77.5273 121.422 80.7113 124.606 84.6389 124.606Z"
            fill="#2A2E37"
          />
          <path
            d="M41.2071 261.758C48.5012 261.758 54.4142 255.844 54.4142 248.55C54.4142 241.256 48.5012 235.343 41.2071 235.343C33.913 235.343 28 241.256 28 248.55C28 255.844 33.913 261.758 41.2071 261.758Z"
            fill="#2A2E37"
          />
          <path
            d="M201.598 328.047C272.713 328.047 330.367 270.393 330.367 199.023C330.367 127.654 272.713 70 201.598 70C130.482 70 72.8281 127.654 72.8281 199.023C72.8281 270.393 130.482 328.047 201.598 328.047Z"
            fill="#2A2E37"
          />
          <g filter="url(#filter0_d_10905_10075)">
            <path
              d="M276.379 89.3926C280.209 89.3926 283.45 92.3244 283.45 96.4289V304C283.45 307.812 280.504 311.037 276.379 311.037H122.876C119.046 311.037 115.805 308.105 115.805 304V96.4289C115.805 92.6176 118.751 89.3926 122.876 89.3926H276.379Z"
              fill="#414758"
            />
          </g>
          <path
            opacity="0.2"
            d="M283.452 111.088V303.707C283.452 307.518 280.506 310.743 276.381 310.743H136.136L123.762 298.43L263.122 107.57L275.202 103.172L283.452 111.088Z"
            fill="black"
          />
          <g filter="url(#filter1_d_10905_10075)">
            <path
              d="M275.791 105.517C275.791 104.345 274.908 103.172 273.434 103.172H126.119C124.94 103.172 123.762 104.051 123.762 105.517V296.084C123.762 297.257 124.646 298.43 126.119 298.43H273.14C274.318 298.43 275.497 297.55 275.497 296.084L275.791 105.517Z"
              fill="url(#paint0_linear_10905_10075)"
            />
          </g>
          <g filter="url(#filter2_d_10905_10075)">
            <path
              d="M275.79 105.518C275.79 104.346 274.906 103.173 273.432 103.173H126.117C124.938 103.173 123.76 104.052 123.76 105.518C125.528 181.452 126.117 267.354 117.867 289.635L258.996 287.583C267.54 248.883 274.022 179.4 275.79 105.518Z"
              fill="url(#paint1_linear_10905_10075)"
            />
          </g>
          <g filter="url(#filter3_d_10905_10075)">
            <path
              d="M275.497 103.466C275.497 103.466 275.202 114.9 271.667 187.902C271.667 188.488 271.667 188.781 271.667 189.368C267.247 260.903 203.607 275.856 195.947 276.735C192.411 277.028 185.634 277.615 173.555 277.615C158.528 278.201 135.547 278.494 99.6021 278.787C98.4236 278.787 97.5397 277.615 98.129 276.442C122.878 226.895 123.173 103.759 123.173 103.759L275.497 103.466Z"
              fill="url(#paint2_linear_10905_10075)"
            />
          </g>
          <path
            d="M271.37 189.074C267.245 260.61 203.31 275.562 195.65 276.442C192.114 276.735 185.338 277.321 173.258 277.321C205.078 262.662 222.167 236.276 221.872 215.754C237.193 216.926 263.71 215.754 271.37 189.074Z"
            fill="#3E4455"
          />
          <path
            d="M235.426 89.9795C235.426 89.6863 235.426 89.6863 235.426 89.9795L233.953 88.5136C233.953 88.5136 233.069 88.8068 232.774 89.3931H209.793C209.793 88.8068 209.793 88.5136 209.793 87.9272C209.793 83.2364 205.668 79.1318 200.954 79.1318C196.24 79.1318 192.115 83.2364 192.115 87.9272C192.115 88.5136 192.115 88.8068 192.41 89.1H168.545C167.366 89.1 166.188 89.9795 166.188 91.7386V99.3613C166.188 105.225 170.312 108.743 175.91 108.743H224.819C230.712 108.743 235.72 105.225 235.72 99.3613V91.4454C236.015 90.859 235.72 90.2727 235.426 89.9795Z"
            fill="#1C3754"
          />
          <path
            d="M234.542 89.9798V97.6025C234.542 98.1888 234.542 98.482 234.542 99.0684C233.659 104.052 229.534 108.157 224.23 108.157H175.027C169.724 108.157 165.599 104.052 164.715 99.0684C164.715 98.482 164.715 98.1888 164.715 97.6025V89.9798C164.715 88.8071 165.599 87.3412 167.072 87.3412H191.232C191.232 86.7548 191.232 86.4616 191.232 86.1684C191.232 81.4776 195.356 77.373 200.071 77.373C204.785 77.373 208.91 81.4776 208.91 86.1684C208.91 86.7548 208.91 87.048 208.91 87.3412H233.069C233.659 87.6343 234.542 88.5139 234.542 89.9798Z"
            fill="url(#paint3_linear_10905_10075)"
          />
          <path
            d="M199.48 90.2725C201.837 90.2725 203.605 88.5134 203.605 86.168C203.605 83.8226 201.837 82.0635 199.48 82.0635C197.123 82.0635 195.355 83.8226 195.355 86.168C195.355 88.5134 197.418 90.2725 199.48 90.2725Z"
            fill="#2A2E37"
          />
          <path
            d="M234.248 99.0684C233.364 104.052 229.239 108.157 223.936 108.157H175.027C169.724 108.157 165.599 104.052 164.715 99.0684H234.248Z"
            fill="#9AA1B2"
          />
          <path
            d="M160 188.194C164.714 188.194 168.25 184.383 168.25 179.985C168.25 175.295 164.419 171.776 160 171.776C155.286 171.776 151.75 175.588 151.75 179.985C151.456 184.383 155.286 188.194 160 188.194Z"
            fill="#989FB0"
          />
          <path
            d="M225.406 187.901C230.12 187.901 233.656 184.09 233.656 179.692C233.656 175.002 229.825 171.483 225.406 171.483C220.986 171.483 217.156 175.295 217.156 179.692C217.156 184.09 220.692 187.901 225.406 187.901Z"
            fill="#989FB0"
          />
          <path
            d="M200.66 196.989H184.75V200.801H200.66V196.989Z"
            fill="#989FB0"
          />
          <path
            d="M358.995 103.766C365.477 134.256 364.593 166.506 355.754 196.41C353.692 202.567 351.629 209.31 347.21 214.001C341.023 221.331 329.827 224.556 320.693 222.21C311.265 219.865 303.604 211.656 301.837 201.688C300.363 195.531 302.426 188.201 307.729 184.39C313.327 180.872 321.282 181.751 325.996 186.149C331.3 190.547 333.362 197.29 333.068 203.74C332.773 210.19 330.416 216.64 327.175 222.21C317.452 240.387 299.774 254.753 279.445 261.203C264.418 265.894 248.214 265.894 233.188 261.79"
            stroke="#667089"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeDasharray="4 4"
          />
          <path
            d="M371.84 95.5495C370.956 98.7745 367.42 99.9472 363.885 97.8949C360.055 96.1359 357.403 94.67 357.992 91.7382C358.876 88.8064 362.412 88.5132 366.536 88.22C371.545 87.6336 372.429 92.3245 371.84 95.5495Z"
            fill="#AFB7C9"
          />
          <path
            d="M343.261 98.4814C344.734 101.12 348.859 102.879 351.805 100.241C355.046 97.3087 357.698 95.2564 356.224 92.3246C354.751 89.686 352.394 90.5656 347.386 91.1519C343.261 92.0315 341.493 95.5496 343.261 98.4814Z"
            fill="#AFB7C9"
          />
          <path
            d="M356.223 87.6338C358.285 87.3406 360.348 88.5133 360.937 90.2724C361.232 90.8587 361.526 91.7383 361.526 92.3246C362.116 96.4292 360.643 99.9473 358.285 100.241C355.634 100.827 352.982 97.8951 352.688 94.0837C352.688 92.911 352.688 92.3246 352.688 91.4451C352.982 89.3928 354.161 87.9269 356.223 87.6338C356.518 87.6338 356.223 87.6338 356.223 87.6338Z"
            fill="#474E61"
          />
          <defs>
            <filter
              id="filter0_d_10905_10075"
              x="93.8047"
              y="78.3926"
              width="211.646"
              height="265.644"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_10905_10075"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_10905_10075"
                result="shape"
              />
            </filter>
            <filter
              id="filter1_d_10905_10075"
              x="101.762"
              y="92.1719"
              width="196.029"
              height="239.258"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_10905_10075"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_10905_10075"
                result="shape"
              />
            </filter>
            <filter
              id="filter2_d_10905_10075"
              x="95.8672"
              y="92.1729"
              width="201.922"
              height="230.462"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_10905_10075"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_10905_10075"
                result="shape"
              />
            </filter>
            <filter
              id="filter3_d_10905_10075"
              x="75.9414"
              y="92.4658"
              width="221.556"
              height="219.322"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_10905_10075"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_10905_10075"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_10905_10075"
              x1="199.727"
              y1="98.6554"
              x2="199.727"
              y2="300.535"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#5C6479" />
              <stop offset="0.9964" stopColor="#5C6479" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_10905_10075"
              x1="196.777"
              y1="98.8598"
              x2="196.777"
              y2="291.645"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#5C6479" />
              <stop offset="0.9964" stopColor="#5C6479" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_10905_10075"
              x1="186.661"
              y1="99.4105"
              x2="186.661"
              y2="280.677"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#5C6479" />
              <stop offset="0.9964" stopColor="#5C6479" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_10905_10075"
              x1="164.799"
              y1="92.7688"
              x2="234.61"
              y2="92.7688"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#B0BACC" />
              <stop offset="1" stopColor="#969EAE" />
            </linearGradient>
          </defs>
        </svg>
      )}
    </EmptyContainer>
  );
};
