import React, {useState} from 'react';
import {Outlet, useMatch, useNavigate, useParams} from 'react-router-dom';
import {Text} from 'shared/ui/Text';
import {Avatar} from 'shared/ui/Avatar';
import User from 'shared/icons/User';
import {ProfileContainer} from './ui/ProfileContainer';
import {Head} from 'shared/ui/Head';
import {ActionIcon} from 'shared/ui/ActionIcon';
import {Menu} from 'shared/ui/Menu';
import {MenuAction} from 'shared/ui/MenuAction';
import styled from 'styled-components';
import {ApolloError, useMutation, useQuery} from '@apollo/client';
import {gql} from 'shared/__generated__';
import {device} from 'shared/device';
import {Trash} from 'shared/icons/Trash';
import {useColors, useEmployeePermission} from 'shared/lib/hooks';
import {Pencil} from 'shared/icons/Pencil';
import {getName, EmployeeRole} from 'shared/lib/utils';
import {Calendar} from 'shared/icons/Calendar';
import {Palette} from 'shared/icons/Palette';
import {Clipboard} from 'shared/icons/Clipboard';
import {Button} from 'shared/ui/Button';
import {Flex} from 'shared/ui/Flex';
import {ArrowLeft2v} from 'shared/icons/ArrowLeft2v';
import {Popup} from 'shared/ui/Popup';
import {Grow} from 'shared/ui/Grow';
import {showAlert} from 'shared/ui/Alert';
import {DeleteSchedule} from 'entities/schedule';
import {useAppSelector} from 'shared/store';
import {DELETE_EMPLOYEE} from 'entities/employees';
import {CloseButton} from 'shared/ui/CloseButton';
import {Secure} from 'shared/icons/Secure';
import {Banknote} from 'shared/icons/Banknote';

const EMPLOYEE = gql(`
  query Employee($employeeId: String!, $companyId: String!) {
    employee(id: $employeeId) {
      id
      surname
      name
      role
      enabled
      avatar {
        id
        url
        url_256
      }
      professions {
        id
        name
      }
      user {
        id
        is_online
      }
    }
    ...Permissions
  }
`);

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 72px;
  grid-column-start: 1;
  grid-column-end: 4;
  @media (${device.mobile}) {
    margin-top: 56px;
    flex-direction: row;
    justify-content: space-between;
  }
`;
const HeadRight = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  gap: 8px;
`;

export const EmployeeRoot = () => {
  const navigate = useNavigate();
  const color = useColors();
  const params = useParams();
  const employeeId = params.id!;
  const companyId = useAppSelector(state => state.company.data!.id);
  const username = useAppSelector(state => state.company.data?.username);
  const match = useMatch(`/${username}/employee/${employeeId}/schedule`);
  const {data, loading} = useQuery(EMPLOYEE, {
    variables: {
      employeeId,
      companyId,
    },
  });
  const {
    edit_employees_permission,
    work_schedule_info_permission,
    edit_work_schedule_permission,
    salaries_permission,
  } = useEmployeePermission(data);
  const employee = data?.employee;
  const [deleteEmployee, {loading: deleteLoading}] = useMutation(
    DELETE_EMPLOYEE,
    {
      variables: {deleteEmployeeId: employeeId},
      refetchQueries: ['CompanyEmployees'],
    },
  );
  const [visible, setVisible] = useState(false);
  const [visibleSchedule, setVisibleSchedule] = useState(false);
  const onClose = () => setVisible(false);
  const professions = employee?.professions
    .map(profession => profession.name)
    .join(', ');

  return (
    <ProfileContainer>
      {employee && (
        <DeleteSchedule
          employeeId={employeeId}
          employeeName={getName(employee?.name, employee?.surname, 'employee')}
          employeeUrl={employee.avatar?.url}
          employeeProfessions={professions}
          visible={visibleSchedule}
          onClose={() => setVisibleSchedule(false)}
        />
      )}
      <Popup {...{visible, onClose}}>
        <Grow style={{gap: 16}}>
          <Flex
            style={{width: 440}}
            justifyContent="space-between"
            alignItems="center">
            <Text typography="title20">Удалить сотрудника</Text>
            <CloseButton onClose={onClose} />
          </Flex>
          <Text>Вы уверены, что хотите удалить сотрудника?</Text>
          <Flex justifyContent="center" gap={8} flex={1}>
            <Button
              size="large"
              style={{borderColor: color.mainPrimary, flex: 1}}
              variant="subtitle"
              onClick={onClose}>
              <Text
                style={{cursor: 'pointer'}}
                typography="text16Semibold"
                color="mainPrimary">
                Отмена
              </Text>
            </Button>
            <Button
              size="large"
              style={{borderColor: color.dangerPrimary, flex: 1}}
              loading={deleteLoading}
              disabled={deleteLoading}
              variant="subtitle"
              onClick={() => {
                deleteEmployee()
                  .then(() => {
                    navigate(`/${username}/employees`);
                  })
                  .catch((e: ApolloError) => showAlert('error', e.message));
              }}>
              <Text
                style={{cursor: 'pointer'}}
                typography="text16Semibold"
                color="dangerPrimary">
                Удалить
              </Text>
            </Button>
          </Flex>
        </Grow>
      </Popup>
      <Nav>
        <Menu
          skeletonItemNumber={6}
          loading={loading}
          avatar={
            <Avatar
              size={64}
              activeSize={16}
              active={employee?.user?.is_online}
              url={employee?.avatar?.url_256 ?? employee?.avatar?.url}
            />
          }
          description={EmployeeRole[employee?.role ?? '']}
          title={getName(employee?.name, employee?.surname, 'employee')}
          data={[
            {
              icon: <User />,
              label: 'О сотруднике',
              to: `/${username}/employee/${employeeId}`,
            },
            {
              icon: <Calendar />,
              label: 'График работ',
              to: `/${username}/employee/${employeeId}/schedule`,
            },
            {
              icon: <Clipboard />,
              label: 'Записи',
              to: `/${username}/employee/${employeeId}/bookings`,
            },
            {
              icon: <Palette />,
              label: 'Услуги',
              to: `/${username}/employee/${employeeId}/services`,
            },
            {
              icon: <Secure />,
              label: 'Права доступа',
              to: `/${username}/employee/${employeeId}/permissions`,
            },
            {
              icon: <Banknote />,
              label: 'Расчет зарплаты',
              to: `/${username}/employee/${employeeId}/salary-rule`,
            },
          ]

            .filter((_, index) => index !== 1 || work_schedule_info_permission)
            .filter(item =>
              item.label === 'Расчет зарплаты'
                ? salaries_permission &&
                  process.env.REACT_APP_DOMAIN?.includes('dev')
                : true,
            )}
        />
        {(loading || edit_employees_permission) && (
          <MenuAction
            loading={loading}
            data={[
              {
                icon: <Pencil />,
                label: 'Редактировать',
                onClick: () => {
                  navigate(`/${username}/edit-employee/${employeeId}`);
                },
              },
              {
                icon: (
                  <div style={{height: 20}}>
                    <Trash size={20} color={color.dangerPrimary} />
                  </div>
                ),
                label: 'Удалить сотрудника',
                onClick: () => {
                  setVisible(true);
                },
              },
            ]}
          />
        )}
      </Nav>
      <Flex
        style={{
          gridColumnStart: 4,
          gridColumnEnd: 12,
        }}>
        <Flex flex={1} direction="column">
          <Flex>
            <Head>
              <ActionIcon icon={<ArrowLeft2v />} onClick={() => navigate(-1)} />
              <Text typography="title24">Профиль сотрудника</Text>
            </Head>
            {match && edit_work_schedule_permission && (
              <HeadRight>
                <Button
                  variant="light"
                  size="medium"
                  typography="subHead14Medium"
                  onClick={() => {
                    setVisibleSchedule(true);
                  }}>
                  Удалить расписание
                </Button>
                <Button
                  size="medium"
                  typography="subHead14Medium"
                  onClick={() =>
                    navigate(`/${username}/create-schedule`, {
                      state: employeeId,
                    })
                  }>
                  Новый график
                </Button>
              </HeadRight>
            )}
          </Flex>
          <Outlet />
        </Flex>
      </Flex>
    </ProfileContainer>
  );
};
