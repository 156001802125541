import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Button} from 'shared/ui/Button';
import {Text} from 'shared/ui/Text';
import {Grow} from 'shared/ui/Grow';
import styled from 'styled-components';
import {useAppSelector} from 'shared/store';
import {EmptyList2} from 'shared/illustration/EmptyList2';

const AddButton = styled(Button)`
  margin-top: 24px;
  align-self: center;
`;

export const ServiceEmptyState = () => {
  const navigate = useNavigate();
  const username = useAppSelector(state => state.company.data?.username);
  return (
    <Grow>
      <EmptyList2 size={300} />
      <Text
        typography="text18"
        color="textTertiary"
        style={{
          textAlign: 'center',
          alignSelf: 'center',
          maxWidth: 500,
          marginTop: 16,
        }}>
        У вас пока нет услуг. Добавьте услуги <br />и начните работу
      </Text>
      <AddButton
        size="large"
        onClick={() => navigate(`/${username}/add-service`)}>
        Добавить услугу
      </AddButton>
    </Grow>
  );
};
