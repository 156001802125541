import React, {useState} from 'react';
import {Grow} from 'shared/ui/Grow';
import styled from 'styled-components';
import {ServicesHeader} from './ui/Header';
import {ServicesSetting} from './ui/Setting';
import {NetworkStatus, useMutation, useQuery} from '@apollo/client';
import {gql} from 'shared/__generated__';
import {useAppSelector} from 'shared/store';
import {
  Category,
  Company,
  Employee,
  QueryCompanyServiceSearchOrderByColumn,
  Service,
  SortOrder,
  Subcategory,
} from 'shared/__generated__/graphql';
import {ensureError} from 'shared/lib/utils';
import {ServiceEmptyState} from './ui/EmptyState';
import {ServiceSkeleton} from './ui/Skeleton';
import {Text} from 'shared/ui/Text';
import {showAlert} from 'shared/ui/Alert';
import {SERVICES} from 'entities/services';
import {TUTORIALCHECK} from 'entities/tutorial';
import {DeleteSelection} from 'entities/loyalty';
import {Popup} from 'shared/ui/Popup';
import {Button} from 'shared/ui/Button';
import {useColors, useEmployeePermission} from 'shared/lib/hooks';
import {CloseButton} from 'shared/ui/CloseButton';
import {Seo} from 'shared/ui/Seo';
import {BetaServiceTable} from './ui/BetaServiceTable';
import InfiniteScroll from 'react-infinite-scroll-component';
import {Flex} from 'shared/ui/Flex';

const PopupWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  width: 392px;
  gap: 8px;
`;
type TypeService = Pick<
  Service,
  | 'id'
  | 'name'
  | 'description'
  | 'type'
  | 'max_price'
  | 'min_price'
  | 'price'
  | 'duration'
  | 'breakout_time'
> & {
  category: Pick<Category, 'id' | 'title'> & {
    subcategories?: Pick<Subcategory, 'id' | 'title'>[];
  };
  subcategory?:
    | (Pick<Subcategory, 'id' | 'title'> & {
        category?: Pick<Category, 'id' | 'title'>;
      })
    | null;
  employees?: Pick<Employee, 'id' | 'name' | 'surname'>[];
  company?: Pick<Company, 'id'> & {
    categories?: Pick<Category, 'id' | 'title'>[];
    subcategories?: Pick<Subcategory, 'id' | 'title'>[];
  };
};

const DELETE_SERVICES = gql(`
  mutation DeleteCompanyServices($ids: [String!]!) {
    deleteCompanyServices(ids: $ids) {
      id
    }
  }
`);
const SERVICES_POSITIONS = gql(`
mutation UpdatePositionServices($input: UpdateServicePositions!) {
  updateServicePositions(input: $input) {
    id
  }
}
`);

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;
type OptionsType = {
  page: number;
  first: number;
  total?: number;
  count?: number;
};
export function Services() {
  const colors = useColors();
  const companyId = useAppSelector(state => state.company.data!.id);
  const [visible, setVisible] = useState(false);
  const [selectedData, setSelectedData] = useState<(TypeService | undefined)[]>(
    [],
  );
  const [categoryId, setCategotyId] = useState<string | null>();
  const [subCategoryId, setSubCategotyId] = useState<string | null>();
  const handleCategoryChange = (categoryId: string | undefined) => {
    setCategotyId(categoryId);
    setSubCategotyId(undefined);
  };
  const handleSubCategoryChange = (subCategoryId: string | undefined) => {
    setSubCategotyId(subCategoryId);
  };
  const handleSelectionChange = (selected: TypeService[]) => {
    setSelectedData(selected);
  };
  const [options, setOptions] = useState<OptionsType>({
    page: 1,
    first: 10,
    total: 0,
    count: 0,
  });
  const [search, setSearch] = useState('');
  const {data, networkStatus, fetchMore, loading} = useQuery(SERVICES, {
    notifyOnNetworkStatusChange: true,
    variables: {
      companyId,
      first: options.first,
      page: options.page,
      query: search,
      categoryId: categoryId,
      subcategoryId: subCategoryId,
      orderBy: [
        {
          order: SortOrder.Asc,
          column: QueryCompanyServiceSearchOrderByColumn.Order,
        },
      ],
    },
    onCompleted: res =>
      setOptions(prev => ({
        ...prev,
        total: res.companyServiceSearch?.paginatorInfo.total,
        count: res.companyServiceSearch?.paginatorInfo.count,
      })),
  });
  const {edit_company_services_permission} = useEmployeePermission(data);
  const services = data?.companyServiceSearch?.data ?? [];

  const servicesLenght = data?.companyServiceSearch?.data.length ?? 0;
  const fetchMoreData = () => {
    const currentPage =
      data?.companyServiceSearch?.paginatorInfo.currentPage || 1;
    if (
      data?.companyServiceSearch?.paginatorInfo.hasMorePages &&
      data &&
      !loading
    ) {
      fetchMore({
        variables: {
          companyId,
          page: currentPage + 1,
          first: 10,
        },
        updateQuery: (prev, {fetchMoreResult}) => {
          if (!fetchMoreResult) return prev;
          const prevServiceData = prev.companyServiceSearch?.data || [];
          const newServiceData =
            fetchMoreResult.companyServiceSearch?.data || [];
          return Object.assign({}, prev, {
            companyServiceSearch: {
              ...fetchMoreResult.companyServiceSearch,
              data: [...prevServiceData, ...newServiceData],
            },
          });
        },
      });
    }
  };
  const [deleteService, {loading: deleteLoading}] = useMutation(
    DELETE_SERVICES,
    {
      refetchQueries: [
        'CompanyServiceSearchServices',
        {
          query: TUTORIALCHECK,
          variables: {
            companyId,
          },
        },
      ],
    },
  );
  const serviceDelete = async () => {
    try {
      await deleteService({
        variables: {
          ids: selectedData.map(item => item!.id),
        },
      });
      showAlert('success', 'Услуга успешно удалена');
      setVisible(false);
      setSelectedData([]);
    } catch (e) {
      const error = ensureError(e);
      showAlert('error', error.message);
    }
  };
  const [updatePosition] = useMutation(SERVICES_POSITIONS);
  return (
    <AppContainer>
      <Seo title="Услуги" />
      <Grow style={{padding: 24, gap: 24}}>
        <ServicesHeader
          total={data?.companyServiceSearch?.paginatorInfo.total}
        />
        <ServicesSetting
          editPermission={edit_company_services_permission}
          search={search}
          onChangeText={setSearch}
          options={options}
          onCategoryChange={handleCategoryChange}
          onSubCategoryChange={handleSubCategoryChange}
        />
        {networkStatus === NetworkStatus.loading ||
        networkStatus === NetworkStatus.setVariables ? (
          <ServiceSkeleton />
        ) : services?.length ? (
          <InfiniteScroll
            dataLength={servicesLenght ?? 0}
            next={fetchMoreData}
            hasMore={
              data?.companyServiceSearch?.paginatorInfo.hasMorePages || false
            }
            loader={
              loading && (
                <Flex justifyContent="center" alignItems="center">
                  <Text typography="text16Regular">Загрузка...</Text>
                </Flex>
              )
            }>
            <BetaServiceTable
              permission={edit_company_services_permission}
              dragDisabled={search !== '' || categoryId !== undefined}
              tableData={services}
              onSelectionChange={handleSelectionChange}
              onDragEnd={async services => {
                try {
                  await updatePosition({
                    variables: {
                      input: {
                        services: services.map((item, index) => ({
                          order: index + 1,
                          service_id: item.id,
                        })),
                      },
                    },
                  });
                } catch (e) {
                  if (e instanceof Error) {
                    showAlert('error', e.message);
                  }
                }
              }}
            />
          </InfiniteScroll>
        ) : (
          <ServiceEmptyState />
        )}
      </Grow>
      <Popup
        visible={visible}
        onClose={() => {
          setVisible(false);
        }}>
        <PopupWrapper>
          <Text
            style={{
              display: 'flex',
              flex: 1,
            }}
            typography="title20"
            color="textPrimary">
            {selectedData.length > 1 ? 'Удалить услуги' : 'Удалить услугу'}
          </Text>
          <CloseButton
            onClose={() => {
              setVisible(false);
            }}
          />
        </PopupWrapper>
        <PopupWrapper>
          <Text typography="text16Regular" color="textPrimary">
            {selectedData.length > 1
              ? 'Вы уверены, что хотите удалить эти услуги?'
              : 'Вы уверены, что хотите удалить эту услугу?'}
          </Text>
        </PopupWrapper>
        <PopupWrapper>
          <Button
            size="large"
            variant="outline"
            style={{flex: 1, borderColor: colors.mainPrimary}}
            onClick={() => {
              setVisible(false);
            }}>
            Отмена
          </Button>
          <Button
            size="large"
            variant="outline"
            loading={deleteLoading}
            style={{flex: 1, borderColor: colors.dangerPrimary}}
            onClick={() => {
              serviceDelete();
            }}>
            <Text color="dangerPrimary">Удалить</Text>
          </Button>
        </PopupWrapper>
      </Popup>
      {selectedData.length > 0 && (
        <DeleteSelection
          selectedLength={selectedData.length}
          length={services?.length ?? 0}
          loading={deleteLoading}
          onDelete={() => {
            setVisible(true);
          }}
        />
      )}
    </AppContainer>
  );
}
