import {useMutation} from '@apollo/client';
import {GET_CERTIFICATE_TEMPLATES} from 'entities/loyalty';
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {gql} from 'shared/__generated__';
import {useAppSelector} from 'shared/store';
import {showAlert} from 'shared/ui/Alert';
import {Button} from 'shared/ui/Button';
import {Content} from 'shared/ui/Content';
import {Flex} from 'shared/ui/Flex';
import {Layout} from 'shared/ui/Layout';
import {NavigateBack} from 'shared/ui/NavigateBack';
import {Seo} from 'shared/ui/Seo';
import {Text} from 'shared/ui/Text';
import {TextArea} from 'shared/ui/TextArea';
import {TextInput} from 'shared/ui/TextInput';
import styled from 'styled-components';

const CREATE_CERTIFICATE = gql(`
  mutation CreateCertificateTemplate($input: CreateCertificateTemplate!) {
    createCertificateTemplate(input: $input) {
      id
    }
  }
`);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  grid-column-start: 4;
  grid-column-end: 10;
  margin: 64px 0 24px 0;
`;

export const CreateCertificate = () => {
  const companyId = useAppSelector(state => state.company.data!.id);
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [price, setPrice] = useState('');
  const [balance, setBalance] = useState('');
  const [activeMonths, setActiveMonths] = useState('');
  const [description, setDescription] = useState('');
  const [createCertificate, {loading}] = useMutation(CREATE_CERTIFICATE, {
    refetchQueries: [
      {
        query: GET_CERTIFICATE_TEMPLATES,
        variables: {
          companyId,
          first: 40,
          page: 1,
        },
      },
    ],
  });
  const [error, setError] = useState<{
    name: string | null;
    price: string | null;
    balance: string | null;
  }>({
    name: null,
    price: null,
    balance: null,
  });

  return (
    <Layout>
      <Seo title="Создание сертификата" />
      <Wrapper>
        <Flex direction="row" gap={16} alignItems="center">
          <NavigateBack />
          <Text typography="title24">Создание сертификата</Text>
        </Flex>
        <Flex direction="column" gap={24}>
          <Content gap="24px">
            <Text typography="title18">Название сертификата</Text>
            <TextInput
              required
              value={name}
              onChange={setName}
              label="Название"
              error={error.name}
            />
          </Content>
          <Content gap="24px">
            <Text typography="title18">Стоимость</Text>
            <Flex direction="row" gap={8} flex={1}>
              <TextInput
                wrapperStyle={{width: '100%'}}
                mask="999999"
                required
                value={price}
                onChange={setPrice}
                label="Цена"
                error={error.price}
                rightElement={
                  <Text typography="subHead14Regular" color="textTertiary">
                    ₽
                  </Text>
                }
              />
              <TextInput
                wrapperStyle={{width: '100%'}}
                mask="999999"
                required
                value={balance}
                error={error.balance}
                onChange={setBalance}
                label="Баланс"
                rightElement={
                  <Text typography="subHead14Regular" color="textTertiary">
                    ₽
                  </Text>
                }
              />
            </Flex>
          </Content>
          <Content gap="24px">
            <Text typography="title18">Дополнительные настройки</Text>
            <Flex direction="column" gap={8}>
              <TextInput
                width="100%"
                value={activeMonths}
                onChange={setActiveMonths}
                mask="99"
                label="Срок действия"
                rightElement={
                  <Text typography="subHead14Regular" color="textTertiary">
                    Мес
                  </Text>
                }
              />
              <Text typography="subHead14Regular" color="textTertiary">
                0 - не ограничен
              </Text>
            </Flex>
            <TextArea
              label="Описание"
              value={description}
              onChange={setDescription}
              size="large"
              maxLength={1000}
            />
          </Content>
          <Flex direction="row">
            <Button
              typography="text16Semibold"
              size="large"
              loading={loading}
              style={{flex: 1}}
              onClick={() => {
                if (name !== '' && price !== '' && balance !== '') {
                  createCertificate({
                    variables: {
                      input: {
                        company_id: companyId,
                        balance: +balance,
                        description: description,
                        name,
                        price: +price,
                        validity_months: +activeMonths,
                      },
                    },
                  })
                    .then(() => {
                      navigate(-1);
                    })
                    .catch(e => {
                      showAlert('error', e.message);
                    });
                } else {
                  setError({
                    name:
                      name === '' ? 'Это поле обязательна к заполнению' : null,
                    price:
                      price === '' ? 'Это поле обязательна к заполнению' : null,
                    balance:
                      balance === ''
                        ? 'Это поле обязательна к заполнению'
                        : null,
                  });
                }
              }}>
              Сохранить
            </Button>
          </Flex>
        </Flex>
      </Wrapper>
    </Layout>
  );
};
