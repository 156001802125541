import React, {useState, useEffect} from 'react';
import {styled} from 'styled-components';
import {FilterButton} from 'shared/ui/FilterButton';
import {getName} from 'shared/lib/utils';
import {Modal} from 'shared/ui/Modal';
import {List} from 'shared/ui/List';
import {Text} from 'shared/ui/Text';
import {useLazyQuery} from '@apollo/client';
import {gql} from 'shared/__generated__';
import {useAppSelector} from 'shared/store';
import {Flex} from 'shared/ui/Flex';
import {Avatar} from 'shared/ui/Avatar';
import {Loading} from 'shared/ui/Loading';
import {UserTriple} from 'shared/icons/UserTriple';

const GET_EMPLOYEES = gql(`
query CompanyEmployeesFilter(
  $first: Int!
  $companyId: String!
  $page: Int
) {
  companyEmployees(
    first: $first
    company_id: $companyId
    page: $page
  ) {
    data {
      id
      name
      surname
      avatar {
        id
        url
        url_256
      }
    }
    paginatorInfo {
      currentPage
      hasMorePages
    }
  }
}
`);

const PopupWrapper = styled.div`
  position: relative;
  display: inline-block;
`;
const ButtonEmployee = styled.button<{checked?: boolean}>`
  display: flex;
  flex-direction: row;
  padding: 0 16px;
  align-items: center;
  height: 44px;
  width: 343px;
  gap: 16px;
  pointer-events: all;
  cursor: pointer;
  background-color: ${({checked, theme}) =>
    checked ? theme.bgSecondary : 'transparent'};
  border: none;
`;
const AllEmployees = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  background-color: ${({theme}) => theme.mainLight50};
`;

export const FilterEmployee = ({
  employeeId,
  setEmployeeId,
}: {
  employeeId: string;
  setEmployeeId: (id: string) => void;
}) => {
  const companyId = useAppSelector(state => state.company.data!.id);
  const [getEmployee, {data, loading}] = useLazyQuery(GET_EMPLOYEES, {
    variables: {
      companyId,
      first: 100,
    },
  });
  const employees = data?.companyEmployees?.data ?? [];
  const employee = employees?.find(item => item.id === employeeId);
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    if (visible) getEmployee();
  }, [getEmployee, visible]);
  return (
    <PopupWrapper>
      <FilterButton
        onClick={() => setVisible(true)}
        active={!!employeeId}
        visible={visible}
        title={
          employee
            ? getName(employee?.name, employee?.surname, 'employee')
            : 'Все сотрудники'
        }
      />
      <Modal
        style={{
          left: 'auto',
          right: 'auto',
          top: 64,
          padding: 0,
          gap: 8,
        }}
        visible={visible}
        onClose={() => setVisible(false)}>
        {loading ? (
          <Flex justifyContent="center" style={{width: 343, height: 60}}>
            <Loading />
          </Flex>
        ) : (
          <Flex
            direction="column"
            style={{
              maxHeight: 284,
              overflowY: 'auto',
              paddingTop: 24,
            }}>
            <List
              gap={16}
              data={[{name: 'Все сотрудники', id: ''}, ...(employees ?? [])]}
              keyExtractor={(_, index) => 'key_' + index}
              renderItem={(item, index) => (
                <ButtonEmployee
                  style={{
                    marginBottom: employees?.length === index ? 24 : 0,
                  }}
                  onClick={() => {
                    setEmployeeId && setEmployeeId(item.id);
                    setVisible(false);
                  }}
                  checked={item.id === employeeId}>
                  {index === 0 && (
                    <AllEmployees>
                      <UserTriple />
                    </AllEmployees>
                  )}
                  {index !== 0 && <Avatar size={40} url={item.avatar?.url} />}
                  <Text style={{pointerEvents: 'none'}}>
                    {getName(item?.name, item?.surname, 'employee')}
                  </Text>
                </ButtonEmployee>
              )}
            />
          </Flex>
        )}
      </Modal>
    </PopupWrapper>
  );
};
