import React from 'react';
import {useColorScheme} from 'shared/lib/hooks';
import {EmptyContainer} from 'shared/ui/EmptyContainer';

export const EmptyMedia = ({size = 400}: {size?: number}) => {
  const colorScheme = useColorScheme();
  return (
    <EmptyContainer size={size}>
      {colorScheme === 'light' ? (
        <svg viewBox="0 0 400 400" fill="none">
          <path
            d="M205.834 350.685C277.452 350.685 335.514 292.623 335.514 220.75C335.514 148.876 277.196 90.8148 205.834 90.8148C134.217 90.8148 76.1553 148.876 76.1553 220.75C76.1553 292.623 134.217 350.685 205.834 350.685Z"
            fill="#EAEEF9"
          />
          <path
            d="M284.25 207.391L290.782 209.498C291.252 209.655 291.645 209.086 291.332 208.702L287.052 203.337C286.939 203.203 286.908 203.032 286.966 202.867L289.064 196.348C289.221 195.878 288.653 195.485 288.269 195.798L282.912 200.065C282.778 200.178 282.607 200.209 282.442 200.151L275.923 198.052C275.453 197.896 275.06 198.464 275.373 198.848L279.653 204.213C279.765 204.347 279.797 204.518 279.739 204.683L277.632 211.215C277.475 211.685 278.043 212.078 278.427 211.765L283.793 207.485C283.914 207.364 284.085 207.333 284.25 207.391Z"
            fill="#D6DCE8"
          />
          <path
            d="M308.827 229.67L314.815 231.601C315.246 231.745 315.606 231.224 315.319 230.872L311.396 225.954C311.293 225.831 311.264 225.674 311.317 225.523L313.241 219.547C313.384 219.116 312.863 218.756 312.511 219.043L307.601 222.954C307.478 223.058 307.322 223.086 307.17 223.033L301.194 221.11C300.764 220.966 300.403 221.487 300.69 221.839L304.613 226.757C304.717 226.88 304.745 227.037 304.692 227.188L302.761 233.175C302.617 233.606 303.138 233.966 303.49 233.679L308.408 229.757C308.519 229.645 308.676 229.617 308.827 229.67Z"
            fill="#D6DCE8"
          />
          <path
            d="M205.094 113.703L208.312 117.255C208.542 117.513 208.978 117.306 208.923 116.965L208.203 112.226C208.181 112.106 208.222 111.991 208.315 111.912L211.857 108.698C212.116 108.468 211.909 108.032 211.567 108.088L206.838 108.803C206.718 108.824 206.603 108.784 206.524 108.691L203.31 105.149C203.08 104.89 202.644 105.097 202.7 105.439L203.419 110.177C203.441 110.298 203.4 110.412 203.308 110.492L199.756 113.71C199.497 113.94 199.704 114.376 200.046 114.32L204.784 113.601C204.9 113.569 205.014 113.61 205.094 113.703Z"
            fill="#D6DCE8"
          />
          <path
            d="M100.939 221.224L107.263 220.757C107.72 220.729 107.887 220.058 107.497 219.819L102.13 216.442C101.991 216.36 101.911 216.213 101.911 216.039L101.733 209.239C101.725 208.747 101.111 208.594 100.873 209.025L97.5274 214.948C97.4456 215.101 97.3067 215.193 97.1453 215.201L90.8355 215.671C90.3787 215.699 90.2118 216.37 90.6018 216.609L95.9684 219.986C96.1075 220.068 96.187 220.215 96.1872 220.389L96.3614 227.204C96.3694 227.696 96.9839 227.849 97.2218 227.418L100.571 221.48C100.639 221.323 100.778 221.231 100.939 221.224Z"
            fill="#D6DCE8"
          />
          <path
            d="M219.34 212.423L223.694 213.828C224.008 213.933 224.27 213.554 224.061 213.298L221.208 209.721C221.133 209.631 221.112 209.517 221.151 209.407L222.549 205.061C222.654 204.748 222.275 204.486 222.019 204.695L218.448 207.539C218.359 207.615 218.245 207.635 218.135 207.597L213.789 206.198C213.475 206.093 213.213 206.472 213.422 206.728L216.275 210.305C216.35 210.394 216.371 210.508 216.332 210.618L214.928 214.973C214.823 215.286 215.202 215.548 215.458 215.339L219.035 212.486C219.116 212.406 219.23 212.385 219.34 212.423Z"
            fill="#D6DCE8"
          />
          <path
            d="M351.976 179.582C357.768 179.582 362.463 174.886 362.463 169.095C362.463 163.303 357.768 158.608 351.976 158.608C346.184 158.608 341.489 163.303 341.489 169.095C341.489 174.886 346.184 179.582 351.976 179.582Z"
            fill="#EAEEF9"
          />
          <path
            d="M367.322 138.657C371.277 138.657 374.484 135.451 374.484 131.496C374.484 127.54 371.277 124.334 367.322 124.334C363.367 124.334 360.16 127.54 360.16 131.496C360.16 135.451 363.367 138.657 367.322 138.657Z"
            fill="#EAEEF9"
          />
          <path
            d="M82.0368 135.576C85.9921 135.576 89.1985 132.369 89.1985 128.414C89.1985 124.459 85.9921 121.252 82.0368 121.252C78.0814 121.252 74.875 124.459 74.875 128.414C74.875 132.369 78.0814 135.576 82.0368 135.576Z"
            fill="#EAEEF9"
          />
          <path
            d="M38.3004 273.696C45.646 273.696 51.6008 267.741 51.6008 260.395C51.6008 253.05 45.646 247.095 38.3004 247.095C30.9548 247.095 25 253.05 25 260.395C25 267.741 30.9548 273.696 38.3004 273.696Z"
            fill="#EAEEF9"
          />
          <path
            d="M315.123 79.1949C312.963 81.3893 309.418 80.64 307.274 77.2708C304.749 73.9979 303.109 71.5306 304.889 69.4325C306.922 67.4763 310.085 68.9401 313.754 70.6876C318.31 72.6224 317.03 76.8587 315.123 79.1949Z"
            fill="#DAE2EB"
          />
          <path
            d="M289.565 68.1734C289.683 71.0275 292.462 74.4438 296.14 73.7182C300.198 72.8963 303.369 72.5054 303.377 69.4131C303.259 66.559 300.852 66.1387 296.294 64.2029C292.369 62.9312 289.321 64.9392 289.565 68.1734Z"
            fill="#DAE2EB"
          />
          <path
            d="M305.335 65.3497C307.256 66.1121 308.533 68.0773 308.271 69.8072C308.269 70.4316 308.138 71.2966 307.881 71.7777C306.587 75.4321 303.759 77.6024 301.581 76.6967C299.018 75.8883 298.003 72.1932 299.424 68.9226C299.94 67.9604 300.198 67.4793 300.585 66.7576C301.744 65.217 303.413 64.5873 305.335 65.3497C305.591 65.493 305.335 65.3497 305.335 65.3497Z"
            fill="#989FB0"
          />
          <path
            d="M298.841 81.9025C270.485 137.805 228.862 160.045 190.712 173.325C181.859 176.45 172.224 179.184 163.109 176.841C153.995 174.497 146.053 165.253 148.527 156.139C150.74 147.675 161.677 143.509 169.62 146.894C177.692 150.279 182.119 159.654 181.338 168.378C180.557 177.101 175.218 184.783 168.448 190.121C161.547 195.46 153.344 198.715 145.011 201.189C126.653 206.657 107.513 209.261 89.6753 216.162C71.7074 223.063 54.6509 235.432 48.5314 253.53C44.1045 266.42 45.9273 281.003 51.6562 293.242C57.3851 305.481 67.1503 315.767 78.0873 323.969C94.8834 336.469 115.586 344.281 136.418 343.76C157.25 343.239 178.343 334.125 191.233 317.72C204.123 301.314 207.899 277.487 198.785 258.608C192.535 245.588 178.343 234.911 164.151 238.036C152.954 240.64 144.751 251.577 143.579 263.165C142.407 274.753 147.485 286.211 155.037 294.804C162.589 303.528 172.614 309.647 183.03 314.985C198.264 322.667 216.232 328.526 231.987 322.016C246.96 315.897 255.683 300.403 261.412 285.429C267.141 270.456 271.438 254.181 281.854 241.812C294.353 226.969 315.446 220.068 334.326 224.495C353.205 228.922 369.09 244.546 373.777 263.295C377.423 277.878 372.996 296.106 359.324 302.486C348.648 307.564 335.497 303.918 326.253 296.627C317.009 289.336 310.759 278.919 304.77 268.764"
            stroke="#D6DCE8"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeDasharray="4 4"
          />
          <g filter="url(#filter0_d_18298_2674)">
            <path
              d="M192.704 136.133L191.9 134.8C188.326 128.875 182.28 124.894 175.325 123.313C166.897 121.425 159.09 116.3 154.354 108.449C144.346 91.857 150.866 69.2608 169.261 59.2429C185.696 50.2405 206.073 54.8805 215.589 69.9449C220.749 78.1437 221.721 87.9292 219.17 96.8502C217.219 103.565 218.432 110.552 221.917 116.33L222.721 117.663"
              fill="url(#paint0_linear_18298_2674)"
            />
          </g>
          <g filter="url(#filter1_d_18298_2674)">
            <path
              d="M194.039 137.045L194.747 138.22C198.289 144.1 205.251 146.84 211.897 145.53C213.866 147.741 217.335 148.208 220.115 146.477C222.896 144.745 224.062 141.391 223.02 138.604C227.188 133.178 227.95 125.629 224.408 119.749L223.7 118.573"
              fill="url(#paint1_linear_18298_2674)"
            />
          </g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M181.94 87.7676C182.591 86.4743 183.935 85.6381 185.365 85.6366L193.958 85.6275L200.06 77.5569C200.954 76.374 202.451 75.7956 203.856 76.0903C205.261 76.3849 206.299 77.4948 206.487 78.9047L212.338 122.695C212.596 124.625 211.202 126.488 209.226 126.857C208.713 126.953 208.212 126.939 207.745 126.831C207.627 127.306 207.409 127.77 207.088 128.193C205.861 129.81 203.592 130.217 202.021 129.101L166.43 103.817C165.285 103.003 164.788 101.561 165.169 100.159C165.551 98.7561 166.737 97.667 168.179 97.3954L178.029 95.5396L181.94 87.7676ZM204.929 122.153L200.447 88.6103L198.49 91.1988C197.761 92.1632 196.618 92.7399 195.432 92.7412L187.299 92.7499L183.597 100.106C183.058 101.178 182.033 101.95 180.856 102.172L177.652 102.776L204.929 122.153Z"
            fill="#9BA3B3"
          />
          <path
            d="M163.804 47.1796C162.45 47.8764 160.756 47.1796 160.079 45.7858L153.983 30.1062C153.306 28.7124 153.983 26.9703 155.338 26.2734C156.693 25.5765 158.386 26.2734 159.063 27.6671L165.159 43.3468C166.175 45.4374 165.497 46.8311 163.804 47.1796Z"
            fill="#989FB0"
          />
          <path
            d="M152.999 60.875C152.331 61.6106 151.664 61.9784 150.33 61.6106L134.652 58.3005C133.317 57.9327 131.983 56.4615 132.316 54.6226C132.65 53.1514 133.984 51.6803 135.652 52.4159L151.331 55.726C152.665 56.0938 153.999 57.5649 153.666 59.4038C153.332 59.7716 153.332 60.5072 152.999 60.875Z"
            fill="#989FB0"
          />
          <path
            d="M184.457 45.0693C184.143 45.0693 183.83 44.7149 183.203 44.7149C182.262 43.6518 181.949 41.8799 182.889 40.4624L191.98 26.9963C192.921 25.9332 194.488 25.5788 195.742 26.6419C196.682 27.705 196.996 29.4769 196.055 30.8944L186.964 44.3605C186.024 44.7149 185.084 45.0693 184.457 45.0693Z"
            fill="#989FB0"
          />
          <path
            d="M234.725 216.912C233.235 210.088 231.077 202.033 227.575 195.924C225.657 192.561 222.605 190.032 218.913 188.907C214.86 187.702 211.134 188.348 207.091 189.079C199.911 190.122 192.748 191.865 185.718 194.101C179.435 195.961 172.07 197.582 166.922 201.781C159.437 208.015 160.69 216.488 162.796 225.614C164.286 232.439 166.444 240.494 169.946 246.603C172.779 251.482 177.993 254.489 183.363 254.284C190.448 254.147 204.773 250.661 211.689 248.632C217.656 246.857 224.344 245.241 229.466 241.578C238.032 235.583 237.095 227.025 234.725 216.912ZM187.355 212.867C186.695 210.401 187.74 209.24 190.149 210.004C196.61 211.973 203.274 214.063 209.736 216.032C212.144 216.796 212.541 218.276 210.881 220.306C206.269 225.242 201.702 230.342 197.09 235.277C195.228 237.186 193.944 236.825 193.17 234.566C192.905 233.58 187.972 215.168 187.355 212.867Z"
            fill="url(#paint2_linear_18298_2674)"
          />
          <g filter="url(#filter2_d_18298_2674)">
            <path
              d="M235.659 354.69L175.135 315.487C171.655 313.211 170.64 308.583 172.905 305.064L210.49 247.197C212.771 243.718 217.403 242.7 220.922 244.96L281.447 284.163C284.927 286.44 285.942 291.067 283.677 294.586L246.108 352.493C243.827 355.972 239.139 356.967 235.659 354.69Z"
              fill="url(#paint3_linear_18298_2674)"
            />
          </g>
          <path
            d="M237.937 343.855L184.725 309.385C183.221 308.428 182.815 306.419 183.775 304.916L214.855 257.069C215.815 255.565 217.825 255.158 219.329 256.116L272.542 290.586C274.045 291.544 274.451 293.552 273.492 295.055L242.411 342.903C241.452 344.406 239.441 344.813 237.937 343.855Z"
            fill="white"
          />
          <path
            d="M224.16 309.196L204.737 296.607L221.565 291.89L224.16 309.196Z"
            fill="#B0B7C4"
          />
          <path
            d="M240.232 319.596L212.25 301.49L236.48 294.713L240.232 319.596Z"
            fill="#CED6E2"
          />
          <path
            d="M220.139 284.332C221.053 286.564 223.606 287.632 225.84 286.717C228.075 285.802 229.145 283.25 228.231 281.018C227.317 278.786 224.765 277.718 222.53 278.634C220.295 279.549 219.225 282.1 220.139 284.332Z"
            fill="#B0B7C4"
          />
          <g filter="url(#filter3_d_18298_2674)">
            <path
              d="M285.293 230.145C313.606 239.729 344.328 224.547 353.913 196.234C363.498 167.921 348.315 137.198 320.002 127.614C291.689 118.029 260.966 133.212 251.382 161.525C241.797 189.838 256.98 220.56 285.293 230.145Z"
              fill="url(#paint4_linear_18298_2674)"
            />
          </g>
          <path
            d="M300.384 185.566C304.077 186.816 308.084 184.836 309.335 181.143C310.585 177.45 308.604 173.443 304.911 172.192C301.218 170.942 297.211 172.922 295.961 176.616C294.711 180.309 296.691 184.316 300.384 185.566Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M304.146 174.45C301.7 173.622 299.046 174.934 298.218 177.38C297.39 179.826 298.702 182.48 301.148 183.308C303.594 184.136 306.248 182.825 307.076 180.378C307.904 177.932 306.593 175.278 304.146 174.45ZM293.703 175.851C295.375 170.911 300.735 168.262 305.675 169.935C310.615 171.607 313.264 176.967 311.592 181.907C309.919 186.847 304.559 189.496 299.619 187.824C294.679 186.151 292.03 180.791 293.703 175.851Z"
            fill="#989FB0"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M314.716 143.183C315.966 143.599 316.642 144.948 316.226 146.197L307.509 172.405C307.093 173.655 305.744 174.33 304.495 173.915C303.246 173.5 302.57 172.15 302.985 170.901L311.702 144.693C312.118 143.443 313.467 142.768 314.716 143.183Z"
            fill="#989FB0"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M329.581 179.853C329.518 181.168 328.4 182.182 327.085 182.119L309.974 181.295C308.659 181.232 307.644 180.114 307.707 178.799C307.771 177.484 308.888 176.47 310.203 176.533L327.315 177.357C328.63 177.42 329.644 178.538 329.581 179.853Z"
            fill="#989FB0"
          />
          <path
            d="M318.399 139.357C319.483 138.839 319.889 137.487 319.305 136.335C318.72 135.184 317.368 134.669 316.283 135.187C315.199 135.704 314.793 137.057 315.377 138.208C315.961 139.36 317.314 139.874 318.399 139.357Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M317.401 137.39C317.462 137.361 317.485 137.284 317.452 137.219C317.419 137.153 317.342 137.124 317.281 137.154C317.219 137.183 317.196 137.26 317.229 137.325C317.262 137.39 317.339 137.419 317.401 137.39ZM313.302 139.198C312.1 136.83 312.934 134.048 315.165 132.984C317.395 131.92 320.178 132.977 321.379 135.345C322.581 137.714 321.747 140.496 319.516 141.56C317.286 142.624 314.503 141.567 313.302 139.198Z"
            fill="#989FB0"
          />
          <path
            d="M290.214 222.501C291.299 221.983 291.704 220.631 291.12 219.479C290.536 218.328 289.183 217.814 288.098 218.331C287.014 218.848 286.608 220.201 287.193 221.352C287.777 222.504 289.13 223.018 290.214 222.501Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M289.215 220.534C289.277 220.505 289.3 220.428 289.266 220.363C289.233 220.297 289.156 220.268 289.095 220.297C289.033 220.327 289.01 220.404 289.044 220.469C289.077 220.534 289.153 220.563 289.215 220.534ZM285.116 222.342C283.915 219.974 284.749 217.191 286.979 216.128C289.21 215.064 291.992 216.121 293.194 218.489C294.395 220.858 293.561 223.64 291.331 224.704C289.1 225.768 286.318 224.71 285.116 222.342Z"
            fill="#989FB0"
          />
          <path
            d="M262.7 166.937C263.784 166.42 264.19 165.067 263.605 163.915C263.021 162.764 261.668 162.25 260.584 162.767C259.499 163.284 259.094 164.637 259.678 165.789C260.262 166.94 261.615 167.454 262.7 166.937Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M261.703 164.97C261.765 164.941 261.788 164.864 261.755 164.799C261.722 164.734 261.645 164.704 261.583 164.734C261.522 164.763 261.499 164.84 261.532 164.905C261.565 164.97 261.642 165 261.703 164.97ZM257.604 166.778C256.403 164.41 257.237 161.628 259.467 160.564C261.698 159.5 264.48 160.557 265.682 162.926C266.884 165.294 266.05 168.076 263.819 169.14C261.588 170.204 258.806 169.147 257.604 166.778Z"
            fill="#989FB0"
          />
          <path
            d="M345.844 195.123C346.929 194.606 347.334 193.253 346.75 192.101C346.166 190.95 344.813 190.436 343.728 190.953C342.644 191.47 342.238 192.823 342.823 193.975C343.407 195.126 344.76 195.64 345.844 195.123Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M344.848 193.156C344.909 193.127 344.932 193.05 344.899 192.985C344.866 192.92 344.789 192.89 344.728 192.92C344.666 192.949 344.643 193.026 344.676 193.091C344.71 193.156 344.786 193.186 344.848 193.156ZM340.749 194.964C339.547 192.596 340.381 189.814 342.612 188.75C344.843 187.686 347.625 188.743 348.827 191.112C350.028 193.48 349.194 196.262 346.964 197.326C344.733 198.39 341.951 197.333 340.749 194.964Z"
            fill="#989FB0"
          />
          <g filter="url(#filter4_d_18298_2674)">
            <path
              d="M73.8537 183.52C81.182 178.779 89.9473 175.977 99.3592 175.977C125.368 175.977 146.419 197.028 146.419 223.036C146.419 249.044 125.368 270.095 99.3592 270.095C73.3508 270.095 52.2998 249.044 52.2998 223.108C52.2998 213.911 54.9581 205.362 59.4844 198.105"
              fill="url(#paint5_linear_18298_2674)"
            />
          </g>
          <path
            d="M79.5299 199.686C84.9184 195.159 91.8156 192.357 99.4313 192.357C116.387 192.357 130.11 206.08 130.11 223.036C130.11 239.991 116.387 253.714 99.4313 253.714C82.4756 253.714 68.7529 239.991 68.7529 223.036C68.7529 215.564 71.4112 208.666 75.9376 203.35"
            fill="#D3DBE9"
          />
          <path
            d="M91.0231 211.684C93.394 209.96 96.2679 208.954 99.4291 208.954C107.26 208.954 113.583 215.277 113.583 223.108C113.583 230.939 107.26 237.262 99.4291 237.262C91.5979 237.262 85.2754 230.939 85.2754 223.108C85.2754 220.378 86.0657 217.791 87.4308 215.636"
            fill="white"
          />
          <path
            d="M75.5049 188.622L75.4331 190.13L74.99 195.89L75.0739 195.806L100.22 220.952C101.154 221.886 101.154 223.395 100.22 224.329C99.2862 225.263 97.7774 225.263 96.8434 224.329L71.6972 199.183L71.7141 199.166L64.3687 199.686C62.4289 199.83 60.489 199.111 59.0521 197.674L44.8984 183.52C43.9644 182.515 44.5392 180.79 45.9761 180.718L52.6415 180.287L51.2927 178.778C50.3587 177.844 50.3587 176.336 51.2927 175.402C52.2267 174.468 53.7355 174.468 54.6695 175.402L56.1598 177.05L56.6093 170.157C56.6812 168.72 58.4055 168.074 59.4113 169.079L73.4932 183.305C74.9302 184.67 75.6486 186.61 75.5049 188.622Z"
            fill="#989FB0"
          />
          <defs>
            <filter
              id="filter0_d_18298_2674"
              x="127.671"
              y="43.6646"
              width="117.051"
              height="125.469"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_18298_2674"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_18298_2674"
                result="shape"
              />
            </filter>
            <filter
              id="filter1_d_18298_2674"
              x="172.039"
              y="107.573"
              width="76.6221"
              height="72.9562"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_18298_2674"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_18298_2674"
                result="shape"
              />
            </filter>
            <filter
              id="filter2_d_18298_2674"
              x="149.696"
              y="232.756"
              width="157.189"
              height="156.158"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_18298_2674"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_18298_2674"
                result="shape"
              />
            </filter>
            <filter
              id="filter3_d_18298_2674"
              x="226.51"
              y="113.741"
              width="152.275"
              height="152.276"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_18298_2674"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_18298_2674"
                result="shape"
              />
            </filter>
            <filter
              id="filter4_d_18298_2674"
              x="30.2998"
              y="164.977"
              width="138.119"
              height="138.119"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_18298_2674"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_18298_2674"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_18298_2674"
              x1="166.564"
              y1="58.7212"
              x2="209.115"
              y2="127.117"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#FDFEFF" />
              <stop offset="0.9964" stopColor="#ECF0F5" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_18298_2674"
              x1="208.6"
              y1="127.383"
              x2="220.51"
              y2="146.508"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#FDFEFF" />
              <stop offset="0.9964" stopColor="#ECF0F5" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_18298_2674"
              x1="163.932"
              y1="230.625"
              x2="233.584"
              y2="211.962"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#B0BACC" />
              <stop offset="1" stopColor="#969EAE" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_18298_2674"
              x1="282.958"
              y1="277.413"
              x2="174.898"
              y2="321.667"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#FDFEFF" />
              <stop offset="0.9964" stopColor="#ECF0F5" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_18298_2674"
              x1="320.771"
              y1="125.231"
              x2="284.885"
              y2="231.239"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#FDFEFF" />
              <stop offset="0.9964" stopColor="#ECF0F5" />
            </linearGradient>
            <linearGradient
              id="paint5_linear_18298_2674"
              x1="99.3285"
              y1="173.8"
              x2="99.3285"
              y2="271.11"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#FDFEFF" />
              <stop offset="0.9964" stopColor="#ECF0F5" />
            </linearGradient>
          </defs>
        </svg>
      ) : (
        <svg viewBox="0 0 400 400" fill="none">
          <path
            d="M205.835 350.685C277.453 350.685 335.514 292.623 335.514 220.75C335.514 148.876 277.197 90.8145 205.835 90.8145C134.217 90.8145 76.1553 148.876 76.1553 220.75C76.1553 292.623 134.217 350.685 205.835 350.685Z"
            fill="#2A2E37"
          />
          <path
            d="M284.252 207.391L290.784 209.498C291.254 209.655 291.647 209.087 291.334 208.702L287.054 203.337C286.941 203.203 286.91 203.032 286.968 202.867L289.066 196.348C289.223 195.878 288.655 195.485 288.271 195.798L282.914 200.065C282.78 200.178 282.609 200.209 282.444 200.151L275.925 198.053C275.455 197.896 275.062 198.464 275.375 198.848L279.655 204.214C279.767 204.348 279.799 204.519 279.74 204.684L277.634 211.215C277.477 211.685 278.045 212.078 278.429 211.765L283.795 207.486C283.916 207.364 284.087 207.333 284.252 207.391Z"
            fill="#474E61"
          />
          <path
            d="M308.828 229.67L314.816 231.602C315.247 231.746 315.607 231.225 315.32 230.872L311.397 225.954C311.294 225.831 311.265 225.675 311.318 225.523L313.242 219.548C313.385 219.117 312.864 218.757 312.512 219.043L307.602 222.955C307.479 223.058 307.323 223.087 307.171 223.033L301.195 221.11C300.765 220.966 300.404 221.487 300.691 221.839L304.614 226.758C304.718 226.88 304.746 227.037 304.693 227.188L302.762 233.176C302.618 233.607 303.139 233.967 303.491 233.68L308.409 229.757C308.52 229.646 308.677 229.617 308.828 229.67Z"
            fill="#474E61"
          />
          <path
            d="M205.093 113.703L208.311 117.255C208.541 117.513 208.977 117.306 208.922 116.965L208.202 112.226C208.18 112.106 208.221 111.991 208.314 111.912L211.856 108.698C212.115 108.468 211.908 108.032 211.566 108.088L206.837 108.803C206.717 108.824 206.602 108.784 206.523 108.691L203.309 105.149C203.079 104.89 202.643 105.097 202.699 105.438L203.418 110.177C203.44 110.298 203.399 110.412 203.307 110.492L199.755 113.71C199.496 113.94 199.703 114.376 200.045 114.32L204.783 113.601C204.899 113.569 205.013 113.61 205.093 113.703Z"
            fill="#474E61"
          />
          <path
            d="M100.938 221.224L107.262 220.758C107.719 220.729 107.886 220.059 107.496 219.819L102.129 216.442C101.99 216.36 101.91 216.214 101.91 216.039L101.732 209.239C101.724 208.747 101.11 208.594 100.872 209.025L97.5264 214.948C97.4446 215.102 97.3057 215.194 97.1444 215.201L90.8345 215.671C90.3778 215.7 90.2108 216.37 90.6008 216.61L95.9674 219.987C96.1065 220.069 96.1861 220.215 96.1863 220.389L96.3605 227.204C96.3684 227.697 96.9829 227.85 97.2208 227.418L100.57 221.481C100.638 221.324 100.777 221.232 100.938 221.224Z"
            fill="#474E61"
          />
          <path
            d="M219.34 212.424L223.694 213.829C224.008 213.933 224.27 213.554 224.061 213.298L221.208 209.721C221.133 209.632 221.112 209.518 221.151 209.408L222.549 205.062C222.654 204.749 222.275 204.487 222.019 204.695L218.448 207.54C218.359 207.615 218.245 207.636 218.135 207.597L213.789 206.198C213.475 206.094 213.213 206.473 213.422 206.729L216.275 210.306C216.35 210.395 216.371 210.509 216.332 210.619L214.928 214.974C214.823 215.287 215.202 215.549 215.458 215.34L219.035 212.487C219.116 212.406 219.23 212.385 219.34 212.424Z"
            fill="#474E61"
          />
          <path
            d="M351.976 179.582C357.768 179.582 362.463 174.887 362.463 169.095C362.463 163.303 357.768 158.608 351.976 158.608C346.184 158.608 341.489 163.303 341.489 169.095C341.489 174.887 346.184 179.582 351.976 179.582Z"
            fill="#2A2E37"
          />
          <path
            d="M367.324 138.658C371.279 138.658 374.486 135.451 374.486 131.496C374.486 127.54 371.279 124.334 367.324 124.334C363.369 124.334 360.162 127.54 360.162 131.496C360.162 135.451 363.369 138.658 367.324 138.658Z"
            fill="#2A2E37"
          />
          <path
            d="M82.0358 135.576C85.9912 135.576 89.1976 132.37 89.1976 128.414C89.1976 124.459 85.9912 121.252 82.0358 121.252C78.0805 121.252 74.874 124.459 74.874 128.414C74.874 132.37 78.0805 135.576 82.0358 135.576Z"
            fill="#2A2E37"
          />
          <path
            d="M38.3005 273.697C45.6461 273.697 51.6009 267.742 51.6009 260.396C51.6009 253.051 45.6461 247.096 38.3005 247.096C30.9548 247.096 25 253.051 25 260.396C25 267.742 30.9548 273.697 38.3005 273.697Z"
            fill="#2A2E37"
          />
          <path
            d="M315.125 79.1951C312.965 81.3895 309.419 80.6401 307.275 77.2709C304.751 73.998 303.111 71.5306 304.891 69.4326C306.924 67.4764 310.087 68.9402 313.756 70.6878C318.311 72.6227 317.032 76.8589 315.125 79.1951Z"
            fill="#AFB7C9"
          />
          <path
            d="M289.565 68.1733C289.683 71.0274 292.462 74.4437 296.14 73.7182C300.198 72.8964 303.368 72.5055 303.377 69.4132C303.259 66.5592 300.852 66.1388 296.294 64.203C292.369 62.9312 289.321 64.9391 289.565 68.1733Z"
            fill="#AFB7C9"
          />
          <path
            d="M305.335 65.3497C307.257 66.1122 308.534 68.0774 308.272 69.8073C308.27 70.4317 308.139 71.2966 307.881 71.7778C306.588 75.4321 303.76 77.6024 301.582 76.6967C299.019 75.8883 298.004 72.1931 299.425 68.9226C299.941 67.9604 300.199 67.4793 300.586 66.7576C301.745 65.217 303.414 64.5873 305.335 65.3497C305.592 65.493 305.335 65.3497 305.335 65.3497Z"
            fill="#474E61"
          />
          <path
            d="M298.843 81.9026C270.486 137.806 228.863 160.045 190.714 173.326C181.86 176.45 172.225 179.185 163.111 176.841C153.997 174.497 146.054 165.253 148.528 156.139C150.742 147.676 161.679 143.509 169.621 146.894C177.694 150.28 182.12 159.654 181.339 168.378C180.558 177.102 175.22 184.783 168.449 190.122C161.548 195.46 153.346 198.715 145.013 201.189C126.654 206.658 107.514 209.262 89.6764 216.162C71.7085 223.063 54.6519 235.432 48.5324 253.531C44.1055 266.421 45.9283 281.003 51.6572 293.242C57.3862 305.481 67.1514 315.767 78.0884 323.97C94.8845 336.47 115.587 344.282 136.419 343.761C157.252 343.24 178.345 334.126 191.235 317.721C204.125 301.315 207.901 277.488 198.786 258.608C192.537 245.588 178.345 234.912 164.152 238.036C152.955 240.64 144.752 251.577 143.58 263.166C142.409 274.754 147.486 286.211 155.038 294.805C162.59 303.528 172.616 309.648 183.032 314.986C198.266 322.668 216.234 328.527 231.988 322.017C246.961 315.898 255.685 300.404 261.414 285.43C267.143 270.457 271.44 254.182 281.856 241.812C294.355 226.969 315.448 220.068 334.327 224.495C353.207 228.922 369.092 244.547 373.779 263.296C377.425 277.878 372.998 296.107 359.326 302.487C348.65 307.565 335.499 303.919 326.255 296.628C317.01 289.336 310.761 278.92 304.771 268.764"
            stroke="#474E61"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeDasharray="4 4"
          />
          <g filter="url(#filter0_d_18298_2676)">
            <path
              d="M192.708 136.134L191.904 134.8C188.33 128.875 182.284 124.894 175.328 123.313C166.901 121.426 159.093 116.3 154.357 108.449C144.349 91.8576 150.869 69.2614 169.264 59.2432C185.699 50.2406 206.076 54.8803 215.591 69.9446C220.751 78.1434 221.724 87.9288 219.174 96.8499C217.222 103.565 218.435 110.552 221.92 116.329L222.725 117.662"
              fill="url(#paint0_linear_18298_2676)"
            />
          </g>
          <g filter="url(#filter1_d_18298_2676)">
            <path
              d="M194.043 137.045L194.751 138.221C198.293 144.101 205.255 146.84 211.901 145.531C213.87 147.741 217.339 148.209 220.119 146.477C222.9 144.745 224.066 141.391 223.024 138.604C227.192 133.179 227.954 125.629 224.412 119.749L223.704 118.573"
              fill="url(#paint1_linear_18298_2676)"
            />
          </g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M181.943 87.7682C182.594 86.4749 183.937 85.6387 185.367 85.6372L193.96 85.6279L200.062 77.5573C200.957 76.3744 202.454 75.796 203.859 76.0906C205.264 76.3852 206.302 77.4951 206.49 78.905L212.341 122.695C212.599 124.625 211.206 126.489 209.229 126.857C208.716 126.953 208.215 126.939 207.749 126.832C207.63 127.306 207.412 127.77 207.091 128.193C205.864 129.811 203.596 130.217 202.024 129.101L166.433 103.818C165.288 103.004 164.79 101.562 165.172 100.159C165.554 98.757 166.74 97.6678 168.182 97.3962L178.031 95.5403L181.943 87.7682ZM204.932 122.153L200.45 88.6107L198.493 91.1992C197.764 92.1636 196.62 92.7404 195.435 92.7416L187.302 92.7504L183.6 100.106C183.061 101.178 182.036 101.951 180.859 102.173L177.655 102.776L204.932 122.153Z"
            fill="#2A2E36"
          />
          <path
            d="M163.803 47.1796C162.449 47.8765 160.755 47.1796 160.078 45.7859L153.983 30.1062C153.305 28.7124 153.983 26.9703 155.337 26.2734C156.692 25.5765 158.385 26.2734 159.062 27.6671L165.158 43.3468C166.174 45.4374 165.496 46.8312 163.803 47.1796Z"
            fill="#474E61"
          />
          <path
            d="M152.999 60.8753C152.331 61.6108 151.664 61.9786 150.33 61.6108L134.652 58.3007C133.317 57.933 131.983 56.4618 132.316 54.6229C132.65 53.1517 133.984 51.6805 135.652 52.4161L151.331 55.7262C152.665 56.094 153.999 57.5652 153.666 59.4041C153.332 59.7719 153.332 60.5075 152.999 60.8753Z"
            fill="#474E61"
          />
          <path
            d="M184.457 45.0693C184.143 45.0693 183.83 44.715 183.203 44.715C182.262 43.6518 181.949 41.88 182.889 40.4625L191.98 26.9963C192.921 25.9332 194.488 25.5788 195.742 26.6419C196.682 27.705 196.996 29.4769 196.055 30.8944L186.964 44.3606C186.024 44.715 185.084 45.0693 184.457 45.0693Z"
            fill="#474E61"
          />
          <g filter="url(#filter2_d_18298_2676)">
            <path
              d="M234.725 216.913C233.235 210.088 231.077 202.033 227.575 195.924C225.657 192.562 222.606 190.032 218.913 188.907C214.861 187.703 211.134 188.349 207.091 189.08C199.911 190.123 192.748 191.866 185.718 194.102C179.435 195.962 172.07 197.583 166.922 201.781C159.437 208.015 160.69 216.489 162.796 225.615C164.286 232.439 166.444 240.494 169.946 246.604C172.779 251.482 177.993 254.49 183.364 254.284C190.448 254.148 204.773 250.662 211.689 248.633C217.656 246.858 224.344 245.242 229.466 241.579C238.032 235.584 237.096 227.025 234.725 216.913ZM187.355 212.868C186.695 210.402 187.74 209.241 190.149 210.005C196.61 211.974 203.274 214.064 209.736 216.033C212.144 216.797 212.541 218.276 210.881 220.307C206.269 225.242 201.702 230.342 197.09 235.278C195.228 237.186 193.945 236.826 193.17 234.567C192.905 233.58 187.972 215.169 187.355 212.868Z"
              fill="#4D5467"
            />
          </g>
          <g filter="url(#filter3_d_18298_2676)">
            <path
              d="M235.659 354.691L175.135 315.488C171.655 313.211 170.64 308.584 172.905 305.065L210.49 247.198C212.771 243.719 217.403 242.701 220.922 244.961L281.447 284.164C284.927 286.44 285.942 291.068 283.677 294.587L246.108 352.494C243.827 355.973 239.139 356.967 235.659 354.691Z"
              fill="url(#paint2_linear_18298_2676)"
            />
          </g>
          <path
            d="M237.938 343.856L184.726 309.386C183.222 308.428 182.816 306.42 183.775 304.916L214.856 257.069C215.816 255.566 217.826 255.159 219.33 256.116L272.543 290.587C274.046 291.544 274.452 293.553 273.493 295.056L242.412 342.903C241.453 344.407 239.442 344.814 237.938 343.856Z"
            fill="#474E61"
          />
          <path
            d="M224.162 309.197L204.739 296.608L221.567 291.891L224.162 309.197Z"
            fill="#292E39"
          />
          <path
            d="M240.234 319.597L212.252 301.491L236.482 294.714L240.234 319.597Z"
            fill="#5C6680"
          />
          <path
            d="M220.14 284.333C221.054 286.565 223.607 287.633 225.841 286.717C228.076 285.802 229.146 283.251 228.232 281.019C227.318 278.787 224.766 277.719 222.531 278.634C220.296 279.549 219.226 282.101 220.14 284.333Z"
            fill="#292E39"
          />
          <g filter="url(#filter4_d_18298_2676)">
            <path
              d="M285.293 230.146C313.606 239.73 344.328 224.548 353.913 196.234C363.498 167.921 348.315 137.199 320.002 127.614C291.689 118.029 260.966 133.212 251.382 161.525C241.797 189.838 256.98 220.561 285.293 230.146Z"
              fill="url(#paint3_linear_18298_2676)"
            />
          </g>
          <path
            d="M300.385 185.567C304.078 186.817 308.085 184.836 309.336 181.143C310.586 177.45 308.605 173.443 304.912 172.193C301.219 170.943 297.212 172.923 295.962 176.616C294.712 180.309 296.692 184.316 300.385 185.567Z"
            fill="#292D36"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M304.146 174.451C301.7 173.623 299.046 174.934 298.218 177.38C297.39 179.826 298.702 182.481 301.148 183.309C303.594 184.137 306.248 182.825 307.076 180.379C307.904 177.933 306.593 175.279 304.146 174.451ZM293.703 175.852C295.375 170.912 300.735 168.263 305.675 169.935C310.615 171.607 313.264 176.968 311.592 181.908C309.919 186.848 304.559 189.497 299.619 187.824C294.679 186.152 292.03 180.792 293.703 175.852Z"
            fill="#474E61"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M314.716 143.183C315.966 143.599 316.642 144.948 316.226 146.198L307.509 172.406C307.093 173.655 305.744 174.331 304.495 173.915C303.246 173.5 302.57 172.15 302.985 170.901L311.702 144.693C312.118 143.444 313.467 142.768 314.716 143.183Z"
            fill="#474E61"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M329.581 179.853C329.518 181.168 328.4 182.182 327.085 182.119L309.974 181.295C308.659 181.232 307.644 180.114 307.707 178.799C307.771 177.485 308.888 176.47 310.203 176.533L327.315 177.357C328.63 177.42 329.644 178.538 329.581 179.853Z"
            fill="#474E61"
          />
          <path
            d="M318.402 139.356C319.486 138.839 319.892 137.486 319.307 136.335C318.723 135.183 317.37 134.669 316.286 135.186C315.201 135.704 314.796 137.057 315.38 138.208C315.964 139.36 317.317 139.874 318.402 139.356Z"
            fill="#292D36"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M317.402 137.39C317.464 137.361 317.487 137.284 317.454 137.219C317.421 137.153 317.344 137.124 317.282 137.154C317.221 137.183 317.198 137.26 317.231 137.325C317.264 137.39 317.341 137.419 317.402 137.39ZM313.304 139.198C312.102 136.83 312.936 134.047 315.167 132.984C317.397 131.92 320.179 132.977 321.381 135.346C322.583 137.714 321.749 140.496 319.518 141.56C317.288 142.624 314.505 141.566 313.304 139.198Z"
            fill="#474E61"
          />
          <path
            d="M290.215 222.501C291.3 221.983 291.705 220.631 291.121 219.479C290.537 218.328 289.184 217.814 288.099 218.331C287.015 218.848 286.609 220.201 287.194 221.352C287.778 222.504 289.131 223.018 290.215 222.501Z"
            fill="#292D36"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M289.218 220.534C289.279 220.505 289.302 220.428 289.269 220.363C289.236 220.297 289.159 220.268 289.098 220.298C289.036 220.327 289.013 220.404 289.046 220.469C289.08 220.534 289.156 220.564 289.218 220.534ZM285.119 222.342C283.918 219.974 284.751 217.192 286.982 216.128C289.212 215.064 291.995 216.121 293.196 218.49C294.398 220.858 293.564 223.64 291.334 224.704C289.103 225.768 286.321 224.711 285.119 222.342Z"
            fill="#474E61"
          />
          <path
            d="M262.7 166.937C263.785 166.42 264.19 165.067 263.606 163.916C263.022 162.764 261.669 162.25 260.585 162.767C259.5 163.285 259.095 164.637 259.679 165.789C260.263 166.94 261.616 167.454 262.7 166.937Z"
            fill="#292D36"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M261.704 164.971C261.766 164.941 261.789 164.864 261.755 164.799C261.722 164.734 261.646 164.705 261.584 164.734C261.523 164.763 261.5 164.84 261.533 164.905C261.566 164.971 261.643 165 261.704 164.971ZM257.606 166.779C256.404 164.41 257.238 161.628 259.468 160.564C261.699 159.5 264.481 160.558 265.683 162.926C266.884 165.294 266.05 168.077 263.82 169.141C261.589 170.204 258.807 169.147 257.606 166.779Z"
            fill="#474E61"
          />
          <path
            d="M345.845 195.123C346.929 194.606 347.335 193.253 346.751 192.102C346.166 190.95 344.814 190.436 343.729 190.953C342.645 191.471 342.239 192.823 342.824 193.975C343.408 195.126 344.761 195.641 345.845 195.123Z"
            fill="#292D36"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M344.85 193.156C344.911 193.127 344.934 193.05 344.901 192.985C344.868 192.92 344.791 192.891 344.73 192.92C344.668 192.949 344.645 193.026 344.678 193.091C344.711 193.157 344.788 193.186 344.85 193.156ZM340.751 194.964C339.549 192.596 340.383 189.814 342.614 188.75C344.844 187.686 347.627 188.744 348.828 191.112C350.03 193.48 349.196 196.263 346.965 197.326C344.735 198.39 341.953 197.333 340.751 194.964Z"
            fill="#474E61"
          />
          <g filter="url(#filter5_d_18298_2676)">
            <path
              d="M73.8538 183.521C81.1821 178.779 89.9474 175.977 99.3593 175.977C125.368 175.977 146.419 197.028 146.419 223.037C146.419 249.045 125.368 270.096 99.3593 270.096C73.3509 270.096 52.2998 249.045 52.2998 223.108C52.2998 213.912 54.9581 205.362 59.4845 198.106"
              fill="url(#paint4_linear_18298_2676)"
            />
          </g>
          <path
            d="M79.5299 199.686C84.9184 195.16 91.8157 192.358 99.4314 192.358C116.387 192.358 130.11 206.08 130.11 223.036C130.11 239.992 116.387 253.715 99.4314 253.715C82.4756 253.715 68.7529 239.992 68.7529 223.036C68.7529 215.564 71.4113 208.667 75.9376 203.35"
            fill="#454C5E"
          />
          <path
            d="M91.0241 211.685C93.395 209.961 96.2689 208.955 99.4301 208.955C107.261 208.955 113.584 215.277 113.584 223.109C113.584 230.94 107.261 237.262 99.4301 237.262C91.5989 237.262 85.2764 230.94 85.2764 223.109C85.2764 220.378 86.0667 217.792 87.4318 215.637"
            fill="#5E6882"
          />
          <path
            d="M75.507 188.622L75.4351 190.131L74.9921 195.891L75.076 195.807L100.222 220.953C101.156 221.887 101.156 223.396 100.222 224.33C99.2883 225.264 97.7795 225.264 96.8455 224.33L71.6992 199.184L71.7162 199.167L64.3708 199.687C62.4309 199.83 60.491 199.112 59.0541 197.675L44.9003 183.521C43.9663 182.515 44.5411 180.791 45.978 180.719L52.6435 180.288L51.2947 178.779C50.3607 177.845 50.3607 176.336 51.2947 175.402C52.2287 174.468 53.7375 174.468 54.6715 175.402L56.1618 177.05L56.6113 170.158C56.6832 168.721 58.4075 168.074 59.4133 169.08L73.4953 183.306C74.9322 184.671 75.6507 186.611 75.507 188.622Z"
            fill="#474E61"
          />
          <defs>
            <filter
              id="filter0_d_18298_2676"
              x="127.674"
              y="43.6646"
              width="117.051"
              height="125.469"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_18298_2676"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_18298_2676"
                result="shape"
              />
            </filter>
            <filter
              id="filter1_d_18298_2676"
              x="172.043"
              y="107.573"
              width="76.6221"
              height="72.9561"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_18298_2676"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_18298_2676"
                result="shape"
              />
            </filter>
            <filter
              id="filter2_d_18298_2676"
              x="157.178"
              y="188.237"
              width="83.1885"
              height="74.0574"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_18298_2676"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_18298_2676"
                result="shape"
              />
            </filter>
            <filter
              id="filter3_d_18298_2676"
              x="149.696"
              y="232.756"
              width="157.189"
              height="156.158"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_18298_2676"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_18298_2676"
                result="shape"
              />
            </filter>
            <filter
              id="filter4_d_18298_2676"
              x="226.509"
              y="113.742"
              width="152.277"
              height="152.276"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_18298_2676"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_18298_2676"
                result="shape"
              />
            </filter>
            <filter
              id="filter5_d_18298_2676"
              x="30.2998"
              y="164.977"
              width="138.119"
              height="138.119"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_18298_2676"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_18298_2676"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_18298_2676"
              x1="166.567"
              y1="58.7215"
              x2="209.119"
              y2="127.117"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#5E6882" />
              <stop offset="0.9964" stopColor="#5E6882" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_18298_2676"
              x1="208.604"
              y1="127.383"
              x2="220.514"
              y2="146.508"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#5E6882" />
              <stop offset="0.9964" stopColor="#5E6882" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_18298_2676"
              x1="282.958"
              y1="277.413"
              x2="174.898"
              y2="321.668"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#5E6882" />
              <stop offset="0.9964" stopColor="#5E6882" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_18298_2676"
              x1="320.771"
              y1="125.231"
              x2="284.885"
              y2="231.24"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#5E6882" />
              <stop offset="0.9964" stopColor="#5E6882" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_18298_2676"
              x1="99.3286"
              y1="173.8"
              x2="99.3286"
              y2="271.111"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#5E6882" />
              <stop offset="0.9964" stopColor="#5E6882" />
            </linearGradient>
          </defs>
        </svg>
      )}
    </EmptyContainer>
  );
};
