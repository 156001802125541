import React from 'react';
import {
  Review as ReviewGraph,
  Employee,
  User,
} from 'shared/__generated__/graphql';
import {Table} from 'shared/ui/Table';
import styled from 'styled-components';
import {Text} from 'shared/ui/Text';
import dayjs from 'dayjs';
import {getName} from 'shared/lib/utils';
import {RatingStar} from 'shared/ui/RatingStar';
import {useResize} from 'shared/lib/hooks';
import {Avatar} from 'shared/ui/Avatar';
import {Divider} from 'shared/ui/Divider';
import {Flex} from 'shared/ui/Flex';
import {useNavigate} from 'react-router-dom';
import {useAppSelector} from 'shared/store';
import {Content} from 'shared/ui/Content';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
`;

const ReviewCart = styled.div`
  background-color: ${({theme}) => theme.bgPrimary};
  padding: 16px;
  border-radius: 20px;
  pointer-events: all;
`;

type OptionsType = {
  page: number;
  first: number;
  lastPage?: number;
  total?: number;
  count?: number;
};

type Props = {
  reviews?: (Pick<ReviewGraph, 'id' | 'created_at' | 'rating' | 'comment'> & {
    employee?: Pick<Employee, 'id' | 'name' | 'surname'> | null;
  } & {
    user: Pick<User, 'id' | 'name' | 'surname' | 'avatar'>;
  })[];
  onClick?: () => void;
  onChange?: () => void;
  options: OptionsType;
  setOptions?: React.Dispatch<React.SetStateAction<OptionsType>>;
  onPressOpenReview?: (index: number) => void;
  workingdate: string;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const ReviewCellText = styled(Text)<{view: boolean}>`
  align-self: center;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 24px;
  max-height: 48px;
  &::after {
    display: ${({view}) => !view && 'none'};
    margin-left: 4px;
    color: ${({theme}) => theme.mainPrimary};
    content: 'еще';
  }
`;

export const ReviewsTable = ({
  reviews = [],
  options,
  setOptions,
  onPressOpenReview,
  workingdate,
}: Props) => {
  const titles = ['Дата', 'Клиент', 'Сотрудник', 'Оценка', 'Отзыв'];
  const isMobile = useResize().isMobile;
  const navigate = useNavigate();
  const username = useAppSelector(state => state.company.data?.username);

  return isMobile ? (
    <Wrapper>
      {reviews?.map((review, index) => [
        <ReviewCart
          key={index + 1}
          onClick={() => {
            navigate(`/${username}/reviews/${review.id}`, {state: review});
          }}>
          <Flex gap={16}>
            <Avatar variant="avatar" url={review.user.avatar?.url} />
            <Flex direction="column">
              <Text>{review.user.name}</Text>
              <RatingStar list rating={review.rating} />
            </Flex>
            <Text style={{marginLeft: 'auto'}}>
              {dayjs(review.created_at).format('DD.MM.YYYY, HH:mm')}
            </Text>
          </Flex>
          <Divider style={{marginTop: 16, marginBottom: 16}} />
          <Flex direction="column" gap={8}>
            <Text typography="subHead14Regular" color="mainPrimary">
              Запись от {workingdate}
            </Text>
            <Text>{review.comment}</Text>
          </Flex>
        </ReviewCart>,
      ])}
    </Wrapper>
  ) : (
    <Content style={{margin: 24}}>
      <Table
        titles={titles}
        options={options}
        columnNoWrap={[1, 2, 3]}
        setOptions={setOptions}
        widths={[178, 304, 304, 214, 566]}
        onClick={onPressOpenReview}
        rows={reviews.map((review, index) => [
          <Flex direction="column" key={index.toString() + '1'}>
            <Text>{dayjs(review.created_at).format('DD.MM.YYYY')}</Text>
            <Text typography="subHead14Medium" color="textTertiary">
              {dayjs(review.created_at).format('HH:mm')}
            </Text>
          </Flex>,
          getName(review.user.name, review.user.surname),
          getName(review.employee?.name, review.employee?.surname, 'employee'),
          <RatingStar
            key={index.toString() + '2'}
            list
            rating={review.rating}
          />,
          <ReviewCellText
            key={index.toString() + '3'}
            view={review.comment!.length > 120}>
            {review.comment!.length > 120
              ? review.comment?.substring(0, 120) + '...'
              : review.comment}
          </ReviewCellText>,
        ])}
      />
    </Content>
  );
};
