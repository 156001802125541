import {
  GuaranteedMinimumType,
  SalaryType,
  ServiceSalaryType,
} from 'shared/__generated__/graphql';

export const salaryType = [
  {label: '₽', value: ServiceSalaryType.Value},
  {label: '%', value: ServiceSalaryType.Percent},
];
export const salaryPeriod = [
  {label: 'День', value: GuaranteedMinimumType.Day},
  {label: 'Месяц', value: GuaranteedMinimumType.Month},
];
export const salaryTime = [
  {label: 'Час', value: SalaryType.Hour},
  {label: 'День', value: SalaryType.Day},
  {label: 'Месяц', value: SalaryType.Month},
];

export type SalaryData = {label: string; value: string};
export type SalarySelectedServices = {
  id: number;
  services: SalaryData[];
  value: string;
  typeIndex: string;
};
