import React from 'react';
import {Trash} from 'shared/icons/Trash';
import {useColors} from 'shared/lib/hooks';
import {Button} from 'shared/ui/Button';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';

export const WrapperDeleteSelection = styled.div`
  position: sticky;
  grid-column-start: 1;
  grid-column-end: 13;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  bottom: 0;
  height: 88px;
  left: 88px;
  right: 0;
  background-color: ${({theme}) => theme.bgPrimary};
  border: 1px solid ${({theme}) => theme.borderPrimary};
  ${({theme}) => theme.tabBarShadow}
`;

export const DeleteSelection = ({
  selectedLength,
  length,
  loading,
  onDelete,
}: {
  selectedLength: number;
  length: number;
  loading: boolean;
  onDelete: () => void;
}) => {
  const colors = useColors();
  return (
    <WrapperDeleteSelection>
      <Text
        color="textTertiary"
        style={{
          width: 'max-content',
          display: 'flex',
          flexDirection: 'row',
          gap: 8,
        }}>
        Выбрано:{' '}
        <Text color="textPrimary">
          {selectedLength} из {length}
        </Text>
      </Text>
      <Button
        variant="outline"
        loading={loading}
        onClick={onDelete}
        typography="subHead14Medium"
        style={{
          borderColor: colors.dangerPrimary,
          color: colors.dangerPrimary,
          width: 172,
        }}>
        <Trash color={colors.dangerPrimary} />
        Удалить
      </Button>
    </WrapperDeleteSelection>
  );
};
