import React from 'react';
import {useColors} from 'shared/lib/hooks';
import {Content} from 'shared/ui/Content';
import {Skeleton} from 'shared/ui/Skeleton';

export const CompanyEditSkeleton = ({
  type,
}: {
  type?: 'caseInfo' | 'caseImage' | 'caseAddress';
}) => {
  const colors = useColors();
  return (
    <>
      {type === 'caseInfo' && (
        <>
          <Skeleton height={100}>
            <circle r={50} cx={334} cy={50} />
            <rect x={0} y={130} width={80} height={10} rx={8} />
            <rect x={0} y={148} width={355} height={14} rx={8} />
          </Skeleton>
          <Content style={{backgroundColor: colors.fillPrimary, height: 64}} />
          <Content style={{backgroundColor: colors.fillPrimary, height: 64}} />
          <Content style={{backgroundColor: colors.fillPrimary, height: 64}} />
        </>
      )}
      {type === 'caseImage' && (
        <>
          <Content
            padding="48px 275px"
            style={{backgroundColor: colors.fillPrimary, height: 168}}>
            <Skeleton>
              <rect x={44} y={0} width={33} height={20} rx={10} />
              <rect x={55} y={25} width={10} height={8} rx={4} />
              <rect x={0} y={42} width={118} height={14} rx={10} />
            </Skeleton>
          </Content>
          <Skeleton height={200}>
            <rect x={0} y={0} width={88} height={88} rx={10} />
            <rect x={96} y={0} width={88} height={88} rx={10} />
            <rect x={192} y={0} width={88} height={88} rx={10} />
            <rect x={288} y={0} width={88} height={88} rx={10} />
            <rect x={384} y={0} width={88} height={88} rx={10} />
            <rect x={480} y={0} width={88} height={88} rx={10} />
            <rect x={576} y={0} width={88} height={88} rx={10} />
            <rect x={0} y={112} width={370} height={12} rx={9} />
            <rect x={0} y={140} width={240} height={12} rx={9} />
            <rect x={0} y={168} width={260} height={12} rx={9} />
          </Skeleton>
        </>
      )}
      {type === 'caseAddress' && (
        <>
          <Content style={{backgroundColor: colors.fillPrimary, height: 64}} />
          <Content style={{backgroundColor: colors.fillPrimary, height: 64}} />
          <Content style={{backgroundColor: colors.fillPrimary, height: 166}} />
        </>
      )}
    </>
  );
};
