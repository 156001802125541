import React, {useState} from 'react';
import {styled} from 'styled-components';
import {FilterButton} from 'shared/ui/FilterButton';
import {Modal} from 'shared/ui/Modal';
import {List} from 'shared/ui/List';
import {Text} from 'shared/ui/Text';
import {sortTypings} from 'entities/messages';

const PopupWrapper = styled.div`
  position: relative;
  display: inline-block;
`;
const ButtonPeriod = styled.button<{checked?: boolean}>`
  display: flex;
  padding: 0 16px;
  align-items: center;
  height: 56px;
  width: 343px;
  cursor: pointer;
  pointer-events: all;
  justify-content: space-between;
  background-color: ${({checked, theme}) =>
    checked ? theme.bgSecondary : 'transparent'};
  border: none;
`;

export const FilterSort = ({
  sort,
  setSort,
}: {
  sort: number;
  setSort: (gender: number) => void;
}) => {
  const [visible, setVisible] = useState(false);
  return (
    <PopupWrapper>
      <FilterButton
        onClick={() => setVisible(true)}
        active={sort !== 0}
        title={sort !== 0 ? sortTypings[sort].label : 'Сортировка'}
        visible={visible}
      />
      <Modal
        style={{
          left: 'auto',
          right: 'auto',
          top: 64,
          paddingLeft: 0,
          paddingRight: 0,
          gap: 8,
        }}
        visible={visible}
        onClose={() => setVisible(false)}>
        <List
          data={[...Object.values(sortTypings)]}
          gap={8}
          keyExtractor={(_, index) => 'key_' + index}
          renderItem={(item, index) => (
            <ButtonPeriod
              onClick={() => {
                setSort(index);
                setVisible(false);
              }}
              checked={index === sort}>
              <Text style={{pointerEvents: 'none'}}>{item.label}</Text>
            </ButtonPeriod>
          )}
        />
      </Modal>
    </PopupWrapper>
  );
};
