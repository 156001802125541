import React, {useState} from 'react';
import dayjs from 'dayjs';
import {device} from 'shared/device';
import styled from 'styled-components';
import {Text} from 'shared/ui/Text';
import {ActionIcon} from 'shared/ui/ActionIcon';
import {ArrowRight1v} from 'shared/icons/ArrowRight1v';
import {DatePicker} from 'shared/ui/DatePicker';
import {capitalize} from 'shared/lib/utils';
import {ArrowLeft1v} from 'shared/icons/ArrowLeft1v';

const SwitcherBox = styled.div`
  width: min-content;
  border: 1px solid ${({theme}) => theme.borderPrimary};
  display: flex;
  flex-direction: row;
  height: 56px;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  gap: 8px;
  width: 384px;
  border-radius: 14px;
  position: relative;
  background-color: ${({theme}) => theme.bgPrimary};
  @media ${device.mobile} {
    width: calc(100dvw - 32px);
    margin-left: 16px;
    border-color: none;
    margin-top: 16px;
  }
`;

export const DateSwitcher = ({
  difference,
  onChange,
  type = 'week',
}: {
  difference: number;
  onChange: (week: number) => void;
  type?: 'week' | 'month';
}) => {
  const today = dayjs();
  const firstDayWeek = today
    .startOf(type)
    .add(difference, type)
    .format('D MMMM');
  const lastDayWeek = today.endOf(type).add(difference, type).format('D MMMM');
  const currentMonth = today
    .endOf(type)
    .add(difference, type)
    .format('MMMM YYYY');
  const viewDate =
    type === 'week'
      ? firstDayWeek + ' - ' + lastDayWeek
      : capitalize(currentMonth);

  const [visbile, setVisible] = useState(false);
  const [startDate, setStartDate] = useState(
    today.startOf('week').format('YYYY-MM-DD'),
  );
  const [endDate, setEndDate] = useState(
    today.endOf('week').format('YYYY-MM-DD'),
  );

  return (
    <SwitcherBox>
      <ActionIcon
        style={{height: 30, width: 30, pointerEvents: 'all', border: 'none'}}
        onClick={() => onChange(difference - 1)}
        icon={<ArrowLeft1v />}
      />
      <Text
        typography="text18"
        align="center"
        style={{flex: 1}}
        onClick={() => setVisible(true)}>
        {viewDate}
      </Text>
      <ActionIcon
        style={{height: 30, width: 30, pointerEvents: 'all', border: 'none'}}
        onClick={() => onChange(difference + 1)}
        icon={<ArrowRight1v />}
      />
      <DatePicker
        variant="week"
        style={{width: 375}}
        visible={visbile}
        onClose={() => setVisible(false)}
        value={startDate}
        valueEnd={endDate}
        onChange={(value, valueEnd) => {
          setStartDate(value);
          if (valueEnd) {
            setEndDate(valueEnd);
          }

          onChange(
            dayjs(value).startOf('week').diff(today.startOf('week'), 'day') / 7,
          );
        }}
      />
    </SwitcherBox>
  );
};
