import React, {useState} from 'react';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';
import {Content} from 'shared/ui/Content';
import {TextInput} from 'shared/ui/TextInput';
import {Head} from 'shared/ui/Head';
import {Select} from 'shared/ui/Select';
import {Button} from 'shared/ui/Button';
import {useMutation, useQuery} from '@apollo/client';
import {useNavigate, useParams} from 'react-router-dom';
import {gql} from 'shared/__generated__';
import {useAppSelector} from 'shared/store';
import {
  CompanyRoles,
  ContactType,
  Image as ImageGQL,
  ImageType,
} from 'shared/__generated__/graphql';
import {TextArea} from 'shared/ui/TextArea';
import {DragDrop} from 'shared/ui/DragDrop';
import {Contact, Social} from 'entities/contact';
import {
  PHONE_MASK,
  getPhoneMask,
  modifyUrl,
  processedAvatar,
} from 'shared/lib/utils';
import {Switch} from 'shared/ui/Switch';
import {TableViewRow} from 'shared/ui/TableViewRow';
import {Grow} from 'shared/ui/Grow';
import {Popup} from 'shared/ui/Popup';
import {Layout} from 'shared/ui/Layout';
import {ImageView} from 'shared/ui/ImageView';
import {Image} from 'shared/ui/Image';
import {showAlert} from 'shared/ui/Alert';
import {Flex} from 'shared/ui/Flex';
import {AvatarCrop, AvatarLoadType} from 'shared/ui/AvatarCrop';
import {MultiSelectChips} from 'shared/ui/MultiSelectChips';
import {GET_COMPANY_EMPLOYEES, SelectProfessions} from 'entities/employees';
import {TUTORIALCHECK} from 'entities/tutorial';
import {NavigateBack} from 'shared/ui/NavigateBack';
import {Seo} from 'shared/ui/Seo';
import {SkeletonEdit} from './ui/Skeleton';
import {DateInput} from 'shared/ui/DateInput';
import dayjs from 'dayjs';

const EMPLOYEE = gql(`
  query GetEditEmployee(
    $employeeId: String!
    $companyId: String!
    $first: Int!
  ) {
    employee(id: $employeeId) {
      id
      name
      surname
      biography
      role
      phone
      enabled
      career_start
      online_booking_enabled
      avatar {
        id
        url
        url_256
      }
      career_start
      phone
      enabled
      images(first: $first) {
        paginatorInfo {
          total
        }
        data {
          id
          url
          url_256
        }
      }
      professions {
        id
        name
      }
      contacts {
        id
        value
        type
      }
      vk_url
      youtube_url
    }
    company(id: $companyId) {
      id
      slots
    }
    getCompanyEmployeesLength(company_id: $companyId, enabled: true)
  }
`);

const UPDATE_EMPLOYEE = gql(`
  mutation UpdateEmployeeEditEmployee(
    $inputEmployee: UpdateEmployee!
  ) {
    updateEmployee(input: $inputEmployee) {
      id
      name
      surname
      biography
      role
      phone
      enabled
      career_start
      online_booking_enabled
      avatar {
        id
        url
        url_256
      }
      career_start
      phone
      enabled
      professions {
        id
        name
      }
      vk_url
      youtube_url
    }
  }
`);
const UPDATE_EMPLOYEE_CONTACTS = gql(`
  mutation UpdateEmployeeContacts(
    $input: [UpdateEmployeeContacts!]!
    $employeeId: String!
  ) {
    updateEmployeeContacts(input: $input, employee_id: $employeeId) {
      employee {
        id
        contacts {
          id
          value
          type
        }
      }
    }
  }
`);

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  grid-column-start: 4;
  grid-column-end: 10;
  padding: 64px 0 24px;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const PhotoTextContainer = styled.div`
  display: flex;
  width: 672px;
  flex-direction: column;
  gap: 8px;
`;

export const EditEmployee = () => {
  const navigate = useNavigate();
  const companyId = useAppSelector(state => state.company.data!.id);
  const username = useAppSelector(state => state.company.data?.username);
  const params = useParams();
  const employeeId = params.id!;
  const [avatar, setAvatar] = useState<AvatarLoadType>();
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [phone, setPhone] = useState('');
  const [startCareer, setStartCareer] = useState<string | undefined>('');
  const [role, setRole] = useState<CompanyRoles>();
  const [biography, setBiography] = useState('');
  const [onlineBooking, setOnlineBooking] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [professions, setProfessions] = useState<
    {label: string; value: string}[]
  >([]);
  const [vk, setVk] = useState('');
  const [youtube, setYoutube] = useState('');
  const [modal, setModal] = useState(false);
  const [photos, setPhotos] = useState<
    Pick<ImageGQL, 'id' | 'url' | 'url_256'>[]
  >([]);
  const [imageVisible, setImageVisible] = useState(false);
  const [indexPhoto, setIndexPhoto] = useState(0);
  const [contacts, setContacts] = useState<Record<ContactType, string[]>>({
    phone: [],
    whatsapp: [],
    telegram: [],
    viber: [],
    email: [],
    website: [],
  });
  const [error, setError] = useState<
    ContactType | 'name' | 'telephone' | null
  >();
  const [errorStart, setErrorStart] = React.useState<string | undefined>(
    undefined,
  );
  const [activeEmployees, setActiveEmployees] = React.useState(1);
  const {data, loading} = useQuery(EMPLOYEE, {
    variables: {companyId, employeeId, first: 100},
    onCompleted: ({employee, getCompanyEmployeesLength}) => {
      setActiveEmployees(getCompanyEmployeesLength ?? 1);
      if (!employee) return;
      setAvatar(employee.avatar ?? undefined);
      setName(employee.name ?? '');
      setSurname(employee.surname ?? '');
      setPhone(getPhoneMask(employee.phone));
      setProfessions(
        employee.professions?.map(item => ({
          value: item.id,
          label: item.name,
        })) ?? [],
      );
      setRole(employee.role ?? undefined);
      setBiography(employee.biography ?? '');
      setOnlineBooking(employee.online_booking_enabled ?? false);
      setEnabled(employee.enabled ?? false);
      setVk(modifyUrl(employee.vk_url ?? ''));
      setYoutube(modifyUrl(employee.youtube_url ?? ''));
      setStartCareer(
        employee.career_start
          ? dayjs(employee.career_start).format('DD.MM.YYYY')
          : '',
      );
      setPhotos(employee.images?.data ?? []);
      const init: Record<ContactType, string[]> = {
        phone: [],
        whatsapp: [],
        telegram: [],
        viber: [],
        email: [],
        website: [],
      };
      employee.contacts.forEach(contact => {
        const type = contact.type;
        init[type].push(contact.value);
      });
      setContacts(init);
    },
  });
  const [update, {loading: updateLoading}] = useMutation(UPDATE_EMPLOYEE, {
    refetchQueries: [
      {
        query: GET_COMPANY_EMPLOYEES,
        variables: {
          companyId,
          first: 10,
          page: 1,
        },
      },
      'GetEditEmployee',
      {
        query: TUTORIALCHECK,
        variables: {
          companyId,
        },
      },
    ],
  });
  const [updateContacts] = useMutation(UPDATE_EMPLOYEE_CONTACTS);
  const employeeAvatar = data?.employee?.avatar?.url;
  const slots = data?.company?.slots ?? 1;

  if (loading) return <SkeletonEdit />;

  return (
    <Layout columns={12}>
      <Seo title="Редактирование сотрудника" />
      <Wrapper>
        <ImageView
          visible={imageVisible}
          onClose={() => setImageVisible(false)}
          images={photos.map(photo => photo.url)}
          index={indexPhoto}
        />
        <Popup visible={modal} onClose={() => setModal(false)}>
          <Grow style={{gap: 24}}>
            <Flex
              gap={12}
              direction="column"
              justifyContent="space-between"
              alignItems="center"
              style={{width: 440, paddingLeft: 32, paddingRight: 32}}>
              <Text align="center" typography="text16Semibold">
                Невозможно сделать сотрудника оказывающим услуги
              </Text>
              <Text typography="subHead14Regular" align="center">
                Улучшите тарифный план
              </Text>
            </Flex>
            <Flex gap={8} justifyContent="center">
              <Button
                style={{flex: 1}}
                variant="outline"
                size="large"
                onClick={() => setModal(false)}>
                Закрыть
              </Button>
              <Button
                style={{flex: 1}}
                size="large"
                onClick={() => {
                  navigate(`/${username}/tariff-plans`);
                }}>
                Улучшить тариф
              </Button>
            </Flex>
          </Grow>
        </Popup>
        <Head>
          <NavigateBack />
          <Text typography="title24">Редактирование сотрудника</Text>
        </Head>
        <Content style={{marginTop: 8}}>
          <Text typography="title18">Основная информация</Text>
          <AvatarCrop
            onChange={value => {
              setAvatar(value);
            }}
            onDelete={() => {
              setAvatar(undefined);
            }}
            url={avatar?.url ?? ''}
          />
          <TextInput
            label="Имя"
            value={name}
            required
            error={error === 'name' ? 'Обязательное поле' : undefined}
            onChange={text => {
              setName(text);
              setError(null);
            }}
          />
          <TextInput
            label="Фамилия"
            value={surname}
            onChange={text => {
              setSurname(text);
            }}
          />
          <Select
            data={[
              {value: CompanyRoles.Participant, label: 'Сотрудник'},
              {value: CompanyRoles.Admin, label: 'Администратор'},
              {value: CompanyRoles.Owner, label: 'Владелец'},
            ]}
            value={role}
            onChange={setRole}
            label="Роль сотрудника"
          />
          <TextInput
            label="Номер телефона"
            value={phone}
            mask={PHONE_MASK}
            error={
              error === 'telephone' ? 'Неправильный формат номера' : undefined
            }
            onChange={text => {
              setPhone(text);
              setError(null);
            }}
            required
          />
          <MultiSelectChips
            label="Специализация"
            values={professions}
            onDelete={value =>
              setProfessions(prev => prev.filter(item => item.value !== value))
            }
            modal={(visible, setVisible) => (
              <SelectProfessions
                label="Специализации"
                visible={visible}
                values={professions}
                onChange={values => {
                  setProfessions(values);
                  setVisible(false);
                }}
                onClose={() => setVisible(false)}
              />
            )}
          />
        </Content>
        <Content style={{marginTop: 24}}>
          <Text typography="title18">Дополнительно</Text>
          <DateInput
            value={startCareer}
            error={errorStart}
            label="Начало карьеры"
            onChange={value => {
              errorStart && setErrorStart(undefined);
              setStartCareer(value !== '' ? value : undefined);
            }}
          />
          <TextArea
            value={biography}
            onChange={setBiography}
            label="Описание"
            size="medium"
          />
        </Content>

        <Content gap={'16px'} style={{marginTop: 24}}>
          <Text typography="title18">Фотографии работ</Text>
          <Flex direction="column" gap={24}>
            <DragDrop
              onChange={value => {
                update({
                  variables: {
                    inputEmployee: {
                      employee_id: employeeId,
                      images: {
                        create: value?.map(item => ({
                          type: ImageType.Image,
                          ...item,
                        })),
                      },
                    },
                  },
                });
              }}
            />
            {photos.length ? (
              <Flex direction="row" style={{flexWrap: 'wrap'}} gap={8}>
                {photos.map((photo, index) => (
                  <Image
                    key={'booking_photo_' + photo.id}
                    src={photo.url}
                    onClick={() => {
                      setIndexPhoto(index);
                      setImageVisible(true);
                    }}
                    onDelete={() => {
                      update({
                        variables: {
                          inputEmployee: {
                            employee_id: employeeId,
                            images: {
                              delete: [photo.id],
                            },
                          },
                        },
                      });
                    }}
                  />
                ))}
              </Flex>
            ) : null}
            <PhotoTextContainer>
              <Text typography="subHead14Regular" color="textTertiary">
                Доступные форматы: jpeg, jpg, png
              </Text>
              <Text typography="subHead14Regular" color="textTertiary">
                Размер файла: до 12 Мб
              </Text>
            </PhotoTextContainer>
          </Flex>
        </Content>

        <Content style={{marginTop: 24}}>
          <Text typography="title18">Контакты для клиентов</Text>
          <Contact
            type={ContactType.Phone}
            initialData={contacts[ContactType.Phone]}
            setError={setError}
            error={error === ContactType.Phone}
            setInitialDate={data =>
              setContacts(prev => ({...prev, phone: data}))
            }
          />
          <Contact
            type={ContactType.Whatsapp}
            initialData={contacts[ContactType.Whatsapp]}
            setError={setError}
            error={error === ContactType.Whatsapp}
            setInitialDate={data =>
              setContacts(prev => ({...prev, whatsapp: data}))
            }
          />
          <Contact
            type={ContactType.Telegram}
            initialData={contacts[ContactType.Telegram]}
            setError={setError}
            error={error === ContactType.Telegram}
            setInitialDate={data =>
              setContacts(prev => ({...prev, telegram: data}))
            }
          />
          <Contact
            type={ContactType.Viber}
            initialData={contacts[ContactType.Viber]}
            setError={setError}
            error={error === ContactType.Viber}
            setInitialDate={data =>
              setContacts(prev => ({...prev, viber: data}))
            }
          />
          <Social type="vk" value={vk} setValue={setVk} />
          <Social type="youtube" value={youtube} setValue={setYoutube} />
        </Content>
        <Grow style={{gap: 16, marginTop: 24}}>
          <Flex alignItems="center">
            <TableViewRow title="Онлайн запись" />
            <Switch value={onlineBooking} onChange={setOnlineBooking} />
          </Flex>
          <Flex alignItems="center">
            <TableViewRow title="Оказывает услуги" subtitle="Занимает 1 слот" />
            <Switch
              value={enabled}
              onChange={() => {
                if (enabled) {
                  setActiveEmployees(item => item - 1);
                  setEnabled(prev => !prev);
                } else {
                  if (activeEmployees >= slots) {
                    setModal(true);
                  } else {
                    setActiveEmployees(item => item + 1);
                    setEnabled(prev => !prev);
                  }
                }
                console.log(activeEmployees);
              }}
            />
          </Flex>
          <Button
            style={{marginTop: 16}}
            size="large"
            loading={loading || updateLoading}
            disabled={loading || updateLoading}
            onClick={async () => {
              try {
                const input = [
                  ...contacts.phone.map(value => ({
                    value,
                    type: ContactType.Phone,
                  })),
                  ...contacts.telegram.map(value => ({
                    value,
                    type: ContactType.Telegram,
                  })),
                  ...contacts.viber.map(value => ({
                    value,
                    type: ContactType.Viber,
                  })),
                  ...contacts.whatsapp.map(value => ({
                    value,
                    type: ContactType.Whatsapp,
                  })),
                ];
                if (!name.trim().length) {
                  setError('name');
                  throw new Error('Имя сотрудника не введено');
                }
                if (phone.length < 18) {
                  setError('telephone');
                  throw new Error('Номер телефона введен некорректно');
                }
                const nullContact = input.find(item => item.value === '');
                if (nullContact) {
                  setError(nullContact.type);
                  throw new Error('Пустое поле');
                }
                await Promise.all([
                  updateContacts({
                    variables: {
                      input,
                      employeeId,
                    },
                  }),
                  update({
                    variables: {
                      inputEmployee: {
                        employee_id: employeeId,
                        avatar: processedAvatar(avatar, employeeAvatar),
                        name,
                        surname,
                        phone,
                        enabled,
                        online_booking_enabled: onlineBooking,
                        role,
                        biography: biography,
                        career_start: startCareer ? startCareer : undefined,
                        professions: professions.map(item => item.value),
                        vk_url: vk === '' ? '' : 'https://' + vk,
                        youtube_url:
                          youtube === '' ? '' : 'https://www.' + youtube,
                      },
                    },
                  }),
                ]);
                navigate(-1);
              } catch (e) {
                if (e instanceof Error) {
                  showAlert('error', e.message);
                }
              }
            }}>
            Сохранить
          </Button>
        </Grow>
      </Wrapper>
    </Layout>
  );
};
