import dayjs from 'dayjs';
import React from 'react';
import {Flex} from 'shared/ui/Flex';
import {Image} from 'shared/ui/Image';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  pointer-events: all;
  cursor: pointer;
`;

type Props = {
  url?: string;
  title: string;
  text: string;
  created: string;
};

export const RenderItem = ({url, title, text, created}: Props) => {
  const today = dayjs();
  return (
    <Wrapper>
      <Flex direction="row" alignItems="center" justifyContent="space-between">
        <Flex alignItems="center" gap={12}>
          <Image width={40} height={40} radius={10} src={url} />
          <Text
            style={{pointerEvents: 'none'}}
            typography="text16Regular"
            color="textTertiary">
            {title}
          </Text>
        </Flex>
        <Text
          style={{pointerEvents: 'none'}}
          typography="subHead14Regular"
          color="textTertiary">
          {dayjs(created).format(
            today.isSame(dayjs(created), 'date')
              ? 'HH:mm'
              : 'DD.MM.YYYY, HH:mm',
          )}
        </Text>
      </Flex>
      <Text
        style={{pointerEvents: 'none'}}
        typography="text16Regular"
        color="textPrimary">
        {text}
      </Text>
    </Wrapper>
  );
};
