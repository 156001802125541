import React from 'react';
import styled from 'styled-components';
import {device} from 'shared/device';
import {Skeleton} from 'shared/ui/Skeleton';
import {Table} from 'shared/ui/Table';
import {useResize} from 'shared/lib/hooks';
import {Content} from 'shared/ui/Content';

const COLUMN = 5;

const RectName = styled.rect<{index: number}>`
  @media ${device.desktop} {
    display: flex;
  }
`;

const MobileSkeleton = styled.div`
  height: 800px;
  margin-top: 8px;
`;

const columns = Array.from(Array(COLUMN).keys());

export const ReviewsSkeleton = () => {
  const titles = ['Дата', 'Клиент', 'Сотрудник', 'Оценка', 'Отзыв'];
  const widths = [178, 304, 304, 214, 566];
  const isMobile = useResize().isMobile;

  return (
    <>
      {isMobile ? (
        <MobileSkeleton>
          <Skeleton>
            <rect width="100%" height={250} rx={20} />
            <rect width="100%" height={250} rx={20} y={258} />
            <rect width="100%" height={250} rx={20} y={516} />
          </Skeleton>
        </MobileSkeleton>
      ) : (
        <Content style={{margin: 24}}>
          <Table
            titles={titles}
            widths={widths}
            rows={columns.map((columnItem, index) => [
              <Skeleton key={index.toString() + '1'} height="34px">
                <RectName index={columnItem} rx={8} width={80} height={14} />
                <RectName
                  index={columnItem}
                  width={35}
                  height={12.5}
                  rx={8}
                  y={20}
                />
              </Skeleton>,
              <Skeleton key={index.toString() + '2'} height="14px">
                <RectName index={columnItem} rx={8} width={145} height={14} />
              </Skeleton>,
              <Skeleton key={index.toString() + '3'} height="14px">
                <RectName index={columnItem} rx={8} width={145} height={14} />
              </Skeleton>,
              <Skeleton key={index.toString() + '4'} height="24px">
                <circle r={12} cx={12} cy={12} />
                <circle r={12} cx={42} cy={12} />
                <circle r={12} cx={72} cy={12} />
                <circle r={12} cx={102} cy={12} />
                <circle r={12} cx={132} cy={12} />
              </Skeleton>,
              <Skeleton key={index.toString() + '5'} height="34px">
                <RectName index={columnItem} rx={8} width={500} height={14} />
                <RectName
                  index={columnItem}
                  rx={8}
                  width={500}
                  height={14}
                  y={20}
                />
              </Skeleton>,
            ])}
          />
        </Content>
      )}
    </>
  );
};
