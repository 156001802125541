import React from 'react';
import styled from 'styled-components';

type Props<Item> = {
  gap?: number;
  keyExtractor?: (
    section: {title: string; data: Item[]},
    index: number,
  ) => string | number;
  renderItem: (item: Item, index: number) => React.ReactElement;
  sectionItem: (section: {title: string; data: Item[]}) => React.ReactElement;
  sections: {title: string; data: Item[]}[];
  style?: React.CSSProperties;
  sectionStyle?: React.CSSProperties;
  showVerticalScrollIndicator?: boolean;
  stickySectionHeadersEnabled?: boolean;
  ListEmptyComponent?: React.ReactElement;
  ListFooterComponent?: React.ReactElement;
  ListHeaderComponent?: React.ReactElement;
};

const WrapperSectionList = styled.div<{
  gap: number;
  showVerticalScrollIndicator?: boolean;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${({gap}) => gap}px;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: ${({showVerticalScrollIndicator}) =>
      showVerticalScrollIndicator ? 'initial' : 'none'};
  }
  &::-webkit-scrollbar-corner {
    display: none;
  }
`;

const TitleWrapper = styled.div<{stickySectionHeadersEnabled?: boolean}>`
  position: ${({stickySectionHeadersEnabled}) =>
    stickySectionHeadersEnabled ? 'sticky' : 'static'};
  top: 0;
  background-color: ${({theme}) => theme.bgPrimary};
  z-index: 1;
`;

export const SectionList = <Item,>({
  gap = 8,
  renderItem,
  sectionItem,
  sections,
  keyExtractor,
  style,
  sectionStyle,
  showVerticalScrollIndicator = true,
  stickySectionHeadersEnabled = true,
  ListHeaderComponent,
  ListFooterComponent,
  ListEmptyComponent,
}: Props<Item>) => {
  if (sections.length === 0 && ListEmptyComponent) {
    return ListEmptyComponent;
  }
  return (
    <>
      {ListHeaderComponent}
      <WrapperSectionList
        showVerticalScrollIndicator={showVerticalScrollIndicator}
        gap={gap}
        style={style}>
        {sections.map((section, sectionIndex) => (
          <React.Fragment
            key={
              keyExtractor
                ? keyExtractor(section, sectionIndex)
                : 'section_title_' + sectionIndex
            }>
            <TitleWrapper
              stickySectionHeadersEnabled={stickySectionHeadersEnabled}
              style={sectionStyle}>
              {sectionItem(section)}
            </TitleWrapper>
            {section.data.map((item, index) => renderItem(item, index))}
          </React.Fragment>
        ))}
      </WrapperSectionList>
      {ListFooterComponent}
    </>
  );
};
