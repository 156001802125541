import React from 'react';
import {LogoCircle} from 'shared/icons/LogoCircle';
import {Flex} from 'shared/ui/Flex';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';

const Wrapper = styled.div`
  background-color: ${({theme}) => theme.bgPrimary};
  border-radius: 18px;
  padding: 16px 24px;
  flex-direction: row;
  gap: 12px;
  display: flex;
  flex-direction: row;
  height: min-content;
  grid-row-start: 2;
  grid-column-start: 2;
  grid-column-end: 9;
`;

export const WelcomeChat = () => {
  return (
    <Wrapper>
      <LogoCircle />
      <Flex direction="column" gap={16}>
        <Text typography="title24">Привет, я – нейросеть Booky!</Text>
        <Text typography="text18">
          Я могу ответить на любые интересующие вас вопросы.
        </Text>
      </Flex>
    </Wrapper>
  );
};
