import React from 'react';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';
import {Content} from 'shared/ui/Content';
import {Head} from 'shared/ui/Head';
import {Button} from 'shared/ui/Button';
import {useNavigate} from 'react-router-dom';
import {device} from 'shared/device';
import {Flex} from 'shared/ui/Flex';
import {CompanyBookingType} from 'shared/__generated__/graphql';
import {gql} from 'shared/__generated__';
import {useAppSelector} from 'shared/store';
import {useMutation, useQuery} from '@apollo/client';
import {showAlert} from 'shared/ui/Alert';
import {Select} from 'shared/ui/Select';
import {ensureError, getMinutStartBooking} from 'shared/lib/utils';
import {Error} from 'shared/ui/Error';
import {Loading} from 'shared/ui/Loading';
import {NavigateBack} from 'shared/ui/NavigateBack';
import {Seo} from 'shared/ui/Seo';

interface IWeenMinuteItem {
  id: number;
  name: string;
  value: number;
}
const getWeekName = (i: number) =>
  i % 10 === 1 && i !== 11
    ? `${i} неделя`
    : i > 10 && i < 15
    ? `${i} недель`
    : i % 10 > 1 && i % 10 < 5
    ? `${i} недели`
    : `${i} недель`;

export const getWeekOnlineBooking = (value: number) => {
  const arr: IWeenMinuteItem[] = [];
  for (let i = 1; i <= value; i++) {
    arr.push({
      id: i,
      name: getWeekName(i),
      value: i,
    });
  }
  return arr;
};

const SETTING = gql(`
  query GetCompanySetting($id: String!) {
    company(id: $id) {
      id
      online_booking_availability_weeks
      minutes_before_start
      booking_type
    }
  }
`);

const UPDATE_SETTING = gql(`
  mutation UpdateSetting($input: UpdateCompany!) {
    updateCompany(input: $input) {
      id
      online_booking_availability_weeks
      minutes_before_start
      booking_type
    }
  }
`);

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0 auto;
  max-width: 720px;
  min-height: 100dvh;
  border-radius: 0;
  padding-top: 64px;
  padding-bottom: 8px;
  @media screen and (${device.mobile}) {
    margin-top: 56px;
  }
`;

export const JournalSetting = () => {
  const navigate = useNavigate();
  const username = useAppSelector(state => state.company.data?.username);
  const companyId = useAppSelector(state => state.company.data!.id);
  const weeksList = getWeekOnlineBooking(20);
  const timeList = getMinutStartBooking(18);

  const [weekIndex, setWeekIndex] = React.useState(1);
  const [timeIndex, setTimeIndex] = React.useState(1);
  const [bookingMethodIndex, setBookingMethodIndex] =
    React.useState<CompanyBookingType>();
  const BookingMethodList = [
    {name: 'Booky', value: CompanyBookingType.Booky},
    {name: 'Контакты', value: CompanyBookingType.Contacts},
    {name: 'Сторонний веб-сервис', value: CompanyBookingType.Another},
  ];
  const {error, loading} = useQuery(SETTING, {
    variables: {id: companyId},
    onCompleted: data => {
      const _weekIndex =
        weeksList.find(
          item =>
            item.value === data?.company?.online_booking_availability_weeks,
        )?.value ?? 1;
      setWeekIndex(_weekIndex);
      const _timeIndex = timeList.findIndex(
        item => item.value === data?.company?.minutes_before_start,
      );
      setTimeIndex(_timeIndex !== -1 ? _timeIndex : 0);
      setBookingMethodIndex(
        BookingMethodList.find(
          item => item.value === data?.company?.booking_type,
        )?.value ?? undefined,
      );
    },
  });
  const [update, {loading: updateLoading}] = useMutation(UPDATE_SETTING, {
    refetchQueries: [SETTING],
  });

  const submit = async () => {
    try {
      type InputVariables = {
        id: string;
        online_booking_availability_weeks: number;
        minutes_before_start: number;
        booking_type: CompanyBookingType;
      };
      const inputVariables: InputVariables = {
        id: companyId,
        online_booking_availability_weeks: weeksList.find(
          item => item.value === weekIndex,
        )!.value,
        minutes_before_start: timeList[timeIndex].value,
        booking_type: bookingMethodIndex!,
      };
      await update({
        variables: {
          input: inputVariables,
        },
      });
      showAlert('success', 'Сохранено');
    } catch (e) {
      const error = ensureError(e);
      showAlert('error', error.message);
    }
  };

  if (loading) {
    return (
      <Wrapper>
        <Loading />
      </Wrapper>
    );
  }
  if (error) {
    return (
      <Wrapper>
        <Error message={error.message} />
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      <Seo title="Настройки онлайн-записи" />
      <Head style={{marginBottom: 32}}>
        <NavigateBack />
        <Text typography="title24">Настройки онлайн-записи</Text>
      </Head>
      <Flex direction="column" gap={32}>
        <Content>
          <Select
            variant="with_label"
            label="Способ записи"
            data={BookingMethodList.map(item => ({
              value: item.value,
              label: item.name,
            }))}
            value={bookingMethodIndex}
            onChange={selected => {
              setBookingMethodIndex(selected);
            }}
          />
          {bookingMethodIndex === 'booky' && (
            <>
              <Select
                variant="with_label"
                label="Определите доступное время для предварительной записи"
                data={weeksList.map(item => ({
                  value: item.id,
                  label: item.name,
                }))}
                value={weekIndex}
                onChange={selected => {
                  setWeekIndex(selected);
                }}
              />
              <Select
                variant="with_label"
                label="За сколько минут до начала услуги доступна онлайн-запись?"
                data={timeList.map(item => ({
                  value: item.id,
                  label: item.name,
                }))}
                value={timeIndex}
                onChange={selected => {
                  setTimeIndex(selected);
                }}
              />
            </>
          )}
          {bookingMethodIndex === 'contacts' && (
            <Text typography="text16Regular">
              Можете задать их в разделе{' '}
              <Text
                display="inline"
                onClick={() => {
                  navigate(`/${username}/company/contact`);
                }}
                typography="text16Regular"
                color="mainPrimary">
                Контакты.
              </Text>
            </Text>
          )}
          {bookingMethodIndex === 'another' && (
            <Text typography="text16Regular">
              Можете задать ссылку на сайт в разделе{' '}
              <Text
                display="inline"
                onClick={() => {
                  navigate(`/${username}/company/contact`);
                }}
                typography="text16Regular"
                color="mainPrimary">
                Контакты
              </Text>
              , клиентам будет открываться первая ссылка из списка.
            </Text>
          )}
        </Content>
        <Button
          size="large"
          disabled={updateLoading}
          loading={updateLoading}
          onClick={submit}>
          Сохранить
        </Button>
      </Flex>
    </Wrapper>
  );
};
