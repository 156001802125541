import React, {useState} from 'react';
import {Content} from 'shared/ui/Content';
import {Text} from 'shared/ui/Text';
import {TextInput} from 'shared/ui/TextInput';
import {Button} from 'shared/ui/Button';
import styled from 'styled-components';
import {gql} from 'shared/__generated__';
import {useMutation, useQuery} from '@apollo/client';
import {Gender, ensureError} from 'shared/lib/utils';
import {device} from 'shared/device';
import {showAlert} from 'shared/ui/Alert';
import {Tooltip} from 'shared/ui/Tooltip';
import Info from 'shared/icons/Info';
import {useColors} from 'shared/lib/hooks';
import DotsVertical from 'shared/icons/DotsVertical';
import {Flex} from 'shared/ui/Flex';
import {Popup} from 'shared/ui/Popup';
import {useAppSelector} from 'shared/store';
import {useNavigate} from 'react-router-dom';
import {BottomSheet} from 'shared/ui/BottomSheet';
import {TableViewRow} from 'shared/ui/TableViewRow';
import CircleAccept from 'shared/icons/CircleAccept';
import {Pencil} from 'shared/icons/Pencil';
import {Trash} from 'shared/icons/Trash';
import {RadioButton} from 'shared/ui/RadioButton';
import {
  CustomerGender,
  ImageType,
  ImageUpload,
} from 'shared/__generated__/graphql';
import {AvatarCrop} from 'shared/ui/AvatarCrop';
import {CloseButton} from 'shared/ui/CloseButton';
import {Seo} from 'shared/ui/Seo';
import {DateInput} from 'shared/ui/DateInput';
import dayjs from 'dayjs';
const ME = gql(`
  query UserData {
    me {
      id
      name
      surname
      phone
      email
      temp_email
      birthdate
      gender
      avatar {
        id
        type
        url
      }
    }
  }
`);

const UPDATE_ME = gql(`
  mutation UpdateMe($input: UpdateMe!) {
    updateMe(input: $input) {
      id
      phone
      surname
      name
      email
      temp_email
      has_password
      employees {
        id
        company {
          id
        }
        role
      }
      created_at
      updated_at
      birthdate
      gender
      avatar {
        id
        type
        url
        url_64
        url_128
        url_256
        url_1536
      }
    }
  }
`);
const SENDCODE = gql(`
  mutation SendEmailVerification {
    sendEmailVerification
  }
`);
const Wrapper = styled.div`
  display: flex;
  flex: 1;
  padding-bottom: 24px;
  flex-direction: column;
  margin-top: 8px;
  gap: 32px;
  @media (${device.mobile}) {
    gap: 8px;
    margin-bottom: 0;
    border-radius: 0;
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const StyledTableViewRow = styled(TableViewRow)`
  gap: 10px;
  padding: 11px 16px 11px 16px;
  &:hover {
    background: ${props => props.theme.bgSecondary};
  }
`;
const DotsButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  margin: 0px;
`;

export function ProfileSet() {
  const navigate = useNavigate();
  const userId = useAppSelector(state => state.auth.userId);
  const colors = useColors();
  const {data} = useQuery(ME, {
    onCompleted({me}) {
      if (me) {
        setAvatar(me.avatar as Omit<ImageUpload, 'type'>);
        setName(me.name ?? '');
        setSurname(me.surname ?? '');
        setBirthdate(
          me.birthdate ? dayjs(me.birthdate).format('DD.MM.YYYY') : '',
        );
        setGender(me.gender ?? undefined);
        setTempMail(me.temp_email ?? '');
      }
    },
  });
  const [updateMe, {loading: updating}] = useMutation(UPDATE_ME, {
    refetchQueries: [ME],
  });

  const user = data?.me;
  const [avatar, setAvatar] = useState<Omit<ImageUpload, 'type'>>();
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState<string | null>(null);
  const [surname, setSurname] = useState('');
  const [tempMail, setTempMail] = useState('');
  const [birthdate, setBirthdate] = useState<string>('');
  const [gender, setGender] = useState<string | null>();
  const [error, setError] = React.useState<string | null>(null);

  const [dotsModal, setDotsModal] = useState(false);
  const [emailModal, setEmailModal] = useState(false);
  const emailConfirmed = !user?.temp_email && user?.email != null;

  const [updateMail, {loading}] = useMutation(UPDATE_ME);
  const [sendMailCode, {loading: mailCodeSending}] = useMutation(SENDCODE, {
    refetchQueries: ['GetProfile'],
  });

  const updateEmail = async () => {
    if (!tempMail) {
      return setError('Введиту почту');
    } else {
      try {
        await updateMail({
          variables: {
            input: {
              id: userId!,
              temp_email: tempMail,
            },
          },
        });
        await sendMailCode();
        navigate('/email-code', {state: {tempMail: tempMail}});
      } catch (e) {
        const error = ensureError(e);
        showAlert('error', error.message);
      }
    }
  };
  const deleteTempMail = async () => {
    try {
      await updateMe({
        variables: {
          input: {
            id: userId!,
            temp_email: null,
          },
        },
      });
      showAlert('success', 'Новая почта успешно удалена');
    } catch (e) {
      const error = ensureError(e);
      showAlert('error', error.message);
    } finally {
      setDotsModal(false);
    }
  };
  const check = async () => {
    if (!name) {
      setNameError('Обязательное поле');
    } else {
      try {
        await updateMe({
          variables: {
            input: {
              id: user!.id,
              name: name,
              surname: surname,
              birthdate: birthdate !== '' ? birthdate : undefined,
              avatar: avatar
                ? avatar.url === data?.me?.avatar?.url
                  ? undefined
                  : {type: ImageType.Avatar, ...avatar}
                : {type: ImageType.Avatar, url: null},
              gender: gender ?? null,
            },
          },
        });
        showAlert('success', 'Сохранено');
      } catch (e) {
        const error = ensureError(e);
        showAlert('error', error.message);
      }
    }
  };

  return (
    <Wrapper>
      <Seo title="Редактировать профиль" />
      <Content>
        <Text typography="title18">Основная информация</Text>
        <AvatarCrop
          onChange={value => {
            setAvatar(value);
          }}
          onDelete={() => {
            setAvatar(undefined);
          }}
          url={avatar?.url ?? undefined}
        />
        <TextInput
          label="Имя"
          required
          error={nameError}
          value={name}
          onChange={text => {
            setName(text);
            setNameError(null);
          }}
        />
        <TextInput
          label="Фамилия"
          value={surname}
          onChange={text => {
            setSurname(text);
          }}
        />
        <TextInput
          disabled
          style={{color: colors.textPrimary}}
          label="E-mail"
          value={user?.temp_email ? user?.temp_email : user?.email ?? ''}
          rightElement={
            <>
              {emailConfirmed ? (
                <Text
                  typography="text16Regular"
                  color="mainPrimary"
                  onClick={() => {
                    setEmailModal(true);
                  }}>
                  Изменить
                </Text>
              ) : (
                <Flex gap={12} style={{position: 'relative'}}>
                  <Tooltip
                    position="right"
                    style={{padding: '8px 12px', borderRadius: 8}}
                    text={'Почта не подтверждена'}>
                    <Info />
                  </Tooltip>
                  <DotsButton
                    style={{cursor: 'pointer'}}
                    onClick={() => {
                      setDotsModal(true);
                    }}>
                    <DotsVertical />
                  </DotsButton>
                  <BottomSheet
                    backdropStyle={{backgroundColor: 'transparent'}}
                    style={{
                      top: 42,
                      left: 'auto',
                      right: 0,
                    }}
                    visible={dotsModal}
                    onClose={() => {
                      setDotsModal(false);
                    }}>
                    <Flex direction="column" gap={8}>
                      {tempMail && (
                        <StyledTableViewRow
                          pointer
                          onClick={() => {
                            sendMailCode();
                            navigate('/email-code', {
                              state: {tempMail: tempMail},
                            });
                          }}
                          title="Подтвердить почту"
                          leftElement={() => <CircleAccept />}
                        />
                      )}
                      <StyledTableViewRow
                        pointer
                        onClick={() => {
                          setDotsModal(false);
                          setEmailModal(true);
                        }}
                        title="Изменить почту"
                        leftElement={() => <Pencil size={24} />}
                      />
                      {tempMail && (
                        <StyledTableViewRow
                          pointer
                          onClick={deleteTempMail}
                          title="Удалить новую почту"
                          leftElement={() => <Trash />}
                        />
                      )}
                    </Flex>
                  </BottomSheet>
                </Flex>
              )}
            </>
          }
        />
        <DateInput
          value={birthdate}
          label="Дата рождения"
          onChange={value => setBirthdate(value)}
        />
        <Flex direction="column" gap={8}>
          <Text typography="subHead14Regular" color="textTertiary">
            Пол
          </Text>
          <Flex flex={1} style={{height: 48}} alignItems="center">
            <Flex flex={1}>
              <RadioButton
                labelPosition="right"
                title={Gender.M}
                selected={gender === CustomerGender.M}
                onChange={() => {
                  if (gender === CustomerGender.M) {
                    setGender(undefined);
                  } else {
                    setGender(CustomerGender.M);
                  }
                }}
              />
            </Flex>
            <Flex flex={1}>
              <RadioButton
                labelPosition="right"
                title={Gender.F}
                selected={gender === CustomerGender.F}
                onChange={() => {
                  if (gender === CustomerGender.F) {
                    setGender(undefined);
                  } else {
                    setGender(CustomerGender.F);
                  }
                }}
              />
            </Flex>
          </Flex>
        </Flex>
      </Content>

      <Button size="large" loading={updating} onClick={check}>
        Сохранить
      </Button>

      <Popup
        visible={emailModal}
        style={{width: 588, alignItems: 'initial'}}
        onClose={() => {
          setEmailModal(false);
        }}>
        <Flex direction="column" gap={24}>
          <Flex flex={1} justifyContent="space-between" alignItems="center">
            <Text typography="title20" color="textPrimary">
              Изменить почту
            </Text>
            <CloseButton
              onClose={() => {
                setEmailModal(false);
              }}
            />
          </Flex>
          <TextInput
            type="email"
            variant="with_label"
            label="Введите новую почту"
            placeholder="Новая почта"
            error={error}
            value={tempMail}
            onChange={text => {
              setTempMail(text);
              setError(null);
            }}
          />
          <Button
            size="large"
            loading={loading || mailCodeSending}
            disabled={loading || mailCodeSending}
            onClick={updateEmail}>
            Получить код
          </Button>
        </Flex>
      </Popup>
    </Wrapper>
  );
}
