import {
  MessageSampleBookingStatus,
  MessageSampleEvent,
  MessageSampleMethod,
  MessageSampleTime,
} from 'shared/__generated__/graphql';

export const getMethod = (method: MessageSampleMethod) => {
  switch (method) {
    case MessageSampleMethod.Sms:
      return 'СМС';
    case MessageSampleMethod.Telegram:
      return 'Telegram';
    case MessageSampleMethod.Whatsapp:
      return 'WhatsApp';
    case MessageSampleMethod.CpTelegram:
      return 'ChatPush-Telegram';
    case MessageSampleMethod.CpWhatsapp:
      return 'ChatPush-WhatsApp';
    default:
      return 'Sms.ru';
  }
};
export const getEvent = (event: MessageSampleEvent) => {
  switch (event) {
    case MessageSampleEvent.After:
      return 'После времени визита';
    case MessageSampleEvent.Before:
      return 'Перед временем визита';
    default:
      return 'После создания записи';
  }
};
export const getTime = (time: MessageSampleTime) => {
  switch (time) {
    case MessageSampleTime.Halfhour:
      return '30 минут';
    case MessageSampleTime.Hour:
      return 'Час';
    case MessageSampleTime.Threehours:
      return '3 часа';
    case MessageSampleTime.Day:
      return '1 день';
    default:
      return 'Сразу';
  }
};
export const getStatus = (status: boolean) => {
  if (status) return 'Включён';
  return 'Отключён';
};

export const messageSampleMethods: {
  label: string;
  value: MessageSampleMethod;
}[] = [
  {label: 'Ручная рассылка смс', value: MessageSampleMethod.Sms},
  {label: 'Ручная рассылка Telegram', value: MessageSampleMethod.Telegram},
  {label: 'Ручная рассылка WhatsApp', value: MessageSampleMethod.Whatsapp},
  {label: 'Sms.ru', value: MessageSampleMethod.SmsRu},
  {label: 'ChatPush-Telegram', value: MessageSampleMethod.CpTelegram},
  {label: 'ChatPush-WhatsApp', value: MessageSampleMethod.CpWhatsapp},
];
export const messageSampleEvents: {
  label: string;
  value: MessageSampleEvent;
}[] = [
  {label: 'После времени визита', value: MessageSampleEvent.After},
  {label: 'Перед временем визита', value: MessageSampleEvent.Before},
  {label: 'После создания записи', value: MessageSampleEvent.Create},
];
export const messageSampleTimes: {
  label: string;
  value: MessageSampleTime;
}[] = [
  {label: 'Сразу', value: MessageSampleTime.Now},
  {label: '30 минут', value: MessageSampleTime.Halfhour},
  {label: 'Час', value: MessageSampleTime.Hour},
  {label: '3 часа', value: MessageSampleTime.Threehours},
  {label: '1 день', value: MessageSampleTime.Day},
];
export const messageSampleBookingsStatus: {
  label: string;
  value: MessageSampleBookingStatus;
}[] = [
  {label: 'Все записи', value: MessageSampleBookingStatus.All},
  {label: 'Отмененные', value: MessageSampleBookingStatus.Cancelled},
  {label: 'Завершенные', value: MessageSampleBookingStatus.Completed},
  {label: 'Подверженные', value: MessageSampleBookingStatus.Confirmed},
  {label: 'В ожидании', value: MessageSampleBookingStatus.Pending},
];

export type IPreset = {label: string; value: string; example: string};
export const presets: IPreset[] = [
  {label: 'имя Вашего клиента', value: '{customer_name}', example: 'Лена'},
  {
    label: 'фамилия Вашего клиента',
    value: '{customer_surname}',
    example: 'Олесовна',
  },
  {label: 'название Вашей локации', value: '{company_name}', example: 'Салон'},
  {
    label: 'название Вашей услуги',
    value: '{services_name}',
    example: 'сделать тату',
  },
  {
    label: 'полное имя сотрудника, к которому записались на прием',
    value: '{manager_name}',
    example: 'Колесников Артем',
  },
  {
    label: 'имя сотрудника, к которому записались на приём',
    value: '{manager_first_name}',
    example: 'Артем',
  },
  {
    label: 'фамилия сотрудника, к которому записались на приём',
    value: '{manager_last_name}',
    example: 'Колесников',
  },
  {
    label: 'должность сотрудника',
    value: '{manager_position}',
    example: 'сотрудник',
  },
  {label: 'дата онлайн-записи', value: '{date}', example: '18.02.2024'},
  {
    label: 'время на которое назначена онлайн-запись',
    value: '{time}',
    example: '15:00',
  },
  {
    label: 'общая длительность записи',
    value: '{full_duration}',
    example: '30',
  },
  {
    label: 'ссылка на страницу сведений об онлайн-записи',
    value: '{url}',
    example: 'https://salon.site.co/booking/5369156',
  },
  {
    label: 'количество бронирований на сегодня',
    value: '{booking_amount_today}',
    example: '2',
  },
  {
    label: 'количество бронирований на завтра',
    value: '{booking_amount_tomorrow}',
    example: '3',
  },
];
