import React from 'react';
import {useColors} from 'shared/lib/hooks';

export function DoubleArrow() {
  const colors = useColors();
  const color = colors.textTertiary;
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.9999 19L6.99988 12L12.9999 5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.9998 18.9999L10.9998 11.9999L16.9998 4.99991"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
