import React, {useState} from 'react';
import styled from 'styled-components';
import {Text} from 'shared/ui/Text';
import {Flex} from 'shared/ui/Flex';
import {Button} from 'shared/ui/Button';
import {Trash} from 'shared/icons/Trash';
import {useColors} from 'shared/lib/hooks';
import {Share} from 'shared/icons/Share';
import {Popup} from 'shared/ui/Popup';
import {CloseButton} from './CloseButton';
import {CSVLink} from 'react-csv';
import {Data} from 'react-csv/lib/core';

const PopupWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  width: 390px;
  gap: 8px;
`;

const Footer = styled.footer`
  position: sticky;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  bottom: 0;
  height: 88px;
  left: 0;
  right: 0;
  background-color: ${({theme}) => theme.bgPrimary};
  border-top: 1px solid ${({theme}) => theme.borderPrimary};
  z-index: 900;
`;

const CSVButton = styled(CSVLink)`
  display: flex;
  gap: 8px;
  height: 48px;
  border: 1px solid ${({theme}) => theme.mainPrimary};
  border-radius: 14px;
  text-decoration-line: none;
  align-items: center;
  justify-content: center;
  padding: 0 32px;
`;

export const FooterAction = ({
  selectedCount,
  allICount,
  onDelete,
  deleteLoading,
  disabled,
  title,
  text,
  editPermission,
  exportData,
  exportable,
  filename = 'filenameCSV',
  popupStyle,
}: {
  popupStyle?: React.CSSProperties;
  selectedCount?: number;
  allICount?: number;
  onDelete?: () => void;
  deleteLoading?: boolean;
  disabled?: boolean;
  title?: string;
  text?: string;
  editPermission?: boolean;
  exportable?: boolean;
  exportData?: Data;
  filename?: string;
}) => {
  const colors = useColors();
  const [visible, setVisible] = useState(false);
  if (!selectedCount) return null;
  return (
    <Footer>
      <Popup
        style={{zIndex: 1000, ...popupStyle}}
        visible={visible}
        onClose={() => {
          setVisible(false);
        }}>
        <PopupWrapper>
          <Text
            style={{
              display: 'flex',
              flex: 1,
            }}
            typography="title20"
            color="textPrimary">
            {title}
          </Text>
          <CloseButton
            onClose={() => {
              setVisible(false);
            }}
          />
        </PopupWrapper>
        <PopupWrapper>
          <Text typography="text16Regular" color="textPrimary">
            {text}
          </Text>
        </PopupWrapper>
        <PopupWrapper>
          <Button
            size="large"
            variant="outline"
            style={{flex: 1}}
            onClick={() => {
              setVisible(false);
            }}>
            Отмена
          </Button>
          <Button
            size="large"
            loading={deleteLoading}
            disabled={deleteLoading}
            variant="outline"
            style={{flex: 1, borderColor: colors.dangerPrimary}}
            onClick={() => {
              onDelete && onDelete();
              setVisible(false);
            }}>
            <Text
              style={{cursor: 'pointer'}}
              typography="text16Semibold"
              color="dangerPrimary">
              Удалить
            </Text>
          </Button>
        </PopupWrapper>
      </Popup>
      <Flex gap={8}>
        <Text typography="text16Regular" color="textTertiary">
          Выбрано:
        </Text>
        <Text typography="text16Regular" color="textPrimary">
          {selectedCount} из {allICount}
        </Text>
      </Flex>
      <Flex gap={8}>
        {editPermission && (
          <Button
            disabled={disabled}
            onClick={() => {
              setVisible(true);
            }}
            size="medium"
            style={{
              borderWidth: 1,
              borderColor: colors.dangerPrimary,
            }}
            variant="subtitle"
            leftIcon={<Trash color={colors.dangerPrimary} size={20} />}>
            <Text typography="subHead14Medium" color="dangerPrimary">
              Удалить
            </Text>
          </Button>
        )}
        {exportable && (
          <CSVButton filename={`${filename}.csv`} data={exportData ?? []}>
            {<Share size={20} />}
            <Text typography="subHead14Medium" color="mainPrimary">
              Экспорт в Excel
            </Text>
          </CSVButton>
        )}
      </Flex>
    </Footer>
  );
};
