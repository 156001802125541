import axios from 'axios';
import {gql} from 'shared/__generated__';
import {client} from 'shared/config/apollo';
import {store} from 'shared/store';
import {ImageUpload} from 'shared/__generated__/graphql';

const GET_ME = gql(`
  query GetMe {
    me {
      id
      name
    }
  }
`);

const DOMAIN = process.env.REACT_APP_DOMAIN;

async function post(
  route: string,
  data?: Record<string, string>,
): Promise<Record<string, string>> {
  const token = store.getState().auth.token;
  const headers = {
    ...(token ? {Authorization: `Bearer ${token}`} : null),
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };
  const res = await fetch(`${DOMAIN}${route}`, {
    method: 'POST',
    headers,
    body: JSON.stringify(data),
  });
  const json = await res.json();
  if (!res.ok) {
    throw new Error(json.message);
  }
  return json;
}

export async function getEmailCode(email: string, token: string) {
  await post(`/api/auth/password/email/recovery `, {
    email,
    ['g-recaptcha-response']: token,
  });
}

export async function getSmsCode(phone: string, token: string) {
  await post(`/api/auth/get-code`, {
    phone,
    ['g-recaptcha-response']: token,
  });
}

export async function loginPassword(
  login: string,
  password: string,
): Promise<string> {
  const res = await post(`/api/auth/login-password`, {
    login: login,
    password: password,
  });
  return res.access_token;
}

export async function login(phone: string, code: string): Promise<string> {
  const res = await post(`/api/auth/login`, {
    phone: phone,
    code: code,
  });
  return res.access_token;
}

export async function getMe() {
  const me = await client.query({query: GET_ME});
  return me.data.me;
}

export async function loginEmail(code: string) {
  await post('/api/auth/password/email/code', {
    code,
  });
}

export async function checkEmail(email: string) {
  await post('/api/auth/check-login', {
    email,
  });
}

export async function checkPhone(phone: string) {
  await post('/api/auth/check-login', {
    phone,
  });
}

export async function resetPassword(
  code: string,
  password: string,
  phone?: string,
) {
  if (phone) {
    await post('/api/auth/password/recovery', {
      phone,
      code,
      password,
    });
  } else {
    await post('/api/auth/password/email/change', {
      code,
      password,
    });
  }
}
export async function setPassword(password: string) {
  await post('/api/auth/password/set', {
    password,
  });
  client.refetchQueries({include: ['GetMyAvatar']});
}
export async function changePassword(
  old_password: string,
  new_password: string,
) {
  await post('/api/auth/password/change', {
    old_password,
    new_password,
  });
}
export async function changePhone(
  old_phone: string,
  phone: string,
  code: string,
  password: string,
) {
  await post('/api/auth/change-phone', {
    old_phone,
    phone,
    code,
    password,
  });
  client.refetchQueries({include: ['GetMyAvatar']});
}
export async function getImagesUrls(files: FileList | File[]) {
  const route = '/api/storage';
  const body = new FormData();
  if (files) {
    for (let i = 0; i < files.length; i++) {
      body.append('files[]', files[i]);
    }
    const response = await axios.post(`${DOMAIN}${route}`, body, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    });
    const data = response.data;
    return {data} as {data: Array<Omit<ImageUpload, 'type'>>};
  }
}

export async function importCustomers(xlsx: File, companyId: string) {
  const token = store.getState().auth.token;
  const route = '/api/company/importCustomers';
  const body = new FormData();
  if (xlsx) {
    body.append('company_id', companyId);
    body.append('file', xlsx, xlsx.name);
    const response = await axios.post(`${DOMAIN}${route}`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    });
    const data = response.data;
    return data;
  }
}

export async function registrationToken(token: string) {
  await post('/api/devices/employee', {
    token,
  });
}

export async function unsubscribe(company_id: string, comment: string) {
  await post('/api/payment/cancel', {
    company_id,
    comment,
  });
}

export async function putOnlineMe() {
  const token = store.getState().auth.token;
  const headers = {
    ...(token ? {Authorization: `Bearer ${token}`} : null),
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };
  await fetch(`${DOMAIN}/api/user/online`, {
    method: 'PUT',
    headers,
  });
}
