import {useQuery} from '@apollo/client';
import React from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {gql} from 'shared/__generated__';
import {useColors} from 'shared/lib/hooks';
import {useAppSelector} from 'shared/store';
import {Content} from 'shared/ui/Content';
import {Divider} from 'shared/ui/Divider';
import {Flex} from 'shared/ui/Flex';
import {Head} from 'shared/ui/Head';
import {Layout} from 'shared/ui/Layout';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';
import {Header} from './ui/Header';
import {CopyLink} from './ui/CopyLink';
import Check from 'shared/icons/Check';
import {NavigateBack} from 'shared/ui/NavigateBack';
import {Support} from 'shared/icons/Support';
import {Button} from 'shared/ui/Button';
import {Seo} from 'shared/ui/Seo';

const APP = gql(`
  query GetAppWebsite($id: String!, $companyId: String!) {
    getAppById(id: $id, company_id: $companyId) {
      id
      key
      name
      image {
        id
        url
      }
      description
      app_url(company_id: $companyId)
      darkImage {
        id
        url
      }
      is_connectable
      is_connected(company_id: $companyId)
      is_free
      is_out
      is_recommended
      short_description
      instruction
      instruction_link
    }
  }
`);
const textData = [
  'Удобная форма записи для клиентов',
  'Безопасно! Уже есть SSL-сертификат',
  'Версия для мобильных и планшетов',
  'Готов для продвижения',
];

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-column-start: 4;
  grid-column-end: 10;
  padding: 64px 0;
  gap: 8px;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const CheckTextContainer = styled.div`
  display: flex;
  height: 48px;
  padding: 0px 16px;
  align-items: center;
  gap: 10px;
`;

export const WebsiteApp = () => {
  const navigate = useNavigate();
  const params = useParams();
  const title = useLocation().state;
  const colors = useColors();
  const companyId = useAppSelector(state => state.company.data!.id);
  const id = params.id!;
  const {data} = useQuery(APP, {
    variables: {
      companyId,
      id,
    },
  });
  const app = data?.getAppById;

  return (
    <Layout columns={12}>
      <Seo title={title} />
      <Wrapper>
        <Head>
          <NavigateBack />
          <Text typography="title24">{title}</Text>
        </Head>
        <Content gap="24px">
          <Header app={app} />
          <Divider />
          <CopyLink link={app?.app_url} />
          <Flex direction="column" gap={8}>
            {textData.map(item => (
              <CheckTextContainer key={item}>
                <Check color={colors.mainPrimary} />
                <Text>{item}</Text>
              </CheckTextContainer>
            ))}
          </Flex>
          {app?.description ? (
            <Flex gap={16} direction="column">
              <Text typography="text16Semibold">Описание</Text>
              <Text color="textSecondary">{app?.description}</Text>
            </Flex>
          ) : null}
          <Button
            variant="outline"
            size="large"
            style={{
              color: colors.textPrimary,
              backgroundColor: colors.fillPrimary,
              border: 'none',
            }}
            onClick={() => navigate('/support')}
            leftIcon={<Support color={colors.textPrimary} />}>
            Написать в чат
          </Button>
        </Content>
      </Wrapper>
    </Layout>
  );
};
