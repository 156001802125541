import React from 'react';

export const Confetti = ({
  color = '#6765F8',
  size = 24,
}: {
  color?: string;
  size?: number;
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 24 24`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.9396 5.90247C14.5832 5.91541 15.9263 7.22579 15.9396 8.82929M12.4396 19.9026C12.7607 19.9026 12.9214 19.9026 13.0329 19.8812C13.6862 19.756 14.0837 19.1275 13.8907 18.5253C13.8577 18.4224 13.7815 18.289 13.6292 18.0221M12.4396 19.9026C12.1184 19.9026 11.9578 19.9026 11.8463 19.8812C11.193 19.756 10.7954 19.1275 10.9885 18.5253C11.0214 18.4225 11.0976 18.289 11.25 18.0221M12.4396 19.9026V22M19.9998 9.61394C20.0332 13.7852 16.5815 17.6432 12.4395 17.6095C8.29748 17.5759 5.03367 13.6633 5.00025 9.49205C4.96682 5.32078 8.29748 1.96659 12.4395 2.00025C16.5815 2.03391 19.9663 5.44268 19.9998 9.61394Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
