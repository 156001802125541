import * as React from 'react';
import {useColors} from 'shared/lib/hooks';

export function WidgetFloatingButton({
  size,
  selected,
}: {
  size?: number;
  selected?: boolean;
}) {
  const colors = useColors();
  const sizeByWindow = 130;
  const sizeWidet = size ?? sizeByWindow;
  if (selected) {
    return (
      <svg
        width={sizeWidet}
        height={sizeWidet}
        viewBox="0 0 109 109"
        fill="none">
        <rect
          x={1}
          y={6}
          width={107}
          height={98}
          rx={19}
          fill={colors.bgSecondary}
        />
        <circle
          cx={82}
          cy={82}
          r={8.5}
          stroke={colors.textTertiary}
          strokeWidth={3}
        />
        <circle cx={26} cy={28} r={7} fill="#E5E5E5" />
        <rect x={37} y={24} width={20} height={3} rx={1.5} fill="#E5E5E5" />
        <rect x={37} y={29} width={16} height={3} rx={1.5} fill="#E5E5E5" />
        <rect
          x={47}
          y={78}
          width={20}
          height={3}
          rx={1.5}
          fill={colors.textTertiary}
        />
        <rect
          x={51}
          y={83}
          width={16}
          height={3}
          rx={1.5}
          fill={colors.textTertiary}
        />
        <rect
          x={43}
          y={45}
          width={17}
          height={17}
          rx={4.81967}
          fill="#E5E5E5"
        />
        <rect
          x={65}
          y={45}
          width={17}
          height={17}
          rx={4.81967}
          fill="#E5E5E5"
        />
        <rect
          x={21}
          y={45}
          width={17}
          height={17}
          rx={4.81967}
          fill="#E5E5E5"
        />
        <rect x={76} y={16} width={22} height={22} rx={11} fill="#1BC760" />
        <path
          d="M83 28.375L85.4 31l5.6-7"
          stroke="#fff"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect
          x={1}
          y={6}
          width={107}
          height={98}
          rx={19}
          stroke="#1BC760"
          strokeWidth={2}
        />
      </svg>
    );
  }
  return (
    <svg width={sizeWidet} height={sizeWidet} viewBox="0 0 109 109" fill="none">
      <rect y={5} width={109} height={100} rx={20} fill={colors.bgSecondary} />
      <circle
        cx={82}
        cy={82}
        r={8.5}
        stroke={colors.textTertiary}
        strokeWidth={3}
      />
      <circle cx={26} cy={28} r={7} fill="#E5E5E5" />
      <rect x={37} y={24} width={20} height={3} rx={1.5} fill="#E5E5E5" />
      <rect x={37} y={29} width={16} height={3} rx={1.5} fill="#E5E5E5" />
      <rect
        x={47}
        y={78}
        width={20}
        height={3}
        rx={1.5}
        fill={colors.textTertiary}
      />
      <rect
        x={51}
        y={83}
        width={16}
        height={3}
        rx={1.5}
        fill={colors.textTertiary}
      />
      <rect x={43} y={45} width={17} height={17} rx={4.81967} fill="#E5E5E5" />
      <rect x={65} y={45} width={17} height={17} rx={4.81967} fill="#E5E5E5" />
      <rect x={21} y={45} width={17} height={17} rx={4.81967} fill="#E5E5E5" />
    </svg>
  );
}
