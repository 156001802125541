import React from 'react';
import {App, AppCategory, Image} from 'shared/__generated__/graphql';
import {Card} from './ui/Card';
import {Header} from './ui/Header';
import {ListHorizontal} from './ui/ListHorizontal';
import {Flex} from 'shared/ui/Flex';
import {RoundGraph} from 'shared/icons/RoundGraph';
import {UserDouble} from 'shared/icons/UserDouble';
import {PresentationGraph} from 'shared/icons/PresentationGraph';
import {CalendarDate} from 'shared/icons/CalendarDate';
import {Bookmark} from 'shared/icons/Bookmark';
import {UserTriple} from 'shared/icons/UserTriple';
import {Bill} from 'shared/icons/Bill';
import {CardTransfer} from 'shared/icons/CardTransfer';
import {DocumentEdit} from 'shared/icons/DocumentEdit';
import {Clipboard} from 'shared/icons/Clipboard';
import {ClipboardRemove} from 'shared/icons/ClipboardRemove';
import {Tag} from 'shared/icons/Tag';
import {Coupon} from 'shared/icons/Coupon';
import {BillCheck} from 'shared/icons/BillCheck';
import {TickerStar} from 'shared/icons/TickerStar';
import {ChartBar} from 'shared/icons/ChartBar';
import {Sale} from 'shared/icons/Sale';
import {Layout} from 'shared/ui/Layout';
import styled from 'styled-components';

export type AppType = Omit<
  App,
  | 'sort_num'
  | 'updated_at'
  | 'created_at'
  | 'image'
  | 'darkImage'
  | 'appCategory'
> & {
  image?: Pick<Image, 'url' | 'id'> | null;
  darkImage?: Pick<Image, 'url' | 'id'> | null;
  appCategory: Pick<AppCategory, 'id' | 'title'>;
};

const Wrapper = styled.div`
  grid-column-start: 1;
  grid-column-end: 13;
  padding-bottom: 24px;
`;

export const Statistics = () => (
  <Layout variant="sidebar">
    <Wrapper>
      <Header />
      <Flex direction="column" gap={16}>
        {statisticsData.map(({title, cards}, index) => (
          <ListHorizontal key={'list_' + index} title={title}>
            {cards?.map((card, index) => (
              <Card {...card} key={'card_' + index} />
            ))}
          </ListHorizontal>
        ))}
      </Flex>
    </Wrapper>
  </Layout>
);

type StatisticsButton = {
  to: string;
  icon: React.ReactElement;
  title: string;
};

const salesCards: StatisticsButton[] = [
  {
    title: 'Продажи по услугам',
    to: 'sales_on_services',
    icon: <RoundGraph size={40} />,
  },
  {
    title: 'Продажи по клиентам',
    to: 'sales_on_customers',
    icon: <UserDouble size={40} />,
  },
  {
    title: 'Продажи по сотрудникам',
    to: 'sales_on_employees',
    icon: <PresentationGraph size={40} />,
  },
  {
    title: 'Продажи по дате',
    to: 'sales_on_date',
    icon: <CalendarDate size={40} />,
  },
  {
    title: 'Журнал продаж',
    to: 'sales_on_journal',
    icon: <Bookmark size={40} />,
  },
];

const loyaltyCards: StatisticsButton[] = [
  {
    title: 'Статистика по скидкам',
    to: 'loyalty_discounts',
    icon: <Tag size={40} />,
  },
  {
    title: 'Статистика по бонусным картам',
    to: 'loyalty_bonus_cards',
    icon: <Coupon size={40} />,
  },
  {
    title: 'Статистика по погашению бонусов',
    to: 'loyalty_uses_bonus_cards',
    icon: <BillCheck size={40} />,
  },
  {
    title: 'Статистика по сертификатам',
    to: 'loyalty_certificates',
    icon: <TickerStar size={40} />,
  },
  {
    title: 'Статистика по продаже сертификатов',
    to: 'loyalty_sales_certificates',
    icon: <ChartBar size={40} />,
  },
  {
    title: 'Статистика по погашению сертификатов',
    to: 'loyalty_uses_certificates',
    icon: <Sale size={40} />,
  },
];

const visitCards: StatisticsButton[] = [
  {
    title: 'Визиты клиентов',
    to: 'visit_customers',
    icon: <DocumentEdit size={40} />,
  },
  {
    title: 'Сводка визитов',
    to: 'visit_summary',
    icon: <Clipboard size={40} />,
  },
  {
    title: 'Отмена визитов',
    to: 'visit_cancellation',
    icon: <ClipboardRemove size={40} />,
  },
];

const financeCards: StatisticsButton[] = [
  {
    title: 'Сводка по финансам и платежам',
    to: 'finance_payment_summary',
    icon: <CardTransfer size={40} />,
  },
  {
    title: 'Список платежных операций',
    to: 'finance_payment_list',
    icon: <Bill size={40} />,
  },
];
const customerCards: StatisticsButton[] = [
  {
    title: 'Статистика по клиентам',
    to: 'customer_statistics',
    icon: <UserTriple size={40} />,
  },
];

const statisticsData = [
  {title: 'Продажи', cards: salesCards},
  {title: 'Визиты', cards: visitCards},
  {title: 'Финансы', cards: financeCards},
  {title: 'Клиенты', cards: customerCards},
  {title: 'Лояльность', cards: loyaltyCards},
];
