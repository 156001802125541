import React from 'react';
import styled from 'styled-components';
import {device} from 'shared/device';
import {Text} from 'shared/ui/Text';
import {Seo} from 'shared/ui/Seo';

const Header = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 24px;
  gap: 10;
  height: 56px;
  @media ${device.desktop} {
    display: flex;
    margin-top: 24px;
  }
`;

export const TutorialHeader = () => {
  return (
    <Header>
      <Seo title="Быстрая настройка салона" />
      <Text typography="title24">Быстрая настройка салона</Text>
    </Header>
  );
};
