import React from 'react';
import {useColors} from 'shared/lib/hooks';
import styled from 'styled-components';
import {TextInput} from 'shared/ui/TextInput';
import {Text} from 'shared/ui/Text';
import {Vk} from 'shared/icons/Vk';
import {Youtube} from 'shared/icons/Youtube';
import {Instagram} from 'shared/icons/Instagram';

type Props = {
  type: 'vk' | 'youtube' | 'instagram';
  value: string;
  setValue: (value: string) => void;
};

const Icon = styled.div`
  display: flex;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  background-color: ${({color}) => color};
`;
const Row = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const socialData = {
  vk: {
    Icon: <Vk />,
    label: 'Vk  ',
    inputLabel: 'Vk',
    placeholder: 'vk.com/mycompany',
  },
  instagram: {
    Icon: <Instagram />,
    label: 'Instagram ',
    inputLabel: 'Instagram',
    placeholder: 'instagram.com/',
  },
  youtube: {
    Icon: <Youtube />,
    label: 'YouTube',
    inputLabel: 'YouTube',
    placeholder: 'youtube.com/mycompany',
  },
};

export const Social = ({type, value, setValue}: Props) => {
  const colors = useColors();

  const socialColors = {
    vk: colors.bgWaiting,
    instagram: colors.bgWaiting,
    youtube: colors.bgDanger,
  };
  const social = socialData[type];

  return (
    <Container>
      <Row>
        <Icon color={socialColors[type]}>{social.Icon}</Icon>
        <Text style={{flex: 1, marginLeft: 8}} typography="subHead14Medium">
          {social.label}
        </Text>
      </Row>
      <TextInput
        value={value}
        placeholder={social.placeholder}
        onChange={setValue}
        label={social.label}
      />
    </Container>
  );
};
