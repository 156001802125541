import React from 'react';
import {Helmet} from 'react-helmet-async';

type Props = {
  title: string;
  description?: string;
  name?: string;
  type?: string;
};

export const Seo = (props: Props) => {
  const {
    title,
    description = 'Booky Business - сервис онлайн-записи клиентов на услуги',
    name = 'Booky Business',
    type = 'website',
  } = props;
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta name="twitter:creator" content={name} />
      <meta name="twitter:card" content={type} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
    </Helmet>
  );
};
