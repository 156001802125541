import React, {useEffect, useState} from 'react';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';
import {Grow} from 'shared/ui/Grow';
import {Button} from 'shared/ui/Button';
import {StatisticsPaymentOperationsSummary} from 'shared/__generated__/graphql';
import {FilterButton} from 'shared/ui/FilterButton';
import {TextInput} from 'shared/ui/TextInput';
import {Modal} from 'shared/ui/Modal';

const PopupWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const Row = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const Box = styled.div`
  display: flex;
  flex: 1;
  align-items: start;
  gap: 8px;
`;

export const FilterStatisticsSummary = ({
  summary,
  setSummary,
}: {
  summary?: StatisticsPaymentOperationsSummary;
  setSummary?: React.Dispatch<
    React.SetStateAction<StatisticsPaymentOperationsSummary | undefined>
  >;
}) => {
  const [visible, setVisible] = useState(false);
  const [summaryPrev, setSummaryPrev] = useState(summary);
  const onClose = () => setVisible(false);
  useEffect(() => {
    setSummaryPrev(summary);
  }, [summary]);
  return (
    <PopupWrapper>
      <FilterButton
        onClick={() => setVisible(true)}
        active={!!summary?.from || !!summary?.to}
        title={
          summary?.to || summary?.from
            ? `${summary.from ? 'От ' + summary.from : ''} ${
                summary.to ? 'До ' + summary.to : ''
              }`
            : 'Сумма'
        }
        visible={visible}
      />
      <Modal
        style={{
          width: 343,
          left: 'auto',
          right: 'auto',
          top: 64,
          gap: 8,
        }}
        visible={visible}
        onClose={onClose}>
        <Grow style={{gap: 16}}>
          <Text>Введите значения</Text>
          <Box>
            <TextInput
              leftElement={<Text color="textSecondary">От</Text>}
              variant="with_label"
              wrapperStyle={{flex: 1}}
              value={summaryPrev?.from?.toString() ?? ''}
              mask="9999999"
              onChange={text =>
                setSummaryPrev(prev => ({
                  ...prev,
                  from: text ? parseInt(text, 10) : undefined,
                }))
              }
            />
            <TextInput
              wrapperStyle={{flex: 1}}
              leftElement={<Text color="textSecondary">До</Text>}
              variant="with_label"
              value={summaryPrev?.to?.toString() ?? ''}
              mask="9999999"
              onChange={text =>
                setSummaryPrev(prev => ({
                  ...prev,
                  to: text ? parseInt(text, 10) : undefined,
                }))
              }
            />
          </Box>
          <Row style={{justifyContent: 'center', gap: 8}}>
            <Button
              style={{flex: 1}}
              size="medium"
              variant="outline"
              onClick={() => {
                setSummary && setSummary(undefined);
                onClose();
              }}>
              Сбросить
            </Button>
            <Button
              style={{flex: 1}}
              size="medium"
              disabled={
                typeof summaryPrev?.from === 'number' &&
                typeof summaryPrev.to === 'number' &&
                summaryPrev.from > summaryPrev.to
              }
              onClick={() => {
                setSummary &&
                  setSummary(
                    summaryPrev?.from || summaryPrev?.to
                      ? summaryPrev
                      : undefined,
                  );
                onClose();
              }}>
              Применить
            </Button>
          </Row>
        </Grow>
      </Modal>
    </PopupWrapper>
  );
};
