import React from 'react';
import {Flex} from './Flex';
import {Text} from './Text';
import {Switch} from './Switch';

export const TextSwitch = ({
  value,
  onClick,
  title,
}: {
  title: string;
  value: boolean;
  onClick: (value: boolean) => void;
}) => (
  <Flex
    alignItems="center"
    justifyContent="space-between"
    onClick={() => onClick(!value)}
    style={{height: 48, pointerEvents: 'all', cursor: 'pointer'}}>
    <Text>{title}</Text>
    <Switch value={value} />
  </Flex>
);
