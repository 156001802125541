import {useLazyQuery} from '@apollo/client';
import {GET_RULES_SALARIES} from 'entities/salary';
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {SalaryType, ServiceSalaryType} from 'shared/__generated__/graphql';
import {EmptyWallet} from 'shared/illustration/EmptyWallet';
import {useAppSelector} from 'shared/store';
import {Content} from 'shared/ui/Content';
import {Flex} from 'shared/ui/Flex';
import {Skeleton} from 'shared/ui/Skeleton';
import {Slide} from 'shared/ui/Slide';
import {OptionsType, Table} from 'shared/ui/Table';
import {Text} from 'shared/ui/Text';

const rulesTitles = ['Название', 'За услуги', 'Оклад', 'Сотрудники', ''];
const getSalaryType = (value: SalaryType) => {
  switch (value) {
    case SalaryType.Day:
      return 'день';
    case SalaryType.Month:
      return 'мес';
    default:
      return 'час';
  }
};
const getSalaryServiceType = (value: ServiceSalaryType) => {
  if (value === ServiceSalaryType.Percent) return ' %';
  return '\u00A0₽';
};

export const RulesSalaries = ({active}: {active: boolean}) => {
  const companyId = useAppSelector(state => state.company.data!.id);
  const username = useAppSelector(state => state.company.data!.username);
  const navigate = useNavigate();
  const [options, setOptions] = useState<OptionsType>({
    page: 1,
    first: 10,
    total: 0,
    count: 0,
  });
  const [getData, {data, loading, called}] = useLazyQuery(GET_RULES_SALARIES, {
    variables: {
      companyId,
      first: options.first,
      page: options.page,
    },
    onCompleted: res =>
      setOptions(prev => ({
        ...prev,
        total: res.companySalaryRules?.paginatorInfo.total ?? 0,
        count: res.companySalaryRules?.paginatorInfo.count ?? 0,
        page: res.companySalaryRules?.paginatorInfo.currentPage ?? 1,
      })),
  });
  const salaries = data?.companySalaryRules?.data ?? [];

  useEffect(() => {
    if (!called && active) getData();
  }, [active, called, getData]);

  return (
    <Slide>
      {loading ? (
        <RulesSkeleton />
      ) : salaries.length === 0 ? (
        <Flex
          alignItems="center"
          flex={1}
          gap={16}
          style={{marginBottom: 8, marginTop: 8}}
          direction="column">
          <EmptyWallet size={300} />
          <Text typography="title20" align="center">
            Правил расчета нет
          </Text>
          <Text typography="text18" color="textTertiary" align="center">
            Предлагаем это исправить. Начните включать расчет зарплат <br /> в
            профилях сотрудников
          </Text>
        </Flex>
      ) : (
        <Content>
          <Table
            titles={rulesTitles}
            widths={[undefined, undefined, undefined, undefined, 48]}
            columnNoWrap={[1]}
            options={options}
            setOptions={setOptions}
            onClick={index =>
              navigate(`/${username}/edit-salary-rule/${salaries[index].id}`)
            }
            rows={salaries.map(item => [
              item.name,
              item.service_salary_value +
                getSalaryServiceType(item.service_salary_type),
              item.salary_value + '\u00A0₽/' + getSalaryType(item.salary_type),
              item.employees?.length ?? 0,
            ])}
          />
        </Content>
      )}
    </Slide>
  );
};
const RulesSkeleton = () => {
  return (
    <Content>
      <Skeleton height={400}>
        {Array(4)
          .fill(0)
          .map((_, index) => (
            <React.Fragment key={'skeleton_rect_' + index}>
              <rect
                width={200}
                height={24}
                rx={14}
                ry={14}
                x={0}
                y={80 + 80 * index}
              />
              <rect
                width={80}
                height={24}
                rx={14}
                ry={14}
                x="30%"
                y={80 + 80 * index}
              />
              <rect
                width={160}
                height={24}
                rx={14}
                ry={14}
                x="50%"
                y={80 + 80 * index}
              />
              <rect
                width={40}
                height={24}
                rx={14}
                ry={14}
                x="80%"
                y={80 + 80 * index}
              />
            </React.Fragment>
          ))}
      </Skeleton>
    </Content>
  );
};
