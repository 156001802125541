import React from 'react';

export function More() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.67188 12.0449C8.67188 12.6662 8.1682 13.1699 7.54688 13.1699C6.92555 13.1699 6.42188 12.6662 6.42188 12.0449C6.42188 11.4236 6.92555 10.9199 7.54688 10.9199C8.1682 10.9199 8.67188 11.4236 8.67188 12.0449Z"
        fill="#6765F8"
      />
      <path
        d="M13.1719 12.0449C13.1719 12.6662 12.6682 13.1699 12.0469 13.1699C11.4256 13.1699 10.9219 12.6662 10.9219 12.0449C10.9219 11.4236 11.4256 10.9199 12.0469 10.9199C12.6682 10.9199 13.1719 11.4236 13.1719 12.0449Z"
        fill="#6765F8"
      />
      <path
        d="M17.6719 12.0449C17.6719 12.6662 17.1682 13.1699 16.5469 13.1699C15.9256 13.1699 15.4219 12.6662 15.4219 12.0449C15.4219 11.4236 15.9256 10.9199 16.5469 10.9199C17.1682 10.9199 17.6719 11.4236 17.6719 12.0449Z"
        fill="#6765F8"
      />
      <rect
        x="2"
        y="2"
        width="20"
        height="20"
        rx="10"
        stroke="#6765F8"
        strokeWidth="1.5"
      />
    </svg>
  );
}
