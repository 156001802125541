import {CompanyRoles} from 'shared/__generated__/graphql';

interface IRoles {
  role: CompanyRoles;
  id: string;
  add_to_customers_bonus_card_permission: boolean;
  add_to_customers_certificate_permission: boolean;
  customers_info_permission: boolean;
  create_customers_permission: boolean;
  create_invitation_permission: boolean;
  create_bookings_permission: boolean;
  edit_apps_permission: boolean;
  edit_bonus_card_permission: boolean;
  edit_certificate_permission: boolean;
  edit_customers_permission: boolean;
  edit_discounts_permission: boolean;
  edit_employees_permission: boolean;
  edit_bookings_permission: boolean;
  edit_company_permission: boolean;
  edit_company_services_permission: boolean;
  edit_work_schedule_permission: boolean;
  fill_bonus_card_permission: boolean;
  employees_info_permission: boolean;
  moderation_permission: boolean;
  notation_permission: boolean;
  reviews_info_permission: boolean;
  reviews_reply_permission: boolean;
  subscription_and_pay_permission: boolean;
  work_schedule_info_permission: boolean;
  edit_bonus_card_templates: boolean;
  edit_certificate_templates: boolean;
  reports_permission: boolean;
  contact_permission: boolean;
  view_service_permission: boolean;
  messages_permission: boolean;
  delete_messages_permission: boolean;
  message_sample_permission: boolean;
  view_products_permission: boolean;
  edit_products_permission: boolean;
  salaries_permission: boolean;
  webhook_permission: boolean;
}

interface IGetDataAccess {
  access: IAccess[];
  admin: IRoles;
  participant: IRoles;
}

export function getDataAccess({access, admin, participant}: IGetDataAccess) {
  let newAccess = access;

  newAccess = newAccess.map(item => {
    switch (item.id) {
      case 1:
        return {
          ...item,
          admin: admin!.edit_bookings_permission,
          participant: participant!.edit_bookings_permission,
        };
      case 2:
        return {
          ...item,
          admin: admin!.create_bookings_permission,
          participant: participant!.create_bookings_permission,
        };
      case 3:
        return {
          ...item,
          admin: admin!.customers_info_permission,
          participant: participant!.customers_info_permission,
        };
      case 4:
        return {
          ...item,
          admin: admin!.contact_permission,
          participant: participant!.contact_permission,
        };
      case 5:
        return {
          ...item,
          admin: admin!.create_customers_permission,
          participant: participant!.create_customers_permission,
        };
      case 6:
        return {
          ...item,
          admin: admin!.edit_customers_permission,
          participant: participant!.edit_customers_permission,
        };
      case 7:
        return {
          ...item,
          admin: admin!.add_to_customers_bonus_card_permission,
          participant: participant!.add_to_customers_bonus_card_permission,
        };
      case 8:
        return {
          ...item,
          admin: admin!.add_to_customers_certificate_permission,
          participant: participant!.add_to_customers_certificate_permission,
        };
      case 9:
        return {
          ...item,
          admin: admin!.employees_info_permission,
          participant: participant!.employees_info_permission,
        };
      case 10:
        return {
          ...item,
          admin: admin!.edit_employees_permission,
          participant: participant!.edit_employees_permission,
        };
      case 11:
        return {
          ...item,
          admin: admin!.create_invitation_permission,
          participant: participant!.create_invitation_permission,
        };
      case 12:
        return {
          ...item,
          admin: admin!.work_schedule_info_permission,
          participant: participant!.work_schedule_info_permission,
        };
      case 13:
        return {
          ...item,
          admin: admin!.edit_work_schedule_permission,
          participant: participant!.edit_work_schedule_permission,
        };
      case 14:
        return {
          ...item,
          admin: admin!.edit_company_services_permission,
          participant: participant!.edit_company_services_permission,
        };
      case 15:
        return {
          ...item,
          admin: admin!.view_service_permission,
          participant: participant!.view_service_permission,
        };
      case 16:
        return {
          ...item,
          admin: admin!.edit_bonus_card_templates,
          participant: participant!.edit_bonus_card_templates,
        };
      case 17:
        return {
          ...item,
          admin: admin!.edit_certificate_templates,
          participant: participant!.edit_certificate_templates,
        };

      case 18:
        return {
          ...item,
          admin: admin!.edit_discounts_permission,
          participant: participant!.edit_discounts_permission,
        };
      case 19:
        return {
          ...item,
          admin: admin!.reviews_info_permission,
          participant: participant!.reviews_info_permission,
        };
      case 20:
        return {
          ...item,
          admin: admin!.reviews_reply_permission,
          participant: participant!.reviews_reply_permission,
        };
      case 21:
        return {
          ...item,
          admin: admin!.edit_company_permission,
          participant: participant!.edit_company_permission,
        };
      case 22:
        return {
          ...item,
          admin: admin!.notation_permission,
          participant: participant!.notation_permission,
        };
      case 23:
        return {
          ...item,
          admin: admin!.edit_apps_permission,
          participant: participant!.edit_apps_permission,
        };
      case 24:
        return {
          ...item,
          admin: admin!.reports_permission,
          participant: participant!.reports_permission,
        };
      case 25:
        return {
          ...item,
          admin: admin!.subscription_and_pay_permission,
          participant: participant!.subscription_and_pay_permission,
        };
      case 26:
        return {
          ...item,
          admin: admin!.messages_permission,
          participant: participant!.messages_permission,
        };
      case 27:
        return {
          ...item,
          admin: admin!.delete_messages_permission,
          participant: participant!.delete_messages_permission,
        };
      case 28:
        return {
          ...item,
          admin: admin!.message_sample_permission,
          participant: participant!.message_sample_permission,
        };
      case 29:
        return {
          ...item,
          admin: admin!.view_products_permission,
          participant: participant!.view_products_permission,
        };
      case 30:
        return {
          ...item,
          admin: admin!.edit_products_permission,
          participant: participant!.edit_products_permission,
        };
      case 31:
        return {
          ...item,
          admin: admin!.salaries_permission,
          participant: participant!.salaries_permission,
        };
      case 32:
        return {
          ...item,
          admin: admin!.webhook_permission,
          participant: participant!.webhook_permission,
        };
    }
    return item;
  });
  return newAccess;
}

export function getPermissionsByRole(
  access: IAccess[],
  fillname: CompanyRoles.Participant | CompanyRoles.Admin,
) {
  return {
    role: fillname,
    edit_bookings_permission: access[0][fillname],
    create_bookings_permission: access[1][fillname],
    customers_info_permission: access[2][fillname],
    contact_permission: access[3][fillname],
    create_customers_permission: access[4][fillname],
    edit_customers_permission: access[5][fillname],
    add_to_customers_bonus_card_permission: access[6][fillname],
    add_to_customers_certificate_permission: access[7][fillname],
    employees_info_permission: access[8][fillname],
    edit_employees_permission: access[9][fillname],
    create_invitation_permission: access[10][fillname],
    work_schedule_info_permission: access[11][fillname],
    edit_work_schedule_permission: access[12][fillname],
    edit_company_services_permission: access[13][fillname],
    view_service_permission: access[14][fillname],
    edit_bonus_card_templates: access[15][fillname],
    edit_certificate_templates: access[16][fillname],
    edit_discounts_permission: access[17][fillname],
    reviews_info_permission: access[18][fillname],
    reviews_reply_permission: access[19][fillname],
    edit_company_permission: access[20][fillname],
    notation_permission: access[21][fillname],
    edit_apps_permission: access[22][fillname],
    reports_permission: access[23][fillname],
    subscription_and_pay_permission: access[24][fillname],
    messages_permission: access[25][fillname],
    delete_messages_permission: access[26][fillname],
    message_sample_permission: access[27][fillname],
    view_products_permission: access[28][fillname],
    edit_products_permission: access[29][fillname],
    salaries_permission: access[30][fillname],
    webhook_permission: access[31][fillname],
  };
}

export interface IAccess {
  id: number;
  title: string;
  content: string;
  owner: true;
  admin: boolean;
  participant: boolean;
}

export const AccessData: IAccess[] = [
  {
    id: 1,
    title: 'Журнал записи',
    content:
      'Сотрудник может редактировать записи о визитах в календаре (в т.ч. закрывать, отменять, менять статус).',
    owner: true,
    admin: true,
    participant: true,
  },
  {
    id: 2,
    title: 'Создание записи',
    content: 'Сотрудник может создавать записи о визитах в календаре.',
    owner: true,
    admin: true,
    participant: true,
  },
  {
    id: 3,
    title: 'Информации о клиентах',
    content: 'Сотрудник может видеть всю информацию о клиенте.',
    owner: true,
    admin: true,
    participant: true,
  },
  {
    id: 4,
    title: 'Доступ к контактам клиентов',
    content: 'Сотрудник может видеть контактные данные клиенте.',
    owner: true,
    admin: true,
    participant: true,
  },
  {
    id: 5,
    title: 'Добавление клиента',
    content: 'Сотрудник может добавлять информацию о новом клиенте.',
    owner: true,
    admin: true,
    participant: true,
  },
  {
    id: 6,
    title: 'Редактирование и удаление клиента',
    content: 'Сотрудник может редактировать и удалять информацию о клиенте.',
    owner: true,
    admin: true,
    participant: true,
  },
  {
    id: 7,
    title: 'Присвоение, пополнение, удаление бонусной карты у клиента',
    content:
      'Сотрудник может выдавать, удалять и пополнять бонусную карту клиента.',
    owner: true,
    admin: true,
    participant: true,
  },
  {
    id: 8,
    title: 'Присвоение, удаление сертификата у клиента',
    content: 'Сотрудник может привязывать и удалять сертификаты клиента.',
    owner: true,
    admin: true,
    participant: true,
  },
  {
    id: 9,
    title: 'Информации о сотрудниках',
    content: 'Сотрудник может видеть всю информацию о сотруднике.',
    owner: true,
    admin: true,
    participant: false,
  },
  {
    id: 10,
    title: 'Добавление, редактирование и удаление сотрудников',
    content:
      'Сотрудник может добавлять, редактировать и удалять информацию о сотруднике.',
    owner: true,
    admin: true,
    participant: false,
  },
  {
    id: 11,
    title: 'Возможность приглашать и отзывать заявки сотрудников',
    content: 'Сотрудник может отправить приглашение другому сотруднику.',
    owner: true,
    admin: true,
    participant: false,
  },
  {
    id: 12,
    title: 'Просмотр графика работы',
    content: 'Сотрудник может видеть график работы сотрудников.',
    owner: true,
    admin: true,
    participant: false,
  },
  {
    id: 13,
    title: 'Добавление, редактирование и удаление графика работы',
    content:
      'Сотрудник может добавлять, редактировать и удалять график работы сотрудников.',
    owner: true,
    admin: true,
    participant: false,
  },
  {
    id: 14,
    title: 'Редактировать и добавлять услуги',
    content:
      'Сотрудник может добавлять, редактировать и удалять услуги организации.',
    owner: true,
    admin: true,
    participant: false,
  },
  {
    id: 15,
    title: 'Просмотр услуг',
    content: 'Сотрудник может смотркть информацию об услуге.',
    owner: true,
    admin: true,
    participant: false,
  },
  {
    id: 16,
    title: 'Бонусные карты',
    content:
      'Сотрудник может добавлять, редактировать и удалять бонусные карты.',
    owner: true,
    admin: true,
    participant: false,
  },
  {
    id: 17,
    title: 'Сертификаты',
    content: 'Сотрудник может добавлять, редактировать и удалять сертификаты.',
    owner: true,
    admin: true,
    participant: false,
  },
  {
    id: 18,
    title: 'Акции и скидки',
    content:
      'Сотрудник может добавлять, редактировать и удалять акции и скидки.',
    owner: true,
    admin: true,
    participant: false,
  },
  {
    id: 19,
    title: 'Отзывы',
    content: 'Сотрудник может видеть отзывы.',
    owner: true,
    admin: true,
    participant: false,
  },
  {
    id: 20,
    title: 'Ответ на отзывы',
    content: 'Сотрудник может отвечать на отзывы.',
    owner: true,
    admin: true,
    participant: false,
  },
  {
    id: 21,
    title: 'Редактирование страницы организации',
    content: 'Сотрудник может редактировать информацию об организации.',
    owner: true,
    admin: true,
    participant: false,
  },
  {
    id: 22,
    title: 'Настройки онлайн-записи',
    content: 'Сотрудник может войти в раздел настройки журнала.',
    owner: true,
    admin: true,
    participant: false,
  },
  {
    id: 23,
    title: 'Каталог приложений',
    content: 'Сотрудник может войти в раздел каталог приложений',
    owner: true,
    admin: true,
    participant: false,
  },
  {
    id: 24,
    title: 'Статистика',
    content: '',
    owner: true,
    admin: true,
    participant: false,
  },
  {
    id: 25,
    title: 'Доступ к тарифным планам и оплате',
    content: 'Сотрудник может получить доступ к тарифному плану и оплате.',
    owner: true,
    admin: false,
    participant: false,
  },
  {
    id: 26,
    title: 'Рассылки',
    content: 'Сотрудник может получить доступ к рассылкам',
    owner: true,
    admin: true,
    participant: false,
  },
  {
    id: 27,
    title: 'Удаление рассылок',
    content: 'Сотрудник может получить доступ к удалению рассылок.',
    owner: true,
    admin: true,
    participant: false,
  },
  {
    id: 28,
    title: 'Шаблоны рассылок',
    content: '',
    owner: true,
    admin: true,
    participant: false,
  },
  {
    id: 29,
    title: 'Товары',
    content: 'Сотрудник может получить доступ к просмотру товаров.',
    owner: true,
    admin: true,
    participant: false,
  },
  {
    id: 30,
    title: 'Редактирование товаров',
    content: 'Сотрудник может получить доступ к редактированию товаров.',
    owner: true,
    admin: true,
    participant: false,
  },
  {
    id: 31,
    title: 'Зарплаты',
    content: '',
    owner: true,
    admin: true,
    participant: false,
  },
  {
    id: 32,
    title: 'Webhook',
    content: '',
    owner: true,
    admin: true,
    participant: false,
  },
];
