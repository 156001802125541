import React, {useState} from 'react';
import {Popup} from 'shared/ui/Popup';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';
import {Grow} from 'shared/ui/Grow';
import {Button} from 'shared/ui/Button';
import {TextInput} from 'shared/ui/TextInput';
import {CloseButton} from 'shared/ui/CloseButton';

const Row = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 394px;
`;

export const AccrualPopup = ({
  visible,
  onClose,
  onClickDone,
  loading,
}: {
  visible: boolean;
  onClose: () => void;
  onClickDone: (value: number) => Promise<void>;
  loading?: boolean;
}) => {
  const [input, setInput] = useState('');
  return (
    <Popup {...{visible, onClose}}>
      <Grow style={{gap: 24}}>
        <Row>
          <Text typography="title20">Пополнение бонусной карты</Text>
          <CloseButton
            onClose={() => {
              onClose();
              setInput('');
            }}
          />
        </Row>
        <TextInput
          label="Введите сумму пополнения"
          mask="9999999"
          value={input}
          onChange={text => setInput(text)}
        />
        <Button
          loading={loading}
          variant="filled"
          onClick={async () => {
            await onClickDone(parseInt(input));
            setInput('');
          }}>
          Готово
        </Button>
      </Grow>
    </Popup>
  );
};
