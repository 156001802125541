import * as React from 'react';
import {useColors} from 'shared/lib/hooks';

export function WidgetPage({
  size,
  selected,
}: {
  size?: number;
  selected?: boolean;
}) {
  const colors = useColors();
  const sizeByWindow = 130;
  const sizeWidet = size ?? sizeByWindow;
  if (selected) {
    return (
      <svg
        width={sizeWidet}
        height={sizeWidet}
        viewBox="0 0 109 109"
        fill="none">
        <g clipPath="url(#clip0_19390_52529)">
          <rect
            y={5}
            width={109}
            height={100}
            rx={20}
            fill={colors.bgSecondary}
          />
          <circle
            cx={30}
            cy={36}
            r={6}
            stroke={colors.textTertiary}
            strokeWidth={2}
          />
          <rect
            x={41}
            y={32}
            width={20}
            height={3}
            rx={1.5}
            fill={colors.textTertiary}
          />
          <rect
            x={41}
            y={37}
            width={16}
            height={3}
            rx={1.5}
            fill={colors.textTertiary}
          />
          <rect
            x={26}
            y={55}
            width={14}
            height={14}
            rx={3.81967}
            stroke={colors.textTertiary}
            strokeWidth={2}
          />
          <rect
            x={48}
            y={55}
            width={14}
            height={14}
            rx={3.81967}
            stroke={colors.textTertiary}
            strokeWidth={2}
          />
          <rect
            x={70}
            y={55}
            width={14}
            height={14}
            rx={3.81967}
            stroke={colors.textTertiary}
            strokeWidth={2}
          />
          <rect
            x={26}
            y={73}
            width={14}
            height={14}
            rx={3.81967}
            stroke={colors.textTertiary}
            strokeWidth={2}
          />
          <rect
            x={48}
            y={73}
            width={14}
            height={14}
            rx={3.81967}
            stroke={colors.textTertiary}
            strokeWidth={2}
          />
          <rect
            x={70}
            y={73}
            width={14}
            height={14}
            rx={3.81967}
            stroke={colors.textTertiary}
            strokeWidth={2}
          />
          <rect x={76} y={16} width={22} height={22} rx={11} fill="#1BC760" />
          <path
            d="M83 28.375L85.4 31l5.6-7"
            stroke="#fff"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <rect
          x={1}
          y={6}
          width={107}
          height={98}
          rx={19}
          stroke="#1BC760"
          strokeWidth={2}
        />
        <defs>
          <clipPath id="clip0_19390_52529">
            <rect y={5} width={109} height={100} rx={20} fill="#fff" />
          </clipPath>
        </defs>
      </svg>
    );
  }
  return (
    <svg width={sizeWidet} height={sizeWidet} viewBox="0 0 109 109" fill="none">
      <g clipPath="url(#clip0_19390_52525)">
        <rect
          y={5}
          width={109}
          height={100}
          rx={20}
          fill={colors.bgSecondary}
        />
        <circle
          cx={30}
          cy={36}
          r={6}
          stroke={colors.textTertiary}
          strokeWidth={2}
        />
        <rect
          x={41}
          y={32}
          width={20}
          height={3}
          rx={1.5}
          fill={colors.textTertiary}
        />
        <rect
          x={41}
          y={37}
          width={16}
          height={3}
          rx={1.5}
          fill={colors.textTertiary}
        />
        <rect
          x={26}
          y={55}
          width={14}
          height={14}
          rx={3.81967}
          stroke={colors.textTertiary}
          strokeWidth={2}
        />
        <rect
          x={48}
          y={55}
          width={14}
          height={14}
          rx={3.81967}
          stroke={colors.textTertiary}
          strokeWidth={2}
        />
        <rect
          x={70}
          y={55}
          width={14}
          height={14}
          rx={3.81967}
          stroke={colors.textTertiary}
          strokeWidth={2}
        />
        <rect
          x={26}
          y={73}
          width={14}
          height={14}
          rx={3.81967}
          stroke={colors.textTertiary}
          strokeWidth={2}
        />
        <rect
          x={48}
          y={73}
          width={14}
          height={14}
          rx={3.81967}
          stroke={colors.textTertiary}
          strokeWidth={2}
        />
        <rect
          x={70}
          y={73}
          width={14}
          height={14}
          rx={3.81967}
          stroke={colors.textTertiary}
          strokeWidth={2}
        />
      </g>
      <defs>
        <clipPath id="clip0_19390_52525">
          <rect y={5} width={109} height={100} rx={20} fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
}
