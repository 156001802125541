import React from 'react';
import {useNavigate} from 'react-router-dom';
import {EmptyBookings} from 'shared/illustration/EmptyBookings';
import {useAppSelector} from 'shared/store';
import {Button} from 'shared/ui/Button';
import {Flex} from 'shared/ui/Flex';
import {Text} from 'shared/ui/Text';

export const BookingsEmptyState = ({
  work_schedule_info_permission,
}: {
  work_schedule_info_permission: boolean;
}) => {
  const navigate = useNavigate();
  const username = useAppSelector(state => state.company.data?.username);
  return (
    <Flex
      direction="column"
      alignItems="center"
      justifyContent="center"
      flex={1}
      style={{marginTop: 138}}
      gap={24}>
      <Flex direction="column" alignItems="center" gap={16}>
        <EmptyBookings />
        <Text typography="title20" style={{alignSelf: 'center'}}>
          Сотрудник не работает
        </Text>
        <Text
          typography="text18"
          color="textTertiary"
          style={{
            textAlign: 'center',
            alignSelf: 'center',
          }}>
          В выбранный день ни один сотрудник не работает. <br />
          Вы можете исправить это в расписании
        </Text>
      </Flex>
      {work_schedule_info_permission && (
        <Button
          size="large"
          style={{
            width: 262,
            alignSelf: 'center',
            pointerEvents: 'auto',
            whiteSpace: 'nowrap',
          }}
          onClick={() => {
            navigate(`/${username}/schedule`);
          }}>
          Посмотреть расписание
        </Button>
      )}
    </Flex>
  );
};
