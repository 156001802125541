import React from 'react';
import styled from 'styled-components';
import {Text} from './Text';
import {useColorScheme} from 'shared/lib/hooks';
import {typography as textTypography} from 'shared/typography';

type TooltipPosition = 'left' | 'center' | 'right';
type Props = {
  text: string | null;
  width?: number;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  typography?: keyof typeof textTypography;
  position?: TooltipPosition;
  textStyle?: React.CSSProperties;
};

const TooltipContainer = styled.div`
  position: relative;
  display: flex;
  &:hover {
    .tooltipVisible {
      visibility: visible;
      opacity: 1;
    }
  }
`;

const TooltipArrow = styled.div<{
  siteTheme: 'dark' | 'light';
  backgroundColor?: string;
}>`
  position: absolute;
  visibility: hidden;
  left: 50%;
  top: 100%;
  margin-top: -5px;
  opacity: 0;
  transform: translateX(-50%);
  z-index: 2;
  border-width: 5px;
  border-style: solid;
  transition: opacity 0.2s ease-in-out;
  border-color: transparent transparent
    ${({theme, backgroundColor, siteTheme}) =>
      backgroundColor
        ? backgroundColor
        : siteTheme === 'dark'
        ? theme.bgTertiary
        : theme.bgPrimary}
    transparent;
`;

const TooltipContent = styled.div<{
  siteTheme: 'dark' | 'light';
  width?: number;
  position?: TooltipPosition;
}>`
  visibility: hidden;
  background-color: ${({theme, siteTheme}) =>
    siteTheme === 'dark' ? theme.bgTertiary : theme.bgPrimary};
  justify-content: center;
  padding: 16px;
  border-radius: 16px;
  position: absolute;
  z-index: 1;
  width: ${props => props.width + 'px'};
  top: 100%;
  left: ${({position}) => {
    if (position === 'left') return '0%';
    if (position === 'right') return 'auto';
    return '50%';
  }};
  right: ${({position}) => (position === 'right' ? '-25%' : 'auto')};
  margin-top: 5px;
  margin-left: ${({position, width}) => {
    if (position === 'left') return `-10px`;
    if (position === 'right') return 'auto';
    return `-${width ? width / 2 : 100}px`;
  }};
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  ${({theme}) => theme.cardShadow}
`;
export const Tooltip = (props: Props) => {
  const {
    text,
    children,
    typography = 'subHead14Regular',
    style,
    width = 200,
    position = 'center',
    textStyle,
  } = props;
  const colorScheme = useColorScheme();

  return (
    <TooltipContainer>
      {children}
      {children && (
        <TooltipArrow
          siteTheme={colorScheme}
          backgroundColor={style?.backgroundColor}
          className="tooltipVisible"
        />
      )}
      <TooltipContent
        width={width}
        position={position}
        style={style}
        siteTheme={colorScheme}
        className="tooltipVisible">
        <Text style={textStyle} typography={typography}>
          {text}
        </Text>
      </TooltipContent>
    </TooltipContainer>
  );
};
