import React from 'react';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';
import {Head} from 'shared/ui/Head';
import {device} from 'shared/device';
import {NavLink} from 'shared/ui/NavLink';
import {Content} from 'shared/ui/Content';
import {Flex} from 'shared/ui/Flex';
import {Logo} from 'shared/icons/Logo';
// eslint-disable-next-line no-restricted-imports
import packageJson from '../../../../package.json';
import {NavigateBack} from 'shared/ui/NavigateBack';
import {Seo} from 'shared/ui/Seo';
const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0 auto;
  max-width: 472px;
  min-height: 100dvh;
  border-radius: 0;
  padding-top: 64px;
  padding-bottom: 8px;
  @media screen and (${device.mobile}) {
    margin-top: 56px;
  }
`;

export const AboutApp = () => {
  return (
    <Wrapper>
      <Seo title="О продукте" />
      <Head>
        <NavigateBack />
        <Text typography="title24">О продукте</Text>
      </Head>
      <Content gap="16px">
        <Flex gap={8} direction="column" alignItems="center">
          <Logo size={100} />
          <Flex gap={2} direction="column" alignItems="center">
            <Text typography="title18">Booky Business</Text>
            <Text typography="footNote12Medium">
              Версия {packageJson.version}
            </Text>
          </Flex>
        </Flex>
        <Flex gap={8} direction="column" style={{paddingLeft: 16}}>
          <NavLink
            label="Политика конфиденциальности"
            onClick={() => {
              window.open(
                'https://business.booky.co/privacy-policy/',
                '_blank',
              );
            }}
          />
          <NavLink
            label="Пользовательское соглашение"
            onClick={() => {
              window.open('https://business.booky.co/terms-of-use/', '_blank');
            }}
          />
          <NavLink
            label="Лицензионный договор"
            onClick={() => {
              window.open(
                'https://business.booky.co/agreement-offer/',
                '_blank',
              );
            }}
          />
        </Flex>
      </Content>
    </Wrapper>
  );
};
