import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  background-color: ${props => props.theme.bgPrimary};
  border-style: solid;
  border-width: 1px;
  border-color: ${props => props.theme.borderPrimary};
  z-index: 9999;
  position: absolute;
  padding: 16px;
  border-radius: 24px;
  left: 16px;
  top: 76px;
  pointer-events: all;
  ${({theme}) => theme.cardShadow}
`;

const Backdrop = styled.div<{variant: 'overlay' | 'transparent'}>`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
  background-color: ${({theme, variant}) =>
    variant === 'transparent' ? 'transparent' : theme.modalOverlay};
  pointer-events: all;
`;

export function Modal({
  visible,
  onClose,
  children,
  style,
  backdropVariant = 'transparent',
}: React.PropsWithChildren<{
  visible?: boolean;
  onClose?: React.MouseEventHandler<HTMLDivElement>;
  style?: React.CSSProperties;
  backdropVariant?: 'overlay' | 'transparent';
}>) {
  if (!visible) {
    return null;
  }
  return (
    <>
      <Backdrop variant={backdropVariant} onClick={onClose} />
      <Wrapper style={style}>{children}</Wrapper>
    </>
  );
}
