import React from 'react';
import {Text} from 'shared/ui/Text';
import {Grow} from 'shared/ui/Grow';
import {useResize} from 'shared/lib/hooks';
import {EmptyReview} from 'shared/illustration/EmptyReview';

export const EmptyState = () => {
  const isMobile = useResize().isMobile;

  return (
    <Grow style={{padding: '0 16px'}}>
      <EmptyReview size={isMobile ? '200px' : '300px'} />
      <Text
        typography="title20"
        style={{textAlign: 'center', alignSelf: 'center', marginTop: 16}}>
        Пока нет отзывов
      </Text>
      <Text
        typography="text16Regular"
        color="textTertiary"
        style={{textAlign: 'center', alignSelf: 'center', marginTop: 16}}>
        Через какое-то время они здесь обязательно появятся
      </Text>
    </Grow>
  );
};
