import React from 'react';
import {useNavigate} from 'react-router-dom';
import {NotFound} from 'shared/illustration/NotFound';
import {Button} from 'shared/ui/Button';
import {Flex} from 'shared/ui/Flex';
import {Seo} from 'shared/ui/Seo';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100vh;
  gap: 234px;
  background-color: ${({theme}) => theme.bgPrimary};
`;

export const NoPermission = () => {
  const navigate = useNavigate();
  return (
    <Wrapper>
      <Seo title="Не найдено" />
      <NotFound />
      <Flex direction="column" alignItems="center">
        <Text style={{fontSize: '180px', fontWeight: 800, lineHeight: '180px'}}>
          403
        </Text>
        <Text typography="title24" style={{marginTop: 16}}>
          Нет доступа к странице
        </Text>
        <Text
          typography="text18"
          color="textTertiary"
          style={{marginTop: 12, textAlign: 'center'}}>
          У вас нет прав доступа к данной странице. <br /> Обратитесь к
          владельцу или администратору данной организации, <br /> чтобы они вам
          дали доступ к данной странице
        </Text>
        <Button
          typography="text16Semibold"
          size="large"
          style={{marginTop: 32, paddingLeft: 76.5, paddingRight: 76.5}}
          onClick={() => {
            navigate('/');
          }}>
          Перейти на главную страницу
        </Button>
      </Flex>
    </Wrapper>
  );
};
