import React from 'react';
import {getTimeArray} from 'shared/lib/utils';
import {useAppSelector} from 'shared/store';
import styled from 'styled-components';

const HEIGHT = 43;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 10px;
  left: 0;
  width: 100%;
  height: 100%;
  gap: 24px;
`;

const Column = styled.div<{$columns: number}>`
  width: ${({$columns}) =>
    `calc((100% - 24px * ${$columns - 1}) / ${$columns})`};
  height: 100%;
`;

const Row = styled.div<{$isTop: boolean}>`
  width: 100%;
  height: ${HEIGHT}px;
  border-top: ${({$isTop, theme}) =>
    $isTop ? `1px solid ${theme.borderPrimary}` : 'none'};
  border-bottom: 1px solid ${({theme}) => theme.borderPrimary};
`;

export const TableBg = ({
  start_time,
  end_time,
  columns,
  length,
}: {
  start_time: number;
  end_time: number;
  columns: number;
  length: number;
}) => {
  const intervalTime = useAppSelector(
    state => state.booking.filter.interval_time,
  );
  return (
    <Wrapper>
      {Array.from(Array(Math.min(columns, length)).keys()).map(item => (
        <Column
          key={'column_table_' + item}
          $columns={Math.min(columns, length)}>
          {getTimeArray(intervalTime, start_time, end_time)
            .slice(0, -1)
            .map((time, index) => (
              <Row key={time} $isTop={index === 0} />
            ))}
        </Column>
      ))}
    </Wrapper>
  );
};
