import React from 'react';
import {Content} from 'shared/ui/Content';
import {Slide} from 'shared/ui/Slide';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';
import {Button} from 'shared/ui/Button';
import {Flex} from 'shared/ui/Flex';
import {SubscriptionsType} from 'shared/__generated__/graphql';
import {TariffPlan, getTariff} from 'shared/lib/utils';
import {MY_TARIFF, TooltipRow} from 'entities/tariff';
import {gql} from 'shared/__generated__';
import {ApolloError, useMutation} from '@apollo/client';
import {showAlert} from 'shared/ui/Alert';
import {useNavigate} from 'react-router-dom';
import {ME} from 'shared/ui/MenuItem';
import {SIDEBAR_QUERY} from 'entities/sidebar';
const CurrentTariff = styled.div`
  display: flex;
  flex-direction: column;
  width: max-content;
  gap: 8px;
  margin: 0 auto;
  align-items: center;
`;
const Badge = styled.div`
  display: flex;
  padding: 8px 16px;
  background-color: ${({theme}) => theme.bgSuccess};
  border-radius: 12px;
  color: ${({theme}) => theme.successPrimary};
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  font-style: normal;
  font-family: 'Inter';
`;
const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
type TooltipRow = {title: string; tooltip: string | null};
const rows: TooltipRow[] = [
  {title: '1 Сотрудник', tooltip: null},
  {
    title: 'Все модули Booky',
    tooltip:
      'Полный комплект функций для управления вашим бизнесом, от онлайн-записи до учета рабочего времени сотрудников',
  },
  {
    title: 'Все интеграции Booky',
    tooltip:
      'Совместимость с популярными инструментами и приложениями. Список всегда пополняется',
  },
  {
    title: 'Управление с любого устройства',
    tooltip:
      'Удобство контроля вашего бизнеса везде и всегда. Полное управление с мобильного приложения',
  },
  {
    title: 'Техническая поддержка 24/7',
    tooltip: 'Поддержка круглосуточно без выходных.',
  },
  {
    title: 'Консультации по API',
    tooltip: 'Помощь в настройке и интеграции API.',
  },
];
const CHANGE_TARIFF_FREE = gql(`
  mutation ChangePlanToFree($companyId: String!) {
    changePlanToFree(company_id: $companyId)
  }
`);
export const FreeSlide = ({
  tariffPlan,
  unavailable = false,
  companyId,
  countActiveEmployees,
}: {
  tariffPlan: TariffPlan | null | undefined;
  unavailable?: boolean;
  companyId: string;
  countActiveEmployees: number;
}) => {
  const [changeTariff, {loading: changeLoading}] = useMutation(
    CHANGE_TARIFF_FREE,
    {
      refetchQueries: [
        {
          query: MY_TARIFF,
          variables: {companyId, first: 5},
        },
        {
          query: ME,
        },
        {
          query: SIDEBAR_QUERY,
          variables: {companyId: companyId},
        },
      ],
    },
  );
  const isPlanForever = tariffPlan?.type === SubscriptionsType.Forever;
  const navigate = useNavigate();
  const onPressChangeTariff = async () => {
    if (countActiveEmployees > 1) {
      navigate('/change-tariff');
    } else {
      try {
        await changeTariff({variables: {companyId}});
        showAlert('success', 'Тариф был изменен на бесплатный');
      } catch (error) {
        if (error instanceof ApolloError) {
          showAlert('error', error.message);
        }
      }
    }
  };
  return (
    <Slide>
      <Content>
        <Head>
          <Text typography="title18">Что вы получаете?</Text>
          {isPlanForever && <Badge>Текущий тариф</Badge>}
        </Head>
        <Flex gap={8} direction="column">
          {rows.map(row => (
            <TooltipRow key={row.title} {...row} />
          ))}
        </Flex>
      </Content>
      {tariffPlan && !unavailable ? (
        <CurrentTariff>
          <Text typography="subHead14Medium" color="textTertiary">
            Текущий тарифный план
          </Text>
          <Text typography="footNote12Regular" color="textTertiary">
            {getTariff(tariffPlan)}
          </Text>
        </CurrentTariff>
      ) : null}
      <Button
        loading={changeLoading}
        disabled={isPlanForever}
        size="large"
        onClick={onPressChangeTariff}>
        Подключить
      </Button>
    </Slide>
  );
};
