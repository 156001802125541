import React, {useState} from 'react';
import {Modal} from 'shared/ui/Modal';
import {styled} from 'styled-components';
import {Flex} from 'shared/ui/Flex';
import {Text} from 'shared/ui/Text';
import {getPhoneMask} from 'shared/lib/utils';
import {Phone} from 'shared/icons/Phone';
import {Chat} from 'shared/icons/Chat';
import {Whatsapp} from 'shared/icons/Whatsapp';
import {Telegram} from 'shared/icons/Telegram';
import {Viber} from 'shared/icons/Viber';
import {Mail} from 'shared/icons/Mail';

const PopupWrapper = styled.div`
  position: relative;
  display: inline-block;
`;
const CellText = styled(Text)`
  align-self: center;
  white-space: nowrap;
`;
const MessengerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  pointer-events: all;
  cursor: pointer;
`;
const CirleBackground = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background-color: ${({theme}) => theme.bgSecondary};
`;

export const Messengers = ({
  phone,
  email,
  permission,
}: {
  phone?: string | null;
  email?: string | null;
  permission: boolean;
}) => {
  const [visible, setVisible] = useState(false);
  const onClickMessage = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setVisible(false);
    e.stopPropagation();
  };
  return (
    <PopupWrapper>
      <CellText
        onClick={e => {
          permission && setVisible(true);
          e.stopPropagation();
        }}
        color="mainPrimary">
        {getPhoneMask(phone)}
      </CellText>
      <Modal
        style={{top: 32, left: 0, width: 280}}
        visible={visible}
        onClose={e => {
          setVisible(false);
          e.stopPropagation();
        }}>
        <Flex gap={16} direction="column">
          <MessengerWrapper
            onClick={e => {
              phone &&
                window.open(
                  `tel:${getPhoneMask(phone)}`,
                  '_blank',
                  'noreferrer',
                );
              onClickMessage(e);
            }}>
            <CirleBackground>
              <Phone />
            </CirleBackground>
            <Text typography="text16Regular" style={{cursor: 'pointer'}}>
              Позвонить
            </Text>
          </MessengerWrapper>
          <MessengerWrapper
            onClick={e => {
              phone &&
                window.open(
                  `sms:${getPhoneMask(phone)}`,
                  '_blank',
                  'noreferrer',
                );
              onClickMessage(e);
            }}>
            <CirleBackground>
              <Chat />
            </CirleBackground>
            <Text typography="text16Regular" style={{cursor: 'pointer'}}>
              Отправить смс
            </Text>
          </MessengerWrapper>
          <MessengerWrapper
            onClick={e => {
              phone && window.open(`https://wa.me/${phone}`, '_blank');
              onClickMessage(e);
            }}>
            <CirleBackground>
              <Whatsapp />
            </CirleBackground>
            <Text typography="text16Regular" style={{cursor: 'pointer'}}>
              Написать в Whatsapp
            </Text>
          </MessengerWrapper>
          <MessengerWrapper
            onClick={e => {
              phone && window.open(`https://t.me/${phone}`, '_blank');
              onClickMessage(e);
            }}>
            <CirleBackground>
              <Telegram />
            </CirleBackground>
            <Text typography="text16Regular" style={{cursor: 'pointer'}}>
              Написать в Telegram
            </Text>
          </MessengerWrapper>
          <MessengerWrapper
            onClick={e => {
              phone &&
                window.open(
                  `https://viber.com/send?phone=${phone}&text=${'Здравствуйте!'}`,
                  '_blank',
                );
              onClickMessage(e);
            }}>
            <CirleBackground>
              <Viber />
            </CirleBackground>
            <Text typography="text16Regular" style={{cursor: 'pointer'}}>
              Написать в Viber
            </Text>
          </MessengerWrapper>
          {email && (
            <MessengerWrapper
              onClick={e => {
                phone &&
                  window.open(`mailto:${email}}`, '_blank', 'noreferrer');
                onClickMessage(e);
              }}>
              <CirleBackground>
                <Mail />
              </CirleBackground>
              <Text typography="text16Regular" style={{cursor: 'pointer'}}>
                Отправить E-mail
              </Text>
            </MessengerWrapper>
          )}
        </Flex>
      </Modal>
    </PopupWrapper>
  );
};
