import React from 'react';
import styled from 'styled-components';
import {Text} from 'shared/ui/Text';
import {Flex} from 'shared/ui/Flex';

const AnalyticBox = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background: none;
  background-color: ${({theme}) => theme.bgPrimary};
  border: 1px solid ${({theme}) => theme.borderPrimary};
  border-radius: 24px;
  min-width: 342px;
  height: 292px;
  padding: 16px;
`;
const PercentBox = styled.div<{sign: boolean}>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
  padding: 0 8px;
  margin-left: 12px;
  border-radius: 8px;
  background-color: ${({theme, sign}) =>
    sign ? theme.bgSuccess : theme.bgDanger};
  color: ${({theme, sign}) =>
    sign ? theme.successPrimary : theme.dangerPrimary};
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  white-space: nowrap;
`;
const AnalyticValueText = styled.div`
  font-family: 'Inter';
  font-size: 64px;
  font-weight: 600;
  line-height: 64px;
  letter-spacing: 0px;
  text-align: left;
  color: ${({theme}) => theme.mainPrimary};
  white-space: nowrap;
`;

export const AnalyiticCard = ({
  children,
  value = '0',
  title,
  percent = 0,
}: React.PropsWithChildren<{
  value?: string;
  title: string;
  percent?: number;
}>) => {
  return (
    <AnalyticBox>
      <Text typography="text18">{title}</Text>
      <Flex mt={24} mb={32} style={{alignItems: 'end'}}>
        <AnalyticValueText>{value}</AnalyticValueText>
        {percent !== 0 && (
          <PercentBox sign={percent >= 0}>{`${
            percent > 0 ? '+' : '-'
          } ${Math.abs(percent)}%`}</PercentBox>
        )}
      </Flex>
      {children}
    </AnalyticBox>
  );
};
