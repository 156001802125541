import {TooltipRow, tariffRows} from 'entities/tariff';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import Close from 'shared/icons/Close';
import {useAppSelector} from 'shared/store';
import {Button} from 'shared/ui/Button';
import {Divider} from 'shared/ui/Divider';
import {Flex} from 'shared/ui/Flex';
import {IconButton} from 'shared/ui/IconButton';
import {Popup} from 'shared/ui/Popup';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 588px;
  gap: 16px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const SubscriptionFree = ({
  visible,
  onClose,
}: {
  visible: boolean;
  onClose: () => void;
}) => {
  const navigate = useNavigate();
  const username = useAppSelector(state => state.company.data?.username);
  const close = () => {
    onClose();
    navigate(`/${username}/my-tariff`);
  };
  return (
    <Popup visible={visible} onClose={close}>
      <ModalWrapper>
        <Header>
          <div style={{width: 40}} />
          <Text align="center" typography="title24">
            Вы перешли на бесплатный тариф
          </Text>
          <IconButton size={40} onClick={onClose}>
            <Close />
          </IconButton>
        </Header>

        <Text align="center">
          Бесплатный тариф начнет действовать после окончания текущей подписки.
          На этом тарифе вам доступны:
        </Text>
        <Flex gap={8} direction="column">
          <TooltipRow {...tariffRows[0]} />
          <Divider />
          {tariffRows
            .filter((_, index) => index !== 0)
            .map(row => (
              <TooltipRow key={row.title} {...row} />
            ))}
        </Flex>
        <Button size="large" variant="outline" onClick={close}>
          Отлично
        </Button>
      </ModalWrapper>
    </Popup>
  );
};
