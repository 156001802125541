import React from 'react';
import styled from 'styled-components';
import {Text} from './Text';
import {Divider} from './Divider';
import {useResize} from 'shared/lib/hooks';
import {device} from 'shared/device';
import {Flex} from './Flex';
import {NavLink, To, useMatch} from 'react-router-dom';
import {showAlert} from './Alert';
import {Skeleton} from './Skeleton';

type DataItem = {
  icon: React.JSX.Element;
  label: string;
  tooltip?: () => React.ReactElement;
  to: To;
  disabled?: boolean;
  alertMessage?: string;
};

interface Props {
  data: DataItem[];
  style?: React.CSSProperties;
  title?: string;
  description?: string;
  avatar?: React.JSX.Element;
  loading?: boolean;
  skeletonItemNumber?: number;
  descriptionStyle?: React.CSSProperties;
}
const Row = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 4px;
  padding: 0 16px;
  overflow-x: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const Box = styled.div`
  padding: 24px;
  background-color: ${({theme}) => theme.bgPrimary};
  border-radius: 24px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({theme}) => theme.borderPrimary};
  gap: 16px;
  display: flex;
  flex-direction: column;
`;
const SectionItem = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  gap: 24px;
`;
const Column = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;
const MenuItem = styled(NavLink)<{isActive: boolean}>`
  display: flex;
  align-items: center;
  height: 56px;
  padding: 16px;
  gap: 16px;
  border-width: 0;
  text-overflow: ellipsis;
  border-radius: 12px;
  background-color: ${({isActive, theme}) =>
    isActive ? theme.bgSecondary : theme.bgPrimary};
  color: ${props => props.theme.textPrimary};
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-decoration-line: none;
  cursor: pointer;
  &:active {
    transform: translateY(2px);
  }
  &:disabled {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
  }
  &:hover {
    background-color: ${({theme}) => theme.bgSecondary};
  }
  transition: background-color 0.3s;
  @media (${device.mobile}) {
    display: flex;
    white-space: nowrap;
    height: auto;
    justify-content: center;
    overflow: visible;
    align-items: center;
    background-color: ${({isActive, theme}) =>
      isActive ? theme.bgPrimary : theme.bgSecondary};
    color: ${({isActive, theme}) =>
      isActive ? theme.mainPrimary : theme.textPrimary};
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    padding: 7px 24px;
  }
`;

export const Menu = (props: React.PropsWithChildren<Props>) => {
  const {
    title,
    description,
    style,
    avatar,
    data,
    loading,
    skeletonItemNumber,
    descriptionStyle,
  } = props;
  const {isMobile} = useResize();

  if (isMobile) {
    return (
      <Row style={style}>
        {data?.map((props, index) => (
          <NavItem {...props} key={'navlink_' + index} />
        ))}
      </Row>
    );
  }

  return (
    <Box style={style}>
      <SectionItem>
        {avatar && loading ? (
          <Skeleton height={64} width={64}>
            <circle r={32} cx={32} cy={32} />
          </Skeleton>
        ) : (
          avatar
        )}
        {(title || description) && (
          <Column>
            {loading ? (
              <Skeleton height={24} width={120}>
                <rect height={24} width={120} rx={12} />
              </Skeleton>
            ) : (
              <>
                <Text
                  style={{
                    WebkitLineClamp: 2,
                    display: '-webkit-box',
                    maxHeight: 48,
                    overflow: 'hidden',
                  }}
                  typography="title18">
                  {title}
                </Text>
                {description && (
                  <Text
                    style={{marginTop: 8, ...descriptionStyle}}
                    typography="subHead14Medium"
                    color="textTertiary">
                    {description}
                  </Text>
                )}
              </>
            )}
          </Column>
        )}
      </SectionItem>
      {(title || description || avatar) && <Divider />}
      <Column style={{marginTop: 8, gap: 16}}>
        {!loading
          ? data?.map((props, index) => (
              <NavItem {...props} key={'navlink_' + index} />
            ))
          : Array.from(Array(skeletonItemNumber ?? 4)).map((_, index) => {
              return (
                <Skeleton height={56} width={'100%'} key={`skeleton_${index}`}>
                  <rect height={56} width={'100%'} rx={12} />
                </Skeleton>
              );
            })}
      </Column>
    </Box>
  );
};

const NavItem = (data: DataItem) => {
  const {to, label, icon, tooltip, disabled, alertMessage} = data;
  const match = useMatch(to.toString());
  return (
    <MenuItem
      replace
      key={label}
      to={to}
      onClick={e => {
        if (disabled) {
          showAlert('warning', alertMessage ?? '');
          e.preventDefault();
        }
      }}
      isActive={!!match}>
      {icon}
      <Flex
        flex={1}
        alignItems="center"
        justifyContent="space-between"
        style={{overflow: 'hidden'}}>
        <Text
          style={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}>
          {label}
        </Text>
      </Flex>
      {tooltip && tooltip()}
    </MenuItem>
  );
};
