import React from 'react';
export const Phone = ({
  size = 24,
  color = '#6765F8',
  type = 'line',
}: {
  size?: number;
  color?: string;
  type?: 'line' | 'bold';
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={type === 'line' ? 'none' : color}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.792 4.48l.73 1.309c.66 1.18.395 2.73-.643 3.767 0 0 0 0 0 0s-1.259 1.26 1.024 3.541c2.281 2.282 3.54 1.025 3.54 1.024 0 0 0 0 0 0 1.039-1.038 2.587-1.302 3.768-.643l1.308.73c1.783.995 1.994 3.495.427 5.062-.942.942-2.096 1.674-3.37 1.723-2.147.081-5.793-.462-9.45-4.12-3.657-3.656-4.2-7.302-4.119-9.448.049-1.275.781-2.429 1.723-3.37 1.567-1.568 4.067-1.357 5.062.426z"
        stroke={type === 'line' ? color : 'none'}
        strokeWidth={1.5}
        strokeLinecap="round"
      />
    </svg>
  );
};
