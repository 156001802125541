import React from 'react';
import {Skeleton} from 'shared/ui/Skeleton';

export const SkeletonUpdateSourceColor = () => {
  return (
    <Skeleton height={136}>
      {Array.from(Array(9).keys()).map(item => (
        <circle
          key={'circle_1_' + item}
          r={28}
          cx={`calc(28px + ((100% - 56px * 9) / 8 + 56px) * ${item})`}
          cy={28}
        />
      ))}
      {Array.from(Array(9).keys()).map(item => (
        <circle
          key={'circle_2_' + item}
          r={28}
          cx={`calc(28px + ((100% - 56px * 9) / 8 + 56px) * ${item})`}
          cy={108}
        />
      ))}
    </Skeleton>
  );
};
