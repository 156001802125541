import React from 'react';
import {Skeleton} from 'shared/ui/Skeleton';

export const SkeletonDetailBooking = () => {
  return (
    <Skeleton height={976} width={'100%'}>
      <rect width={'100%'} x={0} y={0} height={96} rx={18} ry={18} />
      <rect width={'100%'} x={0} y={120} height={152} rx={18} ry={18} />
      <rect width={'100%'} x={0} y={296} height={152} rx={18} ry={18} />
      <rect width={'100%'} x={0} y={472} height={152} rx={18} ry={18} />
      <rect width={'100%'} x={0} y={548} height={152} rx={18} ry={18} />
      <rect width={'100%'} x={0} y={724} height={248} rx={18} ry={18} />
    </Skeleton>
  );
};
