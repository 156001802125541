import {useQuery} from '@apollo/client';
import {GET_WEBHOOKS, WebhookStatus} from 'entities/webhooks';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Plus} from 'shared/icons/Plus';
import {EmptySynchronize} from 'shared/illustration/EmptySynchronize';
import {useAppSelector} from 'shared/store';
import {Button} from 'shared/ui/Button';
import {Content} from 'shared/ui/Content';
import {Flex} from 'shared/ui/Flex';
import {Grow} from 'shared/ui/Grow';
import {NavigateBack} from 'shared/ui/NavigateBack';
import {Seo} from 'shared/ui/Seo';
import {Skeleton} from 'shared/ui/Skeleton';
import {Table} from 'shared/ui/Table';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 56px;
  gap: 16px;
  margin: 0;
  justify-content: space-between;
`;

export const WebHooks = () => {
  const companyId = useAppSelector(state => state.company.data!.id);
  const username = useAppSelector(state => state.company.data?.username);
  const navigate = useNavigate();
  const {data, loading} = useQuery(GET_WEBHOOKS, {variables: {companyId}});
  const webhooks = data?.getWebHooks ?? [];
  return (
    <Wrapper>
      <Seo title="Webhooks" />
      <Header>
        <Flex gap={16} alignItems="center">
          <NavigateBack />
          <Text style={{flex: 1}} typography="title24">
            Webhooks
          </Text>
          {webhooks.length > 0 && (
            <Text color="textTertiary" typography="title24">
              {webhooks.length}
            </Text>
          )}
        </Flex>
        <Button
          leftIcon={<Plus />}
          onClick={() => navigate(`/${username}/add-webhook`)}>
          Добавить Webhook
        </Button>
      </Header>
      {loading ? (
        <SkeletonWebHooks />
      ) : webhooks.length ? (
        <Content>
          <Table
            titles={[
              'URL',
              'Метод',
              'Статус',
              'Ошибки за последние 7 дней',
              'Процент ошибок',
            ]}
            widths={[400, 230, 230, 230, 230]}
            onClick={index =>
              !!webhooks.length &&
              webhooks[index] &&
              navigate(`/${username}/edit-webhook/${webhooks[index]?.id}`)
            }
            rows={webhooks.map((item, index) => [
              item?.url,
              item?.send_type,
              <WebhookStatus
                enabled={item?.is_active ?? false}
                key={'webhook_' + index}
              />,
              item && item.errorsLastSevenDays > 0
                ? item.errorsLastSevenDays
                : '-',
              item && item.errorsPercent > 0
                ? item.errorsPercent + '\u00A0%'
                : '-',
            ])}
          />
        </Content>
      ) : (
        <EmptyWebhooks />
      )}
    </Wrapper>
  );
};

const EmptyWebhooks = () => {
  return (
    <Grow style={{padding: '0 16px'}}>
      <EmptySynchronize size={300} />
      <Text
        typography="title20"
        style={{textAlign: 'center', alignSelf: 'center', marginTop: 16}}>
        Нет данных для показа
      </Text>
      <Text
        typography="text16Regular"
        color="textTertiary"
        style={{textAlign: 'center', alignSelf: 'center', marginTop: 16}}>
        Для изменения нажмите на кнопку “Добавить Webhook”
      </Text>
    </Grow>
  );
};

const SkeletonWebHooks = () => (
  <Content>
    <Skeleton height={400}>
      <rect width={28} height={20} rx={8} ry={8} x={0} y={16} />
      <rect width={45} height={20} rx={8} ry={8} x="30%" y={16} />
      <rect width={48} height={20} rx={8} ry={8} x="50%" y={16} />
      <rect width={200} height={20} rx={8} ry={8} x="70%" y={16} />
      <rect width={116} height={20} rx={8} ry={8} x="90%" y={16} />
      {Array(4)
        .fill(0)
        .map((_, index) => (
          <React.Fragment key={'service_skeleton_' + index}>
            <rect
              width="20%"
              height={24}
              rx={8}
              ry={8}
              x={0}
              y={88 + 64 * index}
            />
            <rect
              width="5%"
              height={24}
              rx={8}
              ry={8}
              x="30%"
              y={88 + 64 * index}
            />
            <rect
              width="10%"
              height={40}
              rx={8}
              ry={8}
              x="50%"
              y={80 + 64 * index}
            />
            <rect
              width="5%"
              height={24}
              rx={8}
              ry={8}
              x="70%"
              y={88 + 64 * index}
            />
            <rect
              width="5%"
              height={24}
              rx={8}
              ry={8}
              x="90%"
              y={88 + 64 * index}
            />
          </React.Fragment>
        ))}
    </Skeleton>
  </Content>
);
