import React, {useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {EmojiLove} from 'shared/icons/EmojiLove';
import {Avatar} from 'shared/ui/Avatar';
import {Button} from 'shared/ui/Button';
import {CheckBox} from 'shared/ui/CheckBox';
import {Divider} from 'shared/ui/Divider';
import {Flex} from 'shared/ui/Flex';
import {RatingStar} from 'shared/ui/RatingStar';
import {Text} from 'shared/ui/Text';
import {TextArea} from 'shared/ui/TextArea';
import styled from 'styled-components';
import {
  Company,
  Employee,
  Image,
  Reply,
  Review as ReviewGraph,
  User,
} from 'shared/__generated__/graphql';
import {ensureError, getName} from 'shared/lib/utils';
import {EmojiAngry} from 'shared/icons/EmojiAngry';
import {EmojiSmileFace} from 'shared/icons/EmojiSmileFace';
import dayjs from 'dayjs';
import {generateReviewReply} from 'shared/api/chatGPT';
import {showAlert} from 'shared/ui/Alert';
import {gql} from 'shared/__generated__';
import {useMutation} from '@apollo/client';
import {Seo} from 'shared/ui/Seo';
import {ArrowLeft2v} from 'shared/icons/ArrowLeft2v';

const CREATE_REPLY = gql(`
  mutation CreateReply($input: CreateReply!) {
    createReply(input: $input) {
      id
    }
  }
`);

const UPDATE_REPLY = gql(`
  mutation UpdateReply($input: UpdateReply!) {
    updateReply(input: $input) {
      id
    }
  }
`);

const Wrapper = styled.div`
  background-color: ${({theme}) => theme.bgSecondary};
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Header = styled(Flex)`
  align-items: center;
  background-color: ${({theme}) => theme.bgPrimary};
  height: 56px;
`;

const NavButton = styled(Button)`
  background-color: ${({theme}) => theme.bgPrimary};
  width: 48px;
  height: 48px;
  margin-left: 6px;
  padding: 0;
`;

const ReviewContent = styled.div`
  padding: 16px;
  background-color: ${({theme}) => theme.bgPrimary};
  border-radius: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 16px 16px 16px;
  gap: 8px;
`;

type Review = Pick<ReviewGraph, 'id' | 'created_at' | 'comment' | 'rating'> & {
  user: Pick<User, 'id' | 'name' | 'surname' | 'avatar'>;
  company: Pick<Company, 'id' | 'avatar' | 'name'>;
  employee?: Pick<Employee, 'id' | 'name' | 'surname' | 'avatar'> | null;
  images: Pick<Image, 'id' | 'url'>[];
  reply?: Pick<Reply, 'id' | 'as_company' | 'text' | 'updated_at'> | null;
  workingdate?: string;
  professionName?: string;
};

export const ReviewMobile = () => {
  const data: Review = useLocation().state;
  const navigate = useNavigate();
  const [reply, setReply] = useState('');
  const [asCompany, setAsCompany] = useState(false);
  const [onEdit, setOnEdit] = useState(false);
  const [generateLoading, setGenerateLoading] = useState(false);

  const [createReply, {loading: loadingCreate}] = useMutation(CREATE_REPLY, {
    refetchQueries: ['ReviewsMainReviews'],
  });
  const [updateReply, {loading: loadingUpdate}] = useMutation(UPDATE_REPLY, {
    refetchQueries: ['ReviewsMainReviews'],
  });

  const replyCreate = async () => {
    const input = {
      as_company: asCompany,
      review_id: data?.id ?? '',
      text: reply,
    };
    await createReply({
      variables: {
        input,
      },
    })
      .then(() => {
        showAlert('success', 'Ответ отправлен');
        setReply('');
        setAsCompany(false);
      })
      .catch(e => {
        const error = ensureError(e);
        showAlert('error', error.message);
      });
  };

  const replyUpdate = async () => {
    const input = {
      as_company: asCompany,
      reply_id: data?.reply?.id ?? '',
      text: reply,
    };
    await updateReply({
      variables: {
        input,
      },
    })
      .then(() => {
        showAlert('success', 'Ответ отправлен');
        setReply('');
        setAsCompany(false);
      })
      .catch(e => {
        const error = ensureError(e);
        showAlert('error', error.message);
      });
  };

  const onPressGenerateGPT = () => {
    setGenerateLoading(true);
    setOnEdit(true);
    generateReviewReply(
      data?.comment ?? '',
      data.rating,
      asCompany ? 'company' : 'employee',
    )
      .then(res => {
        setReply(res.data.choices[0].message.content ?? '');
      })
      .catch(e => {
        const error = ensureError(e);
        showAlert('error', error.message);
      })
      .finally(() => setGenerateLoading(false));
  };

  const getRatingIcon = () => {
    if (data.rating > 4) {
      return <EmojiLove />;
    }
    if (data.rating < 3) {
      return <EmojiAngry />;
    } else {
      return <EmojiSmileFace />;
    }
  };

  return (
    <Wrapper>
      <Seo title="Отзыв" />
      <Header>
        <NavButton onClick={() => navigate('/reviews')}>
          <ArrowLeft2v />
        </NavButton>
        <Text
          align="center"
          style={{position: 'relative', left: '30%'}}
          typography="text16Semibold">
          Отзыв
        </Text>
      </Header>
      <ReviewContent>
        <Flex justifyContent="space-between">
          <Flex gap={16}>
            <Avatar
              url={data.user.avatar?.url}
              size={72}
              variant="avatar"
              icon={getRatingIcon()}
            />
            <Flex direction="column" justifyContent="center">
              <Text typography="text16Semibold">
                {getName(data.user.name, data.user.surname)}
              </Text>
              <RatingStar list rating={data.rating} />
            </Flex>
          </Flex>
          <Text typography="subHead14Regular" color="textTertiary">
            {dayjs(data.created_at).format('DD.MM.YYYY, HH:mm')}
          </Text>
        </Flex>
        <Text mt={16}>{data.comment}</Text>
        <Divider mt={16} mb={16} />
        <Text typography="text16Semibold" mb={16}>
          Детали
        </Text>
        <Flex justifyContent="space-between" mb={16}>
          <Text
            typography="text16Regular"
            color="textTertiary"
            style={{alignSelf: 'center'}}>
            Сотрудник
          </Text>
          <Flex direction="column">
            <Text typography="subHead14Medium">
              {getName(data.employee?.name, data.employee?.surname, 'employee')}
            </Text>
            <Text typography="subHead14Medium" color="textTertiary">
              {data.professionName}
            </Text>
          </Flex>
        </Flex>
        <Flex justifyContent="space-between">
          <Text typography="text16Regular" color="textTertiary">
            Запись
          </Text>
          <Text typography="subHead14Regular" color="mainPrimary">
            запись от {dayjs(data.workingdate).format('DD.MM.YYYY')}
          </Text>
        </Flex>
        <Divider mt={16} mb={16} />
        <Text typography="text16Semibold" mb={16}>
          Ответ на отзыв
        </Text>
        {onEdit || data.reply === null ? (
          <>
            <TextArea value={reply} onChange={setReply} />
            <Text
              mt={16}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}>
              <CheckBox style={{justifyContent: 'normal', marginRight: 16}} />
              Ответить от имени салона
            </Text>
          </>
        ) : (
          <>
            <TextArea value={data.reply?.text ?? ''} disabled />
            <Text typography="subHead14Regular" color="textTertiary">
              {data.reply?.as_company
                ? 'Ответ от имени салона'
                : 'Ответ от имени сотрудника'}
            </Text>
          </>
        )}
      </ReviewContent>
      <ButtonContainer>
        <Button
          loading={generateLoading}
          size="large"
          variant="outline"
          typography="text16Semibold"
          onClick={() => {
            onPressGenerateGPT();
          }}>
          Сгенерировать ответ
        </Button>
        {data.reply === null ? (
          <Button
            disabled={!reply}
            loading={loadingCreate}
            size="large"
            typography="text16Semibold"
            onClick={() => {
              replyCreate();
            }}>
            Отправить ответ
          </Button>
        ) : (
          <Button
            loading={loadingUpdate}
            size="large"
            typography="text16Semibold"
            onClick={() => {
              onEdit ? replyUpdate() : setOnEdit(true);
            }}>
            Редактировать ответ
          </Button>
        )}
      </ButtonContainer>
    </Wrapper>
  );
};
