import React from 'react';
const LockOpen = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.5 8.667h9c2.485 0 4.5 1.99 4.5 4.444v4.445C21 20.01 18.985 22 16.5 22h-9C5.015 22 3 20.01 3 17.556V13.11c0-2.455 2.015-4.444 4.5-4.444zm0 0V6.444c0-1.2.53-2.29 1.392-3.09M12 14.221v2.222M8.892 3.354c.04-.038.08-.074.121-.11m-.121.11a4.63 4.63 0 00-.322.329m.443-.439C9.903 2.474 11.111 2 12.443 2m-3.43 1.244a5.1 5.1 0 012.067-1.072A5.464 5.464 0 0112.444 2m0 0c.56 0 1.12.086 1.655.257.972.31 1.811.886 2.401 1.647"
        stroke="#6765F8"
        strokeWidth={1.5}
        strokeLinecap="round"
      />
    </svg>
  );
};

export default LockOpen;
