import React, {useState} from 'react';
import DotsVertical from 'shared/icons/DotsVertical';
import {Plus2} from 'shared/icons/Plus2';
import {useColors, useResize} from 'shared/lib/hooks';
import {
  clearChats,
  createChat,
  useAppDispatch,
  useAppSelector,
} from 'shared/store';
import {ActionIcon} from 'shared/ui/ActionIcon';
import {Button} from 'shared/ui/Button';
import {Flex} from 'shared/ui/Flex';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';
import dayjs from 'dayjs';
import {Dropdown} from 'shared/ui/Dropdown';
import {Trash} from 'shared/icons/Trash';
import {Modal} from 'shared/ui/Modal';
import Xv2 from 'shared/icons/Xv2';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  grid-column-start: 1;
  grid-column-end: 3;
  background-color: ${({theme}) => theme.bgPrimary};
  padding: 24px;
`;

const ChatItem = styled.button<{active: boolean}>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 12px 24px;
  cursor: pointer;
  pointer-events: all;
  background-color: ${({theme, active}) =>
    active ? theme.bgSecondary : 'transparent'};
  border-radius: 24px;
  border: none;
`;

const ScrollView = styled.div`
  display: flex;
  flex-direction: column;
  overflow: scroll;
  height: calc(100vh - 136px);
  gap: 16px;
  &::-webkit-scrollbar-corner {
    display: none;
  }
`;

type Props = {
  select: number | null;
  onSelect: (value: number | null) => void;
};

export const BlockChats = (props: Props) => {
  const {select, onSelect} = props;
  const colors = useColors();
  const {width} = useResize();
  const chats = useAppSelector(state => state.chats.chats);
  const dispatch = useAppDispatch();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [visible, setVisible] = useState(false);

  return (
    <Wrapper>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        style={{height: 'min-content', width: '100%', position: 'relative'}}>
        <ActionIcon
          icon={<DotsVertical />}
          onClick={() => {
            setDropdownVisible(true);
          }}
        />
        <Dropdown
          visible={dropdownVisible}
          onClose={() => setDropdownVisible(false)}
          data={['Очистить историю']}
          style={{
            top: 28,
            width: 'max-content',
            paddingTop: 16,
          }}
          renderItem={item => (
            <Button
              variant="subtitle"
              color="green"
              style={{
                flex: 1,
                width: 300,
                justifyContent: 'space-between',
              }}
              onClick={() => {
                setVisible(true);
                setDropdownVisible(false);
              }}
              rightIcon={<Trash color="red" />}>
              <Text style={{pointerEvents: 'none'}} color="dangerPrimary">
                {item}
              </Text>
            </Button>
          )}
        />
        <Button
          style={{
            paddingLeft: width < 1280 ? 8 : 16,
            paddingRight: width < 1280 ? 8 : 16,
          }}
          onClick={() => {
            dispatch(createChat({id: chats.length + 1, message: ''}));
            onSelect(chats.length + 1);
          }}>
          {width <= 1280 ? (
            <Plus2 color={colors.white} />
          ) : (
            <Text
              color="white"
              typography="text16Semibold"
              style={{width: 'max-content'}}>
              Новый вопрос
            </Text>
          )}
        </Button>
      </Flex>
      <ScrollView>
        {chats.map(item => (
          <ChatItem
            active={select === item.id}
            key={'chatItem_' + item.id}
            onClick={() => {
              if (select === item.id) {
                onSelect(null);
              } else {
                onSelect(item.id);
              }
            }}>
            <Text
              typography="text18"
              style={{
                pointerEvents: 'none',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: '100%',
                maxHeight: 48,
              }}>
              {item.message.length === 0 ? 'Новый чат' : item.message}
            </Text>
            <Text
              typography="footNote12Medium"
              color="textTertiary"
              style={{pointerEvents: 'none'}}>
              {dayjs(item.created_at).format('DD MMMM YYYY г.')}
            </Text>
          </ChatItem>
        ))}
      </ScrollView>
      <Modal
        style={{
          left: 'calc(50% - 220px)',
          top: 'calc(50% - 106px)',
          gap: 16,
          paddingLeft: 24,
          paddingRight: 24,
        }}
        visible={visible}
        onClose={() => setVisible(false)}>
        <Flex direction="column" gap={16} style={{width: 440}}>
          <Flex alignItems="center" justifyContent="space-between">
            <Text typography="title20">Очистить историю</Text>
            <ActionIcon icon={<Xv2 />} onClick={() => setVisible(false)} />
          </Flex>
          <Text>Вы уверены, что хотите очистить историю?</Text>
          <Flex gap={8} mt={8}>
            <Button
              variant="outline"
              style={{flex: 1}}
              onClick={() => setVisible(false)}>
              Отмена
            </Button>
            <Button
              variant="outline"
              onClick={() => {
                dispatch(clearChats());
                onSelect(null);
                setVisible(false);
              }}
              style={{
                borderColor: colors.dangerPrimary,
                color: colors.dangerPrimary,
                flex: 1,
              }}>
              Очистить
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </Wrapper>
  );
};
