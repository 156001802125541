import React from 'react';

export const Filter1 = ({
  size = 24,
  color = '#6765F8',
}: {
  size?: number;
  color?: string;
}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path
        d="M12 8L12 5M20 10V5M4 14L4 19M12 19V16M20 19V18M4 5V6M10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12ZM18 14C18 12.8954 18.8954 12 20 12C21.1046 12 22 12.8954 22 14C22 15.1046 21.1046 16 20 16C18.8954 16 18 15.1046 18 14ZM2 10C2 11.1046 2.89543 12 4 12C5.10457 12 6 11.1046 6 10C6 8.89543 5.10457 8 4 8C2.89543 8 2 8.89543 2 10Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
