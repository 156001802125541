import {useSelector} from 'react-redux';
import {TypedUseSelectorHook, useDispatch} from 'react-redux';
import {persistStore, persistReducer} from 'redux-persist';
import {combineReducers, configureStore} from '@reduxjs/toolkit';
import AuthSlice, {
  logoutThunk,
  setToken,
  setUserId,
  setTutorialVisible,
} from './slices/authSlice';
import CompanySlice, {setCompanyData} from './slices/companySlice';
import themeSlice, {setThemeSlice} from './slices/themeSlice';
import storage from 'redux-persist/lib/storage';

import bookingSlice, {
  setCreateBookingWorkingDate,
  setCreateBookingServices,
  setCreateBookingDuration,
  setCreateBookingEmployee,
  setCreateBookingCustomer,
  clearCreateBookingState,
  clearEditBookingState,
  setEditBookingId,
  setEditBookingWorkingDate,
  setEditBookingServices,
  setEditBookingDuration,
  setEditBookingEmployee,
  setEditBookingCustomer,
  setBookingFilterInterval,
  setBookingFilterColumn,
  setBookingFilterWith,
  setBookingFilterWithout,
  setBookingScreen,
  setBookingDate,
  setBookingFilterProfessions,
  setBookingFilterFixedCalendar,
  clearBookingFilter,
} from './slices/bookingSlice';
import chatSlice from './slices/chatSlice';

export * from './slices/chatSlice';

export {
  logoutThunk,
  setThemeSlice,
  setToken,
  setUserId,
  setTutorialVisible,
  setCompanyData,
  setCreateBookingWorkingDate,
  setCreateBookingServices,
  setCreateBookingDuration,
  setCreateBookingEmployee,
  setCreateBookingCustomer,
  clearCreateBookingState,
  clearEditBookingState,
  setEditBookingId,
  setEditBookingWorkingDate,
  setEditBookingServices,
  setEditBookingDuration,
  setEditBookingEmployee,
  setEditBookingCustomer,
  setBookingScreen,
  setBookingFilterInterval,
  setBookingFilterColumn,
  setBookingFilterWith,
  setBookingFilterWithout,
  setBookingDate,
  setBookingFilterProfessions,
  setBookingFilterFixedCalendar,
  clearBookingFilter,
};

export const reducersObj = {
  auth: AuthSlice,
  company: CompanySlice,
  theme: themeSlice,
  booking: bookingSlice,
  chats: chatSlice,
};

const rootReducer = combineReducers(reducersObj);

const persistedReducer = persistReducer(
  {
    key: 'root',
    whitelist: Object.keys(reducersObj),
    blacklist: ['booking', 'schedule'],
    storage,
  },
  rootReducer,
);

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleWare =>
    getDefaultMiddleWare({serializableCheck: false}),
});

const persistor = persistStore(store);

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export {store, persistor};
