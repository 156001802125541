import React from 'react';
import styled from 'styled-components';
import {TextInput} from 'shared/ui/TextInput';
import {RadioButton} from 'shared/ui/RadioButton';
import {usePayment} from './Provider';
import {useColors} from 'shared/lib/hooks';
import {TariffBox} from 'entities/tariff';
import {UserCardToken} from 'shared/__generated__/graphql';
import {SkeletonBlock} from './SkeletonBlock';
import {Flex} from 'shared/ui/Flex';

const Inputs = styled.div`
  display: flex;
  gap: 8px;
`;
const BlockNewCard = styled.div<{active: boolean; show: boolean}>`
  padding: 8px 16px;
  background-color: ${({theme}) => theme.fillPrimary};
  border-radius: 18px;
  border: 1px solid ${({theme}) => theme.borderPrimary};
  gap: 8px;
  height: ${({active}) => (active ? '226px' : '64px')};
  display: ${({show}) => (show ? 'flex' : 'none')};
  transition:
    height 300ms ease-out,
    gap 300ms ease-out;
  flex-direction: column;
  overflow: hidden;
`;
const CurrentCardBox = styled.div<{
  show: boolean;
}>`
  display: ${({show}) => (show ? 'block' : 'none')};
`;
const BlockCard = styled.div<{
  show: boolean;
}>`
  display: ${({show}) => (show ? 'none' : 'flex')};
  flex-direction: column;
  gap: 8px;
`;
type CardToken = Omit<UserCardToken, 'updated_at' | 'created_at'>;

const CurrentCard = ({
  lastFourDigits,
  onClick,
  selected,
  show,
}: {
  lastFourDigits: string;
  selected: boolean;
  show: boolean;
  onClick: () => void;
}) => {
  return (
    <CurrentCardBox show={show} onClick={onClick}>
      <TextInput
        style={{pointerEvents: 'none'}}
        label="Ваша карта"
        value={'**** **** **** ' + lastFourDigits}
        leftElement={
          <div style={{marginRight: 8}}>
            <RadioButton pointerEvents="none" selected={selected} />
          </div>
        }
      />
    </CurrentCardBox>
  );
};

export const CardSection = ({
  card,
  loading,
}: {
  loading: boolean;
  card: CardToken | null | undefined;
}) => {
  const {state, setState} = usePayment();
  const {error, numberCard, dateCard, cvvCard, paymentType} = state;
  const colors = useColors();

  const isNumberError = error === 'number' ? 'Заполните поле' : null;
  const isDateError = error === 'date' ? 'Заполните поле' : null;
  const isCvvError = error === 'cvv' ? 'Заполните поле' : null;

  return (
    <TariffBox title="Способы оплаты" gap={8}>
      {loading ? (
        <SkeletonBlock />
      ) : (
        <Flex direction="column" gap={16}>
          <CurrentCard
            show={!!card}
            selected={paymentType === 'current'}
            onClick={() => setState({...state, paymentType: 'current'})}
            lastFourDigits={card?.last_four_digits ?? '0000'}
          />
          <BlockNewCard show={!!card} active={paymentType === 'new'}>
            <RadioButton
              selected={paymentType === 'new'}
              onChange={() => setState({...state, paymentType: 'new'})}
              labelPosition="right"
              title="Новый способ оплаты"
            />
            <TextInput
              label="Номер карты"
              mask="9999 9999 9999 9999"
              backgroundColor={colors.bgPrimary}
              onChange={value => {
                setState({...state, numberCard: value});
                if (error === 'number') setState({...state, error: undefined});
              }}
              value={numberCard}
              error={isNumberError}
            />
            <Inputs>
              <TextInput
                flex={1}
                mask="99 / 99"
                label="ММ / ГГ"
                backgroundColor={colors.bgPrimary}
                onChange={value => {
                  setState({...state, dateCard: value});
                  if (error === 'date') setState({...state, error: undefined});
                }}
                value={dateCard}
                error={isDateError}
              />
              <TextInput
                flex={1}
                mask="999"
                label="CVV"
                backgroundColor={colors.bgPrimary}
                onChange={value => {
                  setState({...state, cvvCard: value});
                  if (error === 'cvv') setState({...state, error: undefined});
                }}
                value={cvvCard}
                error={isCvvError}
                type={'password'}
              />
            </Inputs>
          </BlockNewCard>
          <BlockCard show={!!card}>
            <TextInput
              label="Номер карты"
              mask="9999 9999 9999 9999"
              onChange={value => {
                setState({...state, numberCard: value});
                if (error === 'number') setState({...state, error: undefined});
              }}
              required
              value={numberCard}
              error={isNumberError}
            />
            <Inputs>
              <TextInput
                flex={1}
                mask="99 / 99"
                label="ММ / ГГ"
                onChange={value => {
                  setState({...state, dateCard: value});
                  if (error === 'date') setState({...state, error: undefined});
                }}
                required
                value={dateCard}
                error={isDateError}
              />
              <TextInput
                flex={1}
                mask="999"
                label="CVV"
                onChange={value => {
                  setState({...state, cvvCard: value});
                  if (error === 'cvv') setState({...state, error: undefined});
                }}
                required
                value={cvvCard}
                error={isCvvError}
                type={'password'}
              />
            </Inputs>
          </BlockCard>
        </Flex>
      )}
    </TariffBox>
  );
};
