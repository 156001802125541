import React from 'react';
import {useNavigate} from 'react-router-dom';
import {device} from 'shared/device';
import {ArrowLeft2v} from 'shared/icons/ArrowLeft2v';
import {useColors, useResize} from 'shared/lib/hooks';
import {useAppSelector} from 'shared/store';
import {Content} from 'shared/ui/Content';
import {Divider} from 'shared/ui/Divider';
import {IconButton} from 'shared/ui/IconButton';
import {Layout} from 'shared/ui/Layout';
import {NavLink} from 'shared/ui/NavLink';
import {Seo} from 'shared/ui/Seo';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  grid-column-start: 4;
  grid-column-end: 10;
  @media ${device.mobile} {
    gap: 16px;
    padding: 0;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;

  @media ${device.mobile} {
    justify-content: space-between;
    gap: 16px;
    height: 56px;
    padding: 0 16px;
    background-color: ${({theme}) => theme.bgPrimary};
  }
`;

export const TariffAndPayment = () => {
  const navigate = useNavigate();
  const username = useAppSelector(state => state.company.data?.username);
  const {isMobile} = useResize();
  const colors = useColors();
  return (
    <Layout>
      <Seo title="Тарифы и оплата" />
      <Wrapper>
        <Header>
          <IconButton
            size={40}
            style={{
              borderColor: isMobile ? 'transparent' : colors.borderPrimary,
            }}
            onClick={() => navigate(-1)}>
            <ArrowLeft2v />
          </IconButton>
          <Text typography={isMobile ? 'text16Semibold' : 'title24'}>
            Тарифы и оплата
          </Text>
          <div style={{width: 44}} />
        </Header>
        <Content>
          <NavLink
            label={'Мой тариф'}
            onClick={() => navigate(`/${username}/my-tariff`)}
          />
          <Divider />
          <NavLink
            label={'Тарифные планы'}
            onClick={() => navigate(`/${username}/tariff-plans`)}
          />
          <Divider />
          <NavLink
            label={'История платежей'}
            onClick={() => navigate(`/${username}/payments-history`)}
          />
        </Content>
      </Wrapper>
    </Layout>
  );
};
