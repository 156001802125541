import React, {useState} from 'react';
import {styled} from 'styled-components';
import {CustomerStatus} from 'shared/__generated__/graphql';
import {FilterButton} from 'shared/ui/FilterButton';
import {Status} from 'shared/lib/utils';
import {Modal} from 'shared/ui/Modal';
import {List} from 'shared/ui/List';
import {Text} from 'shared/ui/Text';

const PopupWrapper = styled.div`
  position: relative;
  display: inline-block;
`;
const ButtonPeriod = styled.button<{checked?: boolean}>`
  display: flex;
  padding: 0 16px;
  align-items: center;
  height: 40px;
  width: 343px;
  background-color: ${({checked, theme}) =>
    checked ? theme.bgSecondary : 'transparent'};
  border: none;
`;

export const FilterStatisticsCustomerStatus = ({
  status,
  setStatus,
}: {
  status?: CustomerStatus;
  setStatus?: (gender?: CustomerStatus) => void;
}) => {
  const [visible, setVisible] = useState(false);
  return (
    <PopupWrapper>
      <FilterButton
        onClick={() => setVisible(true)}
        active={!!status}
        title={status ? Status[status] : 'Статус'}
        visible={visible}
      />
      <Modal
        style={{
          left: 'auto',
          right: 'auto',
          top: 64,
          paddingLeft: 0,
          paddingRight: 0,
          gap: 8,
        }}
        visible={visible}
        onClose={() => setVisible(false)}>
        <List
          data={['Все статусы', ...Object.values(Status)]}
          keyExtractor={(_, index) => 'key_' + index}
          renderItem={(item, index) => (
            <ButtonPeriod
              onClick={() => {
                if (index === 0) {
                  setStatus && setStatus(undefined);
                } else {
                  const values = Object.keys(Status) as CustomerStatus[];
                  setStatus && setStatus(values[index - 1]);
                }
                setVisible(false);
              }}
              checked={
                (index === 0 && !status) || (status && Status[status] === item)
              }>
              <Text>{item}</Text>
            </ButtonPeriod>
          )}
        />
      </Modal>
    </PopupWrapper>
  );
};
