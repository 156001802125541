import React from 'react';
import {Popup} from 'shared/ui/Popup';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';
import {Grow} from 'shared/ui/Grow';
import {Button} from 'shared/ui/Button';
import {gql} from 'shared/__generated__';
import {ApolloError, useMutation} from '@apollo/client';
import {showAlert} from 'shared/ui/Alert';
import {Flex} from 'shared/ui/Flex';
import {CloseButton} from 'shared/ui/CloseButton';

const UPDATE_EMPLOYEE = gql(`
  mutation UpdateEmployeeServices($input: UpdateEmployee!) {
    updateEmployee(input: $input) {
      id
    }
  }
`);

const MButton = styled(Button)`
  width: 192px;
  height: 60px;
  border: solid 1px ${({theme}) => theme.borderPrimary};
`;

export const DeletePopup = ({
  visible,
  onClose,
  servicesIds = [],
  serviceId,
  employeeId,
}: {
  visible: boolean;
  onClose: () => void;
  servicesIds?: string[];
  serviceId: string | null;
  employeeId: string;
}) => {
  const [updateEmployee, {loading}] = useMutation(UPDATE_EMPLOYEE, {
    refetchQueries: [
      'EmployeeSerivces',
      'CompanyServiceSearchEmployeeSetServices',
    ],
  });
  return (
    <Popup {...{visible, onClose}}>
      <Grow style={{gap: 16}}>
        <Flex
          style={{width: 392}}
          justifyContent="space-between"
          alignItems="center">
          <Text typography="title24">Удалить услугу</Text>
          <CloseButton
            onClose={() => {
              onClose && onClose();
            }}
          />
        </Flex>
        <Flex style={{width: 280}} flex={1}>
          <Text>Вы уверены, что хотите удалить услугу сотрудника?</Text>
        </Flex>
        <Flex justifyContent="center" gap={8} flex={1}>
          <MButton variant="subtitle" onClick={onClose}>
            <Text
              style={{cursor: 'pointer'}}
              typography="text16Semibold"
              color="mainPrimary">
              Отмена
            </Text>
          </MButton>
          <MButton
            loading={loading}
            disabled={loading}
            variant="subtitle"
            onClick={() => {
              const service_ids = servicesIds.filter(
                item => item !== serviceId,
              );
              const variables = {
                input: {service_ids, employee_id: employeeId},
              };
              updateEmployee({variables})
                .then(() => {
                  onClose();
                })
                .catch((e: ApolloError) => showAlert('error', e.message));
            }}>
            <Text
              style={{cursor: 'pointer'}}
              typography="text16Semibold"
              color="dangerPrimary">
              Удалить
            </Text>
          </MButton>
        </Flex>
      </Grow>
    </Popup>
  );
};
