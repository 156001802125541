import React from 'react';
import styled from 'styled-components';
import {Flex} from 'shared/ui/Flex';
import {Text} from 'shared/ui/Text';
import {Button} from 'shared/ui/Button';
import {useColors, useEmployeePermission} from 'shared/lib/hooks';
import {Image} from 'shared/ui/Image';
import {CircleArrowUp} from 'shared/icons/CircleArrowUp';
import {useLocation, useNavigate} from 'react-router-dom';
import {gql} from 'shared/__generated__';
import {useQuery} from '@apollo/client';
import {Loading} from 'shared/ui/Loading';
import {Seo} from 'shared/ui/Seo';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  width: 472px;
  align-self: center;
  padding-bottom: 24px;
  flex-direction: column;
  align-items: center;
`;
const PERMISSIONS = gql(`
query UnavailableCompanyPermissions($companyId: String!) {
  ...Permissions
}`);

export function UnavailableCompany() {
  const navigate = useNavigate();

  const {role, name, companyId, plan} = useLocation().state;
  const {data, loading} = useQuery(PERMISSIONS, {
    variables: {
      companyId,
    },
  });
  const {subscription_and_pay_permission} = useEmployeePermission(data);
  const colors = useColors();
  const message =
    role === 'owner'
      ? 'Срок действия вашей подписки закончился и теперь некоторые функции недоступны для вас. Для полноценной работы предлагаем вам возобновить подписку на Booky'
      : 'Срок действия вашей подписки закончился. Для продолжения работы обратитесь к администратору организации';

  if (loading) {
    return (
      <Wrapper>
        <Loading />
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      <Seo title={name} />
      <Image
        height={343}
        width={366}
        src={require('../../../../src/shared/illustration/companyBlock@3x.png')}
      />
      <Flex direction="column" gap={24}>
        <Text align="center" typography="title18">
          {name}
        </Text>
        <Text align="center">{message}</Text>
        <Flex direction="column" gap={8}>
          {plan && subscription_and_pay_permission ? (
            <Button
              size="medium"
              rightIcon={<CircleArrowUp color={colors.white} />}
              onClick={() => {
                navigate('/payment-tariff', {
                  state: {plan, companyId},
                });
              }}>
              Продлить подписку
            </Button>
          ) : null}
          <Button
            style={{backgroundColor: 'transparent'}}
            size="medium"
            variant="outline"
            onClick={() => {
              navigate('/my-companies');
            }}>
            Вернуться к моим организациям
          </Button>
          {subscription_and_pay_permission ? (
            <Button
              style={{backgroundColor: 'transparent'}}
              size="large"
              onClick={() => {
                navigate('/unavailable-tariff-plans', {state: {companyId}});
              }}
              variant="subtitle">
              Перейти к тарифам
            </Button>
          ) : null}
        </Flex>
      </Flex>
    </Wrapper>
  );
}
