import axios from 'axios';

export type YandexGPTRoleType = 'system' | 'assistant' | 'user';

type YandexGPTResponseType = {
  result: {
    alternatives: {
      message: {
        role: YandexGPTRoleType;
        text: string;
      };
      status: string;
    }[];
    usage: {
      inputTextTokens: string;
      completionTokens: string;
      totalTokens: string;
    };
    modelVersion: string;
  };
};

export type YandexGPTParamType = {
  messages: {
    role: YandexGPTRoleType;
    text: string;
  }[];
  maxTokens?: number;
};

const YANDEX_API_URL =
  'https://llm.api.cloud.yandex.net/foundationModels/v1/completion';

export const yandexGptCompletion = async (props: YandexGPTParamType) => {
  console.log('test env', process.env.REACT_APP_YANDEX_GPT_FOLDER_ID);
  const response = await axios({
    url: YANDEX_API_URL,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Api-Key ${process.env.REACT_APP_YANDEX_GPT_API_KEY}`,
    },
    data: JSON.stringify({
      modelUri: `gpt://${process.env.REACT_APP_YANDEX_GPT_FOLDER_ID}/yandexgpt-lite/rc`,
      completionOptions: {
        stream: false,
        temperature: 0,
        maxTokens: props.maxTokens,
      },
      messages: props.messages,
    }),
  });

  return {
    data: response.data as YandexGPTResponseType,
    status: response.status,
  };
};
