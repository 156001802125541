import React, {useState} from 'react';
import {ArrowRight1v} from 'shared/icons/ArrowRight1v';
import styled from 'styled-components';
import dayjs from 'dayjs';
import {device} from 'shared/device';
import {Text} from 'shared/ui/Text';
import {capitalize, weekdays} from 'shared/lib/utils';
import {ArrowLeft1v} from 'shared/icons/ArrowLeft1v';

type Props = {
  selectedDays: string[];
};

const Dot = styled.div<{active: boolean}>`
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: ${({theme, active}) =>
    active ? theme.white : theme.mainPrimary};
`;
const Wrapper = styled.div`
  display: none;
  @media ${device.desktop} {
    gap: 16px;
    grid-column-start: 10;
    grid-column-end: 13;
    display: flex;
    flex-direction: column;
    height: max-content;
    margin-top: 136px;
  }
`;
const CalendarContainer = styled.div`
  background-color: ${({theme}) => theme.bgPrimary};
  display: block;
  border-radius: 24px;
  padding: 16px 0;
  border: 1px solid ${({theme}) => theme.borderPrimary};
`;
const ViewWeeks = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  justify-items: center;
  align-items: center;
  height: 43px;
`;
const DayContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  justify-items: center;
  align-items: center;
  gap: 12px 0px;
`;
const Day = styled.div<{gridColumnStart?: number; active: boolean}>`
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  flex-direction: column;
  background-color: ${({theme, active}) =>
    active ? theme.mainPrimary : 'transparent'};
  pointer-events: all;
  grid-row-start: auto;
  grid-column-start: ${({gridColumnStart}) => gridColumnStart};
`;
const HeaderWrapper = styled.div`
  height: 46px;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
  padding: 0 16px;
`;
const ButtonArrow = styled.div`
  pointer-events: all;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const DisplayCalendar = (props: Props) => {
  const {selectedDays} = props;
  const [date, setDate] = useState(dayjs().format('YYYY-MM-DD'));
  const daysInMonth = dayjs(date).daysInMonth();
  const days = Array.from(Array(daysInMonth), (_, index) => {
    const day = dayjs(date)
      .set('date', index + 1)
      .format('YYYY-MM-DD');
    return {
      date: day,
      active: selectedDays.some(item => item === day),
    };
  });

  return (
    <Wrapper>
      <CalendarContainer>
        <Header date={date} onChange={setDate} />
        <Body data={days} />
      </CalendarContainer>
      <Text color="textTertiary">
        Здесь будут показаны дни, которые вы выбрали и настроили
      </Text>
    </Wrapper>
  );
};

const Header = ({
  date,
  onChange,
}: {
  date: string;
  onChange: (value: string) => void;
}) => {
  const onPressChangeDate = (type: 'add' | 'subtract') => {
    const currentDate = dayjs(date);
    const newDate = currentDate[type](1, 'month');
    onChange(newDate.format('YYYY-MM-DD'));
  };
  return (
    <HeaderWrapper>
      <ButtonArrow onClick={() => onPressChangeDate('subtract')}>
        <ArrowLeft1v />
      </ButtonArrow>
      <Text typography="subHead14Medium">
        {capitalize(dayjs(date).format('MMMM YYYY'))}
      </Text>
      <ButtonArrow onClick={() => onPressChangeDate('add')}>
        <ArrowRight1v />
      </ButtonArrow>
    </HeaderWrapper>
  );
};

const Body = ({data}: {data: {date: string; active: boolean}[]}) => {
  const isCurrentMonth = dayjs(data[0].date).month() === dayjs().month();
  const currentDayOfWeek = dayjs().format('dd');
  return (
    <div>
      <ViewWeeks>
        {weekdays.map(item => (
          <Text
            key={item}
            typography="footNote12Medium"
            color={
              item === currentDayOfWeek && isCurrentMonth
                ? 'mainPrimary'
                : 'textTertiary'
            }>
            {item}
          </Text>
        ))}
      </ViewWeeks>
      <DayContainer>
        {data.map((item, index) => {
          const gridPosition =
            dayjs(item.date).get('day') === 0 ? 7 : dayjs(item.date).get('day');
          const isActive = item.active;
          return (
            <Day
              key={'day_' + index}
              gridColumnStart={gridPosition}
              active={isActive}>
              <Text
                typography="footNote12Medium"
                color={isActive ? 'white' : 'textPrimary'}>
                {dayjs(item.date).format('D')}
              </Text>
              {dayjs().isSame(dayjs(item.date), 'date') ? (
                <Dot active={isActive} />
              ) : null}
            </Day>
          );
        })}
      </DayContainer>
    </div>
  );
};
