import React from 'react';
import CircleAccept from 'shared/icons/CircleAccept';
import {useColors} from 'shared/lib/hooks';
import {Button} from 'shared/ui/Button';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 24px;
  justify-content: space-between;
  background-color: ${props => props.theme.bgPrimary};
  border-radius: 24px;
  border: 1px solid ${props => props.theme.borderPrimary};
`;
const IconWrapper = styled.div<{complited?: boolean}>`
  display: flex;
  height: 84px;
  width: 84px;
  border-radius: 24px;
  background-color: ${props =>
    props.complited ? props.theme.bgSuccess : props.theme.bgWaiting};
  justify-content: center;
  align-items: center;
`;
const BodyStyled = styled.div`
  margin-bottom: 40px;
`;
const TextBody = styled.div`
  display: flex;
  margin-top: 16px;
  flex-direction: column;
  gap: 8px;
`;

export const TutoriaCard = ({
  icon,
  title,
  description,
  btnText,
  onPress,
  complited,
}: {
  icon?: React.ReactNode;
  title?: string;
  description?: string;
  btnText?: string;
  onPress?: () => void;
  complited?: boolean;
}) => {
  const colors = useColors();
  return (
    <CardWrapper>
      <BodyStyled>
        <IconWrapper complited={complited}>
          {complited ? (
            <CircleAccept size={40} color={colors.successPrimary} />
          ) : (
            icon
          )}
        </IconWrapper>
        <TextBody>
          <Text typography="title20" color="textPrimary">
            {title}
          </Text>
          <Text typography="text16Regular" color="textSecondary">
            {description}
          </Text>
        </TextBody>
      </BodyStyled>
      <Button
        onClick={onPress}
        style={{
          backgroundColor: complited ? colors.mainLight : colors.mainPrimary,
          color: complited ? colors.mainPrimary : colors.white,
        }}
        size="large">
        {btnText}
      </Button>
    </CardWrapper>
  );
};
