import React from 'react';
import Accept from 'shared/icons/Accept';
import CirclePelp from 'shared/icons/CirclePelp';
import {useColors} from 'shared/lib/hooks';
import {Text} from 'shared/ui/Text';
import {Tooltip} from 'shared/ui/Tooltip';
import styled from 'styled-components';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  height: 48px;
  align-items: center;
`;
type TariffTooltip = {title: string; tooltip: string | null};
export const TooltipRow = ({title, tooltip}: TariffTooltip) => {
  const colors = useColors();
  return (
    <Row>
      <Accept color={!tooltip ? '#FFB743' : undefined} />
      <Text style={{width: 'max-content'}} typography="text16Regular">
        {title}
      </Text>
      {tooltip && (
        <Tooltip style={{width: 232}} text={tooltip}>
          <CirclePelp color={colors.textTertiary} />
        </Tooltip>
      )}
    </Row>
  );
};
