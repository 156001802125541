import React from 'react';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';
import {Content} from 'shared/ui/Content';
import {Head} from 'shared/ui/Head';
import {NetworkStatus, useMutation, useQuery} from '@apollo/client';
import {gql} from 'shared/__generated__';
import {useAppSelector} from 'shared/store';
import {EmployeesDragDrop} from './ui/EmployeesDragDrop';
import {Loader} from 'shared/icons/Loader';
import {showAlert} from 'shared/ui/Alert';
import {Flex} from 'shared/ui/Flex';
import {Layout} from 'shared/ui/Layout';
import {NavigateBack} from 'shared/ui/NavigateBack';
import {Seo} from 'shared/ui/Seo';
import {GET_COMPANY_EMPLOYEES} from 'entities/employees';

const EMPLOYEES = gql(`
query CompanyEmployeesOrderEmployees($first: Int!, $companyId: String, $enabled: Boolean) {
  companyEmployees(first: $first, company_id: $companyId, enabled: $enabled) {
    data {
      avatar {
        id
        url
        url_256
      }
      id
      name
      surname
      invite_status
      position
      role
      enabled
    }
  }
}
`);

const UPDATE_EMPLOYEES = gql(`
  mutation UpdateEmployeesPositions($input: UpdateEmployeesPositions!) {
    updateEmployeesPositions(input: $input) {
      avatar {
        id
        url
        url_256
      }
      id
      name
      surname
      invite_status
      position
      role
      enabled
    }
  }
`);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-column-start: 4;
  grid-column-end: 10;
  padding: 64px 0 24px;
`;
export const OrderEmployees = () => {
  const companyId = useAppSelector(state => state.company.data!.id);
  const {data, networkStatus} = useQuery(EMPLOYEES, {
    variables: {companyId, first: 100},
  });
  const [update] = useMutation(UPDATE_EMPLOYEES, {
    refetchQueries: [
      {
        query: GET_COMPANY_EMPLOYEES,
        variables: {
          companyId,
          first: 10,
          page: 1,
          query: '',
        },
      },
    ],
  });
  const employees = data?.companyEmployees?.data ?? [];
  const sortEmployees = [...employees].sort((a, b) => {
    if (a.position && b.position) {
      return a.position - b.position;
    }
    return 0;
  });

  return (
    <Layout columns={12}>
      <Seo title="Порядок сотрудников" />
      <Wrapper>
        <Head>
          <NavigateBack />
          <Text typography="title24">Порядок сотрудников</Text>
        </Head>
        {networkStatus === NetworkStatus.loading ? (
          <Flex
            mb={136}
            flex={1}
            direction="column"
            justifyContent="center"
            alignItems="center">
            <Loader />
          </Flex>
        ) : (
          <Content style={{marginTop: 8}}>
            <EmployeesDragDrop
              employees={sortEmployees}
              onDragEnd={async employees => {
                try {
                  const input = {
                    employees: employees.map((item, index) => ({
                      employee_id: item.id,
                      position: index + 1,
                    })),
                  };
                  await update({variables: {input}});
                } catch (e) {
                  if (e instanceof Error) {
                    showAlert('error', e.message);
                  }
                }
              }}
            />
          </Content>
        )}
      </Wrapper>
    </Layout>
  );
};
