import React from 'react';
import {Flex} from 'shared/ui/Flex';
import {MenuItem} from 'shared/ui/MenuItem';
import {NavLink} from 'shared/ui/NavLink';
import {useNavigate} from 'react-router-dom';
import User from 'shared/icons/User';
import {Support} from 'shared/icons/Support';
import {tabs} from './router';
import {Education} from 'shared/icons/Education';
import {useAppSelector} from 'shared/store';

export function MoreRoot() {
  const navigate = useNavigate();
  const username = useAppSelector(state => state.company.data?.username);

  return (
    <Flex direction="column" gap={16} mt={16} mb={16}>
      <MenuItem open />
      <Flex
        gap={8}
        direction="column"
        style={{paddingLeft: 16, paddingRight: 16}}>
        {tabs
          .filter(
            tab =>
              tab.place !== 'all' &&
              tab.place !== 'sidebar_bottom' &&
              tab.place !== 'tabbar',
          )
          .map(tab => (
            <NavLink
              key={tab.title}
              label={tab.title}
              styleLabel={{pointerEvents: 'none'}}
              onClick={() => navigate('/' + tab.path ?? '')}
              icon={
                <svg width={24} height={24} color="#6765F8">
                  {tab.icon}
                </svg>
              }
            />
          ))}
        <NavLink
          icon={<Education />}
          styleLabel={{pointerEvents: 'none'}}
          label="Обучение"
          onClick={() => navigate(`/${username}/tutorial`)}
        />
        <NavLink
          icon={<Support />}
          styleLabel={{pointerEvents: 'none'}}
          label="Центр помощи"
          onClick={() => navigate('/support')}
        />
        <NavLink
          icon={<User />}
          styleLabel={{pointerEvents: 'none'}}
          label="Профиль и настройки"
          onClick={() => navigate('/profile-and-settings')}
        />
      </Flex>
    </Flex>
  );
}
