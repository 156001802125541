import React from 'react';
import {getPhoneMask} from 'shared/lib/utils';
import {Flex} from 'shared/ui/Flex';
import {Phone} from 'shared/icons/Phone';
import {Text} from 'shared/ui/Text';
import {Whatsapp} from 'shared/icons/Whatsapp';
import {Telegram} from 'shared/icons/Telegram';
import {styled} from 'styled-components';
import {Chat} from 'shared/icons/Chat';

const MessengerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  pointer-events: all;
  cursor: pointer;
`;
const CirleBackground = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background-color: ${({theme}) => theme.bgSecondary};
`;

export const Communicate = ({phone}: {phone?: string | null}) => {
  return (
    <Flex direction="row" justifyContent="space-between">
      <MessengerWrapper
        onClick={() => {
          phone &&
            window.open(`tel:${getPhoneMask(phone)}`, '_blank', 'noreferrer');
        }}>
        <CirleBackground>
          <Phone />
        </CirleBackground>
        <Text typography="text16Regular" style={{cursor: 'pointer'}}>
          Вызов
        </Text>
      </MessengerWrapper>
      <MessengerWrapper
        onClick={() => {
          phone &&
            window.open(`sms:${getPhoneMask(phone)}`, '_blank', 'noreferrer');
        }}>
        <CirleBackground>
          <Chat />
        </CirleBackground>
        <Text typography="text16Regular" style={{cursor: 'pointer'}}>
          Смс
        </Text>
      </MessengerWrapper>
      <MessengerWrapper
        onClick={() => {
          phone && window.open(`https://wa.me/${phone}`, '_blank');
        }}>
        <CirleBackground>
          <Whatsapp />
        </CirleBackground>
        <Text typography="text16Regular" style={{cursor: 'pointer'}}>
          Whatsapp
        </Text>
      </MessengerWrapper>
      <MessengerWrapper
        onClick={() => {
          phone && window.open(`https://t.me/${phone}`, '_blank');
        }}>
        <CirleBackground>
          <Telegram />
        </CirleBackground>
        <Text typography="text16Regular" style={{cursor: 'pointer'}}>
          Telegram
        </Text>
      </MessengerWrapper>
    </Flex>
  );
};
