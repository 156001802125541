import React from 'react';
import styled from 'styled-components';
import {Text} from './Text';
import {IconButton} from './IconButton';
import {useColors} from 'shared/lib/hooks';
import {CircleClose} from 'shared/icons/CircleClose';

const Wrapper = styled.div<{
  variant: 'light' | 'filled' | 'outline';
  disabled?: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  background-color: ${({theme, variant}) =>
    variant === 'filled'
      ? theme.bgPrimary
      : variant === 'light'
      ? theme.mainLight
      : 'transparent'};
  width: max-content;
  padding: 8px;
  height: 32px;
  border-radius: 8px;
  border: 1px solid
    ${({theme, variant}) =>
      variant === 'light' ? theme.mainLight : theme.borderPrimary};
  opacity: ${({disabled}) => (disabled ? 0.5 : 1)};
  pointer-events: all;
  &:hover {
    background-color: ${({theme, variant}) =>
      variant === 'light'
        ? theme.mainLight50
        : variant === 'filled'
        ? theme.bgTertiary
        : 'transparent'};
  }
`;

type Props = {
  text: string;
  label?: string;
  variant?: 'light' | 'filled' | 'outline';
  onClick?: () => void;
  style?: React.CSSProperties;
  disabled?: boolean;
};

export const Chip = (props: Props) => {
  const {variant = 'filled', text, label, style, onClick, disabled} = props;
  const colors = useColors();

  return (
    <Wrapper variant={variant} style={style} disabled={disabled}>
      <Text typography="footNote12Medium" style={{display: 'flex'}}>
        {label && (
          <Text
            typography="footNote12Medium"
            color="textTertiary"
            style={{marginRight: 4}}>
            {label}
          </Text>
        )}
        {text}
      </Text>
      <IconButton
        size={16}
        onClick={e => {
          onClick && onClick();
          e.stopPropagation();
        }}
        style={{
          backgroundColor: 'transparent',
          padding: 0,
          borderColor: 'transparent',
        }}>
        <CircleClose
          borderWidth={2}
          color={
            variant === 'filled'
              ? colors.textSecondary
              : variant === 'outline'
              ? colors.textTertiary
              : colors.mainPrimary
          }
        />
      </IconButton>
    </Wrapper>
  );
};
