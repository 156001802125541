import React from 'react';
import {Copy} from 'shared/icons/Copy';
import {LogoCircle} from 'shared/icons/LogoCircle';
import {Restart} from 'shared/icons/Restart';
import {Divider} from 'shared/ui/Divider';
import {Flex} from 'shared/ui/Flex';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: scroll;
  grid-column-start: 2;
  grid-column-end: 9;
  gap: 12px;
  &::-webkit-scrollbar {
    display: none;
  }
`;

type Props = {
  messages: {
    id: number;
    message: string;
    created_at: string;
    chat_id: number;
    type: 'user' | 'system';
  }[];
  loading: boolean;
};

export const Messages = (props: Props) => {
  const {messages, loading} = props;
  return (
    <Wrapper>
      {messages.map(message => (
        <MessageItem
          key={message.chat_id + '_' + message.id}
          message={message.message}
          type={message.type}
        />
      ))}
      <LoadingSend loading={loading} />
    </Wrapper>
  );
};

const ItemWrapper = styled.div`
  background-color: ${({theme}) => theme.bgPrimary};
  border-radius: 24px;
  border: 1px solid ${({theme}) => theme.borderPrimary};
  padding: 16px 24px;
  gap: 24px;
  display: flex;
  flex-direction: column;
`;

const MessageItem = ({
  message,
  type,
}: {
  message: string;
  type: 'user' | 'system';
}) => {
  return (
    <ItemWrapper>
      <Flex direction="row" gap={16}>
        {type === 'system' ? <LogoCircle /> : null}
        <Text>{message}</Text>
      </Flex>
      {type === 'system' ? (
        <>
          <Divider />
          <Flex gap={16}>
            <ButtonMessage
              onClick={() => {
                navigator.clipboard.writeText(message);
              }}>
              <Copy /> Скопировать
            </ButtonMessage>
            <ButtonMessage onClick={() => {}}>
              <Restart /> Другой вариант
            </ButtonMessage>
          </Flex>
        </>
      ) : null}
    </ItemWrapper>
  );
};

const ButtonMessage = styled.button`
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border: none;
  color: ${({theme}) => theme.textPrimary};
  pointer-events: all;
  cursor: pointer;
`;

const ContainerLoading = styled.div<{loading: boolean}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  background-color: ${({theme}) => theme.bgPrimary};
  padding: ${({loading}) => (loading ? '16px 24px' : '0px 24px')};
  border-radius: 24px;
  border: ${({loading, theme}) =>
    loading ? `1px solid ${theme.borderPrimary}` : 'none'};
  height: ${({loading}) => (loading ? '104px' : '0px')};
  transition:
    height 200ms ease-out,
    padding 200ms ease-out 100ms,
    border 200ms ease-out;
  overflow: hidden;
`;

const LoadingSend = ({loading}: {loading: boolean}) => {
  return (
    <ContainerLoading loading={loading}>
      <LogoCircle />
      <Text typography="text18" color="textTertiary">
        Генерирую для вас ответ всеми узлами своего нейромозга...
      </Text>
    </ContainerLoading>
  );
};
