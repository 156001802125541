import {ApolloError} from '@apollo/client';
import {requestJSON} from 'entities/tariff';
import React, {useState} from 'react';
import {gql} from 'shared/__generated__';
import {client} from 'shared/config/apollo';
import {useColors} from 'shared/lib/hooks';
import {useAppSelector} from 'shared/store';
import {showAlert} from 'shared/ui/Alert';
import {Button} from 'shared/ui/Button';
import {CloseButton} from 'shared/ui/CloseButton';
import {Flex} from 'shared/ui/Flex';
import {Popup} from 'shared/ui/Popup';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';

export const CHANGE_TARIFF_FREE = gql(`
  mutation ChangePlanToFree($companyId: String!) {
    changePlanToFree(company_id: $companyId)
  }
`);
const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 472px;
  gap: 16px;
`;

export const DeleteCard = ({
  id,
  visible,
  onClose,
}: {
  id: string;
  visible: boolean;
  onClose: () => void;
}) => {
  const [isLoading, setLoading] = useState(false);
  const colors = useColors();
  const token = useAppSelector(state => state.auth.token);

  return (
    <Popup visible={visible} onClose={onClose}>
      <ModalWrapper>
        <Flex justifyContent="space-between" alignItems="center">
          <Text typography="title20">Удаление сохраненной карты</Text>
          <CloseButton onClose={onClose} />
        </Flex>
        <Text>Вы уверены, что хотите удалить сохраненную карту?</Text>
        <Flex gap={8}>
          <Button
            size="large"
            style={{flex: 1}}
            variant="outline"
            onClick={onClose}>
            Отмена
          </Button>
          <Button
            loading={isLoading}
            size="large"
            style={{
              flex: 1,
              borderColor: colors.dangerPrimary,
              color: colors.dangerPrimary,
            }}
            variant="outline"
            onClick={async () => {
              setLoading(true);
              await requestJSON({
                action: 'deleteCard',
                token,
                body: JSON.stringify({user_card_token_id: id}),
              })
                .then(() => {
                  client.refetchQueries({
                    include: ['MyTariff', 'Me', 'SidebarQuery'],
                  });
                })
                .catch((err: ApolloError) => {
                  showAlert('error', err.message);
                })
                .finally(() => setLoading(false));
            }}>
            Удалить
          </Button>
        </Flex>
      </ModalWrapper>
    </Popup>
  );
};
