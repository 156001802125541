import React from 'react';
import {Flex} from './Flex';

export function List<ItemT>({
  data,
  renderItem,
  footer,
  mt,
  divider,
  placeholder,
  keyExtractor,
  mb,
  gap,
  overflow = 'visible',
}: {
  footer?: React.ReactElement;
  data?: ItemT[];
  renderItem: (item: ItemT, index: number) => React.ReactElement | null;
  mt?: number;
  mb?: number;
  gap?: number;
  divider?: React.ReactElement;
  placeholder?: React.ReactElement;
  keyExtractor?: (item: ItemT, index: number) => string;
  overflow?: 'visible' | 'auto';
}) {
  if (!data || data.length === 0) {
    return <>{placeholder}</>;
  }
  return (
    <Flex
      flex={1}
      style={{
        marginBottom: mb,
        marginTop: mt,
        gap: gap,
        overflow,
      }}
      direction={'column'}>
      {data.map((item, idx) => (
        <div key={keyExtractor && keyExtractor(item, idx)}>
          {idx !== 0 && divider}
          {renderItem(item, idx)}
        </div>
      ))}
      {footer ? footer : null}
    </Flex>
  );
}
