import React, {useState} from 'react';
import styled from 'styled-components';
import {ArrowRight2v} from 'shared/icons/ArrowRight2v';
import {Plus} from 'shared/icons/Plus';
import {Setting} from 'shared/icons/Setting';
import {Flex} from 'shared/ui/Flex';
import {Text} from 'shared/ui/Text';
import {useColors, useResize} from 'shared/lib/hooks';
import dayjs from 'dayjs';
import {device} from 'shared/device';
import {NavLink} from 'shared/ui/NavLink';
import {BottomSheet} from 'shared/ui/BottomSheet';
import {CheckBox} from 'shared/ui/CheckBox';
import {
  clearCreateBookingState,
  setBookingDate,
  setBookingFilterColumn,
  setBookingFilterFixedCalendar,
  setBookingFilterInterval,
  setBookingFilterWith,
  setBookingFilterWithout,
  setBookingScreen,
  setCreateBookingWorkingDate,
  useAppDispatch,
  useAppSelector,
} from 'shared/store';
import {RadioButton} from 'shared/ui/RadioButton';
import {DatePicker} from 'shared/ui/DatePicker';
import {useNavigate} from 'react-router-dom';
import {IconButton} from 'shared/ui/IconButton';
import {ArrowLeft2v} from 'shared/icons/ArrowLeft2v';
import {Divider} from 'shared/ui/Divider';
import {FilterBtn} from 'shared/ui/FilterButton';
import {Calendar} from 'shared/icons/Calendar';

const TextTitle = styled(Text)`
  overflow: hidden;
`;

const HeaderButton = styled.button<{
  variant: 'setup' | 'create';
  content?: string;
  showContent?: boolean;
}>`
  display: flex;
  flex-direction: row;
  border-radius: 16px;
  justify-content: center;
  align-items: center;
  width: ${({variant}) => (variant === 'setup' ? '48px' : 'initial')};
  height: 48px;
  border: none;
  pointer-events: all;
  cursor: pointer;
  padding: ${({variant}) => (variant === 'setup' ? '0' : '0 32px')};
  background-color: ${props => props.theme.bgPrimary};
  &::after {
    content: '${({content}) => content}';
    display: ${({showContent = true}) => (showContent ? 'flex' : 'none')};
    font-size: 14px;
    font-family: 'Inter';
    font-weight: 500;
    width: max-content;
    color: ${({theme}) => theme.white};
  }
`;

const ButtonCalendar = styled(IconButton)<{active: boolean}>`
  border: 1px solid
    ${({theme, active}) => (active ? theme.mainPrimary : theme.borderPrimary)};
`;

const HeaderDate = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${({theme}) => theme.borderPrimary};
  height: 48px;
  background-color: ${({theme}) => theme.bgPrimary};
  border-radius: 14px;
  padding: 0 32px;
  pointer-events: all;
  cursor: pointer;
`;

const Container = styled.div`
  width: max-content;
`;

const ViewOption = styled.div`
  display: flex;
  flex-direction: column;
  height: 400px;
  background-color: ${props => props.theme.bgPrimary};
  overflow-y: scroll;
  pointer-events: auto;
  gap: 16px;
`;

const Group = styled.div``;

const Label = styled(Text)`
  margin: 0 24px 8px 24px;
`;
const NavlinkOrder = styled(NavLink)`
  pointer-events: all;
  padding: 8px 12px 8px 24px;
  &:hover {
    background-color: ${({theme}) => theme.bgSecondary};
  }
`;

// видна только для мобильного вида
const MobileBlock = styled.div`
  display: block;
  padding: 0 16px;
  @media ${device.desktop} {
    display: none;
    padding: 0 24px;
  }
`;

const Filter = ({
  employeeCount,
  visible,
  onClose,
  onOpen,
  createPermission,
}: {
  employeeCount: number;
  visible: boolean;
  onClose: () => void;
  onOpen: () => void;
  createPermission: boolean;
}) => {
  const interval = useAppSelector(state => state.booking.filter.interval_time);
  const column = useAppSelector(state => state.booking.filter.countColumn);
  const dispatch = useAppDispatch();
  const employeeWithBooking = useAppSelector(
    state => state.booking.filter.employeeWithBooking,
  );
  const employeeWithoutBooking = useAppSelector(
    state => state.booking.filter.employeeWithoutBooking,
  );
  const navigate = useNavigate();
  const username = useAppSelector(state => state.company.data?.username);
  const colors = useColors();
  return (
    <Container>
      <HeaderButton
        style={{
          border: '1px solid ' + colors.borderPrimary,
          borderRadius: 16,
        }}
        variant="setup"
        onClick={() => onOpen()}>
        <Setting size={24} />
      </HeaderButton>
      <BottomSheet
        title="Настройка отображения"
        visible={visible}
        onClose={() => onClose()}
        wrapperStyle={{
          zIndex: 1001,
          top: 'auto',
          left: createPermission ? 'auto' : 'calc(100% - 532px)',
        }}>
        <ViewOption>
          <Group>
            <Label typography="text16Regular" color="textTertiary">
              Интервал времени
            </Label>
            <RadioButton
              hoverEnabled
              style={{padding: '0 24px'}}
              title="10 минут"
              labelPosition="left"
              selected={interval === 10}
              onChange={() => dispatch(setBookingFilterInterval(10))}
            />
            <RadioButton
              hoverEnabled
              style={{padding: '0 24px'}}
              title="15 минут"
              selected={interval === 15}
              onChange={() => dispatch(setBookingFilterInterval(15))}
            />
            <RadioButton
              hoverEnabled
              style={{padding: '0 24px'}}
              title="20 минут"
              selected={interval === 20}
              onChange={() => dispatch(setBookingFilterInterval(20))}
            />
            <RadioButton
              hoverEnabled
              style={{padding: '0 24px'}}
              title="30 минут"
              selected={interval === 30}
              onChange={() => dispatch(setBookingFilterInterval(30))}
            />
          </Group>
          <Group>
            <Divider style={{margin: '0 16px'}} />
          </Group>
          <Group>
            <Label typography="text16Regular" color="textTertiary">
              Количество столбцов
            </Label>
            <RadioButton
              hoverEnabled
              style={{padding: '0 24px'}}
              title="Автоматически"
              selected={column === 'Автоматически'}
              onChange={() => dispatch(setBookingFilterColumn('Автоматически'))}
            />
            <RadioButton
              hoverEnabled
              style={{padding: '0 24px'}}
              title="1 столбец"
              selected={column === '1'}
              onChange={() => dispatch(setBookingFilterColumn('1'))}
            />
            {employeeCount > 1 ? (
              <RadioButton
                hoverEnabled
                style={{padding: '0 24px'}}
                title="2 столбца"
                selected={column === '2'}
                onChange={() => dispatch(setBookingFilterColumn('2'))}
              />
            ) : null}
            {employeeCount > 2 ? (
              <RadioButton
                hoverEnabled
                style={{padding: '0 24px'}}
                title="3 столбца"
                selected={column === '3'}
                onChange={() => dispatch(setBookingFilterColumn('3'))}
              />
            ) : null}
            {employeeCount > 3 ? (
              <RadioButton
                hoverEnabled
                style={{padding: '0 24px'}}
                title="4 столбца"
                selected={column === '4'}
                onChange={() => dispatch(setBookingFilterColumn('4'))}
              />
            ) : null}
          </Group>
          <Group>
            <Divider style={{margin: '0 16px'}} />
          </Group>
          <NavlinkOrder
            label="Порядок сотрудников"
            onClick={() => {
              navigate(`/${username}/order-employees`);
            }}
          />
          <MobileBlock>
            <Text
              typography="text16Regular"
              color="textTertiary"
              style={{marginBottom: 8}}>
              По записям:
            </Text>
            <CheckBox
              title="Сотрудники с записями"
              checked={employeeWithBooking}
              onChange={state => dispatch(setBookingFilterWith(state))}
            />
            <CheckBox
              title="Сотрудники без записей"
              checked={employeeWithoutBooking}
              onChange={state => dispatch(setBookingFilterWithout(state))}
            />
          </MobileBlock>
        </ViewOption>
      </BottomSheet>
    </Container>
  );
};

const getTypedDate = (date: string, full?: boolean) => {
  const difference = dayjs(dayjs().format('YYYY-MM-DD 00:00:00')).diff(
    date,
    'hour',
  );
  if (difference > 24 && difference <= 48) {
    return full
      ? dayjs(date).format('DD MMMM, Позавчера')
      : dayjs(date).format('DD MMMM');
  }
  if (difference > 0 && difference <= 24) {
    return full
      ? dayjs(date).format('DD MMMM, Вчера')
      : dayjs(date).format('DD MMMM');
  }
  if (difference === 0) {
    return full
      ? dayjs(date).format('DD MMMM, Сегодня')
      : dayjs(date).format('DD MMMM');
  }
  if (difference < 0 && difference >= -24) {
    return full
      ? dayjs(date).format('DD MMMM, Завтра')
      : dayjs(date).format('DD MMMM');
  }
  if (difference < -24 && difference >= -48) {
    return full
      ? dayjs(date).format('DD MMMM, Послезавтра')
      : dayjs(date).format('DD MMMM');
  }
  return full
    ? dayjs(date).format('DD MMMM, dddd')
    : dayjs(date).format('DD MMMM');
};

export const BookingsHeader = ({
  employeeCount,
  createPermission,
  openFilter,
  workingDates,
}: {
  employeeCount: number;
  createPermission: boolean;
  openFilter: () => void;
  workingDates: {
    id: string;
    date: string;
    end_time: string;
    start_time: string;
  }[];
}) => {
  const colors = useColors();
  const date = useAppSelector(state => state.booking.date);
  const username = useAppSelector(state => state.company.data?.username);
  const dispatch = useAppDispatch();
  const [datePicker, setDatePicker] = useState(false);
  const navigate = useNavigate();
  const {isMobile, width} = useResize();
  const fixedCalendar = useAppSelector(
    state => state.booking.filter.fixedCalendar,
  );
  const bookingFilter = useAppSelector(state => state.booking.filter);
  const [modalSetting, setModalSetting] = useState(false);
  const sidebar = document.getElementById('sidebar');

  const getFilterIndicator = React.useCallback(() => {
    const filter: number[] = [];
    if (bookingFilter.employeeWithBooking) {
      filter.push(0);
    }
    if (bookingFilter.employeeWithoutBooking) {
      filter.push(1);
    }
    if (bookingFilter.professions.length) {
      bookingFilter.professions.map((_, index) => {
        filter.push(2 + index);
      });
    }
    return filter.length;
  }, [
    bookingFilter.employeeWithBooking,
    bookingFilter.employeeWithoutBooking,
    bookingFilter.professions,
  ]);

  return (
    <Flex
      style={{
        margin: '0',
        padding: '28px 0 4px 0',
        position: 'sticky',
        top: 0,
        backgroundColor: colors.bgSecondary,
        zIndex: modalSetting ? 1001 : 99,
      }}
      alignItems="center"
      justifyContent="space-between">
      <TextTitle typography="title24">Журнал&nbsp;записи</TextTitle>
      <Flex gap={8} alignItems="flex-end" justifyContent="space-between">
        {!fixedCalendar && (
          <Flex flex={1} direction="row" style={{position: 'relative'}}>
            <HeaderDate
              id="HeaderDate"
              style={{gap: 8}}
              onClick={() => {
                setDatePicker(true);
              }}>
              <IconButton
                size={20}
                variant="unstyled"
                onClick={e => {
                  dispatch(
                    setBookingDate(
                      dayjs(date).subtract(1, 'day').format('YYYY-MM-DD'),
                    ),
                  );
                  e.stopPropagation();
                }}>
                <ArrowLeft2v size={20} />
              </IconButton>
              <Text
                typography="subHead14Medium"
                style={{width: 'max-content', pointerEvents: 'none'}}>
                {getTypedDate(
                  date,
                  width - (sidebar?.offsetWidth ?? 0) + 88 < 888 ? false : true,
                )}
              </Text>
              <IconButton
                size={20}
                variant="unstyled"
                onClick={e => {
                  dispatch(
                    setBookingDate(
                      dayjs(date).add(1, 'day').format('YYYY-MM-DD'),
                    ),
                  );
                  e.stopPropagation();
                }}>
                <ArrowRight2v size={20} />
              </IconButton>
            </HeaderDate>
            <DatePicker
              style={{
                width: isMobile ? 'calc(100vw - 32px)' : '375px',
                position: 'fixed',
                left: isMobile
                  ? '16px'
                  : `calc(100% - ${fixedCalendar ? '408' : '-24'}px - 772px)`,
                top: isMobile ? 56 : 76,
              }}
              visible={datePicker}
              value={date}
              onChange={edit_date => dispatch(setBookingDate(edit_date))}
              onClose={() => setDatePicker(false)}
            />
          </Flex>
        )}
        <FilterBtn
          onClick={() => openFilter()}
          indicator={getFilterIndicator()}
          textVisible={
            width - (sidebar?.offsetWidth ?? 0) + 88 <
            (fixedCalendar ? 1200 : 1055)
              ? false
              : true
          }
        />
        <Filter
          employeeCount={employeeCount}
          visible={modalSetting}
          onClose={() => setModalSetting(false)}
          onOpen={() => setModalSetting(true)}
          createPermission={createPermission || fixedCalendar}
        />
        <ButtonCalendar
          style={{
            backgroundColor: fixedCalendar
              ? colors.mainLight
              : colors.bgPrimary,
          }}
          active={fixedCalendar}
          onClick={() => {
            dispatch(setBookingFilterFixedCalendar(!fixedCalendar));
          }}>
          <Calendar />
        </ButtonCalendar>
        {createPermission && (
          <HeaderButton
            content="Новая запись"
            showContent={
              width - (sidebar?.offsetWidth ?? 0) + 88 <
              (fixedCalendar ? 1135 : 990)
                ? false
                : true
            }
            variant="create"
            style={{
              backgroundColor: colors.mainPrimary,
              gap: 8,
            }}
            onClick={() => {
              dispatch(clearCreateBookingState());
              dispatch(setBookingScreen('create'));
              if (workingDates.length) {
                dispatch(
                  setCreateBookingWorkingDate(
                    `${workingDates[0].date} ${dayjs().format('HH:mm')},${
                      workingDates[0].id
                    }`,
                  ),
                );
              }

              navigate(`/${username}/create-booking`);
            }}>
            <Plus size={20} />
          </HeaderButton>
        )}
      </Flex>
    </Flex>
  );
};
