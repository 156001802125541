import {TariffBox} from './ui/TariffBox';
import {TooltipRow} from './ui/TooltipRow';
import {TariffSelect} from './ui/TariffSelect';
import {type CompanyInformation, CompanyInfo} from './ui/CompanyInfo';
import {
  type TariffTooltip,
  tariffRows,
  type IPaymentResponse,
  requestJSON,
} from './lib/utils';
import {ProSlide} from './ui/ProSlide';
import {FreeSlide} from './ui/FreeSlide';
import {gql} from 'shared/__generated__';

export const MY_TARIFF = gql(`
  query MyTariff($companyId: String!, $first: Int!, $page: Int) {
    company(id: $companyId) {
      id
      subscription_end_date
      is_subscription_active
      user_card_token_id
      companyAccountInfo {
        city {
          id
          name
        }
        address
        company_name
        office
        post_index
        comment
        email
        name
      }
      plan {
        price
        type
        slots
        id
        created_at
      }
      subscriptions(first: $first, page: $page) {
        data {
          type
          id
          created_at
          status
          summary
          slots
        }
      }
    }
    me {
      id
      bonus_account
      card_token {
        id
        first_six_digits
        last_four_digits
      }
    }
    getCompanyEmployeesLength(company_id: $companyId, enabled: true)
    companyPermissions(company_id: $companyId) {
      subscription_and_pay_permission
    }
    ...Permissions
  }
`);

export const PAYMENT_TARIFFS = gql(`
  query PaymentPriceListTariffs($companyId: String) {
    priceList {
      id
      price
      slots
      type
      created_at
    }
    company(id: $companyId) {
      id
      user_card_token_id
      is_subscription_active
      companyAccountInfo {
        city {
          id
          name
        }
        address
        company_name
        office
        post_index
        comment
        email
        name
      }
      plan {
        id
        price
        type
        created_at
        slots
      }
    }
    me {
      id
      card_token {
        id
        last_four_digits
        first_six_digits
      }
      bonus_account
    }
  }
`);
export const COMPANY_CHECK_TARIFF = gql(`
  query CompanyCheckTariff($companyId: String!) {
    company(id: $companyId) {
      plan {
        type
      }
    }
  ...Permissions
  }
`);

export {
  TariffBox,
  TooltipRow,
  tariffRows,
  TariffTooltip,
  CompanyInfo,
  CompanyInformation,
  TariffSelect,
  ProSlide,
  FreeSlide,
  IPaymentResponse,
  requestJSON,
};
