import React, {useEffect, useRef, useState} from 'react';
import {Line} from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import {Content} from 'shared/ui/Content';
import {
  CategoryScale,
  ChartArea,
  ChartData,
  Chart as ChartJS,
  CoreChartOptions,
  DatasetChartOptions,
  ElementChartOptions,
  LineControllerChartOptions,
  PluginChartOptions,
  Point,
  ScaleChartOptions,
} from 'chart.js';
import {Flex} from 'shared/ui/Flex';
import {_DeepPartialObject} from 'chart.js/dist/types/utils';
import {Text} from 'shared/ui/Text';
import {SegmentControl} from 'shared/ui/SegmentControl';
import dayjs from 'dayjs';
import {GetStatisticsLineQuery} from 'shared/__generated__/graphql';
Chart.register(CategoryScale);

const labels = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
];

const data: ChartData<'line', (number | Point | null)[], unknown> = {
  labels,
  datasets: [
    {
      label: '',
      data: [],
      pointBackgroundColor: 'transparent',
      pointBorderColor: 'transparent',
      pointHoverBackgroundColor: '#6765F8',
      pointHoverBorderColor: 'white',
      pointHoverBorderWidth: 2,
      borderColor: '#6765F8',
      borderWidth: 1,
      fill: true,
      tension: 0.5,
    },
  ],
};

const options:
  | _DeepPartialObject<
      CoreChartOptions<'line'> &
        ElementChartOptions<'line'> &
        PluginChartOptions<'line'> &
        DatasetChartOptions<'line'> &
        ScaleChartOptions<'line'> &
        LineControllerChartOptions
    >
  | undefined = {
  interaction: {
    mode: 'index' as const,
    intersect: false,
  },
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      reverse: true,
      display: true,
      ticks: {
        font: {
          size: 12,
          family: 'Inter',
        },
      },
    },
    y: {
      min: 0,
      display: true,
      ticks: {
        font: {
          size: 12,
          family: 'Inter',
        },
      },
    },
  },
};

function createGradient(ctx: CanvasRenderingContext2D, area: ChartArea) {
  const colorStart = 'transparent';
  const colorMid1 = 'rgb(40%, 40%, 97%, 0.2)';
  const colorMid2 = 'rgb(40%, 40%, 97%, 0.3)';
  const colorEnd = 'rgb(40%, 40%, 97%, 0.4)';

  const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top);

  gradient.addColorStop(0, colorStart);
  gradient.addColorStop(0.4, colorMid1);
  gradient.addColorStop(0.8, colorMid2);
  gradient.addColorStop(1, colorEnd);

  return gradient;
}
const segmentData = [
  {
    label: 'День',
    value: 0,
  },
  {
    label: 'Неделя',
    value: 1,
  },
  {
    label: 'Месяц',
    value: 2,
  },
];

export const LineChart = ({
  data: lineData,
  segmentValue,
  setSegmentValue,
}: {
  data?: GetStatisticsLineQuery;
  segmentValue: number;
  setSegmentValue: (value: number) => void;
}) => {
  const chartRef = useRef<ChartJS<'line'>>(null);
  const [chartData, setChartData] = useState<ChartData<'line'>>({
    datasets: [],
  });

  useEffect(() => {
    const chart = chartRef.current;

    if (!chart) {
      return;
    }
    const newChartData = {
      ...data,
      labels: lineData?.getStatistics?.salesGraph?.map(item =>
        segmentValue === 2
          ? dayjs(item?.date).format('MM.YYYY')
          : dayjs(item?.date).format('DD.MM'),
      ),
      datasets: data.datasets.map(dataset => ({
        ...dataset,
        backgroundColor: createGradient(chart.ctx, chart.chartArea),
        data:
          lineData?.getStatistics?.salesGraph?.map(item =>
            item?.sales ? +item.sales : 0,
          ) ?? [],
      })),
    };
    setChartData(newChartData);
  }, [lineData?.getStatistics?.salesGraph, segmentValue]);

  return (
    <Content
      style={{
        display: 'flex',
        flex: 1,
        height: 390,
      }}>
      <Flex justifyContent="space-between">
        <Text typography="title20">Сумма продаж</Text>
        <SegmentControl
          adaptive
          value={segmentValue}
          onClick={value => setSegmentValue(value)}
          data={segmentData}
        />
      </Flex>

      <Flex
        flex={1}
        style={{
          height: 288,
          alignItems: 'end',
        }}>
        <Line ref={chartRef} data={chartData} options={options} height={80} />
      </Flex>
    </Content>
  );
};
