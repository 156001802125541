import React from 'react';
import {device} from 'shared/device';
import styled from 'styled-components';
import {Text} from './Text';

const WrapperBottomSheet = styled.div<{
  visible: boolean;
  wrapperStyle?: React.CSSProperties;
}>`
  display: ${props => (props.visible ? 'block' : 'none')};
  width: max-content;
  left: ${props => props.wrapperStyle?.left ?? '50%'};
  bottom: auto;
  z-index: 1000;
  background-color: ${props => props.theme.bgPrimary};
  border-radius: 20px;
  pointer-events: auto;
  transform: ${props => props.wrapperStyle?.transform};
  transition: transform 200ms;
  position: absolute;
  top: ${props => props.wrapperStyle?.top ?? '50%'};
  padding: 16px 0 16px 0;
  box-shadow: 0 2px 10px 7px ${({theme}) => theme.modalOverlay};

  @media ${device.mobile} {
    display: block;
    width: 100%;
    left: 0;
    bottom: 0;
    border-radius: 24px 24px 0 0;
    transform: translateY(${props => (props.visible ? '0px' : '100vh')});
    position: fixed;
    top: initial;
    padding: initial;
  }
`;

const Indicator = styled.div`
  margin-top: 8px;
  width: 24px;
  height: 4px;
  align-self: center;
  position: relative;
  left: calc(50% - 12px);
  background-color: ${props => props.theme.textPrimary};
  border-radius: 2px;
  @media ${device.desktop} {
    display: none;
  }
`;

const Title = styled(Text)`
  align-self: center;
  text-align: center;
  position: static;
  margin-top: 24px;
  margin-bottom: 16px;
  @media ${device.desktop} {
    display: none;
  }
`;

const Backdrop = styled.div<{visible: boolean}>`
  position: fixed;
  display: ${props => (props.visible ? 'block' : 'none')};
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  pointer-events: all;
  background-color: ${({theme}) => theme.modalOverlay};
`;

type Props = React.PropsWithChildren & {
  visible: boolean;
  onClose: () => void;
  padding?: string | number;
  title?: string;
  wrapperStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  backdropStyle?: React.CSSProperties;
};

export const BottomSheet = (props: Props) => {
  const {
    visible,
    title,
    children,
    onClose,
    wrapperStyle,
    style,
    backdropStyle,
  } = props;
  return (
    <>
      <Backdrop style={backdropStyle} visible={visible} onClick={onClose} />
      <WrapperBottomSheet
        visible={visible}
        wrapperStyle={wrapperStyle}
        style={style}>
        <Indicator />
        <Title typography="text16Semibold">{title}</Title>
        {children}
      </WrapperBottomSheet>
    </>
  );
};
