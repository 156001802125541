import React from 'react';
import {StarStroke} from 'shared/icons/StarStroke';
import styled, {useTheme} from 'styled-components';
import {Text} from './Text';

const Wrapper = styled.div`
  gap: 2px;
  flex-direction: row;
  align-items: center;
`;

export const RatingStar = ({
  list,
  rating,
}: {
  list?: boolean;
  rating: number;
}) => {
  const colors = useTheme();
  if (list) {
    const array = [];
    for (let count = 0; count < 5; count++) {
      array.push(rating > count ? true : false);
    }

    return (
      <Wrapper>
        {array.map((item, index) => (
          <StarStroke
            key={index.toString()}
            color={colors.goldPrimary}
            fillColor={item ? colors.goldPrimary : 'none'}
          />
        ))}
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <StarStroke color={'none'} />
      <Text typography="subHead14Regular" color="goldPrimary">
        {rating}
      </Text>
    </Wrapper>
  );
};
