import React, {useState} from 'react';
import {useQuery} from '@apollo/client';
import {gql} from 'shared/__generated__';
import {getName, getRating} from 'shared/lib/utils';
import {useAppSelector} from 'shared/store';
import {Avatar} from 'shared/ui/Avatar';
import {Dropdown} from 'shared/ui/Dropdown';
import {IconButton} from 'shared/ui/IconButton';
import {TableViewRow} from 'shared/ui/TableViewRow';
import styled from 'styled-components';
import {InputAvatar} from 'entities/booking';
import {useDebounce} from 'shared/lib/hooks';
import {ArrowDown2v} from 'shared/icons/ArrowDown2v';
import Close from 'shared/icons/Close';

const Wrapper = styled.div`
  display: block;
  position: relative;
`;

const COMPANY_EMPLOYEES = gql(`
  query CompanyEmployeesCreateSchedule($companyId: String!, $first: Int!, $query: String) {
    companyEmployees(first: $first, company_id: $companyId, query: $query) {
      data {
        id
        avatar {
          id
          url
        }
        position
        name
        surname
        professions {
          id
          name
        }
        rating_1_count
        rating_2_count
        rating_3_count
        rating_4_count
        rating_5_count
      }
      paginatorInfo {
        currentPage
        hasMorePages
      }
    }
  } 
`);

type Props = {
  value: string | null;
  onChange: (value: string | null) => void;
};

export const ScheduleSelectEmployee = (props: Props) => {
  const {onChange, value} = props;
  const [visible, setVisible] = useState(false);
  const companyId = useAppSelector(state => state.company.data!.id);
  const [search, setSearch] = useState('');

  const {data, refetch} = useQuery(COMPANY_EMPLOYEES, {
    variables: {
      companyId,
      query: search,
      first: 100,
    },
  });

  const employees = data?.companyEmployees?.data ?? [];
  const findEmployees = employees.find(employee => employee.id === value);
  const findEmployeeValue = findEmployees
    ? getName(findEmployees?.name, findEmployees?.surname, 'employee')
    : undefined;
  const findEmployeeLabel =
    findEmployees && findEmployees.professions.length
      ? findEmployees.professions[0].name
      : undefined;
  const queryDebounce = useDebounce((query: string) => {
    refetch({
      companyId,
      query,
      first: 100,
    });
  }, 400);

  return (
    <Wrapper>
      <InputAvatar
        leftElement={<Avatar size={40} url={findEmployees?.avatar?.url} />}
        rightElement={
          <>
            {(value || search) && (
              <IconButton
                size={24}
                variant="unstyled"
                onClick={e => {
                  onChange(null);
                  setSearch('');
                  e.stopPropagation();
                }}>
                <Close />
              </IconButton>
            )}
            <IconButton size={24} variant="unstyled" rotate={visible ? 180 : 0}>
              <ArrowDown2v />
            </IconButton>
          </>
        }
        label={findEmployeeValue}
        value={findEmployeeLabel ?? 'Сотрудник'}
        search={search}
        setSearch={value => {
          setSearch(value);
          queryDebounce(value);
        }}
        onClick={() => setVisible(true)}
      />
      <Dropdown
        visible={visible}
        data={employees}
        style={{top: 48}}
        width="100%"
        onClose={() => setVisible(false)}
        renderItem={item => (
          <TableViewRow
            style={{
              padding: '0 16px',
              width: '100%',
              cursor: 'pointer',
              height: 72,
            }}
            styleTitle={{pointerEvents: 'none'}}
            styleSubTitle={{pointerEvents: 'none'}}
            leftElement={() => (
              <Avatar
                size={56}
                url={item.avatar?.url}
                rating={getRating(item)}
              />
            )}
            title={getName(item.name, item.surname, 'employee')}
            subtitle={
              item.professions && item.professions.length > 0
                ? item.professions[0].name
                : ''
            }
            onClick={() => {
              onChange && onChange(item.id);
              setSearch('');
              setVisible(false);
            }}
          />
        )}
        listEmpty="Сотрудники не найдены"
      />
    </Wrapper>
  );
};
