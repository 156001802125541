import React, {useState} from 'react';
import {Header} from './ui/Header';
import {Table} from 'shared/ui/Table';
import {Text} from 'shared/ui/Text';
import {styled} from 'styled-components';
import {gql} from 'shared/__generated__';
import {useMutation, useQuery} from '@apollo/client';
import {useAppSelector} from 'shared/store';
import {CompanyRoles} from 'shared/__generated__/graphql';
import {AccessData, getDataAccess, getPermissionsByRole} from './lib';
import {CheckBox} from 'shared/ui/CheckBox';
import {Button} from 'shared/ui/Button';
import {showAlert} from 'shared/ui/Alert';
import {Layout} from 'shared/ui/Layout';
import {Seo} from 'shared/ui/Seo';
import {Content} from 'shared/ui/Content';
import {Skeleton} from 'shared/ui/Skeleton';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-column-start: 4;
  grid-column-end: 14;
  padding: 64px 0 0;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const Box = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const Footer = styled.footer`
  display: flex;
  position: sticky;
  grid-column-start: 1;
  grid-column-end: 17;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  bottom: 0;
  height: 80px;
  background-color: ${({theme}) => theme.bgPrimary};
  border-top: 1px solid ${({theme}) => theme.borderPrimary};
`;

const COMPANY_PERMISSIONS = gql(`
  query CompanyEmployeePermissions($companyId: String) {
    company(id: $companyId) {
      id
      roles {
        role
        id
        add_to_customers_bonus_card_permission
        add_to_customers_certificate_permission
        create_bookings_permission
        create_customers_permission
        create_invitation_permission
        customers_info_permission
        edit_apps_permission
        edit_bonus_card_permission
        edit_bookings_permission
        edit_certificate_permission
        edit_company_permission
        edit_company_services_permission
        edit_customers_permission
        edit_discounts_permission
        edit_employees_permission
        edit_work_schedule_permission
        employees_info_permission
        fill_bonus_card_permission
        moderation_permission
        notation_permission
        reviews_info_permission
        reviews_reply_permission
        subscription_and_pay_permission
        work_schedule_info_permission
        edit_bonus_card_templates
        edit_certificate_templates
        reports_permission
        contact_permission
        view_service_permission
        bookings_info_permission
        messages_permission
        delete_messages_permission
        view_products_permission
        salaries_permission
        edit_products_permission
        message_sample_permission
        webhook_permission
      }
    }
  }
`);
const UPDATE_PERMISSION = gql(`
  mutation MutationUpdatePermissions(
    $input: [UpdateCompanyPermissions!]!
    $companyId: String!
  ) {
    updateCompanyPermissions(input: $input, company_id: $companyId) {
      id
    }
  }
`);

const titles = ['Разрешение', 'Сотрудник', 'Администратор', 'Владелец'];
const widths = [0, 180, 180, 180];

export const Permissions = () => {
  const companyId = useAppSelector(state => state.company.data!.id);
  const [access, setAccess] = useState(AccessData);
  const [updateCompanyPermissions, {loading: updateLoading}] =
    useMutation(UPDATE_PERMISSION);
  const {data, loading} = useQuery(COMPANY_PERMISSIONS, {
    variables: {
      companyId,
    },
    onCompleted: res => {
      const participantRes = res?.company?.roles?.find(
        item => item.role === 'participant',
      );
      const adminRes = res?.company?.roles?.find(item => item.role === 'admin');
      if (adminRes && participantRes) {
        setAccess(
          getDataAccess({
            access: AccessData,
            admin: adminRes,
            participant: participantRes,
          }),
        );
      }
      return;
    },
  });
  const participant = data?.company?.roles?.find(
    item => item.role === 'participant',
  );
  const admin = data?.company?.roles?.find(item => item.role === 'admin');

  function updateAccess(
    index: number,
    fillName: CompanyRoles.Participant | CompanyRoles.Admin,
    value: boolean,
  ) {
    setAccess(prev => {
      return prev.map((item, _index) => {
        if (_index === index) {
          return {
            ...item,
            [fillName]: !value,
          };
        }
        return item;
      });
    });
  }

  const visible = () => {
    if (admin && participant) {
      const firstData = getDataAccess({
        access: AccessData,
        admin: admin,
        participant: participant,
      })
        .flatMap(({admin, participant, id}) => [id, admin, participant])
        .join('');
      const secondData = access
        .flatMap(({admin, participant, id}) => [id, admin, participant])
        .join('');
      return firstData !== secondData;
    }
    return false;
  };

  return (
    <Layout columns={16} style={{overflowX: 'hidden'}}>
      <Seo title="Права доступа" />
      <Wrapper>
        <Header
          onReset={() => {
            setAccess(AccessData);
          }}
        />
        <Content>
          <Table
            style={{marginBottom: 0, marginTop: 8}}
            titles={[
              ...titles.map((item, index) => (
                <Text
                  typography="subHead14Regular"
                  color="textTertiary"
                  style={{
                    textAlign: index === 0 ? 'start' : 'center',
                  }}
                  key={index + 1}>
                  {item}
                </Text>
              )),
            ]}
            widths={widths}
            rows={access.map((item, index) => [
              item.title,
              <Box key={index.toString() + '2'}>
                {!loading ? (
                  <CheckBox
                    checked={item.participant}
                    onChange={() => {
                      updateAccess(
                        index,
                        CompanyRoles.Participant,
                        item.participant,
                      );
                    }}
                  />
                ) : (
                  <Skeleton height={24} width={24}>
                    <rect width={24} height={24} rx={6} />
                  </Skeleton>
                )}
              </Box>,
              <Box key={index.toString() + '3'}>
                {!loading ? (
                  <CheckBox
                    checked={item.admin}
                    onChange={() => {
                      updateAccess(index, CompanyRoles.Admin, item.admin);
                    }}
                  />
                ) : (
                  <Skeleton height={24} width={24}>
                    <rect width={24} height={24} rx={6} />
                  </Skeleton>
                )}
              </Box>,
              <Box key={index.toString() + '4'}>
                <CheckBox checked={item.owner} disabled />
              </Box>,
            ])}
          />
        </Content>
      </Wrapper>
      {visible() ? (
        <Footer>
          <Button
            style={{width: 192}}
            typography="text16Semibold"
            variant="light"
            onClick={() => {
              if (admin && participant) {
                setAccess(
                  getDataAccess({
                    access: AccessData,
                    admin: admin,
                    participant: participant,
                  }),
                );
              }
            }}>
            Отменить
          </Button>
          <Button
            style={{width: 192}}
            loading={loading || updateLoading}
            disabled={loading || updateLoading}
            typography="text16Semibold"
            onClick={async () => {
              try {
                const dataPermissions = [
                  getPermissionsByRole(access, CompanyRoles.Admin),
                  getPermissionsByRole(access, CompanyRoles.Participant),
                ];
                await updateCompanyPermissions({
                  variables: {
                    input: dataPermissions,
                    companyId,
                  },
                  refetchQueries: [COMPANY_PERMISSIONS],
                });
                showAlert('success', 'Изменения сохранены');
              } catch (e) {
                if (e instanceof Error) {
                  showAlert('error', e.message);
                }
              }
            }}>
            Сохранить
          </Button>
        </Footer>
      ) : (
        <div style={{gridColumnStart: 1}} />
      )}
    </Layout>
  );
};
