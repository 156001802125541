import React from 'react';
import {Text} from 'shared/ui/Text';
import {NetworkStatus, useQuery} from '@apollo/client';
import {List} from 'shared/ui/List';
import {Avatar} from 'shared/ui/Avatar';
import {Plus} from 'shared/icons/Plus';
import {Error} from 'shared/ui/Error';
import {useNavigate} from 'react-router-dom';
import {Button} from 'shared/ui/Button';
import {Content} from 'shared/ui/Content';
import {NavLink} from 'shared/ui/NavLink';
import {useColorScheme, useColors} from 'shared/lib/hooks';
import {Space} from 'shared/ui/Space';
import {setCompanyData, useAppDispatch, useAppSelector} from 'shared/store';
import {Flex} from 'shared/ui/Flex';
import dayjs from 'dayjs';
import {CircleAlert} from 'shared/icons/CircleAlert';
import InfiniteScroll from 'react-infinite-scroll-component';
import {MY_COMPANIES} from 'entities/create';
import {gql} from 'shared/__generated__';
import {MyCompaniesSkeleton} from './ui/Skeleton';
import {Seo} from 'shared/ui/Seo';
import {ArrowRight1v} from 'shared/icons/ArrowRight1v';
import {EmptySalon} from 'shared/illustration/EmptySalon';

const ME = gql(`
  query GetMyAvatar {
    me {
      id
      name
      surname
      phone
      has_password
      avatar {
        id
        type
        url
      }
    }
  }
`);

export function MyCompanies() {
  const colors = useColors();
  const colorScheme = useColorScheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {data: userData} = useQuery(ME);
  const companyId = useAppSelector(state => state.company.data?.id);
  const userId = useAppSelector(state => state.auth.userId!);
  const {data, error, loading, fetchMore, networkStatus} = useQuery(
    MY_COMPANIES,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        first: 10,
        page: 1,
      },
    },
  );
  const myCompanyLength = data?.myEmployees?.data.length;
  const myCompany = data?.myEmployees?.data;
  const user = userData?.me;
  const fetchMoreData = () => {
    const currentPage = data?.myEmployees?.paginatorInfo.currentPage || 1;
    if (
      data?.myEmployees?.paginatorInfo.hasMorePages &&
      data.myEmployees.data &&
      !loading
    ) {
      fetchMore({
        variables: {
          companyId,
          page: currentPage + 1,
        },
        updateQuery: (prev, {fetchMoreResult}) => {
          if (!fetchMoreResult) return prev;
          const prevCitiesData = prev.myEmployees?.data || [];
          const newCitiesData = fetchMoreResult.myEmployees?.data || [];
          return Object.assign({}, prev, {
            myEmployees: {
              ...fetchMoreResult.myEmployees,
              data: [...prevCitiesData, ...newCitiesData],
            },
          });
        },
      });
    }
  };

  if (error) {
    return <Error message={error.message} />;
  }

  return (
    <Flex direction="column" gap={24}>
      <Seo title="Мои организации" />
      <Text typography="title24">Мои организации</Text>
      {networkStatus === NetworkStatus.loading ? (
        <Flex direction="column" gap={24}>
          <MyCompaniesSkeleton />
          <Button
            size="large"
            onClick={() => {
              navigate('/create-company');
            }}
            leftIcon={<Plus />}>
            Создать организацию
          </Button>
        </Flex>
      ) : (
        <>
          <Content>
            <NavLink
              icon={<Avatar size={56} url={user?.avatar?.url} />}
              label={`${user?.name} ${user?.surname}`}
              onClick={() => {
                navigate('/profile');
              }}
              description={user?.phone ?? '-'}
            />
          </Content>
          {myCompany?.filter(
            item =>
              item.invite_status !== 'declined' &&
              item.invite_status !== 'created',
          ).length ? (
            <Flex direction="column" gap={24}>
              <InfiniteScroll
                dataLength={myCompanyLength ?? 0}
                next={fetchMoreData}
                hasMore={data?.myEmployees?.paginatorInfo.hasMorePages || false}
                loader={
                  networkStatus === NetworkStatus.fetchMore && (
                    <Flex justifyContent="center" alignItems="center">
                      <Text typography="text16Regular">Загрузка...</Text>
                    </Flex>
                  )
                }>
                <Content>
                  <List
                    data={myCompany?.filter(
                      item =>
                        item.invite_status !== 'declined' &&
                        item.invite_status !== 'created',
                    )}
                    divider={<Space height={24} />}
                    renderItem={item => {
                      const avatar = item.company.avatar && (
                        <Avatar
                          size={56}
                          url={item.company.avatar?.url}
                          placeholder={`/icons/company_avatar_${colorScheme}.png`}
                        />
                      );
                      const isAccepted = item.invite_status === 'accepted';
                      const isPending = item.invite_status === 'pending';

                      const descriptionColor = isAccepted
                        ? colors.textTertiary
                        : colors.successPrimary;
                      const getTranslatedRole = () => {
                        switch (item.role) {
                          case 'admin':
                            return 'Администратор';
                          case 'owner':
                            return 'Владелец';
                          case 'participant':
                            return 'Сотрудник';
                        }
                      };
                      const address = `г. ${item.company.city?.name}${
                        item.company.address ? ', ' + item.company.address : ''
                      }`;
                      const description = isAccepted
                        ? address
                        : isPending
                        ? 'Приглашение'
                        : 'Отклонено';
                      return (
                        <NavLink
                          label={item.company.name}
                          styleLabel={{
                            color:
                              companyId === item.company.id
                                ? colors.mainPrimary
                                : colors.textPrimary,
                          }}
                          description={description}
                          styleDescription={{color: descriptionColor}}
                          rightIcon={
                            dayjs().isAfter(
                              dayjs(item.company.subscription_end_date),
                            ) ? (
                              <CircleAlert color={colors.warningPrimary} />
                            ) : (
                              <ArrowRight1v />
                            )
                          }
                          tag={getTranslatedRole()}
                          onClick={() => {
                            const employee = item;
                            const isUnavailableCompany =
                              employee.company.subscription_end_date !== null &&
                              employee.invite_status === 'accepted' &&
                              dayjs().isAfter(
                                dayjs(employee.company.subscription_end_date),
                              );
                            if (isUnavailableCompany) {
                              navigate('/unavailable-company', {
                                state: {
                                  plan: employee.company.plan,
                                  name: employee.company.name,
                                  role: employee.role,
                                  companyId: employee.company.id,
                                },
                              });
                            } else if (employee.invite_status !== 'accepted') {
                              navigate('/invite-company', {
                                state: {
                                  user_id: userId,
                                  company_id: employee.company.id,
                                },
                              });
                            } else {
                              dispatch(
                                setCompanyData({
                                  id: item.company.id,
                                  employeeId: item.id,
                                  username: item.company.username,
                                }),
                              );
                              navigate(`/${item.company.username}/bookings`);
                            }
                          }}
                          icon={avatar ?? <></>}
                        />
                      );
                    }}
                  />
                </Content>
              </InfiniteScroll>

              <Button
                size="large"
                onClick={() => {
                  navigate('/create-company');
                }}
                leftIcon={<Plus />}>
                Создать организацию
              </Button>
            </Flex>
          ) : (
            <Content>
              <Flex
                direction="column"
                alignItems="center"
                gap={16}
                style={{width: 500, alignSelf: 'center'}}>
                <EmptySalon size={300} />
                <Text typography="title20">Добро пожаловать!</Text>
                <Text align="center" typography="text18" color="textTertiary">
                  Введите данные о своей организации и начинайте работать в
                  Booky
                </Text>
              </Flex>
              <Flex direction="column" gap={24}>
                <Button
                  size="large"
                  onClick={() => {
                    navigate('/create-company');
                  }}>
                  Начать
                </Button>
                <Text align="center" color="textTertiary">
                  Если вы работаете мастером, то дождитесь приглашения от вашей
                  организации. После получения вам надо будет подтвердить и
                  присоединиться
                </Text>
              </Flex>
            </Content>
          )}
        </>
      )}
    </Flex>
  );
}
