import {ApolloError, useMutation} from '@apollo/client';
import {COMPANY_SOURCES} from 'entities/bookingSource';
import React from 'react';
import {gql} from 'shared/__generated__';
import Xv2 from 'shared/icons/Xv2';
import {useColors} from 'shared/lib/hooks';
import {useAppSelector} from 'shared/store';
import {showAlert} from 'shared/ui/Alert';
import {Button} from 'shared/ui/Button';
import {Flex} from 'shared/ui/Flex';
import {IconButton} from 'shared/ui/IconButton';
import {Modal} from 'shared/ui/Modal';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 16px;
`;

const DELETE_BOOKING_SOURCE = gql(`
    mutation DeleteSource($deleteSourceId: String!) {
        deleteSource(id: $deleteSourceId) {
            id
            name
        }
        }
`);

export const ModalDelete = ({
  visible,
  onClose,
  id,
  onComplete,
}: {
  visible: boolean;
  onClose: () => void;
  id: string;
  onComplete: () => void;
}) => {
  const colors = useColors();
  const companyId = useAppSelector(state => state.company.data?.id);
  const [deleteSource, {loading: loadingDelete}] = useMutation(
    DELETE_BOOKING_SOURCE,
    {
      refetchQueries: [
        {
          query: COMPANY_SOURCES,
          variables: {
            companyId: companyId!,
          },
        },
      ],
    },
  );
  return (
    <Modal
      style={{
        gap: 16,
        padding: 24,
        left: 'calc((100% - 472px) / 2)',
        width: 472,
        top: 'calc((100% - 200px) / 2)',
      }}
      visible={visible}
      backdropVariant="overlay"
      onClose={onClose}>
      <Wrapper>
        <Flex alignItems="center" justifyContent="space-between">
          <Text typography="title20">Удалить источник</Text>
          <IconButton size={40} onClick={() => onClose()}>
            <Xv2 />
          </IconButton>
        </Flex>
        <Text>Вы уверены, что хотите удалить этот источник?</Text>
        <Flex gap={8}>
          <Button
            size="large"
            variant="outline"
            onClick={() => onClose()}
            style={{
              borderColor: colors.mainPrimary,
              flex: 1,
              color: colors.mainPrimary,
            }}>
            Отмена
          </Button>
          <Button
            size="large"
            variant="outline"
            loading={loadingDelete}
            style={{
              borderColor: colors.dangerPrimary,
              flex: 1,
              color: colors.dangerPrimary,
            }}
            onClick={async () => {
              try {
                await deleteSource({
                  variables: {
                    deleteSourceId: id,
                  },
                });
                onComplete();
              } catch (e) {
                if (e instanceof ApolloError) {
                  showAlert('error', e.message);
                }
              }
            }}>
            Удалить
          </Button>
        </Flex>
      </Wrapper>
    </Modal>
  );
};
