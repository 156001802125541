import React, {useState} from 'react';
import {ApolloError, useMutation, useQuery} from '@apollo/client';
import dayjs from 'dayjs';
import {useNavigate} from 'react-router-dom';
import {gql} from 'shared/__generated__';
import {Confetti} from 'shared/icons/Confetti';
import {Crown} from 'shared/icons/Crown';
import {getName, getPhoneMask} from 'shared/lib/utils';
import {
  setCreateBookingCustomer,
  setEditBookingCustomer,
  useAppDispatch,
  useAppSelector,
} from 'shared/store';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';
import {Dropdown} from 'shared/ui/Dropdown';
import {useColors, useDebounce} from 'shared/lib/hooks';
import {IconButton} from 'shared/ui/IconButton';
import {Avatar} from 'shared/ui/Avatar';
import {Flex} from 'shared/ui/Flex';
import {Phone} from 'shared/icons/Phone';
import {Whatsapp} from 'shared/icons/Whatsapp';
import {Telegram} from 'shared/icons/Telegram';
import {TextArea} from 'shared/ui/TextArea';
import {InputAvatar} from './InputAvatar';
import {ArrowDown2v} from 'shared/icons/ArrowDown2v';
import {Search} from 'shared/icons/Search';
import Check from 'shared/icons/Check';
import {ActionIcon} from 'shared/ui/ActionIcon';
import {Chat} from 'shared/icons/Chat';
import {NewPage} from 'shared/icons/NewPage';
import {Skeleton} from 'shared/ui/Skeleton';
import {EmptyCustomers} from 'shared/illustration/EmptyCustomers';
import {CirclePlus} from 'shared/icons/CirclePlus';
import {SelectTags} from 'entities/tags';
import {showAlert} from 'shared/ui/Alert';
import {CircleClose} from 'shared/icons/CircleClose';
import Xv2 from 'shared/icons/Xv2';
import {CUSTOMERS} from '..';

const DELETE_TAG_FROM_CUSTOMER = gql(`
  mutation DeleteTagFromCustomer($input: UpdateCustomer!) {
    updateCustomer(input: $input) {
      id
      name
      tag {
        id
        name
        order
        color {
          id
          rgb
        }
      }
    }
  }
`);

const TagsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-wrap: wrap;
  overflow-x: scroll;
`;

const TagItemWrapper = styled.button`
  display: flex;
  flex-direction: row;
  background-color: transparent;
  border: 1px solid ${({theme}) => theme.borderPrimary};
  align-items: center;
  padding: 8px;
  border-radius: 8px;
  gap: 4px;
  height: 32px;
  width: max-content;
`;

const TagItem = ({
  name,
  color,
  onClick,
  isCreate,
}: {
  name: string;
  color?: string;
  onClick: () => void;
  isCreate: boolean;
}) => {
  const colors = useColors();
  return (
    <TagItemWrapper onClick={name === 'Добавить тег' ? onClick : undefined}>
      <Text
        typography="footNote12Medium"
        style={{color: `rgb(${color})`, width: 'max-content'}}>
        {name}
      </Text>
      <IconButton variant="unstyled" size={16} onClick={onClick}>
        {isCreate ? (
          <CirclePlus size={16} />
        ) : (
          <CircleClose size={16} color={colors.textSecondary} />
        )}
      </IconButton>
    </TagItemWrapper>
  );
};

export const SelectCustomer = ({
  value,
  disabled,
  comment,
  variant = 'edit',
  permission,
  createPermission,
}: {
  value?: string;
  disabled?: boolean;
  comment?: string | null;
  variant?: 'edit' | 'view';
  permission?: boolean;
  createPermission?: boolean;
}) => {
  const [visible, setVisible] = useState(false);
  const [search, setSearch] = useState('');
  const companyId = useAppSelector(state => state.company.data!.id);
  const screen = useAppSelector(state => state.booking.screen);
  const {customerId, workingDate} = useAppSelector(
    state => state.booking[screen],
  );
  const dispatch = useAppDispatch();
  const setRedux =
    screen === 'create' ? setCreateBookingCustomer : setEditBookingCustomer;
  const navigate = useNavigate();
  const [modalCreateTag, setModalCreateTag] = useState(false);

  const {data, loading, refetch} = useQuery(CUSTOMERS, {
    variables: {
      companyId,
      first: 20,
      page: 1,
      isBlocked: false,
      query: search,
    },
  });
  const [deleteTagFromCustomer] = useMutation(DELETE_TAG_FROM_CUSTOMER);
  const customers = data?.customers?.data ?? [];
  const customer = customers.find(customer => customer.id === value);
  const colors = useColors();
  const username = useAppSelector(state => state.company.data?.username);
  const tags = customer?.tag
    ? [
        ...[...customer.tag].sort(function (a, b) {
          return a.order! - b.order!;
        }),
        {
          id: '0',
          name: 'Добавить тег',
          color: {
            id: '0',
            rgb: '103, 101, 248, 1',
          },
        },
      ]
    : [
        {
          id: '0',
          name: 'Добавить тег',
          color: {
            id: '0',
            rgb: '103, 101, 248, 1',
          },
        },
      ];
  const queryRefetch = useDebounce((query: string) => {
    const variables = {
      companyId,
      first: 20,
      page: 1,
      isBlocked: false,
      query,
    };
    refetch(variables);
  }, 400);
  if (variant === 'view' && value === undefined) {
    return;
  } else {
    return (
      <Flex direction="column" gap={24}>
        <Text typography="title18">Клиент</Text>
        <Flex direction="column" gap={8}>
          {customer && tags && (
            <TagsWrapper>
              {tags.map(item => (
                <TagItem
                  key={item.id}
                  isCreate={item.id === '0'}
                  name={item.name}
                  color={item.color?.rgb}
                  onClick={async () => {
                    if (item.id === '0') {
                      setModalCreateTag(true);
                    } else {
                      if (customer) {
                        try {
                          await deleteTagFromCustomer({
                            variables: {
                              input: {
                                id: customerId!,
                                tags_id: tags
                                  .filter(
                                    item2 =>
                                      item2.id !== item.id && item2.id !== '0',
                                  )
                                  .map(item2 => item2.id),
                              },
                            },
                          });
                        } catch (e) {
                          if (e instanceof ApolloError) {
                            showAlert('error', e.message);
                          }
                        }
                      }
                    }
                  }}
                />
              ))}
            </TagsWrapper>
          )}
          <Flex direction="row" style={{gap: 8, position: 'relative'}}>
            <InputAvatar
              leftElement={
                value ? (
                  <Avatar size={40} url={customer?.avatar?.url} />
                ) : (
                  <Search color={colors.textTertiary} />
                )
              }
              search={search}
              setSearch={value => {
                setSearch(value);
                queryRefetch(value);
              }}
              focused={visible}
              label={
                value ? getName(customer?.name, customer?.surname) : undefined
              }
              placeholder="Поиск клиента"
              value={customer ? getPhoneMask(customer?.phone) : undefined}
              disabled={disabled}
              rightElement={
                variant === 'edit' ? (
                  <>
                    {customer && (
                      <IconButton
                        size={24}
                        variant="unstyled"
                        onClick={e => {
                          setSearch('');
                          dispatch(setRedux(null));
                          e.stopPropagation();
                        }}>
                        <Xv2 />
                      </IconButton>
                    )}
                    <IconButton
                      size={24}
                      variant="unstyled"
                      rotate={visible ? 180 : 0}>
                      <ArrowDown2v />
                    </IconButton>
                  </>
                ) : undefined
              }
              onClick={() => setVisible(true)}
            />
            {customer && permission && (
              <IconButton
                size={64}
                style={{backgroundColor: colors.fillPrimary, borderRadius: 18}}
                onClick={() => {
                  window.open(`/${username}/customer/${customer.id}`);
                }}>
                <NewPage />
              </IconButton>
            )}
            <Dropdown
              visible={visible}
              style={{
                top: 48,
                width: customer && permission ? 'calc(100% - 68px)' : '100%',
                maxHeight: '396px',
              }}
              withBackdrop={false}
              onClose={() => setVisible(false)}
              skeleton={loading ? <CustomerSkeleton /> : undefined}
              header={
                createPermission ? (
                  <Text
                    typography="text16Regular"
                    color="mainPrimary"
                    style={{
                      height: 40,
                      marginBottom: 8,
                      alignItems: 'center',
                      display: 'flex',
                      padding: '0 16px',
                    }}
                    onClick={() =>
                      navigate(`/${username}/add-customer`, {
                        state: {
                          from: 'bookings',
                        },
                      })
                    }>
                    + Создать клиента
                  </Text>
                ) : undefined
              }
              headerDivider={false}
              data={customers}
              listEmpty={
                <Flex
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  gap={16}>
                  <EmptyCustomers size={200} />
                  <Text color="textTertiary" align="center">
                    По вашему запросу ничего не найдено. <br /> Проверьте данные
                    и попробуйте еще раз
                  </Text>
                </Flex>
              }
              renderItem={item => (
                <CustomerItem
                  key={item.id}
                  name={getName(item.name, item.surname)}
                  phone={getPhoneMask(item.phone)}
                  birthdate={
                    item.birthdate
                      ? dayjs(item.birthdate).format('MM-DD') ===
                        dayjs(workingDate?.split(',')[0]).format('MM-DD')
                      : false
                  }
                  vip={item.is_vip}
                  id={item.id}
                  onSelect={value => {
                    dispatch(setRedux(value));
                    setVisible(false);
                  }}
                  selected={item.id === customerId}
                />
              )}
            />
          </Flex>
        </Flex>

        {customer?.phone && (
          <Flex
            direction="row"
            flex={1}
            justifyContent="space-between"
            gap={24}>
            <MessengerWrapper
              onClick={() => {
                window.open(
                  `tel:${getPhoneMask(customer?.phone)}`,
                  '_blank',
                  'noreferrer',
                );
              }}>
              <IconButton
                size={40}
                style={{
                  borderRadius: 20,
                  backgroundColor: colors.bgSecondary,
                  border: 'none',
                }}>
                <Phone />
              </IconButton>
              <Text typography="text16Regular" style={{cursor: 'pointer'}}>
                Вызов
              </Text>
            </MessengerWrapper>
            <MessengerWrapper
              onClick={() => {
                window.open(
                  `sms:${getPhoneMask(customer?.phone)}`,
                  '_blank',
                  'noreferrer',
                );
              }}>
              <IconButton
                size={40}
                style={{
                  borderRadius: 20,
                  backgroundColor: colors.bgSecondary,
                  border: 'none',
                }}>
                <Chat />
              </IconButton>
              <Text typography="text16Regular" style={{cursor: 'pointer'}}>
                Смс
              </Text>
            </MessengerWrapper>
            <MessengerWrapper
              onClick={() => {
                window.open(
                  `https://wa.me/${customer?.phone?.replace('+', '')}`,
                  '_blank',
                );
              }}>
              <IconButton
                size={40}
                style={{
                  borderRadius: 20,
                  backgroundColor: colors.bgSecondary,
                  border: 'none',
                }}>
                <Whatsapp />
              </IconButton>
              <Text typography="text16Regular" style={{cursor: 'pointer'}}>
                Whatsapp
              </Text>
            </MessengerWrapper>
            <MessengerWrapper
              onClick={() => {
                window.open(
                  `https://t.me/${getPhoneMask(customer?.phone)}`,
                  '_blank',
                );
              }}>
              <IconButton
                size={40}
                style={{
                  borderRadius: 20,
                  backgroundColor: colors.bgSecondary,
                  border: 'none',
                }}>
                <Telegram />
              </IconButton>
              <Text typography="text16Regular" style={{cursor: 'pointer'}}>
                Telegram
              </Text>
            </MessengerWrapper>
          </Flex>
        )}
        {customer && (
          <Flex direction="row" gap={8}>
            <Flex flex={1} direction="column" style={{padding: '9px 16px'}}>
              <Text typography="footNote12Regular" color="textTertiary">
                Посещений
              </Text>
              <Text>{customer?.total_visits ?? 0}</Text>
            </Flex>
            <Flex flex={1} direction="column" style={{padding: '9px 16px'}}>
              <Text typography="footNote12Regular" color="textTertiary">
                Отмененных
              </Text>
              <Text>{customer?.total_cancelled ?? 0}</Text>
            </Flex>
            <Flex flex={1} direction="column" style={{padding: '9px 16px'}}>
              <Text typography="footNote12Regular" color="textTertiary">
                Выручка
              </Text>
              <Text>{customer?.total_earned} ₽</Text>
            </Flex>
          </Flex>
        )}

        {comment && (
          <TextArea
            label="Комментарий клиента"
            value={comment ?? ''}
            disabled
          />
        )}
        <SelectTags
          visible={modalCreateTag}
          onClose={() => setModalCreateTag(false)}
          id={customer?.id}
        />
      </Flex>
    );
  }
};

const MessengerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  pointer-events: all;
  cursor: pointer;
`;

const CustomerWrapper = styled.button<{selected: boolean}>`
  display: flex;
  width: 100%;
  border: none;
  flex-direction: row;
  pointer-events: all;
  cursor: pointer;
  justify-content: space-between;
  padding: 10px 0 10px 16px;
  gap: 4px;
  background-color: ${({selected, theme}) =>
    selected ? theme.bgSecondary : 'transparent'};
  &:hover {
    background-color: ${({theme}) => theme.bgSecondary};
  }
`;

const CustomerItem = ({
  name,
  phone,
  birthdate,
  vip,
  onSelect,
  id,
  selected,
}: {
  name: string;
  phone: string;
  birthdate: boolean;
  vip: boolean;
  onSelect: (value: string) => void;
  id: string;
  selected: boolean;
}) => {
  const colors = useColors();
  return (
    <CustomerWrapper selected={selected} onClick={() => onSelect(id)}>
      <Flex direction="column">
        <Flex direction="row" gap={8}>
          <Text style={{cursor: 'pointer'}}>{name}</Text>
          {vip ? <Crown /> : null}
          {birthdate ? <Confetti /> : null}
        </Flex>
        <Text
          typography="subHead14Regular"
          color="textTertiary"
          style={{cursor: 'pointer'}}>
          {phone}
        </Text>
      </Flex>
      {selected && (
        <ActionIcon
          icon={<Check color={colors.mainPrimary} />}
          style={{
            width: 44,
            height: 44,
            borderRadius: 0,
            border: 'none',
            backgroundColor: 'transparent',
          }}
        />
      )}
    </CustomerWrapper>
  );
};

const CustomerSkeleton = () => {
  return (
    <Skeleton height={404}>
      {Array.from(Array(5).keys()).map((_, index) => (
        <React.Fragment key={'customers_sk_' + index}>
          <rect
            width={240}
            height={24}
            rx={8}
            ry={8}
            x={16}
            y={10 + 68 * index}
          />
          <rect
            width={100}
            height={20}
            rx={8}
            ry={8}
            x={16}
            y={38 + 68 * index}
          />
        </React.Fragment>
      ))}
    </Skeleton>
  );
};
