import React, {useEffect, useState} from 'react';
import {device} from 'shared/device';
import styled from 'styled-components';
import {Text} from './Text';
import {typography as typographyFont} from 'shared/typography';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  background-color: ${({theme}) => theme.fillPrimary};
  height: 50px;
  padding: 4px;
  border-radius: 12px;
  @media ${device.mobile} {
    height: 46px;
  }
`;

const Tab = styled.button`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  pointer-events: all;
  cursor: pointer;
  z-index: 10;
  height: 50px;
  @media ${device.mobile} {
    height: 46px;
  }
`;

const ActiveTab = styled.div<{length: number; index: number}>`
  background-color: ${({theme}) => theme.bgPrimary};
  width: calc((100% - 8px) / ${({length}) => length});
  height: 40px;
  border-radius: 12px;
  position: absolute;
  transform: translateX(calc(100% * ${({index}) => index}));
  transition: transform 0.2s;
`;

export const SegmentedControl = ({
  tabs,
  index: startIndex,
  typography = 'text16Semibold',
}: {
  tabs: {name: string; onClick?: () => void}[];
  index?: number;
  typography?: keyof typeof typographyFont;
}) => {
  const [index, setIndex] = useState(startIndex);

  useEffect(() => {
    if (startIndex !== undefined) {
      setIndex(startIndex);
    }
  }, [startIndex]);

  return (
    <Wrapper>
      <ActiveTab length={tabs.length} index={index!} />
      {tabs.map((tab, indexTab) => (
        <Tab
          key={'tab_' + indexTab}
          onClick={() => {
            setIndex(indexTab);
            tab.onClick && tab.onClick();
          }}>
          <Text
            typography={typography}
            color={index === indexTab ? 'mainPrimary' : 'textPrimary'}
            style={{pointerEvents: 'none'}}>
            {tab.name}
          </Text>
        </Tab>
      ))}
    </Wrapper>
  );
};
