import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {logoutThunk} from './authSlice';

interface CompanyStateInterface {
  data: {
    id: string;
    employeeId: string;
    username?: string | null;
  } | null;
}

const initialState: CompanyStateInterface = {
  data: null,
};

const companyService = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setCompanyData: (
      state,
      action: PayloadAction<{
        id: string;
        employeeId: string;
        username?: string | null;
      } | null>,
    ) => {
      state.data = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(logoutThunk.fulfilled, state => {
      state.data = null;
    });
  },
});

export const {setCompanyData} = companyService.actions;
export default companyService.reducer;
