import React from 'react';
const User = ({
  size = 24,
  color = '#6765F8',
}: {
  size?: number;
  color?: string;
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM19 18c0 2.21-3.134 4-7 4s-7-1.79-7-4 3.134-4 7-4 7 1.79 7 4z"
        stroke={color}
        strokeWidth={1.5}
      />
    </svg>
  );
};

export default User;
