import {useQuery} from '@apollo/client';
import React, {useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {gql} from 'shared/__generated__';
import {QrCodeLinkType} from 'shared/__generated__/graphql';
import {Download} from 'shared/icons/Download';
import {Loader} from 'shared/icons/Loader';
import {useColors} from 'shared/lib/hooks';
import {useAppSelector} from 'shared/store';
import {Button} from 'shared/ui/Button';
import {Content} from 'shared/ui/Content';
import {Divider} from 'shared/ui/Divider';
import {Flex} from 'shared/ui/Flex';
import {Head} from 'shared/ui/Head';
import {Image} from 'shared/ui/Image';
import {Layout} from 'shared/ui/Layout';
import {Select} from 'shared/ui/Select';
import {Switch} from 'shared/ui/Switch';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';
import {ColorCell} from './ui/ColorCell';
import {Header} from './ui/Header';
import {NavigateBack} from 'shared/ui/NavigateBack';
import {Support} from 'shared/icons/Support';
import {Seo} from 'shared/ui/Seo';

interface IQRCode {
  selectSource: QrCodeLinkType;
  usingYourLogo: boolean;
  allowSettingColor: boolean;
  backgroundColor: string;
  textColor: string;
}

const QRCODE_LINK_DATA: Array<{
  value: QrCodeLinkType;
  label: string;
}> = [
  {value: QrCodeLinkType.Site, label: 'На ваш веб-сайт'},
  {value: QrCodeLinkType.Widget, label: 'На онлайн-запись (Виджет)'},
];

const APP = gql(`
  query GetAppQrCode($id: String!, $companyId: String!, $input: GetQRCode!) {
    getQRCode(input: $input)
    getAppById(id: $id, company_id: $companyId) {
      id
      key
      name
      image {
        id
        url
      }
      description
      app_url(company_id: $companyId)
      darkImage {
        id
        url
      }
      is_connectable
      is_connected(company_id: $companyId)
      is_free
      is_out
      is_recommended
      short_description
      instruction
      instruction_link
    }
  }
`);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-column-start: 4;
  grid-column-end: 10;
  padding: 64px 0;
  gap: 8px;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const Row = styled.div`
  display: flex;
  height: 48px;
  justify-content: space-between;
  align-items: center;
`;
const Img = styled.div`
  border-radius: 15px;
  display: flex;
  width: 180px;
  height: 180px;
  justify-content: center;
  align-items: center;
`;

export const QrCodeApp = () => {
  const navigate = useNavigate();
  const params = useParams();
  const title = useLocation().state;
  const colors = useColors();
  const companyId = useAppSelector(state => state.company.data!.id);
  const id = params.id!;
  const [qrCode, setQrCode] = useState<IQRCode>({
    selectSource: QrCodeLinkType.Site,
    usingYourLogo: false,
    allowSettingColor: true,
    backgroundColor: colors.fillPrimary,
    textColor: colors.textPrimary,
  });
  const {data, loading} = useQuery(APP, {
    variables: {
      companyId,
      id,
      input: {
        company_id: companyId,
        logo_enabled: qrCode.usingYourLogo,
        backgroundColor: qrCode.allowSettingColor
          ? qrCode.backgroundColor
          : colors.fillPrimary,
        foregroundColor: qrCode.allowSettingColor
          ? qrCode.textColor
          : colors.textPrimary,
        LinkType: qrCode.selectSource,
      },
    },
  });
  const app = data?.getAppById;
  const qrSource = `data:image/png;base64,${data?.getQRCode}`;

  function handleTextChange(
    text: string,
    field: 'backgroundColor' | 'textColor',
  ) {
    const cleanedText = text.replace(/[^0-9a-fA-F]/g, '');
    const formattedText = cleanedText.startsWith('#')
      ? cleanedText
      : `#${cleanedText}`;
    setQrCode(prev => ({
      ...prev,
      [field]: formattedText,
    }));
  }

  return (
    <Layout columns={12}>
      <Seo title={title} />
      <Wrapper>
        <Head>
          <NavigateBack />
          <Text typography="title24">{title}</Text>
        </Head>
        <Content gap="24px">
          <Header app={app} loading={loading} />
          <Divider />
          <Select
            label="Ссылка в QR-коде"
            data={QRCODE_LINK_DATA}
            value={qrCode.selectSource}
            onChange={value =>
              setQrCode(prev => ({
                ...prev,
                selectSource: value,
              }))
            }
          />
          <Row>
            <Text>Добавить ваш логотип</Text>
            <Switch
              value={qrCode.usingYourLogo}
              onChange={value => {
                setQrCode(prev => ({
                  ...prev,
                  usingYourLogo: value,
                }));
              }}
            />
          </Row>
          <Flex gap={16} direction="column">
            <Row>
              <Text>Настроить цвета</Text>
              <Switch
                value={qrCode.allowSettingColor}
                onChange={value => {
                  setQrCode(prev => ({
                    ...prev,
                    allowSettingColor: value,
                  }));
                }}
              />
            </Row>
            {qrCode.allowSettingColor && (
              <Flex gap={8} direction="column">
                <ColorCell
                  label="Цвет фона подсказки"
                  value={qrCode.backgroundColor}
                  onChange={text => handleTextChange(text, 'backgroundColor')}
                />
                <ColorCell
                  label="Цвет текста"
                  value={qrCode.textColor}
                  onChange={text => handleTextChange(text, 'textColor')}
                />
              </Flex>
            )}
          </Flex>
          <Button
            size="medium"
            variant="outline"
            rightIcon={<Download size={20} />}
            onClick={() => {
              const downloadLink = document.createElement('a');
              downloadLink.href = qrSource;
              downloadLink.download = 'downloaded_image.jpg';
              document.body.appendChild(downloadLink);
              downloadLink.click();
              document.body.removeChild(downloadLink);
            }}>
            Скачать QR-код
          </Button>
          <Flex gap={16} direction="column">
            <Text typography="text16Semibold">Ваш QR-код</Text>
            <Flex style={{width: '100%'}} justifyContent="center">
              {loading ? (
                <Img
                  style={{
                    backgroundColor:
                      qrCode.backgroundColor ?? colors.fillPrimary,
                  }}>
                  <Loader size={40} />
                </Img>
              ) : (
                <Image src={qrSource} height={180} width={180} radius={15} />
              )}
            </Flex>
          </Flex>
          {app?.description ? (
            <Flex gap={16} direction="column">
              <Text typography="text16Semibold">Описание</Text>
              <Text color="textSecondary">{app?.description}</Text>
            </Flex>
          ) : null}
          <Button
            variant="outline"
            size="large"
            style={{
              color: colors.textPrimary,
              backgroundColor: colors.fillPrimary,
              border: 'none',
            }}
            onClick={() => navigate('/support')}
            leftIcon={<Support color={colors.textPrimary} />}>
            Написать в чат
          </Button>
        </Content>
      </Wrapper>
    </Layout>
  );
};
