import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {createAsyncThunk} from '@reduxjs/toolkit';
import {client} from 'shared/config/apollo';

interface AuthStateInterface {
  token: string | null;
  userId: string | null;
  tutorialVisible: boolean;
}

const initialState: AuthStateInterface = {
  token: null,
  userId: null,
  tutorialVisible: false,
};

export const logoutThunk = createAsyncThunk('auth/logout', async () => {
  await client.resetStore();
  localStorage.clear();
  window.location.reload();
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUserId: (state, action: PayloadAction<string | null>) => {
      state.userId = action.payload;
    },
    setToken: (state, action: PayloadAction<string | null>) => {
      state.token = action.payload;
    },
    setTutorialVisible: (state, action) => {
      state.tutorialVisible = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(logoutThunk.fulfilled, state => {
      state.userId = null;
      state.token = null;
    });
  },
});

export const {setUserId, setTutorialVisible, setToken} = authSlice.actions;

export default authSlice.reducer;
