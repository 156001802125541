import React, {useState} from 'react';
import {City, CompanyAccountInfo} from 'shared/__generated__/graphql';
import {Pencil} from 'shared/icons/Pencil';
import {ActionIcon} from 'shared/ui/ActionIcon';
import {Flex} from 'shared/ui/Flex';
import {Popup} from 'shared/ui/Popup';
import {Text} from 'shared/ui/Text';
import {TextInput} from 'shared/ui/TextInput';
import styled from 'styled-components';
import {TextArea} from 'shared/ui/TextArea';
import {Button} from 'shared/ui/Button';
import {ApolloError, useMutation} from '@apollo/client';
import {showAlert} from 'shared/ui/Alert';
import {useAppSelector} from 'shared/store';
import {gql} from 'shared/__generated__';
import {CitiesSelect} from 'entities/city';
import {MY_TARIFF, PAYMENT_TARIFFS} from '..';
import {CloseButton} from 'shared/ui/CloseButton';
import {useColors} from 'shared/lib/hooks';

const UPDATE_COMPANY_INFO = gql(`
  mutation UpdateCompanyInfo($companyId: String!, $input: UpdateCompanyAccountInfo) {
    changeCompanyAccountInfo(company_id: $companyId, input: $input) {
      id
    }
  }
`);

type CityType = Pick<City, 'id' | 'name'> | null;
export type CompanyInformation = Pick<
  CompanyAccountInfo,
  | 'office'
  | 'post_index'
  | 'company_name'
  | 'address'
  | '__typename'
  | 'comment'
  | 'name'
  | 'email'
> & {city?: CityType};
const Inputs = styled.div`
  display: flex;
  gap: 8px;
`;

const Info = styled.div`
  display: flex;
  width: 100%;
  border-radius: 20px;
  padding: 16px;
  border: 1px solid ${({theme}) => theme.borderPrimary};
  background-color: ${({theme}) => theme.fillPrimary};
  align-items: center;
`;

export const CompanyInfo = ({
  info,
}: {
  info: CompanyInformation | null | undefined;
}) => {
  const companyId = useAppSelector(state => state.company.data!.id);
  const colors = useColors();
  const [visible, setVisible] = useState(false);
  const [fullName, setFullName] = useState(info?.name ?? '');
  const [companyName, setCompanyName] = useState(info?.company_name ?? '');
  const [email, setEmail] = useState(info?.email ?? '');
  const [street, setStreet] = useState(info?.address ?? '');
  const [office, setOffice] = useState(info?.office ?? '');
  const [index, setIndex] = useState(info?.post_index?.toString() ?? '');
  const [city, setCity] = useState(info?.city?.id ?? '');
  const [description, setDescription] = useState(info?.comment ?? '');

  const [errorMail, setErrorMail] = useState<string | null>(null);
  const [errorName, setErrorName] = useState<string | null>(null);

  const [updateCompanyInfo, {loading: updateLoading}] = useMutation(
    UPDATE_COMPANY_INFO,
    {
      refetchQueries: [
        {
          query: MY_TARIFF,
          variables: {companyId, first: 5},
        },
        {
          query: PAYMENT_TARIFFS,
          variables: {companyId},
        },
      ],
    },
  );
  if (!info || !info.company_name) return null;
  const value = [street, index, office, info?.city?.name]
    .filter(item => item !== '')
    .join(', ');

  const onPressUpdate = () => {
    const variables = {
      companyId,
      input: {
        address: street,
        city_id: city,
        comment: description,
        company_name: companyName,
        email,
        name: fullName,
        office,
        post_index: +index,
      },
    };
    updateCompanyInfo({variables})
      .then(() => {
        showAlert('success', 'Платежная информация обновлена');
        setVisible(false);
      })
      .catch((e: ApolloError) => showAlert('error', e.message));
  };

  return (
    <>
      <Info>
        <Flex flex={1} gap={8} direction="column">
          <Text typography="text16Semibold">{companyName}</Text>
          <Text>{value}</Text>
        </Flex>
        <ActionIcon
          style={{height: 48, width: 48, color: colors.bgPrimary}}
          icon={<Pencil />}
          onClick={() => setVisible(true)}
        />
      </Info>
      <Popup
        visible={visible}
        style={{minWidth: 658, alignItems: 'initial'}}
        onClose={() => {
          setVisible(false);
        }}>
        <Flex direction="column" gap={24}>
          <Flex justifyContent="space-between" alignItems="center">
            <Text typography="title20">Информация для выставления счета</Text>
            <CloseButton onClose={() => setVisible(false)} />
          </Flex>
          <Flex direction="column" gap={8}>
            <TextInput
              label={'ФИО (Будет напечатано на счетах)'}
              value={fullName}
              onChange={setFullName}
            />
            <TextInput
              label={'Название организации'}
              value={companyName}
              onChange={value => {
                if (errorName) setErrorName(null);
                setCompanyName(value);
              }}
              error={errorName}
            />
            <TextInput
              label={'Email (Отправим копии счетов)'}
              value={email}
              error={errorMail}
              onChange={value => {
                if (errorMail) setErrorMail(null);
                setEmail(value);
              }}
            />
          </Flex>
          <Flex direction="column" gap={8}>
            <Text mb={8} color="textSecondary">
              Адрес для выставления счета
            </Text>
            <Inputs>
              <TextInput
                flex={1}
                label="Улица и дом"
                onChange={setStreet}
                value={street}
              />
              <TextInput
                flex={1}
                label="Офис"
                mask="9999"
                onChange={setOffice}
                value={office}
              />
            </Inputs>
            <Inputs>
              <TextInput
                flex={1}
                label="Индекс"
                mask="999999"
                onChange={setIndex}
                value={index}
              />
              <CitiesSelect
                style={{width: 'calc(50% - 4px)'}}
                value={city}
                onChange={setCity}
              />
            </Inputs>
          </Flex>
          <TextArea
            size="medium"
            value={description}
            onChange={setDescription}
            placeholder="Дополнительная информация"
          />
          <Inputs>
            <Button
              style={{flex: 1}}
              size="large"
              variant="outline"
              onClick={() => setVisible(false)}>
              Отмена
            </Button>
            <Button
              style={{flex: 1}}
              loading={updateLoading}
              size="large"
              onClick={onPressUpdate}>
              Сохранить
            </Button>
          </Inputs>
        </Flex>
      </Popup>
    </>
  );
};
