import React, {useEffect, useState} from 'react';
import {useLazyQuery} from '@apollo/client';
import {gql} from 'shared/__generated__';
import {Search} from 'shared/icons/Search';
import {Button} from 'shared/ui/Button';
import {Skeleton} from 'shared/ui/Skeleton';
import {Text} from 'shared/ui/Text';
import {TextInput} from 'shared/ui/TextInput';
import styled from 'styled-components';
import {Flex} from 'shared/ui/Flex';
import {EmptyEmployees} from 'shared/illustration/EmptyEmployees';
import {CheckBox} from 'shared/ui/CheckBox';
import {List} from 'shared/ui/List';
import {CloseButton} from 'shared/ui/CloseButton';
import {useColors} from 'shared/lib/hooks';

const Wrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${({theme}) => theme.bgPrimary};
  width: 588px;
  height: max-content;
  border-radius: 14px;
  padding: 24px;
  z-index: 100;
  gap: 24px;
  ${({theme}) => theme.cardShadow}
`;

const Head = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`;

const FilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const Backdrop = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  background-color: ${({theme}) => theme.modalOverlay};
  opacity: 0.5;
  bottom: 0;
  z-index: 99;
  pointer-events: all;
`;

const PROFESSIONS = gql(`
  query Professions($first: Int!, $name: String, $page: Int) {
    professions(first: $first, name: $name, page: $page) {
      paginatorInfo {
        hasMorePages
        currentPage
        total
        count
      }
      data {
        id
        name
      }
    }
  }
`);

type Props = {
  label?: string;
  visible: boolean;
  onChange: (values: {label: string; value: string}[]) => void;
  onClose: () => void;
  values: {label: string; value: string}[];
};

export const SelectProfessions = ({
  label,
  values,
  visible,
  onChange,
  onClose,
}: Props) => {
  const colors = useColors();
  const [search, setSearch] = useState('');
  const [selected, setSelected] = useState<{id: string; name: string}[]>([]);
  const [lazy, {data, loading}] = useLazyQuery(PROFESSIONS, {
    variables: {
      page: 1,
      first: 100,
      name: search,
    },
    onCompleted: ({professions}) => {
      const temp: {id: string; name: string}[] = [];
      professions?.data.map(item => {
        if (values.map(value => value.value).includes(item.id)) {
          temp.push({id: item.id, name: item.name});
        }
      });
      if (search.length === 0) {
        setSelected(temp);
      }
    },
  });

  useEffect(() => {
    visible && lazy();
  }, [lazy, visible]);

  const professions = data?.professions?.data ?? [];

  return (
    <>
      <Backdrop onClick={() => onClose()} />
      <Wrapper onClick={e => e.stopPropagation()}>
        <Head>
          <Text typography="title20" color="textPrimary">
            {label}
          </Text>
          <CloseButton
            onClose={() => {
              onClose();
            }}
          />
        </Head>
        <FilterWrapper>
          <TextInput
            width="540px"
            height={54}
            variant="default"
            placeholder="Поиск по специализации"
            value={search}
            onChange={setSearch}
            leftElement={<Search color={colors.textTertiary} />}
          />
        </FilterWrapper>
        {loading ? (
          <EmployeeSkeleton />
        ) : professions.length > 0 ? (
          <>
            <Flex
              direction="column"
              flex={1}
              justifyContent="space-between"
              style={{maxHeight: 412, overflowY: 'scroll'}}>
              <List
                gap={24}
                keyExtractor={item => `employee_${item.id}`}
                data={professions}
                renderItem={item => {
                  const checked = selected
                    .map(item2 => item2.id)
                    .includes(item.id);
                  const onClick = () => {
                    if (checked) {
                      setSelected(prev =>
                        prev.filter(prevItem => prevItem.id !== item.id),
                      );
                    } else {
                      setSelected(prev => [
                        ...prev,
                        {id: item.id, name: item.name},
                      ]);
                    }
                  };
                  return (
                    <CheckBox
                      style={{
                        flexDirection: 'row-reverse',
                        gap: 8,
                        justifyContent: 'flex-end',
                      }}
                      title={item.name}
                      checked={checked}
                      onChange={onClick}
                    />
                  );
                }}
              />
            </Flex>
            <Footer>
              <Button
                style={{width: 180}}
                size="large"
                onClick={() => {
                  const temp = selected.map(item2 => ({
                    label: item2.name,
                    value: item2.id,
                  }));
                  onChange(temp);
                }}>
                Сохранить
              </Button>
              <Button
                style={{width: 180}}
                size="large"
                variant="light"
                onClick={() => {
                  onClose();
                }}>
                Отмена
              </Button>
              <Flex style={{width: 148, justifyContent: 'center'}}>
                {selected.length > 0 && (
                  <Text typography="text16Semibold" color="mainPrimary">
                    Выбрано {selected.length}
                  </Text>
                )}
              </Flex>
            </Footer>
          </>
        ) : (
          <EmptyState search={search} />
        )}
      </Wrapper>
    </>
  );
};

const EmployeeSkeleton = () => {
  return (
    <Skeleton height={360} style={{marginTop: 8}}>
      {Array(8)
        .fill(0)
        .map((_, index) => (
          <React.Fragment key={'service_skeleton_' + index}>
            <rect width={24} height={24} rx={8} ry={8} x={0} y={64 * index} />
            <rect
              width={240}
              height={24}
              rx={12}
              ry={12}
              x={32}
              y={64 * index}
            />
          </React.Fragment>
        ))}
    </Skeleton>
  );
};

const EmptyState = ({search}: {search: string}) => {
  return (
    <Flex
      flex={1}
      direction="column"
      justifyContent="center"
      gap={16}
      style={{paddingBottom: 80, paddingTop: 48}}
      alignItems="center">
      <EmptyEmployees size={200} />
      {search.length === 0 ? (
        <Text
          typography="text16Regular"
          color="textTertiary"
          style={{textAlign: 'center'}}>
          Нет специализаций
        </Text>
      ) : (
        <Text
          typography="text16Regular"
          color="textTertiary"
          style={{textAlign: 'center'}}>
          Специализация не найден
        </Text>
      )}
    </Flex>
  );
};
