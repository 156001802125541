import React from 'react';
import {desktopMediaQuery} from 'shared/lib/utils';
import styled from 'styled-components';
import {tabs} from './router';
import {Text} from 'shared/ui/Text';
import {Flex} from 'shared/ui/Flex';
import {useLocation, useNavigate} from 'react-router-dom';

const Wrapper = styled.div`
  ${desktopMediaQuery} {
    display: none;
  }
  border-radius: 24px 24px 0px 0px;
  height: 64px;
  background-color: ${props => props.theme.bgPrimary};
  width: 100%;
  position: fixed;
  bottom: 0;
  box-shadow: 0px -21px 18px 0px rgba(143, 154, 174, 0.04);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px 0 16px;
  z-index: 1000;
`;

const ItemWrapper = styled.div<{active?: boolean}>`
  width: 60px;
  height: 40px;
  display: flex;
  flex-direction: column;
  pointer-events: all;
  color: ${props =>
    props.active ? props.theme.mainPrimary : props.theme.textTertiary};
`;

const Item = ({
  title,
  icon,
  onClick,
  active,
}: {
  title: string;
  icon?: React.JSX.Element;
  onClick?: () => void;
  active?: boolean;
}) => {
  return (
    <ItemWrapper active={active} onClick={onClick}>
      <Flex justifyContent="center" flex={1}>
        {icon}
      </Flex>
      <Text
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          textAlign: 'center',
        }}
        typography="tab10">
        {title}
      </Text>
    </ItemWrapper>
  );
};

export const Tabbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <Wrapper>
      {tabs.map(tab => {
        if (tab.type === 'group') {
          return null;
        }
        if (tab.place === 'sidebar') {
          return null;
        }
        if (tab.place === 'sidebar_bottom') {
          return null;
        }
        if (tab.place === 'unplaced') {
          return null;
        }
        const active = location.pathname.endsWith(tab.path);
        return (
          <Item
            active={active}
            key={tab.title}
            onClick={() => {
              navigate(tab.path);
            }}
            {...tab}
          />
        );
      })}
    </Wrapper>
  );
};
