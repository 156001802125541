import styled from 'styled-components';

export const GridColor = styled.div`
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  gap: 24px;
`;

export const ColorView = styled.button<{color: string}>`
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  border: none;
  pointer-events: all;
  cursor: pointer;
  background-color: ${({color}) => `rgb(${color})`};
  display: flex;
  align-items: center;
  justify-content: center;
`;
