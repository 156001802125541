import {useQuery} from '@apollo/client';
import {BOOKING, ModalDeleteBooking} from 'entities/booking';
import React, {useState} from 'react';
import {Outlet, useLocation, useNavigate, useParams} from 'react-router-dom';
import {MessageStatus} from 'shared/__generated__/graphql';
import {device} from 'shared/device';
import {Bill} from 'shared/icons/Bill';
import {Clipboard} from 'shared/icons/Clipboard';
import {Dialog} from 'shared/icons/Dialog';
import {Pencil} from 'shared/icons/Pencil';
import {Photo} from 'shared/icons/Photo';
import {Trash} from 'shared/icons/Trash';
import {useColors, useEmployeePermission, useResize} from 'shared/lib/hooks';
import {setBookingScreen, useAppDispatch, useAppSelector} from 'shared/store';
import {Content} from 'shared/ui/Content';
import {Flex} from 'shared/ui/Flex';
import {Head} from 'shared/ui/Head';
import {Layout} from 'shared/ui/Layout';
import {Menu} from 'shared/ui/Menu';
import {MenuAction} from 'shared/ui/MenuAction';
import {NavigateBack} from 'shared/ui/NavigateBack';
import {Skeleton} from 'shared/ui/Skeleton';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';

const Container = styled.div`
  grid-column-start: 1;
  grid-column-end: 4;
  margin: 136px 0 0 0;
  @media ${device.mobile} {
    margin: 72px 0 0 0;
  }
`;

const ContainerIcon = styled.div`
  height: 24px;
  position: relative;
`;

const NotificationBooking = styled.div`
  position: absolute;
  display: flex;
  right: -8px;
  top: -8px;
  height: 18px;
  width: 18px;
  border-radius: 9px;
  background-color: ${({theme}) => theme.dangerPrimary};
  color: ${({theme}) => theme.white};
  justify-content: center;
  align-items: center;
  line-height: 18px;
  font-family: 'Inter';
  font-size: 12px;
  font-weight: 500;
`;

const Wrapper = styled.div`
  grid-column-start: 4;
  grid-column-end: 10;
  padding: 64px 0 0 0;
  @media ${device.mobile} {
    padding: 16px 0 0 0;
  }
`;

export const AboutBookingRoot = () => {
  const colors = useColors();
  const params = useParams();
  const navigate = useNavigate();
  const username = useAppSelector(state => state.company.data?.username);
  const {id} = params;
  const {isMobile} = useResize();
  const dispatch = useAppDispatch();
  const companyId = useAppSelector(state => state.company.data?.id);
  const [modal, setModal] = useState(false);
  const {data, loading} = useQuery(BOOKING, {
    variables: {
      bookingId: id!,
      companyId: companyId!,
    },
  });
  const {edit_bookings_permission} = useEmployeePermission(data);
  const messagesPending =
    data?.booking?.messages?.filter(
      message => message.status === MessageStatus.Pending,
    ).length ?? 0;
  const pathname = useLocation().pathname;
  return (
    <Layout columns={12}>
      <Container>
        {loading ? (
          <SkeletonMenu />
        ) : (
          <>
            <Menu
              style={{
                marginBottom: 16,
                padding: '4px 24px 28px 24px',
              }}
              data={[
                {
                  icon: <Clipboard />,
                  label: 'Детали записи',
                  to: `/${username}/booking/${id}`,
                },
                {
                  icon: <Bill />,
                  label: 'Оплата и чеки',
                  to: `/${username}/booking/${id}/payment`,
                },
                {
                  icon: <Photo />,
                  label: 'Фото работ',
                  to: `/${username}/booking/${id}/photos`,
                },
                {
                  icon: <IconMessage count={messagesPending} />,
                  label: 'Рассылки',
                  to: `/${username}/booking/${id}/messages`,
                },
              ]}
            />
            {edit_bookings_permission && (
              <MenuAction
                style={{marginTop: 16}}
                data={[
                  {
                    icon: <Pencil />,
                    label: 'Редактировать',
                    onClick: () => {
                      dispatch(setBookingScreen('edit'));
                      navigate(`/${username}/booking/${id}/edit`);
                    },
                  },
                  {
                    icon: <Trash color={colors.dangerPrimary} />,
                    label: 'Удалить запись',
                    onClick: () => {
                      setModal(true);
                    },
                  },
                ]}
              />
            )}
          </>
        )}

        <ModalDeleteBooking
          visible={modal}
          onClose={() => setModal(false)}
          id={id!}
        />
      </Container>
      <Wrapper
        style={{
          gridColumnEnd: pathname.includes('messages') ? 13 : 10,
        }}>
        <Head style={{marginBottom: 32}}>
          <NavigateBack />
          <Text typography={isMobile ? 'title20' : 'title24'}>
            Подробнее о записи
          </Text>
        </Head>
        <Outlet />
      </Wrapper>
    </Layout>
  );
};

const IconMessage = ({count}: {count: number}) => {
  return (
    <ContainerIcon>
      <Dialog />
      {count > 0 ? <NotificationBooking>{count}</NotificationBooking> : null}
    </ContainerIcon>
  );
};

const SkeletonMenu = () => {
  return (
    <Flex direction="column" gap={16}>
      <Content>
        <Skeleton width={'100%'} height={200}>
          <rect width={'100%'} height={56} rx={12} ry={12} />
          <rect y={72} width={'100%'} height={56} rx={12} ry={12} />
          <rect y={144} width={'100%'} height={56} rx={12} ry={12} />
        </Skeleton>
      </Content>
      <Content>
        <Skeleton height={145}>
          <rect width={'100%'} height={56} rx={12} ry={12} />
          <rect width={'100%'} y={72} height={1} />
          <rect width={'100%'} y={89} height={56} rx={12} ry={12} />
        </Skeleton>
      </Content>
    </Flex>
  );
};
