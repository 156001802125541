import React, {useState} from 'react';
import {TextInput} from 'shared/ui/TextInput';
import {Content} from 'shared/ui/Content';
import {Text} from 'shared/ui/Text';
import {Select} from 'shared/ui/Select';
import {Button} from 'shared/ui/Button';
import {gql} from 'shared/__generated__';
import {useMutation, useQuery} from '@apollo/client';
import {Loading} from 'shared/ui/Loading';
import {Error} from 'shared/ui/Error';
import {ensureError} from 'shared/lib/utils';
import {setCompanyData, useAppDispatch} from 'shared/store';
import {useLocation, useNavigate} from 'react-router-dom';
import {Flex} from 'shared/ui/Flex';
import {NavLink} from 'shared/ui/NavLink';
import {useColors} from 'shared/lib/hooks';
import {CitiesSelect} from 'entities/city';
import {WorkingDay, WorkingDaysDays} from 'shared/__generated__/graphql';
import {getAbbreviatedSchedule} from 'entities/schedule';
import {showAlert} from 'shared/ui/Alert';
import {NavigateBack} from 'shared/ui/NavigateBack';
import {Seo} from 'shared/ui/Seo';
import {useCookies} from 'react-cookie';

const COMPANY_DATA = gql(`
  query GetType {
    companySpecialization {
      id
      title
      types {
        id
        title
      }
    }
    cities(first: 100) {
      data {
        id
        name
        timezone
      }
    }
  }
`);

const CREATE_COMPANY = gql(`
  mutation CreateCompany($input: CreateCompany!) {
    createCompany(input: $input) {
      id
      name
      username
      employees(first: 1) {
        data {
          id
        }
      }
    }
  }
`);
type WorkingDayType = Omit<WorkingDay, 'id' | '__typename' | 'company'>;
interface RouteState {
  updatedWeek?: WorkingDayType[];
  name?: string;
  specialization?: string | undefined;
  type?: string | undefined;
  city?: string | undefined;
  address?: string;
}

const initialWeekDays: WorkingDayType[] = [
  {
    day: WorkingDaysDays.Monday,
    start_time: '09:00',
    end_time: '18:00',
    break_start_time: null,
    break_end_time: null,
    dayoff: false,
  },
  {
    day: WorkingDaysDays.Tuesday,
    start_time: '09:00',
    end_time: '18:00',
    break_start_time: null,
    break_end_time: null,
    dayoff: false,
  },
  {
    day: WorkingDaysDays.Wednesday,
    start_time: '09:00',
    end_time: '18:00',
    break_start_time: null,
    break_end_time: null,
    dayoff: false,
  },
  {
    day: WorkingDaysDays.Thursday,
    start_time: '09:00',
    end_time: '18:00',
    break_start_time: null,
    break_end_time: null,
    dayoff: false,
  },
  {
    day: WorkingDaysDays.Friday,
    start_time: '09:00',
    end_time: '18:00',
    break_start_time: null,
    break_end_time: null,
    dayoff: false,
  },
  {
    day: WorkingDaysDays.Saturday,
    start_time: '09:00',
    end_time: '18:00',
    break_start_time: null,
    break_end_time: null,
    dayoff: true,
  },
  {
    day: WorkingDaysDays.Sunday,
    start_time: '09:00',
    end_time: '18:00',
    break_start_time: null,
    break_end_time: null,
    dayoff: true,
  },
];

export function CreateCompany() {
  const navigate = useNavigate();
  const location = useLocation();
  const [cookies] = useCookies([
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_content',
    'utm_term',
  ]);
  const cookieValue = {
    utm_source: cookies.utm_source,
    utm_medium: cookies.utm_medium,
    utm_campaign: cookies.utm_campaign,
    utm_content: cookies.utm_content,
    utm_term: cookies.utm_term,
  };
  const state = location.state as RouteState;
  const week = state?.updatedWeek ?? initialWeekDays;
  const colors = useColors();
  const {loading, error, data} = useQuery(COMPANY_DATA);
  const [specializationId, setSpecializationId] = useState<string | undefined>(
    state?.specialization ?? undefined,
  );
  const [createCompany, {loading: uploading}] = useMutation(CREATE_COMPANY);
  const [typeId, setTypeId] = useState<string | undefined>(
    state?.type ?? undefined,
  );
  const [cityId, setCityId] = useState<string | undefined>(
    state?.city ?? undefined,
  );
  const [address, setAdress] = useState(state?.address ?? '');
  const [name, setName] = useState(state?.name ?? '');
  const [errorName, setErrorName] = useState<string | null>(null);
  const [errorSphere, setErrorSphere] = useState<string | null>(null);
  const [errorType, setErrorType] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  const types = data?.companySpecialization?.find(
    spec => spec.id === specializationId,
  )?.types;

  if (error) {
    return <Error message={error.message} />;
  }
  if (loading) {
    return <Loading />;
  }
  return (
    <Flex direction="column" gap={24}>
      <Seo title="Создние организации" />
      <Flex alignItems="center" gap={16}>
        <NavigateBack />
        <Text typography="title24">Создание организации</Text>
      </Flex>
      <Content>
        <Text typography="title18">Основная информация</Text>
        <TextInput
          value={name}
          onChange={text => {
            setName(text);
            setErrorName(null);
          }}
          required
          error={errorName}
          label="Название организации"
          maxLength={30}
        />
        <Select
          required
          error={errorSphere}
          data={data?.companySpecialization?.map(e => ({
            label: e.title,
            value: e.id,
          }))}
          value={specializationId}
          onChange={selected => {
            setSpecializationId(selected);
            setErrorSphere(null);
            setTypeId(undefined);
          }}
          label="Сфера организации"
        />
        {specializationId && (
          <Select
            required
            error={errorType}
            data={types?.map(type => ({label: type.title, value: type.id}))}
            label="Тип организации"
            value={typeId}
            onChange={selected => {
              setTypeId(selected);
              setErrorType(null);
            }}
          />
        )}
      </Content>
      <Content>
        <Text typography="title18">Адрес</Text>
        <CitiesSelect
          value={cityId}
          onChange={selected => {
            setCityId(selected);
          }}
        />
        <TextInput
          label="Улица и дом"
          value={address}
          onChange={text => {
            setAdress(text);
          }}
        />
      </Content>
      <Content gap="16px">
        <Flex direction="column" gap={8}>
          <Text typography="title18">Режим работы организации</Text>
          <Text color="textTertiary">
            Не влияет на график работы сотрудников
          </Text>
        </Flex>
        <NavLink
          label={getAbbreviatedSchedule(
            location.state?.updatedWeek ?? initialWeekDays,
          )}
          styleLabel={{color: colors.textSecondary}}
          onClick={() => {
            navigate('/create-company-schedule', {
              state: {
                week,
                name,
                specializationId,
                typeId,
                cityId,
                address,
              },
              replace: true,
            });
          }}
        />
      </Content>
      <Button
        size="large"
        disabled={uploading}
        loading={uploading}
        onClick={async () => {
          let isValid = true;

          if (!name) {
            setErrorName('Обязательное поле');
            isValid = false;
          }
          if (!specializationId) {
            setErrorSphere('Выберите сферу');
            isValid = false;
          }
          if (!typeId) {
            setErrorType('Выберите тип');
            isValid = false;
          }
          if (isValid) {
            try {
              const res = await createCompany({
                variables: {
                  input: {
                    address,
                    name,
                    city_id: cityId ? cityId : undefined,
                    specialization_id: specializationId!,
                    type_id: typeId!,
                    workingDays: week.map(weekItem => {
                      return {
                        day: weekItem.day,
                        dayoff: weekItem.dayoff,
                        end_time: weekItem.end_time,
                        start_time: weekItem.start_time,
                        break_end_time: weekItem.break_end_time,
                        break_start_time: weekItem.break_start_time,
                      };
                    }),
                    ...(Object.values(cookieValue).some(
                      val => val !== undefined,
                    ) && {
                      utm: {
                        utm_source: cookieValue.utm_source,
                        utm_campaign: cookieValue.utm_campaign,
                        utm_content: cookieValue.utm_content,
                        utm_medium: cookieValue.utm_medium,
                        utm_term: cookieValue.utm_term,
                      },
                    }),
                  },
                },
              });
              const data = res.data!.createCompany!;
              dispatch(
                setCompanyData({
                  id: data.id,
                  employeeId: data.employees!.data[0].id,
                  username: data.username,
                }),
              );
              showAlert('success', 'Организация успешно создана');
              navigate(`/${data.username}/tutorial`);
            } catch (err) {
              const error = ensureError(err);
              showAlert('error', error.message);
            }
          }
        }}>
        Создать организацию
      </Button>
    </Flex>
  );
}
