import {useQuery} from '@apollo/client';
import React, {useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {gql} from 'shared/__generated__';
import {device} from 'shared/device';
import styled from 'styled-components';
import {Content} from 'shared/ui/Content';
import {EmptyState} from './ui/EmptyState';
import {SkeletonBooking} from './ui/Skeleton';
import {Seo} from 'shared/ui/Seo';
import {OptionsType, Table} from 'shared/ui/Table';
import dayjs from 'dayjs';
import {BadgeStatus} from 'shared/ui/BadgeStatus';
import {CompanyBookingStatus} from 'shared/__generated__/graphql';
import {
  StatusText,
  getDurationHourMinutes,
  getName,
  getPriceFormat,
} from 'shared/lib/utils';
import {useAppSelector} from 'shared/store';
import {Flex} from 'shared/ui/Flex';
import {Text} from 'shared/ui/Text';

const EMPLOYEE_BOOKINGS = gql(`
  query Bookings($employeeId: String!, $first: Int!, $page: Int) {
    employee(id: $employeeId) {
      id
      bookings(first: $first, page: $page) {
        data {
          id
          start
          end
          status
          duration
          workingDate {
            id
            date
          }
          customer {
            id
            name
            surname
          }
          bookingServices {
            id
            price
            discount_value
            service {
              id
              duration
              name
            }
          }
        }
        paginatorInfo {
          hasMorePages
          currentPage
          count
          total
          lastPage
        }
      }
    }
  }
`);

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-top: 8px;
  padding-bottom: 24px;
  @media screen and (${device.mobile}) {
    margin-top: 56px;
  }
`;

const titles = ['Дата и время', 'Статус', 'Услуга', 'Клиент', 'Итого'];

export const EmployeeBookings = () => {
  const params = useParams();
  const navigate = useNavigate();
  const username = useAppSelector(state => state.company.data?.username);
  const employeeId = params.id!;
  const [options, setOptions] = useState<OptionsType>({
    page: 1,
    first: 10,
    total: 0,
    count: 0,
    lastPage: 1,
  });
  const {data, loading} = useQuery(EMPLOYEE_BOOKINGS, {
    variables: {
      employeeId,
      first: options.first,
      page: options.page,
    },
    onCompleted: ({employee}) =>
      setOptions(prev => ({
        ...prev,
        total: employee?.bookings?.paginatorInfo.total,
        count: employee?.bookings?.paginatorInfo.count,
        lastPage: employee?.bookings?.paginatorInfo.lastPage,
      })),
  });
  const bookings = data?.employee?.bookings?.data ?? [];

  if (loading) return <SkeletonBooking />;

  return (
    <Wrapper>
      <Seo title="Записи сотрудника" />
      {bookings?.length ? (
        <Content>
          <Table
            titles={titles}
            widths={[200, 156, 280, 218]}
            heightRow={'87px'}
            options={options}
            setOptions={setOptions}
            alignRightColumn={[4]}
            onClick={index =>
              !!bookings?.length &&
              navigate(`/${username}/booking/${bookings[index].id}`)
            }
            rows={bookings.map((item, index) => [
              <Flex key={`${index}_1`} direction="column">
                {dayjs(item?.workingDate?.date).format('DD.MM.YYYY')}
                <Text
                  style={{whiteSpace: 'nowrap'}}
                  typography="subHead14Medium"
                  color="textTertiary">{`${item.start}-${
                  item.end
                } · ${getDurationHourMinutes(item.duration ?? 0)}`}</Text>
              </Flex>,
              <BadgeStatus
                key={`${index}_2`}
                type={item.status as CompanyBookingStatus}>
                {StatusText[item.status as CompanyBookingStatus]}
              </BadgeStatus>,
              item.bookingServices.length
                ? item.bookingServices
                    .map(_item => _item.service.name)
                    .join(', ')
                : 'Без оказания услуг',
              getName(
                item?.customer?.name,
                item?.customer?.surname,
                'customer',
              ),
              getPriceFormat(
                item.bookingServices
                  .map(
                    service =>
                      parseFloat(service.price ?? '0') -
                      (service.discount_value ?? 0),
                  )
                  .reduce((a, b) => a + b, 0),
              ),
            ])}
          />
        </Content>
      ) : (
        <EmptyState />
      )}
    </Wrapper>
  );
};
