import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import {List} from './List';
import {Divider} from './Divider';
import {device} from 'shared/device';
import {Text} from './Text';

const Wrapper = styled.div<{width?: string}>`
  position: absolute;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 16px 0 0 0;
  padding: 16px 0;
  background-color: ${props => props.theme.bgPrimary};
  border-style: solid;
  border-width: 1px;
  border-color: ${props => props.theme.borderPrimary};
  border-radius: 18px;
  z-index: 1000;
  pointer-events: all;
  max-height: 350px;
  width: 100%;
  ${({theme}) => theme.cardShadow}
  @media ${device.desktop} {
    width: ${props => props.width ?? '540px'};
  }
`;

const Backdrop = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
  pointer-events: all;
`;

const HeaderWrapper = styled.div`
  position: sticky;
  top: 0;
  background-color: ${props => props.theme.bgPrimary};
`;
const ListWrapper = styled.div`
  overflow-y: auto;
`;
export function Dropdown<ItemT>({
  data,
  visible,
  renderItem,
  onClose,
  header,
  style,
  gap = 8,
  width,
  listEmpty,
  headerDivider = true,
  skeleton,
  withBackdrop = true,
}: React.PropsWithChildren<{
  data?: ItemT[];
  header?: React.ReactElement;
  renderItem: (item: ItemT) => React.ReactElement | null;
  visible?: boolean;
  onClose?: () => void;
  style?: React.CSSProperties;
  gap?: number;
  width?: string;
  listEmpty?: React.ReactElement | string | undefined;
  headerDivider?: boolean;
  skeleton?: React.ReactElement;
  withBackdrop?: boolean;
}>) {
  const selectRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (withBackdrop === false) {
      const handleClickOutside = (event: MouseEvent) => {
        if (
          selectRef.current &&
          !selectRef.current.contains(event.target as Node)
        ) {
          onClose && onClose();
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [onClose, selectRef, withBackdrop]);

  if (!visible) {
    return null;
  }
  return (
    <>
      {withBackdrop && <Backdrop onClick={onClose} />}
      <Wrapper ref={selectRef} style={style} width={width}>
        <HeaderWrapper>{header}</HeaderWrapper>
        {header && headerDivider ? <Divider style={{margin: 16}} /> : null}
        {skeleton ?? (
          <ListWrapper>
            <List
              gap={gap}
              data={data}
              renderItem={renderItem}
              placeholder={
                <Text
                  style={{textAlign: 'center', padding: '0 24px'}}
                  typography="text16Regular"
                  color="textTertiary">
                  {listEmpty}
                </Text>
              }
            />
          </ListWrapper>
        )}
      </Wrapper>
    </>
  );
}
