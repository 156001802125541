import React, {useState} from 'react';
import styled from 'styled-components';
import {useParams} from 'react-router-dom';
import {gql} from 'shared/__generated__';
import {useAppSelector} from 'shared/store';
import {Layout} from 'shared/ui/Layout';
import {PageHeader} from 'shared/ui/PageHeader';
import {TextInput} from 'shared/ui/TextInput';
import {Flex} from 'shared/ui/Flex';
import {useQuery} from '@apollo/client';
import {CircleClose} from 'shared/icons/CircleClose';
import {useColors} from 'shared/lib/hooks';
import {Divider} from 'shared/ui/Divider';
import {CirclePlus} from 'shared/icons/CirclePlus';
import {Text} from 'shared/ui/Text';
import {MultiSelectChips} from 'shared/ui/MultiSelectChips';
import {SelectEmployees, SelectServices} from 'entities/loyalty';
import {getName} from 'shared/lib/utils';
import {
  SalaryData,
  SalarySelectedServices,
  TextInputSwitch,
  TitleContent,
  salaryPeriod,
  salaryTime,
  salaryType,
} from 'entities/salary';
import {Skeleton} from 'shared/ui/Skeleton';
import {FooterButtons} from './ui/FooterButtons';
import {SalaryRuleService, Service} from 'shared/__generated__/graphql';

const GET_SERVICES_AND_SALARY = gql(`
  query GetServicesAndSalaryRule($companySalaryRuleId: String!) {
    companySalaryRule(id: $companySalaryRuleId) {
      id
      name
      salary_type
      salary_value
      service_salary_type
      service_salary_value
      guaranteed_minimum_value
      guaranteed_minimum_type
      employees {
        id 
        name
        surname
      }
      services {
        service_salary_type
        service_salary_value
        service {
          id
          name
        }
      }
    }
  }
`);
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-column-start: 4;
  grid-column-end: 10;
  gap: 24px;
  padding-bottom: 24px;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const Close = styled.button`
  display: flex;
  width: 44px;
  height: 100%;
  border: none;
  background-color: transparent;
  align-items: center;
  cursor: pointer;
`;
const AddSpecify = styled.button`
  display: flex;
  gap: 8px;
  height: 48px;
  border: none;
  background-color: transparent;
  align-items: center;
  cursor: pointer;
  pointer-events: all;
`;

const defaultService = {
  id: 1,
  services: [],
  value: '',
  typeIndex: '0',
};

type IResService = Pick<
  SalaryRuleService,
  'service_salary_type' | 'service_salary_value'
> & {service: Pick<Service, 'id' | 'name'>};
type IResServices = Pick<
  SalaryRuleService,
  'service_salary_type' | 'service_salary_value'
> & {services: Pick<Service, 'id' | 'name'>[]};

const getFormattedServices = (
  data: IResService[],
): SalarySelectedServices[] => {
  const result: IResServices[] = [];
  data.forEach(item => {
    const duplicateIndex = result.findIndex(
      element =>
        element.service_salary_type === item.service_salary_type &&
        element.service_salary_value === item.service_salary_value,
    );
    if (duplicateIndex !== -1) {
      result[duplicateIndex].services = [
        ...result[duplicateIndex].services,
        item.service,
      ];
    } else {
      result.push({...item, services: [item.service]});
    }
  });
  return result.map((customService, index) => {
    const indexSalaryType = salaryType.findIndex(
      e => e.value === customService.service_salary_type,
    );
    return {
      id: index,
      value: customService.service_salary_value.toString(),
      type: indexSalaryType,
      typeIndex: indexSalaryType.toString(),
      services: customService.services.map(service => ({
        value: service.id,
        label: service.name,
      })),
    };
  });
};

export const EditSalaryRule = () => {
  const colors = useColors();
  const {id} = useParams();
  const companyId = useAppSelector(state => state.company.data!.id);
  const {loading} = useQuery(GET_SERVICES_AND_SALARY, {
    variables: {companyId, companySalaryRuleId: id!},
    onCompleted: res => {
      const companySalaryRule = res.companySalaryRule;
      if (!companySalaryRule) return;
      setName(companySalaryRule.name);
      setValue(companySalaryRule.service_salary_value.toString());
      setSalary(companySalaryRule.salary_value.toString());
      setGuaranteedMinimum(
        companySalaryRule.guaranteed_minimum_value.toString(),
      );
      const resServices = companySalaryRule.services ?? [];
      if (resServices.length > 0) {
        setSpecifyServices(getFormattedServices(resServices));
      }
      const employees = companySalaryRule.employees ?? [];
      setSelectedEmployees(
        employees.map(item => ({
          label: getName(item.name, item.surname, 'employee'),
          value: item.id,
        })),
      );
      const timer = setTimeout(() => {
        const indexSalary = salaryTime.findIndex(
          item => item.value === companySalaryRule.salary_type,
        );
        if (indexSalary !== -1) setPeriodSalary(indexSalary);
        const indexSalaryType = salaryType.findIndex(
          item => item.value === companySalaryRule.service_salary_type,
        );
        if (indexSalaryType !== -1) setServiceType(indexSalaryType);
        const indexGuaranteedType = salaryPeriod.findIndex(
          item => item.value === companySalaryRule.guaranteed_minimum_type,
        );
        if (indexGuaranteedType !== -1)
          setGuaranteedMinimumType(indexGuaranteedType);
        clearTimeout(timer);
      }, 200);
    },
  });

  const [name, setName] = useState('');
  const [value, setValue] = useState('');
  const [serviceType, setServiceType] = useState(0);
  const [maxLength, setMaxLength] = useState<number>();

  const [specifyServices, setSpecifyServices] = useState<
    SalarySelectedServices[]
  >([defaultService]);

  const [salary, setSalary] = useState('');
  const [periodSalary, setPeriodSalary] = useState(0);
  const [guaranteedMinimum, setGuaranteedMinimum] = useState('');
  const [guaranteedMinimumType, setGuaranteedMinimumType] = useState(0);
  const [selectedEmployees, setSelectedEmployees] = useState<
    {label: string; value: string}[]
  >([]);

  const excludes = specifyServices.reduce(
    (accumulator, currentValue) => [...accumulator, ...currentValue.services],
    [] as SalaryData[],
  );

  const setChanged = (
    key: 'typeIndex' | 'value' | 'services',
    index: number,
    newValue: string | SalaryData[],
  ) => {
    if (typeof newValue === 'string' && key !== 'services') {
      const copy = [...specifyServices];
      copy[index][key] = newValue;
      setSpecifyServices(copy);
      return;
    }
    if (typeof newValue !== 'object') return;
    const copy = [...specifyServices];
    copy[index].services = newValue;
    setSpecifyServices(copy);
  };
  return (
    <Layout>
      <Wrapper>
        <PageHeader
          back
          variant="layout"
          title="Редактирование правила расчета"
        />
        {loading ? (
          <SkeletonEditSalary />
        ) : (
          <>
            <TitleContent title="Основное">
              <TextInput
                value={name}
                onChange={setName}
                required
                label="Название правила расчета"
              />
              <TextInputSwitch
                switchLabels={salaryType}
                onChangeTab={index => {
                  if (index === 0 && +value > 100) {
                    setValue('100');
                  }
                  setServiceType(index);
                }}
                activeIndex={serviceType}
                label="С услуг"
                value={value}
                onChange={setValue}
              />
            </TitleContent>
            <TitleContent title="Уточнить по категориям и услугам">
              {specifyServices.map((service, index) => (
                <Flex key={index + '_specifyIndex'}>
                  <Flex style={{width: '100%'}} gap={16} direction="column">
                    {index !== 0 && <Divider />}
                    <MultiSelectChips
                      label="Услуги"
                      values={service.services}
                      modal={(visible, setVisible) => (
                        <SelectServices
                          label="Услуги"
                          visible={visible}
                          excludes={excludes}
                          values={service.services}
                          onChange={(values, maxLength) => {
                            if (maxLength) setMaxLength(maxLength);
                            setChanged('services', index, values);
                            setVisible(false);
                          }}
                          onClose={() => setVisible(false)}
                        />
                      )}
                      onDelete={id => {
                        const copy = [...specifyServices];
                        copy[index].services = specifyServices[
                          index
                        ].services.filter(service => service.value !== id);
                        setSpecifyServices(copy);
                      }}
                    />
                    <TextInputSwitch
                      switchLabels={salaryType}
                      onChangeTab={typeIndex =>
                        setChanged('typeIndex', index, typeIndex.toString())
                      }
                      activeIndex={+service.typeIndex}
                      label="С услуги"
                      value={service.value}
                      onChange={text => setChanged('value', index, text)}
                    />
                  </Flex>
                  <Close
                    onClick={() => {
                      setSpecifyServices(prev =>
                        prev.filter((_, deleteIndex) => deleteIndex !== index),
                      );
                    }}>
                    <CircleClose color={colors.dangerPrimary} />
                  </Close>
                </Flex>
              ))}
              {(!maxLength || (maxLength && excludes.length !== maxLength)) &&
                specifyServices.length < 5 && (
                  <AddSpecify
                    onClick={() => {
                      const newId =
                        specifyServices.length > 0
                          ? specifyServices[specifyServices.length - 1].id + 1
                          : 1;
                      setSpecifyServices([
                        ...specifyServices,
                        {id: newId, services: [], value: '', typeIndex: '0'},
                      ]);
                    }}>
                    <CirclePlus color={colors.mainPrimary} />
                    <Text color="mainPrimary">Добавить поле</Text>
                  </AddSpecify>
                )}
            </TitleContent>
            <TitleContent title="Минимальная прибыль">
              <TextInputSwitch
                defaultWidth={70}
                switchLabels={salaryTime}
                onChangeTab={setPeriodSalary}
                activeIndex={periodSalary}
                label="Оклад"
                value={salary}
                onChange={setSalary}
              />
              <TextInputSwitch
                switchLabels={salaryPeriod}
                defaultWidth={70}
                onChangeTab={setGuaranteedMinimumType}
                activeIndex={guaranteedMinimumType}
                label="Гарантированный минимум"
                value={guaranteedMinimum}
                onChange={setGuaranteedMinimum}
              />
            </TitleContent>
            <TitleContent title="Сотрудники">
              <MultiSelectChips
                label="Выберите сотрудников"
                values={selectedEmployees}
                onDelete={id =>
                  setSelectedEmployees(
                    selectedEmployees.filter(
                      selectItem => selectItem.value !== id,
                    ),
                  )
                }
                modal={(visible, setVisible) => (
                  <SelectEmployees
                    label="Выберите сотрудников"
                    visible={visible}
                    values={selectedEmployees}
                    onChange={values => {
                      setSelectedEmployees(values);
                      setVisible(false);
                    }}
                    onClose={() => setVisible(false)}
                  />
                )}
              />
            </TitleContent>
          </>
        )}
        <FooterButtons
          input={{
            id: id!,
            employees: selectedEmployees.map(employee => employee.value),
            guaranteed_minimum_type: salaryPeriod[guaranteedMinimumType].value,
            guaranteed_minimum_value: +guaranteedMinimum,
            name,
            salary_type: salaryTime[periodSalary].value,
            salary_value: +salary,
            service_salary_type: salaryType[serviceType].value,
            service_salary_value: +value,
          }}
          services={specifyServices}
        />
      </Wrapper>
    </Layout>
  );
};

const SkeletonTitle = ({last, title}: {title: string; last?: boolean}) => (
  <TitleContent title={title}>
    <Skeleton width={'100%'} height={last ? 60 : 144}>
      <rect width={'100%'} height={64} rx={12} ry={12} />
      {!last && <rect width={'100%'} y={80} height={64} rx={12} ry={12} />}
    </Skeleton>
  </TitleContent>
);

const SkeletonEditSalary = () => {
  return (
    <Flex direction="column" gap={16}>
      <SkeletonTitle title="Основное" />
      <SkeletonTitle title="Уточнить по категориям и услугам" />
      <SkeletonTitle title="Минимальная прибыль" />
      <SkeletonTitle title="Сотрудники" last />
    </Flex>
  );
};
