import {useQuery} from '@apollo/client';
import React, {useEffect, useState} from 'react';
import {ArrowDown2v} from 'shared/icons/ArrowDown2v';
import {Dropdown} from 'shared/ui/Dropdown';
import {Flex} from 'shared/ui/Flex';
import {Skeleton} from 'shared/ui/Skeleton';
import {TableViewRow} from 'shared/ui/TableViewRow';
import {Text} from 'shared/ui/Text';
import {TextInput} from 'shared/ui/TextInput';
import styled from 'styled-components';
import {PRODUCTS_CATEGORIES} from '..';
import {useAppSelector} from 'shared/store';
import {ModalCategoryCreate} from 'entities/products';
import {EmptyAddCategoryBlock} from 'shared/illustration/EmptyAddCategoryBlock';

const Wrapper = styled.div`
  display: block;
  position: relative;
`;

const MyTableViewRow = styled(TableViewRow)`
  padding: 0 16px;
  width: 100%;
  &:hover {
    background-color: ${({theme}) => theme.bgSecondary};
  }
`;

type Props = {
  data: {label: string; value: string}[];
  label?: string;
  value: string | null;
  onChange: (value: string) => void;
  loading: boolean;
  error?: string | null;
};

export const SelectProductsCategory = (props: Props) => {
  const {data, label, onChange, value, loading, error} = props;
  const [search, setSearch] = useState('');
  const [visible, setVisible] = useState(false);
  const [modalCreate, setModalCreate] = useState(false);
  const companyId = useAppSelector(state => state.company.data?.id);
  const {data: dataQuery} = useQuery(PRODUCTS_CATEGORIES, {
    variables: {
      companyId: companyId!,
      page: 1,
      first: 40,
    },
  });

  useEffect(() => {
    setSearch(data.find(item => item.value === value)?.label ?? '');
  }, [data, value]);

  return (
    <Wrapper>
      <TextInput
        label={label}
        value={search}
        onChange={setSearch}
        error={error}
        onClick={() => setVisible(true)}
        rightElement={<ArrowDown2v />}
      />
      <Dropdown
        visible={visible}
        onClose={() => setVisible(false)}
        data={data}
        style={{top: 50, width: '100%', maxHeight: 508}}
        header={
          <Flex
            direction="row"
            alignItems="center"
            style={{
              height: 40,
              paddingLeft: 16,
              marginBottom: 8,
            }}>
            <Text
              color="mainPrimary"
              style={{cursor: 'pointer'}}
              onClick={() => {
                setModalCreate(true);
              }}>
              + Создать категорию
            </Text>
          </Flex>
        }
        listEmpty={<EmptyState />}
        headerDivider={false}
        renderItem={item => (
          <MyTableViewRow
            title={item.label}
            onClick={() => {
              onChange(item.value);
              setSearch(item.label);
              setVisible(false);
            }}
          />
        )}
        skeleton={loading ? <SkeletonProductCategoryItem /> : undefined}
      />
      <ModalCategoryCreate
        visible={modalCreate}
        onClose={() => setModalCreate(false)}
        categories={
          dataQuery?.productsCategory?.data
            ? dataQuery.productsCategory.data.map(item => ({
                label: item.name,
                value: item.id,
              }))
            : []
        }
      />
    </Wrapper>
  );
};

const EmptyState = () => {
  return (
    <Flex direction="column" style={{paddingBottom: 8}}>
      <Flex direction="column" gap={16} alignItems="center">
        <EmptyAddCategoryBlock size={200} />
        <Text typography="title20" align="center">
          Нет категорий
        </Text>
        <Text color="textTertiary" align="center">
          Нажмите на кнопку “Создать категорию” и <br /> добавьте информацию
        </Text>
      </Flex>
    </Flex>
  );
};

const SkeletonProductCategoryItem = () => {
  return (
    <Skeleton height={236}>
      {Array.from(Array(3).keys()).map(item => (
        <React.Fragment key={'sk_brand_item_' + item}>
          <rect
            width={'60%'}
            height={24}
            x={16}
            y={10 + 68 * item}
            rx={4}
            ry={4}
          />
          <rect
            width={'40%'}
            height={20}
            x={16}
            y={38 + 68 * item}
            rx={4}
            ry={4}
          />
        </React.Fragment>
      ))}
    </Skeleton>
  );
};
