import {useQuery} from '@apollo/client';
import {COMPANY_SOURCES} from 'entities/bookingSource';
import React from 'react';
import {EmptyMedia} from 'shared/illustration/EmptyMedia';
import {useAppSelector} from 'shared/store';
import {Flex} from 'shared/ui/Flex';
import {Select} from 'shared/ui/Select';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';

type Props = {
  value: string | null;
  onChange: (value: string | null) => void;
};

export const SelectBookingSource = (props: Props) => {
  const {value, onChange} = props;
  const companyId = useAppSelector(state => state.company.data?.id);
  const {data} = useQuery(COMPANY_SOURCES, {
    variables: {
      companyId: companyId!,
    },
  });
  const sources = data?.companySources ?? [];

  return (
    <Flex direction="column" gap={16}>
      <Text typography="title18">Источник записи</Text>
      <Select
        data={sources.map(item => ({label: item.name, value: item.id}))}
        onChange={value => onChange(value)}
        label="Выберите источник записи"
        value={value}
        emptyState={
          <Flex direction="column" gap={16} alignItems="center">
            <EmptyMedia size={200} />
            <Text color="textTertiary" align="center">
              Ничего не найдено. Проверьте данные и <br /> попробуйте еще раз
            </Text>
          </Flex>
        }
        renderItem={item => (
          <ItemWrapper>
            <Color
              bg={
                sources.find(source => source.id === item.value)?.color?.rgb
                  ? `rgb(${sources.find(source => source.id === item.value)
                      ?.color?.rgb})`
                  : 'transparent'
              }
            />
            {item.label}
          </ItemWrapper>
        )}
      />
    </Flex>
  );
};

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-left: 16px;
  gap: 16px;
  height: 56px;
  color: ${({theme}) => theme.textPrimary};
  font-size: 16px;
  font-family: 'Inter';
  font-weight: 400;
`;

const Color = styled.div<{bg: string}>`
  width: 6px;
  height: calc(100% - 14px);
  border-radius: 3px;
  margin: 7px 0px;
  background-color: ${({bg}) => bg};
`;
