import React, {useEffect} from 'react';
import {device} from 'shared/device';
import {DeviceLaptop} from 'shared/icons/DeviceLaptop';
import {Mail} from 'shared/icons/Mail';
import {Support as IconSupport} from 'shared/icons/Support';
import {Telegram} from 'shared/icons/Telegram';
import {Train} from 'shared/icons/Train';
import {Vk} from 'shared/icons/Vk';
import {Whatsapp} from 'shared/icons/Whatsapp';
import {Youtube} from 'shared/icons/Youtube';
import {useColors, useResize} from 'shared/lib/hooks';
import {Button} from 'shared/ui/Button';
import {Content} from 'shared/ui/Content';
import {Grow} from 'shared/ui/Grow';
import {Seo} from 'shared/ui/Seo';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 403px 403px;
  gap: 24px;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
  @media ${device.mobile} {
    gap: 16px;
    padding: 0;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  height: 56px;
  align-items: center;
  @media ${device.mobile} {
    padding: 0 16px;
  }
`;
const NavLink = styled(Button)`
  color: ${({theme}) => theme.textPrimary};
  background-color: ${({theme}) => theme.fillPrimary};
  &:hover {
    background-color: ${({theme}) => theme.bgTertiary};
  }
`;
const Box = styled(Content)`
  width: 403px;
  height: max-content;
`;

export const Support = () => {
  const {isMobile} = useResize();
  const colors = useColors();
  const BASE_URL = 'https://app.chatwoot.com';

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    window.chatwootSettings = {
      hideMessageBubble: true,
      position: 'right', // This can be left or right
      locale: 'ru', // Language to be set
      type: 'standard', // [standard, expanded_bubble]
    };
    const g = document.createElement('script');
    const s = document.getElementsByTagName('script')[0];
    g.src = BASE_URL + '/packs/js/sdk.js';
    g.defer = true;
    g.async = true;
    s.parentNode?.insertBefore(g, s);
    g.onload = function () {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      window.chatwootSDK.run({
        websiteToken: process.env.REACT_APP_CHATWOOT_TOKEN,
        baseUrl: BASE_URL,
      });
    };
    document.body.appendChild(g);

    return () => {
      document.body.removeChild(g);
    };
  });

  return (
    <Container>
      <Seo title="Центр помощи" />
      <Header>
        <Text typography={isMobile ? 'text16Semibold' : 'title24'}>
          Центр помощи
        </Text>
      </Header>
      <Wrapper>
        <Box>
          <Text typography="text16Semibold">Связаться с нами</Text>
          <Grow style={{gap: 8}}>
            <NavLink
              onClick={() => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                window.$chatwoot.setCustomAttributes({
                  platform: 'web',
                });
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                window.$chatwoot.toggle();
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                console.log('ta', window.$chatwoot);
              }}
              leftIcon={<IconSupport color={colors.textPrimary} />}>
              Написать в чат
            </NavLink>
            <NavLink
              onClick={() => window.open('mailto:support@booky.co', '_blank')}
              leftIcon={<Mail color={colors.textPrimary} />}>
              Написать на email
            </NavLink>
            <NavLink
              onClick={() =>
                window.open('https://wa.me/?phone=79990600900 ', '_blank')
              }
              leftIcon={<Whatsapp />}>
              WhatsApp
            </NavLink>
            <NavLink
              onClick={() => window.open('https://t.me/+79990600900', '_blank')}
              leftIcon={<Telegram />}>
              Telegram
            </NavLink>
          </Grow>
        </Box>
        <Box>
          <Text typography="text16Semibold">Социальные сети</Text>
          <Grow style={{gap: 8}}>
            <NavLink
              onClick={() =>
                window.open('https://www.youtube.com/@bookyco', '_blank')
              }
              leftIcon={<Youtube />}>
              YouTube
            </NavLink>
            <NavLink
              onClick={() => window.open('https://vk.com/bookyco', '_blank')}
              leftIcon={<Vk />}>
              VK
            </NavLink>
          </Grow>
        </Box>
        <Box>
          <Grow style={{gap: 8}}>
            <NavLink
              onClick={() => window.open('https://support.booky.co/', '_blank')}
              leftIcon={<Train />}>
              Перейти в Базу знаний
            </NavLink>
            <NavLink
              onClick={() =>
                window.open(
                  'https://short.bookydev.co/remote_support',
                  '_blank',
                )
              }
              leftIcon={<DeviceLaptop />}>
              Удаленная помощь
            </NavLink>
          </Grow>
        </Box>
      </Wrapper>
    </Container>
  );
};
