import React from 'react';
import {CompanyBookingStatus} from 'shared/__generated__/graphql';
import {Content} from 'shared/ui/Content';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  border-radius: 12px;
  gap: 4px;
  padding: 4px;
  background-color: ${({theme}) => theme.fillPrimary};
`;

const ButtonStatus = styled.button<{active?: boolean}>`
  display: flex;
  height: 42px;
  width: 100%;
  position: relative;
  align-items: center;
  justify-content: center;
  font-family: 'Inter';
  font-size: 16px;
  color: ${({active, theme}) =>
    active ? theme.mainPrimary : theme.textPrimary};
  border: none;
  font-style: 'normal';
  font-weight: 400;
  line-height: '24px';
  gap: 8px;
  pointer-events: all;
  background-color: transparent;
  cursor: pointer;
`;

const ActiveDiv = styled.div<{position: number}>`
  height: 42px;
  position: absolute;
  width: calc((100% - 20px) / 4);
  border-radius: 12px;
  background-color: ${({theme}) => theme.bgPrimary};
  left: ${({position}) =>
    `calc((100% - 20px) / 4 * ${position} + 4px * ${position + 1})`};
  transition: left 300ms;
`;

const getStatusPosition = (value: CompanyBookingStatus) => {
  switch (value) {
    case CompanyBookingStatus.Pending:
      return 0;
    case CompanyBookingStatus.Completed:
      return 1;
    case CompanyBookingStatus.Cancelled:
      return 2;
    case CompanyBookingStatus.Confirmed:
      return 3;
  }
};

type Props = {
  value: CompanyBookingStatus;
  onChange: (value: CompanyBookingStatus) => void;
};

export const Status = (props: Props) => {
  const {value, onChange} = props;

  return (
    <Content>
      <Text typography="title18" color="textPrimary">
        Статус
      </Text>
      <Wrapper>
        <ActiveDiv position={getStatusPosition(value)} />
        <ButtonStatus
          active={value === CompanyBookingStatus.Pending}
          onClick={() => onChange(CompanyBookingStatus.Pending)}>
          Ожидание
        </ButtonStatus>
        <ButtonStatus
          active={value === CompanyBookingStatus.Completed}
          onClick={() => onChange(CompanyBookingStatus.Completed)}>
          Пришёл
        </ButtonStatus>
        <ButtonStatus
          active={value === CompanyBookingStatus.Cancelled}
          onClick={() => onChange(CompanyBookingStatus.Cancelled)}>
          Не пришёл
        </ButtonStatus>
        <ButtonStatus
          active={value === CompanyBookingStatus.Confirmed}
          onClick={() => onChange(CompanyBookingStatus.Confirmed)}>
          Подтвердил
        </ButtonStatus>
      </Wrapper>
    </Content>
  );
};
