import {ApolloError, useMutation} from '@apollo/client';
import dayjs from 'dayjs';
import React, {useState} from 'react';
import {gql} from 'shared/__generated__';
import {showAlert} from 'shared/ui/Alert';
import {Button} from 'shared/ui/Button';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';
import {EMPLOYEE_SCHEDULE, GET_EMPLOYEES} from '..';
import {GET_COMPANY_EMPLOYEES} from 'entities/employees';
import {useAppSelector} from 'shared/store';
import {CloseButton} from 'shared/ui/CloseButton';
import {Popup} from 'shared/ui/Popup';
import {PeriodSelect} from 'entities/loyalty';
import {useColors} from 'shared/lib/hooks';
import {Avatar} from 'shared/ui/Avatar';

const DELETE_SCHEDULE = gql(`
  mutation DeleteWorkingDatesMany($input: DeleteWorkingDates!) {
    deleteWorkingDatesMany(input: $input) {
      id
    }
  }
`);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 588px;
  gap: 32px;
`;
const Row = styled.div<{gap?: number}>`
  display: flex;
  gap: ${({gap}) => (gap ?? 8) + 'px'};
  align-items: center;
`;
const ModalButton = styled(Button)<{isDelete?: boolean}>`
  flex: 1;
  border: 1px solid ${({theme}) => theme.borderPrimary};
  color: ${({theme, isDelete}) =>
    isDelete ? theme.dangerPrimary : theme.mainPrimary};
`;
const SectionUser = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 16px;
`;
const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  max-width: 256px;
  min-width: 0;
`;
const OverflowText = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const START_DATE = dayjs().startOf('months').format('YYYY-MM-DD');
const END_DATE = dayjs().endOf('months').format('YYYY-MM-DD');

export const DeleteSchedule = ({
  visible,
  onClose,
  employeeId,
  employeeName,
  employeeUrl,
  employeeProfessions,
}: {
  employeeId: string;
  employeeUrl?: string;
  employeeName: string;
  employeeProfessions?: string;
  visible: boolean;
  onClose: () => void;
}) => {
  const colors = useColors();
  const companyId = useAppSelector(state => state.company.data!.id);
  const [period, setPeriod] = useState({from: START_DATE, to: END_DATE});
  const [periodError, setPeriodError] = useState<string | null>(null);
  const [deleteSchedule, {loading}] = useMutation(DELETE_SCHEDULE, {
    refetchQueries: [
      {
        query: EMPLOYEE_SCHEDULE,
        variables: {employeeId, companyId},
      },
      {
        query: GET_EMPLOYEES,
        variables: {companyId, first: 20, page: 1, enabled: true},
      },
      {
        query: GET_COMPANY_EMPLOYEES,
        variables: {companyId, first: 20},
      },
    ],
  });

  const onPressDelete = () => {
    if (!period.from || !period.to) {
      setPeriodError('Неправильно выбран период даты');
      return;
    }
    const payload = {
      from: period.from,
      to: period.to,
      employee_id: employeeId,
    };
    deleteSchedule({variables: {input: payload}})
      .then(() => {
        showAlert('success', 'График работ за этот месяц удален');
        onClose();
      })
      .catch((err: ApolloError) => showAlert('error', err.message));
  };

  return (
    <Popup visible={visible} onClose={onClose}>
      <Container>
        <Row gap={0}>
          <Text style={{flex: 1}} typography="title20">
            Удалить расписание
          </Text>
          <CloseButton onClose={() => onClose()} />
        </Row>
        <SectionUser>
          <Avatar size={56} url={employeeUrl} />
          <UserInfo>
            <OverflowText typography="text16Semibold">
              {employeeName}
            </OverflowText>
            {employeeProfessions ? (
              <OverflowText typography="subHead14Medium" color="textTertiary">
                {employeeProfessions}
              </OverflowText>
            ) : null}
          </UserInfo>
        </SectionUser>
        <PeriodSelect
          label="Выберите период"
          period={period}
          error={periodError}
          onChange={value => {
            if (periodError) setPeriodError(null);
            setPeriod(value);
          }}
        />
        <Row>
          <ModalButton
            onClick={onClose}
            style={{borderColor: colors.mainPrimary}}
            typography="text16Semibold"
            size="large"
            variant="outline">
            Отмена
          </ModalButton>
          <ModalButton
            loading={loading}
            style={{borderColor: colors.dangerPrimary}}
            isDelete
            size="large"
            onClick={onPressDelete}
            typography="text16Semibold"
            color="red"
            variant="outline">
            Удалить
          </ModalButton>
        </Row>
      </Container>
    </Popup>
  );
};
