import React, {useRef} from 'react';
import {useState} from 'react';
import styled from 'styled-components';
import {Text} from './Text';
import InputMask from 'react-input-mask';
import {useColors} from 'shared/lib/hooks';
import {device} from 'shared/device';
import {Flex} from './Flex';
import {ActionIcon} from './ActionIcon';
import {Eye} from 'shared/icons/Eye';
import {EyeHide} from 'shared/icons/EyeHide';
import Close from 'shared/icons/Close';

const Input = styled.input<{animated?: boolean; height: number}>`
  width: 100%;
  height: ${({height}) => height - 24 + 'px'};
  background-color: transparent;
  border: none;
  outline-style: none;
  padding: 0;
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-top: ${({animated}) => (animated ? '24px' : 0)};
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  @media ${device.mobile} {
    font-size: 14px;
  }
`;

const Wrapper = styled.div<{
  focused: boolean;
  error: boolean;
  backgroundColor?: string;
  width?: string;
}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid;
  width: ${({width}) => width};
  border-color: ${({error, theme, focused}) =>
    error
      ? theme.dangerPrimary
      : focused
      ? theme.mainPrimary
      : theme.borderPrimary};
  gap: 8px;
  background-color: ${({theme, backgroundColor}) =>
    backgroundColor ?? theme.fillPrimary};
  padding: 0 16px;
  border-radius: 18px;
  &:disabled {
    border-color: ${({theme}) => theme.borderPrimary};
  }
  @media ${device.mobile} {
    height: 56px;
  }
`;

const WrapperInput = styled.div<{height: number}>`
  position: relative;
  width: 100%;
  height: ${({height}) => height + 'px'};
`;

const Label = styled.span<{
  floating?: boolean;
  required?: boolean;
}>`
  position: absolute;
  left: 0;
  top: 20px !important;
  font-size: ${({floating}) => (floating ? '12px' : '16px')};
  line-height: ${({floating}) => (floating ? '18px' : '24px')};
  font-weight: 400;
  color: ${({theme}) => theme.textTertiary};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  transition:
    transform 150ms ease,
    color 150ms ease,
    font-size 150ms ease;
  transform: ${({floating}) => (floating ? 'translateY(-11px)' : 'none')};
  pointer-events: none;
  @media ${device.mobile} {
    font-size: ${({floating}) => (floating ? '12px' : '14px')};
    transform: ${({floating}) => (floating ? 'translateY(-7px)' : 'none')};
  }
  &::after {
    content: '*';
    color: ${({theme}) => theme.dangerPrimary};
    visibility: ${({required}) => (required ? 'visible' : 'hidden')};
  }
`;
const LimitView = styled.div`
  position: absolute;
  right: 0px;
  bottom: 9px;
`;

const WrapperWithLabel = styled.div<{width?: string}>`
  display: flex;
  gap: 6px;
  flex-direction: column;
  width: ${({width}) => width};
`;

interface Props
  extends Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    'onChange' | 'value'
  > {
  variant?: 'default' | 'with_label' | 'label_animation';
  label?: string;
  required?: boolean;
  mt?: number;
  value?: string;
  error?: string | null;
  mask?: string;
  onChange?: (value: string) => void;
  leftElement?: React.ReactElement;
  rightElement?: React.ReactElement;
  backgroundColor?: string;
  width?: string;
  height?: number;
  flex?: number;
  wrapperStyle?: React.CSSProperties;
  maxLength?: number;
  borderRadius?: number;
  onClick?: () => void;
}

export const TextInput = (props: Props) => {
  const {
    variant = 'label_animation',
    label,
    required,
    disabled,
    value,
    error,
    onChange,
    mt,
    mask = '',
    placeholder,
    leftElement,
    rightElement,
    backgroundColor,
    type,
    width,
    height = 64,
    flex,
    wrapperStyle,
    maxLength,
    borderRadius,
    onClick,
    ...rest
  } = props;
  const colors = useColors();
  const [focused, setFocused] = useState(false);
  const floating = (value ? value.trim().length !== 0 : false) || focused;
  const isPassword = type === 'password';
  const [visible, setVisible] = useState(!isPassword);
  const refInput = useRef<HTMLInputElement>(null);

  if (variant === 'label_animation') {
    return (
      <Flex flex={flex} direction="column" gap={8} style={wrapperStyle}>
        <Wrapper
          backgroundColor={backgroundColor}
          focused={focused}
          error={!!error}
          width={width}
          onClick={() => {
            onClick && onClick();
            if (!disabled) {
              refInput.current?.focus();
            }
          }}
          style={{marginTop: mt, cursor: disabled ? 'default' : 'text'}}>
          {leftElement}
          <WrapperInput height={height}>
            <InputMask
              mask={mask}
              maskPlaceholder={null}
              disabled={disabled}
              type={isPassword && visible ? undefined : type}
              onBlur={() => {
                setFocused(false);
              }}
              onFocus={() => {
                setFocused(true);
              }}
              value={value}
              maxLength={maxLength}
              onChange={e => onChange && onChange(e.target.value)}>
              <Input
                ref={refInput}
                height={height}
                animated
                {...rest}
                style={{
                  color: disabled ? colors.textTertiary : colors.textPrimary,
                  ...rest.style,
                }}
              />
            </InputMask>
            {label ? (
              <Label required={required} floating={floating}>
                {label}
              </Label>
            ) : null}
            {maxLength && (
              <LimitView>
                <Text typography="footNote12Regular" color="textTertiary">
                  {value?.length}/{maxLength}
                </Text>
              </LimitView>
            )}
          </WrapperInput>
          {rightElement && !focused ? (
            rightElement
          ) : isPassword ? (
            <ActionIcon
              style={{
                height: '100%',
                width: 24,
                backgroundColor: 'transparent',
                border: 'none',
              }}
              icon={visible ? <Eye /> : <EyeHide />}
              onClick={() => setVisible(prev => !prev)}
            />
          ) : focused && value!.length > 0 ? (
            <ActionIcon
              style={{
                height: '100%',
                width: 24,
                backgroundColor: 'transparent',
                border: 'none',
              }}
              icon={<Close />}
              onMouseDown={() => {
                onChange && onChange('');
              }}
            />
          ) : null}
        </Wrapper>
        {error ? (
          <Text color="dangerPrimary" typography="footNote12Regular">
            {error}
          </Text>
        ) : null}
      </Flex>
    );
  }

  return (
    <Flex direction="column" gap={8} style={wrapperStyle}>
      <WrapperWithLabel width={width}>
        {variant === 'with_label' && label ? (
          <Text typography="subHead14Regular" color="textTertiary">
            {label}
          </Text>
        ) : null}
        <Wrapper
          focused={focused}
          width={width}
          error={!!error}
          onClick={() => {
            onClick && onClick();
            if (!disabled) {
              refInput.current?.focus();
            }
          }}
          style={{
            marginTop: mt,
            backgroundColor,
            cursor: disabled ? 'default' : 'text',
            borderRadius: borderRadius,
          }}>
          {leftElement}
          <WrapperInput
            height={height}
            style={{display: 'flex', alignItems: 'center'}}>
            <InputMask
              mask={mask}
              maskPlaceholder={null}
              disabled={disabled}
              maxLength={maxLength}
              type={isPassword && visible ? undefined : type}
              onBlur={() => {
                setFocused(false);
              }}
              onFocus={() => {
                setFocused(true);
              }}
              value={value}
              onChange={e => onChange && onChange(e.target.value)}>
              <Input
                ref={refInput}
                height={height}
                {...rest}
                placeholder={placeholder}
                style={{
                  color: disabled ? colors.textTertiary : colors.textPrimary,
                  ...rest.style,
                }}
              />
            </InputMask>
            {maxLength && (
              <LimitView>
                <Text typography="footNote12Regular" color="textTertiary">
                  {value?.length}/{maxLength}
                </Text>
              </LimitView>
            )}
          </WrapperInput>
          {rightElement && !focused ? (
            rightElement
          ) : isPassword ? (
            <ActionIcon
              style={{
                height: '100%',
                width: 24,
                backgroundColor: 'transparent',
                border: 'none',
              }}
              icon={visible ? <Eye /> : <EyeHide />}
              onClick={() => setVisible(prev => !prev)}
            />
          ) : focused && value!.length > 0 ? (
            <ActionIcon
              style={{
                height: '100%',
                width: 24,
                backgroundColor: 'transparent',
                border: 'none',
              }}
              icon={<Close />}
              onMouseDown={() => {
                onChange && onChange('');
              }}
            />
          ) : null}
        </Wrapper>
      </WrapperWithLabel>
      {error ? (
        <Text color="dangerPrimary" typography="footNote12Regular">
          {error}
        </Text>
      ) : null}
    </Flex>
  );
};
