import React, {useState} from 'react';
import {Button} from 'shared/ui/Button';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';
import {AddCertificatePopup} from './AddCertificatePopup';
import {device} from 'shared/device';
import {EmptyWhiteContent} from 'shared/illustration/EmptyWriteContent';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 58px;
  @media (${device.mobile}) {
    margin-left: 0;
    align-self: center;
    justify-content: center;
    padding: 16px;
  }
`;
export const EmptyState = ({customerId}: {customerId: string}) => {
  const [modal, setModal] = useState(false);

  return (
    <Wrapper>
      <AddCertificatePopup
        customerId={customerId}
        visible={modal}
        onClose={() => {
          setModal(false);
        }}
      />
      <EmptyWhiteContent size={300} />
      <Text
        typography="text16Regular"
        color="textTertiary"
        style={{
          textAlign: 'center',
          alignSelf: 'center',
          maxWidth: 500,
          marginTop: 16,
        }}>
        У данного клиента пока нет сертификатов
      </Text>
      <Button
        size="large"
        onClick={() => setModal(true)}
        style={{
          marginTop: 24,
          alignSelf: 'center',
          width: 400,
          marginBottom: 24,
        }}>
        Добавить сертификат
      </Button>
    </Wrapper>
  );
};
