import React from 'react';
import {useNavigate} from 'react-router-dom';
import {device} from 'shared/device';
import {ArrowLeft2v} from 'shared/icons/ArrowLeft2v';
import {useColors, useResize} from 'shared/lib/hooks';
import {Content} from 'shared/ui/Content';
import {Divider} from 'shared/ui/Divider';
import {IconButton} from 'shared/ui/IconButton';
import {Layout} from 'shared/ui/Layout';
import {NavLink} from 'shared/ui/NavLink';
import {Seo} from 'shared/ui/Seo';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  grid-column-start: 4;
  grid-column-end: 10;
  @media ${device.mobile} {
    gap: 16px;
    padding: 0;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;

  @media ${device.mobile} {
    justify-content: space-between;
    gap: 16px;
    height: 56px;
    padding: 0 16px;
    background-color: ${({theme}) => theme.bgPrimary};
  }
`;

export const ProfileMobileRoot = () => {
  const navigate = useNavigate();
  const {isMobile} = useResize();
  const colors = useColors();
  return (
    <Layout>
      <Seo title="Профиль" />
      <Wrapper>
        <Header>
          <IconButton
            size={40}
            style={{
              borderColor: isMobile ? 'transparent' : colors.borderPrimary,
            }}
            onClick={() => {
              navigate(-1);
            }}>
            <ArrowLeft2v />
          </IconButton>
          <Text typography={isMobile ? 'text16Semibold' : 'title24'}>
            Профиль
          </Text>
          <div style={{width: 44}} />
        </Header>
        <Content>
          <NavLink
            label={'Редактировать профиль'}
            onClick={() => navigate('/profile-set')}
          />
          <Divider />
          <NavLink
            label={'Создать пароль'}
            onClick={() => navigate('/password-set')}
          />
          <Divider />
          <NavLink
            label={'Изменить номер'}
            onClick={() => navigate('/phone-change')}
          />
        </Content>
      </Wrapper>
    </Layout>
  );
};
