import React from 'react';
import {Content} from 'shared/ui/Content';
import {Text} from 'shared/ui/Text';
import {ApolloError, useMutation, useQuery} from '@apollo/client';
import {gql} from 'shared/__generated__';
import {useAppSelector} from 'shared/store';
import {List} from 'shared/ui/List';
import {ModerationsCheckStatusOfModerationsQuery} from 'shared/__generated__/graphql';
import dayjs from 'dayjs';
import {Error as ErrorComponent} from 'shared/ui/Error';
import {EmptyTime} from 'shared/illustration/EmptyTime';
import {Button} from 'shared/ui/Button';
import {Flex} from 'shared/ui/Flex';
import {ModerationCard} from './ui/Card';
import {ModerationSkeleton} from './ui/Skeleton';
import {showAlert} from 'shared/ui/Alert';

const MODERATION_CREATE = gql(`
  mutation CreateModeration($companyId: String!) {
    createModeration(company_id: $companyId) {
      id
    }
  }
`);

const MODERATION = gql(`
  query ModerationsCheckStatusOfModerations($companyId: String!) {
    moderations(company_id: $companyId) {
      comment
      id
      status
      created_at
    }
    moderationsCheck(company_id: $companyId) {
      result
      is_employee_added
      is_photo_added
      is_employee_with_specialization_added
      is_required_items_filled
      is_service_added
    }
  }
`);

export function StatusOfModeration() {
  const companyId = useAppSelector(state => state.company.data!.id);
  const {loading, error, data} = useQuery(MODERATION, {
    variables: {companyId: companyId},
  });
  const [submitModeration] = useMutation(MODERATION_CREATE, {
    refetchQueries: ['ModerationsCheckStatusOfModerations'],
  });

  const isValid =
    data?.moderationsCheck && data.moderationsCheck[0] !== null
      ? !data?.moderationsCheck[0].result.includes(false)
      : false;
  const list: ModerationsCheckStatusOfModerationsQuery | undefined = data;
  const moderations = list?.moderations.slice();

  const handlerSubmit = async () => {
    await submitModeration({
      variables: {
        companyId: companyId,
      },
    })
      .then(() => {
        showAlert('success', 'Заявка успешно отправлена!');
      })
      .catch((error: ApolloError) => {
        showAlert('error', error.message);
      });
  };
  if (moderations) {
    moderations.sort((a, b) => {
      return dayjs(a.created_at).isBefore(dayjs(b.created_at)) ? 1 : -1;
    });
  }

  if (error) {
    return <ErrorComponent message={'Не удалось проверить вашу организацию'} />;
  }

  return (
    <Flex gap={32} direction="column">
      <Flex>
        {loading ? (
          <ModerationSkeleton />
        ) : (
          <List
            keyExtractor={item => item.id}
            gap={16}
            placeholder={
              <Content
                style={{
                  gap: 40,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <EmptyTime size={300} />
                <Text
                  typography="text16Regular"
                  color="textTertiary"
                  align="center">
                  Ваша заявка будет рассмотрена в течение 24 часов. Как только
                  изменится статус, вы получите уведомление.
                </Text>
              </Content>
            }
            data={moderations}
            renderItem={item => <ModerationCard item={item} />}
          />
        )}
      </Flex>
      {list?.moderations.length !== 0 && (
        <Button disabled={!isValid} onClick={handlerSubmit} size="large">
          Отправить повторно
        </Button>
      )}
    </Flex>
  );
}
