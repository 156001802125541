import {useMutation} from '@apollo/client';
import dayjs from 'dayjs';
import React, {useEffect, useState} from 'react';
import {gql} from 'shared/__generated__';
import {
  Company,
  Employee,
  Image,
  Reply,
  Review as ReviewGraph,
  User,
} from 'shared/__generated__/graphql';
import {ensureError, getName} from 'shared/lib/utils';
import {Avatar} from 'shared/ui/Avatar';
import {BottomSheet} from 'shared/ui/BottomSheet';
import {Button} from 'shared/ui/Button';
import {CheckBox} from 'shared/ui/CheckBox';
import {Divider} from 'shared/ui/Divider';
import {Image as CommentImage} from 'shared/ui/Image';
import {RatingStar} from 'shared/ui/RatingStar';
import {Text} from 'shared/ui/Text';
import {TextArea} from 'shared/ui/TextArea';
import styled from 'styled-components';
import {EmojiLove} from 'shared/icons/EmojiLove';
import {EmojiSmileFace} from 'shared/icons/EmojiSmileFace';
import {EmojiAngry} from 'shared/icons/EmojiAngry';
import {ImageView} from 'shared/ui/ImageView';
import {Flex} from 'shared/ui/Flex';
import {showAlert} from 'shared/ui/Alert';
import {generateReviewReply} from 'shared/api/chatGPT';
import {IconButton} from 'shared/ui/IconButton';
import {useColors} from 'shared/lib/hooks';
import {device} from 'shared/device';
import Xv2 from 'shared/icons/Xv2';

const CREATE_REPLY = gql(`
  mutation CreateReply($input: CreateReply!) {
    createReply(input: $input) {
      id
    }
  }
`);

const UPDATE_REPLY = gql(`
  mutation UpdateReply($input: UpdateReply!) {
    updateReply(input: $input) {
      id
    }
  }
`);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  width: 668px;
  gap: 24px;
  @media (${device.mobile}) {
    gap: 16px;
  }
`;

const HidingIconButton = styled(IconButton)`
  @media (max-width: 768px) {
    display: none;
  }
`;

const Body = styled.div<{permission: boolean}>`
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: scroll;
  max-height: calc(
    100vh - ${({permission}) => (permission ? '60px' : '0px')} - 192px
  );
  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
  @media (${device.mobile}) {
    gap: 16px;
  }
`;

const ReplyAs = styled(Text)`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;

type Review = Pick<ReviewGraph, 'id' | 'created_at' | 'comment' | 'rating'> & {
  user: Pick<User, 'id' | 'name' | 'surname' | 'avatar'>;
  company: Pick<Company, 'id' | 'avatar' | 'name'>;
  employee?: Pick<Employee, 'id' | 'name' | 'surname' | 'avatar'> | null;
  images: Pick<Image, 'id' | 'url'>[];
  reply?: Pick<Reply, 'id' | 'as_company' | 'text' | 'updated_at'> | null;
  workingdate?: string;
  professionName?: string;
};

export const ReviewModal = ({
  onClose,
  visible,
  review,
  workingdate,
  professionName,
  permission,
}: {
  onClose: () => void;
  visible: boolean;
  review?: Review;
  workingdate: string;
  professionName: string;
  permission?: boolean;
}) => {
  const colors = useColors();
  const [reply, setReply] = useState('');
  const [asCompany, setAsCompany] = useState(false);
  const [onEdit, setOnEdit] = useState(true);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [photoView, setPhotoView] = useState(false);
  const [generateLoading, setGenerateLoading] = useState(false);

  const rating = review?.rating ?? 0;

  const icon =
    rating === 5 ? (
      <EmojiLove />
    ) : rating! > 2 ? (
      <EmojiSmileFace />
    ) : (
      <EmojiAngry />
    );

  const [createReply, {loading: loadingCreate}] = useMutation(CREATE_REPLY, {
    refetchQueries: ['ReviewsMainReviews'],
  });
  const [updateReply, {loading: loadingUpdate}] = useMutation(UPDATE_REPLY, {
    refetchQueries: ['ReviewsMainReviews'],
  });

  const replyCreate = async () => {
    const input = {
      as_company: asCompany,
      review_id: review?.id ?? '',
      text: reply,
    };
    try {
      await createReply({
        variables: {
          input,
        },
      });
      showAlert('success', 'Ответ отправлен');
      setReply('');
      setAsCompany(false);
    } catch (e) {
      const error = ensureError(e);
      showAlert('error', error.message);
    } finally {
      onClose();
    }
  };

  const replyUpdate = async () => {
    const input = {
      as_company: asCompany,
      reply_id: review?.reply?.id ?? '',
      text: reply,
    };
    try {
      await updateReply({
        variables: {
          input,
        },
      });
      showAlert('success', 'Ответ отправлен');
      setReply('');
      setAsCompany(false);
    } catch (e) {
      const error = ensureError(e);
      showAlert('error', error.message);
    } finally {
      onClose();
    }
  };

  const onPressGenerateGPT = () => {
    setGenerateLoading(true);
    setOnEdit(true);
    generateReviewReply(
      review?.comment ?? '',
      rating,
      asCompany ? 'company' : 'employee',
    )
      .then(res => {
        setReply(res.data.choices[0].message.content ?? '');
      })
      .catch(e => {
        const error = ensureError(e);
        showAlert('error', error.message);
      })
      .finally(() => setGenerateLoading(false));
  };

  useEffect(() => {
    if (review?.reply?.text !== undefined) {
      setReply(review.reply.text);
    }
  }, [review?.reply?.text]);

  useEffect(() => {
    if (review?.reply?.as_company !== undefined) {
      setAsCompany(review.reply.as_company);
      setOnEdit(review.reply ? false : true);
    }
  }, [review?.reply?.as_company, review?.reply]);

  useEffect(() => {
    if (visible === false) {
      setReply('');
    }
  }, [visible]);

  if (!review) return null;

  return (
    <>
      <BottomSheet
        style={{
          padding: '24px 0',
          maxHeight: 'calc(100vh - 48px)',
        }}
        wrapperStyle={{
          transform: 'translate(-50%, -50%)',
        }}
        backdropStyle={{
          backgroundColor: photoView ? 'transparent' : colors.modalOverlay,
        }}
        visible={visible}
        onClose={onClose}>
        <Wrapper>
          <Flex alignItems="center" justifyContent="space-between" flex={1}>
            <Text typography="title20">Отзыв</Text>
            <HidingIconButton
              type="modal"
              size={40}
              onClick={() => {
                onClose();
                setReply('');
                setAsCompany(false);
              }}>
              <Xv2 />
            </HidingIconButton>
          </Flex>
          <Body permission={permission ?? false}>
            <Flex alignItems="center" justifyContent="space-between">
              <Flex flex={1} alignItems="center" gap={16}>
                <Avatar
                  icon={icon}
                  variant="avatar"
                  size={72}
                  url={review.user.avatar?.url}
                />
                <Flex direction="column" flex={1} gap={8}>
                  <Text typography="title20">
                    {getName(review.user.name, review.user.surname)}
                  </Text>
                  <RatingStar list rating={review.rating} />
                </Flex>
              </Flex>
              <Text color="textTertiary">
                {dayjs(review.created_at).format('DD.MM.YYYY, HH:mm')}
              </Text>
            </Flex>
            <Text>{review.comment}</Text>
            <Flex style={{gap: 8}}>
              {review.images?.map((image, imageIndex) => (
                <CommentImage
                  style={{marginTop: 16}}
                  radius={12}
                  key={'review.image' + image.id}
                  src={image.url}
                  onClick={() => {
                    setPhotoIndex(imageIndex);
                    setPhotoView(true);
                  }}
                  width={62}
                  height={62}
                />
              ))}
            </Flex>
            <Divider />
            <Flex direction="column" gap={16}>
              <Text typography="text16Semibold">Детали</Text>
              <Flex alignItems="center" justifyContent="space-between">
                <Text style={{flex: 1}} color="textSecondary">
                  Сотрудник
                </Text>
                <Flex justifyContent="space-between" flex={1} gap={16}>
                  <Avatar
                    variant="avatar"
                    size={56}
                    url={review.employee?.avatar?.url}
                  />
                  <Flex direction="column" flex={1} gap={8} style={{gap: 8}}>
                    <Text>
                      {getName(
                        review.employee?.name,
                        review.employee?.surname,
                        'employee',
                      )}
                    </Text>
                    <Text typography="subHead14Regular" color="textSecondary">
                      {professionName}
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
              <Flex justifyContent="space-between">
                <Text style={{flex: 1}} color="textSecondary">
                  Запись
                </Text>
                <Text style={{flex: 1}} color="mainPrimary">
                  {'запись от ' + dayjs(workingdate).format('DD.MM.YYYY')}
                </Text>
              </Flex>
            </Flex>
            <Divider />
            <Text typography="text16Semibold">Ответ на отзыв</Text>
            {(permission && onEdit) || review.reply === null ? (
              <>
                <TextArea
                  maxLength={1000}
                  size="large"
                  value={reply}
                  onChange={setReply}
                />
                <ReplyAs>
                  <CheckBox
                    style={{paddingLeft: 12}}
                    checked={asCompany}
                    onChange={() => setAsCompany(!asCompany)}
                  />
                  Ответить от имени организации
                </ReplyAs>
              </>
            ) : (
              <>
                <Flex alignItems="center" gap={8}>
                  <Avatar
                    variant={review.reply?.as_company ? 'company' : 'avatar'}
                    url={
                      review.reply?.as_company
                        ? review.company.avatar?.url
                        : review.employee?.avatar?.url
                    }
                  />
                  <Flex direction="column" flex={1}>
                    <Text>
                      {review.reply?.as_company
                        ? review.company.name
                        : getName(
                            review.employee?.name,
                            review.employee?.surname,
                            'employee',
                          )}
                    </Text>
                    <Text typography="subHead14Regular" color="textSecondary">
                      {professionName}
                    </Text>
                  </Flex>
                </Flex>
                {review.reply?.text && <Text>{review.reply?.text}</Text>}
                <Flex justifyContent="space-between">
                  <Text typography="subHead14Regular" color="textSecondary">
                    {review.reply?.as_company
                      ? 'Ответ от имени организации'
                      : 'Ответ от сотрудника организации'}
                  </Text>
                  <Text typography="subHead14Regular" color="textSecondary">
                    {dayjs(review.reply?.updated_at).format(
                      'DD.MM.YYYY, HH:mm',
                    )}
                  </Text>
                </Flex>
              </>
            )}
          </Body>
          {permission ? (
            <Flex justifyContent="space-between" flex={1} gap={8}>
              <Button
                loading={generateLoading}
                style={{flex: 1}}
                size="large"
                variant="outline"
                title="Сгенерировать ответ"
                typography="text16Semibold"
                onClick={() => {
                  onPressGenerateGPT();
                }}>
                Сгенерировать ответ
              </Button>
              {review.reply !== null ? (
                <Button
                  loading={loadingUpdate}
                  disabled={!reply}
                  style={{flex: 1}}
                  size="large"
                  title="Редактировать ответ"
                  variant="filled"
                  typography="text16Semibold"
                  onClick={() => (onEdit ? replyUpdate() : setOnEdit(true))}>
                  {onEdit ? 'Отправить ответ' : 'Редактировать ответ'}
                </Button>
              ) : (
                <Button
                  disabled={!reply}
                  loading={loadingCreate}
                  style={{flex: 1}}
                  size="large"
                  title="Отправить ответ"
                  variant="filled"
                  typography="text16Semibold"
                  onClick={() => replyCreate()}>
                  Отправить ответ
                </Button>
              )}
            </Flex>
          ) : (
            <></>
          )}
        </Wrapper>
      </BottomSheet>
      <ImageView
        visible={photoView}
        radius={14}
        onClose={() => setPhotoView(false)}
        images={review.images?.map(image => image.url)}
        index={photoIndex}
      />
    </>
  );
};
