import React, {useState} from 'react';
import {Popup} from 'shared/ui/Popup';
import {Flex} from 'shared/ui/Flex';
import {Text} from 'shared/ui/Text';
import {CloseButton} from 'shared/ui/CloseButton';
import {useColors} from 'shared/lib/hooks';
import {styled} from 'styled-components';
import {Download} from 'shared/icons/Download';
import {Loader} from 'shared/icons/Loader';
import {importCustomers} from 'shared/lib/api';
import {useAppSelector} from 'shared/store';
import {Button} from 'shared/ui/Button';

type Props = {
  visible?: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
};

type Status = 'success' | 'error' | null;
type Resonse = {
  count: number;
  failed: number;
} | null;

export const ImportModal = ({visible, onClose, onSuccess}: Props) => {
  const colors = useColors();
  const companyId = useAppSelector(state => state.company.data!.id);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState<Status>(null);
  const [progress, setProgress] = useState(0);
  const [response, setResponse] = useState<Resonse>(null);
  const [file, setFile] = useState<File | null>(null);

  async function onChange(value: File | null) {
    try {
      setProgress(25);
      setFile(value);
      if (value) {
        setFile(value);
        setStatus(null);
        setLoading(true);
        setProgress(50);
        const upload = await importCustomers(value, companyId);
        setResponse({
          count: upload.count,
          failed: upload.failed,
        });
        setProgress(100);
        setStatus('success');
        onSuccess && onSuccess();
      }
    } catch (error) {
      if (error instanceof Error) {
        console.log('error', error.message);
      }
      setStatus('error');
    } finally {
      setLoading(false);
    }
  }

  const reset = () => {
    setFile(null);
    setStatus(null);
    setResponse(null);
    setLoading(false);
    setProgress(0);
  };

  return (
    <Popup
      {...{visible}}
      onClose={() => {
        reset();
        onClose && onClose();
      }}>
      <Flex gap={24} style={{width: 672}} direction="column">
        <Flex direction="column" gap={8}>
          <Flex justifyContent="space-between" gap={8}>
            <Text typography="title20">Импорт клиентов</Text>
            <CloseButton
              onClose={() => {
                reset();
                onClose && onClose();
              }}
            />
          </Flex>
          {!status && !loading && (
            <Text color="textSecondary">
              <text>
                Загрузите файл XLSX с данными клиента или скачайте шаблон ниже и
                заполните данные.{' '}
                <a
                  style={{
                    color: colors.mainPrimary,
                    textDecoration: 'none',
                  }}
                  href="https://short.bookydev.co/customers_import"
                  target="_blank"
                  rel="noreferrer">
                  Подробнее
                </a>
              </text>
            </Text>
          )}
        </Flex>
        {loading ? (
          <Text align="center" typography="text16Semibold">
            Файл загружается...
          </Text>
        ) : status === 'error' ? (
          <Text align="center" typography="text16Semibold">
            Загрузка файла не удалась
          </Text>
        ) : status === 'success' ? (
          <Flex direction="column" gap={16}>
            <Text align="center" typography="text16Semibold">
              Файл загружен успешно!
              {response?.count ? (
                <Text align="center" typography="text16Semibold">
                  Импортировано {response.count} клиентов
                </Text>
              ) : null}
            </Text>
            {response?.failed ? (
              <Text align="center" color="textTertiary">
                {response.failed} клиентов не удалось загрузить (номер телефона
                некорректный или пользователь с таким номером телефона уже есть
                в вашей клиентской базе)
              </Text>
            ) : null}
          </Flex>
        ) : (
          <></>
        )}
        <DragDrop
          {...{onChange, loading, status, progress}}
          name={file?.name}
        />
        {!status && !loading && (
          <Text color="textSecondary">
            Нет файла? Заполните наш шаблон с информацией о клиенте и загрузите.{' '}
            <Text
              style={{color: colors.mainPrimary, cursor: 'pointer'}}
              onClick={() => {
                const fileUrl =
                  process.env.PUBLIC_URL + '/customers_import_template.xlsx';
                const link = document.createElement('a');
                link.href = fileUrl;
                link.download = 'customers_import_template.xlsx';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }}>
              Скачать шаблон
            </Text>
          </Text>
        )}
        {status === 'success' && (
          <Flex style={{justifyContent: 'end'}}>
            <Button
              size="large"
              onClick={() => {
                reset();
                onClose && onClose();
              }}>
              Готово
            </Button>
          </Flex>
        )}
        {status === 'error' && (
          <Flex style={{justifyContent: 'end'}} gap={12}>
            <Button
              size="large"
              variant="outline"
              onClick={() => {
                reset();
                onClose && onClose();
              }}>
              Закрыть
            </Button>
            <Button
              size="large"
              onClick={() => {
                reset();
              }}>
              Повторить попытку
            </Button>
          </Flex>
        )}
      </Flex>
    </Popup>
  );
};

const Label = styled.label`
  pointer-events: all;
  cursor: pointer;
`;
const Layout = styled.div<{drag: boolean}>`
  display: flex;
  flex: 1;
  height: 520px;
  padding: 48px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 24px;
  border: 1px dashed ${({theme}) => theme.mainPrimary};
  background: ${({theme, drag}) =>
    drag ? theme.mainLight + '80' : theme.fillPrimary};
  &:hover {
    background: ${({theme}) => theme.mainLight + '80'};
  }
`;
const Input = styled.input`
  display: none;
`;
const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 14px;
  background-color: ${({theme}) => theme.bgSecondary};
  padding: 16px;
  gap: 24px;
`;
const ProgressBackground = styled.div<{status: Status}>`
  display: flex;
  width: 100%;
  height: 12px;
  border-radius: 6px;
  background-color: ${({theme, status}) =>
    status === 'error' ? theme.bgDanger : theme.bgSuccess};
`;
const Progress = styled.div<{status: Status; progress: number}>`
  display: inline;
  width: ${({progress, status}) => (status === 'error' ? 100 : progress)}%;
  height: 12px;
  border-radius: 6px;
  background-color: ${({theme, status}) =>
    status === 'error' ? theme.dangerPrimary : theme.successPrimary};
`;

type PropsDragDrop = {
  onChange: (file: File | null) => void;
  loading?: boolean;
  status: Status;
  name?: string;
  progress?: number;
};

export const DragDrop = ({
  onChange,
  loading,
  status,
  name,
  progress = 0,
}: PropsDragDrop) => {
  const [drag, setDrag] = useState(false);

  function onDragStart(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    setDrag(true);
  }
  function onDragLeave(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    setDrag(false);
  }
  function onDrop(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    const files = [...e.dataTransfer.files];
    onChange(files.length ? files[0] : null);
  }
  function handleFileUpload(e: React.ChangeEvent<HTMLInputElement>) {
    const files = e.target.files;
    const file = files?.length ? files[0] : null;
    onChange(file);
  }

  if (status || loading) {
    return (
      <InfoContainer>
        <Flex justifyContent="space-between" alignItems="flex-end">
          <Text typography="text16Semibold">{name}</Text>
          <Text color="textSecondary">
            {status === 'error' ? 'Ошибка' : progress + '%'}
          </Text>
        </Flex>
        <ProgressBackground status={status}>
          <Progress status={status} progress={progress} />
        </ProgressBackground>
      </InfoContainer>
    );
  }

  return (
    <Label>
      <Layout
        drag={drag}
        onDragStart={onDragStart}
        onDragLeave={onDragLeave}
        onDragOver={onDragStart}
        onDrop={e => {
          onDrop(e);
          e.dataTransfer.clearData();
        }}>
        <Input
          type="file"
          accept={'.xlsx, .csv'}
          onChange={e => {
            handleFileUpload(e);
            e.target.files = null;
          }}
        />
        {!loading ? <Download size={40} /> : <Loader size={40} />}
        <Text style={{cursor: 'pointer'}} color="mainPrimary">
          {!loading ? 'Загрузите .xlsx файл' : 'Загрузка файла'}
        </Text>
      </Layout>
    </Label>
  );
};
