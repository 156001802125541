import React from 'react';
import {device} from 'shared/device';
import {Skeleton} from 'shared/ui/Skeleton';
import styled from 'styled-components';
import {Content} from 'shared/ui/Content';

const Container = styled(Content)`
  padding: 16px;
  background: none;
  width: 720px;
  background-color: ${props => props.theme.bgPrimary};
  border-width: 1px;
  padding: 24px;
`;

const RectName = styled.rect`
  display: flex;
  @media ${device.mobile} {
    display: none;
  }
`;
const CircleName = styled.circle`
  display: flex;
  @media ${device.mobile} {
    display: none;
  }
`;
const row = Array.from(Array(2).keys());

export const CustomerCertificatesSkeleton = () => {
  return (
    <>
      <Container>
        <Skeleton height={392}>
          {row.map((row, i) => (
            <React.Fragment key={'row_' + row}>
              <RectName width={135} height={18} rx={9} x={0} y={6 + 228 * i} />
              <CircleName r={9} cx={574} cy={20 + 228 * i} />
              <CircleName r={9} cx={622} cy={20 + 228 * i} />
              <RectName width={65} height={12} rx={9} x={0} y={64 + 228 * i} />
              <RectName width={153} height={14} rx={9} x={0} y={96 + 228 * i} />
              <RectName
                width={60}
                height={12}
                rx={9}
                x={204}
                y={64 + 228 * i}
              />
              <RectName
                width={72}
                height={14}
                rx={9}
                x={204}
                y={96 + 228 * i}
              />
              <RectName
                width={80}
                height={12}
                rx={9}
                x={378}
                y={64 + 228 * i}
              />
              <RectName
                width={60}
                height={14}
                rx={9}
                x={378}
                y={96 + 228 * i}
              />
              <RectName
                width={64}
                height={12}
                rx={9}
                x={543}
                y={64 + 228 * i}
              />
              <RectName
                width={64}
                height={14}
                rx={9}
                x={543}
                y={96 + 228 * i}
              />
              <RectName width={72} height={14} rx={9} x={0} y={150 + 228 * i} />
            </React.Fragment>
          ))}
        </Skeleton>
      </Container>
      <Skeleton>
        <RectName width={720} height={64} rx={14} x={0} y={0} />
      </Skeleton>
    </>
  );
};
