import dayjs from 'dayjs';
import React from 'react';
import styled from 'styled-components';

const BodyYearWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 0 16px;
  gap: 16px;
  height: 409px;
  overflow-y: scroll;
`;

const YearView = styled.div<{current: boolean}>`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 54px;
  cursor: pointer;
  pointer-events: all;
  border-radius: 27px;
  color: ${({theme, current}) => (current ? theme.white : theme.textPrimary)};
  background-color: ${({theme, current}) =>
    current ? theme.mainPrimary : 'transparent'};
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-transform: capitalize;
  &:hover {
    background-color: ${({theme, current}) =>
      current ? theme.mainPrimary : theme.mainLight50};
  }
`;

export const Years = ({
  date,
  onSelect,
}: {
  date: string;
  onSelect: (newYear: number) => void;
}) => {
  const years = Array.from(
    {length: dayjs().get('year') - 2000},
    (_, i) => dayjs().get('year') - i,
  );
  return (
    <BodyYearWrapper>
      {years.map(year => (
        <YearView
          current={dayjs(date).format('YYYY') === year.toString()}
          onClick={() => onSelect(year)}
          key={year.toString()}>
          {year}
        </YearView>
      ))}
    </BodyYearWrapper>
  );
};
