import {gql} from 'shared/__generated__';

export {SelectProfessions} from './ui/SelectProfessions';

export const GET_COMPANY_EMPLOYEES = gql(`
  query CompanyEmployees(
    $first: Int!
    $companyId: String!
    $profession:[String]
    $enabled: Boolean
    $page: Int
    $query: String
    $role: CompanyRoles
    $orderBy: [QueryCompanyEmployeesOrderByOrderByClause!]
  ) {
    companyEmployees(
      first: $first
      company_id: $companyId
      profession: $profession
      enabled: $enabled
      page: $page
      query: $query
      role: $role
      orderBy: $orderBy
    ) {
      data {
        id
        name
        surname
        phone
        invite_status
        enabled
        role
        avatar {
          id
          url
          url_256
        }
        professions {
          id
          name
        }
        services(first: 1) {
          paginatorInfo {
            total
          }
        }
        rating_1_count
        rating_2_count
        rating_3_count
        rating_4_count
        rating_5_count
        user {
          id
          is_online
        }
      }
      paginatorInfo {
        currentPage
        hasMorePages
        lastPage
        total
        count
      }
    }
    ...Permissions
  }
`);
export const DELETE_EMPLOYEE = gql(`
  mutation DeleteEmployee($deleteEmployeeId: String!) {
    deleteEmployee(id: $deleteEmployeeId) {
      id
    }
  }
`);

export const EMPLOYEE_PERMISSIONS = gql(`
  query GetEmployeePermissions($employeeId: String!, $companyId: String!) {
    company(id: $companyId) {
      roles {
        id
        role
        add_to_customers_bonus_card_permission
        add_to_customers_certificate_permission
        contact_permission
        create_bookings_permission
        create_customers_permission
        create_invitation_permission
        customers_info_permission
        edit_apps_permission
        edit_bonus_card_permission
        edit_bonus_card_templates
        edit_bookings_permission
        edit_certificate_permission
        edit_certificate_templates
        edit_company_permission
        edit_company_services_permission
        edit_customers_permission
        edit_discounts_permission
        edit_employees_permission
        edit_work_schedule_permission
        employees_info_permission
        fill_bonus_card_permission
        moderation_permission
        notation_permission
        reports_permission
        reviews_info_permission
        reviews_reply_permission
        subscription_and_pay_permission
        view_service_permission
        work_schedule_info_permission
        bookings_info_permission
        messages_permission
        delete_messages_permission
        view_products_permission
        edit_products_permission
        salaries_permission
        message_sample_permission
        webhook_permission
      }
    }
    getEmployeePermission(employee_id: $employeeId) {
      role
      add_to_customers_bonus_card_permission {
        is_default
        permission
      }
      add_to_customers_certificate_permission {
        is_default
        permission
      }
      bookings_info_permission {
        is_default
        permission
      }
      contact_permission {
        is_default
        permission
      }
      create_bookings_permission {
        is_default
        permission
      }
      create_customers_permission {
        is_default
        permission
      }
      create_invitation_permission {
        is_default
        permission
      }
      customers_info_permission {
        is_default
        permission
      }
      edit_apps_permission {
        is_default
        permission
      }
      edit_bonus_card_permission {
        is_default
        permission
      }
      edit_bonus_card_templates {
        is_default
        permission
      }
      edit_bookings_permission {
        is_default
        permission
      }
      edit_certificate_permission {
        is_default
        permission
      }
      edit_certificate_templates {
        is_default
        permission
      }
      edit_company_permission {
        is_default
        permission
      }
      edit_company_services_permission {
        is_default
        permission
      }
      edit_customers_permission {
        is_default
        permission
      }
      edit_discounts_permission {
        is_default
        permission
      }
      edit_employees_permission {
        is_default
        permission
      }
      edit_work_schedule_permission {
        is_default
        permission
      }
      employees_info_permission {
        is_default
        permission
      }
      fill_bonus_card_permission {
        is_default
        permission
      }
      moderation_permission {
        is_default
        permission
      }
      notation_permission {
        is_default
        permission
      }
      reports_permission {
        is_default
        permission
      }
      reviews_info_permission {
        is_default
        permission
      }
      reviews_reply_permission {
        is_default
        permission
      }
      subscription_and_pay_permission {
        is_default
        permission
      }
      view_service_permission {
        is_default
        permission
      }
      work_schedule_info_permission {
        is_default
        permission
      }
      delete_messages_permission {
        is_default
        permission
      }
      edit_products_permission {
        is_default
        permission
      }
      message_sample_permission {
        is_default
        permission
      }
      messages_permission {
        is_default
        permission
      }
      salaries_permission {
        is_default
        permission
      }
      view_products_permission {
        is_default
        permission
      }
      webhook_permission {
        is_default
        permission
      }
    }
  }
`);

export const CREATE_EMPLOYEE_PERMISSIONS = gql(`
  mutation CreateEmployeePermission($input: CreateCompanyPermissions) {
    createEmployeePermission(input: $input) {
      id
    }
  }
`);
