import {ApolloError, useMutation, useQuery} from '@apollo/client';
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {gql} from 'shared/__generated__';
import {CircleClose} from 'shared/icons/CircleClose';
import {useAppSelector} from 'shared/store';
import {Button} from 'shared/ui/Button';
import {Content} from 'shared/ui/Content';
import {IconButton} from 'shared/ui/IconButton';
import {Layout} from 'shared/ui/Layout';
import {MultiSelectChips} from 'shared/ui/MultiSelectChips';
import {Switch} from 'shared/ui/Switch';
import {Text} from 'shared/ui/Text';
import {TextInput} from 'shared/ui/TextInput';
import styled from 'styled-components';
import {GET_BONUSES, SelectServices} from 'entities/loyalty';
import {showAlert} from 'shared/ui/Alert';
import {TextArea} from 'shared/ui/TextArea';
import {Flex} from 'shared/ui/Flex';
import {NavigateBack} from 'shared/ui/NavigateBack';
import {Seo} from 'shared/ui/Seo';
import {EmptyBlockedState} from 'shared/illustration/EmptyBlockedState';

const PAYMENT_METHODS = gql(`
  query CompanyBonusPaymentMethod($companyId: String) {
    company(id: $companyId) {
      paymentMethods {
        id
        title
      }
    }
  }
`);

const CREATE_BONUS = gql(`
  mutation Mutation($input: CreateBonusCardTemplate!) {
    createBonusCardTemplate(input: $input) {
      id
    }
  }
`);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-column-start: 4;
  grid-column-end: 10;
  padding-bottom: 24px;
  gap: 24px;
`;

const Head = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  background-color: ${({theme}) => theme.bgSecondary};
  z-index: 100;
  padding-top: 64px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`;

const WrapperBonusSize = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`;

const WrapperSwitch = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  border: none;
  background-color: transparent;
  pointer-events: all;
  cursor: pointer;
`;

export const CreateBonus = () => {
  const navigate = useNavigate();
  const companyId = useAppSelector(state => state.company.data!.id);
  const [title, setTitle] = useState('');
  const [bonusSizes, setBonusSizes] = useState<
    {min_amount: string; size: string}[]
  >([
    {
      min_amount: '',
      size: '',
    },
  ]);
  const [paymentLimit, setPaymentLimit] = useState('');
  const {data} = useQuery(PAYMENT_METHODS, {
    variables: {
      companyId,
    },
  });
  const [services, setServices] = useState<{label: string; value: string}[]>(
    [],
  );
  const [description, setDescription] = useState('');
  const paymentMethods =
    data?.company?.paymentMethods.map(item => ({
      label: item.title,
      value: item.id,
    })) ?? [];
  const [selectPayments, setSelectPayments] = useState<
    {label: string; value: string}[]
  >([]);
  const [activeMonths, setActiveMonths] = useState('');
  const [autoAssigned, setAutoAssigned] = useState(false);
  const [isRechargeable, setIsRechargeable] = useState(false);
  const [createBonus, {loading}] = useMutation(CREATE_BONUS, {
    refetchQueries: [
      {
        query: GET_BONUSES,
        variables: {
          page: 1,
          first: 40,
          companyId: companyId,
        },
      },
    ],
  });
  const [errorStatus, setErrorStatus] = useState<{
    title: string | null;
    paymentLimit: string | null;
  }>({
    title: null,
    paymentLimit: null,
  });

  return (
    <Layout columns={12}>
      <Seo title="Создание бонусной карты" />
      <Wrapper>
        <Head>
          <NavigateBack />
          <Text typography="title24">Создание бонусной карты</Text>
        </Head>
        <Content style={{marginTop: 8}} gap="24px">
          <Text typography="title18">Название карты</Text>
          <TextInput
            value={title}
            onChange={text => {
              setTitle(text);
              setErrorStatus({...errorStatus, title: null});
            }}
            label="Название"
            error={errorStatus.title}
          />
        </Content>
        <Content gap="24px">
          <Text typography="title18">Размер бонуса</Text>
          <Flex direction="column" gap={16}>
            {bonusSizes.map((item, index) => (
              <WrapperBonusSize key={'bonus_size_' + index}>
                <TextInput
                  style={{flex: 1}}
                  value={item.min_amount}
                  wrapperStyle={{width: '100%'}}
                  variant="default"
                  mask="999999"
                  onChange={text => {
                    setBonusSizes(
                      bonusSizes.map((item2, index2) => {
                        if (index === index2) {
                          return {min_amount: text, size: item2.size};
                        }
                        return item2;
                      }),
                    );
                  }}
                  leftElement={
                    <Text typography="subHead14Regular" color="textTertiary">
                      От
                    </Text>
                  }
                />
                <TextInput
                  wrapperStyle={{width: '100%'}}
                  value={item.size}
                  variant="default"
                  mask="999"
                  onChange={text => {
                    if (+text <= 100) {
                      setBonusSizes(
                        bonusSizes.map((item2, index2) => {
                          if (index === index2) {
                            return {min_amount: item2.min_amount, size: text};
                          }
                          return item2;
                        }),
                      );
                    }
                  }}
                  rightElement={
                    <Text typography="subHead14Regular" color="textTertiary">
                      %
                    </Text>
                  }
                />
                <IconButton
                  style={{
                    backgroundColor: 'transparent',
                    borderColor: 'transparent',
                  }}
                  onClick={() => {
                    setBonusSizes(
                      bonusSizes.filter((_, index2) => index2 !== index),
                    );
                  }}>
                  <CircleClose />
                </IconButton>
              </WrapperBonusSize>
            ))}
            {bonusSizes.length < 5 && (
              <Text
                typography="subHead14Medium"
                color="mainPrimary"
                onClick={() => {
                  setBonusSizes(
                    bonusSizes.concat([{size: '', min_amount: ''}]),
                  );
                }}>
                + Размер бонуса
              </Text>
            )}
          </Flex>
        </Content>
        <Content gap="24px">
          <Text typography="title18">Исключения по услугам</Text>
          <MultiSelectChips
            label="Исключения по услугам"
            values={services}
            modal={(visible, setVisible) => (
              <SelectServices
                label="Исключения по услугам"
                visible={visible}
                values={services}
                onChange={values => {
                  setServices(values);
                  setVisible(false);
                }}
                onClose={() => setVisible(false)}
              />
            )}
            onDelete={id =>
              setServices(
                services.filter(selectItem => selectItem.value !== id),
              )
            }
          />
        </Content>
        <Content gap="24px">
          <Text typography="title18">Исключения по методам оплаты</Text>
          <MultiSelectChips
            style={{position: 'relative'}}
            data={paymentMethods}
            label="Исключения по методам оплаты"
            values={selectPayments}
            onDelete={id =>
              setSelectPayments(
                selectPayments.filter(selectItem => selectItem.value !== id),
              )
            }
            onChange={values => setSelectPayments(values)}
            ListEmptyComponent={
              <Flex
                direction="column"
                alignItems="center"
                gap={8}
                justifyContent="center"
                style={{marginBottom: 76}}>
                <EmptyBlockedState size={300} />
                <Text
                  align="center"
                  typography="text16Regular"
                  color="textTertiary">
                  Вы пока не выбрали методы оплаты для своей организации.
                  <br />
                  Добавьте хотя бы один метод оплаты
                </Text>
              </Flex>
            }
          />
          <TextInput
            value={paymentLimit}
            onChange={text => {
              if (+text <= 100) {
                setPaymentLimit(text);
                setErrorStatus({...errorStatus, paymentLimit: null});
              }
            }}
            required
            variant="label_animation"
            label="Ограничение платы бонусами"
            mask="999"
            rightElement={
              <Text typography="subHead14Regular" color="textTertiary">
                %
              </Text>
            }
            error={errorStatus.paymentLimit}
          />
          <Text
            typography="subHead14Regular"
            color="textTertiary"
            style={{transform: 'translateY(-16px)'}}>
            Определенный процент стоимости записи клиент может оплатить бонусами
          </Text>
        </Content>
        <Content gap="24px">
          <Text typography="title18">Дополнительные настройки</Text>
          <Flex direction="column" gap={8}>
            <TextInput
              variant="label_animation"
              value={activeMonths}
              onChange={setActiveMonths}
              mask="99"
              label="Срок действия"
              rightElement={
                <Text typography="subHead14Regular" color="textTertiary">
                  Мес
                </Text>
              }
            />
            <Text typography="subHead14Regular" color="textTertiary">
              0 - не ограничен
            </Text>
          </Flex>
          <TextArea
            label="Описание"
            value={description}
            onChange={setDescription}
            size="large"
            maxLength={1000}
          />
          <WrapperSwitch onClick={() => setAutoAssigned(state => !state)}>
            <Text style={{pointerEvents: 'none'}}>
              Автоприсвоение бонусных карт
            </Text>
            <Switch value={autoAssigned} />
          </WrapperSwitch>
          <WrapperSwitch onClick={() => setIsRechargeable(state => !state)}>
            <Text style={{pointerEvents: 'none'}}>
              Возможность пополнения бонусного счета
            </Text>
            <Switch value={isRechargeable} />
          </WrapperSwitch>
        </Content>
        <ButtonContainer>
          <Button
            size="large"
            loading={loading}
            disabled={loading}
            style={{flex: 1}}
            onClick={async () => {
              if (title !== '' || paymentLimit !== '') {
                try {
                  await createBonus({
                    variables: {
                      input: {
                        company_id: companyId,
                        active_months:
                          +activeMonths === 0 ? undefined : +activeMonths,
                        bonus_sizes: bonusSizes.map(item => ({
                          min_amount: +item.min_amount,
                          size: +item.size,
                        })),
                        description: description,
                        is_auto_assigned: autoAssigned,
                        is_rechargeable: isRechargeable,
                        name: title,
                        payment_limit: +paymentLimit,
                        payment_methods:
                          selectPayments.length > 0
                            ? selectPayments.map(
                                paymentItem => paymentItem.label,
                              )
                            : undefined,
                        is_active: true,
                        services:
                          services.length > 0
                            ? services.map(service => service.value)
                            : undefined,
                      },
                    },
                  });
                  navigate(-1);
                } catch (e) {
                  if (e instanceof ApolloError) {
                    showAlert('error', e.message);
                  }
                }
              } else {
                if (title === '') {
                  setErrorStatus({
                    ...errorStatus,
                    title: 'Это поле обязательно к заполнению',
                  });
                }
                if (paymentLimit === '') {
                  setErrorStatus({
                    ...errorStatus,
                    paymentLimit: 'Это поле обязательно к заполнению',
                  });
                }
              }
            }}>
            Сохранить
          </Button>
        </ButtonContainer>
      </Wrapper>
    </Layout>
  );
};
