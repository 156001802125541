import React from 'react';
import {Popup} from 'shared/ui/Popup';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';
import {Grow} from 'shared/ui/Grow';
import {Button} from 'shared/ui/Button';
import {CloseButton} from 'shared/ui/CloseButton';
import {useColors} from 'shared/lib/hooks';

const Row = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 392px;
`;
const Box = styled.div`
  display: flex;
  flex: 1;
  align-items: start;
  width: 280px;
`;

export const DeletePopup = ({
  visible,
  onClose,
  onClickDelete,
  loading,
}: {
  visible: boolean;
  onClose: () => void;
  onClickDelete: () => void;
  loading?: boolean;
}) => {
  const colors = useColors();
  return (
    <Popup {...{visible, onClose}}>
      <Grow style={{gap: 16}}>
        <Row>
          <Text typography="title20">Удалить сертификат</Text>
          <CloseButton
            onClose={() => {
              onClose && onClose();
            }}
          />
        </Row>
        <Box>
          <Text>Вы уверены, что хотите удалить этот сертификат?</Text>
        </Box>
        <Row style={{justifyContent: 'center', gap: 8}}>
          <Button
            style={{width: 192}}
            size="large"
            variant="outline"
            onClick={onClose}>
            <Text typography="text16Semibold" color="mainPrimary">
              Отмена
            </Text>
          </Button>
          <Button
            size="large"
            style={{borderColor: colors.dangerPrimary, width: 192}}
            loading={loading}
            disabled={loading}
            variant="outline"
            onClick={onClickDelete}>
            <Text typography="text16Semibold" color="dangerPrimary">
              Удалить
            </Text>
          </Button>
        </Row>
      </Grow>
    </Popup>
  );
};
