import React, {CSSProperties, useEffect, useRef, useState} from 'react';
import {ArrowRight1v} from 'shared/icons/ArrowRight1v';
import styled from 'styled-components';
import {Text} from './Text';
import dayjs from 'dayjs';
import {useColors} from 'shared/lib/hooks';
import {device} from 'shared/device';
import {IconButton} from './IconButton';
import {weekdays} from 'shared/lib/utils';
import {ArrowLeft1v} from 'shared/icons/ArrowLeft1v';
import {Flex} from './Flex';
import {showAlert} from './Alert';

const Wrapper = styled.div<{visible: boolean}>`
  display: ${({visible}) => (visible ? 'static' : 'none')};
  position: absolute;
  left: 0;
  background-color: ${({theme}) => theme.bgPrimary};
  padding: 16px;
  width: 100%;
  top: 64px;
  z-index: 1000;
  border-radius: 24px;
  flex-direction: column;
  border: 1px solid ${({theme}) => theme.borderPrimary};
  ${({theme}) => theme.cardShadow}
  @media ${device.mobile} {
    top: 64px;
    width: 100vw;
    left: 0px;
  }
`;

type Props = {
  visible: boolean;
  onClose: () => void;
  onChange: (value: string, valueEnd?: string) => void;
  value: string;
  valueEnd?: string;
  marked?: string[];
  style?: CSSProperties;
  variant?: 'day' | 'period' | 'week';
  hoverChanged?: boolean;
  input?: boolean;
  isBirthdate?: boolean;
};
export const DatePicker = (props: Props) => {
  const {
    style,
    visible,
    value,
    onClose,
    onChange,
    valueEnd,
    variant = 'day',
    hoverChanged = true,
    input = false,
    isBirthdate,
  } = props;

  const [headerIndex, setHeaderIndex] = useState(0);
  const [bodyType, setBodyType] = useState<'date' | 'month' | 'year'>('date');
  const [dayValue, setDayValue] = useState(value);
  const [hoverDate, setHoverDate] = useState(valueEnd);
  const [clearState, setClearState] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const checkDate =
    dayValue && dayValue.length > 0 && dayjs(dayValue).isValid()
      ? dayValue
      : undefined;
  const viewMonthDays = dayjs(checkDate)
    .add(headerIndex, bodyType === 'date' ? 'month' : 'year')
    .daysInMonth();
  const days = Array.from(Array(viewMonthDays), (_, index) =>
    dayjs(checkDate)
      .add(headerIndex, bodyType === 'date' ? 'month' : 'year')
      .set('date', index + 1)
      .format('YYYY-MM-DD'),
  );

  useEffect(() => {
    !dayValue && setDayValue(value);
  }, [dayValue, value]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const datePickRef = wrapperRef.current;
      if (datePickRef && !datePickRef.contains(event.target as Node)) {
        onClose();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose, wrapperRef]);

  const bodies = {
    date: (
      <Days
        days={days}
        value={value}
        input={input}
        valueEnd={variant !== 'day' ? valueEnd : undefined}
        hoverDate={
          variant === 'period' && value
            ? !hoverChanged
              ? valueEnd
              : hoverDate
            : undefined
        }
        isBirthdate={isBirthdate}
        variant={variant}
        onClick={date => {
          if (variant === 'day') {
            onChange(date);
            return;
          }
          if (variant === 'week') {
            onChange(
              dayjs(date).startOf('week').format('YYYY-MM-DD'),
              dayjs(date).endOf('week').format('YYYY-MM-DD'),
            );
          } else {
            if (clearState) {
              onChange(date, undefined);
              setClearState(false);
            } else {
              onChange(
                dayjs(value).isBefore(date) ? value : date,
                dayjs(date).isAfter(value) ? date : value,
              );
              setClearState(true);
            }
          }
        }}
        onHover={date => {
          if (variant === 'period' && hoverChanged) {
            setHoverDate(date);
          }
        }}
      />
    ),
    month: (
      <Months
        value={dayValue}
        onChange={value => {
          setDayValue(value);
          setHeaderIndex(0);
          setBodyType('date');
        }}
      />
    ),
    year: (
      <Years
        value={value}
        onChange={value => {
          setDayValue(dayjs(value).set('year', value).format('YYYY-MM-DD'));
          setBodyType('month');
        }}
      />
    ),
  };

  return (
    <Wrapper ref={wrapperRef} style={style} visible={visible}>
      <Header
        index={headerIndex}
        onChange={setHeaderIndex}
        onChangeBodyType={setBodyType}
        bodyType={bodyType}
        days={days}
      />
      {bodies[bodyType]}
    </Wrapper>
  );
};

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 46px;
  gap: 10px;
`;

const HeaderText = styled(Text)`
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  flex: 1;
  &:hover {
    background-color: ${({theme}) => theme.bgSecondary};
  }
`;

const Header = ({
  index,
  onChange,
  bodyType,
  onChangeBodyType,
  days,
}: {
  index: number;
  onChange: (value: number) => void;
  bodyType: 'date' | 'month' | 'year';
  onChangeBodyType: (value: 'date' | 'month' | 'year') => void;
  days: string[];
}) => {
  if (bodyType !== 'year') {
    return (
      <HeaderWrapper>
        <IconButton
          size={44}
          variant="unstyled"
          onClick={e => {
            e.stopPropagation();
            onChange(index - 1);
          }}>
          <ArrowLeft1v />
        </IconButton>
        <HeaderText
          typography="subHead14Medium"
          color="mainPrimary"
          align="center"
          style={{textTransform: bodyType === 'date' ? 'capitalize' : 'none'}}
          onClick={e => {
            e.stopPropagation();
            if (bodyType === 'date') {
              onChangeBodyType('month');
            } else {
              onChangeBodyType('year');
            }
          }}>
          {dayjs(days[0]).format(bodyType === 'date' ? 'MMMM YYYY' : 'YYYY')}
        </HeaderText>
        <IconButton
          size={44}
          variant="unstyled"
          onClick={e => {
            e.stopPropagation();
            onChange(index + 1);
          }}>
          <ArrowRight1v />
        </IconButton>
      </HeaderWrapper>
    );
  }
  return <></>;
};

const DaysWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 48px);
`;

const Day = styled.button<{selected: boolean}>`
  width: 36px;
  height: 36px;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  background-color: ${({theme, selected}) =>
    selected ? theme.mainPrimary : 'transparent'};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${({theme, selected}) => (selected ? theme.white : theme.textPrimary)};
  pointer-events: all;
  border: none;
  cursor: pointer;
  border: 1px solid transparent;
  &:hover {
    border-color: ${({theme}) => theme.mainPrimary};
  }
  z-index: 3;
`;

const Dot = styled.div<{active: boolean}>`
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: ${({active, theme}) =>
    active ? theme.white : theme.mainPrimary};
`;

const SelectedPeriod = styled.div<{gridColumnStart: number}>`
  display: flex;
  height: 48px;
  align-items: center;
  justify-content: center;
  pointer-events: all;
  cursor: pointer;
  grid-column-start: ${({gridColumnStart}) => gridColumnStart};
`;
const BackgroundDay = styled.div<{
  type: 'left' | 'middle' | 'right';
  active: boolean;
}>`
  background-color: ${({active, theme}) =>
    active ? theme.borderPrimary : 'transparent'};
  position: absolute;
  margin: 6px ${({type}) => (type === 'right' ? '24px' : 0)} 6px
    ${({type}) => (type === 'left' ? '24px' : 0)};
  width: ${({type}) => (type === 'middle' ? 48 : 24)}px;
  height: 36px;
`;
const BackgroundCircle = styled.div<{active: boolean}>`
  display: ${({active}) => (active ? 'flex' : 'none')};
  position: absolute;
  height: 36px;
  width: 36px;
  margin: 6px;
  background-color: ${({theme}) => theme.borderPrimary};
  border-radius: 18px;
`;

const getActive = (
  current: string,
  startDate: string | undefined,
  endDate: string | undefined,
  hoverDate: string | undefined,
): {active: boolean; type: 'left' | 'middle' | 'right'} => {
  if (!startDate) return {active: false, type: 'middle'};
  if (!endDate && !hoverDate) return {active: false, type: 'middle'};
  const formattedDay = dayjs(current);
  const startWeek = formattedDay.day() === 1;
  const endWeek = formattedDay.day() === 0;
  const firstMonth =
    formattedDay.startOf('month').format('YYYY-MM-DD') === current;
  const endMonth = formattedDay.endOf('month').format('YYYY-MM-DD') === current;
  const check = hoverDate ? endDate === hoverDate : true;
  if (endDate && check) {
    const isReversed = dayjs(startDate).isAfter(endDate);
    const start = dayjs(isReversed ? endDate : startDate);
    const end = dayjs(isReversed ? startDate : endDate);
    const isLeft =
      startWeek || firstMonth || current === start.format('YYYY-MM-DD');
    const isRight = endWeek || endMonth || current === end.format('YYYY-MM-DD');
    const type = !isLeft && !isRight ? 'middle' : isRight ? 'right' : 'left';
    const formattedStart = start.format('YYYY-MM-DD');
    const formattedEnd = end.format('YYYY-MM-DD');
    const isActive =
      formattedDay.isAfter(start.add(-1, 'd')) &&
      formattedDay.isBefore(end.add(1, 'd')) &&
      formattedStart !== formattedEnd;
    const formattedWeekStart = formattedDay
      .startOf('week')
      .format('YYYY-MM-DD');
    const formattedWeekEnd = formattedDay.endOf('week').format('YYYY-MM-DD');
    const checkLastWeekDay =
      formattedStart === formattedWeekEnd
        ? formattedStart !== formattedWeekEnd
        : true;
    const checkFirstWeekDay =
      formattedEnd === formattedWeekStart
        ? formattedEnd !== formattedWeekStart
        : true;
    return {
      active: checkLastWeekDay && checkFirstWeekDay && isActive,
      type,
    };
  }
  if (!hoverDate) return {active: false, type: 'middle'};
  const isReversed = dayjs(startDate).isAfter(hoverDate);
  const start = dayjs(isReversed ? hoverDate : startDate);
  const end = dayjs(isReversed ? startDate : hoverDate);
  const isLeft =
    startWeek || firstMonth || current === start.format('YYYY-MM-DD');
  const isRight = endWeek || endMonth || current === end.format('YYYY-MM-DD');
  const type = !isLeft && !isRight ? 'middle' : isRight ? 'right' : 'left';
  const checkHoverAndStart =
    start.format('YYYY-MM-DD') !== end.format('YYYY-MM-DD');
  const isActive =
    formattedDay.isAfter(start.add(-1, 'd')) &&
    formattedDay.isBefore(end.add(1, 'd'));
  const checkLastWeekDay =
    start.format('YYYY-MM-DD') ===
    formattedDay.endOf('week').format('YYYY-MM-DD')
      ? start.format('YYYY-MM-DD') !==
        formattedDay.endOf('week').format('YYYY-MM-DD')
      : true;
  const checkFirstWeekDay =
    end.format('YYYY-MM-DD') ===
    formattedDay.startOf('week').format('YYYY-MM-DD')
      ? end.format('YYYY-MM-DD') !==
        formattedDay.startOf('week').format('YYYY-MM-DD')
      : true;
  return {
    active:
      checkFirstWeekDay && checkLastWeekDay && checkHoverAndStart && isActive,
    type,
  };
};

const Days = ({
  days,
  value,
  valueEnd,
  hoverDate,
  onClick,
  variant,
  onHover,
  input,
  isBirthdate,
}: {
  days: string[];
  value: string;
  valueEnd?: string;
  hoverDate?: string;
  input: boolean;
  variant: 'day' | 'period' | 'week';
  onClick: (value: string) => void;
  onHover: (value: string | undefined) => void;
  isBirthdate?: boolean;
}) => {
  return (
    <Flex direction="column">
      <Flex
        direction="row"
        alignItems="center"
        style={{justifyContent: 'space-around', height: 43}}>
        {weekdays.map(weekday => (
          <Text
            key={weekday}
            typography="footNote12Medium"
            color={
              dayjs().format('dd') === weekday ? 'mainPrimary' : 'textPrimary'
            }>
            {weekday}
          </Text>
        ))}
      </Flex>
      <DaysWrapper>
        {days.map(day => {
          const check = getActive(day, value, valueEnd, hoverDate);
          const activeDay = variant !== 'day' && check.active;
          const format = input ? 'DD.MM.YYYY' : undefined;
          const formattedValue = dayjs(value, format).format('DD.MM.YYYY');
          const formattedValueEnd = valueEnd
            ? dayjs(valueEnd, format).format('DD.MM.YYYY')
            : undefined;
          const formattedDay = dayjs(day, 'YYYY-MM-DD').format('DD.MM.YYYY');
          const isStart = formattedValue === formattedDay;
          const isEnd = formattedValueEnd === formattedDay;
          return (
            <SelectedPeriod
              gridColumnStart={
                dayjs(day).get('d') === 0 ? 7 : dayjs(day).get('d')
              }
              onMouseMoveCapture={() => {
                onHover(day);
              }}
              onMouseLeave={() => {
                onHover(undefined);
              }}
              key={'day_' + day}>
              <BackgroundDay active={activeDay} type={check.type} />
              <BackgroundCircle active={activeDay && check.type !== 'middle'} />
              <Day
                selected={isStart || isEnd}
                onClick={e => {
                  if (isBirthdate) {
                    const today = dayjs();
                    if (today.isBefore(day)) {
                      showAlert('error', 'Выберите правильную дату');
                    } else {
                      e.stopPropagation();
                      onClick(day);
                    }
                  } else {
                    e.stopPropagation();
                    onClick(day);
                  }
                }}>
                {dayjs(day).format('D')}
                {dayjs().format('YYYY-MM-DD') ===
                  dayjs(day).format('YYYY-MM-DD') && (
                  <Dot active={dayjs().format('YYYY-MM-DD') === value} />
                )}
              </Day>
            </SelectedPeriod>
          );
        })}
      </DaysWrapper>
    </Flex>
  );
};

const BodyMonthWrapper = styled.div`
  display: grid;
  grid-auto-rows: 1;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  gap: 16px;
`;

const MonthText = styled.div<{current: boolean}>`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 54px;
  width: 54px;
  cursor: pointer;
  pointer-events: all;
  border-radius: 27px;
  color: ${({theme, current}) => (current ? theme.white : theme.textPrimary)};
  background-color: ${({theme, current}) =>
    current ? theme.mainPrimary : 'transparent'};
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  border: 1px solid transparent;
  &:hover {
    border-color: ${({theme}) => theme.mainPrimary};
  }
`;

const Months = ({
  value,
  onChange,
}: {
  onChange: (value: string) => void;
  value: string;
}) => {
  return (
    <BodyMonthWrapper>
      {dayjs.monthsShort().map((item, index) => (
        <MonthText
          key={item}
          current={item === dayjs(value).format('MMM')}
          onClick={e => {
            e.stopPropagation();
            onChange(dayjs(value).set('month', index).format('YYYY-MM-DD'));
          }}>
          {item}
        </MonthText>
      ))}
    </BodyMonthWrapper>
  );
};

const BodyYearWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 0 16px;
  gap: 16px;
  height: 409px;
  overflow-y: scroll;
`;

const YearView = styled.div<{backgroundColor: string}>`
  height: 54px;
  display: flex;
  width: 54px;
  background-color: ${props => props.backgroundColor};
  border-radius: 54px;
  align-items: center;
  justify-content: center;
  pointer-events: all;
  border: 1px solid transparent;
  cursor: pointer;
  &:hover {
    border-color: ${({theme}) => theme.mainPrimary};
  }
`;

const Years = ({
  onChange,
  value,
}: {
  onChange: (value: number) => void;
  value: string;
}) => {
  const years = Array.from(
    {length: dayjs().get('year') - 1960},
    (_, i) => dayjs().get('year') - i,
  );
  const colors = useColors();
  return (
    <BodyYearWrapper>
      {years.map(year => (
        <YearView
          backgroundColor={
            dayjs(value).format('YYYY') === year.toString()
              ? colors.mainPrimary
              : 'transparent'
          }
          onClick={e => {
            e.stopPropagation();
            onChange(year);
          }}
          key={year.toString()}>
          <Text
            typography="footNote12Medium"
            color={
              dayjs(value).format('YYYY') === year.toString()
                ? 'white'
                : 'textPrimary'
            }
            style={{
              textAlign: 'center',
              cursor: 'pointer',
              pointerEvents: 'all',
            }}>
            {year}
          </Text>
        </YearView>
      ))}
    </BodyYearWrapper>
  );
};
