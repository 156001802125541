import React, {useRef, useState} from 'react';
import {Content} from 'shared/ui/Content';
import {Text} from 'shared/ui/Text';
import {TextInput} from 'shared/ui/TextInput';
import {Button} from 'shared/ui/Button';
import {gql} from 'shared/__generated__';
import {useQuery} from '@apollo/client';
import {useAppSelector} from 'shared/store';
import {useNavigate} from 'react-router-dom';
import {ensureError} from 'shared/lib/utils';
import {getSmsCode, loginPassword} from 'shared/lib/api';
import styled from 'styled-components';
import {device} from 'shared/device';
import {showAlert} from 'shared/ui/Alert';
import {Seo} from 'shared/ui/Seo';
import ReCAPTCHA from 'react-google-recaptcha';

const QUERY = gql(`
query GetPhone {
  me {
    id
    phone
  }
}`);

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  padding-bottom: 24px;
  flex-direction: column;
  margin-top: 8px;
  gap: 32px;
  @media (${device.mobile}) {
    gap: 8px;
    margin-bottom: 0;
    border-radius: 0;
    padding-left: 16px;
    padding-right: 16px;
  }
`;
export function PhoneChange() {
  const navigate = useNavigate();
  const token = useAppSelector(state => state.auth.token);
  const [newPhone, setNewPhone] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = React.useState(false);
  const [errorPhone, setErrorPhone] = React.useState<string | null>(null);
  const [errorPas, setErrorPas] = React.useState<string | null>(null);
  const {data} = useQuery(QUERY);
  const phone = data?.me?.phone;
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);

  const onCaptchaChange = async (resToken: string | null) => {
    if (!resToken) {
      return;
    }
    try {
      await getSmsCode(newPhone, resToken);
      navigate('/phone-change-code', {
        state: {
          old_phone: phone!,
          phone: newPhone,
          password: password,
          token,
        },
      });
    } catch (err) {
      const error = ensureError(err);
      showAlert('error', error.message);
    }

    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }
  };
  const onCheck = async () => {
    if (phone === checkPhone || newPhone.length < 18 || password.length < 8) {
      if (phone === checkPhone) {
        showAlert('error', 'Номер телефона уже зарегистрирован в системе');
      }
      if (newPhone.length < 18) {
        setErrorPhone('Обязательное поле');
      }
      if (password.length < 8) {
        setErrorPas('Минимальная длина пароля 8 символов');
      }
    } else {
      try {
        setLoading(true);
        await loginPassword(phone!, password);
        recaptchaRef.current?.execute();
      } catch (e) {
        const error = ensureError(e);
        if (error.message === 'Неверный номер телефона или пароль') {
          setErrorPas('Неверный пароль');
        } else {
          showAlert('error', error.message);
        }
      } finally {
        setLoading(false);
      }
    }
  };
  const maskDelete = (phoneNumber: string) => {
    return phoneNumber.replace(/\D/g, '');
  };
  const checkPhone = '+' + maskDelete(newPhone);

  return (
    <Wrapper>
      <Seo title="Изменить номер" />
      <Content>
        <Text typography="title18">Введите новый номер</Text>
        <TextInput
          label="Новый номер"
          autoComplete="tel"
          mask="+7 (999) 999-99-99"
          value={newPhone}
          error={errorPhone}
          onChange={text => {
            setNewPhone(text);
            setErrorPhone(null);
          }}
        />
        <TextInput
          label="Пароль"
          error={errorPas}
          type="password"
          value={password}
          onChange={text => {
            setPassword(text);
            setErrorPas(null);
          }}
        />
      </Content>

      <Button size="large" onClick={onCheck} loading={loading}>
        Продолжить
      </Button>
      <ReCAPTCHA
        style={{display: 'inline-block', visibility: 'hidden'}}
        ref={recaptchaRef}
        size="invisible"
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY!}
        onChange={onCaptchaChange}
        onExpired={() => {
          showAlert('error', 'Время ввода капчи истекло');
          if (recaptchaRef.current) {
            recaptchaRef.current.reset();
          }
        }}
        onErrored={() => {
          showAlert('error', 'Ошибка ввода капчи');
          if (recaptchaRef.current) {
            recaptchaRef.current.reset();
          }
        }}
      />
    </Wrapper>
  );
}
