import React from 'react';
import styled from 'styled-components';
import {typography} from 'shared/typography';
import {useColors} from 'shared/lib/hooks';
import Check from 'shared/icons/Check';
import {device} from 'shared/device';

const styleDay = typography.text16Regular;

const BoxDay = styled.button`
  height: 128px;
  width: 144px;
  cursor: pointer;
  border-radius: 20px;
  background: ${({theme}) => theme.fillPrimary};
  padding: 12px;
  pointer-events: all;
  border: 1px solid ${({theme}) => theme.borderPrimary};
  color: ${({theme}) => theme.textPrimary};
  font-size: ${styleDay.fontSize}px;
  font-weight: ${styleDay.fontWeight};
  line-height: ${styleDay.lineHeight};
  font-style: ${styleDay.fontStyle};
  text-align: left;
  @media ${device.mobile} {
    display: none;
  }
`;
const Accept = styled.div<{active?: boolean}>`
  display: flex;
  pointer-events: none;
  height: 42px;
  width: 42px;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid ${({theme}) => theme.mainPrimary};
  margin-bottom: 36px;
  background: ${({active, theme}) =>
    active ? theme.mainPrimary : theme.fillPrimary};
  @media ${device.mobile} {
    display: none;
  }
`;

export const Day = ({
  workTime,
  active,
  onSelect,
}: {
  workTime: string;
  active: boolean;
  onSelect: () => void;
}) => {
  const colors = useColors();
  return (
    <BoxDay onClick={onSelect}>
      <Accept active={active}>
        <Check color={active ? colors.white : colors.fillPrimary} />
      </Accept>
      {active && workTime ? workTime : 'Выходной'}
    </BoxDay>
  );
};
