import React, {useState} from 'react';
import styled from 'styled-components';
import {Layout} from 'shared/ui/Layout';
import {Flex} from 'shared/ui/Flex';
import {Text} from 'shared/ui/Text';
import {Button} from 'shared/ui/Button';
import {Content} from 'shared/ui/Content';
import {ResourcesDragDrop} from './ui/ResourcesDragDrop';
import {gql} from 'shared/__generated__';
import {useAppSelector} from 'shared/store';
import {Popup} from 'shared/ui/Popup';
import {CloseButton} from 'shared/ui/CloseButton';
import {TextInput} from 'shared/ui/TextInput';
import {MultiSelectChips} from 'shared/ui/MultiSelectChips';
import {useMutation, useQuery} from '@apollo/client';
import {ensureError} from 'shared/lib/utils';
import {showAlert} from 'shared/ui/Alert';
import {Loading} from 'shared/ui/Loading';
import {Plus} from 'shared/icons/Plus';
import {CompanyResources, Service} from 'shared/__generated__/graphql';
import {EmptyState} from './ui/EmptyState';
const RESOURCES = gql(`
query CompanyResources($companyId: String) {
    company(id: $companyId) {
      id
      name
      resources {
        id
        count
        name
        order
        services {
          id
          name
        }
      }
    }
  }`);
const RESOURCE_SERVICES = gql(`
query CompanyResourceServices($first: Int!, $companyId: String) {
    companyServiceSearch(first: $first, company_id: $companyId) {
      data {
        id
        name
      }
    }
  }
`);
const UPDATE_RESOURCES = gql(`
mutation UpdateCompanyResources($input: [UpdateCompanyResources!]!, $companyId: String!) {
    updateCompanyResources(input: $input, company_id: $companyId) {
      id
    }
  }
`);
const CREATE_RESOURCES = gql(`
mutation CreateCompanyResources($input: [CreateCompanyResources!]!, $companyId: String!) {
    createCompanyResources(input: $input, company_id: $companyId) {
      id
    }
  }
`);

export type ResourceType = Pick<
  CompanyResources,
  'id' | 'name' | 'count' | 'order'
> & {services?: Pick<Service, 'id' | 'name'>[] | null};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-column-start: 4;
  grid-column-end: 10;
  padding: 64px 0;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;
export const Resources = () => {
  const companyId = useAppSelector(state => state.company.data?.id);
  const [createModal, setCreateModal] = useState(false);
  const [name, setName] = useState('');
  const [errorName, setErrorName] = useState<string | null>(null);
  const [count, setCount] = useState('');
  const [errorCount, setErrorCount] = useState<string | null>(null);
  const {data: serviceData} = useQuery(RESOURCE_SERVICES, {
    variables: {companyId: companyId!, first: 100},
  });
  const services = serviceData?.companyServiceSearch?.data;
  const [resourceServices, setResourceServices] = useState<
    {label: string; value: string}[]
  >([]);
  const {data, loading} = useQuery(RESOURCES, {
    variables: {companyId: companyId!},
  });
  const resources = data?.company?.resources ?? [];
  const resourcesLength = data?.company?.resources?.length;
  const sortResources = [...resources].sort((a, b) => {
    if (a.order && b.order) {
      return a.order - b.order;
    }
    return 0;
  });
  const [create, {loading: loadingCreate}] = useMutation(CREATE_RESOURCES, {
    refetchQueries: ['CompanyResources', 'CompanyServiceSearchServices'],
  });
  const [resourceUpdate] = useMutation(UPDATE_RESOURCES, {
    refetchQueries: ['CompanyResources', 'CompanyServiceSearchServices'],
  });
  const createResources = async () => {
    let isValid = true;
    if (!name) {
      setErrorName('Обязательное поле');
      isValid = false;
    }
    if (!count) {
      setErrorCount('Обязательное поле');
      isValid = false;
    }
    if (isValid) {
      try {
        await create({
          variables: {
            companyId: companyId!,
            input: [
              {
                name: name,
                count: +count,
                service_ids: resourceServices.map(item => item.value),
              },
            ],
          },
        });
        setCreateModal(false);
        setName('');
        setCount('');
        setResourceServices([]);
        showAlert('success', 'Сохранено');
      } catch (e) {
        const error = ensureError(e);
        showAlert('error', error.message);
      }
    }
  };
  if (loading) {
    <Wrapper>
      <Loading />
    </Wrapper>;
  }
  return (
    <Layout columns={12}>
      <Wrapper>
        <Flex alignItems="center" justifyContent="space-between">
          <Flex gap={12}>
            <Text typography="title24">Ресурсы</Text>
            <Text typography="title24" color="textTertiary">
              {resourcesLength ? resourcesLength : ''}
            </Text>
          </Flex>
          <Button
            leftIcon={<Plus size={20} strokeWidth={2} />}
            onClick={() => setCreateModal(true)}>
            Добавить ресурс
          </Button>
        </Flex>
        <Text mb={32} typography="subHead14Regular">
          Используйте ресурсы для учета занятости рабочего места.
        </Text>
        {resourcesLength ? (
          <Content gap="0px">
            <ResourcesDragDrop
              resources={sortResources}
              onDragEnd={async resoursesDrag => {
                try {
                  await resourceUpdate({
                    variables: {
                      companyId: companyId!,
                      input: resoursesDrag.map((item, index) => ({
                        resource_id: item.id,
                        order: index + 1,
                      })),
                    },
                  });
                } catch (e) {
                  if (e instanceof Error) {
                    showAlert('error', e.message);
                  }
                }
              }}
            />
          </Content>
        ) : (
          <EmptyState />
        )}
      </Wrapper>

      <Popup
        style={{alignItems: 'normal', gap: 32, width: 472}}
        visible={createModal}
        onClose={() => setCreateModal(false)}>
        <Flex
          style={{width: 424}}
          direction="row"
          alignItems="center"
          justifyContent="space-between">
          <Text typography="title20">Создание ресурса</Text>
          <CloseButton onClose={() => setCreateModal(false)} />
        </Flex>
        <Flex direction="column" gap={16}>
          <TextInput
            variant="label_animation"
            label="Название ресурса"
            required
            value={name}
            onChange={text => {
              setName(text);
              setErrorName(null);
            }}
            error={errorName}
          />
          <TextInput
            variant="label_animation"
            label="Количество единиц ресурса"
            required
            value={count}
            mask="99999"
            onChange={text => {
              setCount(text);
              setErrorCount(null);
            }}
            error={errorCount}
          />
          <MultiSelectChips
            style={{position: 'relative'}}
            data={services?.map(item => ({label: item.name, value: item.id}))}
            listEmpty={'У вас пока нет услуг. Добавьте услуги и начните работу'}
            label="Услуги"
            values={resourceServices}
            onDelete={id => {
              setResourceServices(
                resourceServices.filter(selected => selected.value !== id),
              );
            }}
            onChange={setResourceServices}
          />
        </Flex>
        <Flex gap={8}>
          <Button
            size="large"
            style={{flex: 1}}
            variant="outline"
            typography="text16Semibold"
            onClick={() => setCreateModal(false)}>
            Отмена
          </Button>
          <Button
            size="large"
            loading={loadingCreate}
            style={{flex: 1}}
            onClick={createResources}
            typography="text16Semibold">
            Создать ресурс
          </Button>
        </Flex>
      </Popup>
    </Layout>
  );
};
