import React from 'react';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';
import {device} from 'shared/device';
import {EmptyTags} from 'shared/illustration/EmptyTags';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  align-self: center;
  @media (${device.mobile}) {
    margin-left: 0;
    align-self: center;
    justify-content: center;
    padding: 16px;
  }
`;

const Empty = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: auto;
`;

export const EmptyState = () => {
  return (
    <Wrapper>
      <Empty>
        <EmptyTags size={300} mt={32} />
        <Text typography="title20" align="center">
          У вас пока нет тегов
        </Text>
        <Text
          color="textTertiary"
          style={{
            textAlign: 'center',
            alignSelf: 'center',
            maxWidth: 500,
            marginTop: 16,
          }}>
          Нажмите на кнопку “Добавить тег” и начните работу
        </Text>
      </Empty>
    </Wrapper>
  );
};
