import React from 'react';
import {device} from 'shared/device';
import {Skeleton} from 'shared/ui/Skeleton';
import styled from 'styled-components';
import {Content} from 'shared/ui/Content';

const Container = styled(Content)`
  background-color: ${({theme}) => theme.bgPrimary};
  border-width: 1px;
  padding: 24px;
  min-width: 1092px;
  margin-top: 8px;
  @media ${device.mobile} {
    padding: 16px;
    border-width: 0px;
    background: none;
  }
`;

const RectName = styled.rect<{index: number}>`
  display: flex;
  @media ${device.mobile} {
    display: none;
  }
`;
const columns = Array.from(Array(4).keys());

export const CustomerHistorySkeleton = () => {
  return (
    <Container>
      <Skeleton height={392}>
        <RectName index={1} width={69} height={14} rx={8} x={0} y={24} />
        <RectName index={2} width={44} height={14} rx={8} x={197} y={24} />
        <RectName index={3} width={38} height={14} rx={8} x={480} y={24} />
        <RectName index={4} width={60} height={14} rx={8} x={693} y={24} />
        <RectName index={5} width={35} height={14} rx={8} x={840} y={24} />
        {columns.map((columnItem, index) => (
          <React.Fragment key={'column_' + columnItem}>
            <RectName
              index={columnItem}
              width={100}
              height={14}
              rx={8}
              x={0}
              y={index * 73 + 71}
            />
            <RectName
              index={columnItem}
              width={160}
              height={40}
              rx={8}
              x={197}
              y={index * 73 + 75}
            />
            <RectName
              index={columnItem}
              width={117}
              height={14}
              rx={8}
              x={0}
              y={index * 73 + 71 + 24}
            />
            <RectName
              index={columnItem}
              width={106}
              height={14}
              rx={8}
              x={480}
              y={index * 73 + 83}
            />
            <RectName
              index={columnItem}
              width={38}
              height={14}
              rx={8}
              x={693}
              y={index * 73 + 83}
            />
            <RectName
              index={columnItem}
              width={163}
              height={14}
              rx={8}
              x={840}
              y={index * 73 + 83}
            />
          </React.Fragment>
        ))}
      </Skeleton>
    </Container>
  );
};
