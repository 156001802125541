import React from 'react';
import {Skeleton} from 'shared/ui/Skeleton';

export const EditCertificateSkeleton = () => {
  return (
    <Skeleton height={966}>
      <rect width={'100%'} height={160} rx={24} ry={24} x={0} y={0} />
      <rect width={'100%'} height={160} rx={24} ry={24} x={0} y={184} />
      <rect width={'100%'} height={510} rx={24} ry={24} x={0} y={368} />
      <rect
        width={'calc(50% - 8px)'}
        height={64}
        rx={14}
        ry={14}
        x={0}
        y={368 + 510 + 24}
      />
      <rect
        width={'calc(50% - 8px)'}
        height={64}
        rx={14}
        ry={14}
        x={'calc(50% + 8px)'}
        y={902}
      />
    </Skeleton>
  );
};
