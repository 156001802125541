import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import {ArrowLeft1v} from 'shared/icons/ArrowLeft1v';
import {ArrowRight1v} from 'shared/icons/ArrowRight1v';
import {Days} from './Days';
import {Months} from './Months';
import {Years} from './Years';
import {Flex} from 'shared/ui/Flex';
import {DateInputSalary} from './DateInputSalary';
import {CustomSlide} from './CustomSlide';
import {Text} from 'shared/ui/Text';
import {Switch} from 'shared/ui/Switch';
import {InfinityType} from '..';

const Header = styled.div`
  display: flex;
  height: 46px;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
`;
const ArrowButton = styled.button`
  border: none;
  display: flex;
  background: none;
  padding: 0;
  width: 44px;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
const DateButton = styled.button`
  border: none;
  display: flex;
  background: none;
  flex: 1;
  padding: 0;
  align-items: center;
  justify-content: center;
  font-family: 'Inter';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-transform: capitalize;
  color: ${({theme}) => theme.mainPrimary};
`;
const SwitchInfinity = styled.button`
  display: flex;
  height: 48px;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 0;
  border: none;
  background-color: transparent;
`;

enum TYPE {
  Preset = 'preset',
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Period = 'period',
}
const types = [
  {label: 'Пресеты', type: TYPE.Preset},
  {label: 'День', type: TYPE.Day},
  {label: 'Неделя', type: TYPE.Week},
  {label: 'Месяц', type: TYPE.Month},
  {label: 'Период', type: TYPE.Period},
];

const Head = ({
  label,
  onClick,
}: {
  label: string;
  onClick: (position: 'left' | 'right' | 'middle') => void;
}) => (
  <Header>
    <ArrowButton onClick={() => onClick('left')}>
      <ArrowLeft1v />
    </ArrowButton>
    <DateButton onClick={() => onClick('middle')}>{label}</DateButton>
    <ArrowButton onClick={() => onClick('right')}>
      <ArrowRight1v />
    </ArrowButton>
  </Header>
);

export const Dates = ({
  activeTypeIndex,
  date,
  setDate,
  period,
  setPeriod,
  month,
  setMonth,
  setInfinity,
  variantPeriod = 'default',
}: {
  activeTypeIndex: number;
  date?: string;
  setDate?: (date: string) => void;
  period?: {start: string; end: string};
  setPeriod?: (week: {start: string; end: string}) => void;
  month?: string;
  setMonth?: (date: string) => void;
  variantPeriod?: 'default' | 'custom';
  setInfinity?: (value: InfinityType) => void;
}) => {
  const isSelectedType = types[activeTypeIndex].type;
  const [bodyType, setBodyType] = useState<'days' | 'months' | 'years'>('days');
  const [calendarDate, setCalendarDate] = useState(
    dayjs().format('YYYY-MM-DD'),
  );
  const [previewDates, setPreviewDates] = useState(false);
  const [futureDates, setFutureDates] = useState(false);
  const formattedCalendarDate = dayjs(calendarDate);
  const countDayjsMonth = formattedCalendarDate.daysInMonth();
  const headerLabelFormat = bodyType === 'days' ? 'MMMM YYYY' : 'YYYY';
  const selectedDateFormatted = formattedCalendarDate.format(headerLabelFormat);
  const days = Array.from(Array(countDayjsMonth), (_, index) =>
    formattedCalendarDate.set('date', index + 1).format('YYYY-MM-DD'),
  );
  const isCustom =
    isSelectedType === TYPE.Period && setPeriod && variantPeriod === 'custom';

  useEffect(() => {
    if (isSelectedType === TYPE.Preset) return;
    if (bodyType === 'days' && isSelectedType === TYPE.Month)
      setBodyType('months');
    if (bodyType !== 'days') setBodyType('days');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelectedType]);

  return (
    <CustomSlide>
      <Head
        label={selectedDateFormatted}
        onClick={position => {
          if (position !== 'middle') {
            const diff = position === 'right' ? 1 : -1;
            if (bodyType === 'days') {
              setCalendarDate &&
                setCalendarDate(
                  dayjs(formattedCalendarDate)
                    .add(diff, 'month')
                    .format('YYYY-MM-DD'),
                );
            } else {
              setCalendarDate &&
                setCalendarDate(
                  dayjs(formattedCalendarDate)
                    .add(diff, 'year')
                    .format('YYYY-MM-DD'),
                );
            }
            return;
          }
          if (bodyType === 'years') return;
          if (bodyType === 'days') {
            setBodyType('months');
            return;
          }
          setBodyType('years');
        }}
      />
      {isCustom && (
        <Flex justifyContent="space-between">
          <Flex gap={8} style={{maxWidth: 240}} direction="column">
            <DateInputSalary
              date={period?.start ?? ''}
              onChangeDate={newDate =>
                setPeriod({end: period?.end ?? '', start: newDate})
              }
              label="Дата начала"
            />
            {setInfinity && (
              <SwitchInfinity
                onClick={() => {
                  setPreviewDates(prev => !prev);
                  if (!previewDates) {
                    setInfinity(futureDates ? 'both' : 'prev');
                    return;
                  }
                  setInfinity(futureDates ? 'next' : undefined);
                }}>
                <Text>Все предыдущие даты</Text>
                <Switch value={previewDates} />
              </SwitchInfinity>
            )}
          </Flex>
          <Flex gap={8} style={{maxWidth: 240}} direction="column">
            <DateInputSalary
              date={period?.end ?? ''}
              onChangeDate={newDate =>
                setPeriod({start: period?.start ?? '', end: newDate})
              }
              label="Дата окончания"
            />
            {setInfinity && (
              <SwitchInfinity
                onClick={() => {
                  setFutureDates(prev => !prev);
                  if (!futureDates) {
                    setInfinity(previewDates ? 'both' : 'next');
                    return;
                  }
                  setInfinity(previewDates ? 'prev' : undefined);
                }}>
                <Text>Все будущие даты</Text>
                <Switch value={futureDates} />
              </SwitchInfinity>
            )}
          </Flex>
        </Flex>
      )}
      {bodyType === 'days' && (
        <Days
          period={period}
          days={days}
          date={date}
          input={isSelectedType === TYPE.Period}
          variant={isSelectedType === TYPE.Day ? 'day' : 'week'}
          onClick={newDate => {
            if (isSelectedType === TYPE.Period && setPeriod) {
              if (period?.end.length && !period.start.length) {
                const formattedHover = dayjs(newDate).format('DD.MM.YYYY');
                if (formattedHover === period.end) return;
                const isRevert = dayjs(period.end, 'DD.MM.YYYY').isAfter(
                  newDate,
                );
                setPeriod({
                  start: isRevert
                    ? dayjs(newDate).format('DD.MM.YYYY')
                    : period.end,
                  end: isRevert
                    ? period.end
                    : dayjs(newDate).format('DD.MM.YYYY'),
                });
                return;
              }
              if (!period?.start) {
                setPeriod({
                  end: '',
                  start: dayjs(newDate).format('DD.MM.YYYY'),
                });
                return;
              }
              if (period?.start && !period.end.length) {
                const formattedHover = dayjs(newDate).format('DD.MM.YYYY');
                if (formattedHover === period.start) return;
                const isRevert = dayjs(period.start, 'DD.MM.YYYY').isAfter(
                  newDate,
                );
                setPeriod({
                  start: isRevert
                    ? dayjs(newDate).format('DD.MM.YYYY')
                    : period.start,
                  end: isRevert
                    ? period.start
                    : dayjs(newDate).format('DD.MM.YYYY'),
                });
                return;
              }

              setPeriod({end: '', start: dayjs(newDate).format('DD.MM.YYYY')});
              return;
            }
            isSelectedType === TYPE.Day && setDate && setDate(newDate);
            isSelectedType === TYPE.Week &&
              setPeriod &&
              setPeriod({
                start: dayjs(newDate).startOf('week').format('YYYY-MM-DD'),
                end: dayjs(newDate).endOf('week').format('YYYY-MM-DD'),
              });
          }}
        />
      )}
      {bodyType === 'months' && (
        <Months
          selectMonth={month}
          calendarDate={calendarDate}
          onSelect={newDate => {
            if (isSelectedType === TYPE.Month && setMonth) {
              setMonth(newDate);
              return;
            }
            setCalendarDate(newDate);
            setBodyType('days');
          }}
        />
      )}
      {bodyType === 'years' && (
        <Years
          date={dayjs(formattedCalendarDate).format('YYYY-MM-DD')}
          onSelect={newYear => {
            setCalendarDate &&
              setCalendarDate(
                dayjs(formattedCalendarDate)
                  .set('year', newYear)
                  .format('YYYY-MM-DD'),
              );
            setBodyType('months');
          }}
        />
      )}
    </CustomSlide>
  );
};
