import React from 'react';
import {Flex} from 'shared/ui/Flex';
import {TextInput} from 'shared/ui/TextInput';
import {TabsControl} from './TabsControl';

export const TextInputSwitch = ({
  value,
  onChange,
  required = false,
  label,
  switchLabels,
  activeIndex,
  onChangeTab,
  defaultWidth,
}: {
  value?: string;
  defaultWidth?: number;
  onChange: (text: string) => void;
  required?: boolean;
  label: string;
  activeIndex: number;
  switchLabels: {label: string; value: string}[];
  onChangeTab: (index: number) => void;
  mask?: string;
}) => (
  <Flex alignItems="center" gap={12}>
    <TextInput
      value={value}
      onChange={text => {
        if (activeIndex === 1 && +text > 100) {
          onChange('100');
        } else {
          onChange(text);
        }
      }}
      required={required}
      mask={activeIndex === 1 ? '999:999' : '999999999'}
      label={label}
      flex={1}
    />
    <TabsControl
      labels={switchLabels}
      defaultWidth={defaultWidth}
      onClick={onChangeTab}
      activeIndex={activeIndex}
    />
  </Flex>
);
