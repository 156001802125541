import {Content} from 'shared/ui/Content';
import React, {useState} from 'react';
import styled from 'styled-components';
import {Text} from 'shared/ui/Text';
import {Divider} from 'shared/ui/Divider';
import {Flex} from 'shared/ui/Flex';
import {Button} from 'shared/ui/Button';
import {TariffPlan, getMonths, getPriceFormat, periods} from 'shared/lib/utils';
import {usePayment} from './Provider';
import {showAlert} from 'shared/ui/Alert';
import {useAppSelector} from 'shared/store';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';
import dayjs from 'dayjs';
import {ApolloError, useLazyQuery} from '@apollo/client';
import {Popup} from 'shared/ui/Popup';
import {IPaymentResponse, requestJSON} from 'entities/tariff';
import {Skeleton} from 'shared/ui/Skeleton';
import {gql} from 'shared/__generated__';
import {SubscriptionsType} from 'shared/__generated__/graphql';

const REFETCH_QUERY = gql(`
  query SubscriptionRefetchQuery($companyId: String!, $first: Int!, $page: Int) {
    priceList {
      id
      price
      slots
      type
      created_at
    }
    company(id: $companyId) {
      id
      subscription_end_date
      user_card_token_id
      user_card_token_id
      is_subscription_active
      companyAccountInfo {
        city {
          id
          name
        }
        address
        company_name
        office
        post_index
        comment
        email
        name
      }
      plan {
        id
        price
        type
        created_at
        slots
      }
      subscriptions(first: $first, page: $page) {
        data {
          type
          id
          created_at
          status
          summary
          slots
        }
      }
    }
    getCompanyEmployeesLength(company_id: $companyId, enabled: true)
    moderationsCheck(company_id: $companyId) {
      result
      is_employee_added
      is_employee_with_specialization_added
      is_required_items_filled
      is_service_added
    }
    me {
      id
      name
      surname
      phone
      has_password
      avatar {
        id
        type
        url
      }
      card_token {
        id
        last_four_digits
        first_six_digits
      }
      bonus_account
    }
    companyPermissions(company_id: $companyId) {
      subscription_and_pay_permission
    }
    ...Permissions
  }
`);

const Container = styled.div`
  grid-column-start: 10;
  grid-column-end: 13;
  @media (max-width: 1500px) {
    grid-column-start: 4;
    grid-column-end: 10;
    padding-bottom: 24px;
  }
`;
const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const OriginalPrice = styled.div`
  display: flex;
  justify-content: space-between;
  height: 48px;
  align-items: center;
`;
const Link = styled.a`
  color: ${({theme}) => theme.mainPrimary};
  pointer-events: all;
  cursor: pointer;
`;
const Loader = styled.div<{show: boolean}>`
  display: ${({show}) => (show ? 'flex' : 'none')};
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  border-radius: 24px;
  justify-content: center;
  align-items: center;
  background-color: ${({theme}) => theme.bgPrimary};
  border: none;
`;
type PaymentsError = {
  CardNumber: string | undefined;
  cvv: string | undefined;
  expDateMonth: string | undefined;
};

const getErrorMessage = (errors: PaymentsError) => {
  switch (true) {
    case !!errors.CardNumber:
      showAlert('error', errors.CardNumber ?? '');
      break;
    case !!errors.cvv:
      showAlert('error', errors.cvv ?? '');
      break;
    case !!errors.expDateMonth:
      showAlert('error', errors.expDateMonth ?? '');
      break;
    default:
      showAlert('error', 'Ошибка вводе данных');
      break;
  }
};

const getIp = async () => {
  const response = await fetch('https://ipapi.co/json/');
  const data = await response.json();
  return data.ip;
};
const getUnusedBalance = (value: TariffPlan) => {
  const start = dayjs(value.created_at);
  const end = dayjs(value.created_at).add(getMonths(value.type), 'month');
  const diffDays = dayjs().diff(start, 'd');
  const countDayTariff = end.diff(start, 'd');
  const unusedBalance = Math.round(
    ((countDayTariff - diffDays) / countDayTariff) * value.price,
  );
  return unusedBalance;
};
const getTotalAndPromocodeValue = (
  price: number,
  promocode: string | null,
  unusedBalance: number,
  bonusAccount: number,
) => {
  let tempPrice = price;
  let bonus = 0;
  if (unusedBalance > 0) {
    if (unusedBalance >= tempPrice) {
      bonus = unusedBalance - tempPrice;
      tempPrice = 1;
      return {total: tempPrice, value: 0, bonus};
    } else {
      tempPrice = tempPrice - unusedBalance;
      if (bonusAccount > 0) {
        if (bonusAccount >= tempPrice) {
          bonus = -tempPrice;
          tempPrice = 1;
          return {total: tempPrice, value: 0, bonus};
        } else {
          bonus = 0 - bonusAccount;
          tempPrice = tempPrice - bonusAccount;
        }
      }
    }
  }
  if (!promocode) return {total: tempPrice, value: 0, bonus};
  const data = promocode.split(',');
  const isPercent = data[1] === 'percent';
  if (!isPercent) return {total: tempPrice - +data[0], value: +data[0], bonus};
  const value = tempPrice * (+data[0] / 100);
  return {total: tempPrice - value, value, bonus};
};

const getMonthByTypePlan = (type_s: SubscriptionsType) => {
  switch (type_s) {
    case SubscriptionsType.Year:
      return 12;
    case SubscriptionsType.Sixmonths:
      return 6;
    case SubscriptionsType.Threemonths:
      return 3;
    default:
      return 1;
  }
};

export const RightSection = ({
  plan,
  initialPlan,
  bonusAccount,
  defaultPlan,
  companyPlan,
  user_token,
  subscriptionActive,
  loading,
  data,
}: {
  user_token?: string | null;
  plan: TariffPlan;
  initialPlan: TariffPlan;
  bonusAccount: number;
  defaultPlan?: TariffPlan;
  companyPlan?: TariffPlan | null;
  subscriptionActive: boolean;
  loading: boolean;
  data: TariffPlan[];
}) => {
  const months = loading ? 0 : getMonths(plan.type);
  const navigate = useNavigate();
  const username = useAppSelector(state => state.company.data?.username);
  const companyId = useAppSelector(state => state.company.data!.id);
  const token = useAppSelector(state => state.auth.token);
  const {state, setState} = usePayment();
  console.log('usePayment', state);
  const [cloudPaymentsUrl, setCloudPaymentsUrl] = useState<string | null>(null);
  const [isLoading, setLoading] = useState(false);
  const [getRefetch] = useLazyQuery(REFETCH_QUERY, {
    variables: {
      companyId: companyId!,
      page: 1,
      first: 4,
    },
    onCompleted: () => {
      navigate(`/${username}/my-tariff`);
    },
  });
  const [iframeStatus, setIframeStatus] = useState<
    'default' | 'cloud' | 'answer'
  >('default');
  const [answer, setAnswer] = useState('');
  const selectedPeriod =
    periods.find(period => period.type === plan.type)?.value ?? 0;
  const isDiscount = selectedPeriod !== 0;
  const isBonus = bonusAccount !== 0;
  const discount = (defaultPlan?.price ?? 1) * months * (selectedPeriod / 100);
  const unusedBalance = companyPlan ? getUnusedBalance(companyPlan) : 0;
  const price = (defaultPlan?.price ?? 1) * months - discount;
  const {value: promocodeValue, bonus} = getTotalAndPromocodeValue(
    price,
    state.promoCode,
    unusedBalance,
    bonusAccount,
  );
  const action =
    companyPlan?.id === plan.id && !subscriptionActive
      ? 'updateSubscription'
      : companyPlan && companyPlan.type !== 'forever'
      ? 'change'
      : 'charge';

  const complete = async () => {
    if (
      companyPlan?.id === plan.id &&
      !subscriptionActive &&
      action === 'updateSubscription'
    ) {
      showAlert('success', 'Подписка возобновлена');
    } else {
      showAlert('success', 'Успешный платеж');
    }
    await getRefetch();
  };

  const paymentByCard = async () => {
    const filteredNumber = state.numberCard.replace(/ |_/g, '');
    if (filteredNumber.length < 16) {
      setState({...state, error: 'number'});
      return;
    }
    const filteredDate = state.dateCard.replace(/ |_/g, '');
    if (filteredDate.length < 5) {
      setState({...state, error: 'date'});
      return;
    }
    if (state.cvvCard.length < 3) {
      setState({...state, error: 'cvv'});
      return;
    }
    setLoading(true);
    const cryptogramPacket = await getCryptogram();

    const {name, city, index, office, street} = state;
    const ip = await getIp();
    const payload = {
      idAddress: ip,
      cardData: cryptogramPacket,
      company_id: companyId,
      priceList_id: plan.id,
      amount: plan.price,
      by_token: false,
    };
    console.log('state', state);
    if (name.length > 0) {
      Object.assign(payload, {
        city_id: city,
        company_name: name,
        office,
        post_index: +index,
        address: street,
      });
    }
    await requestJSON({
      action,
      token,
      body: JSON.stringify(payload),
    })
      .then(response => {
        if (action === 'updateSubscription') {
          complete();
          return;
        }
        const resData = response.data as IPaymentResponse;
        if (resData.response.Model.Status === 'Completed') {
          complete();
          return;
        }
        if (resData.response.Model.Status === 'Declined') {
          showAlert('error', resData.response.Model.CardHolderMessage ?? '');
          return;
        }
        const dataRes = {
          paReq: resData.response.Model.PaReq,
          acsUrl: resData.response.Model.AcsUrl,
          TermUrl:
            process.env.REACT_APP_DOMAIN +
            '/api/payment/callback?subscription_id=' +
            resData.subscription_id,
          MD: resData.response.Model.ThreeDsCallbackId,
        };
        const first = encodeURIComponent(dataRes.paReq);
        const second = encodeURIComponent(dataRes.TermUrl);
        const three = encodeURIComponent(dataRes.MD);
        const resUrl =
          dataRes.acsUrl +
          '-get?PaReq=' +
          first +
          '&TermUrl=' +
          second +
          '&MD=' +
          three;
        setCloudPaymentsUrl(resUrl);
        setAnswer(dataRes.TermUrl);
      })
      .catch((err: ApolloError) => {
        console.log('error', err);
        showAlert('error', err.message);
      })
      .finally(() => setLoading(false));
  };
  const getCryptogram = async () => {
    try {
      // TODO: fix eslint error checkout script
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      const checkout = new cp.Checkout({
        publicId: process.env.REACT_APP_CLOUDPAYMENTS_PUBLIC_ID,
      });
      const filteredNumber = state.numberCard.replace(/ |_/g, '');
      const date = state.dateCard.split(' / ');
      const fieldValues = {
        cvv: state.cvvCard,
        cardNumber: filteredNumber,
        expDateMonth: date[0],
        expDateYear: date[1],
      };
      const cryptogram = await checkout.createPaymentCryptogram(fieldValues);
      return cryptogram;
    } catch (errors) {
      getErrorMessage(errors as PaymentsError);
    }
  };

  const paymentByToken = async () => {
    setLoading(true);
    try {
      const {name, city, index, office, street} = state;
      const userIp = await getIp();
      const payload = {
        company_id: companyId,
        ipAddress: userIp,
        priceList_id: plan.id,
        amount: plan.price,
        by_token: true,
        user_card_token_id: user_token,
      };
      if (name.length > 0) {
        Object.assign(payload, {
          city_id: city,
          company_name: name,
          office,
          post_index: +index,
          address: street,
        });
      }
      const response = await requestJSON({
        action,
        token,
        body: JSON.stringify(payload),
      });
      if (response) {
        const resData = response.data as IPaymentResponse;
        if (
          action === 'updateSubscription' ||
          resData.response.Model.Status === 'Completed'
        ) {
          complete();
        } else {
          showAlert('error', resData.response.Model.CardHolderMessage ?? '');
        }
      }
    } catch (error) {
      if (error instanceof Error) {
        showAlert('error', error.message);
        console.log('error', error);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Content gap="24px" style={{height: 'max-content'}}>
        <Main>
          <OriginalPrice>
            <Text typography="text16Semibold">PRO-тариф</Text>
            <Text typography="text16Semibold">
              {getPriceFormat((defaultPlan?.price ?? 1) * months)}
            </Text>
          </OriginalPrice>
          {!loading ? (
            <>
              <Text color="textTertiary">
                {(defaultPlan?.slots ?? 1) === 1
                  ? '1 сотрудник'
                  : (defaultPlan?.slots ?? 1) + ' сотрудника'}
              </Text>
              <Text color="textTertiary">
                {defaultPlan?.price ?? 1} ₽ x {months} мес.
              </Text>
            </>
          ) : (
            <></>
          )}
        </Main>
        {loading ? (
          <Skeleton width={'100%'} height={390}>
            <rect width={'100%'} height={56} rx={8} ry={8} x={0} y={0} />
            <rect width={'100%'} height={1} rx={8} ry={8} x={0} y={80} />
            <rect width={'100%'} height={56} rx={8} ry={8} x={0} y={104} />
            <rect width={'100%'} height={24} rx={8} ry={8} x={0} y={184} />
            <rect width={'100%'} height={1} rx={8} ry={8} x={0} y={232} />
            <rect
              width={'calc(100% / 2 - 24px)'}
              height={28}
              rx={8}
              ry={8}
              x={0}
              y={258}
            />
            <rect
              width={'calc(100% / 2 - 24px)'}
              height={28}
              rx={8}
              ry={8}
              x={'calc(100% - (100% / 2 - 24px))'}
              y={258}
            />
            <rect width={'100%'} height={80} rx={8} ry={8} x={0} y={310} />
          </Skeleton>
        ) : (
          <>
            {isBonus || isDiscount || promocodeValue > 0 || bonus > 0 ? (
              <>
                {isDiscount ? (
                  <>
                    <Divider />
                    <div>
                      <Flex
                        justifyContent="space-between"
                        alignItems="center"
                        direction="row">
                        <Text color="textTertiary">Скидка</Text>
                        <Text typography="text16Semibold">
                          -{' '}
                          {initialPlan.price * getMonthByTypePlan(plan.type) -
                            plan.price}{' '}
                          ₽
                        </Text>
                      </Flex>
                      <Text
                        style={{whiteSpace: 'nowrap'}}
                        color="textTertiary"
                        mt={8}>
                        {Math.round(
                          (1 -
                            (data.find(item => item.id === plan.id)?.price ??
                              0) /
                              getMonthByTypePlan(plan.type) /
                              initialPlan.price) *
                            100,
                        )}
                        % x {months} мес.
                      </Text>
                    </div>
                  </>
                ) : null}
                {promocodeValue > 0 ? (
                  <div>
                    <Flex
                      justifyContent="space-between"
                      alignItems="center"
                      direction="row">
                      <Text color="textTertiary">Промокод</Text>
                      <Text typography="text16Semibold">
                        - {getPriceFormat(Math.round(promocodeValue))}
                      </Text>
                    </Flex>
                    <Text color="textTertiary" mt={8}>
                      +{state.promoCode?.split(',')[0]}% x {months} мес.
                    </Text>
                  </div>
                ) : null}
                {unusedBalance - bonus > 0 ? (
                  <Flex justifyContent="space-between">
                    <Text color="textTertiary">Неиспользованный остаток</Text>
                    <Text
                      style={{whiteSpace: 'nowrap'}}
                      typography="text16Semibold">
                      - {getPriceFormat(unusedBalance - bonus)}
                    </Text>
                  </Flex>
                ) : null}
                {bonus > 0 ? (
                  <Flex justifyContent="space-between">
                    <Text color="textTertiary">Бонусный счет</Text>
                    <Text typography="text16Semibold">
                      + {getPriceFormat(bonus)}
                    </Text>
                  </Flex>
                ) : null}
              </>
            ) : null}
            <Divider />
            <Flex justifyContent="space-between" direction="row">
              <Text typography="title20">Итого к оплате</Text>
              <Text color="mainPrimary" typography="title20">
                {plan.price} ₽
              </Text>
            </Flex>
            <Text typography="subHead14Regular" color="textTertiary">
              Я согласен с{' '}
              <Link href="https://business.booky.co/privacy-policy/">
                Политикой конфиденциальности
              </Link>{' '}
              и{' '}
              <Link href="https://business.booky.co/agreement-offer/">
                Условиями лицензии
              </Link>
              . Я понимаю, что моя подписка начинается сегодня.
            </Text>
          </>
        )}
      </Content>
      <Popup
        style={{
          width: '50%',
          height: '70%',
          backgroundColor: 'white',
          padding: 0,
        }}
        visible={!!cloudPaymentsUrl}
        onClose={() => {
          setCloudPaymentsUrl(null);
        }}>
        <Flex
          style={{
            width: '100%',
            height: '100%',
            backgroundColor: 'white',
            borderRadius: 40,
            padding: '0 30px',
            border: 'none',
          }}
          direction="column"
          gap={24}>
          {cloudPaymentsUrl !== null ? (
            <>
              <Loader show={iframeStatus === 'answer'} />
              <iframe
                id="iframeId"
                seamless
                width={'100%'}
                height={'100%'}
                onError={() => {
                  setCloudPaymentsUrl(null);
                  setIframeStatus('default');
                  showAlert('error', 'Ошибка при оплате');
                }}
                src={cloudPaymentsUrl}
                style={{border: 'none', backgroundColor: 'transparent'}}
                onLoad={async () => {
                  if (iframeStatus === 'default') {
                    setIframeStatus('cloud');
                    return;
                  }
                  try {
                    setIframeStatus('answer');
                    const response = await axios({
                      method: 'POST',
                      headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                      },
                      url: answer,
                      validateStatus: function (status) {
                        return status < 400;
                      },
                    });
                    if (response.data) {
                      complete();
                    } else {
                      showAlert('error', 'Ошибка при оплате');
                    }
                  } catch (error) {
                    showAlert('error', 'Ошибка при оплате');
                  } finally {
                    setCloudPaymentsUrl(null);
                    setIframeStatus('default');
                  }
                }}
              />
            </>
          ) : null}
        </Flex>
      </Popup>
      <Button
        loading={isLoading}
        disabled={isLoading}
        size="large"
        onClick={() => {
          if (state.paymentType === 'current' && user_token) {
            paymentByToken();
          } else {
            paymentByCard();
          }
        }}
        style={{width: '100%', marginTop: 24}}>
        Оплатить
      </Button>
    </Container>
  );
};
