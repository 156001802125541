import React from 'react';
import {Flex} from 'shared/ui/Flex';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';
import dayjs from 'dayjs';
import {BookingEventType} from 'shared/__generated__/graphql';
import {DocumentAccept} from 'shared/icons/DocumentAccept';
import {DocumentClose} from 'shared/icons/DocumentClose';
import {useColors} from 'shared/lib/hooks';
import {DocumentEdit} from 'shared/icons/DocumentEdit';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  pointer-events: all;
  cursor: pointer;
`;

type Props = {
  status: BookingEventType;
  created: string;
  employee: string;
  date: string;
  services: string;
  onClick: () => void;
};

const statusText = {
  created: 'Создание записи',
  updated: 'Изменение записи',
  cancelled: 'Отмена записи',
  completed: 'Завершение записи',
  reviewed: 'Отзыв',
};

const IconDiv = styled.div<{bg?: string}>`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({bg}) => bg};
  border-radius: 10px;
`;

export const RenderItem = (props: Props) => {
  const colors = useColors();
  const {status, created, employee, date, services, onClick} = props;
  const today = dayjs();

  const statusIcon = {
    created: (
      <IconDiv bg={colors.mainLight50}>
        <DocumentAccept />
      </IconDiv>
    ),
    updated: (
      <IconDiv bg={colors.mainLight50}>
        <DocumentEdit />
      </IconDiv>
    ),
    cancelled: (
      <IconDiv bg={colors.bgDanger}>
        <DocumentClose color={colors.dangerPrimary} />
      </IconDiv>
    ),
    completed: (
      <IconDiv bg={colors.bgSuccess}>
        <DocumentAccept color={colors.successPrimary} />
      </IconDiv>
    ),
    reviewed: (
      <IconDiv bg={colors.fillPrimary}>
        <DocumentEdit color={colors.textSecondary} />
      </IconDiv>
    ),
  };

  return (
    <Wrapper onClick={onClick}>
      <Flex direction="row" justifyContent="space-between" alignItems="center">
        <Flex gap={8} alignItems="center">
          {statusIcon[status]}
          <Text color="textTertiary" style={{pointerEvents: 'none'}}>
            {statusText[status]}
          </Text>
        </Flex>
        <Text
          style={{pointerEvents: 'none'}}
          typography="subHead14Regular"
          color="textTertiary">
          {dayjs(created).format(
            today.isSame(dayjs(created), 'date')
              ? 'HH:mm'
              : 'DD.MM.YYYY, HH:mm',
          )}
        </Text>
      </Flex>
      <Flex gap={8}>
        <Text style={{pointerEvents: 'none'}}>Сотрудник: {employee} /</Text>
        <Text style={{pointerEvents: 'none'}} color="mainPrimary">
          {date}
        </Text>
      </Flex>
      <Text style={{pointerEvents: 'none'}}>Услуги: {services}</Text>
    </Wrapper>
  );
};
