import React from 'react';
import {Content} from 'shared/ui/Content';
import {Skeleton} from 'shared/ui/Skeleton';
import {PaginInfo} from 'shared/ui/Table';

const calcSize = (size: number) => {
  return (size * 100) / 1566 + '%';
};

export const CertificatesSkeleton = () => {
  return (
    <Content style={{margin: '0 24px'}}>
      <Skeleton>
        <circle r={12} cx={24} cy={24} />
        <rect
          width={calcSize(93)}
          height={12}
          rx={6}
          ry={6}
          x={calcSize(70)}
          y={12 + 6}
        />
        <rect
          width={calcSize(60)}
          height={12}
          rx={6}
          ry={6}
          x={calcSize(462)}
          y={12 + 6}
        />
        <rect
          width={calcSize(50)}
          height={12}
          rx={6}
          ry={6}
          x={calcSize(838)}
          y={12 + 6}
        />
        <rect
          width={calcSize(103)}
          height={12}
          rx={6}
          ry={6}
          x={calcSize(1232)}
          y={12 + 6}
        />
        <circle r={12} cx={24} cy={24 + 24 + 18 + 14} />
        <rect
          width={calcSize(162)}
          height={12}
          rx={6}
          ry={6}
          x={calcSize(70)}
          y={12 + 6 + 56}
        />
        <rect
          width={calcSize(32)}
          height={12}
          rx={6}
          ry={6}
          x={calcSize(462)}
          y={12 + 6 + 56}
        />
        <rect
          width={calcSize(74)}
          height={12}
          rx={6}
          ry={6}
          x={calcSize(838)}
          y={12 + 6 + 56}
        />
        <rect
          width={calcSize(86)}
          height={12}
          rx={6}
          ry={6}
          x={calcSize(1232)}
          y={12 + 6 + 56}
        />
        <circle r={12} cx={24} cy={24 + 56 + 56} />
        <rect
          width={calcSize(68)}
          height={12}
          rx={6}
          ry={6}
          x={calcSize(70)}
          y={12 + 6 + 56 + 56}
        />
        <rect
          width={calcSize(137)}
          height={12}
          rx={6}
          ry={6}
          x={calcSize(462)}
          y={12 + 6 + 56 + 56}
        />
        <rect
          width={calcSize(74)}
          height={12}
          rx={6}
          ry={6}
          x={calcSize(838)}
          y={12 + 6 + 56 + 56}
        />
        <rect
          width={calcSize(146)}
          height={12}
          rx={6}
          ry={6}
          x={calcSize(1232)}
          y={12 + 6 + 56 + 56}
        />
      </Skeleton>
      <PaginInfo page={1} first={10} total={10} setOptions={() => null} />
    </Content>
  );
};
