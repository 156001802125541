import React, {useState} from 'react';
import styled from 'styled-components';
import {Layout} from 'shared/ui/Layout';
import {Text} from 'shared/ui/Text';
import {gql} from 'shared/__generated__';
import {useMutation, useQuery} from '@apollo/client';
import {useAppSelector} from 'shared/store';
import {TextInput} from 'shared/ui/TextInput';
import {Button} from 'shared/ui/Button';
import {Plus} from 'shared/icons/Plus';
import {useColors, useEmployeePermission} from 'shared/lib/hooks';
import {Content} from 'shared/ui/Content';
import {BonusSkeleton} from './ui/BonusSkeleton';
import {Table} from 'shared/ui/Table';
import dayjs from 'dayjs';
import {EmptyState} from './ui/EmptyState';
import {Search} from 'shared/icons/Search';
import {useNavigate} from 'react-router-dom';
import {GET_BONUSES, ModalIssueBonus} from 'entities/loyalty';
import {showAlert} from 'shared/ui/Alert';
import {Flex} from 'shared/ui/Flex';
import {Seo} from 'shared/ui/Seo';
import {FooterAction} from 'shared/ui/FooterAction';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  grid-column-start: 1;
  grid-column-end: 13;
  gap: 24px;
`;

const Section = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  pointer-events: all;
  margin: 0 24px;
`;

const Head = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 56px;
  gap: 8px;
  margin: 24px 24px 0 24px;
`;

const DELETE_BONUSES = gql(`
  mutation DeleteBonusCards($ids: [String!]!) {
    deleteBonusCardTemplates(ids: $ids) {
      id
    }
  }
`);

const titles = [
  'Бонусные карты',
  'Клиенты',
  'Размер бонуса',
  'Срок действия',
  'Ограничение платы бонусами',
  'Исключения по методам оплаты',
];

const CellText = styled(Text)`
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

type OptionsType = {
  page: number;
  first: number;
  total?: number;
  count?: number;
};

export const BonusCards = () => {
  const navigate = useNavigate();
  const username = useAppSelector(state => state.company.data?.username);
  const colors = useColors();
  const companyId = useAppSelector(state => state.company.data!.id);
  const [search, setSearch] = useState('');
  const [modalIssue, setModalIssue] = useState(false);
  const [bonusId, setBonusId] = useState<string | null>(null);
  const [options, setOptions] = useState<OptionsType>({
    page: 1,
    first: 40,
    total: 0,
    count: 0,
  });
  const {data, loading} = useQuery(GET_BONUSES, {
    variables: {
      first: options.first,
      page: options.page,
      companyId,
      name: search === '' ? undefined : search,
    },
    onCompleted: resData => {
      setOptions(prev => ({
        ...prev,
        total: resData.bonusCardTemplates?.paginatorInfo.total,
        count: resData.bonusCardTemplates?.paginatorInfo.count,
      }));
    },
  });
  const {edit_bonus_card_templates} = useEmployeePermission(data);
  const [selections, setSelections] = useState<number[]>([]);
  const [deleteBonuses] = useMutation(DELETE_BONUSES, {
    refetchQueries: [
      {
        query: GET_BONUSES,
        variables: {
          page: 1,
          first: 40,
          companyId,
        },
      },
    ],
  });

  const bonuses = data?.bonusCardTemplates?.data ?? [];

  return (
    <Layout variant="sidebar">
      <Seo title="Бонусные карты" />
      <Container>
        <Head>
          <Text typography="title24">Бонусные карты</Text>
          <Text typography="title24" color="textTertiary">
            {data?.bonusCardTemplates?.paginatorInfo.total ?? 0}
          </Text>
        </Head>
        <Flex flex={1} gap={24} direction="column">
          <Section>
            <TextInput
              value={search}
              backgroundColor={colors.bgPrimary}
              onChange={setSearch}
              borderRadius={14}
              disabled={false}
              variant="default"
              placeholder="Поиск"
              height={54}
              width="392px"
              leftElement={<Search color={colors.textTertiary} />}
            />
            <Button
              variant="filled"
              size="medium"
              typography="subHead14Medium"
              color={colors.textPrimary}
              onClick={() => navigate(`/${username}/create-bonus`)}>
              <Plus />
              Добавить бонусную карту
            </Button>
          </Section>
          {loading ? (
            <BonusSkeleton />
          ) : bonuses.length > 0 ? (
            <Content
              style={{
                margin: '0 24px 24px 24px',
              }}>
              <Table
                widths={[299, 138, 191, 194, 255, 334]}
                rows={bonuses.map(item => [
                  <CellText key={'bonus_name_' + item.id}>
                    {item.name}
                  </CellText>,
                  <CellText
                    onClick={e => {
                      setBonusId(item.id);
                      setModalIssue(true);
                      e.stopPropagation();
                    }}
                    key={'bonus_customers_' + item.id}
                    color="mainPrimary">
                    {item.bonusCards.reduce((acc, cur) => {
                      if (cur.customer.id) {
                        return acc + 1;
                      }
                      return acc;
                    }, 0)}{' '}
                    шт
                  </CellText>,
                  <Flex direction="column" key={'bonus_sizes_' + item.id}>
                    {item.bonusSizes.map(bonusSize => (
                      <Text key={'bonus_size_' + bonusSize.id}>
                        от {bonusSize.min_amount} ₽
                        {bonusSize.size ? ` - ${bonusSize.size} %` : ''}
                      </Text>
                    ))}
                  </Flex>,
                  <CellText key={'bonus_expiration_' + item.id}>
                    {item.active_months
                      ? dayjs(item.created_at).format('с DD.MM.YYYY') +
                        dayjs(item.created_at)
                          .add(item.active_months, 'month')
                          .format(' до DD.MM.YYYY')
                      : 'Неограничено'}
                  </CellText>,
                  <CellText key={'bonus_limit_' + item.id}>
                    {item.payment_limit}%
                  </CellText>,
                  <CellText key={'bonus_payment_' + item.id}>
                    {item.paymentMethods.length > 0
                      ? item.paymentMethods.map(item2 => item2.title).join(', ')
                      : '-'}
                  </CellText>,
                ])}
                onClick={index => {
                  navigate(`/${username}/bonus/${bonuses[index].id}/edit`);
                }}
                titles={titles}
                selections={selections}
                onSelectionChange={values => setSelections(values)}
                options={options}
                setOptions={setOptions}
              />
            </Content>
          ) : (
            <EmptyState search={search} />
          )}
          <ModalIssueBonus
            visible={modalIssue}
            onClose={() => setModalIssue(false)}
            id={bonusId!}
          />
        </Flex>
        {selections.length > 0 && (
          <FooterAction
            allICount={options.count}
            selectedCount={selections.length}
            editPermission={edit_bonus_card_templates}
            disabled={selections.length === 0}
            onDelete={() => {
              deleteBonuses({
                variables: {
                  ids:
                    data?.bonusCardTemplates?.data
                      .filter((_, index) => selections.includes(index))
                      .map(item => item.id) ?? [],
                },
              })
                .then(() => {
                  setSelections([]);
                })
                .catch(e => {
                  showAlert('error', e.message);
                });
            }}
            title={'Удалить бонусные карты'}
            text="Вы точно хотите удалить бонусные карты?"
          />
        )}
      </Container>
    </Layout>
  );
};
