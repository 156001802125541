import React from 'react';
import styled from 'styled-components';
import {More} from 'shared/icons/More';

const TooltipContainer = styled.div`
  position: relative;
  display: flex;
  &:hover {
    .tooltipVisible {
      visibility: visible;
      opacity: 1;
    }
  }
`;

const TooltipContent = styled.div`
  visibility: hidden;
  background-color: ${({theme}) => theme.bgPrimary};
  justify-content: center;
  border-radius: 16px;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: auto;
  right: -25%;
  margin-top: 0px;
  margin-left: auto;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  padding: 16px 0;
  gap: 8px;
  border: 1px solid ${({theme}) => theme.borderPrimary};
  width: 234px;
  ${({theme}) => theme.cardShadow}
`;
export const TooltipPayments = ({children}: React.PropsWithChildren) => (
  <TooltipContainer>
    <More />
    <TooltipContent className="tooltipVisible">{children}</TooltipContent>
  </TooltipContainer>
);
