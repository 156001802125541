import React from 'react';
import {useColorScheme} from 'shared/lib/hooks';
import {EmptyContainer} from 'shared/ui/EmptyContainer';

export const EmptySalon = ({size = 400}: {size?: number}) => {
  const colorScheme = useColorScheme();
  return (
    <EmptyContainer size={size}>
      {colorScheme === 'light' ? (
        <svg width={size} height={size} viewBox="0 0 400 400" fill="none">
          <path
            d="M15.4519 227.218C18.3382 227.218 20.9038 224.929 20.9038 221.658C20.9038 218.715 18.6589 216.098 15.4519 216.098C12.5656 216.098 10 218.388 10 221.658C10 224.602 12.2449 227.218 15.4519 227.218Z"
            fill="url(#paint0_linear_18292_1426)"
          />
          <path
            d="M386.185 159.842C390.996 155.263 391.316 147.741 386.827 142.835C382.337 137.929 374.961 137.602 369.829 142.18C365.019 146.759 364.698 154.282 369.188 159.188C373.678 164.094 381.054 164.421 386.185 159.842Z"
            fill="#EAEEF9"
          />
          <path
            d="M383.617 106.857C386.503 106.857 388.748 104.568 388.748 101.624C388.748 98.6807 386.503 96.3912 383.617 96.3912C380.73 96.3912 378.485 98.6807 378.485 101.624C378.485 104.568 380.73 106.857 383.617 106.857Z"
            fill="url(#paint1_linear_18292_1426)"
          />
          <path
            d="M364.054 197.455C363.092 197.455 362.45 196.801 362.45 195.82V191.241C362.45 190.259 363.092 189.605 364.054 189.605C365.016 189.605 365.657 190.259 365.657 191.241V195.82C365.657 196.801 364.695 197.455 364.054 197.455Z"
            fill="#BCC5D6"
          />
          <path
            d="M364.054 290.669C363.092 290.669 362.45 290.015 362.45 289.034V225.256C362.45 224.275 363.092 223.621 364.054 223.621C365.016 223.621 365.657 224.275 365.657 225.256V288.707C365.657 290.015 364.695 290.669 364.054 290.669Z"
            fill="#BCC5D6"
          />
          <path
            d="M365.017 227.872H362.772C361.81 227.872 361.169 227.218 360.848 226.237L359.565 219.368C359.244 218.06 360.206 217.079 361.489 217.079H366.3C367.583 217.079 368.545 218.06 368.224 219.368L366.941 226.237C366.621 227.218 365.979 227.872 365.017 227.872Z"
            fill="#BCC5D6"
          />
          <path
            d="M344.168 295.575C344.168 292.632 343.206 290.342 341.282 288.38C342.564 286.418 343.527 284.128 343.527 281.512C343.527 274.97 338.395 269.41 331.661 269.41C331.34 269.41 331.34 269.41 331.019 269.41C331.34 268.429 331.661 267.121 331.661 266.139C331.661 260.906 327.491 256.327 322.04 256.327C322.04 256.327 322.04 256.327 321.719 256.327C321.719 256.327 321.719 256.327 321.398 256.327C315.946 256 306.325 262.542 306.325 262.542L309.853 299.827L313.06 305.715L333.585 306.042C339.678 306.042 344.168 301.463 344.168 295.575Z"
            fill="#BCC5D6"
          />
          <path
            opacity="0.3"
            d="M344.168 295.575C344.168 292.632 343.206 290.342 341.282 288.38C342.564 286.418 343.527 284.128 343.527 281.512C343.527 274.97 338.395 269.41 331.661 269.41C331.34 269.41 331.34 269.41 331.019 269.41C331.34 268.429 331.661 267.121 331.661 266.139C331.661 260.906 327.491 256.327 322.04 256.327C322.04 256.327 322.04 256.327 321.719 256.327C321.719 256.327 321.719 256.327 321.398 256.327C315.946 256 306.325 262.542 306.325 262.542L309.853 299.827L313.06 305.715L333.585 306.042C339.678 306.042 344.168 301.463 344.168 295.575Z"
            fill="url(#paint2_linear_18292_1426)"
          />
          <path
            d="M383.616 312.91H17.375C15.4508 312.91 14.168 311.602 14.168 309.639C14.168 307.677 15.4508 306.369 17.375 306.369H383.295C385.219 306.369 386.502 307.677 386.502 309.639C386.502 311.602 385.219 312.91 383.616 312.91Z"
            fill="#BCC5D6"
          />
          <path
            d="M262.392 306.041H69.0098V123.537H317.232V306.041H290.935H262.392Z"
            fill="#EAEEF9"
          />
          <path
            opacity="0.5"
            d="M262.392 306.041H69.0098V123.537H317.232V306.041H290.935H262.392Z"
            fill="url(#paint3_linear_18292_1426)"
          />
          <path
            d="M342.564 142.508V141.526C342.564 140.545 342.244 139.564 341.923 138.256C341.282 136.948 340.32 135.639 339.037 134.658L317.87 119.613H69.9686L37.5778 134.985C35.9743 135.639 34.6915 136.948 34.0501 138.256C33.4087 139.564 33.088 140.872 33.088 142.508C31.8052 155.263 41.7469 167.692 55.2164 167.692C67.403 167.692 77.3447 157.226 77.3447 144.797C77.3447 157.553 87.2865 167.692 99.4731 167.692C111.66 167.692 121.922 156.244 121.922 143.489C121.922 156.244 131.543 167.692 143.73 167.692C155.275 167.692 164.896 158.207 165.858 146.432C167.462 159.515 178.045 168.019 187.987 168.019C200.173 168.019 209.794 157.88 210.115 145.451C210.115 145.778 210.115 146.105 210.115 146.432V145.451C215.888 178.812 254.051 170.963 254.051 144.47C254.051 144.797 254.051 145.451 254.051 145.778C254.051 144.797 254.372 143.816 254.372 142.835C254.372 154.282 262.71 164.748 273.614 166.711C277.141 167.038 280.669 166.711 284.197 165.729C284.838 165.402 285.48 165.402 285.8 165.075C291.252 162.786 296.063 158.207 297.987 151.338C297.987 151.011 298.308 150.684 298.308 150.357C298.628 149.703 298.628 148.722 298.949 148.068C301.194 158.534 309.853 167.038 320.436 167.038C334.868 167.692 344.168 154.282 342.564 142.508Z"
            fill="#6765F8"
          />
          <path
            d="M299.588 144.796C299.588 146.104 299.588 147.74 299.267 149.048C299.267 149.702 298.947 150.683 298.626 151.337C298.626 151.665 298.305 151.992 298.305 152.319C296.381 158.533 291.891 163.439 286.119 166.056C285.477 166.383 284.836 166.383 284.515 166.71C282.27 167.691 279.705 168.018 277.46 168.018C276.177 168.018 275.215 168.018 273.932 167.691C263.349 165.728 254.69 155.262 254.69 143.815C254.69 144.796 254.69 145.777 254.369 146.759C254.369 146.759 254.369 146.759 254.369 147.086V146.431L254.69 137.601L245.39 119.939H280.987L285.157 124.518L299.267 138.582L299.588 144.796Z"
            fill="white"
          />
          <path
            d="M121.92 137.274L122.241 146.431C122.241 145.45 121.92 144.469 121.92 143.161C121.92 155.916 111.658 167.364 99.4711 167.364C87.2845 167.364 77.3428 157.552 77.3428 144.796C77.3428 145.45 77.3428 145.777 77.3428 146.104L77.6635 138.255L91.7743 124.518L96.5848 119.939H131.221L121.92 137.274Z"
            fill="white"
          />
          <path
            d="M165.538 137.929L165.218 147.087C165.218 146.105 165.538 145.124 165.538 143.816C165.538 156.572 175.801 168.019 187.988 168.019C200.174 168.019 210.116 157.553 210.116 145.124C210.116 145.778 210.116 146.105 210.116 146.432L209.795 138.583L206.268 124.846L204.664 119.613H170.67L165.538 137.929Z"
            fill="white"
          />
          <path
            d="M317.23 105.549V119.94H69.9697V105.549C69.9697 99.9887 74.4595 95.4098 79.9115 95.4098H307.609C312.74 95.4098 317.23 99.9887 317.23 105.549Z"
            fill="#D4DAE8"
          />
          <path
            opacity="0.4"
            d="M202.101 184.045H93.0625V280.53H202.101V184.045Z"
            fill="#1C3754"
          />
          <path
            d="M195.684 190.587H99.1533V273.989H195.684V190.587Z"
            fill="white"
          />
          <path
            d="M127.696 190.587L99.1533 253.057V222.312L113.585 190.587H127.696Z"
            fill="#EAEEF9"
          />
          <path
            d="M186.064 190.587L147.9 273.989H135.072L134.11 273.662L171.953 190.587H186.064Z"
            fill="#EAEEF9"
          />
          <path
            d="M195.684 193.857L158.803 273.989H151.748L190.232 190.587L195.684 193.857Z"
            fill="#EAEEF9"
          />
          <path
            opacity="0.4"
            d="M223.264 184.045V306.368H294.459V184.045H223.264Z"
            fill="#1C3754"
          />
          <path
            d="M288.045 190.587H229.678V246.188H288.045V190.587Z"
            fill="white"
          />
          <path
            d="M288.045 252.73H229.678V299.827H288.045V252.73Z"
            fill="white"
          />
          <path
            opacity="0.4"
            d="M160.408 225.256L144.052 213.481C144.052 212.827 144.052 212.173 144.052 211.846C143.732 210.211 142.128 208.902 140.525 209.23C138.601 209.23 136.997 210.865 136.997 212.827C136.997 213.808 137.318 214.79 137.959 215.444L128.98 233.433C128.659 233.76 128.98 234.414 129.3 234.414H129.621C129.942 234.414 130.262 234.087 130.262 234.087L139.242 216.098C139.563 216.098 139.883 216.425 140.204 216.425C141.166 216.425 142.128 215.771 142.77 215.117L159.125 226.891C159.446 227.218 160.088 227.218 160.408 226.564C160.729 225.91 160.729 225.256 160.408 225.256Z"
            fill="#1C3754"
          />
          <path
            d="M169.067 246.188L131.224 256C129.621 256.327 128.017 255.346 127.376 253.711L123.527 238.011C123.207 236.376 124.169 234.741 125.772 234.087L163.615 224.275C165.218 223.948 166.822 224.929 167.463 226.564L171.312 242.263C171.632 243.899 170.67 245.534 169.067 246.188Z"
            fill="#BCC5D6"
          />
          <path
            d="M134.431 240.955L159.125 234.741C160.408 234.414 161.691 235.068 162.011 236.376C162.332 237.684 161.691 238.993 160.408 239.32L135.714 245.534C134.431 245.861 133.148 245.207 132.828 243.899C132.507 242.263 133.469 241.282 134.431 240.955Z"
            fill="white"
          />
          <path
            d="M227.115 251.094C228.355 251.094 229.36 250.069 229.36 248.805C229.36 247.54 228.355 246.515 227.115 246.515C225.875 246.515 224.87 247.54 224.87 248.805C224.87 250.069 225.875 251.094 227.115 251.094Z"
            fill="white"
          />
          <path
            d="M294.139 299.827H223.264V308.985H294.139V299.827Z"
            fill="#EAEEF9"
          />
          <path
            d="M298.307 308.985H218.132V318.143H298.307V308.985Z"
            fill="white"
          />
          <path
            d="M364.056 220.022C371.14 220.022 376.884 214.165 376.884 206.94C376.884 199.714 371.14 193.857 364.056 193.857C356.971 193.857 351.228 199.714 351.228 206.94C351.228 214.165 356.971 220.022 364.056 220.022Z"
            fill="#EAEEF9"
          />
          <path
            opacity="0.1"
            d="M364.056 220.022C371.14 220.022 376.884 214.165 376.884 206.94C376.884 199.714 371.14 193.857 364.056 193.857C356.971 193.857 351.228 199.714 351.228 206.94C351.228 214.165 356.971 220.022 364.056 220.022Z"
            fill="url(#paint4_linear_18292_1426)"
          />
          <path
            d="M366.939 283.801H360.846C359.563 283.801 358.28 284.782 357.96 286.09L354.111 306.368H373.674L369.826 286.09C369.505 284.782 368.222 283.801 366.939 283.801Z"
            fill="#D4DAE8"
          />
          <path
            d="M43.6728 197.455C42.7107 197.455 42.0693 196.801 42.0693 195.82V191.241C42.0693 190.259 42.7107 189.605 43.6728 189.605C44.6349 189.605 45.2763 190.259 45.2763 191.241V195.82C45.2763 196.801 44.6349 197.455 43.6728 197.455Z"
            fill="#BCC5D6"
          />
          <path
            d="M43.6728 290.669C42.7107 290.669 42.0693 290.015 42.0693 289.034V225.256C42.0693 224.275 42.7107 223.621 43.6728 223.621C44.6349 223.621 45.2763 224.275 45.2763 225.256V288.707C45.2763 290.015 44.6349 290.669 43.6728 290.669Z"
            fill="#BCC5D6"
          />
          <path
            d="M44.9561 227.872H43.0319C42.0698 227.872 41.4284 227.218 41.1077 226.237L39.8249 219.368C39.5042 218.06 40.4663 217.079 41.7491 217.079H46.5596C47.8424 217.079 48.8045 218.06 48.4838 219.368L46.5596 226.237C46.5596 227.218 45.5975 227.872 44.9561 227.872Z"
            fill="#BCC5D6"
          />
          <path
            d="M43.6737 220.022C50.7585 220.022 56.5018 214.165 56.5018 206.94C56.5018 199.714 50.7585 193.857 43.6737 193.857C36.589 193.857 30.8457 199.714 30.8457 206.94C30.8457 214.165 36.589 220.022 43.6737 220.022Z"
            fill="#EAEEF9"
          />
          <path
            opacity="0.1"
            d="M43.6737 220.022C50.7585 220.022 56.5018 214.165 56.5018 206.94C56.5018 199.714 50.7585 193.857 43.6737 193.857C36.589 193.857 30.8457 199.714 30.8457 206.94C30.8457 214.165 36.589 220.022 43.6737 220.022Z"
            fill="url(#paint5_linear_18292_1426)"
          />
          <path
            d="M46.8818 283.801H40.7884C39.5056 283.801 38.2228 284.782 37.9021 286.09L34.0537 306.368H53.6165L49.7681 286.09C49.4474 284.782 48.1646 283.801 46.8818 283.801Z"
            fill="#D4DAE8"
          />
          <path
            opacity="0.3"
            d="M342.247 138.583H34.0537C34.6951 137.274 35.9779 135.966 37.5814 135.312L69.9722 119.94H318.195L339.361 134.985C340.644 135.966 341.606 137.274 342.247 138.583Z"
            fill="url(#paint6_linear_18292_1426)"
          />
          <path
            d="M223.266 126.481H159.768C147.581 126.481 137.96 116.342 137.96 104.241C137.96 91.812 147.902 82 159.768 82H223.266C235.453 82 245.074 92.1391 245.074 104.241C245.395 116.342 235.453 126.481 223.266 126.481Z"
            fill="#F3F6FF"
          />
          <path
            d="M145.016 316.181C145.016 316.508 145.016 316.508 145.016 316.181H40.7885C40.4678 314.872 40.4678 313.237 40.4678 311.929C40.4678 307.023 41.7506 302.444 43.9955 298.519C43.3541 296.884 43.0334 294.921 43.0334 292.959C43.0334 285.436 49.1267 278.895 56.8235 278.895C60.3512 278.895 63.879 280.53 66.4446 282.82C66.7653 275.297 72.8586 269.737 80.2347 269.737C87.9315 269.737 94.0248 275.951 94.0248 283.801C94.0248 286.417 93.3834 288.707 92.1006 290.669C95.3077 291.978 97.8733 294.594 99.1561 297.865C102.684 297.865 105.891 299.5 108.456 301.79C109.098 297.211 112.946 293.94 117.757 293.94C120.322 293.94 122.567 294.921 124.171 296.557C125.133 292.305 128.661 289.361 133.15 289.361C138.282 289.361 142.451 293.613 142.451 298.846C142.451 301.79 141.168 304.079 139.244 306.042C142.771 307.677 145.658 311.929 145.016 316.181Z"
            fill="#BCC5D6"
          />
          <path
            d="M116.251 279.956C116.647 276.366 114.116 273.128 110.596 272.724C107.075 272.319 103.9 274.901 103.504 278.491C103.107 282.081 105.639 285.319 109.159 285.724C112.679 286.128 115.854 283.546 116.251 279.956Z"
            fill="#EAEEF9"
          />
          <path
            d="M86.6473 312.583C86.6473 313.891 86.6473 315.199 86.0059 316.507H56.8221C56.5014 315.199 56.1807 313.891 56.1807 312.583C56.1807 310.62 56.5014 308.658 57.1428 306.695C59.3877 301.135 64.8396 297.21 71.2536 297.21C77.6676 297.21 83.1196 301.462 85.3645 307.022C86.3266 308.658 86.6473 310.62 86.6473 312.583Z"
            fill="#EAEEF9"
          />
          <path
            d="M100.114 314.872C100.114 315.526 100.114 316.18 100.114 316.508H82.1547C82.1547 315.853 81.834 315.526 81.834 314.872C81.834 311.601 83.4375 308.985 86.0031 307.023C87.6066 306.041 89.2101 305.387 91.1343 305.387C95.9448 305.387 100.114 309.639 100.114 314.872Z"
            fill="#EAEEF9"
          />
          <path
            d="M60.3493 313.564C60.3493 314.545 60.0286 315.526 59.7079 316.508H42.39C42.39 315.526 42.0693 314.545 42.0693 313.564C42.0693 308.331 46.2384 304.079 51.049 304.079C53.6146 304.079 55.8595 305.06 57.463 306.696C59.3872 308.331 60.3493 310.948 60.3493 313.564Z"
            fill="#EAEEF9"
          />
          <path
            d="M129.939 316.508H111.659C111.659 311.275 115.828 307.023 120.96 307.023C126.091 307.023 129.939 311.275 129.939 316.508Z"
            fill="#EAEEF9"
          />
          <path
            d="M257.263 190.586L231.607 246.188H229.683V222.312L244.114 190.586H257.263Z"
            fill="#EAEEF9"
          />
          <path
            d="M265.917 190.586L240.582 246.188H236.092L261.427 190.586H265.917Z"
            fill="#EAEEF9"
          />
          <path
            d="M288.048 199.744V216.752L274.579 246.188H266.882L288.048 199.744Z"
            fill="#EAEEF9"
          />
          <path
            d="M254.697 252.729L235.455 299.827H229.683V292.304L245.718 252.729H254.697Z"
            fill="#EAEEF9"
          />
          <path
            d="M288.045 261.233L272.331 299.827H276.179L288.045 271.372V261.233Z"
            fill="#EAEEF9"
          />
          <path
            d="M281.635 252.729L262.393 299.827H256.941L276.504 252.729H281.635Z"
            fill="#EAEEF9"
          />
          <defs>
            <linearGradient
              id="paint0_linear_18292_1426"
              x1="10"
              y1="221.649"
              x2="20.367"
              y2="221.649"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#83A6FF" />
              <stop offset="1" stopColor="#5A78FF" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_18292_1426"
              x1="378.61"
              y1="101.741"
              x2="388.347"
              y2="101.741"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#83A6FF" />
              <stop offset="1" stopColor="#5A78FF" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_18292_1426"
              x1="330.595"
              y1="280.719"
              x2="306.982"
              y2="285.821"
              gradientUnits="userSpaceOnUse">
              <stop offset="0.00289017" stopColor="#606673" stopOpacity="0" />
              <stop offset="1" stopColor="#373C47" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_18292_1426"
              x1="193.028"
              y1="256.729"
              x2="193.028"
              y2="120.124"
              gradientUnits="userSpaceOnUse">
              <stop offset="0.00289017" stopColor="#606673" stopOpacity="0" />
              <stop offset="1" stopColor="#373C47" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_18292_1426"
              x1="354.4"
              y1="195.633"
              x2="372.065"
              y2="215.534"
              gradientUnits="userSpaceOnUse">
              <stop offset="0.00289017" stopColor="#606673" stopOpacity="0" />
              <stop offset="1" stopColor="#373C47" />
            </linearGradient>
            <linearGradient
              id="paint5_linear_18292_1426"
              x1="34.2419"
              y1="195.633"
              x2="51.9065"
              y2="215.534"
              gradientUnits="userSpaceOnUse">
              <stop offset="0.00289017" stopColor="#606673" stopOpacity="0" />
              <stop offset="1" stopColor="#373C47" />
            </linearGradient>
            <linearGradient
              id="paint6_linear_18292_1426"
              x1="188.149"
              y1="138.556"
              x2="188.149"
              y2="119.582"
              gradientUnits="userSpaceOnUse">
              <stop offset="0.00289017" stopColor="#3F5073" stopOpacity="0" />
              <stop offset="1" stopColor="#283247" />
            </linearGradient>
          </defs>
        </svg>
      ) : (
        <svg width={size} height={size} viewBox="0 0 400 400" fill="none">
          <path
            d="M15.4519 227.216C18.3382 227.216 20.9038 224.927 20.9038 221.656C20.9038 218.713 18.6589 216.096 15.4519 216.096C12.5656 216.096 10 218.386 10 221.656C10 224.6 12.2449 227.216 15.4519 227.216Z"
            fill="url(#paint0_linear_18292_1428)"
          />
          <path
            d="M386.183 159.841C390.994 155.262 391.314 147.739 386.824 142.833C382.335 137.927 374.959 137.6 369.827 142.179C365.017 146.758 364.696 154.281 369.186 159.187C373.676 164.093 381.052 164.42 386.183 159.841Z"
            fill="#282C35"
          />
          <path
            d="M383.615 106.856C386.501 106.856 388.746 104.567 388.746 101.623C388.746 98.6796 386.501 96.3901 383.615 96.3901C380.728 96.3901 378.483 98.6796 378.483 101.623C378.483 104.567 380.728 106.856 383.615 106.856Z"
            fill="url(#paint1_linear_18292_1428)"
          />
          <path
            d="M364.053 197.454C363.091 197.454 362.449 196.8 362.449 195.819V191.24C362.449 190.259 363.091 189.605 364.053 189.605C365.015 189.605 365.656 190.259 365.656 191.24V195.819C365.656 196.8 364.694 197.454 364.053 197.454Z"
            fill="#3E4452"
          />
          <path
            d="M364.053 290.668C363.091 290.668 362.449 290.013 362.449 289.032V225.254C362.449 224.273 363.091 223.619 364.053 223.619C365.015 223.619 365.656 224.273 365.656 225.254V288.705C365.656 290.013 364.694 290.668 364.053 290.668Z"
            fill="#3E4452"
          />
          <path
            d="M365.015 227.872H362.77C361.808 227.872 361.167 227.217 360.846 226.236L359.563 219.368C359.242 218.06 360.205 217.078 361.487 217.078H366.298C367.581 217.078 368.543 218.06 368.222 219.368L366.939 226.236C366.619 227.217 365.977 227.872 365.015 227.872Z"
            fill="#3E4452"
          />
          <path
            d="M344.166 295.573C344.166 292.629 343.204 290.34 341.279 288.378C342.562 286.415 343.524 284.126 343.524 281.509C343.524 274.968 338.393 269.408 331.658 269.408C331.338 269.408 331.338 269.408 331.017 269.408C331.338 268.427 331.658 267.118 331.658 266.137C331.658 260.904 327.489 256.325 322.037 256.325C322.037 256.325 322.037 256.325 321.717 256.325C321.717 256.325 321.717 256.325 321.396 256.325C315.944 255.998 306.323 262.539 306.323 262.539L309.851 299.825L313.058 305.712L333.583 306.039C339.676 306.039 344.166 301.46 344.166 295.573Z"
            fill="#3E4452"
          />
          <path
            opacity="0.3"
            d="M344.166 295.573C344.166 292.629 343.204 290.34 341.279 288.378C342.562 286.415 343.524 284.126 343.524 281.509C343.524 274.968 338.393 269.408 331.658 269.408C331.338 269.408 331.338 269.408 331.017 269.408C331.338 268.427 331.658 267.118 331.658 266.137C331.658 260.904 327.489 256.325 322.037 256.325C322.037 256.325 322.037 256.325 321.717 256.325C321.717 256.325 321.717 256.325 321.396 256.325C315.944 255.998 306.323 262.539 306.323 262.539L309.851 299.825L313.058 305.712L333.583 306.039C339.676 306.039 344.166 301.46 344.166 295.573Z"
            fill="url(#paint2_linear_18292_1428)"
          />
          <path
            d="M383.613 312.908H17.375C15.4508 312.908 14.168 311.6 14.168 309.638C14.168 307.675 15.4508 306.367 17.375 306.367H383.292C385.217 306.367 386.499 307.675 386.499 309.638C386.499 311.6 385.217 312.908 383.613 312.908Z"
            fill="#3E4452"
          />
          <path
            d="M262.139 307.284H69.71V122.714H316.708V307.284H290.541H262.139Z"
            fill="#495162"
          />
          <path
            opacity="0.5"
            d="M262.139 304.57H69.71V122.714H316.708V304.57H290.541H262.139Z"
            fill="url(#paint3_linear_18292_1428)"
          />
          <path
            d="M342.562 142.507V141.526C342.562 140.545 342.242 139.564 341.921 138.256C341.28 136.947 340.318 135.639 339.035 134.658L317.869 119.613H69.9684L37.5778 134.985C35.9743 135.639 34.6915 136.947 34.0501 138.256C33.4087 139.564 33.088 140.872 33.088 142.507C31.8052 155.263 41.7469 167.691 55.2162 167.691C67.4028 167.691 77.3444 157.225 77.3444 144.797C77.3444 157.552 87.2861 167.691 99.4727 167.691C111.659 167.691 121.922 156.244 121.922 143.489C121.922 156.244 131.543 167.691 143.729 167.691C155.274 167.691 164.895 158.207 165.857 146.432C167.461 159.515 178.044 168.019 187.986 168.019C200.172 168.019 209.793 157.879 210.114 145.451C210.114 145.778 210.114 146.105 210.114 146.432V145.451C215.886 178.812 254.05 170.962 254.05 144.47C254.05 144.797 254.05 145.451 254.05 145.778C254.05 144.797 254.37 143.816 254.37 142.834C254.37 154.282 262.708 164.748 273.612 166.71C277.14 167.037 280.668 166.71 284.195 165.729C284.837 165.402 285.478 165.402 285.799 165.075C291.251 162.785 296.061 158.207 297.985 151.338C297.985 151.011 298.306 150.684 298.306 150.357C298.627 149.703 298.627 148.722 298.947 148.067C301.192 158.534 309.851 167.037 320.434 167.037C334.866 167.691 344.166 154.282 342.562 142.507Z"
            fill="#6765F8"
          />
          <path
            d="M299.585 144.796C299.585 146.104 299.585 147.739 299.264 149.048C299.264 149.702 298.943 150.683 298.623 151.337C298.623 151.664 298.302 151.991 298.302 152.318C296.378 158.532 291.888 163.438 286.115 166.055C285.474 166.382 284.833 166.382 284.512 166.709C282.267 167.69 279.701 168.017 277.457 168.017C276.174 168.017 275.212 168.017 273.929 167.69C263.346 165.728 254.687 155.262 254.687 143.815C254.687 144.796 254.687 145.777 254.366 146.758C254.366 146.758 254.366 146.758 254.366 147.085V146.431L254.687 137.6L245.387 119.939H280.984L285.153 124.518L299.264 138.581L299.585 144.796Z"
            fill="#586178"
          />
          <path
            d="M121.919 137.273L122.24 146.431C122.24 145.45 121.919 144.469 121.919 143.16C121.919 155.916 111.657 167.363 99.47 167.363C87.2835 167.363 77.3418 157.551 77.3418 144.796C77.3418 145.45 77.3418 145.777 77.3418 146.104L77.6625 138.254L91.7732 124.518L96.5837 119.939H131.219L121.919 137.273Z"
            fill="#586178"
          />
          <path
            d="M165.537 137.928L165.216 147.086C165.216 146.105 165.537 145.124 165.537 143.816C165.537 156.571 175.799 168.019 187.985 168.019C200.172 168.019 210.114 157.552 210.114 145.124C210.114 145.778 210.114 146.105 210.114 146.432L209.793 138.583L206.265 124.846L204.662 119.613H170.668L165.537 137.928Z"
            fill="#586178"
          />
          <path
            d="M317.229 105.549V119.94H69.9697V105.549C69.9697 99.9886 74.4595 95.4097 79.9114 95.4097H307.608C312.739 95.4097 317.229 99.9886 317.229 105.549Z"
            fill="#586178"
          />
          <path
            opacity="0.4"
            d="M202.099 184.045H93.0615V280.529H202.099V184.045Z"
            fill="#353B4A"
          />
          <path
            d="M195.683 190.585H99.1523V273.987H195.683V190.585Z"
            fill="#8E96AB"
          />
          <path
            opacity="0.3"
            d="M127.695 190.585L99.1523 253.055V222.311L113.584 190.585H127.695Z"
            fill="#282C35"
          />
          <path
            opacity="0.3"
            d="M186.065 190.585L147.901 273.987H135.073L134.111 273.66L171.954 190.585H186.065Z"
            fill="#282C35"
          />
          <path
            opacity="0.3"
            d="M195.683 193.856L158.802 273.987H151.747L190.231 190.585L195.683 193.856Z"
            fill="#282C35"
          />
          <path
            opacity="0.4"
            d="M223.262 184.045V306.367H294.457V184.045H223.262Z"
            fill="#353B4A"
          />
          <path
            d="M288.044 190.585H229.677V246.187H288.044V190.585Z"
            fill="#8E96AB"
          />
          <path
            d="M288.044 252.729H229.677V299.826H288.044V252.729Z"
            fill="#8E96AB"
          />
          <path
            opacity="0.4"
            d="M160.407 225.255L144.051 213.481C144.051 212.826 144.051 212.172 144.051 211.845C143.731 210.21 142.127 208.902 140.524 209.229C138.6 209.229 136.996 210.864 136.996 212.826C136.996 213.808 137.317 214.789 137.958 215.443L128.979 233.432C128.658 233.759 128.979 234.413 129.299 234.413H129.62C129.941 234.413 130.261 234.086 130.261 234.086L139.241 216.097C139.562 216.097 139.882 216.424 140.203 216.424C141.165 216.424 142.127 215.77 142.769 215.116L159.124 226.89C159.445 227.217 160.086 227.217 160.407 226.563C160.728 225.909 160.728 225.255 160.407 225.255Z"
            fill="#353B4A"
          />
          <path
            d="M169.065 246.187L131.222 255.999C129.619 256.326 128.015 255.345 127.374 253.71L123.525 238.011C123.205 236.375 124.167 234.74 125.77 234.086L163.613 224.274C165.216 223.947 166.82 224.928 167.461 226.563L171.309 242.262C171.63 243.898 170.668 245.533 169.065 246.187Z"
            fill="#3E4452"
          />
          <path
            d="M134.429 240.954L159.123 234.739C160.406 234.412 161.689 235.066 162.009 236.375C162.33 237.683 161.689 238.991 160.406 239.318L135.712 245.533C134.429 245.86 133.146 245.205 132.826 243.897C132.505 242.262 133.467 241.281 134.429 240.954Z"
            fill="#586178"
          />
          <path
            d="M227.114 251.093C228.354 251.093 229.359 250.068 229.359 248.804C229.359 247.539 228.354 246.514 227.114 246.514C225.874 246.514 224.869 247.539 224.869 248.804C224.869 250.068 225.874 251.093 227.114 251.093Z"
            fill="#586178"
          />
          <path
            d="M294.136 299.825H223.262V308.983H294.136V299.825Z"
            fill="#282C35"
          />
          <path
            d="M298.306 308.983H218.131V318.141H298.306V308.983Z"
            fill="#586178"
          />
          <path
            d="M364.053 220.022C371.137 220.022 376.881 214.164 376.881 206.939C376.881 199.714 371.137 193.856 364.053 193.856C356.968 193.856 351.225 199.714 351.225 206.939C351.225 214.164 356.968 220.022 364.053 220.022Z"
            fill="#282C35"
          />
          <path
            opacity="0.1"
            d="M364.053 220.022C371.137 220.022 376.881 214.164 376.881 206.939C376.881 199.714 371.137 193.856 364.053 193.856C356.968 193.856 351.225 199.714 351.225 206.939C351.225 214.164 356.968 220.022 364.053 220.022Z"
            fill="url(#paint4_linear_18292_1428)"
          />
          <path
            d="M366.936 283.8H360.843C359.56 283.8 358.277 284.781 357.957 286.089L354.108 306.367H373.671L369.823 286.089C369.502 284.781 368.219 283.8 366.936 283.8Z"
            fill="#586178"
          />
          <path
            d="M43.6709 197.454C42.7088 197.454 42.0674 196.8 42.0674 195.819V191.24C42.0674 190.259 42.7088 189.605 43.6709 189.605C44.633 189.605 45.2744 190.259 45.2744 191.24V195.819C45.2744 196.8 44.633 197.454 43.6709 197.454Z"
            fill="#3E4452"
          />
          <path
            d="M43.6709 290.668C42.7088 290.668 42.0674 290.013 42.0674 289.032V225.254C42.0674 224.273 42.7088 223.619 43.6709 223.619C44.633 223.619 45.2744 224.273 45.2744 225.254V288.705C45.2744 290.013 44.633 290.668 43.6709 290.668Z"
            fill="#3E4452"
          />
          <path
            d="M44.956 227.872H43.0318C42.0697 227.872 41.4283 227.217 41.1076 226.236L39.8249 219.368C39.5042 218.06 40.4662 217.078 41.749 217.078H46.5595C47.8423 217.078 48.8044 218.06 48.4837 219.368L46.5595 226.236C46.5595 227.217 45.5974 227.872 44.956 227.872Z"
            fill="#3E4452"
          />
          <path
            d="M43.6737 220.022C50.7583 220.022 56.5016 214.164 56.5016 206.939C56.5016 199.714 50.7583 193.856 43.6737 193.856C36.589 193.856 30.8457 199.714 30.8457 206.939C30.8457 214.164 36.589 220.022 43.6737 220.022Z"
            fill="#282C35"
          />
          <path
            opacity="0.1"
            d="M43.6737 220.022C50.7583 220.022 56.5016 214.164 56.5016 206.939C56.5016 199.714 50.7583 193.856 43.6737 193.856C36.589 193.856 30.8457 199.714 30.8457 206.939C30.8457 214.164 36.589 220.022 43.6737 220.022Z"
            fill="url(#paint5_linear_18292_1428)"
          />
          <path
            d="M46.8797 283.8H40.7864C39.5036 283.8 38.2208 284.781 37.9001 286.089L34.0518 306.367H53.6144L49.766 286.089C49.4453 284.781 48.1625 283.8 46.8797 283.8Z"
            fill="#586178"
          />
          <path
            opacity="0.3"
            d="M342.243 138.583H34.0518C34.6932 137.275 35.976 135.967 37.5794 135.313L69.97 119.94H318.191L339.357 134.985C340.64 135.967 341.602 137.275 342.243 138.583Z"
            fill="url(#paint6_linear_18292_1428)"
          />
          <path
            d="M223.264 126.481H159.766C147.579 126.481 137.958 116.342 137.958 104.24C137.958 91.812 147.9 82 159.766 82H223.264C235.45 82 245.071 92.139 245.071 104.24C245.392 116.342 235.45 126.481 223.264 126.481Z"
            fill="#6D768E"
          />
          <path
            d="M145.014 316.179C145.014 316.506 145.014 316.506 145.014 316.179H40.7865C40.4658 314.871 40.4658 313.235 40.4658 311.927C40.4658 307.021 41.7486 302.442 43.9935 298.517C43.3521 296.882 43.0314 294.92 43.0314 292.957C43.0314 285.435 49.1247 278.893 56.8215 278.893C60.3492 278.893 63.8768 280.529 66.4424 282.818C66.7631 275.296 72.8564 269.736 80.2325 269.736C87.9293 269.736 94.0225 275.95 94.0225 283.799C94.0225 286.416 93.3811 288.705 92.0984 290.668C95.3053 291.976 97.8709 294.593 99.1537 297.863C102.681 297.863 105.888 299.499 108.454 301.788C109.095 297.209 112.944 293.938 117.754 293.938C120.32 293.938 122.565 294.92 124.168 296.555C125.13 292.303 128.658 289.36 133.148 289.36C138.279 289.36 142.448 293.611 142.448 298.844C142.448 301.788 141.165 304.077 139.241 306.04C142.769 307.675 145.655 311.927 145.014 316.179Z"
            fill="#3E4452"
          />
          <path
            d="M116.25 279.955C116.646 276.365 114.115 273.127 110.594 272.722C107.074 272.318 103.899 274.9 103.503 278.49C103.106 282.08 105.638 285.318 109.158 285.723C112.678 286.127 115.853 283.545 116.25 279.955Z"
            fill="#282C35"
          />
          <path
            d="M86.648 312.581C86.648 313.889 86.648 315.198 86.0066 316.506H56.823C56.5023 315.198 56.1816 313.889 56.1816 312.581C56.1816 310.619 56.5023 308.656 57.1437 306.694C59.3886 301.134 64.8405 297.209 71.2545 297.209C77.6685 297.209 83.1204 301.461 85.3652 307.021C86.3273 308.656 86.648 310.619 86.648 312.581Z"
            fill="#282C35"
          />
          <path
            d="M100.114 314.871C100.114 315.526 100.114 316.18 100.114 316.507H82.1547C82.1547 315.853 81.834 315.526 81.834 314.871C81.834 311.601 83.4375 308.984 86.0031 307.022C87.6066 306.041 89.2101 305.386 91.1343 305.386C95.9447 305.386 100.114 309.638 100.114 314.871Z"
            fill="#282C35"
          />
          <path
            d="M60.3472 313.562C60.3472 314.543 60.0265 315.524 59.7058 316.505H42.3881C42.3881 315.524 42.0674 314.543 42.0674 313.562C42.0674 308.329 46.2365 304.077 51.047 304.077C53.6125 304.077 55.8574 305.058 57.4609 306.693C59.3851 308.329 60.3472 310.945 60.3472 313.562Z"
            fill="#282C35"
          />
          <path
            d="M129.939 316.505H111.659C111.659 311.272 115.828 307.02 120.959 307.02C126.091 307.02 129.939 311.272 129.939 316.505Z"
            fill="#282C35"
          />
          <path
            opacity="0.3"
            d="M257.262 190.585L231.606 246.187H229.682V222.311L244.113 190.585H257.262Z"
            fill="#282C35"
          />
          <path
            opacity="0.3"
            d="M265.915 190.585L240.58 246.187H236.09L261.425 190.585H265.915Z"
            fill="#282C35"
          />
          <path
            opacity="0.3"
            d="M288.046 199.743V216.75L274.577 246.186H266.88L288.046 199.743Z"
            fill="#282C35"
          />
          <path
            opacity="0.3"
            d="M254.696 252.729L235.454 299.826H229.682V292.303L245.717 252.729H254.696Z"
            fill="#282C35"
          />
          <path
            opacity="0.3"
            d="M288.044 261.232L272.33 299.826H276.178L288.044 271.371V261.232Z"
            fill="#282C35"
          />
          <path
            opacity="0.3"
            d="M281.633 252.729L262.391 299.826H256.939L276.502 252.729H281.633Z"
            fill="#282C35"
          />
          <defs>
            <linearGradient
              id="paint0_linear_18292_1428"
              x1="10"
              y1="221.647"
              x2="20.3669"
              y2="221.647"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#83A6FF" />
              <stop offset="1" stopColor="#5A78FF" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_18292_1428"
              x1="378.608"
              y1="101.74"
              x2="388.345"
              y2="101.74"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#83A6FF" />
              <stop offset="1" stopColor="#5A78FF" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_18292_1428"
              x1="330.593"
              y1="280.717"
              x2="306.981"
              y2="285.818"
              gradientUnits="userSpaceOnUse">
              <stop offset="0.00289017" stopColor="#606673" stopOpacity="0" />
              <stop offset="1" stopColor="#373C47" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_18292_1428"
              x1="193.117"
              y1="255.433"
              x2="193.117"
              y2="119.313"
              gradientUnits="userSpaceOnUse">
              <stop offset="0.00289017" stopOpacity="0" />
              <stop offset="1" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_18292_1428"
              x1="354.397"
              y1="195.633"
              x2="372.061"
              y2="215.533"
              gradientUnits="userSpaceOnUse">
              <stop offset="0.00289017" stopColor="#606673" stopOpacity="0" />
              <stop offset="1" stopColor="#373C47" />
            </linearGradient>
            <linearGradient
              id="paint5_linear_18292_1428"
              x1="34.2419"
              y1="195.633"
              x2="51.9064"
              y2="215.533"
              gradientUnits="userSpaceOnUse">
              <stop offset="0.00289017" stopColor="#606673" stopOpacity="0" />
              <stop offset="1" stopColor="#373C47" />
            </linearGradient>
            <linearGradient
              id="paint6_linear_18292_1428"
              x1="188.146"
              y1="138.557"
              x2="188.146"
              y2="119.582"
              gradientUnits="userSpaceOnUse">
              <stop offset="0.00289017" stopColor="#3F5073" stopOpacity="0" />
              <stop offset="1" stopColor="#283247" />
            </linearGradient>
          </defs>
        </svg>
      )}
    </EmptyContainer>
  );
};
