import React from 'react';
import {Skeleton} from 'shared/ui/Skeleton';

export const EditBonusSkeleton = () => {
  return (
    <Skeleton height={1344}>
      <rect width={'100%'} height={160} rx={24} ry={24} />
      <rect width={'100%'} height={288} rx={24} ry={24} y={184} />
      <rect width={'100%'} height={168} rx={24} ry={24} y={496} />
      <rect width={'100%'} height={656} rx={24} ry={24} y={688} />
    </Skeleton>
  );
};
