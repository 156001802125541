import React from 'react';

export const Logo = ({size = 50}: {size?: number}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 50C0 27.6931 0 16.5397 6.33858 9.1571C7.20535 8.14757 8.14757 7.20535 9.1571 6.33858C16.5397 0 27.6931 0 50 0C72.3069 0 83.4604 0 90.8429 6.33858C91.8524 7.20535 92.7946 8.14757 93.6614 9.1571C100 16.5397 100 27.6931 100 50C100 72.3069 100 83.4604 93.6614 90.8429C92.7946 91.8524 91.8524 92.7946 90.8429 93.6614C83.4604 100 72.3069 100 50 100C27.6931 100 16.5397 100 9.1571 93.6614C8.14757 92.7946 7.20535 91.8524 6.33858 90.8429C0 83.4604 0 72.3069 0 50Z"
        fill="#6765F8"
      />
      <path
        d="M20 58.4968L23.0928 39.257L52.165 70.3801L40.4124 80L20 58.4968Z"
        fill="white"
      />
      <path
        d="M34.2268 20.0173C26.8041 19.5646 24.5361 28.1281 24.3299 32.4665L68.2474 78.8683L80 69.2484L34.2268 20.0173Z"
        fill="white"
      />
      <path
        d="M62.6804 20.0173L41.0309 20.0173L62.6804 42.0864L74.433 32.4665L62.6804 20.0173Z"
        fill="white"
      />
    </svg>
  );
};
