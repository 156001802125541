import React, {useEffect, useState} from 'react';
import {useAppSelector} from 'shared/store';
import {colors} from 'shared/colors';
import {CompanyPermissions} from 'shared/__generated__/graphql';
import {PERMISSIONS} from 'shared/config/apollo';
import {FragmentType, useFragment} from 'shared/__generated__';

export function useColorScheme() {
  return useAppSelector(state => state.theme.theme);
}

export function useColors() {
  const colorScheme = useColorScheme();
  return colors[colorScheme ?? 'light'];
}

export function useDebounce(
  callback: (...args: string[]) => void,
  delay: number,
) {
  const timer = React.useRef<number | null | NodeJS.Timeout>(null);

  const debounceCallback = React.useCallback(
    (...args: string[]) => {
      if (timer.current) {
        clearTimeout(timer.current as NodeJS.Timeout);
      }
      timer.current = setTimeout(() => {
        callback(...args);
      }, delay);
    },
    [callback, delay],
  );

  return debounceCallback;
}

const isMobile = 768;
const isTable = 1024;
const isLaptop = 1280;
const isDesktop = 2556;

export const useResize = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = (event: Event) => {
      if (event.target && event.target instanceof Window) {
        setWidth(event.target.innerWidth);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return {
    width,
    isMobile: width < isMobile,
    isTable: width < isTable,
    isLaptop: width < isLaptop,
    isDesktop: width < isDesktop,
  };
};

export type Res = Omit<
  CompanyPermissions,
  | '__typename'
  | 'id'
  | 'role'
  | 'created_at'
  | 'updated_at'
  | 'company'
  | 'employee'
>;

const initialState: Res = {
  add_to_customers_bonus_card_permission: false,
  add_to_customers_certificate_permission: false,
  create_bookings_permission: false,
  create_customers_permission: false,
  create_invitation_permission: false,
  customers_info_permission: false,
  edit_apps_permission: false,
  edit_bonus_card_permission: false,
  edit_bonus_card_templates: false,
  edit_bookings_permission: false,
  edit_certificate_permission: false,
  edit_certificate_templates: false,
  edit_company_permission: false,
  edit_company_services_permission: false,
  edit_customers_permission: false,
  edit_discounts_permission: false,
  edit_employees_permission: false,
  edit_work_schedule_permission: false,
  employees_info_permission: false,
  fill_bonus_card_permission: false,
  moderation_permission: false,
  notation_permission: false,
  reviews_info_permission: false,
  reviews_reply_permission: false,
  subscription_and_pay_permission: false,
  work_schedule_info_permission: false,
  reports_permission: false,
  contact_permission: false,
  view_service_permission: false,
  bookings_info_permission: false,
  messages_permission: false,
  delete_messages_permission: false,
  view_products_permission: false,
  edit_products_permission: false,
  salaries_permission: false,
  message_sample_permission: false,
  webhook_permission: false,
};

export const useEmployeePermission = (
  query: FragmentType<typeof PERMISSIONS> | null | undefined,
): Res => {
  return useFragment(PERMISSIONS, query)?.companyPermissions ?? initialState;
};
