import React, {useState} from 'react';
import styled from 'styled-components';
import {device} from 'shared/device';
import {Text} from 'shared/ui/Text';
import {Seo} from 'shared/ui/Seo';
import {Flex} from 'shared/ui/Flex';
import {FilterCustomer} from './ui/FiltersCustomer';
import {FilterEmployee} from './ui/FiltersEmployee';
import {FilterSort} from './ui/FiltersSort';
import {Filters} from './ui/Filters';
import {Content} from 'shared/ui/Content';
import {OptionsType, Table} from 'shared/ui/Table';
import {Avatar} from 'shared/ui/Avatar';
import {getName} from 'shared/lib/utils';
import dayjs from 'dayjs';
import {
  DELETE_MESSAGE,
  GET_MESSAGES_CUSTOMERS,
  Send,
  TooltipMessage,
  messageTypes,
  methodSending,
  sortTypings,
  statusNotification,
} from 'entities/messages';
import {ApolloError, useMutation, useQuery} from '@apollo/client';
import {useAppSelector} from 'shared/store';
import {MessageStatus, SortOrder} from 'shared/__generated__/graphql';
import {Skeleton} from 'shared/ui/Skeleton';
import {Button} from 'shared/ui/Button';
import {useNavigate} from 'react-router-dom';
import {showAlert} from 'shared/ui/Alert';
import {FilterType} from './ui/FiltersType';
import {useEmployeePermission} from 'shared/lib/hooks';
import {EmptySendEmail} from 'shared/illustration/EmptySendEmail';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 56px;
  gap: 12px;
  @media (${device.mobile}) {
    position: absolute;
    top: 16px;
    left: 16px;
    margin-bottom: 0;
  }
`;
const Status = styled.div<{pending: boolean}>`
  padding: 8px 16px;
  border-radius: 12px;
  width: max-content;
  font-family: 'Inter';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  align-items: center;
  color: ${({theme, pending}) =>
    pending ? theme.dangerPrimary : theme.textSecondary};
  background-color: ${({theme, pending}) =>
    pending ? theme.bgDanger : theme.fillPrimary};
`;
const ProfessionText = styled(Text)`
  max-width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Messages = () => {
  const navigate = useNavigate();
  const companyId = useAppSelector(state => state.company.data?.id);
  const username = useAppSelector(state => state.company.data?.username);
  const [customerId, setCustomerId] = useState('');
  const [employeeId, setEmployeeId] = useState('');
  const [sort, setSort] = useState(0);
  const [type, setType] = useState(0);

  const [sendIndexModal, setSendIndexModal] = useState<number | null>(null);
  const [options, setOptions] = useState<OptionsType>({
    page: 1,
    first: 10,
    total: 0,
    count: 0,
  });
  const [filters, setFilters] = useState<{
    status: number;
    method: number;
  }>({
    status: 0,
    method: 0,
  });

  const {data, loading, refetch} = useQuery(GET_MESSAGES_CUSTOMERS, {
    variables: {
      companyId: companyId!,
      page: options.page,
      first: options.first,
      employeeId: employeeId.length > 0 ? employeeId : undefined,
      customerId: customerId.length > 0 ? customerId : undefined,
      status: statusNotification[filters.status].value,
      method: methodSending[filters.method].value,
      type: messageTypes[type].value,
      orderBy: [
        {
          column: sortTypings[sort].value,
          order: sort !== 1 ? SortOrder.Desc : SortOrder.Asc,
        },
      ],
    },
    onCompleted: res => {
      setOptions(prev => ({
        ...prev,
        total: res.getMessages?.paginatorInfo.total,
        count: res.getMessages?.paginatorInfo.count,
        lastPage: res.getMessages?.paginatorInfo.lastPage,
      }));
    },
  });
  const {delete_messages_permission, message_sample_permission} =
    useEmployeePermission(data);

  const [deleteMessage] = useMutation(DELETE_MESSAGE, {
    refetchQueries: [
      {
        query: GET_MESSAGES_CUSTOMERS,
        variables: {companyId, first: 20, page: 1},
      },
    ],
  });
  const countFilters =
    +!!sort +
    +!!filters.method +
    +!!filters.status +
    +!!employeeId +
    +!!customerId +
    +!!type;
  const messages = data?.getMessages?.data ?? [];
  const countMessages = data?.getMessages?.paginatorInfo.total ?? 0;

  return (
    <Wrapper>
      <Seo title={'Рассылка клиентов'} />
      <Header>
        <Text typography="title24">Рассылки</Text>
        {countMessages > 0 && (
          <Text typography="title24" color="textTertiary">
            {countMessages}
          </Text>
        )}
      </Header>
      <Flex gap={8}>
        <Filters {...{filters, setFilters}} />
        <FilterType {...{type, setType}} />
        <FilterSort {...{sort, setSort}} />
        <FilterEmployee {...{employeeId, setEmployeeId}} />
        <FilterCustomer {...{customerId, setCustomerId}} />
      </Flex>
      {messages.length === 0 && !loading ? (
        <Flex
          alignItems="center"
          flex={1}
          gap={16}
          style={{marginBottom: 8, marginTop: 8}}
          direction="column">
          <EmptySendEmail size={300} />
          <Text typography="title20" align="center">
            Рассылки нет
          </Text>
          <Text
            style={{width: 500}}
            align="center"
            typography="text18"
            color="textTertiary">
            {countFilters > 0
              ? 'Создайте шаблон и начните делать рассылки для своих клиентов'
              : 'Чтобы создавались рассылки, нужно создать шаблоны рассылок.'}
          </Text>
          {message_sample_permission && (
            <Button
              size="large"
              onClick={() => navigate(`/${username}/setting/message-samples`)}
              style={{marginTop: 8, height: 56}}>
              Перейти к шаблонам рассылок
            </Button>
          )}
        </Flex>
      ) : (
        <Content>
          {loading ? (
            <SkeletonMessages />
          ) : (
            <Table
              titles={[
                'Название',
                '',
                'Клиенты',
                '',
                'Сотрудник',
                'Дата отправки',
                'Статус',
                '',
              ]}
              widths={[
                undefined,
                56,
                undefined,
                56,
                undefined,
                undefined,
                undefined,
                60,
              ]}
              options={options}
              setOptions={setOptions}
              onClick={index => {
                setSendIndexModal(index);
              }}
              rows={messages.map((item, index) => [
                item.messageSample?.name,
                <Avatar
                  key={'avatar_customer_' + index}
                  size={40}
                  url={
                    item.customer.avatar?.url_64 ?? item.customer.avatar?.url
                  }
                />,
                getName(item.customer.name, item.customer.surname),
                <Avatar
                  key={'avatar_employee_' + index}
                  size={40}
                  url={
                    item.employee.avatar?.url_64 ?? item.employee.avatar?.url
                  }
                />,
                <Flex gap={4} direction="column" key={'employee_' + index}>
                  <Text>
                    {getName(
                      item.employee.name,
                      item.employee.surname,
                      'employee',
                    )}
                  </Text>
                  {item['employee']['professions'].length === 0 && (
                    <ProfessionText
                      color="textTertiary"
                      typography="subHead14Medium">
                      {item.employee.professions
                        .map(item => item.name)
                        .join(', ')}
                    </ProfessionText>
                  )}
                </Flex>,
                dayjs(item.send_at).format('DD.MM.YYYY, в HH:mm'),
                <Status
                  key={'status_' + index}
                  pending={item.status === MessageStatus.Pending}>
                  {item.status === MessageStatus.Completed
                    ? 'Отправлено'
                    : item.status === MessageStatus.Cancelled
                    ? 'Отменено'
                    : 'Не отправлено'}
                </Status>,
                delete_messages_permission ? (
                  <TooltipMessage
                    onClick={() => {
                      deleteMessage({
                        variables: {id: item.id},
                      })
                        .then(() => {
                          showAlert('success', 'рассылка удалена');
                          refetch();
                        })
                        .catch((e: ApolloError) =>
                          showAlert('error', e.message),
                        );
                    }}
                    key={'tooltip_' + index}
                  />
                ) : null,
              ])}
            />
          )}
        </Content>
      )}
      <Send
        data={sendIndexModal !== null ? messages[sendIndexModal] : null}
        onClose={() => setSendIndexModal(null)}
      />
    </Wrapper>
  );
};

const SkeletonMessages = () => {
  return (
    <Skeleton height={360}>
      <rect width={80} height={24} rx={8} ry={8} y={16} x={0} />
      <rect width={80} height={24} rx={8} ry={8} y={16} x={'20%'} />
      <rect width={90} height={24} rx={8} ry={8} y={16} x={'43%'} />
      <rect width={90} height={24} rx={8} ry={8} y={16} x={'66%'} />
      <rect width={50} height={24} rx={8} ry={8} y={16} x={'85%'} />
      {Array.from(Array(4).keys()).map((item, index) => (
        <React.Fragment key={'sk_' + item}>
          <rect
            width={160}
            height={24}
            rx={8}
            ry={8}
            x={0}
            y={80 + item * 80}
          />
          <circle r={20} cx={'calc(20% - 40px)'} cy={92 + index * 80} />
          <rect
            width={100}
            height={24}
            rx={8}
            ry={8}
            x={'20%'}
            y={80 + item * 80}
          />
          <circle r={20} cx={'calc(43% - 40px)'} cy={92 + index * 80} />
          <rect
            width={120}
            height={24}
            rx={8}
            ry={8}
            x={'43%'}
            y={68 + item * 80}
          />
          <rect
            width={80}
            height={20}
            rx={8}
            ry={8}
            x={'43%'}
            y={96 + item * 80}
          />
          <rect
            width={140}
            height={24}
            rx={8}
            ry={8}
            x={'66%'}
            y={80 + item * 80}
          />
          <rect
            width={100}
            height={40}
            rx={8}
            ry={8}
            x={'85%'}
            y={72 + item * 80}
          />
        </React.Fragment>
      ))}
    </Skeleton>
  );
};
