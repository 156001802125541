import React from 'react';

export const Viber = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_12387_8342)">
        <path
          d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
          fill="#7360F2"
        />
        <path
          d="M17.1104 6.75797C16.7645 6.43883 15.3668 5.42417 12.2535 5.41038C12.2535 5.41038 8.58205 5.18898 6.79229 6.83066C5.79601 7.82715 5.44553 9.28523 5.40856 11.093C5.37159 12.9007 5.32376 16.2885 8.58936 17.207H8.59249L8.5904 18.6087C8.5904 18.6087 8.56952 19.1762 8.94318 19.2919C9.39516 19.4323 9.66042 19.001 10.0919 18.5361C10.3288 18.2808 10.6559 17.9059 10.9023 17.6193C13.1357 17.8073 14.8534 17.3777 15.0485 17.3142C15.4994 17.168 18.0511 16.8409 18.4661 13.4535C18.8945 9.96196 18.259 7.75342 17.1104 6.75797ZM17.4889 13.2031C17.1386 16.0316 15.069 16.2093 14.6874 16.3317C14.5251 16.3839 13.0165 16.7592 11.1193 16.6354C11.1193 16.6354 9.70574 18.3408 9.2642 18.7842C9.19527 18.8535 9.11424 18.8815 9.06014 18.8677C8.98411 18.8491 8.96323 18.7591 8.96406 18.6275C8.96531 18.4396 8.97618 16.2983 8.97618 16.2983C8.9745 16.2983 8.97618 16.2983 8.97618 16.2983C6.21373 15.5313 6.37477 12.648 6.4061 11.1381C6.43743 9.62819 6.72107 8.39129 7.56363 7.55939C9.07748 6.18819 12.196 6.39309 12.196 6.39309C14.8296 6.40458 16.0916 7.19763 16.3842 7.46331C17.3558 8.29522 17.8508 10.2859 17.4889 13.2023V13.2031Z"
          fill="#E5E1FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.3069 9.9169C12.312 9.81979 12.3948 9.74518 12.4919 9.75024C12.8552 9.7692 13.1575 9.87918 13.3728 10.1018C13.5872 10.3235 13.6924 10.6333 13.7108 11.0053C13.7157 11.1025 13.6408 11.1851 13.5437 11.1899C13.4466 11.1947 13.3639 11.1199 13.3591 11.0228C13.3433 10.7043 13.2559 10.4875 13.1197 10.3466C12.9842 10.2065 12.7775 10.1178 12.4735 10.1019C12.3764 10.0968 12.3018 10.014 12.3069 9.9169Z"
          fill="#E5E1FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.0395 8.96889C12.0466 8.87191 12.131 8.79904 12.228 8.80614C12.9674 8.86025 13.5625 9.1129 13.996 9.58611L13.9961 9.58617C14.4249 10.0547 14.6287 10.6376 14.6139 11.3213C14.6118 11.4185 14.5313 11.4956 14.4341 11.4935C14.3368 11.4914 14.2597 11.4109 14.2618 11.3137C14.2749 10.7107 14.0976 10.2187 13.7363 9.82391C13.3762 9.43079 12.8727 9.20641 12.2023 9.15735C12.1053 9.15025 12.0324 9.06588 12.0395 8.96889Z"
          fill="#E5E1FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.7305 8.06645C11.7312 7.96921 11.8105 7.89093 11.9078 7.89161C12.9296 7.89872 13.7933 8.23889 14.4867 8.9149C15.186 9.59657 15.5318 10.5246 15.5406 11.6772C15.5413 11.7744 15.4631 11.8539 15.3658 11.8546C15.2686 11.8553 15.1892 11.7771 15.1884 11.6799C15.1802 10.5998 14.8589 9.76949 14.2409 9.16706C13.6171 8.55897 12.8427 8.25027 11.9053 8.24374C11.8081 8.24307 11.7298 8.16369 11.7305 8.06645Z"
          fill="#E5E1FF"
        />
        <path
          d="M12.5179 13.3722C12.5179 13.3722 12.7654 13.393 12.8986 13.2289L13.1585 12.902C13.2838 12.7399 13.5862 12.6365 13.8824 12.8015C14.1043 12.9281 14.3198 13.0657 14.528 13.2138C14.7245 13.3584 15.127 13.6942 15.1283 13.6942C15.32 13.8561 15.3643 14.0938 15.2337 14.3444C15.2337 14.3459 15.2327 14.3484 15.2327 14.3496C15.0889 14.5989 14.9064 14.8237 14.6919 15.0155C14.6894 15.0168 14.6894 15.018 14.6871 15.0193C14.5008 15.1749 14.3178 15.2634 14.138 15.2847C14.1116 15.2894 14.0847 15.291 14.0578 15.2897C13.9786 15.2905 13.8997 15.2784 13.8243 15.2538L13.8185 15.2453C13.5415 15.1671 13.0791 14.9716 12.309 14.5468C11.8634 14.3039 11.4391 14.0236 11.0408 13.7091C10.8411 13.5515 10.6507 13.3826 10.4704 13.2032L10.4512 13.184L10.4319 13.1648L10.4127 13.1455C10.4063 13.1393 10.4 13.1328 10.3935 13.1263C10.2141 12.946 10.0452 12.7556 9.88764 12.5559C9.57313 12.1576 9.29288 11.7334 9.04988 11.2879C8.62505 10.5176 8.42956 10.0556 8.35144 9.77823L8.34288 9.77238C8.31839 9.69699 8.30633 9.61813 8.30716 9.53887C8.3057 9.51204 8.30731 9.48513 8.31197 9.45867C8.33438 9.27918 8.42301 9.09601 8.57785 8.90914C8.5791 8.90685 8.58036 8.90685 8.58161 8.90434C8.77341 8.6899 8.99819 8.50743 9.24747 8.3638C9.24872 8.3638 9.25123 8.36254 9.25269 8.36254C9.50333 8.232 9.74102 8.27628 9.90268 8.46698C9.90393 8.46823 10.2392 8.87071 10.3831 9.06725C10.5312 9.27565 10.6688 9.49132 10.7954 9.71348C10.9604 10.0094 10.857 10.3125 10.6949 10.4374L10.368 10.6972C10.203 10.8305 10.2247 11.078 10.2247 11.078C10.2247 11.078 10.7089 12.9104 12.5179 13.3722Z"
          fill="#E5E1FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_12387_8342">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
