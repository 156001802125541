import {gql} from 'shared/__generated__';

export const COMPANY_SOURCES = gql(`
  query CompanySources($companyId: String!) {
    companySources(company_id: $companyId) {
      id
      name
      ref
      is_online
      color {
        id
        rgb
      }
    }
  }
`);

export const COMPANY_SOURCE_COLORS = gql(`
query CompanySourceColors($first: Int!, $page: Int) {
  colors(first: $first, page: $page) {
    data {
      id
      rgb
    }
  }
}
`);

export {GridColor, ColorView} from './ui/ColorView';
