import React from 'react';
import styled from 'styled-components';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import {Layout} from 'shared/ui/Layout';
import {Head} from 'shared/ui/Head';
import {Text} from 'shared/ui/Text';
import {Content} from 'shared/ui/Content';
import {SegmentedControl} from 'shared/ui/SegmentedControl';
import {NavigateBack} from 'shared/ui/NavigateBack';
import {Seo} from 'shared/ui/Seo';

const Container = styled.div`
  grid-column-start: 4;
  grid-column-end: 10;
  display: flex;
  flex-direction: column;
  padding: 64px 0 24px 0;
`;

export const NotificationRoot = () => {
  const navigate = useNavigate();
  const pathname = useLocation().pathname;

  const tabs = [
    {
      name: 'Записи',
      onClick: () => {
        navigate('booking', {replace: true});
      },
    },
    {
      name: 'Остальные',
      onClick: () => {
        navigate('rest', {replace: true});
      },
    },
  ];
  return (
    <Layout columns={12}>
      <Seo title="Уведомления" />
      <Container>
        <Head>
          <NavigateBack />
          <Text typography="title24">Уведомления</Text>
        </Head>
        <Content style={{marginBottom: 24, marginTop: 8}}>
          <SegmentedControl
            tabs={tabs}
            index={pathname.includes('rest') ? 1 : 0}
            typography="subHead14Regular"
          />
        </Content>
        <Outlet />
      </Container>
    </Layout>
  );
};
