import {ApolloError, useMutation, useQuery} from '@apollo/client';
import {
  COMPANY_SOURCES,
  COMPANY_SOURCE_COLORS,
  ColorView,
  GridColor,
} from 'entities/bookingSource';
import React, {useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {gql} from 'shared/__generated__';
import Accept from 'shared/icons/Accept';
import {Trash} from 'shared/icons/Trash';
import {useColors} from 'shared/lib/hooks';
import {useAppSelector} from 'shared/store';
import {Button} from 'shared/ui/Button';
import {Content} from 'shared/ui/Content';
import {Flex} from 'shared/ui/Flex';
import {Layout} from 'shared/ui/Layout';
import {PageHeader} from 'shared/ui/PageHeader';
import {Switch} from 'shared/ui/Switch';
import {TableViewRow} from 'shared/ui/TableViewRow';
import {Text} from 'shared/ui/Text';
import {TextInput} from 'shared/ui/TextInput';
import styled from 'styled-components';
import {SkeletonUpdateSourceColor} from './ui/SkeletonUpdateSourceColor';
import {showAlert} from 'shared/ui/Alert';
import {ModalDelete} from './ui/ModalDelete';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  grid-column-start: 4;
  grid-column-end: 10;
  gap: 24px;
`;

const UPDATE_BOOKING_SOURCE = gql(`
    mutation UpdateSource($input: UpdateSource!) {
    updateSource(input: $input) {
        id
        name
        ref
        is_online
        color {
          id
          rgb
        }
    }
    }
`);

export const EditBookingSource = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state;
  const colors = useColors();
  const [name, setName] = useState(state.name ?? '');
  const [ref, setRef] = useState(state.ref ?? '');
  const [online, setOnline] = useState(state.online ?? true);
  const [color, setColor] = useState<string | null>(state.color_id ?? null);
  const companyId = useAppSelector(state => state.company.data?.id);
  const [modalDelete, setModalDelete] = useState(false);

  const {data, loading} = useQuery(COMPANY_SOURCE_COLORS, {
    variables: {
      page: 1,
      first: 100,
    },
  });
  const [updateSource, {loading: loadingUpdate}] = useMutation(
    UPDATE_BOOKING_SOURCE,
    {
      refetchQueries: [
        {
          query: COMPANY_SOURCES,
          variables: {
            companyId: companyId!,
          },
        },
      ],
    },
  );

  const colorsData = data?.colors?.data ?? [];

  return (
    <Layout>
      <Wrapper>
        <PageHeader variant="layout" title="Редактирование источника" back />
        <Content gap="16px">
          <TextInput
            required
            label="Название источника"
            value={name}
            onChange={setName}
          />
          <TextInput
            required
            label="Ключ (utm_source)"
            value={ref}
            onChange={setRef}
          />
          <TableViewRow
            title="Онлайн"
            subtitle="Источники с включенной опцией будут учитываться в диаграммах как онлайн-записи. Если этот параметр отключен, бронирования будут учтены как офлайн-записи."
            rightElement={() => <Switch value={online} />}
            style={{cursor: 'pointer'}}
            styleTitle={{pointerEvents: 'none'}}
            styleSubTitle={{pointerEvents: 'none'}}
            onClick={() => setOnline(!online)}
          />
        </Content>
        <Content>
          <Text typography="title20">Цвет источника</Text>
          {loading ? (
            <SkeletonUpdateSourceColor />
          ) : (
            <GridColor>
              {colorsData.map(item => (
                <ColorView
                  key={item.id}
                  color={item.rgb}
                  onClick={() => {
                    setColor(item.id);
                  }}>
                  {item.id === color ? (
                    <Accept size={'min(100%, 28px)'} color={colors.white} />
                  ) : undefined}
                </ColorView>
              ))}
            </GridColor>
          )}
        </Content>
        <Flex gap={16}>
          <Button
            size="large"
            style={{
              flex: 1,
              borderColor: colors.dangerPrimary,
              color: colors.dangerPrimary,
            }}
            variant="outline"
            onClick={() => setModalDelete(true)}>
            <Trash color={colors.dangerPrimary} />
            Удалить
          </Button>
          <Button
            size="large"
            style={{flex: 1}}
            loading={loadingUpdate}
            onClick={async () => {
              if (name !== '' && ref !== '' && color !== null) {
                try {
                  await updateSource({
                    variables: {
                      input: {
                        color_id: color,
                        id: state.id,
                        name,
                        ref,
                        is_online: online,
                      },
                    },
                  });
                  navigate(-1);
                } catch (e) {
                  if (e instanceof ApolloError) {
                    showAlert('error', e.message);
                  }
                }
              } else {
                showAlert('error', 'Заполните все поля и выберите цвет');
              }
            }}>
            Сохранить
          </Button>
        </Flex>
        <ModalDelete
          id={state.id}
          visible={modalDelete}
          onClose={() => setModalDelete(false)}
          onComplete={() => {
            setModalDelete(false);
            navigate(-1);
          }}
        />
      </Wrapper>
    </Layout>
  );
};
