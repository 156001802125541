import {gql} from 'shared/__generated__';
export {SelectBrand} from './ui/SelectBrand';
export {SelectProductsCategory} from './ui/SelectProductsCategory';
export {
  ModalCategoryCreate,
  ModalCategoryEdit,
  ModalBrandCreate,
  ModalBrandEdit,
} from './ui/Modals';
export {Card} from './ui/Card';
export const GET_PRODUCTS = gql(`
  query GetProducts($companyId: String!, $query: String, $productsCategory: [String!], $productsBrands: [String!], $first: Int!, $page: Int) {
    getProducts(company_id: $companyId,  query: $query, products_category: $productsCategory, products_brands: $productsBrands, first: $first, page: $page) {
      data {
        id
        name
        products_category {
          id
          name
        }
        products_brand {
          id
          name
        }
        retail_price
        count
        discount_price
        quantity
      }
      paginatorInfo {
        count
        total
        currentPage
        lastPage
      }
    }
    ...Permissions
  }
`);

export const GET_PRODUCTS_ONE = gql(`
query ProductsOne($id: String!, $companyId: String!) {
  productsOne(id: $id) {
      id
      name
      products_category {
      id
      name
      }
      products_brand {
      id
      name
      }
      barcode
      count
      description
      charge
      cost_price
      discount_price
      images {
      id
      url
      }
      piece_count
      quantity
      retail_price
    notify_critical
    critical_quantity
  }
  ...Permissions
}
`);

export const productPieceCount = [
  {
    label: 'Миллилитры (мл)',
    value: 'milliliters',
    small: 'мл',
  },
  {
    label: 'Литры (л)',
    value: 'liters',
    small: 'л',
  },
  {
    label: 'Жидкие унции (жидк. унц.)',
    value: 'fluid_ounces',
    small: 'жидк. унц.',
  },
  {
    label: 'Граммы (г)',
    value: 'grams',
    small: 'г',
  },
  {
    label: 'Килограммы (кг)',
    value: 'kilograms',
    small: 'кг',
  },
  {
    label: 'Галлоны (галлоны)',
    value: 'gallons',
    small: 'галлоны',
  },
  {
    label: 'Унции (oz)',
    value: 'ounces',
    small: 'oz',
  },
  {
    label: 'Фунты (lb)',
    value: 'pounds',
    small: 'lb',
  },
  {
    label: 'Сантиметры (см)',
    value: 'centimeters',
    small: 'см',
  },
  {
    label: 'Футы (ft)',
    value: 'feet',
    small: 'ft',
  },
  {
    label: 'Дюймы (дюймы)',
    value: 'inches',
    small: 'дюймы',
  },
  {
    label: 'Целый продукт',
    value: 'whole',
    small: '',
  },
];

export interface IBrandEditModalProps {
  id: string;
  name: string;
}
export interface ICategoryEditModalProps extends IBrandEditModalProps {
  parent?: string;
}

export const PRODUCTS_UPDATE_QUANTITY = gql(`
  mutation UpdateProductsCreateQuantity($input:  UpdateProducts!) {
    updateProducts(input: $input) {
      id
      name
    }
  }
`);

export const DELETE_PRODUCTS = gql(`
  mutation DeleteProducts($id: String!) {
  deleteProducts(id: $id) {
    id
  }
}
`);

export const UPDATE_PRODUCTS = gql(`
  mutation UpdateProducts($input: UpdateProducts!) {
    updateProducts(input: $input) {
      id
      name
    }
  }
`);

export const BRANDS = gql(`
  query ProductsBrands($companyId: String!, $page: Int, $first: Int!, $name: String) {
    productsBrands(company_id: $companyId, page: $page, first: $first, name: $name) {
      paginatorInfo {
        hasMorePages
        count
        currentPage
        total
        lastPage
      }
      data {
        id
        name
        products {
          id
          name
        }
      }
    }
    ...Permissions
  }
`);

export const PRODUCTS_CATEGORIES = gql(`
  query ProductsCategory($companyId: String!, $page: Int, $first: Int!, $name: String, $productsCategoryId: [String!]) {
    productsCategory(company_id: $companyId, page: $page, first: $first, name: $name, products_category_id: $productsCategoryId) {
      paginatorInfo {
        hasMorePages
        currentPage
        total
        lastPage
        count
      }
      data {
        id
        name
        parent {
          id
          name
        }
      }
    }
    ...Permissions
  }
`);

export const GET_HISTORY_RESERVE = gql(`
  query GetProductsHistory($productsId: String!, $first: Int!) {
    getProductsHistory(products_id: $productsId, first: $first) {
      data {
        id
        price
        reason
        type
        count
        count_after
        created_at
        employee {
          id
          name
          surname
          avatar {
            url
            url_64
          }
        }
        products {
          id
          company {
            id
            name
          }
        }
      }
    }
  }
`);

export const GET_HISTORY_SALES = gql(`
  query GetProductsSales($productsId: String!, $first: Int!) {
    getProductsSales(products_id: $productsId, first: $first) {
      data {
        count
        id
        sum
        type
        status
        payment_method
        employee {
          id
          name
          surname
          avatar {
            id
            url
            url_64
          }
        }
        customer {
          id
          name
          surname
        }
        cashbox
        created_at
        products {
          id
          name
          company {
            id
            name
          }
          cost_price
        }
      }
    }
  }
`);

export const FILL_PRODUCTS = gql(`
  mutation FillProducts($fillProductsId: String!, $reason: String!, $price: Int, $quantity: Int!) {
    fillProducts(id: $fillProductsId, reason: $reason, price: $price, quantity: $quantity) {
      id
      name
    }
  }
`);

export const REPEAL_PRODUCTS = gql(`
  mutation RepealProducts($repealProductsId: String!, $quantity: Int!, $reason: String!) {
    repealProducts(id: $repealProductsId, quantity: $quantity, reason: $reason) {
      id
      name
    }
  }
`);

export const DELETE_MANY_PRODUCTS_CATEGORY = gql(`
  mutation DeleteManyProductsCategory($deleteManyProductsCategoryId: [String!]) {
    deleteManyProductsCategory(id: $deleteManyProductsCategoryId) {
      id
    }
  }
`);

export const DELETE_MANY_PRODUCTS_BRANDS = gql(`
  mutation DeleteManyProductsBrand($deleteManyProductsBrandId: [String!]) {
    deleteManyProductsBrand(id: $deleteManyProductsBrandId) {
      id
    }
  }
`);

export const DELETE_MANY_PRODUCTS = gql(`
  mutation DeleteManyProducts($deleteManyProductsId: [String!]) {
    deleteManyProducts(id: $deleteManyProductsId) {
      id
    }
  }
`);

export const GET_DATA_CREATE_PRODUCT = gql(`
  query GetDataCreateProduct($companyId: String!, $first: Int!, $categoryFirst2: Int!) {
    productsBrands(company_id: $companyId, first: $first) {
      data {
        id
        name
        products {
            id
          }
        }
    }
    productsCategory(company_id: $companyId, first: $categoryFirst2) {
      data {
        id
        name
      }
    }
  }
`);

export const CREATE_PRODUCT_CATEGORY = gql(`
  mutation CreateProductsCategory($companyId: String!, $name: String!, $productsCategoryId: String) {
    createProductsCategory(company_id: $companyId, name: $name, products_category_id: $productsCategoryId) {
      id
      name
      parent {
        id
        parent {
          id
          name
        }
      }
    }
  }
`);
