import dayjs from 'dayjs';
interface IWorkingDays {
  day:
    | 'monday'
    | 'tuesday'
    | 'wednesday'
    | 'thursday'
    | 'friday'
    | 'saturday'
    | 'sunday';
  start_time: string;
  end_time: string;
  dayoff: boolean;
}

export function getAbbreviatedSchedule(workingDates: IWorkingDays[]): string {
  const daysOfWeek = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
  ];

  const formatTime = (time: string) => {
    const timePattern = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
    if (timePattern.test(time)) {
      return time;
    } else {
      return 'Invalid time';
    }
  };

  let schedule = '';
  let currentDay = workingDates[0]?.day;
  let startTime = workingDates[0]?.start_time;
  let endTime = workingDates[0]?.end_time;
  let isDayOff = workingDates[0]?.dayoff;
  let startDay = workingDates[0]?.day;

  for (let i = 1; i < workingDates.length; i++) {
    const {day, start_time, end_time, dayoff} = workingDates[i];

    if (
      dayoff !== isDayOff ||
      start_time !== startTime ||
      end_time !== endTime
    ) {
      const timeRange = isDayOff
        ? 'выходной'
        : `${formatTime(startTime)}-${formatTime(endTime)}`;
      const range =
        startDay === currentDay
          ? `${dayjs().day(daysOfWeek.indexOf(startDay)).format('dd')}`
          : `${dayjs().day(daysOfWeek.indexOf(startDay)).format('dd')}-${dayjs()
              .day(daysOfWeek.indexOf(currentDay))
              .format('dd')}`;

      schedule += `${schedule ? ', ' : ''}${range}: ${timeRange}`;

      startDay = day;
      isDayOff = dayoff;
      startTime = start_time;
      endTime = end_time;
    }

    currentDay = day;
  }
  const timeRange = isDayOff
    ? 'выходной'
    : `${formatTime(startTime)}-${formatTime(endTime)}`;
  const range =
    startDay === currentDay
      ? `${dayjs().day(daysOfWeek.indexOf(startDay)).format('dd')}`
      : `${dayjs().day(daysOfWeek.indexOf(startDay)).format('dd')}-${dayjs()
          .day(daysOfWeek.indexOf(currentDay))
          .format('dd')}`;

  schedule += `${schedule ? ', ' : ''}${range}: ${timeRange}`;

  return schedule;
}

export const transaleteWeekDay = (day: string) => {
  switch (day) {
    case 'sunday':
      return dayjs().day(0).format('dddd');
    case 'monday':
      return dayjs().day(1).format('dddd');
    case 'tuesday':
      return dayjs().day(2).format('dddd');
    case 'wednesday':
      return dayjs().day(3).format('dddd');
    case 'thursday':
      return dayjs().day(4).format('dddd');
    case 'friday':
      return dayjs().day(5).format('dddd');
    case 'saturday':
      return dayjs().day(6).format('dddd');

    default:
      return 'error';
  }
};
