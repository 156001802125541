import React, {useState} from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import {useNavigate, useParams} from 'react-router-dom';
import {gql} from 'shared/__generated__';
import {device} from 'shared/device';
import {useQuery} from '@apollo/client';
import {OptionsType, Table} from 'shared/ui/Table';
import {
  StatusText,
  getDurationHourMinutes,
  getName,
  getPriceFormat,
} from 'shared/lib/utils';
import {CustomerHistorySkeleton} from './ui/Skeleton';
import {BadgeStatus} from 'shared/ui/BadgeStatus';
import {CompanyBookingStatus} from 'shared/__generated__/graphql';
import {EmptyState} from './ui/EmptyState';
import {Seo} from 'shared/ui/Seo';
import {Content} from 'shared/ui/Content';
import {useAppSelector} from 'shared/store';
import {Flex} from 'shared/ui/Flex';
import {Text} from 'shared/ui/Text';

const CUSTOMER_HISTORY = gql(`
query CustomerHistory(
  $customerId: String!, 
  $first: Int!, 
  $page: Int
  ) {
  customer(id: $customerId) {
    id
    bookings(first: $first, page: $page) {
      paginatorInfo {
        total
        hasMorePages
        currentPage
        count
        lastPage
      }
      data {
        id
        workingDate {
          id
          date
        }
        start
        end
        duration
        status
        employee {
          id
          name
          surname
        }
        bookingServices {
          id
          price
          discount_value
          service {
            id
            name
          }
        }
      }
    }
  }
}
`);

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 24px;
  padding-top: 8px;
  padding-bottom: 24px;
  @media (${device.mobile}) {
    gap: 8px;
    margin-bottom: 0;
    border-radius: 0;
  }
`;
const titles = ['Дата и время', 'Статус', 'Услуга', 'Сотрудник', 'Итого'];

export const CustomerHistory = () => {
  const params = useParams();
  const navigate = useNavigate();
  const username = useAppSelector(state => state.company.data?.username);
  const customerId = params.id!;
  const [options, setOptions] = useState<OptionsType>({
    page: 1,
    first: 10,
    total: 0,
    count: 0,
    lastPage: 1,
  });
  const {data, loading} = useQuery(CUSTOMER_HISTORY, {
    variables: {
      customerId: customerId!,
      first: options.first,
      page: options.page,
    },
    onCompleted: ({customer}) =>
      setOptions(prev => ({
        ...prev,
        total: customer?.bookings?.paginatorInfo.total,
        count: customer?.bookings?.paginatorInfo.count,
        lastPage: customer?.bookings?.paginatorInfo.lastPage,
      })),
  });
  const bookings = data?.customer?.bookings?.data ?? [];

  if (loading) {
    return <CustomerHistorySkeleton />;
  }

  return (
    <Wrapper>
      <Seo title="Записи" />
      {bookings?.length ? (
        <Content>
          <Table
            titles={titles}
            widths={[200, 156, 280, 218]}
            heightRow={'87px'}
            options={options}
            setOptions={setOptions}
            alignRightColumn={[4]}
            onClick={index =>
              !!bookings?.length &&
              navigate(`/${username}/booking/${bookings[index].id}`)
            }
            rows={bookings.map((item, index) => [
              <Flex key={`${index}_1`} direction="column">
                {dayjs(item?.workingDate?.date).format('DD.MM.YYYY')}
                <Text
                  style={{whiteSpace: 'nowrap'}}
                  typography="subHead14Medium"
                  color="textTertiary">{`${item.start}-${
                  item.end
                } · ${getDurationHourMinutes(item.duration ?? 0)}`}</Text>
              </Flex>,
              <BadgeStatus
                key={`${index}_2`}
                type={item.status as CompanyBookingStatus}>
                {StatusText[item.status as CompanyBookingStatus]}
              </BadgeStatus>,
              item.bookingServices.length
                ? item.bookingServices
                    .map(_item => _item.service.name)
                    .join(', ')
                : 'Без оказания услуг',
              getName(
                item?.employee?.name,
                item?.employee?.surname,
                'employee',
              ),
              getPriceFormat(
                item.bookingServices
                  .map(
                    service =>
                      parseFloat(service.price ?? '0') -
                      (service.discount_value ?? 0),
                  )
                  .reduce((a, b) => a + b, 0),
              ),
            ])}
          />
        </Content>
      ) : (
        <EmptyState />
      )}
    </Wrapper>
  );
};
