import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import React from 'react';
import styled from 'styled-components';
import {GripVertical} from 'shared/icons/GripVertical';
import {Text} from 'shared/ui/Text';
import {Flex} from 'shared/ui/Flex';
import {Document} from 'shared/icons/Document';
import {useColors} from 'shared/lib/hooks';
import {ResourceType} from '..';

const Item = styled.div`
  overflow: hidden;
  padding: 0;
  border: none;
  background-color: transparent;
  flex: 1;
  display: flex;
  flex-direction: row;
  height: 80px;
  align-items: center;
  border-radius: 14px;
  touch-action: none;
  &:hover {
    background-color: ${props => props.theme.bgSecondary};
    z-index: 100;
  }
`;
const CellGrip = styled.div`
  display: flex;
  padding: 18px;
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
`;

const ServiceCountView = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  background-color: ${props => props.theme.mainLight50};
  border-radius: 33px;
  gap: 8px;
  margin: 20px;
`;
export const ResourcesSortableItem = ({
  resource,
  onClick,
}: {
  resource: ResourceType;
  onClick: () => void;
}) => {
  const colors = useColors();
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    index,
    activeIndex,
  } = useSortable({id: resource.id});
  const activeTag = index === activeIndex;
  const style = {
    transform: CSS.Transform.toString(
      transform
        ? {
            x: transform.x,
            y: transform.y,
            scaleX: activeTag ? transform.scaleX + 0.03 : transform.scaleX,
            scaleY: activeTag ? transform.scaleY + 0.03 : transform.scaleY,
          }
        : null,
    ),
    transition,
  };
  return (
    <Item ref={setNodeRef} style={style}>
      <CellGrip {...attributes} {...listeners}>
        <GripVertical color={colors.textSecondary} />
      </CellGrip>
      <Flex
        style={{
          flex: 1,
          marginLeft: 16,
          height: 80,
          cursor: 'pointer',
        }}
        alignItems="center"
        justifyContent="space-between"
        onClick={onClick}>
        <Flex direction="column" gap={4} style={{cursor: 'pointer'}}>
          <Text typography="text16Regular" style={{cursor: 'pointer'}}>
            {resource.name}
          </Text>
          {resource.count > 0 && (
            <Text
              typography="subHead14Medium"
              color="textTertiary"
              style={{cursor: 'pointer'}}>
              {`${resource.count} шт.`}
            </Text>
          )}
        </Flex>
        {resource.services && resource.services.length !== 0 && (
          <ServiceCountView>
            <Document color={colors.mainPrimary} />
            <Text
              typography="subHead14Medium"
              color="mainPrimary"
              style={{cursor: 'pointer'}}>
              {`${resource.services!.length} ${
                resource.services!.length === 1
                  ? 'услуга'
                  : resource.services!.length > 1 &&
                    resource.services!.length < 5
                  ? 'услуги'
                  : 'услуг'
              }`}
            </Text>
          </ServiceCountView>
        )}
      </Flex>
    </Item>
  );
};
