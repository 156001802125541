import React from 'react';
const DotsVertical = () => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 17a2 2 0 110 4 2 2 0 010-4zM12 10a2 2 0 110 4 2 2 0 010-4zM12 3a2 2 0 110 4 2 2 0 010-4z"
        fill="#6765F8"
      />
    </svg>
  );
};

export default DotsVertical;
