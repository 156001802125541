import React from 'react';
import {CircleClose} from 'shared/icons/CircleClose';
import {useColors} from 'shared/lib/hooks';
import {Flex} from 'shared/ui/Flex';
import {IconButton} from 'shared/ui/IconButton';
import {TextInput} from 'shared/ui/TextInput';

type IKey = {id: number; name: string; value: string};

export const Key = ({
  data,
  onChange,
  onDelete,
}: {
  data: IKey;
  onChange: (value: IKey) => void;
  onDelete: (id: number) => void;
}) => {
  const colors = useColors();
  return (
    <Flex alignItems="center" gap={12}>
      <TextInput
        flex={1}
        value={data.name}
        onChange={text => {
          onChange({...data, name: text});
        }}
        label="Ключ"
      />
      <TextInput
        flex={1}
        value={data.value}
        onChange={text => {
          onChange({...data, value: text});
        }}
        label="Значение"
      />
      <IconButton variant="unstyled" onClick={() => onDelete(data.id)}>
        <CircleClose color={colors.dangerPrimary} />
      </IconButton>
    </Flex>
  );
};
