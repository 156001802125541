import React, {useEffect, useRef, useState} from 'react';
import {colors} from 'shared/colors';
import styled from 'styled-components';
import {Text} from './Text';
import {ArrowDown2v} from 'shared/icons/ArrowDown2v';
import {Flex} from './Flex';
import InputMask from 'react-input-mask';

type OptionType<T> = {
  value: T;
  label: string;
  hint?: string;
};

type SelectProps<T> = {
  label?: string;
  valueColor?: keyof typeof colors.dark;
  data?: OptionType<T>[];
  onChange: (value: T) => void;
  value?: T;
  icon?: React.ReactNode;
  backgroundColor?: string;
  disabled?: boolean;
  error?: string | null;
  required?: boolean;
  variant?: 'with_label' | 'label_animation' | 'default';
  style?: React.CSSProperties;
  wrapperStyle?: React.CSSProperties;
  dropdownPosition?: 'down' | 'top';
  emptyState?: React.ReactNode;
  renderItem?: (item: OptionType<T>) => React.ReactNode;
  autoScrolling?: boolean;
  mask?: string;
};

const SelectWrapper = styled.div`
  position: relative;
  pointer-events: all;
  width: 100%;
  padding: 0;
  margin: 0;
`;

const StyledLabel = styled(Text)<{
  required?: boolean;
  isOpen?: boolean;
  selected?: string;
}>`
  display: flex;
  &::after {
    content: '*';
    color: ${({theme}) => theme.dangerPrimary};
    visibility: ${({required}) => (required ? 'visible' : 'hidden')};
    font-size: ${({isOpen}) => (isOpen ? '12px' : '16px')};
    transition:
      transform 150ms ease,
      color 150ms ease,
      font-size 150ms ease;
  }
  transition: ${({selected}) => (!selected ? 'all 0.3s ease' : 'none')};
  transform: ${({isOpen, selected}) =>
    isOpen && !selected ? 'translateY(-14px)' : 'none'};
`;

const StyledButton = styled.button<{
  hasLabel: boolean;
  isOpen: boolean;
  background?: string;
  error?: string | null;
}>`
  display: flex;
  height: 64px;
  border-radius: 18px;
  width: 100%;
  pointer-events: all;
  align-items: center;
  gap: 8px;
  padding: 5px 16px 5px 16px;
  cursor: pointer;
  background-color: ${({background, theme}) => background ?? theme.fillPrimary};
  border: 1px solid
    ${props =>
      props.error ? props.theme.dangerPrimary : props.theme.borderPrimary};
  &:focus {
    border: 1px solid
      ${({error, theme, isOpen}) =>
        error
          ? theme.dangerPrimary
          : isOpen
          ? theme.mainPrimary
          : theme.borderPrimary};
    background-color: ${({background}) => background};
    outline: none;
  }
`;
interface CustomDropdownProps {
  isOpen: boolean;
  position: 'down' | 'top';
}

const CustomDropdown = styled.div<CustomDropdownProps>`
  position: absolute;
  width: 100%;
  min-width: max-content;
  border-radius: 18px;
  padding: 16px 0;
  background-color: ${({theme}) => theme.bgPrimary};
  border: 1px solid ${({theme}) => theme.borderPrimary};
  z-index: 100;
  display: ${({isOpen}) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;
  gap: 8px;
  max-height: 400px;
  overflow: auto;
  bottom: ${({position}) => (position === 'top' ? '64px' : undefined)};
`;

const DropdownItem = styled.div<{active: boolean}>`
  background-color: ${({theme, active}) =>
    active ? theme.bgSecondary : theme.bgPrimary};
  color: ${({theme}) => theme.textPrimary};
  cursor: pointer;
  &:hover {
    background-color: ${({theme}) => theme.bgSecondary};
  }
`;

const TextInput = styled.input`
  background-color: transparent;
  outline: none;
  border: none;
  color: ${({theme}) => theme.textPrimary};
  font-size: 16px;
  font-family: 'Inter';
  font-weight: 400;
  line-height: 24px;
`;

export const SelectInput = <T,>(props: SelectProps<T>) => {
  const {
    label = '',
    data,
    onChange,
    value: selected,
    icon,
    backgroundColor,
    disabled,
    error,
    required,
    variant = 'label_animation',
    style,
    wrapperStyle,
    mask = '',
    dropdownPosition = 'down',
    emptyState,
    autoScrolling = true,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const selectedLabel =
    data?.find(({value}) => value === selected)?.label ?? '';
  const selectedHint = data?.find(({value}) => value === selected)?.hint;
  const isLabelShrink = selectedLabel
    ? true
    : !(selected === null || selected === undefined) || isOpen;
  const [textValue, setTextValue] = useState(selected + '');

  const selectRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const refInput = useRef<HTMLInputElement>(null);

  const handleSelect = (value: T) => {
    setIsOpen(false);
    onChange && onChange(value);
    setTextValue(value as string);
  };

  const {
    renderItem = item => (
      <Text
        style={{display: 'flex', pointerEvents: 'none', padding: 16, gap: 4}}>
        {item.label}
        {item.hint && <Text color="textTertiary">{item.hint}</Text>}
      </Text>
    ),
  } = props;

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        selectRef.current &&
        !selectRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectRef]);

  useEffect(() => {
    if (isOpen) {
      const index = data?.findIndex(item => item.value === selected);
      if (index !== -1 && autoScrolling) {
        const selectedItem = document.getElementById(`dropdown_item_${index}`);
        selectedItem?.offsetTop &&
          dropdownRef.current?.scrollTo({
            behavior: 'smooth',
            top: selectedItem?.offsetTop,
          });
      }
    }
  }, [autoScrolling, data, isOpen, selected]);

  return (
    <SelectWrapper ref={selectRef} style={wrapperStyle}>
      {variant === 'with_label' && (
        <StyledLabel mb={8} required={required} color="textTertiary">
          {label}
        </StyledLabel>
      )}
      <StyledButton
        error={error}
        disabled={disabled}
        style={style}
        background={backgroundColor}
        onClick={() => {
          setIsOpen(!isOpen);
          refInput.current?.focus();
        }}
        hasLabel={!!label}
        isOpen={isOpen}>
        <Flex
          flex={1}
          gap={
            label && (variant === 'label_animation' ? selectedLabel : selected)
              ? 4
              : 0
          }
          direction="column">
          {label && variant === 'label_animation' && (
            <StyledLabel
              isOpen={isLabelShrink}
              required={required}
              selected={selectedLabel}
              typography={isLabelShrink ? 'footNote12Regular' : 'text16Regular'}
              color={'textTertiary'}>
              {label}
            </StyledLabel>
          )}
          <Flex gap={4}>
            <InputMask
              mask={mask}
              maskPlaceholder={null}
              value={textValue}
              onChange={e => {
                setTextValue(e.target.value);
                const find = data?.find(item => item.value === e.target.value)
                  ?.value;
                if (find) {
                  handleSelect(find);
                }
              }}>
              <TextInput
                ref={refInput}
                style={{
                  marginTop: 0,
                  padding: 0,
                }}
              />
            </InputMask>
            {selectedHint && <Text color="textTertiary">{selectedHint}</Text>}
          </Flex>
        </Flex>
        {icon ? (
          icon
        ) : (
          <ArrowDown2v
            style={{
              transform: isOpen ? ' rotate(180deg)' : ' rotate(0deg)',
              transition: 'transform 0.3s ease',
            }}
          />
        )}
      </StyledButton>
      {error && (
        <Text mt={8} color="dangerPrimary" typography="footNote12Regular">
          {error}
        </Text>
      )}
      <CustomDropdown
        ref={dropdownRef}
        position={dropdownPosition}
        isOpen={isOpen}>
        {data?.length === 0
          ? emptyState
          : data?.map(({value, label, hint}, index) => (
              <DropdownItem
                key={'dropdown_item' + value}
                id={`dropdown_item_` + index}
                active={selected === value}
                onClick={() => handleSelect(value)}>
                {renderItem({value, label, hint})}
              </DropdownItem>
            ))}
      </CustomDropdown>
    </SelectWrapper>
  );
};
