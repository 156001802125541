export {TagCreateModal} from './ui/TagCreateModal';
export {SelectTags} from './ui/SelectTags';
import {gql} from 'shared/__generated__';

export const TAGS = gql(`
  query CompanyTags($companyId: String!, $colorsFirst: Int!, $colorsPage: Int) {
    companyTags(company_id: $companyId) {
      id
      name
      order
      color {
        id
        rgb
      }
    }
    colors(first: $colorsFirst, page: $colorsPage) {
      data {
        id
        rgb
      }
    }
  }
`);

export const UPDATE_TAGS_POSITION = gql(`
  mutation UpdateCompanyTagPositions($input: UpdateCompanyTagPositions!) {
    updateCompanyTagPositions(input: $input) {
      id
    }
  }
`);

export const TAG_COLORS = gql(`
  query Colors {
    colors(first: 100) {
      data {
        id
        rgb
      }
    }
  }
`);
