import React, {ReactNode, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {WorkingDateType} from 'shared/__generated__/graphql';
import {Button} from 'shared/ui/Button';
import {Content} from 'shared/ui/Content';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';
import dayjs from 'dayjs';
import {
  EMPLOYEE_SCHEDULE,
  GET_EMPLOYEES,
  ScheduleWorkTime,
} from 'entities/schedule';
import {GET_COMPANY_EMPLOYEES} from 'entities/employees';
import {Plus} from 'shared/icons/Plus';
import {useColors} from 'shared/lib/hooks';
import {CustomSchedule} from './ui/CustomSchedule';
import {WeekdaySchedule} from './ui/WeekdaySchedule';
import {useAppSelector} from 'shared/store';
import {ApolloError, useMutation} from '@apollo/client';
import {gql} from 'shared/__generated__';
import {DisplayCalendar} from './ui/DisplayCalendar';
import {Layout} from 'shared/ui/Layout';
import {ScheduleSelectEmployee} from './ui/ScheduleSelectEmployee';
import {showAlert} from 'shared/ui/Alert';
import {device} from 'shared/device';
import {Flex} from 'shared/ui/Flex';
import {PageHeader} from 'shared/ui/PageHeader';
import {SelectPeriod} from 'shared/ui/SelectPeriod';
import {TUTORIALCHECK} from 'entities/tutorial';

interface IScheduleType {
  label: string;
  value: WorkingDateType;
}
const CREATE_WORKING_DATES = gql(`
  mutation CreateWorkingDates($input: CreateWorkingDate!) {
    createWorkingDates(input: $input) {
      id
    }
  }
`);

const Container = styled.div`
  grid-column-start: 4;
  grid-column-end: 10;
  padding-bottom: 24px;
  @media ${device.mobile} {
    margin-top: 48px;
  }
`;
const Title = styled(Text)<{hide?: boolean}>`
  display: ${({hide}) => (hide ? 'none' : 'flex')};
`;
const AddButton = styled(Button)<{count: number}>`
  flex: 1px;
  min-height: 48px;
  display: ${({count}) => (count < 4 ? 'flex' : 'none')};
  border: 1px solid ${({theme}) => theme.mainPrimary};
  background-color: transparent;
  color: ${({theme}) => theme.mainPrimary};
  &:hover {
    background-color: transparent;
  }
`;
const TypeSchedule = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
`;
const Type = styled(Button)<{active: boolean}>`
  flex: 1px;
  border-radius: 16px;
  height: 56px;
  border: 1px solid
    ${({active, theme}) => (active ? theme.mainPrimary : theme.borderPrimary)};
  font-weight: 400;
  background-color: ${({active, theme}) =>
    active ? theme.mainPrimary : theme.fillPrimary};
  color: ${({active, theme}) => (active ? theme.white : theme.textPrimary)};
  &:hover {
    background-color: ${({active, theme}) =>
      active ? theme.mainPrimary : theme.bgPrimary};
  }
`;
const Block = styled.div<{hide?: boolean}>`
  display: ${({hide}) => (hide ? 'none' : 'flex')};
  flex-direction: column;
  gap: 16px;
`;

const typeSchedule: IScheduleType[] = [
  {label: 'Все дни', value: WorkingDateType.Everyday},
  {label: 'Будни', value: WorkingDateType.Weekdays},
  {label: 'По дням недели', value: WorkingDateType.DaysOfWeek},
  {label: 'Настраиваемый', value: WorkingDateType.Custom},
];

const getSelectedWorkingDate = (
  startDate: string | undefined,
  count: number,
  type: WorkingDateType,
  week: number[],
  workdays: number,
  daysOff: number,
) => {
  const days = Array.from(Array(count).keys()).map(index =>
    dayjs(startDate).add(index, 'day').format('YYYY-MM-DD'),
  );
  if (!startDate) return;
  if (type === WorkingDateType.Everyday) {
    return days;
  }
  if (type === WorkingDateType.Weekdays)
    return days.filter(day => dayjs(day).day() !== 6 && dayjs(day).day() !== 0);
  if (type === WorkingDateType.DaysOfWeek) {
    return days.filter(day => {
      const numberDay = dayjs(day).day();
      return week.some(item => item === numberDay);
    });
  }
  const sum = +workdays + +daysOff;
  return days.filter((_, index) => index % sum < workdays);
};

const Box = ({
  children,
  title,
  hide,
}: {
  children: ReactNode;
  title: string;
  hide?: boolean;
}) => (
  <Block hide={hide}>
    <Title typography="title18">{title}</Title>
    {children}
  </Block>
);

const getDateInfo = (item: {from: string | null; to: string | null}) => {
  const last = dayjs(item.to);
  return {
    start: item.from!,
    count: last.isValid() ? last.diff(item.from, 'day') + 1 : 1,
  };
};
export const CreateSchedule = () => {
  const employeeIdParams: string | undefined = useLocation().state;
  const navigate = useNavigate();
  const colors = useColors();
  const companyId = useAppSelector(state => state.company.data!.id);
  const [employeeId, setEmployeeId] = useState<string | null>(
    employeeIdParams ?? null,
  );
  const [selectedType, setSelectedType] = useState<IScheduleType>(
    typeSchedule[0],
  );
  const [startWorkTime, setStartWorkTime] = useState('09:00');
  const [endWorkTime, setEndWorkTime] = useState('20:00');
  const [selectedDaysWeek, setSelectedDaysWeek] = useState<number[]>([]);
  const [workdays, setWorkdays] = useState('2');
  const [dayjsOff, setDaysOff] = useState('2');
  const [breakTimes, setBreakTimes] = useState<{start: string; end: string}[]>(
    [],
  );
  const [dates, setDates] = useState<{from: string | null; to: string | null}>({
    from: null,
    to: null,
  });
  const {start, count} = getDateInfo(dates);

  const [dateError, setDateError] = useState<string | null>(null);
  const selectedDay = getSelectedWorkingDate(
    start,
    count,
    selectedType.value,
    selectedDaysWeek,
    +workdays,
    +dayjsOff,
  );
  const [createWorkDates, {loading}] = useMutation(CREATE_WORKING_DATES, {
    refetchQueries: [
      {
        query: EMPLOYEE_SCHEDULE,
        variables: {employeeId, companyId},
      },
      {
        query: GET_EMPLOYEES,
        variables: {companyId, first: 20, page: 1, enabled: true},
      },
      {
        query: GET_COMPANY_EMPLOYEES,
        variables: {companyId, first: 20},
      },
      {
        query: TUTORIALCHECK,
        variables: {
          companyId,
        },
      },
    ],
  });
  const changeMainTime = ({
    time,
    location,
  }: {
    time: string;
    location: 'start' | 'end';
  }) => {
    if (location === 'start') {
      setStartWorkTime(time);
      return;
    }
    setEndWorkTime(time);
  };
  const changeBreakTime = ({
    time,
    location,
    position,
  }: {
    time: string;
    location: 'start' | 'end';
    position?: number;
  }) => {
    if (!position) return;
    const copy = [...breakTimes];
    copy[position][location] = time;
    setBreakTimes(copy);
  };
  const changeCustom = (value: string, type: 'workday' | 'dayoff') => {
    if (type === 'workday') {
      setWorkdays(Math.abs(+value).toString());
      return;
    }
    setDaysOff(Math.abs(+value).toString());
  };
  const onPressAddBreak = () => {
    if (breakTimes.length === 0) {
      setBreakTimes([...breakTimes, {start: '13:00', end: '14:00'}]);
      return;
    }
    const last = breakTimes[breakTimes.length - 1];
    const today = dayjs().format('YYYY-MM-DD ');
    const newStartTime = dayjs(today + last.end)
      .add(30, 'minutes')
      .format('HH:mm');
    const newEndTime = dayjs(today + last.end)
      .add(1, 'hour')
      .format('HH:mm');
    setBreakTimes([
      ...breakTimes,
      {
        start: newStartTime,
        end: newEndTime,
      },
    ]);
  };

  const saveSchedule = () => {
    if (
      selectedDaysWeek.length === 0 &&
      selectedType.value === WorkingDateType.DaysOfWeek
    ) {
      showAlert('error', 'Выберите хотя бы один день');
      return;
    }
    if (!employeeId) {
      showAlert('error', 'Выберите сотрудника');
      return;
    }
    if (!start) {
      setDateError('Обязательное поле');
      return;
    }
    const to = dayjs(start)
      .add(count - 1, 'day')
      .format('YYYY-MM-DD');
    const isReverse = dayjs(startWorkTime).isAfter(endWorkTime);
    const payload = {
      from: start,
      to,
      employee_id: employeeId,
      type: selectedType.value,
      start_time: isReverse ? endWorkTime : startWorkTime,
      end_time: isReverse ? startWorkTime : endWorkTime,
      company_id: companyId,
    };
    if (selectedType.value === WorkingDateType.Custom) {
      Object.assign(payload, {
        working_days: +workdays,
        day_off: +dayjsOff,
      });
    }
    if (selectedType.value === WorkingDateType.DaysOfWeek) {
      Object.assign(payload, {week_days: selectedDaysWeek});
    }
    const sortedBreak = breakTimes.filter(item => item.start !== item.end);
    if (sortedBreak.length > 0) {
      const break_times = sortedBreak.map(item => ({
        start: dayjs(item.start).isAfter(item.end) ? item.end : item.start,
        end: dayjs(item.start).isAfter(item.end) ? item.start : item.end,
      }));
      Object.assign(payload, {break_times});
    }
    createWorkDates({variables: {input: payload}})
      .then(() => {
        navigate(-1);
      })
      .catch((err: ApolloError) => alert({text: err.message, level: 'error'}));
  };

  return (
    <Layout>
      <Container>
        <PageHeader back variant="layout" title="Новый график" />
        <Content style={{marginTop: 32}} gap="24px">
          <Box title="Сотрудник">
            <ScheduleSelectEmployee
              value={employeeId}
              onChange={setEmployeeId}
            />
          </Box>
          <Box title="Период">
            <SelectPeriod
              type="schedule"
              onChange={setDates}
              error={dateError}
            />
          </Box>
          <Box title="Тип графика">
            <TypeSchedule>
              {typeSchedule.map(type => (
                <Type
                  key={type.value}
                  active={type === selectedType}
                  onClick={() => setSelectedType(type)}>
                  {type.label}
                </Type>
              ))}
            </TypeSchedule>
          </Box>
          <WeekdaySchedule
            show={selectedType.value === WorkingDateType.DaysOfWeek}
            onChange={setSelectedDaysWeek}
            selected={selectedDaysWeek}
          />
          <CustomSchedule
            show={selectedType.value === WorkingDateType.Custom}
            start={workdays}
            end={dayjsOff}
            onChange={changeCustom}
          />
          <Flex direction="column" gap={16}>
            <Box title="Рабочие часы">
              <ScheduleWorkTime
                onChange={(time, location) => changeMainTime({time, location})}
                value={{start: startWorkTime, end: endWorkTime}}
              />
            </Box>
            <Box hide={breakTimes.length === 0} title="Перерыв">
              {breakTimes.map((item, index) => (
                <ScheduleWorkTime
                  key={item.start + index}
                  onChange={(time, location) =>
                    changeBreakTime({time, location, position: index})
                  }
                  onDelete={() =>
                    setBreakTimes(
                      breakTimes.filter(
                        (_, breakIndex) => breakIndex !== index,
                      ),
                    )
                  }
                  value={{start: item.start, end: item.end}}
                />
              ))}
            </Box>
            <AddButton
              typography="subHead14Medium"
              leftIcon={<Plus size={24} color={colors.mainPrimary} />}
              count={breakTimes.length}
              onClick={onPressAddBreak}>
              Добавить перерыв
            </AddButton>
          </Flex>
          <Button
            typography="text16Semibold"
            size="large"
            loading={loading}
            onClick={saveSchedule}>
            Сохранить
          </Button>
        </Content>
      </Container>
      <DisplayCalendar selectedDays={selectedDay ?? []} />
    </Layout>
  );
};
