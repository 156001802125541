import {ApolloError, useMutation} from '@apollo/client';
import React, {useEffect, useState} from 'react';
import {gql} from 'shared/__generated__';
import {useAppSelector} from 'shared/store';
import {BRANDS, GET_DATA_CREATE_PRODUCT, PRODUCTS_CATEGORIES} from '..';
import {Modal} from 'shared/ui/Modal';
import {Head} from 'shared/ui/Head';
import {Text} from 'shared/ui/Text';
import {IconButton} from 'shared/ui/IconButton';
import {Flex} from 'shared/ui/Flex';
import {TextInput} from 'shared/ui/TextInput';
import {Select} from 'shared/ui/Select';
import {Button} from 'shared/ui/Button';
import {showAlert} from 'shared/ui/Alert';
import {useColors} from 'shared/lib/hooks';
import {EmptyAddCategoryBlock} from 'shared/illustration/EmptyAddCategoryBlock';
import Xv2 from 'shared/icons/Xv2';

const CREATE_PRODUCTS_CATEGORY = gql(`
  mutation CreateProductsCategoryModal($companyId: String!, $name: String!, $productsCategoryId: String) {
    createProductsCategory(company_id: $companyId, name: $name, products_category_id: $productsCategoryId) {
      id
      name
    }
  }
`);

const ContainerModal = ({
  children,
  visible,
  onClose,
}: React.PropsWithChildren<{visible: boolean; onClose: () => void}>) => (
  <Modal
    style={{
      left: 'calc((100% - 588px) / 2)',
      top: '27%',
      width: 588,
      gap: 24,
      padding: 24,
      height: 'auto',
    }}
    backdropVariant="overlay"
    visible={visible}
    onClose={onClose}>
    {children}
  </Modal>
);

export const ModalCategoryCreate = ({
  visible,
  onClose,
  categories,
}: {
  visible: boolean;
  onClose: () => void;
  categories: {label: string; value: string}[];
}) => {
  const companyId = useAppSelector(state => state.company.data?.id);
  const [createName, setCreateName] = useState('');
  const [parent, setParent] = useState<string | null>(null);
  const [createProductsCategory, {loading}] = useMutation(
    CREATE_PRODUCTS_CATEGORY,
    {
      refetchQueries: [
        {
          query: PRODUCTS_CATEGORIES,
          variables: {
            companyId: companyId!,
            page: 1,
            first: 40,
          },
        },
        {
          query: GET_DATA_CREATE_PRODUCT,
          variables: {
            first: 40,
            companyId: companyId!,
            categoryFirst2: 40,
          },
        },
      ],
    },
  );

  return (
    <ContainerModal visible={visible} onClose={onClose}>
      <Head style={{justifyContent: 'space-between'}}>
        <Text typography="title20">Создание категории</Text>
        <IconButton size={40} onClick={() => onClose()}>
          <Xv2 />
        </IconButton>
      </Head>
      <Flex direction="column" gap={24}>
        <TextInput
          label="Название категории"
          required
          value={createName}
          onChange={setCreateName}
        />
        <Select
          data={categories}
          label="Родительская категория"
          value={parent}
          onChange={setParent}
          emptyState={
            <Flex
              direction="column"
              alignItems="center"
              gap={16}
              style={{marginBottom: 8}}
              justifyContent="center">
              <EmptyAddCategoryBlock size={200} />
              <Text color="textTertiary">Ничего не найдено</Text>
            </Flex>
          }
        />
        <Button
          size="large"
          disabled={!createName}
          loading={loading}
          onClick={async () => {
            try {
              await createProductsCategory({
                variables: {
                  companyId: companyId!,
                  name: createName,
                  productsCategoryId: parent ?? undefined,
                },
              });
              setCreateName('');
              setParent('');
              onClose();
            } catch (e) {
              if (e instanceof ApolloError) {
                showAlert('error', e.message);
              }
            }
          }}>
          Добавить
        </Button>
      </Flex>
    </ContainerModal>
  );
};

const EDIT_PRODUCTS_CATEGORY = gql(`
  mutation UpdateProductsCategory($id: String!, $name: String, $productsCategoryId: String) {
    updateProductsCategory(id: $id, name: $name, products_category_id: $productsCategoryId) {
      id
      name
    }
  }
`);

const DELETE_PRODUCTS_CATEGORY = gql(`
  mutation DeleteProductsCategory($id: String!) {
    deleteProductsCategory(id: $id) {
      id
      name
    }
  }
`);

export const ModalCategoryEdit = ({
  visible,
  onClose,
  id,
  name,
  categories,
  parentId,
}: {
  visible: boolean;
  onClose: () => void;
  id?: string;
  name?: string;
  categories: {label: string; value: string}[];
  parentId?: string;
}) => {
  const companyId = useAppSelector(state => state.company.data?.id);
  const [editProductsCategory, {loading}] = useMutation(
    EDIT_PRODUCTS_CATEGORY,
    {
      refetchQueries: [
        {
          query: PRODUCTS_CATEGORIES,
          variables: {
            companyId: companyId!,
            page: 1,
            first: 40,
          },
        },
      ],
    },
  );
  const [deleteProductsCategory, {loading: loadingDelete}] = useMutation(
    DELETE_PRODUCTS_CATEGORY,
    {
      refetchQueries: [
        {
          query: PRODUCTS_CATEGORIES,
          variables: {
            companyId: companyId!,
            page: 1,
            first: 40,
          },
        },
      ],
    },
  );
  const colors = useColors();
  const [editName, setEditName] = useState('');
  const [parent, setParent] = useState<string | null>(null);

  useEffect(() => {
    setEditName(name ?? '');
    setParent(parentId ?? null);
  }, [name, id, parentId]);
  return (
    <ContainerModal visible={visible} onClose={onClose}>
      <Head style={{justifyContent: 'space-between'}}>
        <Text typography="title20">Редактирование категории</Text>
        <IconButton size={40} onClick={() => onClose()}>
          <Xv2 />
        </IconButton>
      </Head>
      <Flex direction="column" gap={24}>
        <TextInput
          label="Название категории"
          required
          value={editName}
          onChange={setEditName}
        />
        <Select
          data={categories}
          value={parent}
          label="Родительская категория"
          onChange={setParent}
          emptyState={
            <Flex
              direction="column"
              alignItems="center"
              gap={16}
              style={{marginBottom: 36}}
              justifyContent="center">
              <EmptyAddCategoryBlock size={200} />
              <Text color="textTertiary">К сожалению ничего не найдено</Text>
            </Flex>
          }
        />
        <Flex gap={16}>
          <Button
            variant="outline"
            style={{
              borderColor: colors.dangerPrimary,
              color: colors.dangerPrimary,
              flex: 1,
            }}
            size="large"
            loading={loadingDelete}
            onClick={async () => {
              try {
                await deleteProductsCategory({
                  variables: {
                    id: id!,
                  },
                });
                onClose();
              } catch (e) {
                if (e instanceof ApolloError) {
                  showAlert('error', e.message);
                }
              }
            }}>
            Удалить
          </Button>
          <Button
            size="large"
            style={{flex: 1}}
            loading={loading}
            onClick={async () => {
              try {
                await editProductsCategory({
                  variables: {
                    id: id!,
                    name: editName,
                    productsCategoryId: parent ?? undefined,
                  },
                });
                onClose();
              } catch (e) {
                if (e instanceof ApolloError) {
                  showAlert('error', e.message);
                }
              }
            }}>
            Сохранить
          </Button>
        </Flex>
      </Flex>
    </ContainerModal>
  );
};

const CREATE_BRAND = gql(`
  mutation CreateProductsBrand($companyId: String!, $name: String!) {
    createProductsBrand(company_id: $companyId, name: $name) {
      id
      name
    }
  }
`);

export const ModalBrandCreate = ({
  visible,
  onClose,
}: {
  visible: boolean;
  onClose: () => void;
}) => {
  const companyId = useAppSelector(state => state.company.data?.id);
  const [name, setName] = React.useState('');
  const [createBrand, {loading}] = useMutation(CREATE_BRAND, {
    refetchQueries: [
      {
        query: BRANDS,
        variables: {
          companyId: companyId!,
          first: 40,
          page: 1,
        },
      },
    ],
  });

  return (
    <ContainerModal visible={visible} onClose={onClose}>
      <Head style={{justifyContent: 'space-between'}}>
        <Text typography="title20">Создание бренда</Text>
        <IconButton size={40} onClick={() => onClose()}>
          <Xv2 />
        </IconButton>
      </Head>
      <Flex direction="column" gap={24}>
        <TextInput
          label="Название бренда"
          required
          value={name}
          onChange={setName}
        />
        <Button
          loading={loading}
          size="large"
          onClick={async () => {
            try {
              await createBrand({
                variables: {
                  companyId: companyId!,
                  name,
                },
              });
              setName('');
              onClose();
            } catch (e) {
              if (e instanceof ApolloError) {
                showAlert('error', e.message);
              }
            }
          }}>
          Создать
        </Button>
      </Flex>
    </ContainerModal>
  );
};

const UPDATE_PRODUCTS_BRAND = gql(`
  mutation UpdateProductsBrand($updateProductsBrandId: String!, $name: String!) {
    updateProductsBrand(id: $updateProductsBrandId, name: $name) {
      id
      name
    }
  }
`);

const DELETE_PRODUCTS_BRAND = gql(`
  mutation UpdateProductsBrandModal($deleteProductsBrandId: String!) {
    deleteProductsBrand(id: $deleteProductsBrandId) {
      id
      name
    }
  }
`);

export const ModalBrandEdit = ({
  visible,
  onClose,
  data: editData,
}: {
  visible: boolean;
  onClose: () => void;
  data: {name: string; id: string} | null;
}) => {
  const colors = useColors();
  const [name, setName] = React.useState(editData?.name ?? '');

  const [updateBrand, {loading}] = useMutation(UPDATE_PRODUCTS_BRAND, {
    refetchQueries: [
      {
        query: BRANDS,
        variables: {
          companyId: useAppSelector(state => state.company.data?.id)!,
          first: 40,
          page: 1,
        },
      },
    ],
  });
  const [deleteBrand, {loading: deleteLoading}] = useMutation(
    DELETE_PRODUCTS_BRAND,
    {
      refetchQueries: [
        {
          query: BRANDS,
          variables: {
            companyId: useAppSelector(state => state.company.data?.id)!,
            first: 40,
            page: 1,
          },
        },
      ],
    },
  );

  useEffect(() => {
    if (visible) {
      setName(editData?.name ?? '');
    }
  }, [visible, editData]);

  return (
    <ContainerModal visible={visible} onClose={onClose}>
      <Head style={{justifyContent: 'space-between'}}>
        <Text typography="title20">Редактирование бренда</Text>
        <IconButton size={40} onClick={() => onClose()}>
          <Xv2 />
        </IconButton>
      </Head>
      <Flex direction="column" gap={24}>
        <TextInput
          label="Название бренда"
          required
          value={name}
          onChange={setName}
        />
        <Flex direction="row" gap={8}>
          <Button
            style={{
              flex: 1,
              borderColor: colors.dangerPrimary,
              color: colors.dangerPrimary,
            }}
            variant="outline"
            size="large"
            loading={deleteLoading}
            onClick={async () => {
              try {
                await deleteBrand({
                  variables: {
                    deleteProductsBrandId: editData!.id,
                  },
                });
                onClose();
              } catch (e) {
                if (e instanceof ApolloError) {
                  showAlert('error', e.message);
                }
              }
            }}>
            Удалить
          </Button>
          <Button
            style={{flex: 1}}
            size="large"
            loading={loading}
            onClick={async () => {
              try {
                await updateBrand({
                  variables: {
                    updateProductsBrandId: editData!.id,
                    name,
                  },
                });
                onClose();
              } catch (e) {
                if (e instanceof ApolloError) {
                  showAlert('error', e.message);
                }
              }
            }}>
            Сохранить
          </Button>
        </Flex>
      </Flex>
    </ContainerModal>
  );
};
