import React, {useState} from 'react';
import {Outlet, useLocation, useNavigate, useParams} from 'react-router-dom';
import {Text} from 'shared/ui/Text';
import {Avatar} from 'shared/ui/Avatar';
import User from 'shared/icons/User';
import {ProfileContainer} from './ui/ProfileContainer';
import {Head} from 'shared/ui/Head';
import {ActionIcon} from 'shared/ui/ActionIcon';
import {Menu} from 'shared/ui/Menu';
import {MenuAction} from 'shared/ui/MenuAction';
import styled from 'styled-components';
import {ApolloError, useMutation, useQuery} from '@apollo/client';
import {gql} from 'shared/__generated__';
import {device} from 'shared/device';
import {Trash} from 'shared/icons/Trash';
import {useColors, useEmployeePermission} from 'shared/lib/hooks';
import {Coupon} from 'shared/icons/Coupon';
import {Certificate} from 'shared/icons/Certificate';
import {Flex} from 'shared/ui/Flex';
import {ArrowLeft2v} from 'shared/icons/ArrowLeft2v';
import {Popup} from 'shared/ui/Popup';
import {Grow} from 'shared/ui/Grow';
import {IconButton} from 'shared/ui/IconButton';
import {Button} from 'shared/ui/Button';
import {showAlert} from 'shared/ui/Alert';
import {getName} from 'shared/lib/utils';
import {TextArea} from 'shared/ui/TextArea';
import {Pencil} from 'shared/icons/Pencil';
import DotsVertical from 'shared/icons/DotsVertical';
import {Modal} from 'shared/ui/Modal';
import {DELETE_CUSTOMER, GET_CUSTOMERS} from 'entities/customers';
import {useAppSelector} from 'shared/store';
import {CloseButton} from 'shared/ui/CloseButton';
import {Clipboard} from 'shared/icons/Clipboard';

const CUSTOMER_PROFILE = gql(`
  query Customer($customerId: String, $companyId: String!) {
    customer(id: $customerId) {
      id
      surname
      name
      is_blocked
      segment
      avatar {
        id
        url
        url_256
      }
    }
    ...Permissions
  }
`);

const BLACK_CUSTOMER = gql(`
  mutation BlockedCustomer($input: UpdateCustomer!) {
    updateCustomer(input: $input) {
      id
      is_blocked
      note
    }
  }
`);

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 72px;
  grid-column-start: 1;
  grid-column-end: 4;
  @media (${device.mobile}) {
    margin-top: 56px;
    flex-direction: row;
    justify-content: space-between;
  }
`;
const HeadRight = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  gap: 8px;
`;
const PopupWrapper = styled.div`
  position: relative;
  display: inline-block;
`;
const ButtonSelect = styled.button<{checked?: boolean}>`
  display: flex;
  padding: 0 16px;
  align-items: center;
  height: 56px;
  width: 302px;
  border: none;
  background-color: transparent;
  &:hover {
    background-color: ${({theme}) => theme.bgSecondary};
  }
  pointer-events: all;
  cursor: pointer;
`;

export const CustomerProfileRoot = () => {
  const navigate = useNavigate();
  const color = useColors();
  const params = useParams();
  const pathname = useLocation().pathname;
  const {id: customerId} = params;
  const companyId = useAppSelector(state => state.company.data!.id);
  const {data, loading} = useQuery(CUSTOMER_PROFILE, {
    variables: {
      customerId,
      companyId,
    },
  });
  const {
    edit_customers_permission,
    edit_bonus_card_permission,
    edit_certificate_permission,
  } = useEmployeePermission(data);
  const [blockedCustomer, {loading: loadingBlackList}] =
    useMutation(BLACK_CUSTOMER);
  const [deleteCustomer, {loading: deleteLoading}] = useMutation(
    DELETE_CUSTOMER,
    {
      variables: {deleteCustomerId: customerId!},
      refetchQueries: [
        {
          query: GET_CUSTOMERS,
          variables: {
            companyId,
            first: 10,
            page: 1,
            query: '',
            isBlocked: false,
          },
        },
      ],
    },
  );
  const [visible, setVisible] = useState(false);
  const [visibleBlackList, setVisibleBlackList] = useState(false);
  const [modalSetting, setModalSetting] = React.useState(false);
  const [note, setNote] = useState('');
  const onClose = () => setVisible(false);
  const customer = data?.customer;
  const username = useAppSelector(state => state.company.data?.username);

  return (
    <ProfileContainer>
      <Popup
        visible={visibleBlackList}
        onClose={() => setVisibleBlackList(false)}>
        <Grow style={{gap: 16}}>
          <Flex
            style={{width: 392}}
            justifyContent="space-between"
            alignItems="center">
            <Text typography="title20">Укажите причину</Text>
            <CloseButton onClose={() => setVisibleBlackList(false)} />
          </Flex>
          <TextArea
            size="large"
            label="Причина блокировки"
            maxLength={1000}
            value={note}
            onChange={text => setNote(text)}
          />
          <Button
            size="large"
            loading={loadingBlackList}
            disabled={loadingBlackList}
            onClick={() => {
              blockedCustomer({
                variables: {
                  input: {
                    note,
                    id: customerId!,
                    is_blocked: true,
                  },
                },
              })
                .then(() =>
                  showAlert('success', 'Клиент добавлен в черный список'),
                )
                .catch((e: ApolloError) => showAlert('error', e.message))
                .finally(() => setVisibleBlackList(false));
            }}>
            Заблокировать
          </Button>
        </Grow>
      </Popup>
      <Popup {...{visible, onClose}}>
        <Grow style={{gap: 16, width: 390}}>
          <Flex justifyContent="space-between" alignItems="center">
            <Text typography="title20">Удалить клиента</Text>
            <CloseButton onClose={() => onClose()} />
          </Flex>
          <Text>Вы уверены, что хотите удалить клиента?</Text>
          <Flex justifyContent="center" gap={8} flex={1}>
            <Button
              size="large"
              style={{borderColor: color.mainPrimary, flex: 1}}
              variant="subtitle"
              onClick={onClose}>
              <Text
                style={{cursor: 'pointer'}}
                typography="text16Semibold"
                color="mainPrimary">
                Отмена
              </Text>
            </Button>
            <Button
              size="large"
              style={{borderColor: color.dangerPrimary, flex: 1}}
              loading={deleteLoading}
              disabled={deleteLoading}
              variant="subtitle"
              onClick={() => {
                deleteCustomer()
                  .then(() => {
                    navigate(`/${username}/customers`);
                  })
                  .catch((e: ApolloError) => showAlert('error', e.message));
              }}>
              <Text
                style={{cursor: 'pointer'}}
                typography="text16Semibold"
                color="dangerPrimary">
                Удалить
              </Text>
            </Button>
          </Flex>
        </Grow>
      </Popup>
      <Nav>
        <Menu
          loading={loading}
          avatar={
            <Avatar
              size={64}
              url={customer?.avatar?.url_256 ?? customer?.avatar?.url}
            />
          }
          title={getName(customer?.name, customer?.surname)}
          data={[
            {
              icon: <User />,
              label: 'О клиенте',
              to: `/${username}/customer/${customerId}`,
            },
            {
              icon: <Clipboard size={24} />,
              label: 'Записи',
              to: `/${username}/customer/${customerId}/history`,
            },
            {
              icon: <Coupon />,
              label: 'Бонусные карты',
              to: `/${username}/customer/${customerId}/bonuses`,
            },
            {
              icon: <Certificate />,
              label: 'Сертификаты',
              to: `/${username}/customer/${customerId}/certificates`,
            },
          ].filter((_, index) => {
            if (index === 2) return edit_bonus_card_permission;
            if (index === 3) return edit_certificate_permission;
            return true;
          })}
        />
        {(loading || edit_customers_permission) && (
          <MenuAction
            loading={loading}
            data={[
              {
                icon: <Pencil />,
                label: 'Редактировать',
                onClick: () => {
                  navigate(`/${username}/edit-customer/${customerId}`);
                },
              },
              {
                icon: <Trash color={color.dangerPrimary} />,
                label: 'Удалить клиента',
                onClick: () => {
                  setVisible(true);
                },
              },
            ]}
          />
        )}
      </Nav>
      <Flex
        flex={1}
        direction="column"
        style={{
          gridColumnStart: 4,
          gridColumnEnd: pathname.includes('history') ? 13 : 10,
        }}>
        <Flex flex={1} direction="column">
          <Head>
            <ActionIcon icon={<ArrowLeft2v />} onClick={() => navigate(-1)} />
            <Text typography="title24">Профиль клиента</Text>
            {edit_customers_permission && (
              <HeadRight>
                <PopupWrapper>
                  <IconButton
                    size={40}
                    onClick={() => setModalSetting(true)}
                    style={{
                      borderRadius: 14,
                      backgroundColor: color.bgPrimary,
                    }}>
                    <DotsVertical />
                  </IconButton>
                  <Modal
                    style={{
                      right: 0,
                      left: 'auto',
                      top: 48,
                      paddingLeft: 0,
                      paddingRight: 0,
                    }}
                    visible={modalSetting}
                    onClose={() => setModalSetting(false)}>
                    <Flex direction="column" justifyContent="space-between">
                      <ButtonSelect
                        onClick={() => {
                          if (customer?.is_blocked) {
                            blockedCustomer({
                              variables: {
                                input: {
                                  id: customerId!,
                                  is_blocked: false,
                                },
                              },
                            }).then(() =>
                              showAlert(
                                'success',
                                'Клиент больше не черном списке',
                              ),
                            );
                          } else {
                            setVisibleBlackList(true);
                          }
                          setModalSetting(false);
                        }}>
                        <Text style={{pointerEvents: 'all', cursor: 'pointer'}}>
                          {customer?.is_blocked
                            ? 'Убрать из черного списка'
                            : 'Добавить в черный список'}
                        </Text>
                      </ButtonSelect>
                    </Flex>
                  </Modal>
                </PopupWrapper>
              </HeadRight>
            )}
          </Head>
          <Outlet />
        </Flex>
      </Flex>
    </ProfileContainer>
  );
};
