import {useQuery} from '@apollo/client';
import React, {useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {gql} from 'shared/__generated__';
import {Copy} from 'shared/icons/Copy';
import {WidgetBlock} from 'shared/icons/WidgetBlock';
import {WidgetFloatingButton} from 'shared/icons/WidgetFloatingButton';
import {WidgetPage} from 'shared/icons/WidgetPage';
import {useAppSelector} from 'shared/store';
import {showAlert} from 'shared/ui/Alert';
import {Button} from 'shared/ui/Button';
import {Content} from 'shared/ui/Content';
import {Divider} from 'shared/ui/Divider';
import {Flex} from 'shared/ui/Flex';
import {Head} from 'shared/ui/Head';
import {Layout} from 'shared/ui/Layout';
import {Select} from 'shared/ui/Select';
import {Switch} from 'shared/ui/Switch';
import {Text} from 'shared/ui/Text';
import {TextInput} from 'shared/ui/TextInput';
import styled from 'styled-components';
import {ColorCell} from './ui/ColorCell';
import {CopyLink} from './ui/CopyLink';
import {Header} from './ui/Header';
import {NavigateBack} from 'shared/ui/NavigateBack';
import {Support} from 'shared/icons/Support';
import {useColors} from 'shared/lib/hooks';
import {Seo} from 'shared/ui/Seo';

interface IWidget {
  show?: boolean;
  showHint?: boolean;
  launchByCustomButton?: boolean;
  position: 'right' | 'left';
  color: string;
  backgroundColor: string;
  theme: 'dark' | 'light';
  text?: string;
  css?: string;
}
type IWidgetType = 'floating_button' | 'embed_block' | 'embed_page';
const typeText: Record<IWidgetType, string> = {
  floating_button:
    'Скопируйте и вставьте код на свой сайт. По умолчанию, в правом нижнем углу появится кнопка онлайн-записи.',
  embed_block:
    'Виджет для встраивания это небольшой HTML-код, который можно скопировать и вставить на веб-сайт. Чтобы добавить встроенный блок на ваш сайт, просто поместите этот код в нужное место, где вы хотите его отобразить.',
  embed_page:
    'Данный код предназначен для полного отображения виджета онлайн-записи на веб-странице. Он может быть использован, например, если вы хотите, чтобы на странице по адресу https://example.com/booking был виден только виджет онлайн-записи.',
};
const BUTTON_POSITION_DATA: Array<{label: string; value: 'right' | 'left'}> = [
  {value: 'right', label: 'По умолчанию (справа)'},
  {value: 'left', label: 'Слева'},
];

const THEME_DATA: Array<{label: string; value: 'dark' | 'light'}> = [
  {value: 'light', label: 'По умолчанию (светлая)'},
  {value: 'dark', label: 'Темная'},
];

const APP = gql(`
  query GetAppWidget($id: String!, $companyId: String!) {
    company(id: $companyId) {
      id
      name
      username
    }
    getAppById(id: $id, company_id: $companyId) {
      id
      key
      name
      image {
        id
        url
      }
      description
      app_url(company_id: $companyId)
      darkImage {
        id
        url
      }
      is_connectable
      is_connected(company_id: $companyId)
      is_free
      is_out
      is_recommended
      short_description
      instruction
      instruction_link
    }
  }
`);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-column-start: 4;
  grid-column-end: 10;
  padding: 64px 0;
  gap: 8px;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const Article = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
`;
const WidgetColumn = styled.div`
  display: flex;
  max-width: 130px;
  flex-direction: column;
  pointer-events: all;
  text-align: center;
  cursor: pointer;
`;
const HTMLContainer = styled.div`
  display: flex;
  padding: 16px;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 20px;
  border: 1px solid ${({theme}) => theme.borderPrimary};
  background: ${({theme}) => theme.fillPrimary};
`;
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
`;

export const WidgetApp = () => {
  const navigate = useNavigate();
  const params = useParams();
  const colors = useColors();
  const title = useLocation().state;
  const companyId = useAppSelector(state => state.company.data!.id);
  const id = params.id!;
  const [widgetType, setWidgetType] = useState<IWidgetType>('floating_button');
  const [widget, setWidget] = useState<IWidget>({
    show: true,
    showHint: true,
    launchByCustomButton: false,
    position: 'right',
    color: '#FFFFFF',
    backgroundColor: '#6765F8',
    text: 'Онлайн-запись',
    css: '',
    theme: 'light',
  });
  const {data} = useQuery(APP, {
    variables: {
      companyId,
      id,
    },
  });
  const app = data?.getAppById;
  const companyUserName = data?.company?.username ?? companyId;

  const typeScript: Record<IWidgetType, string> = {
    floating_button: `<!-- Booky Appointment Widget: https://business.booky.co/ -->
   <script src="https://${companyUserName}.bky.link/widget.js"></script>
   <script>
   new BookyWidget({
       url: "https://${companyUserName}.bky.link?theme=${widget.theme}",${
         widget.launchByCustomButton && widget.css
           ? `
       ${"trigger: '" + widget.css + "',"}`
           : ''
       }
       ${
         widget.show
           ? `button: {
         position: '${widget.position}',
         showText: ${widget.showHint},
         backgroundColor: '${widget.backgroundColor}',
         color: '${widget.color}',
         text: '${widget.showHint ? widget.text : ''}'
       }`
           : `
       button: {
         showText: ${widget.showHint && widget.show},
         text: '${widget.showHint && widget.show ? widget.text : ''}'
       }`
       }
   })
</script>`,
    embed_block: `<iframe src="https://${companyUserName}.bky.link?theme=${widget.theme}" style="border: 0" frameborder="0" class="widget-frame"></iframe>
   <style>
       .widget-frame {
           display: block;
           height: 600px;
           width: 100%;
           max-width: 550px;
           margin: 25px auto;
           padding: 10px;
           border-radius: 15px;
           box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
       }
   </style>`,
    embed_page: `<iframe src="https://${companyUserName}.bky.link?theme=${widget.theme}" style="border: 0" frameborder="0" class="widget-full"></iframe>
   <style>
       .widget-full {
           display: block;
           width: 100%;
           height: 100vh;
       },
       body {
           margin: 0;
       }
   </style>`,
  };

  function handleTextChange(
    text: string,
    field: 'color' | 'backgroundColor' | 'text' | 'css',
  ) {
    const cleanedText = text.replace(/[^0-9a-fA-F]/g, '');
    const formattedText = cleanedText.startsWith('#')
      ? cleanedText
      : `#${cleanedText}`;
    setWidget(prev => ({
      ...prev,
      [field]: field !== 'text' && field !== 'css' ? formattedText : text,
    }));
  }

  return (
    <Layout columns={12}>
      <Seo title={title} />
      <Wrapper>
        <Head>
          <NavigateBack />
          <Text typography="title24">{title}</Text>
        </Head>
        <Content gap="24px">
          <Header app={app} />
          <Divider />
          <CopyLink link={app?.app_url} />
          <Flex gap={16} direction="column">
            <Text typography="text16Semibold">Тип интеграции на сайт</Text>
            <Article>
              <WidgetColumn onClick={() => setWidgetType('floating_button')}>
                <WidgetFloatingButton
                  selected={widgetType === 'floating_button'}
                />
                <Text
                  style={{
                    textAlign: 'center',
                    pointerEvents: 'all',
                    cursor: 'pointer',
                  }}
                  typography="footNote12Regular">
                  Плавающая кнопка
                </Text>
              </WidgetColumn>
              <WidgetColumn onClick={() => setWidgetType('embed_block')}>
                <WidgetBlock selected={widgetType === 'embed_block'} />
                <Text
                  style={{
                    textAlign: 'center',
                    pointerEvents: 'all',
                    cursor: 'pointer',
                  }}
                  typography="footNote12Regular">
                  Встроить блок
                </Text>
              </WidgetColumn>
              <WidgetColumn onClick={() => setWidgetType('embed_page')}>
                <WidgetPage selected={widgetType === 'embed_page'} />
                <Text
                  style={{
                    textAlign: 'center',
                    pointerEvents: 'all',
                    cursor: 'pointer',
                  }}
                  typography="footNote12Regular">
                  Встроить как страницу
                </Text>
              </WidgetColumn>
            </Article>
            <Text typography="text16Regular" color="textSecondary">
              {typeText[widgetType]}
            </Text>
          </Flex>
          <Divider />
          {widgetType === 'floating_button' && (
            <>
              <Row>
                <Text>Показать кнопку в углу</Text>
                <Switch
                  value={widget.show}
                  onChange={value => {
                    setWidget(prev => ({
                      ...prev,
                      launchByCustomButton: prev.show
                        ? true
                        : prev.launchByCustomButton,
                      show: value,
                    }));
                  }}
                />
              </Row>
              {widget.show && (
                <>
                  <Select
                    label="Позиция кнопки"
                    data={BUTTON_POSITION_DATA}
                    value={widget.position}
                    onChange={value =>
                      setWidget(prev => ({
                        ...prev,
                        position: value,
                      }))
                    }
                  />
                  <Flex gap={8} direction="column">
                    <ColorCell
                      label="Цвет кнопки"
                      value={widget.backgroundColor}
                      onChange={text =>
                        handleTextChange(text, 'backgroundColor')
                      }
                    />
                    <ColorCell
                      label="Цвет иконки"
                      value={widget.color}
                      onChange={text => handleTextChange(text, 'color')}
                    />
                  </Flex>
                  <Row>
                    <Text>Показать подсказку к кнопке</Text>
                    <Switch
                      value={widget.showHint}
                      onChange={value => {
                        setWidget(prev => ({
                          ...prev,
                          showHint: value,
                        }));
                      }}
                    />
                  </Row>
                  {widget.showHint && (
                    <TextInput
                      label="Текст подсказки"
                      value={widget.text}
                      onChange={text => handleTextChange(text, 'text')}
                    />
                  )}
                </>
              )}
              <Row>
                <Text>Запуск по пользовательской кнопке</Text>
                <Switch
                  value={widget.launchByCustomButton}
                  onChange={value => {
                    setWidget(prev => ({
                      ...prev,
                      launchByCustomButton: value,
                    }));
                  }}
                />
              </Row>
              {widget.launchByCustomButton && (
                <Flex gap={8} direction="column">
                  <TextInput
                    label="CSS класс"
                    value={widget.css}
                    onChange={text => handleTextChange(text, 'css')}
                  />
                  <Text
                    style={{marginTop: 8}}
                    typography="footNote12Regular"
                    color="textTertiary">
                    {
                      'Например, если HTML-код кнопки <a class=”booking”>Click</ a>, добавьте в поле booking'
                    }
                  </Text>
                </Flex>
              )}
            </>
          )}
          <Select
            label="Тема оформления виджета"
            value={widget.theme}
            data={THEME_DATA}
            onChange={value => {
              setWidget(prev => ({
                ...prev,
                theme: value,
              }));
            }}
          />
          <Flex gap={16} direction="column">
            <Text typography="text16Semibold">Ваш HTML код</Text>
            <HTMLContainer>
              <Text>{typeScript[widgetType]}</Text>
            </HTMLContainer>
          </Flex>
          <Button
            size="medium"
            variant="outline"
            rightIcon={<Copy size={20} />}
            onClick={() => {
              navigator.clipboard.writeText(typeScript[widgetType]).then(() => {
                showAlert('success', 'HTML код скорирован');
              });
            }}>
            Скопировать код
          </Button>
          {app?.description ? (
            <Flex gap={16} direction="column">
              <Text typography="text16Semibold">Описание</Text>
              <Text color="textSecondary">{app?.description}</Text>
            </Flex>
          ) : null}
          <Button
            variant="outline"
            size="large"
            style={{
              color: colors.textPrimary,
              backgroundColor: colors.fillPrimary,
              border: 'none',
            }}
            onClick={() => navigate('/support')}
            leftIcon={<Support color={colors.textPrimary} />}>
            Написать в чат
          </Button>
        </Content>
      </Wrapper>
    </Layout>
  );
};
