import React, {useState} from 'react';
import {styled} from 'styled-components';
import {OptionsType, Table, TableSortType} from 'shared/ui/Table';
import {gql} from 'shared/__generated__';
import {useAppSelector} from 'shared/store';
import {useQuery} from '@apollo/client';
import {
  PaymentMethodEnum,
  QueryGetStatisticsPaymentOperationsOrderByColumn,
  SortOrder,
  StatisticsDates,
  StatisticsPaymentOperationsSummary,
} from 'shared/__generated__/graphql';
import {Flex} from 'shared/ui/Flex';
import {
  FilterEmptyState,
  FilterResetButton,
  FilterSearch,
  FilterStatisticsEmployee,
  FilterStatisticsPaymentMethod,
  FilterStatisticsPeriod,
  FilterStatisticsSummary,
} from 'entities/statistics';
import dayjs from 'dayjs';
import {PaymentMethodsText, getPriceFormat} from 'shared/lib/utils';
import {Content} from 'shared/ui/Content';
import {PageHeader} from 'shared/ui/PageHeader';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
`;

const GET_STATISTICS_PAYMENT_OPERATIONS = gql(`
  query GetStatisticsPaymentOperations(
    $companyId: String!
    $date: StatisticsDates!
    $first: Int!
    $query: String
    $employeeId: String
    $paymentMethod: PaymentMethodEnum
    $summary: StatisticsPaymentOperationsSummary
    $period: StatisticsPeriod
    $page: Int
    $orderBy: [QueryGetStatisticsPaymentOperationsOrderByOrderByClause!]
  ) {
    getStatisticsPaymentOperations(
      company_id: $companyId
      date: $date
      first: $first
      query: $query
      employee_id: $employeeId
      payment_method: $paymentMethod
      summary: $summary
      period: $period
      page: $page
      orderBy: $orderBy
    ) {
      paginatorInfo {
        hasMorePages
        currentPage
        total
        count
        lastPage
      }
      data {
        booking_number
        created_at
        customer
        employee
        payment_method
        summary
      }
    }
  }
`);
const titles = [
  'Дата оплаты',
  'Номер записи',
  'Клиент',
  'Ответственный сотрудник',
  'Тип платежа',
  'Сумма',
];
const sortValues: QueryGetStatisticsPaymentOperationsOrderByColumn[] = [
  QueryGetStatisticsPaymentOperationsOrderByColumn.CreatedAt,
  QueryGetStatisticsPaymentOperationsOrderByColumn.BookingNumber,
  QueryGetStatisticsPaymentOperationsOrderByColumn.Customer,
  QueryGetStatisticsPaymentOperationsOrderByColumn.Employee,
  QueryGetStatisticsPaymentOperationsOrderByColumn.PaymentMethod,
  QueryGetStatisticsPaymentOperationsOrderByColumn.Summary,
];

export const FinancePaymentList = () => {
  const companyId = useAppSelector(state => state.company.data!.id);
  const [sort, setSort] = useState<TableSortType>({
    index: 0,
    order: SortOrder.Desc,
  });
  const [search, setSearch] = useState('');
  const [employeeId, setEmployeeId] = useState('');
  const [summary, setSummary] = useState<
    StatisticsPaymentOperationsSummary | undefined
  >();
  const [paymentMethod, setPaymentMethod] = useState<
    PaymentMethodEnum | undefined
  >();
  const [options, setOptions] = useState<OptionsType>({
    page: 1,
    first: 10,
    total: 0,
    count: 0,
    lastPage: 1,
  });
  const [period, setPeriod] = useState<StatisticsDates>(
    StatisticsDates.Alltime,
  );
  const {data, loading} = useQuery(GET_STATISTICS_PAYMENT_OPERATIONS, {
    variables: {
      companyId,
      employeeId,
      paymentMethod,
      summary,
      date: period,
      first: options.first,
      page: options.page,
      query: search,
      orderBy: [
        {
          order: sort.order,
          column: sortValues[sort.index],
        },
      ],
    },
    onCompleted: res =>
      setOptions(prev => ({
        ...prev,
        total: res.getStatisticsPaymentOperations?.paginatorInfo.total,
        count: res.getStatisticsPaymentOperations?.paginatorInfo.count,
        lastPage: res.getStatisticsPaymentOperations?.paginatorInfo.lastPage,
      })),
  });
  const statistics = data?.getStatisticsPaymentOperations?.data ?? [];
  const isEmpty = !statistics?.filter(item => item.created_at !== 'Итого')
    .length;
  return (
    <Wrapper>
      <PageHeader back title="Список платежных операций" />
      <Flex direction="row" gap={8}>
        <FilterSearch onChange={setSearch} value={search} />
        <FilterStatisticsPeriod {...{period, setPeriod}} />
        <FilterStatisticsEmployee {...{employeeId, setEmployeeId}} />
        <FilterStatisticsPaymentMethod {...{paymentMethod, setPaymentMethod}} />
        <FilterStatisticsSummary {...{summary, setSummary}} />
        {summary ||
        paymentMethod ||
        employeeId ||
        search ||
        period !== StatisticsDates.Alltime ? (
          <FilterResetButton
            onClick={() => {
              setSummary(undefined);
              setPaymentMethod(undefined);
              setEmployeeId('');
              setSearch('');
              setPeriod(StatisticsDates.Alltime);
            }}
          />
        ) : (
          <></>
        )}
      </Flex>
      {!loading && isEmpty ? (
        <FilterEmptyState />
      ) : (
        <Content>
          <Table
            options={options}
            setOptions={setOptions}
            titles={titles}
            sort={sort}
            results
            alignRightColumn={[1, 5]}
            columnNoWrap={[5]}
            onClickTitle={(index, order) => setSort({index, order})}
            rows={statistics.map((item, index) => {
              const paymentMethod =
                item.payment_method !== '-'
                  ? PaymentMethodsText[item.payment_method as PaymentMethodEnum]
                  : '-';
              return [
                dayjs(item.created_at).isValid()
                  ? dayjs(item.created_at).format('DD.MM.YYYY')
                  : item.created_at,
                (index === 0 ? '' : '#') + item.booking_number,
                item.customer,
                item.employee,
                paymentMethod,
                getPriceFormat(item.summary),
              ];
            })}
          />
        </Content>
      )}
    </Wrapper>
  );
};
