import React from 'react';
import {useAppSelector} from 'shared/store';

export const NoImage = ({size = 151}: {size?: number}) => {
  const theme = useAppSelector(state => state.theme.theme);

  if (theme === 'light') {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 151 151"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_11224_12892)">
          <path
            d="M109.554 114.577L42.1132 115.06C38.2204 115.077 35.0461 111.938 34.9939 108.07L34.4698 43.5361C34.448 39.642 37.582 36.4703 41.4486 36.4226L108.89 35.9401C112.783 35.9228 115.957 39.0615 116.009 42.9295L116.533 107.464C116.555 111.358 113.421 114.53 109.554 114.577Z"
            fill="url(#paint0_linear_11224_12892)"
          />
        </g>
        <path
          d="M105.741 104.945L46.4626 105.394C44.7858 105.409 43.4362 104.084 43.4194 102.407L42.9697 49.0645C42.9529 47.3867 44.2748 46.0377 45.9516 46.0228L105.23 45.574C106.906 45.5591 108.256 46.8833 108.273 48.5611L108.722 101.903C108.739 103.581 107.417 104.93 105.741 104.945Z"
          fill="#EAEEF9"
        />
        <defs>
          <filter
            id="filter0_d_11224_12892"
            x="12.4697"
            y="24.9399"
            width="126.063"
            height="123.12"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="11" />
            <feGaussianBlur stdDeviation="11" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_11224_12892"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_11224_12892"
              result="shape"
            />
          </filter>
          <linearGradient
            id="paint0_linear_11224_12892"
            x1="38.9923"
            y1="33.0978"
            x2="111.108"
            y2="116.936"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#FDFEFF" />
            <stop offset="0.9964" stopColor="#ECF0F5" />
          </linearGradient>
        </defs>
      </svg>
    );
  }
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 151 151"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_11224_12900)">
        <path
          d="M109.554 114.577L42.1132 115.06C38.2204 115.077 35.0461 111.938 34.9939 108.07L34.4698 43.5361C34.448 39.642 37.582 36.4703 41.4486 36.4226L108.89 35.9401C112.783 35.9228 115.957 39.0615 116.009 42.9295L116.533 107.464C116.555 111.358 113.421 114.53 109.554 114.577Z"
          fill="url(#paint0_linear_11224_12900)"
        />
      </g>
      <path
        d="M105.741 104.945L46.4626 105.394C44.7858 105.409 43.4362 104.084 43.4194 102.407L42.9697 49.0645C42.9529 47.3867 44.2748 46.0377 45.9516 46.0228L105.23 45.574C106.906 45.5591 108.256 46.8833 108.273 48.5611L108.722 101.903C108.739 103.581 107.417 104.93 105.741 104.945Z"
        fill="#2A2E37"
        fillOpacity="0.4"
      />
      <path
        d="M77.0948 84.9247L55.4641 85.0749L66.1868 72.7332L77.0948 84.9247Z"
        fill="#292D36"
      />
      <path
        d="M95.0335 84.7786L63.852 85.0003L79.2946 67.2772L95.0335 84.7786Z"
        fill="#A9AFC3"
      />
      <path
        d="M66.1674 67.305C67.8776 65.8342 68.0703 63.2538 66.5977 61.5417C65.1252 59.8295 62.5451 59.6339 60.8349 61.1048C59.1247 62.5757 58.932 65.156 60.4046 66.8682C61.8771 68.5803 64.4572 68.7759 66.1674 67.305Z"
        fill="#292D36"
      />
      <defs>
        <filter
          id="filter0_d_11224_12900"
          x="12.4697"
          y="24.9399"
          width="126.063"
          height="123.12"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="11" />
          <feGaussianBlur stdDeviation="11" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_11224_12900"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_11224_12900"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_11224_12900"
          x1="38.9923"
          y1="33.0978"
          x2="111.108"
          y2="116.936"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#5C6479" />
          <stop offset="0.9964" stopColor="#5C6479" />
        </linearGradient>
      </defs>
    </svg>
  );
};
