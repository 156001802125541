import React from 'react';
import {Skeleton} from 'shared/ui/Skeleton';
import {Content} from 'shared/ui/Content';

const COLUMN = 5;

const columns = Array.from(Array(COLUMN).keys());

export const CustomersSkeleton = () => {
  return (
    <Content>
      <Skeleton height={468} width={'100%'}>
        <circle r={8} cx={24} cy={28} />
        <rect width={58} height={14} rx={8} y={20} x={72} />
        <rect width={58} height={14} rx={8} y={20} x={391 + 24} />
        <rect width={58} height={14} rx={8} y={20} x={680 + 24} />
        <rect width={58} height={14} rx={8} y={20} x={968 + 24} />
        <rect width={58} height={14} rx={8} y={20} x={1257 + 24} />
        {columns.map((columnItem, index) => (
          <React.Fragment key={'column_' + columnItem}>
            <circle r={8} cx={24} cy={86 + 80 * index} />
            <circle r={20} cx={92} cy={86 + 80 * index} />
            <rect
              width={143}
              height={14}
              rx={8}
              x={103 + 24}
              y={`calc(78px + ${columnItem * 80}px)`}
            />
            <rect
              width={116}
              height={14}
              rx={8}
              x={391 + 24}
              y={`calc(78px + ${columnItem * 80}px)`}
            />
            <rect
              width={116}
              height={14}
              rx={8}
              x={680 + 24}
              y={`calc(78px + ${columnItem * 80}px)`}
            />
            <rect
              width={66}
              height={14}
              rx={8}
              x={968 + 24}
              y={`calc(78px + ${columnItem * 80}px)`}
            />
            <rect
              width={113}
              height={14}
              rx={8}
              x={1257 + 24}
              y={`calc(78px + ${columnItem * 80}px)`}
            />
          </React.Fragment>
        ))}
      </Skeleton>
    </Content>
  );
};
