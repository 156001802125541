import React from 'react';
export const Globus = ({
  size = 24,
  color = '#6765F8',
}: {
  size?: number;
  color?: string;
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 11.25C2.38353 6.07745 6.70174 2 11.9723 2C11.1414 2 10.4017 2.36421 9.7931 2.92113C9.18758 3.47525 8.67588 4.24878 8.262 5.16315C7.84568 6.08292 7.5223 7.15868 7.30342 8.32611C7.12797 9.26194 7.02119 10.2485 6.9856 11.25H2ZM2 12.75H6.9856C7.02119 13.7515 7.12797 14.7381 7.30342 15.6739C7.5223 16.8413 7.84568 17.9171 8.262 18.8368C8.67587 19.7512 9.18758 20.5247 9.7931 21.0789C10.4017 21.6358 11.1414 22 11.9723 22C6.70174 22 2.38353 17.9226 2 12.75Z"
        fill={color}
      />
      <path
        d="M11.9723 3.39535C11.6973 3.39535 11.342 3.51236 10.9289 3.89042C10.5127 4.27126 10.0961 4.86815 9.72801 5.68137C9.36236 6.4892 9.06546 7.46441 8.86114 8.55419C8.70023 9.41242 8.60041 10.3222 8.56538 11.25H15.3792C15.3442 10.3222 15.2444 9.41242 15.0835 8.5542C14.8791 7.46441 14.5822 6.48921 14.2166 5.68137C13.8485 4.86815 13.4319 4.27126 13.0157 3.89042C12.6026 3.51236 12.2473 3.39535 11.9723 3.39535Z"
        fill={color}
      />
      <path
        d="M8.86114 15.4458C9.06545 16.5356 9.36236 17.5108 9.72801 18.3186C10.0961 19.1319 10.5127 19.7287 10.9289 20.1096C11.342 20.4876 11.6973 20.6047 11.9723 20.6047C12.2473 20.6047 12.6026 20.4876 13.0157 20.1096C13.4319 19.7287 13.8485 19.1319 14.2166 18.3186C14.5822 17.5108 14.8791 16.5356 15.0835 15.4458C15.2444 14.5876 15.3442 13.6778 15.3792 12.75H8.56538C8.60041 13.6778 8.70023 14.5876 8.86114 15.4458Z"
        fill={color}
      />
      <path
        d="M11.9723 2C12.8032 2 13.5429 2.36421 14.1515 2.92113C14.757 3.47525 15.2687 4.24878 15.6826 5.16315C16.0989 6.08292 16.4223 7.15868 16.6412 8.32612C16.8166 9.26194 16.9234 10.2485 16.959 11.25H21.9446C21.5611 6.07745 17.2429 2 11.9723 2Z"
        fill={color}
      />
      <path
        d="M16.6412 15.6739C16.4223 16.8413 16.0989 17.9171 15.6826 18.8368C15.2687 19.7512 14.757 20.5247 14.1515 21.0789C13.5429 21.6358 12.8032 22 11.9723 22C17.2429 22 21.5611 17.9226 21.9446 12.75H16.959C16.9234 13.7515 16.8166 14.7381 16.6412 15.6739Z"
        fill={color}
      />
    </svg>
  );
};
