import React from 'react';
import {useColorScheme} from 'shared/lib/hooks';
import {EmptyContainer} from 'shared/ui/EmptyContainer';

export const EmptyBox = ({
  size = 400,
  mt = 58,
}: {
  size?: number;
  mt?: number;
}) => {
  const colorScheme = useColorScheme();
  return (
    <EmptyContainer size={size} mt={mt}>
      {colorScheme === 'light' ? (
        <svg
          viewBox="0 0 400 400"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M324.169 146.483C330.016 146.483 334.755 141.739 334.755 135.888C334.755 130.036 330.016 125.292 324.169 125.292C318.323 125.292 313.583 130.036 313.583 135.888C313.583 141.739 318.323 146.483 324.169 146.483Z"
            fill="#EAEEF9"
          />
          <path
            d="M42.9646 203.783C46.8111 203.783 49.9292 200.662 49.9292 196.812C49.9292 192.962 46.8111 189.841 42.9646 189.841C39.1182 189.841 36 192.962 36 196.812C36 200.662 39.1182 203.783 42.9646 203.783Z"
            fill="#EAEEF9"
          />
          <path
            d="M74.9793 329.105C78.8257 329.105 81.9439 325.984 81.9439 322.134C81.9439 318.284 78.8257 315.163 74.9793 315.163C71.1328 315.163 68.0146 318.284 68.0146 322.134C68.0146 325.984 71.1328 329.105 74.9793 329.105Z"
            fill="#EAEEF9"
          />
          <path
            d="M112.209 329.105L209.942 298.481V172.923L112.209 203.311V329.105Z"
            fill="#B7BFCD"
          />
          <path
            opacity="0.4"
            d="M307.674 329.105L209.941 298.481V172.923L307.674 203.311V329.105Z"
            fill="#6D7C9A"
          />
          <g opacity="0.13">
            <path
              d="M112.209 329.105L209.942 298.481V172.923L112.209 203.311V329.105Z"
              fill="url(#paint0_linear_17969_3300)"
            />
            <path
              d="M307.675 329.105L209.942 298.481V172.923L307.675 203.311V329.105Z"
              fill="url(#paint1_linear_17969_3300)"
            />
          </g>
          <path
            d="M209.942 359.966L112.209 329.342V203.783L209.942 234.407V359.966Z"
            fill="#EDF1F9"
          />
          <path
            d="M209.941 359.966L307.674 329.342V203.783L209.941 234.407V359.966Z"
            fill="#DAE1ED"
          />
          <path
            opacity="0.09"
            d="M187.798 337.822L112.209 329.342V203.783L209.942 234.407L187.798 337.822Z"
            fill="url(#paint2_linear_17969_3300)"
          />
          <path
            opacity="0.2"
            d="M232.085 315.679L307.674 329.342V203.783L209.941 234.407L232.085 315.679Z"
            fill="url(#paint3_linear_17969_3300)"
          />
          <path
            d="M209.941 172.923L163.991 138.53L64.625 171.981L112.209 203.312L209.941 172.923Z"
            fill="#DAE1ED"
          />
          <path
            d="M209.941 172.923L255.892 138.53L355.258 171.981L307.674 203.312L209.941 172.923Z"
            fill="#DAE1ED"
          />
          <path
            d="M112.209 203.783L209.941 234.407L161.192 265.502L64.625 233.465L112.209 203.783Z"
            fill="#EFF4FD"
          />
          <path
            d="M307.674 203.783L209.941 234.407L258.691 265.502L355.258 233.465L307.674 203.783Z"
            fill="#EDF1F9"
          />
          <path
            d="M237.211 52.3825C250.903 89.5406 254.984 97.7968 256.323 134.411C255.808 142.768 255.489 151.857 251.548 159.175C246.286 170.37 233.415 178.122 221.255 178.248C208.727 178.472 196.446 170.802 190.918 158.974C187.028 151.796 187.152 141.977 192.487 135.46C198.287 129.209 208.49 127.651 215.83 131.556C223.903 135.265 228.722 142.977 230.507 151.11C232.292 159.244 231.508 168.065 229.329 176.085C224.773 195.592 220.999 196.536 209.824 229.562"
            stroke="#ABB5CC"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeDasharray="4 4"
          />
          <path
            d="M253.871 32.7152C253.839 38.179 248.755 41.5246 242.307 39.78C235.518 38.6227 230.713 37.4299 230.403 32.5534C230.559 27.5523 236.016 25.5949 242.402 23.3885C250.06 20.3457 253.438 27.3759 253.871 32.7152Z"
            fill="#DAE2EB"
          />
          <path
            d="M210.004 49.4234C213.444 52.9653 220.698 53.9981 224.233 48.5879C228.109 42.5904 231.427 38.2303 227.863 34.2256C224.422 30.6836 221.073 33.0683 213.416 36.1111C207.277 39.243 205.974 45.5433 210.004 49.4234Z"
            fill="#DAE2EB"
          />
          <path
            d="M225.881 26.8216C229.012 25.487 232.763 26.4661 234.437 28.9935C235.15 29.7944 235.986 31.0581 236.234 31.9836C238.899 38.2129 238.061 44.3886 234.465 45.8478C230.528 47.8942 225.103 44.3877 223.027 38.4966C222.531 36.6456 222.283 35.7201 221.911 34.3319C221.509 30.9681 222.749 28.1562 225.881 26.8216C226.346 26.6971 225.881 26.8216 225.881 26.8216Z"
            fill="#989FB0"
          />
          <path
            d="M81.9457 127.099H75.8838V121.037H70.6879V127.099H64.626V132.468H70.6879V138.53H75.8838V132.468H81.9457V127.099Z"
            fill="url(#paint4_linear_17969_3300)"
          />
          <path
            d="M95.2601 279.188H89.1982V273.126H84.0023V279.188H77.9404V284.557H84.0023V290.619H89.1982V284.557H95.2601V279.188Z"
            fill="url(#paint5_linear_17969_3300)"
          />
          <path
            d="M360.255 279.533L352.838 275.788L356.583 268.371L350.225 265.161L346.481 272.578L339.064 268.834L335.747 275.403L343.164 279.147L339.419 286.564L345.777 289.774L349.521 282.357L356.938 286.102L360.255 279.533Z"
            fill="url(#paint6_linear_17969_3300)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_17969_3300"
              x1="179.09"
              y1="182.016"
              x2="165.759"
              y2="237.513"
              gradientUnits="userSpaceOnUse">
              <stop offset="0.00289017" stopColor="#606673" stopOpacity="0" />
              <stop offset="1" stopColor="#373C47" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_17969_3300"
              x1="179.09"
              y1="182.016"
              x2="165.759"
              y2="237.513"
              gradientUnits="userSpaceOnUse">
              <stop offset="0.00289017" stopColor="#606673" stopOpacity="0" />
              <stop offset="1" stopColor="#373C47" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_17969_3300"
              x1="134.12"
              y1="289.715"
              x2="157.599"
              y2="223.4"
              gradientUnits="userSpaceOnUse">
              <stop offset="0.00289017" stopColor="#6C80AA" stopOpacity="0" />
              <stop offset="1" stopColor="#5D6A86" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_17969_3300"
              x1="262.687"
              y1="307.829"
              x2="264.533"
              y2="223.677"
              gradientUnits="userSpaceOnUse">
              <stop offset="0.00289017" stopColor="#314F91" stopOpacity="0" />
              <stop offset="1" stopColor="#324264" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_17969_3300"
              x1="73.2872"
              y1="121.645"
              x2="73.2872"
              y2="131.463"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#B0BACC" />
              <stop offset="1" stopColor="#969EAE" />
            </linearGradient>
            <linearGradient
              id="paint5_linear_17969_3300"
              x1="86.6016"
              y1="273.734"
              x2="86.6016"
              y2="283.553"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#B0BACC" />
              <stop offset="1" stopColor="#969EAE" />
            </linearGradient>
            <linearGradient
              id="paint6_linear_17969_3300"
              x1="353.03"
              y1="267.511"
              x2="346.965"
              y2="279.524"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#B0BACC" />
              <stop offset="1" stopColor="#969EAE" />
            </linearGradient>
          </defs>
        </svg>
      ) : (
        <svg
          viewBox="0 0 400 400"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M324.17 146.484C330.017 146.484 334.756 141.74 334.756 135.888C334.756 130.036 330.017 125.292 324.17 125.292C318.324 125.292 313.584 130.036 313.584 135.888C313.584 141.74 318.324 146.484 324.17 146.484Z"
            fill="#2A2E37"
          />
          <path
            d="M42.9646 203.784C46.8111 203.784 49.9293 200.663 49.9293 196.813C49.9293 192.963 46.8111 189.842 42.9646 189.842C39.1182 189.842 36 192.963 36 196.813C36 200.663 39.1182 203.784 42.9646 203.784Z"
            fill="#2A2E37"
          />
          <path
            d="M74.9793 329.106C78.8257 329.106 81.9439 325.985 81.9439 322.135C81.9439 318.285 78.8257 315.164 74.9793 315.164C71.1328 315.164 68.0146 318.285 68.0146 322.135C68.0146 325.985 71.1328 329.106 74.9793 329.106Z"
            fill="#2A2E37"
          />
          <path
            d="M112.209 329.105L209.942 298.481V172.923L112.209 203.311V329.105Z"
            fill="#B7BFCD"
          />
          <path
            opacity="0.4"
            d="M307.675 329.105L209.941 298.481V172.923L307.675 203.311V329.105Z"
            fill="#6D7C9A"
          />
          <path
            d="M112.209 329.105L209.942 298.481V172.923L112.209 203.311V329.105Z"
            fill="#414758"
          />
          <path
            d="M307.675 329.105L209.942 298.481V172.923L307.675 203.311V329.105Z"
            fill="#414758"
          />
          <path
            d="M307.675 329.105L209.941 298.481V172.923L307.675 203.311V329.105Z"
            fill="#383D4B"
          />
          <path
            d="M209.942 359.966L112.209 329.342V203.784L209.942 234.408V359.966Z"
            fill="#5C6378"
          />
          <path
            d="M209.941 359.966L307.675 329.342V203.784L209.941 234.408V359.966Z"
            fill="#4D5464"
          />
          <path
            opacity="0.29"
            d="M187.799 337.823L112.209 329.342V203.784L209.942 234.408L187.799 337.823Z"
            fill="url(#paint0_linear_17969_3323)"
          />
          <path
            opacity="0.29"
            d="M232.085 315.679L307.675 329.342V203.784L209.941 234.408L232.085 315.679Z"
            fill="url(#paint1_linear_17969_3323)"
          />
          <path
            d="M209.942 172.924L163.991 138.531L64.625 171.982L112.209 203.312L209.942 172.924Z"
            fill="#5C6479"
          />
          <path
            d="M209.941 172.924L255.892 138.531L355.258 171.982L307.675 203.312L209.941 172.924Z"
            fill="#5C6479"
          />
          <path
            d="M112.209 203.784L209.942 234.408L161.192 265.503L64.625 233.465L112.209 203.784Z"
            fill="#5C6479"
          />
          <path
            d="M307.675 203.784L209.941 234.408L258.691 265.503L355.258 233.465L307.675 203.784Z"
            fill="#5C6479"
          />
          <path
            d="M237.211 52.383C250.903 89.5411 254.984 97.7973 256.323 134.411C255.808 142.769 255.489 151.857 251.549 159.175C246.286 170.37 233.415 178.123 221.255 178.249C208.727 178.473 196.446 170.802 190.918 158.975C187.028 151.796 187.152 141.978 192.487 135.461C198.287 129.21 208.49 127.652 215.83 131.557C223.904 135.266 228.723 142.978 230.508 151.111C232.293 159.244 231.508 168.066 229.33 176.086C224.773 195.593 220.999 196.537 209.824 229.563"
            stroke="#667089"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeDasharray="4 4"
          />
          <path
            d="M253.872 32.7157C253.84 38.1795 248.756 41.5251 242.308 39.7805C235.519 38.6232 230.714 37.4304 230.404 32.5539C230.56 27.5528 236.017 25.5954 242.403 23.389C250.061 20.3461 253.439 27.3764 253.872 32.7157Z"
            fill="#AFB7C9"
          />
          <path
            d="M210.004 49.4237C213.444 52.9656 220.698 53.9984 224.233 48.5882C228.109 42.5907 231.427 38.2305 227.863 34.2258C224.422 30.6838 221.073 33.0685 213.416 36.1113C207.277 39.2433 205.974 45.5435 210.004 49.4237Z"
            fill="#AFB7C9"
          />
          <path
            d="M225.882 26.8219C229.013 25.4873 232.764 26.4664 234.438 28.9938C235.151 29.7947 235.987 31.0584 236.235 31.9839C238.9 38.2132 238.063 44.3889 234.466 45.8481C230.529 47.8945 225.104 44.388 223.028 38.4969C222.532 36.6459 222.284 35.7204 221.912 34.3322C221.51 30.9684 222.75 28.1565 225.882 26.8219C226.347 26.6973 225.882 26.8219 225.882 26.8219Z"
            fill="#474E61"
          />
          <path
            d="M81.9457 127.1H75.8838V121.038H70.6879V127.1H64.626V132.469H70.6879V138.531H75.8838V132.469H81.9457V127.1Z"
            fill="#5C6479"
          />
          <path
            d="M95.2602 279.189H89.1983V273.127H84.0023V279.189H77.9404V284.558H84.0023V290.62H89.1983V284.558H95.2602V279.189Z"
            fill="#5C6479"
          />
          <path
            d="M360.256 279.533L352.839 275.789L356.584 268.372L350.226 265.162L346.482 272.579L339.065 268.834L335.748 275.404L343.165 279.148L339.42 286.565L345.778 289.775L349.522 282.358L356.939 286.103L360.256 279.533Z"
            fill="#5C6479"
          />
          <defs>
            <linearGradient
              id="paint0_linear_17969_3323"
              x1="134.12"
              y1="289.715"
              x2="157.6"
              y2="223.401"
              gradientUnits="userSpaceOnUse">
              <stop offset="0.00289017" stopColor="#292D36" stopOpacity="0" />
              <stop offset="1" stopColor="#2A2E37" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_17969_3323"
              x1="231.853"
              y1="284.278"
              x2="252.74"
              y2="221.3"
              gradientUnits="userSpaceOnUse">
              <stop offset="0.00289017" stopColor="#292D36" stopOpacity="0" />
              <stop offset="1" stopColor="#2A2E37" />
            </linearGradient>
          </defs>
        </svg>
      )}
    </EmptyContainer>
  );
};
