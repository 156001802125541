import {getPriceFormat} from 'shared/lib/utils';

export type WEEK =
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday'
  | 'sunday'
  | 'all';
interface IDayOfWeek {
  value: WEEK;
  label:
    | 'Понедельник'
    | 'Вторник'
    | 'Среда'
    | 'Четверг'
    | 'Пятница'
    | 'Суббота'
    | 'Воскресенье'
    | 'Все дни';
}

export const DAYS_WEEK: IDayOfWeek[] = [
  {value: 'all', label: 'Все дни'},
  {value: 'monday', label: 'Понедельник'},
  {value: 'tuesday', label: 'Вторник'},
  {value: 'wednesday', label: 'Среда'},
  {value: 'thursday', label: 'Четверг'},
  {value: 'friday', label: 'Пятница'},
  {value: 'saturday', label: 'Суббота'},
  {value: 'sunday', label: 'Воскресенье'},
];

export const initialDayjsWeekValues = DAYS_WEEK.map(item => item.value);

export const getWorkingDays = (prev: WEEK[], next: WEEK[]): WEEK[] => {
  const prevAll = prev.includes('all');
  const nextAll = next.includes('all');
  if (prevAll && !nextAll) {
    return [];
  }
  if (!prevAll && nextAll) {
    return initialDayjsWeekValues;
  }
  if (prevAll && nextAll && next.length === 7) {
    return next.filter(item => item !== 'all');
  }
  if (!prevAll && !nextAll && next.length === 7) {
    return [...next, 'all'];
  }
  return next;
};

export const getServicePriceOfType = (
  type: 'range' | 'fixed' | 'free' | 'not_fixed',
  price?: string | null,
  min_price?: string | null,
  max_price?: string | null,
) => {
  switch (type) {
    case 'fixed':
      return getPriceFormat(price);
    case 'not_fixed':
      return 'от ' + getPriceFormat(min_price);
    case 'range':
      return getPriceFormat(min_price) + '-' + getPriceFormat(max_price);
    default:
      return 'Бесплатно';
  }
};
