import React from 'react';

export const ArrowDown1v = ({
  color = '#6765F8',
  size = 24,
  style,
}: {
  size?: number;
  color?: string;
  style?: React.CSSProperties;
}) => {
  return (
    <svg
      style={style}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19 10L12 16L5 10"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
