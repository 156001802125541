import {useMutation, useQuery} from '@apollo/client';
import React, {useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {gql} from 'shared/__generated__';
import {useColors} from 'shared/lib/hooks';
import {Button} from 'shared/ui/Button';
import {Content} from 'shared/ui/Content';
import {Flex} from 'shared/ui/Flex';
import {Layout} from 'shared/ui/Layout';
import {Text} from 'shared/ui/Text';
import {TextArea} from 'shared/ui/TextArea';
import {TextInput} from 'shared/ui/TextInput';
import styled from 'styled-components';
import {EditCertificateSkeleton} from './ui/EditCertificateSkeleton';
import {useAppSelector} from 'shared/store';
import {showAlert} from 'shared/ui/Alert';
import {Error} from 'shared/ui/Error';
import {DeleteCertificate} from './ui/DeleteCertificate';
import {
  GET_CERTIFICATE_TEMPLATES,
  ModalIssueCertificate,
} from 'entities/loyalty';
import {NavigateBack} from 'shared/ui/NavigateBack';
import {Seo} from 'shared/ui/Seo';
import {Trash} from 'shared/icons/Trash';

const GET_CERTIFICATE = gql(`
query GetCertificateById($id: String!) {
    certificateTemplate(id: $id) {
      id
      name
      price
      balance
      description
      validity_months
      created_at
      certificates {
        id
      }
    }
  }
`);

const UPDATE_CERTIFICATE = gql(`
  mutation UpdateCertificateTemplate($input: UpdateCertificateTemplate!) {
    updateCertificateTemplate(input: $input) {
      id
    }
  }
`);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  grid-column-start: 4;
  grid-column-end: 10;
  margin: 64px 0 24px 0;
`;

export const EditCertificate = () => {
  const companyId = useAppSelector(state => state.company.data!.id);
  const navigate = useNavigate();
  const username = useAppSelector(state => state.company.data?.username);
  const colors = useColors();
  const params = useParams();
  const id = params.id!;
  const [name, setName] = useState('');
  const [price, setPrice] = useState('');
  const [balance, setBalance] = useState('');
  const [activeMonths, setActiveMonths] = useState('');
  const [description, setDescription] = useState('');
  const {
    data,
    loading,
    error: errorGetData,
  } = useQuery(GET_CERTIFICATE, {
    variables: {
      id,
    },
    onCompleted: resData => {
      setName(resData.certificateTemplate?.name ?? '');
      setPrice(resData.certificateTemplate?.price.toString() ?? '');
      setBalance(resData.certificateTemplate?.balance.toString() ?? '');
      setActiveMonths(
        resData.certificateTemplate?.validity_months?.toString() ?? '',
      );
      setDescription(resData.certificateTemplate?.description ?? '');
    },
  });
  const [updateCertificate, {loading: loadingUpdate}] = useMutation(
    UPDATE_CERTIFICATE,
    {
      refetchQueries: [
        {
          query: GET_CERTIFICATE,
          variables: {
            id,
          },
        },
        {
          query: GET_CERTIFICATE_TEMPLATES,
          variables: {
            companyId,
            page: 1,
            first: 40,
          },
        },
      ],
    },
  );
  const [error, setError] = useState<{
    name: string | null;
    price: string | null;
  }>({
    name: null,
    price: null,
  });
  const [modalDelete, setModalDelete] = useState(false);
  const [modalIssue, setModalIssue] = useState(false);
  return (
    <Layout>
      <Seo title="Редактирование сертификата" />
      <Wrapper>
        <Flex direction="row" gap={16} alignItems="center">
          <NavigateBack />
          <Text typography="title24">Редактировать сертификат</Text>
        </Flex>
        <Flex direction="column" gap={24}>
          {loading ? (
            <EditCertificateSkeleton />
          ) : errorGetData ? (
            <Error message={errorGetData.message} />
          ) : (
            <>
              <Content gap="24px">
                <Text typography="title18">Название сертификата</Text>
                <TextInput
                  value={name}
                  onChange={setName}
                  required
                  label="Название"
                  error={error.name}
                />
              </Content>
              <Content gap="24px">
                <Text typography="title18">Стоимость</Text>
                <Flex direction="row" gap={8}>
                  <TextInput
                    value={price}
                    onChange={setPrice}
                    mask="999999"
                    wrapperStyle={{width: '100%'}}
                    required
                    error={error.price}
                    rightElement={
                      <Text typography="subHead14Regular" color="textTertiary">
                        ₽
                      </Text>
                    }
                    label="Цена"
                  />
                  <TextInput
                    value={balance}
                    onChange={setBalance}
                    mask="999999"
                    wrapperStyle={{width: '100%'}}
                    required
                    rightElement={
                      <Text typography="subHead14Regular" color="textTertiary">
                        ₽
                      </Text>
                    }
                    label="Баланс"
                  />
                </Flex>
              </Content>
              <Content gap="24px">
                <Text typography="title18">Дополнительные настройки</Text>
                <Flex direction="column" gap={8}>
                  <TextInput
                    width="100%"
                    value={activeMonths}
                    onChange={setActiveMonths}
                    mask="99"
                    label="Срок действия"
                    rightElement={
                      <Text typography="subHead14Regular" color="textTertiary">
                        Мес
                      </Text>
                    }
                  />
                  <Text typography="subHead14Regular" color="textTertiary">
                    0 - не ограничен
                  </Text>
                </Flex>
                <TextArea
                  label="Описание"
                  value={description}
                  onChange={setDescription}
                  maxLength={1000}
                  size="large"
                />
                <Button
                  variant="outline"
                  typography="subHead14Medium"
                  onClick={() => {
                    navigate(`/${username}/certificate/${id}/issue`);
                  }}>
                  Выданные сертификаты{' '}
                  {data?.certificateTemplate?.certificates.length ?? 0} шт.
                </Button>
              </Content>
              <Flex direction="row" gap={16}>
                <Button
                  variant="outline"
                  typography="text16Semibold"
                  size="large"
                  style={{
                    flex: 1,
                    color: colors.dangerPrimary,
                    borderColor: colors.dangerPrimary,
                  }}
                  leftIcon={<Trash color={colors.dangerPrimary} />}
                  onClick={() => setModalDelete(true)}>
                  Удалить
                </Button>
                <Button
                  size="large"
                  typography="text16Semibold"
                  loading={loadingUpdate}
                  style={{flex: 1}}
                  onClick={() => {
                    if (name !== '' && price !== '') {
                      updateCertificate({
                        variables: {
                          input: {
                            balance: +balance,
                            company_id: companyId,
                            description: description,
                            id,
                            name,
                            price: +price,
                            validity_months: +activeMonths,
                          },
                        },
                      })
                        .then(() => {
                          navigate(-1);
                        })
                        .catch(e => {
                          showAlert('error', e.message);
                        });
                    } else {
                      setError({
                        name:
                          name === ''
                            ? 'Это поле обязательна к заполнению'
                            : null,
                        price:
                          price === ''
                            ? 'Это поле обязательна к заполнению'
                            : null,
                      });
                    }
                  }}>
                  Сохранить
                </Button>
              </Flex>
            </>
          )}
          <DeleteCertificate
            id={id}
            visible={modalDelete}
            onClose={() => setModalDelete(false)}
          />
          <ModalIssueCertificate
            id={id}
            visible={modalIssue}
            onClose={() => setModalIssue(false)}
          />
        </Flex>
      </Wrapper>
    </Layout>
  );
};
