import {useLazyQuery} from '@apollo/client';
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {gql} from 'shared/__generated__';
import {Search} from 'shared/icons/Search';
import {EmptyCustomers} from 'shared/illustration/EmptyCustomers';
import {useColors} from 'shared/lib/hooks';
import {getName, getPhoneMask} from 'shared/lib/utils';
import {useAppSelector} from 'shared/store';
import {Button} from 'shared/ui/Button';
import {CloseButton} from 'shared/ui/CloseButton';
import {Flex} from 'shared/ui/Flex';
import {Popup} from 'shared/ui/Popup';
import {Skeleton} from 'shared/ui/Skeleton';
import {Table} from 'shared/ui/Table';
import {Text} from 'shared/ui/Text';
import {TextInput} from 'shared/ui/TextInput';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 792px;
  max-height: calc(100vh - 96px);
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const GET_BONUS_ISSUE_CUSTOMERS = gql(`
query GetBonusIssueCustomers($companyId: String!, $first: Int!, $query: String, $page: Int) {
    customers(first: $first, query: $query, page: $page, company_id: $companyId) {
      paginatorInfo {
        currentPage
        count
      }
      data {
        id
        name
        surname
        phone
      }
    }
  }
`);

type CustomerItem = {
  id: string;
  name?: string | null;
  surname?: string | null;
  phone?: string | null;
};

type Props = {
  visible: boolean;
  onClose: () => void;
  onSelect: (values: string[]) => void;
  loading: boolean;
};

export const ModalClient = ({
  visible,
  onClose,
  onSelect,
  loading: loadingCreate,
}: Props) => {
  const colors = useColors();
  const [search, setSearch] = useState('');
  const companyId = useAppSelector(state => state.company.data!.id);
  const [select, setSelect] = useState<CustomerItem[]>([]);
  const [getCustomers, {data, loading}] = useLazyQuery(
    GET_BONUS_ISSUE_CUSTOMERS,
    {
      variables: {
        companyId,
        query: search === '' ? undefined : search,
        page: 1,
        first: 40,
      },
    },
  );

  useEffect(() => {
    if (visible) {
      getCustomers();
      setSelect([]);
    }
  }, [getCustomers, visible]);

  const customers = data?.customers?.data ?? [];

  const initialValueReduce: number[] = [];
  const selections = customers.reduce((acc, curItem, currentIndex) => {
    if (select.map(item => item.id).includes(curItem.id)) {
      const temp = acc;
      temp.push(currentIndex);
      return temp;
    } else {
      return acc;
    }
  }, initialValueReduce);

  return (
    <Popup visible={visible} onClose={onClose}>
      <Wrapper>
        <Flex
          direction="row"
          alignItems="center"
          justifyContent="space-between">
          <Text typography="title20">Клиенты</Text>
          <CloseButton
            onClose={() => {
              onClose();
            }}
          />
        </Flex>
        <TextInput
          value={search}
          width="348px"
          onChange={setSearch}
          variant="default"
          height={54}
          placeholder="Имя и телефон клиента"
          leftElement={<Search color={colors.textTertiary} />}
        />
        {loading ? (
          <ClientSkeleton />
        ) : customers.length === 0 ? (
          <EmptyState search={search} />
        ) : (
          <>
            <Flex
              flex={1}
              direction="column"
              style={{maxHeight: 412, overflow: 'auto'}}>
              <Table
                titles={['Имя', 'Телефон']}
                rows={customers.map(customer => [
                  getName(customer.name, customer.surname),
                  getPhoneMask(customer.phone),
                ])}
                onClick={index => {
                  if (
                    select.map(item => item.id).includes(customers[index].id)
                  ) {
                    setSelect(
                      select.filter(item => item.id !== customers[index].id),
                    );
                  } else {
                    setSelect([...select, customers[index]]);
                  }
                }}
                selections={selections}
                onSelectionChange={indexes => {
                  setSelect(
                    customers.filter((_, index) => indexes.includes(index)),
                  );
                }}
              />
            </Flex>
            <Footer>
              <Button
                style={{width: '160px'}}
                size="large"
                loading={loadingCreate}
                disabled={loadingCreate}
                onClick={() => {
                  onSelect(select.map(item => item.id));
                  setSelect([]);
                }}>
                Сохранить
              </Button>
              <Button
                style={{width: '160px'}}
                variant="light"
                size="large"
                onClick={onClose}>
                Отмена
              </Button>
              {select.length > 0 && (
                <Text
                  typography="text16Semibold"
                  color="mainPrimary"
                  style={{marginLeft: 32}}>
                  Выбрано {select.length}
                </Text>
              )}
            </Footer>
          </>
        )}
      </Wrapper>
    </Popup>
  );
};

const ClientSkeleton = () => {
  return (
    <Skeleton height={254}>
      <circle r={11} cx={29} cy={11} />
      <rect width={162} height={22} rx={11} ry={11} x={76} y={0} />
      <rect width={59} height={22} rx={11} ry={11} x={464} y={0} />
      {Array(4)
        .fill(0)
        .map((_, index) => (
          <React.Fragment key={'skeleton_rect_' + index}>
            <circle r={11} cx={29} cy={11 + 52 * (index + 1)} />
            <rect
              width={284}
              height={22}
              rx={11}
              ry={11}
              x={76}
              y={52 * (index + 1)}
            />
            <rect
              width={181}
              height={22}
              rx={11}
              ry={11}
              x={464}
              y={52 * (index + 1)}
            />
          </React.Fragment>
        ))}
    </Skeleton>
  );
};

const EmptyState = ({search}: {search: string}) => {
  const navigate = useNavigate();
  const username = useAppSelector(state => state.company.data?.username);
  return (
    <Flex
      direction="column"
      alignItems="center"
      justifyContent="center"
      gap={8}>
      <EmptyCustomers size={300} mt={0} />
      {search.length > 0 ? (
        <Text typography="text16Regular" color="textTertiary" align="center">
          По данному запросу клиентов не найдено. Проверьте <br /> данные и
          попробуйте еще раз
        </Text>
      ) : (
        <>
          <Text
            typography="text16Regular"
            color="textTertiary"
            style={{textAlign: 'center'}}>
            У вас пока нет клиентов. Добавьте <br /> клиентов и начните работу
          </Text>
          <Button
            style={{marginTop: 8, width: 360}}
            size="large"
            onClick={() => navigate(`/${username}/add-customer`)}>
            Добавить
          </Button>
        </>
      )}
    </Flex>
  );
};
