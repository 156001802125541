import React, {useState} from 'react';
import {Content} from 'shared/ui/Content';
import {Slide} from 'shared/ui/Slide';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';
import {Divider} from 'shared/ui/Divider';
import {Button} from 'shared/ui/Button';
import {Flex} from 'shared/ui/Flex';
import {TariffPlan, getTariff} from 'shared/lib/utils';
import {TariffSelect} from './TariffSelect';
import {useNavigate} from 'react-router-dom';
import {TooltipRow} from 'entities/tariff';
import {Popup} from 'shared/ui/Popup';
import {CloseButton} from 'shared/ui/CloseButton';

type TooltipRow = {title: string; tooltip: string | null};
const SelectColumn = styled.div`
  margin-bottom: 8px;
`;
const PopupContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 538px;
  gap: 16px;
`;
const CancelButton = styled(Button)`
  flex: 1;
  border-color: ${({theme}) => theme.borderPrimary};
`;
const rows: TooltipRow[] = [
  {
    title: 'Все модули Booky',
    tooltip:
      'Полный комплект функций для управления вашим бизнесом, от онлайн-записи до учета рабочего времени сотрудников',
  },
  {
    title: 'Все интеграции Booky',
    tooltip:
      'Совместимость с популярными инструментами и приложениями. Список всегда пополняется',
  },
  {
    title: 'Управление с любого устройства',
    tooltip:
      'Удобство контроля вашего бизнеса везде и всегда. Полное управление с мобильного приложения',
  },
  {
    title: 'Техническая поддержка 24/7',
    tooltip: 'Поддержка круглосуточно без выходных.',
  },
  {
    title: 'Консультации по API',
    tooltip: 'Помощь в настройке и интеграции API.',
  },
];

export const ProSlide = ({
  tariffPlans,
  tariffPlan,
  countActiveEmployees,
  unavailable = false,
  companyId,
}: {
  companyId: string;
  unavailable?: boolean;
  countActiveEmployees: number;
  tariffPlans: TariffPlan[];
  tariffPlan: TariffPlan | null | undefined;
}) => {
  const navigate = useNavigate();
  const [selectedPlan, setSelectedPlan] = useState<TariffPlan | undefined>();
  const [visible, setVisible] = useState(false);
  const formattedPlans = tariffPlans.map(plan => ({
    label: getTariff(plan),
    value: plan,
  }));
  const price = selectedPlan ? selectedPlan.price / 12 : 320;
  return (
    <Slide>
      <Content>
        <Text typography="title18">Сотрудники</Text>
        <SelectColumn>
          <TariffSelect
            data={formattedPlans}
            value={selectedPlan}
            onChange={setSelectedPlan}
          />
          <Text mt={8} color="textTertiary" typography="footNote12Regular">
            Доступно мест для сотрудника
          </Text>
        </SelectColumn>
        <Text mt={8} typography="title18">
          Что входит:
        </Text>
        <Flex gap={8} direction="column">
          {rows.map(row => (
            <TooltipRow key={row.title} {...row} />
          ))}
        </Flex>
        <Divider style={{margin: '8px 0'}} />
        <div>
          <Flex alignItems="center" gap={5} justifyContent="center">
            <Text
              style={{width: 'max-content'}}
              typography="title24"
              color="mainPrimary">
              {price + ' ₽'}
            </Text>
            <Text style={{width: 'max-content'}}>/ мес</Text>
          </Flex>
          <Text
            mt={8}
            align="center"
            typography="footNote12Regular"
            color="textTertiary">
            Цены указаны за месяц при годовой оплате, без скидки
          </Text>
        </div>
      </Content>
      <Button
        disabled={tariffPlans.length === 0}
        onClick={() => {
          if (!unavailable && tariffPlan) {
            setVisible(true);
            return;
          }
          const state = selectedPlan ?? tariffPlans[0];
          if (tariffPlan && state.slots < countActiveEmployees) {
            navigate('/change-tariff', {state: {plan: state, companyId}});
            return;
          }
          navigate('/payment-tariff', {state: {plan: state, companyId}});
        }}
        size="large"
        typography="text16Semibold">
        {unavailable && tariffPlan?.slots === selectedPlan?.slots
          ? 'Возобновить подписку'
          : tariffPlan
          ? 'Изменить тариф'
          : 'Перейти к оформлению'}
      </Button>
      <Text color="textTertiary" align="center" typography="subHead14Regular">
        Тарифный план будет продлеваться автоматически, в зависимости от
        выбранного типа оплаты. Подписку можно отменить в любое время
      </Text>
      <Popup visible={visible} onClose={() => setVisible(false)}>
        <PopupContainer>
          <Flex justifyContent="space-between" alignItems="center">
            <Text typography="title24">Смена тарифа</Text>
            <CloseButton onClose={() => setVisible(false)} />
          </Flex>
          <Text>
            У вас есть действующий тариф. Вы уверены, что хотите сменить его?
            После подтверждения вам начислятся баллы за неиспользованные дни
          </Text>
          <Flex gap={8}>
            <CancelButton
              variant="outline"
              onClick={() => setVisible(false)}
              size="large">
              Отмена
            </CancelButton>
            <Button
              style={{flex: 1}}
              onClick={() => {
                const state = selectedPlan ?? tariffPlans[0];
                if (tariffPlan && state.slots < countActiveEmployees) {
                  navigate('/change-tariff', {state: {plan: state, companyId}});
                  return;
                }
                navigate('/payment-tariff', {state: {plan: state, companyId}});
              }}
              size="large">
              Сменить тариф
            </Button>
          </Flex>
        </PopupContainer>
      </Popup>
    </Slide>
  );
};
