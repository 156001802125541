import {useQuery} from '@apollo/client';
import React, {useState} from 'react';
import {useNavigate} from 'react-router';
import {gql} from 'shared/__generated__';
import {useEmployeePermission} from 'shared/lib/hooks';
import {setThemeSlice, useAppDispatch, useAppSelector} from 'shared/store';
import {showAlert} from 'shared/ui/Alert';
import {CloseButton} from 'shared/ui/CloseButton';
import {Content} from 'shared/ui/Content';
import {Divider} from 'shared/ui/Divider';
import {Error} from 'shared/ui/Error';
import {Flex} from 'shared/ui/Flex';
import {Layout} from 'shared/ui/Layout';
import {Loading} from 'shared/ui/Loading';
import {NavLink} from 'shared/ui/NavLink';
import {Popup} from 'shared/ui/Popup';
import {RadioButton} from 'shared/ui/RadioButton';
import {Seo} from 'shared/ui/Seo';
import {TableViewRow} from 'shared/ui/TableViewRow';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';

const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const EMPLOYEE = gql(`
query GetEmployee($companyId: String!, $id: String!) {
  ...Permissions
  employee(id: $id) {
    id
    name
    surname
    invite_status
    role
  }
}
`);

const isProd = !process.env.REACT_APP_DOMAIN?.includes('dev');
export const SettingRoot = () => {
  const employeeId = useAppSelector(state => state.company.data!.employeeId);
  const companyId = useAppSelector(state => state.company.data!.id);
  const navigate = useNavigate();
  const username = useAppSelector(state => state.company.data?.username);
  const {
    data: employeeData,
    loading,
    error,
  } = useQuery(EMPLOYEE, {
    variables: {id: employeeId, companyId},
  });
  const isOwner = employeeData?.employee?.role === 'owner';
  const {
    notation_permission,
    edit_company_permission,
    webhook_permission,
    message_sample_permission,
  } = useEmployeePermission(employeeData);
  const theme = useAppSelector(state => state.theme.theme);
  const dispatch = useAppDispatch();
  const [modal, setModal] = useState(false);
  const optionTheme = [
    {
      label: 'Светлая тема',
      value: 'light',
      onPress: () => {
        dispatch(setThemeSlice('light'));
        setModal(false);
        showAlert('success', 'Coxранено');
      },
    },
    {
      label: 'Темная тема',
      value: 'dark',
      onPress: () => {
        dispatch(setThemeSlice('dark'));
        setModal(false);
        showAlert('success', 'Coxранено');
      },
    },
  ];

  if (error) {
    return <Error message={error.message} />;
  }
  if (loading) {
    return (
      <CenteredContainer>
        <Loading />
      </CenteredContainer>
    );
  }

  return (
    <Layout variant="sidebar">
      <Seo title="Настройки" />
      <Flex
        direction="column"
        style={{
          marginTop: 24,
          gap: 24,
          gridColumnStart: 2,
          gridColumnEnd: 10,
          paddingBottom: 24,
        }}>
        <Text style={{padding: '12px 0'}} typography="title24">
          Настройки
        </Text>
        <Flex direction="row" gap={24}>
          <Flex direction="column" flex={1} gap={24}>
            {(edit_company_permission ||
              notation_permission ||
              isOwner ||
              webhook_permission) && (
              <Content gap="32px">
                <Text typography="title18">Настройки организации</Text>
                <Flex direction="column" gap={16}>
                  {edit_company_permission && (
                    <NavLink
                      label="Редактировать организацию"
                      description={
                        'Об организации, контакты, дополнительная информация'
                      }
                      onClick={() => {
                        navigate(`/${username}/company`);
                      }}
                    />
                  )}

                  {notation_permission && (
                    <>
                      {edit_company_permission && <Divider />}
                      <NavLink
                        label="Настройка онлайн-записи"
                        description={
                          'Управляйте настройками онлайн-записи в этом разделе'
                        }
                        onClick={() => {
                          navigate(`/${username}/journal-setting`);
                        }}
                      />
                    </>
                  )}

                  {isOwner && (
                    <>
                      {(notation_permission || edit_company_permission) && (
                        <Divider />
                      )}
                      <NavLink
                        label="Права доступа"
                        description={
                          'Управляйте настройками доступа и разрешений ваших сотрудников'
                        }
                        onClick={() => {
                          navigate(`/${username}/permissions`);
                        }}
                      />
                    </>
                  )}
                  {edit_company_permission && (
                    <>
                      {(isOwner ||
                        notation_permission ||
                        edit_company_permission) && <Divider />}
                      <NavLink
                        label="Изменить домен"
                        description="Изменить домен вашей организации"
                        onClick={() => {
                          navigate(`/${username}/domen-change`);
                        }}
                      />
                    </>
                  )}
                  {!isProd && webhook_permission && (
                    <>
                      {(edit_company_permission ||
                        isOwner ||
                        notation_permission) && <Divider />}
                      <NavLink
                        label={'Webhooks'}
                        description={
                          'Позволяет получать информацию о бронированиях в реальном времени.'
                        }
                        onClick={() => {
                          navigate(`/${username}/setting/webhooks`);
                        }}
                      />
                    </>
                  )}
                </Flex>
              </Content>
            )}
            <Content gap="24px">
              <Text typography="title18">Данные</Text>
              <Flex gap={16} direction="column">
                <NavLink
                  label={'Источники записей'}
                  description={'Добавляйте источники записей'}
                  onClick={() => {
                    navigate(`/${username}/booking-source`);
                  }}
                />
                {message_sample_permission && (
                  <>
                    <Divider />
                    <NavLink
                      label={'Шаблоны рассылок'}
                      description={
                        'Управляйте настройками шаблонов рассылок для клиентов'
                      }
                      onClick={() => {
                        navigate(`/${username}/setting/message-samples`);
                      }}
                    />
                  </>
                )}
              </Flex>
            </Content>
          </Flex>
          <Flex gap={24} direction="column" flex={1}>
            <Content gap="32px">
              <Text typography="title18" color="textPrimary">
                Мой внутренний профиль
              </Text>
              <NavLink
                label="Настройка уведомлений"
                description={
                  'Какие уведомления вы хотите получать по категориям'
                }
                onClick={() => {
                  navigate(`/${username}/notification-settings`);
                }}
              />
            </Content>
            <Content gap="32px">
              <Text typography="title18" color="textPrimary">
                Системные настройки
              </Text>
              <Flex gap={16} direction="column">
                <NavLink
                  label="Тема оформления"
                  description={'Управляйте темой оформления, светлая и темная'}
                  onClick={() => {
                    setModal(true);
                  }}
                />
                <Divider />
                <NavLink
                  label="О продукте"
                  description={
                    'Ознакомьтесь с основными правилами и политике организации'
                  }
                  onClick={() => {
                    navigate('/about-app');
                  }}
                />
              </Flex>
            </Content>
          </Flex>
        </Flex>
      </Flex>
      <Popup
        style={{width: 442, alignItems: 'initial'}}
        visible={modal}
        onClose={() => {
          setModal(false);
        }}>
        <Flex direction="column" gap={24}>
          <Flex flex={1} justifyContent="space-between" alignItems="center">
            <Text typography="title20" color="textPrimary">
              Тема оформления
            </Text>
            <CloseButton
              onClose={() => {
                setModal(false);
              }}
            />
          </Flex>
          <Flex gap={16}>
            {optionTheme.map(item => {
              return (
                <TableViewRow
                  onClick={() => {
                    item.onPress();
                  }}
                  style={{gap: 10}}
                  pointer
                  key={item.value}
                  title={item.label}
                  leftElement={() => (
                    <RadioButton
                      style={{flex: 0}}
                      selected={item.value === theme}
                    />
                  )}
                />
              );
            })}
          </Flex>
        </Flex>
      </Popup>
    </Layout>
  );
};
