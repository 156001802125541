import React from 'react';
import {TextInput} from 'shared/ui/TextInput';
import {Text} from 'shared/ui/Text';
import {useState} from 'react';

import {useMutation} from '@apollo/client';
import {useLocation, useNavigate} from 'react-router-dom';
import {Button} from 'shared/ui/Button';
import {ensureError} from 'shared/lib/utils';
import {gql} from 'shared/__generated__';
import {Content} from 'shared/ui/Content';
import {Flex} from 'shared/ui/Flex';
import {TableViewRow} from 'shared/ui/TableViewRow';
import {RadioButton} from 'shared/ui/RadioButton';
import {showAlert} from 'shared/ui/Alert';
import {NavigateBack} from 'shared/ui/NavigateBack';
import {Seo} from 'shared/ui/Seo';
import {useCookies} from 'react-cookie';

const UPDATE = gql(`
  mutation SignUp($input: UpdateMe!) {
    updateMe(input: $input) {
      id
      phone
      surname
      name
      email
      temp_email
      created_at
      email_notification_enabled
      payment_method
      updated_at
      has_password
      birthdate
      gender
      avatar {
        id
        type
        url
      }
    }
  }
`);

export function SignUp() {
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [gender, setGender] = useState<string | null>(null);
  const [cookies] = useCookies([
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_content',
    'utm_term',
  ]);
  const cookieValue = {
    utm_source: cookies.utm_source,
    utm_medium: cookies.utm_medium,
    utm_campaign: cookies.utm_campaign,
    utm_content: cookies.utm_content,
    utm_term: cookies.utm_term,
  };
  const optionTheme = [
    {
      label: 'Мужской',
      value: 'M',
    },
    {
      label: 'Женский',
      value: 'F',
    },
  ];

  const id = useLocation().state;
  const [updateMe, {loading}] = useMutation(UPDATE, {
    variables: {
      input: {
        id: id.id,
        name,
        surname,
        temp_email: email,
        gender: gender,
        ...(Object.values(cookieValue).some(val => val !== undefined) && {
          utm: {
            utm_source: cookieValue.utm_source,
            utm_campaign: cookieValue.utm_campaign,
            utm_content: cookieValue.utm_content,
            utm_medium: cookieValue.utm_medium,
            utm_term: cookieValue.utm_term,
          },
        }),
      },
    },
  });
  const navigate = useNavigate();
  return (
    <Content gap="24px">
      <Seo title="Регистрация" />
      <Flex alignItems="center" gap={16}>
        <NavigateBack />
        <Text typography="title20">Регистрация</Text>
      </Flex>

      <Text color="textTertiary">Давайте знакомиться!</Text>
      <Flex direction="column" gap={32}>
        <Flex direction="column" gap={16}>
          <TextInput value={name} onChange={setName} label="Имя" required />
          <TextInput value={surname} onChange={setSurname} label="Фамилия" />
          <TextInput
            value={email}
            onChange={setEmail}
            label="Электронная почта"
            required
          />
        </Flex>
        <Flex direction="column" gap={8}>
          <Text typography="subHead14Regular" color="textTertiary">
            Пол
          </Text>
          <Flex gap={8}>
            {optionTheme.map(item => {
              return (
                <TableViewRow
                  pointer={true}
                  style={{gap: 10}}
                  key={item.value}
                  title={item.label}
                  onClick={() => {
                    setGender(item.value);
                  }}
                  leftElement={() => (
                    <RadioButton
                      style={{flex: 0}}
                      selected={item.value === gender}
                    />
                  )}
                />
              );
            })}
          </Flex>
        </Flex>
        <Button
          size="large"
          onClick={async () => {
            try {
              await updateMe();
              navigate('/my-companies');
            } catch (err) {
              const error = ensureError(err);
              showAlert('error', error.message);
            }
          }}
          loading={loading}
          disabled={name.length === 0 || email.length === 0 || loading}>
          Создать профиль
        </Button>
      </Flex>
    </Content>
  );
}
