import React from 'react';
import {Text} from 'shared/ui/Text';
import {useNavigate} from 'react-router-dom';
import {Content} from 'shared/ui/Content';
import {TutorialHeader} from './ui/TutorialHeader';
import {TutoriaCard} from './ui/TutorialCard';
import {CaseBold} from 'shared/icons/CaseBold';
import {UserDoubleBold} from 'shared/icons/UserDoubleBold';
import {CalendarBold} from 'shared/icons/CalendarBold';
import {CosmeticBold} from 'shared/icons/CosmeticBold';
import {TutoriaScale} from './ui/TutorialScale';
import {useQuery} from '@apollo/client';
import {useAppSelector} from 'shared/store';
import {Flex} from 'shared/ui/Flex';
import {TUTORIALCHECK} from 'entities/tutorial';
import styled from 'styled-components';
import {AppStore} from 'shared/icons/AppStore';
import {GooglePlay} from 'shared/icons/GooglePlay';

const Wrapper = styled.button`
  display: flex;
  justify-content: center;
  padding: none;
  margin: 0px;
  padding: 0px;
  border-radius: 14px;
  border: 2px solid ${({theme}) => theme.borderPrimary};
  background: transparent;
  pointer-events: all;
  cursor: pointer;
`;
const URL = {
  appStore:
    'https://apps.apple.com/ru/app/booky-%D0%BE%D0%BD%D0%BB%D0%B0%D0%B9%D0%BD-%D0%B7%D0%B0%D0%BF%D0%B8%D1%81%D1%8C-%D0%BA%D0%BB%D0%B8%D0%B5%D0%BD%D1%82%D0%BE%D0%B2/id6447231466',
  googlePlay: 'https://play.google.com/store/apps/details?id=co.booky.business',
};

export function Tutorial() {
  const navigate = useNavigate();
  const username = useAppSelector(state => state.company.data?.username);
  const companyId = useAppSelector(state => state.company.data!.id);
  const {data} = useQuery(TUTORIALCHECK, {
    variables: {companyId: companyId},
  });
  const rules = data?.moderationsCheck;
  const employeeAdded = rules?.map(
    item => item?.is_employee_with_specialization_added,
  )[0];
  const companyInfo = rules?.map(item => item?.is_required_items_filled)[0];
  const scheduleAdded = rules?.map(item => item?.is_employee_added)[0];
  const serviceAdded = rules?.map(item => item?.is_service_added)[0];
  const combinedObject = {
    employeeAdded,
    companyInfo,
    scheduleAdded,
    serviceAdded,
  };
  const check = Object.values(combinedObject);
  const result = check.filter(item => item === true).length;
  const percent =
    (check.filter(item => item === true).length / check.length) * 100;
  return (
    <Flex direction="column" flex={1} style={{height: '100%'}}>
      <TutorialHeader />
      <Content style={{display: 'flex', flex: 1, margin: 24, gap: 40}}>
        <Flex direction="column" gap={24}>
          <Text typography="title20" color="textPrimary">
            Настройки учетной записи
          </Text>
          <Text typography="text18" color="textSecondary">
            Пройдите быструю настройку своей организации для начала работы
          </Text>
        </Flex>
        <TutoriaScale
          result={result}
          totalChecks={check.length}
          completePercent={percent}
        />
        <Flex direction="row" gap={24}>
          <TutoriaCard
            title="Сведения об организации"
            description="Добавьте информацию об организации. Задача закроется, если написать описание"
            btnText="Добавить информацию"
            icon={<CaseBold size={40} />}
            complited={companyInfo}
            onPress={() => {
              navigate(`/${username}/company`);
            }}
          />
          <TutoriaCard
            title="Добавьте услуги"
            description="Выберите услуги, которые вы оказываете"
            btnText="Выбрать услуги"
            icon={<CosmeticBold size={40} />}
            complited={serviceAdded}
            onPress={() => {
              navigate(`/${username}/services`);
            }}
          />
          <TutoriaCard
            title="Добавьте сотрудника"
            description="Добавьте хотя бы одного сотрудника со специализацией. Если работаете один, добавьте себе специализацию"
            btnText="Добавить информацию"
            icon={<UserDoubleBold size={40} />}
            complited={employeeAdded}
            onPress={() => {
              navigate(`/${username}/employees`);
            }}
          />
          <TutoriaCard
            title="Настройте график"
            description="Создайте график для каждого сотрудника"
            btnText="Создать график"
            icon={<CalendarBold size={40} />}
            complited={scheduleAdded}
            onPress={() => {
              navigate(`/${username}/schedule`);
            }}
          />
        </Flex>
        <Flex direction="column" gap={24}>
          <Text typography="title20">Скачайте мобильное приложение</Text>
          <Flex style={{alignSelf: 'flex-start', gap: 24}}>
            <Wrapper onClick={() => window.open(URL['appStore'], '_blank')}>
              <AppStore width={265} height={80} />
            </Wrapper>
            <Wrapper onClick={() => window.open(URL['googlePlay'], '_blank')}>
              <GooglePlay width={265} height={80} />
            </Wrapper>
          </Flex>
        </Flex>
      </Content>
    </Flex>
  );
}
