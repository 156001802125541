import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Button} from 'shared/ui/Button';
import {Text} from 'shared/ui/Text';
import {Grow} from 'shared/ui/Grow';
import {EmptyCustomers} from 'shared/illustration/EmptyCustomers';
import {useAppSelector} from 'shared/store';
import {EmptyCustomersSearch} from 'shared/illustration/EmptyCustomersSearch';

export const EmptyState = ({filtered}: {filtered?: boolean}) => {
  const navigate = useNavigate();
  const username = useAppSelector(state => state.company.data?.username);
  return (
    <Grow>
      {filtered ? (
        <EmptyCustomersSearch size={300} />
      ) : (
        <EmptyCustomers size={300} />
      )}
      {!filtered && (
        <Text typography="title20" style={{alignSelf: 'center', marginTop: 16}}>
          У вас пока нет сотрудников
        </Text>
      )}
      <Text
        typography="text16Regular"
        color="textTertiary"
        style={{
          textAlign: 'center',
          alignSelf: 'center',
          maxWidth: 500,
          marginTop: 16,
        }}>
        {filtered
          ? 'По данному запросу ничего не найдено. Проверьте \n данные и попробуйте еще раз'
          : 'У вас нет сотрудников.'}
      </Text>
      {!filtered && (
        <>
          <Button
            style={{alignSelf: 'center', marginTop: 40}}
            size="large"
            onClick={() => navigate(`/${username}/add-employee`)}>
            Добавить сотрудника
          </Button>
        </>
      )}
    </Grow>
  );
};
