import React, {RefObject, createRef, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  position: relative;
  padding: 8px;
  height: 64px;
  background-color: ${({theme}) => theme.fillPrimary};
  border-radius: 18px;
  border: 1px solid ${({theme}) => theme.borderPrimary};
`;
const Tab = styled.div<{active: boolean}>`
  align-items: center;
  padding: 12px 20px;
  border-radius: 12px;
  pointer-events: all;
  cursor: pointer;
  font-family: 'Inter';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  z-index: 2;
  color: ${({theme, active}) => (active ? theme.white : theme.textPrimary)};
  transition: color 0.3s ease;
`;
const ActiveTab = styled.div<{
  width: number;
  left: number;
}>`
  display: flex;
  position: absolute;
  border-radius: 12px;
  pointer-events: all;
  cursor: pointer;
  top: 8px;
  left: ${({left}) => left}px;
  background-color: ${({theme}) => theme.mainPrimary};
  height: 48px;
  width: ${({width}) => width}px;
  transition: all 0.3s ease;
`;

const getLeftPosition = (
  refs: RefObject<HTMLDivElement>[],
  activeIndex: number,
) => {
  if (activeIndex === 0) return 8;
  let sum = 0;
  refs.forEach(
    (item, index) =>
      (sum += index >= activeIndex ? 0 : item.current?.offsetWidth ?? 53),
  );
  return 8 + sum;
};

export const TabsControl = ({
  labels,
  onClick,
  activeIndex,
  defaultWidth = 51,
}: {
  activeIndex: number;
  defaultWidth?: number;
  labels: {label: string; value: string}[];
  onClick: (index: number) => void;
}) => {
  const newRefs: RefObject<HTMLDivElement>[] = labels.map(() =>
    createRef<HTMLDivElement>(),
  );
  const firstLoading = useRef(true);
  const [activePosition, setActivePosition] = useState({
    width: defaultWidth,
    left: 8,
  });
  useEffect(() => {
    if (!firstLoading.current) {
      if (window.performance && performance.navigation.type === 1) {
        const timer = setTimeout(() => {
          const width =
            newRefs[activeIndex].current?.offsetWidth ?? defaultWidth;
          const left = getLeftPosition(newRefs, activeIndex);
          setActivePosition({width, left});
          clearTimeout(timer);
        }, 250);
        return;
      }
      const width = newRefs[activeIndex].current?.offsetWidth ?? defaultWidth;
      const left = getLeftPosition(newRefs, activeIndex);
      setActivePosition({width, left});
    } else {
      firstLoading.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex]);

  return (
    <Container>
      <ActiveTab width={activePosition.width} left={activePosition.left} />
      {labels.map((item, index) => (
        <Tab
          key={index + '_tab'}
          active={index === activeIndex}
          ref={newRefs[index]}
          onClick={() => index !== activeIndex && onClick(index)}>
          {item.label}
        </Tab>
      ))}
    </Container>
  );
};
