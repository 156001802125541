import React from 'react';
import styled from 'styled-components';
import {Flex} from 'shared/ui/Flex';
import {device} from 'shared/device';
import {Button} from 'shared/ui/Button';
import {TextInput} from 'shared/ui/TextInput';
import {Plus} from 'shared/icons/Plus';
import {useNavigate} from 'react-router-dom';
import {IconButton} from 'shared/ui/IconButton';
import {useColors, useResize} from 'shared/lib/hooks';
import {Search as SearchIcon} from 'shared/icons/Search';
import {useAppSelector} from 'shared/store';

type Props = {
  search?: string;
  page?: number;
  onChangeText?: (value: string) => void;
  onChangePage?: (value: number) => void;
};

const Header = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  @media ${device.mobile} {
    margin: 24px 16px 0px;
  }
`;
const Search = styled(TextInput)`
  display: flex;
  width: 320px;
  height: 56px;
  pointer-events: all;
  @media ${device.mobile} {
    width: calc(100vw - 128px);
  }
`;
const AddButton = styled(IconButton)`
  background-color: ${({theme}) => theme.mainPrimary};
  position: absolute;
  top: 16px;
  right: 16px;
`;

export const Setting = ({search, onChangeText}: Props) => {
  const navigate = useNavigate();
  const username = useAppSelector(state => state.company.data?.username);
  const color = useColors();
  const {isMobile} = useResize();

  return (
    <Header direction="row" style={{justifyContent: 'space-between'}}>
      <Flex gap={8}>
        <Search
          height={54}
          variant="default"
          onChange={onChangeText}
          value={search}
          backgroundColor={color.bgPrimary}
          placeholder="Поиск"
          leftElement={<SearchIcon color={color.textTertiary} />}
          borderRadius={14}
        />
      </Flex>
      <Flex gap={8}>
        {isMobile ? (
          <AddButton
            size={40}
            onClick={() => navigate(`/${username}/add-discount`)}>
            <Plus />
          </AddButton>
        ) : (
          <Button
            size="medium"
            style={{
              paddingLeft: 32,
              paddingRight: 32,
            }}
            onClick={() => navigate(`/${username}/add-discount`)}
            variant="filled"
            leftIcon={<Plus size={20} />}>
            Добавить акцию
          </Button>
        )}
      </Flex>
    </Header>
  );
};
