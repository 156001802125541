import {useMutation, useQuery} from '@apollo/client';
import {GET_BONUSES, SelectServices} from 'entities/loyalty';
import React, {useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {gql} from 'shared/__generated__';
import {CircleClose} from 'shared/icons/CircleClose';
import {useColors} from 'shared/lib/hooks';
import {useAppSelector} from 'shared/store';
import {showAlert} from 'shared/ui/Alert';
import {Button} from 'shared/ui/Button';
import {Content} from 'shared/ui/Content';
import {Flex} from 'shared/ui/Flex';
import {IconButton} from 'shared/ui/IconButton';
import {Layout} from 'shared/ui/Layout';
import {MultiSelectChips} from 'shared/ui/MultiSelectChips';
import {Switch} from 'shared/ui/Switch';
import {Text} from 'shared/ui/Text';
import {TextArea} from 'shared/ui/TextArea';
import {TextInput} from 'shared/ui/TextInput';
import styled from 'styled-components';
import {DeleteBonus} from './ui/DeleteBonus';
import {EditBonusSkeleton} from './ui/EditBonusSkeleton';
import {ModalIssueBonus} from 'entities/loyalty';
import {Error} from 'shared/ui/Error';
import {NavigateBack} from 'shared/ui/NavigateBack';
import {Seo} from 'shared/ui/Seo';
import {Trash} from 'shared/icons/Trash';
import {EmptyBlockedState} from 'shared/illustration/EmptyBlockedState';

const GET_BONUS = gql(`
query BonusCardTemplate($id: String!, $companyId: String) {
    bonusCardTemplate(id: $id) {
      id
      active_months
      is_active
      name
      payment_limit
      bonusCards {
        id
        customer {
          id
          name
          surname
          phone
        }
        balance
      }
      bonusSizes {
        id
        min_amount
        size
      }
      services {
        id
        name
      }
      paymentMethods {
        id
        title
      }
      description
      is_auto_assigned
      is_rechargeable
    }
  company(id: $companyId) {
    paymentMethods {
      id
      title
    }
    id
  }
}
`);

const UPDATE_BONUS_TEMPLATE = gql(`
mutation UpdateBonusCardTemplate($input: UpdateBonusCardTemplate!) {
    updateBonusCardTemplate(input: $input) {
      id
    }
  }
`);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-column-start: 4;
  grid-column-end: 10;
  gap: 24px;
  padding: 0 0 24px 0;
  position: relative;
`;

const ButtonsDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  pointer-events: all;
`;

export const EditBonus = () => {
  const companyId = useAppSelector(state => state.company.data!.id);
  const navigate = useNavigate();
  const username = useAppSelector(state => state.company.data?.username);
  const colors = useColors();
  const params = useParams();
  const id = params.id!;
  const [name, setName] = useState('');
  const [bonusSizes, setBonusSizes] = useState<
    {
      id?: string;
      min_amount: string;
      size: string;
    }[]
  >([]);
  const [services, setServices] = useState<{label: string; value: string}[]>(
    [],
  );
  const [selectPayments, setSelectPayments] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);
  const [paymentLimit, setPaymentLimit] = useState('100');
  const [activeMonths, setActiveMonths] = useState('');
  const [description, setDescription] = useState('');
  const [autoAssigned, setAutoAssigned] = useState(false);
  const [isRechargeable, setIsRechargeable] = useState(false);
  const [customerCount, setCustomerCount] = useState(0);
  const {
    data,
    loading,
    error: errorQuery,
  } = useQuery(GET_BONUS, {
    variables: {
      id,
      companyId,
    },
    onCompleted: resData => {
      setName(resData.bonusCardTemplate?.name ?? '');
      setBonusSizes(
        resData.bonusCardTemplate?.bonusSizes.map(bonusSize => ({
          min_amount: bonusSize.min_amount?.toString() ?? '',
          size: bonusSize.size.toString(),
          id: bonusSize.id,
        })) ?? [],
      );
      setServices(
        resData.bonusCardTemplate?.services.map(service => ({
          label: service.name,
          value: service.id,
        })) ?? [],
      );
      setSelectPayments(
        resData.bonusCardTemplate?.paymentMethods.map(method => ({
          label: method.title,
          value: method.id,
        })) ?? [],
      );
      setPaymentLimit(
        resData.bonusCardTemplate?.payment_limit.toString() ?? '',
      );
      setActiveMonths(
        resData.bonusCardTemplate?.active_months?.toString() ?? '',
      );
      setDescription(resData.bonusCardTemplate?.description ?? '');
      setAutoAssigned(resData.bonusCardTemplate?.is_auto_assigned ?? false);
      setIsRechargeable(resData.bonusCardTemplate?.is_rechargeable ?? false);
      setCustomerCount(resData.bonusCardTemplate?.bonusCards.length ?? 0);
    },
  });
  const [updateBonus, {loading: loadingUpdate}] = useMutation(
    UPDATE_BONUS_TEMPLATE,
    {
      refetchQueries: [
        {
          query: GET_BONUS,
          variables: {
            id,
            companyId,
          },
        },
        {
          query: GET_BONUSES,
          variables: {
            page: 1,
            first: 40,
            companyId,
          },
        },
      ],
    },
  );
  const [error, setError] = useState<{
    title: string | null;
    paymentLimit: string | null;
  }>({
    title: null,
    paymentLimit: null,
  });
  const paymentMethods =
    data?.company?.paymentMethods.map(item => ({
      label: item.title,
      value: item.id,
    })) ?? [];
  const [modalDelete, setModalDelete] = useState(false);
  const [modalIssue, setModalIssue] = useState(false);

  return (
    <Layout>
      <Seo title="Редактирование бонусной карты" />
      <Wrapper>
        <Flex
          alignItems="center"
          gap={16}
          style={{
            backgroundColor: colors.bgSecondary,
            padding: '64px 0 0 0',
            marginBottom: 8,
          }}>
          <NavigateBack />
          <Text typography="title24">Редактирование бонусной карты</Text>
        </Flex>
        {loading ? (
          <EditBonusSkeleton />
        ) : errorQuery ? (
          <Error message={errorQuery.message} />
        ) : (
          <>
            <Content gap="24px">
              <Text typography="title18">Название карты</Text>
              <TextInput
                value={name}
                onChange={value => {
                  setName(value);
                  setError({...error, title: null});
                }}
                label="Название"
                error={error.title}
              />
            </Content>
            <Content gap="24px">
              <Text typography="title18">Размер бонуса</Text>
              <Flex direction="column" gap={16}>
                {bonusSizes.map((bonusSize, index) => (
                  <BonusSizeDiv key={'bonus_size_' + index}>
                    <TextInput
                      wrapperStyle={{width: '100%'}}
                      variant="default"
                      value={bonusSize.min_amount}
                      mask="999999"
                      onChange={text => {
                        setBonusSizes(
                          bonusSizes.map((item2, index2) => {
                            if (index2 === index) {
                              return {...item2, min_amount: text};
                            } else {
                              return item2;
                            }
                          }),
                        );
                      }}
                      leftElement={
                        <Text
                          typography="subHead14Regular"
                          color="textTertiary">
                          От
                        </Text>
                      }
                      rightElement={
                        <Text
                          typography="subHead14Regular"
                          color="textTertiary">
                          ₽
                        </Text>
                      }
                    />
                    <TextInput
                      wrapperStyle={{
                        width: '100%',
                      }}
                      variant="default"
                      mask="99"
                      value={bonusSize.size}
                      onChange={text => {
                        if (+text <= 100) {
                          setBonusSizes(
                            bonusSizes.map((item2, index2) => {
                              if (index2 === index) {
                                return {...item2, size: text};
                              } else {
                                return item2;
                              }
                            }),
                          );
                        }
                      }}
                      rightElement={
                        <Text
                          typography="subHead14Regular"
                          color="textTertiary">
                          %
                        </Text>
                      }
                    />
                    <IconButton
                      size={24}
                      style={{
                        padding: 0,
                        backgroundColor: 'transparent',
                        borderColor: 'transparent',
                      }}
                      onClick={() => {
                        setBonusSizes(
                          bonusSizes.filter((_, index2) => index !== index2),
                        );
                      }}>
                      <CircleClose />
                    </IconButton>
                  </BonusSizeDiv>
                ))}
                {bonusSizes.length < 5 && (
                  <Text
                    typography="subHead14Medium"
                    color="mainPrimary"
                    style={{pointerEvents: 'all'}}
                    onClick={() => {
                      setBonusSizes([
                        ...bonusSizes,
                        {min_amount: '', size: ''},
                      ]);
                    }}>
                    + Размер бонуса
                  </Text>
                )}
              </Flex>
            </Content>
            <Content gap="24px">
              <Text typography="title18">Исключения по услугам</Text>
              <MultiSelectChips
                label="Исключения по услугам"
                values={services}
                modal={(visible, setVisible) => (
                  <SelectServices
                    label="Услуги"
                    visible={visible}
                    values={services}
                    onChange={values => {
                      setServices(values);
                      setVisible(false);
                    }}
                    onClose={() => setVisible(false)}
                  />
                )}
                onDelete={id =>
                  setServices(
                    services.filter(selectItem => selectItem.value !== id),
                  )
                }
              />
            </Content>
            <Content gap="24px">
              <Text typography="title18">Исключения по методам оплаты</Text>
              <MultiSelectChips
                style={{position: 'relative'}}
                data={paymentMethods}
                label="Исключения по методам оплаты"
                values={selectPayments}
                onDelete={id => {
                  setSelectPayments(
                    selectPayments.filter(
                      selectItem => selectItem.value !== id,
                    ),
                  );
                }}
                onChange={values => setSelectPayments(values)}
                ListEmptyComponent={
                  <Flex
                    direction="column"
                    alignItems="center"
                    gap={8}
                    justifyContent="center"
                    style={{marginBottom: 76}}>
                    <EmptyBlockedState size={300} />
                    <Text
                      align="center"
                      typography="text16Regular"
                      color="textTertiary">
                      Вы пока не выбрали методы оплаты для своей организации.
                      <br />
                      Добавьте хотя бы один метод оплаты
                    </Text>
                  </Flex>
                }
              />
              <TextInput
                value={paymentLimit}
                onChange={value => {
                  if (+value <= 100) {
                    setPaymentLimit(value);
                    setError({...error, paymentLimit: null});
                  }
                }}
                label="Ограничение платы бонусами"
                required
                mask="99"
                error={error.paymentLimit}
                rightElement={
                  <Text typography="subHead14Regular" color="textTertiary">
                    %
                  </Text>
                }
              />
              <Text
                typography="subHead14Regular"
                color="textTertiary"
                style={{transform: 'translateY(-16px)'}}>
                Определенный процент стоимости записи клиент может оплатить
                бонусами
              </Text>
            </Content>
            <Content gap="24px">
              <Text typography="title18">Дополнительные настройки</Text>
              <Flex direction="column" gap={8} style={{pointerEvents: 'all'}}>
                <TextInput
                  value={activeMonths}
                  label="Срок действия"
                  mask="99"
                  onChange={setActiveMonths}
                  rightElement={
                    <Text typography="subHead14Regular" color="textTertiary">
                      Мес
                    </Text>
                  }
                />
                <Text typography="subHead14Regular" color="textTertiary">
                  0 - не ограничен
                </Text>
              </Flex>
              <TextArea
                value={description}
                onChange={setDescription}
                label="Описание"
                size="large"
                maxLength={1000}
              />
              <Flex
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                style={{height: 48}}>
                <Text>Автоприсвоение бонусных карт</Text>
                <Switch value={autoAssigned} onChange={setAutoAssigned} />
              </Flex>
              <Flex
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                style={{height: 48}}>
                <Text>Возможность пополнения бонусного счета</Text>
                <Switch value={isRechargeable} onChange={setIsRechargeable} />
              </Flex>
              <Button
                variant="outline"
                typography="subHead14Medium"
                style={{color: colors.mainPrimary, pointerEvents: 'all'}}
                onClick={() => {
                  navigate(`/${username}/bonus/${id}/issue`);
                }}>
                Выданные карты {customerCount} шт.
              </Button>
            </Content>
            <ButtonsDiv>
              <Button
                typography="text16Semibold"
                variant="outline"
                disabled={modalDelete}
                size="large"
                style={{
                  color: colors.dangerPrimary,
                  borderColor: colors.dangerPrimary,
                  flex: 1,
                }}
                leftIcon={<Trash color={colors.dangerPrimary} />}
                onClick={() => setModalDelete(true)}>
                Удалить
              </Button>
              <Button
                size="large"
                typography="text16Semibold"
                loading={loadingUpdate}
                disabled={loadingUpdate}
                style={{flex: 1}}
                onClick={() => {
                  if (name !== '' && paymentLimit !== '') {
                    updateBonus({
                      variables: {
                        input: {
                          active_months:
                            +activeMonths === 0 ? undefined : +activeMonths,
                          id,
                          bonus_sizes: bonusSizes.map(bonusSize => ({
                            min_amount: +bonusSize.min_amount,
                            size: +bonusSize.size,
                          })),
                          description: description,
                          is_auto_assigned: autoAssigned,
                          is_rechargeable: isRechargeable,
                          name,
                          payment_limit: +paymentLimit,
                          payment_methods:
                            selectPayments.length > 0
                              ? selectPayments.map(
                                  paymentMethod => paymentMethod.label,
                                )
                              : [],
                          services:
                            services.length > 0
                              ? services.map(service => service.value)
                              : undefined,
                        },
                      },
                    })
                      .then(() => navigate(-1))
                      .catch(e => {
                        showAlert('error', e.message);
                      });
                  } else {
                    if (name === '') {
                      setError({
                        ...error,
                        title: 'Это поле обязательна к заполнению',
                      });
                    }
                    if (paymentLimit === '') {
                      setError({
                        ...error,
                        paymentLimit: 'Это поле обязательна к заполнению',
                      });
                    }
                  }
                }}>
                Сохранить
              </Button>
            </ButtonsDiv>
            <DeleteBonus
              visible={modalDelete}
              id={id}
              onClose={() => setModalDelete(false)}
            />
            <ModalIssueBonus
              visible={modalIssue}
              onClose={() => setModalIssue(false)}
              id={id}
            />
          </>
        )}
      </Wrapper>
    </Layout>
  );
};

const BonusSizeDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  pointer-events: all;
`;
