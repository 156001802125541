import React, {useState} from 'react';
import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {Color, CompanyTag} from 'shared/__generated__/graphql';
import {SortableItem} from './SortableItem';
import {TagEditModal} from './TagEditModal';
import {
  restrictToParentElement,
  restrictToVerticalAxis,
} from '@dnd-kit/modifiers';

type TagType = Pick<CompanyTag, 'id' | 'name' | 'order'> & {
  color?: Pick<Color, 'id' | 'rgb'> | null;
};

type Props = {
  data: Array<TagType>;
  onDragEnd: (emploees: TagType[]) => void;
  colors: Array<Pick<Color, 'id' | 'rgb'>>;
};

export const TagsDragDrop = ({data: tags, onDragEnd, colors}: Props) => {
  const [tag, setTag] = useState<TagType | null>(null);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  function handleDragEnd(event: DragEndEvent) {
    const {active, over} = event;

    if (active.id !== over?.id) {
      const ids = tags.map(item => item.id);
      const activeIndex = ids.indexOf(`${active!.id}`);
      const overIndex = ids.indexOf(`${over!.id}`);
      onDragEnd(arrayMove(tags, activeIndex, overIndex));
    }
  }

  return (
    <>
      {tag && (
        <TagEditModal
          colors={colors}
          tag={tag}
          visible={!!tag}
          onClose={() => setTag(null)}
        />
      )}
      <DndContext
        modifiers={[restrictToVerticalAxis, restrictToParentElement]}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        sensors={sensors}>
        <SortableContext items={tags} strategy={verticalListSortingStrategy}>
          {tags.map(item => (
            <SortableItem
              key={item.id}
              tag={item}
              onClick={() => {
                setTag(item);
              }}
            />
          ))}
        </SortableContext>
      </DndContext>
    </>
  );
};
