import React from 'react';
import {Content} from 'shared/ui/Content';
import {Skeleton} from 'shared/ui/Skeleton';

export const SkeletonPayment = () => {
  return (
    <Content style={{height: 456}}>
      <Skeleton width={'100%'}>
        <rect
          x={0}
          y={0}
          width={'calc((100% - 48px) / 720 * 320)'}
          height={28}
          rx={12}
          ry={12}
        />
        <rect
          x={'calc(100% - 96px)'}
          y={0}
          width={'calc((100% - 48px) / 720 * 104)'}
          height={28}
          rx={12}
          ry={12}
        />
        <rect
          x={0}
          y={36}
          width={'calc((100% - 48px) / 720 * 152)'}
          height={20}
          rx={12}
          ry={12}
        />
        <rect
          x={'calc(100% - 64px)'}
          y={36}
          width={'calc((100% - 48px) / 720 * 64)'}
          height={20}
          rx={12}
          ry={12}
        />
        <rect
          x={0}
          y={136}
          width={'calc((100% - 48px) / 720 * 152)'}
          height={20}
          rx={12}
          ry={12}
        />
        <rect
          x={168}
          y={136}
          width={'calc((100% - 48px) / 720 * 152)'}
          height={20}
          rx={12}
          ry={12}
        />
        <rect
          x={'calc(100% - 48px)'}
          y={136}
          width={'calc((100% - 48px) / 720 * 48)'}
          height={20}
          rx={12}
          ry={12}
        />
        <rect
          x={0}
          y={192}
          width={'calc(((100%) - 48px) / 720 * 152)'}
          height={20}
          rx={12}
          ry={12}
        />
        <rect
          x={168}
          y={192}
          width={'calc((100% - 48px) / 720 * 152)'}
          height={20}
          rx={12}
          ry={12}
        />
        <rect
          x={'calc(100% - 48px)'}
          y={192}
          width={'calc((100% - 48px) / 720 * 48)'}
          height={20}
          rx={12}
          ry={12}
        />
        <rect
          x={0}
          y={248}
          width={'calc(((100%) - 48px) / 720 * 152)'}
          height={20}
          rx={12}
          ry={12}
        />
        <rect
          x={168}
          y={248}
          width={'calc((100% - 48px) / 720 * 152)'}
          height={20}
          rx={12}
          ry={12}
        />
        <rect
          x={'calc(100% - 48px)'}
          y={248}
          width={'calc((100% - 48px) / 720 * 48)'}
          height={20}
          rx={12}
          ry={12}
        />
        <rect
          x={0}
          y={304}
          width={'calc(((100%) - 48px) / 720 * 152)'}
          height={20}
          rx={12}
          ry={12}
        />
        <rect
          x={0}
          y={360}
          width={'calc(((100%) - 48px) / 720 * 152)'}
          height={20}
          rx={12}
          ry={12}
        />
      </Skeleton>
    </Content>
  );
};
