import React from 'react';
import styled from 'styled-components';
import {Flex} from 'shared/ui/Flex';
import {Text} from 'shared/ui/Text';
import {CustomerCategory} from 'shared/__generated__/graphql';

const SegmentBox = styled.button<{active?: boolean}>`
  display: flex;
  padding: 16px;
  justify-content: space-between;
  background: none;
  flex: 1;
  background-color: ${({theme}) => theme.bgPrimary};
  border: none;
  outline: ${({active, theme}) =>
    active ? '2px solid ' + theme.mainPrimary : 'none'};
  border-radius: 14px;
  height: 124px;
  pointer-events: all;
  cursor: pointer;
  &:hover {
    background-color: ${({theme}) => theme.bgTertiary};
    transition: 0.15s;
  }
`;

const segmentCards: Record<
  CustomerCategory,
  {label: string; placeholder: string; emoji: JSX.Element}
> = {
  regular: {
    label: 'Постоянные',
    placeholder: 'Две и более записи за 3 мес.',
    emoji: <Text style={{fontSize: 36}}>{'😄'}</Text>,
  },
  inactive: {
    label: 'Спящие',
    placeholder: 'Не записывались более 3 мес.',
    emoji: <Text style={{fontSize: 36}}>{'😴'}</Text>,
  },
  lost: {
    label: 'Пропавшие',
    placeholder: 'Не записывались более 6 мес.',
    emoji: <Text style={{fontSize: 36}}>{'😯'}</Text>,
  },
  prospects: {
    label: 'Не посещали',
    placeholder: 'Нет записей данных клиентов',
    emoji: <Text style={{fontSize: 36}}>{'🙁'}</Text>,
  },
  blocked: {
    label: 'Черный список',
    placeholder: 'Заблокированные клиенты',
    emoji: <Text style={{fontSize: 36}}>{'🚫'}</Text>,
  },
};

export const SegmentCard = ({
  activeSegmentCategoty,
  type,
  value = 0,
  onClick,
}: {
  activeSegmentCategoty?: CustomerCategory;
  type: CustomerCategory;
  value?: number;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}) => {
  return (
    <SegmentBox
      onClick={onClick}
      active={
        activeSegmentCategoty !== undefined && activeSegmentCategoty === type
      }>
      <Flex flex={1} direction="column" justifyContent="space-between">
        <Text
          style={{pointerEvents: 'all', cursor: 'pointer'}}
          typography="title24"
          mb={12}>
          {value}
        </Text>
        <Flex
          direction="column"
          style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}>
          <Text
            style={{pointerEvents: 'all', cursor: 'pointer'}}
            typography="text16Semibold">
            {segmentCards[type].label}
          </Text>
          <Text
            style={{pointerEvents: 'all', cursor: 'pointer'}}
            typography="subHead14Medium">
            {segmentCards[type].placeholder}
          </Text>
        </Flex>
      </Flex>
      <Flex>{segmentCards[type].emoji}</Flex>
    </SegmentBox>
  );
};
