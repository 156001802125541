import React from 'react';
import {Flex} from 'shared/ui/Flex';
import {Chip} from 'shared/ui/Chip';
import {Button} from 'shared/ui/Button';
import {FilterType} from './Setting';
import {Gender} from 'shared/lib/utils';
import {CustomerCategory} from 'shared/__generated__/graphql';

const displayValue = (value: boolean) => (value ? 'flex' : 'none');

const SegmentData = [
  {label: 'Все', value: undefined},
  {label: 'Постоянные', value: CustomerCategory.Regular},
  {label: 'Спящие', value: CustomerCategory.Prospects},
  {label: 'Пропавшие', value: CustomerCategory.Lost},
  {label: 'Не посещали', value: CustomerCategory.Inactive},
  {label: 'Черный список', value: CustomerCategory.Blocked},
];

export const FilterChips = ({
  filter,
  setFilter,
}: {
  filter: FilterType;
  setFilter: React.Dispatch<React.SetStateAction<FilterType>>;
}) => {
  const active =
    !!filter.birthdate ||
    !!filter.gender ||
    !!filter.customerCategory ||
    !!filter.tags;

  if (!active) return;

  return (
    <Flex gap={16}>
      <Chip
        style={{display: displayValue(!!filter.customerCategory)}}
        label="Сегменты клиентов:"
        text={
          SegmentData.find(item => item.value === filter.customerCategory)
            ?.label ?? ''
        }
        onClick={() =>
          setFilter(prev => ({...prev, customerCategory: undefined}))
        }
      />
      <Chip
        label="Пол:"
        text={filter.gender ? Gender[filter.gender] : ''}
        style={{display: displayValue(!!filter.gender)}}
        onClick={() => setFilter(prev => ({...prev, gender: undefined}))}
      />
      <Chip
        style={{display: displayValue(!!filter.birthdate)}}
        onClick={() => setFilter(prev => ({...prev, birthdate: undefined}))}
        text="День рождения"
      />
      <Chip
        style={{display: displayValue(!!filter.tags)}}
        label="Теги:"
        text={filter.tags?.map(item => item.name).join(', ') ?? ''}
        onClick={() => {
          setFilter(prev => ({...prev, tags: undefined}));
        }}
      />
      <Button
        onClick={() => {
          setFilter({
            birthdate: undefined,
            gender: undefined,
            customerCategory: undefined,
            tags: undefined,
          });
        }}
        size="small"
        style={{
          borderRadius: 8,
          paddingLeft: 12,
          paddingRight: 12,
          height: 32,
        }}
        variant="light">
        Сбросить
      </Button>
    </Flex>
  );
};
