import React from 'react';
import {Bar} from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import {Content} from 'shared/ui/Content';
import {
  BarControllerChartOptions,
  BarElement,
  CategoryScale,
  ChartData,
  CoreChartOptions,
  DatasetChartOptions,
  ElementChartOptions,
  Legend,
  LinearScale,
  PluginChartOptions,
  ScaleChartOptions,
  Title,
  Tooltip,
} from 'chart.js';
import {Text} from 'shared/ui/Text';
import {_DeepPartialObject} from 'chart.js/dist/types/utils';
import {Flex} from 'shared/ui/Flex';
import {SegmentControl} from 'shared/ui/SegmentControl';
import {GetStatisticsBarQuery} from 'shared/__generated__/graphql';
import dayjs from 'dayjs';
Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const labels = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
];
const barColors = ['#6765F8', '#1BC760', '#FF1C45'];

const data: ChartData<'bar', (number | [number, number] | null)[], unknown> = {
  labels,
  datasets: [
    {
      label: 'Кол-во записей',
      data: [10, 20, 15, 40, 30, 12, 31, 28],
      backgroundColor: barColors[0],
      borderRadius: 4,
    },
    {
      label: 'Выполненные',
      data: [15, 22, 10, 26, 12, 22, 28, 11],
      backgroundColor: barColors[1],
      borderRadius: 4,
    },
    {
      label: 'Отмененные',
      data: [4, 8, 5, 10, 3, 6, 14, 4],
      backgroundColor: barColors[2],
      borderRadius: 4,
    },
  ],
};
const ColorCircle = ({color}: {color: string}) => (
  <div style={{height: 8, width: 8, borderRadius: 8, backgroundColor: color}} />
);

const options:
  | _DeepPartialObject<
      CoreChartOptions<'bar'> &
        ElementChartOptions<'bar'> &
        PluginChartOptions<'bar'> &
        DatasetChartOptions<'bar'> &
        ScaleChartOptions<'bar'> &
        BarControllerChartOptions
    >
  | undefined = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      reverse: true,
      display: true,
      min: 0,
      ticks: {
        font: {
          size: 12,
          family: 'Inter',
        },
      },
    },
    y: {
      display: true,
      ticks: {
        font: {
          size: 12,
          family: 'Inter',
        },
      },
    },
  },
};

const segmentData = [
  {
    label: 'День',
    value: 0,
  },
  {
    label: 'Неделя',
    value: 1,
  },
  {
    label: 'Месяц',
    value: 2,
  },
];

export const BarChart = ({
  segmentValue,
  setSegmentValue,
  data: barData,
}: {
  segmentValue: number;
  setSegmentValue: (value: number) => void;
  data?: GetStatisticsBarQuery;
}) => {
  const visits = barData?.getStatistics?.visits ?? [];
  const bookings =
    visits?.map(item =>
      item?.statistics?.bookings ? +item.statistics.bookings : 0,
    ) ?? [];
  const completeds =
    visits?.map(item =>
      item?.statistics?.completed ? +item.statistics.completed : 0,
    ) ?? [];
  const cancelleds =
    visits?.map(item =>
      item?.statistics?.cancelled ? +item.statistics.cancelled : 0,
    ) ?? [];

  const newData = {
    labels: visits?.map(item =>
      segmentValue === 2
        ? dayjs(item?.date).format('MM.YYYY')
        : dayjs(item?.date).format('DD.MM'),
    ),
    datasets: data.datasets.map((item, index) => ({
      ...item,
      data: index === 0 ? bookings : index === 1 ? completeds : cancelleds,
    })),
  };

  return (
    <Content style={{display: 'flex', flex: 1, height: 390}} gap="0">
      <Flex justifyContent="space-between">
        <Text typography="title20">Общее кол-во визитов</Text>
        <SegmentControl
          adaptive
          value={segmentValue}
          onClick={value => setSegmentValue(value)}
          data={segmentData}
        />
      </Flex>
      <Flex gap={32} mb={12}>
        <Flex gap={8} alignItems="center">
          <ColorCircle color={barColors[0]} />
          <Text typography="footNote12Regular">Кол-во записей</Text>
        </Flex>
        <Flex gap={8} alignItems="center">
          <ColorCircle color={barColors[1]} />
          <Text typography="footNote12Regular">Выполненные</Text>
        </Flex>
        <Flex gap={8} alignItems="center">
          <ColorCircle color={barColors[2]} />
          <Text typography="footNote12Regular">Отмененные</Text>
        </Flex>
      </Flex>
      <Flex
        flex={1}
        style={{
          height: 288,
          alignItems: 'end',
        }}>
        <Bar options={options} data={newData} height={72} />
      </Flex>
    </Content>
  );
};
