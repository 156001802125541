import React from 'react';
import {TextInput} from 'shared/ui/TextInput';
import styled from 'styled-components';
import {usePayment} from './Provider';
import {CitiesSelect} from 'entities/city';
import {CompanyInfo, CompanyInformation, TariffBox} from 'entities/tariff';
import {SkeletonBlock} from './SkeletonBlock';
const Inputs = styled.div`
  display: flex;
  gap: 8px;
`;
export const AccountInformationSection = ({
  invoice,
  loading,
}: {
  loading: boolean;
  invoice: CompanyInformation | null;
}) => {
  const {state, setState} = usePayment();
  const {name, street, office, city, index} = state;
  return (
    <TariffBox title="Информация для выставления счета" gap={8}>
      {loading ? (
        <SkeletonBlock />
      ) : invoice !== null ? (
        <CompanyInfo info={invoice} />
      ) : (
        <>
          <TextInput
            width="100%"
            label="Организация"
            onChange={value => setState({...state, name: value})}
            value={name}
          />
          <Inputs>
            <TextInput
              flex={1}
              label="Улица и дом"
              onChange={value => setState({...state, street: value})}
              value={street}
            />
            <TextInput
              flex={1}
              label="Офис"
              mask="9999"
              onChange={value => setState({...state, office: value})}
              value={office}
            />
          </Inputs>
          <Inputs>
            <TextInput
              flex={1}
              label="Индекс"
              mask="999999"
              onChange={value => setState({...state, index: value})}
              value={index}
            />
            <CitiesSelect
              style={{width: 'calc(50% - 4px)'}}
              value={city}
              onChange={value => setState({...state, city: value})}
            />
          </Inputs>
        </>
      )}
    </TariffBox>
  );
};
