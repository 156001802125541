import React, {useState, useRef, useEffect} from 'react';
import styled from 'styled-components';
import {DatePicker} from './DatePicker';
import dayjs from 'dayjs';
import InputMask from 'react-input-mask';
import {device} from 'shared/device';
import {Text} from './Text';
import {Flex} from './Flex';
import Close from 'shared/icons/Close';
import {IconButton} from './IconButton';
import {showAlert} from './Alert';

type DateProps = {
  label: string;
  onChange: (value: string) => void;
  value?: string;
  disabled?: boolean;
  required?: boolean;
  style?: React.CSSProperties;
  error?: string | null;
  isBirthdate?: boolean;
};

const Container = styled.div<{
  active: boolean;
}>`
  position: relative;
  pointer-events: all;
  width: 100%;
  background-color: ${({theme}) => theme.fillPrimary};
  padding: 0 16px;
  height: 64px;
  border-radius: 18px;
  border: 1px solid
    ${({theme, active}) => (active ? theme.mainPrimary : theme.borderPrimary)};
  pointer-events: all;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const StyledLabel = styled.div<{
  required?: boolean;
  selected?: boolean;
}>`
  &::after {
    content: '*';
    color: ${({theme}) => theme.dangerPrimary};
    visibility: ${({required}) => (required ? 'visible' : 'hidden')};
    font-size: ${({selected}) => (selected ? '12px' : '16px')};
    transition:
      transform 150ms ease,
      color 150ms ease,
      font-size 150ms ease;
  }
  position: absolute;
  top: ${({selected}) => (selected ? '6px' : '20px')};
  font-size: ${({selected}) => (selected ? '12px' : '16px')};
  line-height: ${({selected}) => (selected ? '18px' : '24px')};
  color: ${({theme}) => theme.textTertiary};
  font-style: normal;
  font-weight: 400;
  font-family: 'Inter';
  background-color: transparent;
  transition: all 0.3s ease;
`;
const Input = styled.input`
  width: 100%;
  background-color: transparent;
  border: none;
  outline-style: none;
  padding: 0;
  font-family: 'Inter';
  font-size: 16px;
  height: 24px;
  margin-top: 24px;
  color: ${({theme}) => theme.textPrimary};
  pointer-events: none;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  @media ${device.mobile} {
    font-size: 14px;
  }
`;

export const DateInput = ({
  label,
  onChange,
  value,
  disabled,
  required,
  style,
  error,
  isBirthdate,
}: DateProps) => {
  const [active, setActive] = useState(false);
  const isLabelShrink = !!value || active;
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSelectDate = (value: string) => {
    const date = dayjs(value).format('DD.MM.YYYY');
    onChange(date);
    setActive(false);
  };

  const checkValue = value ?? '';

  useEffect(() => {
    const isFullDate = checkValue.replace(/[._]/g, '').length === 8;
    if (isFullDate) {
      const isValidateDate = dayjs(checkValue, 'DD.MM.YYYY', true).isValid();
      if (!isValidateDate) {
        const newDate = dayjs()
          .set('month', +checkValue.slice(3, 5) - 1)
          .set('year', +checkValue.slice(6, 10))
          .endOf('month')
          .format('DD.MM.YYYY');
        onChange(newDate);
      }
    }
  }, [checkValue, onChange]);

  return (
    <Flex direction="column" gap={8}>
      <Container
        active={active}
        onClick={() => {
          inputRef.current?.focus();
          setActive(true);
        }}
        style={style}>
        <StyledLabel
          selected={isLabelShrink}
          required={required}
          color={'textTertiary'}>
          {label}
        </StyledLabel>
        <InputMask
          autoComplete="new-password"
          mask={[
            /[0-3]/,
            checkValue.startsWith('3') ? /[0-1]/ : /[0-9]/,
            '.',
            /[0-1]/,
            checkValue[3] === '1' ? /[0-2]/ : /[0-9]/,
            '.',
            /[1-2]/,
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
          ]}
          maskPlaceholder={'_'}
          disabled={disabled}
          value={value}
          onChange={e => {
            if (
              dayjs(e.target.value, 'DD.MM.YYYY', true).isValid() &&
              isBirthdate
            ) {
              const today = dayjs();
              const selectedDay = dayjs(e.target.value);
              if (selectedDay.isBefore(today)) {
                onChange(e.target.value);
              } else {
                showAlert('error', 'Неверная дата');
              }
            } else {
              onChange(e.target.value);
            }
          }}>
          <Input ref={inputRef} />
        </InputMask>
        {!!value && (
          <IconButton
            onClick={e => {
              e.stopPropagation();
              onChange('');
            }}
            size={24}
            style={{
              borderColor: 'transparent',
              backgroundColor: 'transparent',
              padding: 0,
            }}>
            <Close />
          </IconButton>
        )}
        <DatePicker
          style={{width: '100%', maxWidth: 375, right: 'auto', left: 0}}
          value={value ?? ''}
          input
          onChange={newValue => {
            handleSelectDate(newValue);
          }}
          visible={active}
          onClose={() => setActive(false)}
          isBirthdate={isBirthdate}
        />
      </Container>
      {error && (
        <Text color="dangerPrimary" typography="footNote12Regular">
          {error}
        </Text>
      )}
    </Flex>
  );
};
