import React, {useState} from 'react';
import {styled} from 'styled-components';
import {OptionsType, Table} from 'shared/ui/Table';
import {gql} from 'shared/__generated__';
import {useAppSelector} from 'shared/store';
import {useQuery} from '@apollo/client';
import {
  CompanyBookingStatus,
  StatisticsDates,
} from 'shared/__generated__/graphql';
import {Flex} from 'shared/ui/Flex';
import {
  FilterEmptyState,
  FilterResetButton,
  FilterSearch,
  FilterStatisticsBookingStatus,
  FilterStatisticsEmployee,
  FilterStatisticsPeriod,
} from 'entities/statistics';
import {getNumberFormat, getPriceFormat} from 'shared/lib/utils';
import {Content} from 'shared/ui/Content';
import {PageHeader} from 'shared/ui/PageHeader';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
`;

const GET_STATISTICS_VISIT_SUMMARY = gql(`
  query VisitSummary(
    $companyId: String!
    $date: StatisticsDates!
    $search: String
    $status: CompanyBookingStatus
    $employeeIds: [String!]
    $employeeVisitsFirst: Int!
    $serviceVisitsFirst: Int!
    $employeeVisitsPage: Int
    $serviceVisitsPage: Int
  ) {
    visitSummary(
      company_id: $companyId
      date: $date
      search: $search
      status: $status
      employee_ids: $employeeIds
      employee_visits_first: $employeeVisitsFirst
      service_visits_first: $serviceVisitsFirst
      employee_visits_page: $employeeVisitsPage
      service_visits_page: $serviceVisitsPage
    ) {
      employee_visits {
        full_name
        total_price
        visit_count
      }
      service_visits {
        service_name
        total_price
        visit_count
      }
      employee_paginatorInfo {
        hasMorePages
        currentPage
        count
        lastPage
        total
      }
      service_paginatorInfo {
        count
        currentPage
        hasMorePages
        lastPage
        total
      }
    }
  }
`);
const titles_employee = ['Сотрудник', 'Кол-во визитов', 'Общая стоимость'];
const titles_service = ['Услуга', 'Кол-во визитов', 'Общая стоимость'];

export const VisitSummary = () => {
  const companyId = useAppSelector(state => state.company.data!.id);
  const [search, setSearch] = useState('');
  const [employeeId, setEmployeeId] = useState('');
  const [optionsEmployee, setOptionsEmployee] = useState<OptionsType>({
    page: 1,
    first: 10,
    total: 0,
    count: 0,
    lastPage: 1,
  });
  const [optionsService, setOptionsService] = useState<OptionsType>({
    page: 1,
    first: 10,
    total: 0,
    count: 0,
    lastPage: 1,
  });
  const [status, setStatus] = useState<CompanyBookingStatus | undefined>();
  const [period, setPeriod] = useState<StatisticsDates>(
    StatisticsDates.Alltime,
  );
  const {data, loading} = useQuery(GET_STATISTICS_VISIT_SUMMARY, {
    variables: {
      companyId,
      employeeIds: employeeId ? [employeeId] : undefined,
      status,
      date: period,
      search,
      employeeVisitsFirst: optionsEmployee.first,
      employeeVisitsPage: optionsEmployee.page,
      serviceVisitsFirst: optionsService.first,
      serviceVisitsPage: optionsService.page,
    },
    onCompleted: res => {
      setOptionsEmployee(prev => ({
        ...prev,
        total: res.visitSummary.employee_paginatorInfo?.total,
        count: res.visitSummary.employee_paginatorInfo?.count,
        lastPage: res.visitSummary.employee_paginatorInfo?.lastPage,
      }));
      setOptionsService(prev => ({
        ...prev,
        total: res.visitSummary.service_paginatorInfo?.total,
        count: res.visitSummary.service_paginatorInfo?.count,
        lastPage: res.visitSummary.service_paginatorInfo?.lastPage,
      }));
    },
  });
  const visitEmployee = data?.visitSummary.employee_visits ?? [];
  const visitService = data?.visitSummary.service_visits ?? [];
  return (
    <Wrapper>
      <PageHeader back title="Сводка визитов" />
      <Flex direction="row" gap={8}>
        <FilterSearch onChange={setSearch} value={search} />
        <FilterStatisticsPeriod {...{period, setPeriod}} />
        <FilterStatisticsEmployee {...{employeeId, setEmployeeId}} />
        <FilterStatisticsBookingStatus {...{status, setStatus}} />
        {employeeId ||
        status ||
        search ||
        period !== StatisticsDates.Alltime ? (
          <FilterResetButton
            onClick={() => {
              setStatus(undefined);
              setEmployeeId('');
              setSearch('');
              setPeriod(StatisticsDates.Alltime);
            }}
          />
        ) : (
          <></>
        )}
      </Flex>
      {!loading && !visitEmployee?.length && !visitService?.length ? (
        <FilterEmptyState />
      ) : (
        <Flex direction="row" gap={20}>
          <Content style={{width: '100%', height: 'max-content'}}>
            <Table
              titles={titles_employee}
              options={optionsEmployee}
              setOptions={setOptionsEmployee}
              alignRightColumn={[1, 2]}
              columnNoWrap={[2]}
              rows={visitEmployee.map(item => [
                item.full_name,
                getNumberFormat(item.visit_count),
                getPriceFormat(item.total_price),
              ])}
            />
          </Content>
          <Content style={{width: '100%', height: 'max-content'}}>
            <Table
              titles={titles_service}
              options={optionsService}
              setOptions={setOptionsService}
              alignRightColumn={[1, 2]}
              columnNoWrap={[2]}
              rows={visitService.map(item => [
                item.service_name,
                getNumberFormat(item.visit_count),
                getPriceFormat(item.total_price),
              ])}
            />
          </Content>
        </Flex>
      )}
    </Wrapper>
  );
};
