import React from 'react';
import styled from 'styled-components';
import {Text} from 'shared/ui/Text';
import {NavLink} from 'react-router-dom';
import {Flex} from 'shared/ui/Flex';
import {Right} from 'shared/icons/Right';

const CardLink = styled(NavLink)`
  display: flex;
  min-width: 242px;
  max-width: 242px;
  height: 184px;
  padding: 24px 12px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 24px;
  background: ${({theme}) => theme.bgSecondary};
  text-decoration-line: none;
  cursor: pointer;
`;

type Props = {
  title?: string;
  icon?: React.ReactNode;
  to: string;
};

export const Card = ({title, icon, to}: Props) => {
  return (
    <CardLink to={to}>
      <Flex flex={1} alignItems="center" direction="column" gap={8}>
        {icon}
        <Text
          style={{cursor: 'pointer', textAlign: 'center'}}
          typography="text16Semibold">
          {title}
        </Text>
      </Flex>
      <Flex gap={8}>
        <Text style={{cursor: 'pointer'}}>Перейти</Text>
        <Right />
      </Flex>
    </CardLink>
  );
};
