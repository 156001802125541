import React, {useState, useEffect} from 'react';
import {styled} from 'styled-components';
import {FilterButton} from 'shared/ui/FilterButton';
import {getName} from 'shared/lib/utils';
import {Modal} from 'shared/ui/Modal';
import {List} from 'shared/ui/List';
import {Text} from 'shared/ui/Text';
import {useLazyQuery} from '@apollo/client';
import {gql} from 'shared/__generated__';
import {useAppSelector} from 'shared/store';
import {Flex} from 'shared/ui/Flex';
import {Loading} from 'shared/ui/Loading';

const GET_CUSTOMERS = gql(`
  query GetCustomersFilter(
    $companyId: String!
    $first: Int!
    $page: Int
  ) {
    customers(
      company_id: $companyId
      first: $first
      page: $page
    ) {
      paginatorInfo {
        hasMorePages
        currentPage
      }
      data {
        id
        name
        surname
      }
    }
  }
`);

const PopupWrapper = styled.div`
  position: relative;
  display: inline-block;
`;
const ButtonEmployee = styled.button<{checked?: boolean}>`
  display: flex;
  flex-direction: row;
  padding: 0 16px;
  align-items: center;
  height: 44px;
  width: 343px;
  gap: 8px;
  background-color: ${({checked, theme}) =>
    checked ? theme.bgSecondary : 'transparent'};
  border: none;
`;

export const FilterStatisticsCustomer = ({
  customerId,
  setCustomerId,
}: {
  customerId?: string;
  setCustomerId?: (id: string) => void;
}) => {
  const companyId = useAppSelector(state => state.company.data!.id);
  const [lazy, {data, loading}] = useLazyQuery(GET_CUSTOMERS, {
    variables: {
      companyId,
      first: 100,
    },
  });
  const customers = data?.customers?.data;
  const customer = customers?.find(item => item.id === customerId);
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    if (visible) lazy();
  }, [lazy, visible]);
  return (
    <PopupWrapper>
      <FilterButton
        onClick={() => setVisible(true)}
        active={!!customerId}
        title={
          customer ? getName(customer?.name, customer?.surname) : 'Все клиенты'
        }
        visible={visible}
      />
      <Modal
        style={{
          left: 'auto',
          right: 'auto',
          top: 64,
          padding: 0,
          gap: 8,
        }}
        visible={visible}
        onClose={() => setVisible(false)}>
        {loading ? (
          <Flex justifyContent="center" style={{width: 343}}>
            <Loading />
          </Flex>
        ) : (
          <Flex
            direction="column"
            style={{
              maxHeight: 284,
              overflowY: 'auto',
              paddingTop: 24,
            }}>
            <List
              gap={16}
              data={[{name: 'Все клиенты', id: ''}, ...(customers ?? [])]}
              keyExtractor={(_, index) => 'key_' + index}
              renderItem={(item, index) => (
                <ButtonEmployee
                  style={{
                    marginBottom: customers?.length === index ? 24 : 0,
                  }}
                  onClick={() => {
                    setCustomerId && setCustomerId(item.id);
                    setVisible(false);
                  }}
                  checked={item.id === customerId}>
                  <Text>{getName(item?.name, item?.surname)}</Text>
                </ButtonEmployee>
              )}
            />
          </Flex>
        )}
      </Modal>
    </PopupWrapper>
  );
};
