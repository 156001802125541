import React, {useState} from 'react';
import {useAppSelector} from 'shared/store';
import {Layout} from 'shared/ui/Layout';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';
import {useLocation, useNavigate} from 'react-router-dom';
import {gql} from 'shared/__generated__';
import {ApolloError, useMutation, useQuery} from '@apollo/client';
import {Content} from 'shared/ui/Content';
import {Avatar} from 'shared/ui/Avatar';
import {Employee, Image, Profession} from 'shared/__generated__/graphql';
import {TariffPlan, getName} from 'shared/lib/utils';
import {CheckBox} from 'shared/ui/CheckBox';
import {Button} from 'shared/ui/Button';
import {showAlert} from 'shared/ui/Alert';
import {client} from 'shared/config/apollo';
import {SubscriptionFree} from './ui/SubscriptionFree';
import {Flex} from 'shared/ui/Flex';
import {Skeleton} from 'shared/ui/Skeleton';
import {PageHeader} from 'shared/ui/PageHeader';

type IEmployee = Pick<Employee, 'id' | 'name' | 'surname' | 'enabled'> & {
  avatar?: Pick<Image, 'id' | 'url'> | null;
  professions?: Pick<Profession, 'id' | 'name'>[] | null;
};

const CHANGE_TARIFF_FREE = gql(`
  mutation ChangePlanToFree($companyId: String!) {
    changePlanToFree(company_id: $companyId)
  }
`);

const GET_EMPLOYEES = gql(`
  query TariffEmployees($first: Int!, $companyId: String, $page: Int) {
    companyEmployees(first: $first, company_id: $companyId, page: $page) {
      data {
        id
        name
        surname
        enabled
        avatar {
          id
          url
        }
        professions {
          id
          name
        }
      }
      paginatorInfo {
        currentPage
        hasMorePages
      }
    }
  }
`);

const CHANGE_EMPLOYEES = gql(`
  mutation ChangeEmployees($employees: [ChangeEmployees!]!) {
    changeEmployees(employees: $employees) {
      id
    }
  }
`);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  grid-column-start: 4;
  grid-column-end: 10;
  gap: 24px;
  padding-bottom: 24px;
`;
const Info = styled.div`
  margin-bottom: 8px;
  display: flex;
  gap: 8px;
  flex-direction: column;
`;

export function ChangeTariff() {
  const navigate = useNavigate();
  const state: {plan: TariffPlan} | undefined = useLocation().state;
  const [selected, setSelected] = useState<string[]>([]);
  const companyId = useAppSelector(state => state.company.data!.id);
  const [subscriptionFree, setSubscriptionFree] = useState(false);
  const [changeEmployees, {loading: employeeLoading}] =
    useMutation(CHANGE_EMPLOYEES);
  const [changeTariffFree, {loading: tariffLoading}] = useMutation(
    CHANGE_TARIFF_FREE,
    {
      variables: {companyId},
    },
  );
  const {data, loading} = useQuery(GET_EMPLOYEES, {
    variables: {companyId, first: 100, page: 1},
    onCompleted: res => {
      const data =
        res.companyEmployees?.data.filter(item => item.enabled) ?? [];
      setSelected(data.map(item => item.id));
    },
  });

  const employees = data?.companyEmployees?.data ?? [];
  const countSelected = selected.length;
  const slots = state?.plan.slots ?? 1;
  const approve = countSelected <= slots;
  const currentActiveEmployees =
    data?.companyEmployees?.data
      .filter(item => item.enabled)
      .map(emp => emp.id) ?? [];

  return (
    <Layout>
      <Container>
        <PageHeader back variant="layout" title="Смена тарифа" />
        <Info>
          <Text align="center">
            Вам нужно уменьшить кол-во сотрудников по тарифу. Выберите, кого
            оставить активным
          </Text>
          <Text
            color={approve ? 'successPrimary' : 'dangerPrimary'}
            align="center">
            Выбрано {countSelected} из {slots}
          </Text>
        </Info>
        <Content>
          {loading ? (
            <SkeletonChangeTariff />
          ) : (
            employees.map((employee, index) => (
              <EmployeeBox
                key={employee.id + index}
                employee={employee}
                currentActiveEmployees={currentActiveEmployees}
                selected={!!selected.find(item => item === employee.id)}
                onSelect={id => {
                  const active = !!selected.find(item => item === employee.id);
                  if (active) {
                    setSelected(selected.filter(item => item !== id));
                  } else {
                    setSelected([...selected, id]);
                  }
                }}
              />
            ))
          )}
        </Content>
        <Button
          style={{marginTop: 8}}
          size="large"
          disabled={!approve}
          loading={employeeLoading || tariffLoading}
          onClick={async () => {
            try {
              const changedEmployees = employees.map(employee => ({
                employee_id: employee.id,
                enabled: !!selected.find(item => item === employee.id),
              }));
              await changeEmployees({
                variables: {employees: changedEmployees},
              });
              if (state) {
                navigate('/payment-tariff', {
                  state: {plan: state.plan, companyId},
                });
              } else {
                await changeTariffFree();
                client.refetchQueries({
                  include: ['MyTariff', 'Me', 'SidebarQuery'],
                });
                setSubscriptionFree(true);
              }
            } catch (error) {
              if (error instanceof ApolloError) {
                showAlert('error', error.message);
              }
            }
          }}>
          Готово
        </Button>
        <SubscriptionFree
          visible={subscriptionFree}
          onClose={() => setSubscriptionFree(false)}
        />
      </Container>
    </Layout>
  );
}

const SkeletonChangeTariff = () => {
  return (
    <Skeleton width={'100%'} height={380}>
      {Array(5)
        .fill(0)
        .map((_, index) => (
          <React.Fragment key={'issue_sk_rect_' + index}>
            <circle r={32} cx={32} cy={32 + 80 * index} />
            <rect
              width={180}
              height={24}
              rx={8}
              ry={8}
              x={80}
              y={8 + 80 * index}
            />
            <rect
              width={110}
              height={20}
              rx={8}
              ry={8}
              x={80}
              y={36 + 80 * index}
            />
            <rect
              width={24}
              height={24}
              rx={8}
              ry={8}
              x="calc(100% - 24px)"
              y={20 + 80 * index}
            />
          </React.Fragment>
        ))}
    </Skeleton>
  );
};

const EmployeeContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  cursor: pointer;
  pointer-events: all;
`;
const EmployeeBox = ({
  employee,
  selected,
  onSelect,
  currentActiveEmployees,
}: {
  employee: IEmployee;
  selected: boolean;
  currentActiveEmployees: string[];
  onSelect: (id: string) => void;
}) => {
  const data = employee.professions ?? [];
  const isProfession = data.length !== 0;
  const currentActiveEmployee = currentActiveEmployees.includes(employee.id)
    ? true
    : undefined;
  return (
    <EmployeeContainer onClick={() => onSelect(employee.id)}>
      <Avatar
        size={64}
        url={employee.avatar?.url}
        active={currentActiveEmployee}
        activeSize={16}
      />
      <Flex direction="column" style={{pointerEvents: 'none'}} flex={1}>
        <Text>{getName(employee.name, employee.surname)}</Text>
        {isProfession ? (
          <Text typography="subHead14Regular" color="textTertiary">
            {data.map(profession => profession.name).join(', ')}
          </Text>
        ) : null}
      </Flex>
      <CheckBox checked={selected} onChange={() => onSelect(employee.id)} />
    </EmployeeContainer>
  );
};
