import React, {useState} from 'react';
import styled from 'styled-components';
import {device} from 'shared/device';
import {Seo} from 'shared/ui/Seo';
import {Content} from 'shared/ui/Content';
import {Text} from 'shared/ui/Text';
import {Select} from 'shared/ui/Select';
import {ApolloError, useMutation, useQuery} from '@apollo/client';
import {gql} from 'shared/__generated__';
import {useAppSelector} from 'shared/store';
import {useParams} from 'react-router-dom';
import {showAlert} from 'shared/ui/Alert';
import {Skeleton} from 'shared/ui/Skeleton';
import {Flex} from 'shared/ui/Flex';
import {EmptyWallet} from 'shared/illustration/EmptyWallet';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-top: 8px;
  padding-bottom: 24px;
  @media screen and (${device.mobile}) {
    margin-top: 56px;
  }
`;

const GET_RULES_SALARIES = gql(`
  query EmployeeSalaryRules($companyId: String!, $page: Int, $first: Int!, $employeeId: String!) {
    companySalaryRules(company_id: $companyId, page: $page, first: $first) {
      paginatorInfo {
        currentPage
        total
        count
      }
      data {
        name
        id
      }
    }
    employee(id: $employeeId) {
      salary_rule {
        id
        name
      }
    }
  }
`);
const UPDATE_EMPLOYEE_SALARY_RULE = gql(` 
  mutation UpdateEmployeeSalaryRule($input: UpdateEmployee!) {
    updateEmployee(input: $input) {
      id
    }
  }`);

export const EmployeeSalaryRule = () => {
  const {id} = useParams();
  const companyId = useAppSelector(state => state.company.data!.id);
  const [rule, setRule] = useState<string | null>(null);
  const {data} = useQuery(GET_RULES_SALARIES, {
    variables: {companyId, page: 1, first: 100, employeeId: id!},
    onCompleted: res => {
      if (res.employee?.salary_rule) {
        setRule(res.employee.salary_rule[0].id);
      }
    },
  });
  const [updateEmployee, {loading}] = useMutation(UPDATE_EMPLOYEE_SALARY_RULE);

  const resData =
    data?.companySalaryRules?.data.map(item => ({
      value: item.id,
      label: item.name,
    })) ?? [];
  const rules = [{value: null, label: 'Не выбран'}, ...resData];
  return (
    <Wrapper>
      <Seo title="Расчет зарплаты сотрудника" />
      <Content gap="16px" style={{maxWidth: 720}}>
        <Text typography="title18">
          Выберите правило расчета для этого сотрудника
        </Text>
        {loading ? (
          <Skeleton height={64}>
            <rect width={'100%'} height={64} rx={14} ry={14} />
          </Skeleton>
        ) : (
          <Select
            data={resData.length === 0 ? [] : rules}
            value={rule}
            label="Правило расчета"
            emptyState={
              <Flex gap={16} direction="column" alignItems="center">
                <EmptyWallet size={200} />
                <Text typography="title20">Правил расчета нет</Text>
                <Text
                  color="textTertiary"
                  align="center"
                  style={{marginBottom: 8}}>
                  Предлагаем это исправить. Начните <br /> включать расчет
                  зарплат в <br /> разделе зарплаты
                </Text>
              </Flex>
            }
            onChange={value => {
              if (value === rule) {
                return;
              }
              updateEmployee({
                variables: {
                  input: {
                    salary_rule_id: value,
                    employee_id: id!,
                  },
                },
              })
                .then(() => {
                  setRule(value);
                  showAlert(
                    'success',
                    'Правило расчета сотрудника успешно изменено!',
                  );
                })
                .catch((e: ApolloError) => showAlert('error', e.message));
            }}
          />
        )}
      </Content>
    </Wrapper>
  );
};
