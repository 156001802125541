import {ApolloError, useMutation} from '@apollo/client';
import dayjs from 'dayjs';
import {SIDEBAR_QUERY} from 'entities/sidebar';
import {MY_TARIFF, TooltipRow, tariffRows} from 'entities/tariff';
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {gql} from 'shared/__generated__';
import {client} from 'shared/config/apollo';
import {CircleAlert} from 'shared/icons/CircleAlert';
import {unsubscribe} from 'shared/lib/api';
import {useColors} from 'shared/lib/hooks';
import {useAppSelector} from 'shared/store';
import {showAlert} from 'shared/ui/Alert';
import {Button} from 'shared/ui/Button';
import {CloseButton} from 'shared/ui/CloseButton';
import {Divider} from 'shared/ui/Divider';
import {Flex} from 'shared/ui/Flex';
import {ME} from 'shared/ui/MenuItem';
import {Popup} from 'shared/ui/Popup';
import {RadioButton} from 'shared/ui/RadioButton';
import {Text} from 'shared/ui/Text';
import {TextArea} from 'shared/ui/TextArea';
import styled from 'styled-components';

export const CHANGE_TARIFF_FREE = gql(`
  mutation ChangePlanToFree($companyId: String!) {
    changePlanToFree(company_id: $companyId)
  }
`);

const initialReasons = [
  'Мы не смогли внедрить Booky',
  'Компания приостанавливает свою деятельность',
  'Мы нашли решение получше',
  'Нас не устраивает работа Booky',
  'Другое',
];
const cancelInfo = [
  'Онлайн запись будет отключена',
  'Система управления будет заблокирована',
  'Сайт будет отключён',
];
const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
const Reason = styled.div`
  display: flex;
  border-radius: 18px;
  border: 1px solid ${({theme}) => theme.borderPrimary};
  padding: 0 16px;
  height: 64px;
  background-color: ${({theme}) => theme.bgSecondary};
  align-items: center;
`;
const EndDate = styled.div`
  display: flex;
  padding: 16px;
  border: 1px solid ${({theme}) => theme.borderPrimary};
  border-radius: 20px;
  gap: 16px;
  align-items: center;
`;
const Icon = styled.div`
  display: flex;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background-color: ${({theme}) => theme.fillPrimary};
  align-items: center;
  justify-content: center;
`;

export const CancelSubscription = ({
  activeEmployee,
  visible,
  endDate,
  onClose,
  cancelPlan,
  isFree,
}: {
  activeEmployee: number;
  endDate?: string;
  visible: boolean;
  onClose: () => void;
  cancelPlan: string;
  isFree: boolean;
}) => {
  const companyId = useAppSelector(state => state.company.data!.id);
  const navigate = useNavigate();
  const colors = useColors();
  const [step, setStep] = useState<
    'questionFree' | 'causeCancelled' | 'cancelled' | 'free'
  >('questionFree');
  const [changeTariffFree] = useMutation(CHANGE_TARIFF_FREE, {
    variables: {companyId},
  });
  const [reasonIndex, setReasonIndex] = useState(0);
  const [causeComment, setCauseComment] = useState('');
  const isAnotherCause = reasonIndex === initialReasons.length - 1;
  const close = () => {
    setStep('questionFree');
    onClose();
  };
  const modalWidth =
    step === 'questionFree' || step === 'causeCancelled' ? 588 : 472;

  return (
    <Popup style={{width: modalWidth}} visible={visible} onClose={close}>
      <ModalWrapper
        style={{width: '100%', maxHeight: '100%', overflowY: 'hidden'}}>
        {step === 'questionFree' ? (
          <>
            <CloseButton
              style={{position: 'absolute', right: 24, top: 24}}
              onClose={close}
            />
            <Flex direction="column" gap={16}>
              <Text style={{height: 40}} align="center" typography="title24">
                Бесплатный тариф
              </Text>
              <Text align="center">
                Мы хотели бы, чтобы вы остались с нами!
                <br /> Перейдите на бесплатный тарифный план.
              </Text>
            </Flex>
            <Flex gap={8} direction="column">
              {tariffRows.map((row, index) => (
                <>
                  <TooltipRow key={row.title} {...row} />
                  {index === 0 && <Divider />}
                </>
              ))}
            </Flex>
            <Flex gap={8} direction="column">
              {!isFree && (
                <Button
                  size="large"
                  onClick={async () => {
                    try {
                      if (activeEmployee !== 1) {
                        navigate('/change-tariff');
                        return;
                      }
                      await changeTariffFree();
                      showAlert('success', 'Бесплатный тариф активирован');
                      await client.refetchQueries({
                        include: ['MyTariff', 'Me', 'SidebarQuery'],
                      });
                      setStep('free');
                    } catch (error) {
                      if (error instanceof ApolloError) {
                        showAlert('error', error.message);
                      }
                    }
                  }}>
                  Перейти на бесплатный тариф
                </Button>
              )}
              <Button
                size="large"
                variant="outline"
                onClick={() => setStep('causeCancelled')}>
                Отменить подписку
              </Button>
            </Flex>
          </>
        ) : null}
        {step === 'causeCancelled' ? (
          <Flex
            gap={24}
            direction="column"
            style={{overflow: 'auto', maxHeight: 600}}>
            <ModalHeader
              onClose={close}
              title="Отмена подписки"
              description="Это поможет нам сделать наш сервис еще лучше для вас и других пользователей. Можете выбрать несколько причин"
            />
            <Flex direction="column" gap={24} style={{overflow: 'scroll'}}>
              <EndDate>
                <Icon>
                  <CircleAlert color={colors.textTertiary} />
                </Icon>
                <Text typography="subHead14Regular">
                  Вам доступен тариф PRO до конца оплаченного периода -
                  {dayjs(endDate).format(' D MMMM YYYY')} года.
                </Text>
              </EndDate>
              <CancelInformation />
              <Flex gap={8} direction="column">
                {initialReasons.map((reason, index) => (
                  <Reason key={reason + index}>
                    <RadioButton
                      labelPosition="right"
                      selected={reasonIndex === index}
                      onChange={() => setReasonIndex(index)}
                      title={reason}
                    />
                  </Reason>
                ))}
              </Flex>
              {isAnotherCause ? (
                <TextArea
                  label="Добавьте комментарий"
                  value={causeComment}
                  onChange={setCauseComment}
                  style={{minHeight: 100}}
                  size="medium"
                />
              ) : null}
            </Flex>
            <Button
              size="large"
              style={{minHeight: 60}}
              onClick={async () => {
                try {
                  const comment = isAnotherCause
                    ? causeComment
                    : initialReasons[reasonIndex];
                  await unsubscribe(companyId, comment);
                  await client.refetchQueries({
                    include: [MY_TARIFF, ME, SIDEBAR_QUERY],
                  });
                  setStep('cancelled');
                } catch (error) {
                  if (error instanceof Error) {
                    showAlert('error', error.message);
                  }
                }
              }}>
              Отправить
            </Button>
          </Flex>
        ) : null}
        {step === 'cancelled' ? (
          <>
            <CloseButton
              style={{position: 'absolute', right: 24, top: 24}}
              onClose={close}
            />
            <Flex direction="column" gap={16}>
              <Text style={{height: 40}} typography="title20">
                Ваша подписка отменена
              </Text>
              <Text>
                {'Вы успешно отменили подписку ' +
                  cancelPlan +
                  '. Спасибо, что были с нами!'}
              </Text>
            </Flex>
            <Button variant="outline" size="large" onClick={close}>
              Закрыть
            </Button>
          </>
        ) : null}
        {step === 'free' ? (
          <>
            <ModalHeader
              onClose={close}
              title="Вы перешли на бесплатный тариф"
              description="Бесплатный тариф начнет действовать после окончания текущей
              подписки."
            />
            <Button onClick={close}>Отлично</Button>
          </>
        ) : null}
      </ModalWrapper>
    </Popup>
  );
};

const Dot = styled.div`
  height: 5px;
  width: 5px;
  border-radius: 3px;
  background-color: white;
  margin: 0 9px;
`;
const CancelInformation = () => (
  <Flex direction="column" gap={0}>
    <Text mb={16} typography="title18">
      После этого ваш аккаунт будет заблокирован
    </Text>
    {cancelInfo.map((text, index) => (
      <Flex alignItems="center" key={index + '_info'}>
        <Dot />
        <Text>{text}</Text>
      </Flex>
    ))}
  </Flex>
);

const ModalHeader = ({
  title,
  description,
  padding,
  onClose,
}: {
  padding?: string;
  title: string;
  description: string;
  onClose: () => void;
}) => (
  <>
    <CloseButton
      style={{position: 'absolute', right: 24, top: 24}}
      onClose={onClose}
    />
    <Flex style={{padding}} direction="column" gap={16}>
      <Text style={{height: 40}} align="center" typography="title20">
        {title}
      </Text>
      <Text align="center">{description}</Text>
    </Flex>
  </>
);
