import React from 'react';
import {Skeleton} from 'shared/ui/Skeleton';

export const SkeletonHistorySalesProducts = () => {
  return (
    <Skeleton height={264}>
      <rect width={180} height={24} rx={8} ry={8} x={24} />
      {Array.from(Array(3).keys()).map(item => (
        <React.Fragment key={'sk_' + item}>
          <circle r={20} cx={44} cy={76 + 78 * item} />
          <rect
            width={120}
            height={20}
            rx={8}
            ry={8}
            x={72}
            y={54 + 78 * item}
          />
          <rect
            width={120}
            height={20}
            rx={8}
            ry={8}
            x={72}
            y={78 + 78 * item}
          />
          <rect
            width={134}
            height={32}
            rx={12}
            ry={12}
            x={'calc(100% - 150px)'}
            y={48 + 78 * item}
          />
          <rect
            width={80}
            height={20}
            rx={8}
            ry={8}
            x={'calc(100% - 80px - 16px)'}
            y={88 + 78 * item}
          />
        </React.Fragment>
      ))}
    </Skeleton>
  );
};
