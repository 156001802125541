import React, {useState, useRef, useLayoutEffect} from 'react';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';
import {Content} from 'shared/ui/Content';
import {useNavigate} from 'react-router-dom';
import {useAppSelector} from 'shared/store';
import {Layout} from 'shared/ui/Layout';
import {PageHeader} from 'shared/ui/PageHeader';
import {Flex} from 'shared/ui/Flex';
import {Switch} from 'shared/ui/Switch';
import {TextInput} from 'shared/ui/TextInput';
import {TextArea} from 'shared/ui/TextArea';
import {
  GET_MESSAGES,
  GetReadyMessage,
  Preset,
  messageSampleBookingsStatus,
  messageSampleEvents,
  messageSampleMethods,
  messageSampleTimes,
  presets,
} from 'entities/messageTemplates';
import {Select} from 'shared/ui/Select';
import {Button} from 'shared/ui/Button';
import {ApolloError, useMutation} from '@apollo/client';
import {gql} from 'shared/__generated__';
import {showAlert} from 'shared/ui/Alert';
import {useColors} from 'shared/lib/hooks';
import {
  RichTextarea,
  RichTextareaHandle,
  createRegexRenderer,
} from 'rich-textarea';

const CREATE_MESSAGE_SAMPLE = gql(`
  mutation CreateMessageSample($input: CreateMessageSample!) {
    createMessageSample(input: $input) {
      id
    }
  }
`);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-column-start: 4;
  grid-column-end: 10;
  gap: 24px;
  padding: 0 0 16px 0;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const valuePresets = presets.map(item => item.value);

const replaceValueToExample = (value: string) => {
  let copyText = value.trim().replace(/\s+/g, ' ').replaceAll(/[()]/g, '');
  valuePresets.forEach(
    (item, index) =>
      (copyText = copyText.replaceAll(item, presets[index].example)),
  );
  return copyText;
};

const formatted = (value: string) => {
  return value.trim().replace(/\s+/g, ' ').replaceAll(/[()]/g, '');
};

export const CreateMessageTemplates = () => {
  const navigate = useNavigate();
  const colors = useColors();
  const companyId = useAppSelector(state => state.company.data!.id);
  const [enable, setEnable] = useState(true);
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState<string | null>(null);
  const [message, setMessage] = useState('');
  const [messageError, setMessageError] = useState<string>();
  const [method, setMethod] = useState(messageSampleMethods[0].value);
  const [event, setEvent] = useState(messageSampleEvents[0].value);
  const [time, setTime] = useState(messageSampleTimes[0].value);
  const [bookingStatus, setBookingStatus] = useState(
    messageSampleBookingsStatus[0].value,
  );
  const [createMessage, {loading}] = useMutation(CREATE_MESSAGE_SAMPLE, {
    refetchQueries: [
      {query: GET_MESSAGES, variables: {companyId, first: 20, page: 1}},
    ],
  });

  const styles = {
    color: colors.mainPrimary,
    backgroundColor: colors.mainLight50,
    padding: 2,
    borderRadius: 8,
  };

  const renderer = createRegexRenderer([
    [/{customer_name}/g, styles],
    [/{customer_surname}/g, styles],
    [/{company_name}/g, styles],
    [/{services_name}/g, styles],
    [/{manager_name}/g, styles],
    [/{manager_first_name}/g, styles],
    [/{manager_last_name}/g, styles],
    [/{manager_position}/g, styles],
    [/{date}/g, styles],
    [/{time}/g, styles],
    [/{full_duration}/g, styles],
    [/{url}/g, styles],
    [/{booking_amount_today}/g, styles],
    [/{booking_amount_tomorrow}/g, styles],
  ]);

  const refRich = useRef<RichTextareaHandle>(null);
  const [isFocused, setIsFocused] = useState(false);

  const insertAtCursor = (textToInsert: string) => {
    const input = refRich.current;
    if (!input) return;

    const {selectionStart, selectionEnd} = input;
    const currentText = message;

    const newText =
      currentText.substring(0, selectionStart) +
      textToInsert +
      currentText.substring(selectionEnd);

    setMessage(newText);

    setTimeout(() => {
      if (input) {
        input.focus();
        input.selectionStart = input.selectionEnd =
          selectionStart + textToInsert.length;
      }
    }, 0);
  };

  useLayoutEffect(() => {
    if (refRich.current) {
      refRich.current.style.color = 'red';
    }
  }, []);

  return (
    <Layout>
      <Wrapper>
        <PageHeader variant="layout" back title="Создание шаблона рассылок" />
        <Content gap="24px">
          <Text typography="title18">Основная информация</Text>
          <Flex
            alignItems="center"
            justifyContent="space-between"
            onClick={() => setEnable(!enable)}
            style={{height: 48, pointerEvents: 'all', cursor: 'pointer'}}>
            <Text>Включить шаблон</Text>
            <Switch value={enable} />
          </Flex>
          <TextInput
            label="Название шаблона сообщений"
            required
            value={name}
            error={nameError}
            onChange={text => {
              nameError && setNameError(null);
              setName(text);
            }}
          />
          <GetReadyMessage
            onSelect={item => {
              setName(item.name);
              setMessage(item.message);
              setMethod(item.method);
              setEvent(item.event);
              setTime(item.time);
              setBookingStatus(item.booking_status);
            }}
          />
          <Flex style={{position: 'relative'}} gap={8} direction="column">
            <Text color="textTertiary" typography="subHead14Regular">
              Шаблон сообщения
            </Text>
            <div
              style={{
                backgroundColor: colors.fillPrimary,
                minHeight: 160,
                display: 'flex',
                borderRadius: 18,
                width: '100%',
              }}>
              <RichTextarea
                ref={refRich}
                value={message}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                onChange={event => setMessage(event.target.value)}
                style={{
                  padding: 16,
                  borderRadius: 18,
                  outline: 'none',
                  border: `solid 1px ${
                    isFocused ? colors.mainPrimary : colors.borderPrimary
                  }`,
                  gap: 14,
                  width: '100%',
                  minHeight: 160,
                  fontSize: '16px',
                  fontFamily: 'Inter',
                  fontWeight: 'normal',
                  maxWidth: '100%',
                  minWidth: '100%',
                }}
                placeholder="Введите текст сообщения">
                {renderer}
              </RichTextarea>
            </div>
            <Text
              style={{position: 'absolute', bottom: 16, right: 16}}
              typography="footNote12Regular"
              color="textTertiary">
              {message.length + '/1000'}
            </Text>
          </Flex>
          <Flex gap={16} direction="column">
            {presets.map((preset, index) => (
              <Preset
                key={index + '_preset'}
                preset={preset}
                onClick={preset => {
                  insertAtCursor(` ${preset}`);
                }}
              />
            ))}
          </Flex>
          <TextArea
            value={replaceValueToExample(message)}
            disabled
            onChange={text => {
              setMessage(text);
            }}
            style={{backgroundColor: colors.bgPrimary}}
            size="large"
            maxLength={1000}
            label="Предпросмотр"
            error={messageError}
          />
        </Content>
        <Content gap="24px">
          <Text typography="title18">Методы отправки рассылки</Text>
          <Flex direction="column" gap={16}>
            <Select
              value={method}
              onChange={setMethod}
              label="Способ рассылки"
              dropdownPosition="top"
              data={messageSampleMethods}
            />
            <Select
              value={time}
              onChange={setTime}
              label="Момент рассылки"
              dropdownPosition="top"
              data={messageSampleTimes}
            />
            <Select
              value={event}
              onChange={setEvent}
              label="Событие"
              dropdownPosition="top"
              data={messageSampleEvents}
            />
            <Select
              value={bookingStatus}
              onChange={setBookingStatus}
              label="Статус записи"
              dropdownPosition="top"
              data={messageSampleBookingsStatus}
            />
          </Flex>
        </Content>
        <Button
          disabled={loading}
          loading={loading}
          onClick={() => {
            if (name.length === 0) {
              setNameError('Обязательное поле');
              return;
            }
            if (message.length === 0 || message.length > 1000) {
              setMessageError(
                message.length === 0
                  ? 'Заполните поле'
                  : 'Превышен лимит символов',
              );
              return;
            }
            const input = {
              booking_status: bookingStatus,
              company_id: companyId,
              event: event,
              message: formatted(message),
              method: method,
              is_active: enable,
              name,
              time,
            };
            createMessage({variables: {input}})
              .then(() => {
                showAlert('success', 'Шаблон создан');
                navigate(-1);
              })
              .catch((e: ApolloError) => showAlert('error', e.message));
          }}
          size="large">
          Создать
        </Button>
      </Wrapper>
    </Layout>
  );
};
