import React from 'react';
import {useColorScheme} from 'shared/lib/hooks';
export function EmptyEmployees({size = 200}: {size: number}) {
  const colorScheme = useColorScheme();
  return colorScheme === 'light' ? (
    <svg
      width={size}
      height={size}
      viewBox="0 0 208 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_12709_9116)">
        <path
          d="M125.446 122.394H75.6811C73.5591 122.394 71.8413 120.676 71.8413 118.554V50.2973C71.8413 48.1753 73.5591 46.4575 75.6811 46.4575H125.446C127.568 46.4575 129.286 48.1753 129.286 50.2973V118.554C129.286 120.676 127.568 122.394 125.446 122.394Z"
          fill="url(#paint0_linear_12709_9116)"
        />
      </g>
      <path
        d="M107.031 103.978H94.0966C92.6567 103.978 91.4946 102.816 91.4946 101.376C91.4946 99.9362 92.6567 98.7742 94.0966 98.7742H107.031C108.47 98.7742 109.632 99.9362 109.632 101.376C109.632 102.816 108.47 103.978 107.031 103.978Z"
        fill="#D5DDEA"
      />
      <path
        d="M112.967 109.612H88.1346C87.3768 109.612 86.7705 109.005 86.7705 108.247C86.7705 107.49 87.3768 106.883 88.1346 106.883H112.967C113.725 106.883 114.331 107.49 114.331 108.247C114.331 108.98 113.725 109.612 112.967 109.612Z"
        fill="#D5DDEA"
      />
      <path
        d="M117.539 73.5379C117.539 78.6408 115.291 83.2131 111.729 86.3203C108.748 88.9222 104.833 90.4885 100.564 90.4885C96.2945 90.4885 92.3789 88.9223 89.3981 86.295C85.8362 83.1879 83.5879 78.6155 83.5879 73.5127C83.5879 64.1406 91.1916 56.5369 100.564 56.5369C109.936 56.5369 117.539 64.1659 117.539 73.5379Z"
        fill="#D9DEEA"
      />
      <path
        d="M111.729 86.3202C108.748 88.9221 104.833 90.4884 100.564 90.4884C96.2944 90.4884 92.3788 88.9222 89.3979 86.2949C90.6358 85.815 92.2525 85.1834 94.425 84.2993C94.9302 84.0972 95.3092 83.7183 95.5365 83.2383C95.6628 82.9604 95.7134 82.6825 95.7134 82.3794V78.3123C95.6376 78.2365 95.587 78.1354 95.5365 78.0597C95.1829 77.5292 94.9555 76.9229 94.8797 76.2913L94.5261 76.1145C93.4903 76.3671 93.5661 75.2556 93.2882 73.1084C93.1872 72.2242 93.3135 72.0474 93.7177 71.8958L94.0461 71.4411C92.0252 66.894 93.0356 64.0395 95.0313 63.029C94.3745 61.5891 94.324 61.0586 94.324 61.0586C94.324 61.0586 98.5679 61.7659 100.008 61.488C101.852 61.1091 104.706 61.5638 105.767 64.0142C107.536 64.6963 108.218 65.833 108.369 67.0203C108.521 68.1318 108.193 69.3444 107.864 70.2791C107.839 70.3549 107.814 70.4054 107.788 70.4559C107.788 70.4812 107.763 70.5064 107.763 70.5317C107.713 70.658 107.687 70.7591 107.637 70.8601C107.612 70.9106 107.612 70.9611 107.586 71.0117C107.561 71.1127 107.511 71.2138 107.485 71.2895C107.485 71.3148 107.485 71.3148 107.46 71.3401C107.435 71.4159 107.409 71.4916 107.384 71.5674C107.359 71.6685 107.334 71.7443 107.308 71.82C107.334 71.8453 107.334 71.8958 107.359 71.9211C107.637 72.0474 107.738 72.3 107.637 73.0831C107.384 75.1293 107.46 76.3671 106.399 76.0893L105.464 77.706C105.439 77.8828 105.414 77.9839 105.389 78.0849C105.363 78.3881 105.363 78.9943 105.363 82.3289C105.363 82.7331 105.49 83.112 105.692 83.4404C105.919 83.794 106.247 84.0972 106.652 84.274C106.652 84.274 106.677 84.274 106.677 84.2993C108.875 85.234 110.491 85.8402 111.729 86.3202Z"
        fill="white"
      />
      <path
        d="M107.334 71.8453C107.41 71.3401 107.182 70.6833 107.005 70.2538C106.98 70.178 106.93 70.1023 106.904 70.0265C106.096 68.4097 104.277 67.7277 102.559 67.6266C98.1639 67.3993 97.7344 68.2329 96.3703 66.9698C96.7997 67.6014 96.825 68.6371 96.1682 69.9002C95.7135 70.7843 94.8293 71.1885 94.021 71.4411C92 66.894 93.0105 64.0395 95.0062 63.029C94.3494 61.5891 94.2988 61.0586 94.2988 61.0586C94.2988 61.0586 98.5428 61.7659 99.9827 61.488C101.827 61.1091 104.681 61.5638 105.742 64.0142C107.511 64.6963 108.193 65.833 108.344 67.0203C108.647 68.915 107.536 71.0117 107.334 71.8453Z"
        fill="url(#paint1_linear_12709_9116)"
      />
      <path
        d="M105.717 83.4907V84.2739H95.5112V83.2381C95.6375 82.9603 95.6881 82.6824 95.6881 82.3792V78.3121C95.6123 78.2363 95.5618 78.1353 95.5112 78.0595V77.8069C95.7386 78.1858 96.0165 78.5142 96.3449 78.8173L99.5278 81.0656C100.26 81.6972 101.347 81.6972 102.105 81.0909L105.085 78.4132C105.186 78.3374 105.287 78.2363 105.389 78.1353C105.363 78.4384 105.363 79.0447 105.363 82.3792C105.414 82.7834 105.515 83.1623 105.717 83.4907Z"
        fill="url(#paint2_linear_12709_9116)"
      />
      <g filter="url(#filter1_d_12709_9116)">
        <path
          d="M57.8462 110.647H25.8398C23.7178 110.647 22 108.93 22 106.808V62.0441C22 59.9221 23.7178 58.2043 25.8398 58.2043H57.8462C59.9682 58.2043 61.686 59.9221 61.686 62.0441V106.833C61.686 108.955 59.9682 110.647 57.8462 110.647Z"
          fill="url(#paint3_linear_12709_9116)"
        />
      </g>
      <path
        d="M46.3016 97.9409H37.359C36.3738 97.9409 35.5654 97.1325 35.5654 96.1473C35.5654 95.1621 36.3738 94.3538 37.359 94.3538H46.3016C47.2868 94.3538 48.0952 95.1621 48.0952 96.1473C48.0952 97.1325 47.2868 97.9409 46.3016 97.9409Z"
        fill="#D5DDEA"
      />
      <path
        d="M50.4193 101.831H33.2667C32.7362 101.831 32.332 101.402 32.332 100.896C32.332 100.366 32.7615 99.9617 33.2667 99.9617H50.4193C50.9498 99.9617 51.354 100.391 51.354 100.896C51.354 101.402 50.9246 101.831 50.4193 101.831Z"
        fill="#D5DDEA"
      />
      <path
        d="M53.5517 76.8977C53.5517 80.4343 51.9854 83.592 49.5351 85.7392C47.4636 87.5328 44.7859 88.619 41.8303 88.619C38.8747 88.619 36.1717 87.5328 34.1255 85.7392C31.6751 83.592 30.1089 80.4343 30.1089 76.8977C30.1089 70.4307 35.3633 65.1763 41.8303 65.1763C48.2972 65.1763 53.5517 70.4307 53.5517 76.8977Z"
        fill="#D9DEEA"
      />
      <path
        d="M49.5351 85.7398C47.4636 87.5334 44.7859 88.6197 41.8303 88.6197C38.8747 88.6197 36.1717 87.5334 34.1255 85.7398C34.9844 85.4114 36.0959 84.982 37.5863 84.3504C37.94 84.1989 38.1926 83.9463 38.3442 83.6179C38.42 83.441 38.4705 83.2389 38.4705 83.0116V80.2075C38.42 80.157 38.3694 80.0812 38.3442 80.0307C38.0916 79.6771 37.94 79.2476 37.8895 78.8182L37.6369 78.6918C36.9295 78.8687 36.9801 78.0856 36.778 76.6204C36.7022 76.0141 36.8032 75.8878 37.0558 75.7868L37.2832 75.4836C35.8938 72.3259 36.6011 70.3555 37.9653 69.6735C37.5105 68.6883 37.4853 68.3093 37.4853 68.3093C37.4853 68.3093 40.4156 68.7893 41.4261 68.6125C42.7144 68.3599 44.6596 68.663 45.3922 70.3555C46.6047 70.8355 47.0847 71.6186 47.1857 72.427C47.2868 73.2101 47.0594 74.0185 46.8573 74.6753C46.8321 74.7258 46.8321 74.751 46.8068 74.8016C46.8068 74.8268 46.7816 74.8268 46.7816 74.8521C46.7563 74.9279 46.731 75.0037 46.7058 75.0794C46.6805 75.1047 46.6805 75.1552 46.6552 75.1805C46.63 75.2563 46.6047 75.3068 46.5795 75.3826V75.4078C46.5542 75.4584 46.5289 75.5089 46.5289 75.5594C46.5037 75.6352 46.4784 75.6857 46.4784 75.7362C46.5037 75.7615 46.5037 75.7868 46.5037 75.812C46.7058 75.9131 46.7563 76.0646 46.6805 76.5951C46.5037 78.0098 46.5542 78.8687 45.8216 78.6666L45.1901 79.8034C45.1648 79.9297 45.1396 79.9802 45.1396 80.0812C45.1143 80.2833 45.1143 80.7128 45.1143 83.0116C45.1143 83.2895 45.1901 83.5421 45.3416 83.7694C45.4932 84.0221 45.7206 84.2241 45.9984 84.3252H46.0237C47.5899 84.982 48.7014 85.4114 49.5351 85.7398Z"
        fill="white"
      />
      <path
        d="M46.5291 75.7356C46.5796 75.382 46.428 74.9525 46.3017 74.6241C46.2765 74.5736 46.2512 74.5231 46.2259 74.4725C45.6702 73.361 44.4071 72.8811 43.2198 72.8305C40.1884 72.679 39.8853 73.26 38.9506 72.3758C39.2537 72.8305 39.279 73.5126 38.8243 74.3968C38.4959 75.003 37.8896 75.2809 37.3591 75.4577C35.9697 72.3 36.6771 70.3296 38.0412 69.6476C37.5865 68.6624 37.5612 68.2834 37.5612 68.2834C37.5612 68.2834 40.4916 68.7634 41.502 68.5866C42.7904 68.334 44.7355 68.6371 45.4681 70.3296C46.6807 70.8096 47.1606 71.5927 47.2617 72.4011C47.4132 73.7147 46.6554 75.1546 46.5291 75.7356Z"
        fill="url(#paint4_linear_12709_9116)"
      />
      <path
        d="M45.4176 83.7942V84.35H38.3696V83.6174C38.4454 83.4406 38.4959 83.2385 38.4959 83.0111V80.2071C38.4454 80.1565 38.3949 80.0808 38.3696 80.0302V79.8787C38.5212 80.1313 38.7233 80.3839 38.9506 80.5607L41.1484 82.1017C41.6536 82.5311 42.4115 82.5564 42.942 82.1269L45.0134 80.2828C45.0892 80.2323 45.165 80.1565 45.2155 80.0808C45.1903 80.2828 45.1903 80.7123 45.1903 83.0111C45.1903 83.289 45.266 83.5669 45.4176 83.7942Z"
        fill="url(#paint5_linear_12709_9116)"
      />
      <g filter="url(#filter2_d_12709_9116)">
        <path
          d="M174.53 110.647H142.523C140.401 110.647 138.684 108.93 138.684 106.808V62.0441C138.684 59.9221 140.401 58.2043 142.523 58.2043H174.53C176.652 58.2043 178.37 59.9221 178.37 62.0441V106.833C178.37 108.955 176.652 110.647 174.53 110.647Z"
          fill="url(#paint6_linear_12709_9116)"
        />
      </g>
      <path
        d="M162.985 97.9409H154.043C153.057 97.9409 152.249 97.1325 152.249 96.1473C152.249 95.1621 153.057 94.3538 154.043 94.3538H162.985C163.97 94.3538 164.779 95.1621 164.779 96.1473C164.804 97.1325 163.996 97.9409 162.985 97.9409Z"
        fill="#D5DDEA"
      />
      <path
        d="M167.103 101.831H149.95C149.42 101.831 149.016 101.402 149.016 100.896C149.016 100.366 149.445 99.9617 149.95 99.9617H167.103C167.633 99.9617 168.038 100.391 168.038 100.896C168.038 101.402 167.633 101.831 167.103 101.831Z"
        fill="#D5DDEA"
      />
      <path
        d="M170.26 76.8977C170.26 80.4343 168.694 83.592 166.244 85.7392C164.172 87.5328 161.494 88.619 158.539 88.619C155.583 88.619 152.88 87.5328 150.834 85.7392C148.384 83.592 146.817 80.4343 146.817 76.8977C146.817 70.4307 152.072 65.1763 158.539 65.1763C165.006 65.1763 170.26 70.4307 170.26 76.8977Z"
        fill="#D9DEEA"
      />
      <path
        d="M166.244 85.7398C164.173 87.5334 161.495 88.6197 158.539 88.6197C155.584 88.6197 152.881 87.5334 150.834 85.7398C151.693 85.4114 152.805 84.982 154.295 84.3504C154.649 84.1989 154.902 83.9463 155.053 83.6179C155.129 83.441 155.179 83.2389 155.179 83.0116V80.2075C155.129 80.157 155.078 80.0812 155.053 80.0307C154.801 79.6771 154.649 79.2476 154.598 78.8182L154.346 78.6918C153.639 78.8687 153.689 78.0856 153.487 76.6204C153.411 76.0141 153.512 75.8878 153.765 75.7868L153.992 75.4836C152.603 72.3259 153.31 70.3555 154.674 69.6735C154.22 68.6883 154.194 68.3093 154.194 68.3093C154.194 68.3093 157.125 68.7893 158.135 68.6125C159.423 68.3599 161.369 68.663 162.101 70.3555C163.314 70.8355 163.794 71.6186 163.895 72.427C163.996 73.2101 163.768 74.0185 163.566 74.6753C163.541 74.7258 163.541 74.751 163.516 74.8016C163.516 74.8268 163.491 74.8268 163.491 74.8521C163.465 74.9279 163.44 75.0037 163.415 75.0794C163.389 75.1047 163.389 75.1552 163.364 75.1805C163.339 75.2563 163.314 75.3068 163.288 75.3826V75.4078C163.263 75.4584 163.238 75.5089 163.238 75.5594C163.213 75.6352 163.187 75.6857 163.187 75.7362C163.213 75.7615 163.213 75.7868 163.213 75.812C163.415 75.9131 163.465 76.0646 163.389 76.5951C163.213 78.0098 163.263 78.8687 162.531 78.6666L161.899 79.8034C161.874 79.9297 161.849 79.9802 161.849 80.0812C161.823 80.2833 161.823 80.7128 161.823 83.0116C161.823 83.2895 161.899 83.5421 162.051 83.7694C162.202 84.0221 162.43 84.2241 162.707 84.3252H162.733C164.274 84.982 165.385 85.4114 166.244 85.7398Z"
        fill="white"
      />
      <path
        d="M163.213 75.7356C163.263 75.382 163.112 74.9525 162.985 74.6241C162.96 74.5736 162.935 74.5231 162.91 74.4725C162.354 73.361 161.091 72.8811 159.903 72.8305C156.872 72.679 156.569 73.26 155.634 72.3758C155.937 72.8305 155.963 73.5126 155.508 74.3968C155.179 75.003 154.573 75.2809 154.043 75.4577C152.653 72.3 153.361 70.3296 154.725 69.6476C154.27 68.6624 154.245 68.2834 154.245 68.2834C154.245 68.2834 157.175 68.7634 158.186 68.5866C159.474 68.334 161.419 68.6371 162.152 70.3296C163.364 70.8096 163.844 71.5927 163.945 72.4011C164.097 73.7147 163.364 75.1546 163.213 75.7356Z"
        fill="url(#paint7_linear_12709_9116)"
      />
      <path
        d="M162.101 83.7942V84.35H155.053V83.6174C155.129 83.4406 155.18 83.2385 155.18 83.0111V80.2071C155.129 80.1565 155.078 80.0808 155.053 80.0302V79.8787C155.205 80.1313 155.407 80.3839 155.634 80.5607L157.832 82.1017C158.337 82.5311 159.095 82.5564 159.626 82.1269L161.697 80.2828C161.773 80.2323 161.849 80.1565 161.899 80.0808C161.874 80.2828 161.874 80.7123 161.874 83.0111C161.874 83.289 161.95 83.5669 162.101 83.7942Z"
        fill="url(#paint8_linear_12709_9116)"
      />
      <path
        d="M168.543 158.821L136.587 126.89L143.913 119.564L175.843 151.52L168.543 158.821Z"
        fill="#858B9B"
      />
      <path
        d="M184.457 174.863L159.954 150.359C157.907 148.313 157.907 144.978 159.954 142.932C162 140.886 165.334 140.886 167.381 142.932L191.884 167.436C193.93 169.482 193.93 172.817 191.884 174.863C189.838 176.909 186.504 176.909 184.457 174.863Z"
        fill="#858B9B"
      />
      <path
        d="M182.715 176.428L151.188 144.902C150.456 144.169 150.456 142.982 151.188 142.224L159.247 134.166C159.979 133.433 161.166 133.433 161.924 134.166L193.451 165.692C194.183 166.425 194.183 167.612 193.451 168.37L185.392 176.428C184.634 177.161 183.447 177.161 182.715 176.428Z"
        fill="url(#paint9_linear_12709_9116)"
      />
      <path
        d="M100.412 24C66.9661 24 39.8604 51.1057 39.8604 84.5521C39.8604 117.973 66.9661 145.104 100.387 145.104C133.834 145.104 160.939 117.998 160.939 84.5521C160.939 51.1057 133.834 24 100.412 24ZM100.412 133.888C73.5593 133.888 51.8091 111.784 51.8091 84.5268C51.8091 57.2695 73.5593 35.1909 100.412 35.1909C127.266 35.1909 149.016 57.2948 149.016 84.5521C149.016 111.809 127.24 133.888 100.412 133.888Z"
        fill="url(#paint10_linear_12709_9116)"
      />
      <defs>
        <filter
          id="filter0_d_12709_9116"
          x="49.8413"
          y="35.4575"
          width="101.445"
          height="119.937"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="11" />
          <feGaussianBlur stdDeviation="11" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12709_9116"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12709_9116"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_12709_9116"
          x="0"
          y="47.2043"
          width="83.686"
          height="96.4431"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="11" />
          <feGaussianBlur stdDeviation="11" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12709_9116"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12709_9116"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_12709_9116"
          x="116.684"
          y="47.2043"
          width="83.686"
          height="96.4431"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="11" />
          <feGaussianBlur stdDeviation="11" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12709_9116"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12709_9116"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_12709_9116"
          x1="100.545"
          y1="44.701"
          x2="100.545"
          y2="123.213"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FDFEFF" />
          <stop offset="0.9964" stopColor="#ECF0F5" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_12709_9116"
          x1="92.9109"
          y1="66.4531"
          x2="108.399"
          y2="66.4531"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#B0BACC" />
          <stop offset="1" stopColor="#969EAE" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_12709_9116"
          x1="100.617"
          y1="84.3132"
          x2="100.617"
          y2="80.7506"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="#E2E5EC" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_12709_9116"
          x1="41.8301"
          y1="56.9913"
          x2="41.8301"
          y2="111.213"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FDFEFF" />
          <stop offset="0.9964" stopColor="#ECF0F5" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_12709_9116"
          x1="36.5996"
          y1="72.0103"
          x2="47.2836"
          y2="72.0103"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#B0BACC" />
          <stop offset="1" stopColor="#969EAE" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_12709_9116"
          x1="41.8958"
          y1="84.3772"
          x2="41.8958"
          y2="81.914"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="#E2E5EC" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_12709_9116"
          x1="158.514"
          y1="56.9913"
          x2="158.514"
          y2="111.213"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FDFEFF" />
          <stop offset="0.9964" stopColor="#ECF0F5" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_12709_9116"
          x1="153.283"
          y1="72.0103"
          x2="163.968"
          y2="72.0103"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#B0BACC" />
          <stop offset="1" stopColor="#969EAE" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_12709_9116"
          x1="158.579"
          y1="84.3772"
          x2="158.579"
          y2="81.914"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="#E2E5EC" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_12709_9116"
          x1="150.619"
          y1="155.302"
          x2="194.008"
          y2="155.302"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#B0BACC" />
          <stop offset="1" stopColor="#969EAE" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_12709_9116"
          x1="39.8049"
          y1="84.5652"
          x2="160.961"
          y2="84.5652"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#B0BACC" />
          <stop offset="1" stopColor="#969EAE" />
        </linearGradient>
      </defs>
    </svg>
  ) : (
    <svg
      width={size}
      height={size}
      viewBox="0 0 208 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_12709_9152)">
        <path
          d="M125.446 122.393H75.6811C73.5591 122.393 71.8413 120.676 71.8413 118.554V50.2968C71.8413 48.1748 73.5591 46.457 75.6811 46.457H125.446C127.568 46.457 129.286 48.1748 129.286 50.2968V118.554C129.286 120.676 127.568 122.393 125.446 122.393Z"
          fill="url(#paint0_linear_12709_9152)"
        />
      </g>
      <path
        d="M107.031 103.979H94.0971C92.6571 103.979 91.4951 102.817 91.4951 101.377C91.4951 99.9369 92.6571 98.7749 94.0971 98.7749H107.031C108.471 98.7749 109.633 99.9369 109.633 101.377C109.633 102.817 108.471 103.979 107.031 103.979Z"
        fill="#A9AFC3"
      />
      <path
        d="M112.967 109.611H88.1346C87.3768 109.611 86.7705 109.005 86.7705 108.247C86.7705 107.489 87.3768 106.883 88.1346 106.883H112.967C113.725 106.883 114.331 107.489 114.331 108.247C114.331 108.98 113.725 109.611 112.967 109.611Z"
        fill="#A9AFC3"
      />
      <path
        d="M117.539 73.5377C117.539 78.6405 115.291 83.2129 111.729 86.3201C108.748 88.922 104.832 90.4882 100.563 90.4882C96.294 90.4882 92.3784 88.922 89.3976 86.2948C85.8357 83.1876 83.5874 78.6153 83.5874 73.5124C83.5874 64.1404 91.1911 56.5366 100.563 56.5366C109.935 56.5366 117.539 64.1656 117.539 73.5377Z"
        fill="#4B5161"
      />
      <path
        d="M111.73 86.3202C108.749 88.9221 104.833 90.4884 100.564 90.4884C96.2949 90.4884 92.3793 88.9222 89.3984 86.2949C90.6363 85.815 92.253 85.1834 94.4255 84.2993C94.9307 84.0972 95.3097 83.7183 95.537 83.2383C95.6633 82.9604 95.7138 82.6825 95.7138 82.3794V78.3123C95.6381 78.2365 95.5875 78.1354 95.537 78.0597C95.1834 77.5292 94.956 76.9229 94.8802 76.2913L94.5265 76.1145C93.4908 76.3671 93.5666 75.2556 93.2887 73.1084C93.1877 72.2242 93.314 72.0474 93.7182 71.8958L94.0466 71.4411C92.0257 66.894 93.0361 64.0395 95.0318 63.029C94.375 61.5891 94.3245 61.0586 94.3245 61.0586C94.3245 61.0586 98.5684 61.7659 100.008 61.488C101.852 61.1091 104.707 61.5638 105.768 64.0142C107.536 64.6963 108.218 65.833 108.37 67.0203C108.521 68.1318 108.193 69.3444 107.865 70.2791C107.839 70.3549 107.814 70.4054 107.789 70.4559C107.789 70.4812 107.764 70.5064 107.764 70.5317C107.713 70.658 107.688 70.7591 107.637 70.8601C107.612 70.9106 107.612 70.9611 107.587 71.0117C107.562 71.1127 107.511 71.2138 107.486 71.2895C107.486 71.3148 107.486 71.3148 107.46 71.3401C107.435 71.4159 107.41 71.4916 107.385 71.5674C107.359 71.6685 107.334 71.7443 107.309 71.82C107.334 71.8453 107.334 71.8958 107.359 71.9211C107.637 72.0474 107.738 72.3 107.637 73.0831C107.385 75.1293 107.46 76.3671 106.4 76.0893L105.465 77.706C105.44 77.8828 105.414 77.9839 105.389 78.0849C105.364 78.3881 105.364 78.9943 105.364 82.3289C105.364 82.7331 105.49 83.112 105.692 83.4404C105.92 83.794 106.248 84.0972 106.652 84.274C106.652 84.274 106.677 84.274 106.677 84.2993C108.875 85.234 110.492 85.8402 111.73 86.3202Z"
        fill="#A9AFC3"
      />
      <path
        d="M107.336 71.8453C107.412 71.3401 107.184 70.6833 107.007 70.2538C106.982 70.178 106.932 70.1023 106.906 70.0265C106.098 68.4097 104.279 67.7277 102.561 67.6266C98.1658 67.3993 97.7364 68.2329 96.3722 66.9698C96.8017 67.6014 96.827 68.6371 96.1701 69.9002C95.7154 70.7843 94.8313 71.1885 94.0229 71.4411C92.002 66.894 93.0124 64.0395 95.0081 63.029C94.3513 61.5891 94.3008 61.0586 94.3008 61.0586C94.3008 61.0586 98.5447 61.7659 99.9847 61.488C101.829 61.1091 104.683 61.5638 105.744 64.0142C107.513 64.6963 108.195 65.833 108.346 67.0203C108.649 68.915 107.538 71.0117 107.336 71.8453Z"
        fill="#292D35"
      />
      <path
        d="M105.718 83.491V84.2741H95.5122V83.2384C95.6385 82.9605 95.689 82.6826 95.689 82.3795V78.3124C95.6133 78.2366 95.5627 78.1355 95.5122 78.0597V77.8071C95.7396 78.1861 96.0174 78.5145 96.3458 78.8176L99.5288 81.0659C100.261 81.6974 101.348 81.6974 102.105 81.0911L105.086 78.4134C105.187 78.3376 105.288 78.2366 105.389 78.1355C105.364 78.4387 105.364 79.0449 105.364 82.3795C105.415 82.7837 105.516 83.1626 105.718 83.491Z"
        fill="url(#paint1_linear_12709_9152)"
      />
      <g filter="url(#filter1_d_12709_9152)">
        <path
          d="M57.8462 110.647H25.8398C23.7178 110.647 22 108.929 22 106.807V62.0439C22 59.9219 23.7178 58.2041 25.8398 58.2041H57.8462C59.9682 58.2041 61.686 59.9219 61.686 62.0439V106.833C61.686 108.955 59.9682 110.647 57.8462 110.647Z"
          fill="#414758"
        />
      </g>
      <path
        d="M46.3026 97.9412H37.36C36.3748 97.9412 35.5664 97.1328 35.5664 96.1476C35.5664 95.1624 36.3748 94.354 37.36 94.354H46.3026C47.2878 94.354 48.0962 95.1624 48.0962 96.1476C48.0962 97.1328 47.2878 97.9412 46.3026 97.9412Z"
        fill="#8E94A7"
      />
      <path
        d="M50.4184 101.831H33.2657C32.7352 101.831 32.3311 101.401 32.3311 100.896C32.3311 100.366 32.7605 99.9614 33.2657 99.9614H50.4184C50.9489 99.9614 51.353 100.391 51.353 100.896C51.353 101.401 50.9236 101.831 50.4184 101.831Z"
        fill="#8E94A7"
      />
      <path
        d="M53.5507 76.8981C53.5507 80.4348 51.9845 83.5925 49.5341 85.7397C47.4626 87.5333 44.7849 88.6195 41.8293 88.6195C38.8737 88.6195 36.1707 87.5333 34.1245 85.7397C31.6741 83.5925 30.1079 80.4348 30.1079 76.8981C30.1079 70.4312 35.3623 65.1768 41.8293 65.1768C48.2963 65.1768 53.5507 70.4312 53.5507 76.8981Z"
        fill="#4B5161"
      />
      <path
        d="M49.5346 85.7401C47.4631 87.5337 44.7854 88.6199 41.8298 88.6199C38.8742 88.6199 36.1712 87.5337 34.125 85.7401C34.9839 85.4117 36.0954 84.9822 37.5858 84.3507C37.9395 84.1991 38.1921 83.9465 38.3437 83.6181C38.4195 83.4413 38.47 83.2392 38.47 83.0118V80.2078C38.4195 80.1573 38.3689 80.0815 38.3437 80.031C38.0911 79.6773 37.9395 79.2478 37.889 78.8184L37.6364 78.6921C36.929 78.8689 36.9796 78.0858 36.7775 76.6206C36.7017 76.0144 36.8027 75.8881 37.0553 75.787L37.2827 75.4839C35.8933 72.3262 36.6006 70.3558 37.9648 69.6737C37.5101 68.6885 37.4848 68.3096 37.4848 68.3096C37.4848 68.3096 40.4151 68.7895 41.4256 68.6127C42.7139 68.3601 44.6591 68.6632 45.3917 70.3558C46.6042 70.8357 47.0842 71.6188 47.1853 72.4272C47.2863 73.2103 47.0589 74.0187 46.8569 74.6755C46.8316 74.726 46.8316 74.7513 46.8063 74.8018C46.8063 74.8271 46.7811 74.8271 46.7811 74.8523C46.7558 74.9281 46.7305 75.0039 46.7053 75.0797C46.68 75.1049 46.68 75.1555 46.6548 75.1807C46.6295 75.2565 46.6042 75.307 46.579 75.3828V75.4081C46.5537 75.4586 46.5285 75.5091 46.5285 75.5597C46.5032 75.6354 46.4779 75.686 46.4779 75.7365C46.5032 75.7617 46.5032 75.787 46.5032 75.8123C46.7053 75.9133 46.7558 76.0649 46.68 76.5954C46.5032 78.01 46.5537 78.8689 45.8211 78.6668L45.1896 79.8036C45.1643 79.9299 45.1391 79.9804 45.1391 80.0815C45.1138 80.2836 45.1138 80.713 45.1138 83.0118C45.1138 83.2897 45.1896 83.5423 45.3412 83.7697C45.4927 84.0223 45.7201 84.2244 45.998 84.3254H46.0232C47.5894 84.9822 48.7009 85.4117 49.5346 85.7401Z"
        fill="#8E94A7"
      />
      <path
        d="M46.5291 75.7354C46.5796 75.3817 46.428 74.9523 46.3017 74.6239C46.2765 74.5733 46.2512 74.5228 46.2259 74.4723C45.6702 73.3608 44.4071 72.8808 43.2198 72.8303C40.1884 72.6787 39.8853 73.2597 38.9506 72.3756C39.2537 72.8303 39.279 73.5124 38.8243 74.3965C38.4959 75.0028 37.8896 75.2807 37.3591 75.4575C35.9697 72.2998 36.6771 70.3294 38.0412 69.6473C37.5865 68.6621 37.5612 68.2832 37.5612 68.2832C37.5612 68.2832 40.4916 68.7632 41.502 68.5863C42.7904 68.3337 44.7355 68.6369 45.4681 70.3294C46.6807 70.8094 47.1606 71.5925 47.2617 72.4008C47.4132 73.7145 46.6554 75.1544 46.5291 75.7354Z"
        fill="#292D35"
      />
      <path
        d="M45.4166 83.794V84.3497H38.3687V83.6171C38.4444 83.4403 38.495 83.2382 38.495 83.0109V80.2068C38.4444 80.1563 38.3939 80.0805 38.3687 80.03V79.8784C38.5202 80.131 38.7223 80.3836 38.9497 80.5605L41.1474 82.1014C41.6527 82.5309 42.4105 82.5562 42.941 82.1267L45.0125 80.2826C45.0882 80.2321 45.164 80.1563 45.2145 80.0805C45.1893 80.2826 45.1893 80.7121 45.1893 83.0109C45.1893 83.2887 45.2651 83.5666 45.4166 83.794Z"
        fill="url(#paint2_linear_12709_9152)"
      />
      <g filter="url(#filter2_d_12709_9152)">
        <path
          d="M174.53 110.647H142.524C140.402 110.647 138.684 108.929 138.684 106.807V62.0439C138.684 59.9219 140.402 58.2041 142.524 58.2041H174.53C176.652 58.2041 178.37 59.9219 178.37 62.0439V106.833C178.37 108.955 176.652 110.647 174.53 110.647Z"
          fill="#414758"
        />
      </g>
      <path
        d="M162.985 97.9412H154.042C153.057 97.9412 152.249 97.1328 152.249 96.1476C152.249 95.1624 153.057 94.354 154.042 94.354H162.985C163.97 94.354 164.778 95.1624 164.778 96.1476C164.804 97.1328 163.995 97.9412 162.985 97.9412Z"
        fill="#8E94A7"
      />
      <path
        d="M167.102 101.831H149.95C149.419 101.831 149.015 101.401 149.015 100.896C149.015 100.366 149.445 99.9614 149.95 99.9614H167.102C167.633 99.9614 168.037 100.391 168.037 100.896C168.037 101.401 167.633 101.831 167.102 101.831Z"
        fill="#8E94A7"
      />
      <path
        d="M170.261 76.8981C170.261 80.4348 168.695 83.5925 166.245 85.7397C164.173 87.5333 161.495 88.6195 158.54 88.6195C155.584 88.6195 152.881 87.5333 150.835 85.7397C148.385 83.5925 146.818 80.4348 146.818 76.8981C146.818 70.4312 152.073 65.1768 158.54 65.1768C165.007 65.1768 170.261 70.4312 170.261 76.8981Z"
        fill="#4B5161"
      />
      <path
        d="M166.242 85.7401C164.171 87.5337 161.493 88.6199 158.537 88.6199C155.582 88.6199 152.879 87.5337 150.833 85.7401C151.691 85.4117 152.803 84.9822 154.293 84.3507C154.647 84.1991 154.9 83.9465 155.051 83.6181C155.127 83.4413 155.178 83.2392 155.178 83.0118V80.2078C155.127 80.1573 155.076 80.0815 155.051 80.031C154.799 79.6773 154.647 79.2478 154.597 78.8184L154.344 78.6921C153.637 78.8689 153.687 78.0858 153.485 76.6206C153.409 76.0144 153.51 75.8881 153.763 75.787L153.99 75.4839C152.601 72.3262 153.308 70.3558 154.672 69.6737C154.218 68.6885 154.192 68.3096 154.192 68.3096C154.192 68.3096 157.123 68.7895 158.133 68.6127C159.421 68.3601 161.367 68.6632 162.099 70.3558C163.312 70.8357 163.792 71.6188 163.893 72.4272C163.994 73.2103 163.766 74.0187 163.564 74.6755C163.539 74.726 163.539 74.7513 163.514 74.8018C163.514 74.8271 163.489 74.8271 163.489 74.8523C163.463 74.9281 163.438 75.0039 163.413 75.0797C163.388 75.1049 163.388 75.1555 163.362 75.1807C163.337 75.2565 163.312 75.307 163.286 75.3828V75.4081C163.261 75.4586 163.236 75.5091 163.236 75.5597C163.211 75.6354 163.185 75.686 163.185 75.7365C163.211 75.7617 163.211 75.787 163.211 75.8123C163.413 75.9133 163.463 76.0649 163.388 76.5954C163.211 78.01 163.261 78.8689 162.529 78.6668L161.897 79.8036C161.872 79.9299 161.847 79.9804 161.847 80.0815C161.821 80.2836 161.821 80.713 161.821 83.0118C161.821 83.2897 161.897 83.5423 162.049 83.7697C162.2 84.0223 162.428 84.2244 162.705 84.3254H162.731C164.272 84.9822 165.383 85.4117 166.242 85.7401Z"
        fill="#8E94A7"
      />
      <path
        d="M163.211 75.7354C163.261 75.3817 163.11 74.9523 162.983 74.6239C162.958 74.5733 162.933 74.5228 162.908 74.4723C162.352 73.3608 161.089 72.8808 159.901 72.8303C156.87 72.6787 156.567 73.2597 155.632 72.3756C155.935 72.8303 155.961 73.5124 155.506 74.3965C155.178 75.0028 154.571 75.2807 154.041 75.4575C152.651 72.2998 153.359 70.3294 154.723 69.6473C154.268 68.6621 154.243 68.2832 154.243 68.2832C154.243 68.2832 157.173 68.7632 158.184 68.5863C159.472 68.3337 161.417 68.6369 162.15 70.3294C163.362 70.8094 163.842 71.5925 163.943 72.4008C164.095 73.7145 163.362 75.1544 163.211 75.7354Z"
        fill="#292D35"
      />
      <path
        d="M162.1 83.794V84.3497H155.052V83.6171C155.128 83.4403 155.178 83.2382 155.178 83.0109V80.2068C155.128 80.1563 155.077 80.0805 155.052 80.03V79.8784C155.203 80.131 155.405 80.3836 155.633 80.5605L157.831 82.1014C158.336 82.5309 159.094 82.5562 159.624 82.1267L161.696 80.2826C161.771 80.2321 161.847 80.1563 161.898 80.0805C161.872 80.2826 161.872 80.7121 161.872 83.0109C161.872 83.2887 161.948 83.5666 162.1 83.794Z"
        fill="url(#paint3_linear_12709_9152)"
      />
      <path
        d="M168.543 158.821L136.587 126.891L143.913 119.565L175.844 151.521L168.543 158.821Z"
        fill="#858B9B"
      />
      <path
        d="M184.457 174.863L159.954 150.359C157.907 148.313 157.907 144.978 159.954 142.932C162 140.886 165.334 140.886 167.381 142.932L191.884 167.436C193.93 169.482 193.93 172.817 191.884 174.863C189.838 176.909 186.504 176.909 184.457 174.863Z"
        fill="#858B9B"
      />
      <path
        d="M182.716 176.428L151.189 144.901C150.457 144.169 150.457 142.981 151.189 142.224L159.248 134.165C159.98 133.433 161.167 133.433 161.925 134.165L193.452 165.692C194.184 166.424 194.184 167.612 193.452 168.369L185.393 176.428C184.635 177.16 183.448 177.16 182.716 176.428Z"
        fill="url(#paint4_linear_12709_9152)"
      />
      <path
        d="M100.412 24C66.9656 24 39.8599 51.1057 39.8599 84.5521C39.8599 117.973 66.9656 145.104 100.387 145.104C133.833 145.104 160.939 117.998 160.939 84.5521C160.939 51.1057 133.833 24 100.412 24ZM100.412 133.888C73.5588 133.888 51.8086 111.784 51.8086 84.5268C51.8086 57.2695 73.5588 35.1909 100.412 35.1909C127.265 35.1909 149.015 57.2948 149.015 84.5521C149.015 111.809 127.24 133.888 100.412 133.888Z"
        fill="url(#paint5_linear_12709_9152)"
      />
      <defs>
        <filter
          id="filter0_d_12709_9152"
          x="49.8413"
          y="35.457"
          width="101.445"
          height="119.937"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="11" />
          <feGaussianBlur stdDeviation="11" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.27 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12709_9152"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12709_9152"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_12709_9152"
          x="0"
          y="47.2041"
          width="83.686"
          height="96.4429"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="11" />
          <feGaussianBlur stdDeviation="11" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.27 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12709_9152"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12709_9152"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_12709_9152"
          x="116.684"
          y="47.2041"
          width="83.686"
          height="96.4429"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="11" />
          <feGaussianBlur stdDeviation="11" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.27 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12709_9152"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12709_9152"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_12709_9152"
          x1="100.545"
          y1="44.7005"
          x2="100.545"
          y2="123.212"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#5C6479" />
          <stop offset="0.9964" stopColor="#5C6479" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_12709_9152"
          x1="100.595"
          y1="84.0727"
          x2="100.595"
          y2="80.6853"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#8E94A7" />
          <stop offset="0.9913" stopColor="#6C707C" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_12709_9152"
          x1="41.879"
          y1="84.2105"
          x2="41.879"
          y2="81.8684"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#8E94A7" />
          <stop offset="0.9913" stopColor="#6C707C" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_12709_9152"
          x1="158.562"
          y1="84.2105"
          x2="158.562"
          y2="81.8684"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#8E94A7" />
          <stop offset="0.9913" stopColor="#6C707C" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_12709_9152"
          x1="150.62"
          y1="155.301"
          x2="194.009"
          y2="155.301"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#B0BACC" />
          <stop offset="1" stopColor="#969EAE" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_12709_9152"
          x1="39.8044"
          y1="84.5652"
          x2="160.961"
          y2="84.5652"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#B0BACC" />
          <stop offset="1" stopColor="#969EAE" />
        </linearGradient>
      </defs>
    </svg>
  );
}
