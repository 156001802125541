import React from 'react';
import CircleAccept from 'shared/icons/CircleAccept';
import Info from 'shared/icons/Info';
import {Content} from 'shared/ui/Content';
import {Text} from 'shared/ui/Text';
import {Tooltip} from 'shared/ui/Tooltip';
import {useQuery} from '@apollo/client';
import styled from 'styled-components';
import {device} from 'shared/device';
import {Flex} from 'shared/ui/Flex';
import dayjs from 'dayjs';
import {useColors} from 'shared/lib/hooks';
import {TextView} from 'shared/ui/TextView';
import {Seo} from 'shared/ui/Seo';
import {GET_PROFILE} from 'entities/profile';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  padding-bottom: 24px;
  flex-direction: column;
  margin-top: 8px;
  @media (${device.mobile}) {
    gap: 8px;
    margin-bottom: 0;
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 0;
  }
`;

export function Profile() {
  const colors = useColors();
  const {data} = useQuery(GET_PROFILE);
  const user = data?.me;
  const emailConfirmed = !user?.temp_email && user?.email != null;
  const getGenderLabel = ({gender}: {gender: string | undefined | null}) => {
    if (gender === 'F') return 'Женский';
    if (gender === 'M') return 'Мужской';
    return '-';
  };
  return (
    <Wrapper>
      <Seo title="Основная информация" />
      <Content gap="32px">
        <Flex direction="column" gap={16}>
          <Text typography="title18">Основная информация</Text>
          <TextView
            title="Дата рожднния"
            text={
              user?.birthdate
                ? dayjs(user?.birthdate).format('YYYY-MM-DD')
                : 'Не указано'
            }
          />
          <TextView
            title="Пол"
            text={getGenderLabel({gender: user?.gender}) ?? 'Не указано'}
          />
        </Flex>
        <Flex direction="column" gap={16}>
          <Text typography="title18">Контакты</Text>
          <Flex gap={8}>
            <TextView title="Телефон" text={user?.phone ?? 'Не указано'} />
            <TextView
              title="Email"
              text={
                user?.temp_email
                  ? user?.temp_email
                  : user?.email ?? 'Не указано'
              }
              rightElement={() => (
                <Tooltip
                  position="right"
                  style={{padding: '8px 12px', borderRadius: 8}}
                  textStyle={{textAlign: 'center'}}
                  text={
                    emailConfirmed
                      ? 'Почта подтверждена'
                      : 'Почта не подтверждена'
                  }>
                  {emailConfirmed ? (
                    <CircleAccept color={colors.successPrimary} />
                  ) : user?.temp_email ? (
                    <Info />
                  ) : null}
                </Tooltip>
              )}
            />
          </Flex>
        </Flex>
      </Content>
    </Wrapper>
  );
}
