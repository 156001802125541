import React from 'react';
import {Text} from './Text';
import styled from 'styled-components';
import {toast} from 'react-toastify';
import {store} from 'shared/store';
import {colors} from 'shared/colors';
import {CircleAlert} from 'shared/icons/CircleAlert';
import Close from 'shared/icons/Close';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const CloseWrapper = styled.div`
  width: 24px;
  height: 24px;
`;

type Props = {
  title?: string;
  description?: string;
};

export const Notification = (props: Props) => {
  const {title, description} = props;
  return (
    <Wrapper>
      <Text typography="title18" color="textPrimary">
        {title}
      </Text>
      <Text typography="text16Regular" color="textPrimary">
        {description}
      </Text>
    </Wrapper>
  );
};

export const showNotification = (title: string, description: string) => {
  const theme = store.getState().theme.theme;

  return toast(<Notification title={title} description={description} />, {
    position: 'top-right',
    hideProgressBar: true,
    style: {
      borderRadius: 16,
      backgroundColor: colors[theme].bgPrimary,
      padding: '15px 16px',
    },
    autoClose: 3000,
    icon: <CircleAlert size={24} color={colors[theme].textTertiary} />,
    closeButton: (
      <CloseWrapper>
        <Close />
      </CloseWrapper>
    ),
  });
};
