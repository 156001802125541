import {gql} from 'shared/__generated__';
import {WebhookStatus} from './ui/WebhookStatus';
import {SendWebHookType} from './lib/utils';
import {Key} from './ui/Key';
import {TitleContent} from './ui/TitleContent';
import {WebhookRadioBox} from './ui/WebhookRadioBox';

const GET_WEBHOOKS = gql(`
  query GetWebHooks($companyId: String!) {
    getWebHooks(company_id: $companyId) {
      id
      url
      send_type
      type
      is_active
      errorsLastSevenDays
      errorsPercent
    }
  }
`);

export {
  GET_WEBHOOKS,
  WebhookStatus,
  SendWebHookType,
  WebhookRadioBox,
  TitleContent,
  Key,
};
