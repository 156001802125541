import {useMutation} from '@apollo/client';
import {GET_CERTIFICATE_TEMPLATES} from 'entities/loyalty';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {gql} from 'shared/__generated__';
import {useColors} from 'shared/lib/hooks';
import {useAppSelector} from 'shared/store';
import {showAlert} from 'shared/ui/Alert';
import {Button} from 'shared/ui/Button';
import {CloseButton} from 'shared/ui/CloseButton';
import {Flex} from 'shared/ui/Flex';
import {Popup} from 'shared/ui/Popup';
import {Text} from 'shared/ui/Text';

const DELETE_CERTIFICATE = gql(`
mutation DeleteCertificateTemplate($id: String!) {
    deleteCertificateTemplate(id: $id) {
      id
    }
  }
`);

export const DeleteCertificate = ({
  id,
  visible,
  onClose,
}: {
  id: string;
  visible: boolean;
  onClose: () => void;
}) => {
  const colors = useColors();
  const navigate = useNavigate();
  const companyId = useAppSelector(state => state.company.data?.id);
  const [deleteCertificate, {loading}] = useMutation(DELETE_CERTIFICATE, {
    refetchQueries: [
      {
        query: GET_CERTIFICATE_TEMPLATES,
        variables: {
          companyId,
          page: 1,
          first: 40,
        },
      },
    ],
  });

  return (
    <Popup
      style={{width: 472, padding: '24px'}}
      visible={visible}
      onClose={onClose}>
      <Flex flex={1} direction="column" style={{width: '100%'}} gap={16}>
        <Flex
          direction="row"
          alignItems="center"
          justifyContent="space-between">
          <Text typography="title20">Удалить сертификат</Text>
          <CloseButton size={40} onClose={() => onClose()} />
        </Flex>
        <Text>Вы уверены, что хотите удалить сертификат?</Text>
        <Flex
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          gap={8}>
          <Button
            size="large"
            variant="outline"
            onClick={onClose}
            style={{flex: 1, borderColor: colors.mainPrimary}}>
            Отмена
          </Button>
          <Button
            size="large"
            variant="outline"
            loading={loading}
            onClick={() => {
              deleteCertificate({
                variables: {
                  id,
                },
              })
                .then(() => {
                  onClose();
                  navigate(-1);
                })
                .catch(e => {
                  showAlert('error', e.message);
                });
            }}
            style={{
              flex: 1,
              color: colors.dangerPrimary,
              borderColor: colors.dangerPrimary,
            }}>
            Удалить
          </Button>
        </Flex>
      </Flex>
    </Popup>
  );
};
