import React from 'react';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';
import {useColors} from 'shared/lib/hooks';
import {CircleClose} from 'shared/icons/CircleClose';

const BadgeContainer = styled.div`
  display: flex;
  border-radius: 8px;
  border: 1px solid ${({theme}) => theme.borderPrimary};
  background-color: ${({theme}) => theme.bgPrimary};
  height: 40px;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  gap: 4px;
`;
const CloseButton = styled.button`
  padding: 0;
  border: none;
  background: none;
  height: 16px;
  width: 16px;
  border-width: 8px;
  align-items: center;
  justify-content: center;
  pointer-events: all;
  cursor: pointer;
`;

export const BadgeTag = ({
  name,
  color,
  onClose,
  style,
}: {
  name?: string;
  color?: string;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  style?: React.CSSProperties;
}) => {
  const colors = useColors();
  return (
    <BadgeContainer>
      <Text
        style={{
          color: `rgb(${color})` ?? colors.textPrimary,
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          maxWidth: 120,
          overflow: 'hidden',
          ...style,
        }}>
        {name}
      </Text>
      {onClose && (
        <CloseButton onClick={onClose}>
          <CircleClose size={16} color={colors.textSecondary} />
        </CloseButton>
      )}
    </BadgeContainer>
  );
};
