import React from 'react';
import {Text} from 'shared/ui/Text';
import {Grow} from 'shared/ui/Grow';
import styled from 'styled-components';
import {EmptyAnalytics} from 'shared/illustration/EmptyAnalytics';

const Container = styled(Grow)`
  gap: 16px;
  min-height: 400px;
  @media ${`(min-height: 736px)`} {
    margin-top: calc(((100dvh - 400px) / 2) - 168px);
  }
`;

export const FilterEmptyState = ({
  title = 'Ничего не найдено',
  description = 'Проверьте данные и попробуйте еще раз',
}: {
  title?: string;
  description?: string;
}) => {
  return (
    <Container>
      <EmptyAnalytics size={300} />
      <Text typography="title20" align="center">
        {title}
      </Text>
      {description?.length ? (
        <Text color="textTertiary" typography="text18" align="center">
          {description}
        </Text>
      ) : null}
    </Container>
  );
};
