import React, {useState} from 'react';
import {styled} from 'styled-components';
import {OptionsType, Table, TableSortType} from 'shared/ui/Table';
import {gql} from 'shared/__generated__';
import {useAppSelector} from 'shared/store';
import {useQuery} from '@apollo/client';
import {
  QueryGetStatisticsSalesDateOrderByColumn,
  SortOrder,
  StatisticsDateTime,
  StatisticsDates,
} from 'shared/__generated__/graphql';
import {Flex} from 'shared/ui/Flex';
import {
  FilterEmptyState,
  FilterResetButton,
  FilterSearch,
  FilterStatisticsPeriod,
  FilterStatisticsSales,
} from 'entities/statistics';
import dayjs from 'dayjs';
import {getNumberFormat, getPriceFormat} from 'shared/lib/utils';
import {Content} from 'shared/ui/Content';
import {PageHeader} from 'shared/ui/PageHeader';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
`;

const GET_STATISTICS_SALES_DATE = gql(`
  query GetStatisticsSalesDate(
    $companyId: String!
    $date: StatisticsDates!
    $time: StatisticsDateTime!
    $first: Int!
    $page: Int
    $query: String
    $orderBy: [QueryGetStatisticsSalesDateOrderByOrderByClause!]
  ) {
    getStatisticsSalesDate(
      company_id: $companyId
      date: $date
      time: $time
      first: $first
      page: $page
      query: $query
      orderBy: $orderBy
    ) {
      paginatorInfo {
        hasMorePages
        currentPage
        total
        count
        lastPage
      }
      data {
        bonus_used
        certificate_used
        discount_services
        discounts
        income
        saled_services
        time
      }
    }
  }
`);
const titles = [
  'День',
  'Проданных услуг',
  'Заработок',
  'Скидки',
  'Скидки по услугам',
  'Исп. бонусов',
  'Исп. сертификаты',
];
const sortValues: QueryGetStatisticsSalesDateOrderByColumn[] = [
  QueryGetStatisticsSalesDateOrderByColumn.Time,
  QueryGetStatisticsSalesDateOrderByColumn.SaledServices,
  QueryGetStatisticsSalesDateOrderByColumn.Income,
  QueryGetStatisticsSalesDateOrderByColumn.Discounts,
  QueryGetStatisticsSalesDateOrderByColumn.DiscountServices,
  QueryGetStatisticsSalesDateOrderByColumn.BonusUsed,
  QueryGetStatisticsSalesDateOrderByColumn.CertificateUsed,
];

export const SalesOnDate = () => {
  const companyId = useAppSelector(state => state.company.data!.id);
  const [sort, setSort] = useState<TableSortType>({
    index: 0,
    order: SortOrder.Desc,
  });
  const [search, setSearch] = useState('');
  const [time, setTime] = useState(StatisticsDateTime.Day);
  const [options, setOptions] = useState<OptionsType>({
    page: 1,
    first: 10,
    total: 0,
    count: 0,
    lastPage: 1,
  });
  const [period, setPeriod] = useState<StatisticsDates>(
    StatisticsDates.Alltime,
  );
  const {data, loading} = useQuery(GET_STATISTICS_SALES_DATE, {
    variables: {
      companyId,
      time,
      date: period,
      first: options.first,
      page: options.page,
      query: search,
      orderBy: [
        {
          order: sort.order,
          column: sortValues[sort.index],
        },
      ],
    },
    onCompleted: res =>
      setOptions(prev => ({
        ...prev,
        total: res.getStatisticsSalesDate?.paginatorInfo.total,
        count: res.getStatisticsSalesDate?.paginatorInfo.count,
        lastPage: res.getStatisticsSalesDate?.paginatorInfo.lastPage,
      })),
  });
  const statistics = data?.getStatisticsSalesDate?.data ?? [];
  const isEmpty = !statistics?.filter(item => item.time !== 'Итого').length;
  return (
    <Wrapper>
      <PageHeader back title="Продажи по дате" />
      <Flex direction="row" gap={8}>
        <FilterSearch onChange={setSearch} value={search} />
        <FilterStatisticsPeriod {...{period, setPeriod}} />
        <FilterStatisticsSales {...{time, setTime}} />
        {time !== StatisticsDateTime.Day ||
        search ||
        period !== StatisticsDates.Alltime ? (
          <FilterResetButton
            onClick={() => {
              setTime(StatisticsDateTime.Day);
              setSearch('');
              setPeriod(StatisticsDates.Alltime);
            }}
          />
        ) : (
          <></>
        )}
      </Flex>
      {!loading && isEmpty ? (
        <FilterEmptyState />
      ) : (
        <Content>
          <Table
            options={options}
            setOptions={setOptions}
            titles={titles}
            sort={sort}
            results
            columnNoWrap={[0, 2, 3, 4, 5, 6]}
            alignRightColumn={[1, 2, 3, 4, 5, 6]}
            onClickTitle={(index, order) => setSort({index, order})}
            rows={statistics.map(item => [
              dayjs(item.time).isValid()
                ? dayjs(item.time).format('DD MMMM YYYY')
                : '-',
              getNumberFormat(item.saled_services),
              getPriceFormat(item.income),
              getPriceFormat(item.discounts),
              getPriceFormat(item.discount_services),
              getPriceFormat(item.bonus_used),
              getPriceFormat(item.certificate_used),
            ])}
          />
        </Content>
      )}
    </Wrapper>
  );
};
