import React, {useState} from 'react';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';
import {Content} from 'shared/ui/Content';
import {TextInput} from 'shared/ui/TextInput';
import {Button} from 'shared/ui/Button';
import {useNavigate, useParams} from 'react-router-dom';
import {gql} from 'shared/__generated__';
import {useMutation, useQuery} from '@apollo/client';
import {CustomerGender} from 'shared/__generated__/graphql';
import {Head} from 'shared/ui/Head';
import {ActionIcon} from 'shared/ui/ActionIcon';
import {ArrowLeft2v} from 'shared/icons/ArrowLeft2v';
import {AvatarCrop, AvatarLoadType} from 'shared/ui/AvatarCrop';
import {Layout} from 'shared/ui/Layout';
import {Flex} from 'shared/ui/Flex';
import {RadioButton} from 'shared/ui/RadioButton';
import {
  Gender,
  PHONE_MASK,
  getPhoneMask,
  isEmailValid,
  processedAvatar,
} from 'shared/lib/utils';
import {TextArea} from 'shared/ui/TextArea';
import {showAlert} from 'shared/ui/Alert';
import {Seo} from 'shared/ui/Seo';
import {DateInput} from 'shared/ui/DateInput';
import dayjs from 'dayjs';
import {useAppSelector} from 'shared/store';
import {useColors, useEmployeePermission} from 'shared/lib/hooks';
import {MultiSelectChips} from 'shared/ui/MultiSelectChips';
import {TagCreateModal} from 'entities/tags';
import {TableViewRow} from 'shared/ui/TableViewRow';
import {EmptyTags} from 'shared/illustration/EmptyTags';

const CUSTOMER = gql(`
  query EditCustomer($customerId: String!, $companyId: String!) {
    customer(id: $customerId) {
      id
      email
      name
      phone
      surname
      birthdate
      avatar {
        id
        url
        url_256
      }
      gender
      is_blocked
      is_vip
      note
      tag {
        id
        name
        order
        color {
          id
          rgb
        }
      }
    }
    companyTags(company_id: $companyId) {
      id
      name
      order
      color {
        id
        rgb
      }
    }
    ...Permissions
  }
`);

const UPDATE_CUSTOMER = gql(`
  mutation UpdateCustomer($input: UpdateCustomer!) {
    updateCustomer(input: $input) {
      id
      email
      name
      phone
      surname
      birthdate
      avatar {
        id
        url
        url_256
      }
      gender
      is_blocked
      is_vip
      note
      tag {
        id
        name
        order
        color {
          id
          rgb
        }
      }
    }
  }
`);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-column-start: 4;
  grid-column-end: 10;
  padding: 64px 0 24px;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const CustomerEdit = () => {
  const params = useParams();
  const customerId = params.id!;
  const companyId = useAppSelector(state => state.company.data!.id);
  const navigate = useNavigate();
  const colors = useColors();
  const [avatar, setAvatar] = useState<AvatarLoadType>();
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [birthdate, setBirthdate] = useState('');
  const [gender, setGender] = useState<CustomerGender>();
  const [status, setStatus] = useState('');
  const [note, setNote] = useState('');
  const [selectTags, setSelectTags] = useState<
    {value: string; label: string}[]
  >([]);
  const [tagModal, setTagModal] = useState(false);
  const [phoneError, setPhoneError] = React.useState<string | null>(null);
  const [emailError, setEmailError] = React.useState<string | null>(null);
  const [birthdateError, setBirthdateError] = useState<string | null>(null);
  const [updateCustomer, {loading: updateLoading}] =
    useMutation(UPDATE_CUSTOMER);
  const {data, loading} = useQuery(CUSTOMER, {
    variables: {
      companyId,
      customerId,
    },
    onCompleted: ({customer}) => {
      if (!customer) return;
      setAvatar(customer.avatar ?? undefined);
      setName(customer.name ?? '');
      setSurname(customer.surname ?? '');
      setPhone(getPhoneMask(customer.phone));
      setEmail(customer.email ?? '');
      setBirthdate(
        customer.birthdate
          ? dayjs(customer.birthdate).format('DD.MM.YYYY')
          : '',
      );
      setGender(customer.gender ?? undefined);
      setStatus(
        customer.is_vip ? 'vip' : customer.is_vip === false ? 'default' : '',
      );
      setNote(customer.note ?? '');
      customer.tag?.length &&
        setSelectTags(
          customer.tag.map(({id, name}) => ({
            value: id,
            label: name,
          })),
        );
    },
  });
  const {contact_permission} = useEmployeePermission(data);
  const tags = data?.companyTags?.map(({id, name}) => ({
    value: id,
    label: name,
  }));
  const customerAvatar = data?.customer?.avatar?.url;

  const onPressSave = async () => {
    try {
      if (contact_permission && phone.length < 18) {
        setPhoneError('Номер телефона введен некорректно');
        throw new Error('Номер телефона введен некорректно');
      }
      if (contact_permission && email !== '' && !isEmailValid(email)) {
        setEmailError('Неверный формат email');
        throw new Error('Неверный формат email');
      }
      const checkDate = dayjs(birthdate, 'DD.MM.YYYY', true).isValid();
      if (birthdate && !checkDate) {
        setBirthdateError('Неверный формат даты');
        throw new Error('Неверный формат даты');
      }
      const input = {
        id: customerId,
        avatar: processedAvatar(avatar, customerAvatar),
        name,
        surname,
        is_vip: status == 'vip' ? true : false,
        note,
        gender: gender ?? null,
        tags_id: selectTags.map(item => item.value),
      };
      if (contact_permission) {
        Object.assign(input, {phone, email});
      }
      if (birthdate) {
        Object.assign(input, {birthdate});
      }
      await updateCustomer({variables: {input}});
      navigate(-1);
    } catch (e) {
      if (e instanceof Error) {
        showAlert('error', e.message);
      }
    }
  };

  return (
    <Layout columns={12}>
      <Seo title="Редактирование клиента" />
      <TagCreateModal visible={tagModal} onClose={() => setTagModal(false)} />
      <Wrapper>
        <Head>
          <ActionIcon icon={<ArrowLeft2v />} onClick={() => navigate(-1)} />
          <Text typography="title24">Редактировать клиента</Text>
        </Head>
        <Content style={{marginTop: 8}}>
          <Text typography="title18">Основная информация</Text>
          <AvatarCrop
            onChange={value => {
              setAvatar(value);
            }}
            onDelete={() => {
              setAvatar(undefined);
            }}
            url={avatar?.url ?? ''}
          />
          <TextInput
            label="Имя"
            value={name}
            onChange={text => {
              setName(text);
            }}
          />
          <TextInput
            label="Фамилия"
            value={surname}
            onChange={text => {
              setSurname(text);
            }}
          />
          <TextInput
            disabled={!contact_permission}
            label="Номер телефона"
            value={phone}
            mask={PHONE_MASK}
            error={phoneError}
            onChange={text => {
              if (phoneError) {
                setPhoneError(null);
              }
              setPhone(text);
            }}
            required
          />
        </Content>
        <Content style={{marginTop: 24}}>
          <Text typography="title18">Дополнительно</Text>
          <DateInput
            value={birthdate}
            error={birthdateError}
            label="Дата рождения"
            onChange={value => {
              birthdateError && setBirthdateError(null);
              setBirthdate(value);
            }}
          />
          <TextInput
            label="E-mail"
            disabled={!contact_permission}
            error={emailError}
            value={email}
            onChange={text => {
              if (emailError) {
                setEmailError(null);
              }
              setEmail(text);
            }}
          />
          <Flex direction="column" gap={8}>
            <Text typography="subHead14Regular" color="textTertiary">
              Пол
            </Text>
            <Flex flex={1} style={{height: 48}} alignItems="center">
              <Flex flex={1}>
                <RadioButton
                  labelPosition="right"
                  title={Gender.M}
                  selected={gender === CustomerGender.M}
                  onChange={() => {
                    if (gender === CustomerGender.M) {
                      setGender(undefined);
                    } else {
                      setGender(CustomerGender.M);
                    }
                  }}
                />
              </Flex>
              <Flex flex={1}>
                <RadioButton
                  labelPosition="right"
                  title={Gender.F}
                  selected={gender === CustomerGender.F}
                  onChange={() => {
                    if (gender === CustomerGender.F) {
                      setGender(undefined);
                    } else {
                      setGender(CustomerGender.F);
                    }
                  }}
                />
              </Flex>
            </Flex>
          </Flex>
          <MultiSelectChips
            label="Теги клиента"
            data={tags}
            values={selectTags}
            onChange={setSelectTags}
            onDelete={value => {
              setSelectTags(prev => prev.filter(item => item.value !== value));
            }}
            renderItemLeftElement={item => (
              <div
                style={{
                  width: 4,
                  height: 42,
                  marginRight: 16,
                  borderRadius: 4,
                  backgroundColor: data?.companyTags.find(
                    tag => tag.id === item.value,
                  )
                    ? `rgb(${data?.companyTags.find(
                        tag => tag.id === item.value,
                      )?.color?.rgb})`
                    : 'transparent',
                }}
              />
            )}
            ListEmptyComponent={
              <Flex flex={1} alignItems="center" direction="column" gap={16}>
                <EmptyTags size={200} mt={8} />
                <Text typography="title20">У вас пока нет тегов</Text>
                <Text color="textTertiary" style={{width: 343}} align="center">
                  Нажмите на кнопку “Создать новый тег” и начните работу
                </Text>
              </Flex>
            }
            itemHeight={56}
            addItem={
              <TableViewRow
                hover
                pointer
                title="+ Создать новый тег"
                style={{
                  width: '100%',
                  padding: '0 16px',
                  marginBottom: 8,
                  position: 'sticky',
                  top: -16,
                  backgroundColor: colors.bgPrimary,
                }}
                styleTitle={{color: colors.mainPrimary}}
                onClick={() => {
                  setTagModal(true);
                }}
              />
            }
          />
          <TextArea
            size="medium"
            label="Примечание"
            value={note}
            onChange={text => setNote(text)}
          />
        </Content>
        <Flex mt={32} flex={1} direction="column">
          <Button
            size="large"
            disabled={phone.length < 18 || loading || updateLoading}
            loading={loading || updateLoading}
            onClick={onPressSave}>
            Сохранить
          </Button>
        </Flex>
      </Wrapper>
    </Layout>
  );
};
