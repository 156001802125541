import React from 'react';

export const Youtube = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_12387_8332)">
        <path
          d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
          fill="#FF0000"
        />
        <path
          d="M18.5985 8.71483C18.439 8.12138 17.9696 7.65193 17.3761 7.49249C16.3044 7.2002 11.9908 7.2002 11.9908 7.2002C11.9908 7.2002 7.67714 7.20905 6.60539 7.50135C6.01193 7.66079 5.54248 8.13023 5.38305 8.72369C5.09961 9.79545 5.09961 12.0364 5.09961 12.0364C5.09961 12.0364 5.09961 14.2773 5.39191 15.358C5.55134 15.9514 6.02079 16.4209 6.61424 16.5803C7.686 16.8726 11.9996 16.8726 11.9996 16.8726C11.9996 16.8726 16.3132 16.8726 17.385 16.5803C17.9784 16.4209 18.4479 15.9514 18.6073 15.358C18.8996 14.2862 18.8996 12.0364 18.8996 12.0364C18.8996 12.0364 18.8908 9.79545 18.5985 8.71483Z"
          fill="white"
        />
        <path
          d="M10.6191 14.1091L14.1976 12.0364L10.6191 9.96378V14.1091Z"
          fill="#FF0000"
        />
      </g>
      <defs>
        <clipPath id="clip0_12387_8332">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
