import React, {useState, useRef, useEffect} from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import {Flex} from 'shared/ui/Flex';
import {Text} from 'shared/ui/Text';
import {DatePicker} from 'shared/ui/DatePicker';
import {IconButton} from 'shared/ui/IconButton';
import Xv2 from 'shared/icons/Xv2';

type SelectProps = {
  label: string;
  period: {from: string; to: string};
  onChange: ({from, to}: {from: string; to: string}) => void;
  backgroundColor?: string;
  disabled?: boolean;
  error?: string | null;
  required?: boolean;
  style?: React.CSSProperties;
  leftElement?: React.ReactElement;
};

const SelectWrapper = styled.div`
  position: relative;
  pointer-events: all;
  width: 100%;
`;

const StyledLabel = styled(Text)<{
  required?: boolean;
  isOpen?: boolean;
  selected?: string;
}>`
  &::after {
    content: '*';
    color: ${({theme}) => theme.dangerPrimary};
    visibility: ${({required}) => (required ? 'visible' : 'hidden')};
    font-size: ${({isOpen}) => (isOpen ? '12px' : '16px')};
    transition:
      transform 150ms ease,
      color 150ms ease,
      font-size 150ms ease;
  }
  transition: ${({selected}) => (!selected ? 'all 0.3s ease' : 'none')};
  transform: ${({isOpen, selected}) =>
    isOpen && !selected ? 'translateY(-14px)' : 'none'};
`;

const StyledButton = styled.button<{
  hasLabel: boolean;
  isOpen: boolean;
  background?: string;
  error?: string | null;
}>`
  display: flex;
  height: 64px;
  border-radius: 18px;
  width: 100%;
  pointer-events: all;
  align-items: center;
  padding: 5px 16px 5px 16px;
  cursor: pointer;
  background-color: ${({background, theme}) => background ?? theme.fillPrimary};
  border: 1px solid
    ${props =>
      props.error ? props.theme.dangerPrimary : props.theme.borderPrimary};
  &:focus {
    border: 1px solid
      ${({error, theme, isOpen}) =>
        error
          ? theme.dangerPrimary
          : isOpen
          ? theme.mainPrimary
          : theme.borderPrimary};
    outline: none;
    gap: 8px;
  }
`;

export const PeriodSelect = ({
  label,
  onChange,
  backgroundColor,
  disabled,
  error,
  required,
  style,
  leftElement,
  period,
}: SelectProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectPeriod, setSelectPeriod] = useState(period);
  const isLabelShrink = selectPeriod.from.length > 0 || isOpen;
  const selectRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        selectRef.current &&
        !selectRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectRef]);

  const text = `${dayjs(selectPeriod.from).format('DD MMMM YYYY')} - ${
    selectPeriod.to ? dayjs(selectPeriod.to).format('DD MMMM YYYY') : ''
  }`;

  return (
    <SelectWrapper ref={selectRef} style={style}>
      <StyledButton
        error={error}
        disabled={disabled}
        background={backgroundColor}
        onClick={() => setIsOpen(!isOpen)}
        hasLabel={!!label}
        isOpen={isOpen}>
        <Flex
          flex={1}
          gap={label && selectPeriod.from ? 4 : 0}
          direction="column">
          <StyledLabel
            isOpen={isLabelShrink}
            required={required}
            selected={selectPeriod.from}
            typography={isLabelShrink ? 'footNote12Regular' : 'text16Regular'}
            color={'textTertiary'}>
            {label}
          </StyledLabel>
          {leftElement}
          {selectPeriod.from.length ? (
            <Text
              typography="text16Regular"
              color={disabled ? 'textTertiary' : 'textPrimary'}
              style={{
                marginTop: 0,
                padding: 0,
              }}>
              {text}
            </Text>
          ) : null}
        </Flex>
        {selectPeriod.from.length ? (
          <IconButton
            size={24}
            variant="unstyled"
            onClick={e => {
              e.stopPropagation();
              setSelectPeriod({from: '', to: ''});
            }}>
            <Xv2 />
          </IconButton>
        ) : null}
      </StyledButton>
      {error && (
        <Text mt={8} color="dangerPrimary" typography="footNote12Regular">
          {error}
        </Text>
      )}
      <DatePicker
        variant="period"
        style={{width: '100%', maxWidth: 375, right: 0, left: 'auto'}}
        value={
          selectPeriod.from ? dayjs(selectPeriod.from).format('YYYY-MM-DD') : ''
        }
        valueEnd={
          selectPeriod.to
            ? dayjs(selectPeriod.to).format('YYYY-MM-DD')
            : undefined
        }
        onChange={(value, valueEnd) => {
          setSelectPeriod({from: value, to: valueEnd ?? ''});
        }}
        visible={isOpen}
        onClose={() => {
          if (selectPeriod.from && selectPeriod.to) {
            onChange(selectPeriod);
          } else {
            setSelectPeriod(period);
          }
          setIsOpen(false);
        }}
      />
    </SelectWrapper>
  );
};
