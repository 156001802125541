import {useMutation} from '@apollo/client';
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {gql} from 'shared/__generated__';
import {useColors} from 'shared/lib/hooks';
import {showAlert} from 'shared/ui/Alert';
import {Button} from 'shared/ui/Button';
import {CloseButton} from 'shared/ui/CloseButton';
import {Flex} from 'shared/ui/Flex';
import {Popup} from 'shared/ui/Popup';
import {TableViewRow} from 'shared/ui/TableViewRow';
import {Text} from 'shared/ui/Text';
import {TextArea} from 'shared/ui/TextArea';

const DELETE_BOOKING = gql(`
mutation DeleteBooking($deleteBookingId: String!, $deleteReason: String) {
    deleteBooking(id: $deleteBookingId, delete_reason: $deleteReason) {
      id
    }
  }
`);

export const ModalDeleteBooking = ({
  id,
  visible,
  onClose,
}: {
  id: string;
  visible: boolean;
  onClose: () => void;
}) => {
  const [desctiption, setDescription] = useState('');
  const [deleteBooking, {loading}] = useMutation(DELETE_BOOKING, {
    refetchQueries: ['BookingsData', 'EmployeeDetailScreen'],
  });
  const [selectIndex, setSelectIndex] = useState<number | null>(null);
  const colors = useColors();
  const navigate = useNavigate();
  return (
    <Popup style={{padding: 0}} visible={visible} onClose={onClose}>
      <Flex flex={1} direction="column" gap={16} style={{width: 440}}>
        <Flex
          direction="row"
          alignItems="center"
          style={{margin: '24px 24px 0 24px'}}
          justifyContent="space-between">
          <Text typography="title20">Причина удаления</Text>
          <CloseButton
            onClose={() => {
              onClose();
            }}
          />
        </Flex>
        <Flex direction="column" style={{height: 192}}>
          <TableViewRow
            title="Забыл"
            style={{
              padding: '0 24px',
              minHeight: 48,
              maxHeight: 48,
              cursor: 'pointer',
              backgroundColor:
                selectIndex === 1 ? colors.fillPrimary : 'transparent',
            }}
            styleTitle={{pointerEvents: 'none'}}
            onClick={() => {
              setSelectIndex(1);
              setDescription('Забыл');
            }}
          />
          <TableViewRow
            title="Появились другие планы"
            onClick={() => {
              setSelectIndex(2);
              setDescription('Появились другие планы');
            }}
            style={{
              padding: '0 24px',
              minHeight: 48,
              maxHeight: 48,
              cursor: 'pointer',
              backgroundColor:
                selectIndex === 2 ? colors.fillPrimary : 'transparent',
            }}
            styleTitle={{pointerEvents: 'none'}}
          />
          <TableViewRow
            title="Заболел"
            onClick={() => {
              setSelectIndex(3);
              setDescription('Заболел');
            }}
            style={{
              padding: '0 24px',
              minHeight: 48,
              maxHeight: 48,
              cursor: 'pointer',
              backgroundColor:
                selectIndex === 3 ? colors.fillPrimary : 'transparent',
            }}
            styleTitle={{pointerEvents: 'none'}}
          />
          <TableViewRow
            title="Другое"
            onClick={() => {
              setSelectIndex(4);
              setDescription('');
            }}
            style={{
              padding: '0 24px',
              minHeight: 48,
              maxHeight: 48,
              cursor: 'pointer',
              backgroundColor:
                selectIndex === 4 ? colors.fillPrimary : 'transparent',
            }}
            styleTitle={{pointerEvents: 'none'}}
          />
        </Flex>
        {selectIndex === 4 && (
          <TextArea
            style={{margin: '0 24px'}}
            value={desctiption}
            onChange={setDescription}
            size="large"
            label="Напишите причину"
          />
        )}
        <Flex
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          style={{margin: '0 24px 24px 24px'}}
          gap={8}>
          <Button
            style={{flex: 1}}
            size="large"
            onClick={() => {
              onClose();
              setSelectIndex(null);
              setDescription('');
            }}
            typography="text16Semibold"
            variant="outline">
            Отмена
          </Button>
          <Button
            style={{
              flex: 1,
              color: colors.dangerPrimary,
              borderColor: colors.dangerPrimary,
            }}
            size="large"
            loading={loading}
            onClick={() => {
              deleteBooking({
                variables: {
                  deleteBookingId: id,
                  deleteReason: desctiption,
                },
              })
                .then(() => {
                  onClose();
                  navigate(-1);
                })
                .catch(e => {
                  showAlert('error', e.message);
                });
            }}
            typography="text16Semibold"
            variant="outline">
            Удалить
          </Button>
        </Flex>
      </Flex>
    </Popup>
  );
};
