import React from 'react';
import {Outlet, useLocation, useNavigate, useParams} from 'react-router-dom';
import {Text} from 'shared/ui/Text';
import {Head} from 'shared/ui/Head';
import {ActionIcon} from 'shared/ui/ActionIcon';
import {SegmentedControl} from 'shared/ui/SegmentedControl';
import {Content} from 'shared/ui/Content';
import {ArrowLeft2v} from 'shared/icons/ArrowLeft2v';
import styled from 'styled-components';
import {Layout} from 'shared/ui/Layout';
import {useAppSelector} from 'shared/store';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-column-start: 4;
  grid-column-end: 10;
  padding-top: 64px;
  padding-bottom: 24px;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export function ModerationRoot() {
  const navigate = useNavigate();
  const title = useLocation().state;
  const username = useAppSelector(state => state.company.data?.username);
  const params = useParams();
  const id = params.id!;
  const location = useLocation();
  const tabs = [
    {
      name: 'Новая заявка',
      onClick: () => {
        navigate(`new-moderation/${id}`, {state: title});
      },
    },
    {
      name: 'Статус заявки',
      onClick: () => {
        navigate(`status-of-moderations/${id}`, {state: title});
      },
    },
  ];
  return (
    <Layout columns={12}>
      <Wrapper>
        <Head style={{marginBottom: 32}}>
          <ActionIcon
            icon={<ArrowLeft2v />}
            onClick={() =>
              navigate(`/${username}/platform-app/${id}`, {state: title})
            }
          />
          <Text typography="title24">Модерация</Text>
        </Head>
        <Content style={{marginBottom: 32}}>
          <SegmentedControl
            typography="subHead14Regular"
            tabs={tabs}
            index={location.pathname.includes('new-moderation') ? 0 : 1}
          />
        </Content>
        <Outlet />
      </Wrapper>
    </Layout>
  );
}
