import React from 'react';
import styled from 'styled-components';
import {Text} from 'shared/ui/Text';
import dayjs from 'dayjs';

const Card = styled.button<{select: boolean; unCurrentMonth: boolean}>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 128px;
  width: 124px;
  border-radius: 20px;
  padding: 12px;
  border: ${({select}) => (select ? '2px' : '1px')} solid
    ${({theme, select}) => (select ? theme.mainPrimary : theme.borderPrimary)};
  background: ${({theme, unCurrentMonth}) =>
    unCurrentMonth ? theme.bgPrimary : theme.fillPrimary};
`;
const Date = styled.div<{isWork: boolean}>`
  display: flex;
  min-width: 48px;
  height: 48px;
  justify-content: center;
  align-items: ${({isWork}) => (isWork ? 'center' : 'flex-start')};
  border-radius: 24px;
  background-color: ${({theme, isWork}) =>
    isWork ? theme.mainPrimary : 'transparent'};
  flex-direction: column;
  padding: 12px 10px;
`;
const Circle = styled.div`
  width: 6px;
  height: 6px;
  background-color: ${({theme}) => theme.white};
  border-radius: 3px;
`;

export const ScheduleCard = ({
  date,
  start,
  end,
  isWork,
  select = false,
  selectedMonth,
  onClick,
}: {
  date: string;
  start?: string;
  end?: string;
  isWork: boolean;
  select?: boolean;
  selectedMonth: string;
  onClick?: () => void;
}) => {
  const formattedDate = dayjs(date).format('YYYY-MM-DD');
  const isToday = dayjs().format('YYYY-MM-DD') === formattedDate;
  const unCurrentMonth =
    dayjs(selectedMonth).get('month') !== dayjs(date).get('month');
  const isFirstMonthDay =
    dayjs(date).startOf('month').format('YYYY-MM-DD') === formattedDate;
  const isLastMonthDay =
    dayjs(date).endOf('month').format('YYYY-MM-DD') === formattedDate;
  const formatDay = isFirstMonthDay || isLastMonthDay ? 'D MMM' : 'D';

  return (
    <Card {...{select, onClick, unCurrentMonth}}>
      <Date isWork={isWork}>
        <Text
          color={isWork ? 'white' : 'textTertiary'}
          typography="text16Semibold">
          {dayjs(date).format(formatDay)}
        </Text>
        {isToday && <Circle />}
      </Date>
      {start && end && (
        <Text>
          {start}-{end}
        </Text>
      )}
    </Card>
  );
};
