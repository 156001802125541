import React from 'react';
import {Skeleton} from 'shared/ui/Skeleton';
import {Content} from 'shared/ui/Content';

const COLUMN = 3;

const columns = Array.from(Array(COLUMN).keys());
const contact = Array.from(Array(4).keys());

export const SkeletonAbout = () => {
  return (
    <>
      <Content>
        <Skeleton height={336}>
          <rect width={363} height={24} rx={12} y={0} x={0} />
          {columns.map((columnItem, index) => (
            <React.Fragment key={'column_' + columnItem}>
              <rect width={80} height={12} rx={6} y={59 + 78 * index} x={0} />
              <rect width={160} height={16} rx={6} y={89 + 78 * index} x={0} />
            </React.Fragment>
          ))}
          {contact.map((columnItem, index) => (
            <React.Fragment key={'column_' + columnItem}>
              <circle r={20} cx={20 + 160 * index} cy={316} />
              <rect
                width={64}
                height={12}
                rx={6}
                y={310}
                x={56 + 160 * index}
              />
            </React.Fragment>
          ))}
        </Skeleton>
      </Content>
      <Skeleton>
        <rect width={720} height={64} rx={14} x={0} y={0} />
      </Skeleton>
    </>
  );
};
