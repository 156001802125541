import React from 'react';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';
import {device} from 'shared/device';
import {EmptyRecord} from 'shared/illustration/EmptyRecord';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 120px;
  @media (${device.mobile}) {
    margin-top: 0;
    margin-left: 0;
    align-self: center;
    justify-content: center;
    padding: 16px;
  }
`;
export const EmptyState = () => {
  return (
    <Wrapper>
      <EmptyRecord size={300} />
      <Text
        typography="text16Regular"
        color="textTertiary"
        align="center"
        mt={16}>
        У данного клиента пока нет записей
      </Text>
    </Wrapper>
  );
};
