import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Button} from 'shared/ui/Button';
import {Text} from 'shared/ui/Text';
import {Grow} from 'shared/ui/Grow';
import {EmptyCustomers} from 'shared/illustration/EmptyCustomers';
import {useAppSelector} from 'shared/store';

export const EmptyState = ({filtered}: {filtered?: boolean}) => {
  const username = useAppSelector(state => state.company.data?.username);
  const navigate = useNavigate();
  return (
    <Grow>
      <EmptyCustomers size={300} />
      <Text typography="title20" style={{alignSelf: 'center', marginTop: 16}}>
        {filtered ? 'Не найдено' : 'У вас пока нет клиентов'}
      </Text>
      <Text
        typography="text16Regular"
        color="textTertiary"
        style={{
          textAlign: 'center',
          alignSelf: 'center',
          maxWidth: 500,
          marginTop: 16,
          paddingLeft: 52,
          paddingRight: 52,
        }}>
        {filtered
          ? 'Клиента с таким именем или телефоном номера не найдено. Проверьте данные и попробуйте еще раз'
          : 'Предлагаем это исправить. Придумывайте акции и привлекайте клиентов в вашу организацию'}
      </Text>
      {!filtered && (
        <Button
          style={{alignSelf: 'center', marginTop: 24}}
          size="large"
          onClick={() => navigate(`/${username}/add-customer`)}>
          Добавить клиента
        </Button>
      )}
    </Grow>
  );
};
