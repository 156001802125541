import React, {useState} from 'react';
import {styled} from 'styled-components';
import {StatisticsDates} from 'shared/__generated__/graphql';
import {FilterButton} from 'shared/ui/FilterButton';
import {Periods} from 'shared/lib/utils';
import {Modal} from 'shared/ui/Modal';
import {List} from 'shared/ui/List';
import {Text} from 'shared/ui/Text';

const PopupWrapper = styled.div`
  position: relative;
  display: inline-block;
`;
const ButtonPeriod = styled.button<{checked?: boolean}>`
  display: flex;
  padding: 0 16px;
  align-items: center;
  height: 40px;
  width: 343px;
  background-color: ${({checked, theme}) =>
    checked ? theme.bgSecondary : 'transparent'};
  border: none;
`;

export const FilterStatisticsPeriod = ({
  period = StatisticsDates.Alltime,
  setPeriod,
}: {
  period?: StatisticsDates;
  setPeriod?: (period: StatisticsDates) => void;
}) => {
  const [visible, setVisible] = useState(false);
  return (
    <PopupWrapper>
      <FilterButton
        onClick={() => setVisible(true)}
        active={StatisticsDates.Alltime !== period}
        title={
          period && period !== StatisticsDates.Period ? Periods[period] : ''
        }
        visible={visible}
      />
      <Modal
        style={{
          left: 'auto',
          right: 'auto',
          top: 64,
          paddingLeft: 0,
          paddingRight: 0,
          gap: 8,
        }}
        visible={visible}
        onClose={() => setVisible(false)}>
        <List
          data={Object.values(Periods)}
          keyExtractor={(_, index) => 'key_' + index}
          renderItem={(item, index) => (
            <ButtonPeriod
              onClick={() => {
                setPeriod &&
                  setPeriod(Object.keys(Periods)[index] as StatisticsDates);
                setVisible(false);
              }}
              checked={
                period !== StatisticsDates.Period && item === Periods[period]
              }>
              <Text>{item}</Text>
            </ButtonPeriod>
          )}
        />
      </Modal>
    </PopupWrapper>
  );
};
