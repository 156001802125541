import React, {useState, useRef, useEffect} from 'react';
import styled from 'styled-components';
import {Text} from 'shared/ui/Text';
import {Flex} from 'shared/ui/Flex';
import {TariffPlan, getPriceFormat} from 'shared/lib/utils';
import {ArrowDown2v} from 'shared/icons/ArrowDown2v';

type OptionType = {
  value: TariffPlan;
  label: string;
};

type SelectProps = {
  data: OptionType[];
  onChange: (value: TariffPlan) => void;
  value?: TariffPlan;
};

const SelectWrapper = styled.div`
  position: relative;
  pointer-events: all;
  width: 100%;
  padding: 0;
  margin: 0;
`;
const Button = styled.button<{
  isOpen: boolean;
}>`
  display: flex;
  height: 64px;
  border-radius: 18px;
  width: 100%;
  pointer-events: all;
  align-items: center;
  padding: 5px 16px 5px 16px;
  cursor: pointer;
  background-color: ${({theme}) => theme.fillPrimary};
  border: 1px solid
    ${({isOpen, theme}) => (isOpen ? theme.mainPrimary : theme.borderPrimary)};
`;

const CustomDropdown = styled.div<CustomDropdownProps>`
  position: absolute;
  margin-top: 5px;
  width: 100%;
  border-radius: 18px;
  padding: 16px 0;
  background-color: ${({theme}) => theme.bgPrimary};
  border: 1px solid ${({theme}) => theme.borderPrimary};
  z-index: 100;
  display: ${({isOpen}) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;
  gap: 8px;
  max-height: 400px;
  overflow: auto;
`;

interface CustomDropdownProps {
  isOpen: boolean;
}
const getDropdownText = (count: number) => {
  if (count === 1) return '1 сотрудник';
  return `До ${count} сотрудников`;
};

export const TariffSelect = ({
  data,
  onChange,
  value: selected,
}: SelectProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        selectRef.current &&
        !selectRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectRef]);

  const handleSelect = (value: TariffPlan) => {
    setIsOpen(false);
    onChange(value);
  };

  const selectedLabel = data?.find(option => option.value === selected)?.label;

  return (
    <SelectWrapper ref={selectRef}>
      <Button onClick={() => setIsOpen(!isOpen)} isOpen={isOpen}>
        <Flex flex={1} direction="column">
          <Text
            typography="text16Regular"
            color={!selectedLabel ? 'textTertiary' : 'textPrimary'}
            style={{marginTop: 0, padding: 0}}>
            {!selectedLabel ? data[0].label : selectedLabel}
          </Text>
        </Flex>
        <ArrowDown2v
          style={{
            transform: isOpen ? ' rotate(180deg)' : ' rotate(0deg)',
            transition: 'transform 0.3s ease',
          }}
        />
      </Button>
      <CustomDropdown isOpen={isOpen}>
        {data.map(option => (
          <DropdownItem
            key={'dropdown_item' + option.value}
            count={option.value.slots}
            price={option.value.price}
            onClick={() => handleSelect(option.value)}
          />
        ))}
      </CustomDropdown>
    </SelectWrapper>
  );
};

const Item = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 16px;
  min-height: 56px;
  justify-content: space-between;
  align-items: center;
  background-color: ${({theme}) => theme.bgPrimary};
  cursor: pointer;
  &:hover {
    background-color: ${({theme}) => theme.bgSecondary};
  }
`;

const Price = styled.div`
  display: flex;
  width: max-content;
  padding: 8px 16px;
  border-radius: 8px;
  background-color: ${({theme}) => theme.fillPrimary};
  color: ${({theme}) => theme.textPrimary};
  align-items: center;
`;

const DropdownItem = ({
  count,
  price,
  onClick,
}: {
  count: number;
  price: number;
  onClick: () => void;
}) => (
  <Item onClick={onClick}>
    <Text>{getDropdownText(count)}</Text>
    <Price>{getPriceFormat(price) + ' мес.'}</Price>
  </Item>
);
