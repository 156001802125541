import React, {useEffect, useState} from 'react';
import {Avatar} from 'shared/ui/Avatar';
import styled from 'styled-components';
import {getImagesUrls} from 'shared/lib/api';
import {Loader} from 'shared/icons/Loader';
import {ImageUpload} from 'shared/__generated__/graphql';
import {Popup} from 'shared/ui/Popup';
import {Flex} from 'shared/ui/Flex';
import {Text} from 'shared/ui/Text';
import Cropper, {Area} from 'react-easy-crop';
import {useColors} from 'shared/lib/hooks';
import {Button} from 'shared/ui/Button';
import {showAlert} from './Alert';
import {CloseButton} from './CloseButton';
import {DeleteBold} from 'shared/icons/DeleteBold';

const Label = styled.label`
  position: relative;
  display: inline-block;
  align-self: center;
  border-radius: 50%;
  pointer-events: all;
  cursor: pointer;
`;

const Input = styled.input`
  display: none;
`;
const DeleteDiv = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  padding: 0;
  z-index: 99;
  pointer-events: all;
  cursor: pointer;
`;
const LoaderAvatar = styled.div`
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
export type AvatarLoadType = Omit<ImageUpload, 'type'> | undefined;

export const AvatarCrop = ({
  url,
  onChange,
  onDelete,
  variant = 'avatar',
}: {
  url?: string;
  onChange?: (value?: AvatarLoadType) => void;
  onDelete?: () => void;
  variant?: 'image' | 'avatar' | 'company';
}) => {
  const colors = useColors();
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [avatar, setAvatar] = useState<string | null>(null);
  const [crop, setCrop] = useState({x: 0, y: 0});
  const [zoom, setZoom] = useState(1);
  const [cropPixel, setCropPixel] = useState<Area>({
    width: 0,
    height: 0,
    x: 0,
    y: 0,
  });
  useEffect(() => {
    if (modal) {
      setCropPixel({
        width: 0,
        height: 0,
        x: 0,
        y: 0,
      });
      setZoom(1);
      setCrop({x: 0, y: 0});
    }
  }, [modal]);

  const onCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
    setCropPixel(croppedAreaPixels);
  };
  const onSave = async () => {
    try {
      setLoading(true);
      if (cropPixel) {
        const file = await getCroppedImg(avatar!, cropPixel);
        if (file) {
          const image = await getImagesUrls(file);
          onChange && image && onChange(image.data[0]);
          setModal(false);
        }
      }
    } catch (e) {
      if (e instanceof Error) {
        showAlert('error', e.message);
      }
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Popup
        visible={modal}
        onClose={() => {
          setModal(false);
        }}>
        <Flex direction="column">
          <Flex
            justifyContent="space-between"
            alignItems="center"
            style={{width: 394}}>
            <Text typography="title20">Редактирование фото</Text>
            <CloseButton
              onClose={() => {
                setModal(false);
              }}
            />
          </Flex>
          <Flex
            mt={32}
            alignItems="center"
            justifyContent="center"
            style={{
              width: 394,
              height: 394,
            }}>
            {avatar ? (
              <Cropper
                image={avatar}
                crop={crop}
                zoom={zoom}
                aspect={1}
                style={{
                  containerStyle: {
                    width: 394,
                    height: 394,
                    position: 'relative',
                  },
                  cropAreaStyle: {
                    borderRadius: 394,
                    borderColor: colors.mainPrimary,
                  },
                }}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            ) : (
              <Loader size={120} />
            )}
          </Flex>
          <Flex mt={16}>
            <ProgressBar
              value={zoom}
              onChange={value => value && setZoom(value)}
            />
          </Flex>
          <Button
            style={{marginTop: 32}}
            size="large"
            loading={loading}
            onClick={onSave}>
            Сохранить
          </Button>
        </Flex>
      </Popup>
      <Flex
        style={{
          position: 'relative',
          alignSelf: 'center',
          pointerEvents: 'all',
          cursor: 'pointer',
        }}>
        <Label>
          <Input
            type="file"
            accept="image/*"
            onChange={value => {
              const files = value.target.files && value.target.files[0];
              const url = files && URL.createObjectURL(files);
              if (url) {
                setModal(true);
                setAvatar(url);
              }
              value.target.value = '';
            }}
          />

          <Avatar variant={variant} size={100} url={url} overlay />
          {loading && (
            <LoaderAvatar>
              <Loader />
            </LoaderAvatar>
          )}
        </Label>
        {url && (
          <DeleteDiv
            onClick={event => {
              event.stopPropagation();
              onDelete && onDelete();
            }}>
            <DeleteBold size={24} />
          </DeleteDiv>
        )}
      </Flex>
    </>
  );
};

const InputRange = styled.input`
  appearance: none;
  background: #6765f87f;
  border: none;
  border-radius: 5px;
  height: 5px;
  width: 100%;
  outline: none;
  z-index: 99;
  &::-webkit-slider-runnable-track {
    height: 5px;
    border-radius: 5px;
  }
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    margin-top: -5.5px;
    height: 16px;
    width: 16px;
    background: ${({theme}) => theme.white};
    border-radius: 50%;
    border: 4.5px solid ${({theme}) => theme.mainPrimary};
    cursor: pointer;
  }
  &::-moz-range-track {
    height: 5px;
    border-radius: 5px;
  }
  &::-moz-range-thumb {
    appearance: none;
    margin-top: -5.5px;
    height: 16px;
    width: 16px;
    background: ${({theme}) => theme.white};
    border-radius: 50%;
    border: 4.5px solid ${({theme}) => theme.mainPrimary};
    cursor: pointer;
  }
`;

const ProgressSlider = styled.div<{value?: number}>`
  position: absolute;
  width: ${({value = 0}) =>
    `calc((100% - 54px)  * (${value - 1} / 2) + ${
      value - 1 > 0 && value - 1 <= 1 ? 3 : 0
    }px)`};
  height: 5px;
  background-color: ${({theme}) => theme.mainPrimary};
  border-radius: 5px;
  bottom: 132px;
  left: 26px;
`;

const ProgressBar = ({
  value,
  onChange,
}: {
  value?: number;
  onChange?: (value?: number) => void;
}) => {
  return (
    <Flex style={{width: '100%', height: 36}} alignItems="center">
      <InputRange
        type="range"
        min={1}
        max={3}
        step={0.1}
        value={value}
        onChange={e => onChange && onChange(parseFloat(e.target.value))}
      />
      <ProgressSlider value={value} />
    </Flex>
  );
};

const createImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', error => reject(error));
    image.setAttribute('crossOrigin', 'anonymous');
    image.src = url;
  });

function getRadianAngle(degreeValue: number) {
  return (degreeValue * Math.PI) / 180;
}

const getCroppedImg = async (
  imageSrc: string,
  pixelCrop: Area,
  rotation: number = 0,
): Promise<FileList | null> => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) return new Promise(resolve => resolve(null));

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  canvas.width = safeArea;
  canvas.height = safeArea;

  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.rotate(getRadianAngle(rotation));
  ctx.translate(-safeArea / 2, -safeArea / 2);

  ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5,
  );
  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  ctx.putImageData(
    data,
    0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
    0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y,
  );
  return new Promise(resolve => {
    canvas.toBlob(blob => {
      if (blob) {
        const file = new File([blob], 'cropped-image.jpg', {
          lastModified: Date.now(),
          type: blob.type,
        });

        const dataTransfer = new DataTransfer();
        dataTransfer.items.add(file);

        const fileList = dataTransfer.files;
        resolve(fileList);
      } else {
        resolve(null);
      }
    }, 'image/jpeg');
  });
};
