import React from 'react';
import {useColorScheme} from 'shared/lib/hooks';
import {EmptyContainer} from 'shared/ui/EmptyContainer';

export const EmptyList = ({size = 400}: {size?: number}) => {
  const colorScheme = useColorScheme();
  return (
    <EmptyContainer size={size}>
      {colorScheme === 'light' ? (
        <svg viewBox="0 0 400 400" fill="none">
          <path
            d="M200 350.216C282.843 350.216 350 283.01 350 200.108C350 117.206 282.843 50 200 50C117.157 50 50 117.206 50 200.108C50 283.01 117.157 350.216 200 350.216Z"
            fill="#EAEEF9"
          />
          <g filter="url(#filter0_d_18292_387)">
            <path
              d="M312.065 337.156H90.3749C83.5653 337.156 77.8906 331.667 77.8906 324.663V75.5559C77.8906 68.7414 83.3761 63.252 90.3749 63.252H312.065C318.874 63.252 324.549 68.7414 324.549 75.7452V324.663C324.549 331.478 319.063 337.156 312.065 337.156Z"
              fill="url(#paint0_linear_18292_387)"
            />
          </g>
          <path
            d="M298.254 138.399H102.667C100.397 138.399 98.5059 136.506 98.5059 134.234V97.1332C98.5059 94.8617 100.397 92.9688 102.667 92.9688H298.254C300.524 92.9688 302.415 94.8617 302.415 97.1332V134.045C302.604 136.506 300.713 138.399 298.254 138.399Z"
            fill="#EAEEF9"
          />
          <path
            d="M118.936 121.362C122.07 121.362 124.611 118.82 124.611 115.684C124.611 112.547 122.07 110.005 118.936 110.005C115.802 110.005 113.262 112.547 113.262 115.684C113.262 118.82 115.802 121.362 118.936 121.362Z"
            fill="#AAB2C5"
          />
          <path
            d="M258.531 119.47H143.714C142.768 119.47 142.012 118.713 142.012 117.766V113.413C142.012 112.466 142.768 111.709 143.714 111.709H258.531C259.477 111.709 260.234 112.466 260.234 113.413V117.766C260.234 118.713 259.477 119.47 258.531 119.47Z"
            fill="#AAB2C5"
          />
          <path
            d="M298.254 196.701H102.667C100.397 196.701 98.5059 194.808 98.5059 192.536V155.624C98.5059 153.353 100.397 151.46 102.667 151.46H298.254C300.524 151.46 302.415 153.353 302.415 155.624V192.536C302.604 194.808 300.713 196.701 298.254 196.701Z"
            fill="#EAEEF9"
          />
          <path
            d="M118.936 179.663C122.07 179.663 124.611 177.121 124.611 173.984C124.611 170.848 122.07 168.306 118.936 168.306C115.802 168.306 113.262 170.848 113.262 173.984C113.262 177.121 115.802 179.663 118.936 179.663Z"
            fill="#AAB2C5"
          />
          <path
            d="M258.531 177.961H143.714C142.768 177.961 142.012 177.204 142.012 176.258V171.904C142.012 170.957 142.768 170.2 143.714 170.2H258.531C259.477 170.2 260.234 170.957 260.234 171.904V176.258C260.234 177.204 259.477 177.961 258.531 177.961Z"
            fill="#AAB2C5"
          />
          <path
            d="M298.254 313.493H102.667C100.397 313.493 98.5059 311.6 98.5059 309.328V272.416C98.5059 270.145 100.397 268.252 102.667 268.252H298.254C300.524 268.252 302.415 270.145 302.415 272.416V309.328C302.604 311.6 300.713 313.493 298.254 313.493Z"
            fill="#EAEEF9"
          />
          <path
            d="M118.936 296.46C122.07 296.46 124.611 293.918 124.611 290.781C124.611 287.645 122.07 285.103 118.936 285.103C115.802 285.103 113.262 287.645 113.262 290.781C113.262 293.918 115.802 296.46 118.936 296.46Z"
            fill="#AAB2C5"
          />
          <path
            d="M258.531 294.563H143.714C142.768 294.563 142.012 293.806 142.012 292.859V288.505C142.012 287.559 142.768 286.802 143.714 286.802H258.531C259.477 286.802 260.234 287.559 260.234 288.505V292.859C260.234 293.806 259.477 294.563 258.531 294.563Z"
            fill="#AAB2C5"
          />
          <path
            d="M341.955 255.001H146.368C144.099 255.001 142.207 253.108 142.207 250.837V213.925C142.207 211.654 144.099 209.761 146.368 209.761H341.955C344.225 209.761 346.116 211.654 346.116 213.925V250.837C346.116 253.108 344.225 255.001 341.955 255.001Z"
            fill="#D6DCE8"
          />
          <path
            d="M162.638 238.154C165.772 238.154 168.312 235.612 168.312 232.476C168.312 229.339 165.772 226.797 162.638 226.797C159.504 226.797 156.963 229.339 156.963 232.476C156.963 235.612 159.504 238.154 162.638 238.154Z"
            fill="#AAB2C5"
          />
          <path
            d="M302.232 236.262H187.415C186.47 236.262 185.713 235.505 185.713 234.558V230.205C185.713 229.258 186.47 228.501 187.415 228.501H302.232C303.178 228.501 303.935 229.258 303.935 230.205V234.558C303.935 235.505 303.178 236.262 302.232 236.262Z"
            fill="#AAB2C5"
          />
          <defs>
            <filter
              id="filter0_d_18292_387"
              x="55.8906"
              y="52.252"
              width="290.658"
              height="317.904"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_18292_387"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_18292_387"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_18292_387"
              x1="201.139"
              y1="56.9163"
              x2="201.139"
              y2="340.109"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#FDFEFF" />
              <stop offset="0.9964" stopColor="#ECF0F5" />
            </linearGradient>
          </defs>
        </svg>
      ) : (
        <svg viewBox="0 0 400 400" fill="none">
          <path
            d="M200 350.216C282.843 350.216 350 283.01 350 200.108C350 117.206 282.843 50 200 50C117.157 50 50 117.206 50 200.108C50 283.01 117.157 350.216 200 350.216Z"
            fill="#2A2E37"
          />
          <g filter="url(#filter0_d_18292_418)">
            <path
              d="M312.064 337.155H90.3739C83.5643 337.155 77.8896 331.665 77.8896 324.662V75.5544C77.8896 68.7399 83.3751 63.2505 90.3739 63.2505H312.064C318.873 63.2505 324.548 68.7399 324.548 75.7437V324.662C324.548 331.476 319.062 337.155 312.064 337.155Z"
              fill="#5C6479"
            />
          </g>
          <path
            d="M298.254 138.399H102.667C100.397 138.399 98.5059 136.506 98.5059 134.235V97.1339C98.5059 94.8624 100.397 92.9695 102.667 92.9695H298.254C300.524 92.9695 302.415 94.8624 302.415 97.1339V134.046C302.604 136.506 300.713 138.399 298.254 138.399Z"
            fill="#474E61"
          />
          <path
            d="M118.936 121.363C122.07 121.363 124.611 118.82 124.611 115.684C124.611 112.548 122.07 110.005 118.936 110.005C115.802 110.005 113.261 112.548 113.261 115.684C113.261 118.82 115.802 121.363 118.936 121.363Z"
            fill="#AAB2C5"
          />
          <path
            d="M258.531 119.47H143.714C142.768 119.47 142.011 118.713 142.011 117.767V113.413C142.011 112.467 142.768 111.709 143.714 111.709H258.531C259.477 111.709 260.233 112.467 260.233 113.413V117.767C260.233 118.713 259.477 119.47 258.531 119.47Z"
            fill="#AAB2C5"
          />
          <path
            d="M298.254 196.701H102.667C100.397 196.701 98.5059 194.808 98.5059 192.536V155.624C98.5059 153.353 100.397 151.46 102.667 151.46H298.254C300.524 151.46 302.415 153.353 302.415 155.624V192.536C302.604 194.808 300.713 196.701 298.254 196.701Z"
            fill="#474E61"
          />
          <path
            d="M118.936 179.665C122.07 179.665 124.611 177.122 124.611 173.986C124.611 170.85 122.07 168.307 118.936 168.307C115.802 168.307 113.261 170.85 113.261 173.986C113.261 177.122 115.802 179.665 118.936 179.665Z"
            fill="#AAB2C5"
          />
          <path
            d="M258.531 177.961H143.714C142.768 177.961 142.011 177.204 142.011 176.258V171.904C142.011 170.957 142.768 170.2 143.714 170.2H258.531C259.477 170.2 260.233 170.957 260.233 171.904V176.258C260.233 177.204 259.477 177.961 258.531 177.961Z"
            fill="#AAB2C5"
          />
          <path
            d="M298.254 313.494H102.667C100.397 313.494 98.5059 311.601 98.5059 309.33V272.418C98.5059 270.146 100.397 268.253 102.667 268.253H298.254C300.524 268.253 302.415 270.146 302.415 272.418V309.33C302.604 311.601 300.713 313.494 298.254 313.494Z"
            fill="#474E61"
          />
          <path
            d="M118.936 296.458C122.07 296.458 124.611 293.916 124.611 290.779C124.611 287.643 122.07 285.101 118.936 285.101C115.802 285.101 113.261 287.643 113.261 290.779C113.261 293.916 115.802 296.458 118.936 296.458Z"
            fill="#AAB2C5"
          />
          <path
            d="M258.531 294.564H143.714C142.768 294.564 142.011 293.807 142.011 292.861V288.507C142.011 287.561 142.768 286.803 143.714 286.803H258.531C259.477 286.803 260.233 287.561 260.233 288.507V292.861C260.233 293.807 259.477 294.564 258.531 294.564Z"
            fill="#AAB2C5"
          />
          <g filter="url(#filter1_d_18292_418)">
            <path
              d="M341.949 255.002H146.363C144.093 255.002 142.202 253.109 142.202 250.838V213.926C142.202 211.655 144.093 209.762 146.363 209.762H341.949C344.219 209.762 346.111 211.655 346.111 213.926V250.838C346.111 253.109 344.219 255.002 341.949 255.002Z"
              fill="url(#paint0_linear_18292_418)"
            />
          </g>
          <path
            d="M162.632 238.155C165.766 238.155 168.306 235.613 168.306 232.477C168.306 229.34 165.766 226.798 162.632 226.798C159.498 226.798 156.957 229.34 156.957 232.477C156.957 235.613 159.498 238.155 162.632 238.155Z"
            fill="#8E96AB"
          />
          <path
            d="M302.227 236.263H187.409C186.464 236.263 185.707 235.505 185.707 234.559V230.205C185.707 229.259 186.464 228.502 187.409 228.502H302.227C303.172 228.502 303.929 229.259 303.929 230.205V234.559C303.929 235.505 303.172 236.263 302.227 236.263Z"
            fill="#8E96AB"
          />
          <defs>
            <filter
              id="filter0_d_18292_418"
              x="55.8896"
              y="52.2505"
              width="290.658"
              height="317.904"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_18292_418"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_18292_418"
                result="shape"
              />
            </filter>
            <filter
              id="filter1_d_18292_418"
              x="120.202"
              y="198.762"
              width="247.909"
              height="89.2405"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_18292_418"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_18292_418"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_18292_418"
              x1="188.975"
              y1="221.637"
              x2="194.702"
              y2="265.332"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#E3EAF7" />
              <stop offset="1" stopColor="#ADB6C8" />
            </linearGradient>
          </defs>
        </svg>
      )}
    </EmptyContainer>
  );
};
