import React from 'react';
import {Content} from 'shared/ui/Content';
import {Text} from 'shared/ui/Text';

export const TitleContent = ({
  title,
  children,
}: React.PropsWithChildren<{title: string}>) => (
  <Content>
    <Text typography="title18">{title}</Text>
    {children}
  </Content>
);
