import {gql} from 'shared/__generated__';

export const SIDEBAR_QUERY = gql(`
  query SidebarQuery($companyId: String!) {
    company(id: $companyId) {
      plan {
        type
      }
    }
    moderationsCheck(company_id: $companyId) {
      result
      is_employee_added
      is_employee_with_specialization_added
      is_required_items_filled
      is_service_added
    }
    me {
      id
      unviewed_notifications
    }
    ...Permissions
  }
`);
