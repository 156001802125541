import React from 'react';
import styled from 'styled-components';
import {device} from 'shared/device';
import {Seo} from 'shared/ui/Seo';

const Head = styled.header`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  margin: 24px;
  @media ${device.mobile} {
    margin: 16px;
  }
`;
const H1 = styled.div`
  color: ${({theme}) => theme.textPrimary};
  display: flex;
  text-align: left;
  justify-content: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  height: 56px;
  align-items: center;
`;

export const Header = () => {
  return (
    <Head>
      <Seo title="Отчеты" />
      <H1>Отчеты</H1>
    </Head>
  );
};
