import React, {useCallback, useRef} from 'react';
import {Text} from 'shared/ui/Text';
import {useLocation, useNavigate} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {changePhone, getSmsCode} from 'shared/lib/api';
import {ensureError} from 'shared/lib/utils';
import {CodeInput} from 'shared/ui/CodeInput';
import {Content} from 'shared/ui/Content';
import {device} from 'shared/device';
import styled from 'styled-components';
import {Head} from 'shared/ui/Head';
import {showAlert} from 'shared/ui/Alert';
import {Flex} from 'shared/ui/Flex';
import {Popup} from 'shared/ui/Popup';
import {NavigateBack} from 'shared/ui/NavigateBack';
import {CloseButton} from 'shared/ui/CloseButton';
import {Seo} from 'shared/ui/Seo';
import ReCAPTCHA from 'react-google-recaptcha';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0 auto;
  max-width: 472px;
  min-height: 100dvh;
  border-radius: 0;
  padding-top: 64px;
  padding-bottom: 24px;
  gap: 8px;
  @media screen and (${device.mobile}) {
    margin-top: 56px;
  }
`;
const TIMEOUT = 60;

export function PhoneChangeCode() {
  const {old_phone, phone, password} = useLocation().state;
  const navigate = useNavigate();
  const [value, setValue] = useState(['', '', '', '']);
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState(0);
  const [sending, setSending] = useState(false);
  const [infoModal, setInfoModal] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);

  const onCaptchaChange = async (token: string | null) => {
    if (!token) {
      return;
    }
    try {
      await getSmsCode(phone, token);
    } catch (err) {
      const error = ensureError(err);
      showAlert('error', error.message);
    }
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimer(t => t + 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);
  const submit = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      await changePhone(old_phone, phone, value.join(''), password);
      showAlert('success', 'Ваш номер изменён');
      navigate('/profile');
    } catch (err) {
      if (err instanceof Error) {
        if (err.message !== 'Неверный код') {
          showAlert('error', err.message);
        } else {
          setError(err.message);
        }
      }
    } finally {
      setLoading(false);
    }
  }, [navigate, old_phone, password, phone, value]);
  useEffect(() => {
    if (value.join('').length === 4) {
      submit();
    }
    setError(null);
  }, [value, submit]);
  return (
    <Wrapper>
      <Seo title="Подтвердите номер телефона" />
      <Head>
        <NavigateBack />
        <Text typography="title24">Подтвердите номер телефона</Text>
      </Head>
      <Content gap="24px">
        <Text typography="title24">Код из смс</Text>
        <Flex direction="column">
          <Text color="textTertiary">
            Мы отправили смс код на ваш номер телефона:
          </Text>
          <Text display="inline">
            {phone}{' '}
            <Text
              display="inline"
              typography="text16Regular"
              color="mainPrimary"
              onClick={() => {
                navigate(-1);
              }}>
              изменить
            </Text>
          </Text>
        </Flex>

        <CodeInput
          error={error}
          value={value}
          onChange={setValue}
          disabled={loading}
        />
        <Flex
          direction="column"
          gap={16}
          justifyContent="center"
          alignItems="center">
          {timer < TIMEOUT ? (
            <Text color="textTertiary" align="center">
              {`Вы можете повторно получить код через ${TIMEOUT - timer} сек`}
            </Text>
          ) : (
            <Text
              disabled={sending}
              onClick={async () => {
                try {
                  setSending(true);
                  setError(null);
                  recaptchaRef.current?.execute();
                  setTimer(0);
                } catch (err) {
                  const error = ensureError(err);
                  showAlert('error', error.message);
                } finally {
                  setSending(false);
                }
              }}
              align="center"
              color="mainPrimary">
              Получить код повторно
            </Text>
          )}
          <Text
            color="textTertiary"
            onClick={() => {
              setInfoModal(true);
            }}>
            Почему не приходит код?
          </Text>
          <Popup
            style={{width: 442, alignItems: 'initial'}}
            visible={infoModal}
            onClose={() => {
              setInfoModal(false);
            }}>
            <Flex direction="column" gap={24}>
              <Flex flex={1} justifyContent="space-between" alignItems="center">
                <Text typography="title20" color="textPrimary">
                  Не получили код?
                </Text>
                <CloseButton
                  onClose={() => {
                    setInfoModal(false);
                  }}
                />
              </Flex>
              <Flex direction="column" gap={16}>
                <Text>
                  — Просто задерживается на пару минут — иногда такое бывает
                </Text>
                <Text>— У оператора технические проблемы</Text>
                <Text>
                  Если у оператора всё хорошо, но СМС не приходит даже с третьей
                  попытки,{' '}
                  <Text
                    display="inline"
                    color="mainPrimary"
                    onClick={() => {
                      setInfoModal(false);
                      window.open('https://support.booky.co', '_blank');
                    }}>
                    напишите нам
                  </Text>
                </Text>
              </Flex>
            </Flex>
          </Popup>
        </Flex>
      </Content>
      <ReCAPTCHA
        style={{display: 'inline-block', visibility: 'hidden'}}
        ref={recaptchaRef}
        size="invisible"
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY!}
        onChange={onCaptchaChange}
        onExpired={() => {
          showAlert('error', 'Время ввода капчи истекло');
          recaptchaRef.current?.reset();
        }}
        onErrored={() => {
          showAlert('error', 'Ошибка ввода капчи');
          recaptchaRef.current?.reset();
        }}
      />
    </Wrapper>
  );
}
