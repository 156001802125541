import React, {CSSProperties, ReactElement, createRef, useState} from 'react';
import styled from 'styled-components';
import {useColors, useResize} from 'shared/lib/hooks';
import {CircleAlert} from 'shared/icons/CircleAlert';

type Props = {
  element?: ReactElement;
  text: string;
  style?: CSSProperties;
};

const Backdrop = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
  pointer-events: all;
`;

const Container = styled.div<{
  x: number | null;
  y: number | null;
  isLeft: boolean;
  width: number;
}>`
  position: fixed;
  align-self: center;
  max-width: 343px;
  top: ${({y}) => (y ?? 0) + 7}px;
  left: ${({x, isLeft, width}) =>
    isLeft ? `${(x ?? 0) - 16}px` : `calc(${(x ?? 0) + 32}px - ${width}px)`};
  background-color: ${({theme}) => theme.bgTertiary};
  color: ${({theme}) => theme.textTertiary};
  padding: 16px;
  border-radius: 16px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: left;
  opacity: ${({x}) => (x ? 1 : 0)};
  visibility: ${({x}) => (x ? 'visible' : 'hidden')};
  transition: opacity 0.3s;
`;

const Arrow = styled.div<{x: number | null; y: number | null}>`
  position: fixed;
  top: ${({y}) => y ?? 0}px;
  left: ${({x}) => (x ?? 0) + 7}px;
  opacity: ${({x}) => (x ? 1 : 0)};
  visibility: ${({x}) => (x ? 'visible' : 'hidden')};
  transition: opacity 0.3s;
  padding: 0;
  display: flex;
  flex: 0;
`;

export const Popover = (props: Props) => {
  const colors = useColors();
  const {element = <CircleAlert color={colors.warningPrimary} />, text} = props;
  const [visible, setVisible] = useState(false);
  const [position, setPosition] = useState<{
    x: number | null;
    y: number | null;
    width: number | null;
  }>({
    x: null,
    y: null,
    width: null,
  });
  const ref = createRef<HTMLDivElement>();
  const refContainer = createRef<HTMLDivElement>();
  const {width} = useResize();

  return (
    <div>
      <div
        style={{
          cursor: 'pointer',
          pointerEvents: 'all',
        }}
        ref={ref}
        onClick={e => {
          e.stopPropagation();
          const element = ref.current?.getBoundingClientRect();
          setPosition({
            x: element?.left ?? null,
            y: element?.bottom ?? null,
            width: refContainer.current?.offsetWidth ?? null,
          });
          setVisible(true);
        }}>
        {element}
      </div>
      {visible && (
        <Backdrop
          onClick={e => {
            setVisible(false);
            setPosition({
              x: null,
              y: null,
              width: null,
            });
            e.stopPropagation();
          }}
        />
      )}
      <Arrow {...position}>
        <svg
          width="10"
          height="7"
          viewBox="0 0 10 7"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M3.304 1.71359C4.08734 0.460258 5.91266 0.460258 6.696 1.71359L10 7H0L3.304 1.71359Z"
            fill={colors.bgTertiary}
          />
        </svg>
      </Arrow>
      <Container
        ref={refContainer}
        style={props.style}
        {...position}
        width={position.width ?? 0}
        isLeft={(position.x ?? 0) < width / 2}>
        {text}
      </Container>
    </div>
  );
};
