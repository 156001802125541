import React, {useState} from 'react';
import {
  BonusCard as BonusCardType,
  BonusSize,
} from 'shared/__generated__/graphql';
import styled from 'styled-components';
import {Text} from 'shared/ui/Text';
import {Trash} from 'shared/icons/Trash';
import {useColors} from 'shared/lib/hooks';
import dayjs from 'dayjs';
import {DeletePopup} from './DeletePopup';
import {AccrualPopup} from './AccrualPopup';
import {ApolloError, useMutation} from '@apollo/client';
import {gql} from 'shared/__generated__';
import {HistoryPopup} from './HistoryPopup';
import {showAlert} from 'shared/ui/Alert';
import {History} from 'shared/icons/History';
import {Flex} from 'shared/ui/Flex';

const DELETE_CARD = gql(`
  mutation DeleteBonusCard($deleteBonusCardId: String!) {
    deleteBonusCard(id: $deleteBonusCardId) {
      id
    }
  }
`);

const ACCRUAL_CARD = gql(`
  mutation BonusCardAccrual($bonusCardId: String!, $summary: Int!) {
    bonusCardAccrual(bonus_card_id: $bonusCardId, summary: $summary) {
      id
      balance
    }
  }
`);

const Bonus = styled.div`
  display: flex;
  flex-direction: column;
  width: '100%';
  gap: 24px;
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Button = styled.button`
  display: flex;
  width: 40px;
  height: 40px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 14px;
  background-color: ${({theme}) => theme.bgPrimary};
  border: 1px solid ${({theme}) => theme.borderPrimary};
  pointer-events: all;
  cursor: pointer;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const countValidDay = (created_at: string, validity_months: number) => {
  if (!validity_months) {
    return 'Бессрочно';
  }
  const create = dayjs(created_at);
  const valid = dayjs(validity_months);
  const daysLeft = valid.diff(create, 'day');
  return daysLeft;
};

export const BonusCard = ({
  bonus,
  editPermission,
  fillPermission,
}: {
  bonus: Pick<
    BonusCardType,
    'id' | 'name' | 'balance' | 'created_at' | 'valid_until' | 'is_rechargeable'
  > & {
    bonusSizes: Array<Pick<BonusSize, 'id' | 'min_amount' | 'size'>>;
  };
  editPermission: boolean;
  fillPermission: boolean;
}) => {
  const color = useColors();
  const [deleteModal, setDeleteModal] = useState(false);
  const [accrualModal, setAccrualModal] = useState(false);
  const [historyModal, setHistoryModal] = useState(false);
  const [deleteBonus, {loading: deleteLoading}] = useMutation(DELETE_CARD, {
    refetchQueries: ['CustomersBonus'],
  });
  const [accrualBonus, {loading: accrualLoading}] = useMutation(ACCRUAL_CARD, {
    refetchQueries: ['GetBonusCardHistory'],
  });

  return (
    <Bonus>
      <DeletePopup
        visible={deleteModal}
        loading={deleteLoading}
        onClose={() => setDeleteModal(false)}
        onClickDelete={() => {
          deleteBonus({variables: {deleteBonusCardId: bonus.id}})
            .then(() => setDeleteModal(false))
            .catch((e: ApolloError) => showAlert('error', e.message));
        }}
      />
      <AccrualPopup
        visible={accrualModal}
        loading={accrualLoading}
        onClose={() => setAccrualModal(false)}
        onClickDone={async value => {
          if (bonus.is_rechargeable) {
            await accrualBonus({
              variables: {
                bonusCardId: bonus.id,
                summary: value,
              },
            })
              .then(() => {
                setAccrualModal(false);
              })
              .catch((e: ApolloError) => showAlert('error', e.message));
          }
        }}
      />
      <HistoryPopup
        id={bonus.id}
        visible={historyModal}
        onClose={() => setHistoryModal(false)}
      />
      <Content>
        <Text typography="title20">{bonus?.name}</Text>
        <div style={{display: 'flex', flexDirection: 'row', gap: 8}}>
          <Button onClick={() => setHistoryModal(true)}>
            <History />
          </Button>
          {editPermission && (
            <Button onClick={() => setDeleteModal(true)}>
              <Trash color={color.dangerPrimary} />
            </Button>
          )}
        </div>
      </Content>
      <Content style={{alignItems: 'flex-start'}}>
        <Box>
          <Text color="textTertiary" typography="subHead14Regular">
            Размер бонуса
          </Text>
          <Flex direction="column" gap={16}>
            {bonus.bonusSizes.map(bonusSize => (
              <Text
                key={bonusSize.id}
                typography="text16Semibold">{`от ${bonusSize.min_amount} ₽ - ${bonusSize.size}%`}</Text>
            ))}
          </Flex>
        </Box>
        <Box>
          <Text color="textTertiary" typography="subHead14Regular">
            Дата привязки
          </Text>
          <Text typography="text16Semibold">
            {dayjs(bonus?.created_at).format('DD.MM.YYYY')}
          </Text>
        </Box>
        <Box>
          <Text color="textTertiary" typography="subHead14Regular">
            Накопленный бонус
          </Text>
          <Text typography="text16Semibold">{bonus?.balance} Б</Text>
        </Box>
        <Box>
          <Text color="textTertiary" typography="subHead14Regular">
            Осталось дней
          </Text>
          <Text typography="text16Semibold">
            {countValidDay(bonus.created_at, bonus.valid_until)}
          </Text>
        </Box>
      </Content>
      {fillPermission && bonus?.is_rechargeable && (
        <Content>
          <button
            onClick={() => setAccrualModal(true)}
            style={{border: 0, backgroundColor: 'transparent'}}>
            <Text
              style={{pointerEvents: 'all', cursor: 'pointer'}}
              color="mainPrimary">
              + Пополнить
            </Text>
          </button>
        </Content>
      )}
    </Bonus>
  );
};
