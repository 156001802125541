import React from 'react';
import styled from 'styled-components';
import {device} from 'shared/device';

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;
  @media (${device.mobile}) {
    position: absolute;
    top: 16px;
    z-index: 100;
    left: 16px;
    margin-bottom: 0;
  }
`;

export function Head({
  children,
  style,
}: React.PropsWithChildren<{style?: React.CSSProperties}>) {
  return <Header style={style}>{children}</Header>;
}
