import * as React from 'react';

export const Success = ({size = 24}: {size?: number}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 116 116" fill="none">
      <circle opacity={0.05} cx={58} cy={58} r={58} fill="#6765F8" />
      <circle opacity={0.05} cx={58} cy={58} r={43} fill="#6765F8" />
      <circle opacity={0.05} cx={58} cy={58} r={28} fill="#6765F8" />
      <path
        d="M53 59.4l3.143 3.6L64 54"
        stroke="#6765F8"
        strokeWidth={2.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
