import React from 'react';
import {Discount, DiscountType} from 'shared/__generated__/graphql';
import {OptionsType, Table} from 'shared/ui/Table';
import {Text} from 'shared/ui/Text';
import {useNavigate} from 'react-router-dom';
import dayjs from 'dayjs';
import {useAppSelector} from 'shared/store';
import {Content} from 'shared/ui/Content';
import {getNumberFormat} from 'shared/lib/utils';

type Props = {
  discounts?: Array<
    Pick<
      Discount,
      | 'id'
      | 'name'
      | 'type'
      | 'value'
      | 'valid_from'
      | 'valid_to'
      | 'valid_from_time'
      | 'valid_to_time'
      | 'active_on_friday'
      | 'active_on_monday'
      | 'active_on_saturday'
      | 'active_on_sunday'
      | 'active_on_thursday'
      | 'active_on_tuesday'
      | 'active_on_wednesday'
    > & {category?: {id: string; title?: string}}
  >;
  onClick?: () => void;
  onChange?: () => void;
  checked?: boolean;
  options: OptionsType;
  setOptions: React.Dispatch<React.SetStateAction<OptionsType>>;
  onSelectionChange?: (values: number[]) => void;
};

const days = (
  discount: Pick<
    Discount,
    | 'active_on_friday'
    | 'active_on_monday'
    | 'active_on_saturday'
    | 'active_on_sunday'
    | 'active_on_thursday'
    | 'active_on_tuesday'
    | 'active_on_wednesday'
  >,
) =>
  [
    {active: discount?.active_on_monday, label: 'Пн'},
    {active: discount?.active_on_tuesday, label: 'Вт'},
    {active: discount?.active_on_wednesday, label: 'Ср'},
    {active: discount?.active_on_thursday, label: 'Чт'},
    {active: discount?.active_on_friday, label: 'Пт'},
    {active: discount?.active_on_saturday, label: 'Сб'},
    {active: discount?.active_on_sunday, label: 'Вс'},
  ]
    .filter(day => day.active)
    .map(day => day.label);
const formatTime = (time?: string | null) =>
  time ? dayjs(time).format('HH:mm') : null;
const formatDate = (date?: string | null) =>
  date ? dayjs(date).format('DD.MM.YYYY') : null;

const titles = [
  'Название',
  'Категория',
  'Период действия',
  'Время действия акции',
  'День',
  'Размер скидки',
];

export const DiscountsTable = ({
  discounts = [],
  options,
  setOptions,
  onSelectionChange,
}: Props) => {
  const navigate = useNavigate();
  const username = useAppSelector(state => state.company.data?.username);

  return (
    <Content>
      <Table
        titles={titles.map((item, index) => (
          <Text
            typography="subHead14Regular"
            color="textTertiary"
            key={index + 1}>
            {item}
          </Text>
        ))}
        options={options}
        setOptions={setOptions}
        onSelectionChange={onSelectionChange}
        onClick={index =>
          !!discounts?.length &&
          navigate(`/${username}/edit-discount/${discounts[index].id}`)
        }
        rows={discounts.map(discount => {
          const activeDays = days(discount);
          const daysText: string =
            activeDays.length === 7
              ? 'Вcе дни'
              : activeDays.length === 5 &&
                activeDays[0] === 'Пн' &&
                activeDays[4] === 'Пт'
              ? 'Пн-Пт'
              : activeDays.join(', ');
          const validityPeriod =
            discount?.valid_from && discount?.valid_to
              ? `${formatDate(discount.valid_from)}-${formatDate(
                  discount?.valid_to,
                )}`
              : 'Бессрочно';
          const validityTime =
            discount?.valid_from_time && discount?.valid_to_time
              ? `с ${formatTime(discount.valid_from_time)} до ${formatTime(
                  discount.valid_to_time,
                )}`
              : 'Круглосуточно';
          return [
            discount.name ?? '',
            discount.category?.title ?? '',
            validityPeriod,
            validityTime,
            daysText,
            getNumberFormat(
              discount.value,
              discount.type === DiscountType.Percentage ? ' %' : ' ₽',
            ),
          ];
        })}
      />
    </Content>
  );
};
