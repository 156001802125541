import React from 'react';
import {Content} from 'shared/ui/Content';
import {Flex} from 'shared/ui/Flex';
import {Skeleton} from 'shared/ui/Skeleton';

export const SkeletonDetailProduct = () => {
  return (
    <Flex direction="column" gap={24}>
      <Content>
        <Skeleton height={329}>
          <rect width={150} height={24} rx={8} ry={8} />
          <rect
            width={120}
            height={20}
            rx={4}
            ry={4}
            x={'calc(100% - 120px)'}
            y={2}
          />
          {Array.from(Array(4).keys()).map(item => (
            <React.Fragment key={'sk1_' + item}>
              <rect
                width={300}
                height={20}
                rx={4}
                ry={4}
                x={0}
                y={48 + 36 * item}
              />
              <rect
                width={80}
                height={20}
                rx={4}
                ry={4}
                x={'calc(100% - 80px)'}
                y={48 + 36 * item}
              />
            </React.Fragment>
          ))}
          <rect width={'100%'} height={1} y={200} />
          <rect width={150} height={20} x={0} y={225} rx={4} ry={4} />
          <rect width={210} height={20} x={0} y={261} rx={4} ry={4} />
          <rect width={150} height={20} x={0} y={285} rx={4} ry={4} />
          <rect width={100} height={20} x={0} y={309} rx={4} ry={4} />
        </Skeleton>
      </Content>
      <Content>
        <Skeleton height={264}>
          <rect width={150} height={24} rx={8} ry={8} />
          <rect
            width={120}
            height={20}
            rx={4}
            ry={4}
            x={'calc(100% - 120px)'}
            y={2}
          />
          {Array.from(Array(6).keys()).map(item => (
            <React.Fragment key={'sk1_' + item}>
              <rect
                width={300}
                height={20}
                rx={4}
                ry={4}
                x={0}
                y={48 + 36 * item}
              />
              <rect
                width={80}
                height={20}
                rx={4}
                ry={4}
                x={'calc(100% - 80px)'}
                y={48 + 36 * item}
              />
            </React.Fragment>
          ))}
        </Skeleton>
      </Content>
      <Content>
        <Skeleton height={210}>
          <rect width={300} height={24} rx={8} ry={8} x={0} y={0} />
          <rect width={162} height={162} rx={24} ry={24} y={48} />
          <rect width={162} height={162} rx={24} ry={24} x={170} y={48} />
          <rect width={162} height={162} rx={24} ry={24} x={340} y={48} />
          <rect width={162} height={162} rx={24} ry={24} x={510} y={48} />
        </Skeleton>
      </Content>
    </Flex>
  );
};
