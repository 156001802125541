import {ScheduleWorkTime} from './ui/ScheduleTime';
import {SettingSchedule, type WorkDate} from './ui/SettingSchedule';
import {DeleteSchedule} from './ui/DeleteSchedule';
import {getAbbreviatedSchedule, transaleteWeekDay} from './lib/utils';
import {gql} from 'shared/__generated__';

export const GET_EMPLOYEES = gql(`
  query CompanyEmployeesSchedule($companyId: String!, $first: Int!, $enabled: Boolean, $page: Int) {
    companyEmployees(first: $first, enabled: $enabled, company_id: $companyId, page: $page) {
      data {
        id
        avatar {
          id
          url
        }
        position
        name
        surname
        professions {
          id
          name
        }
        workingDates {
          id
          date
          start_time
          end_time
          breakTimes {
            id
            start
            end
          }
        }
      }
      paginatorInfo {
        currentPage
        hasMorePages
      }
    }
    ...Permissions
  }
`);
export const EMPLOYEE_SCHEDULE = gql(`
  query EmployeeSchedule($employeeId: String!, $companyId: String!) {
    employee(id: $employeeId) {
      id
      name
      surname
      avatar {
        id 
        url
      }
      workingDates {
        id
        date
        end_time
        start_time
        breakTimes {
          id
          start
          end
        }
      }
    }
    ...Permissions
  }
`);

export {
  ScheduleWorkTime,
  SettingSchedule,
  DeleteSchedule,
  getAbbreviatedSchedule,
  transaleteWeekDay,
  WorkDate,
};
