import React from 'react';
import {Flex} from 'shared/ui/Flex';
import {Text} from 'shared/ui/Text';

export const TextView = ({
  title,
  text,
  rightElement,
}: {
  title?: string | null;
  text?: string | null;
  rightElement?: () => React.ReactNode;
}) => {
  return (
    <Flex flex={1} alignItems="center" style={{overflowWrap: 'anywhere'}}>
      <Flex
        flex={1}
        direction="column"
        style={{height: 64}}
        gap={8}
        justifyContent="center">
        <Text typography="footNote12Regular" color="textTertiary">
          {title}
        </Text>
        <Text>{text ? text : 'Не указано'}</Text>
      </Flex>
      {rightElement && rightElement()}
    </Flex>
  );
};
