import React, {useState} from 'react';
import {Button} from 'shared/ui/Button';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';
import {AddBonusPopup} from './AddBonusPopup';
import {device} from 'shared/device';
import {EmptyBonusCard} from 'shared/illustration/EmptyBonusCard';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (${device.mobile}) {
    margin-left: 0;
    align-self: center;
    justify-content: center;
    padding: 16px;
  }
`;

const Empty = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: auto;
  padding-top: 58px;
`;

export const EmptyState = ({customerId}: {customerId: string}) => {
  const [modal, setModal] = useState(false);
  return (
    <Wrapper>
      <AddBonusPopup
        customerId={customerId}
        visible={modal}
        onClose={() => {
          setModal(false);
        }}
      />
      <Empty>
        <EmptyBonusCard size={300} />
        <Text
          typography="text16Regular"
          color="textTertiary"
          style={{
            textAlign: 'center',
            alignSelf: 'center',
            maxWidth: 500,
            marginTop: 16,
          }}>
          У данного клиента пока нет бонусной карты
        </Text>
      </Empty>
      <Button
        size="large"
        onClick={() => setModal(true)}
        style={{
          marginTop: 24,
          alignSelf: 'center',
          width: 400,
          marginBottom: 24,
        }}>
        Добавить бонусную карту
      </Button>
    </Wrapper>
  );
};
