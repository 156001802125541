import React from 'react';
export const Right = ({
  color = '#6765F8',
  size = 24,
}: {
  color?: string;
  size?: number;
}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path
        d="M5 12L19 12M19 12L13 18M19 12L13 6"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
