import React from 'react';
import {TextInput} from 'shared/ui/TextInput';
import {styled} from 'styled-components';
import {device} from 'shared/device';
import {Search} from 'shared/icons/Search';
import {useColors} from 'shared/lib/hooks';

const SearchInput = styled(TextInput)`
  display: flex;
  width: 254px;
  height: 56px;
  pointer-events: all;
  @media ${device.mobile} {
    width: calc(100vw - 128px);
  }
`;

export const FilterSearch = ({
  onChange,
  value,
}: {
  onChange: React.Dispatch<React.SetStateAction<string>>;
  value?: string;
}) => {
  const color = useColors();
  return (
    <SearchInput
      height={54}
      variant="default"
      onChange={onChange}
      borderRadius={14}
      value={value}
      backgroundColor={color.bgPrimary}
      placeholder="Поиск"
      leftElement={<Search color={color.textTertiary} />}
    />
  );
};
