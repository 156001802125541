import {useQuery} from '@apollo/client';
import React, {useEffect, useState} from 'react';
import {gql} from 'shared/__generated__';
import Close from 'shared/icons/Close';
import {useAppSelector} from 'shared/store';
import {Button} from 'shared/ui/Button';
import {Flex} from 'shared/ui/Flex';
import {Head} from 'shared/ui/Head';
import {IconButton} from 'shared/ui/IconButton';
import {Modal} from 'shared/ui/Modal';
import {MultiSelectChips} from 'shared/ui/MultiSelectChips';
import {Text} from 'shared/ui/Text';

const GET_PRODUCTS = gql(`
  query GetProductsFilter($companyId: String!, $first: Int!, $categoryFirst: Int!) {
    productsBrands(company_id: $companyId, first: $first) {
        data {
        id
        name
        }
    }
    productsCategory(company_id: $companyId, first: $categoryFirst) {
      data {
        id
        name
      }
      paginatorInfo {
        count
        currentPage
        hasMorePages
        lastPage
        total
      }
    }
  }
`);

export const ModalFilter = ({
  visible,
  onClose,
  categories: categoryValues,
  brands: brandValues,
  onChangeCategory,
  onChangeBrand,
}: {
  visible: boolean;
  onClose: () => void;
  categories: string[];
  brands: string[];
  onChangeCategory: (value: string[]) => void;
  onChangeBrand: (value: string[]) => void;
}) => {
  const companyId = useAppSelector(state => state.company.data?.id);
  const {data} = useQuery(GET_PRODUCTS, {
    variables: {
      first: 10,
      companyId: companyId!,
      categoryFirst: 40,
    },
  });
  const brands = data?.productsBrands?.data ?? [];
  const categories = data?.productsCategory?.data ?? [];

  const [selectCategories, setSelectCategories] =
    useState<string[]>(categoryValues);
  const [selectBrands, setSelectBrands] = useState<string[]>(brandValues);

  useEffect(() => {
    if (visible) {
      setSelectCategories(categoryValues);
      setSelectBrands(brandValues);
    }
  }, [brandValues, categoryValues, visible]);

  return (
    <Modal
      style={{
        left: 'calc((100% - 588px) / 2)',
        top: '25%',
        width: 588,
        gap: 24,
        height: 'auto',
        padding: 24,
      }}
      backdropVariant="overlay"
      visible={visible}
      onClose={onClose}>
      <Head
        style={{
          flex: 1,
          justifyContent: 'space-between',
        }}>
        <Text typography="title20">Фильтры</Text>
        <IconButton size={40} onClick={() => onClose()}>
          <Close />
        </IconButton>
      </Head>
      <Flex direction="column" gap={16}>
        <MultiSelectChips
          values={categories
            .filter(item => selectCategories.includes(item.id))
            .map(item => ({label: item.name, value: item.id}))}
          label="Категории"
          data={categories.map(item => ({label: item.name, value: item.id}))}
          onChange={values =>
            setSelectCategories(values.map(item => item.value))
          }
          onDelete={id =>
            setSelectCategories(selectCategories.filter(item => item !== id))
          }
          listEmpty={'Список категорий пуст'}
        />
        <MultiSelectChips
          data={brands.map(item => ({label: item.name, value: item.id}))}
          values={brands
            .filter(item => selectBrands.includes(item.id))
            .map(item => ({label: item.name, value: item.id}))}
          label="Бренды"
          onChange={values => setSelectBrands(values.map(item => item.value))}
          listEmpty={'Список брендов пуст'}
          onDelete={id =>
            setSelectBrands(selectBrands.filter(item => item !== id))
          }
        />
      </Flex>
      <Flex direction="row" gap={8} style={{marginTop: 24}}>
        <Button
          size="large"
          style={{flex: 1}}
          variant="outline"
          onClick={() => {
            onClose();
          }}>
          Отмена
        </Button>
        <Button
          size="large"
          style={{flex: 1}}
          variant="filled"
          onClick={() => {
            onChangeCategory(selectCategories);
            onChangeBrand(selectBrands);
            onClose();
          }}>
          Применить
        </Button>
      </Flex>
    </Modal>
  );
};
