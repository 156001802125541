import React, {useState} from 'react';
import {ApolloError, useMutation, useQuery} from '@apollo/client';
import {
  SelectCustomer,
  SelectDate,
  SelectEmployee,
  SelectServices,
  Status,
  Comment,
  BOOKING,
} from 'entities/booking';
import {useParams} from 'react-router-dom';
import {CompanyBookingStatus} from 'shared/__generated__/graphql';
import {SkeletonDetailBooking} from './ui/Skeleton';
import {Container} from './ui/Container';
import {Error} from 'shared/ui/Error';
import {
  setBookingScreen,
  setEditBookingCustomer,
  setEditBookingDuration,
  setEditBookingServices,
  setEditBookingWorkingDate,
  useAppDispatch,
  useAppSelector,
} from 'shared/store';
import {Content} from 'shared/ui/Content';
import {UPDATE_COMPANY_BOOKING} from 'entities/booking';
import {showAlert} from 'shared/ui/Alert';
import {useEmployeePermission} from 'shared/lib/hooks';
import {Seo} from 'shared/ui/Seo';
import {Flex} from 'shared/ui/Flex';

export const DetailBooking = () => {
  const params = useParams();
  const {id} = params;
  const companyId = useAppSelector(state => state.company.data?.id);
  const dispatch = useAppDispatch();
  const {data, loading, error} = useQuery(BOOKING, {
    variables: {
      bookingId: id!,
      companyId: companyId!,
    },
    onCompleted: resData => {
      setStatus(resData.booking?.status as CompanyBookingStatus);
      dispatch(setBookingScreen('edit'));
      dispatch(setEditBookingCustomer(resData.booking?.customer?.id ?? null));
      dispatch(setEditBookingDuration(resData.booking?.duration ?? 0));
      dispatch(
        setEditBookingServices(
          resData.booking?.bookingServices.map(service => ({
            price: service.price,
            additional_price: service.additional_price,
            service_id: service.service.id,
            discount_id: service.discount?.id,
          })) ?? [],
        ),
      );
      dispatch(
        setEditBookingWorkingDate(
          resData.booking?.workingDate.date +
            ' ' +
            resData.booking?.start +
            ',' +
            resData.booking?.workingDate.id,
        ),
      );
    },
  });
  const [status, setStatus] = useState<CompanyBookingStatus>(
    data?.booking?.status as CompanyBookingStatus,
  );
  const {customers_info_permission, edit_bookings_permission} =
    useEmployeePermission(data);
  const [updateCompanyStatus] = useMutation(UPDATE_COMPANY_BOOKING, {
    refetchQueries: [BOOKING],
  });

  const date = data
    ? data?.booking?.workingDate.date + ' ' + data?.booking?.start
    : null;

  if (loading) {
    return (
      <Container>
        <SkeletonDetailBooking />
      </Container>
    );
  }

  if (error) {
    return <Error message={error.message} />;
  }

  return (
    <Container>
      <Seo title="Подробнее о записи" />
      <Status
        value={status}
        onChange={value => {
          if (edit_bookings_permission) {
            setStatus(value);
            updateCompanyStatus({
              variables: {
                input: {
                  booking_id: id!,
                  company_id: companyId!,
                  status: value,
                },
              },
            }).catch(e => {
              if (e instanceof ApolloError) {
                showAlert('error', e.message);
              }
            });
          }
        }}
      />
      <Flex direction="column" gap={24}>
        <Content>
          <SelectEmployee
            services={
              data?.booking?.bookingServices.map(
                service => service.service.id,
              ) ?? []
            }
            value={data?.booking?.employee.id}
            workingDate={data?.booking?.workingDate.date}
            start={data?.booking?.start}
            booking={false}
            disabled
            variant="view"
          />
        </Content>
        <Content>
          <SelectDate disabled value={date} variant="view" />
        </Content>
        {data?.booking?.customer?.id && (
          <Content>
            <SelectCustomer
              value={data?.booking?.customer?.id}
              disabled
              comment={data?.booking?.customer_comment}
              variant="view"
              permission={customers_info_permission}
            />
          </Content>
        )}
        <Content>
          <SelectServices disabled variant="view" />
        </Content>
        <Content>
          <Comment
            value={data?.booking?.comment ?? ''}
            disabled
            id={id}
            author={data?.booking?.author}
            created_at={data?.booking?.created_at}
          />
        </Content>
      </Flex>
    </Container>
  );
};
