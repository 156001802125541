import React from 'react';
import {useColorScheme} from 'shared/lib/hooks';
import {EmptyContainer} from 'shared/ui/EmptyContainer';

export const EmptyBonusCard = ({size = 400}: {size?: number}) => {
  const colorScheme = useColorScheme();
  return (
    <EmptyContainer size={size}>
      {colorScheme === 'light' ? (
        <svg viewBox="0 0 400 400" fill="none">
          <path
            d="M341.916 203.944C341.916 235.756 331.27 264.961 313.613 288.69C304.005 301.467 292.32 312.68 278.818 321.285C257.266 335.626 231.3 343.97 203.257 343.97C126.657 343.97 64.3379 281.128 64.3379 203.683C64.3379 126.238 126.657 63.9172 202.997 63.9172C230.781 63.9172 256.487 72.2615 278.558 86.3424C292.061 94.9473 303.746 106.16 313.353 118.937C331.01 142.927 341.916 172.131 341.916 203.944Z"
            fill="#EAEEF9"
          />
          <path
            d="M95.4868 344.689C98.0834 344.689 100.42 342.602 100.42 339.734C100.42 336.866 98.3431 334.78 95.4868 334.78C92.8902 334.78 90.5532 336.866 90.5532 339.734C90.5532 342.863 92.8902 344.689 95.4868 344.689Z"
            fill="url(#paint0_linear_18292_149)"
          />
          <path
            d="M248.178 97.5549L331.01 180.736C341.396 191.167 341.396 207.855 331.01 218.285L201.958 347.621C197.804 351.793 192.351 354.401 186.898 355.183C178.848 356.226 170.539 353.879 164.567 347.621L81.7352 264.44C71.3487 254.009 71.3487 237.321 81.7352 226.89L210.527 97.5549C215.461 92.6005 222.212 89.9929 228.963 89.7322C235.974 89.7322 242.725 92.3398 248.178 97.5549Z"
            fill="url(#paint1_linear_18292_149)"
          />
          <g filter="url(#filter0_d_18292_149)">
            <path
              d="M248.178 97.5549L331.01 180.736C341.396 191.167 341.396 207.855 331.01 218.285L201.958 347.621C197.804 351.793 192.351 354.401 186.898 355.183C178.848 356.226 170.539 353.879 164.567 347.621L81.7352 264.44C71.3487 254.009 71.3487 237.321 81.7352 226.89L210.527 97.5549C215.461 92.6005 222.212 89.9929 228.963 89.7322C235.974 89.7322 242.725 92.3398 248.178 97.5549Z"
              fill="url(#paint2_linear_18292_149)"
            />
          </g>
          <path
            d="M275.701 125.195L289.204 138.754C289.204 139.015 288.944 139.015 288.684 139.276L248.437 179.954L295.695 227.151L308.419 239.928C308.678 240.189 308.678 240.189 308.938 240.45L295.435 254.009C295.176 254.009 295.176 253.748 294.916 253.748L247.658 206.812L234.675 194.035L123.02 306.16L109.258 292.34L220.913 180.215L174.693 133.8L188.455 119.98L234.934 166.134L275.442 125.456C275.182 125.456 275.442 125.456 275.701 125.195Z"
            fill="url(#paint3_linear_18292_149)"
          />
          <path
            d="M330.75 218.285L305.303 243.839H216.499L221.173 226.108H207.67L204.554 204.986L236.493 188.298L264.796 150.488L262.978 139.015H289.723L331.009 180.475C341.136 191.166 341.136 207.855 330.75 218.285Z"
            fill="url(#paint4_linear_18292_149)"
          />
          <path
            d="M303.483 210.202L288.683 213.07L290.24 226.369V227.673L288.163 226.369L240.905 196.643L239.347 226.369L238.568 244.361L228.701 235.235L216.756 243.579L217.276 226.369L217.795 211.245L260.899 180.736L303.483 210.202Z"
            fill="white"
          />
          <path
            d="M206.892 252.184L152.883 306.422C151.585 307.725 151.585 309.811 152.883 311.115C154.181 312.419 156.259 312.419 157.557 311.115L211.566 256.878C212.865 255.574 212.865 253.488 211.566 252.184C210.268 250.88 208.191 250.88 206.892 252.184Z"
            fill="#AAB2C5"
          />
          <path
            d="M202.22 279.824L188.198 293.905C186.9 295.209 186.9 297.295 188.198 298.599C189.497 299.903 191.574 299.903 192.872 298.599L206.894 284.518C208.192 283.214 208.192 281.128 206.894 279.824C205.596 278.52 203.518 278.52 202.22 279.824Z"
            fill="#AAB2C5"
          />
          <path
            opacity="0.6"
            d="M303.488 210.202L288.687 213.07L290.245 226.369H288.168L240.909 196.643L239.351 226.369H217.28L217.8 211.245L260.903 180.736L303.488 210.202Z"
            fill="url(#paint5_linear_18292_149)"
          />
          <path
            d="M180.925 301.466L164.567 317.894C163.268 319.198 163.268 321.284 164.567 322.588C165.865 323.891 167.942 323.891 169.24 322.588L185.599 306.16C186.897 304.856 186.897 302.77 185.599 301.466C184.041 300.163 182.223 300.163 180.925 301.466Z"
            fill="#AAB2C5"
          />
          <path
            d="M213.904 176.564H230.003L242.986 193.253L235.456 198.468L217.799 222.718C213.904 227.933 205.855 227.412 202.739 221.675L185.601 189.863C182.485 183.865 186.64 176.564 193.391 176.564H194.429"
            fill="url(#paint6_linear_18292_149)"
          />
          <path
            d="M241.947 194.035L250.257 190.645L280.118 189.08C286.609 188.82 290.764 181.779 287.648 175.782L270.51 143.969C267.394 137.972 258.825 137.711 255.19 143.187L235.196 173.696L241.947 194.035Z"
            fill="url(#paint7_linear_18292_149)"
          />
          <g opacity="0.3">
            <path
              opacity="0.3"
              d="M238.313 197.425L235.976 198.989L236.236 198.729L223.772 215.417C221.695 218.285 219.617 220.893 217.54 223.761C215.203 226.369 211.308 227.412 207.933 226.108V225.847C211.308 226.89 214.943 225.847 217.021 223.24C219.098 220.632 220.916 217.764 222.993 214.896L234.937 197.946L237.274 196.382L238.313 197.425Z"
              fill="#AAB2C5"
            />
          </g>
          <g opacity="0.3">
            <path
              opacity="0.3"
              d="M245.581 191.689L249.995 189.863L270.249 189.342L280.376 189.081C283.751 188.82 286.867 186.474 287.906 183.344C286.867 186.474 283.751 189.081 280.376 189.603L270.249 190.385L249.995 191.689H250.255L246.1 192.993L245.581 191.689Z"
              fill="#AAB2C5"
            />
          </g>
          <path
            d="M223.25 186.212C220.135 178.911 222.731 171.088 228.444 167.959C234.935 164.308 244.023 168.48 248.697 176.825C253.371 185.169 251.554 195.078 245.062 198.728C240.388 201.336 234.416 200.293 230.002 196.121C226.626 193.252 225.328 190.906 223.25 186.212Z"
            fill="#ECF0F5"
          />
          <path
            d="M201.337 85.7567C200.558 85.7567 199.779 85.4959 199.26 84.9744L198.221 84.1921C198.221 84.1921 197.962 84.1921 197.962 83.9314L182.901 68.8075C181.603 67.5037 181.603 65.4176 182.901 64.1138C184.2 62.81 186.277 62.81 187.575 64.1138L203.415 80.02C204.713 81.063 204.973 83.1491 203.934 84.7136C203.155 85.2352 202.376 85.7567 201.337 85.7567Z"
            fill="#969EAE"
          />
          <path
            d="M227.042 53.9441C225.224 53.9441 223.667 52.3795 223.667 50.5542V28.3898C223.667 26.5645 225.224 25 227.042 25C228.86 25 230.418 26.5645 230.418 28.3898V50.815C230.418 52.3795 228.86 53.9441 227.042 53.9441Z"
            fill="#969EAE"
          />
          <path
            d="M227.042 76.1083C225.224 76.1083 223.667 74.5437 223.667 72.7184V62.8096C223.667 60.9843 225.224 59.4198 227.042 59.4198C228.86 59.4198 230.418 60.9843 230.418 62.8096V72.7184C230.418 74.5437 228.86 76.1083 227.042 76.1083Z"
            fill="#969EAE"
          />
          <path
            d="M253.529 80.802C252.75 80.802 251.971 80.5413 251.192 79.759C249.894 78.4552 249.894 76.3692 251.192 75.0654L264.176 62.0275C265.474 60.7237 267.551 60.7237 268.849 62.0275C270.148 63.3313 270.148 65.4174 268.849 66.7212L255.866 79.759C255.347 80.5413 254.308 80.802 253.529 80.802Z"
            fill="#969EAE"
          />
          <path
            d="M126.14 125.195C123.283 120.241 116.013 119.459 112.377 124.152C108.742 119.459 101.472 120.241 98.6154 125.195C96.0188 129.367 97.8365 133.279 101.212 137.19C104.588 141.102 109.002 144.491 111.339 146.056C111.599 146.317 112.118 146.317 112.637 146.317C113.156 146.317 113.676 146.317 113.935 146.056C116.013 144.752 120.687 141.362 124.062 137.19C126.919 133.54 128.736 129.628 126.14 125.195Z"
            fill="url(#paint8_linear_18292_149)"
          />
          <path
            d="M341.396 304.596C340.358 298.86 333.866 295.73 328.673 298.86C326.596 293.123 319.845 291.298 315.171 295.209C311.276 298.338 311.795 302.771 313.613 307.465C315.43 312.158 318.546 317.113 320.104 319.199C320.364 319.459 320.624 319.72 321.143 319.981C321.662 320.242 321.922 320.242 322.441 319.981C325.038 319.459 330.491 317.895 334.905 315.287C339.059 312.68 342.175 309.551 341.396 304.596Z"
            fill="url(#paint9_linear_18292_149)"
          />
          <path
            opacity="0.5"
            d="M249.216 353.357C244.542 352.835 240.647 356.747 241.686 361.44C237.012 361.44 233.896 366.134 235.454 370.567C236.752 374.217 240.128 375 244.023 375C248.177 375 252.332 373.696 254.409 372.914C254.669 372.914 254.929 372.653 255.188 372.392C255.448 372.131 255.448 371.871 255.708 371.349C255.967 369.263 255.967 364.83 255.188 360.658C254.669 357.007 253.111 353.878 249.216 353.357Z"
            fill="url(#paint10_linear_18292_149)"
          />
          <path
            opacity="0.5"
            d="M73.1673 189.342C68.4934 188.82 64.5985 192.731 65.6371 197.425C60.9632 197.425 57.8473 202.119 59.4053 206.552C60.9632 210.202 64.3388 210.984 68.2338 210.984C72.3883 210.984 76.5429 209.681 78.6202 208.898C78.8799 208.898 79.1395 208.638 79.3992 208.377C79.6589 208.116 79.9185 207.595 79.9185 207.334C80.1782 205.248 80.1782 200.815 79.3992 196.643C78.6202 192.992 77.0622 189.863 73.1673 189.342Z"
            fill="url(#paint11_linear_18292_149)"
          />
          <path
            d="M317.247 97.2935C315.429 93.1214 309.976 91.8176 306.601 95.2075C304.004 91.2961 298.551 91.0354 295.695 94.686C293.358 97.815 294.397 100.944 296.474 104.334C298.551 107.724 301.927 110.853 303.485 112.157C303.744 112.417 304.004 112.417 304.523 112.678C304.783 112.678 305.302 112.678 305.562 112.417C307.38 111.635 311.275 109.288 314.391 106.42C316.987 103.812 318.805 100.944 317.247 97.2935Z"
            fill="url(#paint12_linear_18292_149)"
          />
          <defs>
            <filter
              id="filter0_d_18292_149"
              x="60.9453"
              y="84.7322"
              width="290.854"
              height="291.69"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="8" />
              <feGaussianBlur stdDeviation="6.5" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.396078 0 0 0 0 0.478431 0 0 0 0 0.576471 0 0 0 0.4 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_18292_149"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_18292_149"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_18292_149"
              x1="90.6306"
              y1="339.862"
              x2="99.8488"
              y2="339.862"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#B0BACC" />
              <stop offset="1" stopColor="#969EAE" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_18292_149"
              x1="185.617"
              y1="353.847"
              x2="226.397"
              y2="97.5661"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#AAB2C5" />
              <stop offset="1" stopColor="#AAB2C5" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_18292_149"
              x1="248.65"
              y1="113.168"
              x2="153.581"
              y2="326.767"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#FDFEFF" />
              <stop offset="1" stopColor="#ECF0F5" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_18292_149"
              x1="123.22"
              y1="306.043"
              x2="290.131"
              y2="139.834"
              gradientUnits="userSpaceOnUse">
              <stop offset="0.0113208" stopColor="#B0BACC" />
              <stop offset="1" stopColor="#ECF0F5" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_18292_149"
              x1="222.565"
              y1="188.3"
              x2="308.427"
              y2="197.177"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#AAB2C5" />
              <stop offset="1" stopColor="#B0BACC" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id="paint5_linear_18292_149"
              x1="244.903"
              y1="180.164"
              x2="258.992"
              y2="214.499"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#B0BACC" />
              <stop offset="1" stopColor="#969EAE" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id="paint6_linear_18292_149"
              x1="230.374"
              y1="214.642"
              x2="211.305"
              y2="193.824"
              gradientUnits="userSpaceOnUse">
              <stop offset="0.0113208" stopColor="#969EAE" />
              <stop offset="1" stopColor="#ECF0F5" />
            </linearGradient>
            <linearGradient
              id="paint7_linear_18292_149"
              x1="267.254"
              y1="180.863"
              x2="248.185"
              y2="160.044"
              gradientUnits="userSpaceOnUse">
              <stop offset="0.0113208" stopColor="#969EAE" />
              <stop offset="1" stopColor="#ECF0F5" />
            </linearGradient>
            <linearGradient
              id="paint8_linear_18292_149"
              x1="122.611"
              y1="139.141"
              x2="102.182"
              y2="121.605"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#B0BACC" />
              <stop offset="1" stopColor="#969EAE" />
            </linearGradient>
            <linearGradient
              id="paint9_linear_18292_149"
              x1="333.252"
              y1="316.434"
              x2="319.93"
              y2="292.995"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#B0BACC" />
              <stop offset="1" stopColor="#969EAE" />
            </linearGradient>
            <linearGradient
              id="paint10_linear_18292_149"
              x1="256.051"
              y1="362.294"
              x2="235.195"
              y2="366.318"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#B0BACC" />
              <stop offset="1" stopColor="#969EAE" />
            </linearGradient>
            <linearGradient
              id="paint11_linear_18292_149"
              x1="79.9573"
              y1="198.335"
              x2="59.1014"
              y2="202.359"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#B0BACC" />
              <stop offset="1" stopColor="#969EAE" />
            </linearGradient>
            <linearGradient
              id="paint12_linear_18292_149"
              x1="313.106"
              y1="107.805"
              x2="298.743"
              y2="92.0967"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#B0BACC" />
              <stop offset="1" stopColor="#969EAE" />
            </linearGradient>
          </defs>
        </svg>
      ) : (
        <svg viewBox="0 0 400 400" fill="none">
          <path
            d="M341.917 203.943C341.917 235.756 331.27 264.961 313.613 288.69C304.006 301.467 292.321 312.679 278.819 321.284C257.267 335.626 231.301 343.97 203.258 343.97C126.658 343.97 64.3389 281.128 64.3389 203.683C64.3389 126.238 126.658 63.917 202.998 63.917C230.782 63.917 256.488 72.2612 278.559 86.3421C292.062 94.9471 303.746 106.16 313.354 118.937C331.011 142.926 341.917 172.131 341.917 203.943Z"
            fill="#282C35"
          />
          <path
            d="M95.4868 344.689C98.0834 344.689 100.42 342.603 100.42 339.734C100.42 336.866 98.3431 334.78 95.4868 334.78C92.8902 334.78 90.5532 336.866 90.5532 339.734C90.5532 342.863 92.8902 344.689 95.4868 344.689Z"
            fill="url(#paint0_linear_18292_147)"
          />
          <path
            d="M248.179 97.5549L331.011 180.736C341.398 191.167 341.398 207.855 331.011 218.285L201.96 347.621C197.805 351.793 192.352 354.401 186.899 355.183C178.85 356.226 170.541 353.879 164.568 347.621L81.7366 264.439C71.3502 254.009 71.3502 237.321 81.7366 226.89L210.529 97.5549C215.462 92.6005 222.213 89.9929 228.964 89.7322C235.975 89.7322 242.727 92.3397 248.179 97.5549Z"
            fill="url(#paint1_linear_18292_147)"
          />
          <path
            d="M248.179 97.5549L331.011 180.736C341.398 191.167 341.398 207.855 331.011 218.285L201.96 347.621C197.805 351.793 192.352 354.401 186.899 355.183C178.85 356.226 170.541 353.879 164.568 347.621L81.7366 264.439C71.3502 254.009 71.3502 237.321 81.7366 226.89L210.529 97.5549C215.462 92.6005 222.213 89.9929 228.964 89.7322C235.975 89.7322 242.727 92.3397 248.179 97.5549Z"
            fill="url(#paint2_linear_18292_147)"
          />
          <path
            d="M275.705 125.195L289.207 138.755C289.207 139.015 288.947 139.015 288.688 139.276L248.44 179.954L295.698 227.151L308.422 239.928C308.681 240.189 308.681 240.189 308.941 240.45L295.439 254.009C295.179 254.009 295.179 253.749 294.919 253.749L247.661 206.812L234.678 194.035L123.024 306.161L109.262 292.341L220.916 180.215L174.696 133.8L188.458 119.98L234.938 166.134L275.445 125.456C275.185 125.456 275.445 125.456 275.705 125.195Z"
            fill="url(#paint3_linear_18292_147)"
          />
          <path
            d="M330.751 218.286L305.305 243.84H216.501L221.174 226.108H207.672L204.556 204.987L236.494 188.299L264.798 150.489L262.98 139.016H289.725L331.011 180.476C341.138 191.167 341.138 207.855 330.751 218.286Z"
            fill="url(#paint4_linear_18292_147)"
          />
          <path
            d="M303.487 210.202L288.686 213.07L290.244 226.369V227.672L288.167 226.369L240.909 196.642L239.351 226.369L238.572 244.361L228.705 235.234L216.76 243.579L217.28 226.369L217.799 211.245L260.903 180.736L303.487 210.202Z"
            fill="#8E96AB"
          />
          <path
            d="M206.894 252.184L152.884 306.422C151.586 307.725 151.586 309.811 152.884 311.115C154.183 312.419 156.26 312.419 157.558 311.115L211.568 256.878C212.866 255.574 212.866 253.488 211.568 252.184C210.269 250.88 208.192 250.88 206.894 252.184Z"
            fill="#8E96AB"
          />
          <path
            d="M202.22 279.824L188.198 293.905C186.9 295.209 186.9 297.295 188.198 298.599C189.497 299.903 191.574 299.903 192.872 298.599L206.894 284.518C208.192 283.214 208.192 281.128 206.894 279.824C205.596 278.52 203.518 278.52 202.22 279.824Z"
            fill="#8E96AB"
          />
          <path
            opacity="0.6"
            d="M303.487 210.202L288.687 213.07L290.245 226.369H288.167L240.909 196.642L239.351 226.369H217.28L217.799 211.245L260.903 180.736L303.487 210.202Z"
            fill="url(#paint5_linear_18292_147)"
          />
          <path
            d="M180.928 301.467L164.569 317.894C163.271 319.198 163.271 321.284 164.569 322.588C165.867 323.892 167.945 323.892 169.243 322.588L185.602 306.16C186.9 304.856 186.9 302.77 185.602 301.467C184.044 300.163 182.226 300.163 180.928 301.467Z"
            fill="#8E96AB"
          />
          <path
            d="M213.904 176.564H230.003L242.987 193.253L235.456 198.468L217.799 222.718C213.904 227.934 205.855 227.412 202.739 221.675L185.601 189.863C182.485 183.866 186.64 176.564 193.391 176.564H194.43"
            fill="url(#paint6_linear_18292_147)"
          />
          <path
            d="M241.949 194.035L250.258 190.646L280.119 189.081C286.611 188.82 290.765 181.78 287.649 175.782L270.512 143.97C267.396 137.973 258.827 137.712 255.192 143.188L235.198 173.696L241.949 194.035Z"
            fill="url(#paint7_linear_18292_147)"
          />
          <g opacity="0.3">
            <path
              opacity="0.3"
              d="M238.313 197.425L235.976 198.989L236.236 198.729L223.772 215.417C221.695 218.285 219.617 220.893 217.54 223.761C215.203 226.369 211.308 227.412 207.933 226.108V225.847C211.308 226.89 214.943 225.847 217.021 223.24C219.098 220.632 220.916 217.764 222.993 214.896L234.937 197.946L237.274 196.382L238.313 197.425Z"
              fill="#5C6479"
            />
          </g>
          <g opacity="0.3">
            <path
              opacity="0.3"
              d="M245.583 191.688L249.997 189.863L270.251 189.341L280.378 189.08C283.753 188.82 286.869 186.473 287.908 183.344C286.869 186.473 283.753 189.08 280.378 189.602L270.251 190.384L249.997 191.688H250.257L246.102 192.992L245.583 191.688Z"
              fill="#5C6479"
            />
          </g>
          <path
            d="M223.253 186.213C220.137 178.911 222.734 171.089 228.446 167.96C234.938 164.309 244.026 168.481 248.7 176.825C253.374 185.17 251.556 195.078 245.064 198.729C240.391 201.337 234.418 200.294 230.004 196.121C226.629 193.253 225.33 190.906 223.253 186.213Z"
            fill="#E3EAF6"
          />
          <path
            d="M201.334 85.7562C200.555 85.7562 199.776 85.4954 199.257 84.9739L198.218 84.1916C198.218 84.1916 197.958 84.1916 197.958 83.9309L182.898 68.807C181.6 67.5032 181.6 65.4171 182.898 64.1133C184.196 62.8096 186.274 62.8096 187.572 64.1133L203.411 80.0195C204.71 81.0625 204.969 83.1486 203.931 84.7131C203.152 85.2347 202.373 85.7562 201.334 85.7562Z"
            fill="#5C6479"
          />
          <path
            d="M227.041 53.944C225.223 53.944 223.665 52.3795 223.665 50.5542V28.3898C223.665 26.5645 225.223 25 227.041 25C228.858 25 230.416 26.5645 230.416 28.3898V50.8149C230.416 52.3795 228.858 53.944 227.041 53.944Z"
            fill="#5C6479"
          />
          <path
            d="M227.041 76.1081C225.223 76.1081 223.665 74.5436 223.665 72.7183V62.8095C223.665 60.9842 225.223 59.4197 227.041 59.4197C228.858 59.4197 230.416 60.9842 230.416 62.8095V72.7183C230.416 74.5436 228.858 76.1081 227.041 76.1081Z"
            fill="#5C6479"
          />
          <path
            d="M253.527 80.8019C252.748 80.8019 251.969 80.5411 251.19 79.7589C249.891 78.4551 249.891 76.369 251.19 75.0653L264.173 62.0274C265.471 60.7236 267.548 60.7236 268.847 62.0274C270.145 63.3312 270.145 65.4172 268.847 66.721L255.863 79.7589C255.344 80.5411 254.305 80.8019 253.527 80.8019Z"
            fill="#5C6479"
          />
          <path
            d="M126.138 125.195C123.281 120.241 116.011 119.459 112.376 124.152C108.74 119.459 101.47 120.241 98.6135 125.195C96.0169 129.368 97.8345 133.279 101.21 137.19C104.586 141.102 109 144.491 111.337 146.056C111.597 146.317 112.116 146.317 112.635 146.317C113.155 146.317 113.674 146.317 113.933 146.056C116.011 144.752 120.685 141.362 124.06 137.19C126.917 133.54 128.734 129.628 126.138 125.195Z"
            fill="url(#paint8_linear_18292_147)"
          />
          <path
            d="M341.398 304.596C340.359 298.86 333.868 295.73 328.674 298.86C326.597 293.123 319.846 291.298 315.172 295.209C311.277 298.338 311.797 302.771 313.614 307.465C315.432 312.158 318.548 317.113 320.106 319.199C320.365 319.459 320.625 319.72 321.144 319.981C321.664 320.242 321.923 320.242 322.443 319.981C325.039 319.459 330.492 317.895 334.906 315.287C339.061 312.68 342.177 309.551 341.398 304.596Z"
            fill="url(#paint9_linear_18292_147)"
          />
          <path
            opacity="0.5"
            d="M249.217 353.357C244.543 352.836 240.648 356.747 241.686 361.441C237.013 361.441 233.897 366.134 235.455 370.567C236.753 374.218 240.128 375 244.023 375C248.178 375 252.333 373.696 254.41 372.914C254.67 372.914 254.929 372.653 255.189 372.392C255.448 372.132 255.448 371.871 255.708 371.349C255.968 369.263 255.968 364.83 255.189 360.658C254.67 357.008 253.112 353.879 249.217 353.357Z"
            fill="url(#paint10_linear_18292_147)"
          />
          <path
            opacity="0.5"
            d="M73.1673 189.342C68.4934 188.82 64.5985 192.732 65.6371 197.425C60.9632 197.425 57.8473 202.119 59.4053 206.552C60.9632 210.202 64.3388 210.985 68.2338 210.985C72.3883 210.985 76.5429 209.681 78.6202 208.898C78.8799 208.898 79.1395 208.638 79.3992 208.377C79.6588 208.116 79.9185 207.595 79.9185 207.334C80.1782 205.248 80.1782 200.815 79.3992 196.643C78.6202 192.992 77.0622 189.863 73.1673 189.342Z"
            fill="url(#paint11_linear_18292_147)"
          />
          <path
            d="M317.249 97.2941C315.432 93.122 309.979 91.8182 306.603 95.2081C304.007 91.2967 298.554 91.036 295.697 94.6866C293.36 97.8156 294.399 100.945 296.476 104.335C298.554 107.724 301.929 110.854 303.487 112.157C303.747 112.418 304.007 112.418 304.526 112.679C304.786 112.679 305.305 112.679 305.565 112.418C307.382 111.636 311.277 109.289 314.393 106.421C316.99 103.813 318.807 100.945 317.249 97.2941Z"
            fill="url(#paint12_linear_18292_147)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_18292_147"
              x1="90.6306"
              y1="339.863"
              x2="99.8488"
              y2="339.863"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#2A2E37" />
              <stop offset="1" stopColor="#2A2E37" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_18292_147"
              x1="185.618"
              y1="353.847"
              x2="226.399"
              y2="97.5661"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#AAB2C5" />
              <stop offset="1" stopColor="#AAB2C5" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_18292_147"
              x1="248.651"
              y1="113.168"
              x2="153.583"
              y2="326.767"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#5C6479" />
              <stop offset="1" stopColor="#5C6479" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_18292_147"
              x1="123.224"
              y1="306.044"
              x2="290.135"
              y2="139.834"
              gradientUnits="userSpaceOnUse">
              <stop offset="0.0113208" stopColor="#E3EAF6" />
              <stop offset="1" stopColor="#ADB6C8" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_18292_147"
              x1="222.567"
              y1="188.3"
              x2="308.429"
              y2="197.178"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#2A2E37" />
              <stop offset="1" stopColor="#E3EAF6" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id="paint5_linear_18292_147"
              x1="244.903"
              y1="180.163"
              x2="258.991"
              y2="214.499"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#5C6479" />
              <stop offset="1" stopColor="#E3EAF6" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id="paint6_linear_18292_147"
              x1="230.374"
              y1="214.643"
              x2="211.306"
              y2="193.824"
              gradientUnits="userSpaceOnUse">
              <stop offset="0.0113208" stopColor="#E3EAF6" />
              <stop offset="1" stopColor="#ADB6C8" />
            </linearGradient>
            <linearGradient
              id="paint7_linear_18292_147"
              x1="267.255"
              y1="180.864"
              x2="248.186"
              y2="160.045"
              gradientUnits="userSpaceOnUse">
              <stop offset="0.0113208" stopColor="#E3EAF6" />
              <stop offset="1" stopColor="#8E96AB" />
            </linearGradient>
            <linearGradient
              id="paint8_linear_18292_147"
              x1="122.609"
              y1="139.142"
              x2="102.18"
              y2="121.605"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#5C6479" />
              <stop offset="1" stopColor="#5C6479" />
            </linearGradient>
            <linearGradient
              id="paint9_linear_18292_147"
              x1="333.254"
              y1="316.434"
              x2="319.931"
              y2="292.995"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#2A2E37" />
              <stop offset="1" stopColor="#2A2E37" />
            </linearGradient>
            <linearGradient
              id="paint10_linear_18292_147"
              x1="256.051"
              y1="362.294"
              x2="235.195"
              y2="366.318"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#2A2E37" />
              <stop offset="1" stopColor="#2A2E37" />
            </linearGradient>
            <linearGradient
              id="paint11_linear_18292_147"
              x1="79.9573"
              y1="198.335"
              x2="59.1014"
              y2="202.359"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#5C6479" />
              <stop offset="1" stopColor="#5C6479" />
            </linearGradient>
            <linearGradient
              id="paint12_linear_18292_147"
              x1="313.109"
              y1="107.805"
              x2="298.745"
              y2="92.0973"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#5C6479" />
              <stop offset="1" stopColor="#5C6479" />
            </linearGradient>
          </defs>
        </svg>
      )}
    </EmptyContainer>
  );
};
