import React, {useEffect} from 'react';
import {Navigate, Outlet, redirect} from 'react-router-dom';
import {useAppSelector} from 'shared/store';

const PrivateRouter = () => {
  const token = useAppSelector(state => state.auth.token);

  useEffect(() => {
    if (!token) {
      redirect('/');
    }
  }, [token]);

  return token ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateRouter;
