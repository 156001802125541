import React, {useState} from 'react';
import {styled} from 'styled-components';
import {StatisticsDateTime} from 'shared/__generated__/graphql';
import {FilterButton} from 'shared/ui/FilterButton';
import {Sales} from 'shared/lib/utils';
import {Modal} from 'shared/ui/Modal';
import {List} from 'shared/ui/List';
import {Text} from 'shared/ui/Text';

const PopupWrapper = styled.div`
  position: relative;
  display: inline-block;
`;
const ButtonPeriod = styled.button<{checked?: boolean}>`
  display: flex;
  padding: 0 16px;
  align-items: center;
  height: 40px;
  width: 343px;
  background-color: ${({checked, theme}) =>
    checked ? theme.bgSecondary : 'transparent'};
  border: none;
`;

export const FilterStatisticsSales = ({
  time = StatisticsDateTime.Day,
  setTime,
}: {
  time?: StatisticsDateTime;
  setTime?: (time: StatisticsDateTime) => void;
}) => {
  const [visible, setVisible] = useState(false);
  return (
    <PopupWrapper>
      <FilterButton
        onClick={() => setVisible(true)}
        active={StatisticsDateTime.Day !== time}
        title={time && Sales[time]}
        visible={visible}
      />
      <Modal
        style={{
          left: 'auto',
          right: 'auto',
          top: 64,
          paddingLeft: 0,
          paddingRight: 0,
          gap: 8,
          width: 200,
          overflow: 'hidden',
        }}
        visible={visible}
        onClose={() => setVisible(false)}>
        <List
          data={Object.values(Sales)}
          keyExtractor={(_, index) => 'key_' + index}
          renderItem={(item, index) => (
            <ButtonPeriod
              onClick={() => {
                setTime &&
                  setTime(Object.keys(Sales)[index] as StatisticsDateTime);
                setVisible(false);
              }}
              checked={item === Sales[time]}>
              <Text>{item}</Text>
            </ButtonPeriod>
          )}
        />
      </Modal>
    </PopupWrapper>
  );
};
