import React from 'react';
import {Button} from 'shared/ui/Button';
import Close from 'shared/icons/Close';

export const FilterResetButton = ({onClick}: {onClick?: () => void}) => {
  return (
    <Button
      leftIcon={<Close size={20} />}
      style={{height: 56, padding: '18px 16px'}}
      variant="light"
      size="large"
      onClick={onClick}>
      Сбросить фильтры
    </Button>
  );
};
