import React, {useEffect, useState} from 'react';
import {TextInput} from 'shared/ui/TextInput';
import styled, {useTheme} from 'styled-components';
import {Search} from 'shared/icons/Search';
import {Select} from 'shared/ui/Select';
import {Button} from 'shared/ui/Button';
import {ReviewsFilters} from './ReviewsFilters';
import {BottomSheet} from 'shared/ui/BottomSheet';
import {Text} from 'shared/ui/Text';
import {Filter1} from 'shared/icons/Filter1';
import {useResize} from 'shared/lib/hooks';
import {device} from 'shared/device';
import {useNavigate} from 'react-router-dom';
import {useAppSelector} from 'shared/store';
import {CloseButton} from 'shared/ui/CloseButton';
import {FilterBtn} from 'shared/ui/FilterButton';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 24px 24px 0 24px;
  gap: 8px;
  @media (${device.mobile}) {
    margin: 16px 16px 0 16px;
  }
`;

const FilterButton = styled(Button)<{active: boolean}>`
  padding: 16px;
  height: 56px;
  gap: 8px;
  width: ${({active}) => (active ? '84px' : '56px')};
`;

const FilterText = styled(Text)`
  background-color: white;
  width: 20px;
  height: 20px;
  border-radius: 6px;
  justify-content: center;
`;

const SearchReview = styled(TextInput)`
  flex: 1;
  pointer-events: all;
  @media (${device.mobile}) {
    min-width: 160px;
  }
`;

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 8px 24px;
  width: 540px;
`;
const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const ModalButtons = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  flex: 1;
  margin-top: 24px;
`;

const sortData = [
  {label: 'По умолчанию', value: undefined},
  {label: 'От новым к старым', value: 'new'},
  {label: 'От старым к новым', value: 'old'},
  {label: 'От лучших к худшим', value: 'best'},
  {label: 'От худших к лучшим', value: 'worst'},
];

type FilterType = {
  reply?: 'with' | 'without';
  target?: 'company' | 'employees';
  ratings?: 'good' | 'bad';
  period?: number;
  orderBy?: string;
  search?: string;
};

export const ReviewsSettings = ({
  onChange,
  filtersData,
}: {
  onChange?: (value: FilterType) => void;
  filtersData: FilterType;
}) => {
  const colors = useTheme();
  const [modal, setModal] = useState(false);
  const [sort, setSort] = useState<string | undefined>(sortData[0].value);
  const [countActiveFilters, setCountActiveFilters] = useState(0);

  const isMobile = useResize().isMobile;
  const navigate = useNavigate();
  const username = useAppSelector(state => state.company.data?.username);

  const [filtersDesk, setFilters] = useState<FilterType>({
    reply: filtersData?.reply,
    target: filtersData?.target,
    ratings: filtersData?.ratings,
    period: filtersData?.period,
    search: filtersData.search,
  });

  const isActiveFilters = countActiveFilters > 0;

  useEffect(() => {
    setFilters(filtersData);
    setCountActiveFilters(
      +!!filtersData.reply +
        +!!filtersData.target +
        +!!filtersData.ratings +
        +(filtersData.period !== undefined),
    );
  }, [filtersData]);

  return (
    <Wrapper>
      <SearchReview
        wrapperStyle={isMobile ? {width: '100%'} : {width: 350}}
        height={56}
        borderRadius={14}
        backgroundColor={colors.bgPrimary}
        variant="default"
        leftElement={<Search color={colors.textTertiary} />}
        placeholder="Поиск"
        value={filtersDesk.search ?? ''}
        onChange={text => {
          onChange && onChange({search: text});
        }}
      />
      {isMobile ? (
        <FilterButton
          active={isActiveFilters}
          onClick={() => {
            navigate(`/${username}/reviews/filters`, {state: {filtersData}});
          }}
          style={{
            backgroundColor: isActiveFilters
              ? colors.mainPrimary
              : colors.bgPrimary,
          }}>
          <Filter1
            color={isActiveFilters ? colors.white : colors.mainPrimary}
          />
          <FilterText
            align="center"
            typography="footNote12Regular"
            style={{
              display: isActiveFilters ? 'flex' : 'none',
            }}>
            {countActiveFilters}
          </FilterText>
        </FilterButton>
      ) : (
        <>
          <FilterBtn
            onClick={() => setModal(true)}
            indicator={countActiveFilters}
            style={{height: 58, borderRadius: 14}}
          />
          <Select
            wrapperStyle={{maxWidth: 230, marginLeft: 'auto'}}
            style={{
              maxWidth: 230,
              marginLeft: 'auto',
              height: 56,
            }}
            backgroundColor={colors.bgPrimary}
            data={sortData.map(item => ({
              value: item.value,
              label: item.label,
            }))}
            value={sort}
            onChange={value => {
              setSort(value);
              onChange && onChange({orderBy: value});
            }}
          />
        </>
      )}

      <BottomSheet
        visible={modal}
        onClose={() => setModal(false)}
        wrapperStyle={{transform: 'translate(-50%, -50%)'}}>
        <ModalWrapper>
          <ModalHeader>
            <Text typography="title20" color="textPrimary" align="center">
              Фильтры
            </Text>
            <CloseButton
              onClose={() => {
                setModal(false);
              }}
            />
          </ModalHeader>
          <ReviewsFilters filtersData={filtersDesk} onChange={setFilters} />
          <ModalButtons>
            <Button
              style={{flex: 1}}
              size="large"
              variant="outline"
              typography="text16Semibold"
              onClick={() => setModal(false)}>
              Отмена
            </Button>
            <Button
              style={{flex: 1}}
              size="large"
              typography="text16Semibold"
              onClick={() => {
                onChange && onChange(filtersDesk);
                setModal(false);
                setCountActiveFilters(
                  +!!filtersDesk.reply +
                    +!!filtersDesk.target +
                    +!!filtersDesk.ratings +
                    +(filtersDesk.period !== undefined),
                );
              }}>
              Применить
            </Button>
          </ModalButtons>
        </ModalWrapper>
      </BottomSheet>
    </Wrapper>
  );
};
