import {useQuery} from '@apollo/client';
import React from 'react';
import {ArrowLeft2v} from 'shared/icons/ArrowLeft2v';
import {ArrowRight2v} from 'shared/icons/ArrowRight2v';
import {Plus2} from 'shared/icons/Plus2';
import {useAppSelector} from 'shared/store';
import {Button} from 'shared/ui/Button';
import {Content} from 'shared/ui/Content';
import {Flex} from 'shared/ui/Flex';
import {IconButton} from 'shared/ui/IconButton';
import {Layout} from 'shared/ui/Layout';
import {Table} from 'shared/ui/Table';
import {Text} from 'shared/ui/Text';
import {styled} from 'styled-components';
import {EmptyState} from './ui/EmptyState';
import {SkeletonBookingSource} from './ui/Skeleton';
import {useNavigate} from 'react-router-dom';
import {Seo} from 'shared/ui/Seo';
import {COMPANY_SOURCES} from 'entities/bookingSource';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-column-start: 4;
  grid-column-end: 10;
  padding-top: 64px;
  gap: 24px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;

const ColorView = styled.div<{color: string}>`
  width: 4px;
  height: 56px;
  border-radius: 8px;
  background-color: ${({color}) => `rgb(${color})`};
`;

const TypeSource = styled(Text)<{isOnline: boolean}>`
  padding: 8px 16px;
  margin: 0 16px;
  width: max-content;
  border-radius: 12px;
  background-color: ${({isOnline, theme}) =>
    isOnline ? theme.bgSuccess : theme.fillPrimary};
`;

export const BookingSource = () => {
  const companyId = useAppSelector(state => state.company.data?.id);
  const {data, loading} = useQuery(COMPANY_SOURCES, {
    variables: {
      companyId: companyId!,
    },
  });
  const username = useAppSelector(state => state.company.data?.username);
  const navigate = useNavigate();

  const sources = data?.companySources ?? [];

  return (
    <Layout variant="stack" columns={12}>
      <Wrapper>
        <Header>
          <Seo title="Источники записей" />
          <Row>
            <IconButton size={40} onClick={() => navigate(-1)}>
              <ArrowLeft2v />
            </IconButton>
            <Text typography="title24">Источники записей</Text>
          </Row>
          <Button
            size="medium"
            onClick={() => navigate(`/${username}/booking-source/create`)}>
            <Plus2 color="white" size={20} /> Добавить источник
          </Button>
        </Header>
        <Content>
          {loading ? (
            <SkeletonBookingSource />
          ) : data?.companySources?.length === 0 ? (
            <EmptyState />
          ) : (
            <Table
              titles={['Название источника', 'Ключ (ref)', 'Тип источника']}
              widths={[undefined, 240, 133, 60]}
              styleTd={{padding: '12px 0'}}
              onClick={index => {
                const source = sources[index];
                if (source) {
                  // TODO: удалить когда будет сделать запрос для одного ресурса
                  navigate(`/${username}/booking-source/${source.id}/edit`, {
                    state: {
                      id: source.id,
                      name: source.name,
                      ref: source.ref,
                      online: source.is_online,
                      color_id: source.color?.id,
                    },
                  });
                }
              }}
              rows={sources.map(item => [
                <Flex key={'name_' + item.id} gap={12} alignItems="center">
                  <ColorView color={item.color?.rgb ?? 'transparent'} />
                  <Text>{item.name}</Text>
                </Flex>,
                <Text
                  key={'ref_' + item.id}
                  style={{padding: '0 16px'}}
                  color="textTertiary">
                  {item.ref}
                </Text>,
                <TypeSource
                  key={'typeSource_' + item.id}
                  isOnline={item.is_online}>
                  <Text
                    typography="text16Semibold"
                    color={item.is_online ? 'successPrimary' : 'textSecondary'}>
                    {item.is_online ? 'Онлайн' : 'Офлайн'}
                  </Text>
                </TypeSource>,
                <IconButton
                  size={60}
                  key={'arrowRight_' + item.id}
                  variant="unstyled">
                  <ArrowRight2v />
                </IconButton>,
              ])}
            />
          )}
        </Content>
      </Wrapper>
    </Layout>
  );
};
