import React from 'react';
import {useAppSelector} from 'shared/store';

export const EmptyNotification = ({size = 400}: {size?: number}) => {
  const theme = useAppSelector(state => state.theme.theme);

  if (theme === 'light') {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 400 400"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M209.898 352.924C282.346 352.924 341.081 294.189 341.081 221.482C341.081 148.775 282.087 90.04 209.898 90.04C137.45 90.04 78.7148 148.775 78.7148 221.482C78.7148 294.189 137.45 352.924 209.898 352.924Z"
          fill="#EAEEF9"
        />
        <path
          d="M349.23 306.29C355.088 306.29 359.838 301.541 359.838 295.682C359.838 289.823 355.088 285.073 349.23 285.073C343.371 285.073 338.621 289.823 338.621 295.682C338.621 301.541 343.371 306.29 349.23 306.29Z"
          fill="#EAEEF9"
        />
        <path
          d="M364.753 264.891C368.754 264.891 371.997 261.647 371.997 257.646C371.997 253.645 368.754 250.401 364.753 250.401C360.751 250.401 357.508 253.645 357.508 257.646C357.508 261.647 360.751 264.891 364.753 264.891Z"
          fill="#EAEEF9"
        />
        <path
          d="M85.6979 135.062C89.6992 135.062 92.9428 131.818 92.9428 127.817C92.9428 123.816 89.6992 120.572 85.6979 120.572C81.6967 120.572 78.4531 123.816 78.4531 127.817C78.4531 131.818 81.6967 135.062 85.6979 135.062Z"
          fill="#EAEEF9"
        />
        <path
          d="M41.4547 274.783C48.8855 274.783 54.9094 268.759 54.9094 261.328C54.9094 253.898 48.8855 247.874 41.4547 247.874C34.0239 247.874 28 253.898 28 261.328C28 268.759 34.0239 274.783 41.4547 274.783Z"
          fill="#EAEEF9"
        />
        <path
          d="M226.358 269.138H197.205C190.45 269.138 184.762 275.37 184.762 282.769V298.737C184.762 315.095 196.85 328.337 211.782 328.337C226.714 328.337 238.802 315.095 238.802 298.737V282.769C238.802 274.98 233.113 269.138 226.358 269.138Z"
          fill="white"
        />
        <g filter="url(#filter0_d_10185_7494)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M194.613 116.131V113.409C194.613 105.235 201.013 98.8372 209.19 98.8372C217.367 98.8372 223.766 105.235 223.766 113.409V116.131C252.292 122.729 273.541 148.276 273.541 178.806V186.98C273.541 255.931 325.448 301.069 325.448 301.069H92.5781C92.5781 301.069 144.84 255.931 144.84 186.98V178.806C144.84 148.277 166.088 122.73 194.613 116.131Z"
            fill="url(#paint0_linear_10185_7494)"
          />
        </g>
        <path
          d="M182.171 215.059C173.283 215.059 165.817 209.372 162.973 201.198C162.262 199.421 163.328 197.643 165.106 196.933C166.884 196.222 168.661 197.288 169.372 199.065C171.15 204.396 176.483 208.306 182.171 208.306C187.86 208.306 193.192 204.752 194.97 199.065C195.681 197.288 197.459 196.222 199.236 196.933C201.014 197.643 202.081 199.421 201.37 201.198C198.525 209.728 191.059 215.059 182.171 215.059Z"
          fill="#ADB6C8"
        />
        <path
          d="M235.855 215.059C226.967 215.059 219.501 209.372 216.656 201.198C215.945 199.421 217.012 197.643 218.789 196.933C220.567 196.222 222.345 197.288 223.056 199.065C224.833 204.396 230.166 208.306 235.855 208.306C241.543 208.306 246.876 204.752 248.654 199.065C249.365 197.288 251.142 196.222 252.92 196.933C254.698 197.643 255.764 199.421 255.053 201.198C252.209 209.728 244.743 215.059 235.855 215.059Z"
          fill="#ADB6C8"
        />
        <path
          d="M209.193 258.576C213.709 258.576 217.37 254.916 217.37 250.402C217.37 245.887 213.709 242.227 209.193 242.227C204.677 242.227 201.016 245.887 201.016 250.402C201.016 254.916 204.677 258.576 209.193 258.576Z"
          fill="#ADB6C8"
        />
        <path
          d="M253.008 58.0307V46H299.505V58.0307L267.778 96.3103H300.052V108.341H251.367V96.3103L283.094 58.0307H253.008Z"
          fill="url(#paint1_linear_10185_7494)"
        />
        <path
          d="M291.297 148.261V140.605H321.383V148.261L301.143 172.87H321.93V180.526H290.203V172.87L310.443 148.261H291.297Z"
          fill="url(#paint2_linear_10185_7494)"
        />
        <defs>
          <filter
            id="filter0_d_10185_7494"
            x="70.5781"
            y="87.8372"
            width="276.87"
            height="246.232"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="11" />
            <feGaussianBlur stdDeviation="11" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_10185_7494"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_10185_7494"
              result="shape"
            />
          </filter>
          <linearGradient
            id="paint0_linear_10185_7494"
            x1="208.937"
            y1="94.1593"
            x2="208.937"
            y2="303.25"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#FDFEFF" />
            <stop offset="0.9964" stopColor="#ECF0F5" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_10185_7494"
            x1="275.713"
            y1="48.1657"
            x2="275.713"
            y2="83.1571"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#B0BACC" />
            <stop offset="1" stopColor="#969EAE" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_10185_7494"
            x1="306.069"
            y1="141.992"
            x2="306.069"
            y2="164.399"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#B0BACC" />
            <stop offset="1" stopColor="#969EAE" />
          </linearGradient>
        </defs>
      </svg>
    );
  } else {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 400 400"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M209.894 352.924C282.342 352.924 341.077 294.189 341.077 221.482C341.077 148.775 282.084 90.0405 209.894 90.0405C137.446 90.0405 78.7109 148.775 78.7109 221.482C78.7109 294.189 137.446 352.924 209.894 352.924Z"
          fill="#2A2E37"
        />
        <path
          d="M349.23 306.291C355.089 306.291 359.839 301.541 359.839 295.682C359.839 289.823 355.089 285.074 349.23 285.074C343.371 285.074 338.622 289.823 338.622 295.682C338.622 301.541 343.371 306.291 349.23 306.291Z"
          fill="#2A2E37"
        />
        <path
          d="M364.753 264.892C368.754 264.892 371.997 261.648 371.997 257.647C371.997 253.646 368.754 250.402 364.753 250.402C360.751 250.402 357.508 253.646 357.508 257.647C357.508 261.648 360.751 264.892 364.753 264.892Z"
          fill="#2A2E37"
        />
        <path
          d="M85.6979 135.061C89.6992 135.061 92.9428 131.818 92.9428 127.817C92.9428 123.815 89.6992 120.572 85.6979 120.572C81.6967 120.572 78.4531 123.815 78.4531 127.817C78.4531 131.818 81.6967 135.061 85.6979 135.061Z"
          fill="#2A2E37"
        />
        <path
          d="M41.4547 274.783C48.8855 274.783 54.9094 268.76 54.9094 261.329C54.9094 253.898 48.8855 247.874 41.4547 247.874C34.0239 247.874 28 253.898 28 261.329C28 268.76 34.0239 274.783 41.4547 274.783Z"
          fill="#2A2E37"
        />
        <path
          d="M226.358 269.138H197.205C190.45 269.138 184.762 275.369 184.762 282.769V298.737C184.762 315.094 196.85 328.336 211.782 328.336C226.714 328.336 238.802 315.094 238.802 298.737V282.769C238.802 274.98 233.113 269.138 226.358 269.138Z"
          fill="#5C6479"
        />
        <g filter="url(#filter0_d_10185_8346)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M194.613 116.132V113.41C194.613 105.235 201.013 98.8379 209.19 98.8379C217.367 98.8379 223.766 105.235 223.766 113.41V116.132C252.292 122.73 273.54 148.277 273.54 178.807V186.981C273.54 255.932 325.447 301.07 325.447 301.07H92.5776C92.5776 301.07 144.84 255.932 144.84 186.981V178.807C144.84 148.277 166.088 122.731 194.613 116.132Z"
            fill="url(#paint0_linear_10185_8346)"
          />
        </g>
        <path
          d="M182.171 215.06C173.283 215.06 165.817 209.373 162.973 201.199C162.262 199.422 163.328 197.644 165.106 196.934C166.884 196.223 168.661 197.289 169.372 199.066C171.15 204.397 176.483 208.307 182.171 208.307C187.86 208.307 193.192 204.753 194.97 199.066C195.681 197.289 197.459 196.223 199.236 196.934C201.014 197.644 202.081 199.422 201.37 201.199C198.525 209.729 191.059 215.06 182.171 215.06Z"
          fill="#9FA8BA"
        />
        <path
          d="M235.855 215.06C226.967 215.06 219.501 209.373 216.656 201.199C215.945 199.422 217.012 197.644 218.789 196.934C220.567 196.223 222.345 197.289 223.056 199.066C224.833 204.397 230.166 208.307 235.855 208.307C241.543 208.307 246.876 204.753 248.654 199.066C249.365 197.289 251.142 196.223 252.92 196.934C254.698 197.644 255.764 199.422 255.053 201.199C252.209 209.729 244.743 215.06 235.855 215.06Z"
          fill="#9FA8BA"
        />
        <path
          d="M209.193 258.577C213.709 258.577 217.37 254.917 217.37 250.402C217.37 245.887 213.709 242.228 209.193 242.228C204.677 242.228 201.016 245.887 201.016 250.402C201.016 254.917 204.677 258.577 209.193 258.577Z"
          fill="#9FA8BA"
        />
        <path
          d="M253.008 58.0307V46H299.504V58.0307L267.777 96.3103H300.051V108.341H251.367V96.3103L283.094 58.0307H253.008Z"
          fill="#9FA8BA"
        />
        <path
          d="M291.298 148.262V140.606H321.384V148.262L301.144 172.87H321.931V180.526H290.204V172.87L310.443 148.262H291.298Z"
          fill="#9FA8BA"
        />
        <defs>
          <filter
            id="filter0_d_10185_8346"
            x="70.5776"
            y="87.8379"
            width="276.87"
            height="246.232"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="11" />
            <feGaussianBlur stdDeviation="11" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.27 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_10185_8346"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_10185_8346"
              result="shape"
            />
          </filter>
          <linearGradient
            id="paint0_linear_10185_8346"
            x1="208.936"
            y1="94.1601"
            x2="208.936"
            y2="303.251"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#5C6479" />
            <stop offset="0.9964" stopColor="#5C6479" />
          </linearGradient>
        </defs>
      </svg>
    );
  }
};
