import React from 'react';
import {Text} from './Text';
import {Flex} from './Flex';

export function Error({message}: {message: string}) {
  return (
    <Flex justifyContent={'center'} alignItems={'center'} flex={1}>
      <Text align="center">{message}</Text>
    </Flex>
  );
}
