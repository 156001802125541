import {ApolloError, useMutation, useQuery} from '@apollo/client';
import React, {useState} from 'react';
import {gql} from 'shared/__generated__';
import Accept from 'shared/icons/Accept';
import {useColors} from 'shared/lib/hooks';
import {useAppSelector} from 'shared/store';
import {showAlert} from 'shared/ui/Alert';
import {Button} from 'shared/ui/Button';
import {Content} from 'shared/ui/Content';
import {Layout} from 'shared/ui/Layout';
import {PageHeader} from 'shared/ui/PageHeader';
import {Switch} from 'shared/ui/Switch';
import {TableViewRow} from 'shared/ui/TableViewRow';
import {Text} from 'shared/ui/Text';
import {TextInput} from 'shared/ui/TextInput';
import styled from 'styled-components';
import {SkeletonCreateSourceColor} from './ui/Skeleton';
import {useNavigate} from 'react-router-dom';
import {
  COMPANY_SOURCES,
  COMPANY_SOURCE_COLORS,
  ColorView,
  GridColor,
} from 'entities/bookingSource';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  grid-column-start: 4;
  grid-column-end: 10;
  gap: 24px;
`;

const CREATE_COMPANY_SOURCE = gql(`
  mutation CreateSource($input: CreateSource!) {
    createSource(input: $input) {
      id
      is_online
      name
      ref
      color {
        id
        rgb
      }
    }
  }
`);

export const CreateBookingSource = () => {
  const colors = useColors();
  const [name, setName] = useState('');
  const [ref, setRef] = useState('');
  const [online, setOnline] = useState(true);
  const [color, setColor] = useState<string | null>(null);
  const {data, loading} = useQuery(COMPANY_SOURCE_COLORS, {
    variables: {
      page: 1,
      first: 100,
    },
  });
  const navigate = useNavigate();
  const companyId = useAppSelector(state => state.company.data?.id);
  const colorsData = data?.colors?.data ?? [];
  const [createCompanySource, {loading: loadingCreate}] = useMutation(
    CREATE_COMPANY_SOURCE,
    {
      refetchQueries: [
        {
          query: COMPANY_SOURCES,
          variables: {
            companyId: companyId!,
          },
        },
      ],
    },
  );

  return (
    <Layout variant="stack">
      <Wrapper>
        <PageHeader variant="layout" back title="Добавить источник" />
        <Content>
          <TextInput
            required
            label="Название источника"
            value={name}
            onChange={setName}
          />
          <TextInput
            required
            label="Ключ (ref)"
            value={ref}
            onChange={setRef}
          />
          <TableViewRow
            title="Онлайн"
            subtitle="Источники с включенной опцией будут учитываться в диаграммах как онлайн-записи. Если этот параметр отключен, бронирования будут учтены как офлайн-записи."
            rightElement={() => <Switch value={online} />}
            style={{cursor: 'pointer'}}
            styleTitle={{pointerEvents: 'none'}}
            styleSubTitle={{pointerEvents: 'none'}}
            onClick={() => setOnline(state => !state)}
          />
        </Content>
        <Content>
          <Text typography="title18">Цвет источника</Text>
          {loading ? (
            <SkeletonCreateSourceColor />
          ) : (
            <GridColor>
              {colorsData.map(item => (
                <ColorView
                  key={item.id}
                  color={item.rgb}
                  onClick={() => {
                    setColor(item.id);
                  }}>
                  {item.id === color ? (
                    <Accept size={'min(100%, 28px)'} color={colors.white} />
                  ) : undefined}
                </ColorView>
              ))}
            </GridColor>
          )}
        </Content>
        <Button
          style={{marginTop: 8}}
          size="large"
          disabled={name === '' || ref === '' || color === null}
          loading={loadingCreate}
          onClick={async () => {
            if (name !== '' && ref !== '' && color !== null) {
              try {
                await createCompanySource({
                  variables: {
                    input: {
                      company_id: companyId!,
                      name,
                      ref,
                      color_id: color,
                      is_online: online,
                    },
                  },
                });
                navigate(-1);
              } catch (e) {
                if (e instanceof ApolloError) {
                  showAlert('error', e.message);
                }
              }
            } else {
              showAlert('error', 'Заполните все поля и выберите цвет');
            }
          }}>
          Добавить источник
        </Button>
      </Wrapper>
    </Layout>
  );
};
