import React from 'react';
import {useColorScheme} from 'shared/lib/hooks';
import {EmptyContainer} from 'shared/ui/EmptyContainer';

export const EmptyCustomersSearch = ({size = 400}: {size?: number}) => {
  const colorScheme = useColorScheme();
  return (
    <EmptyContainer size={size} mt={58}>
      {colorScheme === 'light' ? (
        <svg viewBox="0 0 400 400" fill="none">
          <g filter="url(#filter0_d_17168_46)">
            <path
              d="M234.892 244.788H135.362C131.118 244.788 127.682 241.353 127.682 237.109V100.595C127.682 96.3509 131.118 92.9153 135.362 92.9153H234.892C239.136 92.9153 242.572 96.3509 242.572 100.595V237.109C242.572 241.353 239.136 244.788 234.892 244.788Z"
              fill="url(#paint0_linear_17168_46)"
            />
          </g>
          <path
            d="M198.061 207.956H172.193C169.313 207.956 166.989 205.632 166.989 202.752C166.989 199.873 169.313 197.549 172.193 197.549H198.061C200.941 197.549 203.265 199.873 203.265 202.752C203.265 205.632 200.941 207.956 198.061 207.956Z"
            fill="#D5DDEA"
          />
          <path
            d="M209.934 219.223H160.27C158.754 219.223 157.542 218.011 157.542 216.495C157.542 214.979 158.754 213.767 160.27 213.767H209.934C211.45 213.767 212.662 214.979 212.662 216.495C212.662 217.96 211.45 219.223 209.934 219.223Z"
            fill="#D5DDEA"
          />
          <path
            d="M219.079 147.076C219.079 157.281 214.582 166.426 207.459 172.64C201.497 177.844 193.666 180.977 185.127 180.977C176.589 180.977 168.758 177.844 162.796 172.59C155.672 166.375 151.176 157.231 151.176 147.025C151.176 128.281 166.383 113.073 185.127 113.073C203.871 113.073 219.079 128.332 219.079 147.076Z"
            fill="#D9DEEA"
          />
          <path
            d="M207.458 172.64C201.497 177.844 193.666 180.977 185.127 180.977C176.589 180.977 168.758 177.844 162.796 172.59C165.272 171.63 168.505 170.367 172.85 168.599C173.86 168.194 174.618 167.437 175.073 166.477C175.326 165.921 175.427 165.365 175.427 164.759V156.625C175.275 156.473 175.174 156.271 175.073 156.119C174.366 155.058 173.911 153.846 173.759 152.583L173.052 152.229C170.981 152.734 171.132 150.511 170.576 146.217C170.374 144.448 170.627 144.095 171.435 143.792L172.092 142.882C168.05 133.788 170.071 128.079 174.063 126.058C172.749 123.178 172.648 122.117 172.648 122.117C172.648 122.117 181.136 123.532 184.016 122.976C187.704 122.218 193.413 123.128 195.535 128.028C199.072 129.393 200.436 131.666 200.739 134.041C201.042 136.264 200.385 138.689 199.728 140.558C199.678 140.71 199.627 140.811 199.577 140.912C199.577 140.962 199.526 141.013 199.526 141.063C199.425 141.316 199.375 141.518 199.274 141.72C199.223 141.821 199.223 141.922 199.173 142.023C199.122 142.225 199.021 142.428 198.971 142.579C198.971 142.63 198.971 142.63 198.92 142.68C198.869 142.832 198.819 142.983 198.768 143.135C198.718 143.337 198.667 143.489 198.617 143.64C198.667 143.691 198.667 143.792 198.718 143.842C199.274 144.095 199.476 144.6 199.274 146.166C198.768 150.259 198.92 152.734 196.798 152.179L194.929 155.412C194.878 155.766 194.828 155.968 194.777 156.17C194.727 156.776 194.727 157.989 194.727 164.658C194.727 165.466 194.979 166.224 195.383 166.881C195.838 167.588 196.495 168.194 197.303 168.548C197.303 168.548 197.354 168.548 197.354 168.599C201.749 170.468 204.983 171.68 207.458 172.64Z"
            fill="white"
          />
          <path
            d="M198.667 143.691C198.819 142.68 198.364 141.367 198.01 140.508C197.96 140.356 197.859 140.205 197.808 140.053C196.192 136.819 192.554 135.455 189.118 135.253C180.327 134.799 179.468 136.466 176.74 133.94C177.599 135.203 177.65 137.274 176.336 139.8C175.426 141.569 173.658 142.377 172.041 142.882C168 133.788 170.02 128.079 174.012 126.058C172.698 123.178 172.597 122.117 172.597 122.117C172.597 122.117 181.085 123.532 183.965 122.976C187.653 122.218 193.362 123.128 195.484 128.028C199.021 129.393 200.385 131.666 200.688 134.041C201.294 137.83 199.071 142.023 198.667 143.691Z"
            fill="url(#paint1_linear_17168_46)"
          />
          <path
            d="M195.434 166.982V168.548H175.023V166.477C175.276 165.921 175.377 165.365 175.377 164.759V156.624C175.225 156.473 175.124 156.271 175.023 156.119V155.614C175.478 156.372 176.033 157.029 176.69 157.635L183.056 162.132C184.521 163.395 186.694 163.395 188.21 162.182L194.171 156.827C194.373 156.675 194.575 156.473 194.778 156.271C194.727 156.877 194.727 158.09 194.727 164.759C194.828 165.567 195.03 166.325 195.434 166.982Z"
            fill="url(#paint2_linear_17168_46)"
          />
          <g filter="url(#filter1_d_17168_46)">
            <path
              d="M99.6925 221.295H35.6795C31.4356 221.295 28 217.859 28 213.615V124.088C28 119.844 31.4356 116.409 35.6795 116.409H99.6925C103.936 116.409 107.372 119.844 107.372 124.088V213.666C107.372 217.91 103.936 221.295 99.6925 221.295Z"
              fill="url(#paint3_linear_17168_46)"
            />
          </g>
          <path
            d="M76.6032 195.882H58.718C56.7476 195.882 55.1309 194.265 55.1309 192.294C55.1309 190.324 56.7476 188.707 58.718 188.707H76.6032C78.5736 188.707 80.1904 190.324 80.1904 192.294C80.1904 194.265 78.5736 195.882 76.6032 195.882Z"
            fill="#D5DDEA"
          />
          <path
            d="M84.8387 203.662H50.5334C49.4724 203.662 48.6641 202.803 48.6641 201.793C48.6641 200.732 49.523 199.923 50.5334 199.923H84.8387C85.8997 199.923 86.708 200.782 86.708 201.793C86.708 202.803 85.8492 203.662 84.8387 203.662Z"
            fill="#D5DDEA"
          />
          <path
            d="M91.1038 153.795C91.1038 160.869 87.9714 167.184 83.0706 171.478C78.9277 175.066 73.5723 177.238 67.661 177.238C61.7498 177.238 56.3438 175.066 52.2515 171.478C47.3507 167.184 44.2183 160.869 44.2183 153.795C44.2183 140.861 54.7271 130.353 67.661 130.353C80.595 130.353 91.1038 140.861 91.1038 153.795Z"
            fill="#D9DEEA"
          />
          <path
            d="M83.0701 171.48C78.9272 175.067 73.5718 177.239 67.6606 177.239C61.7493 177.239 56.3434 175.067 52.251 171.48C53.9688 170.823 56.1918 169.964 59.1727 168.701C59.88 168.398 60.3852 167.893 60.6884 167.236C60.8399 166.882 60.941 166.478 60.941 166.023V160.415C60.8399 160.314 60.7389 160.162 60.6884 160.061C60.1831 159.354 59.88 158.495 59.7789 157.636L59.2737 157.384C57.8591 157.737 57.9601 156.171 57.5559 153.241C57.4043 152.028 57.6064 151.776 58.1117 151.574L58.5664 150.967C55.7876 144.652 57.2023 140.711 59.9305 139.347C59.0211 137.377 58.9706 136.619 58.9706 136.619C58.9706 136.619 64.8313 137.579 66.8522 137.225C69.4289 136.72 73.3192 137.326 74.7843 140.711C77.2094 141.671 78.1694 143.237 78.3715 144.854C78.5736 146.42 78.1189 148.037 77.7147 149.351C77.6642 149.452 77.6642 149.502 77.6136 149.603C77.6136 149.654 77.5631 149.654 77.5631 149.704C77.5126 149.856 77.4621 150.007 77.4115 150.159C77.361 150.209 77.361 150.31 77.3105 150.361C77.26 150.513 77.2094 150.614 77.1589 150.765V150.816C77.1084 150.917 77.0579 151.018 77.0579 151.119C77.0074 151.27 76.9568 151.371 76.9568 151.472C77.0074 151.523 77.0074 151.574 77.0074 151.624C77.4115 151.826 77.5126 152.129 77.361 153.19C77.0074 156.02 77.1084 157.737 75.6432 157.333L74.3801 159.607C74.3296 159.859 74.2791 159.96 74.2791 160.162C74.2286 160.567 74.2286 161.426 74.2286 166.023C74.2286 166.579 74.3801 167.084 74.6833 167.539C74.9864 168.044 75.4411 168.448 75.9969 168.65H76.0474C79.1799 169.964 81.4029 170.823 83.0701 171.48Z"
            fill="white"
          />
          <path
            d="M77.0577 151.471C77.1587 150.764 76.8556 149.905 76.603 149.248C76.5524 149.147 76.5019 149.046 76.4514 148.945C75.3399 146.722 72.8137 145.762 70.4391 145.661C64.3763 145.358 63.7701 146.52 61.9007 144.752C62.507 145.661 62.5575 147.025 61.6481 148.794C60.9913 150.006 59.7787 150.562 58.7177 150.915C55.939 144.6 57.3536 140.659 60.0819 139.295C59.1725 137.325 59.1219 136.567 59.1219 136.567C59.1219 136.567 64.9826 137.527 67.0036 137.173C69.5802 136.668 73.4705 137.274 74.9357 140.659C77.3608 141.619 78.3208 143.185 78.5229 144.802C78.826 147.429 77.3103 150.309 77.0577 151.471Z"
            fill="url(#paint4_linear_17168_46)"
          />
          <path
            d="M74.8347 167.588V168.7H60.7388V167.235C60.8903 166.881 60.9914 166.477 60.9914 166.022V160.414C60.8903 160.313 60.7893 160.162 60.7388 160.06V159.757C61.0419 160.263 61.4461 160.768 61.9008 161.121L66.2963 164.203C67.3068 165.062 68.8225 165.113 69.8835 164.254L74.0264 160.566C74.1779 160.465 74.3295 160.313 74.4306 160.162C74.38 160.566 74.38 161.425 74.38 166.022C74.38 166.578 74.5316 167.134 74.8347 167.588Z"
            fill="url(#paint5_linear_17168_46)"
          />
          <g filter="url(#filter2_d_17168_46)">
            <path
              d="M333.06 221.295H269.047C264.803 221.295 261.367 217.859 261.367 213.615V124.088C261.367 119.844 264.803 116.409 269.047 116.409H333.06C337.304 116.409 340.739 119.844 340.739 124.088V213.666C340.739 217.91 337.304 221.295 333.06 221.295Z"
              fill="url(#paint6_linear_17168_46)"
            />
          </g>
          <path
            d="M309.97 195.882H292.085C290.115 195.882 288.498 194.265 288.498 192.294C288.498 190.324 290.115 188.707 292.085 188.707H309.97C311.941 188.707 313.558 190.324 313.558 192.294C313.608 194.265 311.991 195.882 309.97 195.882Z"
            fill="#D5DDEA"
          />
          <path
            d="M318.206 203.662H283.901C282.84 203.662 282.031 202.803 282.031 201.793C282.031 200.732 282.89 199.923 283.901 199.923H318.206C319.267 199.923 320.075 200.782 320.075 201.793C320.075 202.803 319.267 203.662 318.206 203.662Z"
            fill="#D5DDEA"
          />
          <path
            d="M324.521 153.795C324.521 160.869 321.388 167.184 316.488 171.478C312.345 175.066 306.989 177.238 301.078 177.238C295.167 177.238 289.761 175.066 285.668 171.478C280.768 167.184 277.635 160.869 277.635 153.795C277.635 140.861 288.144 130.353 301.078 130.353C314.012 130.353 324.521 140.861 324.521 153.795Z"
            fill="#D9DEEA"
          />
          <path
            d="M316.488 171.48C312.345 175.067 306.989 177.239 301.078 177.239C295.167 177.239 289.761 175.067 285.668 171.48C287.386 170.823 289.609 169.964 292.59 168.701C293.297 168.398 293.803 167.893 294.106 167.236C294.257 166.882 294.358 166.478 294.358 166.023V160.415C294.257 160.314 294.156 160.162 294.106 160.061C293.601 159.354 293.297 158.495 293.196 157.636L292.691 157.384C291.277 157.737 291.378 156.171 290.973 153.241C290.822 152.028 291.024 151.776 291.529 151.574L291.984 150.967C289.205 144.652 290.62 140.711 293.348 139.347C292.439 137.377 292.388 136.619 292.388 136.619C292.388 136.619 298.249 137.579 300.27 137.225C302.846 136.72 306.737 137.326 308.202 140.711C310.627 141.671 311.587 143.237 311.789 144.854C311.991 146.42 311.536 148.037 311.132 149.351C311.082 149.452 311.082 149.502 311.031 149.603C311.031 149.654 310.981 149.654 310.981 149.704C310.93 149.856 310.88 150.007 310.829 150.159C310.778 150.209 310.778 150.31 310.728 150.361C310.677 150.513 310.627 150.614 310.576 150.765V150.816C310.526 150.917 310.475 151.018 310.475 151.119C310.425 151.27 310.374 151.371 310.374 151.472C310.425 151.523 310.425 151.574 310.425 151.624C310.829 151.826 310.93 152.129 310.779 153.19C310.425 156.02 310.526 157.737 309.061 157.333L307.798 159.607C307.747 159.859 307.697 159.96 307.697 160.162C307.646 160.567 307.646 161.426 307.646 166.023C307.646 166.579 307.798 167.084 308.101 167.539C308.404 168.044 308.859 168.448 309.414 168.65H309.465C312.547 169.964 314.77 170.823 316.488 171.48Z"
            fill="white"
          />
          <path
            d="M310.425 151.471C310.526 150.764 310.223 149.905 309.971 149.248C309.92 149.147 309.87 149.046 309.819 148.945C308.708 146.722 306.181 145.762 303.807 145.661C297.744 145.358 297.138 146.52 295.268 144.752C295.875 145.661 295.925 147.025 295.016 148.794C294.359 150.006 293.146 150.562 292.085 150.915C289.307 144.6 290.721 140.659 293.45 139.295C292.54 137.325 292.49 136.567 292.49 136.567C292.49 136.567 298.35 137.527 300.371 137.173C302.948 136.668 306.838 137.274 308.303 140.659C310.728 141.619 311.688 143.185 311.891 144.802C312.194 147.429 310.728 150.309 310.425 151.471Z"
            fill="url(#paint7_linear_17168_46)"
          />
          <path
            d="M308.202 167.588V168.7H294.106V167.235C294.258 166.881 294.359 166.477 294.359 166.022V160.414C294.258 160.313 294.156 160.162 294.106 160.06V159.757C294.409 160.263 294.813 160.768 295.268 161.121L299.664 164.203C300.674 165.062 302.19 165.113 303.251 164.254L307.394 160.566C307.545 160.465 307.697 160.313 307.798 160.162C307.747 160.566 307.747 161.425 307.747 166.022C307.747 166.578 307.899 167.134 308.202 167.588Z"
            fill="url(#paint8_linear_17168_46)"
          />
          <path
            d="M321.085 317.642L257.173 253.781L271.825 239.129L335.686 303.041L321.085 317.642Z"
            fill="#858B9B"
          />
          <path
            d="M352.915 349.726L303.907 300.718C299.815 296.626 299.815 289.957 303.907 285.864C308 281.772 314.669 281.772 318.761 285.864L367.769 334.872C371.861 338.964 371.861 345.633 367.769 349.726C363.676 353.818 357.007 353.818 352.915 349.726Z"
            fill="#858B9B"
          />
          <path
            d="M349.429 352.857L286.376 289.804C284.911 288.338 284.911 285.964 286.376 284.448L302.493 268.331C303.958 266.866 306.332 266.866 307.848 268.331L370.901 331.384C372.366 332.849 372.366 335.224 370.901 336.74L354.784 352.857C353.268 354.322 350.894 354.322 349.429 352.857Z"
            fill="url(#paint9_linear_17168_46)"
          />
          <path
            d="M184.824 48C117.932 48 63.7202 102.211 63.7202 169.104C63.7202 235.946 117.932 290.208 184.774 290.208C251.667 290.208 305.878 235.997 305.878 169.104C305.878 102.211 251.667 48 184.824 48ZM184.824 267.776C131.118 267.776 87.6177 223.568 87.6177 169.054C87.6177 114.539 131.118 70.3818 184.824 70.3818C238.531 70.3818 282.031 114.59 282.031 169.104C282.031 223.619 238.48 267.776 184.824 267.776Z"
            fill="url(#paint10_linear_17168_46)"
          />
          <defs>
            <filter
              id="filter0_d_17168_46"
              x="105.682"
              y="81.9153"
              width="158.89"
              height="195.873"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_17168_46"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_17168_46"
                result="shape"
              />
            </filter>
            <filter
              id="filter1_d_17168_46"
              x="6"
              y="105.409"
              width="123.372"
              height="148.886"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_17168_46"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_17168_46"
                result="shape"
              />
            </filter>
            <filter
              id="filter2_d_17168_46"
              x="239.367"
              y="105.409"
              width="123.372"
              height="148.886"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_17168_46"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_17168_46"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_17168_46"
              x1="185.09"
              y1="89.4023"
              x2="185.09"
              y2="246.425"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#FDFEFF" />
              <stop offset="0.9964" stopColor="#ECF0F5" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_17168_46"
              x1="169.821"
              y1="132.906"
              x2="200.798"
              y2="132.906"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#B0BACC" />
              <stop offset="1" stopColor="#969EAE" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_17168_46"
              x1="185.235"
              y1="168.627"
              x2="185.235"
              y2="161.501"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="white" />
              <stop offset="1" stopColor="#E2E5EC" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_17168_46"
              x1="67.6601"
              y1="113.983"
              x2="67.6601"
              y2="222.426"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#FDFEFF" />
              <stop offset="0.9964" stopColor="#ECF0F5" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_17168_46"
              x1="57.1987"
              y1="144.021"
              x2="78.5666"
              y2="144.021"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#B0BACC" />
              <stop offset="1" stopColor="#969EAE" />
            </linearGradient>
            <linearGradient
              id="paint5_linear_17168_46"
              x1="67.791"
              y1="168.754"
              x2="67.791"
              y2="163.828"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="white" />
              <stop offset="1" stopColor="#E2E5EC" />
            </linearGradient>
            <linearGradient
              id="paint6_linear_17168_46"
              x1="301.027"
              y1="113.983"
              x2="301.027"
              y2="222.426"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#FDFEFF" />
              <stop offset="0.9964" stopColor="#ECF0F5" />
            </linearGradient>
            <linearGradient
              id="paint7_linear_17168_46"
              x1="290.566"
              y1="144.021"
              x2="311.935"
              y2="144.021"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#B0BACC" />
              <stop offset="1" stopColor="#969EAE" />
            </linearGradient>
            <linearGradient
              id="paint8_linear_17168_46"
              x1="301.158"
              y1="168.754"
              x2="301.158"
              y2="163.828"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="white" />
              <stop offset="1" stopColor="#E2E5EC" />
            </linearGradient>
            <linearGradient
              id="paint9_linear_17168_46"
              x1="285.237"
              y1="310.603"
              x2="372.016"
              y2="310.603"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#B0BACC" />
              <stop offset="1" stopColor="#969EAE" />
            </linearGradient>
            <linearGradient
              id="paint10_linear_17168_46"
              x1="63.6093"
              y1="169.13"
              x2="305.922"
              y2="169.13"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#B0BACC" />
              <stop offset="1" stopColor="#969EAE" />
            </linearGradient>
          </defs>
        </svg>
      ) : (
        <svg viewBox="0 0 400 400" fill="none">
          <g filter="url(#filter0_d_17168_82)">
            <path
              d="M234.892 244.787H135.362C131.118 244.787 127.682 241.351 127.682 237.107V100.594C127.682 96.3496 131.118 92.9141 135.362 92.9141H234.892C239.136 92.9141 242.572 96.3496 242.572 100.594V237.107C242.572 241.351 239.136 244.787 234.892 244.787Z"
              fill="url(#paint0_linear_17168_82)"
            />
          </g>
          <path
            d="M198.062 207.958H172.194C169.314 207.958 166.99 205.634 166.99 202.754C166.99 199.874 169.314 197.55 172.194 197.55H198.062C200.942 197.55 203.266 199.874 203.266 202.754C203.266 205.634 200.942 207.958 198.062 207.958Z"
            fill="#A9AFC3"
          />
          <path
            d="M209.934 219.222H160.269C158.754 219.222 157.541 218.01 157.541 216.494C157.541 214.978 158.754 213.766 160.269 213.766H209.934C211.449 213.766 212.662 214.978 212.662 216.494C212.662 217.959 211.449 219.222 209.934 219.222Z"
            fill="#A9AFC3"
          />
          <path
            d="M219.078 147.075C219.078 157.281 214.581 166.426 207.458 172.64C201.496 177.844 193.665 180.976 185.126 180.976C176.588 180.976 168.757 177.844 162.795 172.59C155.671 166.375 151.175 157.231 151.175 147.025C151.175 128.281 166.382 113.073 185.126 113.073C203.871 113.073 219.078 128.331 219.078 147.075Z"
            fill="#4B5161"
          />
          <path
            d="M207.459 172.64C201.498 177.844 193.667 180.977 185.128 180.977C176.59 180.977 168.759 177.844 162.797 172.59C165.273 171.63 168.506 170.367 172.851 168.599C173.861 168.194 174.619 167.437 175.074 166.477C175.327 165.921 175.428 165.365 175.428 164.759V156.625C175.276 156.473 175.175 156.271 175.074 156.119C174.367 155.058 173.912 153.846 173.76 152.583L173.053 152.229C170.982 152.734 171.133 150.511 170.577 146.217C170.375 144.448 170.628 144.095 171.436 143.792L172.093 142.882C168.051 133.788 170.072 128.079 174.064 126.058C172.75 123.178 172.649 122.117 172.649 122.117C172.649 122.117 181.137 123.532 184.017 122.976C187.705 122.218 193.414 123.128 195.536 128.028C199.073 129.393 200.437 131.666 200.74 134.041C201.043 136.264 200.386 138.689 199.729 140.558C199.679 140.71 199.628 140.811 199.578 140.912C199.578 140.962 199.527 141.013 199.527 141.063C199.426 141.316 199.376 141.518 199.275 141.72C199.224 141.821 199.224 141.922 199.174 142.023C199.123 142.225 199.022 142.428 198.972 142.579C198.972 142.63 198.972 142.63 198.921 142.68C198.87 142.832 198.82 142.983 198.769 143.135C198.719 143.337 198.668 143.489 198.618 143.64C198.668 143.691 198.668 143.792 198.719 143.842C199.275 144.095 199.477 144.6 199.275 146.166C198.769 150.259 198.921 152.734 196.799 152.179L194.93 155.412C194.879 155.766 194.829 155.968 194.778 156.17C194.728 156.776 194.728 157.989 194.728 164.658C194.728 165.466 194.98 166.224 195.384 166.881C195.839 167.588 196.496 168.194 197.304 168.548C197.304 168.548 197.355 168.548 197.355 168.599C201.75 170.468 204.984 171.68 207.459 172.64Z"
            fill="#A9AFC3"
          />
          <path
            d="M198.672 143.691C198.823 142.68 198.368 141.367 198.015 140.508C197.964 140.356 197.863 140.205 197.813 140.053C196.196 136.819 192.558 135.455 189.123 135.253C180.332 134.799 179.473 136.466 176.744 133.94C177.603 135.203 177.654 137.274 176.34 139.8C175.431 141.569 173.663 142.377 172.046 142.882C168.004 133.788 170.025 128.079 174.016 126.058C172.703 123.178 172.602 122.117 172.602 122.117C172.602 122.117 181.089 123.532 183.969 122.976C187.658 122.218 193.367 123.128 195.489 128.028C199.025 129.393 200.389 131.666 200.692 134.041C201.299 137.83 199.076 142.023 198.672 143.691Z"
            fill="#292D35"
          />
          <path
            d="M195.435 166.981V168.548H175.024V166.476C175.277 165.921 175.378 165.365 175.378 164.758V156.624C175.226 156.473 175.125 156.271 175.024 156.119V155.614C175.479 156.372 176.034 157.028 176.691 157.635L183.057 162.131C184.522 163.394 186.695 163.394 188.21 162.182L194.172 156.826C194.374 156.675 194.576 156.473 194.779 156.271C194.728 156.877 194.728 158.089 194.728 164.758C194.829 165.567 195.031 166.325 195.435 166.981Z"
            fill="url(#paint1_linear_17168_82)"
          />
          <g filter="url(#filter1_d_17168_82)">
            <path
              d="M99.6925 221.295H35.6795C31.4356 221.295 28 217.859 28 213.615V124.088C28 119.844 31.4356 116.409 35.6795 116.409H99.6925C103.936 116.409 107.372 119.844 107.372 124.088V213.666C107.372 217.91 103.936 221.295 99.6925 221.295Z"
              fill="#414758"
            />
          </g>
          <path
            d="M76.6052 195.882H58.72C56.7496 195.882 55.1328 194.266 55.1328 192.295C55.1328 190.325 56.7496 188.708 58.72 188.708H76.6052C78.5756 188.708 80.1923 190.325 80.1923 192.295C80.1923 194.266 78.5756 195.882 76.6052 195.882Z"
            fill="#8E94A7"
          />
          <path
            d="M84.8367 203.662H50.5315C49.4705 203.662 48.6621 202.803 48.6621 201.792C48.6621 200.731 49.521 199.923 50.5315 199.923H84.8367C85.8977 199.923 86.7061 200.782 86.7061 201.792C86.7061 202.803 85.8472 203.662 84.8367 203.662Z"
            fill="#8E94A7"
          />
          <path
            d="M91.1014 153.796C91.1014 160.87 87.9689 167.185 83.0682 171.479C78.9253 175.067 73.5698 177.239 67.6586 177.239C61.7474 177.239 56.3414 175.067 52.249 171.479C47.3483 167.185 44.2158 160.87 44.2158 153.796C44.2158 140.862 54.7247 130.354 67.6586 130.354C80.5925 130.354 91.1014 140.862 91.1014 153.796Z"
            fill="#4B5161"
          />
          <path
            d="M83.0692 171.48C78.9263 175.067 73.5708 177.24 67.6596 177.24C61.7484 177.24 56.3424 175.067 52.25 171.48C53.9678 170.823 56.1908 169.964 59.1717 168.701C59.879 168.398 60.3842 167.893 60.6874 167.236C60.8389 166.883 60.94 166.478 60.94 166.024V160.416C60.8389 160.315 60.7379 160.163 60.6874 160.062C60.1821 159.355 59.879 158.496 59.778 157.637L59.2727 157.384C57.8581 157.738 57.9591 156.172 57.5549 153.241C57.4034 152.029 57.6055 151.776 58.1107 151.574L58.5654 150.968C55.7866 144.652 57.2013 140.712 59.9295 139.347C59.0201 137.377 58.9696 136.619 58.9696 136.619C58.9696 136.619 64.8303 137.579 66.8512 137.225C69.4279 136.72 73.3182 137.326 74.7834 140.712C77.2085 141.671 78.1684 143.238 78.3705 144.854C78.5726 146.421 78.1179 148.037 77.7137 149.351C77.6632 149.452 77.6632 149.503 77.6127 149.604C77.6127 149.654 77.5621 149.654 77.5621 149.705C77.5116 149.856 77.4611 150.008 77.4106 150.159C77.36 150.21 77.36 150.311 77.3095 150.361C77.259 150.513 77.2085 150.614 77.1579 150.766V150.816C77.1074 150.917 77.0569 151.018 77.0569 151.119C77.0064 151.271 76.9559 151.372 76.9559 151.473C77.0064 151.523 77.0064 151.574 77.0064 151.625C77.4106 151.827 77.5116 152.13 77.36 153.191C77.0064 156.02 77.1074 157.738 75.6423 157.334L74.3792 159.607C74.3286 159.86 74.2781 159.961 74.2781 160.163C74.2276 160.567 74.2276 161.426 74.2276 166.024C74.2276 166.579 74.3792 167.085 74.6823 167.539C74.9855 168.045 75.4402 168.449 75.9959 168.651H76.0464C79.1789 169.964 81.4019 170.823 83.0692 171.48Z"
            fill="#8E94A7"
          />
          <path
            d="M77.0577 151.47C77.1587 150.763 76.8556 149.904 76.603 149.247C76.5524 149.146 76.5019 149.045 76.4514 148.944C75.3399 146.721 72.8137 145.761 70.4391 145.66C64.3763 145.357 63.7701 146.519 61.9007 144.751C62.507 145.66 62.5575 147.024 61.6481 148.793C60.9913 150.005 59.7787 150.561 58.7177 150.915C55.939 144.599 57.3536 140.658 60.0819 139.294C59.1725 137.324 59.1219 136.566 59.1219 136.566C59.1219 136.566 64.9826 137.526 67.0036 137.172C69.5802 136.667 73.4705 137.273 74.9357 140.658C77.3608 141.618 78.3208 143.184 78.5229 144.801C78.826 147.428 77.3103 150.308 77.0577 151.47Z"
            fill="#292D35"
          />
          <path
            d="M74.8328 167.587V168.699H60.7368V167.234C60.8884 166.88 60.9894 166.476 60.9894 166.021V160.413C60.8884 160.312 60.7873 160.161 60.7368 160.059V159.756C61.04 160.262 61.4441 160.767 61.8988 161.12L66.2944 164.202C67.3048 165.061 68.8205 165.112 69.8815 164.253L74.0244 160.565C74.176 160.464 74.3276 160.312 74.4286 160.161C74.3781 160.565 74.3781 161.424 74.3781 166.021C74.3781 166.577 74.5297 167.133 74.8328 167.587Z"
            fill="url(#paint2_linear_17168_82)"
          />
          <g filter="url(#filter2_d_17168_82)">
            <path
              d="M333.061 221.295H269.048C264.804 221.295 261.369 217.859 261.369 213.615V124.088C261.369 119.844 264.804 116.409 269.048 116.409H333.061C337.305 116.409 340.741 119.844 340.741 124.088V213.666C340.741 217.91 337.305 221.295 333.061 221.295Z"
              fill="#414758"
            />
          </g>
          <path
            d="M309.969 195.882H292.084C290.114 195.882 288.497 194.266 288.497 192.295C288.497 190.325 290.114 188.708 292.084 188.708H309.969C311.94 188.708 313.557 190.325 313.557 192.295C313.607 194.266 311.99 195.882 309.969 195.882Z"
            fill="#8E94A7"
          />
          <path
            d="M318.205 203.662H283.9C282.839 203.662 282.03 202.803 282.03 201.792C282.03 200.731 282.889 199.923 283.9 199.923H318.205C319.266 199.923 320.074 200.782 320.074 201.792C320.074 202.803 319.266 203.662 318.205 203.662Z"
            fill="#8E94A7"
          />
          <path
            d="M324.522 153.796C324.522 160.87 321.39 167.185 316.489 171.479C312.346 175.067 306.991 177.239 301.079 177.239C295.168 177.239 289.762 175.067 285.67 171.479C280.769 167.185 277.637 160.87 277.637 153.796C277.637 140.862 288.146 130.354 301.079 130.354C314.013 130.354 324.522 140.862 324.522 153.796Z"
            fill="#4B5161"
          />
          <path
            d="M316.484 171.48C312.341 175.067 306.986 177.24 301.075 177.24C295.163 177.24 289.757 175.067 285.665 171.48C287.383 170.823 289.606 169.964 292.587 168.701C293.294 168.398 293.799 167.893 294.102 167.236C294.254 166.883 294.355 166.478 294.355 166.024V160.416C294.254 160.315 294.153 160.163 294.102 160.062C293.597 159.355 293.294 158.496 293.193 157.637L292.688 157.384C291.273 157.738 291.374 156.172 290.97 153.241C290.818 152.029 291.02 151.776 291.526 151.574L291.98 150.968C289.202 144.652 290.616 140.712 293.345 139.347C292.435 137.377 292.385 136.619 292.385 136.619C292.385 136.619 298.245 137.579 300.266 137.225C302.843 136.72 306.733 137.326 308.198 140.712C310.623 141.671 311.583 143.238 311.786 144.854C311.988 146.421 311.533 148.037 311.129 149.351C311.078 149.452 311.078 149.503 311.028 149.604C311.028 149.654 310.977 149.654 310.977 149.705C310.927 149.856 310.876 150.008 310.826 150.159C310.775 150.21 310.775 150.311 310.725 150.361C310.674 150.513 310.624 150.614 310.573 150.766V150.816C310.522 150.917 310.472 151.018 310.472 151.119C310.421 151.271 310.371 151.372 310.371 151.473C310.421 151.523 310.421 151.574 310.421 151.625C310.826 151.827 310.927 152.13 310.775 153.191C310.421 156.02 310.522 157.738 309.057 157.334L307.794 159.607C307.744 159.86 307.693 159.961 307.693 160.163C307.643 160.567 307.643 161.426 307.643 166.024C307.643 166.579 307.794 167.085 308.097 167.539C308.401 168.045 308.855 168.449 309.411 168.651H309.461C312.543 169.964 314.766 170.823 316.484 171.48Z"
            fill="#8E94A7"
          />
          <path
            d="M310.421 151.47C310.522 150.763 310.219 149.904 309.967 149.247C309.916 149.146 309.866 149.045 309.815 148.944C308.704 146.721 306.177 145.761 303.803 145.66C297.74 145.357 297.134 146.519 295.264 144.751C295.871 145.66 295.921 147.024 295.012 148.793C294.355 150.005 293.143 150.561 292.082 150.915C289.303 144.599 290.717 140.658 293.446 139.294C292.536 137.324 292.486 136.566 292.486 136.566C292.486 136.566 298.346 137.526 300.367 137.172C302.944 136.667 306.834 137.273 308.299 140.658C310.725 141.618 311.685 143.184 311.887 144.801C312.19 147.428 310.725 150.308 310.421 151.47Z"
            fill="#292D35"
          />
          <path
            d="M308.2 167.587V168.699H294.104V167.234C294.256 166.88 294.357 166.476 294.357 166.021V160.413C294.256 160.312 294.155 160.161 294.104 160.059V159.756C294.407 160.262 294.811 160.767 295.266 161.12L299.662 164.202C300.672 165.061 302.188 165.112 303.249 164.253L307.392 160.565C307.543 160.464 307.695 160.312 307.796 160.161C307.745 160.565 307.745 161.424 307.745 166.021C307.745 166.577 307.897 167.133 308.2 167.587Z"
            fill="url(#paint3_linear_17168_82)"
          />
          <path
            d="M321.087 317.643L257.175 253.782L271.827 239.13L335.688 303.042L321.087 317.643Z"
            fill="#858B9B"
          />
          <path
            d="M352.914 349.726L303.907 300.718C299.814 296.626 299.814 289.957 303.907 285.864C307.999 281.772 314.668 281.772 318.761 285.864L367.768 334.872C371.86 338.964 371.86 345.633 367.768 349.726C363.676 353.818 357.007 353.818 352.914 349.726Z"
            fill="#858B9B"
          />
          <path
            d="M349.431 352.856L286.378 289.803C284.913 288.338 284.913 285.963 286.378 284.448L302.495 268.331C303.96 266.866 306.334 266.866 307.85 268.331L370.903 331.384C372.368 332.849 372.368 335.224 370.903 336.739L354.786 352.856C353.27 354.321 350.896 354.321 349.431 352.856Z"
            fill="url(#paint4_linear_17168_82)"
          />
          <path
            d="M184.824 48C117.931 48 63.7197 102.211 63.7197 169.104C63.7197 235.946 117.931 290.208 184.773 290.208C251.666 290.208 305.878 235.997 305.878 169.104C305.878 102.211 251.666 48 184.824 48ZM184.824 267.776C131.118 267.776 87.6172 223.568 87.6172 169.054C87.6172 114.539 131.118 70.3818 184.824 70.3818C238.53 70.3818 282.031 114.59 282.031 169.104C282.031 223.619 238.48 267.776 184.824 267.776Z"
            fill="url(#paint5_linear_17168_82)"
          />
          <defs>
            <filter
              id="filter0_d_17168_82"
              x="105.682"
              y="81.9141"
              width="158.89"
              height="195.873"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_17168_82"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_17168_82"
                result="shape"
              />
            </filter>
            <filter
              id="filter1_d_17168_82"
              x="6"
              y="105.409"
              width="123.372"
              height="148.886"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_17168_82"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_17168_82"
                result="shape"
              />
            </filter>
            <filter
              id="filter2_d_17168_82"
              x="239.369"
              y="105.409"
              width="123.372"
              height="148.886"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_17168_82"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_17168_82"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_17168_82"
              x1="185.09"
              y1="89.4011"
              x2="185.09"
              y2="246.424"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#5C6479" />
              <stop offset="0.9964" stopColor="#5C6479" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_17168_82"
              x1="185.19"
              y1="168.145"
              x2="185.19"
              y2="161.37"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#8E94A7" />
              <stop offset="0.9913" stopColor="#6C707C" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_17168_82"
              x1="67.7576"
              y1="168.42"
              x2="67.7576"
              y2="163.736"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#8E94A7" />
              <stop offset="0.9913" stopColor="#6C707C" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_17168_82"
              x1="301.125"
              y1="168.42"
              x2="301.125"
              y2="163.736"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#8E94A7" />
              <stop offset="0.9913" stopColor="#6C707C" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_17168_82"
              x1="285.239"
              y1="310.603"
              x2="372.018"
              y2="310.603"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#B0BACC" />
              <stop offset="1" stopColor="#969EAE" />
            </linearGradient>
            <linearGradient
              id="paint5_linear_17168_82"
              x1="63.6088"
              y1="169.13"
              x2="305.922"
              y2="169.13"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#B0BACC" />
              <stop offset="1" stopColor="#969EAE" />
            </linearGradient>
          </defs>
        </svg>
      )}
    </EmptyContainer>
  );
};
