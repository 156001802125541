import React from 'react';

export const Whatsapp = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_17954_95359)">
        <path
          d="M12 0C5.3736 0 0 5.3736 0 12C0 18.6264 5.3736 24 12 24C18.6264 24 24 18.6264 24 12C24 5.3736 18.6264 0 12 0Z"
          fill="#25D366"
        />
        <path
          d="M5.39844 18.6004L6.33292 15.2093C5.7567 14.2147 5.45362 13.0881 5.45383 11.9417C5.45383 8.33687 8.40273 5.40039 12.0227 5.40039C13.7809 5.40039 15.4283 6.08286 16.6741 7.31653C17.2863 7.92442 17.7713 8.64582 18.1015 9.43946C18.4317 10.2331 18.6006 11.0834 18.5984 11.9417C18.5984 15.5468 15.6499 18.4833 12.0295 18.4833C10.9292 18.4833 9.8493 18.2073 8.88693 17.6837L5.39844 18.6004Z"
          fill="#25D366"
        />
        <path
          d="M5.39844 18.6004L6.33423 15.2139C5.7589 14.2193 5.45707 13.0929 5.45883 11.9469C5.45192 8.3344 8.39936 5.40039 12.0221 5.40039C13.7798 5.40039 15.4305 6.07891 16.6737 7.31644C17.917 8.55398 18.5984 10.1972 18.5984 11.9469C18.5984 15.553 15.6514 18.4874 12.0286 18.4874C10.9302 18.4888 9.84937 18.2141 8.88763 17.689L5.39844 18.6004ZM9.04154 16.5112L9.24201 16.6311C10.083 17.1267 11.0436 17.3887 12.0224 17.3895C15.0299 17.3895 17.4829 14.9475 17.4829 11.9538C17.4829 10.5031 16.915 9.13279 15.8857 8.10799C14.8556 7.08391 13.4789 6.51158 12.0217 6.51158C9.00733 6.51158 6.55435 8.95353 6.55435 11.9473C6.55435 12.9721 6.84178 13.9767 7.39008 14.8414L7.51706 15.048L6.96221 17.0573L9.04081 16.5116L9.04154 16.5112Z"
          fill="url(#paint0_linear_17954_95359)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.3193 8.86245C10.1958 8.58205 10.0581 8.57539 9.9413 8.57539C9.83801 8.56836 9.72799 8.56836 9.61123 8.56836C9.50121 8.56836 9.3156 8.60942 9.15768 8.78033C8.99938 8.95123 8.56641 9.35482 8.56641 10.1823C8.56641 11.0095 9.17152 11.8096 9.25385 11.9188C9.33618 12.0283 10.4225 13.7787 12.141 14.4557C13.5709 15.0161 13.8598 14.907 14.1693 14.8726C14.4784 14.8386 15.1658 14.4694 15.3103 14.0725C15.4476 13.6829 15.4476 13.3411 15.4064 13.2727C15.3653 13.2042 15.2481 13.1632 15.0831 13.0744C14.9113 12.9923 14.0866 12.5824 13.9283 12.5273C13.7703 12.4725 13.6603 12.4452 13.5503 12.6094C13.4403 12.7803 13.1173 13.1565 13.014 13.2657C12.9179 13.3752 12.8149 13.3888 12.6499 13.3067C12.4778 13.225 11.9352 13.047 11.2885 12.4725C10.787 12.0283 10.4502 11.4745 10.347 11.3102C10.2508 11.1393 10.3331 11.0505 10.4225 10.9684C10.4981 10.8933 10.5947 10.7701 10.677 10.6743C10.7593 10.5785 10.7867 10.5034 10.8488 10.3939C10.9038 10.2848 10.8761 10.182 10.8349 10.1002C10.7938 10.0248 10.4704 9.1976 10.3193 8.86245Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_17954_95359"
          x1="11.9973"
          y1="18.6018"
          x2="11.9973"
          y2="5.40219"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#F9F9F9" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <clipPath id="clip0_17954_95359">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
