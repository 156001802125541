import React from 'react';

export const Handshake = ({
  size = 24,
  color = '#6765F8',
}: {
  size?: number;
  color?: string;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none">
      <path
        d="M22.5656 11.4186L20.25 12.5717L17.25 6.8342L19.5938 5.66232C19.7679 5.57354 19.9701 5.55722 20.1563 5.61693C20.3425 5.67665 20.4975 5.80754 20.5875 5.98107L22.8937 10.3967C22.9405 10.4851 22.9691 10.5819 22.9779 10.6816C22.9867 10.7812 22.9755 10.8815 22.9449 10.9767C22.9143 11.072 22.865 11.1601 22.7999 11.236C22.7348 11.3118 22.6551 11.3739 22.5656 11.4186V11.4186Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.74984 12.4784L1.43422 11.3159C1.34506 11.2722 1.26559 11.211 1.20055 11.136C1.13551 11.0609 1.08622 10.9736 1.05561 10.8791C1.02499 10.7847 1.01369 10.685 1.02236 10.5861C1.03103 10.4872 1.05951 10.3911 1.10609 10.3034L3.41234 5.88774C3.50256 5.71433 3.65691 5.58299 3.84253 5.52171C4.02814 5.46042 4.23035 5.47402 4.40609 5.55961L6.74984 6.73149L3.74984 12.4784Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.25 12.5718L18.75 14.3343L15.3 17.7843C15.206 17.8722 15.092 17.936 14.968 17.9703C14.8439 18.0046 14.7133 18.0083 14.5875 17.9812L9.15 16.6218C9.05067 16.5941 8.95812 16.5462 8.87812 16.4812L3.75 12.478"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.7506 14.3345L14.6256 11.3345L13.4256 12.2345C12.9057 12.6225 12.2743 12.8321 11.6256 12.8321C10.9768 12.8321 10.3455 12.6225 9.82557 12.2345L9.31932 11.8501C9.23371 11.785 9.16291 11.7025 9.11158 11.608C9.06026 11.5135 9.0296 11.4092 9.02162 11.3019C9.01365 11.1947 9.02854 11.087 9.06531 10.9859C9.10209 10.8849 9.15991 10.7928 9.23495 10.7157L12.9099 7.0501C12.979 6.98141 13.0609 6.927 13.151 6.89C13.2411 6.853 13.3376 6.83414 13.4349 6.83448H17.2506"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.80469 6.73121L11.6141 5.32496C11.785 5.27595 11.9677 5.28924 12.1297 5.36246L15.3734 6.83433"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 19.9594L7.67813 19.2469C7.56313 19.2209 7.45666 19.166 7.36875 19.0875L5.25 17.25"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
