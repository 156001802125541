import {useQuery} from '@apollo/client';
import dayjs from 'dayjs';
import {CompanyInfo, MY_TARIFF, TariffBox} from 'entities/tariff';
import React, {useRef, useState} from 'react';
import {useAppSelector} from 'shared/store';
import {Layout} from 'shared/ui/Layout';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';
import {SubscriptionInfo} from './ui/SubscriptionInfo';
import List from 'shared/icons/List';
import {NavLink} from 'shared/ui/NavLink';
import {Subscription} from './ui/SubscriptionHistory';
import {Button} from 'shared/ui/Button';
import {CancelSubscription} from './ui/CancelSubscription';
import {useNavigate} from 'react-router-dom';
import {Skeleton} from 'shared/ui/Skeleton';
import {Content} from 'shared/ui/Content';
import {Flex} from 'shared/ui/Flex';
import {Seo} from 'shared/ui/Seo';
import {CircleClose} from 'shared/icons/CircleClose';
import {useColors} from 'shared/lib/hooks';
import {DeleteCard} from './ui/DeleteCard';
import {getPriceFormat} from 'shared/lib/utils';
import {SubscriptionsType} from 'shared/__generated__/graphql';

const Wrapper = styled.div`
  grid-column-start: 1;
  grid-column-end: 13;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 720px;
  margin: 0 auto;
  padding: 24px 0;
  gap: 24px;
`;
const Head = styled.div`
  display: flex;
  align-items: center;
  height: 56px;
`;
const CircleIcon = styled.div`
  height: 48px;
  width: 48px;
  border-radius: 24px;
  display: flex;
  border: 1px solid ${({theme}) => theme.borderPrimary};
  justify-content: center;
  align-items: center;
`;
const Card = styled.div`
  flex: 1;
  padding: 16px;
  height: 64px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  background: ${({theme}) => theme.fillPrimary};
  border: 1px solid ${({theme}) => theme.borderPrimary};
`;
const DeleteButton = styled.button`
  display: flex;
  height: 100%;
  width: 44px;
  align-items: center;
  justify-content: center;
  pointer-events: all;
  cursor: pointer;
  border: none;
  background-color: transparent;
`;

const NavIcon = (
  <CircleIcon>
    <List />
  </CircleIcon>
);

const getDescription = (type: SubscriptionsType, slots: number) => {
  if (type === SubscriptionsType.Forever)
    return 'c тарифом бесплатный, 1 пользователь';
  const countEmployeesText = slots
    ? 'с 1 пользователем'
    : `до ${slots} пользователей`;
  switch (type) {
    case SubscriptionsType.Threemonths:
      return 'тарифом на 3 месяца, ' + countEmployeesText;
    case SubscriptionsType.Sixmonths:
      return 'тарифом на 6 месяцев, ' + countEmployeesText;
    case SubscriptionsType.Year:
      return 'тарифом на год, ' + countEmployeesText;
    default:
      return 'тарифом на месяц, ' + countEmployeesText;
  }
};

export function MyTariff() {
  const navigate = useNavigate();
  const colors = useColors();
  const username = useAppSelector(state => state.company.data?.username);
  const companyId = useAppSelector(state => state.company.data?.id);
  const [cancelPlanModal, setCancelPlanModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const prevCompanyName = useRef(username);
  const {data, loading} = useQuery(MY_TARIFF, {
    variables: {companyId: companyId!, first: 4, page: 1},
    onCompleted: res => {
      const isPermissionSuccess =
        res.companyPermissions?.subscription_and_pay_permission;
      if (!isPermissionSuccess || res.company?.plan === null) {
        navigate(`/${username}/bookings`);
        return;
      }
      if (prevCompanyName.current !== username) {
        prevCompanyName.current = username;
      }
    },
  });
  const plan = data?.company?.plan;
  const cancelPlan = plan
    ? getDescription(plan?.type, plan?.slots)
    : 'Не выбран';
  const expired = dayjs().isAfter(dayjs(data?.company?.subscription_end_date));
  const free = plan?.type === 'forever';
  const subscriptionTitle = free ? 'Бесплатный тариф' : 'PRO-тариф';
  const isSubscriptionActive = data?.company?.is_subscription_active ?? false;
  const activeEmployee = data?.getCompanyEmployeesLength ?? 1;
  const historySubscriptions = data?.company?.subscriptions?.data ?? [];
  const balance = data?.me?.bonus_account ?? 0;
  const CompanyInformation = data?.company?.companyAccountInfo;
  const isActivePlan = plan?.type ? !expired : false;
  const findCompanyCard = data
    ? data.me?.card_token?.find(
        item => item.id === data?.company?.user_card_token_id,
      )
    : null;
  return (
    <Layout variant="sidebar">
      <Seo title="Мой тариф" />
      <Wrapper>
        <Container>
          <Head>
            <Text typography="title24">Мой тариф</Text>
          </Head>
          {loading ? (
            <SkeletonMyTariff />
          ) : (
            <>
              <SubscriptionInfo
                plan={plan}
                status={
                  free
                    ? 'free'
                    : expired || !isSubscriptionActive
                    ? 'canceled'
                    : 'active'
                }
                title={subscriptionTitle}
                subscriptionEndDate={data?.company?.subscription_end_date}
              />
              <TariffBox title="История платежей">
                {historySubscriptions.length > 0 ? (
                  <>
                    {historySubscriptions.map(item => (
                      <Subscription key={item.id} subscription={item} />
                    ))}
                    <NavLink
                      onClick={() => navigate(`/${username}/payments-history`)}
                      icon={NavIcon}
                      label="Все платежи"
                    />
                  </>
                ) : (
                  <Text color="textTertiary">
                    У вас еще нет оплаченных счетов
                  </Text>
                )}
              </TariffBox>
              <TariffBox title="Счёт">
                <Flex gap={4} direction="column">
                  <Text typography="footNote12Regular" color="textTertiary">
                    Баланс
                  </Text>
                  <Text typography="title24">{getPriceFormat(balance)}</Text>
                </Flex>
              </TariffBox>
              {findCompanyCard && (
                <TariffBox
                  alignItems="center"
                  gap={16}
                  direction="row"
                  title="Платёжные реквизиты">
                  <Card>
                    <Text>
                      {'**** **** **** ' + findCompanyCard.last_four_digits}
                    </Text>
                  </Card>
                  <DeleteButton
                    onClick={() => {
                      setDeleteModal(true);
                    }}>
                    <CircleClose color={colors.dangerPrimary} />
                  </DeleteButton>
                </TariffBox>
              )}
              {CompanyInformation && (
                <TariffBox title="Информация для выставления счёта">
                  <CompanyInfo info={CompanyInformation} />
                </TariffBox>
              )}
              {!free && isActivePlan ? (
                <Button
                  size="large"
                  onClick={() => setCancelPlanModal(true)}
                  variant="light">
                  Отменить подписку
                </Button>
              ) : null}
              {expired ? (
                <Button
                  size="large"
                  onClick={() => navigate(`/${username}/tariff-plans`)}
                  variant="light">
                  Выбрать тарифный план
                </Button>
              ) : null}
            </>
          )}
        </Container>
      </Wrapper>
      <CancelSubscription
        activeEmployee={activeEmployee}
        visible={cancelPlanModal}
        isFree={free}
        onClose={() => setCancelPlanModal(false)}
        endDate={data?.company?.subscription_end_date ?? undefined}
        cancelPlan={cancelPlan}
      />
      {findCompanyCard?.id && (
        <DeleteCard
          id={findCompanyCard?.id}
          visible={deleteModal}
          onClose={() => setDeleteModal(false)}
        />
      )}
    </Layout>
  );
}

const SkeletonMyTariff = () => {
  const width = 'calc((100% - 120px)/4)';
  const widthCount = 'calc((100% - 120px)/4 - 72px)';
  return (
    <Flex gap={24} direction="column" flex={1}>
      <Content>
        <Skeleton height={236}>
          <rect x={0} y={6} width={240} height={28} rx={8} ry={8} />
          <rect
            x={'calc(100% - 128px)'}
            y={0}
            width={128}
            height={40}
            rx={8}
            ry={8}
          />
          {Array.from(Array(4).keys()).map(index => (
            <React.Fragment key={'my_tariff_' + index}>
              <rect
                x={`calc((${index} * (100% - 120px) / 4) + ${index * 40}px)`}
                y={64}
                width={width}
                height={40}
                rx={8}
                ry={8}
              />
              <rect
                width={widthCount}
                height={20}
                rx={8}
                ry={8}
                x={`calc(36px + (${index} * (100% - 120px) / 4) + ${
                  index * 40
                }px)`}
                y={120}
              />
            </React.Fragment>
          ))}
          <rect x={0} y={174} width={'100%'} height={60} rx={14} ry={14} />
        </Skeleton>
      </Content>
      <Content>
        <Skeleton height={68}>
          <rect x={0} y={12} width={474} height={14} rx={12} />
          <rect x={0} y={42} width={536} height={14} rx={12} />
        </Skeleton>
      </Content>
      <Content>
        <Skeleton height={108}>
          <rect x={0} y={6} width={403} height={16} rx={12} />
          <rect x={0} y={44} width={'100%'} height={64} rx={12} />
        </Skeleton>
      </Content>
      <Content>
        <Skeleton height={132}>
          <rect x={0} y={0} width={403} height={28} rx={8} />
          <rect x={0} y={44} width={'100%'} height={88} rx={20} />
        </Skeleton>
      </Content>
    </Flex>
  );
};
