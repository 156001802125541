import React from 'react';
import {
  CompanyTag,
  Customer,
  Image,
  Color,
  OrderByOrder,
  SortOrder,
} from 'shared/__generated__/graphql';
import {getName} from 'shared/lib/utils';
import {OptionsType, Table} from 'shared/ui/Table';
import {Text} from 'shared/ui/Text';
import {useNavigate} from 'react-router-dom';
import {useAppSelector} from 'shared/store';
import {BadgeTag, Messengers} from 'entities/customers';
import {Avatar} from 'shared/ui/Avatar';
import {Content} from 'shared/ui/Content';
import {Flex} from 'shared/ui/Flex';
import styled from 'styled-components';

type Props = {
  customers?: Array<
    Pick<
      Customer,
      | 'id'
      | 'name'
      | 'surname'
      | 'email'
      | 'phone'
      | 'is_vip'
      | 'total_visits'
      | 'total_cancelled'
      | 'total_earned'
      | 'birthdate'
      | 'is_blocked'
    > & {
      avatar?: Pick<Image, 'id' | 'url' | 'url_256'> | null;
      tag?: Array<
        Pick<CompanyTag, 'id' | 'name' | 'order'> & {
          color?: Pick<Color, 'id' | 'rgb'> | null;
        }
      > | null;
    }
  >;
  onClick?: () => void;
  onChange?: () => void;
  checked?: boolean;
  options: OptionsType;
  setOptions: React.Dispatch<React.SetStateAction<OptionsType>>;
  onSelectionChange?: (values: number[]) => void;
  permission: boolean;
  contactPermission: boolean;
  sort?: {index: number; order: OrderByOrder};
  setSort: React.Dispatch<
    React.SetStateAction<{index: number; order: OrderByOrder} | undefined>
  >;
  selections: number[];
};

const TagsList = styled.div`
  display: flex;
  gap: 12px;
  min-width: 320px;
`;

const titles = [
  'Клиент',
  'Телефон',
  'Посещений',
  'Отменённые',
  'Выручка',
  'Теги',
];
const widths = [303, 268, 180, 180, 220, 372];

export const CustomersTable = ({
  customers = [],
  options,
  setOptions,
  onSelectionChange,
  permission,
  contactPermission,
  sort,
  setSort,
  selections,
}: Props) => {
  const navigate = useNavigate();
  const username = useAppSelector(state => state.company.data?.username);
  customers.map(item => item.tag?.map(item => item.color?.id));

  return (
    <Content>
      <Table
        style={{overflow: 'visible'}}
        titles={titles}
        widths={widths}
        options={options}
        setOptions={setOptions}
        onSelectionChange={onSelectionChange}
        selections={selections}
        alignRightColumn={[2, 3, 4]}
        sort={
          sort
            ? {
                index: sort.index,
                order:
                  sort.order === OrderByOrder.Asc
                    ? SortOrder.Asc
                    : SortOrder.Desc,
              }
            : undefined
        }
        onClickTitle={(index, order) => {
          if (sort?.index === index && sort.order === OrderByOrder.Asc) {
            setSort(undefined);
            return;
          }
          setSort({
            index,
            order:
              order === SortOrder.Asc ? OrderByOrder.Asc : OrderByOrder.Desc,
          });
          onSelectionChange && onSelectionChange([]);
        }}
        onClick={index =>
          permission &&
          !!customers?.length &&
          navigate(`/${username}/customer/${customers[index].id}`)
        }
        rows={customers.map((customer, index) => [
          <Flex key={index + 1} alignItems="center" gap={16}>
            <Avatar
              size={40}
              url={customer.avatar?.url_256 ?? customer.avatar?.url}
            />
            <Text key={`name_${index}`}>
              {getName(customer.name, customer.surname)}
            </Text>
          </Flex>,
          contactPermission ? (
            <Messengers
              permission={permission}
              key={`phone_${index}`}
              phone={customer.phone}
              email={customer.email}
            />
          ) : (
            customer.phone
          ),
          customer.total_visits,
          customer.total_cancelled,
          customer.total_earned,
          <TagsList key={`tags_${index}`}>
            {customer.tag
              ?.slice(0, 4)
              .map(item => (
                <BadgeTag
                  key={`tag_${item.id}`}
                  name={item.name}
                  color={item.color?.rgb}
                />
              ))}
            {customer.tag && customer.tag.length > 4 ? (
              <BadgeTag
                name={'...'}
                style={{paddingLeft: 6, paddingRight: 6}}
              />
            ) : null}
          </TagsList>,
        ])}
      />
    </Content>
  );
};
