import React, {useEffect, useState} from 'react';
import {DndContext, DragEndEvent, closestCenter} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {ResourcesSortableItem} from './ResourcesSortableItem';
import {ResourceType} from '..';
import {ResourceEditModal} from './ResourceEditModal';
import {
  restrictToParentElement,
  restrictToVerticalAxis,
} from '@dnd-kit/modifiers';
import {Divider} from 'shared/ui/Divider';

type Props = {
  resources: Array<ResourceType>;
  onDragEnd?: (resourses: ResourceType[]) => void;
};

export const ResourcesDragDrop = ({
  resources: resourcesData,
  onDragEnd,
}: Props) => {
  const [resources, setResources] = useState(resourcesData);
  const [resource, setResource] = useState<ResourceType | null>(null);

  function handleDragEnd(event: DragEndEvent) {
    const {active, over} = event;

    if (active.id !== over?.id) {
      setResources(items => {
        const ids = items.map(item => item.id);
        const activeIndex = ids.indexOf(active!.id.toString());
        const overIndex = ids.indexOf(over!.id.toString());
        onDragEnd && onDragEnd(arrayMove(items, activeIndex, overIndex));
        return arrayMove(items, activeIndex, overIndex);
      });
    }
  }
  useEffect(() => {
    setResources(resourcesData);
  }, [resourcesData]);

  return (
    <>
      {resource && (
        <ResourceEditModal
          resource={resource}
          visible={!!resource}
          onClose={() => {
            setResource(null);
          }}
        />
      )}
      <DndContext
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToVerticalAxis, restrictToParentElement]}>
        <SortableContext
          items={resources}
          strategy={verticalListSortingStrategy}>
          {resources.map((resource, index) => (
            <>
              <ResourcesSortableItem
                key={resource.id}
                resource={resource}
                onClick={() => setResource(resource)}
              />
              {index < resources.length - 1 && <Divider />}
            </>
          ))}
        </SortableContext>
      </DndContext>
    </>
  );
};
