import React from 'react';
import {TariffPlan} from 'shared/lib/utils';
import {SubscriptionsType} from 'shared/__generated__/graphql';
import styled from 'styled-components';
import {Flex} from 'shared/ui/Flex';
import {RadioButton} from 'shared/ui/RadioButton';
import {Text} from 'shared/ui/Text';
import {TariffBox} from 'entities/tariff';
const PeriodContainer = styled(Flex)`
  height: 48px;
  gap: 16px;
  pointer-events: all;
  cursor: pointer;
`;
const Discount = styled.div`
  display: flex;
  padding: 6px 14px;
  background-color: ${({theme}) => theme.bgDanger};
  color: ${({theme}) => theme.dangerPrimary};
  border-radius: 8px;
`;
const Period = ({
  title,
  type,
  selectedType,
  value,
  onSelect,
}: {
  title: string;
  type: SubscriptionsType;
  value: number;
  selectedType: SubscriptionsType;
  onSelect: (type: SubscriptionsType) => void;
}) => {
  return (
    <PeriodContainer onClick={() => onSelect(type)} alignItems="center">
      <RadioButton
        style={{flex: 0}}
        selected={type === selectedType}
        onChange={() => onSelect(type)}
      />
      <Text style={{pointerEvents: 'none'}}>{title}</Text>
      {value > 0 ? <Discount>{`Скидка ${value}%`}</Discount> : null}
    </PeriodContainer>
  );
};

const getLabelByTypePlan = (type_s: SubscriptionsType) => {
  switch (type_s) {
    case SubscriptionsType.Year:
      return 'Ежегодно';
    case SubscriptionsType.Sixmonths:
      return 'Каждые 6 месяцев';
    case SubscriptionsType.Threemonths:
      return 'Каждые 3 месяца';
    default:
      return 'Ежемесячно';
  }
};
const getMonthByTypePlan = (type_s: SubscriptionsType) => {
  switch (type_s) {
    case SubscriptionsType.Year:
      return 12;
    case SubscriptionsType.Sixmonths:
      return 6;
    case SubscriptionsType.Threemonths:
      return 3;
    default:
      return 1;
  }
};

export const TypeSection = ({
  plan,
  onSelect,
  data,
  initialPlan,
}: {
  plan: TariffPlan;
  initialPlan: TariffPlan;
  data: TariffPlan[];
  onSelect: (value: TariffPlan) => void;
}) => {
  const isDev = process.env.REACT_APP_DOMAIN?.includes('dev') ?? false;
  const filteredPeriod = isDev
    ? data
    : data.filter(p => p.type !== SubscriptionsType.Day);
  return (
    <TariffBox title="Период оплаты" gap={8}>
      {filteredPeriod.map(period => (
        <Period
          {...period}
          value={Math.round(
            (1 -
              period.price /
                getMonthByTypePlan(period.type) /
                initialPlan.price) *
              100,
          )}
          title={getLabelByTypePlan(period.type)}
          key={period.id + ' period'}
          selectedType={plan.type}
          onSelect={type => {
            const find = data.find(item => item.type === type);
            find && onSelect(find);
          }}
        />
      ))}
    </TariffBox>
  );
};
