import dayjs from 'dayjs';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {ProductsHistoryEnum} from 'shared/__generated__/graphql';
import Xv2 from 'shared/icons/Xv2';
import {getNumberFormat, getPriceFormat} from 'shared/lib/utils';
import {useAppSelector} from 'shared/store';
import {Flex} from 'shared/ui/Flex';
import {Head} from 'shared/ui/Head';
import {IconButton} from 'shared/ui/IconButton';
import {Modal} from 'shared/ui/Modal';
import {Text} from 'shared/ui/Text';

export const ModalReserve = ({
  visible,
  onClose,
  type,
  address,
  countAfter,
  costPrice,
  created,
  employeeId,
  employeeName,
  count,
  operation,
}: {
  visible: boolean;
  onClose: () => void;
  id?: string;
  type?: ProductsHistoryEnum;
  address?: string;
  countAfter: number;
  costPrice?: number;
  created?: string;
  employeeName?: string;
  employeeId?: string;
  count?: number;
  operation?: string;
}) => {
  const username = useAppSelector(state => state.company.data?.username);
  const navigate = useNavigate();
  return (
    <Modal
      style={{width: '588px', left: 'calc((100% - 588px) / 2)', padding: 24}}
      visible={visible}
      onClose={onClose}>
      <Flex direction="column" gap={32}>
        <Head style={{justifyContent: 'space-between'}}>
          <Text typography="title20">Сведения об операции</Text>
          <IconButton onClick={() => onClose()}>
            <Xv2 />
          </IconButton>
        </Head>
        <Flex direction="column" gap={24}>
          <Flex justifyContent="space-between">
            <Text typography="text16Semibold">Дата</Text>
            <Text>{dayjs(created).format('DD MMM YYYY в HH:mm')}</Text>
          </Flex>
          <Flex justifyContent="space-between">
            <Text typography="text16Semibold">Сотрудник</Text>
            <Text
              color="mainPrimary"
              style={{cursor: 'pointer'}}
              onClick={() => {
                onClose();
                navigate(`/${username}/employee/${employeeId}`);
              }}>
              {employeeName}
            </Text>
          </Flex>
          <Flex justifyContent="space-between">
            <Text typography="text16Semibold">Адрес</Text>
            <Text
              color="mainPrimary"
              style={{cursor: 'pointer'}}
              onClick={() => {
                onClose();
                navigate(`/${username}/company`);
              }}>
              {address}
            </Text>
          </Flex>
          <Flex justifyContent="space-between">
            <Text typography="text16Semibold">Операция</Text>
            <Text>{operation}</Text>
          </Flex>
          {type === ProductsHistoryEnum.Repeal && (
            <Flex justifyContent="space-between">
              <Text typography="text16Semibold">Клиент</Text>
            </Flex>
          )}
          <Flex justifyContent="space-between">
            <Text typography="text16Semibold">Количество</Text>
            <Text>
              {type === ProductsHistoryEnum.Fill
                ? '+ ' + getNumberFormat(count, ' шт.')
                : '- ' + getNumberFormat(count, ' шт.')}
            </Text>
          </Flex>
          <Flex justifyContent="space-between">
            <Text typography="text16Semibold">Себестоимость</Text>
            <Text>{getPriceFormat(costPrice)}</Text>
          </Flex>
          <Flex justifyContent="space-between">
            <Text typography="text16Semibold">
              Запасы склада после изменений
            </Text>
            <Text>{getNumberFormat(countAfter, ' шт.')}</Text>
          </Flex>
        </Flex>
      </Flex>
    </Modal>
  );
};
