import React from 'react';
import {useColors} from 'shared/lib/hooks';

export function Train() {
  const colors = useColors();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none">
      <path
        d="M8.65235 2.91637C9.83652 2.36121 11.1636 2.36121 12.3477 2.91637L17.9238 5.53053C19.1366 6.09916 19.1366 8.06754 17.9237 8.63617L12.3478 11.2503C11.1636 11.8055 9.83659 11.8055 8.65242 11.2503L3.07641 8.63614C1.86352 8.0675 1.86353 6.09912 3.07642 5.53049L8.65235 2.91637Z"
        stroke={colors.mainPrimary}
        strokeWidth="1.5"
      />
      <path
        d="M2.16675 7.08333V11.6667"
        stroke={colors.mainPrimary}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M16.3334 9.58333V13.8545C16.3334 14.6945 15.9138 15.481 15.179 15.888C13.9553 16.5656 11.9968 17.5 10.5001 17.5C9.00341 17.5 7.04483 16.5656 5.82119 15.888C5.08633 15.481 4.66675 14.6945 4.66675 13.8545V9.58333"
        stroke={colors.mainPrimary}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}
