import React, {useEffect, useState} from 'react';
import {useLazyQuery} from '@apollo/client';
import {Search} from 'shared/icons/Search';
import {useAppSelector} from 'shared/store';
import {Button} from 'shared/ui/Button';
import {Select} from 'shared/ui/Select';
import {Skeleton} from 'shared/ui/Skeleton';
import {Table} from 'shared/ui/Table';
import {Text} from 'shared/ui/Text';
import {TextInput} from 'shared/ui/TextInput';
import styled from 'styled-components';
import {Flex} from 'shared/ui/Flex';
import {useNavigate} from 'react-router-dom';
import {CloseButton} from 'shared/ui/CloseButton';
import {useColors} from 'shared/lib/hooks';
import {ArrowDown2v} from 'shared/icons/ArrowDown2v';
import {
  SERVICES_SELECT_SERVICES,
  getServicePriceOfType,
} from 'entities/loyalty';
import {EmptyList2} from 'shared/illustration/EmptyList2';

const Wrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${({theme}) => theme.bgPrimary};
  border: 1px solid ${({theme}) => theme.borderPrimary};
  width: 988px;
  max-height: calc(100vh - 96px);
  border-radius: 24px;
  padding: 24px;
  z-index: 1001;
  gap: 24px;
  ${({theme}) => theme.cardShadow}
`;

const Head = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`;

const FilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const Backdrop = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  background-color: ${({theme}) => theme.modalOverlay};
  opacity: 0.5;
  bottom: 0;
  z-index: 999;
  pointer-events: all;
`;

type Props = {
  label?: string;
  visible: boolean;
  onChange: (
    values: {label: string; value: string}[],
    maxLength?: number,
  ) => void;
  onClose: () => void;
  values: {label: string; value: string}[];
  changeLoading?: boolean;
  excludes?: {label: string; value: string}[];
};

export const SelectServices = ({
  label,
  values,
  visible,
  changeLoading,
  onChange,
  onClose,
  excludes = [],
}: Props) => {
  const companyId = useAppSelector(state => state.company.data!.id);
  const colors = useColors();
  const [search, setSearch] = useState('');
  const [selected, setSelected] = useState<number[]>([]);
  const [category, setCategory] = useState<
    | {
        label: string;
        value: string;
      }
    | undefined
  >();
  const [subcategory, setSubCategory] = useState<
    | {
        label: string;
        value: string;
      }
    | undefined
  >();
  const filterExcludes = excludes.filter(item => !values.includes(item));

  const [getServices, {data, loading}] = useLazyQuery(
    SERVICES_SELECT_SERVICES,
    {
      variables: {
        companyId,
        query: search,
        page: 1,
        first: 100,
        categoryId: category?.value ? [category.value] : undefined,
        subcategoryId: subcategory?.value ? [subcategory.value] : undefined,
      },
      onCompleted: resData => {
        const temp: number[] = [];
        const resServices = resData.companyServiceSearch?.data ?? [];
        if (resServices.length) {
          const selectedServicesId = values.map(value => value.value);
          resServices
            .filter(
              item => !filterExcludes.map(item => item.value).includes(item.id),
            )
            .forEach((service, serviceIndex) => {
              if (selectedServicesId.includes(service.id)) {
                temp.push(serviceIndex);
              }
            });
          setSelected(temp);
        }
      },
    },
  );

  useEffect(() => {
    if (visible) {
      getServices();
    }
  }, [getServices, values, visible]);

  const services =
    data?.companyServiceSearch?.data.filter(
      item => !filterExcludes.map(item => item.value).includes(item.id),
    ) ?? [];
  const categories =
    data?.company?.categories.map(categoryItem => ({
      label: categoryItem.title,
      value: categoryItem.id,
    })) ?? [];
  const subcategories =
    data?.company?.categories
      .find(categoryItem => categoryItem.id === category?.value)
      ?.subcategories.map(subcategoryItem => ({
        label: subcategoryItem.title,
        value: subcategoryItem.id,
      })) ?? [];
  return (
    <>
      <Backdrop onClick={() => onClose()} />
      <Wrapper onClick={e => e.stopPropagation()}>
        <Head>
          <Text typography="title20" color="textPrimary">
            {label}
          </Text>
          <CloseButton
            onClose={() => {
              onClose();
            }}
          />
        </Head>
        <FilterWrapper>
          <TextInput
            width="348px"
            placeholder="Поиск по названию услуги"
            value={search}
            height={54}
            variant="default"
            onChange={setSearch}
            leftElement={<Search color={colors.textTertiary} />}
          />
          <Select
            label={category?.value ? '' : 'Категория'}
            variant="default"
            valueColor={category?.value ? 'white' : 'textPrimary'}
            wrapperStyle={{width: 'auto'}}
            style={{height: 56}}
            backgroundColor={category?.value ? colors.mainPrimary : undefined}
            icon={
              <ArrowDown2v
                color={category?.value ? colors.white : colors.mainPrimary}
              />
            }
            data={[{label: 'Все категории', value: ''}, ...categories]}
            value={category?.value}
            onChange={value => {
              setCategory(
                categories.find(categoryItem => categoryItem.value === value),
              );
              setSubCategory(undefined);
            }}
          />
          {category?.value && (
            <Select
              variant="default"
              valueColor={subcategory?.value ? 'white' : 'textPrimary'}
              label={subcategory?.value ? '' : 'Подкатегория'}
              wrapperStyle={{width: 'auto'}}
              style={{height: 56}}
              backgroundColor={
                subcategory?.value ? colors.mainPrimary : colors.fillPrimary
              }
              icon={
                <ArrowDown2v
                  color={subcategory?.value ? colors.white : colors.mainPrimary}
                />
              }
              data={[{label: 'Все подкатегории', value: ''}, ...subcategories]}
              value={subcategory?.value}
              onChange={value => {
                setSubCategory(
                  subcategories.find(
                    subcategoryItem => subcategoryItem.value === value,
                  ),
                );
              }}
            />
          )}
        </FilterWrapper>
        {loading ? (
          <ServiceSkeleton />
        ) : services.length ? (
          <Flex
            flex={1}
            justifyContent="space-between"
            direction="column"
            style={{gap: 24}}>
            <Flex
              flex={1}
              direction="column"
              style={{width: '100%', maxHeight: 412, overflow: 'auto'}}>
              <Table
                heightRow={'60px'}
                titles={['Название', 'Длительность', 'Цена от и до']}
                rows={services.map(service => [
                  service.name,
                  service.full_duration,
                  getServicePriceOfType(
                    service.type,
                    service.price,
                    service.min_price,
                    service.max_price,
                  ),
                ])}
                onClick={index => {
                  if (selected.includes(index)) {
                    setSelected(selected.filter(item => item !== index));
                  } else {
                    setSelected([...selected, index]);
                  }
                }}
                selections={selected}
                onSelectionChange={value => setSelected(value)}
              />
            </Flex>
            <Footer>
              <Button
                loading={changeLoading}
                disabled={changeLoading}
                style={{width: 180}}
                size="large"
                onClick={() => {
                  const temp = services
                    .filter((_, serviceIndex) =>
                      selected.includes(serviceIndex),
                    )
                    .map(service => ({
                      label: service.name,
                      value: service.id,
                    }));
                  onChange(
                    temp,
                    data?.companyServiceSearch?.paginatorInfo.total,
                  );
                }}>
                Сохранить
              </Button>
              <Button
                style={{width: 180}}
                size="large"
                variant="light"
                onClick={() => {
                  onClose();
                }}>
                Отмена
              </Button>
              <Flex style={{width: 148, justifyContent: 'center'}}>
                {selected.length > 0 && (
                  <Text typography="text16Regular" color="mainPrimary">
                    Выбрано {selected.length}
                  </Text>
                )}
              </Flex>
            </Footer>
          </Flex>
        ) : (
          <EmptyState search={search} />
        )}
      </Wrapper>
    </>
  );
};

const LENGTH = 4;

const ServiceSkeleton = () => {
  return (
    <Skeleton height={36 + LENGTH * 54} style={{marginTop: 8}}>
      <circle r={12} cx={12} cy={12} />
      <rect width={162} height={24} rx={12} ry={12} x={51} />
      <rect width={59} height={24} rx={12} ry={12} x={414} />
      <rect width={59} height={24} rx={12} ry={12} x={674} />
      {Array(LENGTH)
        .fill(0)
        .map((_, index) => (
          <React.Fragment key={'service_skeleton_' + index}>
            <circle r={12} cx={12} cy={12 + 54 * (index + 1)} />
            <rect
              width={284}
              height={24}
              rx={12}
              ry={12}
              x={51}
              y={54 * (index + 1)}
            />
            <rect
              width={181}
              height={24}
              rx={12}
              ry={12}
              x={414}
              y={54 * (index + 1)}
            />
            <rect
              width={181}
              height={24}
              rx={12}
              ry={12}
              x={674}
              y={54 * (index + 1)}
            />
          </React.Fragment>
        ))}
    </Skeleton>
  );
};

const EmptyState = ({search}: {search: string}) => {
  const navigate = useNavigate();
  const username = useAppSelector(state => state.company.data?.username);
  return (
    <Flex flex={1} direction="column" gap={16} alignItems="center">
      <EmptyList2 size={300} />
      {search.length === 0 ? (
        <>
          <Text
            typography="text18"
            color="textTertiary"
            style={{textAlign: 'center'}}>
            У вас пока нет услуг. Добавьте услуги <br /> и начните работу
          </Text>
          <Button
            size="large"
            style={{width: 360}}
            onClick={() => navigate(`/${username}/services`)}>
            Добавить услуги
          </Button>
        </>
      ) : (
        <Text
          typography="text16Regular"
          color="textTertiary"
          style={{textAlign: 'center'}}>
          По данному запросу услуг не найдено. Проверьте <br /> данные и
          попробуйте еще раз
        </Text>
      )}
    </Flex>
  );
};
