import React from 'react';
const Info = () => {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 11v6m10-1c0 5.523-4.477 10-10 10S6 21.523 6 16 10.477 6 16 6s10 4.477 10 10z"
        stroke="#FF8E3C"
        strokeWidth={1.5}
        strokeLinecap="round"
      />
      <circle cx={16} cy={20} r={1} fill="#FF8E3C" />
    </svg>
  );
};

export default Info;
